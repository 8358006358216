import * as React from "react";
import { motion } from "framer-motion";

const Transition = ({children}: React.PropsWithChildren) => {
  return(
  <motion.main
   className="main__container"
   initial={{ opacity: 0, scale: 1 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5, ease: "easeIn" }}
  >
    {children}
  </motion.main>
  )
}

export default Transition;