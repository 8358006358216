import * as React from "react";
import { connect } from "react-redux";
import { ComponentWrapper, StyledForm, SettingWrapper, StyledWrapper } from "../styled";
import { useIntl } from "react-intl";
import { DeviceComponents } from "@Safemate/Settings/tabDefinition";
import { SettingEnum as ComponentSettingEnum } from "../../../settingEnum";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";
import SettingsHeader from "../../header";
import { Button, Col } from "react-bootstrap";
import styled from "styled-components";
import { AppState } from "@Safemate/Store/types";
import { CButton, Icon } from "./logsReports";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";


interface LogProps{
  dehaId: number;
  deviId: number;
}

const Log = ({ dehaId, deviId }: LogProps) => {

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const { formatMessage } = useIntl();

  return(
    <StyledWrapper>
      <SettingsHeader 
        title={formatMessage({id: "deviceLogsTab", defaultMessage: "Enhetslogg"})}
      />
      <SettingWrapper>
        {components.has(ComponentSettingEnum.GX8_LOG) 
          && components.get(ComponentSettingEnum.GX8_LOG)?.map(comp => {
            return comp
        })}
      </SettingWrapper>
    </StyledWrapper>
  )
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }}}: AppState) => {
  return {
    dehaId,
    deviId
  }
}

export default connect(mapStateToProps)(Log);

const NoPadCol = styled(Col)`
  padding: 0;
`;

const InfoButton = styled(Button)<{deleteType?: boolean}>`
  width: 300px;
  margin-bottom: 10px;
  ${props => props.deleteType && `
    background: #F12638;
    color: white;
  `}
`;

interface LogButtonProps{
  deviId: number;
}

const mapLogButtonStateToProps = ({ settings: {initialization: { deviId }}}: AppState) => {
  return {
    deviId
  }
}

export const AuditLog = connect(mapLogButtonStateToProps)(({ deviId }: LogButtonProps) => {
  
  const { formatMessage } = useIntl();

  return(
    <CButton
      onClick={() => location.hash = `device_audit_log/${deviId}`}
    >
      <Icon icon={faFileAlt}/>
      {formatMessage({id: "deviceAuditLog", defaultMessage: "Enhets Auditlogg"})}
    </CButton>
  )
})

export const CallLog = connect(mapLogButtonStateToProps)(({ deviId }: LogButtonProps) => {
  
  const { formatMessage } = useIntl();

  return(
    <CButton
      onClick={() => location.hash = `device_call_log/${deviId}`}
    >
      <Icon icon={faFileAlt}/>
      {formatMessage({id: "deviceCallLog", defaultMessage: "Enhets samtalelogg"})}
    </CButton>
  )
})