import React from 'react';
import { connect, Field } from 'formik';

import { CheckBoxRow, Tooltip } from '../layoutComp';
import { deviceSettingsTooltips as Tips } from '../tooltips';
import Switch from '../../../Elements/Switch';

import {
  CollapsedWrapper,
  TabHeader,
  CapitalizedString,
  BelongingContent
} from '../WrapperStyles';
import { useIntl } from 'react-intl';
import { Role } from '../Store/types';
import { Collapse } from 'react-collapse';

const DeviceSettings = connect<{}, Role>(({formik: { values, setFieldValue }}) => {

  const enabled = !!values.policies.deviceSettings.rolePolicies.status;
  const { formatMessage } = useIntl();
  
  return (
    <CollapsedWrapper>
      <TabHeader>
        <CapitalizedString>
          {formatMessage({
            id: 'deviceSettingsRoleEditorHeading',
            defaultMessage: 'Device Settings'
          })}
        </CapitalizedString>
        <Switch
          checked={enabled}
          onChange={(value: boolean) => {
            setFieldValue("policies.deviceSettings.rolePolicies.status", value);
          }}
        />
        &nbsp;
        <Tooltip
          id="eventSetupTip"
          tooltip={formatMessage({
            id: Tips.deviceSettings,
            defaultMessage: Tips.deviceSettingsContent
          })}
        />
      </TabHeader>
        <Collapse isOpened={enabled}>
          <CheckBoxRow
            values={values.policies.deviceTracking.rolePolicies.status}
            name="policies.deviceTracking.rolePolicies.status"
            label={formatMessage({id: 'tracking', defaultMessage: 'Tracking'})}
            tooltip={formatMessage({id: Tips.tracking, defaultMessage: Tips.trackingContent})}
          />
          <CheckBoxRow
            values={values.policies.deviceIndoor.rolePolicies.status}
            name="policies.deviceIndoor.rolePolicies.status"
            label={formatMessage({id: 'indoor', defaultMessage: 'Indoor'})}
            tooltip={formatMessage({id: Tips.indoor, defaultMessage: Tips.indoorContent})}
          />
          <CheckBoxRow
            values={values.policies.deviceGeofence.rolePolicies.status}
            name="policies.deviceGeofence.rolePolicies.status"
            label={formatMessage({id: 'geofence', defaultMessage: 'Geofence'})}
            tooltip={formatMessage({id: Tips.geofence, defaultMessage: Tips.geofenceContent})}
          />
          <CheckBoxRow
            values={values.policies.deviceOrganization.rolePolicies.status}
            name="policies.deviceOrganization.rolePolicies.status"
            label={formatMessage({id: 'organizationText', defaultMessage: 'Organization'})}
            tooltip={formatMessage({id: Tips.organization, defaultMessage: Tips.organizationContent})}
          />
          <CheckBoxRow
            values={values.policies.deviceInfo.rolePolicies.status}
            name="policies.deviceInfo.rolePolicies.status"
            label={formatMessage({id: 'info', defaultMessage: 'Info'})}
            tooltip={formatMessage({id: Tips.info, defaultMessage: Tips.infoContent})}
          />
          {values.policies.deviceInfo.rolePolicies.status && (
            <BelongingContent>
              <CheckBoxRow
                values={values.policies.deviceNote.rolePolicies.status}
                name="policies.deviceNote.rolePolicies.status"
                label={formatMessage({
                  id: 'deviceNotePolicy',
                  defaultMessage: 'Legge til/endre/slette enhetsnotat'
                })}
                tooltip={formatMessage({id: Tips.deviceNote, defaultMessage: Tips.deviceNoteContent})}
              />
            </BelongingContent>
          )}
          <CheckBoxRow
            values={values.policies.deviceAccessMap.rolePolicies.status}
            name="policies.deviceAccessMap.rolePolicies.status"
            label={formatMessage({id: 'accessMapText', defaultMessage: 'Access Map'})}
            tooltip={formatMessage({id: Tips.accessMap, defaultMessage: Tips.accessMapContent})}
          />
          <CheckBoxRow
            values={values.policies.deviceTransactionLog.rolePolicies.status}
            name="policies.deviceTransactionLog.rolePolicies.status"
            label={formatMessage({id: 'transactionLog', defaultMessage: 'Transaction Log'})}
            tooltip={formatMessage({
              id: Tips.accessTransactionLog,
              defaultMessage: Tips.accessTransactionLogContent
            })}
          />
          {values.policies.deviceTransactionLog.rolePolicies.status && (
            <BelongingContent>
              <CheckBoxRow
                values={values.policies.deviceLogs.rolePolicies.status}
                name="policies.deviceLogs.rolePolicies.status"
                label={formatMessage({
                  id: 'downloadLogsText',
                  defaultMessage: 'Access to exporting logs'
                })}
                tooltip={formatMessage({id: Tips.logs, defaultMessage: Tips.logsContent})}
              />
            </BelongingContent>
          )}
      </Collapse>
    </CollapsedWrapper>
  );
});

export default DeviceSettings;
