import React from 'react';
import PropTypes from 'prop-types';
import { getBgColor } from '../utils';
import { withTheme } from 'styled-components';
import Signal from './Signal';
import Bar from './Bar';

const WideSignal = ({ strength, maxStrength, history, theme }) => {
  const signalBars = [];
  for (let i = 0; i < maxStrength; i++) {
    const bgColor =
      i >= 0 && i < strength
        ? {
            background: getBgColor(strength, maxStrength, history, theme),
            border: history ? '1px solid gray' : 'initial'
          }
        : undefined;
    signalBars.push(<Bar key={i} style={bgColor} />);
  }

  return <Signal title={`${strength}/${maxStrength}`}>{signalBars}</Signal>;
};

WideSignal.propTypes = {
  strength: PropTypes.number.isRequired,
  maxStrength: PropTypes.number.isRequired
};

export default withTheme(WideSignal);
