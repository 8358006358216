import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import ahpSaga from "./ahp";
import capabilitySaga from "./capability";
import deviceSaga from "./device";
import deviceEventSetupSaga from "./deviceEventSetup";
import policySaga from "./policy";

function* EventSetupSaga(){
  try{
    yield all([
      fork(initializationSaga),
      fork(ahpSaga),
      fork(capabilitySaga),
      fork(deviceSaga),
      fork(deviceEventSetupSaga),
      fork(policySaga),
    ])
  }
  catch(e){
    console.log(e);
  }
}
export default EventSetupSaga;
