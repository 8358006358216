import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import customerSaga from "./customer";
import filteringSaga from './filtering';

function* partnerDashboardSaga(){
  yield all([
    fork(initializationSaga),
    fork(customerSaga),
    fork(filteringSaga)
  ])
}
export default partnerDashboardSaga;
