import React from 'react';
import moment, {Moment} from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Glyphicon } from 'react-bootstrap';
import { Wrapper } from './styles';


interface DatepickerProps{
  date: Moment;
  handleChange: (date: Moment | null) => void;
  id: string;
  disabled?: boolean;
}

const Datepicker = ({date, id, disabled, handleChange}: DatepickerProps) => {
  
  const [ currentdate, setCurrentDate ] = React.useState<Moment | null>(date);

  React.useEffect(() => {
    setCurrentDate(date);
  }, [date])
  
  return(
    <Wrapper disabled={disabled}>
      <DatePicker
        showIcon
        disabled={disabled}
        selected={currentdate.toDate()}
        onChange={date => {
          if(date){
            const momentDate = moment(date);
            setCurrentDate(momentDate);
            handleChange(momentDate);
          }
        }}
        id={id}
        icon={<Glyphicon glyph="calendar" />}
        monthsShown={1}
      />
    </Wrapper>
  )
}

export default Datepicker;
