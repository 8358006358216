import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { Role } from '../types';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';

function* getRoles(action: AppAction<null>){
  try{
    const roles: Role[] = yield call(Api.getRoles);
    yield put(Routines.getRoles.success(roles));
  }
  catch(e){
    yield feedbackFailure(e);
    console.log(e);
    yield put(Routines.getRoles.failure(e));
  }
}

function* deleteRole(action: AppAction<number>){
  try{
    yield call(Api.deleteRole, action.payload);
    const roles: Role[] = yield call(Api.getRoles);
    yield put(Routines.getRoles.success(roles));
    yield feedbackSuccess("roleDeleted");
  }
  catch(e){
    yield feedbackFailure(e);
    console.log(e);
    yield put(Routines.deleteRole.failure(e));
  }
}

function* roleSaga(){
    yield takeLatest(Routines.getRoles.TRIGGER, getRoles);
    yield takeLatest(Routines.deleteRole.TRIGGER, deleteRole);
}

export default roleSaga;
