import {GeofenceSettings} from "@Safemate/DefaultSettings/Settings/types";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import * as React from "react";
import {mapGeofence,} from "@Safemate/DefaultSettings/Settings/utils";
import {Form, Formik} from "formik";
import {Frame} from "@Safemate/styles";
import {Col, Row} from "react-bootstrap";
import UnsavedCheck from "@Safemate/SaveNeeded/unsavedModal";
import {AppState} from "@Safemate/Store/types";
import {saveGeofenceSettings,} from "@Safemate/DefaultSettings/Store/routines";
import {connect} from "react-redux";
import {FooterComp, FooterContext} from "@Safemate/DefaultSettings/Settings/footer";
import {DefaultGeofences} from "@Safemate/DefaultSettings/Settings/Geofence";

interface GeofenceSettingsProps{
  geofenceSettings: GeofenceSettings;
  saveGeofenceSettings: Routine<ActionFunctionAny<Action<any>>>;
}

const GeofenceSettingsComp = ({ geofenceSettings, saveGeofenceSettings }: GeofenceSettingsProps) => {

  return(
    <Formik
      initialValues={geofenceSettings}
      enableReinitialize
      onSubmit={(values: GeofenceSettings) => {
        saveGeofenceSettings(mapGeofence(values))
      }}
      >
        <Form>
          <Frame>
            <Row>
              <Col style={{paddingBottom: "1em"}}>
                <DefaultGeofences/>
              </Col>
              <FooterComp context={FooterContext.GEOFENCE}/>
            </Row>
          </Frame>
          <UnsavedCheck />
        </Form>
      </Formik>
  )
}

const mapStateToProps = ({ defaultSettings: {settings: { geofenceSettings }}}: AppState) => {
  return{
    geofenceSettings
  }
};

const mapDispatchToProps = {
  saveGeofenceSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(GeofenceSettingsComp);
