import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Texts
export const getTextLabels: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TranslatePage/GET_TEXT_LABELS'
);

// Filter
export const filter: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TranslatePage/FILTER'
)