import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Field } from "formik";

import { Checkbox } from "../../../../Elements/CheckboxFormik";
import { NewFieldWrapper, NewHeader, StyledField } from ".";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";
import Dropdown from "@Elements/Dropdown/Dropdown";
import {Option} from "@Elements/Dropdown/types";
import {Customer} from "@Safemate/AddSafemate/Store/types";
import styled from "styled-components";

export const DropdownWrapper = styled.div`  
  width: 100%;
`;


interface ShareProps extends WrappedComponentProps{
  customers: Customer[],
  ownedByCustomer: number;
}

const Share = injectIntl(withFormWrapper<GeofenceValues, ShareProps>(({ intl: {formatMessage}, formik: { values: { geofence }, setFieldValue }, customers, ownedByCustomer }) =>
  {
    const mappedCustomers: Option[] = React.useMemo(() => {
      return customers.map((customer) => {
        return{
          text: customer.firmName,
          value: customer.custId
        }
      })
    }, [customers]);

    return (<React.Fragment>
      <NewFieldWrapper>
        <NewHeader>{formatMessage({id: "share", defaultMessage: "Share"})}</NewHeader>
        <div>{formatMessage({id: "underDevelopment", defaultMessage: "Funksjonalitet under utvikling"})}</div>
        {<div>
          <Field
            value={geofence.share}
            name="geofence.share"
            id="share"
            label={formatMessage({ id: "shareGeofence", defaultMessage: "Allow geofence to be used for other devices" })}
            component={Checkbox}
            outerDiv={{float: "none"}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const checked = e.target.checked;
              setFieldValue( "geofence.sharedCustId", geofence.sharedCustId > 0 ? geofence.sharedCustId : ownedByCustomer )
            }}
          />
        </div>}
      </NewFieldWrapper>
      {
        geofence.share && <DropdownWrapper>
          <Dropdown
            options={mappedCustomers}
            onChange={(option) => {
              const { value } = option as Option;
              setFieldValue("geofence.sharedCustId", value);
            }}
            initial={ geofence.sharedCustId > 0 ? geofence.sharedCustId : ownedByCustomer}
            searchable={true}
            size={{height: "40px"}}
          />
        </DropdownWrapper>
      }
    </React.Fragment>)
  }

))

export default Share;
