
import React from 'react';
import SIcon from './SIcon';
import { withTheme } from 'styled-components';

const Wadc1NewIcon = withTheme(props => (
  <svg viewBox="0 0 30 30" width="1em" height="1em" {...props}>
    <path
      vectorEffect="non-scaling-stroke"
      transform="matrix(.06 0 0 .05 16.09 6.06)"
      d="M-50-50H50V50H-50z"
      stroke={props.theme.colors.svgFill}
      fill="none"
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M11.788.205h8.604v28.868s.05.642-.328.642h-7.936s-.377-.076-.34-.453c.038-.377 0-29.057 0-29.057z"
      stroke={props.theme.colors.svgFill}
      fill="none"
    />
  </svg>
));

const Icon = props => <SIcon {...props} icon={Wadc1NewIcon} />;

export default Icon
