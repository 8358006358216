import * as React from "react";
import { Button } from "react-bootstrap";
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { getTextForLabel, deleteText, addTexts } from "./Store/routines";
import { RootState, SelectedText, Texts, TranslationEventBody, FormattedTexts } from "./Store/types";
import { connect } from "react-redux";
import { addEmail, AddEmail } from "./Store/actions";
import TextListItem from "./TextListItem";
import { TranslationTab, PaddedWrapper, TranslationContent, Table, Tr, Th } from "./styles";
import { HorizontalTabs, Tab } from "../../../Elements/Tabs";
import EventComp from "./Event";
import Feedback from "./Feedback";
import { AppState } from "@Safemate/Store/types";

interface TranslationTabComp extends WrappedComponentProps {
    texts: FormattedTexts;
    selected: SelectedText;
    addEmail: AddEmail;
    getTextForLabel: Routine<ActionFunctionAny<Action<any>>>;
    deleteText: Routine<ActionFunctionAny<Action<any>>>;
    addTexts: Routine<ActionFunctionAny<Action<any>>>;
    emails: TranslationEventBody[];
    sms: TranslationEventBody[];
    originalTexts: Texts;
  }
  
  const mapTranslationTabCompStateToProps = ({ translationOverlay: {text: { selected, emails, sms }}}: AppState) => {
    return {
      selected,
      emails,
      sms,
    }
  }
  
  const mapTranslationTabCompDispatchToProps = {
    getTextForLabel,
    deleteText,
    addTexts,
    addEmail
  }
  
  export default connect(mapTranslationTabCompStateToProps, mapTranslationTabCompDispatchToProps)(
    injectIntl(({originalTexts, texts, emails, sms, intl: { formatMessage }}: TranslationTabComp) => {
      const [open, setOpen] = React.useState(false);
      const [text, setText] = React.useState([] as JSX.Element[]);
      const [search, setSearch] = React.useState("");
  
      const [ notif, setNotif ] = React.useState(0);
      const inputref = React.useRef<HTMLInputElement>(null);
  
      const getTexts = () => {
        const temp = Object.keys(texts).map(key => {
          const lowerCaseSearch = search.toLowerCase();
          const value = texts[key].text;
          const exist = texts[key].exist;
          if(lowerCaseSearch === "" || value.toLowerCase().includes(lowerCaseSearch) || key.toLowerCase().includes(lowerCaseSearch)){
            return <TextListItem exist={exist} value={value} label={key}/>
          };
        }).filter(entry => entry) as JSX.Element[];
        setText(temp);
      }

      const inputRef = React.useRef<HTMLInputElement>(null);

      React.useEffect(() => {
        if(inputRef.current && open){
          setTimeout(() => {
            inputRef.current.focus();
          }, 100)
        }
      }, [inputRef.current, open])
  
      React.useEffect(() => {
        getTexts();
      }, [search, texts])

      return(
        <TranslationTab open={open}>
          <PaddedWrapper>
            <input type="hidden" value={notif} ref={inputref}/>
            <Button 
              onClick={() => {
                setOpen(!open)
                setNotif(0);
                getTexts();
              }}
            >
                {open ? "Close" : `Open (${notif})`}
            </Button>
          </PaddedWrapper>
          <TranslationContent open={open}>
            <HorizontalTabs>
              <Tab 
                key="texts" 
                name="texts" 
                title={formatMessage({id: "texts", defaultMessage: "Tekster"})}
              >
                <div style={{minHeight: "249px"}}>
                <div>
                  <input ref={inputRef} type="text" onChange={(event) => setSearch(event.target.value)} placeholder={formatMessage({id: "search", defaultMessage: "search"})}/>
                </div>
                <Table>
                  <thead>
                    <Tr exist={true}>
                      <Th>Current text</Th>
                      <Th>Label</Th>
                      <Th>Edit</Th>
                      <Th style={{textAlign: "center"}}>Added</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {text}
                  </tbody>
                </Table>
                </div>
              </Tab>
              <Tab 
                key="email" 
                name="email" 
                title={formatMessage({id: "email", defaultMessage: "E-Poster"})}
              >
                <EventComp events={emails}/>
              </Tab>
              <Tab 
                key="sms" 
                name="sms" 
                title={formatMessage({id: "sms", defaultMessage: "SMS"})}
              >
                <EventComp events={sms}/>
              </Tab>
              <Tab 
                key="feedback" 
                name="feedback" 
                title={formatMessage({id: "feedback", defaultMessage: "Feedback"})}
              >
                <Feedback originalTexts={originalTexts}/>
              </Tab>
            </HorizontalTabs>
          </TranslationContent>
        </TranslationTab>
      )
    })
  );
