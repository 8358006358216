import {DeviceAuditLogState} from "../types";
import {Reducer} from "redux";
import {getDeviceAuditLog, getDeviceCallLog} from "../routines";
import {CallLog, EventLog} from "@Safemate/Model/EventLog";
import { LogActionTypes } from "../actionTypes";

const deviceAuditLogState: DeviceAuditLogState = {
  deviceAuditLog: [] as EventLog[],
  filteredDeviceAuditLog: [] as EventLog[],
  deviceCallLogs: [] as CallLog[]
};

const deviceAuditLog: Reducer<DeviceAuditLogState> = (state: DeviceAuditLogState = deviceAuditLogState, action) => {
  switch (action.type) {

    case getDeviceAuditLog.SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case getDeviceCallLog.SUCCESS:
      return {
        ...state,
        deviceCallLogs: action.payload
      };

    case LogActionTypes.RESET_STATE:
      return {
        ...state,
        ...deviceAuditLogState
      }

    default:
      return state;
  }
};

export default deviceAuditLog;
