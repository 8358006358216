import { v4 } from "uuid";
import { RecipientType } from "../Body/AddRecipient";
import SimpleDevice from "./SimpleDevice";

export default interface DeviceEventSetup{
  deviceEventSetupId: number;
  deviId: number;
  custId: number;
  alarmWarnRecipient: boolean;
  manDownWarnRecipient: boolean;
  batteryWarnRecipient: boolean;
  geofenceWarnRecipient: boolean;
  selfcheckWarnRecipient: boolean;
  outOfBatteryWarnRecipient: boolean;
  offlineWarnRecipient: boolean;
  staticWarnRecipient: boolean;
  warnTimeFrom: number
  warnTimeFromString: string;
  warnTimeTo: number
  warnTimeToString: string;
  familyMember: boolean;
  familyMemberTracking: boolean;
  familyMemberSchedule: boolean;
  familyMemberCalling: boolean;
  familyMemberImage: boolean;
  familyMemberVacationUntil: string
  email: string;
  phone: string;
  padlocked: boolean;
  warnOnMon: boolean;
  warnOnTue: boolean;
  warnOnWed: boolean;
  warnOnThu: boolean;
  warnOnFri: boolean;
  warnOnSat: boolean;
  warnOnSun: boolean;
  zoneId: string;
  sort: number;
  lastChanged: string;
  lastChangedBy: number;
  givenName: string;
  epilepsyAlarmRecipient: boolean;
  wandererAlarmRecipient: boolean;
  fridgeWarnRecipient: boolean;
  fireWarnRecipient: boolean;
  inBedWarnRecipient: boolean;
  outOfBedWarnRecipient: boolean;
  trained: boolean;
  trainedApprovedBy: string;
  localId: string;
  nin: string;
  [index: string]: string | number | boolean;
}

export const newEventSetup = (type: RecipientType, device: SimpleDevice, sort: number): DeviceEventSetup => {
  return {
    alarmWarnRecipient: true,
    batteryWarnRecipient: true,
    beta: false,
    custId: 0,
    deviId: device.deviId,
    deviceEventSetupId: 0,
    email: type === RecipientType.EMAIL ? '' : null,
    epilepsyAlarmRecipient: true,
    familyMember: false,
    familyMemberCalling: false,
    familyMemberImage: false,
    familyMemberSchedule: false,
    familyMemberTracking: false,
    familyMemberVacation: false,
    familyMemberVacationUntil: null,
    fireWarnRecipient: true,
    fridgeWarnRecipient: true,
    geofenceWarnRecipient: true,
    givenName: "",
    inBedWarnRecipient: true,
    lastChanged: "",
    lastChangedBy: 0,
    manDownWarnRecipient: false,
    name: '',
    offlineWarnRecipient: true,
    outOfBedWarnRecipient: true,
    padlocked: true,
    phone: "",
    selfcheckWarnRecipient: true,
    outOfBatteryWarnRecipient: true,
    sort: sort,
    staticWarnRecipient: true,
    wandererAlarmRecipient: true,
    warnOnFri: true,
    warnOnMon: true,
    warnOnSat: true,
    warnOnSun: true,
    warnOnThu: true,
    warnOnTue: true,
    warnOnWed: true,
    zoneId: null,
    warnTimeFrom: 0,
    warnTimeTo: 0,
    warnTimeFromString: '00:00',
    warnTimeToString: '00:00',
    trained: false,
    trainedApprovedBy: "",
    localId: v4(),
    nin: "",
  };
};
