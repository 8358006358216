import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { Field } from "formik";

import { Checkbox } from "../../../../Elements/CheckboxFormik";
import {Header, CapitalizedWrapper, ComponentWrapper, StyledField, } from ".";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../../Elements/Dropdown/types";
import { populateAvailableTimes } from "../utils";
import { Collapse } from "react-collapse";
import styled from "styled-components";
import Toggle from "@Elements/Switch/Toggle";

const Wrapper = styled.div`
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
  margin: 10px 0px;
`;

const PaddedRow = styled(Row)`
  padding-bottom: 1em;
`

const HeaderWidthFull = styled.label`
  width: 100%;
`;

const TimeControls = injectIntl(withFormWrapper<GeofenceValues, InjectedIntlProps>(({ intl: {formatMessage}, formik: { setFieldValue, values: { geofence } } }) =>
  <Collapse isOpened={!!geofence.timeToggled}>
    <div>
      <PaddedRow>
        <Col md={6} sm={6}>
          <Header>{formatMessage({id: "from", defaultMessage: "Fra"})}</Header>
          <Dropdown
            options={populateAvailableTimes()}
            initial={geofence.enabledTimeFrom}
            onChange={(opt) => {
              const { value } = opt as Option;
              setFieldValue("geofence.enabledTimeFrom", value);
            }}
            disabled={!geofence.editEnabled}
          />
        </Col>
        <Col md={6} sm={6}>
          <Header>{formatMessage({id: "to", defaultMessage: "Til"})}</Header>
          <Dropdown
            options={populateAvailableTimes()}
            initial={geofence.enabledTimeTo}
            onChange={(opt) => {
              const { value } = opt as Option;
              setFieldValue("geofence.enabledTimeTo", value);
            }}
            disabled={!geofence.editEnabled}
          />
        </Col>
      </PaddedRow>
      <PaddedRow>
        <Col md={12}>
          <HeaderWidthFull>{formatMessage({id: "geofenceDelayInMinutes", defaultMessage: "Delay alert in minutes"})}</HeaderWidthFull>
          { geofence.editEnabled ? <StyledField style={{width: "100%"}} name="geofence.delayMinutes" type="number" /> : <span>{ geofence.delayMinutes }</span> }
        </Col>
      </PaddedRow>
      <PaddedRow>
        <Col md={12}>
          <HeaderWidthFull>{formatMessage({id: "geofenceAlertByCallLabel", defaultMessage: "Alert by call"})}</HeaderWidthFull>
          <Toggle
            onChange={(value: boolean) => {
              setFieldValue("geofence.alertByCall", value);
            }}
            checked={geofence.alertByCall}
            disabled={!geofence.editEnabled}
          />
        </Col>
      </PaddedRow>
    </div>
  </Collapse>
))

export default TimeControls;
