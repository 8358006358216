import {RoleState} from "../types";
import {Reducer} from "redux";
import {initializeAdminRole, initializeRoles} from "../routines";
import {GlobalRoleName} from "../../../Policy/GlobalRoleName";

const roleState: RoleState = {
  roles: [],
  admin: {
    id: 1,
    roleName: GlobalRoleName.SUPERFIRM,
    custId: 0,
    global: true
  }
};

const role: Reducer<RoleState> = (state: RoleState = roleState, action: any) => {
  switch (action.type) {
    case initializeRoles.SUCCESS:
      return {
        ...state,
        roles: action.payload
      };

    case initializeAdminRole.SUCCESS:
      return {
        ...state,
        admin: action.payload
      }

    default:
      return state;
  }
};

export default role;