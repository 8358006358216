import * as React from "react";
import { injectIntl, MessageDescriptor, WrappedComponentProps } from "react-intl";

import { TabsEnum } from "./TabsEnum";


import Address from "./Address";
import InvoiceSettings from "./InvoiceSettings";
import Settings from "./Settings";


interface LightHeaderProps extends WrappedComponentProps {
  message: MessageDescriptor;
}

export const Header = injectIntl(({ intl: { formatMessage }, message }: LightHeaderProps) => {
  return (
    <div>
      <h3>
        {formatMessage(message)}
      </h3>
    </div>
  )
})


export const getMain = () => {

  const DEFAULT = new Map<TabsEnum, JSX.Element>([
    [TabsEnum.ADDRESS, <Address/>],
    [TabsEnum.INVOICE_SETTINGS, <InvoiceSettings/>],
    [TabsEnum.SETTINGS, <Settings/>]

  ]);

  return DEFAULT;
}
