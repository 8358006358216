export enum PolicyNameEnum{
  ADMIN = "admin",

  ACCESS = "access",
  REQUIRE_TFA = "requireTfa",
  USER_ADMIN= "userAdmin",
  USER_PRIVS = "userPrivs",
  AUDIT_LOG = "auditLog",
  DELETED_USERS = "deletedUsers",
  LAST_LOGIN = "lastLogin",
  ACTIVATE = "activate",
  PROMOTE_TEMP = "promoteTemp",
  EVENT_SETUP = "eventSetup",
  GSM_RECIPIENTS = "gsmRecipients",
  NEXT_OF_KIN_CHECK = "nextOfKinCheck",
  NEXT_OF_KIN_TRACKING = "nextOfKinTracking",
  NEXT_OF_KIN_SCHEDULE = "nextOfKinSchedule",
  NEXT_OF_KIN_CALL = "nextOfKinCall",
  NEXT_OF_KIN_IMAGE = "nextOfKinImage",
  EMAIL_RECIPIENTS = "emailRecipients",
  ALARM_CENTER = "alarmCenter",
  CONTROL_CALLING = "controlCalling",
  DEVICE_NAME_CHANGE = "deviceNameChange",
  DEVICE_SETTINGS = "deviceSettings",
  DEVICE_TRACKING = "deviceTracking",
  DEVICE_GEOFENCE = "deviceGeofence",
  CUSTOMER_GEOFENCE = "customerGeofence",
  DEVICE_INDOOR = "deviceIndoor",
  DEVICE_INDOOR_CUSTOMER = "deviceIndoorCustomer",
  DEVICE_SENSOR = "deviceSensor",
  DEVICE_LIGHT_SOUND = "deviceLightSound",
  DEVICE_ORGANIZATION = "deviceOrganization",
  DEVICE_INFO = "deviceInfo",
  DEVICE_NOTE = "deviceNote",
  DEVICE_ACCESS_MAP = "deviceAccessMap",
  DEVICE_TRANSACTION_LOG = "deviceTransactionLog",
  DEVICE_LOGS = "deviceLogs",
  GENERAL_SETTINGS = "generalSettings",
  GX8_LIGHT_SOUND = "Gx8LightSound",
  GX8_BATTERY = "Gx8Battery",
  GX8_LOGS_REPORT = "Gx8LogsReport",
  GX8_GENERAL = "Gx8General",
  GX8_COMMUNICATION = "Gx8Communication",
  GX8_INFORMATION = "Gx8Information",
  SAFEMATE_GENERAL = "safemateGeneral",
  SAFEMATE_DEVICE_LOGS = "safemateDeviceLogs",
  SAFEMATE_DELETE_DATA = "safemateDeleteData",
  SAFEMATE_POSITIONING = "safematePositioning",
  SAFEMATE_BATTERY_TRESHHOLD = "safemateBatteryThreshhold",
  SAFEMATE_ALARM = "safemateAlarm",
  SAFEMATE_MAN_DOWN = "safemateManDown",
  SAFEMATE_HEART_RATE_BLOOD_PRESSURE = "safemateHeartRateBloodPressure",
  TWIN_DEVICE = "twinDevice",
  DEFAULT_SETTINGS = "defaultSettings",
  DEFAULT_COMMUNICATION = "defaultCommunication",
  DEFAULT_NOT_USED = "defaultNotUsed",
  DEFAULT_POSITIONING = "defaultPositioning",
  DEFAULT_TRACKING = "defaultTracking",
  DEFAULT_LIGHT_SOUND = "defaultLightSound",
  DEFAULT_BATTERY = "defaultBattery",
  SUB_ORGS = "subOrgs",
  EDIT_SUB_ORGS = "editSubOrgs",
  DEFAULT_GENERAL = "defaultGeneral",
  ACCESS_TO_ALL = "accessToAll",
  DEFAULT_PRIVACY = "defaultPrivacy",
  DEFAULT_FIRMWARE = "defaultFirmware",
  DEFAULT_WHITELIST = "defaultWhitelist",
  DEFAULT_INTEGRATION = "defaultIntegration",
  DEFAULT_EVENT_SETUP = "defaultEventSetup",
  PARTNER = "partner",
  PARTNER_PRICES = "partnerPrices",
  PARTNER_INVOICES_MENU = "partnerInvoicesMenu",
  ADD_DEVICE_MENU = "addDeviceMenu",
  SHOP_MENU = "shopMenu"
}
