import { combineReducers } from "redux";
import { RootState } from "../types";
import feedback from "../../../Feedback/reducer";
import initialization from "./initialization";
import customer from "./customer";
import subUnits from "./subUnits";
import paymentProfile from "./paymentProfile";
import user from "./user";
import invoices from "./invoices";
import settings from "./settings";
import country from "./country";

export default combineReducers<RootState>({
    feedback,
    initialization,
    customer,
    subUnits,
    paymentProfile,
    user,
    invoices,
    settings,
    country
});
