import {SubUnitsActionTypes as Actions} from "./actionTypes";
import {AppAction} from "./types";

export const selectCustomer = (customerId: number, customerKey: number): AppAction<number> => ({
  type: Actions.SET_CUSTOMER,
  payload: customerId
});

export type CreateCustomer = () => AppAction<boolean>;

export const createCustomer = () => ({
  type: Actions.CREATE_CUSTOMER,
  payload: true
})