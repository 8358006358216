import { addRolesToRequest } from '@Safemate/utils';
import _axios, {AxiosInstance} from 'axios';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getUser = async () => {
  const url: string = `/user/auth`;
  const response = await axios.get(url);
  return response.data;  
};

export const getSso = async() => {
  const url: string = `/sso`;
  const response = await axios.get(url);
  return response.data;
}

export const isSso = async() => {
  const url: string = `/user/sso`;
  const response = await axios.get(url);
  return response.data;
}

export const getAdminSso = async() => {
  const url: string = `/user/adminUserSso`;
  const response = await axios.get(url);
  return response.data;
}

export const getCustomer = async() => {
  const url: string = `firm/superfirm`;
  const response = await axios.get(url);
  return response.data;
}

export const authPro = async() => {
  const url: string = `auth/pro`;
  const response = await axios.get(url);
  return response.data;
} 

export const authAhp = async() => {
  const url: string = `auth/ahp`;
  const response = await axios.get(url);
  return response.data;
} 

export const isAhp = async() => {
  const url: string = `auth/isahp`;
  const response = await axios.get(url);
  return response.data;
}

export const isPro = async() => {
  const url: string = `auth/ispro`;
  const response = await axios.get(url);
  return response.data;
}

export const getUserType = async() => {
  const url: string = `auth/userType`;
  const response = await axios.get(url);
  return response.data;
}

export const getTexts = async() => {
  const url: string = `/text/loggedIn`;
  const response = await axios.get(url);
  return response.data;
}

export const getPrivate = async() => {
  const url: string = `/user/private`;
  const response = await axios.get(url);
  return response.data;
}

export const getPartner = async() => {
  const url: string = `/user/partner`;
  const response = await axios.get(url);
  return response.data;
}

export const getSuborgsWithRoles = async() => {
  const url: string = `/firm/trimmedsuborgunitswithwithrights${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;
}

export const getMunicipalities = async () => {
  const url = "/device/ahp/trimmedmunicipalities";
  const response = await axios.get(url);
  return response.data;
}

export const getHierarchy = async () => {
  const url: string = `/firm/hierarchy`;
  const response = await axios.get(url);
  return response.data;
}

export const getPartnerUser = async () => {
  const url = "/session/partneruser";
  const response = await axios.get(url);
  return response.data;
}

export const getBackdoorUser = async () => {
  const url = "/session/backdooruser";
  const response = await axios.get(url);
  return response.data;
}

export const getRestrictedUser = async () => {
  const url = "/user/restricteduser";
  const response = await axios.get(url);
  return response.data;
}

export const redirectPartner = async () => {
  const url = "/partner/viewcustomer/0/true";
  const response = await axios.get(url);
  return response.data;
}

export const getActiveRoles = async () => {
  const url = "/userroles/getActiveRoles";
  const response = await axios.get(url);
  return response.data;
}

export const getSettings = async () => {
  const url = "/settings/pro";
  const response = await axios.get(url);
  return response.data;
}

export const getMarket = async () => {
  const url = "/market";
  const response = await axios.get(url);
  return response.data;
}

export const getMarketSetting = async () => {
  const url = "/market/setting";
  const response = await axios.get(url);
  return response.data;
}

export const getLastAlarm = async () => {
  const url = "/device/lastalarm";
  const response = await axios.get(url);
  return response.data;
}

export const getLastAlarmPrivate = async () => {
  const url = "/device/lastalarmforprivate";
  const response = await axios.get(url);
  return response.data;
}

export const getAhpRoles = async () => {
  const url: string = `/userRoleAhp`;
  const response = await axios.get(url);
  return response.data;
}

export const getBillingFilled = async () => {
  const url: string = `/customer/getBillingFilled`;
  const response = await axios.get(url);
  return response.data;
}

export const getGdprManager = async () => {
  const url: string = `/customer/getGdprManagerForCustomer`;
  const response = await axios.get(url);
  return response.data;
}

export const getRequireNationalId = async () => {
  const url: string = `/customer/getRequireNationalId`;
  const response = await axios.get(url);
  return response.data;
}

export const getPreventPartnerReferenceChange = async () => {
  const url: string = `/customer/getPreventPartnerReferenceChange`;
  const response = await axios.get(url);
  return response.data;
}

export const getLanguages = async () => {
  const url: string = `/language`;
  const response = await axios.get(url);
  return response.data;
}
