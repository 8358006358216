import * as React from "react";
import { useIntl } from "react-intl";
import { InnerFrame, TrainedWrapper, SubHeaderWrapper } from './styles';
import {Checkbox} from "../../../../Elements/CheckboxFormik";
import {connect} from "formik";
import {connect as reduxConnect} from "react-redux";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {Col, Row} from "react-bootstrap";
import Trained from "../../../../Elements/Icon/Icons/Trained";
import {LabelEnum} from "../../LabelEnum";
import { NoFlexContent } from "@Safemate/RoleEditor/WrapperStyles";
import { AppState } from "@Safemate/Store/types";

interface TrainingProps{
  id: number;
  name: string;
}

const mapStateToProps = ({ appData: { user: {id, name} } }: AppState) => {
  return {
    id,
    name
  }
}

const Training = reduxConnect(mapStateToProps)(
  connect<TrainingProps, UserWithCustomer>(({id, name, formik: {setFieldValue, values: {user: {trained, trainingApprovedByName}}}}) => {

  const {formatMessage} = useIntl();

  const trainedLabel = React.useMemo(() => formatMessage({id: LabelEnum.USER_TRAINED, defaultMessage: "Bruker har fått opplæring"}), []);
  const trainedByLabel = React.useMemo(() => formatMessage({id: LabelEnum.USER_TRAINED_BY, defaultMessage: "Opplæring godkjent av"}), []);

  return(
    <Row>
      <TrainedWrapper md={12}>
        <NoFlexContent>
          <Checkbox
            value={trained}
            name={"user.trained"}
            id={"user.trained"}
            label={trained ? `${trainedLabel} (${trainedByLabel} ${trainingApprovedByName})` : trainedLabel}
            labelComp={trained && <Trained size="medium" inline />}
            labelStyle={{ display: 'flex', alignItems: 'center' }}
            onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
              const value = event.currentTarget.checked;
              setFieldValue("user.trained", value);
              setFieldValue("user.trainingApprovedBy", value ? id : "0");
              setFieldValue("user.trainingApprovedByName", value ? name : "");
            }}
          />
        </NoFlexContent>
      </TrainedWrapper>
    </Row>
  )
}));

export default Training;
