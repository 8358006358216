import {WrappedComponentProps, injectIntl} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import {IndoorLocation, IndoorLocationFormik} from "../types";
import {connect} from "react-redux";
import {
  setIndoorLocationValuesChanged, SetIndoorLocationValuesChanged,
} from "@Safemate/Settings/Store/actions";
import {isSaveNeeded} from "@Safemate/Settings/IndoorLocation/utils";
import { AppState } from "@Safemate/Store/types";



interface ValuesListenerProps extends WrappedComponentProps{
  initialIndoorLocation: IndoorLocation;
  setIndoorLocationValuesChanged: SetIndoorLocationValuesChanged;
}

const mapStateToProps = ({ settings: {indoorLocation: { initialIndoorLocation}}}: AppState) => {
  return {
    initialIndoorLocation
  }
}

const mapDispatchToProps = {
  setIndoorLocationValuesChanged
};

export const ValuesListener = injectIntl(withFormWrapper<IndoorLocationFormik, ValuesListenerProps>(
  ({formik: { setFieldValue, values }, intl: { formatMessage }, initialIndoorLocation, setIndoorLocationValuesChanged  }) => {

    React.useEffect( () => {
      setIndoorLocationValuesChanged(isSaveNeeded(values.indoorLocation, initialIndoorLocation));
    }, [values.indoorLocation, initialIndoorLocation] );

    return(
      <React.Fragment>

      </React.Fragment>
    )}
))

export const ValuesListenerIndoorLocation = connect(mapStateToProps, mapDispatchToProps)(ValuesListener);
