import {FilterAccountStatus, FilterState} from "./types";
import { OrderFilterEnum } from '../OrderFilterEnum';


export interface PersistedFilters{
    customer: number[];
    role: number[];
    search: String;
    status: FilterAccountStatus;
    orderFilter: OrderFilterEnum;
  }
  
  export const loadFilters = () => {
    try {
      const serializedState = localStorage.getItem('users_filter');
      if (serializedState === null) {
        return;
      }
      const jsonSerialziedState = JSON.parse(serializedState);
      if(jsonSerialziedState.search != null){
        jsonSerialziedState.search = "";
      }
      return jsonSerialziedState;
    } catch (err) {
      return;
    }
  }; 
  
  export const saveFilters = (state: PersistedFilters) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('users_filter', serializedState);
    } catch {
      // ignore write errors
    }
  };