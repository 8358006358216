import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Log/INITIALIZE'
);

//User audit log
export const getUserAuditLog: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Log/GET_USER_AUDIT_LOG'
);

export const getUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Log/GET_USER'
);

//Device audit log
export const getDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Log/GET_DEVICE'
);

export const getDeviceAuditLog: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Log/GET_DEVICE_AUDIT_LOG'
);

//Device call log
export const getDeviceCallLog: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Log/GET_DEVICE_CALL_LOG'
);
