import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { SoundLightSettings } from '../../types';
import { feedbackSuccess, feedbackFailure } from '../../../Feedback/reducer';
import { Feedback } from '../../labels';

function* fetchSoundLightSettings(action: AppAction<number>){
  try {
    const soundLight = yield call(Api.getSoundLight, action.payload);
    yield put(Routines.fetchSoundLight.success(soundLight));
  }
  catch(e){
    yield put(Routines.fetchSoundLight.failure());
  }
}

export type SaveSoundLight = {
  deviceId: number;
  settings: SoundLightSettings;
}

function* saveSoundLight(action: AppAction<SaveSoundLight>){
  try{
    const settings = yield call(Api.saveSoundLight, action.payload);
    yield put(Routines.saveSoundLight.success(settings));
    yield feedbackSuccess(Feedback.SAVED_SETTINGS);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveSoundLight.failure());
  }
}

function* soundLightSaga(){
    yield takeLatest(Routines.fetchSoundLight.TRIGGER, fetchSoundLightSettings);
    yield takeLatest(Routines.saveSoundLight.TRIGGER, saveSoundLight);
}

export default soundLightSaga;
