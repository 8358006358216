import * as React from "react";
import Alarm from "@Elements/Icon/Icons/eventicons/Alarm";
import Battery from "@Elements/Icon/Icons/eventicons/Battery";
import Geofence from "@Elements/Icon/Icons/eventicons/Geofence";
import Mandown from "@Elements/Icon/Icons/eventicons/Mandown";
import { EventViewAlarm } from "@Safemate/Model/EventViewAlarm";
import googleUtils from "@Safemate/googleUtils";

export const prepareAlarms = async (alarms: EventViewAlarm[]) => {
  for(let i = 0; i < alarms.length; i++){
    const alarm = alarms[i];
    if(alarm.posStr && !alarm.streetAddress){
      const address = await googleUtils.addressLookup(parseFloat(alarm.posLati), parseFloat(alarm.posLong), alarm.deviId, 0);
      if (address) {
        alarm.streetAddressOk = true;
        alarm.streetAddress = address.replace(/,/g, '<br>');
      } else {
        alarm.streetAddress = '';
      }

      let addr = alarm.streetAddress || '';
      let i = addr.indexOf('<br>'); 
      if(addr.length > 0) {
        if(i > 0) {
          alarm.streetAddressHtml = addr.substring(0, i);
        } else {
          alarm.streetAddressHtml = alarm.streetAddress; 
        }
      } else {
        alarm.streetAddressHtml = '';
      }
    }
  }
}

export const getEventIcon = (alarmTag: string) => {
  switch(alarmTag){
    case "alarm":
      return <Alarm/>;
    case "battery":
      return <Battery/>;
    case "geofence":
      return <Geofence/>;
    case "mandown":
      return <Mandown/>;
  }
  return null;
}