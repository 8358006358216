export const callbackTimerOpts = [
  0,
  300,
  600,
  1200,
  1800,
  2400,
  3000,
  3600
];

export const twoWayDectTimer = [
  300,
  600,
  900,
  1200
]

export const prefComMethod = [
  {text: "Ethernet", value: 0},
  {text: "Mobilnett", value: 1}
]

export const climaxReportGroups = [
  {text: "1", value: 1},
  {text: "2", value: 2},
  {text: "3", value: 3},
  {text: "4", value: 4},
  {text: "5", value: 5},
]

export const climaxReportAlert = [ 
  { text : 'All events', value : 0 }, 
  { text : 'Alarm events', value : 1 }, 
  { text : 'Status events', value : 3 }, 
  { text : 'Fire events', value : 4 } 
];