import styled from "styled-components";
import { Button, Row } from "react-bootstrap";

export const SettingDiv = styled.div<{isChanged: boolean, hide?: boolean}>`
  input {
    ${props => !props.isChanged && "border: 1px green solid;"}
    background: ${props => props.theme.colors.backgroundPrimary} !important;
  }

  ${props => props.hide ? "display: none;" : ""}

  padding: 10px;
`;

export const SettingHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-bottom: 5px;

  div {
    width: auto;
  }
`

export const TitleSpan = styled.span`
  path {
    fill: ${props => props.theme.colors.textPrimary};
  }

  display: flex;
  align-items: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const TitleWrap = styled.p<{right?: boolean}>`
  ${props => props.right ? "margin-right" : "margin-left"}: 5px;
  white-space: nowrap;
  display: block;
  justify-content: center;

  div {
    display: inline-block;
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const ContentWrap = styled.p<{right?: boolean}>`
  ${props => props.right ? "margin-right" : "margin-left"}: 5px;
  display: block;
  justify-content: center;

  div {
    display: inline-block;
  }
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const CheckboxWrap = styled.div<{isChanged: boolean, isSet: boolean}>`
  display: flex;
  align-items: center;
  span {
    ${props => !props.isChanged ? "border: 1px green solid !important;" : ""}
    ${props => (!props.isChanged && props.isSet) ? "background: green !important;" : ""}
  }
`;

export const ToggleWrap = styled.span`
`;

export const Header = styled.div`
  padding: 15px;

  h2 {
    margin-bottom: 0;
  }
`

export const Footer = styled.div`
  display: flex;
`

export const ResetContainer = styled.div<{disabled: boolean}>`
  display: flex;
  justify-content: center;
  ${props => props.disabled &&
  `button{
      pointer-events:none;
    }`
}
`

export const SaveContainer = styled.div<{disabled?: boolean}>`
  margin-left: auto;
  ${props => props.disabled &&
    `button{
      pointer-events:none;
    }`
  }
`;

export const CancelButton = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};
  margin-right: 15px;
`;

export const SaveButton = styled(Button)`
  background: ${props => props.theme.colors.accent};
`

export const DeleteButton = styled(Button)`
  background: #d60403;
`

export const ModalDialog = styled.p`
  padding: 20px;
`

export const ButtonWrapper = styled.div`
  margin: 0px 15px;
`;

export const CenteredRow = styled(Row)`
  display: flex;
  justify-content: center;
`
