import React from 'react';
import {
  Overlay,
  Header,
  Body,
  Content,
  OverlayContent,
  StyledModal
} from '../../Roles/Modal/elements';

const Modal = props => {
  return (
    <StyledModal
      isOpen={!!props.display}
      onRequestClose={props.handleClose}
      ariaHideApp={false}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '1000',
          backgroundColor: 'rgba(0, 0, 0, .8)'
        }
      }}
    >
      <div>
        <Header>{props.headerContent}</Header>
        <Body>{props.bodyContent || props.children}</Body>
      </div>
    </StyledModal>
  );
};

export default Modal;
