import { OrganisationState } from "../types";
import {Reducer} from "redux";
import { fetchOrganisation } from "../routines";

const organisationState: OrganisationState = {
  customers: [],
  loaded: false
};

const organisation: Reducer<OrganisationState> = (state: OrganisationState = organisationState, action: any) => {
  switch (action.type) {
    case fetchOrganisation.SUCCESS:
      return {
          ...state,
          customers: action.payload,
          loaded: true
      };


    default:
        return state;
  }
};

export default organisation;
