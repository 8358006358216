import * as React from "react";
import { SettingEnum } from "../../../../DefaultSettings/Settings/settingEnum";
import { heartBeatOpts, sensorSupCheckTimerOpts, sensorSupCheckTimerPendantOpts, guardTimeOpts, bypassTimerOpts, sensorSensitivity } from "./options";
import withFormWrapper from "@Elements/Form/formWrapper";
import { Collapse } from "react-collapse";
import styled from "styled-components";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import { ToggleWrap } from "@Elements/Form/ToggleWrap";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { Col } from "react-bootstrap";
import { ComponentWrapper } from "../styled";
import { GeneralSettingsForm } from "../../types";
import { AclCheck } from "../generalSettings";
import { TIME_OFFSET, status } from "@Elements/Form/utils";

const SensorSupervision = DropdownWrap(
  "sensorSupervisionCheckTimer",
  {
    title: {id: "sensorSupervisionCheckText", defaultMessage: "Sensor overvåking"},
    tooltip: "generalSensorSupervisionToolTipText",
    tooltipTitle: {id: "generalSensorSupervisionToolTipTitle", defaultMessage: "Sensor overvåking"},
    dataOptions: {
      options: sensorSupCheckTimerOpts,
    },
    width: "250px"
  }
)

const SensorSupervisionPendant = DropdownWrap(
  "sensorSupervisionCheckTimerPendant",
  {
    title: {id: "sensorSupervisionCheckPendantText", defaultMessage: "Sensor overvåking pendant"},
    tooltip: "generalSensorSupervisionPendantToolTipText",
    tooltipTitle: {id: "sensorSupervisionCheckPendantText", defaultMessage: "Sensor overvåking pendant"},
    dataOptions: {
      options: sensorSupCheckTimerPendantOpts,
      mapRoundDown: true
    },
    width: "250px"
  }
)

export const GX8Heartbeat = 
  DropdownWrap(
    SettingEnum.HEARTBEAT_INTERVAL, 
    {
      title: {id: "heartbeatIntervalText", defaultMessage: "Heartbeat intervall"},
      dataOptions: {
        options: heartBeatOpts,
        offset: TIME_OFFSET.SECONDS_IN_MINUTE,
        nullValue: status.disable
      },
      width: "250px"
    }
  );

export const GuardTime = 
  DropdownWrap(
    "guardTime", 
    {
      title: {id: "guardTimeText", defaultMessage: "Angrefristalarm"},
      tooltip: "generalSensorGuardToolTipText",
      tooltipTitle: {id: "generalSensorGuardToolTipTitle", defaultMessage: "Angrefristalarm"},
      dataOptions: {
        options: guardTimeOpts,
        nullValue: status.disable
      },
      width: "250px"
    }
  );

export const BypassTimer = 
  DropdownWrap(
    "bypassTimer", 
    {
      title: {id: "silenceWandereralarmLabel", defaultMessage: "Bedøv vandreralarm"},
      tooltip: "silenceWandereralarmText",
      tooltipTitle: {id: "silenceWandereralarmLabel", defaultMessage: "Bedøv vandreralarm"},
      dataOptions: {
        options: bypassTimerOpts,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      width: "250px"
    }
  );

export const LocalLearning =
  CheckWrap<GeneralSettingsForm>(
    "localLearning",
    {
      label: {
        id: "activateLocalLearning",
        defaultMessage: "Aktiver Lokal innlæring"
      },
      contents: true,
      tooltip: "localLearningToolTip",
      tooltipTitle: {id: "activateLocalLearning", defaultMessage: "Aktiver Lokal innlæring"}
    }
  )

  export const SensorSensitivity = 
    DropdownWrap(
      SettingEnum.SENSOR_SENSITIVITY , 
      {
        title: {id: "sensorSensitivity", defaultMessage: "Sensor sensitivity"},
        tooltip: "sensorSensitivityTooltip",
        tooltipTitle: {id: "sensorSensitivityTooltipTitle", defaultMessage: "Sensor sensitivity"},
        dataOptions: {
          options: sensorSensitivity,
          skipMap: true
        },
        width: "250px"
      }
    );

export const GX8GeneralSettingsSectionOne = () => {
  return(
    <Col md={6}>
      <ComponentWrapper>
        <SensorSupervision/>
      </ComponentWrapper>
      <ComponentWrapper>
        <SensorSupervisionPendant/>
      </ComponentWrapper>
      <ComponentWrapper>
        <GX8Heartbeat/>
      </ComponentWrapper>
      <ComponentWrapper>
        <SensorSensitivity/>
      </ComponentWrapper>
    </Col>
  )
}

export const GX8GeneralSettingsSectionTwo = () => {
  return(
    <Col md={6}>
      <ComponentWrapper>
        <GuardTime/>
      </ComponentWrapper>
      <ComponentWrapper>
        <BypassTimer/>
      </ComponentWrapper>
      <ComponentWrapper>
        <AclCheck/>
      </ComponentWrapper>
      <ComponentWrapper>
        <LocalLearning/>
      </ComponentWrapper>
    </Col>
  )
}