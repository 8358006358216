import React from 'react';
import { connect, Field } from 'formik';
import { Grid, Row } from 'react-bootstrap';

import { CheckBoxRow, Tooltip } from '../layoutComp';
import { eventSetupTooltips as Tips } from '../tooltips';
import { Radio, RadioGroup } from '../../../Elements/RadioFormik';
import { Checkbox, CheckboxGroup } from '../../../Elements/CheckboxFormik';
import Switch from '../../../Elements/Switch';

import {
  TabHeader,
  SubHeader,
  Content,
  SubContent,
  CapitalizedString, CollapsedWrapper
} from '../WrapperStyles';
import { useIntl } from 'react-intl';
import { Collapse } from 'react-collapse';
import { Role } from '../Store/types';

const EventSetup = connect<{}, Role>(({formik: { values, setFieldValue }}) => {

  const enabled = !!values.policies.eventSetup.rolePolicies.status;
  const { formatMessage } = useIntl();

  return (
    <CollapsedWrapper>
        <TabHeader>
          <CapitalizedString>
            {formatMessage({id: 'eventSetupRoleEditor', defaultMessage: 'Event Setup'})}
          </CapitalizedString>
          <Switch
            checked={enabled}
            onChange={(value: boolean) => {
              setFieldValue("policies.eventSetup.rolePolicies.status", value);
            }}
          />
          &nbsp;
          <Tooltip
            id="eventSetupTip"
            tooltip={formatMessage({
              id: Tips.eventSetup,
              defaultMessage: Tips.eventSetupContent
            })}
          />
        </TabHeader>
        <Collapse isOpened={enabled}>
          <SubHeader>
            <CapitalizedString>
              {formatMessage({id: 'gsmRecipients', defaultMessage: 'GSM Recipients'})}:
            </CapitalizedString>
            <Tooltip
              id="gsmRecipientsTip"
              tooltip={formatMessage({
                id: Tips.gsmRecipients,
                defaultMessage: Tips.gsmRecipientsContent
              })}
            />
          </SubHeader>
          <Content>
            <RadioGroup disabled={false}>
              <Field
                component={Radio}
                name="policies.gsmRecipients.rolePolicies.information"
                radioValue="all"
                id="policies.gsmRecipients.rolePolicies.information.all"
                label={formatMessage({
                  id: 'allGsm',
                  defaultMessage: 'Add/delete/edit GSM Recipients'
                })}
              />
              <Field
                component={Radio}
                name="policies.gsmRecipients.rolePolicies.information"
                radioValue="read"
                id="policies.gsmRecipients.rolePolicies.information.read"
                label={formatMessage({id: 'read', defaultMessage: 'Read Only'})}
              />
              <Field
                component={Radio}
                name="policies.gsmRecipients.rolePolicies.information"
                radioValue="hidden"
                id="policies.gsmRecipients.rolePolicies.information.hidden"
                label={formatMessage({id: 'radioHidden', defaultMessage: 'Hidden'})}
              />
            </RadioGroup>
          </Content>
          <SubHeader>
            <CapitalizedString>
              {formatMessage({id: 'nextOfKinRecipients', defaultMessage: 'Next-of-kin Recipients'})}:
            </CapitalizedString>
            <Tooltip
              id="nextOfKinTip"
              tooltip={formatMessage({
                id: Tips.nextOfKin,
                defaultMessage: Tips.nextOfKinContent
              })}
            />
          </SubHeader>
        <CheckBoxRow
          values={values.policies.nextOfKinCheck.rolePolicies.status}
          name="policies.nextOfKinCheck.rolePolicies.status"
          label={formatMessage({
            id: 'addNextOfKinRecipients',
            defaultMessage: 'May add next-of-kin recipients'
          })}
        />
          <SubContent endSection>
            <CheckboxGroup disabled={false}>
              <Field
                name="policies.nextOfKinTracking.rolePolicies.status"
                id="policies.nextOfKinTracking.rolePolicies.status"
                label={formatMessage({id: 'nextOfKinTrackingTitle', defaultMessage: 'Tracking'})}
                disabled={!values.policies.nextOfKinCheck.rolePolicies.status}
                value={values.policies.nextOfKinTracking.rolePolicies.status}
                component={Checkbox}
                outerDiv={{ padding: '5px' }}
              />
              <Field
                name="policies.nextOfKinSchedule.rolePolicies.status"
                id="policies.nextOfKinSchedule.rolePolicies.status"
                label={formatMessage({
                  id: 'nextOfKinScheduleTitle',
                  defaultMessage: 'Manage own schedule'
                })}
                disabled={!values.policies.nextOfKinCheck.rolePolicies.status}
                value={values.policies.nextOfKinSchedule.rolePolicies.status}
                component={Checkbox}
                outerDiv={{ padding: '5px' }}
              />
              <Field
                name="policies.nextOfKinCall.rolePolicies.status"
                id="policies.nextOfKinCall.rolePolicies.status"
                label={formatMessage({id: 'nextOfKinCallingTitle', defaultMessage: 'Call Safemate'})}
                disabled={!values.policies.nextOfKinCheck.rolePolicies.status}
                value={values.policies.nextOfKinCall.rolePolicies.status}
                component={Checkbox}
                outerDiv={{ padding: '5px' }}
              />
              <Field
                name="policies.nextOfKinImage.rolePolicies.status"
                id="policies.nextOfKinImage.rolePolicies.status"
                label={formatMessage({
                  id: 'nextOfKinImageTitleInfo',
                  defaultMessage: 'Upload Safemate user image'
                })}
                disabled={!values.policies.nextOfKinCheck.rolePolicies.status}
                value={values.policies.nextOfKinImage.rolePolicies.status}
                component={Checkbox}
                outerDiv={{ padding: '5px' }}
              />
            </CheckboxGroup>
          </SubContent>
          <SubHeader>
            <CapitalizedString>
              {formatMessage({id: 'emailRecipients', defaultMessage: 'E-Mail Recipients'})}:
            </CapitalizedString>
            <Tooltip
              id="emailRecipientsTip"
              tooltip={formatMessage({
                id: Tips.emailRecipients,
                defaultMessage: Tips.emailRecipientsContent
              })}
            />
          </SubHeader>
          <Content endSection>
            <RadioGroup disabled={false}>
              <Field
                component={Radio}
                name="policies.emailRecipients.rolePolicies.information"
                radioValue="all"
                id="policies.emailRecipients.rolePolicies.information.all"
                label={formatMessage({
                  id: 'allEmail',
                  defaultMessage: 'Add/delete/edit E-Mail Recipients'
                })}
              />
              <Field
                component={Radio}
                name="policies.emailRecipients.rolePolicies.information"
                radioValue="read"
                id="policies.emailRecipients.rolePolicies.information.read"
                label={formatMessage({id: 'read', defaultMessage: 'Read Only'})}
              />
              <Field
                component={Radio}
                name="policies.emailRecipients.rolePolicies.information"
                radioValue="hidden"
                id="policies.emailRecipients.rolePolicies.information.hidden"
                label={formatMessage({id: 'radioHidden', defaultMessage: 'Hidden'})}
              />
            </RadioGroup>
          </Content>
        <CheckBoxRow
          values={values.policies.alarmCenter.rolePolicies.status}
          name="policies.alarmCenter.rolePolicies.status"
          label={formatMessage({
            id: 'allowAlarmCenter',
            defaultMessage: 'May allow alarm center connection'
          })}
          tooltip={formatMessage({
            id: Tips.alarmCenterConnection,
            defaultMessage: Tips.alarmCenterConnection
          })}
        />
        <CheckBoxRow
          values={values.policies.controlCalling.rolePolicies.status}
          name="policies.controlCalling.rolePolicies.status"
          label={formatMessage({
            id: 'toggleCalling',
            defaultMessage: 'Toggle sequential / parallel calling'
          })}
          tooltip={formatMessage({id: Tips.controlCalling, defaultMessage: Tips.controlCalling})}
        />
        <CheckBoxRow
          values={values.policies.deviceNameChange.rolePolicies.status}
          name="policies.deviceNameChange.rolePolicies.status"
          label={formatMessage({
            id: 'allowDeviceNameChange',
            defaultMessage: 'Allow device name change'
          })}
          tooltip={formatMessage({id: Tips.deviceNameChange, defaultMessage: Tips.deviceNameChange})}
        />
      </Collapse>
    </CollapsedWrapper>
  );
});

export default EventSetup;
