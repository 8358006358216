import { SiteTypeEnum } from "@Safemate/SiteTypeEnum"
import { AppAction } from "./types"
import Actions from "./actionTypes";
import { CustomRole } from "@Safemate/Model/Role";

export type SetSiteContext = (context: SiteTypeEnum) => AppAction<SiteTypeEnum>

export const setSiteContext: SetSiteContext = (context) => ({
  type: Actions.SET_SITE_CONTEXT,
  payload: context
})

export type SetSelectedRoles = (roles: CustomRole[]) => AppAction<CustomRole[]>

export const setSelectedRoles: SetSelectedRoles = (roles) => ({
  type: Actions.SET_SELECTED_ROLES,
  payload: roles
})

export type SetShowAlarmNotification = (show: boolean) => AppAction<boolean>

export const setShowAlarmNotification: SetShowAlarmNotification = (show) => ({
  type: Actions.SET_SHOW_ALARM_NOTIFICATION,
  payload: show
})
