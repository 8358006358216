import {takeLatest, put, race, take, all } from 'redux-saga/effects';
import { AppAction, IRace } from '@Safemate/Store/types';
import * as Routines from "../routines";

interface Initialize{
  deviceId: number;
}

function* initialize(action: AppAction<Initialize>){
  try{

    yield put(Routines.getDevice(action.payload.deviceId));
    yield put(Routines.getAhpPrivateLink());
  }
  catch(e){
    console.log(e)
  }
  yield put(Routines.initialize.fulfill());
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
