import { Grid } from 'react-bootstrap';
import styled from 'styled-components';

export const CollapsedWrapper = styled.div`
  padding: 10px;
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

export const MainHeader = styled.div`
  font-size: 35px;
  padding: 10px 0px 25px 15px;
`;

export const DisplayWrapper = styled.div<{display: boolean}>`
  display: ${props => (props.display ? 'block' : 'none')};
`;

export const Container = styled.div`
  background: ${props => props.theme.colors.backgroundPrimary};
  border-radius: ${props => props.theme.colors.borderRadius};
  border: ${props => props.theme.colors.border};
  padding: 25px;
`;

export const TabHeader = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.textPrimary};
  display: flex;
  padding-bottom: 20px;
  font-size: 25px;
`;

export const SubHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  padding: 25px 0px 5px 0px;
`;

export const CapitalizedString = styled.span`
  &::first-letter {
    text-transform: uppercase;
  }
  margin-right: 5px;
`;

export const GeneralSubHeader = styled<any>(SubHeader)`
  padding-top: 10px;
`;

export const HeaderSpan = styled.span<{accent: boolean, bold:boolean}>`
  color: ${props =>
    props.accent
      ? props.theme.colors.accent
      : props.theme.colors.textSecondary};
  font-weight: ${props => (props.bold ? 'bold' : 'none')};
`;

export const ListWrapper = styled.div`
  @media (max-width: 1200px) {
    margin-top: 75px;
  }
`;

export const Content = styled.div<{endSection?: boolean}>`
  align-items: center;
  display: flex;
  padding: 5px 0px 20px 0px;
  border-bottom: ${props => (props.endSection ? '1px solid #595a5e' : 'none')};
  margin-bottom: ${props => (props.endSection ? '10px' : 'initial')};
`;

export const NoFlexContent = styled<any>(Content)`
  display: block;
`;

export const SubContent = styled.div<{endSection: boolean}>`
  padding: 15px 0px 20px 15px;
  border-bottom: ${props => (props.endSection ? '1px solid #595a5e' : 'none')};
`;

export const BelongingContent = styled.div`
  padding-left: 15px;
`;

export const GlyphContainer = styled.span`
  align-items: center;
  margin-bottom: 1px;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
`;

export const ToolTip = styled.div`
  display: none;
  position: fixed;
  bottom: 2%;
  right: 1%;
  background-color: #e1e1e1;
  padding: 1em;
  min-width: 300px;
  max-width: 300px;
  color: #282f3a;
  border-radius: 5px;
  border: 2px solid #858c97;
  font-weight: 300;
  z-index: 1;

  ${GlyphContainer}:hover & {
    display: block;
  }
`;

export const StyledField = styled.div`
  padding-bottom: 20px;
`;
