import {Reducer} from "redux";
import * as SettingsRoutines from "../../../Settings/Store/routines";
import {getAllNotes, updateNotes} from "../Routines";
import { InfoState } from "../types";
import { DeviceListActionTypes } from "../actionTypes";
import { getNoteExistence } from "../api"

const infoState: InfoState = {
  open: 0,
  noteFilled: [],
};

const info: Reducer<InfoState> = (state: InfoState = infoState, action) => {
  switch (action.type) {
    case SettingsRoutines.getNote.SUCCESS:
      return {
        ...state,
        note: action.payload || null
      };

    case DeviceListActionTypes.TOGGLE_INFO:
      return {
        ...state,
        open: action.payload
      }

    case getAllNotes.SUCCESS:
      return {
        ...state,
        noteFilled: action.payload
      }

    default:
      return state;
  }
};

export default info;
