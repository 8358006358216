import styled from "styled-components";
import { Button } from "react-bootstrap";

import { Form } from "formik";

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    height: 40px;
    width: 40px;
  }
`;

export const FullWidthInput = styled.input`
  width: 100%;
`;

export const SettingDiv = styled.div<{isChanged: boolean}>`
  input {
    ${props => !props.isChanged && "border: 1px green solid;"}
    background: ${props => props.theme.colors.backgroundPrimary};
  }

  padding: 15px;
`;

export const SettingHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  padding-bottom: 5px;

  div {
    width: auto;
  }
`

export const TitleSpan = styled.span`
  path {
    fill: ${props => props.theme.colors.textPrimary};
  }

  display: flex;
  align-items: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;



export const TitleWrap = styled.p<{right?: boolean}>`
  ${props => props.right ? "margin-right" : "margin-left"}: 5px;
  white-space: nowrap;
  display: block;
  justify-content: center;

  div {
    display: inline-block;
  }

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const CheckboxWrap = styled.div<{isChanged: boolean, isSet: boolean}>`
  span {
    ${props => `
      ${!props.isChanged && "border: 1px green solid;"}
      ${props.isSet && "background: green;"}
    `}
  }
`;

export const Header = styled.div`
  padding: 15px;

  h2 {
    margin-bottom: 0;
  }
`

export const Footer = styled.div`
  display: flex;
`

export const ResetContainer = styled.div<{disabled: boolean}>`
  display: flex;
  justify-content: center;
  ${props => props.disabled &&
  `button{
      pointer-events:none;
    }`
}
`

export const SaveContainer = styled.div<{disabled?: boolean}>`
  margin-left: auto;
  ${props => props.disabled &&
    `button{
      pointer-events:none;
    }`
  }
`;

export const CancelButton = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};
  margin-right: 15px;
`;




export const SaveButton = styled(Button)`
  background: ${props => props.theme.colors.accent};
`

export const ModalDialog = styled.p`
  padding: 20px;
`


export const ComponentWrapper = styled.div`

    margin-bottom: 1em;

`;

export const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 5px 14.7px;
`;

export const StyledButton = styled(Button)`
  margin-right: 10px;
  height: 4.5em;
  min-width: 200px;
`;

export const ButtonContainer = styled(SaveContainer)`
  padding: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const FooterCheck = styled.div`
  width: 100%;
  border-top: ${props => props.theme.colors.border};
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
`;

export const FooterNoBorder = styled.div`
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
`;

export const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;