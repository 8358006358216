import _axios, { AxiosInstance} from 'axios';
import { SiteTypeEnum } from '../../SiteTypeEnum';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---


export interface individualBillingProps{
  custId: number,
  value: boolean
}

export interface individualReferenceProps{
  custId: number,
  referenceVal: String
}


export const getUsers = async (siteType: SiteTypeEnum) => {
  const url: string = siteType === SiteTypeEnum.AHP ? `/user/forahp` : `/user/forfirm`;
  const response = await axios.get(url);
  return response.data;
};

export const getCustomersForList = async (parent: number) => {
  const url: string = `/partner/trimmedsuborgunitswithwithrightstemp/${parent}`;
  const response = await axios.get(url);
  return response.data;
}

export const getCustomerView = async (custId: number) => {
  const url: string = `/partner/viewcustomer/${custId}/false`;
  const response = await axios.get(url);
  return response.data;
}

export const setIndividualBilling = async ({value, custId}: individualBillingProps) => {
  const url: string = `/partner/individualBilling/${custId}/${value}`;
  const response = await axios.get(url);
  return response.data;
}

export const setAllowDeviceDeletes = async ({value, custId}: individualBillingProps) => {
  const url: string = `/partner/allowDeviceDeletes/${custId}/${value}`;
  const response = await axios.get(url);
  return response.data;
}

export const setReference = async ({custId, referenceVal}: individualReferenceProps) => {
  const url: string = `partner/reference/${custId}/${referenceVal}`;
  const response = await axios.get(url);
  return response.data;
}

export const getSuperFirm = async () => {
  const url: string = `/firm/superfirm/`;
  const response = await axios.get(url);
  return response.data;
}
