import {Reducer} from "redux";
import {UserState} from "../types";
import {deleteUser, initializeDeletedUsers, initializeLdapUsers, initializeUsers, saveUser} from "../routines";
import {UserAdminActionTypes} from "../actionTypes";
import {UserWithCustomer} from "../../Models/UserWithCustomer";

const userState: UserState = {
  users: [],
  deletedUsers: [],
  ldap: {
    ldapUsers: [],
    loading: true,
    selected: false
  }
};

const user: Reducer<UserState> = (state: UserState = userState, action: any) => {
  switch (action.type) {
    case initializeUsers.SUCCESS:
      return {
        ...state,
        users: action.payload
      };

    case initializeDeletedUsers.SUCCESS:
      return {
        ...state,
        deletedUsers: action.payload
      }

    case initializeLdapUsers.SUCCESS:
      return {
        ...state,
        ldap: {
          ...state.ldap,
          ldapUsers: action.payload,
          loading: false,
        }
      };

    case initializeLdapUsers.FAILURE:
      return{
        ...state,
        ldap: {
          ...state.ldap,
          loading: false
        }
      };

    case saveUser.SUCCESS:
      const savedUser: UserWithCustomer = action.payload;
      const newUsers = state.users.filter(user => savedUser.user.userId !== user.user.userId);
      newUsers.push(savedUser);
      return{
        ...state,
        users: newUsers
      };

    case deleteUser.SUCCESS:
      return{
        ...state,
        users: state.users.filter(user => user.user.userId !== action.payload)
      }

    case UserAdminActionTypes.SELECT_LDAP:
      return{
        ...state,
        ldap: {
          ...state.ldap,
          selected: action.payload
        }
      };

    default:
      return state;
  }
};

export default user;
