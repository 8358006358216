import {FirmwareState} from "../types";
import {Reducer} from "redux";
import {fetchDeviceFirmware} from "../routines";

const firmwareState: FirmwareState = {
  firmware: {}
};

const firmware: Reducer<FirmwareState> = (state: FirmwareState = firmwareState, action) => {
  switch (action.type) {
    case fetchDeviceFirmware.SUCCESS:

        const { firmware, id } = action.payload;

        return {
            ...state,
            firmware: {
                ...state.firmware,
                [id]: firmware
            }
        };

    default:
        return state;
  }
};

export default firmware;
