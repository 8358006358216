import styled from "styled-components";

export const HeaderWrapper = styled.span`
  display: flex;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const Highlighted = styled.span`
  color: ${props => props.theme.colors.accent};
  display: flex;
  align-items: center;

  svg {
    fill: ${props => props.theme.colors.accent};
  }

`;