import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import styled from "styled-components";
import { Col, Row, Button } from "react-bootstrap";

const StyledLifetimeRow = styled(Row)`
  padding: 20px 0px 20px 0px;
  margin-bottom: 30px;
  width: 100%;
  box-shadow: 0px 0px 15px 0px gray;
  margin-left: 0px;
  `;

const StyledColCenter = styled(Col)`
  text-align: center;
  padding-top:15px;
`


const StyledP = styled.p`
  padding-top: 19px
`;

const EmptyP = styled.p`
  display:none;
`;

const H4 = styled.h4`
  white-space: normal;
  padding-left: 0px;
  font-weight: bold!important;

`;

const StyledH3 = styled.h3`
  white-space: normal;
  padding-left: 0px;
  font-weight: bold!important;
  font-size: 30px;
`;

interface LifetimeSupportProps extends WrappedComponentProps{
  phoneNum: String;
  cost: String;
}

export const LifetimeSupport =  injectIntl(({intl: {formatMessage}, phoneNum, cost }: LifetimeSupportProps) => {
  const [ lifetimeSupport, setLifetimeSupport ] = React.useState(false);
  const handleClick = () => {
    setLifetimeSupport(true);
  }

  return(
    <StyledLifetimeRow>
      <Col md={6}>
        <H4>{formatMessage({id: "lifetimeSupport", defaultMessage: "Lifetime Support"})}</H4>
        <p>{formatMessage({id: "helpWhenYouNeedIt", defaultMessage: "Alltid hjelp når du trenger det"})}</p>
        {!lifetimeSupport ? <Button onClick={handleClick} type="submit">
          {formatMessage({id: "buy", defaultMessage: "kjøp"})}
        </Button> :
        <StyledP>{formatMessage({id: "lifetimeDevelopmentSoon", defaultMessage: "Funksjonalitet under utvikling, vennligst kontatk Safemate support for livstidssupport"})}</StyledP>}
      </Col>
      <StyledColCenter md={6}>
        <label>{formatMessage({id: "only", defaultMessage: "kun"})}</label>
        <p><StyledH3>{cost}</StyledH3></p>
      </StyledColCenter>
    </StyledLifetimeRow>
  )
})