import {call, put, takeLatest, select, take, race} from 'redux-saga/effects';

import * as Routines from '../routines';
import * as Api from '../api';
import { feedbackFailure } from '../../../Feedback/reducer';
import { AppAction } from '@Safemate/Store/types';
import { selectSuperfirm } from '@Safemate/Store/selectors';
import { ICustomer } from '@Safemate/Model/Customer';
import { BillingRoundDate } from '@Safemate/PartnerBilling/Models/BillingRoundDate';


export interface CsvProps{
  invoiceNumber: string,
  pdfUrl: string, 
  yearMonth: string;
}

export interface PdfProps{
  pdfUrl: string, 
  yearMonth: string;
}

function* getHistory(action: AppAction<null>){
  try{
    const history: BillingRoundDate[] = yield call(Api.getHistory);
    yield put(Routines.getHistory.success(history));
  }
  catch{
    yield put(Routines.getHistory.failure());
  }
}

function* downloadCsv(action: AppAction<number>){
  try{
    const data = yield call(Api.downloadCsv, action.payload);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    yield put(Routines.downloadCsv.success());
  }
  catch{
    yield put(Routines.downloadCsv.failure());
  }
}

function* fetchInvoices(action: AppAction<string | undefined>){
  try{
    const invoices = yield call(Api.getInvoices);
    if(invoices.data == null){
      yield put(Routines.fetchInvoices.success(invoices.data));
    }else{
      const invoiceData = invoices.data.arTransactions.edges;
      yield put(Routines.fetchInvoices.success(invoiceData));
    }
  }
  catch(e){
    yield feedbackFailure(e)
  }
}

function* setCustomersClickedOpen(action: AppAction<Array<number>>){
    try{
      yield put(Routines.setCustomersClickedOpen.success(action.payload));
    }
    catch(e){
      yield put(Routines.setCustomersClickedOpen.failure());
    }
  }

function* setDownloading(action: AppAction<boolean>){
  try{
    yield put(Routines.setDownloading.success(action.payload));
  }
  catch(e){
    yield put(Routines.setDownloading.failure());
  }
}


function* getCsv(action: AppAction<CsvProps>){
  const {pdfUrl, invoiceNumber, yearMonth} = action.payload;
  let csvProcessing = true;
  try{
    yield put(Routines.setCsvProcessing.success({csvProcessing, invoiceNumber}));
    const csvData = yield call(Api.getCsv, {pdfUrl, yearMonth});
    yield put(Routines.getCsv.success({csvData, invoiceNumber}));
    csvProcessing = false;
    yield put(Routines.setCsvProcessing.success({csvProcessing, invoiceNumber}));
  }
  catch(e){
    csvProcessing = false;
    yield put(Routines.setCsvProcessing.failure({csvProcessing}));
    yield put(Routines.getCsv.failure());
  }
}


function* invoiceSaga(){
  yield takeLatest(Routines.fetchInvoices.TRIGGER, fetchInvoices);
  yield takeLatest(Routines.setCustomersClickedOpen.TRIGGER, setCustomersClickedOpen);
  yield takeLatest(Routines.setDownloading.TRIGGER, setDownloading);
  yield takeLatest(Routines.getCsv.TRIGGER, getCsv);
  yield takeLatest(Routines.getHistory.TRIGGER, getHistory);
  yield takeLatest(Routines.downloadCsv.TRIGGER, downloadCsv);
}

export default invoiceSaga;
