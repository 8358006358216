import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import { useIntl } from "react-intl";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import { Table, Th, Tr } from "./TableElementStyles";
import { EventSetupForm } from "./types";
import { hasEmailRecipient, ModalEnum, useDefault } from "./utils";
import { Field } from "formik";
import { Days } from "./Days";
import { Events } from "./Events";
import { TrashWrap } from "./WrapperStyles";
import Trash from "@Elements/Icon/Icons/Trash";
import { TdWrap } from ".";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { connect } from "react-redux";
import { AppState } from "@Safemate/Store/types";
import { Device } from "@Safemate/Settings/types";


const EmailRecipient = withFormWrapper<EventSetupForm, {}>(({ formik: { values: { eventSetups } } }) => {
  
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const { formatMessage } = useIntl();
  if(!hasEmailRecipient(eventSetups) || (!permissions.get(PolicyNameEnum.EMAIL_RECIPIENTS).generalPermissions.all && !permissions.get(PolicyNameEnum.EMAIL_RECIPIENTS).generalPermissions.read)) return null;
  
  return(
    <div>
      <h2>{formatMessage({id: 'emailRecipientsEventSetup', defaultMessage: 'Event receivers e-mail'})}</h2>
      <Table>
        <TableHeader/>
        {eventSetups.sort((a, b) => a.sort - b.sort).map((eventSetup, index) => {
          if(eventSetup.email !== null){
            return(
              <TableBody eventSetup={eventSetup} index={index}/>
              )
            }
          })}
      </Table>
    </div>
  )
})

interface TableHeaderProps{
  device: Device;
}

const mapStateToHeaderProps = ({ eventSetup: {device: { device }}}: AppState) => {
  return {
    device
  }
}

const TableHeader = connect(mapStateToHeaderProps)(({ device }: TableHeaderProps) => {

  const { formatMessage } = useIntl();

  return(
    <thead>
      <tr>
        <Th width="30%">{formatMessage({id: "email", defaultMessage: "E-Mail"})}</Th>
        <Th hideMobile>{formatMessage({id: "from", defaultMessage: "Fra"})}</Th>
        <Th hideMobile>{formatMessage({id: "to", defaultMessage: "Til"})}</Th>
        <Th hideMobile>{formatMessage({id: "days", defaultMessage: "Dager"})}</Th>
        <Th hideMobile>{formatMessage({id: "eventTypes", defaultMessage: "Hendelser"})}</Th>
        {!useDefault(device) && <Th>{formatMessage({id: "deleteSlett", defaultMessage: "Slett"})}</Th>}
      </tr>
    </thead>
  )
})

interface TableBodyProps{
  eventSetup: DeviceEventSetup;
  index: number;
  device: Device;
}

const mapStateToProps = ({ eventSetup: {device: { device }}}: AppState) => {
  return {
    device
  }
}

const TableBody = connect(mapStateToProps)(({ device, eventSetup, index }: TableBodyProps) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const disable: boolean = !permissions.get(PolicyNameEnum.EMAIL_RECIPIENTS).generalPermissions.all || useDefault(device);

  return(
    <tbody>
      <Tr id={`row-${index}`}>
        <TdWrap mobileWidth="100%" eventSetup={eventSetup} width="20%">
          <Field disabled={disable} style={{width: "100%"}} type="text" name={`eventSetups.${index}.email`} />
        </TdWrap>
        <TdWrap hideMobile eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.FROM} index={index}>
          {eventSetup.warnTimeFromString}
        </TdWrap>
        <TdWrap hideMobile eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.TO} index={index}>
          {eventSetup.warnTimeToString}
        </TdWrap>
        <TdWrap hideMobile eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.DAYS} index={index}>
          <Days eventSetup={eventSetup}/>
        </TdWrap>
        <TdWrap hideMobile eventSetup={eventSetup} clickable modal={ModalEnum.EVENT_TYPES} index={index}>
          <Events eventSetup={eventSetup}/>
        </TdWrap>
        {!useDefault(device) && <TdWrap eventSetup={eventSetup} clickable={!disable} modal={ModalEnum.DELETE} index={index}>
          <TrashWrap>
            <Trash style={{ width: '20px', height: '20px', margin: '0 auto' }} />
          </TrashWrap>
        </TdWrap>}
      </Tr>
    </tbody>
  )
})

export default EmailRecipient;