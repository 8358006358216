import { AppState } from "@Safemate/Store/types";
import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { setShowAlarmNotification, SetShowAlarmNotification } from "@Safemate/Store/actions";
import { getLastAlarm } from "@Safemate/Store/routines";
import { FilterAlarm, filterAlarm } from "@Safemate/DeviceList/Store/actions";
import { AlarmFilterEnum } from "@Safemate/DeviceList/AlarmFilterEnum";
import StatusAlarm from "@Elements/Icon/Icons/Status/StatusAlarm";
import { useIntl } from "react-intl";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";

const AlarmNotif = styled.div`
  z-index: 9999;
`

interface AlarmNotificationProps{
  showAlarmNotification: boolean;
  setShowAlarmNotification: SetShowAlarmNotification;
  getLastAlarm: Routine<ActionFunctionAny<Action<any>>>;
  filterAlarm: FilterAlarm;
  currentContext: SiteTypeEnum;
}

const mapStateToProps = ({ appData: { showAlarmNotification, currentContext } }: AppState) => {
  return {
    showAlarmNotification,
    currentContext
  }
}

const mapDispatchToProps = {
  setShowAlarmNotification,
  getLastAlarm,
  filterAlarm
}

const AlarmNotification = connect(mapStateToProps, mapDispatchToProps)(({ currentContext, getLastAlarm, filterAlarm, showAlarmNotification, setShowAlarmNotification }: AlarmNotificationProps) => {

  let interval: NodeJS.Timer = null;

  React.useEffect(() => {
    if(currentContext === SiteTypeEnum.PROFFWEB){
      interval = setInterval(() => {
        getLastAlarm()
      }, 30000)
    }  

    return () => {
      // Cleanup polling
      if(currentContext === SiteTypeEnum.PROFFWEB){
        clearTimeout(interval);
      }
    }
  }, [])

  const { formatMessage } = useIntl();

  const goToAlarms = () => {
    filterAlarm([AlarmFilterEnum.ALARM])
    location.hash = 'safemates';
  }

  const close = () => {
    setShowAlarmNotification(false);
  }

  if(!showAlarmNotification) return null;   
  
  return(
    <AlarmNotif className="alarm-notification pointer">
      <Col md={2}>
        <StatusAlarm/>
      </Col>
      <Col onClick={goToAlarms} md={9}>
        <h2>{formatMessage({id: "newAlarm", defaultMessage: "Ny alarm"})}</h2>
        <p>{formatMessage({id: "newAlarmDesc", defaultMessage: "Trykk her for å gå til enhetsoversikt med detaljer"})}</p>
      </Col>
      <Col md={1} className="dismiss">
        <button onClick={close} type="button" className="close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </Col>
	  </AlarmNotif>)
})

export default AlarmNotification;