import {call, put, select, take, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction, AppState } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure } from '../../../Feedback/reducer';
import { Battery, Position, RootState, Signal, Transaction } from '../types';
import { getLocations, prepareTransaction } from '../../Log/utils';
import {AlarmFilterEnum} from "@Safemate/DeviceList/AlarmFilterEnum";
import { IDevice } from '@Safemate/Model/Device';
import { selectDevices, selectFilterSelector, selectFilteredDevices } from '../selectors';

export interface GetAllTransactions{
  maxTransactionId: number;
  transactionCount: number;
  deviceIds?: number[]
}
export interface GetTransactions{
  deviceId: number;
  transactionCount?: number;
  maxTransactionId?: number;
  filter?: boolean;
}

export interface GetTransactionsResult{
  transactions: Transaction[];
  maxTransactionId: number;
  replace?: boolean;
  ignoreFilter?: boolean;
}

const maxTransactionIdSelector = (state: AppState) => state.transactionLog.transaction.maxTransactionId; 
const transactionCountSelector = (state: AppState) => state.transactionLog.transaction.transactionCount; 
const positionCountSelector = (state: AppState) => state.transactionLog.transaction.positionCount;
const transactionsSelector = (state: AppState) => state.transactionLog.transaction.transactions;
const renderSelector = (state: AppState) => state.transactionLog.transaction.render;
const googleMapsSelector = (state: AppState) => state.transactionLog.transaction.googleMaps;

function* getTransactionsForAll(action: AppAction<GetAllTransactions>){
  try{
    const filter: AlarmFilterEnum[] = yield select(selectFilterSelector);

    let deviceIds = null;
    const filteredDevices: IDevice[] = yield select(selectFilteredDevices);

    if(filter.length > 0){
      deviceIds = filteredDevices.map(device => device.deviId);
    }

    const maxTransactionId: number = yield select(maxTransactionIdSelector);
    let transactionCount: number = yield select(transactionCountSelector);
    console.log(transactionCount, action.payload);

    transactionCount += action.payload.transactionCount;

    const transactions: Transaction[] = yield call(Api.getAllTransactions, {
      transactionCount,
      maxTransactionId: action.payload.maxTransactionId !== undefined ? action.payload.maxTransactionId : maxTransactionId,
      deviceIds
    })

    yield put(Routines.getTransactionsForAll.success({transactionCount, noMore: transactions.length === 0}));
    yield modifyTransactions(transactions, maxTransactionId, false, false, false, filteredDevices);
  }
  catch(e){
    console.log(e);
    yield feedbackFailure(e);
    yield put(Routines.getTransactions.failure());
  }
}

function* getTransactions(action: AppAction<GetTransactions>){
  try {

    let filter: AlarmFilterEnum[] = yield select(selectFilterSelector);
    const apiData: Api.GetTransactionsApi = {
      ...action.payload,
      maxTransactionId: action.payload.maxTransactionId !== undefined ? action.payload.maxTransactionId : yield select(maxTransactionIdSelector),
      filter: filter && filter.length > 0,
      alarmFilter: filter
    }

    const transactions: Transaction[] = yield call(Api.getTransactions, apiData);
    if(apiData.maxTransactionId > 0){
      transactions.forEach(tx => {
        tx.isNew = true
      })
      setTimeout(() => {
        transactions.forEach(tx => {
          tx.isNew = false
        })
        modifyTransactions(transactions, apiData.maxTransactionId, false, false, false);
      }, 10000)
    }
    yield modifyTransactions(transactions, apiData.maxTransactionId, false, false, false);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getTransactions.failure());
  }
}

function* getMoreTransactions(action: AppAction<GetTransactions>){
  try{
    const currentTransactions: Transaction[] = yield select(transactionsSelector);
    let maxTransactionId = yield select(maxTransactionIdSelector);
    let filter:AlarmFilterEnum[] = yield select(selectFilterSelector);
    if(currentTransactions.length > 0){
      maxTransactionId = currentTransactions[currentTransactions.length - 1].id;
    }
    const apiData: Api.GetTransactionsApi = {
      ...action.payload,
      maxTransactionId,
      transactionCount: 25,
      filter: filter && filter.length > 0,
      alarmFilter: filter
    }
    const transactions = yield call(Api.getMoreTransactions, apiData);
    yield modifyTransactions(transactions, yield select(maxTransactionIdSelector), false, true, true);
    
  }
  catch(e){
    console.log(e);
    yield feedbackFailure(e);
    yield put(Routines.getMoreTransactions.failure());
  }
}

function* modifyTransactions(transactions: Transaction[], maxTransactionId: number, replace: boolean, ignoreFilter: boolean, getMoreTransactions: boolean, devices: IDevice[] = null){
  for(let i = 0; i < transactions.length; i++){
    prepareTransaction(transactions[i]);
    transactions[i].ignoreFilter = ignoreFilter;

    if(devices){
      const device = devices.find(device => device.deviId === transactions[i].deviId);
      transactions[i].dehaId = device ? device.dehaId : 0;
    }
  }
  const results: GetTransactionsResult = {
    transactions,
    maxTransactionId: transactions.length > 0 ? transactions[0].id : maxTransactionId,
    replace,
  }
  
  if(getMoreTransactions){
    let noMoreTransactionsToFetch = transactions.length == 0 && getMoreTransactions
    yield put(Routines.getMoreTransactions.success(noMoreTransactionsToFetch));
  }
  yield put(Routines.getTransactions.success(results));
  yield prepareLocations(results);
}

export interface GetPositions{
  deviceId: number;
  newDevice? : boolean;
  count?: number;
  uuid?: string;
}

export interface GetPosition{
  deviceId: number;
  tranId: number;
}

export interface GetPositionResults{
  positions: Position[];
  count: number;
  newDevice: boolean;
}

function* getPositions(action: AppAction<GetPositions>){
  try{

    const apiData: GetPositions = {
      ...action.payload,
      count: action.payload.count ? action.payload.count : yield select(positionCountSelector)
    }
  
    const positions: Position[] = yield call(Api.getPositions, apiData);

    const results:GetPositionResults = {
      positions,
      count: apiData.count,
      newDevice: action.payload.newDevice ? action.payload.newDevice : false
    }
    yield put(Routines.getPositions.success(results));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getPositions.failure());
  }
}

function* getPositionsUuid(action: AppAction<GetPositions>){
  try{

    const apiData: GetPositions = {
      ...action.payload,
      count: action.payload.count ? action.payload.count : yield select(positionCountSelector)
    }

    const positions: Position[] = yield call(Api.getPositionsUuid, apiData);

    const results:GetPositionResults = {
      positions,
      count: apiData.count,
      newDevice: action.payload.newDevice ? action.payload.newDevice : false
    }
    yield put(Routines.getPositions.success(results));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getPositions.failure());
  }
}

function* getPosition(action: AppAction<GetPosition>){
  try{
    const position: Position = yield call(Api.getPosition, action.payload);
    yield put(Routines.getPosition.success(position));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getPosition.failure());
  }
}

export function* prepareLocations(data: GetTransactionsResult){
  let render = yield select(renderSelector);
  let googleMaps = yield select(googleMapsSelector);
  yield call(getLocations, data.transactions, render, googleMaps);
  yield put(Routines.getTransactions.success(data));
}

export function* updateTransaction(transactions: Transaction[]){
  yield put(Routines.getTransactions.success(transactions));
}

export function* getAddress(action: AppAction<Transaction>){
  try{
    const address = yield call(Api.getAddress, action.payload);
    yield put(Routines.getAddress.success(address));
  }
  catch(e){
    yield put(Routines.getAddress.failure());
  }
}

export function* getDatesWithTxForDevice(action: AppAction<number>){
  try{
    const datesWithTransactions = yield call(Api.getDatesWithTxForDevice, action.payload);
    yield put(Routines.getDatesWithTxForDevice.success(datesWithTransactions));
  }
  catch(e){
    yield put(Routines.getDatesWithTxForDevice.failure());
  }
}

export interface GetTransactionsForDate{
  deviceId: number;
  fromDate: string;
  toDate: string;
  filter?: boolean;
  alarmFilter: AlarmFilterEnum[];
}

export function* getTransactionsForDate(action: AppAction<GetTransactionsForDate>){
  try{
    let filter:AlarmFilterEnum[] = yield select(selectFilterSelector);
    const apiData: GetTransactionsForDate = {
      ...action.payload,
      filter: filter && filter.length > 0,
      alarmFilter: filter
    }
    const transactions: Transaction[] = yield call(Api.getTransactionsForDate, apiData);
    const maxTransactionId = transactions.length > 0 ? transactions[0].id : 0;
    yield modifyTransactions(transactions, maxTransactionId, true, false, false);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getTransactionsForDate.failure());
  }
}

function* getBatteryForRange(action: AppAction<number>){
  try {
    const battery: Battery[] = yield call(Api.getBatteryForRange, action.payload);
    yield put(Routines.getBatteryForRange.success(battery));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getBatteryForRange.failure());
  }
}

function* getSignalForRange(action: AppAction<number>){
  try {
    const signal: Signal[] = yield call(Api.getSignalForRange, action.payload);
    yield put(Routines.getSignalForRange.success(signal));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getSignalForRange.failure());
  }
}

function* transactionSaga(){
    yield takeLatest(Routines.getTransactions.TRIGGER, getTransactions);
    yield takeLatest(Routines.getTransactionsForAll.TRIGGER, getTransactionsForAll);
    yield takeLatest(Routines.getMoreTransactions.TRIGGER, getMoreTransactions);
    yield takeLatest(Routines.getPositions.TRIGGER, getPositions);
    yield takeLatest(Routines.getPositionsUuid.TRIGGER, getPositionsUuid);
    yield takeLatest(Routines.getPosition.TRIGGER, getPosition);
    yield takeLatest(Routines.getDatesWithTxForDevice.TRIGGER, getDatesWithTxForDevice);
    yield takeLatest(Routines.getTransactionsForDate.TRIGGER, getTransactionsForDate);
    yield takeLatest(Routines.getSignalForRange.TRIGGER, getSignalForRange);
    yield takeLatest(Routines.getBatteryForRange.TRIGGER, getBatteryForRange);
}

export default transactionSaga;
