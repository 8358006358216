import {injectIntl, WrappedComponentProps} from "react-intl";
import  ArrowDown from "../../../../Elements/Icon/Icons/arrows/ArrowDown";
import  ArrowRight from "../../../../Elements/Icon/Icons/arrows/ArrowRight";
import {Col, Row} from "react-bootstrap";
import { toggleChildren } from "../../Store/routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {RootState} from "../../Store/types";
import {Customer} from "../../Models/Customer";



import * as React from "react";
import { AppState } from "@Safemate/Store/types";

interface ArrowProps extends WrappedComponentProps{
  toggleChildren: Routine<ActionFunctionAny<Action<any>>>;
  customer: Customer;
  toggledChildren: number[];
}

const mapStateToProps = ({ partnerDashboard: {customer: { toggledChildren }}}: AppState) => {
  return {
    toggledChildren
  }
}

const mapDispatchFromState = {
  toggleChildren,
}



const Arrow = connect(mapStateToProps, mapDispatchFromState)(injectIntl(({customer, toggledChildren, toggleChildren, intl: {formatMessage}}: ArrowProps) => {



const toggle = () => {
  toggleChildren(customer.custId);
}


return(
  <Col style={{padding: "12px"}}>
    {toggledChildren.includes(customer.custId) ? 
      <ArrowRight
          tabIndex="0"
          title={formatMessage({id: "rightArrow", defaultMessage: "Høyre pil"})}
          size="medium"
          onClick={toggle}
          inline
      />: 
      <ArrowDown
          tabIndex="0"
          title={formatMessage({id: "downArrow", defaultMessage: "Pil ned"})}
          size="medium"
          onClick={toggle}
          inline
      />}
  </Col>
  )
}));

    

export default Arrow;
