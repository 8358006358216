import React from 'react';
import {connect, Field, FieldArray} from 'formik';
import { Collapse } from "react-collapse";
import { accessTooltips as Tips } from '../tooltips';
import { Radio, RadioGroup } from '../../../Elements/RadioFormik';
import Switch from '../../../Elements/Switch';
import { CheckBoxRow, Tooltip } from '../layoutComp';

import {
  TabHeader,
  SubHeader,
  SubContent,
  CapitalizedString, CollapsedWrapper
} from '../WrapperStyles';
import { useIntl } from 'react-intl';
import { Role } from '../Store/types';
import {Button, Col, Row} from "react-bootstrap";
import styled from "styled-components";

const StyledField = styled(Field)`
  width: 100%;
`;

const RowPaddingTop = styled(Row)`
  padding-top: 1em;
`;

const StButton = styled(Button)`
 margin-top: 1em;
`;

const Access = connect<{}, Role>(({formik: { values, setFieldValue }}) => {

  const enabled = !!values.policies.access.rolePolicies.status;
  const { formatMessage } = useIntl();

  return (
    <CollapsedWrapper>
      <TabHeader>
        <CapitalizedString>
          {formatMessage({id: 'accessRoleEditor', defaultMessage: 'Access'})}
        </CapitalizedString>
        <Switch
          checked={enabled}
          onChange={(value: boolean) => {
            setFieldValue("policies.access.rolePolicies.status", value);
          }}
        />
        &nbsp;
        <Tooltip
          id="userAdmin"
          tooltip={formatMessage({
            id: Tips.access,
            defaultMessage: Tips.accessContent
          })}
        />
      </TabHeader>
      <Collapse isOpened={enabled}>
        <SubHeader>
          <CapitalizedString>
            {formatMessage({id: 'twoFactorAuth', defaultMessage: 'Two Factor Authentication'})}
          </CapitalizedString>
          <Tooltip
            id="twoFactorAuth"
            tooltip={formatMessage({
              id: Tips.twoFactorAuth,
              defaultMessage: Tips.twoFactorAuthContent
            })}
          />
        </SubHeader>
        <CheckBoxRow
          values={values.policies.requireTfa.rolePolicies.status}
          name="policies.requireTfa.rolePolicies.status"
          label={formatMessage({id: 'requireTfa', defaultMessage: 'Require TFA'})}
        />
          <SubHeader>
            <CapitalizedString>
              {formatMessage({id: 'formatHeader', defaultMessage: 'Format'})}
            </CapitalizedString>
            <Tooltip
              id="format"
              tooltip={formatMessage({
                id: Tips.formatHeaderTooltip,
                defaultMessage: Tips.formatHeaderTooltipContent
              })}
            />
          </SubHeader>
          <SubContent endSection>
            <RadioGroup
              disabled={!values.policies.requireTfa.rolePolicies.status}
            >
              <Field
                component={Radio}
                name="policies.requireTfa.rolePolicies.information"
                radioValue="SMS"
                id="policies.requireTfa.rolePolicies.information.sms"
                label={formatMessage({id: 'codeSentSMS', defaultMessage: 'Code sent via SMS'})}
                disabled={!values.policies.requireTfa.rolePolicies.status}
              />
              <Field
                component={Radio}
                name="policies.requireTfa.rolePolicies.information"
                id="policies.requireTfa.rolePolicies.information.email"
                radioValue="EMail"
                label={formatMessage({id:'codeSentEMail', defaultMessage: 'Code sent via E-Mail'})}
                disabled={!values.policies.requireTfa.rolePolicies.status}
              />
              <Field
                component={Radio}
                name="policies.requireTfa.rolePolicies.information"
                radioValue="User"
                id="policies.requireTfa.rolePolicies.information.user"
                label={formatMessage({
                  id: 'tfaUserChoice',
                  defaultMessage: 'Let the application user device delivery format (SMS or E-Mail)'
                })}
                disabled={!values.policies.requireTfa.rolePolicies.status}
              />
            </RadioGroup>
          </SubContent>

        <SubHeader>
          <CapitalizedString>
            {formatMessage({id: 'ipFilter', defaultMessage: 'IP Filter for user login'})}
          </CapitalizedString>
          <Tooltip
            id="twoFactorAuth"
            tooltip={formatMessage({
              id: Tips.requireIp,
              defaultMessage: 'Require IP for filtering'
            })}
          />
        </SubHeader>
        <CheckBoxRow
          values={values.policies.requireIp.rolePolicies.status}
          name="policies.requireIp.rolePolicies.status"
          label={formatMessage({id: 'requireIp', defaultMessage: 'Enable IP Filtering'})}
        />

        <SubHeader>
          <CapitalizedString>
            {formatMessage({id: 'requireIpRange', defaultMessage: 'Ip Range'})}
          </CapitalizedString>
          <Tooltip
            id="format"
            tooltip={formatMessage({
              id: Tips.requireIpRange,
              defaultMessage: Tips.formatHeaderTooltipContent
            })}
          />
        </SubHeader>

        <SubContent endSection>
          <FieldArray name="policies.ipRange.rolePolicies.ipRange" render={({ insert, remove, push }) => (
            <div>
              {
                values.policies.ipRange.rolePolicies.ipRange.length > 0 && values.policies.ipRange.rolePolicies.ipRange.map((friend, index) => (
                  <RowPaddingTop style={{display: "flex", alignItems: "end"}}>
                    <Col sm={4} xs={12}>
                      <div>{formatMessage({
                        id: 'requireIpFrom',
                        defaultMessage: 'IP from'
                      })}</div>
                      <StyledField
                        type="text"
                        name={`policies.ipRange.rolePolicies.ipRange[${index}].from`}
                        id={`policies.ipRange.rolePolicies.ipRange[${index}].from`}
                        label={formatMessage({
                          id: 'requireIpFrom',
                          defaultMessage: 'IP from'
                        })}
                        disabled={!values.policies.requireIp.rolePolicies.status}
                      />
                    </Col>
                    <Col sm={4} xs={12}>
                      <div>{formatMessage({
                        id: 'requireIpTo',
                        defaultMessage: 'IP to'
                      })}</div>
                      <StyledField
                        type="text"
                        name={`policies.ipRange.rolePolicies.ipRange[${index}].to`}
                        id={`policies.ipRange.rolePolicies.ipRange[${index}].to`}
                        label={formatMessage({
                          id: 'requireIpTo',
                          defaultMessage: 'IP to'
                        })}
                        disabled={!values.policies.requireIp.rolePolicies.status}
                      />
                    </Col>
                    <Col sm={4} xs={12}>
                      <StButton disabled={!values.policies.requireIp.rolePolicies.status} onClick={() => remove(index)}>{formatMessage({id: "removeIpRange", defaultMessage: "Remove IP Range"})}</StButton>
                    </Col>
                  </RowPaddingTop>
                ))
              }

              <StButton style={{cursor: 'pointer'}} disabled={!values.policies.requireIp.rolePolicies.status} onClick={() => {
                push({ from: "", to: "" })
              }}>{formatMessage({id: "addIpRange", defaultMessage: "Add IP Range"})}
              </StButton>
            </div>

          )}>
          </FieldArray>

        </SubContent>
        </Collapse>
      </CollapsedWrapper>
  );
});

export default Access;
