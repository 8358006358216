import * as React from "react";
import styled from "styled-components";
import moment from "moment";
import { WideSignal } from '@Elements/Signal';
import AlarmSVG from '@Icons/Alarm';
import MagneticSVG from '@Icons/Magnetic';
import WirelessSVG from '@Icons/Wireless';
import StikkSVG from '@Icons/Stikk';
import { Tr } from "@Elements/Table";
import { useIntl } from "react-intl";
import { IWalktest } from "./types";
import { getIconForType } from "../Sensor/sensorType";

export const Std = styled.td`
  vertical-align: middle;
  padding: 12px;
  border-top: ${props => props.theme.colors.border};
`;

export const SvgWrap = styled.div`
  float: left;
  fill: ${props => props.theme.colors.textPrimary};
  margin: 0 10px 0 0;
`;

export const TdCellText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  @media (max-width: 1024px) {
    max-width: 250px;
  }
  @media (max-width: 992px) {
    max-width: 90px;
  }
  @media (max-width: 768px) {
    max-width: 90px;
  }
`;

const IconWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface WalktestItemProps{
  walktest: IWalktest;
  history?: boolean;
  time: number;
}

const WalkTestItem = ({walktest, history, time: walktestTime}: WalktestItemProps) => {

  const { formatMessage } = useIntl();

  let icon;
  switch (walktest.sensorTypeCode) {
    case 'alarm':
      icon = <AlarmSVG className="svg sm_svgicon" />;
      break;
    case 'magnetic':
      icon = <MagneticSVG className="svg sm_svgicon" />;
      break;
    case 'wireless':
      icon = <WirelessSVG className="svg sm_svgicon" />;
      break;
    case 'stikk':
      icon = <StikkSVG className="svg sm_svgicon" />;
      break;
    default:
      break;
  }

  const date = 
    walktest.signalStrength === null
        ? ' - '
        : moment(walktest.date).format('DD.MM.YYYY')

  const noData = formatMessage({ id: 'noPrevData', defaultMessage: 'Ingen tidligere data' });

  const title = walktest.dect 
    ? walktest.signalStrength === null
      ? noData
      : "1 / 1"
    : walktest.signalStrength === null
      ? noData
      : `${walktest.signalStrength} / 9`

  return (
    <Tr>
      <Std style={{ width: '10%' }}>
        {date}
      </Std>
      <Std style={{ width: '10%' }}>
        {walktestTime}
      </Std>
      <Std>
        <IconWrap>
          <SvgWrap>{getIconForType(walktest.sensorTypeCode)}</SvgWrap>
          <TdCellText>
            <NameWrap title={walktest.name}>
              {walktest.name}
            </NameWrap>
          </TdCellText>
        </IconWrap>
      </Std>
      <Std>{walktest.gatewayId}</Std>
      <Std
        title={title}
      >
        <WideSignal
          strength={walktest.signalStrength}
          maxStrength={walktest.dect ? 1 : 9}
          history={history}
        />
      </Std>
    </Tr>
  );
};

export default WalkTestItem;
