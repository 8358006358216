import {CustomerState} from "../types";
import {Reducer} from "redux";
import {selectCustomer} from "../routines";

const customerState: CustomerState = {
  selectedCustomer: null
};

const customer: Reducer<CustomerState> = (state: CustomerState = customerState, action: any) => {
  switch (action.type) {
    case selectCustomer.SUCCESS:
      return {
          ...state,
        selectedCustomer: action.payload
      };

    default:
        return state;
  }
};

export default customer;
