import {call, put, select, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import {RoleWithCustomerNameWrapperDTO, User} from '../types';
import { feedbackFailure } from '../../../Feedback/reducer';
import { getUser } from '@Safemate/Store/routines';
import { SiteTypeEnum } from '@Safemate/SiteTypeEnum';
import { selectCurrentContext } from '@Safemate/Store/selectors';

function* initialize(action: AppAction<null>){
  try{

    const currentContext: SiteTypeEnum = yield select(selectCurrentContext);

    yield put(getUser.trigger());
    yield getUserRoleWithCustomerName(action);
    yield getAllowDelete(action);
    yield getPrivate(action);
    yield getCountries(action);
    if(currentContext === SiteTypeEnum.PROFFWEB){
      yield getTfaRules(action);
    }
    yield put(Routines.initialize.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.initialize.failure());
  }
}

function* getUserRoleWithCustomerName(action: AppAction<null>){
  try {
    const userWithRolesAndCustomers: RoleWithCustomerNameWrapperDTO = yield call(Api.getUserRoleWithCustomerName);
    yield put(Routines.getUserRoleWithCustomerName.success(userWithRolesAndCustomers));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getUserRoleWithCustomerName.failure());
  }
}

function* getCountries(action: AppAction<null>){
  try {
    const countries = yield call(Api.getCountries);
    yield put(Routines.getCountries.success(countries));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getCountries.failure());
  }
}


function* getAllowDelete(action: AppAction<null>){
  try {
    const allowDelete: string = yield call(Api.getAllowDelete);
    yield put(Routines.getAllowDelete.success(allowDelete));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getAllowDelete.failure());
  }
}

function* getTfaRules (action: AppAction<null>){
  try {
    const tfaRules: string = yield call(Api.getTfaRules);
    yield put(Routines.getTfaRules.success(tfaRules));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getTfaRules.failure());
  }
}

function* getPrivate (action: AppAction<null>){
  try {
    const privateUser: string = yield call(Api.getPrivate);
    yield put(Routines.getPrivate.success(privateUser));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getTfaRules.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
