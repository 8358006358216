import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { HeaderWrap, StatusDescription } from "./styles";
import StartIcon from "@Icons/FontAwesome/Start";
import StopIcon from "@Icons/FontAwesome/Stop";
import Loader from "@Elements/Loader";

const messages = defineMessages({
  playIcon: { id: 'playIconToolTip', defaultMessage: 'Start signalstyrketest' },
  stopIcon: { id: 'stopIconToolTip', defaultMessage: 'Stopp signalstyrketest' },
  inProgress: {
    id: 'walkTestInProgress',
    defaultMessage: 'Signalstyrketest pågår...'
  }
});

interface HeaderProps{
  walktestRunning: boolean;
  loading: boolean;
  start: () => void;
  stop: () => void;
}

const Header = ({ walktestRunning, loading, start, stop }: HeaderProps) => {

  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      <HeaderWrap>
        <StatusDescription show={walktestRunning}>
          {formatMessage(messages.inProgress)}
        </StatusDescription>
        {loading ? <Loader/> : walktestRunning
          ? <StopIcon
              onClick={stop}
              large
              animate
              title={formatMessage(messages.stopIcon)}
            />
          : <StartIcon
              onClick={start}
              large
              animate
              title={formatMessage(messages.playIcon)}
            />
        }
      </HeaderWrap>
      {walktestRunning && <div style={{padding: "15px"}}>
        {formatMessage({id: "walktestDesc", defaultMessage: "Trigger a signal from a sensor by pressing the button on the sensor, the hub will determine the signal strength based on this signal and display it in the list."})}
      </div>}
    </React.Fragment>
  )
}

export default Header;