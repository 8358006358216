import {FilterState} from "../types";
import {Reducer} from "redux";
import {PartnerDashboardActionTypes} from "../actionTypes";
import { saveFilters, loadFilters } from '../utils';
import { OrderFilterEnum } from "../../OrderFilterEnum";

const filterState: FilterState = {
  partners: [],
  orderFilter: OrderFilterEnum.DEFAULT,
};

const filter: Reducer<FilterState> = (state = {...filterState, ...loadFilters()}, action) => {
  switch (action.type) {
    case PartnerDashboardActionTypes.FILTER_ORDER:
        const orderState = {
          ...state,
          orderFilter: action.payload
        }
      save(orderState);
      return orderState
    default:
      return state;
  }
};

const save = (state: FilterState) => {
  saveFilters({
    orderFilter: state.orderFilter,
  })
}

export default filter;
