import styled from 'styled-components';
import { darken } from 'polished';

const ContentContainer = styled.div`
  background: ${props => darken(0.05, props.theme.colors.backgroundPrimary)};
  padding: 15px;
  min-height: 15em;
  flex-grow: 1;
`;

export default ContentContainer;
