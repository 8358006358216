import {put, takeLatest, race, take, select} from 'redux-saga/effects';
import { AppAction, IRace } from '../../../Store/types';
import * as Routines from "../routines";
import { Device } from '../types';
import { feedbackFailure } from '../../../Feedback/reducer';
import {GetPositionResults, GetPositions} from './transaction';
import moment from 'moment';
import { selectIsTracker, selectUser } from '@Safemate/Store/selectors';
import { IUser } from '@Safemate/Model/User';
import { MapType } from '@Safemate/map';

interface Initialize{
  deviceId: number;
  mapType: MapType;
  logPermission: boolean;
  uuid:string;
}

function* initialize(action: AppAction<Initialize>){

  const isTracker: boolean = yield select(selectIsTracker);
  const user: IUser = yield select(selectUser);
  const { deviceId, mapType, logPermission, uuid } = action.payload;

  try{

    let mapConsentModal = false;

    if(mapType === MapType.ALL && logPermission){
      yield put(Routines.getTransactionsForAll({
        transactionCount: 0
      }))
    }
    else{

      const getPositionsData: GetPositions = {
        count: 1,
        deviceId,
        uuid
      }

      yield put(Routines.getPositions(getPositionsData));
      const {success: getPositionsSuccess, failure: getPositionsFailure}: IRace<GetPositionResults> = yield race({
        success: take(Routines.getPositions.SUCCESS),
        failure: take(Routines.getPositions.FAILURE)
      });

      yield put(Routines.getDevice(action.payload.deviceId));
      const {success: { payload: device }}: IRace<Device> = yield race({
        success: take(Routines.getDevice.SUCCESS),
        failure: take(Routines.getDevice.FAILURE)
      })


      if(!isTracker){
        yield put(Routines.getPolicies(action.payload.deviceId));
      }
      yield put(Routines.initializeUser.success(user));
      yield put(Routines.fetchGeofence.trigger(deviceId));
      yield put(Routines.fetchGlobalGeofence.trigger(deviceId));
      if(getPositionsSuccess){
        yield put(Routines.initialize.success({mapConsentModal, mapType}));
      }
    }
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.initialize.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
