import styled from 'styled-components';

export const SpacedTd = styled.td`
  padding: 5px;
  border-top: ${props => props.theme.colors.border};
`;

export const Td = styled.td<{system?: boolean}>`
  padding: 12px;
  text-align: ${props => props.align};
  width: ${props => (props.width ? props.width : 'initial')};
  color: ${props =>
    props.system ? props.theme.colors.accent : props.theme.colors.textPrimary};
`;

export const Th = styled.th<{alignText?: string}>`
  padding: 12px;
  ${props => props.alignText && `text-align: ${props.alignText};`}
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
`;

export const TranslatedSpan = styled.span`
  @media (max-width: 1200px) {
    transform-origin: bottom left;
    transform: rotate(-60deg) translateY(25%);
    position: absolute;
    bottom: 15px;
  }
`;

export const HeaderTr = styled.tr`
  text-transform: capitalize;
`;

export const ContentTr = styled.tr`
  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
  outline: ${props => props.theme.colors.border};
`;

export const HiddenTr = styled.tr`
  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
  border-right: ${props => props.theme.colors.border};
  border-left: ${props => props.theme.colors.border};

  text-align: center;
`;

export const SpacedTr = styled.tr``;

export const Table = styled.table`
  width: 100%;
  margin-top: 40px;
`;
