import {connect} from "react-redux";
import {WrappedComponentProps, injectIntl} from "react-intl";
import * as React from "react";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import List from "../../../Elements/SpacedList";
import {Invoice} from "../Store/types";
import { mapHeader, mapInvoices} from "./utils";
import  Loader  from "@Elements/Loader";
import {deleteUser} from "../Store/routines";
import styled from "styled-components";
import {initializeInvoices} from "../Store/routines";
import { AppState } from "@Safemate/Store/types";


export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    height: 40px;
    width: 40px;
  }
`;

export const NoBillingWrap = styled.div`
  text-align: center;
`;

interface UserProps extends WrappedComponentProps{
  invoicesInitialized: boolean;
  invoiceList: Invoice[];
  initializeInvoices: Routine<ActionFunctionAny<Action<any>>>;
  isPrivate: boolean;
}

const mapStateToProps = ({ appData: { isPrivate }, myPageBilling: {initialization: {invoicesInitialized}, invoices: {invoiceList}}}: AppState) => {
  return{
    invoicesInitialized,
    invoiceList,
    isPrivate
  }
}

const mapDispatchToProps = {
  deleteUser,
  initializeInvoices
}

const BillingHistory = (injectIntl(
    ({ isPrivate, invoicesInitialized, initializeInvoices, invoiceList, intl: {formatMessage}}: UserProps) => {

      React.useEffect(() => {
        initializeInvoices();
      }, [])

      let mappedInvoices = mapInvoices(invoiceList);
      if(mappedInvoices!=null){
        mappedInvoices.filter(function (e) {return e != null;});
      }

      return (
        <React.Fragment>
          {!invoicesInitialized
            ? <LoaderWrap><Loader/></LoaderWrap>
            : 
            <React.Fragment>
            {mappedInvoices != null ? <List
              header={mapHeader(formatMessage, false, isPrivate)}
              content={ mappedInvoices }
            /> : 
            <NoBillingWrap>
                {formatMessage({id: "noBillingHistory", defaultMessage: "No billing history available"})}
            </NoBillingWrap>}
            </React.Fragment>
          }
        </React.Fragment>
      )
    }
  )
);

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistory);
