import * as React from "react";
import GoogleMap, { Maps} from "google-map-react";
import styled from "styled-components";
import {Row} from "react-bootstrap";
import { MapWrapper } from "./mapWrapper";
import { Position } from "../Store/types";
import { connect } from "react-redux";
import {User} from "../../UserAdmin/Models/User";
import {getGoogleMapLanguageCode} from "../utils";
import { Routine } from "redux-saga-routines";
import { ActionFunctionAny, Action } from "redux-actions";
import { Geofence,  } from "../../Settings/Geofence/types";
import {getPositions, getAllDevices} from "@Safemate/map/Store/routines";
import { AppState } from "@Safemate/Store/types";
import { MapType } from "..";
import MapDelegate from "@Safemate/map/Map/mapDelegate";
import {googleMaps, GoogleMaps} from "@Safemate/map/Store/actions";


const MapDivWrapper = styled(Row)`
  height: 40em;
  padding: 0px;
  margin: 0px 0px;
  position: relative;
`;

interface MapProps{
  positions: Position[];
  user: User;
  initialFences: Geofence[];
  geofence: Geofence[];
  id: number;
  deviceId: number;
  mapType: MapType;
  getPositions: Routine<ActionFunctionAny<Action<any>>>;
  getAllDevices: Routine<ActionFunctionAny<Action<any>>>;
  googleMaps: GoogleMaps;
}

const mapStateToProps = ({ transactionLog: {transaction: { positions }, initialization: {user}, device:{id}, geofence:{initialFences, geofence}}}: AppState) => {
  return {
    positions,
    user,
    id,
    initialFences,
    geofence
  }
}
const mapDispatchToProps = {
  getPositions,
  getAllDevices,
  googleMaps

}

const Map = connect(mapStateToProps, mapDispatchToProps)(({mapType, initialFences, geofence, positions, user, googleMaps}: MapProps) => {

  const [mapLoaded, setMapLoaded] = React.useState(false);

  let mapWrapper: MapWrapper = React.useMemo(() => {
      return new MapWrapper();
    }, [])

  let languageCode = getGoogleMapLanguageCode(user.languageCode);
  let pos = { lat: 59.5058648, lng: 9.1907483 };
  let defaultZoom = 4;
  if( positions && positions.length > 0 ){
    let position: Position = positions[0];
    if( position.posTime && position.posLati && position.posLong ){
      pos = { lat: parseFloat(position.posLati), lng: parseFloat(position.posLong) }
      defaultZoom = 16;
    }
  }
    return(
      <MapDivWrapper>
        <GoogleMap
          defaultZoom={defaultZoom}
          defaultCenter={pos}
          bootstrapURLKeys={
            {
              key: 'AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no',
              language: languageCode,
              v: '3',
              libraries: ['drawing','places']
            }
          }
          options={(map: Maps) => {
            return {
              streetViewControl: true,
              mapTypeControl: true,
              maxZoom: 20,
              mapTypeControlOptions: {
                mapTypeIds: [
                  map.MapTypeId.ROADMAP,
                  map.MapTypeId.SATELLITE,
                  map.MapTypeId.TERRAIN
                ]
              }
            }
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            googleMaps(maps);
            mapWrapper.init(map, maps, initialFences, mapType);
          }}
          onTilesLoaded={() => {
            mapWrapper.setInitialFences(initialFences);
            if(mapType === MapType.SINGLE){
              mapWrapper.createFences(geofence);
              mapWrapper.resetFences();
            }
            setMapLoaded(true);
          }}
        >
        </GoogleMap>
        { mapLoaded && <MapDelegate mapWrapper={ mapWrapper } /> }
      </MapDivWrapper>
    )
}
)

export default Map;
