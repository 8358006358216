import _axios, {AxiosInstance} from 'axios';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getRoles = async () => {
  const url: string = `/userroles/role`;
  const response = await axios.get(url);
  return response.data;  
};

export const deleteRole = async (id: number) => {
  const url: string = `/userroles/${id}`;
  const response = await axios.delete(url);
  return response.data;
}