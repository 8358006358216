import * as React from "react";
import {SvgWrap} from "./styles";
import Log from "./log";
import Edit from "./Edit";
import Delete from "./delete";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {AllPermissions, PolicyContext} from "../../../Policy/Provider";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import { accessToUser } from "@Safemate/Policy/rightsUtil";

interface ActionsProps{
  user: UserWithCustomer;
  isAhp: boolean;
}

const Actions = ({user: {user: {userId}}, user, isAhp}: ActionsProps) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const custIds = user.subOrgs.map(u => u.custId).concat(user.temporarySubOrgs.map(u => u.custId));

  const auditLog = accessToUser(permissions.get(PolicyNameEnum.AUDIT_LOG), custIds) || isAhp;
  const userPrivs = accessToUser(permissions.get(PolicyNameEnum.USER_PRIVS), custIds) || isAhp;

  return(
    <SvgWrap>
      {auditLog && <Log userId={userId}/>}
      {userPrivs &&
      <React.Fragment>
          <Edit user={user}/>
          <Delete user={user}/>
      </React.Fragment>
      }
    </SvgWrap>
  )
}

export default Actions;
