import * as React from "react";
import { connect } from "react-redux";
import { injectIntl, useIntl, WrappedComponentProps } from "react-intl";
import { Field, Formik } from "formik";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { SettingEnum as ComponentSettingEnum } from "../../settingEnum";
import { OrganisationForm } from "../types";
import { DeviceComponents } from "../../tabDefinition";
import { DeviceHardwareEnum, isGx8 } from "../../../DeviceHardwareEnum";
import { ComponentWrapper, Footer, ButtonContainer, StyledForm, SettingWrapper } from "./styled";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { Option, TreeDropdownOption } from "../../../../Elements/Dropdown/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { CancelButton, SaveButton } from "../../../DefaultSettings/Settings/styled";
import { saveOrganisation } from "../../Store/routines";
import SettingsHeader from "../header";
import { AppState } from "@Safemate/Store/types";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { CustomerWithChildren, ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { mapCustomersForUser } from "@Safemate/UserAdmin/utils";
import TreeDropdown from "@Elements/Dropdown/Tree";
import { CheckWrapper } from "@Elements/CheckboxFormik/elements";
import { Checkbox } from "@Elements/CheckboxFormik";


interface DeleteDataProps extends WrappedComponentProps{
  dehaId: number;
  deviId: number;
  ownedByCustomer: number;
  saveOrganisation: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ settings: {initialization: { dehaId, deviId }, device: { device: { ownedByCustomer }}}}: AppState) => {
  return{
    dehaId,
    deviId,
    ownedByCustomer
  }
}

const mapDispatchToProps = {
  saveOrganisation
}


const Organisation = connect(mapStateToProps, mapDispatchToProps)(injectIntl(({ saveOrganisation, dehaId, deviId, ownedByCustomer, intl: {formatMessage} }: DeleteDataProps) => {
  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  
  return(
    <Formik
        onSubmit={(values) => {
          saveOrganisation({
            deviceId: deviId,
            settings: values
          })
        }}
        enableReinitialize
        initialValues={{
          custId: `${ownedByCustomer}`,
          updateAhp: false
        }}
      >    
        {({dirty, setValues}) => 
        <StyledForm>
          <SettingsHeader 
            title={formatMessage({id: "org", defaultMessage: "Organisasjon"})}
          />
          <SettingWrapper>
              {components.has(ComponentSettingEnum.ORGANIZATION) 
                && components.get(ComponentSettingEnum.ORGANIZATION)?.map(comp => {
                  return <ComponentWrapper>{comp}</ComponentWrapper>
              })}
          </SettingWrapper>
          <Footer>
            <ButtonContainer disabled={!dirty}>
              <CancelButton disabled={!dirty} onClick={() => setValues({ 
                custId: `${ownedByCustomer}`,
                updateAhp: false
                })}>
                {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
              </CancelButton>
              <SaveButton disabled={!dirty} type="submit">
                {formatMessage({id: "save", defaultMessage: "Lagre"})}
              </SaveButton>
            </ButtonContainer>
          </Footer>
        </StyledForm>}
      </Formik>
  )

}))

export default Organisation;

interface SwapOrganisationProps{
  customerHierarchy: CustomerWithChildren;

}

const mapStateToSwapOrgProps = ({ appData: { customerHierarchy } }: AppState) => {
  return{
    customerHierarchy,
  }
}

export const SwapOrganisation = connect(mapStateToSwapOrgProps)(withFormWrapper<OrganisationForm, SwapOrganisationProps>(({ formik: { setFieldValue, values: { custId } }, customerHierarchy }) => {

  const [mappedCustomers, setMappedCustomers] = React.useState([] as TreeDropdownOption[]);
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (customerHierarchy) {
      setMappedCustomers(mapCustomersForUser([custId], customerHierarchy, permissions.get(PolicyNameEnum.DEVICE_ORGANIZATION).customerPermissions, ["all"], {singleSelect: true, includeTop: true}));
    }
  }, [customerHierarchy ])

  console.log(mappedCustomers);

  return(
    <TreeDropdown
      onChange={(values) => {
        if(values.length > 0){
          setFieldValue("custId", parseInt(values[0]));
        }
      }}
      options={mappedCustomers}
      title={formatMessage({id: "selectSuborg", defaultMessage: "Velg underavdeling"})}
    />
  )
}))

interface UpdateAhpProps{
  customersWithPolicy: ICustomerWithPolicy[];
  ahpId: number;
  ownedByCustomer: number;
}

const mapStateToUpdateAhpProps = ({ appData: { customersWithPolicy }, settings: { device: { device: { ahpId, ownedByCustomer }}}}: AppState) => {
  return {
    customersWithPolicy,
    ahpId,
    ownedByCustomer
  }
}

export const UpdateAhp = connect(mapStateToUpdateAhpProps)(withFormWrapper<OrganisationForm, UpdateAhpProps>(({  formik: { values: { custId, updateAhp } }, customersWithPolicy, ahpId, ownedByCustomer }) => {

  const [ selectedCustomerAhp, setSelectedCustomerAhp ] = React.useState<number>(ahpId);
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    const foundCust = customersWithPolicy.find(cust => cust.customerId === custId);

    if(foundCust){
      setSelectedCustomerAhp(foundCust.defaultAhp);
    }
  }, [custId, customersWithPolicy])

  if(selectedCustomerAhp === ahpId || custId === ownedByCustomer) return null;

  return(
    <CheckWrapper>
      <Field
        value={updateAhp}
        name="updateAhp"
        id="updateAhp"
        label={formatMessage({ id: "ahpCheckBoxLabel", defaultMessage: "Overfør alarmsentral innstillinger til valgt organisasjon" })}
        component={Checkbox}
      />
    </CheckWrapper>
  )
}))