import React from 'react';

const Photo = props => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      {...props}
    >
      <switch>
        <foreignObject
          requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
          x="0"
          y="0"
          width="1"
          height="1"
        />
        <g>
          <g>
            <path d="M29.988,46.279c0,0,0.362,6.601,6.463,13.67v0.004c1.285,1.561,6.268,7.052,12.909,7.327	c4.881,0.183,9.753-2.456,14.504-7.906l0.008,0.007c5.645-6.849,6.01-13.102,6.01-13.102s3.308,0.552,5.145-7.348     c1.836-7.9-1.837-6.061-1.837-6.061C77.963,7.894,58.472,1.097,58.472,1.097c-3.579-1.078-6.388-1.229-8.537-1.01     c-2.143-0.22-4.958-0.068-8.533,1.01c0,0-19.492,6.797-14.717,31.773c0,0-3.672-1.84-1.836,6.061     C26.685,46.831,29.988,46.279,29.988,46.279z" />
            <path d="M98.11,92.008c-4.041-10.652-10.469-12.671-15.612-13.587c-5.14-0.92-17.632-4.045-18.73-6.979     c-0.37-0.989-0.487-2.915-0.485-5.026c-4.297,3.627-8.782,5.464-13.392,5.464c-0.253,0-0.506-0.007-0.759-0.018     c-5.23-0.228-9.481-2.77-12.413-5.195c-0.007,2.012-0.129,3.824-0.484,4.775c-1.099,2.935-13.591,6.06-18.731,6.979     C12.36,79.337,5.932,81.356,1.891,92.008C-2.15,102.664,102.151,102.664,98.11,92.008z" />
          </g>
        </g>
      </switch>
    </svg>
  );
};

export default Photo;
