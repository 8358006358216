import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { CancelButton } from "./styled";
import { BillingHistory } from "./fields";
import withFormWrapper from "@Elements/Form/formWrapper";
import { BillingSettings } from "./types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const Footer =
  withFormWrapper<BillingSettings>(
    ({formik: {dirty, initialValues, resetForm}}) => {

      const { formatMessage } = useIntl();

      const handleCancel = () => {
        resetForm(initialValues);
      }

      return(
        <Wrapper>
          <div>
            <BillingHistory />
          </div>
          <div>
            <CancelButton disabled={(!dirty) } onClick={handleCancel}>
              {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
            </CancelButton>
            <Button type="submit" disabled={(!dirty) } >
              {formatMessage({id: "save", defaultMessage: "Lagre"})}
            </Button>
          </div>
        </Wrapper>
    )
  })

export default Footer;
