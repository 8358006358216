import * as React from "react";
import { useIntl, WrappedComponentProps } from "react-intl";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { TrackingButton } from "..";
import { findMe } from "../../Store/routines";
import { connect } from "react-redux";
import { AppState } from "@Safemate/Store/types";
import {Device} from "@Safemate/Settings/types";
import styled from "styled-components";
import {Button} from "react-bootstrap";
import {confirmModal} from "@Safemate/Settings/Components/modal";


export const ButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 5px 14.7px;
  div{
    height: 26px;
    width: 26px;
  }
`;

const FindMeButton = styled(Button)`
  margin-right: 15px;
  color: white;
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  display: block;
  font-size: 14px;
  height: 40px;
`;

interface GetFindMeProps{
    findMe: Routine<ActionFunctionAny<Action<any>>>;
    device: Device;
  }
  
  const mapStateToProps = ({ settings: {device: { device }}}: AppState) => {
    return{
      device
    }
  }

const mapDispatchToProps = {
    findMe
}

const FindMe = connect(mapStateToProps, mapDispatchToProps)(({ device, findMe }: GetFindMeProps) => {

    const { formatMessage } = useIntl();

    if( device.twin ){

      const {Comp, func} = confirmModal();
      const triggerFindMe = ( id: number ) => {
        findMe(id);
        func(false);
      }

      return(
        <React.Fragment>
          <TrackingButton onClick={() => func(true)}>
            {formatMessage({id: "findMe", defaultMessage: "Gjenfinnings lyd"})}
          </TrackingButton>
          <Comp
            title={`${formatMessage({id: "findMe", defaultMessage: "Gjenfinnings lyd"})}`}
            body={
              <React.Fragment>
                <FindMeButton onClick={() => triggerFindMe(device.deviId)}>
                  { device.deviceName } ({ device.deviceSerialNumber })
                </FindMeButton>
                <FindMeButton style={{marginTop:"20px"}} onClick={() => triggerFindMe(device.twinDevice.deviId)}>
                  { device.twinDevice.deviceName } ({ device.twinDevice.deviceSerialNumber })
                </FindMeButton>
              </React.Fragment>
            }
          />
        </React.Fragment>
      )
    } else {
      return(
        <TrackingButton onClick={() => findMe(device.deviId)}>
          {formatMessage({id: "findMe", defaultMessage: "Gjenfinnings lyd"})}
        </TrackingButton>
      )
    }
})

export default FindMe;
