import * as React from "react";
import {connect} from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {Col, Grid, Row} from "react-bootstrap";

import {initialize} from "./Store/routines";
import {RootState, Customer as CustomerType} from "./Store/types";
import subunitsReducer from './Store/Reducer';
import subunitsSaga from './Store/Saga';
import Customer from "./Customer";
import Form from "./Form";
import styled from "styled-components";
import Spinner from "../Spinner";
import { Title} from "../styles";
import SubUnitsSVG from "../../Elements/Icon/Icons/menu/SubUnits";
import configureProvider from "../DefaultProvider";
import { useIntl } from "react-intl";
import { AppState } from "@Safemate/Store/types";

interface SubUnitsProps {
  init: Routine<ActionFunctionAny<Action<any>>>;
  customer: CustomerType;
  initialized: boolean;
}

const CenteredRow = styled(Row)`
  display: flex;
  justify-content: center;
`

const StyledCol = styled(Col)`
  display: flex;
  margin-left: 15px;
  svg{
    width: 32px;
    height: 32px;
    float: left;
    margin-top: 9px;
  }
`

export const Frame = styled.div`
  border-radius: 4px;
  padding: 25px;
  padding-right: 40px;
  padding-left: 30px;
  ${props =>
  `
    background: ${props.theme.colors.backgroundPrimary};
    border: ${props.theme.colors.border};
  `
}
`;

const Wrap = styled.div`
  position: relative;
`

const mapStateToProps = ({ subUnits: {customer: {selectedCustomer}, initialization: {initialized}}}: AppState) => {
  return{
    initialized,
    customer: selectedCustomer,
  }
};

const mapDispatchToProps  = {
  init: initialize
};

// Outer component responsible for loading initial data.
export default connect(mapStateToProps, mapDispatchToProps)(({init, initialized, customer }: SubUnitsProps) => {

  const {formatMessage} = useIntl();
  // On mount
  React.useEffect(() => {
    init();
  }, []);

  if(!initialized)
    return (
      <Wrap>
        <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
      </Wrap>
    )

  return(
    <Wrap>
      <Grid fluid>
        <Row>
          <StyledCol>
            <h1><SubUnitsSVG/></h1>
            <Title>
              {formatMessage({id: "subUnitsIn", defaultMessage: "Underavdelinger i"})}
              &nbsp;
              {customer.customerName}
            </Title>
          </StyledCol>
        </Row>
        <Row>
          <Col md={4}>
            <Customer/>
          </Col>
          <Col md={8}>
            <Frame>
              <Row>
                <Form/>
              </Row>
            </Frame>
          </Col>
        </Row>
      </Grid>
    </Wrap>
  )
});