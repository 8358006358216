import { Field, useFormikContext } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { setRegisterStep, SetRegisterStep } from "../Store/actions";
import { connect } from "react-redux";
import { RegisterFirmForm, RegisterUserForm } from "./types";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { customerValidation, registerCustomer } from "../Store/routines";
import { RegisterStep } from ".";
import {AppState} from "@Safemate/Store/types";
import {MarketEnum} from "@Safemate/MarketEnum";
import {getZipCodeLength} from "@Safemate/utils";
import confirmModal from "@Safemate/Modals/confirmModal";

interface RegisterFirmAddressProps{
  setRegisterStep: SetRegisterStep;
  customerValidation: Routine<ActionFunctionAny<Action<any>>>;
  market: MarketEnum;
  showNameValidationModal: boolean;
}

const mapDispatchToProps = {
  setRegisterStep,
  customerValidation
}

const mapStateToProps = ({ appSettings: {market}, session: { showNameValidationModal }}: AppState) => {
  return{
    market,
    showNameValidationModal
  }
};

interface PostalResponse{
  postalCodeType: string;
  result: string;
  valid: boolean;
}

export const RegisterFirmAddress = connect(mapStateToProps, mapDispatchToProps)(({ setRegisterStep, customerValidation, market, showNameValidationModal }: RegisterFirmAddressProps) => {

  const { values, setFieldValue } = useFormikContext<RegisterFirmForm>();
  const { formatMessage } = useIntl();

  const {func, Comp} = confirmModal();

  React.useEffect(() => {
    func(showNameValidationModal);
  }, [showNameValidationModal])

  const getZipCity = (zip: string) => {
    if(zip && zip.length === getZipCodeLength(market)){

      (async () => {
        try{
          const response = await fetch(`/ajax/firm/postcode/${zip}`);
          const result: PostalResponse = await response.json();
          if(result.valid){
            setFieldValue("city", result.result)
          }
        }
        catch(e){
          console.log(e);
        }
      })()
    }
  }

  return(
    <React.Fragment>
      <h4>{formatMessage({id: "companyInfoGo", defaultMessage: "Register ny bruker"})}</h4>
      <Field type="text" name="companyName" placeholder={formatMessage({id: "companyName", defaultMessage: "companyName"})}/>
      <Field type="text" name="organizationNo" placeholder={formatMessage({id: "organizationNo", defaultMessage: "organizationNo"})}/> 
      <Field type="text" name="reference" placeholder={formatMessage({id: "ADDR_REFERENCE", defaultMessage: "ADDR_REFERENCE"})}/>
      <Field type="text" name="addrLine1" placeholder={formatMessage({id: "ADDRESS_LINE_1", defaultMessage: "ADDRESS_LINE_1"})}/>
      <Field type="text" name="addrLine2" placeholder={formatMessage({id: "ADDRESS_LINE_2", defaultMessage: "ADDRESS_LINE_2"})}/>
      <Field onKeyUp={(event) => getZipCity(event.currentTarget.value)} type="text" name="zip" placeholder={formatMessage({id: "ADDR_ZIP_CODE", defaultMessage: "ADDR_ZIP_CODE"})}/>
      <Field type="text" name="city" placeholder={formatMessage({id: "ADDR_CITY", defaultMessage: "ADDR_CITY"})}/>
      <Button type="button" onClick={() => setRegisterStep(RegisterStep.INDEX)}>
        {formatMessage({id: "back", defaultMessage: "Tilbake"})}
      </Button>
      <Button type="button" onClick={() => customerValidation({values, validateName: true})}
        disabled={!values.companyName || !values.organizationNo || !values.addrLine1 || !values.zip || !values.city}
      >
        {formatMessage({id: "next", defaultMessage: "Neste"})}
      </Button>
      <Comp
        title=""
        standardBtn
        confirmText={formatMessage({id: "yes", defaultMessage: "Yes"})}
        body={
          <React.Fragment>
          <span>{`${values.companyName} ${formatMessage({id: "duplicateCompanyName", defaultMessage: "already exists and is in active use. Are you sure you want to create another instance of"})} ${values.companyName}?`}</span>
          <br/>
          <span><a target="_blank" href="https://servicedesk.sensio.no/nb-NO/support/home">{formatMessage({id: "toSupport", defaultMessage: "To support"})}</a></span>
          </React.Fragment>
        }
        confirmFunc={() => {
          func(false)
          customerValidation({values, validateName: false});
        }}
      />
    </React.Fragment>
  )
})

interface RegisterFirmDetailsProps{
  setRegisterStep: SetRegisterStep;
  registerCustomer: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToDetailsProps = {
  setRegisterStep,
  registerCustomer
}

export const RegisterFirmDetails = connect(null, mapDispatchToDetailsProps)(({ setRegisterStep, registerCustomer }: RegisterFirmDetailsProps) => {

  const { values } = useFormikContext<RegisterFirmForm>();
  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      <h4>{formatMessage({id: "firstAdministratorGo", defaultMessage: "Register ny bruker"})}</h4>
      <Field type="text" name="username" placeholder={formatMessage({id: "emailGo", defaultMessage: "emailGo"})}/>
      <Field type="password" name="passwordClear" placeholder={formatMessage({id: "password", defaultMessage: "password"})}/>
      <Field type="password" name="confirmPassword" placeholder={formatMessage({id: "confirmPasswordPro", defaultMessage: "confirmPasswordPro"})}/>
      <Field type="text" name="firstName" placeholder={formatMessage({id: "firstName", defaultMessage: "firstName"})}/>
      <Field type="text" name="lastName" placeholder={formatMessage({id: "lastName", defaultMessage: "lastName"})}/>
      <Field type="text" name="mobile" placeholder={formatMessage({id: "mobile", defaultMessage: "mobile"})}/>
      <Button type="button" onClick={() => setRegisterStep(RegisterStep.FIRM_ADDRESS)}>
        {formatMessage({id: "back", defaultMessage: "Tilbake"})}
      </Button>
      <Button type="button" onClick={() => registerCustomer(values)}
        disabled={!values.firstName || !values.lastName || !values.mobile || !values.username || !values.passwordClear || !values.confirmPassword}
      >
        {formatMessage({id: "next", defaultMessage: "Neste"})}
      </Button>
    </React.Fragment>
  )
})
