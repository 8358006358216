import { combineReducers } from "redux";
import { RootState } from "../types";
import text from "./text";
import country from "./country";
import feedbackText from "./feedbackTexts";

export default combineReducers<RootState>({
  text,
  country,
  feedbackText
});