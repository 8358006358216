import {Policies} from "./types";
import {PolicyNameEnum} from "../PolicyNameEnum";
import {GlobalRoles} from "../GlobalRoles";
import { CustomRoleWithPolicy } from "@Safemate/Model/Policy";
import { AllPermissions } from "./Provider";

type AccessToCustomer = (policies: CustomRoleWithPolicy, policyToCheck: null | PolicyNameEnum, allowedGlobals: string[], information?: string[]) => boolean;
type AccessToCustomerPolicies = (policies: CustomRoleWithPolicy, policyToCheck: PolicyNameEnum[], allowedGlobals: string[], information?: string[]) => boolean;
type AccessToUser = (policies: CustomRoleWithPolicy[], policyToCheck: PolicyNameEnum, allowedGlobals: Array<GlobalRoles>, information?: Array<string>) => boolean;

export const accessToCustomer: AccessToCustomer = (policies, policyToCheck, allowedGlobals, information) => {

  if(!policies)
    return false;

  if (allowedGlobals.includes(policies.roleName))
    return true;

  if( policyToCheck === null ){
    return false;
  }

  const currentPolicy = policies.policies && policies.policies[policyToCheck] && policies.policies[policyToCheck].rolePolicies;

  if(!currentPolicy)
    return false;

  if(currentPolicy.status){

    if(information && information.includes(currentPolicy.information))
      return true;

    if(!information)
      return true;

  }
  return false;
}

export const accessToCustomerPolicies : AccessToCustomerPolicies = (policies, policiesToCheck, allowedGlobals, information) => {
  let access = false;
  if( policiesToCheck && policiesToCheck.length > 0){
    for( let index= 0; index < policiesToCheck.length; index++ ){
      if(accessToCustomer(policies, policiesToCheck[index], allowedGlobals, information)){
        access = true;
        break;
      }
    }
  }
  return access;
}


export const accessToUser = ( perms: AllPermissions, custIds: number[], information?: string[] ) => {
  let permission = false;
  custIds.forEach(custId => {
    const customerPermissions = perms.customerPermissions[custId];
    if(information){
      information.forEach(info => {
        permission = permission || customerPermissions[info];
      })
    }
    else{
      permission = permission || customerPermissions.all;
    }
  })
  return permission;
}

export const checkPermissionsForCustomer = (permissions: Map<PolicyNameEnum, AllPermissions>, policy: PolicyNameEnum, custId: number, info?: string) => {
  if(!permissions.get(policy))
    return false;

  if(!permissions.get(policy).customerPermissions)
    return false;

  if(!permissions.get(policy).customerPermissions[custId])
    return false;

  if(info){
    return permissions.get(policy).customerPermissions[custId][info];
  }
  return permissions.get(policy).customerPermissions[custId].all;
}
