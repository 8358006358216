import React from 'react';
import styled from 'styled-components';
import Modal from '../../../Safemate/Modals';
import Logo from '../../../../assets/images/logo/logo.png';
import SafecallLogo from '../../../../assets/images/logo/safecall-logo.png';
import { MarketEnum } from '../../../Safemate/MarketEnum';
import {AppState} from "@Safemate/Store/types";
import { connect } from 'react-redux';

export const LogoContainer = styled.div<{isSafecall: boolean}>`
  background: url(${props => (Logo)}) center
    center no-repeat;
  height: 100px;
  max-width: 500px;
`;

export const BodyContent = styled.div`
  background: #fff;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  padding: 30px;
  max-width: 500px;
`;

export const Header = styled.h4`
  text-align: center;
  color: #5f6a6d;
  margin: 10px 0 20px 0;
  font-weight: 300;
  font-size: 22px;
  padding: 0;
  display: block;
`;

export const BodyText = styled.p`
  text-align: center;
  color: #889399;
`;

export const StyledButton = styled.button`
  text-shadow: none;
  transition: color 0.3s;
  height: 50px;
  width: 100%;
  font-size: 12px;
  vertical-align: middle;
  outline: none;
  background-color: ${props =>
    props.theme.colors.accent ? props.theme.colors.accent : 'white'};
  color: ${props => props.theme.colors.accentContrast};
  margin-top: 20px;
  margin-right: 15px;
`;

interface HeaderContentProps{
  market: MarketEnum;
}

const mapStateToProps = ({ appSettings: {market} }: AppState) => {
  return {
    market
  }
}

export const HeaderContent = connect(mapStateToProps)(({market}: HeaderContentProps) => {
  const isSafecall = market === MarketEnum.SAFECALL;
  return <LogoContainer isSafecall={false} />;
});

export const Body = props => {
  return (
    <BodyContent>
      <Header>{props.headerText}</Header>

      <BodyText>{props.bodyText}</BodyText>

      <StyledButton
        color="#f5a623"
        id="unsaved-cancel"
        className="btn"
        onClick={props.handleCancel}
      >
        {props.translations
          ? props.translations('universalCancelBtn', 'Cancel')
          : 'Avbryt'}
      </StyledButton>
      <StyledButton
        color="#f5a623"
        id="unsaved-continue"
        className="btn"
        onClick={props.handleContinue}
      >
        {props.translations
          ? props.translations('universalProceedBtn', 'Proceed')
          : 'Fortsett'}
      </StyledButton>
    </BodyContent>
  );
};

const UnsavedModal = ({ display, handleCancel, handleContinue, ...props }) => {
  return (
    <Modal
      display={display}
      headerContent={<HeaderContent />}
      bodyContent={
        <Body
          handleCancel={handleCancel}
          handleContinue={handleContinue}
          {...props}
        />
      }
      {...props}
    />
  );
};

export default UnsavedModal;
