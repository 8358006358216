export enum LabelEnum{
  DELETE = "deleteActionTooltip",
  LOG = "log",
  EDIT = "edit",
  SUB_UNITS = "subUnits",
  USER_DELETED = "applicationUserDeleted",
  USER_SAVED = "applicationUserSaved",
  DATE_FROM = "fromTitle",
  DATE_TO = "toTitle",
  DEACTIVATE_USER = "deactivateUser",
  TEMPORARY_DEACTIVATE_USER = "temporaryDeactivate",
  DEACTIVATE_CURRENT_USER = "deactivateThisUser",
  USER_CREATED_BY_NAME = "userCreatedByName",
  FIRST_ADMIN_CUSTOMER = "firstAdminCustomer",
  DEACTIVATED_BY_NAME = "deactivatedByUser",
  DEACTIVATED_DATE = "deactivatedByUserDate",
  PERSONAL_INFORMATION = "myPageCrumb",
  BACK = "back",
  SELECT_LDAP_USER = "selectLdapUser",
  USER_ROLE = "userRole",
  ROLE = "role",
  SELECT_ROLE = "selectRole",
  TEMPORARY_ROLE = "temporaryRole",
  SELECT_CUSTOMER = "selectSuborg",
  TRAINING = "training",
  USER_TRAINED = "userTraining",
  USER_TRAINED_BY = "trainingApprovedBy",
  INFORMATION = "informationText",
  PERSONALIA = "personalia",
  ACCESS_LEVEL = "accessLevel",
  FIRST_NAME = "userFirstname",
  LAST_NAME = "userLastname",
  IDENTITY = "ident",
  PHONE_NUMBER =  "userPhone",
  EMAIL =  "userEmail",
  SHOW_ALL = "showAll",
  USER_ADMIN_HEADER = "userAdminFor",
  ADD_NEW_USER = "addNewUser",
  ROLE_OVERVIEW = "roleOverview",
  SEARCH = "search",
  SHOW_ADVANCED = "showAdvanced",
  EMAIL_AS_USERNAME = "emailAsUsername",
  SEND_NEW_PASSWORD = "sendNewPassword",
  ALPHABETICAL = "rolesAlphabetical",
  LAST_LOGIN = "rolesLastLogin",
  NATIONAL_IDENTITY_NUMBER =  "nationalIdentityNumber",
  ACTIVE_USERS = "activeUsers",
  DELETED_USERS = "deletedUsers"
}
