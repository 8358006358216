import  initializationSaga from './initialization';
import { all, fork } from 'redux-saga/effects';
import filterSaga from './filtering';
import alarmAnalysis from './alarmAnalysis';
import deviceSaga from './device';

function* DevicesSaga(){
  yield all([
    fork(initializationSaga),
    fork(filterSaga),
    fork(alarmAnalysis),
    fork(deviceSaga),
  ])
}
export default DevicesSaga;
