import Dropdown from "@Elements/Dropdown/Dropdown";
import * as React from "react";
import { useIntl } from "react-intl";
import { DropdownWrapper, DropdownTitle } from "./styles";
import { Option } from "@Elements/Dropdown/types";
import { ISensorDTO } from "../Sensor/types";

interface SensorSelectProps{
  sensors: Option[];
  sensor: ISensorDTO | null;
  setSelected: (sensor: ISensorDTO) => void;
}

const SensorSelect = ({ sensors, sensor, setSelected }: SensorSelectProps) => {

  const { formatMessage } = useIntl();

  return(
    <DropdownWrapper>
      <DropdownTitle>{formatMessage({id:"sensor", defaultMessage: "Sensor"})}:</DropdownTitle>
      <Dropdown
        title="Sensor:"
        options={sensors}
        initial={sensor}
        onChange={( opt: Option | Option[] ) => {
          const value = (opt as Option).value;
          setSelected(value);
        }}
      />
    </DropdownWrapper>
  )
}

export default SensorSelect;