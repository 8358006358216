import * as React from "react";
import {connect} from "react-redux";
import {
  IndoorLocationSettings
} from "./types";
import {DeviceComponents} from "./tabDefinition";
import {defaultSettingHorizontalTabWrapper, mapMainTabs} from "./TabContent";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import {AllPermissions, PolicyContext} from "../../Policy/Provider";
import {DeviceHardwareEnum} from '../../DeviceHardwareEnum';
import {useIntl} from "react-intl";
import { AppState } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";

interface SettingsProps{
  indoorLocationSettings: IndoorLocationSettings;
  selectedDeviceHardwareId: number;
  customer: ICustomerWithPolicy;
}


const SettingsComp = ({ indoorLocationSettings, customer, selectedDeviceHardwareId }: SettingsProps) => {

  const categoryKey = DeviceComponents.has(selectedDeviceHardwareId) ? selectedDeviceHardwareId : DeviceHardwareEnum.DEFAULT;
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const { formatMessage } = useIntl();

  return(
    <div style={{paddingBottom: "1em"}}>
      { defaultSettingHorizontalTabWrapper(mapMainTabs(categoryKey, permissions, indoorLocationSettings, customer.customerId, formatMessage)) }
    </div>)
}

const mapStateToProps = ({ defaultSettings: {deviceHardware, settings, customer: {selectedCustomer}}}: AppState) => {
  return{
    indoorLocationSettings: settings.indoorLocationSettings,
    selectedDeviceHardwareId: deviceHardware.selectedDeviceHardwareId,
    customer: selectedCustomer,
  }
};

export default connect(mapStateToProps, null)(SettingsComp);
