import { Checkbox } from "@Elements/CheckboxFormik";
import confirmModal from "@Safemate/Modals/confirmModal";
import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { updateOverride } from "../Store/routines";
import { RootState } from "../Store/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { EventSetupForm } from "./types";
import { AppState } from "@Safemate/Store/types";
import { Device } from "@Safemate/Settings/types";

interface DefaultEventSetupCheckProps{
  device: Device;
  updateOverride: Routine<ActionFunctionAny<Action<any>>>;
  isPrivate: boolean;
}

const mapStateToProps = ({ appData: {isPrivate}, eventSetup: {device: { device }}}: AppState) => {
  return {
    device,
    isPrivate
  }
}

const mapDispatchToProps = {
  updateOverride
}

const DefaultEventSetupCheck = connect(mapStateToProps, mapDispatchToProps)(
  withFormWrapper<EventSetupForm, DefaultEventSetupCheckProps>(({ formik: { values: { eventSetups } }, isPrivate, device, updateOverride }) => {

  const { formatMessage } = useIntl();

  if(isPrivate || !device.enableDefaultEventSetup) return null;

  const { Comp: Modal, func: displayModal } = confirmModal();

  const handleChange = () => {
    if(device.overrideDefaultEventSetup && eventSetups.length > 0){
      displayModal(true);
    }
    else{
      save();
    }
  }

  const save = () => {
    updateOverride({
      deviceId: device.deviId,
      value: !device.overrideDefaultEventSetup
    })
  }

  return(
    <React.Fragment>
    <Checkbox
      label={formatMessage({id: "overrideDefault", defaultMessage: "Overstyr standard varslingsliste"})}
      value={device.overrideDefaultEventSetup}
      id="override-checkbox"
      onChange={handleChange}
    />
    <Modal
      standardBtn
      title={formatMessage({id: "overrideDefault", defaultMessage: "Overstyr standard varslingsliste"})}
      body={`${formatMessage({id: "confirmOverrideEventSetupOne", defaultMessage: "You are about to disable individual event setup for"})} ${device.deviceName} (${device.deviceSerialNumber}) ${formatMessage({id: "confirmOverrideEventSetupTwo", defaultMessage: "and apply default event setup? Are you sure you want to continue?"})}`}
      confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
      confirmFunc={() => {
        save();
        displayModal(false);
      }}
    />
    </React.Fragment>
  )
}))

export default DefaultEventSetupCheck;