import * as React from "react";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { RootState } from "../Store/types";
import { DeviceComponents, getEventConfigTabs } from "../tabDefinition";
import { Tab, VerticalRouteTabs } from "@Elements/Tabs";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";
import { SettingEnum } from "../settingEnum";
import { fetchInactivity, fetchSensors } from "../Store/routines";
import { ISensorDTO } from "../Sensor/types";
import { AppState } from "@Safemate/Store/types";

interface EventConfigProps{
  deviId: number;
  dehaId: number;
  sensors: ISensorDTO[];
  inactivityFunction: boolean;
  fetchSensors: Routine<ActionFunctionAny<Action<any>>>;
  fetchInactivity: Routine<ActionFunctionAny<Action<any>>>;
}

const EventConfig = ({ deviId, dehaId, sensors, inactivityFunction, fetchSensors, fetchInactivity }: EventConfigProps) => {

  React.useEffect(() => {
    fetchSensors(deviId);
    fetchInactivity(deviId);
  }, [deviId, dehaId])

  const components: Map<SettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) ? DeviceComponents.get(dehaId)! : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;
  const tabs: (JSX.Element | null)[] = getEventConfigTabs(dehaId, sensors, inactivityFunction).map(({tab, comp, title}) => {

    const contains = components.get(tab);

    if(contains){
      return (
        <Tab
          key={tab}
          renderTitle={() => title}
          name={tab}
        >
          {comp}
        </Tab>
      )
    }
    return null;
  }).filter(tab => tab);

  if(tabs.length < 1) return null;

  return(
    <VerticalRouteTabs>
      {tabs}
    </VerticalRouteTabs>
  )
}


const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, sensor: { sensors }, general: { inactivity: { inactivityFunction }}}}: AppState) => {
  return {
    deviId,
    dehaId,
    sensors,
    inactivityFunction
  }
}

const mapDispatchToProps  = {
  fetchSensors,
  fetchInactivity
};

export default connect(mapStateToProps, mapDispatchToProps)(EventConfig);
