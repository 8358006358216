import {initialize} from '../routines';
import {PaymentProfileState} from "../types";
import {Reducer} from "redux";

const paymentProfileState: PaymentProfileState = {
  paymentProfiles: [],
};

const individualPaymentProfiles: Reducer<PaymentProfileState> = (state: PaymentProfileState = paymentProfileState, action: any) => {
  switch (action.type) {

    case initialize.SUCCESS:
      return {
        ...state,
        paymentProfiles: action.payload.paymentProfiles
      };
      
    default:
        return state;
  }
};

export default individualPaymentProfiles;
