import { AppAction } from "../../Store/types";
import { LogActionTypes } from "./actionTypes";

export type SetFilter = (filter: number[]) => AppAction<number[]>;

export const setFilter: SetFilter = (filter) => ({
  type: LogActionTypes.SET_FILTER,
  payload: filter
})

export type ResetState = () => AppAction<null>;

export const resetState: ResetState = () => ({
  type: LogActionTypes.RESET_STATE,
  payload: null
})
