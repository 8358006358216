import { Button } from "react-bootstrap";
import styled from "styled-components"

export const TimeInput = styled.input`
  background: none;
  border: ${props => props.theme.colors.border};
  border-radius: 4px;
  color: ${props => props.theme.colors.textPrimary};
  outline: none;
  text-align: center;
  transition: border-color 0.3s;
  min-width: 10px;
  max-width: 100%;
  width: 100px;
  height: 100px;

  &:focus {
    border-color: ${props => props.theme.colors.textFocus};
    transition: border-color 0.3s;
  }
`;

export const StyledButton = styled(Button)`
  transition: color 0.3s;
  margin: 5px;
`;
