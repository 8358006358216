import * as React from "react";
import Modal from "react-modal";
import { useIntl } from "react-intl";
import { Formik, Form } from "formik";
import { DatabaseCountry, TranslationEventBody } from "./Store/types";
import { addTexts } from "./Store/routines";
import { ModalTitle, ModalFooter, ConfirmBtn, CancelButton, Input } from "./styles";
import { ThemeProps, withTheme } from "styled-components";
import { ITheme } from "@Theme/types";
import { EditModalBody, FormValues } from "./EditModal";

interface EventModalProps extends ThemeProps<ITheme> {
  open: boolean;
  setModalState: (open: boolean) => void;
  event: TranslationEventBody;
  countries: DatabaseCountry[];
}

const EventModal = withTheme(({open, setModalState, theme, event, countries}: EventModalProps) => {

  Modal.setAppElement("#react");

  const { formatMessage } = useIntl();

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setModalState(false)}
      style={
        {
          content: {
            maxWidth: "80%",
            width: "750px",
            height: "fit-content",
            position: "unset",
            background: theme.colors.backgroundPrimary,
            color: ""
          },
          overlay:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }
        }
      }
    >
      {event.labels.map(label => {

        const mappedValues: FormValues = {};

        countries.forEach(country => {
          mappedValues[country.languageCode] = null;
        })

        event.texts.filter(text => text.label === label).forEach(text => {
          mappedValues[text.languageCode] = {...text};
        })

        return(
          <React.Fragment>
            <ModalTitle>
              <h2>Edit texts for {label}</h2>
            </ModalTitle>
            <Formik
              initialValues={mappedValues}
              enableReinitialize
              onSubmit={(values) => {
                const textsToSave = [];
                for(const property in values){
                  if(values[property])
                    textsToSave.push(values[property]);
                }
                addTexts(textsToSave);
              }}
            >
              {({ values, setFieldValue }) =>
                <Form>
                  <React.Fragment>
                    <EditModalBody label={label} values={values} setFieldValue={setFieldValue}/>
                    <ModalFooter>
                      <ConfirmBtn type="submit">
                        {formatMessage({ id: "save", defaultMessage: "Lagre" })}
                      </ConfirmBtn>
                      <CancelButton
                        onClick={() => setModalState(false)}>
                        {formatMessage({ id: "cancel", defaultMessage: "Avbryt" })}
                      </CancelButton>
                    </ModalFooter>
                  </React.Fragment>
                </Form>}
            </Formik>
          </React.Fragment>)
      })}
    </Modal>
  )
})

export default EventModal;