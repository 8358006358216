import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {InnerFrame} from "./styled";
import CustomerList from "./customerList";
import {LabelEnum} from "../LabelEnum";
import {Frame} from "../../styles";

const CustomerFrame = (props: WrappedComponentProps) =>
    <Frame>
      <h2>{props.intl.formatMessage({id: LabelEnum.SUBUNITS, defaultMessage: "Underavdelinger"})}</h2>
      <InnerFrame>
        <CustomerList/>
      </InnerFrame>
    </Frame>

export default injectIntl(CustomerFrame);
