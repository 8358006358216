import React from 'react';
import { Field, connect } from 'formik';
import { Collapse } from "react-collapse";
import { CheckBoxRow, Tooltip } from '../layoutComp';
import { userAdminTooltips as Tips } from '../tooltips';
import { Radio, RadioGroup } from '../../../Elements/RadioFormik';

import {
  DisplayWrapper,
  Container,
  TabHeader,
  Content,
  SubHeader,
  CapitalizedString,
  CollapsedWrapper
} from '../WrapperStyles';
import { Role } from '../Store/types';
import { useIntl } from 'react-intl';
import Switch from '../../../Elements/Switch';

const  UserAdmin = connect<{}, Role>(({formik: { values, setFieldValue }}) => {

  const enabled = !!values.policies.userAdmin.rolePolicies.status
  const { formatMessage } = useIntl();

  return (
    <CollapsedWrapper>
      <TabHeader>
        <CapitalizedString>
          {formatMessage({id: 'userAdminRoleEditor', defaultMessage: 'User admin'})}
        </CapitalizedString>
        <Switch
          checked={enabled}
          onChange={(value: boolean) => {
            setFieldValue("policies.userAdmin.rolePolicies.status", value);
          }}
        />
        &nbsp;
        <Tooltip
          id="userAdmin"
          tooltip={formatMessage({
            id: Tips.userAdmin,
            defaultMessage: Tips.userAdminContent
          })}
        />
      </TabHeader>
      <Collapse isOpened={enabled}>
          <SubHeader>
            <CapitalizedString>
              {formatMessage({ id: 'allowedToTitle', defaultMessage: 'Allowed to'})}:
            </CapitalizedString>
            <Tooltip
              id="allowedTo"
              tooltip={formatMessage({
                id: Tips.allowedTo,
                defaultMessage: Tips.allowedToContent
              })}
            />
          </SubHeader>
        <Content endSection>
          <RadioGroup disabled={false}>
            <Field
              component={Radio}
              name="policies.userPrivs.rolePolicies.information"
              radioValue="all"
              id="policies.userPrivs.rolePolicies.information.all"
              label={formatMessage({ id: 'allAccess', defaultMessage: 'Add/delete/edit'})}
            />
            <Field
              component={Radio}
              name="policies.userPrivs.rolePolicies.information"
              radioValue="add"
              id="policies.userPrivs.rolePolicies.information.add"
              label={formatMessage({ id: 'addAccess', defaultMessage: 'Add'})}
            />
            <Field
              component={Radio}
              name="policies.userPrivs.rolePolicies.information"
              radioValue="read"
              id="policies.userPrivs.rolePolicies.information.read"
              label={formatMessage({ id: 'read', defaultMessage: 'Read only'})}
            />
          </RadioGroup>
        </Content>
        <CheckBoxRow
          values={values.policies.auditLog.rolePolicies.status}
          name="policies.auditLog.rolePolicies.status"
          label={formatMessage({ id: 'auditLogRight', defaultMessage: 'View audit log'})}
          tooltip={formatMessage({ id: Tips.auditLog, defaultMessage: Tips.auditLogContent})}
        />
        <CheckBoxRow
          values={values.policies.deletedUsers.rolePolicies.status}
          name="policies.deletedUsers.rolePolicies.status"
          label={formatMessage({ id: 'deletedUserRight', defaultMessage: 'View deleted users'})}
          tooltip={formatMessage({
            id: Tips.deletedUsers,
            defaultMessage: Tips.deletedUsersContent
          })}
        />
        <CheckBoxRow
          values={values.policies.lastLogin.rolePolicies.status}
          name="policies.lastLogin.rolePolicies.status"
          label={formatMessage({
            id: 'lastLoginRight',
            defaultMessage: 'View last login for users'
          })}
          tooltip={formatMessage({ id: Tips.lastLogin, defaultMessage: Tips.lastLoginContent})}
        />
        <CheckBoxRow
          values={values.policies.activate.rolePolicies.status}
          name="policies.activate.rolePolicies.status"
          label={formatMessage({
            id: 'deactivateUserRight',
            defaultMessage: 'Deactivate users'
          })}
          tooltip={formatMessage({ id: Tips.activate, defaultMessage: Tips.activateContent})}
        />
        <CheckBoxRow
          values={values.policies.promoteTemp.rolePolicies.status}
          name="policies.promoteTemp.rolePolicies.status"
          label={formatMessage({
            id: 'promoteTempRight',
            defaultMessage: 'Promote users temporary'
          })}
          tooltip={formatMessage({
            id: Tips.promoteTemp,
            defaultMessage: Tips.promoteTempContent
          })}
        />
      </Collapse>
    </CollapsedWrapper>
  );
});

export default UserAdmin;
