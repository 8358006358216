import React from 'react';
import extendIcon from '../extendIcon';

const Static = props => (
  <svg viewBox="0 0 128 128" width="1em" height="1em" {...props}>
    <g>
      <g>
        <path
          d="M103.819,18.854L93.039,29.63l-6.536-6.537c-1.074-1.073-2.812-1.073-3.887,0c-1.073,1.073-1.073,2.812,0,3.886
			l8.481,8.48c0.537,0.538,1.239,0.807,1.941,0.807c0.704,0,1.406-0.269,1.943-0.807l12.723-12.721c1.073-1.073,1.073-2.812,0-3.887
			C106.634,17.779,104.891,17.779,103.819,18.854z"
        />
        <path
          d="M94.427,1.339c-15.913,0-28.859,12.947-28.859,28.859c0,15.914,12.946,28.86,28.859,28.86
			c15.912,0,28.862-12.946,28.862-28.86C123.289,14.286,110.339,1.339,94.427,1.339z M94.427,50.813
			c-11.367,0-20.612-9.246-20.612-20.615c0-11.367,9.245-20.613,20.612-20.613c11.365,0,20.614,9.246,20.614,20.613
			C115.041,41.566,105.792,50.813,94.427,50.813z"
        />
        <circle cx="27.206" cy="15.609" r="12.367" />
        <path
          d="M78.505,70.501H50.434c-0.997,0-2.769-1.382-3.011-2.348l-7.023-27.945c-1.479-5.889-7.454-9.46-13.342-7.983
			c-5.89,1.48-9.462,7.454-7.984,13.342l7.024,27.943c2.718,10.819,13.179,18.978,24.336,18.978h22.574v26.267
			c0,4.552,3.691,8.245,8.247,8.245c4.553,0,8.244-3.691,8.244-8.245V81.494C89.499,75.425,84.576,70.501,78.505,70.501z"
        />
        <path
          d="M61.605,97.987H48.001c-12.337,0-25.356-10.328-28.43-22.554l-6.45-25.669c-0.556-2.21-2.801-3.559-5.002-2.993
			c-2.208,0.552-3.549,2.794-2.993,5.002l6.451,25.669c3.986,15.875,20.329,28.791,36.424,28.791h13.604
			c2.277,0,4.123-1.848,4.123-4.123C65.729,99.832,63.882,97.987,61.605,97.987z"
        />
      </g>
    </g>
  </svg>
);

export default extendIcon(Static);
