import * as React from "react";
import { Grid, Col, Row, Button } from "react-bootstrap";
import { Formik, Form } from "formik";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import styled from "styled-components";
import { Header, DeviceComponents } from "../tabDefinition";
import { SettingEnum } from "../settingEnum";
import { DeviceHardwareEnum } from "../../../Safemate/DeviceHardwareEnum";
import { connect } from "react-redux";
import { fetchSoundLight, saveSoundLight } from "../Store/routines";
import { SoundLightSettings } from "../types";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { SaveButton, CancelButton } from "../../DefaultSettings/Settings/styled";
import { ButtonContainer, Footer } from "../General/Tabs/styled";
import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { Layout } from "../LayoutEnum";
import { AppState } from "@Safemate/Store/types";

const PaddedCol = styled(Col) <{ left?: boolean, right?: boolean, addBorder?: boolean }>`

    padding: 0 0;

`;

const Container = styled(Grid)`

    padding-top: 25px;

`

export interface SoundLight extends WrappedComponentProps {
  settings: SoundLightSettings;
  fetchSettings: Routine<ActionFunctionAny<Action<any>>>;
  saveSoundLight: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  dehaId: number;
  layout: Layout;
}

const ComponentWrapper = styled.div`

    margin-bottom: 1em;

`;

const SoundLight = injectIntl(({ intl: { formatMessage }, settings, fetchSettings, saveSoundLight, deviId, dehaId, layout }: SoundLight) => {

  const components: Map<SettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) ? DeviceComponents.get(dehaId)! : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  React.useEffect(() => {
    fetchSettings(deviId)
  }, [deviId])

  const addBorder = components.has(SettingEnum.LIGHT) && components.has(SettingEnum.SOUND);

  return (
    <Formik
      onSubmit={(values) => {
        saveSoundLight({
          deviceId: deviId,
          settings: values
        })
      }}
      enableReinitialize
      initialValues={settings}
    >
      {({dirty, setValues}) => (
          <Container fluid>
            <Form>
              <Row>
                <div>
                {components.has(SettingEnum.LIGHT) &&
                  <PaddedCol addBorder={addBorder} left md={6}>
                    <Header message={{ id: "light", defaultMessage: "Lys" }} />
                    {components.get(SettingEnum.LIGHT)?.map(comp => {
                      return (
                        <ComponentWrapper>
                          {React.cloneElement(comp, {layout})}
                        </ComponentWrapper>
                      )
                    })}
                  </PaddedCol>
                }
                {components.has(SettingEnum.SOUND) &&
                  <PaddedCol addBorder={addBorder} right md={6}>
                    <Header message={{ id: "sound", defaultMessage: "Lyd" }} />
                    {components.get(SettingEnum.SOUND)?.map(comp => {
                      return (
                        <ComponentWrapper>
                          {React.cloneElement(comp, {layout})}
                        </ComponentWrapper>
                      )
                    })}
                  </PaddedCol>
                }
                </div>
              </Row>
              <Footer>
                <ButtonContainer disabled={!dirty}>
                  <CancelButton disabled={!dirty} onClick={() => setValues(settings)}>
                    {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
                  </CancelButton>
                  <SaveButton disabled={!dirty} type="submit">
                    {formatMessage({id: "save", defaultMessage: "Lagre"})}
                  </SaveButton>
                </ButtonContainer>
              </Footer>
            <UnsavedCheck/>
            </Form>
          </Container>
      )}
    </Formik>
  )
})

const mapStateToProps = ({ settings: {initialization: {layout}, soundLight: { settings }, initialization: {deviId}, device: { device: { dehaId } }}}: AppState) => {
  return {
    settings,
    deviId,
    dehaId,
    layout
  }
}

const mapDispatchToProps = {
  fetchSettings: fetchSoundLight,
  saveSoundLight
};


export default connect(mapStateToProps, mapDispatchToProps)(SoundLight);