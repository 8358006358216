import _axios, {AxiosInstance} from 'axios';
import { BillingSettings } from '../Form/types';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getFirm = async () => {
  const url: string = `/firm/firmforbilling`;
  const response = await axios.get(url);
  return response.data;
}

export const getPaymentOptions = async (custId: number, parent: number) => {
  const url: string = `/paymentProfile/choices/${custId}/${parent}`;
  const response = await axios.get(url);
  return response.data;
}

export const saveForm = async (values: BillingSettings) => {
  const url: string = `/firm/save/${values.pptyId}`;
  const response = await axios.post(url, values);
  return response.data;
}

export const getPostCity = async (pnr: string) => {
  const url = `https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=www.safemate.no&pnr=${pnr}`;
  const response = await axios.get(url);
  return response.data;
}

export const getMarketProgId = async () => {
  const url: string = `/user/getMarketProgId`;
  const response = await axios.get(url);
  return response.data;
}