import {call, put, race, take, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import * as Routines from '../routines';
import {AppAction, Customer} from "../types";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {GlobalRoles} from "../../../GlobalRoles";
import {accessToCustomer} from "../../../Policy/rightsUtil";
import { SiteTypeEnum } from '../../../SiteTypeEnum';
import { feedbackFailure } from '../../../Feedback/reducer';


export interface IRace<T> {
  success: AppAction<T>;
  failure: any;
}


function* initialize(action: AppAction<null>){
  try {
    yield put(Routines.fetchCustomersForList());
    const {success: forListSuccess, failure: forListFailure}: IRace<Array<Customer>> = yield race({
      success: take(Routines.fetchCustomersForList.SUCCESS),
      failure: take(Routines.fetchCustomersForList.FAILURE)
    });

    yield put(Routines.fetchCustomersForForm());
    const {success: forFormSuccess, failure: forFormFailure} = yield race({
      success: take(Routines.fetchCustomersForForm.SUCCESS),
      failure: take(Routines.fetchCustomersForForm.FAILURE)
    });

    if (forListSuccess && forFormSuccess) {
      const selectedFirm: Customer = forListSuccess.payload.find(customer =>
        accessToCustomer(customer.policies, PolicyNameEnum.SUB_ORGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM])) as Customer;

      // Fetch alarm help information from the top level customer which will always be loaded as the first element in the list
      yield put(Routines.initializeAlarmHelper.trigger(forListSuccess.payload[0].customerId));

      yield put(Routines.selectCustomer.trigger(selectedFirm));
      const {success: selectCustomerSuccess, failure: selectCustomerFailure}: IRace<Array<null>> = yield race({
        success: take(Routines.selectCustomer.SUCCESS),
        failure: take(Routines.selectCustomer.FAILURE),
      });
      if(selectCustomerSuccess) {
        yield put(Routines.initialize.success());
      }
      else{
        yield put(Routines.initialize.failure());
      }
    }
    else{
      yield put(Routines.initialize.failure());
    }
  }
  catch(e){
    console.log(e)
    yield put(Routines.initialize.failure());
  }
}

function* initializeAlarmHelper(action: AppAction<number>){
  try{
    const alarmOptions = yield call(Api.getAlarmOptions, action.payload);
    yield put(Routines.initializeAlarmHelper.success(alarmOptions));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.initializeAlarmHelper.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.initializeAlarmHelper.TRIGGER, initializeAlarmHelper);
}

export default initializationSaga;



