import * as React from "react";
import RoleManagerSaga from "./Store/Saga";
import RoleManagerReducer from "./Store/Reducer";
import configureProvider from "../DefaultProvider";
import { RootState } from "./Store/types";
import { initialize } from "./Store/routines";
import Spinner from "../Spinner";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";
import styled from "styled-components";
import { useIntl } from "react-intl";
import RoleElement from "./elements/roleElement";
import { mapHeaders } from "./utils";
import { HeaderTr, Table, Th, TranslatedSpan } from "./elements/tableLayout";
import SafemateHeader from "../Header";
import { Button } from "react-bootstrap";
import { Role } from "@Safemate/RoleEditor/Store/types";
import { AppState } from "@Safemate/Store/types";
const Wrap = styled.div`
  position: relative;
`

const ButtonWrapper = styled.span`
  margin-left: auto;
`;

interface RoleManagerProps{
  initialized: boolean;
  roles: Role[]
  isPartner: boolean;
  initialize: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ appData: {isPartner}, roleManager: {initialization: { initialized }, role: { roles }}}: AppState) => {
  return{
    initialized,
    roles,
    isPartner
  }
}

const mapDispatchToProps = {
  initialize
}

const RoleManager = connect(mapStateToProps, mapDispatchToProps)(({ initialize, isPartner, initialized, roles }: RoleManagerProps) => {
  
  React.useEffect(() => {
    initialize();
  }, [])
  
  const { formatMessage } = useIntl();

  if(!initialized)
    return (
      <Wrap>
        <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
      </Wrap>
    )


  const header = mapHeaders(roles[0], isPartner, formatMessage);

  header.unshift({
    id: 0,
    name: formatMessage({id: 'roleTitle', defaultMessage:'Role name'}),
    status: false,
    roleName: ""
  });
  header.push({
    id: 0,
    name: formatMessage({id: 'thAction', defaultMessage:'Handlinger'}),
    status: false,
    roleName: "",
    align: "right"
  });

  return(
    <div>
      <SafemateHeader
        icon={null}
        title={formatMessage({id: "roleOverview", defaultMessage: "Rolle oversikt"})}
      >
        <ButtonWrapper>
          <Button
            onClick={() => {
              location.hash = 'role_editor';
            }}
          >
            {formatMessage({id:'addNewRole', defaultMessage: 'Add new role'})}
          </Button>
        </ButtonWrapper>
      </SafemateHeader>
      <Table>
        <thead>
          <HeaderTr>
            {header.map((item, index) => {
              return(
                <Th key={index} alignText={item.align}>
                  {item.rotate ? <TranslatedSpan>{item.name}</TranslatedSpan> : item.name}
                </Th>
              )
            })}
          </HeaderTr>
        </thead>
        {roles.map(role => {
          return(
            <RoleElement role={role} isPartner={isPartner}/>
          )
        })}
      </Table>
    </div>
  )
})

export default RoleManager;