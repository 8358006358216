import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import alarmSaga from "./alarm";

function* EventViewSaga(){
  yield all([
    fork(initializationSaga),
    fork(alarmSaga)
  ])
}
export default EventViewSaga;
