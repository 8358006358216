import {useIntl} from "react-intl";
import * as React from "react";

import Phone from "../../../../Elements/Icon/Icons/Safemate/solid/Phone";
import { StyledButton } from './index';
import { StyledModal, ButtonWrap } from '../TableElements';
import { CancelButton } from '../../../DefaultSettings/Settings/styled';
import { LabelEnum } from '../../LabelEnum';
import styled from "styled-components";

interface PhoneProps{
  phoneNumber: string;
}

const ModalContent = styled.p`
  width: 100%;
  text-align: center;
  font-size: 4em;
`;

const TrackerPhone = ({phoneNumber}: PhoneProps) =>{
  const formattedNumber = phoneNumber.replace(/\s/g,'')

  const [open, setOpen] = React.useState(false);

  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      <StyledButton
        title={formatMessage({id: LabelEnum.SHOW_PHONE_NUMBER, defaultMessage: "Vis telefonnummer"})}
        onClick={(event) => {
          setOpen(true);
        }}
        onKeyDown={(event) => {
          setOpen(true);
        }}
      >
        <Phone
          size="medium"
          inline
        />
      </StyledButton>
      <StyledModal
        open={open}
        setOpen={setOpen}
      >
        <div>
          <div>
            <h2>{formatMessage({id: LabelEnum.PHONE_NUMBER, defaultMessage: "telefonnummer"})}</h2>
          </div>
          <div>
            <ModalContent>
              <a href={`tel:${formattedNumber}`}>{formattedNumber}</a>
            </ModalContent>
          </div>
          <ButtonWrap>
            <CancelButton onClick={() => setOpen(false)}>Ok</CancelButton>
          </ButtonWrap>
        </div>
      </StyledModal>
    </React.Fragment>
  )
}

export default TrackerPhone;
