import _axios, {AxiosInstance} from 'axios';
import { AlarmShare } from './Saga/alarmAnalysis';
import { addRolesToRequest } from '@Safemate/utils';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---


export const getDevices = async (deviId: number, retrieveFrom: number, isPrivate: boolean) => {
  let url = `/device/devicewithstatusforuser/${deviId}/${retrieveFrom}${addRolesToRequest()}`;
  if(isPrivate)
    url = `/device/devicewithstatusforprivate/${deviId}/${retrieveFrom}`;
  const response = await axios.get(url);
  return response.data;
}

export const getCustomers = async () => {
  const url = `/firm/suborgunitswithdevicecountandrights${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;
}

export const searchSensors = async (search: string) => {
  const url = `/logistics/api/Device/SearchSensor/${search}`;
  const response = await axios.get(url);
  return response.data;
}

export const alarmAnalysis = async (deviceId: number) => {
  const url =  `/alarmanalysis/fordevice/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const shareAlarm = async (alarmShare: AlarmShare) => {
  const url = `/share/${alarmShare.deviceId}?shareRecipient=${alarmShare.recipients}`;
  const response = await axios.get(url);
  return response.data;
}

export const resetAlarm = async (deviceId: number) => {
  const url = `/device/reset/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const startTracking = async (deviceId: number) => {
  const url = `/device/track/${deviceId}/30/2`;
  const response = await axios.get(url);
  return response.data;
}

export const getMunicipalities = async () => {
  const url = "/device/ahp/trimmedmunicipalities";
  const response = await axios.get(url);
  return response.data;
}

export const getAllNotes = async (deviceList: number[]) => {
  const url: string = `/deviceSettings/getAllNotes`;
  const response = await axios.post(url,deviceList);
  return response.data;
}

export const getNote = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/note`;
  const response = await axios.get(url);
  return response.data;
}

export const getNoteExistence = async (deviceId: number) => {
  const url: string = `/deviceSettings/${deviceId}/doesDeviceNoteExist`;
  const response = await axios.get(url);
  return response.data;
}

export const getTwinSuffix = async () => {
  const url: string = `/deviceSettings/twinsuffix`;
  const response = await axios.get(url);
  return response.data;
}

export const getPartnerDeviceList = async () => {
  const url = `/partner/partnerdevicelist`;
  const response = await axios.get(url);
  return response.data;
}