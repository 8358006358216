import { Device } from "@Safemate/Settings/types";
import DeviceEventSetup from "../Model/DeviceEventSetup";

export const useDefault = (device: Device) => !device.overrideDefaultEventSetup && device.enableDefaultEventSetup;

export enum SortEnum{
  DECREMENT,
  INCREMENT
}

export enum ModalEnum{
  NONE,
  FROM,
  TO,
  DAYS,
  EVENT_TYPES,
  TRAINED,
  NEXT_OF_KIN,
  DELETE,
  COVERAGE
}

export const getValidHour = (hour: number) => {
  const MAX = 23;
  const MIN = 0;

  if (hour < MIN) {
    return MAX;
  }
  if (hour > MAX) {
    return MIN;
  }
  return hour;
};

export const getValidMinute = (minute: number) => {
  if (minute < 0) {
    return 55;
  }
  if (minute > 55) {
    return 0;
  }
  return minute;
};

export const intToTime = (num: number) => {
  let str = `${num}`;

  while (str.length < 4) {
    str = 0 + str;
  }
  return `${str.slice(0, 2)}:${str.slice(2)}`;
};

export const formatTime = (number: number) => {
  return number > 9 ? number : `0${number}`;
};

export const isGsmRecipient = (eventSetup: DeviceEventSetup) => {
  if (eventSetup.email === null) {
    return true;
  }
  return false;
};

export const isEmailRecipient = (eventSetup: DeviceEventSetup) => {
  if (eventSetup.email !== null) {
    return true;
  }
  return false;
};

export const hasGsmRecipient = (eventSetups: DeviceEventSetup[]) => {
  return eventSetups.filter(setup => setup.email === null).length > 0;
}

export const hasEmailRecipient = (eventSetups: DeviceEventSetup[]) => {
  return eventSetups.filter(setup => setup.email !== null).length > 0;
}

export const uniqueGsmRecipients = (eventSetups: DeviceEventSetup[]) => {
  const uniqueNumbers: string[] = [];
  eventSetups.forEach(setup => {
    if(!uniqueNumbers.includes(setup.phone) && isGsmRecipient(setup)){
      uniqueNumbers.push(setup.phone);
    }
  })
  return uniqueNumbers.length;
}