export enum LabelEnum{
  SETTINGS_SAVED = "settingsSaved",
  RESET_SETTINGS = "revertToDefaultDefaultSettings",
  CANCEL = "cancel",
  SAVE = "save",
  REVERT = "revert",
  REVERT_SETTINGS = "revertSettings",
  REVERT_DIALOG = "revertDialog",
  SUBUNITS = "subUnits",
}
