import * as React from "react";
import { useIntl } from "react-intl";
import EventSetupIcon from "@Elements/Icon/Icons/menu/EventSetup";
import Tooltip from "@Elements/Tooltip";
import InformationIcon from "@Icons/Information1";
import { connect } from "react-redux";
import { AppState } from "@Safemate/Store/types";
import DeviceHeader, { StyledSpan } from "@Safemate/DeviceHeader";
import { Device } from "@Safemate/Settings/types";


const mapStateToProps = ({ eventSetup: {device: { device }}}: AppState) => {
  return {
    device
  }
}

interface HeaderProps{
  device: Device;
}

const Header = connect(mapStateToProps)(({device}: HeaderProps) => {

  console.log(device);
  
  const { formatMessage } = useIntl();

  return(
      <DeviceHeader 
        dehaId={device.dehaId}
        name={device.deviceName} 
        deviId={device.deviId} 
        custId={device.ownedByCustomer} 
        isTwin={device.twinChild} 
        serialNumber={device.deviceSerialNumber} 
        deviceId={device.deviceId}>
      <>
        <StyledSpan>
          {` ${formatMessage({ id: "eventSetup", defaultMessage: "Varslingsliste" })} `}
        </StyledSpan>
        < Tooltip
            popoverId="event-setup-popover"
            labelId="tooltip_eventSetup"
            defaultMessage="Her angis hvordan varslingen av hendelser skal skje. Man kan velge mellom varsling på mobiltelefon (GSM) eller pr. epost. Man kan velge mellom å la mottakere være tilgjengelig kun i tider av uka og til visse tider av døgnet.

            Hjerte-ikonet åpner valg for pårørende.
            
            Stiplet hjerte indikerer at hendelsesmottakeren ikke er en pårørende."
          >
            <InformationIcon size="medium" />
          </Tooltip>
      </>
    </DeviceHeader>
  )
})

export default Header;