import { DeviceEventSetupState } from "../types";
import {Reducer} from "redux";
import { deleteDeviceEventSetup, getDeviceEventSetups, saveDeviceEventSetup, setDeviceEventSetupModal } from "../routines";
import DeviceEventSetup from "@Safemate/EventSetupNew/Model/DeviceEventSetup";
import { ModalEnum } from "@Safemate/EventSetupNew/Body/utils";
import { v4 } from "uuid";

const deviceEventSetupState: DeviceEventSetupState = {
  eventSetups: [],
  selectedEventSetup: null,
  modalType: ModalEnum.NONE,
  modalEventSetupIndex: 0
};

const deviceEventSetup: Reducer<DeviceEventSetupState> = (state: DeviceEventSetupState = deviceEventSetupState, action) => {
  switch (action.type) {

    case getDeviceEventSetups.SUCCESS:
      return {
        ...state,
        eventSetups: action.payload.sort((a: DeviceEventSetup, b: DeviceEventSetup) => {
          return a.sort - b.sort;
        }).map((eventSetup: DeviceEventSetup) => {
          return {
            ...eventSetup,
            localId: v4()
          }
        })
      }

    case deleteDeviceEventSetup.SUCCESS:
      return{
        ...state,
        eventSetups: action.payload.eventSetups.filter((setup: DeviceEventSetup) => setup.deviceEventSetupId !== action.payload.eventSetupId)
      }

    case deleteDeviceEventSetup.FULFILL:
      return{
        ...state,
        modalType: ModalEnum.NONE,
        modalEventSetupIndex: 0,
        selectedEventSetup: null
      }

    case saveDeviceEventSetup.SUCCESS:
      return {
        ...state,
        eventSetups: action.payload
      }

    case setDeviceEventSetupModal.TRIGGER:
      return {
        ...state,
        ...action.payload
      }
    

    default:
      return state;
  }
};

export default deviceEventSetup;
