import Log from "@Elements/Icon/Icons/Log";
import SafemateHeader from "@Safemate/Header";
import { IDevice } from "@Safemate/Model/Device";
import { IUser } from "@Safemate/Model/User";
import { AppState } from "@Safemate/Store/types";
import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import UserAuditLog from "./UserAuditLog";
import { EventLog } from "@Safemate/Model/EventLog";
import { formatDate } from "@Safemate/utils";
import TableExport from "@Elements/Export/TableExport";
import { capitalizeFirstLetter } from "./utils";
import styled from "styled-components";
import { SetFilter, setFilter } from "./Store/actions";
import {DeviceAuditLog, DeviceCallLog} from "./DeviceAuditLog";
import { Button } from "react-bootstrap";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {getDevice} from "@Safemate/Log/Store/routines";

const ExportWrapper = styled.div`
  margin-left: auto;
`;

export enum LogType{
  USER_AUDIT = "user_audit_log",
  DEVICE_AUDIT = "device_audit_log",
  DEVICE_CALL = "device_call_log"
}

interface LogProps{
  user: IUser;
  device: IDevice;
  filteredUserAuditLog: EventLog[];
  filteredDeviceAuditLog: EventLog[];
  getDevice: Routine<ActionFunctionAny<Action<any>>>;
  setFilter: SetFilter;
}

const mapStateToProps = ({ log: { initialization: { user, device }, deviceAuditLog: { filteredDeviceAuditLog }, userAuditLog: { filteredUserAuditLog }} }: AppState) => {
  return {
    user,
    device,
    filteredUserAuditLog,
    filteredDeviceAuditLog
  }
}

const mapDispatchToProps = {
  setFilter,
  getDevice,
}

export interface LogParams{
  id: string;
}

const SafemateLog = connect(mapStateToProps, mapDispatchToProps)(({user, device, setFilter, getDevice, filteredUserAuditLog, filteredDeviceAuditLog}: LogProps) => {

  const [ title, setTitle ] = React.useState("");
  const [ logType, setLogType ] = React.useState<LogType>();
  const [ exportData, setExportData ] = React.useState<string[][]>();
  const [ exportFileName, setExportFileName ] = React.useState("");
  const [ exportColWidth, setExportColWidth ] = React.useState([]);
  const [ Comp, setComp ] = React.useState<React.ReactNode>();

  const { formatMessage } = useIntl();
  const {pathname} = useLocation();

  const { id } = useParams<LogParams>();
  const deviceId = parseInt(id);

  React.useEffect(() => {
    if(pathname.includes(LogType.USER_AUDIT)){
      setLogType(LogType.USER_AUDIT);
      setComp(<UserAuditLog/>);
    }
    else if(pathname.includes(LogType.DEVICE_AUDIT)){
      getDevice(deviceId);
    }
    else if(pathname.includes(LogType.DEVICE_CALL)){
      getDevice(deviceId);
    }

    return () => {
      setFilter([]);
    }

  },[])

  React.useEffect(() => {
    if(pathname.includes(LogType.DEVICE_AUDIT)){
      setLogType(LogType.DEVICE_AUDIT);
      setComp(<DeviceAuditLog/>);
    }
    else if(pathname.includes(LogType.DEVICE_CALL)){
      setLogType(LogType.DEVICE_CALL);
      setComp(<DeviceCallLog/>);
    }

  },[device])

  React.useEffect(() => {
    if(logType === LogType.USER_AUDIT && filteredUserAuditLog.length > 0){
      const data = [
        [capitalizeFirstLetter(formatMessage({id: "timestamp", defaultMessage: "Tid"})),
        capitalizeFirstLetter(formatMessage({id: "serialNumber", defaultMessage: "Serienummer"})),
        capitalizeFirstLetter(formatMessage({id: "deviceName", defaultMessage: "Enhetsnavn"})),
        capitalizeFirstLetter(formatMessage({id: "action", defaultMessage: "Handling"}))]
      ];
      filteredUserAuditLog.forEach(entry => {
        data.push([
          formatDate(entry.createdTime, true, false, false, false, false, formatMessage),
          entry.deviceSnNum,
          entry.deviceSnNum,
          `${formatMessage({id: `et.${entry.eventType.name}`})}${entry.header ? ` - ${entry.header}` : ""}${entry.description ? ` ${entry.description}` : ""}${entry.remoteUser ? ` ${entry.remoteUser}` : ""}`
        ])
      })

      setExportData(data);
      setExportFileName(`User_audit_${user.name}`);
      setExportColWidth([15, 15, 15, 55])
    }
  }, [filteredUserAuditLog])

  React.useEffect(() => {
    if(logType === LogType.DEVICE_AUDIT && filteredDeviceAuditLog.length > 0){
      const data = [
        [capitalizeFirstLetter(formatMessage({id: "timestamp", defaultMessage: "Tid"})),
        capitalizeFirstLetter(formatMessage({id: "action", defaultMessage: "Handling"})),
        capitalizeFirstLetter(formatMessage({id: "user", defaultMessage: "Bruker"}))]
      ];
      filteredDeviceAuditLog.forEach(entry => {
        data.push([
          formatDate(entry.createdTime, true, false, false, false, false, formatMessage),
          `${formatMessage({id: `et.${entry.eventType.name}`})}${entry.header ? ` - ${entry.header}` : ""}${entry.description ? ` ${entry.description}` : ""}${entry.remoteUser ? ` ${entry.remoteUser}` : ""}`,
          entry.remoteUser ? entry.remoteUser : entry.createdByUser ? (entry.createdByType == 1 ? formatMessage({id: "customerService_plhold", defaultMessage: "Customer service"}) : entry.createdByUser.name) : ""
        ])
      })

      setExportData(data);
      setExportFileName(`Device_audit_${device ? device.deviceSNNum : deviceId}`);
      setExportColWidth([20, 40, 40])
    }
  }, [filteredDeviceAuditLog])

  React.useEffect(() => {
    if(user){
      setTitle(`${formatMessage({id: "userAuditLogFor", defaultMessage: ""})} ${user.name}`);
    }
  }, [user])

  React.useEffect(() => {
    if(device && logType === LogType.DEVICE_AUDIT){
      setTitle(`${formatMessage({id: "deviceAuditLogFor", defaultMessage: ""})} ${device.deviceSNNum}`);
    }
    if(device && logType === LogType.DEVICE_CALL){
      setTitle(`${formatMessage({id: "deviceCallLogFor", defaultMessage: ""})} ${device.deviceSNNum}`);
    }
  }, [device])

  return(
    <React.Fragment>
      <SafemateHeader
        icon={<Log/>}
        title={title}
        >
        <ExportWrapper>
          <Button style={{marginRight: "1em"}} onClick={() => window.history.back()}>
            {formatMessage({id: "back", defaultMessage: "Tilbake"})}
          </Button>
          {exportData 
            ? <React.Fragment>
                <TableExport type="csv" data={exportData} fileName={exportFileName}/>
                <TableExport colWidth={exportColWidth} type="pdf" data={exportData} fileName={exportFileName}/>
              </React.Fragment>
          : null}
        </ExportWrapper>
      </SafemateHeader>
      {Comp && Comp}
    </React.Fragment>
  )
})

export default SafemateLog;


