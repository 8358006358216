import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../../Store/types';
import * as Routines from "../routines";

function* getTextLabels(action: AppAction<null>){
  try {
    const texts = yield call(Api.getTextLabels);
    yield put(Routines.getTextLabels.success(texts));
  }
  catch(e){
    yield put(Routines.getTextLabels.failure());
  }
  yield put(Routines.getTextLabels.fulfill());
}


function* textLabelSaga(){
  yield takeLatest(Routines.getTextLabels.TRIGGER, getTextLabels);
}

export default textLabelSaga;
