import * as React from "react";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import { Action, ActionFunctionAny } from "redux-actions";
import {Formik, Form, Field, FieldArray} from "formik";
import {Checkbox} from "../../../Elements/CheckboxFormik";
import {
  getDeviceMacs,
  addDeviceKnownMac,
  deleteDeviceKnownMac,
  getInitialPosition, addDeviceKnownMacs,
  scanWifi,
} from "../Store/routines";
import { DeviceMac, DeviceKnownMac } from "../Indoor/types";
import { LoaderWrap } from "../../UserAdmin/Users/Edit/styles";
import {setDeviceMac, SetDeviceMac, setScannedMinutes, SetScannedMinutes} from "../Store/actions";
import { injectIntl, useIntl, WrappedComponentProps } from "react-intl";
import {defaultDeviceMac} from "./utils"
import styled from "styled-components";
import { Col, Row, Button } from "react-bootstrap";
import {confirmModal} from "../General/Tabs/deleteData";
import {DeviceContent} from "./field";
import {IndoorMap, Map} from "./Map/map";
import withFormWrapper from "@Elements/Form/formWrapper";
import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import {Option} from "../../DefaultSettings/Settings/FieldWrapper";
import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";

import { supportWifiScan } from "../tabDefinition";
import Refresh from "@Elements/Icon/Icons/FontAwesome/Refresh";
import Loader from "@Elements/Loader";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";

export const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const TextArea = styled("textarea")`
  resize: none;
  width: 100%;
  height: 5em;
  padding: 10px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 25px;
  .confirmPassword{
    label{
      text-transform: none;
    }
  }
`;

const DeleteBtn = styled(Button)`
  background: #d60403;
  color: white;
`;

const ClearBtn = styled(Button)`  
  margin-left: 15px;
`;

const AccentedText = styled.span`
  color: ${props => props.theme.colors.accent};
`;

const RowWrapper = styled(Row)`
  .hide-lg {
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .hide-md {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .hide-sm.hide-sm {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .centered {
    display: flex;
    justify-content: center;
  }

  .end {
    display: flex;
    justify-content: flex-end;
  }
  
  .highlight {
    border: 2px solid ${props => props.theme.colors.accent};
  }
`;

const ListRow = styled(Row)`
  min-height: 48px;
  align-items: center;
  margin: 0px 0px 5px 15px;
  border: ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
`;

const IndoorHeading = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 15px;
  display: flex;
`;

const IndoorSubHeading = styled.div`
  font-size: 16px;
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 10px;
`;

const DropdownWrapper = styled.div`
  margin-left: 0px;
  display: flex;
  align-items: center;
  font-size: 16px;
  @media (max-width: 600px) {
    margin-right: auto;
    margin-left: 0;
  }
`;

export const DropdownLabel = styled.span`
  white-space: nowrap;
`;

export const DropdownWrapped = styled.div`
  width: 200px;
  margin: 0px 5px;
`;

interface DeleteDeviceKnownMacProps{
  deleteDeviceKnownMac: Routine<ActionFunctionAny<Action<any>>>;
  getDeviceMacs: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  deviceMac: DeviceMac;
}

const mapDeleteStateToProps = ({ settings: {initialization: {deviId}, indoor: { deviceMac }}}: AppState) => {
  return {
    deviId, deviceMac
  }
}

const mapDeleteDispatchToProps = {
  deleteDeviceKnownMac,
  getDeviceMacs
};



export const DeleteDeviceKnownMac = connect(mapDeleteStateToProps, mapDeleteDispatchToProps)
(({ deviId, deviceMac, deleteDeviceKnownMac, getDeviceMacs }: DeleteDeviceKnownMacProps) => {

  const {Comp, func} = confirmModal();

  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      <DeleteBtn onClick={() => func(true)}>{formatMessage({id: "delete", defaultMessage: "Delete"})}</DeleteBtn>
      <Comp
        title={`${formatMessage({id: "delete", defaultMessage: "Delete"})} ${formatMessage({id: "mac", defaultMessage: "Mac Address"})}`}
        body={
          <p>
            <span>{formatMessage({id: "deleteMacDesc", defaultMessage: "Do you want to really delete this device from known device list"})}</span>
            <AccentedText>{` ${deviceMac.macAddress}?`}</AccentedText>
          </p>
        }
        confirmText={formatMessage({id: "delete", defaultMessage: "Slette"})}
        confirmFunc={() => {
          deleteDeviceKnownMac(deviceMac);
          getDeviceMacs(deviId);
          func(false);
        }}
      />
    </React.Fragment>
  )
})


interface IndoorAwarenessProps{
  getDeviceMacs: Routine<ActionFunctionAny<Action<any>>>;
  addDeviceKnownMac: Routine<ActionFunctionAny<Action<any>>>;
  addDeviceKnownMacs: Routine<ActionFunctionAny<Action<any>>>;
  getInitialPosition: Routine<ActionFunctionAny<Action<any>>>;
  scanWifi: Routine<ActionFunctionAny<Action<any>>>;
  setDeviceMac: SetDeviceMac;
  setScannedMinutes: SetScannedMinutes;
  deviId: number;
  dehaId: number;
  deviceMac: DeviceMac;
  deviceKnownMac: DeviceKnownMac;
  deviceKnownMacs: DeviceKnownMac[];
  deviceMacs: DeviceMac[];
  loading: boolean;
  deviceMacsRefresh: boolean;
  deviceMacInitialValues: DeviceMac;
  valuesChanged: boolean;
  selectedIndexes: number[];
  hasSavedMacs: boolean;
  hasScannedMacs: boolean;
  hasHeaderSaved: boolean;
  scanMinutes: number;
  fetchingMacs: boolean;
  learnLink: string;
}

const IndoorComp = ({
        deviId,
        dehaId,
        getDeviceMacs,
        getInitialPosition,
        addDeviceKnownMac,
        addDeviceKnownMacs,
        deviceMac,
        deviceMacs,
        loading,
        setDeviceMac,
        setScannedMinutes,
        deviceMacsRefresh,
        deviceMacInitialValues,
        valuesChanged,
        selectedIndexes,
        hasSavedMacs,
        hasScannedMacs,
        hasHeaderSaved,
        scanMinutes,
        scanWifi,
        fetchingMacs,
        learnLink,
    }: IndoorAwarenessProps) => {

    const { formatMessage } = useIntl();

    React.useEffect(() => {
        getDeviceMacs([deviId, scanMinutes]);
    }, [deviceMacsRefresh, scanMinutes])

    React.useEffect(() => {
      getInitialPosition(deviId);
      setDeviceMac(defaultDeviceMac(deviId));
      getDeviceMacs([deviId, scanMinutes]);
    }, [deviId])

    const refresh = () => {
      getDeviceMacs([deviId, scanMinutes])
    }

    const enableScanWifi = supportWifiScan.get(dehaId);

    if(loading){
        return (
          <LoaderWrap><Loader/></LoaderWrap>
        )
    }else{
      return (

          <Formik
            onSubmit={(values) => {
              addDeviceKnownMacs(values.devices);
            }
            }
            enableReinitialize
            initialValues={{ devices: deviceMacs }}
          >
            {({ values, dirty }) => (
              <Form>

                <Row>
              <Col md={5} sm={5} xs={12}>
                <Row>
                  <Col md={12}>
                    <IndoorHeading>
                      <span style={{paddingRight: "10px"}}>{formatMessage({id: "indoorPositioning", defaultMessage: "Innendørsposisjonering"})}</span>
                      <Tooltip
                        popoverId={`indoorPositionInfoLabel`}
                        labelId={`indoorPositionInfoLabel`}
                        defaultMessage="Her kan du angi referansepunkter. Om Safemate har kontakt med minst et av referansepunktene, vil GPS være avskrudd, og geofence ut-meldinger vil ikke sendes."
                        postfix={<u><a target="_blank" href={`${learnLink}`}>{formatMessage({ id: "readMoreHear", defaultMessage: "Les mer her" })}</a></u>}
                      >
                        <InformationIcon size="medium" />
                      </Tooltip>
                    </IndoorHeading>
                    <IndoorSubHeading>{formatMessage({id: "indoorPositioningText", defaultMessage: "Velg de referansepunktene du ønsker at Safematen skal kunne bruke"})}</IndoorSubHeading>
                  </Col>
                </Row>
                <Row>
                  {enableScanWifi &&
                  <Col xs={6} sm={6} md={6}>
                    <IndoorHeading>
                      <Button onClick={() => scanWifi(deviId)}>{formatMessage({id: "scanWifi", defaultMessage: "Skann WiFi"})}</Button>
                      <Tooltip
                        popoverId={`scanWifiInfoLabel`}
                        labelId={`scanWifiInfoLabel`}
                        defaultMessage="Scan wifi will scan near by wifi devices and reports it to server"
                        style={{marginLeft: '10px', marginTop: '5px'}}
                      >
                        <InformationIcon size="medium" />
                      </Tooltip>
                    </IndoorHeading>
                  </Col>}
                  <Col md={12}>
                    {
                      hasSavedMacs &&
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <IndoorSubHeading style={{textTransform: "capitalize", marginTop: "10px"}}>{formatMessage({id: "savedReferencePoints", defaultMessage: "Saved reference points"})}</IndoorSubHeading>
                        </Col>
                      </Row>
                    }
                    <RowWrapper>
                      <FieldArray name="devices" render={({ insert, remove, push }) => (
                        <React.Fragment>
                          {
                            values.devices.map((device,index)=>(
                              <Row key={device.demaId}>
                                <Col md={12}>
                                  {
                                    device.headerSaved &&
                                    <Row>
                                      <Col xs={12} sm={12} md={12} style={{marginLeft: '15px', marginBottom: '15px'}}>
                                        <DropdownWrapper>
                                          <DropdownLabel>
                                            {formatMessage({id: "lastScannedDevices", defaultMessage: "Devices scanned in"})}
                                          </DropdownLabel>
                                          <DropdownWrapped>
                                            <Dropdown
                                              initial={scanMinutes}
                                              options={[
                                                {text: `60 ${formatMessage({id: "minutes", defaultMessage: " minutes"})}`, value: 60},
                                                {text: `120 ${formatMessage({id: "minutes", defaultMessage: " minutes"})}`, value: 120},
                                                {text: `180 ${formatMessage({id: "minutes", defaultMessage: " minutes"})}`, value: 180},
                                                {text: `300 ${formatMessage({id: "minutes", defaultMessage: " minutes"})}`, value: 300},
                                                {text: `${formatMessage({id: "all", defaultMessage: " All"})}`, value: 527040}
                                              ]}
                                              onChange={(option) => {
                                                const selected = (option as Option).value;
                                                setScannedMinutes(selected);
                                              }}
                                            />
                                          </DropdownWrapped>
                                        </DropdownWrapper>
                                        <Refresh spin={fetchingMacs} onClick={refresh}/>
                                      </Col>
                                    </Row>
                                  }
                                  {
                                    device.headerSaved && hasScannedMacs &&
                                    <Row>
                                      <Col xs={12} sm={12} md={12} style={{marginLeft: '15px'}}>
                                        <IndoorSubHeading style={{textTransform: "capitalize"}}>{formatMessage({id: "potentialReferencePoints", defaultMessage: "Potential reference points"})}</IndoorSubHeading>
                                      </Col>
                                    </Row>
                                  }

                              <ListRow className={`${device.selected || device.visible ? "highlight" : ""}`}>
                                <Col md={12}>
                                  <DeviceContent device={device} index={index} />
                                </Col>
                                {
                                  device.visible &&
                                  <Col md={12}>
                                    <Row>
                                      <Col md={12} style={{paddingBottom: '10px'}}>
                                        <Label>{formatMessage({id: "nameDeviceMac", defaultMessage: "Name"})}</Label>
                                        <StyledField
                                          type="text"
                                          name={`devices.${index}.name`}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12} style={{paddingBottom: '10px'}}>
                                        <Label>{formatMessage({id: "addressDeviceKnownMac", defaultMessage: "Address"})}</Label>
                                        <StyledField
                                          type="text"
                                          id={`${index}-device-address`}
                                          name={`devices.${index}.deviceKnownMac.address`}
                                        />
                                        <StyledField
                                          type="hidden"
                                          name={`devices.${index}.deviceKnownMac.latLng`}
                                        />
                                        <StyledField
                                          type="hidden"
                                          name={`devices.${index}.latLng`}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12} style={{paddingBottom: '10px'}}>
                                        <Label>{formatMessage({id: "locationDeviceKnownMac", defaultMessage: "Location"})}</Label>
                                        <StyledField
                                          type="text"
                                          name={`devices.${index}.deviceKnownMac.location`}
                                        />
                                      </Col>
                                    </Row>
                                    {
                                      device.deviceKnownMac.dekmId>0 &&
                                      <Row>
                                        <Col md={12} style={{paddingBottom: '10px'}}>
                                          <DeleteDeviceKnownMac />
                                          <Button  style={{cursor: 'pointer', marginLeft: '1em'}} onClick={(event: any) => {
                                            event.stopPropagation();
                                            addDeviceKnownMac(values.devices[index]);
                                          }}>
                                            {formatMessage({id: "save", defaultMessage: "Save"})}
                                          </Button>
                                        </Col>
                                      </Row>
                                    }
                                  </Col>
                                }
                              </ListRow>
                                </Col>
                              </Row>
                            ))
                          }
                        </React.Fragment>
                      )} />

                    </RowWrapper>
                    {
                      !hasHeaderSaved && !hasScannedMacs &&
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <DropdownWrapper>
                            <DropdownLabel>
                              {formatMessage({id: "lastScannedDevices", defaultMessage: "Devices scanned in"})}
                            </DropdownLabel>
                            <DropdownWrapped>
                              <Dropdown
                                initial={scanMinutes}
                                options={[
                                  {text: `60 ${formatMessage({id: "minutes", defaultMessage: " minutes"})}`, value: 60},
                                  {text: `120 ${formatMessage({id: "minutes", defaultMessage: " minutes"})}`, value: 120},
                                  {text: `180 ${formatMessage({id: "minutes", defaultMessage: " minutes"})}`, value: 180},
                                  {text: `300 ${formatMessage({id: "minutes", defaultMessage: " minutes"})}`, value: 300},
                                  {text: `${formatMessage({id: "all", defaultMessage: " All"})}`, value: 527040}
                                ]}
                                onChange={(option) => {
                                  const selected = (option as Option).value;
                                  setScannedMinutes(selected);
                                }}
                              />
                            </DropdownWrapped>
                          </DropdownWrapper>
                          <Refresh spin={fetchingMacs} onClick={refresh}/>
                        </Col>
                      </Row>
                    }
                  </Col>
                  {
                    hasScannedMacs &&
                    <Row>
                      <Col xs={12} sm={12} md={12} style={{marginLeft: '15px', marginBottom: '15px'}}>
                        <Button type={`submit`} disabled={ (!valuesChanged || selectedIndexes.length<1)? true : false }>{formatMessage({id: "save", defaultMessage: "Lagre"})}</Button>
                      </Col>
                    </Row>
                  }
                </Row>
              </Col>
              <Col md={7} sm={7} xs={12}>
                <IndoorMap />
              </Col>
            </Row>
                <UnsavedCheck dirtyOverride={() => {
                  if(valuesChanged)
                    return true;
                  else{
                    return false;
                  }
                  return false;
                }}/>

              </Form>
            )}
          </Formik>
        )
    }
}


const mapStateToProps = ({ settings: {initialization: {deviId}, device: { device: { dehaId } }, indoor: { fetchingMacs, deviceMac, deviceMacs, loading, deviceMacsRefresh, deviceMacInitialValues, valuesChanged, selectedIndexes, hasSavedMacs, hasScannedMacs, scanMinutes, hasHeaderSaved, learnLink}}}: AppState) => {
    return {
        deviId, deviceMac, deviceMacs, loading, deviceMacsRefresh, deviceMacInitialValues, valuesChanged, selectedIndexes, hasSavedMacs, hasScannedMacs, scanMinutes, hasHeaderSaved, fetchingMacs, dehaId, learnLink
    }
}
  
const mapDispatchToProps = {
    getDeviceMacs,
    getInitialPosition,
    addDeviceKnownMac,
    addDeviceKnownMacs,
    setDeviceMac,
    setScannedMinutes,
    scanWifi,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndoorComp);
