import * as React from "react";
import { injectIntl, MessageDescriptor, WrappedComponentProps } from "react-intl";

import { TabsEnum } from "./TabsEnum";


import BillingInformation from "./BillingInformation";
import BillingHistory from "./BillingHistory";
import Settings from "./Settings";


interface LightHeaderProps extends WrappedComponentProps {
  message: MessageDescriptor;
}

export const Header = injectIntl(({ intl: { formatMessage }, message }: LightHeaderProps) => {
  return (
    <div>
      <h3>
        {formatMessage(message)}
      </h3>
    </div>
  )
})


export const getMain = () => {
  //adding settings and billing history content next sprint
  const DEFAULT = new Map<TabsEnum, JSX.Element>([
    [TabsEnum.SETTINGS, <Settings/>],
    [TabsEnum.BILLING_INFORMATION, <BillingInformation/>],
    [TabsEnum.BILLING_HISTORY, <BillingHistory/>]


  ]);

  return DEFAULT;
}
