import * as React from "react";
import GoogleMap, {Maps} from 'google-map-react';
import {Row} from "react-bootstrap";
import styled from "styled-components";
import {GeofenceWrapper} from "./GeofenceWrapper";
import {connect} from "react-redux";
import {Geofence, GeofenceDistance} from "../types";
import {setControlState, SetControlState, setGeofenceType, SetGeofenceType} from "../../Store/actions";
import {GeofenceValues} from "..";
import withFormWrapper from "@Elements/Form/formWrapper";
import {GeofenceTypeEnum} from "../GeofenceTypeEnum";
import UnsavedChanges from "../../../../Roles/RoleEditor/Modals/UnsavedChangesModal"
import {injectIntl, WrappedComponentProps} from "react-intl";
import {getFenceDistance, getLatestPosition} from "../../Store/routines";
import {Routine} from "redux-saga-routines";
import {ActionFunctionAny} from "redux-actions";
import {getGeofenceDistance} from "../utils";
import {Position} from "@Safemate/map/Store/types";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import { AppAction, AppState } from "@Safemate/Store/types";
import {getGoogleMapLanguageCode} from "@Safemate/map/utils";


const MapWrapper = styled(Row)`
  height: 40em;
  padding: 0px;
  position: relative;
`;

const FenceTabHolder = styled.div`
  position: absolute;
  left: 0%;
  top:0%;
`;

const FenceTab = styled.div<{selected?: boolean}>`

  &:first-child {
    margin-top: 53px;
  }
  
  &.selected {
    background-color: ${props => props.theme.colors.backgroundPrimary};	
    svg {
         fill: ${props => props.theme.colors.accent};
         stroke: ${props => props.theme.colors.accent};
       }
  }
 
  svg {
    fill: gray;
    stroke: gray;
    height: 20px;
    width: 20px;
  }
  display: flex;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  margin-top: 8px;
  padding: 8px 12px 12px 9px;
  height: 35px;
  background-color: ${props => 
    props.selected 
    ? props.theme.colors.backgroundPrimary
    : props.theme.colors.backgroundSecondary};

`;

const Search = styled.input`
  width: 15%;
  height: 40px;
  color: #CACACB;
  margin-left: 6px;
  margin-top: 6px;
  background: black;
  opacity: 0.4;

  border: 1px solid #36393e;
  border-radius: 4px;
  outline: none;
  padding: 0 15px;

  transition: width 1s, opacity 1s;
  -webkit-transition: width 1s, opacity 1s;
  -moz-transition: width 1s, opacity 1s;
  -ms-transition: width 1s, opacity 1s;
  -o-transition: width 1s, opacity 1s;

  &:hover {
    opacity: 0.6;
  }
  &:focus {
    opacity: 0.8;
    width: 33%;
  }
`;

interface MapProps extends WrappedComponentProps{
  geofence: Geofence[];
  initialFences: Geofence[];
  setGeofenceType: SetGeofenceType;
  setControlState: SetControlState;
  getFenceDistance: Routine<ActionFunctionAny<AppAction<GeofenceDistance>>>;
  getLatestPosition: Routine<ActionFunctionAny<AppAction<number>>>;
  deviId: number;
  posLong: string;
  posLati: string;
  geofenceType: GeofenceTypeEnum;
  refreshToken: string;
  languageCode: string;
  latestPosition: Position[];
  indoorLocations: IndoorLocation[];
}

const Map = injectIntl(withFormWrapper<GeofenceValues, MapProps>(
  ({formik: { setFieldValue, values }, intl: { formatMessage }, getLatestPosition, latestPosition, getFenceDistance, setGeofenceType, setControlState, geofence, initialFences, refreshToken, geofenceType, deviId, posLong, posLati, languageCode, indoorLocations}) => {

    const searchRef = React.useRef<HTMLInputElement>(null);

    const [ unsaved, setUnsaved ] = React.useState({
      modal: false,
      func: () => {}
    });
    const [ mapLoaded, setMapLoaded ] = React.useState(false);

    React.useEffect(() => {
      getLatestPosition(deviId);
    }, [])


    let geofenceWrapper: GeofenceWrapper = React.useMemo(() => {
      return new GeofenceWrapper(setGeofenceType, setControlState, setFieldValue, setUnsaved, values);
    }, [])

    geofenceWrapper.updateValues(values);

    React.useEffect(() => {
      if(latestPosition.length > 0 && mapLoaded){
        geofenceWrapper.createMarker(latestPosition[0]);
        geofenceWrapper.fitBounds();
      }
    }, [latestPosition])

    React.useEffect(() => {
      geofenceWrapper.setInitialFences(initialFences);
    }, [initialFences])

    React.useEffect(() => {
      geofenceWrapper.setFences(geofence);
    }, [geofence])

    React.useEffect(() => {
      geofenceWrapper.setIndoorLocations(indoorLocations);
    }, [indoorLocations])

    React.useEffect(() => {
      geofenceWrapper.select(values.geofence.id)
      geofenceWrapper.resetFences();
      if(mapLoaded){
        geofenceWrapper.fitBounds();
      }
    }, [values.geofence.id, refreshToken])

    React.useEffect(() => {
      geofenceWrapper.selectIndoorMarker(values.geofence.inloIds)
    }, [values.geofence.inloIds])

    React.useEffect(() => {
      geofenceWrapper.toggleEnable();
    }, [values.geofence.enabled])

    React.useEffect(() => {
      if(values.geofence.copy)
        geofenceWrapper.copyFence(values.geofence);
    }, [values.geofence.copy])

    React.useEffect(() => {
      if(searchRef.current)
        geofenceWrapper.setSearchBox(searchRef.current);
    },[searchRef.current])

    React.useEffect(() => {
      geofenceWrapper.removeNew();
        if(geofenceType !== GeofenceTypeEnum.NONE && geofenceType !== GeofenceTypeEnum.INDOOR && geofenceWrapper.getDrawingManager()){
          geofenceWrapper.getDrawingManager().setOptions({
            drawingMode : geofenceType
          });
        } else if(geofenceType === GeofenceTypeEnum.INDOOR && mapLoaded){
          setFieldValue("geofence.vertices", null);
          geofenceWrapper.disableDrawingManager();
          geofenceWrapper.createIndoorMarkers();
        }
    }, [geofenceType])

    React.useEffect(() => {
      if( values.geofence !== undefined && values.geofence.vertices && values.geofence.vertices.length > 0 && values.geofence.inEdit && geofenceType !== GeofenceTypeEnum.INDOOR ){
        const geofenceDistance = getGeofenceDistance(values.geofence);
        getFenceDistance(geofenceDistance);
      }
    }, [values.geofence.vertices, values.geofence.radius])

    let lang = getGoogleMapLanguageCode(languageCode);

    return(
      <MapWrapper>
        <GoogleMap 
          defaultZoom={14} 
          defaultCenter={{ lat: posLati ? parseFloat(posLati) : 59.5058648, lng: posLong ? parseFloat(posLong) : 9.1907483 }}
          bootstrapURLKeys={
            {
              key: 'AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no',
              language: lang,
              v: '3',
              libraries: ['drawing','places']
            }
          }
          options={(map: Maps) => {
            return {
              streetViewControl: true,
              mapTypeControl: true,
              maxZoom: 18,
              mapTypeControlOptions: {
                mapTypeIds: [
                  map.MapTypeId.ROADMAP,
                  map.MapTypeId.SATELLITE,
                  map.MapTypeId.TERRAIN
                ]
              }
            }
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            geofenceWrapper.init(map, maps, initialFences);
            geofenceWrapper.createFences(geofence);
            geofenceWrapper.select(values.geofence.id);
            geofenceWrapper.resetFences();
            if(searchRef.current){
              geofenceWrapper.setSearchBox(searchRef.current);
            }
            setMapLoaded(true);
          }}
        />
        <Search ref={searchRef} placeholder={formatMessage({id: "enterAddress", defaultMessage: "Oppgi adresse"})}/>
        <UnsavedChanges
          headerText={formatMessage({id: "navigationWithoutSaveTitle", defaultMessage: "Ulagrede endringer"})}
          bodyText={formatMessage({id: "navigationWithoutSaveDesc", defaultMessage: "Du er i ferd med å forlate siden, men du har ulagrede endringer som vil gå tapt. Vil du fortsette?"})}
          display={unsaved.modal}
          handleCancel={() => {
            setUnsaved({
              modal: false,
              func: () => {}
            });
          }}
          handleContinue={() => {
            unsaved.func();
            setUnsaved({
              modal: false,
              func: () => {}
            });
          }}
        />
      </MapWrapper>
  )}
))

const mapStateToProps = ({settings: {geofence: {geofence, geofenceType, initialFences, refreshToken, latestPosition, indoorLocations}, device: {device: {deviId, posLong, posLati}}, initialization:{user: {languageCode}}}}: AppState) => {
  return{
    geofence,
    deviId,
    posLong,
    posLati,
    geofenceType,
    initialFences,
    refreshToken,
    languageCode,
    latestPosition,
    indoorLocations
  }
}

const mapDispatchToProps = {
  setGeofenceType,
  setControlState,
  getFenceDistance,
  getLatestPosition
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
