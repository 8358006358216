import {call, put, takeLatest, select, take, race} from 'redux-saga/effects';

import {AppAction, Customer, RootState, MappedSubOrgs} from "../types";
import * as Routines from '../routines';
import * as Api from '../api';
import { feedbackFailure, feedbackSuccess, feedbackFailureString } from '../../../Feedback/reducer';
import { OrganizationForm } from '../../Form/types';
import { IRace } from '../../../Store/types';
import { accessToCustomer } from '../../../Policy/rightsUtil';
import { PolicyNameEnum } from '../../../PolicyNameEnum';
import { GlobalRoles } from '../../../GlobalRoles';
import { StringLiteral } from '@babel/types';
import * as Utils from '../../Form/utils';
import { getCustomersForForm } from '../selectors';
import { getSuborgsWithRoles } from '@Safemate/Store/routines';


export interface PostalProps{
  pnr: string,
  isBilling: boolean
}

export interface CustomerProps{
  customer: Customer;
  customerKey: number;
}


function* selectCustomer(action: AppAction<Customer>){
  try{
    const { customerId, parent } = action.payload;
    const paymentDetail = yield call(Api.getPaymentOptions, customerId, parent);
    const customersForForm = yield select(getCustomersForForm);
    let paidByCustomer = "";
    let parentCustomer = "";
    const customer = action.payload;
    if(parent > 0){
      paidByCustomer = Utils.findClosestPaidByParent(customer.parent, customersForForm);
      parentCustomer = customersForForm[customer.parent];
    }
    yield put(Routines.getPayment.success(paymentDetail));
    yield put(Routines.selectCustomer.success({customer, parentCustomer, paidByCustomer}));
  }
  catch(e){
    console.log(e)
    yield put(Routines.selectCustomer.failure());
  }
}

function* fetchCustomersForForm(action: AppAction<null>){
  try{
    const customers = yield call(Api.getCustomersForForm);
    yield put(Routines.fetchCustomersForForm.success(customers));
  }
  catch(e){
    yield feedbackFailure(e)
  }
}

function* fetchCustomersForList(action: AppAction<null>){
  try{
    const customers = yield call(Api.getCustomersForList);
    yield put(Routines.fetchCustomersForList.success(customers));
  }
  catch(e){
    yield feedbackFailure(e)
  }
}

export interface SaveFormInterface{
  formValues: OrganizationForm;
  selectedCustomer: Customer;
  errorParentMessage: string;
  saveMessage: string;
}

function* saveCustomer(action: AppAction<SaveFormInterface>){
  try{
    const { formValues, formValues: { pptyId }, saveMessage } = action.payload;
    
    const response: OrganizationForm = yield call(Api.saveForm, {pptyId, formValues});
    yield put(Routines.fetchCustomersForForm.trigger());
    yield put(Routines.fetchCustomersForList.trigger());
    const {success, failure}: IRace<Array<Customer>> = yield race({
      success: take(Routines.fetchCustomersForList.SUCCESS),
      failure: take(Routines.fetchCustomersForList.FAILURE)
    });
    if(success){
      const selectedFirm: Customer = success.payload.find(customer => customer.customerId === response.custId) as Customer;
      yield put(Routines.selectCustomer.trigger(selectedFirm));
      yield put(getSuborgsWithRoles.trigger());
    }
    yield put(Routines.saveCustomer.success());
    yield feedbackSuccess(saveMessage + " " + formValues.lastName);
    

  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveCustomer.failure());
  }
}


function* deleteCustomer(action: AppAction<number>){
  try {
    yield call(Api.deleteSubOrg , action.payload);
    yield put(Routines.fetchCustomersForForm.trigger());
    yield put(Routines.fetchCustomersForList.trigger());
    const {success, failure}: IRace<Array<Customer>> = yield race({
      success: take(Routines.fetchCustomersForList.SUCCESS),
      failure: take(Routines.fetchCustomersForList.FAILURE)
    });
    if(success){
      const selectedFirm: Customer = success.payload.find(customer =>
        accessToCustomer(customer.policies, PolicyNameEnum.SUB_ORGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM])) as Customer;
      yield put(Routines.selectCustomer.trigger(selectedFirm));
    }
    yield put(Routines.deleteCustomer.success());
    yield feedbackSuccess("subUnitDeleted");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteCustomer.failure());
  }
}

function* getPostalCity(action: AppAction<PostalProps>){
  try{
    const postalCity = yield call(Api.getPostCity, action.payload.pnr);
    if(action.payload.isBilling){
      yield put(Routines.getBillingPostCity.success(postalCity));
    }else{
      yield put(Routines.getPostCity.success(postalCity));
    }
  }
  catch(e){
    yield put(Routines.getPostCity.failure());
  }
}

function* setPaidByCustomer(action: AppAction<number>){
  try{
    const customersForForm = yield select(getCustomersForForm);
    let paidByCustomer = "";
    const customer = customersForForm[action.payload];
    const parentCustomerId = action.payload;
    if(customer.firm.parent > 0){
      paidByCustomer = Utils.findClosestPaidByParent(parentCustomerId, customersForForm);
    }else{
      paidByCustomer = customer;
    }
    yield put(Routines.setPaidByCustomer.success(paidByCustomer));
  }
  catch(e){
    yield put(Routines.setPaidByCustomer.failure());
  }
}

function* customerSaga(){
  yield takeLatest(Routines.selectCustomer.TRIGGER, selectCustomer);
  yield takeLatest(Routines.fetchCustomersForForm.TRIGGER, fetchCustomersForForm);
  yield takeLatest(Routines.fetchCustomersForList.TRIGGER, fetchCustomersForList);
  yield takeLatest(Routines.saveCustomer.TRIGGER, saveCustomer);
  yield takeLatest(Routines.deleteCustomer.TRIGGER, deleteCustomer);
  yield takeLatest(Routines.getPostCity.TRIGGER, getPostalCity);
  yield takeLatest(Routines.getBillingPostCity.TRIGGER, getPostalCity);
  yield takeLatest(Routines.setPaidByCustomer.TRIGGER, setPaidByCustomer);
}

export default customerSaga;
