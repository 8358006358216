import { AppState } from "@Safemate/Store/types";
import { RootState } from "./types";

export const getDehaId = ({ settings: {device: { device:  {dehaId} }}}: AppState) => dehaId;
export const getDeviId = ({ settings: {initialization: { deviId }}}: AppState) => deviId;
export const getCustId = ({ settings: {device: { device: { ownedByCustomer }}}}: AppState) => ownedByCustomer; 

export const getSensors = ({ settings: {sensor: { sensors }}}: AppState) => sensors;

export const getRoles = ({ settings: {initialization: { roles }}}: AppState) => roles;
