import {SettingsActionTypes as Actions} from "./actionTypes";
import { AppAction } from "../../../Safemate/Store/types";
import { Geofence } from "../Geofence/types";
import { GeofenceTypeEnum } from "../Geofence/GeofenceTypeEnum";
import { Layout } from "../LayoutEnum";
import { ControlState } from "../Geofence/Controls";
import { DeviceMac } from "../Indoor/types";

// Init

export type SetLayout = (layout: Layout) => AppAction<Layout>

export const setLayout: SetLayout = (layout) => ({
  type: Actions.SET_LAYOUT,
  payload: layout
})

// Geofence
export type SetEditing = (editing: boolean) => AppAction<boolean>
export type SetGeofence = (geofence: Geofence) => AppAction<Geofence>
export type SetGeofenceType = (type: GeofenceTypeEnum) => AppAction<GeofenceTypeEnum>
export type SetControlState = (state: ControlState) => AppAction<ControlState>
export type SetRefresh = (token: string) => AppAction<string>;
export type SetInitialSelection = (status: boolean) => AppAction<boolean>;

export const setEditing: SetEditing = (editing) => ({
  type: Actions.SET_EDITING,
  payload: editing
});

export const setGeofence: SetGeofence = (geofence) => ({
  type: Actions.SET_GEOFENCE,
  payload: geofence
});

export const setGeofenceType: SetGeofenceType = (type) => ({
  type: Actions.SET_GEOFENCE_TYPE,
  payload: type
})

export const setControlState: SetControlState = (state) => ({
  type: Actions.SET_CONTROL_STATE,
  payload: state
})

export const setRefresh: SetRefresh = (token) => ({
  type: Actions.SET_GEOFENCE_REFRESH,
  payload: token
})

export const setInitialSelection: SetInitialSelection = (status) => ({
  type: Actions.SET_INITIAL_SELECTION,
  payload: status
}) 

// Tracking
export type SetTrackingAdvanced = (advanced: boolean) => AppAction<boolean>

export const setTrackingAdvanced: SetTrackingAdvanced = (advanced) => ({
  type: Actions.SET_TRACKING_ADVANCED,
  payload: advanced
})

// Indoor

export type SetDeviceMac = (deviceMac : DeviceMac) => AppAction<DeviceMac>

export const setDeviceMac: SetDeviceMac = (deviceMac) => ({
  type: Actions.SET_INDOOR_DEVICE_MAC,
  payload: deviceMac
})

export type SetDeviceMacs = (deviceMacs : DeviceMac[]) => AppAction<DeviceMac[]>

export const setDeviceMacs: SetDeviceMacs = (deviceMacs) => ({
  type: Actions.SET_INDOOR_DEVICE_MACS,
  payload: deviceMacs
})

export type SetOpenDevice = (openDevice : number) => AppAction<number>

export const setOpenDevice: SetOpenDevice = (openDevice) => ({
  type: Actions.SET_INDOOR_OPEN_DEVICE,
  payload: openDevice
})

export type SetValuesChanged = (flag : boolean) => AppAction<boolean>

export const setValuesChanged: SetValuesChanged = (flag) => ({
  type: Actions.SET_INDOOR_VALUES_CHANGED,
  payload: flag
})

export type SetSelectedIndexes = (indexes : number[]) => AppAction<number[]>

export const setSelectedIndexes: SetSelectedIndexes = (indexes) => ({
  type: Actions.SET_INDOOR_SELECTED_INDEXES,
  payload: indexes
})

export type SetActiveIndex = (index : number) => AppAction<number>

export const setActiveIndex: SetActiveIndex = (index) => ({
  type: Actions.SET_INDOOR_ACTIVE_INDEX,
  payload: index
})

export type SetScannedMinutes = (scannedMinutes : number) => AppAction<number>

export const setScannedMinutes: SetScannedMinutes = (scannedMinutes) => ({
  type: Actions.SET_INDOOR_SCANNED_MINUTES,
  payload: scannedMinutes
})


