import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import customerSaga from "./customer";

function* SettingsSaga(){
  yield all([
    fork(initializationSaga),
    fork(customerSaga)
  ])
}
export default SettingsSaga;
