import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";

import { deleteGeofence } from "../../Store/routines";
import { connect } from "react-redux";
import { GeofenceValues } from "..";
import withFormWrapper from "@Elements/Form/formWrapper";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import confirmModal from "../../../Modals/confirmModal";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";

export const CancelButton = styled(Button)`
  color: white;
  background: red;
  margin-right: 15px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  svg {
    height: 24px;
    width: 24px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  border-top: ${props => props.theme.colors.border};
  display: flex;
  padding-top: 15px;
`;

export const SaveButton = styled(Button)`
  background: ${props => props.theme.colors.accent};
  margin-left: auto;
`

interface SaveProps extends WrappedComponentProps{
  deleteGeofence: Routine<ActionFunctionAny<Action<any>>>;
  processDelete: boolean;
  processSave: boolean;
}

const mapDispatchToProps = {
  deleteGeofence
}

const mapStateToProps = ({ settings: {geofence: { processDelete, processSave }}}: AppState) => {
  return {
    processDelete,
    processSave
  }
}

const Save = connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(withFormWrapper<GeofenceValues, SaveProps>(({ deleteGeofence, processDelete, processSave, formik: { values, dirty }, intl: { formatMessage } }) => {

    const {Comp, func} = confirmModal();

    return(
      <Footer>
        {processDelete ? <LoaderWrapper><Loader/></LoaderWrapper> :
        <CancelButton disabled={!values.geofence.editEnabled} onClick={() => func(true)}>
          {formatMessage({ id: "delete", defaultMessage: "Slette" })}
        </CancelButton>}
        {processSave ? <LoaderWrapper><Loader/></LoaderWrapper> :
        <SaveButton disabled={!dirty || !values.geofence.editEnabled} type="submit">
          {formatMessage({id: "save", defaultMessage: "Lagre"})}
        </SaveButton>}
        <Comp
          title={formatMessage({id: "deleteGeofence", defaultMessage: "Slett geofence"})}
          body={
            <p>
              {`${formatMessage({id: "deleteGeofenceDesc", defaultMessage: "Er du sikker på at du vil slette"})} ${values.geofence.name}?`}
            </p>
          }
          maxWidth="800px"
          confirmText={formatMessage({id: "delete", defaultMessage: "Slett"})}
          confirmFunc={() => {
            deleteGeofence(values);
            func(false);
          }}
        />
      </Footer>
    )
  }))
)

export default Save;
