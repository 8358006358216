import {OrganizationForm,} from "./types";
import {  MappedSubOrgs } from "../Store/types";

export const populateForm = (values: OrganizationForm, billingCode: string, reflectBillingAddress: boolean) => {
  const fillForm: OrganizationForm ={
    activatedDate: values.activatedDate || 0,
    addrCity: values.addrCity || '',
    addrCounId: values.addrCounId || 0,
    addressLine1: values.addressLine1 || '',
    addressLine2: values.addressLine2 || '',
    addrName: values.addrName || '',
    addrNameForBilling: values.addrName || '',
    addrReference: values.addrReference || '',
    addrSamesame: values.addrSamesame || false,
    addrZipCode: values.addrZipCode || '',
    allowAllPhoneNo: values.allowAllPhoneNo || false,
    allowDeviceDeletes: (values.allowDeviceDeletes == null || values.allowDeviceDeletes == undefined) ? true : values.allowDeviceDeletes,
    billingAddrCity: (reflectBillingAddress ? values.addrCity : values.billingAddrCity) || '',
    billingAddrCounId: values.addrCounId || 0,
    billingAddrLine1: (reflectBillingAddress ? values.addressLine1 : values.billingAddrLine1) || '',
    billingAddrLine2: (reflectBillingAddress ? values.addressLine2 : values.billingAddrLine2) || '',
    billingAddrName: (reflectBillingAddress ? values.addrName : values.billingAddrName) || '',
    billingAddrReference: (reflectBillingAddress ? values.addrReference : values.billingAddrReference) || '',
    billingAddrZipCode: (reflectBillingAddress ? values.addrZipCode : values.billingAddrZipCode) || '',
    billingCode: billingCode,
    company: values.company || false,
    custId: values.custId || 0,
    defaultAhp: values.defaultAhp || 0,
    email: values.email || '',
    firstName: values.firstName || '',
    forceCoverage: values.forceCoverage || false,
    forceIncomingRing: values.forceIncomingRing || false,
    forceIncomingRingAlways: values.forceIncomingRingAlways || false,
    forceTfa: values.forceTfa || false,
    integrationId: values.integrationId || '',
    integrationRole: values.integrationRole || '',
    invoiceBalanceExclVat: values.invoiceBalanceExclVat || 0,
    invoiceCreditLimitExclVat: values.invoiceCreditLimitExclVat || 0,
    lastChanged: values.lastChanged || 0,
    lastChangedBy: values.lastChangedBy || 0,
    lastName: values.lastName || '',
    mobile: values.mobile || '',
    orgNum: values.orgNum || '',
    parent: values.parent || 0,
    prgrId: values.prgrId || 0,
    pptyId: values.billingCode || billingCode,
    status: values.status || 0,
    tempAhp: 0,
  }
  return fillForm;
}

export const emptyForm = (parent: number, iniSame: boolean) => {
  const fillForm: OrganizationForm ={
    activatedDate: 0,
    addrCity: '',
    addrCounId:  1,
    addressLine1: '',
    addressLine2:  '',
    addrName:  '',
    addrNameForBilling: '',
    addrReference: '',
    addrSamesame:  iniSame,
    addrZipCode: '',
    allowAllPhoneNo:null,
    allowDeviceDeletes: true,
    billingAddrCity: '',
    billingAddrCounId:  null,
    billingAddrLine1:  '',
    billingAddrLine2:  '',
    billingAddrName:  '',
    billingAddrReference:  '',
    billingAddrZipCode:  '',
    billingCode: "3",
    company:  true,
    custId:  0,
    defaultAhp:  0,
    email:  '',
    firstName:  '',
    forceCoverage:  null,
    forceIncomingRing: null,
    forceIncomingRingAlways: null,
    forceTfa:  null,
    integrationId:  null,
    integrationRole: null,
    invoiceBalanceExclVat: null,
    invoiceCreditLimitExclVat:  null,
    lastChanged: null,
    lastChangedBy:  null,
    lastName: '',
    mobile:  '',
    orgNum:  null,
    parent,
    prgrId: 0,
    pptyId: "3",
    status: 1,
    tempAhp: 0,
  }
  return fillForm;
}

//True or false values can either be set to "true"/"false" (strings) or "1"/"0" (strings).
export const parseCheckboxValue = (checkboxString: boolean, value: string): boolean => {
  return checkboxString ? value === "true" : value === "1";
}

export const convertToBaseCheckboxValue = (checkboxString: boolean, value: boolean): string => {
  return checkboxString ? value ? "true" : "false" : value ? "1" : "0";
}

export const findClosestPaidByParent = (parentCustomerId: number, customersForForm: MappedSubOrgs) => {
  if(customersForForm[parentCustomerId].paymentProfile.pptyId != 3){
    return customersForForm[parentCustomerId];
  }else{  
    if(customersForForm[parentCustomerId].firm.parent == 0){
      return customersForForm[parentCustomerId];
    }else{
      return findClosestPaidByParent(customersForForm[parentCustomerId].firm.parent, customersForForm);
    }
  }
}

