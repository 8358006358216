
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Routines from '../routines';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import * as Api from '../api';
import { AppAction } from '../../../DefaultSettings/Store/types';

function* setMapConsent(action: AppAction<null>){
  try{
    yield call(Api.setMapConsent);
    yield put(Routines.setMapConsent.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.setMapConsent.failure());
  }
}
function* statusSaga(){
    yield takeEvery(Routines.setMapConsent.TRIGGER, setMapConsent);
}

export default statusSaga;
