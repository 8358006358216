import {call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import { Feedback } from '../../labels';
import * as Routines from "../routines";
import { feedbackFailure, feedbackSuccess } from '@Safemate/Feedback/reducer';

export interface GetDevice{
  deviceId: string;
}

function* getDevice(action: AppAction<GetDevice>){
  try{
    const device = yield call(Api.getDevice, action.payload);
    yield put(Routines.getDevice.success(device));
  }
  catch(e){
    yield put(Routines.getDevice.failure());
  }
}

function* syncDevice(action: AppAction<number>){
  try {
    const device = yield call(Api.syncDevice, action.payload);
    yield put(Routines.syncDevice.success(device));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.syncDevice.failure());
  }
  yield put(Routines.syncDevice.fulfill());
}

function* smsReboot(action: AppAction<number>){
  try {
    yield call(Api.smsReboot, action.payload);
    yield put(Routines.smsReboot.success());
    yield feedbackSuccess(Feedback.SMS_REBOOT);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.smsReboot.failure());
  }
  yield put(Routines.smsReboot.fulfill());
}

function* smsReset(action: AppAction<number>){
  try {
    yield call(Api.smsReset, action.payload);
    yield put(Routines.smsReset.success());
    yield feedbackSuccess(Feedback.SMS_RESET);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.smsReset.failure());
  }
  yield put(Routines.smsReset.fulfill());
}

function* factoryReset(action: AppAction<number>){
  try {
    yield call(Api.factoryReset, action.payload);
    yield put(Routines.factoryReset.success());
    yield feedbackSuccess(Feedback.FACTORY_RESET);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.factoryReset.failure());
  }
  yield put(Routines.factoryReset.fulfill());
}

function* deviceSaga(){
    yield takeLatest(Routines.syncDevice.TRIGGER, syncDevice);
    yield takeLatest(Routines.smsReboot.TRIGGER, smsReboot);
    yield takeLatest(Routines.smsReset.TRIGGER, smsReset);
    yield takeLatest(Routines.factoryReset.TRIGGER, factoryReset);
    yield takeLatest(Routines.getDevice.TRIGGER, getDevice);
}

export default deviceSaga;
