import React from 'react';
import SIcon from './SIcon';
import { FormattedMessage } from 'react-intl';

const FallSensor = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M14.686 6.19c.036-.445-.114-.816-.394-1.242-.54-.537-1.302-.553-1.914-.198l-.777.745-.093.038-2.507-.066c-.093.038-.223-.017-.315.02.017-.223-.002-.538-.022-.854-.132-.594-.45-1.113-1.008-1.426-.818-.425-1.95-.29-2.673.324-.536.54-.868 1.322-.623 2.194.132.594.45 1.112 1.007 1.426.428.259.781.332 1.227.367-.239.744-.087 1.653.584 2.245l5.926 6.126c.578.63 1.525.57 2.061.03.63-.578.57-1.524.03-2.061l-1.453-1.462.093-.037 1.824 1.312c.596.406 1.542.347 2.134-.323.204-.298.37-.689.312-1.097a1.389 1.389 0 0 0-.597-.945l-1.992-1.46c-.466-.351-1.08-.535-1.58-.44l-1.578.098-.876-.833 1.262-.078L14.3 7.102c.296-.336.351-.466.387-.912zm-9.05-1.844c.389-.372 1.02-.412 1.54-.19.727.461.786 1.408.249 1.948-.481.41-1.113.45-1.54.191a1.255 1.255 0 0 1-.249-1.949zm8.837 10.127c.205.24.188.464-.053.669-.24.205-.464.187-.669-.053l-2.664-2.591 1.262-.079 2.124 2.054zm-2.14-6.9l-2.6-.028-.186.075c-.093.037-.11.26.02.315l2.404 2.48 1.986-.155c.316-.02.669.053.967.256l1.992 1.46c.167.148.28.427.077.724l-.093.038a.48.48 0 0 1-.631.04l-1.955-1.368c-.13-.055-.26-.11-.353-.073l-3.063.159c-.409.057-.93-.164-1.172-.497L7.862 9.055c-.54-.536-.562-1.39-.062-2.024l.092-.037c.296-.335.76-.523 1.113-.45l2.915.009.926-.913c.24-.205.463-.188.668.053.205.24.188.464-.053.669l-1.129 1.21zM8.23 13.436c-.148.168-.37.15-.594.132-.427-.258-.893-.61-1.358-.96-.373-.39-.783-.87-.97-1.334a.55.55 0 0 1 .09-.576c.24-.205.594-.132.8.109.242.333.522.76.857 1.055.335.296.67.592 1.098.85.26.111.28.427.077.724zm.027 2.47c-1.134-.405-2.25-1.032-3.2-2.05-.914-.925-1.586-2.056-2.036-3.168-.075-.186-.057-.409.129-.484.203-.297.686-.17.798.109.412 1.02 1.01 1.965 1.793 2.835s1.77 1.442 2.773 1.792c.39.165.578.629.244.872-.148.167-.333.242-.5.094z" />
    <title>
      <FormattedMessage id="fallAlarm" defaultMessage="Fallalarm" />
    </title>
  </svg>
);

const Icon = props => <SIcon {...props} icon={FallSensor} />;

export default Icon;
