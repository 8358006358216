import * as React from "react";
import withFormWrapper from "@Elements/Form/formWrapper";
import { Field, FormikProps } from "formik";
import { Checkbox } from "../../../Elements/CheckboxFormik";
import { injectIntl, WrappedComponentProps, MessageDescriptor } from "react-intl";
import { SettingDiv, SettingHeader, TitleWrap } from "./styles";
import InformationIcon from "@Icons/Information1";
import Tooltip from "@Elements/Tooltip";

interface CheckboxProps<T> extends WrappedComponentProps{
    formik: FormikProps<T>;
}

interface CheckboxOptions{
    label: MessageDescriptor;
    prefix?: string;
    tooltip?: string;
    tooltipTitle?: MessageDescriptor
  }

type StrIndex<TValue> = {
    [key: string]: TValue
}

export function CheckWrap<T>(name: string, options: CheckboxOptions): React.FunctionComponent<any>{
    return withFormWrapper(
        injectIntl(({intl: {formatMessage}, formik: {values}}: CheckboxProps<StrIndex<T>>) => {
            return(
            <SettingDiv key={name}>
                {options.tooltip &&
                <Tooltip
                    popoverId={`${name}-popover`}
                    labelId={options.tooltip}
                    defaultMessage={options.tooltip}
                    title={options.tooltipTitle ? formatMessage(options.tooltipTitle) : ""}
                >
                    <InformationIcon pointer size="medium" />
                </Tooltip>
                }
                <Field
                    value={values[name]}
                    name={name}
                    id={name}
                    label={formatMessage(options.label)}
                    component={Checkbox}
                />
            </SettingDiv>)
        })
    ) as React.FunctionComponent<any>
}

export function CheckWrapPrivate<T>(name: string, label: MessageDescriptor): React.FunctionComponent<any>{
    return withFormWrapper(
        injectIntl(({intl: {formatMessage}, formik: {values}}: CheckboxProps<StrIndex<T>>) => 
            <Field
                value={values[name]}
                name={name}
                id={name}
                label={formatMessage(label)}
                component={Checkbox}
                outerDiv={{

                }}
            />
        )
    ) as React.FunctionComponent<any>
}

export function CheckWrapPrivateTab<T>( position:number, connectionArray:boolean[], onChanageFunc: Function, device: any): React.FunctionComponent<any>{
    return withFormWrapper(
        injectIntl(({intl: {formatMessage}, formik: {values}}: CheckboxProps<StrIndex<T>>) => 
            <Field
                value={connectionArray[position]}
                checked={connectionArray[position]}
                name={`device-${position}`}
                id={`device-${position}`}
                component={Checkbox}
                onChange={() => onChanageFunc(position, device)}
            />
        )
    ) as React.FunctionComponent<any>
}



