import {connect} from "react-redux";
import * as React from "react";

import List from "../../../Elements/SpacedList";
import {header, partnerHeader} from "../options";
import {FilterAccountStatus, FilterState} from "../Store/types";
import {UserWithCustomer} from "../Models/UserWithCustomer";
import {mapDeletedUsers, mapUsers, getExportData, mapHeader} from "./utils";
import {Role} from "../Models/Role";
import Header from "./Header";
import {LoaderWrap} from "./Edit/styles";
import {DeletedUser} from "../Models/User";
import DeleteModal from "../../Modals/DeleteModal";
import {SetDeleteModal, setDeleteModal} from "../Store/actions";
import {deleteUser} from "../Store/routines";
import {Routine} from "redux-saga-routines";
import {ActionFunctionAny} from "redux-actions";
import {UserToDelete} from "../Store/Saga/actions";
import TableExport from "../../../Elements/Export/TableExport";
import { Row, Col } from "react-bootstrap";
import { SiteTypeEnum } from "../../SiteTypeEnum";
import Loader from "@Elements/Loader";
import { AppAction, AppState, GdprManager } from "@Safemate/Store/types";
import { useIntl } from "react-intl";

interface UserProps{
  deleteUser: Routine<ActionFunctionAny<AppAction<UserToDelete>>>;
  users: Array<UserWithCustomer>;
  deletedUsers: Array<DeletedUser>;
  roles: Array<Role>;
  userInitialized: boolean;
  filter: FilterState;
  setDeleteModal: SetDeleteModal;
  deleteModal: UserWithCustomer | null;
  siteType: SiteTypeEnum;
  isPartner: boolean;
  isPrivate: boolean;
  isAdminSso: boolean;
  custId: number;
  gdprManager: GdprManager;
}

const mapStateToProps = ({appData: { gdprManager, currentContext, isPartner, isPrivate, isAdminSso, superfirm: {custId}}, users: {user: {users, deletedUsers}, action: {deleteModal}, filter, initialization: {initializedAll}, role: {roles}}}: AppState) => {
  return{
    users,
    deletedUsers,
    roles,
    userInitialized:initializedAll,
    filter,
    deleteModal,
    siteType: currentContext,
    isPartner,
    isPrivate,
    isAdminSso,
    custId,
    gdprManager
  }
}

const mapDispatchToProps = {
  setDeleteModal,
  deleteUser
}

const Users = connect(mapStateToProps, mapDispatchToProps)(
  ({gdprManager, isPartner, isPrivate, isAdminSso, custId, users, deletedUsers, setDeleteModal, deleteModal, deleteUser, userInitialized, roles, filter, siteType}: UserProps) => {

    const { formatMessage } = useIntl();

    const isAhp = siteType === SiteTypeEnum.AHP;

    const mappedUsers = React.useMemo(() => mapUsers(users, roles, filter, formatMessage, isAhp, isPrivate, isPartner, isAdminSso), [users, roles, filter, siteType, formatMessage]);
    const mappedDeletedUsers = React.useMemo(() => mapDeletedUsers(deletedUsers, roles, filter, formatMessage), [deletedUsers, roles, filter, formatMessage]);
    const mappedHeader = mapHeader(formatMessage, isAhp, isPrivate, isPartner, filter.status === FilterAccountStatus.ACTIVE);

    const exportData  = getExportData(mappedHeader,(filter.status === FilterAccountStatus.ACTIVE) ? mappedUsers : mappedDeletedUsers, formatMessage );


    const disableDelete = deleteModal && deleteModal.user.userId == gdprManager.userId;

    return (
      <React.Fragment>
        <Header/>
        {!userInitialized
          ? <LoaderWrap><Loader/></LoaderWrap>
          : 
          <React.Fragment>
          {(!isPrivate && mappedUsers.length > 1) && <Row>
            <Col md={12} style={{ float: "right", textAlign: "right" }}>
              <TableExport type="csv" data={exportData} fileName="users" />
              <TableExport type="pdf" data={exportData} fileName="users" colWidth={
                filter.status === FilterAccountStatus.ACTIVE ? [25, 10, 7, 25, 5, 10, 25] : [26, 12, 26, 6, 10, 20]
              } />
            </Col>
          </Row>}
          <List
            header={mappedHeader}
            content={(filter.status === FilterAccountStatus.ACTIVE || isPrivate || mappedUsers.length < 2) ? mappedUsers : mappedDeletedUsers}
          />
          </React.Fragment>
        }
        <DeleteModal
          disableDelete={disableDelete}
          handleCancel={() => setDeleteModal(null)}
          confirmDelete={() => !disableDelete && deleteUser({userId: deleteModal && deleteModal.user.userId, custId})}
          display={deleteModal !== null}
          headerText={`${formatMessage({
            id: "deleteUser",
            defaultMessage: "Slett bruker"
          })}: ${deleteModal && deleteModal.user.name}`}
          bodyText={disableDelete
            ? formatMessage({id: "gdprUserDeleteDesc", defaultMessage: "Kan ikke slette en behandingsansvarlig."})
            : formatMessage({
            id: "deleteUserDesc",
            defaultMessage: "Er du sikker på at du vil slette denne brukeren?"
          })}
          translations={(id: string, defaultMessage: string) => formatMessage({id, defaultMessage})}
        />
      </React.Fragment>
    )
  }
);

export default Users;
