import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import styled from "styled-components";
import { Col, Row,  } from "react-bootstrap";

import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {connect as formikConnect, FormikProps} from "formik";
import {RootState, Organization, PostCity, Firm} from "../Store/types";
import {Action, ActionFunctionAny} from "redux-actions";
import { getPostCity} from "../Store/routines";

import Toggle from "@Elements/Switch/Toggle";


const Label = styled.label`
  text-transform: capitalize;
  display: block;
  @media only screen and (min-width: 992px) {
    padding-left: 20px;
  }
`;

const StyledMainRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center;
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }

`;

interface TFAProps<T> extends WrappedComponentProps{
  base: Organization;
  formik: FormikProps<T>;
  getPostCity: Routine<ActionFunctionAny<Action<any>>>;
  postCity: PostCity;
}
const mapStateToPropsZip = ( {subUnits: {customers}, customer: {postCity}} : RootState) => {
  return{
    base: customers,
    postCity
  }
};
const mapDispatchFromStateZip = {
  getPostCity
}

export const Tfa = connect(mapStateToPropsZip, mapDispatchFromStateZip)(
  formikConnect(
    injectIntl(
      ({intl: {formatMessage}, formik: {  setFieldValue, values}, postCity, base, getPostCity }: TFAProps<Firm>) => {
        const [ twoFactorAuth, setTwoFactorAuth ] = React.useState(false);
        React.useEffect(() => {
          //setTwoFactorAuth(values.forceTfa);
          if(twoFactorAuth != values.forceTfa){
            //setFieldValue("forceTfa", twoFactorAuth);
            setTwoFactorAuth(values.forceTfa);
          }
        })
        

        const setTfa = (value: boolean) => {
          setFieldValue("forceTfa", value);
          setTwoFactorAuth(value);
        }

      return(
        <StyledMainRow>
        <Col md={4}>
          <Label>{formatMessage({id: "TofaktorAutentisering", defaultMessage: "Tofaktor-autentisering"})}</Label>
        </Col>      
        <Col md={8}>
          <span>
            <Toggle
              onChange={(value: boolean) => {
                setTfa(value);
              }}
              checked={twoFactorAuth}
            />
          </span>
          </Col>
        </StyledMainRow>
        )
      })
    )
  )