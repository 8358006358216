
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Routines from '../routines';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import * as Api from '../api';
import { AppAction } from '../../../DefaultSettings/Store/types';

function* notificationEvents(action: AppAction<number>){
  try{
    const notificationEvents = yield call(Api.getNotificationEvents, action.payload);
    yield put(Routines.getNotificationEvents.success({events: notificationEvents, id: action.payload}));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getNotificationEvents.failure());
  }
}
function* notificationEventsSaga(){
    yield takeEvery(Routines.getNotificationEvents.TRIGGER, notificationEvents);
}

export default notificationEventsSaga;
