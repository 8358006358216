import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { AppState } from "@Safemate/Store/types";

export const getCurrentDeviceHardwareId = ({ defaultSettings: {deviceHardware}}: AppState): number =>
  deviceHardware.selectedDeviceHardwareId;

export const getCurrentCustomer = ({defaultSettings: {customer}}: AppState): ICustomerWithPolicy =>
  customer.selectedCustomer;

export const getCurrentCustomerId = ({defaultSettings: {customer}}: AppState): number =>
  customer.selectedCustomer.customerId;
