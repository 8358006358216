import {initializeAlarmHelper} from '../routines';
import {AlarmState,} from "../types";
import {Reducer} from "redux";

const alarmSettings: AlarmState = {
  alarms: [],
};

const alarmState: Reducer<AlarmState> = (state: AlarmState = alarmSettings, action: any) => {
  switch (action.type) {
    case initializeAlarmHelper.SUCCESS:
      return {
        ...state,
      alarms: action.payload
      };

    default:
        return state;
  }
};

export default alarmState;
