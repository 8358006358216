import * as React from "react";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import { ComponentWrapper, StyledForm, SettingWrapper, StyledWrapper } from "../styled";
import { useIntl } from "react-intl";
import { DeviceComponents } from "@Safemate/Settings/tabDefinition";
import { SettingEnum as ComponentSettingEnum } from "../../../settingEnum";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";
import SettingsHeader from "../../header";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchWalktest } from "../../../Store/routines";

import Tooltip from "@Elements/Tooltip";
import InformationIcon from "@Icons/Information1";
import WalktestModal from "@Safemate/Settings/Walktest/walktestModal";
import { IWalktest } from "@Safemate/Settings/Walktest/types";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";


interface LogsReportsProps{
  dehaId: number;
  deviId: number;
}

const LogsReports = ({ dehaId, deviId }: LogsReportsProps) => {

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const { formatMessage } = useIntl();

  return(
    <StyledWrapper>
      <SettingsHeader 
        title={formatMessage({id: "logsAndReportsSettingsTab", defaultMessage: "Rapporter"})}
        tooltip="logsAndReportsHeadlineToolTipText"
        tooltipTitle={{
          id: "logsAndReportsSettingsTab",
          defaultMessage: "Rapporter"
        }}
      />
      <SettingWrapper>
        {components.has(ComponentSettingEnum.GX8_LOGS_REPORT) 
          && components.get(ComponentSettingEnum.GX8_LOGS_REPORT)?.map(comp => {
            return <ComponentWrapper>{comp}</ComponentWrapper>
        })}
      </SettingWrapper>
    </StyledWrapper>
  )
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }}}: AppState) => {
  return {
    dehaId,
    deviId
  }
}

export default connect(mapStateToProps)(LogsReports);

export const Icon = styled(FontAwesomeIcon)`
  width: ${({
    theme: {
      icons: { medium }
    }
  }) => medium};
  height: ${({
    theme: {
      icons: { medium }
    }
  }) => medium};
  transition: 0s;
  margin: 0 5px 0 0;
`;

export const CButton = styled(Button)`
  min-width: 250px;
  text-transform: none;
  display: flex;
  background-color: #464a4c;
  color: white;
  margin-right: 5px;
  margin-bottom: 10px;

  &:disabled {
    height: 38px;
    font-size: 13.33px;
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: 0 0 0 0;
  }
`;

interface SignalStrengthReportProps{
  walktest: IWalktest[];
  loadingWalktest: boolean;
  deviId: number;
  fetchWalktest: Routine<ActionFunctionAny<Action<any>>>;
}

const mapReportPropsToState = ({ settings: {initialization: { deviId}, sensor: { walktest, loadingWalktest }}}: AppState) => {
  return{
    walktest,
    loadingWalktest,
    deviId
  }
}

const mapReportDispatchToState = {
  fetchWalktest
}

export const SignalStrengthReport = connect(mapReportPropsToState, mapReportDispatchToState)
  (({ walktest, deviId, loadingWalktest, fetchWalktest }: SignalStrengthReportProps) => {

    const { formatMessage } = useIntl();
    const [ display, setDisplay ] = React.useState(false);

    React.useEffect(() => {
      fetchWalktest(deviId);
    }, []);

    const handleReportClick = () => {
      setDisplay(true);
    }

    const handleClose = () => {
      setDisplay(false);
    }

    return(
      <React.Fragment>
        <CButton
          disabled={walktest.length === 0}
          onClick={handleReportClick}
        >
          {loadingWalktest 
            ? <Loader/>
            : <React.Fragment>
                <Icon icon={faFileAlt} />
                {formatMessage({id: "logsAndReportsReportBtn", defaultMessage: "Signalstyrke-rapport"})}
              </React.Fragment>}
        </CButton>
        <WalktestModal
          display={display}
          walktest={walktest}
          handleClose={handleClose}
        />
      </React.Fragment>
    )
  })

const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

export const GdprReport = () => {

  const { formatMessage } = useIntl();

  return(
    <Wrap>
      <CButton
        disabled={true}
      >
        <Icon icon={faFileAlt}/>
        {formatMessage({id: "logsAndReportsExportBtn", defaultMessage: "Eksporter personlig data"})}
      </CButton>
      <Tooltip
        labelId="logsAndReportsExportBtnToolTipText"
        defaultMessage="Funksjonalitet tilknyttet 'Eksporter personlig data' vil bli implementert senere."
        popoverId="gdpr-popover"
        title={formatMessage({
          id: "logsAndReportsExportBtntooltipTitle",
          defaultMessage: "Eksporter personlig data"
        })}
      >
        <InformationIcon size="medium"/>
      </Tooltip>
    </Wrap>
  )
}