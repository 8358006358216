import * as React from "react";
import {connect} from "react-redux";
import {Redirect, Route, Switch, useRouteMatch} from "react-router";
import {initialize} from "./Store/routines";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import Users from "./Users";
import EditForm from "./Users/Edit";
import { AhpRoles } from "../Policy/GlobalRoleName";
import {LoaderWrap} from "@Safemate/UserAdmin/Users/Edit/styles";
import Loader from "@Elements/Loader";
import { AppState, GdprManager } from "@Safemate/Store/types";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";

export const GdprWarning = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 10px
  border-radius: 5px;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
`;
interface UserAdminProps{
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  initializedAll: boolean;
  gdprManager: GdprManager;
  isPrivate: boolean;
  name: string;
}

const mapDispatchToProps = {
  initialize
}

const mapStateToProps = ({ users: {initialization: {initializedAll}}, appData: { isPrivate, gdprManager, superfirm: { name }}}: AppState) => {
  return{
    initializedAll,
    isPrivate,
    gdprManager,
    name
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ({initialize, name, gdprManager, isPrivate, initializedAll}: UserAdminProps) => {

    const { path, url } = useRouteMatch();
    const { formatMessage } = useIntl();

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const isSuperadmin = permissions.has(PolicyNameEnum.ADMIN) && permissions.get(PolicyNameEnum.ADMIN)!.generalPermissions.all;
    
    React.useEffect(() => {
      initialize();
    }, [])

    return(
      <React.Fragment>
        {(!gdprManager.userId && isSuperadmin && !isPrivate) && <GdprWarning>{`${name} ${formatMessage({id: "gdprUserWarning", defaultMessage: "har ingen behandlingsansvarlig"})}`}</GdprWarning>}
        {!initializedAll? <LoaderWrap><Loader/></LoaderWrap> : 
        <Switch>
          <Redirect exact from="/users" to={`${url}/list`} />
          <Route path={`${path}/list`} component={Users}/>
          <Route path={`${path}/edit/:id?`} component={EditForm}/>
        </Switch>}
      </React.Fragment>
    )
  }
)