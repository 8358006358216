import FieldWrapper, {GenericCheckbox, GenericDropdown, GenericSlider} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {
  dectSoundLevel,
  lightLevel,
  soundLevel,
  soundLevelGX8,
  soundLevelRingtone,
  soundLevelRingtoneWatchOne, soundLevelWatchOne,
  soundLevelRingtoneTriggerTwo4G,
  twoWayMic,
  lightMode,
  soundLevelTriggerFour,
  voicePromptVolumeTriggerFour,
  micTriggerFour,
  soundLevelRingToneTriggerFour,
  voicePromptVolumeGX8,
  lightLevelClimax
} from "./options";
import {LabelEnum} from "../../LabelEnum";
import { ValueSelector } from "@Safemate/Settings/SoundLight/Components/valueSelector";
import React from "react";
import { SettingDiv } from "../styled";

// Dropdowns
export const LightLevel = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.LIGHT_LEVEL,
  title: LabelEnum.LIGHT_LEVEL,
  dataOptions: {
    options: lightLevel,
    skipMap: true
  }
});

export const SoundLevel = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL,
  title: LabelEnum.SOUND_LEVEL,
  dataOptions: {
    options: soundLevel,
    skipMap: true
  }
});


export const SoundLevelGX8 = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL,
  title: LabelEnum.SOUND_LEVEL,
  dataOptions: {
    options: soundLevelGX8,
    skipMap: true
  }
});

export const VoicePromptVolumeGX8 = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.VOICE_PROMPT_VOLUME_GX8,
  title: LabelEnum.VOICE_PROMPT_VOLUME,
  dataOptions: {
    options: voicePromptVolumeGX8,
    skipMap: true
  }
})

export const DectSoundLevel = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.DECT_SOUND_LEVEL,
  title: LabelEnum.DECT_SOUND_LEVEL,
  dataOptions: {
    options: dectSoundLevel,
    skipMap: true
  }
});

export const TwoWayMic = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.TWO_WAY_MIC,
  title: LabelEnum.TWO_WAY_MIC,
  dataOptions: {
    options: twoWayMic,
    skipMap: true
  }
});

export const SoundLevelRingtone = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL_RINGTONE,
  title: LabelEnum.SOUND_LEVEL_RINGTONE,
  dataOptions: {
    options: soundLevelRingtone,
    skipMap: true
  }
});

export const SoundLevelRingtoneTriggerTwo4G = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL_RINGTONE_TRIGGER_TWO_4G,
  title: LabelEnum.SOUND_LEVEL_RINGTONE,
  dataOptions: {
    options: soundLevelRingtoneTriggerTwo4G,
    skipMap: true
  }
});

export const SoundLevelWatchOne = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL,
  title: LabelEnum.CALL_VOLUME,
  dataOptions: {
    options: soundLevelWatchOne,
    translate: true,
    skipMap: true
  }
});

export const SoundLevelRingtoneWatchOne = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL_RINGTONE,
  title: LabelEnum.SOUND_LEVEL_RINGTONE,
  dataOptions: {
    options: soundLevelRingtoneWatchOne,
    translate: true,
    skipMap: true
  }
});


export const LightMode = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.LIGHT_MODE,
  title: LabelEnum.LIGHT_MODE,
  dataOptions: {
    options: lightMode,
    translate: true,
    skipMap: true
  }
});

export const LightAlarmMode = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.LIGHT_ALARM_MODE,
  title: LabelEnum.LIGHT_ALARM_MODE,
  dataOptions: {
    options: lightLevelClimax,
    translate: true,
    skipMap: true
  }
});

export const LightNormalMode = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.LIGHT_NORMAL_MODE,
  title: LabelEnum.LIGHT_NORMAL_MODE,
  dataOptions: {
    options: lightLevelClimax,
    translate: true,
    skipMap: true
  }
});

// Checkboxes
export const SystemSounds = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.SYSTEM_SOUNDS, defaultMessage: "Lyder ifm. lading og lavt batteri"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SYSTEM_SOUNDS
});

export const IncomingRingtone = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.INCOMING_RINGTONE, defaultMessage: "Ringelyd ved innkommende anrop"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.INCOMING_RINGTONE
});

export const GuardTimeBeep = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.GUARD_TIME_BEEP, defaultMessage: "Lyd ved Angrefristalarm"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.GUARD_TIME_BEEP
});

export const ConfirmBeep = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.CONFIRM_BEEP, defaultMessage: "Kø lyd"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CONFIRM_BEEP
});

export const WandererBeep = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.WANDERER_BEEP, defaultMessage: "Vandre pling"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.WANDERER_BEEP
});

export const HelpArriveIndicator = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.HELP_ARRIVE_INDICATOR, defaultMessage: "Hjelp ankommet indikator"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.HELP_ARRIVE_INDICATOR
});


export const CallbackIndicator = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.CALLBACK_INDICATOR, defaultMessage: "Tilbakeringing indikator"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CALLBACK_INDICATOR
});

export const Led = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.ENABLE_LED, defaultMessage: "LED Aktivert"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.LED
});

export const VoicePromptAlarm = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.VOICE_PROMPT_ALARM, defaultMessage: "Alarm"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CLIMAX_VOICE_PROMPT_ALARM
});

export const VoicePromptCallback = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.VOICE_PROMPT_CALLBACK, defaultMessage: "Tilbakeringing"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CLIMAX_VOICE_PROMPT_CALLBACK
});

export const VoicePromptFall = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.VOICE_PROMPT_FALL, defaultMessage: "Fall"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CLIMAX_VOICE_PROMPT_FALL
});

export const VoicePromptWanderer = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.VOICE_PROMPT_WANDERER, defaultMessage: "Vandrealarm"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CLIMAX_VOICE_PROMPT_WANDERER
});

export const VoicePromptBed = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.VOICE_PROMPT_BED, defaultMessage: "Ut av seng/Ikke tilbake i seng"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CLIMAX_VOICE_PROMPT_BED
});

export const VoicePromptACFailure = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.VOICE_PROMPT_AC_FAILURE, defaultMessage: "AC Failure"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CLIMAX_VOICE_PROMPT_AC_FAILURE
});

export const VoicePromptACRestore = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.VOICE_PROMPT_AC_RESTORE, defaultMessage: "AC Restore"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.CLIMAX_VOICE_PROMPT_AC_RESTORE
});

export const SynchronizeSound = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.SYNCHRONIZE_SOUND, defaultMessage: "Sync sound"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SYNCHRONIZE_SOUND
});

//Sliders

export const SoundLevelTriggerFour = FieldWrapper({
  componentOptions: {
    Component: GenericSlider,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL_TRIGGER_FOUR,
  title: LabelEnum.SOUND_LEVEL,
  dataOptions: {
    options: soundLevelTriggerFour,
    skipMap: true
  }
});

export const SoundLevelRingToneTriggerFour = FieldWrapper({
  componentOptions: {
    Component: GenericSlider,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL_RINGTONE_TRIGGER_FOUR,
  title: LabelEnum.SOUND_LEVEL_RINGTONE,
  dataOptions: {
    options: soundLevelRingToneTriggerFour,
    skipMap: true
  }
});

export const MicTriggerFour = FieldWrapper({
  componentOptions: {
    Component: GenericSlider,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.MIC_VOLUME,
  title: LabelEnum.MIC_VOLUME,
  dataOptions: {
    options: micTriggerFour,
    skipMap: true
  }
});

export const VoicePromptVolumeTriggerFour = FieldWrapper({
  componentOptions: {
    Component: GenericSlider,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.VOICE_PROMPT_VOLUME_TRIGGER_FOUR,
  title: LabelEnum.VOICE_PROMPT_VOLUME,
  dataOptions: {
    options: voicePromptVolumeTriggerFour,
    skipMap: true
  }
});

export const SoundLevelTriggerTwo4G = FieldWrapper({
  componentOptions: {
    Component: GenericSlider,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.SOUND_LEVEL_TRIGGER_TWO_4G,
  title: LabelEnum.SOUND_LEVEL,
  dataOptions: {
    options: soundLevelTriggerFour,
    skipMap: true
  }
});

export const ScreenBrightness = () => 
  <SettingDiv isChanged>
    <ValueSelector
      title={{ id: "screenBrightness", defaultMessage: "Screen brightness" }}
      name="screenBrightness"
      startLevel={1}
      count={5}
    />
  </SettingDiv>

export const ScreenLightMode = () => 
  <SettingDiv isChanged>
    <ValueSelector
      title={{ id: "screenLightMode", defaultMessage: "Screen mode" }}
      name="screenLightMode"
      startLevel={1}
      count={3}
      label={[
        {},
        { id: "screenLightModeSosButton", defaultMessage: "Sos button" },
        { id: "screenLightModeAlwaysOn", defaultMessage: "Always on" },
        { id: "screenLightModeOnRaise", defaultMessage: "On hand raise" },
      ]}
    />
  </SettingDiv>


export const LowBatteryVoicePrompt = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: "lowBatteryVoicePrompt", defaultMessage: "Low battery voice prompt"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.SOUND_LIGHT,
  field: SettingEnum.LOW_BATTERY_VOICE_PROMPT
});
