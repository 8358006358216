import * as React from "react";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { ButtonWrap, DescriptionParagraph, HeaderRow, LoaderWrap, SaveWrap, StyledP, Wrapper, RefreshWrapper } from "./styles";
import { addScannedSensor, addSensor, fetchSensors, scanBLE } from "@Safemate/Settings/Store/routines";
import { defineMessages, useIntl } from "react-intl";
import { ISensor, ISensorDTO } from "../types";
import { DeviceType, SensorTypeEnum } from "../SensorTypeEnum";
import { Button } from "react-bootstrap";
import { Field, Formik } from "formik";
import { HorizontalRow } from "../styles";
import { getIconForType } from "../sensorType";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { Option } from "@Elements/Dropdown/types";
import AddIcon from "./addIcon";
import { Link } from "react-router-dom";
import { Refresh } from "@Elements/Icon/Icons/FontAwesome";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";

const translatedText = defineMessages({
  addText: { id: 'addSensTooltip', defaultMessage: 'Legg til sensor' }
});

interface AddSensorProps{
  deviId: number;
  unsavedSensors: ISensorDTO[];
  sensors: ISensorDTO[];
  savingSensor: boolean;
  fetchingSensors: boolean;
  fetchSensors: Routine<ActionFunctionAny<Action<any>>>; 
  addSensor: Routine<ActionFunctionAny<Action<any>>>; 
  addScannedSensor: Routine<ActionFunctionAny<Action<any>>>; 
  scanBLE: Routine<ActionFunctionAny<Action<any>>>; 
  returnPath?: string;
}

const mapStateToProps = ({ settings: {initialization: { deviId }, sensor: { unsavedSensors, sensors, savingSensor, fetchingSensors }}}: AppState) => {
  return {
    deviId,
    savingSensor,
    sensors,
    unsavedSensors,
    fetchingSensors
  }
}

const mapDispatchToProps = {
  fetchSensors,
  addSensor,
  addScannedSensor,
  scanBLE
}

const AddSensor = connect(mapStateToProps, mapDispatchToProps)(
  ({ 
    deviId, returnPath, savingSensor, fetchingSensors, unsavedSensors, 
    sensors, fetchSensors, addSensor, addScannedSensor, scanBLE
  }: AddSensorProps) => {

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    fetchSensors(deviId);
  }, [])

  const refresh = () => {
    fetchSensors(deviId);
  }

  const scan = () => {
    scanBLE(deviId);
  }

  const hasWristband = React.useMemo(() => {
    return !!sensors.find(sensor => sensor.sensor.type === SensorTypeEnum.WRIST_BAND);
  }, [sensors])

  return(
    <Wrapper>
      <HeaderRow>
        <h1>
          {formatMessage({id: "addSensor", defaultMessage: "Legg til sensor"})}
        </h1>
      </HeaderRow>
      {hasWristband 
        ? <div>
            <DescriptionParagraph>
              {formatMessage({id: 'triggerFourSensorLimitWristband', defaultMessage: 'Du kan bare ha ett armbånd koblet mot denne enheten.'})}
            </DescriptionParagraph>
          </div>
        : <AddWristband savingSensor={savingSensor} deviId={deviId} addSensor={addSensor}/>
      }
      <RefreshWrapper>
        <DescriptionParagraph>
          {formatMessage({id: "scannedDevices", defaultMessage: "Skannede enheter"})}
        </DescriptionParagraph>
        <Refresh spin={fetchingSensors} onClick={refresh}/>
      </RefreshWrapper>
      {unsavedSensors.map(sensor => {
        if(hasWristband && sensor.sensor.type === SensorTypeEnum.WRIST_BAND) return null;
        return(
        <ScannedSensor
          sensor={sensor}
          addScannedSensor={addScannedSensor}
          savingSensor={savingSensor}
        />)
      }).filter(sensor => sensor)}
      <ButtonWrap>
        <Link to={returnPath}>
          <Button>{formatMessage({id: "back", defaultMessage: "Tilbake"})}</Button>
        </Link>
        <Button onClick={scan}>{formatMessage({id: "scanBle", defaultMessage: "Scan BLE"})}</Button>
      </ButtonWrap>
    </Wrapper>
  )
})

interface ScannedSensorProps{
  sensor: ISensorDTO;
  savingSensor: boolean;
  addScannedSensor: Routine<ActionFunctionAny<Action<any>>>; 
}

const ScannedSensor = ({sensor, savingSensor, addScannedSensor}: ScannedSensorProps) => {

  const { formatMessage } = useIntl();
  const [ selectedType, setSelectedType ] = React.useState(sensor.sensor.type);

  return(
    <HorizontalRow>
      {getIconForType(selectedType)}
      <StyledP>{sensor.sensor.macAddress}</StyledP>
      <Dropdown
        initial={selectedType}
        options={[
            {text: formatMessage({id: "triggerFourWristband", defaultMessage: " Armbånd"}), value: DeviceType.WRIST_BAND},
            {text: formatMessage({id: "triggerFourBaseStation", defaultMessage: " Basestasjon"}), value: DeviceType.BASE_STATION},
        ]}
        onChange={(option) => {
            const selected = (option as Option).value;
            setSelectedType(selected);
        }}
      />
      {savingSensor
        ? <Loader />
        : <AddIcon
          tabIndex="0"
          onKeyDown={(event: KeyboardEvent) =>{
            if(event.key === "Enter"){
              addScannedSensor({
                ...sensor,
                type: selectedType
              })
            }
          }}
          onClick={() => {
            addScannedSensor({
              ...sensor,
              type: selectedType
            })
          }}
          title={formatMessage(translatedText.addText)}
        />}
    </HorizontalRow>
  )
}

interface AddWristbandProps{
  addSensor: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  savingSensor: boolean;
}

const AddWristband = ({addSensor, savingSensor, deviId}: AddWristbandProps) => {

  const { formatMessage } = useIntl();

  return (
    <Formik
      enableReinitialize
      onSubmit={(values) => {
        addSensor({
          deviId,
          mac: values.macAddress
        })
      }}
      initialValues={{
        macAddress: ""
      }}
    >
      {() => 
        <React.Fragment>
          <Field
            type="text"
            name="macAddress"
          />
          <SaveWrap>
            {savingSensor
              ? <LoaderWrap><Loader/></LoaderWrap>
              : <Button type="submit">{formatMessage({id: "saveWristband", defaultMessage: "Lagre armbånd"})}</Button>}
          </SaveWrap>
        </React.Fragment>
      }
    </Formik>
  )
}

export default AddSensor;