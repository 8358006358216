import { combineReducers } from "redux";
import { RootState } from "../types";
import feedback from "../../../Feedback/reducer";
import initialization from "./initialization";
import transaction from "./transaction";
import device from "./device";
import alarmAnalysis from "./alarmAnalysis";
import notificationEvents from "./notificationEvents";
import status from "./status";
import policy from "./policy";
import geofence from "./geofence";

export default combineReducers<RootState>({
    feedback,
    initialization,
    transaction,
    device,
    alarmAnalysis,
    notificationEvents,
    status,
    policy,
    geofence
});
