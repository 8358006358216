import React from 'react';
import SearchIcon from '../../Elements/Icon/Icons/Search';

import { InputWrapper, StyledInput, SearchIconWrapper } from './styles';

interface SearchProps{
  placeholder: string;
  handleChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  id: string;
  value: string;
  autoComplete: string;
  onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Search = ({ placeholder, handleChange, onBlur, onKeyDown, id, value }: SearchProps) => {
  return (
    <InputWrapper>
      <StyledInput 
        autoComplete="off" 
        type={"text"}
        placeholder={placeholder}
        id={id}
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        value={value}/>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
    </InputWrapper>
  );
};

export default Search;
