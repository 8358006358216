import {DeviceState} from "../types";
import {Reducer} from "redux";
import { initialize, updateTraining, saveGlobalSettings, saveLastConnectionClick, updateDevice, deleteTwin, syncDevice, getDevice } from "../routines";

const deviceState: DeviceState = {
  updatingDevice: false,
  capabilities: {
    id: 0,
    dehaId: 0,
    detyId: 0,
    alarm: false,
    selfcheck: false,
    teamAlert: false,
    motion: false,
    shutdownAlert: false,
    batteryPercentage: false,
    batteryLow: false,
    outOfBed: false,
    fridge: false,
    wanderer: false,
    epilepsy: false,
    inBed: false,
    fire: false,
    persistentConnection: false,
    positioning: false,
    positioningRequest: false,
    callGsm: false,
    callVoip: false,
    reportsAccessPoints: false,
    reportsCellTowers: false,
    whitelistSize: 0,
    speakerVolumeMin: 0,
    speakerVolumeMax: 0,
    microphoneVolumeMin: 0,
    microphoneVolumeMax: 0,
    gsmStrengthMin: 0,
    gsmStrengthMax: 0,
    heartbeatIntervalMin: 0,
    heartbeatIntervalMax: 0,
    configurableSosButton: false,
    configurableSosButtonDuration: 0,
    configurableOffButton: false,
    fallImpact: false,
    fallImpactMin: 0,
    fallImpactMax: 0,
    fallTilt: false,
    fallTiltMin: 0,
    fallTiltMax: 0,
    vitalsPulse: false,
    vitalsBloodPressure: false,
    vitalsWearing: false,
    twin: false,
    silentMode: false,
    findMe: false,
  },
  device: {
    deviId: 0,
    dehaId: 0,
    detyId: 0,
    ahpId: 0,
    ownedByCustomer: 0,
    deviceSerialNumber: "",
    deviceName: "",
    deviceTypeName: "",
    deviceId: "",
    twin: false,
    twinLearn: "",
    twinDevice: {
      deviId: 0,
      deviceSerialNumber: ""
    },

    hasDeviceSensor: false,
    formattedPhoneNumE164: "",
    fiwaNum: 0,
    activatedDate: 0,
    lastMsgIn: 0,
    firmName: "",
    trainingApprovedBy: 0,
    trainingApprovedByName: "",

    neverUsed: false,
    failedSelfCheck: false,
    tracking: false,
    offline: false,
    staticTooLong: false,
    batteryLow: false,
    inAlarmMode: false,

    eview: false,
    eview07B: false,
    peaktech: false,
    takit: false,
    toplovo: false,
    connected: false,
    connectionResetDate: "",
    lastConnectionClick: "",
    connectionCounter: 0,
    connectedSince: "",

    gsmStrength: 0,

    posLati: "",
    posLong: "",

    delayDay: "",
    delayNight: "",
    delayMotionless: "",
    callAlert: "",
    geofenceWifiLocationAlert: false,

    policies: {
      custId: 0,
      global: false,
      id: 0,
      policies: {},
      roleName: "",
      technicianRights: false
    },
    watchRemoved: false,
    watchRemovedEnabled: false
  }
};

const device: Reducer<DeviceState> = (state: DeviceState = deviceState, action: any) => {
  switch (action.type) {
    case initialize.SUCCESS:
      return {
          ...state,
        device: action.payload.device,
        capabilities: action.payload.capabilities
      };

    case getDevice.SUCCESS:
      return {
        ...state,
        device: action.payload
      }

    case updateTraining.SUCCESS:
      return {
        ...state,
        device: {
          ...state.device,
          ...action.payload
        }
      };

    case saveGlobalSettings.SUCCESS:
      return{
        ...state,
        device: {
          ...state.device,
          delayDay: action.payload.delayDay,
          delayNight: action.payload.delayNight,
          delayMotionless: action.payload.delayMotionless,
          callAlert: action.payload.callAlert,
          geofenceWifiLocationAlert: action.payload.geofenceWifiLocationAlert
        }
      }
    
    case saveLastConnectionClick.SUCCESS:
      return {
        ...state,
        device: {
          ...state.device,
          lastConnectionClick: action.payload
        }
      }

      
    case updateDevice.SUCCESS:
      return {
        ...state,
        device: action.payload
      }

    case deleteTwin.SUCCESS:
      return {
      ...state,
      device: action.payload
    }
            
    case syncDevice.TRIGGER:{
      return {
        ...state,
        updatingDevice: true
      }
    }

    case syncDevice.SUCCESS:
      return {
        ...state,
        updatingDevice: false,
        device: action.payload
    }

    case updateDevice.FULFILL:
      return {
      ...state,
      updatingDevice: false
    }

    default:
        return state;
  }
};

export default device;
