import React from 'react';

import { connect, Field } from 'formik';
import { CheckBoxRow, Tooltip } from '../layoutComp';
import { Radio, RadioGroup } from '../../../Elements/RadioFormik';
import Switch from '../../../Elements/Switch';

import {
  CollapsedWrapper,
  TabHeader,
  SubHeader,
  Content,
  CapitalizedString
} from '../WrapperStyles';
import { Role } from '../Store/types';
import { useIntl } from 'react-intl';
import { NonStaticRender } from '../utils';
import { Collapse } from 'react-collapse';
import { FullWidthStyledInput } from '../FormStyles';

interface CustomTabProps{
  nonStaticRender: NonStaticRender;
}

const CustomTab = connect<CustomTabProps, Role>(({formik: { values, setFieldValue }, nonStaticRender}) => {

  const { key, title, children } = nonStaticRender;

  const enabled = !!values.policies[key].rolePolicies.status;
  const { formatMessage } = useIntl();

  return (
    <CollapsedWrapper>
      <TabHeader>
        <CapitalizedString>
          {formatMessage({id: `${key}RoleEditor`, defaultMessage: title})}
        </CapitalizedString>
        <Switch
          checked={enabled}
          onChange={(value: boolean) => {
            setFieldValue(`policies.${key}.rolePolicies.status`, value);
          }}
        />
      </TabHeader>
      <Collapse isOpened={enabled}>
        {children.map(item => {
          switch (item.policyDetails.type) {
            case 'radio':
              return (
                <div key={item.policyName}>
                  <SubHeader>
                    <CapitalizedString>
                      {formatMessage({
                        id: item.policyName,
                        defaultMessage: item.policyDetails.label
                      })}
                    </CapitalizedString>
                  </SubHeader>
                  <Content endSection>
                    <RadioGroup disabled={false}>
                      <Field
                        component={Radio}
                        name={`policies.${
                          item.policyName
                        }.rolePolicies.information`}
                        radioValue="all"
                        id={`policies.${
                          item.policyName
                        }.rolePolicies.information.all`}
                        label={formatMessage({id: 'write', defaultMessage: 'Write'})}
                      />
                      <Field
                        component={Radio}
                        name={`policies.${
                          item.policyName
                        }.rolePolicies.information`}
                        radioValue="read"
                        id={`policies.${
                          item.policyName
                        }.rolePolicies.information.read`}
                        label={formatMessage({id: 'read', defaultMessage: 'Read'})}
                      />
                      <Field
                        component={Radio}
                        name={`policies.${
                          item.policyName
                        }.rolePolicies.information`}
                        radioValue="hidden"
                        id={`policies.${
                          item.policyName
                        }.rolePolicies.information.hidden`}
                        label={formatMessage({id: 'radioHidden', defaultMessage: 'Hidden'})}
                      />
                    </RadioGroup>
                  </Content>
                </div>
              );
            case 'checkbox':
              return (
                <CheckBoxRow
                  values={
                    values.policies[item.policyName].rolePolicies.status
                  }
                  key={item.policyName}
                  name={`policies.${item.policyName}.rolePolicies.status`}
                  label={formatMessage({
                    id: item.policyName,
                    defaultMessage: item.policyDetails.label
                  })}
                  tooltip={item.policyName=="editSubOrgs" && formatMessage({id: "EditSubOrgsRoleEditorToolTip", defaultMessage: "Med denne rettigheten kan rolleinnehaveren redigere eksisterende underavdelinger, men ikke legge til nye eller slette eksisterende."})}
                  //tooltip={}
                  endSection={item.policyDetails.endSection}
                />
              );
            case 'input':
              return (
                <div key={item.policyName}>
                  <SubHeader>
                    <CapitalizedString>
                      {item.policyDetails.label}
                    </CapitalizedString>
                  </SubHeader>
                  <Content>
                    <FullWidthStyledInput
                      name={`policies.${item.policyName}.rolePolicies.information`}
                    />
                  </Content>
                </div>
              );
            default:
              return null;
          }
        })}
      </Collapse>
    </CollapsedWrapper>
  );
});

export default CustomTab;
