import styled from 'styled-components';

const Container = styled.div`
  color: ${props => props.theme.colors.textPrimary};
  position: relative;
  margin: 15px 0;
  @media (min-width: 770px) {
    display: flex;
  }
`;

export default Container;
