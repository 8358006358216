import {RoleState} from "../types";
import {Reducer} from "redux";
import {getRoles} from "../routines";

const roleState: RoleState = {
  roles: []
};

const role: Reducer<RoleState> = (state: RoleState = roleState, action) => {
  switch (action.type) {
    case getRoles.SUCCESS:
      return {
        roles: action.payload
      };

    default:
      return state;
  }
};

export default role;
