import * as React from "react";
import styled from 'styled-components';
import moment from 'moment';
import { defineMessages, useIntl } from 'react-intl';
import { StatusWrap } from "../styled";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { getDevice, syncDevice, connectDevice } from "@Safemate/Settings/Store/routines";
import { AppState } from "@Safemate/Store/types";
import { DeviceStatus } from "@Safemate/Settings/Info";
import { Device } from "@Safemate/Settings/types";
import { Button } from "react-bootstrap";
import Tooltip from "@Elements/Tooltip";
import InformationIcon from "@Elements/Icon/Icons/InformationIcon";
import { supportGsmStrength } from "@Safemate/Settings/tabDefinition";
import SignalBars from "@Safemate/DeviceList/DeviceList/signalBars";
import { convertScale } from "@Safemate/DeviceList/DeviceList/TableElements";

const translatedText = defineMessages({
  invalid: {
    id: 'statusBarInvalidDate',
    defaultMessage: 'Har aldri oppnådd kontakt'
  },
  updateConfig: {
    id: 'updateConfigBtn',
    defaultMessage: 'Oppdater konfigurasjon'
  },
  nullRssi: {
    id: 'noSignal',
    defaultMessage: 'Ingen signal'
  }
});

const Item = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 5px;
  padding: 0;
  align-items: baseline;
`;

const W = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Text = styled.p`
  ::first-letter {
    text-transform: capitalize;
  }
  margin: 0
    ${({
      theme: {
        margins: { standard }
      }
    }) => standard}
    0 0;
  padding: 0;
  border: 0;
  color: ${({
    theme: {
      colors: { textPrimary }
    }
  }) => textPrimary};
  & :last-child {
    margin-right: 0;
  }
`;

const HighlightText = styled.span`
  color: ${({
    theme: {
      colors: { textHighlight }
    }
  }) => textHighlight};
`;

interface StatusBarProps{
  updatingDevice: boolean;
  device: Device;
  syncDevice: Routine<ActionFunctionAny<Action<any>>>;
  getDevice: Routine<ActionFunctionAny<Action<any>>>;
  connectDevice: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ settings: {device: { updatingDevice, device }}}: AppState) => {
  return {
    updatingDevice,
    device
  }
}

const mapDispatchToProps = {
  syncDevice,
  getDevice,
  connectDevice
}

export const StatusBar = connect(mapStateToProps, mapDispatchToProps)(({updatingDevice, connectDevice, device: { connected, deviId, dehaId, gsmStrength, lastMsgIn, fiwaNum}, device, syncDevice, getDevice}: StatusBarProps) => {

  const { formatMessage } = useIntl();

  const eview = device.eview || device.eview07B;
  const takit = device.takit;
  const toplovo = device.toplovo;
  const peaktech = device.peaktech;

  const lastConnectionClick = device.lastConnectionClick ? new Date(device.lastConnectionClick) : new Date();
  const MinuteSinceClick = device.lastConnectionClick ? new Date(lastConnectionClick.getTime() + 60000) : new Date(lastConnectionClick.getTime() - 60000);
  const connectionResetDate = device.connectionResetDate ? new Date(device.connectionResetDate) : new Date(lastConnectionClick.getTime() - 60000);

  const [disabled, setDisabled] = React.useState(MinuteSinceClick > new Date());
  const [frontEndClick, setFrontEndClick] = React.useState(new Date());

  React.useEffect(() => {

    let updateInfoInterval: NodeJS.Timeout = setInterval(() => {
      getDevice({deviceId: deviId});
    }, 10000);

    const lastMinuteClick = setInterval(() => {
      // if there is a last connection click we add 1 minute to that value, if there isn't we subtract 1 minute from the current time
      const wait1MinuteSinceClick = device.lastConnectionClick ? new Date(lastConnectionClick.getTime() + 60000) : new Date(lastConnectionClick.getTime() - 60000);

      if(wait1MinuteSinceClick < new Date() && frontEndClick < new Date()){
        setDisabled(false);
      }else{
        setDisabled(true);
      }
    }, 10000)

    return () => {
      // Cleanup polling
      clearTimeout(updateInfoInterval);
      clearTimeout(lastMinuteClick);
    }

  }, [])

  const handleClick = () => {
    if(device.climax){
      update();
    }
    else{
      connectDevice({deviId, eview, peaktech, takit, toplovo, lastConnectionClick});
    }
    setDisabled(true);
    setFrontEndClick(new Date(new Date().getTime() + 60000));
  }

  const statusDate = moment
      .utc(lastMsgIn)
      .local()
      .format('DD.MM.YYYY HH:mm');

  const statusLastContact =
    statusDate === 'Invalid date'
      ? formatMessage(translatedText.invalid)
      : statusDate;

  const gsmSupport = supportGsmStrength.get(device.dehaId);

  let strength = 0

  if(gsmSupport) {

    const offset = gsmSupport.low === 0 ? 0 : -(gsmSupport.low);
    const scale =  100 / (gsmSupport.high + offset);
    strength = (device.gsmStrength + offset) * scale;

  }

  const update = () => {
    syncDevice(deviId)
  }

  return (
    <StatusWrap>
      <W>
        <Item>
          <Text>
            {formatMessage({id: "statusBarFirmwareVers", defaultMessage: "Firmware versjon"})}:
          </Text>
          <Text>
            <HighlightText>{fiwaNum}</HighlightText>
          </Text>
        </Item>
        <Item>
          <Text>
            {formatMessage({id: "status", defaultMessage: "Status"})}:
          </Text>
          <Text>
            <DeviceStatus/>
          </Text>
        </Item>
        <Item>
          <HighlightText>{formatMessage({id: (connected ? "panelOnline" : "panelOffline"), defaultMessage: (connected ? "Tilkoblet" : "Frakoblet")})}</HighlightText>
        </Item>
        <Item>
          <Text>
            {formatMessage({id: "statusBarLastConfig", defaultMessage: "Forrige konfigurasjonsendring: "})}
          </Text>
          <Text>
            <HighlightText>{statusLastContact}</HighlightText>
          </Text>
        </Item>
        {gsmSupport &&
        <Item
          style={{ display: 'flex', alignItems: 'center' }}
          title={`${convertScale(strength)}/5`}
        >
          <Text>
            {formatMessage({id: "statusBarGsmSignal", defaultMessage: "GSM signal"})}:
          </Text>
          <SignalBars dehaId={dehaId} size={30} strength={gsmStrength} />
        </Item>}
        <Item>
          {device.climax ?
            device.connected && <Button disabled={disabled} onClick={handleClick}>
              {formatMessage({id: "updateDevice", defaultMessage: "Oppdater"})}
            </Button> :
            !device.connected && <>
              <Button disabled={(device.connectionCounter  > 9 && connectionResetDate > new Date()) || disabled } onClick={handleClick}>
                {formatMessage({id: "connect", defaultMessage: "Koble til"})}
              </Button> { device.connectionCounter > 9 && <Tooltip
                popoverId="connectCounter-popover"
                labelId="connectionCounterMaxAttemptsLabel"
                defaultMessage="TCP connect-melding allerede sendt."
              >
                <InformationIcon size="medium" />
              </Tooltip>}
            </>}
        </Item>
      </W>
    </StatusWrap>
  )
})
