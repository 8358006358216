import * as React from "react";
import Header from "./Header";
import { connect } from "react-redux";
import { initialize, searchSensors, updateDevice } from './Store/Routines';
import { Routine } from "redux-saga-routines";
import { ActionFunctionAny, Action } from "redux-actions";
import DeviceList from "./DeviceList";
import styled from "styled-components";
import { Grid } from "react-bootstrap";
import { 
  filterSearch, 
  FilterSearch, 
  filterAlarm, 
  FilterAlarm, 
  filterDeviceType, 
  FilterDeviceType,
  filterCustomer,
  FilterCustomer
} from './Store/actions';
import { AlarmFilterEnum } from './AlarmFilterEnum';
import { AppState } from "@Safemate/Store/types";

const StyledGrid = styled(Grid)`
@media (max-width: 499px) {
  padding-left: 10px!important;
  padding-right: 10px!Important;
}
`

interface DevicesProps {
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  searchSensors: Routine<ActionFunctionAny<Action<any>>>;
  updateDevice: Routine<ActionFunctionAny<Action<any>>>;
  search: string;
  filterSearch: FilterSearch;
  filterAlarm: FilterAlarm;
  filterDeviceType: FilterDeviceType;
  filterCustomer: FilterCustomer;
  deviceTypes: number[];
  alarmFilters: AlarmFilterEnum[];
}

const mapStateToProps = ({deviceList: {filter: { deviceTypes, alarmFilters, search }}}: AppState) => {
  return {
    deviceTypes,
    alarmFilters,
    search
  }
}

const mapDispatchToProps = {
  initialize,
  updateDevice,
  filterSearch,
  filterAlarm,
  filterDeviceType,
  filterCustomer,
  searchSensors
}

export default connect(mapStateToProps, mapDispatchToProps)(({
  initialize,
  searchSensors, 
  filterSearch, 
  updateDevice, 
  search, 
  deviceTypes,
  alarmFilters,
  filterAlarm,
  filterDeviceType,
  filterCustomer
}: DevicesProps) => {


  React.useEffect(() => {

    initialize();

    if(alarmFilters.length > 0){
      const clearFilter = alarmFilters.includes(AlarmFilterEnum.CLEAR);
      filterAlarm(clearFilter ? [] : alarmFilters);
      if(clearFilter){
        filterCustomer([]);
        if(deviceTypes.length === 0){
          filterDeviceType([]);
        }
      }
    }
    if(deviceTypes.length > 0)
      filterDeviceType(deviceTypes);
    // Poll for updates
    const interval = setInterval(() => {
      updateDevice({deviceId: 0})
    }, 20000)

    return () => {
      // Cleanup polling
      clearTimeout(interval);
    }
  }, [])

  React.useEffect(() => {
    if(search)
      searchSensors(search);
    filterSearch(search);
  }, [search])

  return(
    <StyledGrid fluid>
      <Header />
      <DeviceList />
    </StyledGrid>
  )
});
