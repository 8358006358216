import * as React from "react";
import Chart from 'react-apexcharts';
import { formatDate } from "@Safemate/utils";
import { useIntl } from "react-intl";
import styled, { ThemeProps, withTheme } from "styled-components";
import { ITheme } from "@Theme/types";
import { Collapse } from "react-collapse";
import { connect } from "react-redux";
import { AppState } from "@Safemate/Store/types";
import { getBatteryForRange, getSignalForRange } from "../Store/routines";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Battery, Signal } from "../Store/types";
import { useParams } from "react-router";
import { LogParams, MapType } from "..";
import { Checkbox } from "@Elements/CheckboxFormik";
import { Button } from "react-bootstrap";

const StyledButton = styled(Button)`
  margin-left: 5px;
  width: 100px;
`;

const ChartWrapper = styled.div`
  padding: 20px 0px;
  .apexcharts-tooltip {
    background: ${props => props.theme.colors.backgroundPrimary} !important;
    color: ${props => props.theme.colors.textPrimary};
  }
  .apexcharts-tooltip-title{
    border-bottom: none !important;
    background: ${props => props.theme.colors.backgroundPrimary} !important;
    color: ${props => props.theme.colors.textPrimary};
  }
  .apexcharts-svg {
    overflow: visible !important;
  }
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`

export interface ChartData{
  x: string;
  y: number;
}

export interface YAxis{
  max: number;
  min: number;
}

interface ChartProps extends ThemeProps<ITheme>{
  displayGraph: boolean;
  battery: Battery[];
  signal: Signal[];
  mapType: MapType;
  getBatteryForRange: Routine<ActionFunctionAny<Action<any>>>;
  getSignalForRange: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ transactionLog: { transaction: { displayGraph, battery, signal } } }: AppState) => {
  return {
    displayGraph,
    battery,
    signal
  }
}

const mapDispatchToProps = {
  getBatteryForRange,
  getSignalForRange
}

enum Range{
  ONE_DAY,
  THREE_DAYS,
  FIVE_DAYS,
  SEVEN_DAYS,
  FOURTEEN_DAYS
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme(({mapType, battery, signal, getBatteryForRange, getSignalForRange, displayGraph, theme}: ChartProps) => {

  const { formatMessage } = useIntl();
  const { id } = useParams<LogParams>();
  const deviId = parseInt(id);

  const [ batteryToggle, setBatteryToggle ] = React.useState(true);
  const [ signalToggle, setSignalToggle ] = React.useState(false);
  const [ displayRange, setDisplayRange ] = React.useState(Range.FIVE_DAYS);

  var currentDate = new Date();
  let ticks = 10;
  let xMax = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0).getTime();
  let xMin = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 4, 0, 0, 0, 0).getTime();


  switch(displayRange){
    case Range.FOURTEEN_DAYS:{
      xMax = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0).getTime());
      xMin = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 13, 0, 0, 0, 0).getTime());
      ticks = 14;
      break;
    }
    case Range.ONE_DAY:{
      xMax = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0).getTime());
      xMin = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0).getTime());
      ticks = 12;
      break;
    }
    case Range.THREE_DAYS:{
      xMax = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0).getTime());
      xMin = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 2, 0, 0, 0, 0).getTime());
      ticks = 12;
      break;
    }
    case Range.FIVE_DAYS:{
      xMax = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0).getTime());
      xMin = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 4, 0, 0, 0, 0).getTime());
      ticks = 10;
      break;
    }
    case Range.SEVEN_DAYS:{
      xMax = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0).getTime());
      xMin = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 6, 0, 0, 0, 0).getTime());
      ticks = 14;
      break;
    }
    default: {
      xMax = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1, 0, 0, 0, 0).getTime());
      xMin = (new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 4, 0, 0, 0, 0).getTime());
      ticks = 10;
      break;
    }
  }

  React.useEffect(() => {
    if(mapType === MapType.SINGLE){
      getBatteryForRange(deviId)
      getSignalForRange(deviId)
    }
  }, [])

  const mappedBattery = React.useMemo(() => {
    return battery.map(data => {
      return {
        x: data.time,
        y: data.battery
      }
    })
  }, [battery])

  const mappedSignal = React.useMemo(() => {
    return signal.map(data => {
      return {
        x: data.time,
        y: data.signal
      }
    })
  }, [signal])

  const series = [];
  if(batteryToggle){
    series.push({
      name: formatMessage({id: "battery", defaultMessage: "Batteri"}),
      color: "rgb(115, 191, 105)",
      data: mappedBattery
    })
  }

  if(signalToggle){
    series.push({
      name: formatMessage({id: "signalStrength", defaultMessage: "Signal Strength"}),
      color: "#87ceeb",
      data: mappedSignal
    })
  }

  const maxY = ( battery: boolean, signal: boolean ): number =>{
    let maxBattery = 100;
    let maxSignal = 100;
    if( battery ){
      maxBattery = 100;
    } else if( signal ){
      if (mappedSignal && mappedSignal.length > 1){
        maxSignal = Math.max.apply(Math, mappedSignal.map(function(o) { return o.y; }));
      }
    }
    return battery ? maxBattery : maxSignal;
  }

  return( 
    <ChartWrapper>
      <Collapse isOpened={displayGraph}>
        <div style={{padding: "18px 0px", display: "flex", alignItems: "center"}}>
          <Checkbox
            outerDiv={{marginRight: "10px"}}
            id="battery-toggle"
            value={batteryToggle}
            onChange={() => {
              setBatteryToggle(!batteryToggle)
            }}
            label={formatMessage({id: "battery", defaultMessage: "Batteri"})}
          />
          <Checkbox
            id="signal-toggle"
            value={signalToggle}
            onChange={() => {
              setSignalToggle(!signalToggle)
            }}
            label={formatMessage({id: "signalStrength", defaultMessage: "Signal Strength"})}
          />
          <StyledButton style={{marginLeft: "auto"}} onClick={() => {
            setDisplayRange(Range.ONE_DAY);
          }}>1 {formatMessage({id: "day", defaultMessage: "Dag"})}</StyledButton>
          <StyledButton onClick={() => {
            setDisplayRange(Range.THREE_DAYS);
          }}>3 {formatMessage({id: "days", defaultMessage: "Dager"})}</StyledButton>
          <StyledButton onClick={() => {
            setDisplayRange(Range.FIVE_DAYS);
          }}>5 {formatMessage({id: "days", defaultMessage: "Dager"})}</StyledButton>
          <StyledButton onClick={() => {
            setDisplayRange(Range.SEVEN_DAYS);
          }}>7 {formatMessage({id: "days", defaultMessage: "Dager"})}</StyledButton>
          <StyledButton onClick={() => {
            setDisplayRange(Range.FOURTEEN_DAYS);
          }}>14 {formatMessage({id: "days", defaultMessage: "Dager"})}</StyledButton>
        </div>
        <Chart key={"scatter"} options={{
            grid: {
              borderColor: `${theme.colors.textPrimary}30`,
              xaxis: {
                lines: {
                  show: true
                }
              }
            },
            colors: ["rgba(115, 191, 105, 0.5)"],
            markers: {
              size: 3,
              colors: ['rgb(115, 191, 105)', "#87ceeb"],
              strokeWidth: 0,
            },
            chart: {
              id: `chart`,
              background: theme.colors.backgroundSecondary,
              foreColor: theme.colors.textPrimary,
              events: {
                beforeZoom(chart, {xaxis}) {
                  return({
                    xaxis: {
                      ...xaxis,
                      max: xMax
                    }
                  })
                },
              }
            },
            xaxis: {
              type: 'datetime',
              tickPlacement: 'on', 
              tickAmount: ticks,
              max: xMax,
              min: xMin,
              labels: {
                rotate: -45,
                rotateAlways: true,
                trim: false,
                show: true,
                formatter: (value, timestamp) => {
                  return formatDate(value, true, false, true, false, false, formatMessage);
                }
              },
            },
            yaxis:{
              max: maxY( batteryToggle, signalToggle )
            },
          }
        } series={series} type={"scatter"} height={300} />
      </Collapse>
    </ChartWrapper>
  )
}))
