import {DeviceMac} from "../types";

export class MapWrapper{
  private map: any;
  private maps: any;
  private geocoder: any;
  private deviceMacs: DeviceMac[] = [];
  private deviceMac: DeviceMac;
  private readonly setFieldValue: Function;
  private markers: Map<number, any> = new Map<number, any>();
  private mapInitialized: boolean = false;
  private initialPosition: string = "";
  private selectedIndexes: number[] = [];
  private activeIndex: number = 0;
  private autoCompleteInput: any;
  private autoComplete: any;

  constructor(setFieldValue: Function, deviceMacs: DeviceMac[], deviceMac: DeviceMac, initialPosition: string){
    this.setFieldValue = setFieldValue;
    this.deviceMacs = deviceMacs;
    this.deviceMac = deviceMac;
    this.initialPosition =initialPosition;
  }

  public init = (map:any, maps: any) => {
    this.map = map;
    this.maps = maps;
    this.geocoder = new maps.Geocoder();
    this.mapInitialized = true;
    this.createMarkers();
  }

  public createMarkers = () =>{
    const self = this;
    if( self.mapInitialized ){
      self.clearMarkers();
      self.deviceMacs.map(( deviceMac: DeviceMac, index:number ) => {
        self.createMarker(deviceMac,index);
      });
      self.fitBounds();
    }
  }

  public setInitialPosition = (position: string) => {
    this.initialPosition = position;
  }

  public setDeviceMac = (deviceMac: DeviceMac) =>{
    const self = this;
    self.deviceMac = deviceMac;
    self.selectMarker(self.markers.get(self.deviceMac.demaId), true);
  }

  public setActiveIndex = (activeIndex: number) =>{
    const self = this;
    if(self.mapLoaded()){
      if(activeIndex>-1){
        self.activeIndex = activeIndex;
        self.selectMarker(self.markers.get(activeIndex), true);
        self.autoCompleteAddress();
      } else{
        self.fitBounds();
      }
    }
  }

  public setSelectedIndexes = (selectedIndexes: number[]) =>{
    const self = this;
    if(self.mapLoaded()) {
      self.selectedIndexes = selectedIndexes;
      self.resetMarkers();
    }
  }

  public setDeviceMacs = (deviceMacs: DeviceMac[]) =>{
    const self = this;
    self.deviceMacs = deviceMacs;
  }

  private autoCompleteAddress = () => {
    const self = this;
    self.autoCompleteInput = document.getElementById(`${self.activeIndex}-device-address`) as HTMLInputElement;
    if(self.autoCompleteInput && self.maps.places){
      self.autoComplete = new self.maps.places.Autocomplete(self.autoCompleteInput, { types: ["address"] });
      self.autoComplete.setFields(["formatted_address", "geometry"]);
      self.autoComplete.addListener("place_changed", ()=>{
        const place = self.autoComplete.getPlace();
        if(place.geometry && place.geometry.location){
          self.setFieldValue(`devices.${self.activeIndex}.deviceKnownMac.address`, place.formatted_address);
          const position = place.geometry.location.lat().toFixed(5) + "," + place.geometry.location.lng().toFixed(5);
          self.setPosition(position, self.activeIndex);
          const marker = self.markers.get(self.activeIndex);
          marker.setPosition(place.geometry.location);
          self.selectMarker(self.markers.get(self.activeIndex), false);
        }
      });
    }
  }

  private createMarker = (deviceMac: DeviceMac, index: number) =>{
    const self =this;
    const marker = new self.maps.Marker();
    marker.setMap(self.map);
    marker.setDraggable(true);
    marker.setPosition(self.getPosition(deviceMac));
    marker.setIcon('/proffweb/images/logo-map-gray.png');
    marker.setZIndex(0);
    this.maps.event.addListener(marker, 'dragend', (event:any) =>{
      const position = event.latLng.lat().toFixed(5) + "," + event.latLng.lng().toFixed(5);
      marker.setPosition(event.latLng);
      self.setPosition(position, index);
      this.geocoder.geocode(
        { location: event.latLng },
        ( results: any, status: any ) => {
          if (status === "OK") {
            if (results[0]) {
              this.setFieldValue(`devices.${index}.deviceKnownMac.address`, results[0].formatted_address);
            }
          }
        }
      );
    });
    self.markers.set(index, marker);
  }

  private selectMarker = (marker: any, resetMarkers: boolean) => {
    const self = this;
    if(marker){
      if(resetMarkers){
        self.resetMarkers();
      }
      marker.setZIndex(1);
      marker.setDraggable(true);
      marker.setClickable(true);
      marker.setIcon('/proffweb/images/logo-map.png');
      marker.setAnimation(self.maps.Animation.BOUNCE);
      setTimeout(() => { marker.setAnimation(null) }, 1000);
      self.map.setCenter(marker.getPosition());
    }
  }

  private resetMarkers = () => {
    const self= this;
    for(let entry of self.markers.entries()){
      entry[1].setZIndex(0);
      entry[1].setDraggable(false);
      entry[1].setClickable(false);
      if(self.selectedIndexes.indexOf(entry[0]) >= 0){
        entry[1].setIcon('/proffweb/images/logo-map.png');
      }else{
        entry[1].setIcon('/proffweb/images/logo-map-gray.png');
      }
    }
  }

  private clearMarkers = () => {
    const self= this;
    for(let entry of self.markers.entries()){
      entry[1].setMap(null);
    }
    self.markers.clear();
  }

  private setPosition = ( position: string, index: number ) => {
    this.setFieldValue(`devices.${index}.deviceKnownMac.latLng`, position);
    this.setFieldValue(`devices.${index}.latLng`, position);
  }

  private fitBounds = () => {
    const self = this;
    if( self.deviceMacs.length !==0 && self.mapInitialized){
      const bounds = new self.maps.LatLngBounds();
      for(let entry of self.markers.entries()){
        bounds.extend(entry[1].getPosition());
      }
      if (!bounds.isEmpty()) {
        self.map.fitBounds(bounds);
      }
    }else if(self.initialPosition){
      const bounds = new self.maps.LatLngBounds();
      bounds.extend(self.getPositionFromString(self.initialPosition));
      if (!bounds.isEmpty()) {
        self.map.fitBounds(bounds);
      }
    }
  }

  private getPosition =(deviceMac: DeviceMac) => {
    const self = this;
    if( deviceMac.deviceKnownMac.latLng ){
      return self.getPositionFromString(deviceMac.deviceKnownMac.latLng);
    }else if (deviceMac.latLng){
      return self.getPositionFromString(deviceMac.latLng);
    }else {
      const positionFromDeviceMacs = self.getPositionFromDeviceMacs();
      if(positionFromDeviceMacs){
        return self.getPositionFromString(positionFromDeviceMacs);
      }else if( self.initialPosition ){
        return self.getPositionFromString(self.initialPosition);
      }else{
        return self.map.getCenter();
      }
    }
  }

  private getPositionFromString( position: any ){
    const latAndLng = position.split(",");
    return {
      lat: parseFloat(latAndLng[0]),
      lng: parseFloat(latAndLng[1])
    };
  }

  private getPositionFromDeviceMacs =() =>{
    const self = this;
    let lat = 0.00000, lng = 0.00000, position, size=0;
    self.deviceMacs.map((deviceMac: DeviceMac) => {
      if( deviceMac.deviceKnownMac.latLng || deviceMac.latLng ){
        position = self.getPositionFromString((deviceMac.deviceKnownMac.latLng) ? deviceMac.deviceKnownMac.latLng : deviceMac.latLng);
        lat = lat + position.lat;
        lng = lng + position.lng;
        size = size + 1;
      }
    });
    if(size>0){
      return (lat/size).toFixed(5) + "," + (lng/size).toFixed(5);
    } else {
      return null;
    }
  }

  private mapLoaded = (): boolean => {
    const self = this;
    if(self.maps && self.map){
      return true;
    }
    return false;
  }

}
