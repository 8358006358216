import { combineReducers } from 'redux';
import {RootState} from "../types";
import initialization from "./initialization";
import customer from "./customer";
import filter from "./filter";
import feedback from "../../../Feedback/reducer";

export default combineReducers<RootState>({
  initialization,
  feedback,
  customer,
  filter,
});
