import {DeviceHardware} from "../Store/types";
import {SelectOption} from "../types";
import { MessageDescriptor } from "react-intl";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { isDeviceHardwareContent } from "../Settings/TabContent";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";
import { AllPermissions } from "@Safemate/Policy/Provider";

type MapDeviceHardwareOptions = (
  options: Array<DeviceHardware>, 
  formatMessage: (messageDescriptor: MessageDescriptor) => string, 
  permissions: Map<PolicyNameEnum, AllPermissions>,
  custId: number
) => Array<SelectOption>;

export const mapDeviceHardwareOptions: MapDeviceHardwareOptions = (options, formatMessage, permissions, custId: number) => {
  const mappedOptions =  options.map((deviceHardware: DeviceHardware) => {
    if(isDeviceHardwareContent(deviceHardware.dehaId, permissions, custId)){
      return {
        value: deviceHardware.dehaId,
        text: formatMessage({id: `deviceHardware${deviceHardware.dehaId}`, defaultMessage: deviceHardware.name})
      }
    }
  }).filter(opt => opt);
  if(isDeviceHardwareContent(DeviceHardwareEnum.ALL, permissions, custId)){
    mappedOptions.unshift({value: 0, text: formatMessage({id: "all", defaultMessage: "Alle"})});
  }
  return mappedOptions;
};
