import { DeviceSettings, DeviceSettingsWithFallback } from "@Safemate/DefaultSettings/Settings/types";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import * as React from "react";
import { mapDeviceSettings, } from "@Safemate/DefaultSettings/Settings/utils";
import {
  settingsTabWrapper
} from "@Safemate/DefaultSettings/Settings/TabContent";
import {ButtonWrapper, CenteredRow} from "@Safemate/DefaultSettings/Settings/styled";
import {Form, Formik} from "formik";
import {Frame} from "@Safemate/styles";
import {Row} from "react-bootstrap";
import {FooterComp, FooterContext} from "@Safemate/DefaultSettings/Settings/footer";
import UnsavedCheck from "@Safemate/SaveNeeded/unsavedModal";
import {AppState} from "@Safemate/Store/types";
import {saveDeviceSettings} from "@Safemate/DefaultSettings/Store/routines";
import {connect} from "react-redux";
import DeviceHardware from "@Safemate/DefaultSettings/DeviceHardware";
import { useIntl } from "react-intl";
import { Tab } from "@Elements/Tabs";

interface DeviceSettingsProps{
  tabs: JSX.Element[];
  deviceSettings: DeviceSettingsWithFallback;
  selectedDeviceHardwareId: number;
  saveDeviceSettings: Routine<ActionFunctionAny<Action<any>>>;
}

const DeviceSettingsComp = ({ tabs, deviceSettings, selectedDeviceHardwareId, saveDeviceSettings,  }: DeviceSettingsProps) => {

  const { formatMessage } = useIntl();  
  const settings: DeviceSettings = mapDeviceSettings(deviceSettings);

  return(
    <Tab key={`deviceSettings`} title={formatMessage({id: "settings", defaultMessage: "Settings"})} name={`deviceSettings`}>
      <Formik
        initialValues={settings}
        enableReinitialize
        onSubmit={(values: DeviceSettings) => {saveDeviceSettings(values, selectedDeviceHardwareId)}}
      >
        <Form>
          <Frame>
            <Row>
              <div style={{paddingBottom: "1em"}}>
                <ButtonWrapper>
                  <CenteredRow>
                    <DeviceHardware/>
                  </CenteredRow>
                </ButtonWrapper>
                {settingsTabWrapper(tabs)}
              </div>
              <FooterComp context={FooterContext.DEVICE_SETTINGS}/>
            </Row>
          </Frame>
          <UnsavedCheck />
        </Form>
      </Formik>
    </Tab>
  )
}

const mapStateToProps = ({defaultSettings: {deviceHardware, settings: {deviceSettings}}}: AppState) => {
  return{
    deviceSettings,
    selectedDeviceHardwareId: deviceHardware.selectedDeviceHardwareId,
  }
};

const mapDispatchToProps = {
  saveDeviceSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettingsComp);
