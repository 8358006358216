import styled from "styled-components";

export const InnerFrame = styled.ul`
  padding: 10px;
  margin-bottom: 15px;
  padding-right: 0;
  padding-left: 0;
  
  ${props =>
    `
      border: ${props.theme.colors.border};
      background: ${props.theme.colors.backgroundTertiary};
    `
  }
`;

export const CustomerRow = styled.li<{active: boolean, level: number, hasAccess: boolean}>`
  
  cursor: pointer;
  line-height: 2.5em;
  
  &:hover{
    ${props =>
      `
        ${props.hasAccess && `background: ${props.theme.colors.borderColor};`}
      `
    } 
  }
  
  ${props =>
    `
      padding-left: ${props.level*20}px;
      color: ${props.active ? "black" : props.hasAccess ? props.theme.colors.textPrimary : "gray"};
      ${props.active && `background: ${props.theme.colors.accent};`}
    `
  }
`;
