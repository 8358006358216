import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import deviceSaga from "./device";

function* AddSafemateSaga(){
  yield all([
    fork(initializationSaga),
    fork(deviceSaga)
  ])
}
export default AddSafemateSaga;
