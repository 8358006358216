import styled from 'styled-components';

const Bar = styled.div`
  background: gray;
  height: 24px;
  width: 4px;

  & + & {
    margin-left: 1px;
  }
`;

export default Bar;
