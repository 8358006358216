import FieldWrapper, {GenericDropdown} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {batteryThreshold, powerFailureReportDelay} from "./options";
import {LabelEnum} from "../../LabelEnum";
import { TIME_OFFSET, status } from "@Elements/Form/utils";

// Dropdowns
export const BatteryThreshold = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.BATTERY,
  field: SettingEnum.BATTERY_THRESHOLD,
  title: LabelEnum.BATTERY_THRESHOLD,
  dataOptions: {
    options: batteryThreshold,
    skipMap: true
  }
});

export const PowerFailureReportDelay = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.BATTERY,
  field: SettingEnum.POWER_FAILURE_REPORT_DELAY,
  title: LabelEnum.POWER_FAILURE_REPORT_DELAY,
  dataOptions: {
    options: powerFailureReportDelay,
    nullValue: status.disable,
    offset: TIME_OFFSET.SECONDS_IN_SECOND
  }
});
