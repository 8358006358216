import {call, put, select, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../../Store/types';
import * as Routines from "../routines";
import { DatabaseText, SelectedText } from '../types';
import { feedbackFailure, feedbackSuccess } from '@Safemate/Feedback/reducer';
import { getLanguageCode } from '../selectors';

function* getTexts(action: AppAction<string>){
  try {
    const texts = yield call(Api.getTexts, action.payload);
    yield put(Routines.getTexts.success(texts));
  }
  catch(e){
    yield put(Routines.getTexts.failure());
  }
}

function* deleteText(action: AppAction<number>){
  try{
    const texts = yield call(Api.deleteText, action.payload);
    yield put(Routines.deleteText.success(texts));
    yield feedbackSuccess("textDeleted");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteText.failure());
  }
}

function* addTexts(action: AppAction<DatabaseText[]>){
  try{
    const texts = yield call(Api.addTexts, action.payload);

    const languageCode = yield select(getLanguageCode);
    yield put(Routines.getTexts.trigger(languageCode));

    yield put(Routines.addTexts.success(texts));
    yield feedbackSuccess("textAdded");
  }
  catch(e){
    console.log(e)
    yield feedbackFailure(e);
    yield put(Routines.addTexts.failure());
  }
}

function* getTextForLabel(action: AppAction<string>){
  try{
    const text = yield call(Api.getTextForLabel, action.payload);
    const selected: SelectedText = {
      label: action.payload,
      texts: text
    }
    yield put(Routines.getTextForLabel.success(selected));
  }
  catch(e){
    yield put(Routines.getTextForLabel.failure());
  }
}

function* textSaga(){
  yield takeLatest(Routines.getTexts.TRIGGER, getTexts);
  yield takeLatest(Routines.deleteText.TRIGGER, deleteText);
  yield takeLatest(Routines.addTexts.TRIGGER, addTexts);
  yield takeLatest(Routines.getTextForLabel.TRIGGER, getTextForLabel);
}

export default textSaga;
