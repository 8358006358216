import * as React from "react";
import {Button} from "react-bootstrap";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import styled from 'styled-components';
import {
  FilterCustomer,
  filterCustomer,
  FilterRole,
  filterRole,
  FilterSearch,
  filterSearch,
  FilterStatus,
  filterStatus,
  FilterOrder,
  filterOrder,
  SetNew,
  setNew
} from "../../Store/actions";
import {connect} from "react-redux";
import {defaultValues} from "../Edit/defaultValues";
import {RouteEnum} from "../../RouteEnum";
import {FilterAccountStatus, FilterState, RootState} from "../../Store/types";
import {Option} from "../../../DefaultSettings/Settings/FieldWrapper";
import {GetOrderFilter, mapRoles} from "../utils";
import {Role} from "../../Models/Role";
import {Customer} from "../../Models/Customer";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {getCustomerListAsNumber} from "../utils";
import {DropdownSpan, FirstDropdown, SecondDropdown, ThirdDropdown, FlexDiv, LastDropdown, NewSpan, RoleButton} from "./styles";
import {initializeLdapUsers} from "../../Store/routines";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {LabelEnum} from "../../LabelEnum";
import {AllPermissions, PolicyContext} from "../../../Policy/Provider";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import SafemateHeader from "../../../Header";
import { SiteTypeEnum } from "../../../SiteTypeEnum";
import Search from "@Elements/Search";
import { OrderFilterEnum } from "../../OrderFilterEnum";
import { getOrderFilter } from "../utils";
import {FormattedMessage, MessageDescriptor, useIntl} from "react-intl";
import { useHistory } from "react-router";
import { AppState } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";


const StyledBtn = styled(Button)`
  margin-bottom: 5px;
`;

export type GetActiveFilter = (
  formatMessage: (message: MessageDescriptor) => string
) => Option[];

export const getActiveFilter: GetActiveFilter = (formatMessage) => {
  return[
    {text: formatMessage({id: LabelEnum.ACTIVE_USERS, defaultMessage: "Aktive"}), value: FilterAccountStatus.ACTIVE},
    {text: formatMessage({id: LabelEnum.DELETED_USERS, defaultMessage: "Slettet"}), value: FilterAccountStatus.DELETED},
  ]
}

interface HeaderProps{
  setNew: SetNew;
  selectedStatus: number;
  selectedRole: number[];
  selectedCustomer: number[];
  selectedSearch: string;
  selectedOrderFilter: OrderFilterEnum;
  filterSearch: FilterSearch;
  filterRole: FilterRole;
  filterCustomer: FilterCustomer;
  filterStatus: FilterStatus;
  filterOrder: FilterOrder;
  roles: Array<Role>;
  users: Array<UserWithCustomer>;
  customers: ICustomerWithPolicy[];
  filter: FilterState,
  isPrivate: boolean;
  siteType: SiteTypeEnum;
  initializeLdapUsers: Routine<ActionFunctionAny<Action<any>>>;
  companyName: string;
}

const mapStateToProps = (
  {
    appData:{
      currentContext,
      isPrivate,
      customersWithPolicy,
      superfirm: { name }
    },
    users: {
      role: {roles}, 
      filter, 
      filter:{status: selectedStatus, role: selectedRole, customer: selectedCustomer, search: selectedSearch, orderFilter: selectedOrderFilter},
      user: {users}
    }
  }: AppState) => {
  return{
    siteType: currentContext,
    roles,
    users,
    customers: customersWithPolicy,
    filter,
    selectedStatus,
    selectedRole,
    selectedCustomer,
    selectedSearch,
    selectedOrderFilter,
    isPrivate,
    companyName: name
  }
}

const mapDispatchToProps = {
  setNew,
  filterSearch,
  filterRole,
  filterCustomer,
  filterStatus,
  filterOrder,
  initializeLdapUsers,
}

const Header = connect(mapStateToProps, mapDispatchToProps)(
  ({ companyName, initializeLdapUsers, roles, users, customers, selectedStatus, selectedRole, selectedCustomer, selectedSearch, selectedOrderFilter, isPrivate, siteType, setNew, filter, filterSearch, filterRole, filterCustomer, filterStatus, filterOrder}: HeaderProps) => {
    
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const isAllowedToAddNew = permissions.has(PolicyNameEnum.USER_PRIVS) && (permissions.get(PolicyNameEnum.USER_PRIVS)!.generalPermissions.edit || permissions.get(PolicyNameEnum.USER_PRIVS)!.generalPermissions.all);
    const isSuperadmin = permissions.has(PolicyNameEnum.ACCESS_TO_ALL) && permissions.get(PolicyNameEnum.ACCESS_TO_ALL)!.generalPermissions.all;
    const isAhp = siteType === SiteTypeEnum.AHP;

    const { formatMessage } = useIntl();
    const history = useHistory();

    const mappedRoles = React.useMemo(() => {
      return mapRoles(roles, formatMessage, isAhp);
    }, [roles, formatMessage]);

    const mappedCustomers = React.useMemo(() => {
      const relevantCustomers = getCustomerListAsNumber(users);

      const c: Option[] = customers.map(customer => {
        if (relevantCustomers.includes(customer.customerId))
          return {
            text: customer.customerName,
            value: customer.customerId
          };
      }).filter(item => item) as Option[];
      return c;
    }, [users, customers]);


    return (
      <div>
        <SafemateHeader
          icon={null}
          title={`${formatMessage({id: LabelEnum.USER_ADMIN_HEADER, defaultMessage: "Brukeradministrasjon for"})} ${companyName}`}
        >
          <NewSpan>
            {isSuperadmin && !isPrivate && !isAhp &&
              <RoleButton
                needsMargin={isAllowedToAddNew}
                onClick={() => {
                  location.hash = "role_manager"
                }}
              >
                {formatMessage({id: "roleEditor", defaultMessage: "Rolle-editor"})}
              </RoleButton>
            }
            {isAllowedToAddNew &&
              <StyledBtn
                onClick={() => {
                  if(!isAhp){
                    initializeLdapUsers();
                  }
                  setNew(defaultValues);
                  history.push(RouteEnum.EDIT)
                }}
              >
                {formatMessage({id: LabelEnum.ADD_NEW_USER, defaultMessage: "Legg til ny bruker"})}
              </StyledBtn>
            }
          </NewSpan>
        </SafemateHeader>
        {!isPrivate && users && users.length > 1  &&
        <FlexDiv>
          <FirstDropdown>
            <Dropdown
              initial={selectedStatus}
              options={getActiveFilter(formatMessage)}
              onChange={(option) => {
                const value = (option as Option).value
                filterStatus(value)}
              }
            />
          </FirstDropdown>
          <DropdownSpan>
            <Dropdown
              enableReset
              initial={selectedRole}
              options={mappedRoles}
              multi
              title={formatMessage({id: "allRoles", defaultMessage: "Alle roller"})}
              onChange={(option) => {
                const selected = (option as Option[]).map(item => item.value);
                filterRole(selected)}
              }
            />
          </DropdownSpan>
          {filter.status === FilterAccountStatus.ACTIVE && siteType !== SiteTypeEnum.AHP &&
          <DropdownSpan>
            <SecondDropdown>
              <Dropdown
                enableReset
                initial={selectedCustomer}
                options={mappedCustomers}
                multi
                title={formatMessage({id: "allCustomers", defaultMessage: "All Customers"})}
                onChange={(option) => {
                  const selected = (option as Option[]).map(item => item.value);
                  filterCustomer(selected)}
                }
              />
            </SecondDropdown>
          </DropdownSpan>}
          {filter.status === FilterAccountStatus.ACTIVE && siteType !== SiteTypeEnum.AHP &&
          <DropdownSpan>
            <ThirdDropdown>
              <Dropdown
                initial={selectedOrderFilter}
                options={getOrderFilter(formatMessage)}
                title={formatMessage({id: "rolesSortPro", defaultMessage: "Standardsortering"})}
                onChange={option => {
                  const selected = (option as Option).value;
                  filterOrder(selected);
                }}
              />
            </ThirdDropdown>
          </DropdownSpan>}
          <LastDropdown>
            <Search
              id="search"
              value={selectedSearch}
              autoComplete={"new-password"}
              placeholder={selectedSearch.trim() != "" ? selectedSearch : formatMessage({id: "userSearch", defaultMessage: "Search"})}
              handleChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                const value = event.currentTarget.value;
                filterSearch(value || "");
              }}
            />
          </LastDropdown>
            </FlexDiv> }
      </div>
    )
  }
)

export default Header;
