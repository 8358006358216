import { PolicyState } from "../types";
import {Reducer} from "redux";
import { getPolicies } from "../routines";
import { MapActionTypes } from "../actionTypes";

const policyState: PolicyState = {
  policies: {
    custId: 0,
    global: false,
    id: 0,
    policies: {},
    roleName: "",
    technicianRights: false
  }
};

const policy: Reducer<PolicyState> = (state: PolicyState = policyState, action) => {
  switch (action.type) {

    case getPolicies.SUCCESS:
      return {
        ...state,
        policies: action.payload
      }

    case MapActionTypes.RESET_STATE:{
      return policyState
    }

    default:
      return state;
  }
};

export default policy;
