import styled from 'styled-components';

export const SpacedTd = styled.td`
  padding: 2px;
`;

export const Td = styled.td<{priority?: number, align?: string, width?: string, system?: boolean}>`
  
  ${props => 
    `
    ${props.priority && `@media (max-width: 1200px) {
      ${props.priority <= 1 && 'display: none;'};
    }
    @media (max-width: 992px) {
      ${props.priority <= 2 && 'display: none;'};
    }
    @media (max-width: 768px) {
      ${props.priority <= 3 && 'display: none;'};
    }`}
    
    text-align: ${props.align};
    width: 25%;
    color: ${props.system ? props.theme.colors.accent : props.theme.colors.textPrimary};
    `
  }
  
  padding: 12px;
`;

export const Th = styled.th<{priority: number}>`
  @media (max-width: 1200px) {
    ${props => props.priority <= 1 && 'display: none;'};
  }
  @media (max-width: 992px) {
    ${props => props.priority <= 2 && 'display: none;'};
  }
  @media (max-width: 768px) {
    ${props => props.priority <= 3 && 'display: none;'};
  }

  padding: 12px;
  text-align: ${props => props.align};
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
`;

export const TranslatedSpan = styled.span`
  @media (max-width: 1200px) {
    transform-origin: bottom left;
    transform: rotate(-60deg) translateY(25%);
    position: absolute;
    bottom: 15px;
  }
`;

export const HeaderTr = styled.tr`
  text-transform: capitalize;
`;

export const ContentTr = styled.tr<{removeHover?: boolean}>`
  ${props => !props.removeHover &&  `
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }`
  }

  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
  outline: ${props => props.theme.colors.border};
`;

export const HiddenTr = styled.tr<{priority: number}>`
  @media (min-width: 1200px) {
    ${props => props.priority >= 1 && 'display: none;'};
  }
  @media (min-width: 992px) {
    ${props => props.priority >= 2 && 'display: none;'};
  }
  @media (min-width: 768px) {
    ${props => props.priority >= 3 && 'display: none;'};
  }

  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
  border-right: ${props => props.theme.colors.border};
  border-left: ${props => props.theme.colors.border};

  text-align: center;
`;

export const SpacedTr = styled.tr``;

export const Table = styled.table`
  width: 100%;
  margin-bottom: 30px;
`;
