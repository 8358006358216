import React from 'react';

const HeartDotted = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="25px"
      height="25px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      {...props}
    >
      <path
        d="M35.575,18.573c1.355,0,2.703,0.134,4.032,0.367c0.339-4.199,0.473-8.415,0.526-12.637
                C38.627,6.11,37.106,6,35.575,6c-6.669,0-12.846,1.806-18.079,4.963c2.429,3.411,4.74,6.898,6.991,10.427
                C27.705,19.595,31.467,18.573,35.575,18.573z"
      />
      <path
        d="M58.016,33.408H69.77c1.571-4.135,4.571-7.709,8.328-10.319c-0.247-4.665-0.512-9.327-0.58-13.995
                C72.25,11.38,67.53,14.83,63.863,19.137c-3.692-4.336-8.448-7.802-13.757-10.088c-0.085,4.611-0.305,9.23-0.789,13.813
                C53.234,25.488,56.371,29.155,58.016,33.408z"
      />
      <path
        d="M92.152,18.573c2.731,0,5.302,0.469,7.673,1.294c0.525-1.586,1.037-3.177,1.535-4.773
                c0.74-2.372,1.391-4.828,2.167-7.232C99.977,6.659,96.154,6,92.15,6c-1.558,0-3.104,0.114-4.635,0.315
                c-0.005,4.227,0.188,8.445,0.411,12.664C89.318,18.722,90.731,18.573,92.152,18.573z"
      />
      <path
        d="M126.666,38.558c-0.602-10.897-6.052-20.307-14.281-26.169c-1.146,3.588-1.969,7.373-3.127,10.791
                c-0.263,0.776-0.529,1.554-0.798,2.333c3.165,3.444,5.191,7.962,5.606,13.102C118.27,38.623,122.469,38.599,126.666,38.558z"
      />
      <path
        d="M116.225,69.175c4.914-6.906,8.239-13.66,9.68-20.672c-4.33,0.047-8.664,0.063-13.001,0.064
                c-1.369,4.494-3.849,9.057-7.288,13.826C109.12,64.702,112.657,66.961,116.225,69.175z"
      />
      <path
        d="M35.262,95.166c2.96-2.977,5.856-6.03,8.575-9.23c-6.189-5.865-11.593-11.294-16.049-16.429
                c-2.951,2.98-5.963,5.906-9.003,8.804C23.487,83.68,29.025,89.24,35.262,95.166z"
      />
      <path
        d="M91.526,91.735c0.547,0.906,1.115,1.799,1.668,2.702c6.439-6.134,12.115-11.883,16.874-17.441
                c-3.588-2.242-7.147-4.527-10.675-6.863c-3.648,4.152-7.909,8.5-12.691,13.114C88.271,86.104,89.85,88.955,91.526,91.735z"
      />
      <path
        d="M58.145,99.137c-2.469-2.241-4.869-4.428-7.174-6.549c-2.602,3.243-5.373,6.391-8.402,9.409
                c3.854,3.556,7.917,7.25,12.178,11.122l3.926,3.546C58.5,110.822,58.307,104.98,58.145,99.137z"
      />
      <path
        d="M83.882,98.425c-1.597-2.647-3.099-5.399-4.593-8.177c-3.494,3.242-7.204,6.622-11.103,10.157
                c0.157,5.461,0.339,10.923,0.494,16.385c0.002,0.057,0.002,0.114,0.004,0.171l4.294-3.91c4.474-4.057,8.73-7.919,12.756-11.632
                C85.12,100.419,84.488,99.43,83.882,98.425z"
      />
      <path
        d="M21.57,61.637c-3.287-4.677-5.631-9.161-6.897-13.583c-4.298,0.065-8.597,0.142-12.896,0.223
                c1.504,7.547,5.197,14.792,10.7,22.246C15.547,67.602,18.594,64.652,21.57,61.637z"
      />
      <path
        d="M13.715,38.079c0.365-3.504,1.477-6.71,3.194-9.468c-2.304-3.818-4.652-7.596-7.143-11.249
                C4.762,22.94,1.587,30.201,1.08,38.293C5.292,38.215,9.503,38.144,13.715,38.079z"
      />
    </svg>
  );
};

export default HeartDotted;
