import * as React from "react";
import { ISensorDTO } from "./types";
import { Routine } from "redux-saga-routines";
import styled from "styled-components";
import { Action, ActionFunctionAny } from "redux-actions";
import { Grid, Row, Col } from "react-bootstrap";
import { getImageForType } from "./sensorType";
import { useIntl } from "react-intl";
import { formatDate } from "@Safemate/utils";
import moment from "moment";
import { AppState } from "@Safemate/Store/types";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { fetchSensors, getTransactionsForSensor } from "../Store/routines";
import Loader from "@Elements/Loader";
import { Wrapper } from "./Add/styles";
import { SensorTransactions } from "../Store/types";
import { mapHeader, mapTransaction } from "@Safemate/map/Log/utils";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";
import { MapType } from "@Safemate/map";
import {Table, Tr, Td, Th} from "@Safemate/map/Log/table";
import { Transaction } from "@Safemate/map/Store/types";
import {Button} from "react-bootstrap";
import { Link } from "react-router-dom";

const InfoSpan = styled.span`
  margin-right: 15px;
`;

const InfoDiv = styled.div`
  display: flex;
  align-items: center;
`;

const BackSpan = styled.span`
  margin-left: auto;
`;

const Pagination = styled.div`

  margin: 10px 0;
  text-align: center;

  button {
    margin: 0 2px;
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

interface SensorDetailsProps{
  returnPath: string;
  sensors: ISensorDTO[];
  deviId: number;
  fetchSensors: Routine<ActionFunctionAny<Action<any>>>; 
  getTransactionsForSensor: Routine<ActionFunctionAny<Action<any>>>; 
  sensorTransactions: SensorTransactions;
}

interface RouterParams{
  id: string;
}

const mapStateToProps = ({ settings: { initialization: { deviId }, sensor: { sensors, sensorTransactions } } }: AppState) => {
  return{
    sensors,
    deviId,
    sensorTransactions
  }
}

const mapDispatchToProps = {
  fetchSensors,
  getTransactionsForSensor
}

export const SensorDetails = connect(mapStateToProps, mapDispatchToProps)(({returnPath, sensors, deviId, sensorTransactions, fetchSensors, getTransactionsForSensor}: SensorDetailsProps) => {

  const { formatMessage } = useIntl();
  const [ sensor, setSensor ] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [ transactions, setSensorTransactions ] = React.useState<Transaction[]>([]);
  const { id } = useParams<RouterParams>();
  const sensorId = parseInt(id);
  const rowsPerPage = 10;

  React.useEffect(() => {
    fetchSensors(deviId);
    getTransactionsForSensor(sensorId);
  }, [])

  React.useEffect(() => {
    const sensor = sensors.find(sensor => sensor.sensor.deseId === sensorId);
    const tx = sensorTransactions[sensorId];
    setSensor(sensor);
    setSensorTransactions(tx || []);
  }, [sensors, sensorTransactions])

  const transactionsHeader = mapHeader(formatMessage, DeviceHardwareEnum.SAFEMATE_HOME, MapType.SINGLE, false);
  const transactionsContent = transactions.map(transaction => { 
    const mappedTransaction = mapTransaction(transaction, formatMessage, DeviceHardwareEnum.SAFEMATE_HOME, [], MapType.SINGLE, false);
    return <Tr key={transaction.id} new={transaction.isNew} alarm={transaction.alarmType} notifyEvent={transaction.isNotifyEvent}>
      {mappedTransaction.map(item => {
        if(item.display){
          return <Td priority={item.priority}>{
            item.value
          }</Td>
        }
      }).filter(item => item)}
    </Tr>
  })

  if(!sensor){
    return <Wrapper><Loader/></Wrapper>
  }
  
  const totalRows = transactionsContent.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const displayRows = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return transactionsContent.slice(start, end);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return(
    <Wrapper>
    <Grid fluid>
      <Row style={{ paddingBottom: '10px' }}>
        <Col md={4} sm={5} xs={12}>
          {getImageForType(sensor.type.code)}
        </Col>
        <Col md={8} sm={7} xs={12}>
          <InfoDiv>
            {sensor.sensor.manufactureDate ? <InfoSpan>{formatMessage({id: "manufactureDate", defaultMessage: "Manufacture date"})}: </InfoSpan> : ""}
            {sensor.sensor.created ? <InfoSpan>{formatMessage({id: "added", defaultMessage: "Lagt til"})}: {formatDate(sensor.sensor.created, true, false, false, false, false, formatMessage)}</InfoSpan> : ""}
            {(sensor.sensor.lastContact && moment.utc(sensor.sensor.lastContact).year() > 1970) ? <InfoSpan>{formatMessage({id: "sensTableHeadLastContact", defaultMessage: "Siste kontakt"})}: {moment.utc(sensor.sensor.lastContact).format('DD.MM.YYYY HH:mm')}</InfoSpan> : ""}
            <BackSpan>
              <Link to={returnPath}>
              < Button>{formatMessage({id: "back", defaultMessage: "tilbake"})}</Button>
              </Link>
            </BackSpan>
          </InfoDiv>
          <div>
          <Table>
            {transactionsContent.length!=0 && <thead>
              <tr>
                {transactionsHeader.map(item => {
                  if(item.display)
                    return <Th priority={item.priority}>{item.value}</Th>
                }).filter(item => item)}
              </tr>
            </thead>}
            <tbody>
              {displayRows()}
            </tbody>
          </Table>
          </div>
          {transactionsContent.length > 0 && <Pagination>
            <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
              {formatMessage({id: "previous", defaultMessage: "Forrige"})}
            </Button>
            <span>{formatMessage({id: "page", defaultMessage: "Side"})} {currentPage} {formatMessage({id: "of", defaultMessage: "av"})} {totalPages}</span>
            <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
              {formatMessage({id: "next", defaultMessage: "Neste"})}
            </Button>
          </Pagination>}
        </Col>
      </Row>
    </Grid>
    </Wrapper>
  )
})