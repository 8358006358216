import {call, put, select, takeLatest} from "redux-saga/effects";
import * as Api from '../api';
import * as Routines from '../routines';
import {getCurrentCustomer, getCurrentDeviceHardwareId} from "../selectors";
import {
  EventSetupSettings,
  DeviceSettings,
  DeviceSettingsWithFallback,
  OtherSettings,
  OtherSettingsWithFallback,
  CustomerSettings,
  IndoorLocationSettings,
  GeofenceSettings
} from "../../Settings/types";
import {LabelEnum} from "../../LabelEnum";
import {feedbackFailure, feedbackSuccess} from "../../../Feedback/reducer";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import {Geofence} from "@Safemate/Settings/Geofence/types";
import { AppAction } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { Location } from "../types";

function* fetchSettingsFromDeviceHardware(action: AppAction<number>){

  const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
  const deviceHardwareId = action.payload;

  try{
    yield put(Routines.fetchSettings({customerId: customer.customerId, deviceHardwareId}));
    yield put(Routines.fetchSettingsFromDeviceHardware.success());
  }
  catch(e){
    yield put(Routines.fetchSettingsFromDeviceHardware.failure());
  }
}

function* fetchSettingsFromCustomer(action: AppAction<ICustomerWithPolicy>){

  const customerId = action.payload.customerId;
  const deviceHardwareId: number = yield select(getCurrentDeviceHardwareId);

  try{
    yield put(Routines.fetchSettings({customerId, deviceHardwareId}));
    yield put(Routines.fetchSettingsFromCustomer.success());
  }
  catch(e){
    yield put(Routines.fetchSettingsFromCustomer.failure());
  }
}

interface FetchSettings{
  customerId: number;
  deviceHardwareId: number;
}

function* fetchSettings(action: AppAction<FetchSettings>){
  try{

    const customerSetting: CustomerSettings = yield call(Api.getCustomerSettings, action.payload);
    yield put(Routines.fetchCustomerSettings.success(customerSetting));

    const customerLocation: Location = yield call(Api.getPositionForCustomer, action.payload.customerId);
    yield put(Routines.customerPosition.success(customerLocation));

    const indoorLocations: IndoorLocation[] = yield call(Api.getCustomerIndoorLocations, action.payload.customerId);
    yield put(Routines.customerIndoorLocations.success(indoorLocations));

    yield put(Routines.fetchSettings.success());
  }
  catch(e){
    yield put(Routines.fetchSettings.failure(e));
  }
}


function* saveDeviceSettings(action: AppAction<DeviceSettings>){
  try{
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const customerId = customer.customerId;
    const response: DeviceSettingsWithFallback = yield call(Api.saveDeviceSettings, {customerId, settings: action.payload});
    yield put(Routines.saveDeviceSettings.success(response));
    yield feedbackSuccess(LabelEnum.SETTINGS_SAVED);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveDeviceSettings.failure(e));
  }
  yield put(Routines.saveDeviceSettings.fulfill());
}

function* saveOtherSettings(action: AppAction<OtherSettings>){
  try{
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const customerId = customer.customerId;
    const response: OtherSettingsWithFallback = yield call(Api.saveOtherSettings, {customerId, settings: action.payload});
    yield put(Routines.saveOtherSettings.success(response));
    yield feedbackSuccess(LabelEnum.SETTINGS_SAVED);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveOtherSettings.failure());
  }
  yield put(Routines.saveOtherSettings.fulfill());
}

function* saveEventSetupSettings(action: AppAction<EventSetupSettings>){
  try{
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const customerId = customer.customerId;
    const response: EventSetupSettings = yield call(Api.saveEventSetupSettings, {customerId, settings: action.payload});
    yield put(Routines.saveEventSetupSettings.success(response));
    yield feedbackSuccess(LabelEnum.SETTINGS_SAVED);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveEventSetupSettings.failure());
  }
  yield put(Routines.saveEventSetupSettings.fulfill());
}

function* saveIndoorLocationSettings(action: AppAction<IndoorLocationSettings>){
  try{
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const customerId = customer.customerId;
    const response: IndoorLocation[] = yield call(Api.saveIndoorLocationSettings, {customerId, settings: action.payload.indoorLocations});
    yield put(Routines.saveIndoorLocationSettings.success(response));
    yield feedbackSuccess(LabelEnum.SETTINGS_SAVED);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveIndoorLocationSettings.failure());
  }
  yield put(Routines.saveIndoorLocationSettings.fulfill());
}

function* deleteIndoorLocation(action: AppAction<number>){
  try{
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const custId = customer.customerId;
    const response: IndoorLocation[] = yield call(Api.deleteIndoorLocation, {customerId: custId, deleteId: action.payload});
    yield put(Routines.deleteIndoorLocation.success(response));

    const customerSetting: CustomerSettings = yield call(Api.getCustomerSettings, {customerId: custId, deviceHardwareId: 0});
    yield put(Routines.fetchCustomerSettings.success(customerSetting));

    const indoorLocations: IndoorLocation[] = yield call(Api.getCustomerIndoorLocations, custId);
    yield put(Routines.customerIndoorLocations.success(indoorLocations));

    yield feedbackSuccess("deleteIndoorLocation");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteIndoorLocation.failure(e));
  }
}

function* saveGeofenceSettings(action: AppAction<GeofenceSettings>){
  try{
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const customerId = customer.customerId;
    const response: Geofence[] = yield call(Api.saveGeofenceSettings, {customerId, settings: action.payload.geofences});
    yield put(Routines.saveGeofenceSettings.success(response));
    yield feedbackSuccess(LabelEnum.SETTINGS_SAVED);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveGeofenceSettings.failure());
  }
  yield put(Routines.saveGeofenceSettings.fulfill());
}

export interface DeleteGeofence{
  custId: number;
  geofenceId: number;
}

function* deleteGeofence(action: AppAction<number>){
  try{
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const custId = customer.customerId;
    const response: Geofence[] = yield call(Api.deleteGeofence, {customerId: custId, deleteId: action.payload});
    yield put(Routines.deleteGeofence.success(response));
    yield feedbackSuccess("deletedGeofence");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteGeofence.failure(e));
  }
}

function* resetDeviceSettings(action: AppAction<null>){
  try {
    const deviceHardwareId: number = yield select(getCurrentDeviceHardwareId);
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const customerId = customer.customerId;
    const revertedSettings: DeviceSettingsWithFallback = yield call(Api.revertDeviceSettings, customerId, deviceHardwareId);
    yield put(Routines.resetDeviceSettings.success(revertedSettings));
  }
  catch(e) {
    yield put(Routines.resetDeviceSettings.failure());
  }
  yield put(Routines.resetDeviceSettings.fulfill());
}

function* resetOtherSettings(action: AppAction<null>){
  try {
    const customer: ICustomerWithPolicy = yield select(getCurrentCustomer);
    const customerId = customer.customerId;
    const revertedSettings: DeviceSettingsWithFallback = yield call(Api.revertOtherSettings, customerId);
    yield put(Routines.resetOtherSettings.success(revertedSettings));
  }
  catch(e) {
    yield put(Routines.resetOtherSettings.failure());
  }
  yield put(Routines.resetOtherSettings.fulfill());
}

function* getClimaxConfig(action: AppAction<number>){
  try{
    const climaxConfig: boolean = yield call(Api.getClimaxConfig, action.payload);
    yield put(Routines.getClimaxConfig.success(climaxConfig));
  }
  catch(e){
    yield put(Routines.getClimaxConfig.failure());
  }
}


function* settingsSaga(){
  yield takeLatest(Routines.fetchSettings.TRIGGER, fetchSettings);
  yield takeLatest(Routines.fetchSettingsFromDeviceHardware.TRIGGER, fetchSettingsFromDeviceHardware);
  yield takeLatest(Routines.fetchSettingsFromCustomer.TRIGGER, fetchSettingsFromCustomer);
  yield takeLatest(Routines.saveDeviceSettings.TRIGGER, saveDeviceSettings);
  yield takeLatest(Routines.saveOtherSettings.TRIGGER, saveOtherSettings);
  yield takeLatest(Routines.saveEventSetupSettings.TRIGGER, saveEventSetupSettings);
  yield takeLatest(Routines.saveIndoorLocationSettings.TRIGGER, saveIndoorLocationSettings);
  yield takeLatest(Routines.deleteIndoorLocation.TRIGGER, deleteIndoorLocation);
  yield takeLatest(Routines.saveGeofenceSettings.TRIGGER, saveGeofenceSettings);
  yield takeLatest(Routines.deleteGeofence.TRIGGER, deleteGeofence);
  yield takeLatest(Routines.resetDeviceSettings.TRIGGER, resetDeviceSettings);
  yield takeLatest(Routines.resetOtherSettings.TRIGGER, resetOtherSettings);
  yield takeLatest(Routines.getClimaxConfig.TRIGGER, getClimaxConfig);
}

export default settingsSaga;
