import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import actionSaga from "./actions";
import userSaga from "./user";

function* userAdminSaga(){
  yield all([
    fork(initializationSaga),
    fork(actionSaga),
    fork(userSaga)
  ])
}
export default userAdminSaga;
