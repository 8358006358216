import { combineReducers } from 'redux';

import customer from "./customer";
import paymentProfile from "./paymentProfile";
import initialization from "./initialization";
import {RootState} from "../types";
import feedback from "../../../Feedback/reducer";


export default combineReducers<RootState>({
  customer,
  initialization,
  feedback,
  paymentProfile,
});
