import {call, put, race, take, takeLatest} from 'redux-saga/effects';
import * as Routines from "../routines";
import {Customer} from "../../Models/Customer";
import { SiteTypeEnum } from "../../../SiteTypeEnum";
import { AppAction } from '@Safemate/Store/types';

export interface Initialize{
  roles: string;
  siteType: SiteTypeEnum;
}

export interface IRace<T> {
  success: AppAction<T>;
  failure: any;
}

function* initialize(action: AppAction<Initialize>){
  try {

    yield put(Routines.fetchInvoices());
    yield put(Routines.getHistory())
    const {success: forListSuccess, failure: forListFailure}: IRace<Array<Customer>> = yield race({
      success: take(Routines.fetchInvoices.SUCCESS),
      failure: take(Routines.fetchInvoices.FAILURE)
    });


    if(forListSuccess){
      yield put(Routines.initializeInvoices.success());
    }
  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
}

function* initializationSaga(){
  yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
