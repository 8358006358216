import styled from 'styled-components';
import { FormGroup } from 'react-bootstrap';

export const StyledButton = styled.button`
  font-size: 18px !important;
  text-shadow: none;
  transition: color 0.3s;
  height: 40px;
  width: 175px;
  vertical-align: middle;
  outline: none;
  color: white;
  background-color: ${props => (props.color ? props.color : 'white')};
  margin-top: 15px;
  margin-right: 15px;
`;

export const StyledInput = styled.input`
  border-radius: 5px;
  line-height: 2.3;
  width: 40%;
  height: 40px;
  background: ${props =>
    props.disabled
      ? 'rgba(202,202,203,0.1)'
      : props.theme.colors.backgroundPrimary};
  ${props => (props.disabled ? 'cursor: not-allowed' : '')};
  border: ${props => props.theme.colors.border};
  padding-left: 15px;
`;

export const FullWidthStyledInput = styled(StyledInput)`
  width: 100%;
  height: 42px;
`;

export const StyledFormGroup = styled(FormGroup)`
  width: ${props => (props.full ? '100%' : 'initial')};
  margin-bottom: 0 !important;
`;
