import * as React from "react";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { micAndSpeakerOptions, micVolumeOptions, dectSpeakerOptions, voicePromptVolumeOptions } from "./options";
import { ToggleWrap } from "@Elements/Form/ToggleWrap";
import { ComponentWrapper, StyledForm, ButtonContainer, Footer } from "../styled";
import { useIntl } from "react-intl";
import { DeviceComponents } from "@Safemate/Settings/tabDefinition";
import { SettingEnum as ComponentSettingEnum } from "../../../settingEnum";
import { DeviceHardwareEnum, isGx8 } from "@Safemate/DeviceHardwareEnum";
import { Formik } from "formik";
import SettingsHeader from "../../header";
import { CancelButton, SaveButton } from "../../../../DefaultSettings/Settings/styled";
import { saveSoundLight } from "../../../Store/routines";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { SoundLightSettings } from "@Safemate/Settings/types";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import { SettingEnum } from "@Safemate/DefaultSettings/Settings/settingEnum";
import { AppState } from "@Safemate/Store/types";

interface VoicePromptSettingsProps{
  dehaId: number;
  deviId: number;
  soundLight: SoundLightSettings;
  saveSoundLight: Routine<ActionFunctionAny<Action<any>>>;
}

const VoicePromptSettings = ({ dehaId, deviId, soundLight, saveSoundLight }: VoicePromptSettingsProps) => {

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const { formatMessage } = useIntl();

  return(
    <Formik
      onSubmit={(values) => {
        saveSoundLight({
          deviceId: deviId,
          settings: values
        })
      }}
      initialValues={soundLight}
      enableReinitialize
    >
    {({ dirty, resetForm}) => 
      <StyledForm>
        <SettingsHeader 
          title={formatMessage({id: "voicePrompt", defaultMessage: "Talemeldinger"})}
          tooltip="voicePromptTabToolTipText"
          tooltipTitle={{
            id: "voicePrompt",
            defaultMessage: "Talemeldinger"
          }}
        />
        <div>
          {components.has(ComponentSettingEnum.GX8_VOICE_PROMPT) 
            && components.get(ComponentSettingEnum.GX8_VOICE_PROMPT)?.map(comp => {
              return <ComponentWrapper>{comp}</ComponentWrapper>
          })}
        </div>
        <Footer>
          <ButtonContainer disabled={!dirty}>
            <CancelButton disabled={!dirty} onClick={() => resetForm(soundLight)}>
              {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
            </CancelButton>
            <SaveButton disabled={!dirty} type="submit">
              {formatMessage({id: "save", defaultMessage: "Lagre"})}
            </SaveButton>
          </ButtonContainer>
        </Footer>
      </StyledForm>}
    </Formik>
  )
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: { soundLight }}}: AppState) => {
  return {
    dehaId,
    deviId,
    soundLight
  }
}

const mapDispatchToProps = {
  saveSoundLight
}

export default connect(mapStateToProps, mapDispatchToProps)(VoicePromptSettings);


export const VoicePromptAlarm = 
  CheckWrap<SoundLightSettings>(
    SettingEnum.CLIMAX_VOICE_PROMPT_ALARM,
    {
      label: {
        id: "alarm",
        defaultMessage: "Alarm"
      },
      contents: true,
      tooltip: "voicePromptAlarmTooltip",
      tooltipTitle: {id: "alarm", defaultMessage: "Alarm"}
    }
  )

export const VoicePromptCallback = 
  CheckWrap<SoundLightSettings>(
    SettingEnum.CLIMAX_VOICE_PROMPT_CALLBACK,
    {
      label: {
        id: "callback",
        defaultMessage: "callback"
      },
      contents: true,
      tooltip: "voicePromptCallbackTooltip",
      tooltipTitle: {id: "callback", defaultMessage: "callback"}
    }
  )

export const VoicePromptFall = 
  CheckWrap<SoundLightSettings>(
    SettingEnum.CLIMAX_VOICE_PROMPT_FALL,
    {
      label: {
        id: "fallAlarm",
        defaultMessage: "Fallalarm"
      },
      contents: true,
      tooltip: "voicePromptFallTooltip",
      tooltipTitle: {id: "fallAlarm", defaultMessage: "Fallalarm"}
    }
  )

export const VoicePromptWanderer = 
  CheckWrap<SoundLightSettings>(
    SettingEnum.CLIMAX_VOICE_PROMPT_WANDERER,
    {
      label: {
        id: "wanderer",
        defaultMessage: "Vandreralarm"
      },
      contents: true,
      tooltip: "voicePromptWandererTooltip",
      tooltipTitle: {id: "wanderer", defaultMessage: "Vandreralarm"}
    }
  )

export const VoicePromptBed = 
  CheckWrap<SoundLightSettings>(
    SettingEnum.CLIMAX_VOICE_PROMPT_BED,
    {
      label: {
        id: "voicePromptBed",
        defaultMessage: "Ut av seng/Ikke tilbake i seng"
      },
      contents: true,
      tooltip: "voicePromptBedTooltip",
      tooltipTitle: {id: "voicePromptBed", defaultMessage: "Ut av seng/Ikke tilbake i seng"}
    }
  )

export const VoicePromptACFailure = 
  CheckWrap<SoundLightSettings>(
    SettingEnum.CLIMAX_VOICE_PROMPT_AC_FAILURE,
    {
      label: {
        id: "voicePromptACFailure",
        defaultMessage: "AC failure"
      },
      contents: true,
      tooltip: "voicePrompACFailureTooltip",
      tooltipTitle: {id: "voicePromptACFailure", defaultMessage: "AC failure"}
    }
  )

export const VoicePromptACRestore = 
  CheckWrap<SoundLightSettings>(
    SettingEnum.CLIMAX_VOICE_PROMPT_AC_RESTORE,
    {
      label: {
        id: "voicePromptACRestore",
        defaultMessage: "AC restore"
      },
      contents: true,
      tooltip: "voicePromptACRestoreTooltip",
      tooltipTitle: {id: "voicePromptACRestore", defaultMessage: "AC restore"}
    }
  )
/*
 <h4>{formatMessage({id: "voicePrompt", defaultMessage: "Talemeldinger"})}</h4>
      </Row>
      <Row>
        <Col md={6}>
          <ComponentWrapper>
            <VoicePromptAlarm/>
          </ComponentWrapper>
          <ComponentWrapper>
            <VoicePromptCallback/>
          </ComponentWrapper>
          <ComponentWrapper>
            <VoicePromptFall/>
          </ComponentWrapper>
        </Col>
        <Col md={6}>
          <ComponentWrapper>
            <VoicePromptWanderer/>
          </ComponentWrapper>
          <ComponentWrapper>
            <VoicePromptBed/>
          </ComponentWrapper>
        </Col>
      </Row>
      */