import * as React from "react";
import { Col } from "react-bootstrap";
import { WrappedComponentProps, injectIntl } from "react-intl";
import styled from "styled-components";

import { DeviceComponents } from "../../tabDefinition";
import { SettingEnum } from "../../settingEnum";
import { connect } from "react-redux";
import { DeviceHardwareEnum } from "../../../DeviceHardwareEnum";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";

const Wrapper = styled.div<{active?: boolean}>`
  margin: 3px;
  padding: 15px;
  min-height: 230px;
  border: 1px solid ${props => props.active ? "green" : "gray"};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const NoPadCol = styled(Col)`
  padding: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h3 {
    margin-bottom: 0;
  }
`;

interface AutoTrackingProps extends WrappedComponentProps{
  dehaId: number;
  autoTracking: boolean;
}

const AutoTracking = injectIntl(({ intl: {formatMessage}, dehaId, autoTracking }: AutoTrackingProps) => {

  const components: Map<SettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) ? DeviceComponents.get(dehaId)! : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;
  return(
    <NoPadCol md={6} sm={6}>
      <Wrapper active={autoTracking}>
        <Header>
          <h3>{formatMessage({id: "automaticTracking", defaultMessage: "Automatisk lokalisering"})}</h3>
          <Tooltip
            popoverId="auto-tracking-popover"
            labelId="autoTrackingDesc"
            defaultMessage="Enheten vil forsøke å lokalisere iht. angitt lokaliseringsintervall mens Safematen er i bevegelse. Når Safematen er i ro, vil den spare batterikapasitet ved å skru av lokaliseringen."
          >
            <InformationIcon size="medium" />
          </Tooltip>
        </Header>
        {components.has(SettingEnum.TRACKING_AUTO) && components.get(SettingEnum.TRACKING_AUTO)?.map(comp => comp)}
      </Wrapper>
    </NoPadCol>
  )
})

const mapStateToProps = ({ settings: {device: {device: {dehaId}}, tracking: {autoTracking}}}: AppState) => {
  return {
      dehaId,
      autoTracking
  }
}

export default connect(mapStateToProps)(AutoTracking);