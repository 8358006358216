import * as React from "react";
import { injectIntl, useIntl } from "react-intl";
import { Field } from "formik";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {RootState, Organization, PostCity, Firm} from "../Store/types";
import {Action, ActionFunctionAny} from "redux-actions";
import { getPostCity} from "../Store/routines";
import withFormWrapper from "@Elements/Form/formWrapper";
import { AppState } from "@Safemate/Store/types";
import { MarketEnum } from "@Safemate/MarketEnum";

const Label = styled.label`
  text-transform: capitalize;
  display: block;
  @media only screen and (min-width: 992px) {
    padding-left: 20px;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledMainRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center;
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }

`;


export const InvoiceAddress = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "navn", defaultMessage: "Navn"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="addrName"
          
        />
      </Col>
    </StyledMainRow>
  )
})

export const Reference = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "addrReference", defaultMessage: "Referanse"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="addrReference"
          
        />
      </Col>
    </StyledMainRow>
  )
})


export const AddressOne = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
          <Col md={4}>
            <Label>{formatMessage({id: "addressLine1", defaultMessage: "Adresselinje 1"})}</Label>
            </Col>
            <Col md={8}>
            <StyledField
                type="text"
                name="addressLine1"
                
              />
          </Col>
    </StyledMainRow>
  )
})

export const AddressTwo = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
          <Col md={4}>
          <Label>{formatMessage({id: "addressLine2", defaultMessage: "Adresselinje 2"})}</Label>
          </Col>
          <Col md={8}>
            <StyledField
                type="text"
                name="addressLine2"
                
              />
          </Col>
    </StyledMainRow>
  )
})

export const Post = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "addrCity", defaultMessage: "Poststed"})}</Label>
        </Col>
        <Col md={8}>

        <StyledField
          type="text"
          name="addrCity"
          
        />
        </Col>
    </StyledMainRow>
  )
})

interface ZipProps{
  getPostCity: Routine<ActionFunctionAny<Action<any>>>;
  postCity: PostCity;
  market: MarketEnum;
}
const mapStateToPropsZip = ( { appSettings: {market}, partnerOrganization: {customer: {postCity}} } : AppState) => {
  return{
    postCity,
    market
  }
};
const mapDispatchFromStateZip = {
  getPostCity
}

export const Zip = connect(mapStateToPropsZip, mapDispatchFromStateZip)(
  withFormWrapper<Firm, ZipProps>(
    ({formik: {  setFieldValue }, postCity, market, getPostCity }) => {
      const [ postalCity, setPostalCity ] = React.useState("");


      const { formatMessage } = useIntl();

      const enableAutoFill = market !== MarketEnum.SAFECALL;
      
      React.useEffect(() => {
        if(postalCity != postCity.result){
          setPostalCity(postCity.result);
          setFieldValue("addrCity", postCity.result);
        }
      })

      const getPostalCity = (e: React.FormEvent<HTMLInputElement>) => {
        if(e.currentTarget.value.length == 4 && enableAutoFill){
          getPostCity(e.currentTarget.value);
        }
      }

    return(
      <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "addrZipCode", defaultMessage: "Postnummer"})}</Label>
      </Col>      
      <Col md={8}>
        <StyledField
            type="text"
            name="addrZipCode"
            onKeyUp={getPostalCity}
          />
      </Col>
    </StyledMainRow>
    )
  })
)
