import * as React from "react";
import { Tab } from "../../../Elements/Tabs";
import MenuItem from "../menu";
import { NonStatic, NonStaticRender } from "../utils";
import Access from "./access";
import CustomTab from "./custom";
import DeviceSettings from "./deviceSettings";
import EventSetup from "./eventSetup";
import GeneralSettings from "./generalSettings";
import RoleTitle from "./title";
import UserAdmin from "./userAdmin";

type MapTabs = (nonStatic: NonStatic, isSso: boolean) => JSX.Element[];

export const tabs = [
  {name: "roletitle", hideSso: false, toggle: false, Comp: RoleTitle},
  {name: "userAdmin", hideSso: false, toggle: true, Comp: UserAdmin},
  {name: "access", hideSso: true, toggle: true, Comp: Access},
  {name: "eventSetup", hideSso: false, toggle: true, Comp: EventSetup},
  {name: "deviceSettings", hideSso: false, toggle: true, Comp: DeviceSettings},
  {name: "generalSettings", hideSso: false, toggle: true, Comp: GeneralSettings},
]

const mapTabs: MapTabs = (nonStatic, isSso) => {

  return tabs.map(tab => {
    if(tab.hideSso && isSso) return null;
    return(
      <Tab
        key={tab.name}
        name={tab.name}
        renderTitle={() => <MenuItem toggle={tab.toggle} menuKey={tab.name}/>}
      >
        {React.createElement(tab.Comp)}
      </Tab>
    )
  }).concat(Object.values(nonStatic).map((item: NonStaticRender) => {
    return(
      <Tab
        key={item.key}
        name={item.key}
        renderTitle={() => <MenuItem toggle={true} menuKey={item.key}/>}
      >
        <CustomTab
          nonStaticRender={item}
        />
      </Tab>
    )
  }).filter(item => item))
}

export default mapTabs;
