import {all, fork} from "redux-saga/effects";
import textLabelSaga from "./textLabel";
import filterSaga from "./filter";
import country from "@Safemate/TranslationModule/TranslateOverlay/Store/Saga/country";

function* TextModuleSaga(){
  yield all([
    fork(textLabelSaga),
    fork(filterSaga),
    fork(country)
  ])
}
export default TextModuleSaga;
