import {Reducer} from "redux";
import { feedbackText } from "../routines";

const feedbackTextState: string[] = [];

const feedback: Reducer<string[]> = (state: string[] = feedbackTextState, action) => {
  switch (action.type) {
    case feedbackText.SUCCESS:
      return [
        ...state,
        action.payload
      ];

    case feedbackText.FAILURE:
      return [
        ...state,
        action.payload
      ]

    default:
      return state;
  }
};

export default feedback;
