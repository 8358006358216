import {call, put, takeLatest, race, take} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { DeviceHardwareEnum } from '../../../DeviceHardwareEnum';

export interface Initialize{
  deviceId: string;
  dehaId: DeviceHardwareEnum;
}

function* initialize(action: AppAction<Initialize>){
  try {
    
    const device = yield call(Api.getDevice, action.payload);

    const capabilities = yield call(Api.getCapabilities, action.payload);

    const supportDeviceMac = yield call(Api.getSupportDeviceMac);

    yield put(Routines.initialize.success({device, capabilities, supportDeviceMac, ...action.payload}));
  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
}

function* getUser(action: AppAction<Initialize>){
  try {
    const user = yield call(Api.getUser);
    yield put(Routines.initializeUser.success(user));
  }
  catch(e){
    yield put(Routines.initializeUser.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.initializeUser.TRIGGER, getUser);
}

export default initializationSaga;
