import { addTexts } from "@Safemate/TranslationModule/TranslateOverlay/Store/routines";
import {Reducer} from "redux";
import { TranslationModuleActionTypes as Actions } from "../actionTypes";
import { TextState } from "../types";

const textState: TextState = {
  selected: {
    label: "",
    count: 0,
    source: "",
    texts: []
  },
  editModal: true
};

const text: Reducer<TextState> = (state: TextState = textState, action) => {
  switch (action.type) {
    case addTexts.SUCCESS:
      return{
        ...state,
        selected: {
          ...state.selected,
          texts: action.payload
        }
      }
    case Actions.SET_SELECTED:
      return{
        ...state,
        selected: action.payload,
        editModal: true
      }
    case Actions.RESET_SELECTED:
      return{
        ...state,
        selected: {
          label: "",
          count: 0,
          source: "",
          texts: []
        }
      }
    case Actions.SET_MODAL_STATE:
      return{
        ...state,
        editModal: action.payload
      }

    default:
      return state;
  }
};

export default text;
