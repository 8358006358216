import {TextState} from "../types";
import {Reducer} from "redux";
import {getTexts, deleteText, addTexts, getTextForLabel } from "../routines";
import { TranslationModuleActionTypes as Actions } from "../actionTypes";
import { TranslationModuleActionTypes as PageActions } from "@Safemate/TranslationModule/TranslatePage/Store/actionTypes";

const textState: TextState = {
  texts: {},
  selected: {
    label: "",
    texts: []
  },
  emails: [],
  sms: [],
  editModal: false
};

const text: Reducer<TextState> = (state: TextState = textState, action) => {
  switch (action.type) {
    case getTexts.SUCCESS:
      return {
        ...state,
        texts: action.payload,
      };

    case deleteText.SUCCESS:
      return{
        ...state,
        texts: action.payload
      }
    
    case addTexts.SUCCESS:
      return{
        ...state,
        selected: {
          ...state.selected,
          texts: action.payload
        }
      }

    case getTextForLabel.SUCCESS:
      return{
        ...state,
        selected: action.payload
      }

    case Actions.RESET_SELECTED:
      return{
        ...state,
        selected: {
          label: "",
          texts: []
        }
      }

    case Actions.ADD_EMAIL:
      return{
        ...state,
        emails: [...state.emails, action.payload]
      }

    case Actions.ADD_SMS:
      return{
        ...state,
        sms: [...state.sms, action.payload]
      }

    case Actions.SET_MODAL_STATE:
      return{
        ...state,
        editModal: action.payload
      }

    case PageActions.SET_SELECTED:
      return{
        ...state,
        selected: action.payload,
        editModal: true
      }

    default:
      return state;
  }
};

export default text;
