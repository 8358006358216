import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { User } from '../types';
import { feedbackFailure, feedbackFailureString, feedbackSuccess } from '../../../Feedback/reducer';
import { UserForm } from '../../types';
import { getTexts } from '@Safemate/Store/routines';

function* saveUser(action: AppAction<UserForm>){

  const { password, confirm } = action.payload;

  if(password !== confirm){
    yield feedbackFailureString("passwordDoesntMatch");
    return;
  }

  try {
    const user: User = yield call(Api.saveUser, action.payload);
    yield feedbackSuccess("userMyPageUpdated");

    yield put(getTexts.trigger());

    yield put(Routines.saveUser.success(user));
  }
  catch(e){
    console.log(e)
    yield feedbackFailure(e);
    yield put(Routines.saveUser.failure());
  }
}

function* setStyle(action: AppAction<boolean>){
  try{
    yield call(Api.setStyle, action.payload);
    yield put(Routines.setStyle.success(action.payload));
  }
  catch(e){
    yield feedbackFailure(e);
  }
}

function* deleteUser(action: AppAction<null>){
  try {
    yield call(Api.deleteUser);
    window.location.reload(); 
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getAllowDelete.failure());
  }
}


function* sendGdprEmail(action: AppAction<null>){
  try {
    yield call(Api.sendGdprEmail);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getAllowDelete.failure());
  }
}

function* userSaga(){
    yield takeLatest(Routines.saveUser.TRIGGER, saveUser);
    yield takeLatest(Routines.sendGdprEmail.TRIGGER, sendGdprEmail);
    yield takeLatest(Routines.deleteUser.TRIGGER, deleteUser);
    yield takeLatest(Routines.setStyle.TRIGGER, setStyle);
}

export default userSaga;
