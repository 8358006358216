import { FilterState, TextLabelWithText} from "../types";
import {Reducer} from "redux";
import { getTextLabels } from "../routines";
import { TranslationModuleActionTypes as Actions } from "../actionTypes";
import { SortEnum } from "../SortEnum";
import { LanguageOptionEnum } from "../LanguageOptionEnum";
import { getCountries } from "@Safemate/TranslationModule/TranslateOverlay/Store/routines";
import { DatabaseCountry } from "@Safemate/TranslationModule/TranslateOverlay/Store/types";

const filterState: FilterState = {
  search: "",
  sort: SortEnum.DEFAULT,
  selectedLanguage: [],
  languageOption: LanguageOptionEnum.ALL
}

const filter: Reducer<FilterState> = (state: FilterState = filterState, action) => {
  switch (action.type) {

    case getCountries.SUCCESS:{
      return {
        ...state,
        selectedLanguage: action.payload.map((country: DatabaseCountry) => {
          return country.languageCode;
        })
      }
    }

    case Actions.SORT_TABLE:
      return {
        ...state,
        sort: action.payload
      }

    case Actions.SET_SEARCH:
      return {
        ...state,
        search: action.payload
      }

    case Actions.SET_LANGUAGE_FILTER:
      return {
        ...state,
        selectedLanguage: action.payload
      }

    case Actions.SET_LANGUAGE_OPTION:
      return {
        ...state,
        languageOption: action.payload
      }

    default:
      return state;
  }
};

export default filter;
