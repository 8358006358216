import {PartnerOrganizationActionTypes as Actions} from "./actionTypes";
import { AppAction } from "../../../Safemate/Store/types";
import { Layout } from "../LayoutEnum";

// Init

export type SetLayout = (layout: Layout) => AppAction<Layout>

export const setLayout: SetLayout = (layout) => ({
  type: Actions.SET_LAYOUT,
  payload: layout
})
