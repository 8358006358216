import * as React from "react";
import { connect } from "react-redux";
import { DeviceComponents } from "../tabDefinition";
import { DeviceHardwareEnum } from "../../DeviceHardwareEnum";
import { SettingEnum } from "../settingEnum";
import styled from "styled-components";
import { AppState } from "@Safemate/Store/types";

const Wrap = styled.div`
  margin-bottom: 5px;
`;

interface TrackingOptionsProps{
  dehaId: number;
}

const mapStateToProps = ({ settings: {device: { device : { dehaId }}}}: AppState) => {
  return{
    dehaId
  }
}

const TrackingOptions = connect(mapStateToProps)(({ dehaId }: TrackingOptionsProps) => {
  
  const components: Map<SettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) ? DeviceComponents.get(dehaId)! : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;
  
  return(
    <React.Fragment>
      {components.has(SettingEnum.TRACKING_OPTIONS) && components.get(SettingEnum.TRACKING_OPTIONS)?.map(comp => <Wrap>{comp}</Wrap>)}
    </React.Fragment>
  )
})

export default TrackingOptions;