import _axios, {AxiosInstance} from 'axios';
import { Initialize } from './Saga/initialization';
import {OrganizationForm} from './types';
import { addRolesToRequest } from '@Safemate/utils';


// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};


export interface SaveSettings{
  settings: OrganizationForm;
  PPTID: string;
}

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getDevice = async ({deviceId}: Initialize) => {
  const url: string = `/deviceSettings/${deviceId}${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;  
};

export const getUser= async () => {
  const url: string = `/user/me?_=${new Date().getMilliseconds()}`;
  const response = await axios.get(url);
  return response.data;
}


// Organisation
export const getOrganisation = async () => {
  const url: string = `/firm/trimmedsuborgunitswithwithrights/`;
  const response = await axios.get(url);
  return response.data;
}

export const getSubUnits = async () => {
  const url = `/firm/suborgunitswithdevicecountandrights`;
  const response = await axios.get(url);
  return response.data;
}

export const saveForm = async ({PPTID, settings}: SaveSettings) => {
  const url: string = `/firm/save/${PPTID}`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const getSubOrgs = async () => {
  const url: string = `/firm/trimmedsuborgunitswithwithrights/`;
  const response = await axios.get(url);
  return response.data;
}

export const getPostCity = async (pnr: string) => {
  const url = `https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=www.safemate.no&pnr=${pnr}`;
  const response = await axios.get(url);
  return response.data;
}

export const getPaymentOptions = async (custId: number, parent: number) => {
  const url: string = `/paymentProfile/choices/${custId}/${parent}`;
  const response = await axios.get(url);
  return response.data;
}