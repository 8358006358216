import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Texts
export const getTexts: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TextModule/GET_TEXTS'
);
export const getTextForLabel: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TextModule/GET_TEXT_FOR_LABEL'
);
export const deleteText: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TextModule/DELETE_TEXT'
);
export const addTexts: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TextModule/ADD_TEXTS'
);

// Country
export const getCountries: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TextModule/GET_COUNTRIES'
);

// Feedback
export const feedbackText: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TextModule/FEEDBACK_TEXT'
);