import styled from 'styled-components';

const TabContainer = styled.div`
  background: slate;
  display: flex;
  position: relative;
  top: 1px;
`;

export default TabContainer;
