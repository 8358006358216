import {Role, RoleState} from "../types";
import {Reducer} from "redux";
import {getPolicies, getRole, saveRole} from "../routines";

const defaultRole = {
  custId: 0,
  global: false,
  id: 0,
  policies: {},
  roleName: "",
  technicianRights: false
}

const roleState: RoleState = {
  role: {...defaultRole},
  saving: false
};

const role: Reducer<RoleState> = (state: RoleState = roleState, action) => {
  switch (action.type) {
    case getRole.SUCCESS:
      return {
        ...state,
        role: extractRole(action.payload)
      };

    case getPolicies.SUCCESS:
      return{
        ...state,
        role: action.payload
      }

    case saveRole.TRIGGER:
      return {
        ...state,
        saving: true
      }

    case saveRole.SUCCESS:
      return{
        ...state,
        saving: false,
        role: action.payload
      }
    case saveRole.FAILURE:
      return {
        ...state,
        saving: false
      }
    

    default:
      return state;
  }
};

const extractRole = (roles: Role[]) => {
  if(roles.length > 0){

    const role = roles[0];
    if(role.global){
      role.id = 0;
      role.roleName = "";
    }

    return roles[0];
  }
  return {...defaultRole};
}

export default role;
