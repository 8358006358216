import {GeofenceState} from "../types";
import {Reducer} from "redux";
import { fetchGeofence, fetchGlobalGeofence } from "../routines";
import { Geofence } from "../../../../Safemate/Settings/Geofence/types";
import { defaultFence } from "../../../../Safemate/Settings/Geofence/utils";
import { GeofenceTypeEnum } from "../../../../Safemate/Settings/Geofence/GeofenceTypeEnum";
import { v4 } from "uuid"
import { ControlState } from "../../../../Safemate/Settings/Geofence/Controls";

const geofenceState: GeofenceState = {
    editing: false,
    geofence: [],
    globalGeofence: [],
    currentGeofence: defaultFence(),
    geofenceType: GeofenceTypeEnum.NONE,
    initialFences: [],
    refreshToken: v4(),
    controlState: ControlState.DEFAULT,
    initialSelection: false,
    processDelete: false,
    processSave: false,
    loading: true,
    latestPosition: []
};

const geofence: Reducer<GeofenceState> = (state: GeofenceState = geofenceState, action: any) => {
  switch (action.type) {

    case fetchGeofence.TRIGGER:
      return {
        ...state,
        loading: true
      }

    case fetchGeofence.SUCCESS:
      return getFetchedState(state, action.payload);

    case fetchGlobalGeofence.SUCCESS:
      return{
        ...state,
        globalGeofence: action.payload
      }

    default:
        return state;
  }
};

export default geofence;


const getFetchedState = (currentState: GeofenceState, geofence: Geofence[]) => {
  const newState = {
    ...currentState,
    refreshToken: v4(),
    geofence: geofence,
    loading: false
  }
  newState.initialFences = JSON.parse(JSON.stringify(geofence));
  return newState;
}