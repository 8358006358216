import {CustomerArrState} from "../types";
import {Reducer} from "redux";
import {initializeSubUnits} from "../routines";

const customerState: CustomerArrState = {
  customers: {
    customerId: 0,
    firm: {
      activatedDate: 0,
      active: false,
      addrCity: "",
      addrCounId: 0,
      addressLine1: "",
      addressLine2: "",
      addrName: "",
      addrNameForBilling: "",
      addrReference: "",
      addrSamesame: false,
      addrZipCode: "",
      allowAllPhoneNo: false,
      billingAddrCity: "",
      billingAddrCounId: 0,
      billingAddrLine1: "",
      billingAddrLine2: "",
      billingAddrName: "",
      billingAddrReference: "",
      billingAddrZipCode: "",
      coaddress: "",
      company: false,
      country: "",
      custId: 0,
      created: 0,
      createdBy: 0,
      defaultAhp: 0,
      deleted: false,
      email: "",
      firstName: "",
      firmName: "",
      forceCoverage: false,
      forceIncomingRing: false,
      forceIncomingRingAlways: false,
      forceTfa: false,
      informalContactName: "",
      integrationId: "",
      integrationRole: "",
      invoiceBalanceExclVat: 0,
      invoiceCreditLimitExclVat: 0,
      languageCode: "",
      lastChanged: 0,
      lastChangedBy: 0,
      lastName: "",
      latestInvoiceDate: 0,
      mobile: "",
      name: "",
      numActiveDevices: 0,
      orgNum: "",
      parent: 0,
      prgrId: 0,
      priceGroup: [],
      pptyId: "",
      privateUser: false,
      status: 0,
      streetAddress: "",
      billAddrDefined: false,
    },
    policies: [],
    paymentProfile: [],
    numberOfDevices: 0
  }
};

const subUnits: Reducer<CustomerArrState> = (state: CustomerArrState = customerState, action) => {
  switch (action.type) {

    case initializeSubUnits.SUCCESS:
      return {
        ...state,
        customers: action.payload
      }
      

    default:
        return state;
  }
};

export default subUnits;
