import * as React from "react";
import { useIntl } from "react-intl";
import SafemateHeader from "@Safemate/Header";
import { AppState } from "@Safemate/Store/types";
import { ICustomer } from "@Safemate/Model/Customer";
import { connect } from "react-redux";

interface headerProps{
  superfirm: ICustomer;
}

const mapPropsToState = ({ appData: { superfirm } }: AppState) => {
  return{
    superfirm
  }
}

const Header = connect(mapPropsToState)(({superfirm}: headerProps) => {
  const companyName = superfirm.name;

  const { formatMessage } = useIntl();

  return (
    <div>
      <SafemateHeader
        icon={null}
        title={`${formatMessage({id: "Fakturaoversikt", defaultMessage: "Fakturaoversikt"})} ${companyName}`}
      >
      </SafemateHeader>
    </div>
  )
});

export default Header;
