import * as React from "react";
import {Grid, Col, Row} from "react-bootstrap";
import { useIntl } from "react-intl";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";

import SafemateAlarm from "../../Elements/Icon/Icons/DeviceIcons/SafemateAlarm";
import Card, {AlarmHandlingCard} from "./card";
import Donut from "./donut";
import { initialize, fetchAlarms, fetchAhpReport, fetchReport, fetchAlarmHandleReport } from "./Store/routines";
import { Status, DeviceHardware, AhpReport } from "./Store/types";
import { getStatusLink } from "./utils";
import { StatusEnum } from "./StatusEnum";
import { AppState } from "@Safemate/Store/types";
import { FilterAlarm, filterAlarm } from "@Safemate/DeviceList/Store/actions";
import { AlarmFilterEnum } from "@Safemate/DeviceList/AlarmFilterEnum";
import {ICustomerWithPolicy} from "@Safemate/Model/Customer";
import {isAdmin} from "@Safemate/utils";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";
import AlarmOperator from "@Elements/Icon/Icons/menu/AlarmOperator";
import Person from "@Elements/Icon/Icons/menu/Person";
import SubUnits from "@Elements/Icon/Icons/menu/SubUnits";


interface DashboardProps{
  init: Routine<ActionFunctionAny<Action<any>>>;
  fetchAlarms: Routine<ActionFunctionAny<Action<any>>>;
  fetchAhpReport: Routine<ActionFunctionAny<Action<any>>>;
  fetchReport: Routine<ActionFunctionAny<Action<any>>>;
  fetchAlarmHandleReport: Routine<ActionFunctionAny<Action<any>>>;
  initialized: boolean;
  ahpReport: AhpReport;
  alarms: number;
  status: Status;
  deviceHardware: DeviceHardware;
  recipientPercentage: number;
  alarmHandledPercentage: number;
  recipientNOKPercentage: number;
  alarmHandledNOKPercentage: number;
  filterAlarm: FilterAlarm;
  customersWithPolicy: ICustomerWithPolicy[];
  percentageReportInitialized: boolean;
  currentContext: SiteTypeEnum;
}

type ChartValues = {
  values: number[],
  labels: string[],
  filter?: AlarmFilterEnum[];
  type?: number[];
};

const mapStateToProps = ({ dashboard: {initialization: {initialized}, report: {ahpReport, alarms, status, deviceHardware, recipientPercentage, alarmHandledPercentage, recipientNOKPercentage, alarmHandledNOKPercentage, percentageReportInitialized}}, appData: {customersWithPolicy, currentContext}}: AppState) => {
  return {
    initialized,
    ahpReport,
    alarms,
    status,
    deviceHardware,
    customersWithPolicy,
    recipientPercentage,
    recipientNOKPercentage,
    alarmHandledPercentage,
    alarmHandledNOKPercentage,
    percentageReportInitialized,
    currentContext
  }
}

const mapDispatchToProps  = {
  init: initialize,
  fetchAlarms: fetchAlarms,
  fetchReport: fetchReport,
  fetchAhpReport,
  fetchAlarmHandleReport: fetchAlarmHandleReport,
  filterAlarm
};

export default connect(mapStateToProps, mapDispatchToProps)(({init, fetchAlarms, fetchReport, fetchAhpReport, ahpReport, alarms, status, deviceHardware, filterAlarm, customersWithPolicy, alarmHandledPercentage, recipientPercentage, fetchAlarmHandleReport, percentageReportInitialized, currentContext, recipientNOKPercentage, alarmHandledNOKPercentage}: DashboardProps) => {

  let alarmInterval: NodeJS.Timeout;
  let reportInterval:  NodeJS.Timeout;
  let ahpReportInterval: NodeJS.Timeout;
  let alarmHandleReportInterval: NodeJS.Timeout;

  const { formatMessage } = useIntl();
  const adminUser = isAdmin(customersWithPolicy);

  React.useEffect(() => {
    setTimeout(setup, 500);
    return () => {
      teardown();
    }
  }, [])

  React.useEffect(() => {
    setTimeout(setup, 500);
    if(currentContext === SiteTypeEnum.PROFFWEB){
      clearInterval(ahpReportInterval);
    }
    else if(currentContext === SiteTypeEnum.AHP){
      clearInterval(alarmInterval);
      clearInterval(reportInterval);
      if(adminUser){
        clearInterval(alarmHandleReportInterval);
      }
    }
    return () => {
      teardown();
    }
  }, [currentContext])

  const setup = () => {
    if(currentContext === SiteTypeEnum.AHP){
      fetchAhpReport();
      ahpReportInterval = setInterval(fetchAhpReport, 5*60000);
    }
    else{
      init();
      alarmInterval = setInterval(fetchAlarms, 15000);
      reportInterval = setInterval(fetchReport, 5*60000);


      if( adminUser ){
        fetchAlarmHandleReport();
        alarmHandleReportInterval = setInterval(fetchAlarmHandleReport, 5*60000);
      }
    }
  }

  const teardown = () => {
    if(currentContext === SiteTypeEnum.AHP){
      clearInterval(ahpReportInterval);
    }
    else{
      clearInterval(alarmInterval);
      clearInterval(reportInterval);
      if(adminUser){
        clearInterval(alarmHandleReportInterval);
      }
    }
  }

  const mappedDeviceHardware = React.useMemo(() => {
    const s: ChartValues = {
      values: [],
      labels: [],
      type: [],
      filter: []

    }
    for(const [key, value] of Object.entries(deviceHardware)){
      s.values.push(value);
      s.labels.push(`${value} ${formatMessage({id: `deviceHardware${key}`, defaultMessage: key})}`);
      s.type.push(parseInt(key));
      s.filter.push(AlarmFilterEnum.CLEAR);
    }
    return s;
  }, [deviceHardware])

  const mappedStatus = React.useMemo(() => {
    const s: ChartValues = {
      values: [],
      labels: [],
      type: [],
      filter: []
    };
    for(const [key, value] of Object.entries(status)){
      s.values.push(value);
      s.labels.push(`${value} ${formatMessage({id: key, defaultMessage: key})}`);
      s.type.push(0);
      s.filter.push(getStatusLink(key as StatusEnum));
    }
    return s;
  }, [status])



  React.useEffect(() => {
  }, [alarmHandledPercentage, recipientPercentage]);

  const alarmHandleReportContent = adminUser && percentageReportInitialized ? <React.Fragment>
    <Row style={{padding: "0px 10px"}}>
      <Col xs={12}>
        { `${formatMessage({id: "recipientPercentageLabel1", defaultMessage: "Next-of-kins exist in"})} ${recipientPercentage}% ${formatMessage({id: "recipientPercentageLabel2", defaultMessage: " of the event lists"})}` }
      </Col>
      <Col xs={12}>
        { `${formatMessage({id: "alarmHandledPercentageLabel1", defaultMessage: "Last week"})} ${alarmHandledPercentage}% ${formatMessage({id: "alarmHandledPercentageLabel2", defaultMessage: "of alarm events have been handled by next-of-kins"})}` }
      </Col>
    </Row>
  </React.Fragment>: <React.Fragment></React.Fragment>;

  if(currentContext === SiteTypeEnum.AHP){
    return(
      <Grid fluid>
        <Row style={{marginBottom: "20px"}}>
          <Col md={4}>
            <Card
              title={formatMessage({id: "escalatedAlarms", defaultMessage: "Eskalerte alarmer"})}
              count={ahpReport.numEscalatedAlarms}
              icon={<SafemateAlarm/>}
              color="#F12638"
              onClick={() => {
                location.hash = "eventview";
              }}
            />
          </Col>
          <Col md={4}>
            <Card
              title={formatMessage({id: "nonEscalatedAlarms", defaultMessage: "Ikke-eskalerte alarmer"})}
              count={ahpReport.numNonEscalatedAlarms}
              icon={<SafemateAlarm/>}
              color="#f5a623"
              onClick={() => {
                filterAlarm([AlarmFilterEnum.ALARM])
                location.hash = 'safemates';
              }}
            />
          </Col>
          <Col md={4}>
            <Card
              title={formatMessage({id: "onlineOperators", defaultMessage: "Påloggede operatører"})}
              count={ahpReport.numOnlineOperators}
              icon={<AlarmOperator/>}
              color="#26f16a"
              onClick={() => {
                location.hash = 'users/list';
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card
              title={formatMessage({id: "privateCustomers", defaultMessage: "Privatkunder"})}
              count={ahpReport.numPrivateCustomers}
              icon={<Person/>}
              color="#26f16a"
            />
          </Col>
          <Col md={4}>
            <Card
              title={formatMessage({id: "municipalities", defaultMessage: "Kommune-kunder"})}
              count={ahpReport.numMunicipalities}
              icon={<SubUnits/>}
              color="#26f16a"
            />
          </Col>
          <Col md={4}>
            <Card
              title={formatMessage({id: "totalDevices", defaultMessage: "Enheter totalt"})}
              count={ahpReport.numTotalDevices}
              icon={<SafemateAlarm/>}
              color="#26f16a"
              onClick={() => {
                filterAlarm([AlarmFilterEnum.CLEAR])
                location.hash = 'safemates';
              }}
            />
          </Col>
        </Row>
      </Grid>
    )
  }

  const adminAlarmReportFlag = adminUser && percentageReportInitialized;

  return(
    <Grid fluid>
      <Row>
        <Col md={4}>
          <Card
            title={formatMessage({id: "dashAlarmHead", defaultMessage: "Pågående alarmer"})}
            count={alarms}
            icon={<SafemateAlarm/>}
            color="#F12638"
            onClick={() => {
              filterAlarm([AlarmFilterEnum.ALARM])
              location.hash = 'safemates';
            }}
          />
        </Col>
        <Col md={4}>
          <Donut
            title={formatMessage({id: "dashStatusHead", defaultMessage: "Statusoversikt"})}
            values={mappedStatus.values}
            labels={mappedStatus.labels}
            type={mappedStatus.type}
            filter={mappedStatus.filter}
            heightMobile={"150px"}
            customScale={1.2}
          />
        </Col>
        <Col md={4}>
          <Donut
            title={formatMessage({id: "dashDeviceTypeHead", defaultMessage: "Enhetoversikt"})}
            values={mappedDeviceHardware.values}
            labels={mappedDeviceHardware.labels}
            type={mappedDeviceHardware.type}
            filter={mappedDeviceHardware.filter}
            heightMobile={"150px"}
            customScale={1.2}
          />
        </Col>
        {

        }
      </Row>
      {
        adminAlarmReportFlag && <Row style={{paddingTop: "15px"}}>
          <Col md={4}>
            <AlarmHandlingCard
              title={formatMessage({id: "dashAlarmHandlingHead", defaultMessage: "Alarm handling"})}
              recipientPercentage={recipientNOKPercentage}
              recipientPercentageContent={formatMessage({id: "recipientNOKPercentageLabel", defaultMessage: "Next-of-kins exist in the event set up list"})}
              alarmHandledPercentage={alarmHandledNOKPercentage}
              alarmHandledPercentageContent={formatMessage({id: "alarmHandledNOKPercentageLabel", defaultMessage: "Alarm handled by next-of-kins"})}
            />
          </Col>
        </Row>
      }
    </Grid>
  )
})
