import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styled, { css, keyframes } from 'styled-components';

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    height: 40px;
    width: 40px;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const rotateRule = css`
  animation: ${rotate} 1.5s linear infinite;
`;

const Rotate = styled(FontAwesomeIcon)`
  ${rotateRule};
`;

const Loader = () => <Rotate icon={faSpinner} />;

export const WrappedLoader = () => 
  <LoaderWrap>
    <Loader/>
  </LoaderWrap>

export default Loader;
