import { FilterState } from './Store/types';
import { Option } from '../../Safemate/DefaultSettings/Settings/FieldWrapper';
import { AlarmFilterEnum } from './AlarmFilterEnum';
import { StatusType } from './DeviceList/deviceStatus';
import { OrderFilterEnum } from './OrderFilterEnum';
import { formatDate } from "../utils";
import { ICustomerWithPolicy } from '@Safemate/Model/Customer';
import { IDevice } from '@Safemate/Model/Device';



export type UpdateDeviceAndModifiedTime = (devices: IDevice[], lastModifiedTime: number) => {alarms: IDevice[], lastModifiedTime: number};

export const updateDeviceAndModifiedTime: UpdateDeviceAndModifiedTime = (devices, lastModifiedTime) => {

  const alarms: IDevice[] = [];

  for(const device of devices){

    if(lastModifiedTime < device.lastMsgIn) lastModifiedTime = device.lastMsgIn;
    if(lastModifiedTime < device.lastMsgOut) lastModifiedTime = device.lastMsgOut;

    device.tracking = isDeviceTracking(device.tracking, device.trackingDate, device.trackingInterval);

    if(device[StatusType.ALARM_WARNING])
      alarms.push(device);
  }
  return { alarms, lastModifiedTime };
}

export const isDeviceTracking = (tracking: boolean, trackingDate: number, trackingInterval: number) => {
  if (!tracking) {
    return false;
  }
  if ((new Date().getTime() - trackingDate) > (trackingInterval * 2 * 1000)) {
    return false;
  }
  return true;
}

export const mapDeviceTypes = (devices: IDevice[]) => {
  const deviceTypes: Option[] = [];
  const uniqueTypes: number[] = [];
  devices.forEach(device => {
    if(!uniqueTypes.includes(device.dehaId)){
      uniqueTypes.push(device.dehaId);
      deviceTypes.push({
        text: device.detyName,
        value: device.dehaId
      })
    }
  })
  return deviceTypes;
}

export const filterDevices = (devices: IDevice[], filters: FilterState, customers: ICustomerWithPolicy[], deviceTypes: Option[], twinSuffix: string) => {

  const devicesWithTwin = devices.map(device => {
    if(device.deviceTwin){
      let deviId = device.deviceTwin.deviId === device.deviId ? device.deviceTwin.masterDevice : device.deviceTwin.deviId;
      device.deviceTwin.device = devices.find(d => d.deviId === deviId);
      if(device.deviceTwin.masterDevice === device.deviId && device.deviceTwin.device){
        device.deviceTwin.device.deviceName = `${device.deviceName} ${twinSuffix}`;
      }
    }
    return device;
  })

  if(devicesWithTwin.length < 2){
    const stringFilteredDevices =  devicesWithTwin.filter(device => {
      if(filters.search != ""){
        return (filterString(device, filters.search) || filterWithSensor(device, filters.matchedGX8));
      }else{
        return devicesWithTwin;
      }
    });
    return stringFilteredDevices;
  }

  const filteredDevices =  devicesWithTwin.filter(device => {
    return (filterCustomers(device, filters.customers, customers)
          && filterDeviceTypes(device, filters.deviceTypes, deviceTypes)
          && (filterString(device, filters.search) || filterWithSensor(device, filters.matchedGX8))
          && filterAlarm(device, filters.alarmFilters) && (!device.deviceTwin || device.deviceTwin.deviId !== device.deviId))
  }).sort((a, b) => {
    const f = filterOrder(filters.orderFilter, a, b)
    return f;
  });

  return filteredDevices;
}

export const devicesSearchNoFilter = (filters: FilterState, deviceList: IDevice[]) => {
  const filtedSearchDevices =  deviceList.filter(device => {
    return (filterString(device, filters.search) || filterWithSensor(device, filters.matchedGX8));
  })
  return filtedSearchDevices;
}

export const filterPartnerDevices = (filters: FilterState, partnerDeviceList: IDevice[]) => {
  const filteredPartnerDevices =  partnerDeviceList.filter(device => {
    return (filterStringExactSNNum(device, filters.search) 
  )})
  return filteredPartnerDevices;
}

const filterOrder = (filter: OrderFilterEnum, deviceOne: IDevice, deviceTwo: IDevice): number => {
  if(deviceOne.deviceTwin && deviceOne.deviceTwin.masterDevice !== deviceOne.deviId){
    return deviceOne.deviceTwin.masterDevice - deviceTwo.deviId;
  }
  switch (filter){
    case OrderFilterEnum.LAST_CONTACT_ASC:
      return deviceOne.lastMsgIn - deviceTwo.lastMsgIn;
    case OrderFilterEnum.LAST_CONTACT_DESC:
      return deviceTwo.lastMsgIn - deviceOne.lastMsgIn;
    case OrderFilterEnum.NAME:
      return sortByString(deviceOne.deviceName, deviceTwo.deviceName);
    case OrderFilterEnum.PHONE_NUMBER:
      return sortByString(deviceOne.phoneNumber, deviceTwo.phoneNumber);
    case OrderFilterEnum.NOTES_ADDED_DESC:
      if (deviceOne.hasNote && !deviceTwo.hasNote) {
        return -1;
      }
      if (!deviceOne.hasNote && deviceTwo.hasNote) {
        return 1;
      }
    default:
      if (deviceOne.statusRank > deviceTwo.statusRank) return 1;
      if (deviceOne.statusRank < deviceTwo.statusRank) return -1;
      if (deviceOne.tracking && !deviceTwo.tracking) return -1;
      if (deviceTwo.tracking && !deviceOne.tracking) return 1
      return deviceOne.deviceName < deviceTwo.deviceName ? -1 : 1;
  }
}

type SortByString = (a: string, b: string) => number;

const sortByString: SortByString = (a,b) => {
  const nameA = a.toUpperCase(); // ignore upper and lowercase
  const nameB = b.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

const filterDeviceTypes = (device: IDevice, deviceTypeFilter: number[], deviceTypes: Option[]) => {

  // If none of the device types in the filter match the device types the user current can filter between we don't want to apply a filter as the data is out of date.
  deviceTypeFilter = deviceTypeFilter.filter(deviceTypeId => !!deviceTypes.find(deviceType => deviceType.value === deviceTypeId));
  return (deviceTypeFilter.includes(device.dehaId) || deviceTypeFilter.length === 0);
}

const filterCustomers = (device: IDevice, customerFilter: number[], customers: ICustomerWithPolicy[]) => {

  // If none of the customers in the filter match the customers the user has access to we don't want to apply a filter as the data is out of date.
  customerFilter = customerFilter.filter(customerId => !!customers.find(customer => customer.customerId === customerId));
  return (customerFilter.includes(device.ownedByCustId) || customerFilter.length === 0)
}

const filterString = (device: IDevice, search: string) => {
  return (device.deviceSNNum.toUpperCase().includes(search.toUpperCase())
        || device.deviceName.toUpperCase().includes(search.toUpperCase())
        || device.phoneNumber.toUpperCase().includes(search.toUpperCase())
        || device.ownerName && device.ownerName.toUpperCase().includes(search.toUpperCase()))
        || (device.deviceTwin && (device.deviceTwin.device.deviceSNNum.toUpperCase().includes(search.toUpperCase())
        || device.deviceTwin.device.deviceName.toUpperCase().includes(search.toUpperCase())
        || device.deviceTwin.device.phoneNumber.toUpperCase().includes(search.toUpperCase())
        || device.deviceTwin.device.ownerName && device.deviceTwin.device.ownerName.toUpperCase().includes(search.toUpperCase())))
}

const filterStringExactSNNum = (device: IDevice, search: string) => {
  return (device.deviceSNNum.toUpperCase() == search.toUpperCase());
}

const filterWithSensor = (device: IDevice, foundDevices: string[]) => {
  return foundDevices.includes(device.deviceSNNum);
}

const filterAlarm = (device: IDevice, alarmFilters: AlarmFilterEnum[]) => {

  if(alarmFilters.length < 1) return true;

  if(getStatusWithTwin(device, StatusType.ALARM_WARNING) && alarmFilters.includes(AlarmFilterEnum.ALARM)) return true;
  
  if(getStatusWithTwin(device, StatusType.GEOFENCE_WARNING) && alarmFilters.includes(AlarmFilterEnum.GEOFENCE)) return true;

  if(getStatusWithTwin(device, StatusType.OUT_OF_BATTERY_WARNING) && alarmFilters.includes(AlarmFilterEnum.OUT_OF_BATTERY)) return true;

  if((getStatusWithTwin(device, StatusType.BATTERY_WARNING)
    || getStatusWithTwin(device, StatusType.POWER_FAILURE))
    && getStatusWithTwin(device, StatusType.LAST_MESSAGE_IN)
    && !getStatusWithTwin(device, StatusType.SELF_CHECK_WARNING)
    && alarmFilters.includes(AlarmFilterEnum.BATTERY)
  ) return true;
  
  if(getStatusWithTwin(device, StatusType.SELF_CHECK_WARNING) && !getStatusWithTwin(device, StatusType.OUT_OF_BATTERY_WARNING) && alarmFilters.includes(AlarmFilterEnum.SELFCHECK)) return true;

  if(getStatusWithTwin(device, StatusType.OUTDATED_POSITION_WARNING) && alarmFilters.includes(AlarmFilterEnum.OUTDATEDPOSITION)) return true;

  if(getStatusWithTwin(device, StatusType.INACTIVE_WARNING) && alarmFilters.includes(AlarmFilterEnum.STATIC)) return true;

  if(getStatusWithTwin(device, StatusType.OFFLINE_WARNING) && alarmFilters.includes(AlarmFilterEnum.OFFLINE)) return true;

  if(getStatusWithTwin(device, StatusType.UNKNOWN_WARNING) && alarmFilters.includes(AlarmFilterEnum.UNKNOWN)) return true;

  if(getStatusWithTwin(device, StatusType.DEFAULT) && alarmFilters.includes(AlarmFilterEnum.OK)) return true;

  const status = getStatus(device);
  if(status == "ok" && alarmFilters.includes(AlarmFilterEnum.OK)) return true;
  
  return false;
}

const getStatusWithTwin = (device: IDevice, type: StatusType) => {
  return device[type] || (device.deviceTwin && device.deviceTwin.device[type]);
}

export const mapHeader = (formatMessage: any) => {
  return[
    {value: formatMessage({id: "status", defaultMessage: "status"}).toUpperCase(), display: true},
    {value: formatMessage({id: "type", defaultMessage: "type"}).toUpperCase(), display: true},
    {value: formatMessage({id: "SSNM", defaultMessage: "SSNM"}).toUpperCase(), display: true},
    {value: formatMessage({id: "name", defaultMessage: "name"}).toUpperCase(), display: true},
    {value: formatMessage({id: "battery", defaultMessage: "battery"}).toUpperCase(), display: true},
    {value: formatMessage({id: "trackingStatus", defaultMessage: "tracking"}).toUpperCase(), display: true},
    {value: formatMessage({id: "lastContact", defaultMessage: "last contact"}).toUpperCase(), display: true},
    {value: formatMessage({id: "number", defaultMessage: "number"}).toUpperCase(), display: true},
    {value: formatMessage({id: "note", defaultMessage: "note"}).toUpperCase(), display: true},
    {value: formatMessage({id: "suborg", defaultMessage: "suborg"}).toUpperCase(),  display: true},
    {value: formatMessage({id: "actiDate", defaultMessage: "acti. date"}).toUpperCase(),  display: true},
    {value: formatMessage({id: "lstSlfChck", defaultMessage: "last self check"}).toUpperCase(),  display: true},
    {value: formatMessage({id: "cost", defaultMessage: "cost"}).toUpperCase(),  display: true},
    {value: formatMessage({id: "picture", defaultMessage: "picture"}).toUpperCase(),  display: true},
    {value: formatMessage({id: "version", defaultMessage: "version"}).toUpperCase(),  display: true}
  ]
}

export enum ExportType{
  CSV,
  PDF
}


export const mapDevice = (device: IDevice, isBeta:boolean, formatMessage: any) => {
  let lastContact;
  device.lastMsgIn > device.lastMsgOut ? lastContact = device.lastMsgIn : lastContact = device.lastMsgOut;
  let status: string = formatMessage({id: "ok", defaultMessage: "Ok"});
  if(device.neverUsed) status = formatMessage({id: "new", defaultMessage: "Ny"});
  else if(device.manDownWarning) status = formatMessage({id: "manDownStatusHover", defaultMessage: "Man down"});
  else if(device.inAlarmMode) status = formatMessage({id: "alarm", defaultMessage: "Alarm"});
  else if(device.failedSelfCheck) status = formatMessage({id: "selfCheckStatusHover", defaultMessage: "Feilet selvsjekk"});
  else if(device.outdatedPositionWarning) status = formatMessage({id: "outdatedposition", defaultMessage: "Outdated position"});
  else if(device.geofenceWarning) status = formatMessage({id: "geofence", defaultMessage: "Geofence"});
  else if(device.batteryLow) status = formatMessage({id: "lowBattery", defaultMessage: "lavt batteri"});
  else if(device.staticTooLong) status = formatMessage({id: "static", defaultMessage: "Ikke i aktiv bruk"});
  else if(device.offline) status = formatMessage({id: "offline", defaultMessage: "Avskrudd"});
  else if(device.tcpStatusDisabledWarning && isBeta) status = formatMessage({id: "tcpStatusDisabledHover", defaultMessage: "Ingen kontakt"});
  return[
    {value: status, display: true, priority: 2},
    {value: device.detyName, display: true, priority: 2},
    {value: device.deviceSNNum, display: true, priority: 2},
    {value: device.deviceName, display: true, priority: 2},
    {value: device.battery, display: true, priority: 2},
    {value: device.tracking.toString(), display: true, priority: 2},
    {value: formatDate(lastContact, false, false, false, false, false, formatMessage), display: true, priority: 2},
    {value: device.phoneNumber + " ", display: true, priority: 2},
    {value: device.deviceNote, display: true, priority: 2},
    {value: device.ownerName, display: true, priority: 2},
    {value: formatDate(device.activatedDate, false, false, false, false, false, formatMessage), display: true, priority: 2},
    {value: formatDate(device.lastMsgIn, false, false, false, false, false, formatMessage), display: true, priority: 2}, 
    {value: device.totalCost, display: true, priority: 2},
    {value: device.hasUserImage.toString(), display: true, priority: 2},
    {value: device.firmwareVersion, display: true, priority: 2},
  ]
}

const getStatus = (device: IDevice) => {
  let status: string =  "ok";

  if(device.neverUsed) status = "new";
  else if(device.manDownWarning) status = "manDownStatusHover";
  else if(device.inAlarmMode) status = "alarm";
  else if(device.failedSelfCheck) status = "selfCheckStatusHover";
  else if(device.outdatedPositionWarning) status = "outdatedposition";
  else if(device.geofenceWarning) status = "geofence";
  else if(device.batteryLow) status =  "lowBattery";
  else if(device.staticTooLong) status = "static";
  else if(device.offline) status = "offline";
  else if(device.tcpStatusDisabledWarning) status = "tcpStatusDisabledHover";
  return status;
}


export const getExportData = (type: ExportType, devices: IDevice[], isBeta: boolean, formatMessage: any) => {
  const exportData = [];
  const header = mapHeader(formatMessage);
  exportData.push(header.map(item => {
    if(item.display){
      return item.value || ""
    }
  }).filter(item => item !== undefined));

  devices.forEach((device) => {
    exportData.push(mapDevice(device, isBeta, formatMessage).map(item => {
      if(item.display){
        return item.value || "";
      }
    }).filter(item => item !== undefined));
  })
  return exportData;
}
