import { DeviceState, Device } from '../types';
import {Reducer} from "redux";
import { initialize, filterDevices, updateDevice, updateNotes, partnerDeviceList, filterPartnerDevices } from '../Routines';
import uuid from 'uuid';

const deviceState: DeviceState = {
  listKey: uuid(),
  devices: [],
  lastModifiedTime: 0,
  filteredDevices: [],
  deviceTypes: [],
  partnerDeviceList: [],
  filteredPartnerDeviceList: [],
  filteredPartnerDevicesInit: false,
};

const device: Reducer<DeviceState> = (state: DeviceState = deviceState, action) => {
  switch (action.type) {
    case initialize.SUCCESS:
      return {
        ...state,
        listKey: uuid(),
        ...action.payload
      };

    case filterDevices.SUCCESS:
      return{
        ...state,
        filteredDevices: action.payload
      }

    case updateDevice.SUCCESS:
    
      return{
        ...state,
        ...action.payload
      }

    case partnerDeviceList.SUCCESS:
      return{
        ...state,
        partnerDeviceList: action.payload
      }

    
    case updateNotes.SUCCESS:
      return{
        ...state,
        devices: action.payload
      }

    case filterPartnerDevices.SUCCESS:
      return{
        ...state,
        filteredPartnerDeviceList: action.payload,
        filteredPartnerDevicesInit: true
      }

    default:
      return state;
  }
};

export default device;
