import * as React from "react";
import {connect} from "react-redux";
import {connect as formikConnect} from "formik";
import uuid from "uuid";

import {CancelButton, SaveButton, SaveContainer} from "../../../DefaultSettings/Settings/styled";
import {LabelEnum} from "../../../DefaultSettings/LabelEnum";
import {Footer, LoaderWrap} from "./styles";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {FormReset, formReset, SelectLdap, selectLdap} from "../../Store/actions";
import confirmModal, { ConfirmModalProps } from "./ConfirmModal";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";
import { useIntl } from "react-intl";
import { SubLoader } from "@Safemate/Spinner";



interface FooterProps{
  userToEdit: UserWithCustomer;
  selectLdap: SelectLdap;
  formReset: FormReset;
  saving: boolean;
  isPrivate: boolean;
}

const mapStateToProps = ({ appData: {isPrivate}, users: {action: {edit: {user, saving}}}}: AppState) => {
  return{
    userToEdit: user,
    saving,
    isPrivate
  }
}

const mapDispatchToProps = {
  selectLdap,
  formReset
}

const FooterComp = connect(mapStateToProps, mapDispatchToProps)(
    formikConnect<FooterProps, UserWithCustomer>(
      ({formReset, selectLdap, saving, isPrivate, userToEdit, formik:{dirty, resetForm, submitForm, values}}) => {
        const {Comp, func} = confirmModal();
        const {formatMessage} = useIntl();
 
        const cancel = () => {
          resetForm({ values: userToEdit});
          formReset(uuid.v4());
          selectLdap(!!userToEdit.user.userType);
        }

        const submitFormHandler = (e:any) => {
          if(values.user.emailAsUsername){
            if(values.user.username.includes("kommune") && isPrivate){
              func(true);
            }else{
              submitForm();
            }
          }else{
            if(values.user.email.includes("kommune") && isPrivate){
              func(true);
            }else{
              submitForm();
            }
          }
        }

        return (
          <Footer>
            {saving
              ? <SubLoader/> 
              : <SaveContainer disabled={!dirty}>
                <CancelButton
                  disabled={!dirty}
                  onClick={cancel}>
                  {formatMessage({id: LabelEnum.CANCEL, defaultMessage: "Avbryt"})}
                </CancelButton>
                <SaveButton id="saveButton" onClick={submitFormHandler} disabled={!dirty} type="button">
                  {formatMessage({id: LabelEnum.SAVE, defaultMessage: "Lagre"})}
                </SaveButton>
                <Comp
                  title={``}
                  afterFooter={<div></div>}
                  body={
                    <p>
                      <span>{formatMessage({id: "confirmKommune", defaultMessage: "Du er i ferd med å registrere en bruker med 'kommune' i e-posten. Kommunale brukere bør bruke Safemate Pro, ikke Safemate Go."})}</span>
                    </p>
                  }
                  confirmText={formatMessage({id: "registerAnywayGo", defaultMessage: "Registrer likevel"})}
                  confirmFunc={() => {
                    submitForm();
                    func(false);
                  }}
                />
              </SaveContainer>
            }
          </Footer>
        )
      }
    )
  );

export default FooterComp;
