import styled from "styled-components";
import {Frame} from "../../../styles";
import {Col, Row} from "react-bootstrap";

export const SubHeaderWrapper = styled.span`
  display: flex;
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 5px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const CenteredRow = styled(Row)`
  display: flex;
  justify-content: center;
  padding: 10px;

  h2 {
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const StyledFrame = styled(Frame)`
  padding: 0;
  margin-bottom: 25px;
`

export const InnerFrameHeader = styled(Row)<{border?: boolean}>`
  padding: 25px;
  ${props => props.border && "border-bottom: 1px solid gray"};
`;

export const InnerFrame = styled(InnerFrameHeader)`
  margin: 0 15px;
`;

export const Footer = styled(Row)`
  margin-top: -1px;
  padding: 25px;
  border-top: 1px solid gray;
  display: flex;
  min-height: 100px;
`;

export const BackWrapper = styled.span`
  float: right;
`;

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    height: 40px;
    width: 40px;
  }
`;

export const LoaderWrapAbs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: absolute;
  right: 50%;

  svg {
    height: 40px;
    width: 40px;
  }
`;

export const TrainedWrapper = styled(Col)`
  label {
    display: flex;
    align-items: center;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`


export const SwitchHeader = styled.h2`
  padding-right: 15px;
`;
