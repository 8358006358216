import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

let client: Client;

export const connectWebsocket = (): Client => {


    if(!client || !client.connected){
        client = new Client();
        // @ts-ignore
        client.webSocketFactory = function () {
            return new SockJS("/ajax/socket");
          };
    }
    
    client.onStompError = function (frame) {
        console.log('Broker reported error: ' + frame.headers['message']);
        console.log('Additional details: ' + frame.body);
    };

    return client;
}

export const disconnectWebsocket = () => {
    if(client && client.active){
        client.deactivate();
    }
}
