import * as React from "react";
import { injectIntl, WrappedComponentProps, useIntl } from "react-intl";
import { Field } from "formik";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../Elements/Dropdown/types";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {RootState, Organization, PostCity, Firm} from "../Store/types";
import {Action, ActionFunctionAny} from "redux-actions";
import { getPostCity} from "../Store/routines";
import withFormWrapper from "@Elements/Form/formWrapper";
import { AppState } from "@Safemate/Store/types";

const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const LabelNoCapitalize = styled.label`
  text-transform: none;
  display: block;

`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  padding-bottom: 10px;
`;

const StyledMainRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center;
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }

`;

export const Name = injectIntl(({ intl: { formatMessage } }) => {
  return (
    <StyledRow>
      <Col md={6}>
        <Label>{formatMessage({id: "firstName", defaultMessage: "Fornavn"})}</Label>
        <StyledField
          type="text"
          name="firstName"
        />
      </Col>
      <Col md={6}>
        <Label>{formatMessage({id: "lastName", defaultMessage: "Etternavn"})}</Label>
        <StyledField
          type="text"
          name="lastName"
        />
      </Col>
    </StyledRow>
  )
})


interface ZipProps{
  base: Organization;
  getPostCity: Routine<ActionFunctionAny<Action<any>>>;
  postCity: PostCity;
}
const mapStateToPropsZip = ( { myPageBilling: {subUnits: {customers}, customer: {postCity}}} : AppState) => {
  return{
    base: customers,
    postCity
  }
};
const mapDispatchFromStateZip = {
  getPostCity
}

export const ZipAndPost = connect(mapStateToPropsZip, mapDispatchFromStateZip)(
  withFormWrapper<Firm, ZipProps>(
    ({formik: { setFieldValue }, postCity, getPostCity }) => {
      const [ postalCity, setPostalCity ] = React.useState("");
      const { formatMessage } = useIntl();
      
      React.useEffect(() => {
        if(postalCity != postCity.result){
          setPostalCity(postCity.result);
          setFieldValue("addrCity", postCity.result);
        }
      })
      
      const getPostalCity = (e: React.FormEvent<HTMLInputElement>) => {
        if(e.currentTarget.value.length == 4){
          getPostCity(e.currentTarget.value);
        }
      }

    return(
      <StyledRow>
      <Col md={6}>
        <Label>{formatMessage({id: "addrZipCode", defaultMessage: "Postnummer"})}</Label>
        <StyledField
          type="text"
          name="addrZipCode"
          onKeyUp={getPostalCity}
        />
      </Col>
      <Col md={6}>
        <Label>{formatMessage({id: "addrCity", defaultMessage: "Poststed"})}</Label>
        <StyledField
          type="text"
          name="addrCity"
        />
      </Col>
    </StyledRow>
  )
  })
)

export const Post = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "addrCity", defaultMessage: "Poststed"})}</Label>
        </Col>
        <Col md={8}>

        <StyledField
          type="text"
          name="addrCity"
          
        />
        </Col>
    </StyledMainRow>
  )
})


interface LandProps extends WrappedComponentProps{
  countryCode: string;
}

interface InvoiceProps extends WrappedComponentProps{
  invoiceCode: string;
}

export const Land = injectIntl(
  withFormWrapper<Firm, LandProps>(({ intl: { formatMessage }, formik: { setFieldValue }, countryCode}) => {

    const CountryMethod = React.useMemo(() => {
      return [
        {value: "nbno", text: formatMessage({id: "Norway", defaultMessage: "Norway"})},
        {value: "SE", text: formatMessage({id: "Sweden", defaultMessage: "Sweden"})},
        {value: "DK", text: formatMessage({id: "Denmark", defaultMessage: "Denmark"})},
        {value: "IS", text: formatMessage({id: "Iceland", defaultMessage: "Iceland"})}
      ]
    }, [countryCode])

    return (
      <StyledMainRow>
        <Col md={12}>
          <Label>{formatMessage({id: "country", defaultMessage: "Land"})}</Label>
          <Dropdown
            options={CountryMethod}
            onChange={(option) => {
              const { value } = option as Option;
              setFieldValue("country", value);
            }}
            initial={countryCode}
            size={{width: "200px"}}
          />  
        </Col>
      </StyledMainRow>
    )
  })
);


export const InvoiceAndEmail = injectIntl(
  withFormWrapper<Firm, InvoiceProps>(({ intl: { formatMessage }, formik: { setFieldValue }, invoiceCode}) => {

    const invoiceMethod = React.useMemo(() => {
      return [
        {value: "6", text: formatMessage({id: "e-post", defaultMessage: "e-post"})},
        {value: "4", text: formatMessage({id: "post", defaultMessage: "post"})}
      ]
    }, [])

    return (
      <StyledMainRow>
          <Col md={6}>
            <Label>{formatMessage({id: "invoiceMethod", defaultMessage: "Fakturametode"})}</Label>
            <Dropdown
                options={invoiceMethod}
                onChange={(option) => {
                  const { value } = option as Option;
                  setFieldValue("pptyId", value);
                }}
                initial={invoiceCode}
                size={{width: "200px"}}
              />
          </Col>
          <Col md={6}>
            <LabelNoCapitalize>{formatMessage({id: "emailInvoice", defaultMessage: "Faktura pr. e-post"})}</LabelNoCapitalize>
          <StyledField
              type="text"
              name="email"  
            />
          </Col>
      </StyledMainRow>
    )
  })
);

export const InvoiceAddress = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "navn", defaultMessage: "Navn"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="addrName"
          
        />
      </Col>
    </StyledMainRow>
  )
})

export const Reference = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={12}>
        <Label>{formatMessage({id: "addrReference", defaultMessage: "Referanse"})}</Label>
        <StyledField
          type="text"
          name="addrReference"
          
        />
      </Col>
    </StyledMainRow>
  )
})




export const AddressOne = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
          <Col md={12}>
            <Label>{formatMessage({id: "addressLine1", defaultMessage: "Adresselinje 1"})}</Label>
            <StyledField
                type="text"
                name="addressLine1" 
              />
          </Col>
    </StyledMainRow>
  )
})

export const AddressTwo = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
          <Col md={12}>
          <Label>{formatMessage({id: "addressLine2", defaultMessage: "Adresselinje 2"})}</Label>
            <StyledField
                type="text"
                name="addressLine2"
                
              />
          </Col>
    </StyledMainRow>
  )
})
