import { AhpState } from "../types";
import {Reducer} from "redux";
import { getDefaultAhp } from "../routines";

const ahpState: AhpState = {
  defaultAhp: null,
  privateLink: ""
};

const ahp: Reducer<AhpState> = (state: AhpState = ahpState, action) => {
  switch (action.type) {

    case getDefaultAhp.SUCCESS:
      return {
        ...state,
        defaultAhp: action.payload
      }

    default:
      return state;
  }
};

export default ahp;
