import { EventLog } from "@Safemate/Model/EventLog";

export type FilterList = (list: EventLog[], typeFilter: number[]) => EventLog[];

export const filterlist: FilterList = (list, type) => {
  return list.filter(event => {
    if(type.includes(event.evtyId) || type.length === 0){
      return event;
    }
  });
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}