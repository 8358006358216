import {call, put, select, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../../Store/types';
import * as Routines from "../routines";
import { TranslationModuleActionTypes as Actions } from "../actionTypes";
import { getLanguageCode, getFilter, getUnfilteredTextLabels } from '../selectors';
import { filterTexts } from '../utils';
import { filter, getTextLabels } from '../routines';
import { FilterState, TextLabelWithText } from '../types';

function* filterTextLabels(action: AppAction<null>){
  try {

    const languageCode: string = yield select(getLanguageCode);
    const textLabel: TextLabelWithText[] = yield select(getUnfilteredTextLabels);
    const filterState: FilterState = yield select(getFilter);

    const filteredTexts = filterTexts(languageCode, textLabel, filterState);

    yield put(Routines.filter.success(filteredTexts));
  }
  catch(e){
    yield put(Routines.filter.failure());
  }
  yield put(Routines.filter.fulfill());
}


function* filterSaga(){
  yield takeLatest(filter.TRIGGER, filterTextLabels);
  yield takeLatest(getTextLabels.FULFILL, filterTextLabels);
  yield takeLatest(Actions.SORT_TABLE, filterTextLabels);
  yield takeLatest(Actions.SET_SEARCH, filterTextLabels);
  yield takeLatest(Actions.SET_LANGUAGE_OPTION, filterTextLabels);
  yield takeLatest(Actions.SET_LANGUAGE_FILTER, filterTextLabels);
}

export default filterSaga;
