import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import invoiceSaga from "./invoices";

function* partnerDashboardSaga(){
  yield all([
    fork(initializationSaga),
    fork(invoiceSaga)
  ])
}
export default partnerDashboardSaga;
