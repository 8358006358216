import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';

const SIcon = styled(FontAwesomeIcon)`
  color: ${({
    theme: {
      colors: { textSecondary }
    }
  }) => textSecondary};
  height: ${({
    theme: {
      icons: { medium }
    }
  }) => medium};
  width: ${({
    theme: {
      icons: { medium }
    }
  }) => medium};
  cursor: pointer;
  animation: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: ${({ spin }) => (spin ? 'running' : 'paused')};

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Icon = props => <SIcon {...props} icon={faSyncAlt} />;

export default Icon;
