import {Position} from "@Safemate/map/Store/types";
import {isValid, MapWrapper} from "@Safemate/map/Map/mapWrapper";
import {connect} from "react-redux";
import * as React from "react";
import {AppState} from "@Safemate/Store/types";


interface MapDelegateProps{
  mapWrapper: MapWrapper
  positions: Position[];
}

const mapDelegateStateToProps = ({ transactionLog: {transaction: { positions }}}: AppState) => {
  return {
    positions,
  }
}

const MapDelegate = connect(mapDelegateStateToProps, null)(({positions, mapWrapper}: MapDelegateProps) => {

  const getValidPositions = (positions: Position[]) => {
      let firstPos = true;
      const validPositions = positions.map(pos => {
        if(isValid(pos)){
          if(firstPos){
            pos.drawMarker = true;
            firstPos = false;
          }
          return pos;
        }
      }).filter(pos => pos) as Position[];
      return validPositions;
    }

    React.useEffect(() => {
      if(mapWrapper.isMapInitialized()){
        mapWrapper.setPositions(getValidPositions(positions));
        console.log("Update positions");
      }
    }, [positions])

    return null;
  }
)

export default MapDelegate;




