export enum OrderFilterEnum{
    DEFAULT = "default",
    LAST_CONTACT_ASC = "lastcontactasc",
    LAST_CONTACT_DESC = "lastcontactdesc",
    NAME = "name",
    NOTES_ADDED_DESC = "notesaddeddesc",
    PHONE_NUMBER = "phonenumber",
    CUSTOMER_ID_ASC = "customerIdAsc",
    PRICE_GROUP_ASC = "priceGroupAsc",
    NO_OF_DEVICES_DESC = "noOfDevices",
  }