import { combineReducers } from 'redux';
import {RootState} from "../types";
import user from "./user";
import initialization from "./initialization";
import action from "./actions";
import role from "./role";
import filter from "./filter";

export default combineReducers<RootState>({
  user,
  initialization,
  action,
  role,
  filter,
});
