import {NokEmployeeLoginIdleHour} from "@Safemate/DefaultSettings/Settings/Privacy";

export enum SettingEnum{
  TRIGGER_FOUR_HEARTBEAT = "triggerFourHeartbeat",
  SELF_CHECK_LIMIT = "selfCheckLimit",
  OUTDATED_POSITION_THRESHOLD = "outdatedPositionThreshold",
  MESSAGE_INTERVAL_PERIODIC = "messageIntervalPeriodic",
  CALLBACK_TIMER = "callbackTimer",
  PREF_COM_METHOD = "prefComMethod",
  TWO_WAY_DECT_TIMER = "twoWayDECTTimer",
  ANSWER_INCOMING = "answerIncoming",
  SILENT_ACTIVE = "silentActive",
  HEARTBEAT_INTERVAL = "heartbeatInterval",
  STATIC_TOO_LONG = "staticTooLong",
  SATELLITES = "satellites",
  DISABLE_POSITIONING = "disablePositioning",
  TRACKING_INTERVAL = "trackingInterval",
  AUTO_TRACKING = "autoTracking",
  VOICE_PROMPT = "voicePrompt",
  GUARD_TIME_BEEP = "guardTimeBeep",
  CONFIRM_BEEP = "confirmBeep",
  WANDERER_BEEP = "wandererBeep",
  SOUND_LEVEL = "soundLevel",
  SOUND_LEVEL_TRIGGER_FOUR = "soundLevelTriggerFour",
  SOUND_LEVEL_RINGTONE_TRIGGER_FOUR = "soundLevelRingtoneTriggerFour",
  SOUND_LEVEL_TRIGGER_TWO_4G = "soundLevelTriggerTwo4G",
  SOUND_LEVEL_RINGTONE_TRIGGER_TWO_4G = "soundLevelRingtoneTriggerTwo4G",
  MIC_VOLUME = "micVolume",
  VOICE_PROMPT_VOLUME_TRIGGER_FOUR = "voicePromptVolumeTriggerFour",
  SOUND_LEVEL_RINGTONE = "soundLevelRingtone",
  DECT_SOUND_LEVEL = "dectSoundLevel",
  TWO_WAY_MIC = "twoWayMic",
  LIGHT_LEVEL = "lightLevel",
  SYSTEM_SOUNDS = "systemSounds",
  INCOMING_RINGTONE = "incomingRingTone",
  HELP_ARRIVE_INDICATOR = "helpArriveIndicator",
  CALLBACK_INDICATOR = "callbackIndicator",
  LIGHT_ALARM_MODE = "lightAlarmMode",
  LIGHT_NORMAL_MODE = "lightNormalMode",
  BATTERY_THRESHOLD = "batteryThreshold",
  POWER_FAILURE_REPORT_DELAY = "powerFailureReportDelay",
  SENSOR_SUPERVISION_CHECK_ACTIVE = "sensorSupervisionCheckActive",
  SENSOR_SUPERVISION_CHECK_TIMER = "sensorSupervisionCheckTimer",
  SENSOR_SUPERVISION_CHECK_TIMER_PENDANT = "sensorSupervisionCheckTimerPendant",
  GUARD_TIME = "guardTime",
  ALARM_TIMEOUT = "alarmTimeout",
  HELP_ARRIVE = "helpArrive",
  LOCAL_LEARNING = "localLearning",
  BYPASS_TIMER = "bypassTimer",
  LED = "led",
  SOUND_ON = "soundOnOff",
  LOW_BATTERY_VOICE_PROMPT = "lowBatteryVoicePrompt",
  RINGING_ON = "ringingOnOff",
  LIGHT_MODE="lightMode",
  ENABLE_OFF_BUTTON="enableOffButton",
  ALARM_SMS_RECEIPT="alarmSmsReceipt",
  DEVICE_TRANSACTION_LOGS = "deviceTransactionLogsDays",
  USER_TRANSACTION_LOGS = "userTransactionLogsDays",
  DEVICE_POSITION_HISTORY_DEPTH_HOURS = "devicePositionHistoryDepthHours",
  DEVICE_POSITION_HISTORY_DEPTH_POS_PLOTS = "devicePositionHistoryDepthPosPlots",
  NOK_EMPLOYEE_LOGIN_IDLE_HOUR = "nokEmployeeLoginIdleHour",
  ACCEPT_LBS = "acceptLBS",
  GEOLOCATION_WIFI_LOOKUP_GOOGLE = "geolocationWIFILookupGoogle",
  GEOLOCATION_WIFI_LOOKUP_COMBAIN = "geolocationWIFILookupCombain",
  FIRMWARE = "firmware",
  SAFEMATE_AS_RECEIVER = "safemateAsReceiver",
  VOICE_PROMPT_VOLUME_GX8 = "voicePromptVolumeGX8",
  ACL_CHECK = "aclCheck",
  SENSOR_DISCONNECT_THRESHOLD = "sensorDisconnectThreshold",
  PHONE_NO_WHITE_LIST_1 = "phoneNoWhiteList1",
  PHONE_NO_WHITE_LIST_2 = "phoneNoWhiteList2",
  PHONE_NO_WHITE_LIST_3 = "phoneNoWhiteList3",
  AHP_NUMBER = "ahpNumber",
  VIBRATION = "vibration",
  INTEGRATION = "msgIntegration",
  INTEGRATION_HANDLER = "msgI9nHandler",
  INTEGRATION_ENDPOINT = "msgI9nEndpoint",
  INTEGRATION_BEFORE_CALL = "msgI9nBeforeCall",
  INTEGRATION_AFTER_CALL = "msgI9nAfterCall",
  INTEGRATION_HALT_CALL = "msgI9nHaltCalls",
  INTEGRATION_HALT_INTEGRATION = "msgI9nHaltI9n",
  INTEGRATION_SECURED = "msgIntegrationSecured",
  INTEGRATION_USERNAME = "msgI9nUsername",
  INTEGRATION_PASSWORD = "msgI9nPassword",
  INTEGRATION_FAILURE_FALLBACK = "msgI9nFailureFallback",
  INTEGRATION_FAILURE_FALLBACK_NUMBER = "msgI9nFailureFallbackNumber",
  ENABLE_DEFAULT_EVENT_SETUP = "enableDefaultEventSetup",
  EVENT_SETUP = "deviceEventSetups",
  SEQUENTIAL_CALLING = "sequentialCalling",
  SENSOR_SENSITIVITY = "sensorSensitivity",
  INTEGRATION_PERMISSION = "integrationPermission",
  TEAM_ALERT = "teamAlert",
  CLIMAX_VOICE_PROMPT_ALARM = "climaxVoicePromptAlarm",
	CLIMAX_VOICE_PROMPT_CALLBACK = "climaxVoicePromptCallback",
	CLIMAX_VOICE_PROMPT_FALL = "climaxVoicePromptFall",
	CLIMAX_VOICE_PROMPT_WANDERER = "climaxVoicePromptWanderer",
	CLIMAX_VOICE_PROMPT_BED = "climaxVoicePromptBed",
	CLIMAX_VOICE_PROMPT_AC_FAILURE = "climaxVoicePromptACFailure",
	CLIMAX_VOICE_PROMPT_AC_RESTORE = "climaxVoicePromptACRestore",
  INTEGRATION_ROLE = "integrationRole",
  SYNCHRONIZE_SOUND = "synchronizeSound",
  USER_INACTIVITY_LIMIT = "userInactivityLimit"
}

export enum SettingPrefixEnum{
  DEFAULT,
  COMMUNICATION = "communication",
  NOT_IN_USE = "notInUse",
  POSITIONING = "positioning",
  TRACKING = "tracking",
  SOUND_LIGHT = "soundLight",
  SOUND = "sound",
  LIGHT = "light",
  VOICE_PROMPT = "voicePrompt",
  BATTERY = "battery",
  GENERAL = "general",
  PRIVACY = "privacy",
  FIRMWARE = "firmware",
  WHITELIST = "whitelist",
  INTEGRATION = "integration",
  EVENT_SETUP = "eventSetup",
  INDOOR_LOCATION = "indoorLocation",
  GEOFENCE = "geofence",
  MISC = "misc",
  DEVICE_SETTING = "deviceSetting",
  OTHER_SETTING = "otherSetting",
  MOBILE_APP = "mobileAppSetting"
}
