import { Formik } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { DeviceHardwareEnum, isGx8 } from "@Safemate/DeviceHardwareEnum";
import SettingsHeader from "../General/header";
import { ComponentWrapper, StyledForm } from "../General/Tabs/styled";
import { DeviceComponents } from "../tabDefinition";
import Footer from "./Footer";
import { SettingEnum as ComponentSettingEnum } from "../settingEnum";
import Toggle from "@Elements/Switch";

interface SettingTabProps{
  dehaId: number;
  deviId: number;
  settings: any;
  save: Routine<ActionFunctionAny<Action<any>>>;
  settingEnum: ComponentSettingEnum;
  header: {
    title: string;
    tooltip?: string;
    tooltipTitle?: string;
    children?: JSX.Element;
    toggle?: boolean;
    toggleValue?: string[];
  }
}

const SettingTab = ({ dehaId, deviId, settings, save, settingEnum, header }: SettingTabProps) => {

  const { formatMessage } = useIntl();

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  return(
    <Formik
      onSubmit={(values) => {
        save({
          deviceId: deviId,
          settings: values
        })
      }}
      initialValues={settings}
      enableReinitialize
    >
    {({values, setFieldValue}) => 
      <StyledForm>
        <SettingsHeader 
          title={formatMessage({id: header.title, defaultMessage: header.title})}
          tooltip={header.tooltip}
          tooltipTitle={{
            id: header.tooltipTitle,
            defaultMessage: header.tooltipTitle
          }}
        >
          <React.Fragment>
          {(header.toggleValue && header.toggle)  &&
            <Toggle
              checked={(() => {
                let value = values;
                for(let i = 0; i < header.toggleValue.length; i++){
                  value = value[header.toggleValue[i]];
                }
                return value;
              })()}
              onChange={(value: boolean) => setFieldValue(header.toggleValue.join("."), value)}
            />}
            {header.children}
          </React.Fragment>
        </SettingsHeader>
        <div>
          {components.has(settingEnum) 
            && components.get(settingEnum)?.map(comp => {
              return <ComponentWrapper>{comp}</ComponentWrapper>
          })}
        </div>
        <Footer
          settings={settings}
        />
      </StyledForm>}
    </Formik>
  )
}

export default SettingTab;