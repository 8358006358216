import * as React from "react";
import FieldWrapper, {GenericCheckbox} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {LabelEnum} from "../../LabelEnum";
import withFormWrapper from "@Elements/Form/formWrapper";
import { EventSetupSettings } from "../types";
import Modal from "../../../Modals";
import { Days, DaysView } from "./days";
import { connect } from "react-redux";
import styled from "styled-components";
import { MainWrap, StyledCol, Title, Section, Sequential, RadioWrapper, WarnDiv } from "./styles";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Field } from "formik";
import { Time, Type } from "./time";
import { AllEvents } from "@Safemate/EventSetupNew/Body/Events/eventTypes";
import { MainHeader, TableEventWrapper } from "@Safemate/EventSetupNew/Body/WrapperStyles";
import { Events } from "./events";
import { Collapse } from "react-collapse";
import { newEventSetup, RecipientType } from "./utils";
import Trash from "@Elements/Icon/Icons/Trash";
import { Delete } from "./delete";
import { Caret } from "@Safemate/EventSetupNew/Body/TableElementStyles";
import { Radio } from "@Elements/RadioFormik";
import { AppState } from "@Safemate/Store/types";

// Checkboxes
export const EnableDefaultEventSetup = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.ENABLE_DEFAULT_EVENT_SETUP, defaultMessage: "Bruk standard varslingsliste"}
    }
  },
  prefix: SettingPrefixEnum.EVENT_SETUP,
  field: SettingEnum.ENABLE_DEFAULT_EVENT_SETUP
});

const Wrapper = styled.div`
  padding: 0px;

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

interface DefaultEventSetupProps{
  custId: number;
}

const mapStateToProps = ({ defaultSettings: {customer: { selectedCustomer: { customerId }}}}: AppState) => {
  return {
    custId: customerId
  }
}

export const DefaultEventSetup: any = connect(mapStateToProps)(withFormWrapper<EventSetupSettings, DefaultEventSetupProps>(({custId, formik: { setFieldValue, values: { sequentialCalling, deviceEventSetups, enableDefaultEventSetup }}}) => {

  const [index, setIndex] = React.useState(0);

  const [showDays, setShowDays] = React.useState(false);
  const [showTime, setShowTime] = React.useState(false);
  const [timeType, setTimeType] = React.useState(Type.FROM);
  const [showEvents, setShowEvents] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);

  const { formatMessage } = useIntl();

  const displayDays = (index: number) => {
    setIndex(index);
    setShowDays(true);
  }

  const displayTime = (index: number, type: Type) => {
    setIndex(index);
    setTimeType(type);
    setShowTime(true);
  }

  const displayEvents = (index: number) => {
    setIndex(index);
    setShowEvents(true);
  }

  const displayDelete = (index: number) => {
    setIndex(index);
    setShowDelete(true);
  }

  const addNew = (type: RecipientType) => {
    const setupsToKeep = deviceEventSetups.filter(event => {
      return event.custId === custId;
    })

    let sort = 1;
    setupsToKeep.forEach(event => {
      if (type === RecipientType.GSM && event.email === null) sort++;
      if (type === RecipientType.EMAIL && event.email != null) sort++;
    })

    setupsToKeep.push(newEventSetup(custId, type, sort, setupsToKeep.length));
    setFieldValue(`${prefix}`, setupsToKeep)
  }

  let gsmCount = 0;

  const setups = deviceEventSetups.map((event, index) => {
    if(event.email === null) gsmCount++;
    return {
      ...event,
      localSort: event.email === null ? event.sort : event.sort + 100
    }
  })

  const prefix = SettingEnum.EVENT_SETUP;

  const increase = (index: number) => {
    const currentSort = deviceEventSetups[index].sort;
    const setup = deviceEventSetups.find(event => event.sort === currentSort - 1);
    if(setup){
      setFieldValue(`${prefix}.${setup.index}.sort`, setup.sort + 1);
    }
    setFieldValue(`${prefix}.${index}.sort`, deviceEventSetups[index].sort - 1);
  }

  const decrease = (index: number) => {
    const currentSort = deviceEventSetups[index].sort;
    const setup = deviceEventSetups.find(event => event.sort === currentSort + 1);
    if(setup){
      setFieldValue(`${prefix}.${setup.index}.sort`, setup.sort - 1);
    }
    setFieldValue(`${prefix}.${index}.sort`, deviceEventSetups[index].sort + 1);
  }

  return(
    <Wrapper>
      <Collapse isOpened={enableDefaultEventSetup === "true"}>
        <div>
        <MainHeader>
          {formatMessage({id: 'gsmRecipients', defaultMessage: 'GSM Recipients'})}
        </MainHeader>
        {gsmCount > 4 &&<WarnDiv>
          {formatMessage({id: "defaultEventSetupExceedFourWarn", defaultMessage: "Be mindful that not all device types support more than 4 entries in the event setup."})}
        </WarnDiv>}
        {setups.sort((a, b) => {return a.localSort - b.localSort}).map((event, index) => {
          if(event.email !== null) return null;
          return(
            <div style={{display: "flex", alignItems: "center"}}>
              <MainWrap default={event.custId !== custId} fluid>
                <Trash onClick={() => displayDelete(event.index)} style={{position: "absolute", right: "10px", zIndex: "100"}}/>
                <ContactInformation index={event.index}/>
                <TimeInformation index={event.index} displayTime={displayTime} displayDays={displayDays}/>
                <EventInformation index={event.index} displayEvents={displayEvents}/>
              </MainWrap>
              {sequentialCalling === "true" &&
              <Sequential>
                {event.sort !== 1 && 
                  <Caret
                    tabIndex={0}
                    id={`sort-up-${event.index}`}
                    onClick={() => increase(event.index)}
                    onKeyDown={e =>
                      e.key === 'Enter' &&
                      increase(event.index)
                    }
                    up
                    size={6}
                  />}
                <div>{event.sort}</div>
                {gsmCount !== event.sort && <Caret
                    tabIndex={0}
                    id={`sort-up-${event.index}`}
                    onClick={() => decrease(event.index)}
                    onKeyDown={e =>
                      e.key === 'Enter' &&
                      decrease(event.index)
                    }
                    down
                    size={6}
                  />}
              </Sequential>}
            </div>
          )
        }).filter(event => event)}
          <RadioWrapper>
            <Field
              component={Radio}
              name={SettingEnum.SEQUENTIAL_CALLING}
              radioValue="false"
              id="parallel"
              label={formatMessage({id: "parallelCalling", defaultMessage: "Parallel Calling"})}
              outerDiv={{float: "unset"}}
            />
            <Field
              component={Radio}
              name={SettingEnum.SEQUENTIAL_CALLING}
              radioValue="true"
              id="sequential"
              label={formatMessage({id: "sequentialCalling", defaultMessage: "Sequential Calling"})}
              outerDiv={{float: "unset"}}
            />
          </RadioWrapper>
          {gsmCount < 10 && <div>
          <Button
            onClick={() => addNew(RecipientType.GSM)}
          >{formatMessage({id: 'newGsm', defaultMessage: 'NEW GSM RECIPIENT'})}</Button>
          </div>}
        </div>
        <div>
        <MainHeader>
          {formatMessage({id: 'emailRecipientsEventSetup', defaultMessage: 'Event receivers e-mail'})}
        </MainHeader>
        {setups.sort((a, b) => {return a.localSort - b.localSort}).map((event) => {
          if(event.email === null) return null;
          return(
            <div style={{display: "flex", alignItems: "center"}}>
              <MainWrap default={event.custId !== custId} fluid>
                <Trash onClick={() => displayDelete(event.index)} style={{position: "absolute", right: "10px", zIndex: "100"}}/>
                <EmailInformation index={event.index}/>
                <TimeInformation index={event.index} displayTime={displayTime} displayDays={displayDays}/>
                <EventInformation index={event.index} displayEvents={displayEvents}/>
              </MainWrap>
            </div>
          )
        }).filter(event => event)}
        <div>
        <Button
           onClick={() => addNew(RecipientType.EMAIL)}
        >{formatMessage({id: 'newEmail', defaultMessage: 'NY EPOST-MOTTAKER'})}</Button>
        </div>
        </div>
      <Modal display={showDays}>
          <Days index={index} close={setShowDays}/>
      </Modal>
      <Modal display={showTime}>
          <Time type={timeType} index={index} display={setShowTime}/>
      </Modal>
      <Modal display={showEvents}>
          <Events index={index} display={setShowEvents}/>
      </Modal>
      <Modal display={showDelete}>
          <Delete index={index} display={setShowDelete}/>
      </Modal>
      </Collapse>
    </Wrapper>
  )
}))

interface InformationProps{
  index: number;
}

const ContactInformation = ({ index }: InformationProps) => {

  const { formatMessage } = useIntl();

  const prefix = `${SettingEnum.EVENT_SETUP}.${index}`;

  return(
    <Section>
      <StyledCol xs={12} sm={6} md={4}>
        <div><Title>{formatMessage({id: "receiver", defaultMessage: "Recipient"})}</Title></div>
        <div><Field name={`${prefix}.givenName`} type="text"/></div>
      </StyledCol>
      <StyledCol xs={12} sm={6} md={4}>
        <div><Title>{formatMessage({id: "phone", defaultMessage: "Phone number"})}</Title></div>
        <div><Field name={`${prefix}.phone`} type="text"/></div>
      </StyledCol>
    </Section>
  )
}

const EmailInformation = ({ index }: InformationProps) => {
  const { formatMessage } = useIntl();

  const prefix = `${SettingEnum.EVENT_SETUP}.${index}`;

  return(
    <Section>
      <StyledCol sm={12} md={8}>
        <div><Title>{formatMessage({id: "email", defaultMessage: "E-Post"})}</Title></div>
        <div><Field name={`${prefix}.email`} type="text"/></div>
      </StyledCol>
    </Section>
  )
}

interface TimeInformationProps extends InformationProps{
  displayDays: (index: number) => void;
  displayTime: (index: number, type: Type) => void;
}

const TimeInformation = withFormWrapper<EventSetupSettings, TimeInformationProps>(({ formik: { values: {  deviceEventSetups }}, index, displayDays, displayTime }) => {

  const { formatMessage } = useIntl();
  
  return(
    <Section>
      <StyledCol sm={3} md={1} onClick={() => displayTime(index, Type.FROM)} style={{cursor: "pointer"}}>
        <div><Title>{formatMessage({id: "from", defaultMessage: "Fra"})}</Title></div>
        <div><span>{deviceEventSetups[index].warnTimeFromString}</span></div>
      </StyledCol>
      <StyledCol sm={3} md={1} onClick={() => displayTime(index, Type.TO)} style={{cursor: "pointer"}}>
        <div><Title>{formatMessage({id: "to", defaultMessage: "Til"})}</Title></div>
        <div><span>{deviceEventSetups[index].warnTimeToString}</span></div>
      </StyledCol>
      <StyledCol sm={6} md={2} onClick={() => displayDays(index)} style={{cursor: "pointer"}}>
        <div><Title>{formatMessage({id: "days", defaultMessage: "Dager"})}</Title></div>
        <DaysView index={index}/>
      </StyledCol>
    </Section>
  )
})

interface EventInformationProps extends InformationProps{
  displayEvents: (index: number) => void;
}

const EventInformation = withFormWrapper<EventSetupSettings, EventInformationProps>(({ formik: { values: { deviceEventSetups }}, index, displayEvents }) => {

  const { formatMessage } = useIntl();

  const events = AllEvents.filter((event: any) => deviceEventSetups[index][event.type]);

  return(
    <Section>
      <StyledCol md={12}  onClick={() => displayEvents(index)} style={{cursor: "pointer"}}>
        <div><Title>{formatMessage({id: 'eventTypes', defaultMessage: 'Event types'})}</Title></div>
        {events.length > 0 
          ? events.map(event => 
            <TableEventWrapper
              key={event.type}
              style={{marginRight: '10px'}}
            >
            {event.iconSmall}
            </TableEventWrapper>)
          : <span>
              {formatMessage({
                id: 'noneSelected',
                defaultMessage: 'Ingen valgt'})}
            </span>
          }
      </StyledCol>
    </Section>
  )
})
