import createTabsComponent from '../createTabsComponent';
import createRouteTabsComponent from '../createRouteTabsComponent';

import StyledContainer from './Container';
import StyledTabContainer from './TabContainer';
import StyledTab from './Tab';
import StyledContent from './ContentContainer';

export const VerticalTabs = createTabsComponent({
  StyledContainer,
  StyledTabContainer,
  StyledTab,
  StyledContent
});

export const VerticalRouteTabs = createRouteTabsComponent({
  StyledContainer,
  StyledTabContainer,
  StyledTab,
  StyledContent
});

export default VerticalTabs;
