import * as React from "react";
import { Field } from "formik";
import {Action, ActionFunctionAny} from "redux-actions";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import { setReference} from "../../Store/routines";
import styled from "styled-components";
import { Col, Row, Button } from "react-bootstrap";
import Check from "../../../../Elements/Icon/Icons/Check";
import {Customer} from "../../Models/Customer";


export const SubmitButton = styled(Button)`
  height: 40px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  padding-right: 5px!important;
  padding-left: 5px!important;
`;


const StyledDiv = styled(Col)`
  padding-right: 0px;
  padding-left: 0px;
  input {
    width: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 0 5px;
    background: none;
  }
`;

const StyledButtonDiv = styled(Col)`
  padding-left: 0px;
`;


const StyledField = styled(Field)`
  width: 100%;
`;

interface OrganisationProps{
    reference: string;
    custId: number;
    setReference: Routine<ActionFunctionAny<Action<any>>>;
    custTree: number[];
  
  }

  const mapDispatchFromState = {
    setReference,
  }

export const Reference = connect(null, mapDispatchFromState)(({reference, custId, custTree, setReference}: OrganisationProps) => {

  const [ referenceVal, setReferenceVal ] = React.useState(reference);
  const [ disabledVal, setDisabledVal ] = React.useState(true);


  React.useEffect(() => {
    setReferenceVal(reference || "");
  }, [reference])


  const handleChange = (event: { target: HTMLInputElement; }) => {
    setReferenceVal(event.target.value);
    if(event.target.value != reference){
      setDisabledVal(false);
    }else{
      setDisabledVal(true);
    }
  }

  const handleSubmit = () => {
    setReference({referenceVal, custId, custTree});
    setDisabledVal(true);
  }

  return(
    <React.Fragment>
        <StyledDiv md={10}>
        <input
            type="text"
            value={referenceVal}
            onChange={handleChange}
          />
        </StyledDiv>
        <StyledButtonDiv md={2}>
        <SubmitButton 
          type="submit"
          onClick={handleSubmit}
          disabled={disabledVal}
          >
            <Check inline/>
          </SubmitButton>
        </StyledButtonDiv>
    </React.Fragment>
  )
})