import * as React from "react";
import { injectIntl, WrappedComponentProps, MessageDescriptor} from "react-intl";

import {
  Table,
  Td,
  Th,
  HeaderTr,
  ContentTr,
  HiddenTr,
  SpacedTr,
  SpacedTd,
  TranslatedSpan
} from './layout';

interface ListProps extends WrappedComponentProps{
  header: Array<any>;
  content: Array<any>;
  onRowClick?: (index: number) => void;
  innerRef?: React.MutableRefObject<HTMLTableElement | null>;
  removeHover?: boolean;
  onMouseOver?: Function;
  onMouseLeave?: Function;
}

const List = injectIntl(({intl: {formatMessage}, header, content, innerRef, onRowClick, removeHover, onMouseLeave, onMouseOver}: ListProps) => {

  const [selected, setSelected] = React.useState(null);

  return(
    <Table ref={innerRef}>
      <thead>
        <HeaderTr>
          {header.map((item, index) =>
            <Th width={item.width} priority={item.priority} key={`header=${index}`} align={item.align}>
              {item.rotate ? <TranslatedSpan>{item.text}</TranslatedSpan> : item.text}
            </Th>
          )}
        </HeaderTr>
      </thead>
      <tbody>
      {content.map((item, index) => {
        if(item){
        const id = item.id || index;
        return(
          <React.Fragment>
            <ContentTr
              onMouseOver={() => onMouseOver && onMouseOver(item)}
              onMouseLeave={() => onMouseLeave && onMouseLeave(item)}
              key={`content-${index}`}
              onClick={() => {
                setSelected(id === selected ? null : id)
              }}
              removeHover={removeHover}
            >
              {item.map((subItem: any, subIndex: number) => {
                if(subItem.hide) return null;
                return (<Td
                  onClick={() => {
                    console.log(subItem)
                    if(onRowClick && !subItem.preventClick) onRowClick(index);
                  }}
                  key={`subitem-${index}-${subIndex}`}
                  priority={subItem.priority}
                  align={subItem.align}
                  width={subItem.width}
                  system={subItem.system}
                  colSpan={subItem.colspan || 1}
                >
                  {getColContent(subItem.text, subItem.skipTranslate, formatMessage)}
                </Td>)
              }).filter((item: any) => item)}
            </ContentTr>
            {id === selected && item.map((subItem: any, subIndex: number) => {
              if(subItem.text && subItem.priority)
                return(
                  <HiddenTr key={`hidden-${subIndex}`} priority={subItem.priority}>
                    <Td colSpan={99}>
                      {getColContent(subItem.text, subItem.skipTranslate, formatMessage)}
                    </Td>
                  </HiddenTr>
                )
              return null;
              }
            ).filter((item:any) => item)}
            <SpacedTr key={`spacedtr-${index}`}>
              <SpacedTd key={`spacedtd-${index}`}/>
            </SpacedTr>
          </React.Fragment>
        )
      }})}
      </tbody>
    </Table>
  )
});

const getColContent = (text: string | React.ReactElement, skipTranslate: boolean, formatMessage:(message: MessageDescriptor) => string) => {
  if(text) {
    if (typeof (text) === "string" && !skipTranslate) {
      return formatMessage({id: text, defaultMessage: text});
    }
    return text;
  }
  return "";
}

export default List;
