
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as Routines from '../Routines';
import * as Api from '../api';
import { AppAction } from '../../../DefaultSettings/Store/types';
import { updateDeviceAndModifiedTime } from '../../utils';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import { getLastModifiedTime, getDevices } from '../selectors';
import { LabelEnum } from '../../LabelEnum';
import { selectIsPrivate } from '@Safemate/Store/selectors';
import { IDevice } from '@Safemate/Model/Device';

export interface UpdateDevice{
  deviceId: number;
}

export interface UpdateNotes{
  device: IDevice;
  devices: IDevice[];
  noteExists: boolean;
}

function* updateDevice(action: AppAction<UpdateDevice>){
  try{
    let modTime: number = yield select(getLastModifiedTime);
    const params: UpdateDevice = action.payload;

    const isPrivate: boolean = yield select(selectIsPrivate);

    // If we query for a single device we set last modified time to 0
    const devices: IDevice[] = yield call(Api.getDevices, params.deviceId, params.deviceId === 0 ? modTime : 0, isPrivate);
    const oldDevices: IDevice[] = yield select(getDevices);
    const mergedDevices = oldDevices.filter((device: IDevice) => {
      return !devices.find((incomingDevice: IDevice) => device.deviId === incomingDevice.deviId);
    }).concat(devices);
    
    const { alarms, lastModifiedTime } = updateDeviceAndModifiedTime(mergedDevices, modTime);
    
    for(const alarmDevice of alarms){
      if(alarmDevice.alarmDate && (new Date().getTime() - alarmDevice.alarmDate < 15*60000))
        yield put(Routines.alarmAnalysis(alarmDevice.deviId));
    }

    yield put(Routines.updateDevice.success({devices: mergedDevices, lastModifiedTime}));
  }
  catch(e){
    yield put(Routines.updateDevice.failure());
  }
}

function* startTracking(action: AppAction<number>){
  try{ 
    yield call(Api.startTracking, action.payload);
    yield feedbackSuccess(LabelEnum.TRACKING_ON);
    yield put(Routines.startTracking.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.startTracking.failure());
  }
}

function* updateNotes(action: AppAction<UpdateNotes>){
  try{
    const deviceInfo: UpdateNotes = action.payload;
    const device = deviceInfo.device;
    const deviceList = deviceInfo.devices;
    const hasNote = deviceInfo.noteExists;

    for(const el of deviceList){
      if(el.deviId == device.deviId){
        el.hasNote = hasNote;
        break;
      }
    }

    yield put(Routines.updateNotes.success(deviceList));

  }catch(e){
    yield feedbackFailure(e);
    yield put(Routines.updateNotes.failure());
  }
}

function* deviceSaga(){
    yield takeLatest(Routines.updateDevice.TRIGGER, updateDevice);
    yield takeLatest(Routines.startTracking.TRIGGER, startTracking);
    yield takeLatest(Routines.updateNotes.TRIGGER, updateNotes);
}

export default deviceSaga;
