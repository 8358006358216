import {WrappedComponentProps, injectIntl} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import {DeviceMac, DeviceMacs} from "./types";
import {Checkbox} from "../../../Elements/CheckboxFormik";
import styled, {ThemeProps, withTheme} from "styled-components";
import InfoCircle from "../../../Elements/Icon/Icons/Safemate/outline/InfoCircle";
import {Col, Grid, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {
  SetOpenDevice,
  setOpenDevice,
  SetSelectedIndexes,
  setSelectedIndexes,
  SetActiveIndex,
  setActiveIndex,
  setDeviceMac, SetDeviceMac
} from "../Store/actions";
import Modal from "react-modal";
import {ITheme} from "../../../../themes/types";
import {formatDate} from "./utils";
import {default as Signal0Icon} from "../../../Elements/Icon/Icons/signal/Signal0";
import {default as Signal1Icon} from "../../../Elements/Icon/Icons/signal/Signal1";
import {default as Signal2Icon} from "../../../Elements/Icon/Icons/signal/Signal2";
import {default as Signal3Icon} from "../../../Elements/Icon/Icons/signal/Signal3";
import {default as Signal4Icon} from "../../../Elements/Icon/Icons/signal/Signal4";
import { AppState } from "@Safemate/Store/types";


const ListColWrapperLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;
const ListColWrapperRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  float: right;
`;

const ListCol = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
`;

const ListColRight = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  justify-content: right;
  margin-left: 5px;
`;

const ListColRightIcon = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  justify-content: right;
  margin-left: 5px;
  font-weight: bold;
  color: ${props => props.theme.colors.accent};
  svg{
    display:inline;
    fill: ${props => props.theme.colors.accent};
  }
  span{
    color: ${props => props.theme.colors.accent};
    padding-left: 5px;
    text-transform: uppercase;
  }
`;

export const Close = styled.span`
  float: right;
  font-size: 28px;
  min-height: 40px;
  margin-bottom: -60px;
  margin-top: -30px;
  margin-right: -15px;
  cursor: pointer;
`;

const RowInfoBox = styled(Row)`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  padding: 5px 0px 0px 0px;
  border-bottom: none)};
  margin-bottom: initial};
`;

export const NoFlexContent = styled(Content)`
  display: block;
`;

export const modalStyles = {
  content: {
    width: "400px",
    height: "fit-content",
    position: "unset",
    background: "",
    color: ""
  },
  overlay:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  }
}

interface DeviceContentProps extends WrappedComponentProps{
  device: DeviceMac;
  index: number;
  selectedIndexes: number[];
  activeIndex: number;
  setActiveIndex: SetActiveIndex;
  setSelectedIndexes: SetSelectedIndexes;
  setDeviceMac: SetDeviceMac;
}

const mapDeviceContentStateToProps = ({ settings: {indoor: { deviceMac, selectedIndexes, activeIndex }}}: AppState) => {
  return {
    selectedIndexes, activeIndex
  }
}

const mapDeviceContentDispatchToProps = {
  setSelectedIndexes, setActiveIndex, setDeviceMac
};

export const DeviceContent = connect(mapDeviceContentStateToProps, mapDeviceContentDispatchToProps)(injectIntl(
  withFormWrapper<DeviceMacs, DeviceContentProps>(({ intl: { formatMessage }, formik: { setFieldValue, values }, device, index, activeIndex, selectedIndexes,setActiveIndex, setSelectedIndexes, setDeviceMac}) => {

    return (
      <Row style={{cursor: 'pointer'}}
      >
        <Col xs={8} sm={9} md={9}>
          <ListColWrapperLeft>
            {
              device.deviceKnownMac.dekmId < 1 &&
              <ListCol style={{width: '30px', marginTop: '-5px'}}>
                <NoFlexContent>
                  <Checkbox
                    value={device.selected}
                    name={`devices.${index}.selected`}
                    id={`devices.${index}.selected`}
                    labelStyle={{ display: 'flex', alignItems: 'center' }}
                    circle={`true`}
                    onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                      const value = event.currentTarget.checked;
                      setFieldValue(`devices.${index}.selected`, value);
                      const indexes = [...selectedIndexes];
                      if(value){
                        indexes.push(index);
                        setSelectedIndexes(indexes);
                      }else{
                        const key = indexes.indexOf(index);
                        if( key >= 0 ){
                          indexes.splice(key, 1);
                          setSelectedIndexes(indexes);
                        }
                      }
                    }}
                  />
                </NoFlexContent>
              </ListCol>
            }
            <ListCol style={{width: 'auto', overflow: 'hidden', cursor: 'pointer'}}
                     onClick={() => {
                       values.devices.map((deviceMac, indexInner)=>{
                         setFieldValue(`devices.${indexInner}.visible`, false);
                       })
                       if(activeIndex===index){
                         setActiveIndex(-1);
                       }else{
                         setActiveIndex(index);
                       }
                       setFieldValue(`devices.${index}.visible`, !device.visible);
                       setDeviceMac(device);
                     }}
            >{device.name?device.name:device.macAddress}</ListCol>
            <ListCol style={{paddingLeft: '5px', cursor: 'pointer'}}>
              <InfoBox device={device} />
            </ListCol>
            <ListCol onClick={() => {
              values.devices.map((deviceMac, indexInner)=>{
                setFieldValue(`devices.${indexInner}.visible`, false);
              })
              if(activeIndex===index){
                setActiveIndex(-1);
              }else{
                setActiveIndex(index);
              }
              setFieldValue(`devices.${index}.visible`, !device.visible);
              setDeviceMac(device);
            }}
            style={{flexGrow: 1}}
            ><div></div></ListCol>
          </ListColWrapperLeft>
        </Col>
        <Col xs={4} sm={3} md={3}
             onClick={() => {
               values.devices.map((deviceMac, indexInner)=>{
                 setFieldValue(`devices.${indexInner}.visible`, false);
               })
               if(activeIndex===index){
                 setActiveIndex(-1);
               }else{
                 setActiveIndex(index);
               }
               setFieldValue(`devices.${index}.visible`, !device.visible);
               setDeviceMac(device);
             }}
        >
          <ListColWrapperRight>
            <ListColRight>
              {
                device.rssi<1 && device.rssi>=-50 &&
                <Signal4Icon size={`medium`} />
              }
              {
                device.rssi<-50 && device.rssi>=-60 &&
                <Signal3Icon size={`medium`} />
              }
              {
                device.rssi<-60 && device.rssi>=-67 &&
                <Signal2Icon size={`medium`} />
              }
              {
                device.rssi<-67 && device.rssi>=-70 &&
                <Signal1Icon size={`medium`} />
              }
              {
                device.rssi<-70&&
                <Signal0Icon size={`medium`} />
              }
            </ListColRight>

          </ListColWrapperRight>

        </Col>

      </Row>

    )
  })
));


interface InfoBoxProps extends WrappedComponentProps{
  device: DeviceMac;
  setOpenDevice: SetOpenDevice;
  openDevice: number;
}


const mapInfoBoxStateToProps = ({ settings: {indoor: { deviceMac, openDevice }}}: AppState) => {
  return {
    openDevice
  }
}

const mapInfoBoxDispatchToProps = {
  setOpenDevice
};
export const InfoBox = connect(mapInfoBoxStateToProps, mapInfoBoxDispatchToProps)
(injectIntl(
  withFormWrapper<DeviceMacs, InfoBoxProps>(({ intl: { formatMessage }, device, setOpenDevice, openDevice}) => {

    const setDevice = (open: number) => {
      setOpenDevice(open);
    };

    return (
      <React.Fragment>
        <InfoCircle
          tabIndex="0"
          onClick={()=>{setDevice(device.demaId)}}
          size="medium"
        />
        <StyledModal
          open={openDevice === device.demaId && openDevice !== 0}
        >
          <React.Fragment>
            <Close onClick={()=>{setDevice(0)}}>
              &times;
            </Close>
            <Grid fluid>
              <Row>
                <Col xs={12}>
                  <h4>{ device.name }</h4>
                </Col>
              </Row>
              <RowInfoBox>
                <Col xs={4}>{formatMessage({id: "locationDeviceKnownMac", defaultMessage: "Location"})}</Col>
                <Col xs={8}>{device.deviceKnownMac.location}</Col>
              </RowInfoBox>
              <RowInfoBox>
                <Col xs={4}>{formatMessage({id: "typeDeviceMac", defaultMessage: "Signal Type"})}</Col>
                <Col xs={8}>{device.type === 'W' ? formatMessage({id: "wifi", defaultMessage: "WiFi"}) : formatMessage({id: "bluetooth", defaultMessage: "Bluetooth"}) }</Col>
              </RowInfoBox>
              <RowInfoBox>
                <Col xs={4}>{formatMessage({id: "macAddressDeviceMac", defaultMessage: "Mac Address"})}</Col>
                <Col xs={8}>{device.macAddress}</Col>
              </RowInfoBox>
              <RowInfoBox>
                <Col xs={4}>{formatMessage({id: "latestTimestampDeviceMac", defaultMessage: "Last received"})}</Col>
                <Col xs={8}>{formatDate(device.latestTimestamp)}</Col>
              </RowInfoBox>
              <RowInfoBox>
                <Col xs={4}>{formatMessage({id: "latLngDeviceMac", defaultMessage: "Position"})}</Col>
                <Col xs={8}>{device.latLng}</Col>
              </RowInfoBox>
              <RowInfoBox>
                <Col xs={4}>{formatMessage({id: "addressDeviceKnownMac", defaultMessage: "Address"})}</Col>
                <Col xs={8}>{device.deviceKnownMac.address}</Col>
              </RowInfoBox>
            </Grid>
          </React.Fragment>
        </StyledModal>
      </React.Fragment>
    )
  })
));


interface StyledModalProps extends ThemeProps<ITheme>{
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const StyledModal = withTheme(({children, open, setOpen, theme}: React.PropsWithChildren<StyledModalProps>) => {

  const styles = modalStyles;
  styles.content.background = theme.colors.backgroundPrimary;
  styles.content.color = theme.colors.textPrimary;
  Modal.setAppElement("#react");
  return(
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      style={{...styles}}
    >
      {children}
    </Modal>
  )
})

