import styled from "styled-components";
import { Button } from "react-bootstrap";

export const FlexDiv = styled.div`
  display: flex;
`;

export const NewSpan = styled.span`
  margin-left: auto;
`;

export const DropdownSpan = styled.span`
  padding: 10px;
  width: 100%;
`;

export const FirstDropdown = styled(DropdownSpan)`
  padding-left: 0;
`;

export const SecondDropdown = styled.span`
  width: 100%;
`

export const ThirdDropdown = styled.span`
  width: 100%;
`

export const LastDropdown = styled(DropdownSpan)`
  padding-right: 0;
`;

export const RoleButton = styled(Button)<{needsMargin?: boolean}>`
  ${props => props.needsMargin && "margin-right: 10px"};
  margin-bottom: 5px;
`;
