import { setLoggedIn } from "@Safemate/Session/Store/actions";
import { Axios, AxiosError } from "axios";
import {Action, AnyAction, applyMiddleware, createStore, Dispatch, Reducer, Store} from "redux";
import createSagaMiddleware from 'redux-saga'

export function configureStore(reducer : Reducer<any>, ...saga : (() => IterableIterator<any>)[]){

  const sagaMiddleware = createSagaMiddleware();



  const store: Store<AnyAction> = createStore(
    reducer,
    {},
    applyMiddleware(sagaMiddleware, errorMiddleware)
  )
  saga.forEach(s => {
    sagaMiddleware.run(s);
  })

  return store;
}

interface AxiosData{
  details: string;
  errorCode: number;
  message: string;
}

const errorMiddleware = () => (next: any) => (action: any) => {

  if(action.error){
    if(action.payload instanceof AxiosError){
      const response = (action.payload as AxiosError<AxiosData>).response;
      if(response.status === 403){

        if(response.data.details === "Insufficient permissions"){
          location.href = "dashboard";
        }
        else{
          next(setLoggedIn(false));
        }
      }
    }
  }

  try{
    return next(action);
  }
  catch(error){
    return next({type: "ERROR", error});
  }
}