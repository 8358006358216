import * as React from "react";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { micAndSpeakerOptions, micVolumeOptions, dectSpeakerOptions, voicePromptVolumeOptions, lightModeOptions } from "./options";
import { ToggleWrap } from "@Elements/Form/ToggleWrap";
import { ComponentWrapper, StyledForm, ButtonContainer, Footer } from "../styled";
import { useIntl } from "react-intl";
import { DeviceComponents } from "@Safemate/Settings/tabDefinition";
import { SettingEnum as ComponentSettingEnum } from "../../../settingEnum";
import { DeviceHardwareEnum, isGx8 } from "@Safemate/DeviceHardwareEnum";
import { Formik } from "formik";
import SettingsHeader from "../../header";
import { CancelButton, SaveButton } from "../../../../DefaultSettings/Settings/styled";
import { saveSoundLight } from "../../../Store/routines";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { SoundLightSettings } from "@Safemate/Settings/types";
import { CheckWrap } from "@Elements/Form/CheckWrap";
import { SettingEnum } from "@Safemate/DefaultSettings/Settings/settingEnum";
import { AppState } from "@Safemate/Store/types";

interface LightSettingsProps{
  dehaId: number;
  deviId: number;
  soundLight: SoundLightSettings;
  saveSoundLight: Routine<ActionFunctionAny<Action<any>>>;
}

const LightSettings = ({ dehaId, deviId, soundLight, saveSoundLight }: LightSettingsProps) => {

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const { formatMessage } = useIntl();

  return(
    <Formik
      onSubmit={(values) => {
        saveSoundLight({
          deviceId: deviId,
          settings: values
        })
      }}
      initialValues={soundLight}
      enableReinitialize
    >
    {({ dirty, resetForm}) => 
      <StyledForm>
        <SettingsHeader 
          title={formatMessage({id: "light", defaultMessage: "Lys"})}
          tooltip="lightTabToolTipText"
          tooltipTitle={{
            id: "light",
            defaultMessage: "Lys"
          }}
        />
        <div>
          {components.has(ComponentSettingEnum.GX8_LIGHT) 
            && components.get(ComponentSettingEnum.GX8_LIGHT)?.map(comp => {
              return <ComponentWrapper>{comp}</ComponentWrapper>
          })}
        </div>
        <Footer>
          <ButtonContainer disabled={!dirty}>
            <CancelButton disabled={!dirty} onClick={() => resetForm(soundLight)}>
              {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
            </CancelButton>
            <SaveButton disabled={!dirty} type="submit">
              {formatMessage({id: "save", defaultMessage: "Lagre"})}
            </SaveButton>
          </ButtonContainer>
        </Footer>
      </StyledForm>}
    </Formik>
  )
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: { soundLight }}}: AppState) => {
  return {
    dehaId,
    deviId,
    soundLight
  }
}

const mapDispatchToProps = {
  saveSoundLight
}

export default connect(mapStateToProps, mapDispatchToProps)(LightSettings);

export const LightAlarmMode = DropdownWrap(
  SettingEnum.LIGHT_ALARM_MODE,
  {
    title: {id: "lightAlarmModeTitle", defaultMessage: "LED styrke alarm"},
    tooltip: "lightAlarmModeToolTipText",
    tooltipTitle: {
      id: "lightAlarmModeTitle",
      defaultMessage: "LED styrke alarm"
    },
    dataOptions: {
      options: lightModeOptions,
      skipMap: true
    },
    width: "250px"
  }
)

export const LightNormalMode = DropdownWrap(
  SettingEnum.LIGHT_NORMAL_MODE,
  {
    title: {id: "lightNormalModeTitle", defaultMessage: "LED styrke hvilemodus"},
    tooltip: "lightNormalModeToolTipText",
    tooltipTitle: {
      id: "lightNormalModeTitle",
      defaultMessage: "LED styrke hvilemodus"
    },
    dataOptions: {
      options: lightModeOptions,
      skipMap: true
    },
    width: "250px"
  }
)


export const HelpArriveIndicator =
  CheckWrap<SoundLightSettings>(
    SettingEnum.HELP_ARRIVE_INDICATOR,
    {
      label: {
        id: "soundHelpArriveIndicatorParagraphTitle",
        defaultMessage: "Hjelp ankommet indikator"
      },
      contents: true,
      tooltip: "helpArriveIndicatorToolTipText",
      tooltipTitle: {id: "soundHelpArriveIndicatorParagraphTitle", defaultMessage: "Hjelp ankommet indikator"}
    }
  )

export const CallbackIndicator =
  CheckWrap<SoundLightSettings>(
    SettingEnum.CALLBACK_INDICATOR,
    {
      label: {
        id: "soundCallbackIndicatorParagraphTitle",
        defaultMessage: "Tilbakeringing indikator"
      },
      contents: true,
      tooltip: "callbackIndicatorToolTipText",
      tooltipTitle: {id: "soundCallbackIndicatorParagraphTitle", defaultMessage: "Tilbakeringing indikator"}
    }
  )