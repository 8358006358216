import { combineReducers } from "redux";
import { RootState } from "../types";
import feedback from "../../../Feedback/reducer";
import initialization, { initializationState } from "./initialization";
import customer, { customerState } from "./customer";
import device, { deviceState } from "./device";

export default combineReducers<RootState>({
    feedback,
    initialization,
    customer,
    device
});

export const addSafemateDefaultState = {
    initialization: initializationState,
    device: deviceState,
    customer: customerState
}