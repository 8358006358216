import styled from 'styled-components';

export const Tr = styled.tr<{family?: boolean}>`
  border-left: ${props => props.theme.colors.border};
  border-right: ${props => props.theme.colors.border};
`;

export const Td = styled.td<{pointer?: boolean, name?: string, family?: boolean, width?: string, hideMobile?: boolean, mobileWidth: string}>`
  vertical-align: middle;
  text-align: center;
  background: ${props => props.family ? props.theme.eventSetupColors.family : props.theme.colors.backgroundPrimary};
  border-top: ${props => props.theme.colors.border};
  border-bottom: ${props => props.theme.colors.border};
  cursor: ${props => (props.pointer ? 'pointer' : 'auto')};

  ${props => props.width && `width: ${props.width};`}

  &:hover {
    background: ${props => {
      if(props.pointer){
        if(props.family){
          return props.theme.eventSetupColors.familyHover;
        }
        else{
          return props.theme.colors.backgroundSecondary;
        }
      }
      return "auto"
    }};
  }

  @media (max-width: 630px) {
    ${props => props.hideMobile && "display: none;"}
    ${props => props.mobileWidth && `width: ${props.mobileWidth};`}
  }

  @media (min-width: 600px) and (max-width: 992px) {
    padding: 4px;
    padding-right: ${props => (props.name === 'eventSetupTd' ? '8px' : '')};
    padding-left: ${props => (props.name === 'eventSetupTd' ? '8px' : '')};
  }

  @media (min-width: 992px) {
    padding: 8px;
  }

  @media (min-width: 1200px) {
    padding: 13px;
  }
`;

export const VisibleTd = styled(Td)`
  display: ${props => (props.visible ? 'table-cell' : 'none')};
`;

export const VisibleNameTd = styled(VisibleTd)`
  @media (max-width: 450px) {
    width: 100% !important;
  }
`;

export const CheckBoxWrapper = styled.td`
  span {
    transition: background 0.1s !important;
  }
`;

export const Th = styled.th<{width?: string, hideMobile?: boolean}>`
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;

  ${props => props.width && `width: ${props.width};`}

  @media (max-width: 630px) {
    ${props => props.hideMobile && "display: none;"}
  }

  @media (min-width: 600px) {
    padding: 4px;
  }

  @media (min-width: 992px) {
    padding: 8px;
  }

  @media (min-width: 1200px) {
    padding: 13px;
  }
`;

export const DaysTh = styled.th`
  text-align: center;
  padding: 5px;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin: 0 !important;
`;

export const B = styled.b<{selected?: boolean, last?: boolean}>`
  color: ${props =>
    props.selected
      ? props.theme.colors.accent
      : props.theme.colors.textPrimary};
  padding-right: ${props => (props.last ? '0px' : '5px')};
`;

export const Caret = styled.b<{down?: boolean, up?: boolean, size: number}>`
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: ${props => (props.down ? `${props.size}px dashed` : 'none')};
  border-right: ${props => `${props.size}px solid transparent`};
  border-left: ${props => `${props.size}px solid transparent`};
  border-bottom: ${props => (props.up ? `${props.size}px dashed` : 'none')};
  cursor: pointer;

  &:focus {
    outline: 1px dotted ${props => props.theme.colors.textPrimary};
  }
`;

export const TimeTable = styled.table`
  margin: 0 auto;

  th {
    padding: 5px;
    text-align: center;
  }

  td {
    font-size: 50px;
    padding: 5px;
  }
`;

export const SortWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowWrapper = styled.div`
  margin-left: auto;
`;