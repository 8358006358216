import styled from "styled-components";

export const Title = styled.h1`
  padding: 15px;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const TitleSmall = styled.h1`
  padding: 15px;
    @media only screen and (min-width: 601px) {
      display: none;
    }
`;

export const Frame = styled.div`
  border-radius: 4px;
  padding: 25px;
  ${props =>
  `
    background: ${props.theme.colors.backgroundPrimary};
    border: ${props.theme.colors.border};
  `
}
`;


export const SvgButton = styled.button`
  background: ${props => props.theme.colors.backgroundSecondary};
  border: 1px solid ${props => props.theme.colors.buttonBorder};
  border-radius: 4px;
  text-transform: uppercase;
  transition: 0.3s;
  padding: 0 20px;
  vertical-align: middle;
  outline: none;
  height: 41px;
  display: inline-flex;
  align-items: center;

  svg {
    &:hover {
      fill: ${props => props.theme.colors.backgroundSecondary};
    }
  }
  
  &:hover {
    background: ${props => props.theme.colors.accent};
    color: ${props => props.theme.colors.backgroundSecondary};

    svg {
      fill: ${props => props.theme.colors.backgroundSecondary};
    }
  }
`;