import * as React from "react";
import styled from "styled-components";

export const Heading = styled.div<{centered?: boolean, margin?: string}>`
  margin-bottom: ${props => props.margin || "40px"};
  display: flex;
  ${props => props.centered && "align-items: center;"}
`;

export const H1 = styled.h1`
  white-space: normal;
`;

export const Span = styled.span`
  display: flex;
`;

export const CapitalizedSpan = styled.span`

  margin: 0 5px;

  &::first-letter{
    text-transform: uppercase;
  }

`;

export const AdditionalHeader = styled.span`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

interface SafemateHeaderProps{
  icon: JSX.Element | null;
  title: string;
  centered?: boolean;
  margin?: string;
  postfix?: string;
}

const SafemateHeader = ({ icon, title, centered, margin, postfix, children }: React.PropsWithChildren<SafemateHeaderProps>) => 
  <Heading margin={margin} centered={centered}>
    <H1>
      <Span>
        {icon}
        <CapitalizedSpan>
          {title}
        </CapitalizedSpan>
        {postfix &&
        <AdditionalHeader>
          &nbsp;
          {postfix}
        </AdditionalHeader>}
      </Span>
    </H1>
    {children}
  </Heading>


export default SafemateHeader;