import {InitializationState} from "../types";
import {Reducer} from "redux";
import {initialize} from "../routines";

const initializationState: InitializationState = {
  initialized: false
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action) => {

  switch (action.type) {
    case initialize.SUCCESS:
      return {
        ...state,
        initialized: true
      };

    default:
      return state;
  }
};

export default initialization;
