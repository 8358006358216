import DeviceEventSetup from "../Model/DeviceEventSetup";
import { isEmailRecipient, isGsmRecipient } from "./utils";

export const isRecipientProvided = (desList: DeviceEventSetup[]) => {
  for (let i = 0; i < desList.length; i++) {
    if (!(desList[i].email || desList[i].phone)) {
      return false;
    }
    if (
      (isGsmRecipient(desList[i]) && !desList[i].phone) ||
      (desList[i].phone && desList[i].phone.length < 8)
    ) {
      return false;
    } else if (
      (isEmailRecipient(desList[i]) && !desList[i].email) ||
      (desList[i].email && desList[i].email.length < 3)
    ) {
      return false;
    }
  }
  return true;
};

export const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

interface ICoverage{
  [index: string]: ITimesCovered[];
}

export const checkCoverage = (desList: DeviceEventSetup[], pro: boolean) => {
  const coverage: ICoverage = {};
  days.forEach(data => {
    const tempArray = checkDay(desList, data, pro);
    if (tempArray.length > 0) {
      coverage[data] = tempArray;
    }
  });
  return coverage;
};

interface ITimesCovered{
  from: number;
  to: number;
}

export const checkDay = (desList: DeviceEventSetup[], day: string, pro: boolean): ITimesCovered[] => {
  const timesCovered: ITimesCovered[] = [];

  desList.forEach(data => {
    if (
      (data.phone || data.phone === '') &&
      (!pro || !data.familyMember) &&
      data.alarmWarnRecipient &&
      data[`warnOn${day}`]
    ) {
      if (data.warnTimeFrom > data.warnTimeTo && data.warnTimeTo !== 0) {
        timesCovered.push({ from: data.warnTimeFrom, to: 2400 });
        timesCovered.push({ from: 0, to: data.warnTimeTo });
      } else if (data.warnTimeTo === 0) {
        timesCovered.push({ from: data.warnTimeFrom, to: 2400 });
      } else {
        timesCovered.push({ from: data.warnTimeFrom, to: data.warnTimeTo });
      }
    }
  });

  timesCovered.sort((a, b) => {
    return a.from - b.from;
  });

  const timesNotCovered = [];

  if (timesCovered.length > 0) {
    if (timesCovered[0].from > 0) {
      timesNotCovered.push({ from: 0, to: timesCovered[0].from });
    }

    for (let i = 0; i < timesCovered.length; i++) {
      if (timesCovered[i].to === 0) {
        timesCovered[i].to = 2400;
      }

      if (timesCovered[i + 1]) {
        if (
          timesCovered[i].from <= timesCovered[i + 1].from &&
          timesCovered[i + 1].from <= timesCovered[i].to
        ) {
          timesCovered[i + 1].from = timesCovered[i].from;
          if (timesCovered[i].to > timesCovered[i + 1].to) {
            timesCovered[i + 1].to = timesCovered[i].to;
          }
        } else {
          timesNotCovered.push({
            from: timesCovered[i].to,
            to: timesCovered[i + 1].from
          });
        }
      } else if (timesCovered[i].to < 2400) {
        timesNotCovered.push({ from: timesCovered[i].to, to: 2400 });
      }
    }
  } else {
    timesNotCovered.push({ from: 0, to: 2400 });
  }
  return timesNotCovered;
};