import React from 'react';
import { connect } from 'formik';
import { Col } from 'react-bootstrap';

import { CheckBoxRow, Tooltip } from '../layoutComp';
import { deviceSettingsTooltips, generalSettingsTooltips as Tips } from '../tooltips';
import Switch from '../../../Elements/Switch';

import {
  CollapsedWrapper,
  TabHeader,
  GeneralSubHeader,
  CapitalizedString
} from '../WrapperStyles';
import { Role } from '../Store/types';
import { useIntl } from 'react-intl';
import { Collapse } from 'react-collapse';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FirstColumn = styled.div`
  width: 50%;
`;

const SecondColumn = styled.div`
  padding-left: 20px;
  border-left: ${props => props.theme.colors.border};
`;

const  GeneralSettings = connect<{}, Role>(({formik: { values, setFieldValue }}) => {

  const enabled = !!values.policies.generalSettings.rolePolicies.status
  const { formatMessage } = useIntl();

  return (
    <CollapsedWrapper>
      <TabHeader>
        <CapitalizedString>
          {formatMessage({id: 'generalSettingsRoleEditor', defaultMessage: 'General Settings'})}
        </CapitalizedString>
        <Switch
          checked={enabled}
          onChange={(value: boolean) => {
            setFieldValue("policies.generalSettings.rolePolicies.status", value);
          }}
        />
        &nbsp;
        <Tooltip
          id="userAdmin"
          tooltip={formatMessage({
            id: Tips.generalSettings,
            defaultMessage: Tips.generalSettingsContent
          })}
        />
      </TabHeader>
      <Collapse isOpened={enabled}>
        <ContentWrapper>
        <FirstColumn>
          <GeneralSubHeader>
            <CapitalizedString>
              {formatMessage({id: 'gx8Devices', defaultMessage: 'GX-8 Devices'})}:
            </CapitalizedString>
            <Tooltip
              id="gx8DevicesTip"
              tooltip={formatMessage({
                id: Tips.Gx8.header,
                defaultMessage: Tips.Gx8.header
              })}
            />
          </GeneralSubHeader>
          <CheckBoxRow
            values={values.policies.Gx8LightSound.rolePolicies.status}
            name="policies.Gx8LightSound.rolePolicies.status"
            label={formatMessage({id: 'lightSound', defaultMessage: 'Light And Sound'})}
            tooltip={formatMessage({id: Tips.Gx8.lightSound, defaultMessage: Tips.Gx8.lightSound})}
          />
          <CheckBoxRow
            values={values.policies.Gx8Battery.rolePolicies.status}
            name="policies.Gx8Battery.rolePolicies.status"
            label={formatMessage({id: 'battery', defaultMessage: 'Battery'})}
            tooltip={formatMessage({id: Tips.Gx8.battery, defaultMessage: Tips.Gx8.battery})}
          />
          <CheckBoxRow
            values={values.policies.Gx8LogsReport.rolePolicies.status}
            name="policies.Gx8LogsReport.rolePolicies.status"
            label={formatMessage({
              id: 'logsReportsTextToCheckbox',
              defaultMessage: 'Logs and Reports'
            })}
            tooltip={formatMessage({id: Tips.Gx8.logsReport, defaultMessage: Tips.Gx8.logsReport})}
          />
          <CheckBoxRow
            values={values.policies.Gx8General.rolePolicies.status}
            name="policies.Gx8General.rolePolicies.status"
            label={formatMessage({id: 'general', defaultMessage: 'General'})}
            tooltip={formatMessage({id: Tips.Gx8.general, defaultMessage: Tips.Gx8.general})}
          />
          <CheckBoxRow
            values={values.policies.Gx8Communication.rolePolicies.status}
            name="policies.Gx8Communication.rolePolicies.status"
            label={formatMessage({id: 'communication', defaultMessage: 'Communication'})}
            tooltip={formatMessage({
              id: Tips.Gx8.communication,
              defaultMessage: Tips.Gx8.communication
            })}
          />
          <CheckBoxRow
            values={values.policies.Gx8Information.rolePolicies.status}
            name="policies.Gx8Information.rolePolicies.status"
            label={formatMessage({id: 'informationText', defaultMessage: 'Information'})}
            tooltip={formatMessage({
              id: Tips.Gx8.information,
              defaultMessage: Tips.Gx8.information
            })}
          />
        </FirstColumn>
        <SecondColumn>
          <GeneralSubHeader>
            <CapitalizedString>
              {formatMessage({id: 'safemateDevices', defaultMessage: 'Safemates'})}:
            </CapitalizedString>
            <Tooltip
              id="safemateDevicesTip"
              tooltip={formatMessage({
                id: Tips.safemate.header,
                defaultMessage: Tips.safemate.header
              })}
            />
          </GeneralSubHeader>
          <CheckBoxRow
            values={values.policies.safemateGeneral.rolePolicies.status}
            name="policies.safemateGeneral.rolePolicies.status"
            label={formatMessage({id: 'general', defaultMessage: 'General'})}
            tooltip={formatMessage({
              id: Tips.safemate.general,
              defaultMessage: Tips.safemate.general
            })}
          />
          <CheckBoxRow
            values={values.policies.safemateDeviceLogs.rolePolicies.status}
            name="policies.safemateDeviceLogs.rolePolicies.status"
            label={formatMessage({id: 'deviceLogs', defaultMessage: 'Device Logs'})}
            tooltip={formatMessage({
              id: Tips.safemate.deviceLogs,
              defaultMessage: Tips.safemate.deviceLogs
            })}
          />
          <CheckBoxRow
            values={values.policies.safemateDeleteData.rolePolicies.status}
            name="policies.safemateDeleteData.rolePolicies.status"
            label={formatMessage({id: 'deleteDeviceData', defaultMessage: 'Delete Device Data'})}
            tooltip={formatMessage({
              id: Tips.safemate.deletedDeviceData,
              defaultMessage: Tips.safemate.deletedDeviceData
            })}
          />
          <CheckBoxRow
            values={
              values.policies.safemateBatteryThreshhold.rolePolicies.status
            }
            name="policies.safemateBatteryThreshhold.rolePolicies.status"
            label={formatMessage({id: 'batteryTreshold', defaultMessage: 'Battery Threshold'})}
            tooltip={formatMessage({
              id: Tips.safemate.batteryThreshold,
              defaultMessage: Tips.safemate.batteryThreshold
            })}
          />
          <CheckBoxRow
            values={values.policies.deviceLightSound.rolePolicies.status}
            name="policies.deviceLightSound.rolePolicies.status"
            label={formatMessage({id: 'lightSound', defaultMessage: 'Light And Sound'})}
            tooltip={formatMessage({id: deviceSettingsTooltips.lightSound, defaultMessage: deviceSettingsTooltips.lightSoundContent})}
          />
          <CheckBoxRow
            values={values.policies.safemateAlarm.rolePolicies.status}
            name="policies.safemateAlarm.rolePolicies.status"
            label={formatMessage({id: 'alarm', defaultMessage: 'Alarm'})}
            tooltip={formatMessage({id: Tips.safemate.alarm, defaultMessage: Tips.safemate.alarm})}
          />
          <CheckBoxRow
            values={values.policies.safemateManDown.rolePolicies.status}
            name="policies.safemateManDown.rolePolicies.status"
            label={formatMessage({id: 'manDown', defaultMessage: 'Man down'})}
            tooltip={formatMessage({id: Tips.safemate.alarm, defaultMessage: Tips.safemate.alarm})}
          />
        </SecondColumn>
        </ContentWrapper>
      </Collapse>
    </CollapsedWrapper>
  );
});

export default GeneralSettings;
