import _axios, {AxiosInstance} from 'axios';
import { UserForm } from '../types';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getUser = async () => {
  const url: string = `/user/me`;
  const response = await axios.get(url);
  return response.data;  
};

export const getUserRoleWithCustomerName = async () => {
  const url: string = `/user/me/roles`;
  const response = await axios.get(url);
  return response.data;
};

export const getAllowDelete  = async () => {
  const url: string = `/user/allowDelete`;
  const response = await axios.get(url);
  return response.data;
}

export const getTfaRules  = async () => {
  const url: string = `/userroles/getTfaRules`;
  const response = await axios.get(url);
  return response.data;
}

export const getPrivate = async () => {
  const url: string = `/user/private`;
  const response = await axios.get(url);
  return response.data;
};

export const saveUser = async (userForm: UserForm) => {
  const url: string = `/user/me/${userForm.applyToAll}`;
  const response = await axios.post(url, userForm);
  return response.data;
}

export const setStyle = async (safemateDark: boolean) => {
  const url: string = `/user/setStyle/${safemateDark}`;
  const response = await axios.get(url);
  return response.data;
}

export const deleteUser = async () => {
  const url: string = `/user/me`;
  const response = await axios.delete(url);
  return response.data;
}

export const sendGdprEmail = async () => {
  const url: string = `/user/sendEmailGdpr`;
  const response = await axios.get(url);
  return response.data;
};

export const getCountries = async () => {
  const url: string = `/language`;
  const response = await axios.get(url);
  return response.data;  
};

export const getTextsMyPage = async () => {
  const url: string = `/text/loggedIn`;
  const response = await axios.get(url);
  return response.data;  
};
