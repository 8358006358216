export interface LoginResponse{
  feedback: string;
  error: boolean;
  showTfa?: boolean;
}

export const parseAuthException = (authException: string, formatMessage: Function): LoginResponse => {

  let remainingTries = 0;

  if(authException === "TFA"){
    return {
      showTfa: true,
      error: false,
      feedback: formatMessage({id: "tfaInfo", defaultMessage: "En SMS har blitt sendt til ditt telefonnummer med to-faktor-kode. Skriv inn koden under."})
    }
  }

  if(authException === "VALIDATE_ACCOUNT"){
    return {
      showTfa: false,
      error: true,
      feedback: formatMessage({id: "validateAccount", defaultMessage: "Please validate your account. (Check your e-mail)"})
    }
  }

  if(authException === "TFA_EMAIL"){
    return {
      showTfa: true,
      error: false,
      feedback: formatMessage({id: "tfaInfoEmail", defaultMessage: "Du har blitt tilsendt en epost med to-faktor-kode. Skriv inn koden under."})
    }
  }

  if(authException === "DISABLED"){
    return {
      error: true,
      feedback: formatMessage({id: "userDisabled", defaultMessage: "User is disabled"})
    }
  }

  if(authException.startsWith("IP_FILTER")){
    return {
      error: false,
      feedback: `${formatMessage({id: "ipFilterFailed", defaultMessage: "ipFilterFailed"})} ${authException.split("-")[1]}`
    }
  }

  if(authException.startsWith("TFA_FAILED")){
    remainingTries = parseInt(authException.split('-')[1]);
    if (remainingTries > 0) {
      return {
        error: true,
        feedback: `${formatMessage({id: 'tfaFailed', defaultMessage: "tfaFailed"})} ${formatMessage({id: 'remainingTries', defaultMessage: "remainingTries"})} ${remainingTries}`
      }
    } else {
      return {
        error: true,
        feedback: formatMessage({id: "accountBlocked", defaultMessage: "accountBlocked"})
      }
    }
  }

  remainingTries = parseInt(authException);
  if (remainingTries > 0) {
    return{
      error: true,
      feedback: `${formatMessage({id: "ERR022", defaultMessage: "ERR022"})} ${formatMessage({id: "remainingTries", defaultMessage: "remainingTries"})} ${remainingTries}`
    }
  } else {
    return {
      error: true,
      feedback: formatMessage({id: "accountBlocked", defaultMessage: "accountBlocked"})
    }
  }
}
