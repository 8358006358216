import { AppState } from "@Safemate/Store/types";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { setRegisterStep, SetRegisterStep } from "../Store/actions";
import { connect } from "react-redux";
import { ConfirmMunicipality, ConfirmPrivate, RegisterPrivate } from "./private";
import { RegisterFirmAddress, RegisterFirmDetails } from "./firm";

export enum RegisterStep{
  INDEX,
  PRIVATE,
  PRIVATE_CONFIRM,
  FIRM_ADDRESS,
  FIRM_DETAILS,
  MUNICIPALITY_CONFIRM
}

interface RegisterProps{
  registerStep: RegisterStep;
}

const mapStateToProps = ({ session: { registerStep } }: AppState) => {
  return {
    registerStep
  }
}

const Register = connect(mapStateToProps)(({registerStep}: RegisterProps) => {

  const Comp = React.useMemo(() => {
    switch(registerStep){
      case RegisterStep.PRIVATE:
        return <RegisterPrivate/>
      case RegisterStep.PRIVATE_CONFIRM:
        return <ConfirmPrivate/>
      case RegisterStep.FIRM_ADDRESS:
        return <RegisterFirmAddress/>
      case RegisterStep.FIRM_DETAILS:
        return <RegisterFirmDetails/>
      case RegisterStep.MUNICIPALITY_CONFIRM:
        return <ConfirmMunicipality/>

      default:
        return <RegisterIndex/>
    }
  }, [registerStep])

  return(
    <Formik
      onSubmit={(values) => {}}
      initialValues={{}}
    >
      <Form>
        {Comp}
      </Form>
    </Formik>
  )
})

export interface RegisterStepProps{
  setRegisterStep: SetRegisterStep;
}

export const mapDispatchToRegisterStepProps = {
  setRegisterStep
}

const RegisterIndex = connect(null, mapDispatchToRegisterStepProps)(({setRegisterStep}: RegisterStepProps) => {

  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      <h4>{formatMessage({id: "selectDomain", defaultMessage: "Please select domain"})}</h4>
      <Button onClick={() => setRegisterStep(RegisterStep.PRIVATE)} type="button">
        {formatMessage({id: "privatkundeGo", defaultMessage: "Register private"})}
      </Button>
      <Button onClick={() => setRegisterStep(RegisterStep.FIRM_ADDRESS)} type="button">
        {formatMessage({id: "T232", defaultMessage: "Register firm"})}
      </Button>
    </React.Fragment>
  )
})

export default Register;