import * as React from "react";
import {  useIntl,  } from "react-intl";
import { Row } from "react-bootstrap";
import { Field } from "formik";

import withFormWrapper from "@Elements/Form/formWrapper";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { Option } from "@Elements/Dropdown/types";
import { populateAvailableTimes } from "@Safemate/Settings/Geofence/utils";
import { Collapse } from "react-collapse";
import styled from "styled-components";
import {GeofencesIndexProps, PaddedCol} from "@Safemate/DefaultSettings/Settings/Geofence";
import Toggle from "@Elements/Switch/Toggle";
import { GeofenceSettings } from "../../types";
import { Header } from "../styles";


const StyledField = styled(Field)`
  width: 100%;
`;

const TimeControls = withFormWrapper<GeofenceSettings, GeofencesIndexProps>(({ formik: { setFieldValue, values: { geofences } }, geofencesIndex }) => {

  const { formatMessage } = useIntl();

  return(
    <Collapse isOpened={!!geofences[geofencesIndex].timeToggled}>
      <div>
        <Row>
          <PaddedCol md={4} sm={6}>
            <Header>{formatMessage({id: "from", defaultMessage: "Fra"})}</Header>
            <Dropdown
              options={populateAvailableTimes()}
              initial={geofences[geofencesIndex].enabledTimeFrom}
              onChange={(opt) => {
                const { value } = opt as Option;
                setFieldValue(`geofences.${geofencesIndex}.enabledTimeFrom`, value);
              }}
            />
          </PaddedCol>
          <PaddedCol md={4} sm={6}>
            <Header>{formatMessage({id: "to", defaultMessage: "Til"})}</Header>
            <Dropdown
              options={populateAvailableTimes()}
              initial={geofences[geofencesIndex].enabledTimeTo}
              onChange={(opt) => {
                const { value } = opt as Option;
                setFieldValue(`geofences.${geofencesIndex}.enabledTimeTo`, value);
              }}
            />
          </PaddedCol>
          <PaddedCol md={4} sm={6} xs={12}>
            <Header>{formatMessage({id: "geofenceDelayInMinutes", defaultMessage: "Delay alert in minutes"})}</Header>
            <StyledField style={{height: "42px"}} type="text" name={`geofences.${geofencesIndex}.delayMinutes`} />
          </PaddedCol>
          <PaddedCol md={4} sm={6} xs={12} >
            <Header>{formatMessage({id: "geofenceAlertByCallLabel", defaultMessage: "Alert by call"})}</Header>
            <div style={{margin: "4px 0px"}}>
              <Toggle
                      onChange={(value: boolean) => {
                        setFieldValue(`geofences.${geofencesIndex}.alertByCall`, value);
                      }}
                      checked={geofences[geofencesIndex].alertByCall}
              />
            </div>
          </PaddedCol>
        </Row>
      </div>
    </Collapse>
  )
})

export default TimeControls;
