import * as React from "react";
import { useIntl } from "react-intl";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { connect } from "react-redux";
import styled from "styled-components";

import { setLayout, SetLayout } from "./Store/actions";
import {initialize, initializeUser} from "./Store/routines";
import { RootState, Customer as CustomerType } from "./Store/types";
import Spinner from "../Spinner";
import { mapTabs } from "./tabs";
import { HorizontalRouteTabs, VerticalRouteTabs } from "../../Elements/Tabs";
import { getLayout, Layout } from "./LayoutEnum";
import SettingsHeader from "./header";
import Form from "./Form";



import {Col, Grid, Row} from "react-bootstrap";
import {Frame, Title, TitleSmall} from "../styles";
import { AppState } from "@Safemate/Store/types";


interface SettingsProps {
  init: Routine<ActionFunctionAny<Action<any>>>;
  customer: CustomerType;
  initializeUser: Routine<ActionFunctionAny<Action<any>>>;
  setLayout: SetLayout;
  initialized: boolean;
  layout: Layout;
}

const Wrap = styled.div`
  position: relative;
`;

const StyledColNoLRPad = styled(Col)`
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 25px;
`


const SettingsComp = ({ customer, initializeUser, initialized, layout, setLayout }: SettingsProps) => {

  const [ windowLayout, setWindowLayout ] = React.useState(getLayout(window.innerWidth));

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    initializeUser();
    //init({deviceId, roles});
    window.addEventListener("resize", resizeEvent);
    return () => {
      window.removeEventListener("resize", resizeEvent);
    }

  }, [])

  React.useEffect(() => {
    if(windowLayout !== layout)
      setLayout(windowLayout);
  }, [windowLayout])

  const resizeEvent = (event: UIEvent) => {
    const win: Window = event.currentTarget as Window;
    const newLayout = getLayout(win.innerWidth);
    setWindowLayout(newLayout);
  }

  
  const tabs = React.useMemo(() => {
    return mapTabs(formatMessage);
  }, []);

  if (!initialized)
  return (
    <Wrap>
      <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
    </Wrap>
    )

  return (
    <React.Fragment>
      <SettingsHeader/>
      <Row>
          <Col md={8}>
            <Frame>
              <Row>
                <Form/>
              </Row>
            </Frame>
          </Col>
        </Row>
          {layout === Layout.VERTICAL
          ? <VerticalRouteTabs>
            {tabs}
          </VerticalRouteTabs>
          :           
          <StyledColNoLRPad md={8}>
                          <Row>
            <HorizontalRouteTabs>
              {tabs}
            </HorizontalRouteTabs>    
            </Row>   
          </StyledColNoLRPad>
        }

    </React.Fragment>
  )
}

const mapStateToProps = ( {  partnerOrganization: {initialization: { initialized, layout,  }, customer: {selectedCustomer}}}: AppState) => {
  return{
    initialized,
    layout,
    customer: selectedCustomer,
  }
}

const mapDispatchToProps = {
  init: initialize,
  initializeUser: initializeUser,
  setLayout
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsComp);
