import styled from "styled-components";

export const ContentDiv = styled.div`
  width: 500px;
  padding: 60px 0px;
  max-height: 100%;
  overflow: auto;
  border-radius: 5px 5px 5px 5px;
`;

export const BackgroundDiv = styled.div`
  background: url(${require("../../../assets/images/login-background.png")}) top center no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  overflow-y: visible;
  padding-left: 0px;
`;