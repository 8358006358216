import _axios, {AxiosInstance, AxiosResponse} from 'axios';
import {OrganizationForm, PostalCityResult} from "../Form/types";
import { addRolesToRequest } from '@Safemate/utils';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export interface SaveSettings{
  formValues: OrganizationForm;
  pptyId: string;
}

export const getCustomersForList = async () => {
  const url: string = `/firm/trimmedsuborgunitswithwithrights${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;
}

export const getCustomersForForm = async () => {
  const url = `/firm/suborgunitswithdevicecountandrights${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;
}

export const getPaymentOptions = async (custId: number, parent: number) => {
  const url: string = `/paymentProfile/choices/${custId}/${parent}`;
  const response = await axios.get(url);
  return response.data;
}

export const deleteSubOrg = async (custId: number) => {
  const url: string = `/firm/${custId}`;
  const response = await axios.delete(url); 
  return response.data;
}

export const getSubUnits = async () => {
  const url = `/firm/suborgunitswithdevicecountandrights`;
  const response = await axios.get(url);
  return response.data;
}

export const saveForm = async ({pptyId, formValues}: SaveSettings) => {
  const url: string = `/firm/save/${pptyId}`;
  const response = await axios.post(url, formValues);
  return response.data;
}

export const getAlarmOptions = async (custId: number) => {
  const url = `/alarmHelpProvider/forcustomer/${custId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getPostCity = async (pnr: string) => {
  const url = `/firm/postcode/${pnr}`;
  const response: AxiosResponse<PostalCityResult> = await axios.get(url);
  return response.data;
}
