import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import reportSaga from "./report";

function* DashboardSaga(){
  yield all([
    fork(initializationSaga),
    fork(reportSaga),
  ])
}
export default DashboardSaga;
