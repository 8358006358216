import _axios, {AxiosInstance} from 'axios';
import { GetAuditLog } from './Saga/userAuditLog';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getUserAuditLog = async ({id, limit, evenId}: GetAuditLog) => {
  const url: string = `/eventLog/custuser/audit/${id}/${limit}/${evenId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getDeviceAuditLog = async ({id, limit, evenId}: GetAuditLog) => {
  const url: string = `/eventLog/device/audit/${id}/${limit}/${evenId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getDeviceCallLog = async ({id, limit}: GetAuditLog) => {
  const url: string = `/callLog/device/${id}/true`;
  const response = await axios.get(url);
  return response.data;
}

export const getUser = async (id: number) => {
  const url: string = `/user/${id}`;
  const response = await axios.get(url);
  return response.data;
}

export const getDevice = async (id: number) => {
  const url: string = `/device/${id}`;
  const response = await axios.get(url);
  return response.data;
}
