import {injectIntl, WrappedComponentProps} from "react-intl";
import  OrganisationIcon from "../../../../Elements/Icon/Icons/menu/SubUnits";
import * as React from "react";
import { connect } from "react-redux";
import { getCustomerView } from "../../Store/routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Routine } from "redux-saga-routines";
import confirmModal from "../../../Modals/confirmModal";
import { Col, Button, Row } from "react-bootstrap";


interface OrganisationProps extends WrappedComponentProps{
  customerId: number
  customerName: string;
  getCustomerView: Routine<ActionFunctionAny<Action<any>>>;

}

const mapOrganisationDispatchToProps = {
  getCustomerView
}


const Organisation = connect(null, mapOrganisationDispatchToProps)(injectIntl(({customerId, customerName, getCustomerView, intl: {formatMessage}}: OrganisationProps) => {
  const {Comp, func} = confirmModal();

  return(
    <Col style={{cursor: "pointer"}}>

      <OrganisationIcon
      tabIndex="0"
      title={formatMessage({id: "Organisasjon", defaultMessage: "Organisasjon"})}
      size="medium"
      onClick={(event: any) => {
        func(true);

    //    getCustomerView(customerId);
      //  event.stopPropagation();
      }}
      /*onKeyDown={(event: any) => {
        if (event.key === 'Enter') 
          location.hash = `user_audit_log/userId=${userId}`;
      }}*/
      inline
    />
      <Comp
        standardBtn
        title={`${formatMessage({id: "view", defaultMessage: "Utsikt"})}`}
        body={
          <p>
            <span>{`${formatMessage({id: "partnerRedirectPartOne", defaultMessage: "Du logges nå videre inn i "})}${customerName} ${formatMessage({id: "partnerRedirectPartTwo", defaultMessage: "sin Safemate Pro. For å returnere til Partnerportalen velg dette under menyen oppe til høyre."})}`}</span>
          </p>
        }
        confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
        confirmFunc={() => {
          getCustomerView({customerId});
          func(false);
        }}
      />
        </Col>

  )}
));

export default Organisation;
