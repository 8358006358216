import {call, put, all, takeLatest, take} from 'redux-saga/effects';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import * as Api from "../api";
import { Device } from '@Safemate/Settings/types';


function* getDevice(action: AppAction<number>){
  try{

    const deviId = action.payload;

    const device: Device = yield call(Api.getDevice, deviId);

    yield put(Routines.getCapabilities(device));
    yield put(Routines.getDefaultAhp(device));
    yield put(Routines.getDeviceEventSetups(device));
    yield put(Routines.getPolicies(device));

    yield put(Routines.getDevice.success(device));
  }
  catch(e){
    yield put(Routines.getDevice.failure());
    console.log(e)
  }
  yield put(Routines.getDevice.fulfill());
}

export interface UpdateName{
  deviceId: number;
  deviceName: string;
}

function* updateName(action: AppAction<UpdateName>){
  try{
    const {deviceId, deviceName} = action.payload;
    yield call(Api.updateName, deviceId, deviceName);

    yield put(Routines.updateName.success(deviceName));
    yield feedbackSuccess("deviceNameUpdated");
  }
  catch(e){
    yield put(Routines.updateName.failure());
    yield feedbackFailure(e);
  }
}

export interface UpdateOverride{
  deviceId: number;
  value: boolean;
}

function* updateOverride(action: AppAction<UpdateOverride>){
  try{
    const {deviceId, value} = action.payload;
    const response: boolean = yield call(Api.updateOverride, deviceId, value);

    yield put(Routines.updateOverride.success(value));
    yield put(Routines.getDeviceEventSetups.trigger(response));
    yield feedbackSuccess("deviceOverrideUpdated");
  }
  catch(e){
    console.log(e);
    yield put(Routines.updateOverride.failure());
    yield feedbackFailure(e);
  }
}

function* deviceSaga(){
    yield takeLatest(Routines.getDevice.TRIGGER, getDevice);
    yield takeLatest(Routines.updateName.TRIGGER, updateName);
    yield takeLatest(Routines.updateOverride.TRIGGER, updateOverride);
}

export default deviceSaga;
