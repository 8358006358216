import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";
import {AppAction} from "../../DefaultSettings/Store/types";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/INITIALIZE'
);

export const initializeUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/INITIALIZE_USER'
);

// Organisation
export const fetchOrganisation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/FETCH_ORGANISATION'
);


export const selectCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/SELECT_CUSTOMER'
);

export const initializeSubOrgs: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/INITIALIZE_SUB_ORGS'
);

export const initializeBillingOptions: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/INITIALIZE_BILLING_OPTIONS'
);

export const getPostCity: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_POST_CITY'
);

export const setBillingSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/SET_BILLING_SETTINGS'
);



export const initializeSubUnits: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/INITIALIZE_SUB_UNITS'
);

export const cancelSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/CANCEL_SETTINGS'
);

export const saveForm: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/SAVE_FORM'
);

export const getPayment: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_PAYMENT'
);

export const initializePaymentProfiles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/INITIALIZE_PAYMENT_PROFILE'
);

export const getUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_USER'
);
export const getTfaRules: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_TFA_RULES'
);
export const getAllowDelete: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_ALLOW_DELETE'
);
export const getPrivate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_PRIVATE'
);

export const getBillingSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_BILLING_SETTINGS'
);

export const getLifetimeCost: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_LIFETIME_COST'
);

export const getLifetimeMobile: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_LIFETIME_MOBILE'
);

export const getPaymentCurrency: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_PAYMENT_CURRENCY'
);

export const deleteSafemate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/DELETE_SAFEMATE'
);

// User
export const saveUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/SAVE_USER'
);
export const deleteUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/DELETE_USER'
);

export const sendGdprEmail: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/SEND_GDPR_EMAIL'
);

export const fetchInvoices: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/FETCH_INVOICES'
);

export const initializeInvoices: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/INITIALIZE_INVOICES'
);

// Country

export const getCountries: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@TextModule/GET_COUNTRIES'
);

export const getTextsMyPage: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/GET_TEXTS_MY_PAGE'
);

export const getMarketProgId: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PrivateMyBilling/GET_MARKET_PROG_ID'
);