import * as React from "react";
import {createIntl, createIntlCache, IntlProvider, MessageDescriptor, RawIntlProvider } from 'react-intl'
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";
import { getTexts, getCountries } from "./Store/routines";
import { Texts, FormattedTexts, TranslationEventBody } from "./Store/types";
import Overlay from "./Overlay";
import EditModal from "./EditModal";
import { Client, IMessage } from "@stomp/stompjs";
import { connectWebsocket } from "@Safemate/Websocket";
import { TranslationTypeEnum } from "./Store/TranslationTypeEnum";
import { addEmail, AddEmail, addSMS, AddSMS } from "./Store/actions";
import { AppState } from "@Safemate/Store/types";
import { ITexts } from "@Safemate/Model/Text";

interface TranslationProviderProps{
  userId: number;
  languageCode: string;
  translator: boolean;
  children: React.ReactNode;
  getTexts: Routine<ActionFunctionAny<Action<any>>>;
  getCountries: Routine<ActionFunctionAny<Action<any>>>;
  texts: ITexts;
  translatorTexts: Texts;
  addEmail: AddEmail;
  addSMS: AddSMS;
}

const TranslationProvider = ({children, userId, languageCode, translator, addEmail, addSMS, getTexts, getCountries, texts, translatorTexts}: TranslationProviderProps) => {

  const [ websocket, setWebsocket ] = React.useState<Client>();

  React.useEffect(() => {

    if(translator){
      getCountries();
      return () => {
        stopSocket();
      }
    }
  }, [])

  React.useEffect(() => {
    if(translator)
      getTexts(languageCode || "nb_no");
  }, [languageCode])

  React.useEffect(() => {
    if(translator)
      startSocket();
  }, [userId])

  if(!translator) return(
    <IntlProvider defaultLocale="nb-NO" locale="nb-NO" messages={texts}>
      {children}
    </IntlProvider>
  )

  const startSocket = () => {

    if(!userId && translator) return;

    if(websocket){
      websocket.deactivate();
    }

    const socket = connectWebsocket();

    if(socket.connected){
      socket.subscribe(`/topic/translation/${userId}`, (message: IMessage) => {
        handleTranslationEvent(message);
      });
    }
    else{
      socket.onConnect = (frame) => {
        socket.subscribe(`/topic/translation/${userId}`, (message: IMessage) => {
          handleTranslationEvent(message);
        });
      }
    }
    
    if(!socket.active){
      socket.activate();
    }

    socket.onDisconnect = () => {
      stop();
    }

    setWebsocket(socket);
  };

  const stopSocket = () => {
    if (websocket && websocket.active) {
      websocket.unsubscribe(`/topic/translation/${userId}`)
    }
  };


  const handleTranslationEvent = (message: IMessage) => {
    const body: TranslationEventBody = JSON.parse(message.body);
    switch(body.type){
      case TranslationTypeEnum.EMAIL:
        addEmail(body);
        break;
      case TranslationTypeEnum.SMS:
        addSMS(body);
        break;
    }
  }

  const cache = createIntlCache()

  const intl = createIntl({
    locale: 'en',
    messages: translatorTexts
  }, cache)

  const formattedTexts: FormattedTexts = {};

  const formatMsg = intl.formatMessage;
  // @ts-ignore
  intl.formatMessage = (message: MessageDescriptor) => {
    const { id, defaultMessage } = message;
    const text = formatMsg(message);
    if(id){
      formattedTexts[id] = {
        text,
        exist: !!translatorTexts[id]
      }
      if(websocket && websocket.connected){
        websocket.publish({destination: "/app/text-label", body: JSON.stringify({label: id, source: "REACT"})});
      }
    }
    return text;
  }
  return(
    <RawIntlProvider value={intl}>
      {children}
      <Overlay originalTexts={translatorTexts} texts={formattedTexts}/>
      <EditModal/>
    </RawIntlProvider>
  )
}


const mapStateToProps = ({ translationOverlay: {text: { texts: translatorTexts }}, appData: { user: { userId, languageCode, translator }, texts}}: AppState) => {
  return {
    texts,
    translatorTexts,
    userId,
    languageCode,
    translator
  }
}

const mapDispatchToProps = {
  getTexts,
  getCountries,
  addEmail,
  addSMS
}

export default connect(mapStateToProps, mapDispatchToProps)(TranslationProvider);
