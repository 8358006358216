import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

import { SettingEnum as ComponentSettingEnum } from "../../settingEnum";
import { DeviceComponents } from "../../tabDefinition";
import { DeviceHardwareEnum } from "../../../DeviceHardwareEnum";
import { StyledButton, ButtonWrapper } from "./styled";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";

const mapStateToProps = ({ settings: {initialization: { dehaId }}}: AppState) => {
  return{
    dehaId
  }
}

interface DeviceLogProps extends WrappedComponentProps{
  dehaId: number;
}

const DeviceLog = connect(mapStateToProps)(injectIntl(({ intl: { formatMessage }, dehaId }: DeviceLogProps) => {
 
  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;
  
  return(
    <div>
      <h2>{formatMessage({id: "deviceLogs", defaultMessage: "Enhetslogg"})}</h2>
      <div>
          {components.has(ComponentSettingEnum.DEVICE_LOG) 
            && components.get(ComponentSettingEnum.DEVICE_LOG)?.map(comp => 
                comp)}
        </div>
    </div>
  )
}))

export default DeviceLog;


interface DeviceLogBtnProps extends WrappedComponentProps{
  deviId: number;
}

const mapDeviIdStateToProps = ({settings: {device: {device: {deviId}}}}: AppState) => {
  return {
    deviId
  }
}

export const DeviceAuditLog = connect(mapDeviIdStateToProps)(
  injectIntl(({ intl: {formatMessage}, deviId }: DeviceLogBtnProps) => 
    <ButtonWrapper>
      <StyledButton
        onClick={() => {
          location.hash = `device_audit_log/${deviId}`
        }}
      >
        {formatMessage({id: "deviceAuditLog", defaultMessage: "Enhets auditlogg"})}
      </StyledButton>
      <Tooltip
        popoverId="device-audit-log-popover"
        labelId="tooltip_deviceAuditLog"
        defaultMessage="Trykk på denne knappen for å navigere til auditloggen for denne enheten."
      >
        <InformationIcon size="medium" />
      </Tooltip>
    </ButtonWrapper>
  )
)

export const DeviceCallLog = connect(mapDeviIdStateToProps)(
  injectIntl(({ intl: {formatMessage}, deviId }: DeviceLogBtnProps) => 
  <ButtonWrapper>
    <StyledButton
      onClick={() => {
        location.hash = `device_call_log/${deviId}`
      }}
    >
      {formatMessage({id: "deviceCallLogBtn", defaultMessage: "Enhets samtalelogg"})}
    </StyledButton>
    <Tooltip
      popoverId="device-call-log-popover"
      labelId="tooltip_deviceCallLog"
      defaultMessage="Samtaleloggen viser en oversikt over de samtaler som har blitt gjennomført med safematen og noen, med unntak av de tilfeller der noen har ringt direkte til Safemate."
    >
      <InformationIcon size="medium" />
    </Tooltip>
  </ButtonWrapper>
  )
)