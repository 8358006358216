import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import soundLightSaga from "./soundLight";
import organisationSaga from "./organisation";
import geofenceSaga from "./geofence";
import trackingSaga from "./tracking";
import generalSaga from "./general";
import infoSaga from "./info";
import indoorSaga from "./indoor";
import sensorSaga from "./sensor";
import walktestSaga from "./walktest";
import deviceSaga from "./device";
import indoorLocationSaga from "./indoorLocation";

function* SettingsSaga(){
  yield all([
    fork(initializationSaga),
    fork(soundLightSaga),
    fork(organisationSaga),
    fork(geofenceSaga),
    fork(trackingSaga),
    fork(generalSaga),
    fork(infoSaga),
    fork(indoorSaga),
    fork(indoorLocationSaga),
    fork(sensorSaga),
    fork(walktestSaga),
    fork(deviceSaga)
  ])
}
export default SettingsSaga;
