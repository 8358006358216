import * as React from "react";
import {Moment} from "moment";
import {Col, Row} from "react-bootstrap";

import {SubHeaderWrapper} from "./styles";
import {connect} from "formik";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {LabelEnum} from "../../LabelEnum";
import Datepicker from "@Elements/Datepicker";
import { useIntl } from "react-intl";

interface DateSelectorProps{
  disable: boolean
  values: DateValues;
}

interface DateValues{
  from: Moment;
  to: Moment;
  fromField: string;
  toField: string;
}

const DateSelector = connect<DateSelectorProps, UserWithCustomer>(({ formik: {setFieldValue}, disable, values}) => {

  const { formatMessage } = useIntl();

  return(
    <Row>
      <Col md={6}>
        <SubHeaderWrapper>
          {formatMessage({id: LabelEnum.DATE_FROM, defaultMessage: "Fra"})}
        </SubHeaderWrapper>
        <Datepicker
          disabled={disable}
          date={values.from}
          id={`${values.from}.from`}
          handleChange={(date: Moment | null) => {
            setFieldValue(values.fromField, date)
          }}
        />
      </Col>
      <Col md={6}>
        <SubHeaderWrapper>
          {formatMessage({id: LabelEnum.DATE_TO, defaultMessage: "Til"})}
        </SubHeaderWrapper>
        <Datepicker
          disabled={disable}
          date={values.to}
          id={`${values.to}.to`}
          handleChange={(date: Moment | null) => {
            setFieldValue(values.toField, date)
          }}
        />
      </Col>
    </Row>
  )
});

export default DateSelector;
