export enum UserAdminActionTypes {
  SET_DELETE_MODAL = "@@UserAdmin/SET_DELETE_MODAL",
  SET_EDIT = "@@UserAdmin/SET_EDIT",
  SET_NEW = "@@UserAdmin/SET_NEW",
  SELECT_LDAP = "@@UserAdmin/SELECT_LDAP",
  FORM_RESET = "@@UserAdmin/FORM_RESET",
  FILTER_SEARCH = "@@UserAdmin/FILTER_SEARCH",
  FILTER_CUSTOMER = "@@UserAdmin/FILTER_CUSTOMER",
  FILTER_ROLE = "@@UserAdmin/FILTER_ROLE",
  FILTER_STATUS = "@@UserAdmin/FILTER_STATUS",
  FILTER_ORDER = "@@UserAdmin/FILTER_ORDER",
  SEND_NEW_PASSWORD = "@@UserAdmin/SEND_NEW_PASSWORD"
}
