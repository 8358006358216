import * as React from "react";
import { StyledButton } from './index';
import { DeviceHardwareEnum } from '../../../DeviceHardwareEnum';
import { LabelEnum } from '../../LabelEnum';
import Settings from "../../../../Elements/Icon/Icons/settings/Settings";
import { useIntl } from "react-intl";

interface SettingsActionProps{
  deviceId: number
  deviceHardware: DeviceHardwareEnum;
}

const SettingsAction = ({deviceId, deviceHardware}: SettingsActionProps) =>{

  const { formatMessage } = useIntl();

  const settingsUrl = localStorage.getItem(deviceId+'settingsTab') || "";
  let url = `settings/${deviceId}/${deviceHardware}`;
  if(settingsUrl) url += settingsUrl;

  return(
    <StyledButton
      title={formatMessage({id: LabelEnum.SETTINGS, defaultMessage: "Enhetsinnstillinger"})}
      onClick={(event) => {
        event.stopPropagation();
        location.hash = url;
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') 
          location.hash = url;
      }}
    >
      <Settings
        size="medium"
        inline
      />
    </StyledButton>
  )
}

export default SettingsAction;
