export enum PartnerDashboardActionTypes {
  SET_DELETE_MODAL = "@@PartnerDashboard/SET_DELETE_MODAL",
  SET_EDIT = "@@PartnerDashboard/SET_EDIT",
  SET_NEW = "@@PartnerDashboard/SET_NEW",
  SELECT_LDAP = "@@PartnerDashboard/SELECT_LDAP",
  FORM_RESET = "@@PartnerDashboard/FORM_RESET",
  FILTER_SEARCH = "@@PartnerDashboard/FILTER_SEARCH",
  FILTER_CUSTOMER = "@@PartnerDashboard/FILTER_CUSTOMER",
  FILTER_ROLE = "@@PartnerDashboard/FILTER_ROLE",
  FILTER_STATUS = "@@PartnerDashboard/FILTER_STATUS",
  SEND_NEW_PASSWORD = "@@PartnerDashboard/SEND_NEW_PASSWORD",
  FILTER_ORDER = "@@PartnerDashboard/FILTER_ORDER"
}
