import React from 'react';
import SIcon from './SIcon';

const DectAlarmMobile = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M9.5 13.5c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm3-7.5v11c0 .8-.7 1.5-1.5 1.5H4c-.8 0-1.5-.7-1.5-1.5V6c0-.8.7-1.5 1.5-1.5h7c.8 0 1.5.7 1.5 1.5zm-1 0c0-.3-.2-.5-.5-.5H4c-.3 0-.5.2-.5.5v11c0 .3.2.5.5.5h7c.3 0 .5-.2.5-.5V6zm-.8-1.6c1.2-.7 2.1-.9 2.9-.6 1.5.7 2.1 2.9.9 5.3-.5 1-.4 1.9.1 2.2.4.3 1.1 0 1.7-.9 2-3.5 0-7.8-2.9-7.8-1.5.1-2.4.6-2.7 1.8zm-1.2.4c.3-2.1 1.6-3.2 3.8-3.2 3.6 0 6.1 5.2 3.7 9.3-.8 1.4-2.1 1.9-3.1 1.2-.9-.6-1.2-2.1-.5-3.5 1-2 .5-3.5-.4-4-.4-.2-1 0-2 .6v.2H9.4l.1-.6z" />
  </svg>
);

const Icon = props => <SIcon {...props} icon={DectAlarmMobile} />;

export default Icon;
