import * as React from "react";
import {connect} from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {Col, Grid, Row} from "react-bootstrap";

import {initialize} from "./Store/routines";
import Customer from "./Customer";
import Settings from "./Settings";
import styled from "styled-components";
import {injectIntl, WrappedComponentProps} from "react-intl";
import Spinner from "../Spinner";
import {Title} from "../styles";
import { AppState } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";

interface DefaultSettingsProps extends WrappedComponentProps {
  init: Routine<ActionFunctionAny<Action<any>>>;
  customer: ICustomerWithPolicy;
  initialized: boolean;
}

const Wrap = styled.div`
  position: relative;
`

// Outer component responsible for loading initial data.
const DefaultSettings = ({init, initialized, customer, intl: {formatMessage}}: DefaultSettingsProps) => {

  // On mount
  React.useEffect(() => {
    init();
  }, []);

  if(!initialized)
    return (
      <Wrap>
        <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
      </Wrap>
    )

  return(
    <Wrap>
      <Grid fluid>
        <Row>
          <Title>
            {formatMessage({id: "defaultSettingsIn", defaultMessage: "Standardinnstillinger i"})}
            &nbsp;
            {customer.customerName}
          </Title>
        </Row>
        <Row>
          <Col md={4}>
            <Customer/>
          </Col>
          <Col md={8}>
            <Settings/>
          </Col>
        </Row>
      </Grid>
    </Wrap>
  )
};

const mapStateToProps = ({defaultSettings: {customer: {selectedCustomer}, initialization: {initialized}}}: AppState) => {
  return{
    initialized,
    customer: selectedCustomer
  }
};

const mapDispatchToProps  = {
  init: initialize
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DefaultSettings));
