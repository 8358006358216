import * as React from "react";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { EventLog } from "@Safemate/Model/EventLog";
import { AppState } from "@Safemate/Store/types";
import { LogParams } from "..";
import { getUser, getUserAuditLog } from "../Store/routines";
import { Button, Grid, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { formatDate } from "@Safemate/utils";
import styled from "styled-components";
import { Option } from "@Elements/Dropdown/types";
import { Checkbox } from "@Elements/CheckboxFormik";
import { SetFilter, setFilter, ResetState, resetState } from "../Store/actions";
import { HeaderRow } from "../styles";

const UppercaseTd = styled.td`
  text-transform: uppercase;
`;

interface UserAuditLogProps{
  filteredUserAuditLog: EventLog[];
  userAuditLog: EventLog[];
  getUser: Routine<ActionFunctionAny<Action<any>>>;
  getUserAuditLog: Routine<ActionFunctionAny<Action<any>>>;
  filter: number[];
  setFilter: SetFilter;
  resetState: ResetState;
}

const mapStateToProps = ({ log: { initialization: { filter }, userAuditLog: { filteredUserAuditLog, userAuditLog } } }: AppState) => {
  return{
    filteredUserAuditLog,
    userAuditLog,
    filter
  }
}

const mapDispatchToProps = {
  getUser,
  getUserAuditLog,
  setFilter,
  resetState
}

export default connect(mapStateToProps, mapDispatchToProps)(({filteredUserAuditLog, userAuditLog, getUser, getUserAuditLog, resetState, setFilter, filter}: UserAuditLogProps) => {

  const { id } = useParams<LogParams>();
  const userId = parseInt(id);
  const [ evenId, setEvenId ] = React.useState<number>(0);
  const [ limit, setLimit ] = React.useState<number>(0);

  const { formatMessage } = useIntl();
  
  React.useEffect(() => {
    getUser(userId);

    return () => {
      resetState();
    }
  }, [])

  React.useEffect(() => {
    getUserAuditLog({id: userId, limit: 9, evenId})
  }, [evenId])

  const typeFilter = React.useMemo(() => {
    const filterList: number[] = [];
    const mappedFilterList: Option[] = [];
    mappedFilterList.push({
      text: "all",
      value: 0
    })
    userAuditLog.forEach(event => {
      if(!filterList.includes(event.evtyId)){
        filterList.push(event.evtyId);
        mappedFilterList.push({
          text: event.eventType.name,
          value: event.evtyId
        })
      }
    })
    return mappedFilterList;
  }, [userAuditLog])

  return(
    <Grid fluid>
      <HeaderRow>
        <Col md={12}>
          {typeFilter.map(item => {
            return(
              <Checkbox
                outerDiv={{marginRight: "7.5px"}}
                label={formatMessage({id: `et.${item.text}`, defaultMessage: item.text})}
                id={item.value}
                onChange={(e: React.BaseSyntheticEvent) => {
                  const value = parseInt(e.target.value);
                  if(!value){
                    setFilter([]);
                  }
                  else if(filter.includes(value)){
                    setFilter(filter.filter(item => item !== value));
                  }
                  else{
                    setFilter([...filter, value]);
                  }
                }}
                value={filter.includes(item.value) || (item.value === 0 && filter.length === 0)}

              />
            )
          })}
        </Col>
      </HeaderRow>
      <Row>
        <Col md={12}>
          <table className="customeOne-table table">
            <thead>
              <tr>
                <UppercaseTd>{formatMessage({id: "timestamp", defaultMessage: "Tid"})}</UppercaseTd>
                <UppercaseTd>{formatMessage({id: "serialNumber", defaultMessage: "Serienummer"})}</UppercaseTd>
                <UppercaseTd>{formatMessage({id: "deviceName", defaultMessage: "Enhetsnavn"})}</UppercaseTd>
                <UppercaseTd>{formatMessage({id: "action", defaultMessage: "Handling"})}</UppercaseTd>
              </tr>
            </thead>
            <tbody>
              {filteredUserAuditLog.map(entry => {
                return(
                  <tr>
                    <td>{formatDate(entry.createdTime, true, false, false, false, false, formatMessage)}</td>
                    <td>{entry.deviceSnNum}</td>
                    <td>{entry.deviceName}</td>
                    <td>
                      <span>{formatMessage({id: `et.${entry.eventType.name}`})}</span>
                      {entry.header && <span>{` - ${entry.header}`}</span>}
                      <br/>
                      {entry.description && <pre>{entry.description}</pre>}
                      {entry.remoteUser && <span>{entry.remoteUser}</span>}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row style={{textAlign: "center"}}>
        <Button disabled={limit > userAuditLog.length} onClick={() => {
          setEvenId(userAuditLog[(userAuditLog.length-1)].evenId);
          setLimit( limit + 9);
        }}>
          {formatMessage({id: "loadMore", defaultMessage: "Hent mer"})}
        </Button>
      </Row>
    </Grid>
  )
})
