import * as React from "react";
import Dashboard from "@Elements/Icon/Icons/menu/Dashboard";
import { GlobalRoles } from "@Safemate/GlobalRoles";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import Toolbox from "@Elements/Icon/Icons/menu/Toolbox";
import Users from "@Elements/Icon/Icons/menu/Users";
import PartnerDashboard from "@Elements/Icon/Icons/menu/PartnerDashboard";
import Billing from "@Elements/Icon/Icons/menu/Billing";
import Settings from "@Elements/Icon/Icons/menu/Settings";
import SubUnits from "@Elements/Icon/Icons/menu/SubUnits";
import AddSafemate from "@Elements/Icon/Icons/menu/AddSafemate";
import Shop from "@Elements/Icon/Icons/menu/Shop";
import Help from "@Elements/Icon/Icons/menu/Help";
import Person from "@Elements/Icon/Icons/menu/Person";
import SignOut from "@Elements/Icon/Icons/menu/SignOut";
import Map from "@Elements/Icon/Icons/menu/Map";
import AlarmOperator from "@Elements/Icon/Icons/menu/AlarmOperator";

export interface Menu{
  link?: string;
  externalLink?: string;
  name: string;
  label: string;
  icon: string | React.ReactNode;
  allowedGlobals?: GlobalRoles[];
  showIfRight?: PolicyNameEnum[];
  private?: boolean;
  restrictedUser?: boolean;
  partnerOnly?: boolean;
  privateOnly?: boolean;
  superAhp?: boolean;
  showByDefault?: boolean;
}

export const ahpMenuOperational: Menu[] = [
  {
    link: "/dashboard",
    name: "dashboard",
    label: "menu_dashboard",
    icon: <Dashboard overwriteSize={{height: "32px", width: "38px"}}/>
  },
  {
    link: "/eventview",
    name : 'eventview',
    label : 'menu_eventview',
    icon : <AlarmOperator overwriteSize={{height: "32px", width: "60.5px"}}/>
  },
  {
    link: "/safemates",
    name : 'safemates',
    label : 'menu_toolbox',
    icon : <Toolbox overwriteSize={{height: "32px", width: "60.5px"}}/>
  },
  {
    link: "/map",
    name: "map",
    label: "menu_map",
    icon: <Map overwriteSize={{height: "32px", width: "38px"}}/>
  }
]

export const ahpMenuSetup: Menu[] = [
  {
    link: "/users/list",
    name: "users",
    label: "menu_users",
    icon : <Users overwriteSize={{height: "32px", width: "38px"}}/>,
    superAhp: true
  }
]

export const menuOperational: Menu[] = [
  {
    link: "/dashboard",
    name : 'dashboard',
    label : 'menu_dashboard',
    icon : <Dashboard overwriteSize={{height: "32px", width: "38px"}}/>,
    allowedGlobals: [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM],
  }, 
  {
    link: "/safemates",
    name : 'safemates',
    label : 'menu_toolbox',
    icon : <Toolbox overwriteSize={{height: "32px", width: "60.5px"}}/>,
    allowedGlobals: [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.TRACKER, GlobalRoles.RESTRICTED_USER],
    private: true,
    restrictedUser: true,
    showByDefault: true,
  },
  {
    link: "/users/list",
    name : 'users',
    label : 'menu_users',
    icon : <Users overwriteSize={{height: "32px", width: "38px"}}/>,
    showIfRight: [PolicyNameEnum.USER_ADMIN],
    allowedGlobals: [GlobalRoles.SUPERFIRM],
    private: true
  }, 
  {
    link: "/partner_dashboard",
    name : 'partner_dashboard',
    label : 'menu_partner',
    icon : <PartnerDashboard overwriteSize={{height: "32px", width: "38px"}}/>,
    showIfRight: [PolicyNameEnum.PARTNER],
    allowedGlobals: [GlobalRoles.SUPERFIRM],
    partnerOnly: true
  }
]

export const menuSetup: Menu[] = [
  {
    link: "/partner_billing",
    name : 'partner_billing',
    label : 'menu_faktura',
    icon : <Billing overwriteSize={{height: "32px", width: "38px"}}/>,
    showIfRight: [PolicyNameEnum.PARTNER_INVOICES_MENU],
    allowedGlobals: [GlobalRoles.SUPERFIRM],
    partnerOnly: true
  },
  {
    link: "/default_settings",
    name : 'default_settings',
    label : 'menuDefaultSettings',
    icon : <Settings overwriteSize={{height: "32px", width: "38px"}}/>,
    showIfRight: [PolicyNameEnum.DEFAULT_SETTINGS],
    allowedGlobals: [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM],
  }, 
  {
    link: "/sub_units_react",
    name : 'sub_units_react',
    label : 'menu_sub_units',
    icon : <SubUnits overwriteSize={{height: "32px", width: "38px"}}/>,
    showIfRight: [PolicyNameEnum.SUB_ORGS],
    allowedGlobals: [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM],
  },
  {
    externalLink: 'https://shop.sensio.no/safemate/',
    name : 'shop',
    label : 'menu_shop',
    icon : <Shop overwriteSize={{height: "32px", width: "38px"}}/>,
    showIfRight: [PolicyNameEnum.SHOP_MENU],
    allowedGlobals: [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM],
    private: true,
  }, 
  {
    externalLink: "https://learn.sensio.no/?sfwd-courses=safemate-go",
    name : 'help',
    label : 'menu_help',
    icon : <Help overwriteSize={{height: "32px", width: "38px"}}/>,
    allowedGlobals: [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM],
    private: true,
    privateOnly: true
  }, 
  {
    link: "/my_page_private",
    name : 'my_page_private',
    label : 'menu_my_page',
    icon : <Person overwriteSize={{height: "32px", width: "38px"}}/>,
    allowedGlobals: [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.TRACKER],
    private: true,
    privateOnly: true,
  },
  {
    name : 'logout',
    label : 'logout',
    icon : <SignOut overwriteSize={{height: "32px", width: "38px"}}/>,
    allowedGlobals: [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.TRACKER],
    private: true,
    privateOnly: true,
  }
]
