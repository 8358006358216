import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import { ArrowWrapper, Caret, SortWrapper } from "./TableElementStyles";
import { EventSetupForm } from "./types";
import { SortEnum } from "./utils";

interface SequentialOrderProps{
  eventSetup: DeviceEventSetup;
  index: number;
  count: number;
}

const SequentialOrder = withFormWrapper<EventSetupForm, SequentialOrderProps>(({ eventSetup, index, count, formik: { values: { eventSetups }, setFieldValue}}) => {

  const editSort = (order: SortEnum) => {

    let swapTarget: DeviceEventSetup;
    let closestSort;

    for(let i = 0; i < eventSetups.length; i++){
      if(!eventSetups[i].email){
        let diff = 10;
        if(eventSetups[i].sort > eventSetup.sort && order === SortEnum.INCREMENT){
          diff = eventSetups[i].sort - eventSetup.sort;
          
        }
        else if(eventSetups[i].sort < eventSetup.sort && order === SortEnum.DECREMENT){
          diff = eventSetup.sort - eventSetups[i].sort;
        }
        if(!closestSort || diff < closestSort){
          closestSort = diff;
          swapTarget = eventSetups[i];
        }
      }
    }

    const updatedList = [
      ...eventSetups.filter(setup => {
        return setup.localId !== eventSetup.localId && setup.localId !== swapTarget.localId;
      }),
      {
        ...swapTarget,
        sort: eventSetup.sort
      },
      {
        ...eventSetup,
        sort: swapTarget.sort
      }
    ]

    setFieldValue("eventSetups", updatedList);
  }

  return(
    <SortWrapper>
      <div>{index} </div>
      <ArrowWrapper>
        {index !== 1 &&
        <div>
          <Caret
            tabIndex={0} id={`sort-up-${index}`} up size={6} onClick={() => editSort(SortEnum.DECREMENT)}
            onKeyDown={event => event.key === "Enter" && editSort(SortEnum.DECREMENT)}
          />
        </div>}
        {index < count && 
        <div>
          <Caret
            tabIndex={0} id={`sort-up-${index}`} down size={6} onClick={() => editSort(SortEnum.INCREMENT)}
            onKeyDown={event => event.key === "Enter" && editSort(SortEnum.INCREMENT)}
          />
        </div>}
      </ArrowWrapper>
    </SortWrapper>
  )
  
})

export default SequentialOrder;