import * as React from "react";
import { MessageDescriptor} from "react-intl";
import {Customer} from "../Models/Customer";
import Arrows from "./Actions";
import {OrgBilling} from "./Actions";
import {BillingGrouping, AllowDeviceDeletes} from "./Actions/switch";
import {Reference} from "./Actions/reference";
import styled from "styled-components";
import { CustomerName, DeviceCount, Prices } from "./ListElements";
import { ReactNode } from "react";

const UlStyled = styled.ul`
  max-width: 280px;
`;

export type MapMunicipalities = (
  customer: Array<Customer>,
  customerIdList: Array<number>,
  viewPrices: boolean
) => PartnerInfo;

export type Header = {
  text: string;
  priority?: number;
  align?: string;
  export?: boolean;
}

export type PartnerInfo = {
  partnerContent: Array<Array<RowContent>>;
  totalDevices: DeviceInterface;
}

export interface DeviceInterface{
  allDevices: number;
  homeDevices: number;
}

export type MapHeader = (formatMessage: (message: MessageDescriptor) => string, isAhp: boolean) => Header[];

export interface RowContent{
  text: string | React.ReactElement;
  priority?: number;
  className: string;
  skipTranslate: boolean;
  parent: number;
  viewPricesPolicy: boolean;
  priceSection: boolean;

}

export interface TableRow{
  expand: TableData;
  custId: TableData;
  customerName: TableData;
  orgNumber: TableData;
  prices: TableData;
  children: TableData;
  deviceCount: TableData;
  reference: TableData;
  billingGroup: TableData;
  allowDeviceDeletes: TableData;
  orgBilling: TableData;
}

export interface TableData{
  data: string | ReactNode;
  options: RowOptions;
}
export interface RowOptions{
  level?: number;
  managedCustomer?: boolean;
}

export enum TooltipOriention{
  UP,
  DOWN
}

const getPartnerRow = (customer: Customer, level: number, custTree: number[], tooltipOriention: TooltipOriention): TableRow => {
  return {
    expand: {data: customer.children.length > 0 ? <Arrows customer={customer} /> : null, options: {level}},
    custId: {data: customer.custId, options: {managedCustomer: customer.managedCustomer}}, 
    customerName: {data: <CustomerName tooltipOriention={tooltipOriention} customer={customer}/>, options: {}},
    orgNumber: {data: customer.orgNum, options: {}},
    prices: {data: customer.prices ? <Prices tooltipOriention={tooltipOriention} customer={customer}/> : null, options: {}},
    children: {data: customer.numberOfChildren, options: {}},
    deviceCount: {data: <DeviceCount tooltipOriention={tooltipOriention} customer={customer}/>, options: {}},
    reference: {data: <Reference reference={customer.reference} custId={customer.custId} custTree={custTree} />, options: {}},
    billingGroup: {data: <BillingGrouping individualBilling={customer.individualBilling} custId={customer.custId} custTree={custTree}/>, options: {}},
    allowDeviceDeletes: {data: <AllowDeviceDeletes allowDelete={customer.allowDeviceDelete} custId={customer.custId} custTree={custTree}/>, options: {}},
    orgBilling: {data: <OrgBilling customerName={customer.customerName} customerId={customer.custId} />, options: {}}
  }
}


export const mapPartners = (customers: Customer[], level: number, toggledChildren: number[], custTree: number[]): TableRow[] => {
  if(!customers) return;
  return customers.flatMap((customer, index) => {
    if(toggledChildren.includes(customer.custId)){
      return [
        getPartnerRow(customer, level, custTree, index < 5 ? TooltipOriention.DOWN : TooltipOriention.UP),
        ...mapPartners(customer.children, level + 1, toggledChildren, [...custTree, customer.custId])
      ]
    }
    else{
      return [ getPartnerRow(customer, level, custTree, index < 5 ? TooltipOriention.DOWN : TooltipOriention.UP) ];
    }
  });
}


export const makeShortSubOrgStr = (orgList: Array<Customer>, formatMessage: (message: MessageDescriptor) => string) => {
  var str = formatMessage({id: 'multiple...', defaultMessage: "Flere..."});
  if(orgList.length == 1) {
    str = orgList[0].customerName;
  }
  return str;
};

export const mapHeader: MapHeader = (formatMessage, viewPrices) => {
 const header: Header[] = [
  { text: ""},
  { text: formatMessage({id: "customerId", defaultMessage: "Kunde-id"})},
  { text: formatMessage({id: "name", defaultMessage: "Navn"}) },
  { text: formatMessage({id: "orgNr", defaultMessage: "Org.nr."})},
  viewPrices ? { text: formatMessage({id: "priceGroup", defaultMessage: "Prisgruppe"})}: null,
  { text: formatMessage({id: "Suborgs", defaultMessage: "Underorg."})},
  { text: formatMessage({id: "Devices", defaultMessage: "Enheter"})},
  { text: formatMessage({id: "Reference", defaultMessage: "Reference"})},
  { text: formatMessage({id: "Billing", defaultMessage: "Faktura"})},
  { text: formatMessage({id: "allowDeviceDeletes", defaultMessage: "Allow Device Deletes"})},
  { text: formatMessage({id: "View", defaultMessage: "Utsikt"})},]
  return header.filter(n => n !== null);
}

export const mapCSVHeader = (formatMessage: any, viewPrices: boolean) => {
  return[
    {value: formatMessage({id: "customerId", defaultMessage: "Customer Id"}).toUpperCase(), display: true},
    {value: formatMessage({id: "customerName", defaultMessage: "Customer Name"}).toUpperCase(), display: true},
    {value: formatMessage({id: "orgNum", defaultMessage: "Org. Number"}).toUpperCase(), display: true},
    {value: formatMessage({id: "stdPrice", defaultMessage: "Standard Price"}).toUpperCase(), display: viewPrices ? true : false},
    {value: formatMessage({id: "homePrice", defaultMessage: "Home Price"}).toUpperCase(), display: viewPrices ? true : false},
    {value: formatMessage({id: "twinPrice", defaultMessage: "twin Price"}).toUpperCase(), display: viewPrices ? true :false},
    {value: formatMessage({id: "noOfSuborgs", defaultMessage: "Number of Suborgs"}).toUpperCase(), display: true},
    {value: formatMessage({id: "stdDevices", defaultMessage: "Standard Devices"}).toUpperCase(), display: true},
    {value: formatMessage({id: "homeDevices", defaultMessage: "Home Devices"}).toUpperCase(), display: true},
    {value: formatMessage({id: "twinDevices", defaultMessage: "Twin Devices"}).toUpperCase(),  display: true},
    {value: formatMessage({id: "reference", defaultMessage: "Reference"}).toUpperCase(),  display: true},
    {value: formatMessage({id: "individualBilling", defaultMessage: "Individual billing"}).toUpperCase(),  display: true},
  ]
}

export enum ExportType{
  CSV,
  PDF
}


export const mapPartnerDashboard = (customer: Customer, viewPrices:boolean, formatMessage: any) => {
  return[
    {value: customer.custId, display: true, priority: 2},
    {value: customer.customerName, display: true, priority: 2},
    {value: customer.orgNum , display: true, priority: 2},
    {value: customer.prices ? customer.prices.safematePrice.toFixed(2).replace(".", ",") : "", display: viewPrices? true : false, priority: 2},
    {value:customer.prices ?  customer.prices.safemateHomePrice.toFixed(2).replace(".", ",") : "", display: viewPrices? true : false, priority: 2},
    {value: customer.prices ? customer.prices.safemateTwinPrice.toFixed(2).replace(".", ",") : "", display: viewPrices? true : false, priority: 2},
    {value: customer.numberOfChildren, display: true, priority: 2},
    {value: customer.numberOfDevices, display: true, priority: 2},
    {value: customer.numberOfSafemateHomes, display: true, priority: 2},
    {value: customer.numberOfSafemateTwin, display: true, priority: 2},
    {value: customer.reference, display: true, priority: 2},
    {value: customer.individualBilling ? "true" : "false", display: true, priority: 2},
  ]
}

export const getExportData = (type: ExportType, customers: Customer[], viewPrices: boolean, formatMessage: any) => {
  const exportData = [];
  const header = mapCSVHeader(formatMessage, viewPrices);
  exportData.push(header.map(item => {
    if(item.display){
      return item.value || ""
    }
  }).filter(item => item !== undefined));

  customers.forEach((customer) => {
    exportData.push(mapPartnerDashboard(customer, viewPrices, formatMessage).map(item => {
      if(item.display){
        if(item.value === 0){
          return 0;
        }
        return item.value || "";
      }
    }).filter(item => item !== undefined));
  })
  const results = exportData.filter(element => {
    return element.length > 0;
  })
  return results;
}

