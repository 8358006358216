import * as React from "react";

import {Tab} from '../../Elements/Tabs';
import { getMain, SettingToPolicyMap } from "./tabDefinition";
import {DeviceHardwareEnum, isGx8} from "../DeviceHardwareEnum";
import { Policies } from "../Policy/types";
import { accessToCustomer, checkPermissionsForCustomer } from "../Policy/rightsUtil";
import { GlobalRoles } from "../GlobalRoles";
import { Device } from "./types";
import { SettingEnum } from "./settingEnum";
import { Route } from "react-router-dom";
import Add from "./Sensor/Add";
import {StatusBar} from "@Safemate/Settings/General/Tabs/gx8/statusBar";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import LocalLearning from "./Sensor/Add/localLearning";
import { SensorDetails } from "./Sensor/sensorDetails";


export const SettingAllowGlobalRoles = [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM, GlobalRoles.TECHNICIAN, GlobalRoles.RESTRICTED_USER];
export const SettingAllowCustomerGlobalRoles = [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM];

type MapTabs = ( dehaId: DeviceHardwareEnum, formatMessage: Function, isAhp: boolean, isPrivate: boolean, device: Device, permissions: Map<PolicyNameEnum, AllPermissions>) => JSX.Element[];

export const mapTabs: MapTabs = ( dehaId, formatMessage, isAhp, isPrivate, device, permissions) => {

  const comps = getMain(dehaId, device, !!device.lastMsgIn, isAhp, isPrivate);
  
  const entries = comps.entries();
  let result = entries.next();
  const tabs: JSX.Element[] = [];


  while(!result.done){
    const [ menu, comp ] = result.value;
    const policyToCheck = SettingToPolicyMap.get(menu);
    if(policyToCheck && checkPermissionsForCustomer(permissions, policyToCheck, device.ownedByCustomer))
      tabs.push(
        <Tab name={menu} title={formatMessage({id: menu, defaultMessage: menu})}>
          <StatusBar/>
          {comp}
        </Tab>
      )
    result = entries.next();
  }
  return tabs;
}

type MapAdditionalRoutes = (dehaId: DeviceHardwareEnum, isAhp: boolean, isPrivate: boolean, beta: boolean, device: Device, baseUrl: string) => JSX.Element[]

export const mapAdditionalRoutes: MapAdditionalRoutes = (dehaId, isAhp, isPrivate, beta, device, baseUrl) => {

  const comps = getMain(dehaId, device, !!device.lastMsgIn, isAhp, isPrivate);

  if(comps.has(SettingEnum.SENSOR)){
    return [
      <Route exact path={`${baseUrl}/add`}>
        <LocalLearning returnPath={`${baseUrl}/sensor`}/>
      </Route>,
      <Route exact path={`${baseUrl}/info/sensor/:id`}>
        <SensorDetails returnPath={`${baseUrl}/sensor`}/>
      </Route>
    ]
  }
  return []
}
