import { combineReducers } from 'redux';

import initialization from "./initialization";
import { RootState } from '../types';
import device from './device';
import filter from './filter';
import alarmAnalysis from './alarmAnalysis';
import deviceInfo from './info';


export default combineReducers<RootState>({
  initialization,
  device,
  filter,
  alarmAnalysis,
  deviceInfo,
});
