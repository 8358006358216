import * as React from "react";
import styled from "styled-components";
import { injectIntl, WrappedComponentProps } from "react-intl";

import {Checkmark} from "../../RoleManager/layout";
import {faTimes} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Active = styled.div<{active: boolean, overOneYear: boolean}>`
  height: 18px;
  width: 18px;
  border-radius: 18px;
  background: ${props => (props.active ? '#64800c' : props.overOneYear ? `#cc0000` : '#a9adb0')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.3px;
  margin-left: 3px;
`;

const SsoLoggedIn = styled.div`
  background-color: transparent;
  border: 2.5px solid   ${props => props.theme.colors.textPrimary};
  height: 28px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 28px;
`;

interface ActiveProps extends WrappedComponentProps{
  isOnlineNow: boolean,
  overOneYearInactive: boolean,
  sso: boolean | undefined,
}

interface DeactivatedProps extends WrappedComponentProps{
  sso: boolean | undefined,
}


export default injectIntl(({isOnlineNow, overOneYearInactive, sso, intl: {formatMessage}}: ActiveProps) => {
  return(
    <div style={{display: "flex", justifyContent: "center"}}>    {sso ? <SsoLoggedIn>
      <Active
        active={isOnlineNow}
        overOneYear={overOneYearInactive}
        title={
          isOnlineNow
            ? formatMessage({id: "userOnline", defaultMessage: "Tilkoblet"})
            : formatMessage({id: "userOffline", defaultMessage: "Frakoblet"})
        }
      >
        {isOnlineNow && <Checkmark />}
      </Active>
      </SsoLoggedIn> : 
      <Active
        active={isOnlineNow}
        overOneYear={overOneYearInactive}
        title={
          isOnlineNow
            ? formatMessage({id: "userOnline", defaultMessage: "Tilkoblet"})
            : formatMessage({id: "userOffline", defaultMessage: "Frakoblet"})
        }
      >
        {isOnlineNow && <Checkmark />}
      </Active>}
    </div>
)});

const StyledDeactivated = styled.div<{title: string}>`
  margin: auto;
  width: 18px;
  height: 18px;
  background-color: #d02424;
  position: relative;
  border-radius: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  */ &:before,
  &:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 2px;
    background-color: black;
    top: 8px;
  }
  */ &:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 2px;
  }
  &:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 2px;
  }
`;

export const Deactivated = injectIntl(({intl: {formatMessage}}: WrappedComponentProps) =>
  <StyledDeactivated title={formatMessage({id: "deactivated", defaultMessage: "Deaktivert"})}>
    <FontAwesomeIcon icon={faTimes} color="white" />
  </StyledDeactivated>
)
