import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { connect } from "react-redux";
import styled from "styled-components";

import {
  cleanup,
  Cleanup,
  setLayout,
  SetLayout,
  SetSubscription,
  setWebsocketClient,
  SetWebsocketClient
} from "./Store/actions";
import {initialize, initializeUser} from "./Store/routines";
import { WebsocketState} from "./Store/types";
import Spinner from "../Spinner";
import { mapAdditionalRoutes, mapTabs } from "./tabs";
import { HorizontalRouteTabs, VerticalRouteTabs } from "../../Elements/Tabs";
import { getLayout, Layout } from "./LayoutEnum";
import { Device } from "./types";
import SettingsHeader from "./header";
import {User} from "../MyPage/Store/types";
import { Route, withRouter, RouteComponentProps } from "react-router-dom";
import {connectWebsocket} from "@Safemate/Websocket";
import { useIntl } from "react-intl";
import { Switch } from "react-router-dom";
import { AppState } from "@Safemate/Store/types";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";
import { useLocation, useParams, useRouteMatch } from "react-router";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import DeviceHeader, { StyledSpan } from "@Safemate/DeviceHeader";


interface SettingsProps extends RouteComponentProps{
  init: Routine<ActionFunctionAny<Action<any>>>;
  initializeUser: Routine<ActionFunctionAny<Action<any>>>;
  setLayout: SetLayout;
  device: Device;
  initialized: boolean;
  layout: Layout;
  user: User;
  cleanup: Cleanup;
  supportDeviceMac: boolean
  isPrivate: boolean;
  currentContext: SiteTypeEnum;
  setWebsocketClient: SetWebsocketClient;
  websocket: WebsocketState;
}

const Wrap = styled.div`
  position: relative;
`;

interface Params{
  id: string;
  dehaId: string;
}

const SettingsComp = ({ isPrivate, currentContext, init, initializeUser, user, device, websocket, setWebsocketClient, initialized, layout, setLayout, cleanup }: SettingsProps) => {

  const [ windowLayout, setWindowLayout ] = React.useState(getLayout(window.innerWidth));

  const { formatMessage } = useIntl();
  const { path, url } = useRouteMatch();
  const params = useParams<Params>();
  const dehaId = parseInt(params.dehaId);
  const deviId = parseInt(params.id);
  let location = useLocation();


  const isAhp = currentContext === SiteTypeEnum.AHP;


  React.useEffect(() => {

    if(websocket.client && !websocket.client.active){
      const socket = connectWebsocket();
      if(!socket.active){
        socket.activate();
      }
      setWebsocketClient(socket);
    } else {

    }
    if(websocket.client){
      if( !websocket.client.active ){
        websocket.client.activate();
      }
    } else {
      const socket = connectWebsocket();
      if(!socket.active){
        socket.activate();
      }
      setWebsocketClient(socket);
    }

    if(!initialized || device.deviId !== deviId){
      initializeUser();
      init({dehaId, deviceId: deviId});
    }
    window.addEventListener("resize", resizeEvent);


    return () => {
      cleanup();
      window.removeEventListener("resize", resizeEvent);
    }
  }, [])

  React.useEffect(() => {
    const settingsUrl = url;
    const subPath = settingsUrl.split(`/${deviId}/${dehaId}`);
    if(subPath.length > 1){
      localStorage.setItem(`${deviId}settingsTab`, subPath[1]);
    }
  }, [params, url])

  React.useEffect(() => {
    if(windowLayout !== layout)
      setLayout(windowLayout);
  }, [windowLayout])

  const resizeEvent = (event: UIEvent) => {
    const win: Window = event.currentTarget as Window;
    const newLayout = getLayout(win.innerWidth);
    setWindowLayout(newLayout);
  }

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const tabs = React.useMemo(() => {
    return mapTabs(dehaId, formatMessage, isAhp, isPrivate, device, permissions);
  }, [dehaId, device, user.beta]);

  const routes = React.useMemo(() => {
    return mapAdditionalRoutes(dehaId, isAhp, isPrivate, user.beta, device, url);
  }, [dehaId, device])

  if (!initialized || device.deviId !== deviId)
  return (
    <Wrap>
      <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
    </Wrap>
    )

  return (
      <React.Fragment>
        <DeviceHeader 
          dehaId={dehaId}
          name={device.deviceName} 
          deviId={device.deviId} 
          custId={device.ownedByCustomer} 
          isTwin={device.twinChild} 
          serialNumber={device.deviceSerialNumber} 
          deviceId={device.deviceId}>
            <StyledSpan>
              {` ${formatMessage({ id: "settingsFor", defaultMessage: "Innstillinger for" })} `}
            </StyledSpan>
        </DeviceHeader>
        <Switch location={location} key={location.pathname}>
          {routes}
          <React.Fragment>
          {layout === Layout.VERTICAL
            ? <VerticalRouteTabs>
              {tabs}
            </VerticalRouteTabs>
            : <HorizontalRouteTabs>
              {tabs}
            </HorizontalRouteTabs>
          }
          </React.Fragment>
        </Switch>
      </React.Fragment>
  )
}

const mapStateToProps = ( { appData: { isPrivate, currentContext }, settings: {device: { device }, initialization: { initialized, layout, supportDeviceMac, user }, websocket }}: AppState) => {
  return{
    device,
    initialized,
    layout,
    user,
    websocket,
    isPrivate,
    currentContext,
    supportDeviceMac
  }
}

const mapDispatchToProps = {
  init: initialize,
  initializeUser: initializeUser,
  setLayout,
  cleanup,
  setWebsocketClient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingsComp));
