import {IndoorState} from "../types";
import {Reducer} from "redux";
import {
  getDeviceMacs,
  addDeviceKnownMac,
  deleteDeviceKnownMac,
  getInitialPosition,
  addDeviceKnownMacs,
  getLearnLink
} from "../routines";
import {defaultDeviceKnownMac, defaultDeviceMac, defaultDeviceMacs} from "../../Indoor/utils"
import {SettingsActionTypes} from "../actionTypes";
import {DeviceMac} from "../../Indoor/types";

const indoorState: IndoorState = {
    deviceMac: defaultDeviceMac(0),
    deviceMacs: defaultDeviceMacs(0),
    deviceKnownMac: defaultDeviceKnownMac(0),
    deviId: 0,
    loading: true,
    deviceMacsRefresh: true,
    deviceMacInitialValues: defaultDeviceMac(0),
    deviceMacsInitialValues: defaultDeviceMacs(0),
    valuesChanged: false,
    initialPosition: "",
    openDevice: 0,
    selectedIndexes: [],
    activeIndex: -1,
    hasSavedMacs: false,
    hasScannedMacs: false,
    hasHeaderSaved: false,
    scanMinutes: 60,
    fetchingMacs: false,
    deviceKnownMacs: [],
    learnLink: "",
};

const indoor: Reducer<IndoorState> = (state: IndoorState = indoorState, action: any) => {

  switch (action.type) {
    
    case getDeviceMacs.TRIGGER:
      return {
        ...state,
        fetchingMacs: true
      }

    case getLearnLink.SUCCESS:
      return {
        ...state,
        learnLink: action.payload,
      }

    case getDeviceMacs.SUCCESS:
      return {
        ...state,        
        deviceMacs: setDefaultDeviceMacs(action.payload),
        deviceMacsInitialValues: setDefaultDeviceMacs(action.payload),
        loading: false,
        valuesChanged: false,
        selectedIndexes: [],
        activeIndex: -1,
        hasSavedMacs: hasSavedMacs(action.payload),
        hasScannedMacs: hasScannedMacs(action.payload),
        fetchingMacs: false
      }

    case getInitialPosition.SUCCESS:
      return {
        ...state,
        initialPosition: action.payload,
      }

    case addDeviceKnownMac.SUCCESS:
      return {
        ...state,
        deviceMac: action.payload,
        valuesChanged: false,
        deviceMacsRefresh: toggleRefresh(state),
        selectedIndexes: [],
        activeIndex: -1
      }

    case addDeviceKnownMacs.SUCCESS:
      return {
        ...state,
        valuesChanged: false,
        selectedIndexes: [],
        activeIndex: -1,
        deviceMacsRefresh: toggleRefresh(state)
      }

    case addDeviceKnownMacs.FAILURE:
      return {
        ...state,
        valuesChanged: false,
        selectedIndexes: [],
        activeIndex: -1,
        deviceMacsRefresh: toggleRefresh(state)
      }

    case deleteDeviceKnownMac.SUCCESS:
      return {
        ...state,
        deviceMac: defaultDeviceMac(state.deviId),
        valuesChanged: false,
        deviceMacInitialValues: defaultDeviceMac(state.deviId),
        deviceMacsRefresh: toggleRefresh(state),
        selectedIndexes: [],
        activeIndex: -1
      }

    case SettingsActionTypes.SET_INDOOR_DEVICE_MAC:
      return {
        ...state,
        deviceMac: action.payload,
        deviceMacInitialValues: {...action.payload},
        valuesChanged: false,
      }

    case SettingsActionTypes.SET_INDOOR_VALUES_CHANGED:
      return {
        ...state,
        valuesChanged: action.payload
      }

    case SettingsActionTypes.SET_INDOOR_OPEN_DEVICE:
      return {
        ...state,
        openDevice: action.payload
      }

    case SettingsActionTypes.SET_INDOOR_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.payload
      }

    case SettingsActionTypes.SET_INDOOR_SELECTED_INDEXES:
      return {
        ...state,
        selectedIndexes: action.payload
      }

    case SettingsActionTypes.SET_INDOOR_SCANNED_MINUTES:
      return {
        ...state,
        scanMinutes: action.payload
      }

    default:
        return state;
  }
};

const setDefaultDeviceMacs =  (deviceMacs: DeviceMac[]) : DeviceMac[] => {
  deviceMacs.map((deviceMac) => {
    setDefaultDeviceMac(deviceMac)
  })
  return deviceMacs;
}
const setDefaultDeviceMac =  (deviceMac: DeviceMac) : DeviceMac => {
  if(!deviceMac.deviceKnownMac){
    deviceMac.deviceKnownMac = defaultDeviceKnownMac(deviceMac.deviId)
  }
  return deviceMac;
}

const hasSavedMacs =  (deviceMacs: DeviceMac[]) : boolean => {
  let result = false;
  deviceMacs.map((deviceMac) => {
    if(deviceMac.deviceKnownMac && deviceMac.deviceKnownMac.demaId!==0){
      result = true;
    }
  })
  return result;
}

const hasScannedMacs =  (deviceMacs: DeviceMac[]) : boolean => {
  let result = false;
  deviceMacs.map((deviceMac) => {
    if(!deviceMac.deviceKnownMac || deviceMac.deviceKnownMac.demaId === 0){
      result = true;
    }
  })
  return result;
}

const hasHeaderSaved =  (deviceMacs: DeviceMac[]) : boolean => {
  let result = false;
  deviceMacs.map((deviceMac) => {
    if(deviceMac.headerSaved){
      result = true;
    }
  })
  return result;
}

const toggleRefresh = (state: IndoorState):boolean => {
  return !state.deviceMacsRefresh
}

export default indoor;
