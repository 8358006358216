import {call, put, takeLatest, select, take, race} from 'redux-saga/effects';

import {AppAction} from "../../../DefaultSettings/Store/types";

import * as Routines from '../routines';
import * as Api from '../api';
import { feedbackFailure } from '../../../Feedback/reducer';



export interface PostalProps{
  pnr: string,
  isBilling: boolean
}


function* fetchInvoices(action: AppAction<any>){
  try{
    const invoices = yield call(Api.getInvoices);
    yield put(Routines.fetchInvoices.success(invoices));
  }
  catch(e){
    yield feedbackFailure(e)
  }
}


function* invoiceSaga(){
  yield takeLatest(Routines.fetchInvoices.TRIGGER, fetchInvoices);
}

export default invoiceSaga;
