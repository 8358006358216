import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { AddDeviceForm } from '../types';
import { feedbackSuccess, feedbackFailure } from '../../../Feedback/reducer';


export interface AddDevice{
  values: AddDeviceForm;
  callback: () => void;
  device: string;
  deviceCSID: string;
}

function* addDevice(action: AppAction<AddDevice>){
  try {
    const device = yield call(Api.addDevice, action.payload.values);
   yield feedbackSuccess(action.payload.device);
    if(device.ahpAcctId){
      yield feedbackSuccess("Safemate " + action.payload.values.serialNumber + " " + action.payload.deviceCSID);
      //yield feedbackSuccess("unitAddedWithCSID");
    }
    else{
      yield feedbackSuccess("Safemate " + action.payload.values.serialNumber + " " + action.payload.device);
     //yield feedbackSuccess("unitAdded");
    }
    yield put(Routines.addDevice.success());
    action.payload.callback();
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.addDevice.failure());
  }
}

function* getTwin(action: AppAction<number>){
  try{
    const twinDevice = yield call(Api.getTwin, action.payload);
    yield put(Routines.getTwin.success(twinDevice));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getTwin.failure());
  }
}

export interface CheckTwinSerialNumber{
  serialNumber: string;
  twinDeviId: number
}

function* checkTwinSerialNumber(action: AppAction<CheckTwinSerialNumber>){
  try{
    const match = yield call(Api.checkTwinSerialNumber, action.payload);
    yield put(Routines.checkTwinSerialNumber.success({
      match,
      serialNumber: action.payload.serialNumber
    }));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.checkTwinSerialNumber.failure({
      match: false,
      serialNumber: action.payload.serialNumber
    }));
  }
  yield put(Routines.checkTwinSerialNumber.fulfill());
}

function* DeviceSaga(){
    yield takeLatest(Routines.addDevice.TRIGGER, addDevice);
    yield takeLatest(Routines.getTwin.TRIGGER, getTwin);
    yield takeLatest(Routines.checkTwinSerialNumber.TRIGGER, checkTwinSerialNumber);
}

export default DeviceSaga;
