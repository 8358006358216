import * as React from "react";
import { Field } from "formik";

import ArrowLeft from "../Icon/Icons/arrows/ArrowLeft";
import { Radio, RadioGroup } from "../RadioFormik";
import ArrowRight from "../Icon/Icons/arrows/ArrowRight";
import styled from "styled-components";

const Wrapper = styled.div<{vertical?: boolean}>`

    display: ${props => props.vertical ? "block" : "flex"};
    align-items: center;

`

const StyledArrowLeft = styled(ArrowLeft)<{vertical?: boolean}>`
    cursor: pointer;
    
    ${props => props.vertical && "transform: rotate(90deg);"}
`;

const StyledArrowRight = styled(ArrowRight)<{vertical?: boolean}>`
    cursor: pointer;

    ${props => props.vertical && "transform: rotate(90deg);"}
`;

const StyledFieldset = styled.fieldset<{vertical?: boolean}>`
    ${props => props.vertical && "display: flex;"}
`;

export interface RadioOptions{
    label: string;
    value: any;
}

interface RadioWithArrowsProps{
    options: RadioOptions[];
    name: string;
    currentValue: any;
    setValue: (name: string, value: any) => void;
    vertical?: boolean;
}

const RadioWithArrows = ({options, name, currentValue, setValue, vertical}: RadioWithArrowsProps) => {
    
    const shiftLeft = () => {
        const current = options.findIndex(option => option.value == currentValue);
        if(current > 0){
            setValue(name, options[current - 1].value);
        }
    }

    const shiftRight = () => {
        const current = options.findIndex(option => option.value == currentValue);
        if(current < options.length - 1){
            setValue(name, options[current + 1].value);
        }
    }

    return(
        <Wrapper vertical={vertical}>
            <StyledArrowLeft onClick={shiftLeft} vertical={vertical}/>
                <StyledFieldset vertical={vertical}>
                    {options.map(option => 
                        <Field 
                            component={Radio}
                            label={option.label} 
                            radioValue={option.value} 
                            id={`${name}-${Math.floor(Math.random() * 10)}`}
                            name={name}
                        />
                    )}
                </StyledFieldset>
            <StyledArrowRight onClick={shiftRight} vertical={vertical}/>
        </Wrapper>
    )
}

export default RadioWithArrows;