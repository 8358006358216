import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/INITIALIZE'
);
export const getUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/GET_USER'
);
export const getUserRoleWithCustomerName: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/GET_USER_ROLE_CUSTOMER_NAME'
);
export const getTfaRules: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/GET_TFA_RULES'
);
export const getAllowDelete: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/GET_ALLOW_DELETE'
);
export const getPrivate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/GET_PRIVATE'
);

// User
export const saveUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/SAVE_USER'
);
export const deleteUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/DELETE_USER'
);
export const sendGdprEmail: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/SEND_GDPR_EMAIL'
);
export const setStyle: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/SET_STYLE'
);

// Country

export const getCountries: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/GET_COUNTRIES'
);

export const getTextsMyPage: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@MyPage/GET_TEXTS_MY_PAGE'
);
