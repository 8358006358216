import * as React from "react";
import {connect} from "react-redux";
import {useEffect} from "react";
import {FeedbackState} from "./reducer";
import { FeedbackBox } from "./FeedbackBox";
import { useIntl } from "react-intl";
import { AppState } from "@Safemate/Store/types";

interface FeedbackProps{
  feedback: FeedbackState;
  small?: boolean;
}


const mapStateToProps = ({feedback}: AppState) => {
  return {
    feedback
  }
};

export const Feedback = connect(mapStateToProps)(({feedback: {feedbackUUID, danger, message}, small}: FeedbackProps) => {

  const didMount = useDidMount();

  const { formatMessage } = useIntl();

  const [ show, setShow ] = React.useState<boolean>(false);

  
  React.useEffect(() => {
    if(message && didMount){
      if(message === "null"){
        message = "";
      }
      else{
        setShow(true);
      }
    }
  }, [feedbackUUID])

  if(!message) return null;

  return(
    <FeedbackBox
      small={small}
      show={show}
      msg={formatMessage({id: message, defaultMessage: message})}
      duration={danger ? 200000 : 10000}
      setShow={setShow}
      danger={danger}
    />
  )
});

function useDidMount() {
  const [didMount, setDidMount] = React.useState(false)
  useEffect(() => setDidMount(true), [])

  return didMount
}

export default Feedback;
