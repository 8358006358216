import {call, put, select, takeLatest} from "redux-saga/effects";
import * as Routines from "@Safemate/Settings/Store/routines";
import {AppAction} from "@Safemate/Store/types";
import * as Api from "@Safemate/Settings/Store/api";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import {feedbackFailure, feedbackSuccess} from "@Safemate/Feedback/reducer";
import {Feedback} from "@Safemate/Settings/labels";
import { Customer } from "@Safemate/AddSafemate/Store/types";

function* getIndoorLocationsForDevice(action: AppAction<number>){
  try {
    const indoorLocations:IndoorLocation[] = yield call(Api.getIndoorLocationsForDevice, action.payload);
    yield put(Routines.getIndoorLocationsForDevice.success(indoorLocations));
  }
  catch(e){
    yield put(Routines.getIndoorLocationsForDevice.failure());
  }
}

function* getIndoorLocationsForCustomer(action: AppAction<number>){
  try {
    const indoorLocations:IndoorLocation[] = yield call(Api.getIndoorLocationsForCustomer, action.payload);
    yield put(Routines.getIndoorLocationsForCustomer.success(indoorLocations));
  }
  catch(e){
    yield put(Routines.getIndoorLocationsForCustomer.failure());
  }
}

function* saveIndoorLocation(action: AppAction<IndoorLocation>){
  try {
    const indoorLocation:IndoorLocation = yield call(Api.saveIndoorLocation, action.payload);

    yield put(Routines.saveIndoorLocation.success(indoorLocation));
    yield feedbackSuccess(Feedback.INDOOR_LOCATION_SAVE_SUCCESS);
  }
  catch(e){
    yield put(Routines.saveIndoorLocation.failure());
    yield feedbackFailure(e);
  }
}

function* saveIndoorLocationName(action: AppAction<IndoorLocation>){
  try {
    const indoorLocation:IndoorLocation = yield call(Api.saveIndoorLocationName, action.payload);

    yield put(Routines.saveIndoorLocationName.success(indoorLocation));
    yield feedbackSuccess(Feedback.INDOOR_LOCATION_NAME_SAVE_SUCCESS);
  }
  catch(e){
    yield put(Routines.saveIndoorLocationName.failure());
    yield feedbackFailure(e);
  }
}

function* deleteIndoorLocation(action: AppAction<IndoorLocation>){
  try {
    yield call(Api.deleteIndoorLocation, action.payload.inloId);
    yield put(Routines.deleteIndoorLocation.success());
    yield feedbackSuccess(Feedback.INDOOR_LOCATION_DELETE_SUCCESS);
  }
  catch(e){
    yield put(Routines.deleteIndoorLocation.failure());
    yield feedbackFailure(e);
  }
}

function* scanIndoorLocationsForDevice(action: AppAction<number>){
  try {
    yield call(Api.scanIndoorLocationsForDevice, action.payload);
    yield put(Routines.scanWifiIndoorLocation.success());
  }
  catch(e){
    yield put(Routines.scanWifiIndoorLocation.failure());
    yield feedbackFailure(e);
  }
}

function* scanIndoorLocationsForDeviceFailure(action: AppAction<string>){
  try {
    yield call(Api.scanIndoorLocationsForDeviceFailure);
    yield put(Routines.scanWifiIndoorLocationFailure.success());
  }
  catch(e){
    yield put(Routines.scanWifiIndoorLocationFailure.failure());
    yield feedbackFailure(e);
  }
}

function* getCustomers(action: AppAction<number>){
  try {
    const customers: Customer[] = yield call(Api.getCustomers, action.payload);
    yield put(Routines.getCustomersForIndoor.success(customers));
  }
  catch(e){
    yield put(Routines.getCustomersForIndoor.failure());
  }
}

function* indoorLocationSaga(){
  yield takeLatest(Routines.getIndoorLocationsForDevice.TRIGGER, getIndoorLocationsForDevice);
  yield takeLatest(Routines.getIndoorLocationsForCustomer.TRIGGER, getIndoorLocationsForCustomer);
  yield takeLatest(Routines.saveIndoorLocation.TRIGGER, saveIndoorLocation);
  yield takeLatest(Routines.saveIndoorLocationName.TRIGGER, saveIndoorLocationName);
  yield takeLatest(Routines.deleteIndoorLocation.TRIGGER, deleteIndoorLocation);
  yield takeLatest(Routines.scanWifiIndoorLocation.TRIGGER, scanIndoorLocationsForDevice);
  yield takeLatest(Routines.scanWifiIndoorLocationFailure.TRIGGER, scanIndoorLocationsForDeviceFailure);
  yield takeLatest(Routines.getCustomersForIndoor.TRIGGER, getCustomers);
}

export default indoorLocationSaga;
