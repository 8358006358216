import * as React from "react";
import { useIntl } from "react-intl";
import { Contract } from "@Elements/Icon/Icons/FontAwesome";
import { AppState, GdprManager } from "@Safemate/Store/types";
import { connect } from "react-redux";
import { SvgWrap } from "@Safemate/UserAdmin/Users/Actions/styles";

interface GdprProps{
  gdprManager: GdprManager;
  dpaLink: string;
}

const mapStateToProps = ({ appData: { gdprManager }, appSettings: { marketSetting: { dpaLink }} }: AppState) => {
  return {
    gdprManager,
    dpaLink
  }
}

//https://sensio.atlassian.net/wiki/external/MGU4ZTFlNmNkZDNmNGUyMmIwN2YzYTAzNmM4MDA2ZDk

const Gdpr = connect(mapStateToProps)(({ gdprManager, dpaLink }: GdprProps) => {

  const { formatMessage } = useIntl();

  return (
    <div> 
      <h3>
        <a target="_blank" href={dpaLink}>
          {formatMessage({id: "gdprDocument", defaultMessage: "Databehandleravtale"})}
        </a>
      </h3>
      {gdprManager.userId
        ? <div style={{display: "flex", alignItems: "center"}}>
            <Contract medium/>
            <a href={`mailto: ${gdprManager.email}`}>{gdprManager.email}</a>
          </div>
        : <div>
            {formatMessage({id: "noGdprManager", defaultMessage: "Det er ingen behandlingsansvarlig i denne organisasjonen."})}
          </div>
    }
    </div>
  )
})

export default Gdpr;