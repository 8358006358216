import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

export const validate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/VALIDATE'
);

export const prePopulateEmail: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/PRE_POPULATE_EMAIL'
);

export const login: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/LOGIN'
);

export const logout: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/LOGOUT'
);

export const forgotPassword: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/FORGOT_PASSWORD'
);

export const resetPassword: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/RESET_PASSWORD'
);

export const resendVerification: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/RESEND_VERIFICATION'
);

export const userValidation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/USER_VALIDATION'
);

export const couponValidation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/COUPON_VALIDATION'
);

export const registerUserPrivate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/REGISTER_USER_PRIVATE'
);

export const customerValidation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/CUSTOMER_VALIDATION'
);

export const registerCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/REGISTER_CUSTOMER'
);

export const sendGdpr: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/SEND_GDPR'
);

export const acceptGdpr: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/ACCEPT_GDPR'
);

export const getTexts: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Session/GET_TEXTS'
);
