import React from 'react';
import { withTheme } from 'styled-components';
import {
  StyledCheck,
  StyledSpan,
  CheckWrapper,
  Wrapper,
  StyledFieldset,
  CheckLabel
} from './elements';

import Check from '../Icon/Icons/Check';

class CheckBoxNoTheme extends React.Component {
  constructor(props) {
    super(props);
    this.radioRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (this.props.field) {
      this.props.field.onChange(e);
    }
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  handleClick() {
    if (!this.props.disabled) {
      this.radioRef.current.click();
    }
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.radioRef.current.click();
    }
  };

  render() {
    const { large } = this.props;
    const spanProps = {
      active: this.props.value && !this.props.disabled
    };
    const checkSize = {
      width: large ? '24px' : '21px',
      height: large ? '24px' : '10px'
    };
    let htmlFor = 'none';
    if (!this.props.readOnly) {
      spanProps.onClick = this.handleClick;
      spanProps.onKeyDown = this.handleKeyDown;
      if (!this.props.disabled) {
        htmlFor = this.props.id;
      }
    }
    return (
      <Wrapper style={this.props.outerDiv} disabled={this.props.disabled}>
        <StyledSpan
          large={this.props.large}
          circle={this.props.circle}
          role="checkbox"
          aria-labelledby={`${this.props.id}-label`}
          tabIndex={this.props.disabled ? undefined : '0'}
          {...spanProps}
        >
          {this.props.value ? (
            <CheckWrapper>
              <Check
                size={checkSize}
                color={this.props.theme.colors.backgroundPrimary}
              />
            </CheckWrapper>
          ) : null}
        </StyledSpan>
        <StyledCheck
          readOnly={this.props.readOnly}
          ref={this.radioRef}
          name={this.props.field ? this.props.field.name : this.props.name}
          id={this.props.id}
          value={this.props.id}
          checked={this.props.value}
          onChange={this.handleChange}
          onBlur={
            this.props.field ? this.props.field.onBlur : this.props.onBlur
          }
          type="checkbox"
        />
        <CheckLabel
          id={`${this.props.id}-label`}
          style={this.props.labelStyle}
          disabled={this.props.disabled}
          htmlFor={htmlFor}
        >
          {this.props.labelComp ? this.props.labelComp : null}
          {this.props.label ? `${this.props.label.charAt(0).toUpperCase()}${this.props.label.slice(1)}` : ""}
        </CheckLabel>
      </Wrapper>
    );
  }
}

export const Checkbox = withTheme(CheckBoxNoTheme);

export const CheckboxGroup = ({ children, disabled }) => {
  return (
    <StyledFieldset style={{ width: '100%' }} disabled={disabled}>
      {children}
    </StyledFieldset>
  );
};
