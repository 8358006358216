import React from 'react';

const HeadlineGeofence = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="20px"
      height="20px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      fill="#999"
    >
      <path
        d="M99.348,8.706c-11.618,0-21.039,9.42-21.039,21.039c0,0.25,0.008,0.502,0.019,0.758c0.077,2.211,0.494,4.333,1.205,6.319
                c4.493,14.754,19.815,34,19.815,34s15.328-19.245,19.817-33.999c0.709-1.986,1.124-4.109,1.205-6.321
                c0.01-0.256,0.021-0.509,0.021-0.758C120.391,18.126,110.967,8.706,99.348,8.706z M99.348,40.05
                c-5.688,0-10.302-4.615-10.302-10.305s4.613-10.304,10.302-10.304c5.693,0,10.305,4.616,10.305,10.304
                C109.652,35.435,105.041,40.05,99.348,40.05z"
      />
      <g>
        <path
          d="M100.169,81.023l9.423-10.563h15.31l-16.369,15.53l17.626,15.795h-16.096l-9.735-10.779l-9.733,10.779H75.286
                    l16.799-15.795l-16.369-15.53H90.91L100.169,81.023z"
        />
      </g>
      <g>
        <path
          d="M73.592,108.919c-0.646-0.698-1.166-1.494-1.549-2.362c-0.632-0.204-1.229-0.452-1.756-0.776
                    c-4.942,2.055-10.356,3.198-16.034,3.198c-23.087,0-41.869-18.783-41.869-41.869c0-23.088,18.781-41.869,41.867-41.869
                    c3.556,0,6.993,0.484,10.29,1.327c0.484-0.463,1.042-0.861,1.658-1.194c0.069-0.068,0.125-0.145,0.197-0.209
                    c-0.304-2.497,0.313-5.122,1.845-7.085c-0.29-0.571-0.522-1.173-0.691-1.794c-4.251-1.122-8.695-1.768-13.298-1.768
                    c-28.999,0-52.592,23.594-52.592,52.594s23.594,52.594,52.592,52.594c7.62,0,14.841-1.683,21.38-4.619
                    c-0.517-1.157-0.778-2.43-0.816-3.711C74.267,110.633,73.89,109.791,73.592,108.919z"
        />
      </g>
    </svg>
  );
};

export default HeadlineGeofence;
