import {Reducer} from "redux";
import { AppSettings } from "../types";
import {getLanguages, getMarket, getMarketSetting, getSettings} from "../routines";
import { MarketEnum } from "@Safemate/MarketEnum";

const appSettings: AppSettings = {
  market: MarketEnum.NORWAY,
  languages:[],
  marketSetting:{
    showWebShopLink: false,
    webShopLink: "",
    dpaLink: ""
  },
  beta: false,
  forcedAhp: false,
  toplovoGpsSelfValidation: false,
  showMainMap: false,
  ldapOnly: false,
  trondheim2: false,
  sensorRefresh: 30
};

const appSettingsReducer: Reducer<AppSettings> = (state: AppSettings = appSettings, action) => {
  switch (action.type) {
    case getSettings.SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    case getMarket.SUCCESS:
      return{
        ...state,
        market: action.payload
      }

    case getMarketSetting.SUCCESS:
      return{
        ...state,
        marketSetting: action.payload
      }

    case getLanguages.SUCCESS:
      return{
        ...state,
        languages: action.payload
      }

    default:
      return state;
  }
};

export default appSettingsReducer;
