import React from 'react';
import PropTypes from 'prop-types';
import { getBgColor } from '../utils';

import Signal from './Signal';

const CircleSignal = ({ strength, maxStrength }) => {
  const style =
    strength > 0
      ? { background: getBgColor(strength, maxStrength) }
      : undefined;
  return <Signal style={style} />;
};

CircleSignal.propTypes = {
  strength: PropTypes.number.isRequired,
  maxStrength: PropTypes.number.isRequired
};

export default CircleSignal;
