import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { TwinDevice as TwinDeviceType } from "@Safemate/Settings/types";
import SettingsHeader from "../header";
import { TwinLink, TwinSection } from "./styled";
import { deleteTwin } from "../../Store/routines";
import confirmModal from "@Safemate/Modals/confirmModal";
import { AppState } from "@Safemate/Store/types";

const mapStateToProps = ({ settings: {device: { device: {twin, twinLearn}}}}: AppState) => {
  return{
    twin,
    twinLearn
  }
}

interface TwinDeviceProps{
  twin: boolean;
  twinLearn: string;
}

const TwinDevice = connect(mapStateToProps)(({twin, twinLearn}: TwinDeviceProps) => {

  const { formatMessage } = useIntl();

  return (
    <div>
      <SettingsHeader 
        title={formatMessage({id: "twinDevice", defaultMessage: "Twin Device"})}
        customText={
          <p>{formatMessage({id: "twinLearn", defaultMessage: "Lær mer om tvilling konfigurasjon her: "})}<a href={twinLearn} target="_blank">{twinLearn}</a></p>
        }
      />
      <div>
        {twin ? 
          <div>
            <TwinInformation/>
            <DeleteTwin/>
          </div>
          : <AddTwin/>
        }
      </div>
    </div>
  )
})

const mapStateToTwinInformationProps = ({ settings: {device: { device: { twinDevice, deviceSerialNumber }}}}: AppState) => {
  return {
    deviceSerialNumber,
    twinDevice
  }
}

interface TwinInformationProps{
  deviceSerialNumber: string;
  twinDevice: TwinDeviceType;
}

const TwinInformation = connect(mapStateToTwinInformationProps)(({ deviceSerialNumber, twinDevice }: TwinInformationProps) => {

  const { formatMessage } = useIntl();

  const searchTwin = () => {
    location.hash = `safemates/search=${twinDevice.deviceSerialNumber}` 
  }

  return(
    <TwinSection style={{display: "flex"}}>
      <TwinLink onClick={searchTwin}>{twinDevice.deviceSerialNumber}</TwinLink>
      &nbsp;
      <p>{`${formatMessage({id: "currentTwinConfiguration", defaultMessage: "er konfigurert som tvilling av"})} ${deviceSerialNumber}.`}</p>
    </TwinSection>
  )
})

const mapStateToDeleteTwin = ({ settings: {initialization: { deviId }, device: { device: { twinDevice, deviceSerialNumber, deviceName }}}}: AppState) => {
  return {
    deviId,
    twinDevice,
    deviceSerialNumber,
    deviceName
  }
}

const mapDispatchToDeleteTwin = {
  deleteTwin
}

interface DeleteTwinProps{
  deviId: number;
  deleteTwin: Routine<ActionFunctionAny<Action<any>>>;
  twinDevice: TwinDeviceType;
  deviceSerialNumber: string;
  deviceName: string;
}

const DeleteTwin = connect(mapStateToDeleteTwin, mapDispatchToDeleteTwin)(({deviId, twinDevice, deleteTwin, deviceSerialNumber, deviceName}: DeleteTwinProps) => {

  const { formatMessage } = useIntl();
  const {Comp, func} = confirmModal();

  return (
    <TwinSection>
      <Button onClick={() => func(true)}>{formatMessage({id: "deleteTwin", defaultMessage: "Delete twin"})}</Button>
      <Comp
        title={formatMessage({id: "deleteTwin", defaultMessage: "Delete twin"})}
        body={
          <p>{`${formatMessage({id: "deleteTwinDesc", defaultMessage: "Er du sikker på at du vil slette tvillingen"})} ${twinDevice.deviceSerialNumber} (${twinDevice.deviceName})`}</p>
        }
        confirmText={formatMessage({id: "confirm", defaultMessage: "Godta"})}
        confirmFunc={() => {
          deleteTwin(deviId);
          func(false);
        }}
      />
    </TwinSection>
  )
})

const mapStateToAddTwinProps = ({ settings: {initialization: { deviId }}}: AppState) => {
  return {
    deviId
  }
}

interface AddTwinProps{
  deviId: number;
}

const AddTwin = connect(mapStateToAddTwinProps)(({ deviId }: AddTwinProps) => {

  const { formatMessage } = useIntl();

  const addTwin = () => {
    location.hash = `add_safemate/twin=${deviId}`;
  }

  return(
    <TwinSection>
      <Button onClick={addTwin}>{formatMessage({id: "addTwin", defaultMessage: "Legg til tvilling enhet"})}</Button>
    </TwinSection>
  )
})

export default TwinDevice;