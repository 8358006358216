import React from 'react';
import {
  StyledRadio,
  StyledSpan,
  RadioWrapper,
  RadioLabel
} from './elements';

export class Radio extends React.Component {
  constructor(props) {
    super(props);
    this.radioRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.radioRef.current.click();
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.radioRef.current.click();
    }
  };

  render() {
    return (
      <RadioWrapper
        style={this.props.outerdiv}
        disabled={this.props.disabled}
        onClick={this.handleClick}
        onKeyDown={this.handleKeyDown}
        tabIndex="0"
      >
        <StyledSpan
          role="radio"
          aria-labelledby={`${this.props.id}-label`}
          active={
            (this.props.field ? this.props.field.value : this.props.value) ===
              this.props.radioValue && !this.props.disabled
          }
        />
        <StyledRadio
          ref={this.radioRef}
          name={this.props.field ? this.props.field.name : this.props.name}
          id={this.props.id}
          value={this.props.radioValue}
          checked={
            this.props.radioValue ===
            (this.props.field ? this.props.field.value : this.props.value)
          }
          onChange={
            this.props.field ? this.props.field.onChange : this.props.onChange
          }
          onBlur={
            this.props.field ? this.props.field.onBlur : this.props.onBlur
          }
          type="radio"
        />
        <RadioLabel
          id={`${this.props.id}-label`}
          disabled={this.props.disabled}
          htmlFor={this.props.id}
        >
          {this.props.label}
        </RadioLabel>
      </RadioWrapper>
    );
  }
}

export const RadioGroup = ({ children, disabled }) => {
  return (
    <fieldset style={{ width: '100%' }} disabled={disabled}>
      {children}
    </fieldset>
  );
};
