import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/INITIALIZE'
);

export const initializeCustomers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/INITIALIZE_CUSTOMERS'
);

export const initializeSubOrgs: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/INITIALIZE_SUB_ORGS'
);

export const initializeSubUnits: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/INITIALIZE_DEVICE_HARDWARE'
);

export const initializePaymentProfiles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/INITIALIZE_PAYMENT_PROFILE'
);

export const updateAddrSame: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/UPDATE_ADDR_SAME'
);

export const resetSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/RESET_SETTINGS'
);

export const cancelSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/CANCEL_SETTINGS'
);

// Customer

export const fetchCustomersForForm: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/FETCH_CUSTOMERS_FOR_FORM'
);

export const fetchCustomersForList: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/FETCH_CUSTOMERS_FOR_LIST'
);

export const saveCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/SAVE_CUSTOMER'
);

export const deleteCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/DELETE_CUSTOMER'
);

export const selectCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/SELECT_CUSTOMER'
);

export const getPayment: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/GET_PAYMENT'
);

export const getPaymentForNew: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/GET_PAYMENT_FOR_NEW'
);

export const createSubOrg: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/CREATE_SUB_ORG'
);

export const initializeAlarmHelper: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/INITIALIZE_ALARM_HELPER'
);

export const getPostCity: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/GET_POST_CITY'
);

export const getBillingPostCity: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/GET_BILLING_POST_CITY'
);

export const setPaidByCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@SubUnits/SET_PAID_BY_CUSTOMER'
);