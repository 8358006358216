import React from "react";
import { useIntl } from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import { SortWrapper } from "./TableElementStyles";
import { ModalEnum } from "./utils";
import { RootState } from "../Store/types";
import { connect } from "react-redux";
import { setDeviceEventSetupModal } from "../Store/routines";
import withFormWrapper from "@Elements/Form/formWrapper";
import { EventSetupForm } from "./types";
import confirmModal from "@Safemate/Modals/confirmModal";
import { Checkbox } from "@Elements/CheckboxFormik";
import { AppState } from "@Safemate/Store/types";


interface TrainedSelectorProps{
  modalType: ModalEnum;
  modalEventSetupIndex: number;
  selectedEventSetup: DeviceEventSetup;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ eventSetup: {deviceEventSetup: { selectedEventSetup, modalType, modalEventSetupIndex }}}: AppState) => {
  return {
    selectedEventSetup,
    modalType,
    modalEventSetupIndex
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal
}

const TrainedSelector = connect(mapStateToProps, mapDispatchToProps)(
  withFormWrapper<EventSetupForm, TrainedSelectorProps>(({ formik: {setFieldValue}, modalType, modalEventSetupIndex, selectedEventSetup, setDeviceEventSetupModal }) => {

    const [ trained, setTrained ] = React.useState(false);
    const { Comp: Modal, func: displayModal } = confirmModal();
    const { formatMessage } = useIntl();

    React.useEffect(() => {
      if(modalType === ModalEnum.TRAINED){
        displayModal(true);
        setTrained(selectedEventSetup.trained);
      }
      else{
        displayModal(false);
      }
    }, [modalType, selectedEventSetup])

    const confirm = () => {
      setFieldValue(`eventSetups.${modalEventSetupIndex}`,{
        ...selectedEventSetup,
        trained
      });
      closeModal();
    }

    const closeModal = () => {
      setDeviceEventSetupModal({
        selectedEventSetup: null,
        modalType: ModalEnum.NONE,
        modalEventSetupIndex: 0
      })
    }

    return(
      <Modal
        buttonAlign="center"
        standardBtn
        disableClose
        cancelFunc={closeModal}
        confirmFunc={confirm}
        confirmText={formatMessage({id: "ok", defaultMessage: "Ok"})}
        title={formatMessage({id: "trained", defaultMessage: "Opplært"})}
        body={
          <SortWrapper>
            <Checkbox
              value={trained}
              id="trained-checkbox"
              label={formatMessage({id: "userTraining", defaultMessage: "User is trained"})}
              onChange={() => {
                setTrained(!trained)
              }}
            />
          </SortWrapper>
        }
      />
    )
  })
)

export default TrainedSelector;