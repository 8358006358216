import * as React from "react";
import {Form, Formik, } from "formik";
import {connect} from "react-redux";
import { Grid} from "react-bootstrap";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";

import { Firm } from "../Store/types";
import { popForm} from "./utils";
import { RootState, Organization} from "../Store/types";
import {saveForm} from "../Store/routines";
import {Footer} from "./footer";

import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { Email, OrgNum, CompanyName } from "./fields";
import { AppState } from "@Safemate/Store/types";

interface FormProps{
  saveForm: Routine<ActionFunctionAny<Action<any>>>;
  customers: Organization;
  selectedRootParent: boolean;
}



const FormComp = ({  saveForm, customers}: FormProps) => {
      let iniVals;
      let pptyId:string;
      let billAddrDefined:boolean;
      let countryCode:string;
      customers.paymentProfile != null ? pptyId = customers.paymentProfile.pptyId+'' : customers.firm.privateUser ? pptyId="6" : pptyId="4";
      countryCode = customers.firm.country;
      customers.firm.billingAddrName === null || customers.firm.billingAddrName === "" ? billAddrDefined = false : billAddrDefined = true;
      iniVals = popForm(customers.firm, pptyId, billAddrDefined);
      return(
        <Formik
          initialValues={iniVals}
          enableReinitialize
          onSubmit={(values: Firm) => saveForm({values, customers})}
        >
        {({values: { mobile }, dirty}) => 
            <Form id="form">
                          <CompanyName/>
                          <Email/>
                          <OrgNum/>
                          <Footer />
                          <UnsavedCheck/>
            </Form>
        }
          </Formik>
        
      )
  }

const mapStateToProps = ({ partnerOrganization: {subUnits: {customers}}}: AppState) => {
  return{
    customers
  }
};

const mapDispatchToProps = {
  saveForm
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComp);
