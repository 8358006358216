import {Reducer} from "redux";
import {SettingsState} from "../types";
import * as Routines from "../routines";

const settingsState: SettingsState = {
    deviceInfo: [],
};

const invoices: Reducer<SettingsState> = (state = settingsState, action) => {
  switch (action.type) {
    
    case Routines.getBillingSettings.SUCCESS:
      return{
        ...state,
        deviceInfo: action.payload,
      }
      
    default:
      return state;
  }
};

export default invoices;
