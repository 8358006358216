import * as React from "react";
import confirmModal from "@Safemate/Modals/confirmModal";
import { useIntl } from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { RootState } from "../Store/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { EventSetupForm } from "./types";
import { connect } from "react-redux";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import { setDeviceEventSetupModal, deleteDeviceEventSetup } from "../Store/routines";
import { ModalEnum } from "./utils";
import { AppState } from "@Safemate/Store/types";

interface DeleteProps{
  selectedEventSetup: DeviceEventSetup;
  modalType: ModalEnum;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
  deleteDeviceEventSetup: Routine<ActionFunctionAny<Action<any>>>;
}

const mapPropsToState = ({ eventSetup: {deviceEventSetup: { selectedEventSetup, modalType }}}: AppState) => {
  return{
    selectedEventSetup,
    modalType
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal,
  deleteDeviceEventSetup
}

const Delete = connect(mapPropsToState, mapDispatchToProps)(
  withFormWrapper<EventSetupForm, DeleteProps>(({ formik: { values: { eventSetups }, setFieldValue }, modalType, deleteDeviceEventSetup, selectedEventSetup, setDeviceEventSetupModal }) => {

  const { Comp: Modal, func: displayModal } = confirmModal();
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    displayModal(modalType === ModalEnum.DELETE);
  }, [modalType, selectedEventSetup])

  const confirm = () => {
     
    // Only added in the GUI, not saved yet.
    if(selectedEventSetup.deviceEventSetupId === 0){
      setFieldValue("eventSetups", eventSetups.filter(setup => setup.localId !== selectedEventSetup.localId));
      closeModal();
    }
    else{
      deleteDeviceEventSetup({
        eventSetupId: selectedEventSetup.deviceEventSetupId,
        eventSetups
      })
    }
  }

  const closeModal = () => {
    setDeviceEventSetupModal({
      selectedEventSetup: null,
      modalType: ModalEnum.NONE,
      modalEventSetupIndex: 0
    })
  }

  return(
    <Modal
      buttonAlign="center"
      confirmFunc={confirm}
      confirmText={formatMessage({id: "delete", defaultMessage: "Slett"})}
      title={formatMessage({id: "deleteRecipientHeader", defaultMessage: "Slett alarmmottaker"})}
      body={<p>
        {formatMessage({id: "deleteRecipientText", defaultMessage: "Er du sikker på at du vil slette denne alarmmottakeren?"})}
      </p>}
    />
  )
}))

export default Delete;