import * as React from "react";
import { Customer, DashboardPrice } from "../Models/Customer";
import ReactTooltip from "react-tooltip";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { TooltipOriention } from "./utils";

const UlStyled = styled.ul`
  max-width: 280px;
`;

interface ListElementProps{
  customer: Customer;
  tooltipOriention: TooltipOriention;
}

export const CustomerName = ({customer: { custId, customerName, differentPrices }, tooltipOriention}: ListElementProps) => {
  const { formatMessage } = useIntl();
  if(!differentPrices){
    return <React.Fragment>{customerName}</React.Fragment>;
  }
  return(
    <div className="reactPartnerToolTip">
      <p>
        <a data-tip data-for={`custName${custId}`} style={{color: "red"}}>
          {customerName}
        </a>
      </p>
    <ReactTooltip place={tooltipOriention === TooltipOriention.UP ? "top" : "bottom"} class="reactPartnerToolTip" id={`custName${custId}`} aria-haspopup="true" role="example" effect="solid">
      <UlStyled>
        <li> {formatMessage({id: "partnerOverviewPpg", defaultMessage: "Denne kunden har minst en underavdeling med andre priser"})}</li>

      </UlStyled>
    </ReactTooltip></div>
  )
}

export const Prices = ({customer: { prices, custId }, tooltipOriention}: ListElementProps) => {
  return(
    <div className="reactPartnerToolTip">
      <p>
        <a data-tip data-for={`safematePriceTooltip${custId}`}>
          {prices.safematePrice} / {prices.safemateHomePrice} / {prices.safemateTwinPrice}
        </a>
      </p>
      <ReactTooltip place={tooltipOriention === TooltipOriention.UP ? "top" : "bottom"} class="reactPartnerToolTip" id={`safematePriceTooltip${custId}`} aria-haspopup="true" role="example" effect="solid">
        <ul>
          <li>Safemate Standard: {prices.safematePrice}</li>
          <li>Safemate Home: {prices.safemateHomePrice}</li>
          <li>Safemate Twin: {prices.safemateTwinPrice}</li>
        </ul>
      </ReactTooltip>
    </div>
  )
}



export const DeviceCount = ({ customer, tooltipOriention }: ListElementProps) => {

  const { formatMessage } = useIntl();

  return(
    <div className="reactPartnerToolTip">
      <p>
        <a data-tip data-for={`devicesTooltip${customer.custId}`}>
          {customer.numberOfDevices} / {customer.numberOfSafemateHomes} / {customer.numberOfSafemateTwin}
        </a>
      </p>
      <ReactTooltip place={tooltipOriention === TooltipOriention.UP ? "top" : "bottom"} class="reactPartnerToolTip" id={`devicesTooltip${customer.custId}`} aria-haspopup="true" role="example" effect="solid">
        <p>{formatMessage({id: "safemateCount", defaultMessage: "Total number of devices"})}:</p>
        <ul>
          <li>Safemate standard: {customer.numberOfDevices}</li>
          <li>Safemate Home: {customer.numberOfSafemateHomes}</li>
          <li>Safemate Twin: {customer.numberOfSafemateTwin}</li>
        </ul>
    </ReactTooltip></div>
  )
}
