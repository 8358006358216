import * as React from "react";
import Trash from "../../../../Elements/Icon/Icons/Trash";
import { injectIntl, WrappedComponentProps } from "react-intl";
import {LabelEnum} from "../../LabelEnum";
import {SetDeleteModal, setDeleteModal} from "../../Store/actions";
import {connect} from "react-redux";
import {UserWithCustomer} from "../../Models/UserWithCustomer";

interface DeleteProps extends WrappedComponentProps{
  setDeleteModal: SetDeleteModal;
  user: UserWithCustomer;
}

const mapDispatchToProps = {
  setDeleteModal
}

const Delete = connect(null, mapDispatchToProps)(injectIntl(({user, setDeleteModal, intl: {formatMessage}}: DeleteProps) =>
  <React.Fragment>
    <Trash
      tabIndex="0"
      title={formatMessage({id: LabelEnum.DELETE, defaultMessage: "Slett"})}
      size="medium"
      onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        event.stopPropagation();
        setDeleteModal(user);
      }}
      onKeyDown={(event: React.KeyboardEvent<SVGSVGElement>) => {
        if(event.key === "Enter")
          setDeleteModal(user);
      }}
      inline
    />
  </React.Fragment>
));

export default Delete;
