import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {DropdownField} from "./FormFields";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {AllPermissions, PolicyContext} from "../../../Policy/Provider";
import {mapCustomersForUser} from "../../utils";
import {connect as reduxConnect} from "react-redux";
import {connect as formikConnect} from "formik";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {InnerFrame, SubHeaderWrapper} from "./styles";
import {NoFlexContent} from "@Safemate/RoleEditor/WrapperStyles";
import {LabelEnum} from "../../LabelEnum";
import {Option} from "../../../DefaultSettings/Settings/FieldWrapper";
import {Role} from "../../Models/Role";
import { SiteTypeEnum } from "../../../SiteTypeEnum";
import TreeDropdown from "@Elements/Dropdown/Tree";
import { TreeDropdownOption } from "@Elements/Dropdown/types";
import { AppState } from "@Safemate/Store/types";
import { useIntl } from "react-intl";
import { CustomerWithChildren, ICustomerWithPolicy } from "@Safemate/Model/Customer";

interface PermanentRoleProps{
  customerHierarchy: CustomerWithChildren| null;
  isNew: boolean;
  admin: Role;
  roles: Array<Option>;
  formKey: string;
  siteType: SiteTypeEnum;
  companyName: string;
  custId: number;
  customersWithPolicy: ICustomerWithPolicy[];
}

const mapStateToProps = ({appData: { customersWithPolicy, customerHierarchy, currentContext, superfirm: { name, custId } }, users: {role: {admin}, action: {edit: {isNew, formKey}}}}: AppState) => {
  return{
    customerHierarchy,
    isNew,
    admin,
    formKey,
    siteType: currentContext,
    companyName: name,
    custId,
    customersWithPolicy
  }
}

const PermanentRole =
reduxConnect(mapStateToProps)(
    formikConnect<PermanentRoleProps, UserWithCustomer>(
      ({customerHierarchy, customersWithPolicy, companyName, custId, formKey, roles, admin, siteType, formik: {values: { role, subOrgs }, setFieldValue}, isNew}) => {

        const {formatMessage} = useIntl();

        const [mappedCustomers, setMappedCustomers] = React.useState([] as Array<TreeDropdownOption>);
        const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>
        const isAhp = siteType === SiteTypeEnum.AHP;

        React.useEffect(() => {
          if (customerHierarchy) {
            setMappedCustomers(mapCustomersForUser(subOrgs.map(s => s.custId), customerHierarchy, permissions.get(PolicyNameEnum.USER_PRIVS).customerPermissions, isNew ? ["all", "add"] : ["all"]));
          }
        }, [customerHierarchy, role ])

        if (!mappedCustomers) return null;

        return (
          <InnerFrame border>
            <h2>
              {formatMessage({id: LabelEnum.ACCESS_LEVEL, defaultMessage: "Tilgangsnivå"})}
            </h2>
            <Row>
              <Col md={6}>
                <DropdownField
                  header={{id: LabelEnum.ROLE ,defaultMessage:"Rolle"}}
                  field={["role"]}
                  data={roles}
                  title={formatMessage({id: LabelEnum.SELECT_ROLE, defaultMessage: "Velg rolle"})}
                  onChange={({value}: Option) => {
                    // Set the suborg field to the admin firm if admin is selected
                    if(value === admin.id){
                      setFieldValue("subOrgs", [{custId, firmName: companyName}])
                    }

                  }}
                />
              </Col>
              {!isAhp &&
              <Col md={6}>
                <NoFlexContent>
                  <SubHeaderWrapper>
                    {formatMessage({id: LabelEnum.SUB_UNITS, defaultMessage: "subUnits"})}
                  </SubHeaderWrapper>
                  {role === admin.id || role == -1
                    ? <div style={{padding: "10px"}}>{companyName}</div>
                    : <TreeDropdown
                        multiSelect
                        onChange={(values) => {
                          const selectedSuborgs = customersWithPolicy.map(cust => {
                            if(values.includes(cust.customerId.toString())){
                              return {
                                custId: cust.customerId,
                                firmName: cust.customerName
                              }
                            }
                          }).filter(c => c);
                          setFieldValue("subOrgs", selectedSuborgs);
                        }}
                        options={mappedCustomers}
                        title={formatMessage({id: LabelEnum.SELECT_CUSTOMER, defaultMessage: "Velg underavdeling"})}
                        sessionKey={formKey}
                      />
                  }
                </NoFlexContent>
              </Col>}
            </Row>
          </InnerFrame>
        )
      }
    )
  )

export default PermanentRole;
