import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import {Routine} from "redux-saga-routines";
import styled from "styled-components";
import { getMoreTransactions, getTransactionsForAll } from "./Store/routines";
import { AllPermissions, PolicyContext } from "../Policy/Provider";
import { PolicyNameEnum } from "../PolicyNameEnum";
import { AppState } from "@Safemate/Store/types";
import { MapType } from ".";

interface FooterProps{
  getMoreTransactions: Routine<ActionFunctionAny<Action<any>>>;
  getTransactionsForAll: Routine<ActionFunctionAny<Action<any>>>;
  deviceId: number;
  noMoreToFetch: boolean;
  isTrackerOnly: boolean;
  mapType: MapType;
}

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px;
`;

const mapStateToProps = ({appData: {isTrackerOnly}}: AppState) => {
  return {
    isTrackerOnly
  }
}

const mapDispatchToProps = {
  getMoreTransactions,
  getTransactionsForAll
}

const Footer = connect(mapStateToProps, mapDispatchToProps)(({ mapType, getMoreTransactions, getTransactionsForAll, deviceId, isTrackerOnly, noMoreToFetch }: FooterProps) => {

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const { formatMessage } = useIntl(); 
  const fetchMore = () => {
    if(mapType === MapType.SINGLE){
      getMoreTransactions({deviceId});
    }
    else if(mapType === MapType.ALL){
      getTransactionsForAll({
        maxTransactionId: 0,
        transactionCount: 25
      })
    }
  }
  
  if(isTrackerOnly || !permissions.get(PolicyNameEnum.DEVICE_TRANSACTION_LOG).generalPermissions.all) return null;
  return(
    <FooterWrapper>
      {!noMoreToFetch && <Button onClick={fetchMore}>{formatMessage({id: "loadMore", defaultMessage: "Hent mer"})}</Button>}
    </FooterWrapper>
  )
})

export default Footer;
