import {WrappedComponentProps, injectIntl} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import GoogleMap, {Maps} from "google-map-react";
import styled from "styled-components";
import {Row} from "react-bootstrap";
import {IndoorLocation, IndoorLocationFormik} from "../types";
import {MapWrapper} from "./MapWrapper";
import {
  setIndoorLocationValuesChanged,
  SetIndoorLocationValuesChanged,
} from "../../Store/actions";
import {connect} from "react-redux";
import {Device} from "@Safemate/Settings/types";
import {isSaveNeeded} from "@Safemate/Settings/IndoorLocation/utils";
import {StyledField} from "@Safemate/Settings/IndoorLocation";
import { AppState } from "@Safemate/Store/types";

const MapDivWrapper = styled(Row)`
  height: 40em;
  padding: 0px;
  margin: 0px 0px;
  position: relative;
  border: ${props => props.theme.colors.border};;
  
`;

const SearchBar = styled.div`
  position: absolute;
  top: 10px;
  left: 220px;
  background: white;
`;

interface MapProps extends WrappedComponentProps{
  indoorLocation: IndoorLocation,
  initialIndoorLocation: IndoorLocation,
  device: Device,
  setIndoorLocationValuesChanged: SetIndoorLocationValuesChanged
  languageCode: string
}

const mapStateToProps = ({ settings: {indoorLocation: { indoorLocation, initialIndoorLocation}, device: {device}, initialization: {user: languageCode}}}: AppState) => {
  return {
    indoorLocation, languageCode, device, initialIndoorLocation
  }
}

const mapDispatchToProps = {
  setIndoorLocationValuesChanged
};

export const MapIndoor = injectIntl(withFormWrapper<IndoorLocationFormik, MapProps>(
  ({formik: { setFieldValue, values }, intl: { formatMessage }, device, indoorLocation, languageCode,setIndoorLocationValuesChanged}) => {
    const position: string | null = indoorLocation.latLng && indoorLocation.latLng.length > 2 ?
      indoorLocation.latLng
      : device  && device.posLati  && device.posLati.length > 2 ?`${device.posLati},${device.posLong}` : null;

    const infoBoxContent = formatMessage({id: "scanIndoorLocationMapInfowindow",defaultMessage: "Drag to point position"});

    let mapWrapper: MapWrapper = React.useMemo(() => {
      return new MapWrapper(setFieldValue, position != null ? position: "62.91776,10.53775", infoBoxContent, (values.indoorLocation.custId > 0 && values.indoorLocation.deviId == 0) );
    }, [])

    const zoomLevel = position != null ? 18 : 4;

    const center = position != null ? mapWrapper.getPositionFromString(position)  : { lat: 62.91776, lng: 10.53775 }

    React.useEffect( () => {
      //mapWrapper.createMarker();
    }, [device.deviId] );

    React.useEffect( () => {
      setIndoorLocationValuesChanged( isSaveNeeded(values.indoorLocation, indoorLocation) );
    }, [values.indoorLocation] );

    return(
      <MapDivWrapper>
        <GoogleMap
          defaultZoom={zoomLevel}
          defaultCenter={ center }
          bootstrapURLKeys={{key: 'AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no', language: languageCode}}
          options={(map: Maps) => {
            return {
              streetViewControl: true,
              mapTypeControl: true,
              maxZoom: 18,
              mapTypeControlOptions: {
                mapTypeIds: [
                  map.MapTypeId.ROADMAP,
                  map.MapTypeId.SATELLITE,
                  map.MapTypeId.TERRAIN
                ]
              }
            }
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            mapWrapper.init(map, maps);
          }}
        >
        </GoogleMap>
        <SearchBar>
          <StyledField type="text" id={`mapAddress`} name={`indoorLocation.address`} />
        </SearchBar>
      </MapDivWrapper>
    )}
))

export const IndoorLocationMap = connect(mapStateToProps, mapDispatchToProps)(MapIndoor);
