import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { Geofence, GlobalGeofence } from '../../../../Safemate/Settings/Geofence/types';



function* fetchGeofence(action: AppAction<number>){

  try {
    const geofence: Geofence[] = yield call(Api.getGeofence, action.payload);
    yield put(Routines.fetchGeofence.success(geofence));
  }
  catch(e){
    yield put(Routines.fetchGeofence.failure());
  }
}

function* fetchGlobalGeofence(action: AppAction<number>){

  try {
    const geofence: GlobalGeofence[] = yield call(Api.getGlobalGeofence, action.payload);
    yield put(Routines.fetchGlobalGeofence.success(geofence));
  }
  catch(e){
    yield put(Routines.fetchGlobalGeofence.failure());
  }
}


export interface SaveGeofenceResponse{
  savedGeofence: Geofence;
  allGeofences: Geofence[];
}

export interface SavedGeofenceSuccess{
  savedGeofence: Geofence;
  allGeofences: Geofence[];
}




function* geofenceSaga(){
    yield takeLatest(Routines.fetchGeofence.TRIGGER, fetchGeofence);
    yield takeLatest(Routines.fetchGlobalGeofence.TRIGGER, fetchGlobalGeofence);
}

export default geofenceSaga;
