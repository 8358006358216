import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@RoleManager/INITIALIZE'
);

// Roles
export const getRoles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@RoleManager/GET_ROLES'
);
export const deleteRole: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@RoleManager/DELETE_ROLE'
);