import * as React from "react";
import { connect } from "react-redux";
import { WrappedComponentProps, injectIntl } from "react-intl";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import FeetWalk from "../../../../Elements/Icon/Icons/Old/FeetWalk";
import FeetStop from "../../../../Elements/Icon/Icons/Old/FeetStop";
import {Header} from ".";
import { AppState } from "@Safemate/Store/types";

interface TrackingInfoProps extends WrappedComponentProps{
  isTracking: boolean;
}

const TrackingDiv = styled.div<{active: boolean}>`

  ${props => props.active && 
    `color: green;
    div {
      svg {
        fill: green;
      }
    }`}
    display: flex;
    align-items: center;

`;

const CapitalizedText = styled.span`
  text-transform: uppercase;
  font-weight: 300;
  display: inline-block;
  font-size: 18pt;
`;

const TrackingInfo = injectIntl(({ intl: {formatMessage }, isTracking}: TrackingInfoProps) => 
  <Col md={4} sm={4}>
    <Header>{formatMessage({id: "isSafemateTrackingNow", defaultMessage: "Lokaliserer enheten nå?"})}</Header>
    <div>
      {isTracking
        ? <TrackingDiv active={true}>
            <CapitalizedText>{formatMessage({id: "yes", defaultMessage: "Ja"})}</CapitalizedText>
            <FeetWalk inline size="medium"/>
          </TrackingDiv>
        : <TrackingDiv active={false}>
            <CapitalizedText>{formatMessage({id: "no", defaultMessage: "Nei"})}</CapitalizedText>
            <FeetStop inline size="medium"/>
          </TrackingDiv>}
    </div>
  </Col>
)

const mapStateToProps = ({ settings: {tracking: {settings: {tracking}}}}: AppState) => {
  return {
      isTracking: tracking
  }
}


export default connect(mapStateToProps)(TrackingInfo);