export enum Layout{
  VERTICAL,
  HORIZONTAL
}

export const getLayout = (width: number) => {
  if(width >= 768){
    return Layout.HORIZONTAL;
  }
  else{
    return Layout.VERTICAL;
  }
}