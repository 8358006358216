import * as React from "react";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Route} from "react-router-dom";
import { Action, ActionFunctionAny } from "redux-actions";
import { Formik, Form } from "formik";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { Geofence, GlobalGeofence, TrackingInfo } from "./types";
import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { isSaveNeeded } from "./utils";
import {
  fetchGeofence,
  fetchGlobalGeofence, getCustomersGeofence, getGeofenceIndoorLocations,
  saveGeofence
} from "../Store/routines";
import { setInitialSelection, SetInitialSelection, setRefresh, SetRefresh, setGeofence, SetGeofence } from "../Store/actions";
import { injectIntl, WrappedComponentProps, MessageDescriptor } from "react-intl";
import { Link } from "react-router-dom";
import withFormWrapper from "@Elements/Form/formWrapper";
import EditFence from "./editFence";
import { LoaderWrap } from "../../UserAdmin/Users/Edit/styles";
import Loader from "@Elements/Loader";
import { useLocation, useHistory } from "react-router";
import { AppState } from "@Safemate/Store/types";
//import autoTracking from "../Tracking/TrackingMode/autoTracking";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
`;

const Btn = styled.div<{disabled?: boolean}>`
  padding: 10px;
  ${props => props.disabled && "cursor: not-allowed;"}
`;

interface GeofenceProps {
  fetchGeofence: Routine<ActionFunctionAny<Action<any>>>;
  fetchGlobalGeofence: Routine<ActionFunctionAny<Action<any>>>;
  saveGeofence: Routine<ActionFunctionAny<Action<any>>>;
  getGeofenceIndoorLocations: Routine<ActionFunctionAny<Action<any>>>;
  getCustomersGeofence: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  delayDay: string;
  delayNight: string;
  delayMotionless: string;
  callAlert: string;
  geofenceWifiLocationAlert: boolean;
  currentGeofence: Geofence;
  initialFences: Geofence[];
  setInitialSelection: SetInitialSelection;
  initialSelection: boolean;
  loading: boolean;
  autoTracking: boolean;
  disablePositioning: boolean;
}

export interface GeofenceValues {
  geofence: Geofence;
}

export const BaseUrlContext = React.createContext("");

const GeofenceComp = (
  { 
    fetchGeofence, 
    fetchGlobalGeofence, 
    saveGeofence,
    getGeofenceIndoorLocations,
    getCustomersGeofence,
    currentGeofence, 
    initialFences, 
    autoTracking,
    disablePositioning,
    deviId, 
    delayDay, 
    delayNight, 
    delayMotionless, 
    callAlert,
    geofenceWifiLocationAlert,
    loading 
  }: GeofenceProps) => {

  React.useEffect(() => {
    getCustomersGeofence(deviId);
    fetchGeofence(deviId);
    fetchGlobalGeofence();
    getGeofenceIndoorLocations(deviId);
  }, [deviId])

  const location = useLocation();
  const history = useHistory();

  const baseUrl = location.pathname;

  const preventSubmitOnEnter = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if((event.charCode || event.keyCode) === 13){
      event.preventDefault();
    }
  }

  if(loading){
    return (
      <LoaderWrap><Loader/></LoaderWrap>
    )
  }

  return (
    <Formik
      onSubmit={(values) => {
        values.deviId = deviId;
        values.geofence.deviId = deviId;
        saveGeofence({values});
      }
      }
      enableReinitialize
      initialValues={{ deviId, delayDay, delayNight, delayMotionless, callAlert, geofenceWifiLocationAlert, geofence: currentGeofence, autoTracking, disablePositioning }}
    >
      {({ values }) =>
        <BaseUrlContext.Provider value={baseUrl}>
          <Form onKeyDown={preventSubmitOnEnter}>
            <EditFence/>
            <UnsavedCheck dirtyOverride={() => {
              const currentFence = values.geofence;
              const initialValues = initialFences.find(initialFence => initialFence.id === currentFence.id);
              if(initialValues){
                if(isSaveNeeded(currentFence, initialValues))
                  return true;
                else{
                  return false;
                }
              }
              return false;
            }}/>
          </Form>
        </BaseUrlContext.Provider>
     }
     </Formik>
  )
}

const HeaderWrapper = styled.div`
  display: flex;
  padding-bottom: 15px;
  border-bottom: ${props =>props.theme.colors.border};
`;

const BackWrapper = styled.div`
  margin-left: auto;
`;

interface EditHeaderProps extends WrappedComponentProps{
  globalGeofence: GlobalGeofence[];
}

const mapEditHeaderStateToProps = ({ settings: {geofence: { globalGeofence }}}: AppState) => {
  return{
    globalGeofence
  }
}

export const EditHeader = connect(mapEditHeaderStateToProps)(injectIntl(({ intl: { formatMessage }, globalGeofence }: EditHeaderProps) => {

  const baseUrl = React.useContext(BaseUrlContext);

  return(
    <HeaderWrapper>
      <h1>
        {formatMessage({id: "editGeofence", defaultMessage: "Endre geofence"})}
      </h1>
      <BackWrapper>
        <Link to={`${baseUrl}/new`}>
          <Button style={{marginRight: "15px"}}>
          {formatMessage({id: "addNewGeofence", defaultMessage: "Nytt geofence"})}
          </Button>
        </Link>
        {globalGeofence.length !== 0 &&
          <Link to={`${baseUrl}/reuse`}>
            <Button>
              {formatMessage({id: "reuseGeofence", defaultMessage: "Gjenbruk eksisterende geofence"})}
            </Button>
          </Link>
        }
      </BackWrapper>
    </HeaderWrapper>
  )
}))


interface BackHeaderProps extends WrappedComponentProps{
  title: MessageDescriptor;
  setRefresh: SetRefresh;
  geofence: Geofence[];
  setGeofence: SetGeofence;
}

const mapBackHeaderStateToProps = ({ settings: {geofence: { geofence }}}: AppState) => {
  return{
    geofence
  }
}

const mapBackHeaderDispatchToProps = {
  setRefresh,
  setGeofence
}

export const BackHeader = connect(mapBackHeaderStateToProps, mapBackHeaderDispatchToProps)(
  injectIntl(withFormWrapper<GeofenceValues, BackHeaderProps>(({ formik: { setFieldValue }, intl: { formatMessage },  title, setRefresh, setGeofence, geofence }) => {

    const baseUrl = React.useContext(BaseUrlContext);

    return(
      <HeaderWrapper>
        <h1>
          {formatMessage(title)}
        </h1>
        <BackWrapper>
          <Link to={baseUrl}>
            <Button onClick={() => {
              if(geofence.length > 0){
                setFieldValue("geofence", geofence[0]);
                setGeofence(geofence[0]);
              }
            }}>
              {formatMessage({id: "back", defaultMessage: "Tilbake"})}
            </Button>
          </Link>
        </BackWrapper>
      </HeaderWrapper>
    )
  }
)))

const mapStateToProps = ({ settings: {initialization: {deviId}, tracking: {autoTracking , disablePositioning}, device: { device: { delayDay, delayNight, delayMotionless, callAlert, geofenceWifiLocationAlert } }, geofence: { currentGeofence, initialFences, initialSelection, loading }}}: AppState) => {
  return {
    deviId,
    delayDay,
    delayNight,
    delayMotionless,
    callAlert,
    currentGeofence,
    initialFences,
    initialSelection,
    loading,
    autoTracking,
    disablePositioning,
    geofenceWifiLocationAlert
  }
}

const mapDispatchToProps = {
  fetchGeofence,
  fetchGlobalGeofence,
  saveGeofence,
  getGeofenceIndoorLocations,
  getCustomersGeofence,
  setInitialSelection
};


export default connect(mapStateToProps, mapDispatchToProps)(GeofenceComp);
