import {FilterAccountStatus, FilterState} from "../types";
import {Reducer} from "redux";
import {UserAdminActionTypes} from "../actionTypes";
import { saveFilters, loadFilters } from '../utils';
import { OrderFilterEnum } from '../../OrderFilterEnum';


const filterState: FilterState = {
  search: "",
  customer: [],
  role: [],
  status: FilterAccountStatus.ACTIVE,
  orderFilter: OrderFilterEnum
};

const filter: Reducer<FilterState> = (state = {...filterState, ...loadFilters()}, action) => {
  switch (action.type) {
    case UserAdminActionTypes.FILTER_SEARCH:
      const searchState = {
        ...state,
        search: action.payload
      }
      save(searchState);
      return searchState
    case UserAdminActionTypes.FILTER_ROLE:
      
      const roleState ={
        ...state,
        role: action.payload
      }
      save(roleState);
      return roleState
    case UserAdminActionTypes.FILTER_CUSTOMER:
      const customerState = {
        ...state,
        customer: action.payload
      }
      save(customerState);
      return customerState
    case UserAdminActionTypes.FILTER_STATUS:
      const statusState = {
        ...state,
        status: action.payload
      }
      save(statusState);
      return statusState
    case UserAdminActionTypes.FILTER_ORDER:
      const orderState = {
        ...state,
        orderFilter: action.payload
      }
    save(orderState);
    return orderState

    default:
      return state;
  }
};

const save = (state: FilterState) => {
  saveFilters({
    customer: state.customer,
    role: state.role,
    search: "",
    status: state.status,
    orderFilter: state.orderFilter
  })
}

export default filter;
