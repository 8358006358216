import {useIntl} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import GoogleMap, {Maps} from "google-map-react";
import styled from "styled-components";
import {Row} from "react-bootstrap";
import {Settings} from "../types";
import {MapWrapper} from "./MapWrapper";
import {StyledField} from "@Safemate/Settings/IndoorLocation";

const MapDivWrapper = styled(Row)`
  height: 40em;
  padding: 0px;
  margin: 0px 0px;
  position: relative;
  border: ${props => props.theme.colors.border};  
`;

const SearchBar = styled.div`
  position: absolute;
  top: 10px;
  left: 200px;
  background: white;
`;

interface IndoorLocationProps {
  indoorLocationIndex: number;
}

export const IndoorLocationMap = withFormWrapper<Settings, IndoorLocationProps>(({ formik: { values, setFieldValue}, indoorLocationIndex }) => {

    const { formatMessage } = useIntl();
    const position: string | null = values.indoorLocations.indoorLocations[indoorLocationIndex].latLng;

    const infoBoxContent = formatMessage({id: "scanIndoorLocationMapInfowindow",defaultMessage: "Drag to point position"});

    let mapWrapper: MapWrapper = React.useMemo(() => {
      return new MapWrapper(setFieldValue, position ? position: "62.91776,10.53775", infoBoxContent, indoorLocationIndex );
    }, [])

    const zoomLevel = position != null ? 18 : 4;

    const center = position ? mapWrapper.getPositionFromString(position)  : { lat: 62.91776, lng: 10.53775 }

    return(
      <MapDivWrapper>
        <GoogleMap
          defaultZoom={zoomLevel}
          defaultCenter={ center }
          bootstrapURLKeys={{key: 'AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no'}}
          options={(map: Maps) => {
            return {
              streetViewControl: true,
              mapTypeControl: true,
              maxZoom: 18,
              mapTypeControlOptions: {
                mapTypeIds: [
                  map.MapTypeId.ROADMAP,
                  map.MapTypeId.SATELLITE,
                  map.MapTypeId.TERRAIN
                ]
              }
            }
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            mapWrapper.init(map, maps);
          }}
        >
        </GoogleMap>
        <SearchBar>
          <StyledField type="text" id={`mapAddress-${indoorLocationIndex}`} name={`indoorLocations.indoorLocations.${indoorLocationIndex}.address`} />
        </SearchBar>
      </MapDivWrapper>
    )}
)
