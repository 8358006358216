import * as React from "react";
import FieldWrapper, { ComponentProps, GenericDropdown } from "../FieldWrapper";
import { SettingPrefixEnum, SettingEnum } from "../settingEnum";
import { LabelEnum } from "../../LabelEnum";
import { connect } from "react-redux";
import { FirmwareForDevice } from "../../Store/types";
import { useIntl } from "react-intl";
import { AppState } from "@Safemate/Store/types";

interface SelectFirmwareProps{
    firmware: FirmwareForDevice;
    selectedDeviceHardwareId: number;
}

const mapStateToProps = ({defaultSettings: {firmware: { firmware }, deviceHardware: { selectedDeviceHardwareId }}}: AppState) => {
    return{
        firmware,
        selectedDeviceHardwareId
    }
  };

export const SelectFirmware = connect(mapStateToProps)(({ firmware, selectedDeviceHardwareId }: SelectFirmwareProps) => {

    const { formatMessage } = useIntl();

    const mappedOptions = React.useMemo(() => {
        if(firmware[selectedDeviceHardwareId]){
            const opts: {text: string, value: number | null}[] = firmware[selectedDeviceHardwareId].map((f) => {
                return {
                    text: f.name,
                    value: f.fiwaNum
                }
            })
            opts.unshift({text: formatMessage({id: "defaultFirmware", defaultMessage: "Ingen overstyring"}), value: null});
            return opts;
        }
        return [];
    }, [firmware, selectedDeviceHardwareId]);

    return React.createElement(FieldWrapper({
        componentOptions: {
          Component: GenericDropdown,
          props: {}
        },
        prefix: SettingPrefixEnum.DEVICE_SETTING,
        policyPrefix: SettingPrefixEnum.FIRMWARE,
        field: SettingEnum.FIRMWARE,
        title: LabelEnum.SELECT_FIRMWARE,
        tooltip: LabelEnum.SELECT_FIRMWARE_TOOLTIP,
        dataOptions: {
          options: mappedOptions,
          skipMap: true
        }
      }))
}) as React.FunctionComponent<ComponentProps>

