import {connect} from "react-redux";
import {WrappedComponentProps, injectIntl} from "react-intl";
import * as React from "react";

import List from "./List/SpacedList";
import {RootState, Invoice} from "../Store/types";
import { mapHeader, mapHistory, mapXledgerInvoices} from "./utils";
import Header from "./Header";
import  Loader  from "@Elements/Loader";
import {LoaderWrap} from "./Edit/styles";
import {deleteUser} from "../Store/routines";
import { SiteTypeEnum } from "../../SiteTypeEnum";
import { Grid, Row, Col } from "react-bootstrap";
import { AppState } from "@Safemate/Store/types";
import { BillingRoundDate } from "../Models/BillingRoundDate";


interface UserProps extends WrappedComponentProps{
  invoicesInitialized: boolean;
  invoiceList: Invoice[];
  history: BillingRoundDate[];
  isPrivate: boolean;
}

const mapStateToProps = ({ appData: { isPrivate }, partnerBilling: {initialization: {invoicesInitialized}, invoices: {invoiceList, history}}}: AppState) => {
  return{
    invoicesInitialized,
    invoiceList,
    history,
    isPrivate,
  }
}

const mapDispatchToProps = {
  deleteUser
}

const Users = connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(
    ({ invoicesInitialized, history, isPrivate, invoiceList, intl: {formatMessage}}: UserProps) => {
      let mappedInvoices = React.useMemo(() => mapXledgerInvoices(invoiceList), [invoiceList]);
      if(mappedInvoices){
        mappedInvoices.filter(function (e) {return e != null;});
      }


      return (
        <React.Fragment>
          <Header/>
          {!invoicesInitialized
            ? <LoaderWrap><Loader/></LoaderWrap>
            : 
            <React.Fragment>
            {history != null ? <List
              header={mapHeader(formatMessage, false, isPrivate)}
              content={ mapHistory(history) }
            /> : 
            <Col>
                {formatMessage({id: "noBillingHistory", defaultMessage: "No billing history available."})}
            </Col>}
            </React.Fragment>
          }
        </React.Fragment>
      )
    }
  )
);

export default Users;
