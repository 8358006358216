import styled from 'styled-components';

export const StyledInput = styled.input<{active?: boolean}>`
  text-transform: capitalize;
  position: relative;
  background: none;
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius};
  color: ${props => props.theme.colors.textPrimary};
  outline: none;
  transition: 0.3s;
  border-color: ${props => (props.active ? '#adadad' : '')};
  width: 100% !important;
  height: 40px;
  padding: 15px;
  &:hover {
    border-color: #adadad;
  }
`;
export const StyledInputEmpty = styled.input`
  display:none;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const SearchIconWrapper = styled.span`
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 15px;
`;
