import * as React from "react";
import RadioWithArrows, { RadioOptions } from "../../../../Elements/Radiogroup";
import { SoundLightSettings } from "../../types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { injectIntl, WrappedComponentProps, MessageDescriptor } from "react-intl";
import styled from "styled-components";
import { Layout } from "../../LayoutEnum";

interface ValueSelectorProps extends WrappedComponentProps{
    count: number;
    name: string;
    startLevel?: number;
    title?: MessageDescriptor;
    label?: MessageDescriptor[];
    optionsOverride?: any[];
    layout?: Layout;
}

const TitleWrapper = styled.div`

    padding: 10px 0;

`;

export const ValueSelector = injectIntl(
  withFormWrapper<SoundLightSettings, ValueSelectorProps>(
    ({label, optionsOverride, startLevel, title, count, name, layout, intl: {formatMessage}, formik: {setFieldValue, values}}) => {

      const index = startLevel || 0;

      const options = React.useMemo(() => {
          const o: RadioOptions[] = [];
          for(let i = index || 0; i < count + index; i++){
              o.push({
                  value: `${optionsOverride ? optionsOverride[i] : i}`,
                  label: `${label ? formatMessage(label[i]) : i}`
              })
          }
          return o;
      }, [count])

      const value = values[name];

      return(
          <div>
              <TitleWrapper>{title && <label>{formatMessage(title)}</label>}</TitleWrapper>
              <RadioWithArrows 
                  name={name}
                  options={options}
                  setValue={setFieldValue}
                  currentValue={value}
                  vertical={layout === Layout.VERTICAL}
              />
          </div>
      )
    }
  )
)