import {CustomerState} from "../types";
import {Reducer} from "redux";
import {initialize} from "../routines";

export const customerState: CustomerState = {
  customers: []
};

const customer: Reducer<CustomerState> = (state: CustomerState = customerState, action) => {
  switch (action.type) {
    case initialize.SUCCESS:
      return {
        ...state,
        customers: action.payload
      };

    default:
      return state;
  }
};

export default customer;
