import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import { useIntl } from "react-intl";
import { ButtonContainer, Footer } from "./styles";
import { CancelButton, SaveButton } from "@Safemate/DefaultSettings/Settings/styled";
import { RootState } from "../Store/types";
import Loader from "@Elements/Loader";
import { connect } from "react-redux";
import { LoaderWrap } from "@Safemate/Spinner";
import { AppState } from "@Safemate/Store/types";

interface FooterProps{
  settings: any;
  saving: boolean;
}

const mapStateToProps = ({settings: {initialization: { saving }}}: AppState) => {
  return{
    saving
  }
}

export default connect(mapStateToProps)(withFormWrapper<any, FooterProps>(({formik: { dirty, resetForm }, saving, settings}) => {

  const { formatMessage } = useIntl();

  return(
    <Footer>
      <ButtonContainer disabled={!dirty}>
        <CancelButton disabled={!dirty} onClick={() => resetForm(settings)}>
          {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
        </CancelButton>
      {saving 
      ? <LoaderWrap><Loader/></LoaderWrap>
      : <SaveButton disabled={!dirty} type="submit">
          {formatMessage({id: "save", defaultMessage: "Lagre"})}
        </SaveButton>}
      </ButtonContainer>
    </Footer>
  )
}))