import * as React from "react";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import { ComponentWrapper, StyledForm, SettingWrapper, StyledButton, ButtonWrapper, StyledWrapper } from "../styled";
import { useIntl } from "react-intl";
import { DeviceComponents } from "@Safemate/Settings/tabDefinition";
import { SettingEnum as ComponentSettingEnum } from "../../../settingEnum";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";
import SettingsHeader from "../../header";
import { Button, Col } from "react-bootstrap";
import styled from "styled-components";
import { deleteSafemate, factoryReset, smsReboot, smsReset } from "../../../Store/routines";
import confirmModal from "@Safemate/Modals/confirmModal";
import { formatDate } from "@Safemate/utils";
import { AppState } from "@Safemate/Store/types";


interface InformationProps{
  dehaId: number;
  deviId: number;
}

const Information = ({ dehaId, deviId }: InformationProps) => {

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const { formatMessage } = useIntl();

  return(
    <StyledWrapper>
      <SettingsHeader 
        title={formatMessage({id: "informationSettingsTab", defaultMessage: "Annet"})}
        tooltip="otherHeadlineToolTipText"
        tooltipTitle={{
          id: "informationSettingsTab",
          defaultMessage: "Annet"
        }}
      />
      <SettingWrapper>
        {components.has(ComponentSettingEnum.GX8_INFORMATION) 
          && components.get(ComponentSettingEnum.GX8_INFORMATION)?.map(comp => {
            return comp;
        })}
      </SettingWrapper>
    </StyledWrapper>
  )
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }}}: AppState) => {
  return {
    dehaId,
    deviId
  }
}

export default connect(mapStateToProps)(Information);

const InfoText = styled.span`
  color: ${props => props.theme.colors.textHighlight};
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface PhonenumberProps{
  formattedPhoneNumE164: string;
}

const mapPhonenumberStateToProps = ({ settings: {device: { device: { formattedPhoneNumE164 } } }}: AppState) => {
  return{
    formattedPhoneNumE164
  }
}

export const Phonenumber = connect(mapPhonenumberStateToProps)(({ formattedPhoneNumE164 }: PhonenumberProps) => {
  
  const { formatMessage } = useIntl();
  
  return(
    <ComponentWrapper>
    <InfoWrapper>
      {formatMessage({id: "otherMobileNumberParagraphTitle", defaultMessage: "Mobilnummer:"})}&nbsp;
      <p>
        <InfoText>{formattedPhoneNumE164}</InfoText>
      </p>
    </InfoWrapper>
    </ComponentWrapper>
  )
})

interface MacProps{
  deviceMac: string;
}

const mapMacStateToProps = ({ settings: {device: { device: { deviceMac }}}}: AppState) => {
  return {
    deviceMac
  }
}

export const Mac = connect(mapMacStateToProps)(({ deviceMac }: MacProps) => {
  const { formatMessage } = useIntl();

  return(
    <ComponentWrapper>
    <InfoWrapper>
      {formatMessage({id: "DEVICE_MAC", defaultMessage: "Mac:"})}:&nbsp;
      <p>
        <InfoText>{deviceMac}</InfoText>
      </p>
    </InfoWrapper>
    </ComponentWrapper>
  )
})

interface ActiveSinceProps{
  activatedDate: number;
}

const mapActiveSinceStateToProps = ({ settings: {device: { device: { activatedDate }}}}: AppState) => {
  return {
    activatedDate
  }
}

export const ActiveSince = connect(mapActiveSinceStateToProps)(({ activatedDate }: ActiveSinceProps) => {
  const { formatMessage } = useIntl();

  return(
    <ComponentWrapper>
    <InfoWrapper>
      {formatMessage({id: "otherCreatedParagraphTitle", defaultMessage: "Aktiv siden:"})}&nbsp;
      <p>
        <InfoText>
          {formatDate(activatedDate, false, false, false, false, false, formatMessage)}
        </InfoText>
      </p>
    </InfoWrapper>
    </ComponentWrapper>
  )
})

const NoPadCol = styled(Col)`
  padding: 0;
`;

const InfoButton = styled(Button)<{deleteType?: boolean}>`
  width: 300px;
  margin-bottom: 10px;
  ${props => props.deleteType && `
    background: #F12638;
    color: white;
  `}
`;

interface DeviceActionProps{
  deviId: number;
  deviceName: string;
  deviceSerialNumber: string;
  allowDeviceDeletes: boolean;
  isPartner: boolean;
  partnerUser: string;
}

const mapDeviceActionStateToProps = ({ appData: {isPartner, partnerUser}, settings: {initialization: { deviId }, device: { device: { deviceName, deviceSerialNumber}}, general: { allowDeviceDeletes }}}: AppState) => {
  return{
    deviId,
    deviceName,
    deviceSerialNumber,
    allowDeviceDeletes,
    isPartner,
    partnerUser
  }
}

const mapRestoreFactoryDispatchToProps = {
  factoryReset
}

interface RestoreFactoryProps extends DeviceActionProps{
  factoryReset: Routine<ActionFunctionAny<Action<any>>>;
}

export const RestoreFactorySettings = connect(mapDeviceActionStateToProps, mapRestoreFactoryDispatchToProps)
  (({ deviId, deviceName, deviceSerialNumber, factoryReset }: RestoreFactoryProps) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();

  return(
    <ButtonWrapper>
      <StyledButton onClick={() => func(true)}>
        {formatMessage({id: "otherRestoreFactorySettingsBtn", defaultMessage: "Gjenopprett fabrikkinnstillinger"})}
      </StyledButton>
      <Comp
        width="650px"
        title={formatMessage({id: "factoryResetModalTitle", defaultMessage: "Gjenopprett fabrikkinnstillinger"})}
        body={`${formatMessage({id: "factoryResetModalText", defaultMessage: "Advarsel! Vennligst bekreft gjenoppretting av fabrikkinnstillinger for"})} ${deviceName} (${deviceSerialNumber})`}
        confirmText={formatMessage({id: "defaultSettingsReset", defaultMessage: "Gjenopprett fabrikkinnstillinger"})}
        confirmFunc={() => {
          func(false)
          factoryReset(deviId)
        }}
      />
    </ButtonWrapper>
  )
})

interface ResetViaSmsProps extends DeviceActionProps{
  smsReset: Routine<ActionFunctionAny<Action<any>>>;
}

const mapResetViaSmsDispatchToProps = {
  smsReset
}

export const ResetViaSms = connect(mapDeviceActionStateToProps, mapResetViaSmsDispatchToProps)
  (({ deviId, deviceName, deviceSerialNumber, smsReset }: ResetViaSmsProps) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();

  return(
    <ButtonWrapper>
      <StyledButton onClick={() => func(true)}>
        {formatMessage({id: "otherResetDeviceViaSmsBtn", defaultMessage: "Tilbakestill enhet via SMS"})}
      </StyledButton>
      <Comp
        width="650px"
        title={formatMessage({id: "smsResetModalTitle", defaultMessage: "Tilbakestill enhet via SMS?"})}
        body={`${formatMessage({id: "smsResetModalText", defaultMessage: "Advarsel! Vennligst bekreft tilbakestilling av"})} ${deviceName} (${deviceSerialNumber})`}
        confirmText={formatMessage({id: "otherResetDeviceViaSmsBtn", defaultMessage: "Tilbakestill enhet via SMS"})}
        confirmFunc={() => {
          func(false)
          smsReset(deviId)
        }}
      />
    </ButtonWrapper>
  )
})

interface DeleteDeviceProps extends DeviceActionProps{
  deleteSafemate: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDeleteDeviceDispatchToProps = {
  deleteSafemate
}

export const DeleteDevice = connect(mapDeviceActionStateToProps, mapDeleteDeviceDispatchToProps)
  (({ isPartner, partnerUser, deviId, deviceName, deviceSerialNumber, deleteSafemate, allowDeviceDeletes }: DeleteDeviceProps) => {

  const deletesAllowed = isPartner || partnerUser || allowDeviceDeletes;

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();

  if(!deletesAllowed) return null;

  return(
    <NoPadCol md={6}>
      <InfoButton
        deleteType
        onClick={() => func(true)}
      >
        {formatMessage({id: "otherDeleteGX8Btn", defaultMessage: "Slett GX-8"})}
      </InfoButton>
      <Comp
        width="650px"
        title={formatMessage({id: "deleteGx8ModalTitle", defaultMessage: "Slette enhet?"})}
        body={`${formatMessage({id: "deleteGx8ModalText", defaultMessage: "Advarsel! En slettet GX-8 vil ikke kunne slå alarm. Vennligst bekreft sletting av"})} ${deviceName} (${deviceSerialNumber})`}
        confirmText={formatMessage({id: "otherDeleteGX8Btn", defaultMessage: "Slett GX-8"})}
        confirmFunc={() => {
          func(false)
          deleteSafemate(deviId)
        }}
      />
    </NoPadCol>
  )
})

interface RebootDeviceProps extends DeviceActionProps{
  smsReboot: Routine<ActionFunctionAny<Action<any>>>;
}

const mapRebootDeviceDispatchToProps = {
  smsReboot
}

export const RebootDevice = connect(mapDeviceActionStateToProps, mapRebootDeviceDispatchToProps)
  (({ deviId, deviceName, deviceSerialNumber, smsReboot }: RebootDeviceProps) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();

  return(
    <ButtonWrapper>
      <StyledButton onClick={() => func(true)}>
        {formatMessage({id: "otherRestartGX8Btn", defaultMessage: "Restart device"})}
      </StyledButton>
      <Comp
        width="650px"
        title={formatMessage({id: "rebootGx8ModalTitle", defaultMessage: "Restart enhet?"})}
        body={`${formatMessage({id: "smsRebootModalText", defaultMessage: "Advarsel! Vennligst bekreft omstart av"})} ${deviceName} (${deviceSerialNumber})`}
        confirmText={formatMessage({id: "otherRebootGX8Btn", defaultMessage: "Restart device"})}
        confirmFunc={() => {
          func(false)
          smsReboot(deviId)
        }}
      />
    </ButtonWrapper>
  )
})