import { connect } from "react-redux";
import * as React from "react";
import { connect as formikConnect } from "formik";
import styled from "styled-components";
import { ClimaxReport } from "../../Store/types";
import { DeviceSettings } from "../types";
import { useIntl } from "react-intl";
import { Button } from "react-bootstrap";
import { climaxReportGroups, climaxReportAlert } from "./options";
import Trash from '../../../../Elements/Icon/Icons/Trash';
import { reportsChanged } from "../../Store/actions";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { input } from "@Theme/common";
import { Option } from "@Elements/Dropdown/types";
import { AppAction, AppState } from "@Safemate/Store/types";
import { ComponentProps } from "../FieldWrapper";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";

const ButtonRow = styled.div`
  margin: 15px 0px;
  padding: 5px;
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
`;

const Th = styled.th`
  text-align: center;
`;

const ReportWrapper = styled.div`
  border: ${props => props.theme.colors.border};
  padding: 10px;
  margin: 15px;
`;

const FullSizeInput = styled.input`
  width: 100%;
`;

const InputWrapper = styled.div`
  margin: 10px 0px;
`;

interface ClimaxReportProps{
    climaxConfig: boolean;
    selectedCustomer: ICustomerWithPolicy;
    reportsChanged:  (changed: boolean) => AppAction<boolean>
}

const mapStateToProps = ({ defaultSettings: {settings: { climaxConfig }, customer: { selectedCustomer }}}: AppState) => {
    return {
        climaxConfig,
        selectedCustomer
    }
}

const mapDispatchToProps = {
    reportsChanged
}

const ClimaxReportSelector = connect(mapStateToProps, mapDispatchToProps)(
    formikConnect<ClimaxReportProps, DeviceSettings>((
        {formik: { values: { climaxReport }, setFieldValue, setFieldTouched }, climaxConfig, selectedCustomer, reportsChanged}) => {
    
    const { formatMessage } = useIntl();

    if(!climaxConfig){
        return null;
    }

    const addReport = () => {
        if(climaxReport.length < 20){
            const updatedReports = [...climaxReport];
            const priority = updatedReports.length > 0 ? updatedReports[updatedReports.length - 1].priority + 1 : 1; 
            updatedReports.push({
                id: 0,
                priority,
                deviId: 0,
                custId: selectedCustomer.customerId,
                url: "",
                reportgroup: 1,
                alertlevel: 0
            })
            reportsChanged(true);
            setFieldValue("climaxReport", updatedReports);
        }
    }

    const removeReport = (index: number) => {
        const updatedReports = [...climaxReport];
        updatedReports.splice(index, 1);
        for(let i = 0; i < updatedReports.length; i++){
            updatedReports[i].priority = i+1;
        }
        reportsChanged(true);
        setFieldValue("climaxReport", updatedReports);
    }

    
    return(
        <React.Fragment>
            {climaxReport.map((report, index) => {
                return <Report 
                        report={report} 
                        setFieldValue={setFieldValue} 
                        setFieldTouched={setFieldTouched} 
                        removeReport={removeReport}
                        index={index}
                        custId={selectedCustomer.customerId}
                        reportsChanged={reportsChanged}
                    />
            })}
            {climaxReport.length < 20 &&
                <ButtonRow>
                    <Button onClick={addReport}>{formatMessage({id: "addRow", defaultMessage: "Legg til report"})}</Button>
                </ButtonRow>
            }
        </React.Fragment>
    )
})) as React.FunctionComponent<ComponentProps>

interface ReportProps{
    setFieldTouched: Function;
    setFieldValue: Function;
    report: ClimaxReport;
    index: number;
    removeReport: Function;
    custId: number;
    reportsChanged:  (changed: boolean) => AppAction<boolean>
}

const Report = ({report, index, removeReport, setFieldValue, custId, reportsChanged}: ReportProps) => {

    const disabled = (report.deviId === 0 && report.custId === 0);
    const { formatMessage } = useIntl();

    return(
        <ReportWrapper>
        <Table>
            <thead>
                <tr>
                <th>{formatMessage({id: "priority", defaultMessage: "Prioritet"})}</th>
                <th>{formatMessage({id: "group", defaultMessage: "Gruppe"})}</th>
                <th>{formatMessage({id: "alertLevel", defaultMessage: "Varslingsnivå"})}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>
                    {report.priority}
                </td>
                <td>
                    {disabled ? report.reportgroup :
                    <Dropdown
                        size={{width: input.width.small}}
                        disabled={disabled}
                        title={formatMessage({id: "group", defaultMessage: "Gruppe"})}
                        options={climaxReportGroups}
                        initial={report.reportgroup}
                        onChange={(object: Option | Option[]) => {
                            const value = (object as Option).value;
                            reportsChanged(true);
                            setFieldValue(`climaxReport.${index}.reportgroup`, value)
                        }}
                    />}
                </td>
                <td>
                    {disabled ? climaxReportAlert.find(r => r.value === report.alertlevel)?.text :
                    <Dropdown
                        size={{width: "150px"}}
                        disabled={disabled}
                        title={formatMessage({id: "alertlevel", defaultMessage: "Alertlevel"})}
                        options={climaxReportAlert}
                        initial={report.alertlevel}
                        onChange={(object: Option | Option[]) => {
                            const value = (object as Option).value;
                            reportsChanged(true);
                            setFieldValue(`climaxReport.${index}.alertlevel`, value)
                        }}
                    />}
                </td>
                <td>
                    {disabled || 
                    <span onClick={() => removeReport(index)}>
                        <Trash style={{ width: '20px', height: '20px', margin: '0 auto' }} />
                    </span>}
                </td>
                </tr>
            </tbody>
        </Table>
        <InputWrapper>
            <FullSizeInput
                placeholder={report.url || formatMessage({id: "endpoint", defaultMessage: "Endepunkt"})}
                value={disabled ? "" : report.url}
                onChange={(event) => {
                    const value = event.target.value;
                    reportsChanged(true);
                    setFieldValue(`climaxReport.${index}.url`, value)
                    if(report.custId === 0){
                        setFieldValue(`climaxReport.${index}.custId`, custId)
                        setFieldValue(`climaxReport.${index}.id`, 0)
                    }
                }}
                type="text"
            />
        </InputWrapper>
        </ReportWrapper>
    )
}

export default ClimaxReportSelector;
