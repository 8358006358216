import * as React from "react";
import Toggle from "@Elements/Switch/Toggle";
import {Action, ActionFunctionAny} from "redux-actions";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import { setSwitchValue, setAllowDeviceDeletes} from "../../Store/routines";

interface BillingProps{
  individualBilling: boolean;
  custId: number;
  setSwitchValue: Routine<ActionFunctionAny<Action<any>>>;
  custTree: number[];

}

const mapDispatchFromState = {
  setSwitchValue,
}

export const BillingGrouping = connect(null, mapDispatchFromState)((({individualBilling, custId, custTree, setSwitchValue}: BillingProps) => {
  
    return(
      <React.Fragment>
        <div>
        <Toggle
          onChange={(value: boolean) => {
            setSwitchValue({value, custId, custTree});
          }}
          checked={individualBilling}
        />
        </div>
      </React.Fragment>
    )
    })
  )

  const mapDispatchFromStateForDeviceDeleteProps = {
    setAllowDeviceDeletes,
  }

  interface DeviceDeleteProps{
    allowDelete: boolean;
    custId: number;
    setAllowDeviceDeletes: Routine<ActionFunctionAny<Action<any>>>;
    custTree: number[];

  }


  export const AllowDeviceDeletes = connect(null, mapDispatchFromStateForDeviceDeleteProps)((({allowDelete, custId, custTree, setAllowDeviceDeletes}: DeviceDeleteProps) => {
  
    return(
      <React.Fragment>
        <div>
        <Toggle
          onChange={(value: boolean) => {
              setAllowDeviceDeletes({value, custId, custTree});
          }}
          checked={allowDelete}
        />
        </div>
      </React.Fragment>
    )
  })
)
