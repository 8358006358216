import {call, put, race, take, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction, IRace } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure } from '../../../Feedback/reducer';
import { Role } from '../types';

function* initialize(action: AppAction<null>){
  try{
    yield put(Routines.getRoles());
    const {success, failure}: IRace<Array<Role>> = yield race({
      success: take(Routines.getRoles.SUCCESS),
      failure: take(Routines.getRoles.FAILURE)
    });

    if(success){
      yield put(Routines.initialize.success());
    }
    else{
      yield put(Routines.initialize.failure());
    }
  }
  catch(e){
    yield feedbackFailure(e);
    console.log(e);
    yield put(Routines.initialize.failure());
  }
}

function* initializationSaga(){
  yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
