import * as React from "react";
import { Grid, Col, Row, Button } from "react-bootstrap";
import styled from "styled-components";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { Device } from "../types";
import { formatDate } from "../../utils";
import { saveImage, removeImage, updateTraining, connectDevice } from "../Store/routines";
import { confirmModal } from "../General/Tabs/deleteData";
import { Checkbox } from "../../../Elements/CheckboxFormik";
import Trained from "../../../Elements/Icon/Icons/Trained";
import Note from "./note";
import { supportGsmStrength } from "../tabDefinition";
import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";
import {DeviceHardwareEnum, isGx8} from "../../DeviceHardwareEnum";
import Message from "./message";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";
import { WrapDiv } from "../Sensor/styles";
import { convertScale } from "@Safemate/DeviceList/DeviceList/TableElements";
import SignalBars from "@Safemate/DeviceList/DeviceList/signalBars";

const ConnectButton = styled(Button)`
  margin: 4px;
`;

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Label = styled.label`
  width: calc(50% - 2.5px);
  margin-top: 5px;
  margin-right: 2.5px;
  display: inline-block;
  line-height: 4.3em;
`;

const StyledButton = styled.button`
  width: calc(50% - 2.5px);
  margin-top: 5px;
  margin-left: 2.5px;
  display: inline-block;
  line-height: 4.3em;
`;

const ImageWrapper = styled.div`
  display: flex;
  background: ${props => props.theme.colors.backgroundSecondary};
  max-width: 100%;
  height: 270px;
  white-space: nowrap;
  text-align: center; 
  margin: 0;
  img {
    margin: auto;
    max-width: 100%;
    max-height: 270px;
    vertical-align: middle;
  }
`;

const ButtonWrapper = styled.div`
  label{
    height: 4.5em;
  }
  button{
    height: 4.5em;
  }
`;

const Table = styled.table`
  td{
    padding: 5px;
  }
`;

const TrainedWrapper = styled.div`
  padding: 5px;
`;

const TrainedLabel = styled.div`
  margin-top: 10px;
`;

const GsmRow = styled.td`
  display: flex;
  align-items: center;
`;

const StatusRow = styled.td`
  text-transform: capitalize;
`;

const CopyButton = styled.button`
display: block;
width: 40px;
height: 40px;

cursor: pointer;

${props =>
  `
    background-color: ${props.theme.colors.backgroundSecondary};
    `
}
background-image: url('../../../../proffweb/images/clipboard.png');
background-size: 60% auto;
background-position: center center;
background-repeat: no-repeat;
border: 1px solid rgba(0,0,0,.29);
border-bottom-color: rgba(0,0,0,.36);
border-radius: 3px;
box-shadow: 0 1px 1px rgba(0,0,0,.12);

&:before {
  content: '';
  display: none;
  z-index: 9998;
  margin-top:40px;
  width: 0;
  height: 0;
  margin-left: 12px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0,0,0,.72);
}

&:after {
  content: 'Copy to Clipboard';
  display: none;
  margin-top:2px;
  z-index: 9999;
  top: 40px;
  left: -37px;
  width: 114px;
  height: 36px;

  color: #fff;
  font-size: 10px;
  line-height: 36px;
  text-align: center;

  background: rgba(0,0,0,.72);
  border-radius: 3px;
  position: absolute;
  left: 240px;
  top: 47px;
}

&:hover {
  ${props =>
    `
      background-color: ${props.theme.colors.backgroundTertiary};
      `
  }  
  &:before, &:after {
    display: block;
  }
}

&:active, &:focus {
  outline: none;
  
  &:after {
    content: 'Copied!';
  }
}
`;

interface InfoProps extends WrappedComponentProps{
  device: Device;
  deviId: number;
  imageUUID: string;
  isBeta: boolean;
  isPrivate: boolean;
  saveImage: Routine<ActionFunctionAny<Action<any>>>;
  updateTraining: Routine<ActionFunctionAny<Action<any>>>;
  connectDevice: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ appSettings: {beta}, appData: {isPrivate}, settings: {device: { device }, initialization: {deviId}, info: { imageUUID }}}: AppState) => {
  return{
    device,
    deviId,
    imageUUID,
    isBeta: beta,
    isPrivate
  }
}

const mapDispatchToProps = {
  saveImage,
  updateTraining,
  connectDevice
}

const Info = connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(({ isBeta, isPrivate, device, deviId, intl: { formatMessage }, saveImage, updateTraining, connectDevice, imageUUID }: InfoProps) => {

    const isTwin = device.twinChild;
    
    const eview = device.eview || device.eview07B;
    const takit = device.takit;
    const toplovo = device.toplovo;
    const peaktech = device.peaktech;
    const climax = device.climax;
    const lastConnectionClick = device.lastConnectionClick ? new Date(device.lastConnectionClick) : new Date();
    const MinuteSinceClick = device.lastConnectionClick ? new Date(lastConnectionClick.getTime() + 60000) : new Date(lastConnectionClick.getTime() - 60000);
    const connectionResetDate = device.connectionResetDate ? new Date(device.connectionResetDate) : new Date(lastConnectionClick.getTime() - 60000);

    const [disabled, setDisabled] = React.useState(MinuteSinceClick > new Date());
    const [frontEndClick, setFrontEndClick] = React.useState(new Date());

    React.useEffect(() => {
      const lastMinuteClick = setInterval(() => {
        // if there is a last connection click we add 1 minute to that value, if there isn't we subtract 1 minute from the current time
        const wait1MinuteSinceClick = device.lastConnectionClick ? new Date(lastConnectionClick.getTime() + 60000) : new Date(lastConnectionClick.getTime() - 60000);

        if(wait1MinuteSinceClick < new Date() && frontEndClick < new Date()){
          setDisabled(false);
        }else{
          setDisabled(true);
        }
      }, 10000)
  
      return () => {
        // Cleanup
        clearTimeout(lastMinuteClick);
      }
    })

    
    const handleClick = (deviId:number, eview: boolean, peaktech: boolean, takit: boolean, toplovo: boolean) => {
      connectDevice({deviId, eview, peaktech, takit, toplovo, lastConnectionClick});
      setDisabled(true);
      setFrontEndClick(new Date(new Date().getTime() + 60000));
    }

    const fallbackImg = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const src = event.currentTarget.src;
      if (src != 'proffweb/images/menu/person.svg')
        event.currentTarget.src = 'proffweb/images/menu/person.svg'
    }

    function updateNote(note:String){

    };

    const gsmStrength = supportGsmStrength.get(device.dehaId);
    let strength = 0

    if(gsmStrength) {

      const offset = gsmStrength.low === 0 ? 0 : -(gsmStrength.low);
      const scale =  100 / (gsmStrength.high + offset);
      strength = (device.gsmStrength + offset) * scale;

    }

    const removeFirmwareVersion = isGx8(device.dehaId);
    const removeStatus = isGx8(device.dehaId);
    const displayMac = isGx8(device.dehaId);

    const trained = device.trainingApprovedBy !== 0;

    return(
      <Grid fluid>
        <Row style={{ paddingBottom: '10px' }}>
          <Col md={4} sm={5}>
            <ImageWrapper>
              <img
                onError={fallbackImg}
                src={`/ajax/file/userimage/${deviId}/?${imageUUID}`}
              />
            </ImageWrapper>
            {!isTwin &&
            <ButtonWrapper>
              <FileInput
                id="file"
                name="file"
                type="file"
                accept="image/png,image/jpeg,image/jpg"
                onChange={(e) => {
                  if(e.target.files)
                  saveImage({
                    deviceId: deviId,
                    file: e.target.files[0]
                  })
                }}
              />
              <Label className="btn btn-dark" htmlFor="file">
                {formatMessage({id: "addPicture", defaultMessage: "Legg til bilde"})}
              </Label>
              <RemovePicture/>
            </ButtonWrapper>}
          </Col>
          <Col md={4} sm={7}>
            <Table>
              <tbody>
                <tr>
                  <td>{formatMessage({id: "gsmNumber", defaultMessage: "GSM-nummer"})}</td>
                  <td>:</td>
                  <td>{device.formattedPhoneNumE164}</td>
                  <CopyButton onClick={() => {navigator.clipboard.writeText(device.formattedPhoneNumE164)}}></CopyButton>
                </tr>
                <tr>
                  <td>{formatMessage({id: "imei", defaultMessage: "IMEI"})}</td>
                  <td>:</td>
                  <td>{device.deviceImei}</td>
                  <CopyButton onClick={() => {navigator.clipboard.writeText(device.deviceImei)}}></CopyButton>
                </tr>
                {displayMac && <tr>
                  <td>{formatMessage({id: "DEVICE_MAC", defaultMessage: "Mac:"})}</td>
                  <td>:</td>
                  <td>{device.deviceMac}</td>
                  <CopyButton onClick={() => {navigator.clipboard.writeText(device.deviceMac)}}></CopyButton>
                </tr>}
                {gsmStrength &&
                  <tr>
                    <td>{formatMessage({id: "statusBarGsmSignal", defaultMessage: "GSM signal"})}</td>
                    <td>:</td>
                    <WrapDiv title={`${convertScale(strength)}/5`}>
                      <SignalBars dehaId={device.dehaId} size={30} strength={device.gsmStrength} />
                    </WrapDiv>
                </tr>
                }
                {!removeFirmwareVersion &&
                <tr>
                  <td>{formatMessage({id: "firmwareVersion", defaultMessage: "Firmware-versjon"})}</td>
                  <td>:</td>
                  <td>{device.fiwaNum}</td>
                </tr>}
                <tr>
                  <td>{formatMessage({id: "activeSinceInfo", defaultMessage: "Aktiv siden"})}</td>
                  <td>:</td>
                  <td>{formatDate(device.activatedDate, false, false, false, false, false, formatMessage)}</td>
                </tr>
                <tr>
                  <td>{formatMessage({id: "lastSelfCheckInfo", defaultMessage: "Siste selvsjekk"})}</td>
                  <td>:</td>
                  <td>{formatDate(device.lastMsgIn, true, false, false, false, false, formatMessage)}</td>
                </tr>
                <tr>
                  <td>{formatMessage({id: "firstActivationInfo", defaultMessage: "Først aktivert"})}</td>
                  <td>:</td>
                  <td>{formatDate(device.activatedDate, true, false, false, false, false, formatMessage)}</td>
                </tr>
                {!removeStatus && <tr>
                  <StatusRow>{formatMessage({id: "status", defaultMessage: "Status"})}</StatusRow>
                  <td>:</td>
                  <td><DeviceStatus/></td>
                </tr>}
                { device.watchRemovedEnabled && <tr>
                  <StatusRow>{formatMessage({id: "watchRemovedInfoBoxText", defaultMessage: "Watch is worn by user"})}</StatusRow>
                  <td>:</td>
                  <td>{device.watchRemoved? formatMessage({id: "no", defaultMessage: "NO"}) : formatMessage({id: "yes", defaultMessage: "Yes"})}</td>
                </tr>}
                {!isPrivate && (device.eview || device.eview07B || device.peaktech || device.takit || device.toplovo) && <tr>
                  <td>{formatMessage({id: "tcpConnection", defaultMessage: "TCP forbindelse"})}</td>
                  <td>:</td>
                  <td>{device.connected 
                    ? formatDate(device.connectedSince, false, false, false, false, false, formatMessage) 
                    : formatMessage({id: "panelOffline", defaultMessage: "Frakoblet"})}
                  </td>
                </tr>}
              </tbody>
            </Table>
            {!isPrivate && <TrainedWrapper>
              <Checkbox
                readOnly={isTwin}
                disable={isTwin}
                outerDiv={{float: "none"}}
                value={trained}
                name={"trained"}
                id={"trained"}
                label={formatMessage({id: "userTraining", defaultMessage: "Bruker har fått opplæring"})}
                labelComp={trained && <Trained size="medium" inline />}
                labelStyle={{ display: 'flex', alignItems: 'center' }}
                onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                  updateTraining({
                    deviceId: deviId,
                    status: event.currentTarget.checked
                  })
                }}
              />
              {trained &&
              <TrainedLabel>
                <label>
                  <span>
                    {`${formatMessage({id: "trainingApprovedBy", defaultMessage: "Opplæring godkjent av"})} ${device.trainingApprovedByName}`}
                  </span>
                </label>
              </TrainedLabel>}
            </TrainedWrapper>}
          </Col>
          <Col md={4} sm={12}>
            <Note updateNote={updateNote} device={device} custId={device.ownedByCustomer}/>
          </Col>
          {(device.dehaId === DeviceHardwareEnum.WATCH_TWO || device.dehaId === DeviceHardwareEnum.WATCH_FIVE) && isBeta &&
          <Col md={4} sm={12}>
            <Message/>
          </Col>}
        </Row>
      </Grid>
    )
  })
)

export default Info;

interface RemovePictureProps extends WrappedComponentProps{
  removeImage: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
}

const mapRemovePictureStateToProps = ({ settings: {initialization: {deviId}}}: AppState) => {
  return{
    deviId
  }
}

const mapRemovePictureDispatchToProps = {
  removeImage
}

const RemovePicture = connect(mapRemovePictureStateToProps, mapRemovePictureDispatchToProps)(
  injectIntl(({intl: { formatMessage }, removeImage, deviId}: RemovePictureProps) => {

    const { Comp, func } = confirmModal();

    return(
      <React.Fragment>
        <StyledButton onClick={() => func(true)} className="btn btn-dark">
          {formatMessage({id: "removePicture", defaultMessage: "Fjern bilde"})}
        </StyledButton>
        <Comp
          title={`${formatMessage({id: "remove", defaultMessage: "Fjern"})} ${formatMessage({id: "userPicture ", defaultMessage: "brukerbilde"})}`}
          body={
            <p>
              <span>{formatMessage({id: "removePictureDesc", defaultMessage: "Er du sikker på at du vil fjerne dette bildet? Handlingen kan ikke angres."})}</span>
            </p>
          }
          confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
          confirmFunc={() => {
            removeImage(deviId);
            func(false);
          }}
        />
      </React.Fragment>
    )
  })
)

interface DeviceStatusProps extends WrappedComponentProps{
  device: Device;
}

const mapDeviceStaticStateToProps = ({ settings: {device: {device }}}: AppState) => {
  return{
    device
  }
}

export const DeviceStatus = connect(mapDeviceStaticStateToProps)(
  injectIntl(({intl: { formatMessage: fm }, device}: DeviceStatusProps) => {

    let status: string = fm({id: "ok", defaultMessage: "Ok"});

    if(device.neverUsed) status = fm({id: "new", defaultMessage: "Ny"});
    else if(device.inAlarmMode) status = fm({id: "alarm", defaultMessage: "Alarm"});
    else if(device.offline) status = fm({id: "offline", defaultMessage: "Avskrudd"});
    else if(device.failedSelfCheck) status = fm({id: "selfCheckStatusHover", defaultMessage: "Feilet selvsjekk"});
    else if(device.batteryLow) status = fm({id: "lowBattery", defaultMessage: "lavt batteri"});
    else if(device.staticTooLong) status = fm({id: "static", defaultMessage: "Ikke i aktiv bruk"});
    else if(device.deviceStatus !== null){
      status = fm({id: device.deviceStatus, defaultMessage: device.deviceStatus});
    }
    else if(!device.connected){
      status = fm({id: "panelOffline", defaultMessage: "Frakoblet"});
    }

    return(
      <span>{`${status.charAt(0).toUpperCase()}${status.slice(1)}`}</span>
    )  
  })
)
