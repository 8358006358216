import * as React from "react";
import withFormWrapper from "@Elements/Form/formWrapper";
import { ModalBody, ModalDiv, ModalHeader } from "@Safemate/EventSetupNew/Body/ModalStyles";
import { SettingEnum } from "../settingEnum";
import { EventSetupSettings } from "../types";
import { Caret, TimeTable } from "@Safemate/EventSetupNew/Body/TableElementStyles";
import { StyledButton, TimeInput } from "@Safemate/EventSetupNew/Body/FormElementStyles";
import { ButtonDiv } from "@Safemate/EventSetupNew/Body/WrapperStyles";
import { useIntl } from "react-intl";
import { getValidHour, getValidMinute, formatTime } from "@Safemate/EventSetupNew/Body/utils";

interface TimeProps{
  type: Type;
  index: number;
  display: (display: boolean) => void;
}

enum Action{
  INCREASE,
  DECREASE
}

enum TimeType{
  MINUTE,
  HOUR
}

export enum Type{
  FROM = "From",
  TO = "To"
}


export const Time = withFormWrapper<EventSetupSettings, TimeProps>(
  ({ display, type, index, formik: { setFieldValue, initialValues: { deviceEventSetups : initial}, values: { deviceEventSetups } } }) => {

  const { formatMessage } = useIntl();

  const prefix = `${SettingEnum.EVENT_SETUP}.${index}.warnTime${type}`; 

  const time = deviceEventSetups[index][`warnTime${type}String`];

  const hours = time.split(":")[0];
  const minutes = time.split(":")[1];

  const updateTime = (action: Action, timeType: TimeType) => {

    let hourFormatted: string | number = hours;
    let minuteFormatted: string | number = minutes;

    switch(timeType){
      case TimeType.HOUR:{
        const hourValue = parseInt(hours, 10) + (action === Action.INCREASE ? +1 : -1);
        hourFormatted = formatTime(getValidHour(hourValue))
        break;
      }
      case TimeType.MINUTE:{
        const minuteValue = parseInt(minutes, 10) + (action === Action.INCREASE ? +5 : -5);
        minuteFormatted = formatTime(getValidMinute(minuteValue));
        break;
      }
    }
    

    const timeString = `${hourFormatted}:${minuteFormatted}`;
    const timeNumber = parseInt(timeString.replace(":", ""), 10);
    setFieldValue(prefix, timeNumber);
    setFieldValue(`${prefix}String`, timeString);
  }

  return(
    <ModalDiv>
      <ModalHeader>
        {formatMessage({ id: 'time', defaultMessage: 'Time' })}
      </ModalHeader>
      <ModalBody>
        <TimeTable>
          <thead>
            <tr>
              <th>
                <Caret
                  tabIndex={0}
                  id={`increase-hour-${type}`}
                  onClick={() => updateTime(Action.INCREASE, TimeType.HOUR)}
                  onKeyDown={event =>
                    event.key === 'Enter' && updateTime(Action.INCREASE, TimeType.HOUR)
                  }
                  up
                  size={45}
                />
              </th>
              <th />
              <th>
                <Caret
                  tabIndex={0}
                  id={`increase-minute-${type}`}
                  onClick={() => updateTime(Action.INCREASE, TimeType.MINUTE)}
                  onKeyDown={event =>
                    event.key === 'Enter' && updateTime(Action.INCREASE, TimeType.MINUTE)
                  }
                  up
                  size={45}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TimeInput
                  id={`hour-${type}`}
                  value={hours}
                  disabled
                />
              </td>
              <td>:</td>
              <td>
                <TimeInput
                  id={`minute-${type}`}
                  value={minutes}
                  disabled
                />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>
                <Caret
                  tabIndex={0}
                  id={`decrease-hour-${type}`}
                  onClick={() => updateTime(Action.DECREASE, TimeType.HOUR)}
                  onKeyDown={event =>
                    event.key === 'Enter' && updateTime(Action.DECREASE, TimeType.HOUR)
                  }
                  down
                  size={45}
                />
              </th>
              <th />
              <th>
                <Caret
                  tabIndex={0}
                  id={`decrease-minute-${type}`}
                  onClick={() => updateTime(Action.DECREASE, TimeType.MINUTE)}
                  onKeyDown={event =>
                    event.key === 'Enter' && updateTime(Action.DECREASE, TimeType.MINUTE)
                  }
                  down
                  size={45}
                />
              </th>
            </tr>
          </tfoot>
        </TimeTable>
      </ModalBody>
      <ButtonDiv>
        <StyledButton
          id={`confirm-${type}`}
          onClick={() => display(false)}
        >
          {formatMessage({ id: 'ok', defaultMessage: 'Ok' })}
        </StyledButton>
        <StyledButton
          id={`cancel-${type}`}
          onClick={() => {
            setFieldValue(prefix, initial[index][`warnTime${type}`]);
            setFieldValue(`${prefix}String`, initial[index][`warnTime${type}String`]);
            display(false);
          }}
        >
          {formatMessage({
            id: 'cancel',
            defaultMessage: 'Cancel'
          })}
        </StyledButton>
      </ButtonDiv>
    </ModalDiv>
  )
})