export enum LabelEnum{
    TRACKING_ON = "trackingOn",
    ALARM_SHARED = "alarmShared",
    SAFEMATES = "safemates",
    SELECT_CUSTOMER = "selectCustomer",
    SELECT_DEVICE_TYPE = "selectDeviceType",
    SELECT_ALARM_FILTER = "selectAlarmFilter",
    SEND = "send",
    CANCEL = "cancel",
    RESET = "reset",
    SHARE = "share",
    SHARE_ALARM_DESC = "shareAlarmDesc2",
    OK = "ok",
    BATTERY_LEVEL = "batteryLevel",
    TIME = "time",
    DATE = "date",
    LAST_MESSAGE_FROM_DEVICE = "lastMessageFromDevice",
    PHONE_NUMBER = "phoneNumber",
    INFO_ABOUT = "infoAbout",
    MORE_INFO = "moreInfo",
    IN_TRACKING = "inTracking",
    NOT_IN_TRACKING = "notInTracking",
    SERIAL_NUMBER = "T24",
    UNIT_NAME = "unitName",
    NO_DEVICES_FOUND = "noDevicesFound",
    SHOW_PHONE_NUMBER = "safematesPhoneHover",
    START_TRACKING = "safematesTrackHover",
    START_TRACKING_PART_ONE = "trackNowQ1",
    START_TRACKING_PART_TWO = "trackNowQ2",
    TRACKING = "tracking",
    SETTINGS = "safematesSettingsHover",
    SHOW_USER_IMAGE = "safematesShowImageHover",
    SHOW_MAP = "safematesToMapHover",
    SHOW_LOG = "toLogSvgTooltip",
    SHOW_EVENT_SETUP = "safematesEventSetupHover",
  
    // Alarm filters
    ALARM = "alarm",
    GEOFENCE = "geofence",
    BATTERY = "battery",
    STATIC = "static",
    SELFCHECK = "selfcheck",
    OUTDATEDPOSITION = "outdatedposition",
    OFFLINE = "offline",
    UNKNOWN = "unknown",
  
    // Order filters
    DEFAULT = "default",
    LAST_CONTACT_ASC = "lastContactAsc",
    LAST_CONTACT_DESC = "lastContactDesc",
    NAME = "name",
    NOTES_ADDED_DESC = "notesAddedDesc",
    DEVICE_LIST_SORT = "deviceListSort",
    DEVICE_LIST_SORT_PARTNER = "deviceListSortPartner",

    CUSTOMER_ID_ASC = "customerIdAsc",
    PRICE_GROUP_ASC = "priceGroupAsc",
    NO_OF_DEVICES_DESC = "noOfDevices",
  }
  