import {SettingsActionTypes as Actions} from "./actionTypes";
import { AppAction } from "../../../Safemate/Store/types";
import { Geofence } from "../Geofence/types";
import { GeofenceTypeEnum } from "../Geofence/GeofenceTypeEnum";
import { Layout } from "../LayoutEnum";
import { ControlState } from "../Geofence/Controls";
import { DeviceMac } from "../Indoor/types";
import { IWalktest } from "../Walktest/types";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import {Client} from "@stomp/stompjs";
import {Subscription} from "@Safemate/Settings/Store/types";

// Init

export type SetLayout = (layout: Layout) => AppAction<Layout>

export const setLayout: SetLayout = (layout) => ({
  type: Actions.SET_LAYOUT,
  payload: layout
})

// Cleanup

export type Cleanup = () => AppAction<null>

export const cleanup: Cleanup = () => ({
  type: Actions.CLEANUP,
  payload: null
})

// Geofence
export type SetEditing = (editing: boolean) => AppAction<boolean>
export type SetGeofence = (geofence: Geofence) => AppAction<Geofence>
export type SetGeofenceType = (type: GeofenceTypeEnum) => AppAction<GeofenceTypeEnum>
export type SetControlState = (state: ControlState) => AppAction<ControlState>
export type SetRefresh = (token: string) => AppAction<string>;
export type SetInitialSelection = (status: boolean) => AppAction<boolean>;

export const setEditing: SetEditing = (editing) => ({
  type: Actions.SET_EDITING,
  payload: editing
});

export const setGeofence: SetGeofence = (geofence) => ({
  type: Actions.SET_GEOFENCE,
  payload: geofence
});

export const setGeofenceType: SetGeofenceType = (type) => ({
  type: Actions.SET_GEOFENCE_TYPE,
  payload: type
})

export const setControlState: SetControlState = (state) => ({
  type: Actions.SET_CONTROL_STATE,
  payload: state
})

export const setRefresh: SetRefresh = (token) => ({
  type: Actions.SET_GEOFENCE_REFRESH,
  payload: token
})

export const setInitialSelection: SetInitialSelection = (status) => ({
  type: Actions.SET_INITIAL_SELECTION,
  payload: status
}) 

// Tracking
export type SetTrackingAdvanced = (advanced: boolean) => AppAction<boolean>

export const setTrackingAdvanced: SetTrackingAdvanced = (advanced) => ({
  type: Actions.SET_TRACKING_ADVANCED,
  payload: advanced
})

// Indoor

export type SetDeviceMac = (deviceMac : DeviceMac) => AppAction<DeviceMac>

export const setDeviceMac: SetDeviceMac = (deviceMac) => ({
  type: Actions.SET_INDOOR_DEVICE_MAC,
  payload: deviceMac
})

export type SetDeviceMacs = (deviceMacs : DeviceMac[]) => AppAction<DeviceMac[]>

export const setDeviceMacs: SetDeviceMacs = (deviceMacs) => ({
  type: Actions.SET_INDOOR_DEVICE_MACS,
  payload: deviceMacs
})

export type SetOpenDevice = (openDevice : number) => AppAction<number>

export const setOpenDevice: SetOpenDevice = (openDevice) => ({
  type: Actions.SET_INDOOR_OPEN_DEVICE,
  payload: openDevice
})

export type SetValuesChanged = (flag : boolean) => AppAction<boolean>

export const setValuesChanged: SetValuesChanged = (flag) => ({
  type: Actions.SET_INDOOR_VALUES_CHANGED,
  payload: flag
})

export type SetSelectedIndexes = (indexes : number[]) => AppAction<number[]>

export const setSelectedIndexes: SetSelectedIndexes = (indexes) => ({
  type: Actions.SET_INDOOR_SELECTED_INDEXES,
  payload: indexes
})

export type SetActiveIndex = (index : number) => AppAction<number>

export const setActiveIndex: SetActiveIndex = (index) => ({
  type: Actions.SET_INDOOR_ACTIVE_INDEX,
  payload: index
})

export type SetScannedMinutes = (scannedMinutes : number) => AppAction<number>

export const setScannedMinutes: SetScannedMinutes = (scannedMinutes) => ({
  type: Actions.SET_INDOOR_SCANNED_MINUTES,
  payload: scannedMinutes
})

//Walktest

export type UpdateWalktest = (walktest: IWalktest) => AppAction<IWalktest>

export const updateWalktest: UpdateWalktest = (walktest) => ({
  type: Actions.UPDATE_WALKTEST,
  payload: walktest
})

// Indoor location
export type SetIndoorLocation = (indoorLocation : IndoorLocation) => AppAction<IndoorLocation>

export const setIndoorLocation: SetIndoorLocation = (indoorLocation) => ({
  type: Actions.SET_INDOOR_LOCATION,
  payload: indoorLocation
})

export type SetIndoorLocations = (indoorLocations : IndoorLocation[]) => AppAction<IndoorLocation[]>

export const setIndoorLocations: SetIndoorLocations = (indoorLocations) => ({
  type: Actions.SET_INDOOR_LOCATIONS,
  payload: indoorLocations
})



export type SetDisplayEdit= (display : boolean) => AppAction<boolean>

export const setDisplayEdit: SetDisplayEdit = (display) => ({
  type: Actions.SET_INDOOR_DISPLAY_EDIT,
  payload: display
})

export type SetDisplayMap= (display : boolean) => AppAction<boolean>

export const setDisplayMap: SetDisplayMap = (display) => ({
  type: Actions.SET_INDOOR_DISPLAY_MAP,
  payload: display
})

export type SetDisplayIndoorDevices= (display : boolean) => AppAction<boolean>

export const setDisplayIndoorDevices: SetDisplayIndoorDevices = (display) => ({
  type: Actions.SET_INDOOR_DISPLAY_INDOOR_DEVICES,
  payload: display
})

export type SetDisplayList= (display : boolean) => AppAction<boolean>

export const setDisplayList: SetDisplayList = (display) => ({
  type: Actions.SET_INDOOR_DISPLAY_LIST,
  payload: display
})

export type SetScan= (display : boolean) => AppAction<boolean>

export const setScan: SetScan = (display) => ({
  type: Actions.SET_INDOOR_DISPLAY_SCAN,
  payload: display
})

export type SetScanStart= (display : boolean) => AppAction<boolean>

export const setScanStart: SetScanStart = (display) => ({
  type: Actions.SET_INDOOR_SCAN_START,
  payload: display
})

export type SetScanInProgress= (display : boolean) => AppAction<boolean>

export const setScanInProgress: SetScanInProgress = (display) => ({
  type: Actions.SET_INDOOR_SCAN_IN_PROGRESS,
  payload: display
})

export type SetScanSuccess = (success : boolean) => AppAction<boolean>

export const setScanSuccess: SetScanSuccess = (success) => ({
  type: Actions.SET_INDOOR_SCAN_SUCCESS,
  payload: success
})

export type SetEdit= (display : boolean) => AppAction<boolean>

export const setEdit: SetEdit = (display) => ({
  type: Actions.SET_INDOOR_SCAN_EDIT,
  payload: display
})

export type SetIndoorLocationValuesChanged = (flag : boolean) => AppAction<boolean>

export const setIndoorLocationValuesChanged: SetIndoorLocationValuesChanged = (flag) => ({
  type: Actions.SET_INDOOR_LOCATION_VALUES_CHANGED,
  payload: flag
})


export type SetWebsocketClient = (client : Client) => AppAction<Client>

export const setWebsocketClient: SetWebsocketClient = (client) => ({
  type: Actions.SET_WEBSOCKET_CLIENT,
  payload: client
})

export type SetSubscription = (subscription : Subscription) => AppAction<Subscription>

export const setSubscription: SetSubscription = (subscription) => ({
  type: Actions.SET_WEBSOCKET_SUBSCRIPTION,
  payload: subscription
})


// Sensor

export type SetHoverRow = (serialNumber: number) => AppAction<number>

export const setHoverRow: SetHoverRow = (serialNumber) => ({
  type: Actions.SET_HOVER_ROW,
  payload: serialNumber
})