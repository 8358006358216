import React from 'react';
import styled from 'styled-components';
import Modal from '.';
import Logo from '../../../assets/images/logo/logo.png';

import { StyledButton } from '../../Roles/Styles/FormElementStyles';

export const Header = styled.h4`
  text-align: center;
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 20px;
  display: block;
`;

export const LogoContainer = styled.div`
  background: url(${Logo}) center center no-repeat;
  height: 100px;
  min-width: 500px;
`;

export const TextContainer = styled.div`
  min-width: 500px;
  text-align: center;
  color: ${props => props.theme.modalColors.text};
  display: block;
  padding: 25px;
  background-color: ${props => props.theme.backgroundSecondary};
  border-radius: 5px 5px 0 0;
`;

export const HeaderContent = props => {
  return (
    <TextContainer>
      <h1>{props.headerText}</h1>
    </TextContainer>
  );
};

export const Body = props => {
  return (
    <div style={{ padding: '20px' }}>
      <p style={{ textAlign: 'center' }}>{props.bodyText}</p>
      <StyledButton
        id="cancel-delete"
        color="#f7ae11"
        className="btn"
        onClick={props.handleCancel}
        style={{ width: '100%' }}
      >
        {props.translations ? props.translations('cancel', 'Cancel') : 'Cancel'}
      </StyledButton>
      {!props.disableDelete &&
      <StyledButton
        id="confirm-delete"
        color="#d60403"
        className="btn"
        onClick={props.confirmDelete}
        style={{ width: '100%' }}
      >
        {props.translations ? props.translations('delete', 'Delete') : 'Delete'}
      </StyledButton>}
    </div>
  );
};

const DeleteModal = ({
  display,
  handleCancel,
  confirmDelete,
  headerText,
  ...props
}) => {
  return (
    <Modal
      display={display}
      headerContent={<HeaderContent headerText={headerText} />}
      bodyContent={
        <Body
          handleCancel={handleCancel}
          confirmDelete={confirmDelete}
          {...props}
        />
      }
      {...props}
    />
  );
};

export default DeleteModal;
