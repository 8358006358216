import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {connect as formikConnect, FormikProps} from "formik";
import {Action, ActionFunctionAny} from "redux-actions";
import { injectIntl, WrappedComponentProps } from "react-intl";
import styled from "styled-components";
import { cancelSettings, deleteUser} from "../Store/routines";
import {RootState, Organization, CustomerArrState} from "../Store/types";
import confirmModal from "../../Modals/confirmModal";
import { CancelButton } from "../Form/styled";
import { AppState } from "@Safemate/Store/types";

const StyledRow = styled(Row)`
  .buttons{
    width: 230px;
    float: right;
    margin-right: -2px;
    @media (max-width: 430px){
      margin-top: 10px;
      margin-left: 5px;
      float: left;
    }
  }
  .billinghistory{
    .row{
      margin-right:-14px;
      float: right;
    }
  }
`;


interface FooterProps<T> extends WrappedComponentProps{
  base: Organization;
  formik: FormikProps<T>;
  cancelSettings: Routine<ActionFunctionAny<Action<any>>>;
  deleteUser: Routine<ActionFunctionAny<Action<any>>>;
  allowDelete: String;
  email: String;
}
const mapStateToProps = ( { myPageBilling: {subUnits: {customers}}} : AppState) => {
  return{
    base: customers
  }
};
const mapDispatchFromState = {
  cancelSettings,
  deleteUser
}

const DeleteButton = styled(Button)`
  margin-left: 5px;
  background: red;
`;

const DeleteCol = styled(Col)`
  float: left;
`;

export const Footer = connect(mapStateToProps, mapDispatchFromState)(
  formikConnect(
    injectIntl(
      ({intl: {formatMessage}, formik: {dirty, initialValues, resetForm}, base, cancelSettings, deleteUser, allowDelete, email}: FooterProps<CustomerArrState>) => {
        const handleCancel = () => {
          cancelSettings(base);
          resetForm(initialValues);
        }
        const {Comp, func} = confirmModal();
      return(
        <StyledRow>
          <DeleteCol md={6}>
          <DeleteButton onClick={() => func(true)}>
              {formatMessage({id: "deleteMyPage", defaultMessage: "Slette"})}
            </DeleteButton>
          </DeleteCol>
          <Col md={12} className="buttons" >
            <CancelButton disabled={(!dirty) } onClick={handleCancel}>
              {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
            </CancelButton>
            <Button type="submit" disabled={(!dirty) } >
              {formatMessage({id: "save", defaultMessage: "Lagre"})}
            </Button>
          </Col>
          <Comp
          title={`${formatMessage({id: "deleteSelf", defaultMessage: "Slett bruker"})}`}
          body={
            <p>
              {!allowDelete
                ? <span>{formatMessage({id: "deleteSelfDesc", defaultMessage: "Er du sikker på at du vil slette denne brukeren?"})}</span>
                :  allowDelete != "UserStillHasDevicesConnected" ? 
                  <span>
                    {`
                      ${formatMessage({id: "theUser", defaultMessage: "Brukeren"})}
                      ${email}
                      ${formatMessage({id: "isTheLastAdminFor", defaultMessage: "er den siste administratoren i"})}
                      ${allowDelete}
                      ${formatMessage({id: "andCantBeDeleted", defaultMessage: "og kan derfor ikke slettes"})}
                    `}
                  </span>
                   : <span>{formatMessage({id: "userHasDevicesConnected", defaultMessage: "Denne brukeren har fremdeles tilkoblede enheter."})}</span>
              } 
            </p>
          }
          info={!!allowDelete}
          confirmText={formatMessage({id: "delete", defaultMessage: "Slette"})}
          confirmFunc={() => {
            deleteUser();
            func(false);
          }}
        />
        </StyledRow>
      )
    })
  )
)

export default Footer;
