export const deviceTransactionLogs = [
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"},
  {value: 7, text: "7"},
  {value: 7, text: "10"},
  {value: 14, text: "14"}
];

export const userTransactionLogs = [
  {value: 5, text: "5"},
  {value: 10, text: "10"},
  {value: 30, text: "30"},
];

export const devicePositionHistoryDepthHours = [
  {value: 1, text: "1"},
  {value: 3, text: "3"},
  {value: 6, text: "6"},
  {value: 12, text: "12"},
  {value: 24, text: "24"},
  {value: 48, text: "48"}
];

export const devicePositionHistoryDepthPosPlots = [
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 10, text: "10"}
];

export const nokEmployeeLoginIdleHour = [
  {value: 0, text: "never"},
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 6, text: "6"},
  {value: 12, text: "12"},
  {value: 24, text: "24"},
  {value: 36, text: "36"},
  {value: 48, text: "48"},
];

export const userInactivityLimitOptions = [
  {value: 0, text: "never"},
  {value: 1, text: "oneYear"},
  {value: 2, text: "twoYears"},
  {value: 3, text: "threeYears"},
  {value: 5, text: "fiveYears"},
];
