import * as React from "react";

import {Tab} from '../../Elements/Tabs';
import { getMain } from "./tabDefinition";



type MapTabs = (settings:string, billingInformation: string, billingHistory: string) => JSX.Element[];

export const mapTabs: MapTabs = (settings, billingInformation, billingHistory) => {
  const comps = getMain();
  const translations = [settings,billingInformation, billingHistory];
  const entries = comps.entries();
  let result = entries.next();
  const tabs: JSX.Element[] = [];
  let i = 0;
  while(!result.done){
    const [ menu, comp ] = result.value;
      tabs.push(
        <Tab name={menu} title={translations[i]}>
          {comp}
        </Tab>
      )
    result = entries.next();
    i++;
  }
  return tabs;
}
