import {DeviceState} from "../types";
import {Reducer} from "redux";
import {getTwin, checkTwinSerialNumber} from "../routines";
import { v4 } from "uuid";

export const deviceState: DeviceState = {
  twinDevice: {
    deviId: 0,
    deviceSNNum: "",
    ownedByCustId: 0
  },
  checkedTwin: {
    serialNumber: "",
    match: false,
    twinCheckUUID: ""
  },
  loadingTwinSerial: false
};

const device: Reducer<DeviceState> = (state: DeviceState = deviceState, action) => {
  switch (action.type) {
    case getTwin.SUCCESS:
      return {
        ...state,
        twinDevice: action.payload
      };

    case checkTwinSerialNumber.TRIGGER:
      return {
        ...state,
        loadingTwinSerial: true
      }

    case checkTwinSerialNumber.SUCCESS:
      return {
        ...state,
        checkedTwin: action.payload
      }

    case checkTwinSerialNumber.FAILURE:
      return {
        ...state,
        checkedTwin: action.payload
      }

    case checkTwinSerialNumber.FULFILL:
      return {
        ...state,
        checkedTwin: {
          ...state.checkedTwin,
          twinCheckUUID: v4()
        },
        loadingTwinSerial: false
      }

    default:
      return state;
  }
};

export default device;
