import * as React from "react";
import { useIntl } from "react-intl";
import { InnerFrame, TrainedWrapper, SubHeaderWrapper } from './styles';
import {Checkbox} from "../../../../Elements/CheckboxFormik";
import {connect} from "formik";
import {connect as reduxConnect} from "react-redux";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {Col, Row} from "react-bootstrap";
import {LabelEnum} from "../../LabelEnum";
import { NoFlexContent } from "@Safemate/RoleEditor/WrapperStyles";
import { AppState, GdprManager } from "@Safemate/Store/types";
import {AllPermissions, PolicyContext} from "../../../Policy/Provider";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { assignGdprManager, removeGdprManager } from "@Safemate/UserAdmin/Store/routines";
import confirmModal from "@Safemate/Modals/confirmModal";

interface TrainingProps{
  gdprManager: GdprManager;
  assignGdprManager: Routine<ActionFunctionAny<Action<any>>>;
  removeGdprManager: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({  appData: { gdprManager } }: AppState) => {
  return {
    gdprManager
  }
}

const mapDispatchToProps = {
  assignGdprManager,
  removeGdprManager
}

const GdprManager = reduxConnect(mapStateToProps, mapDispatchToProps)(
  connect<TrainingProps, UserWithCustomer>(({gdprManager, assignGdprManager, removeGdprManager, formik: { values: {user: {userId }, superAdmin }}}) => {

  const {formatMessage} = useIntl();

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const isSuperadmin = permissions.has(PolicyNameEnum.ADMIN) && permissions.get(PolicyNameEnum.ADMIN)!.generalPermissions.all;

  const { Comp, func } = confirmModal();

  const selected = gdprManager.userId === userId;
  const enabled = selected || !gdprManager.userId;

  if(!isSuperadmin || !superAdmin || !enabled) return null;


  return(
    <Row>
      <TrainedWrapper md={12}>
        <NoFlexContent>
          <Checkbox
            disabled={!enabled}
            value={selected}
            name={"gdprManager"}
            id={"gdprManager"}
            label={formatMessage({id: "gdprManagerLabel", defaultMessage: "Bruker er behandlingsansvarlig"})}
            onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
              func(true);
            }}
          />
        </NoFlexContent>
      </TrainedWrapper>
      <Comp
        standardBtn
        title={formatMessage({id: "editGdprManager", defaultMessage: "Endre behandlingsansvarlig"})}
        body={
          <p>
            {selected 
            ? <span>
                {formatMessage({id: "removeGdprManager", defaultMessage: "Vil du fjerne denne brukeren som behandlingsansvarlig?"})}
              </span>
            : <span>
              {formatMessage({id: "addGdprManager", defaultMessage: "Vil du legge til denne brukeren som behandlingsansvarlig?"})}
            </span>}
          </p>
        }
        confirmText={formatMessage({id: "ok", defaultMessage: "ok"})}
        confirmFunc={() => {
          if(selected){
            removeGdprManager();
          }
          else{
            assignGdprManager(userId);
          }
          func(false);
        }}
      />
    </Row>
  )
}));

export default GdprManager;
