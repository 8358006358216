import {Reducer} from "redux";
import {ActionState} from "../types";
import {UserAdminActionTypes} from "../actionTypes";
import {defaultValues} from "../../Users/Edit/defaultValues";
import {mapUserForEdit} from "../../Users/utils";
import uuid from "uuid";
import {deleteUser, saveUser, getUser, sendNewPassword} from "../routines";

const actionState: ActionState = {
  deleteModal: null,
  sendNewPasswordModal: null,
  edit: {
    isNew: true,
    formKey: uuid.v4(),
    user: defaultValues,
    saving: false
  }
};

const action: Reducer<ActionState> = (state: ActionState = actionState, action: any) => {
  switch (action.type) {

    case saveUser.TRIGGER:
      return {
        ...state,
        edit: {
          ...state.edit,
          saving: true
        }
      }

    case saveUser.FULFILL:
      return {
        ...state,
        edit: {
          ...state.edit,
          saving: false
        }
      }

    case saveUser.SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          user: mapUserForEdit(action.payload),
          isNew: false
        }
      }

    case getUser.SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          user: mapUserForEdit(action.payload),
          isNew: false
        }
      }
    
    case deleteUser.FULFILL:{
      return{
        ...state,
        deleteModal: null
      }
    }
    case UserAdminActionTypes.SET_DELETE_MODAL:
      return {
        ...state,
        deleteModal: action.payload
      };

    case sendNewPassword.FULFILL:{
      return{
        ...state,
        sendNewPasswordModal: null
      }
    }
    case UserAdminActionTypes.SEND_NEW_PASSWORD:
      return {
        ...state,
        sendNewPasswordModal: action.payload
      };

    case UserAdminActionTypes.SET_NEW:
      return{
        ...state,
        edit: {
          ...state.edit,
          isNew: true,
          user: action.payload
        }
      };

    case UserAdminActionTypes.FORM_RESET:
      return{
        ...state,
        edit: {
          ...state.edit,
          formKey: action.payload
        }
      }

    default:
      return state;
  }
};

export default action;
