import styled from "styled-components";
import { Field } from "formik";
import * as React from "react";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Collapse } from "react-collapse";
import FieldWrapper, { ComponentProps, GenericCheckbox, GenericDropdown, GenericTextField} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {LabelEnum} from "../../LabelEnum";
import { MessageRecipients, orderOfEscalationOpts, OrderOfEscalationEnum} from "./options";
import withFormWrapper from "@Elements/Form/formWrapper";
import Dropdown from "@Elements/Dropdown/Dropdown";
import {Option} from "@Elements/Dropdown/types";
import { connect } from "react-redux";
import {OtherSettings, OtherSettingsWithFallback} from "../types";
import { ButtonWrapper, ContentWrap, SaveButton, SettingDiv, SettingHeader, TitleWrap } from "../styled";
import { resetIntegrationPassword, testIntegrationPing } from "@Safemate/DefaultSettings/Store/routines";
import { useIntl } from "react-intl";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { SettingToPolicyMap } from "../tabDefinition";
import { parseCheckboxValue } from "../utils";
import { Checkbox } from "@Elements/CheckboxFormik";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import {Button, Col, Overlay, Row} from "react-bootstrap";
import {Label} from "@Safemate/Settings/Indoor";
import Tooltip from "@Elements/Tooltip";
import { AppState, GdprManager } from "@Safemate/Store/types";
import { GdprWarning } from "@Safemate/UserAdmin";
import InfoPopover from "@Elements/Tooltip/Popover";
import { Eye, EyeSlash } from "@Elements/Icon/Icons/FontAwesome";
import confirmModal from "@Safemate/Modals/confirmModal";

const StyledField = styled(Field)`
  width: 100%;
`;

const StyleCol = styled(Col)`
  padding: 0px 10px 10px 10px;
`;

// Dropdowns
export const MessageRecipient = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  dataOptions: {
    options: MessageRecipients,
    skipMap: true
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION_HANDLER,
  title: LabelEnum.MESSAGE_IN_HANDLER,
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false",
  hideField: SettingEnum.INTEGRATION,
  hideFieldValue: "false",
});

export const MessageAddress = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {width: "100%"}
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION_ENDPOINT,
  title: LabelEnum.MESSAGE_ADDRESS,
  tooltip: LabelEnum.MESSAGE_ADDRESS_TOOLTIP,
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false",
  hideField: SettingEnum.INTEGRATION,
  hideFieldValue: "false",
});

export const IntegrationSecured = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.INTEGRATION_SECURED, defaultMessage: "Secured"}
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION_SECURED,
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false",
  hideField: SettingEnum.INTEGRATION,
  hideFieldValue: "false",
});

export const IntegrationUsername = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {width: "100%"}
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION_USERNAME,
  title: LabelEnum.INTEGRATION_USERNAME,
  tooltip: LabelEnum.INTEGRATION_USERNAME_TOOLTIP,
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false",
  hideField: SettingEnum.INTEGRATION_SECURED,
  hideFieldValue: "false"
});

/*export const IntegrationPassword = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {width: "100%", fieldType: "password"}
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION_PASSWORD,
  title: LabelEnum.INTEGRATION_PASSWORD,
  tooltip: LabelEnum.INTEGRATION_PASSWORD_TOOLTIP,
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false",
  hideField: SettingEnum.INTEGRATION_SECURED,
  hideFieldValue: "false"
});*/

const PasswordSpan = styled.span`
  svg {
    position: relative;
    margin-left: -115%;
    margin-top: 30%;
    cursor: pointer;
    background: none;
    z-index: 9999;
  }

  svg:focus {
    border: none !important;
    outline: none !important;
  }
`

interface IntegrationPasswordProps{
  resetIntegrationPassword: Routine<ActionFunctionAny<Action<any>>>;
  customerId: number;
}

const mapStateToIntegrationPasswordProps = ({ defaultSettings: { customer: { selectedCustomer: { customerId } }} }: AppState) => {
  return {
    customerId
  }
}

const mapDispatchToIntegrationPasswordProps = {
  resetIntegrationPassword
}

export const IntegrationPassword = connect(mapStateToIntegrationPasswordProps, mapDispatchToIntegrationPasswordProps)(withFormWrapper<OtherSettings, IntegrationPasswordProps>(( 
  { resetIntegrationPassword, customerId, formik: { setFieldValue, touched, values: { inheritPassword, hasPassword, integrationPermission, msgIntegrationSecured }} } ) => {

  const { formatMessage } = useIntl();

  const [ displayPassword, setDisplayPassword ] = React.useState(false);

  const isTouched = touched.msgI9nPassword;

  const { Comp, func } = confirmModal();

  const confirm = () => {
    resetIntegrationPassword(customerId);
    func(false);
  }

  if(!msgIntegrationSecured || msgIntegrationSecured === "false") return null;

  return(
    <>
    {inheritPassword && <div>{formatMessage({id: "integrationInheritPassword", defaultMessage: "Denne avdelingen arver passord fra et høyere nivå."})}</div>}
    <SettingDiv style={{display: "flex"}} isChanged={!inheritPassword} key={SettingEnum.INTEGRATION_PASSWORD}>
      {hasPassword 
        ? <span>{formatMessage({id: "integrationPasswordSet", defaultMessage: "Passord har blitt satt, tilbakestill passord for å bytte passord."})}</span>
        : <><Field
            placeholder={formatMessage({id: "password", defaultMessage: "Passord"})}
            style={{width: "100%"}}
            disabled={!integrationPermission || integrationPermission === "false"}
            key={SettingEnum.INTEGRATION_PASSWORD}
            name={SettingEnum.INTEGRATION_PASSWORD}
            type={displayPassword ? "text" : "password"}
          />
          <PasswordSpan>
              {displayPassword 
                ? <Eye disabled={!isTouched} onClick={() => {
                    if(isTouched)
                      setDisplayPassword(false)}
                  } medium/>
                : <EyeSlash disabled={!isTouched} onClick={() => {
                    if(isTouched)
                      setDisplayPassword(true)}
                  } medium/>}
          </PasswordSpan>
        </>}
      <Button onClick={() => func(true)} disabled={!hasPassword}>{formatMessage({id: "clearPassword", defaultMessage: "Tilbakestill passord"})}</Button>
      <Comp
        buttonAlign="center"
        confirmFunc={confirm}
        confirmText={formatMessage({id: "resetPassword", defaultMessage: "Tilbakestill"})}
        title={formatMessage({id: "resetIntegrationPassword", defaultMessage: "Tilbakestill integreringspassord"})}
        body={<p>
          {formatMessage({id: "resetIntegrationPasswordText", defaultMessage: "Er du sikker på at du vil tilbakestille integreringspassord?"})}
        </p>}
      />
    </SettingDiv>
    </>
  )

}))

/*
 <Field
    placeholder="password"
    style={{width: "100%"}}
    disabled={!integrationPermission || integrationPermission === "false"}
    key={SettingEnum.INTEGRATION_PASSWORD}
    name={SettingEnum.INTEGRATION_PASSWORD}
    type={"password"}
  />
*/

const mapDispatchToEndpointProps = {
  testIntegrationPing
}

interface EndpointProps{
  testIntegrationPing: Routine<ActionFunctionAny<Action<any>>>;
}

export const Endpoint = connect(null, mapDispatchToEndpointProps)(withFormWrapper<OtherSettings, EndpointProps>(({testIntegrationPing, formik: { values }}) => {

  const { formatMessage } = useIntl();

  const isValidURL = (url:string) => {
    if(url == "" || url == null){
      return false;
    }
    if (/(https:\/\/.)(www\.)?(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(url)){
      return true;
    }else{
      var res = url.match(/(https:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
      return (res !== null)

    }
  };

  return(
    <React.Fragment>
      {React.createElement(MessageAddress)}
      {React.createElement(IntegrationSecured)}
      {React.createElement(IntegrationUsername)}
      {React.createElement(IntegrationPassword)}
      {
        values.msgIntegration && values.msgIntegration === "true" && <ButtonWrapper>
          <SaveButton onClick={() => testIntegrationPing({msgI9nEndpoint: values.msgI9nEndpoint, msgI9nUsername: values.msgI9nUsername,msgI9nPassword: values.msgI9nPassword})} disabled={!isValidURL(values.msgI9nEndpoint) || values.integrationPermission === "false"}>
            {formatMessage({id: "pingIntegrationEndPoint", defaultMessage: "Ping end point"})}
          </SaveButton>
        </ButtonWrapper>
      }

    </React.Fragment>
  )
}))

const mapStateToOrderEscalationProps = ({ defaultSettings: { customer: { selectedCustomer: { customerId }}, settings: { otherSettings }}}: AppState) => {
  return{
    otherSettings,
    customerId
  }
}

interface OrderEscalationProps{
  otherSettings: OtherSettingsWithFallback;
  customerId: number;
}

export const OrderEscalation = connect(mapStateToOrderEscalationProps)(withFormWrapper<OtherSettings, OrderEscalationProps>(
  ({ formik: { setFieldValue, setFieldTouched, values, touched}, otherSettings, customerId }) => {

    const isChanged = 
      (!!otherSettings[SettingEnum.INTEGRATION_AFTER_CALL] || !!(values.msgI9nAfterCall !== otherSettings[`${SettingEnum.INTEGRATION_AFTER_CALL}WithFallback`] && touched.msgI9nAfterCall))
      || (!!otherSettings[SettingEnum.INTEGRATION_BEFORE_CALL] || !!(values.msgI9nBeforeCall !== otherSettings[`${SettingEnum.INTEGRATION_BEFORE_CALL}WithFallback`] && touched.msgI9nBeforeCall))

    const permissions: Map<PolicyNameEnum, AllPermissions> = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const policy: PolicyNameEnum = SettingToPolicyMap.get(SettingPrefixEnum.INTEGRATION) as PolicyNameEnum;
    const currentPermission = permissions.get(policy).customerPermissions[customerId];

    const { formatMessage } = useIntl();

    const options = React.useMemo(() => {
      return orderOfEscalationOpts.map(opt => {
        return {
          value: opt,
          text: formatMessage({id: opt, defaultMessage: opt})
        };
      });
    }, [])

    let disabled = (!currentPermission.edit && currentPermission.read) || !parseCheckboxValue(true, otherSettings.integrationPermission);
    let selected = OrderOfEscalationEnum.INTEGRATE_AND_CALL_WITHOUT_DELAY;
    let description = LabelEnum.INTEGRATE_AND_CALL_DESC;

    if(parseCheckboxValue(true, values.msgI9nAfterCall)){
      selected = OrderOfEscalationEnum.CALL_FIRST_THEN_INTEGRATE;
      description = LabelEnum.INTEGRATE_CALL_FIRST_DESC;
    }
    else if(parseCheckboxValue(true, values.msgI9nBeforeCall)){
      selected = OrderOfEscalationEnum.INTEGRATE_FIRST_THEN_CALL;
      description = LabelEnum.INTEGRATE_FIRST_DESC;
    }

    const setFields = (integrateBeforeCalls:string, integrateAfterCalls:string, haltCallsUponSuccessulIntegration:string, haltIntegrationUponAnsweredCall:string) => {
      setFieldValue(SettingEnum.INTEGRATION_BEFORE_CALL, integrateBeforeCalls);
      setFieldValue(SettingEnum.INTEGRATION_AFTER_CALL, integrateAfterCalls);
      setFieldValue(SettingEnum.INTEGRATION_HALT_CALL, haltCallsUponSuccessulIntegration);
      setFieldValue(SettingEnum.INTEGRATION_HALT_INTEGRATION, haltIntegrationUponAnsweredCall);
      setFieldTouched(SettingEnum.INTEGRATION_BEFORE_CALL);
      setFieldTouched(SettingEnum.INTEGRATION_AFTER_CALL);
      setFieldTouched(SettingEnum.INTEGRATION_HALT_CALL);
      setFieldTouched(SettingEnum.INTEGRATION_HALT_INTEGRATION);
    }

    const styles: any = {}
    if(!isChanged){
      styles.border = "1px solid green";
    }

    if( values.msgIntegration && values.msgIntegration === "false" ){
      return ( <React.Fragment></React.Fragment> )
    } else {
      return(
        <SettingDiv isChanged={isChanged} key={"orderEscalation"}>
          <SettingHeader>
            <TitleWrap right>
              {formatMessage({id: LabelEnum.INTEGRATION_DROPDOWN, defaultMessage: LabelEnum.INTEGRATION_DROPDOWN})}
            </TitleWrap>
          </SettingHeader>
          {disabled
            ? formatMessage({id: selected, defaultMessage: selected})
            : <Dropdown
              key={"orderEscalation"}
              title={formatMessage({id: LabelEnum.INTEGRATION_DROPDOWN, defaultMessage: LabelEnum.INTEGRATION_DROPDOWN})}
              options={options}
              initial={selected}
              wrapperStyle={styles}
              onChange={(object) => {
                const value = (object as Option).value;

                switch(value){
                  case OrderOfEscalationEnum.INTEGRATE_AND_CALL_WITHOUT_DELAY:{
                    setFields("false", "false", "false", "false");
                    break;
                  }
                  case OrderOfEscalationEnum.CALL_FIRST_THEN_INTEGRATE:{
                    setFields("false", "true", "false", "true");
                    break;
                  }
                  case OrderOfEscalationEnum.INTEGRATE_FIRST_THEN_CALL:{
                    setFields("true", "false", "true", "false");
                    break;
                  }
                }
              }}
            />
          }
          <ContentWrap>
            {formatMessage({id: description, defaultMessage: description})}
          </ContentWrap>
        </SettingDiv>
      )
    }

  })
)

export const IntegrationHaltCall = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      tooltip: LabelEnum.HALT_CALLS_UPON_SUCCESSFUL_INTEGRATION_TOOLTIP,
      label: {id: LabelEnum.HALT_CALLS_UPON_SUCCESSFUL_INTEGRATION, defaultMessage: "Ring etter vellykket integrasjon"}
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION_HALT_CALL,
  hideField: SettingEnum.INTEGRATION_BEFORE_CALL,
  hideFieldValue: "false",
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false"
});

export const IntegrationHaltIntegration  = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      tooltip: LabelEnum.HALT_INTEGRATION_UPON_ANSWERED_CALL_TOOLTIP,
      label: {id: LabelEnum.HALT_INTEGRATION_UPON_ANSWERED_CALL, defaultMessage: "Integrer etter vellykket oppringning"}
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION_HALT_INTEGRATION,
  hideField: SettingEnum.INTEGRATION_AFTER_CALL,
  hideFieldValue: "false",
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false"
});

export const IntegrationFailureFallback  = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      tooltip: LabelEnum.INTEGRATION_FAILURE_FALLBACK_TOOLTIP,
      label: {id: LabelEnum.INTEGRATION_FAILURE_FALLBACK, defaultMessage: "On integration failure call fallback number"}
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION_FAILURE_FALLBACK,
  hideField: SettingEnum.INTEGRATION_AFTER_CALL,
  hideFieldValue: "false",
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false"
});

const validateCountryCode = (countryCode: string) => {
  if(!countryCode){
    return "errorRequired";
  }

  const countryCodePattern = /^([+]|[00]|[0])([0-9]{1,3})$/;
  const match = countryCodePattern.test(countryCode) && countryCode.trim() !== "";
  if(!match){
    return "errorInvalidCountryCode";
  }
}

const validateNumber = (number: string) => {
  if(!number){
    return "errorRequired";
  }

  const numberPattern = /^([0-9]{5,14})$/;
  const match = numberPattern.test(number) && number.trim() !== "";
  if(!match){
    return "errorInvalidNumber";
  }
}

export const IntegrationFailureFallbackNumbers = connect(mapStateToOrderEscalationProps)(withFormWrapper<OtherSettings, OrderEscalationProps>(
  ({ formik: { values, setFieldTouched, errors} }) => {
    if( values.msgI9nFailureFallback && JSON.parse(values.msgIntegration) && JSON.parse(values.msgI9nFailureFallback) && JSON.parse(values.msgI9nAfterCall)  ){
      const { formatMessage } = useIntl();

      React.useEffect(() => {
        // Trigger the error checking so saving is disabled
        setFieldTouched("msgI9nFailureFallbackCountryCode");
      }, [])

      const countryCodeRef = React.useRef(null)
      const numberRef = React.useRef(null);
      return (<React.Fragment>
        <Row style={{margin: "0px"}}>
          <StyleCol xs={12} sm={3}>
            <Label>{formatMessage({id: "msgI9nFailureFallbackCountryCodeLabel", defaultMessage: "Country code"})}</Label>
            {(errors && errors.msgI9nFailureFallbackCountryCode) &&
            <Overlay onHide={() => {}} show={true} target={countryCodeRef.current} placement="top" rootClose>
                <InfoPopover >
                  <p>{formatMessage({id: errors.msgI9nFailureFallbackCountryCode, defaultMessage: errors.msgI9nFailureFallbackCountryCode})}</p>
                </InfoPopover>
            </Overlay>}
            <StyledField
              innerRef={countryCodeRef}
              validate={validateCountryCode}
              style={{width: "75px"}}
              type="text"
              name={`msgI9nFailureFallbackCountryCode`}
            />
          </StyleCol>
          <StyleCol xs={12} sm={9}>
            <Label>{formatMessage({id: "msgI9nFailureFallbackNumberLabel", defaultMessage: "Phone number"})}</Label>
            {(errors && errors.msgI9nFailureFallbackNumber) &&
            <Overlay onHide={() => {}} show={true} target={numberRef.current} placement="top" rootClose>
                <InfoPopover >
                  <p>{formatMessage({id: errors.msgI9nFailureFallbackNumber, defaultMessage: errors.msgI9nFailureFallbackNumber})}</p>
                </InfoPopover>
            </Overlay>}
            <StyledField
              innerRef={numberRef}
              validate={validateNumber}
              type="text"
              name={`msgI9nFailureFallbackNumber`}
            />
          </StyleCol>
        </Row>
      </React.Fragment>)
    } else {
      return (<React.Fragment></React.Fragment>)
    }

  })
)

export const EnableIntegration  = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.ENABLE_INTEGRATION, defaultMessage: "Enable integration"}
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.INTEGRATION,
  field: SettingEnum.INTEGRATION,
  disabledField: SettingEnum.INTEGRATION_PERMISSION,
  disabledFieldValue: "false"
});

interface CustIdProps{
  customerId: number;
}

const mapStateToProps = ({ defaultSettings: { customer: { selectedCustomer: { customerId }}} }: AppState) => {
  return{ 
    customerId
  }
}

export const SensioIntegrationCheck = connect(mapStateToProps)(withFormWrapper<OtherSettings, CustIdProps>(({formik: { values: { msgIntegration, sensioIntegration }, setFieldValue}, customerId}) => {

  if(!parseCheckboxValue(true, msgIntegration)){
    return null;
  }
  
  const { formatMessage } = useIntl();


  const permissions: Map<PolicyNameEnum, AllPermissions> = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const policy: PolicyNameEnum = SettingToPolicyMap.get(SettingPrefixEnum.INTEGRATION) as PolicyNameEnum;
  const currentPermission = permissions.get(policy).customerPermissions[customerId];

  let disabled = !currentPermission.edit && currentPermission.read;
    
  const value = sensioIntegration.integrationRole && sensioIntegration.integrationRole === "sensio";
  
  return(
    <Checkbox
      disabled={disabled}
      id="sensio"
      value={value}
      label={formatMessage({id: "unityIntegration", defaultMessage: "Unity integration"})}
      onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
        if(value){
          setFieldValue(`sensioIntegration.integrationRole`, null);
          setFieldValue(`${SettingEnum.INTEGRATION_HANDLER}`, null);
        }
        else{
          setFieldValue(`sensioIntegration.integrationRole`, event.currentTarget.value);
          setFieldValue(`${SettingEnum.INTEGRATION_HANDLER}`, event.currentTarget.value.toUpperCase());
        }
      }}
    />
  )
}))

export const SensioIntegrationId = connect(mapStateToProps)(withFormWrapper<OtherSettings, CustIdProps>(({ formik: { values: { sensioIntegration } }, customerId }) => {

  const { formatMessage } = useIntl();

  if(sensioIntegration.integrationRole !== "sensio"){
    return null;
  }

  const permissions: Map<PolicyNameEnum, AllPermissions> = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const policy: PolicyNameEnum = SettingToPolicyMap.get(SettingPrefixEnum.INTEGRATION) as PolicyNameEnum;
  const currentPermission = permissions.get(policy).customerPermissions[customerId];

  let disabled = !currentPermission.edit && currentPermission.read;

  return(
    <SettingDiv isChanged={true}>
      <SettingHeader>
        <TitleWrap right>
          {formatMessage({id: LabelEnum.INTEGRATION_ID, defaultMessage: "Unity site ID"})}
        </TitleWrap>
        <Tooltip
          popoverId="integration-id"
          labelId="integrationIdTooltip"
          defaultMessage="Unity Site ID"
        >
            <InformationIcon size="medium" />
        </Tooltip>
      </SettingHeader>
      <Field
        style={{width: "100%"}}
        disabled={disabled}
        name={`sensioIntegration.integrationId`}
        type="text"
      />
    </SettingDiv>
  )
}))

export const GdprIntegrationWarning = () => {
  
  const { formatMessage } = useIntl();

  return(
    <GdprWarning>{formatMessage({id: "gdprWarningIntegration", defaultMessage: "Changing integration endpoints entails risk of data breach. Please configure a user as Behandlingsansvarlig"})}</GdprWarning>
  )
}

const Wrapper = styled.div`
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

const IntegrationWrap = styled.div`
  display: flex;
  align-items: center;
`;

const mapStateToIntegrationProps = ({ appData: { gdprManager } }: AppState) => {
  return {
    gdprManager
  }
}

interface IntegrationProps{
  gdprManager: GdprManager;
}

export const Integration = connect(mapStateToIntegrationProps)(withFormWrapper<OtherSettings, IntegrationProps>(({ gdprManager, formik: { values}}) => {

  return(
    <Wrapper>
      {!gdprManager.userId && <GdprIntegrationWarning/>}
      <IntegrationWrap>
        {React.createElement(EnableIntegration)}
        {React.createElement(SensioIntegrationCheck)}
      </IntegrationWrap>
      <Collapse isOpened={parseCheckboxValue(true, values.msgIntegration)}>
        {React.createElement(SensioIntegrationId)}
        {React.createElement(Endpoint)}
        {React.createElement(OrderEscalation)}
        {React.createElement(IntegrationHaltCall)}
        {React.createElement(IntegrationHaltIntegration)}
        {React.createElement(IntegrationFailureFallback)}
        {React.createElement(IntegrationFailureFallbackNumbers)}
      </Collapse>
    </Wrapper>
  )
})) as React.FunctionComponent<ComponentProps>
