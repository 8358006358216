import {all, fork} from "redux-saga/effects";
import SetupSaga from "./initialization";
import DashboardSaga from "@Safemate/Dashboard/Store/Saga/index";
import PartnerDashboardSaga from "@Safemate/PartnerDashboard/Store/Saga/index";
import PartnerBillingSaga from "@Safemate/PartnerBilling/Store/Saga/index";
import UserAdminSaga from "@Safemate/UserAdmin/Store/Saga/index";
import SafemateListSaga from "@Safemate/DeviceList/Store/Saga/index";
import SettingSaga from "@Safemate/Settings/Store/Saga/index";
import BillingSaga from "@Safemate/Billing/Store/Saga/index";
import PartnerOrganizationSaga from "@Safemate/PartnerOrganization/Store/Saga/index";
import SubUnitSaga from "@Safemate/SubUnits/Store/Saga/index";
import AddSafemateSaga from "@Safemate/AddSafemate/Store/Saga/index";
import EventSetupSaga from "@Safemate/EventSetupNew/Store/Saga/index";
import MapSaga from "@Safemate/map/Store/Saga/index";
import MyPageSaga from "@Safemate/MyPage/Store/Saga/index";
import PrivateMyBillingSaga from "@Safemate/PrivateMyBilling/Store/Saga/index";
import RoleEditorSaga from "@Safemate/RoleEditor/Store/Saga/index";
import RoleManagerSaga from "@Safemate/RoleManager/Store/Saga/index";
import DefaultSettingSaga from "@Safemate/DefaultSettings/Store/Saga/index";
import LogSaga from "@Safemate/Log/Store/Saga/index";
import TranslationOverlaySaga from "@Safemate/TranslationModule/TranslateOverlay/Store/Saga";
import TranslationPageSaga from "@Safemate/TranslationModule/TranslatePage/Store/Saga";
import EventViewSaga from "@Safemate/EventView/Store/Saga";
import SessionSaga from "@Safemate/Session/Store/Saga";

function* rootSaga(){
  yield all([
    fork(SetupSaga),
    fork(DashboardSaga),
    fork(PartnerDashboardSaga),
    fork(PartnerBillingSaga),
    fork(UserAdminSaga),
    fork(SafemateListSaga),
    fork(SettingSaga),
    fork(BillingSaga),
    fork(PartnerOrganizationSaga),
    fork(SubUnitSaga),
    fork(AddSafemateSaga),
    fork(EventSetupSaga),
    fork(MapSaga),
    fork(MyPageSaga),
    fork(PrivateMyBillingSaga),
    fork(RoleEditorSaga),
    fork(RoleManagerSaga),
    fork(DefaultSettingSaga),
    fork(LogSaga),
    fork(TranslationOverlaySaga),
    fork(TranslationPageSaga),
    fork(EventViewSaga),
    fork(SessionSaga)
  ])
}
export default rootSaga;
