import _axios, {AxiosInstance, AxiosPromise, AxiosResponse} from 'axios';
import {AlarmFilterEnum} from "@Safemate/DeviceList/AlarmFilterEnum";
import { Policies } from '@Safemate/Policy/types';
import DeviceEventSetup from '../Model/DeviceEventSetup';
import Ahp from '../Model/Ahp';
import { Capabilities } from '@Safemate/Settings/Store/types';
import { SaveDeviceEventSetup } from './Saga/deviceEventSetup';
import { addRolesToRequest } from '@Safemate/utils';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getPoliciesForDevice = async (deviceId: number) => {
  const url: string = `/userroles/getpoliciesfordevice/${deviceId}`;
  const response: AxiosResponse<Policies> = await axios.get(url);
  return response.data;
}

export const getDevice = async (deviceId: number) => {
  const url: string = `/deviceeventsetup/${deviceId}${addRolesToRequest()}`;
  const response = await axios.get(url);
  return response.data;  
};

export const updateName = async (deviceId: number, deviceName: string) => {
  const url = `/device/${deviceId}/changenameto`;
  const response = await axios.post(url, {deviceName});
  return response.data;
}

export const updateOverride = async (deviceId: number, value: boolean) => {
  const url = `/deviceeventsetup/override/${deviceId}/${value}${addRolesToRequest()}`;
  const response: AxiosResponse<boolean> = await axios.put(url);
  return response.data;
}

export const getDeviceEventSetups = async (deviceId: number) => {
  const url: string = `/deviceeventsetup/device/${deviceId}`;
  const response: AxiosResponse<DeviceEventSetup[]> = await axios.get(url);
  return response.data;
}

export const getDefaultDeviceEventSetups = async (deviceId: number) => {
  const url: string = `/deviceeventsetup/default/${deviceId}`;
  const response: AxiosResponse<DeviceEventSetup[]> = await axios.get(url);
  return response.data;
}

export const deleteDeviceEventSetup = async (eventSetupId: number) => {
  const url: string = `/deviceeventsetup/${eventSetupId}`;
  const response = await axios.delete(url);
  return response.data;
}

export const saveDeviceEventSetup = async ({ deviceId, eventSetups, sequentialCalling }: SaveDeviceEventSetup) => {
  const url: string = `/deviceeventsetup/device/${deviceId}/${sequentialCalling}`;
  const response: AxiosResponse<DeviceEventSetup[]> = await axios.post(url, eventSetups);
  return response.data;
}

export const getDefaultAhp = async (deviceId: number) => {
  const url: string = `/device/ownersdefaultahp/${deviceId}`;
  const response: AxiosResponse<Ahp> = await axios.get(url);
  return response.data;
}

export const connectAhp = async (deviceId: number) => {
  const url: string = `/device/connecttoownersdefaultahp/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const disconnectAhp = async (deviceId: number) => {
  const url: string = `/device/disconnectfromownersdefaultahp/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getAhpPrivateLink = async () => {
  const url: string = `/settings/ahpPrivateLink`;
  const response: AxiosResponse<string> = await axios.get(url);
  return response.data;
}

export const getCapabilities = async (deviceId: number) => {
  const url: string = `/capabilities/device/${deviceId}`;
  const response: AxiosResponse<Capabilities> = await axios.get(url);
  return response.data;
}