export enum SortEnum{
  DEFAULT = "DEFAULT",
  COUNT_ASC = "COUNT_ASC",
  COUNT_DESC = "COUNT_DESC",
  SOURCE_ASC = "SOURCE_ASC",
  SOURCE_DESC = "SOURCE_DESC",
  TEXT_ASC = "TEXT_ASC",
  TEXT_DESC = "TEXT_DESC",
  LABEL_ASC = "LABEL_ASC",
  LABEL_DESC = "LABEL_DESC"
}