import Feedback from "@Safemate/Feedback";
import { MarketEnum } from "@Safemate/MarketEnum";
import {Logoheader} from "@Safemate/Modals/Logo";
import { LogoEnum } from "@Safemate/Modals/Logo/LogoEnum";
import { AppState } from "@Safemate/Store/types";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { BackgroundDiv, ContentDiv } from "../styles";
import { login } from "../Store/routines";
import { IdentityProvider } from "@Safemate/Model/Sso";
import { useHistory } from "react-router";


interface LoginProps{
  market: MarketEnum;
  prePopulatedEmail: string;
  showTfa: boolean;
  ssoSelect: boolean;
  login: Routine<ActionFunctionAny<Action<any>>>;
  idps: IdentityProvider[];
}

const mapStateToProps = ({ appSettings: { market }, appData: {sso: {idps, ssoSelect}}, session: { prePopulatedEmail, showTfa } }: AppState) => {
  return {
    market,
    prePopulatedEmail,
    showTfa,
    ssoSelect,
    idps
  }
}

const mapDispatchToProps = {
  login
}

const Login = connect(mapStateToProps, mapDispatchToProps)(({market, idps, ssoSelect, prePopulatedEmail, showTfa, login}: LoginProps) => {

  const [ sso, setSso ] = React.useState(false);
  const [ username, setUsername ] = React.useState(prePopulatedEmail);
  const [ password, setPassword ] = React.useState("");
  const [ tfa, setTfa ] = React.useState("");
  const { formatMessage } = useIntl();
  const history = useHistory();

  const keyUpEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter"){
      doLogin();
    }
  }

  const changeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  }

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  }

  const changeTfa = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTfa(event.currentTarget.value);
  }

  const doLogin = () => {
    login({
      username, password, TWO_FACTOR_KEY: tfa, formatMessage, history
    })
  }

  const normalLogin = <React.Fragment>
    <input id="loginId" value={username} className="focus" type="email" name="username" 
      placeholder={formatMessage({id: "loginUsernamePlaceholder", defaultMessage: "username"})}
      onKeyUp={keyUpEvent}
      onChange={changeUsername}
    />
    <input id="passwordId" type="password" name="password"
      placeholder={formatMessage({id: "loginPasswordPlaceholder", defaultMessage: "password"})}
      onKeyUp={keyUpEvent}
      onChange={changePassword}
    />
    {showTfa && <input id="tfaId" type="email" name="tfa"
      placeholder={formatMessage({id: "tfaCode", defaultMessage: "tfa"})}
      onKeyUp={keyUpEvent}
      onChange={changeTfa}
    />}
    <Button onClick={doLogin} disabled={(!username || !password) || (showTfa && !tfa)} type="button" >
      {formatMessage({id: "logIn", defaultMessage: "Logg inn"})}
    </Button>
    {ssoSelect && 
    <Button onClick={() => setSso(true)} id="ssoButtonId" type="button" className="btn btn-light btn-sso">
      <a>
        <img src="/images/logos/login-sso.svg"/>
        <span>{formatMessage({id: "ssoLogin", defaultMessage: "Other login"})}</span>
      </a>
    </Button>
    }
  </React.Fragment>

  const ssoLogin = <React.Fragment>
    <div id="ssoLoginId">
      <div className="sso-desc">{formatMessage({id: "ssoDesc", defaultMessage: "ssoDesc"})}</div>
      <div style={{marginBottom: "5px"}} className="sso-list">
        {idps.map(idp => {
          return(
          <button style={{margin: "3px"}} className="btn btn-default sso-row">
            <a href={idp.request}>
              <img src={idp.logo}/>
              <span data-bind="text: $data.name">{formatMessage({id: idp.name, defaultMessage: idp.name})}</span>
            </a>
          </button>
          )
        })}
      </div>
      <Button onClick={() => setSso(false)} id="ssoBackId" type="button" className="btn btn-light btn-sso">
        <a>
          <img src='/images/logos/login-sso.svg'/>
          <span>{formatMessage({id: "ssoBack", defaultMessage: "back"})}</span>
        </a>
      </Button>
  </div>
  </React.Fragment>

  return sso ? ssoLogin : normalLogin
})

export default Login;