import styled, { keyframes } from 'styled-components';
import ReactModal from 'react-modal';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background-color: #000;
  z-index: 1040;
`;

export const OverlayContent = styled.div`
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  transition: visibility 0.3s linear;
  display: flex;
  visibility: ${props => (props.display ? 'visible' : 'hidden')};
  animation: ${props => (props.display ? fadeIn : fadeOut)} 0.3s linear;
`;

export const StyledModal = styled(ReactModal)`
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
`;

export const Body = styled.div`
  background: ${props => props.theme.colors.backgroundPrimary};
  overflow: auto;
  border-radius: 0 0 5px 5px;
`;

export const Header = styled.div`
  background: #2c2e32;
  min-width: 500px;
  position: relative;
`;
