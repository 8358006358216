import * as React from "react";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { VerticalRouteTabs, Tab } from "../../../Elements/Tabs";
import { fetchGeneral } from "../Store/routines";
import { connect } from "react-redux";
import { getGeneralTabs, DeviceComponents, SettingToPolicyMap } from "../tabDefinition";
import { DeviceHardwareEnum, isGx8 } from "../../DeviceHardwareEnum";
import { SettingEnum } from "../settingEnum";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { AllPermissions, PolicyContext } from "../../Policy/Provider";
import {User} from "../../MyPage/Store/types";
import {Device} from "@Safemate/Settings/types";
import { AppState } from "@Safemate/Store/types";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";

interface GeneralProps{
  deviId: number;
  dehaId: number;
  device: Device;
  user: User;
  fetchGeneral: Routine<ActionFunctionAny<Action<any>>>;
  isPrivate: boolean;
}

const General = ({ deviId, dehaId, fetchGeneral, user, device, isPrivate }: GeneralProps) => {

  
  React.useEffect(() => {
    fetchGeneral(deviId);
  }, [])

  const components: Map<SettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) ? DeviceComponents.get(dehaId)! : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const tabs: (JSX.Element | null)[] = getGeneralTabs(dehaId, device, isPrivate, user.beta, user.translator).map(({tab, comp, title}) => {

    const contains = components.get(tab);

    if(contains){
      const policyToCheck = SettingToPolicyMap.get(tab)!;

      if(policyToCheck && checkPermissionsForCustomer(permissions, policyToCheck, device.ownedByCustomer)){

        return (
          <Tab
            key={tab}
            renderTitle={() => title}
            name={tab}
          >
            {comp}
          </Tab>
        )
      }
    }
    return null;
  }).filter(tab => tab);

  if(tabs.length < 1) return null;

  return(
    <React.Fragment>
      <VerticalRouteTabs>
        {tabs}
      </VerticalRouteTabs>
    </React.Fragment>
  )
}


const mapStateToProps = ({ appData:{ currentContext, isPrivate }, settings: {initialization: {deviId, dehaId, user}, device:{device}}}: AppState) => {
  return {
    deviId,
    dehaId,
    user,
    device,
    currentContext,
    isPrivate
  }
}

const mapDispatchToProps  = {
  fetchGeneral,
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
