import * as React from "react";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { prefComOpts, twoWayDECTTimerOpts, answerIncomingOpts, callbackTimerOpts, externalAntennaOpts } from "./options";
import { TIME_OFFSET, status } from "@Elements/Form/utils";
import { ToggleWrap } from "@Elements/Form/ToggleWrap";
import { ComponentWrapper, StyledForm, ButtonContainer, Footer } from "../styled";
import { Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { DeviceComponents } from "@Safemate/Settings/tabDefinition";
import { SettingEnum as ComponentSettingEnum } from "../../../settingEnum";
import { DeviceHardwareEnum, isGx8 } from "@Safemate/DeviceHardwareEnum";
import { Formik } from "formik";
import SettingsHeader from "../../header";
import { CancelButton, SaveButton } from "../../../../DefaultSettings/Settings/styled";
import { RootState } from "@Safemate/Settings/Store/types";
import { saveCommunication } from "../../../Store/routines";
import { CommunicationForm } from "../../types";
import { AppState } from "@Safemate/Store/types";

interface CommunicationSettingsProps{
  dehaId: number;
  deviId: number;
  communication: CommunicationForm;
  saveCommunication: Routine<ActionFunctionAny<Action<any>>>;
}

const CommunicationSettings = ({ dehaId, deviId, communication, saveCommunication }: CommunicationSettingsProps) => {

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const { formatMessage } = useIntl();

  return(
    <Formik
      onSubmit={(values) => {
        saveCommunication({
          deviceId: deviId,
          settings: values
        })
      }}
      initialValues={communication}
      enableReinitialize
    >
    {({ dirty, resetForm}) => 
      <StyledForm>
        <SettingsHeader 
          title={formatMessage({id: "communicationSettingsTab", defaultMessage: "Kommunikasjonsinnstillinger"})}
          tooltip="communicationSettingsTabToolTipText"
          tooltipTitle={{
            id: "communicationSettingsTab",
            defaultMessage: "Kommunikasjonsinnstillinger"
          }}
        />
        <div>
          {components.has(ComponentSettingEnum.GX8_COMMUNICATION) 
            && components.get(ComponentSettingEnum.GX8_COMMUNICATION)?.map(comp => {
                if(isGx8(dehaId)){
                  return comp
                }
                return <ComponentWrapper>{comp}</ComponentWrapper>
            })}
        </div>
        <Footer>
          <ButtonContainer disabled={!dirty}>
            <CancelButton disabled={!dirty} onClick={() => resetForm(communication)}>
              {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
            </CancelButton>
            <SaveButton disabled={!dirty} type="submit">
              {formatMessage({id: "save", defaultMessage: "Lagre"})}
            </SaveButton>
          </ButtonContainer>
        </Footer>
      </StyledForm>}
    </Formik>
  )
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: { communication }}}: AppState) => {
  return {
    dehaId,
    deviId,
    communication
  }
}

const mapDispatchToProps = {
  saveCommunication
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationSettings);

const PrimaryCommunication = DropdownWrap(
  "prefComMethod",
  {
    title: {id: "communicationPreferredParagraphTitle", defaultMessage: "Primær kommunikasjonsform"},
    tooltip: "communicationPreferredToolTipText",
    tooltipTitle: {
      id: "communicationPreferredToolTipTitle",
      defaultMessage: "Primær kommunikasjonsform"
    },
    dataOptions: {
      options: prefComOpts,
      skipMap: true
    },
    width: "250px"
  }
)

const TwoWayDectTimer = DropdownWrap(
  "twoWayDECTTimer",
  {
    title: {id: "communicationDECTParagraphTitle", defaultMessage: "Maks samtalelengde"},
    tooltip: "communicationDECTToolTipText",
    tooltipTitle: {
      id: "communicationDECTToolTipTitle",
      defaultMessage: "Maks samtalelengde"
    },
    dataOptions: {
      options: twoWayDECTTimerOpts,
      offset: TIME_OFFSET.SECONDS_IN_SECOND,
      nullValue: status.disable
      
    },
    width: "250px"
  }
)

const mapAnswerIncomingPropsToState = ({ appSettings: { trondheim2 } }: AppState) => {
  return {
    trondheim2
  }
}

interface AnswerIncomingProps{
  trondheim2: boolean;
}

const AnswerIncoming = connect(mapAnswerIncomingPropsToState)(({trondheim2}: AnswerIncomingProps) => {

  if(trondheim2) return null;

  return DropdownWrap(
    "answerIncoming",
    {
      title: {id: "communicationAnswerParagraphTitle", defaultMessage: "Besvare innkommende anrop"},
      tooltip: "communicationAnswerToolTipText",
      tooltipTitle: {
        id: "communicationAnswerToolTipTitle",
        defaultMessage: "Valg for innkommende anrop"
      },
      dataOptions: {
        options: answerIncomingOpts,
        translate: true,
        skipMap: true        
      },
      width: "250px"
    }
  )
})

const CallbackTimer = DropdownWrap(
  "callbackTimer",
  {
    title: {id: "communicationCallbackTimer", defaultMessage: "Tilbakeringstimer"},
    tooltip: "communicationCallbackTimerToolTipText",
    tooltipTitle: {
      id: "communicationCallbackTimerToolTipTitle",
      defaultMessage: "Tilbakeringstimer"
    },
    dataOptions: {
      options: callbackTimerOpts,
      offset: TIME_OFFSET.SECONDS_IN_SECOND,
    },
    width: "250px"
  }
)

const ExternalAntenna = DropdownWrap(
  "externalAntenna",
  {
    title: {id: "communicationExternalAntenna", defaultMessage: "Antennevalg"},
    tooltip: "communicationExternalAntennaToolTipText",
    tooltipTitle: {
      id: "communicationExternalAntennaToolTipTitle",
      defaultMessage: "Antennevalg"
    },
    dataOptions: {
      options: externalAntennaOpts,
      translate: true,
      skipMap: true
      
    },
    width: "250px"
  }
)

const SilentActive = ToggleWrap(
  "silentActive",
  {
    title: {id: "communicationSilentParagraphTitle", defaultMessage: "Stille panel"},
    tooltip: "communicationSilentToolTipText",
    tooltipTitle: {id: "communicationSilentToolTipTitle", defaultMessage: "Stille panel"}
  }
)

export const GX8CommunicationSettingsSectionOne = () => {
  return(
    <Col md={6}>
      <ComponentWrapper>
        <PrimaryCommunication/>
      </ComponentWrapper>
      <ComponentWrapper>
        <CallbackTimer/>
      </ComponentWrapper>
      <ComponentWrapper>
        <ExternalAntenna/>
      </ComponentWrapper>
    </Col>
  )
}

export const GX8CommunicationSettingsSectionTwo = () => {
  return(
    <Col md={6}>
      <ComponentWrapper>
        <TwoWayDectTimer/>
      </ComponentWrapper>
      <ComponentWrapper>
        <SilentActive/>
      </ComponentWrapper>
    </Col>
  )
}