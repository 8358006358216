import * as React from "react";
import { Collapse } from "react-collapse";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { WrappedComponentProps, injectIntl } from "react-intl";

import { Option } from "../../../../Elements/Dropdown/types";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { TrackingSettings } from "../../types";
import withFormWrapper from "@Elements/Form/formWrapper";
import styled from "styled-components";
import { Header } from ".";
import { AppState } from "@Safemate/Store/types";

interface AdvancedContentProps extends WrappedComponentProps{
  advanced: boolean;
}

const BorderRow = styled(Row)`
  padding-top: 10px;
  border-top: ${props => props.theme.colors.border};
`;

const AdvancedWrapper = styled.div`
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
  margin-top: 10px;
`;

const AdvancedContent = injectIntl(
  withFormWrapper<TrackingSettings, AdvancedContentProps>(
    ({advanced, intl: {formatMessage}, formik: {values, setFieldValue}}) => {

      const trackingIntervalNightsOpt: Option[] = React.useMemo(() => {
        const a = [
          1,2,3,4,5,10,20
        ].map(opt => {return({text: `${opt}`, value: opt})});
        a.unshift({text: formatMessage({id: "asDaytime", defaultMessage: "Som dag"}), value: 0});
        return a;
      }, [])

      const [trackingNightStartsOpt, trackingNightEndsOpt] = React.useMemo(() => {
        let fhour;
        let thour;
        const a = [];
        const b = [];
        for(let i = 0; i < 7; i++) {
          fhour = 20 + i;
          if(fhour > 23) {
            fhour = 20 + i - 24;
          }
          a.push({
            text: (fhour < 10 ? '0' : '') + fhour + ':00',
            value: fhour + '00'
          });
          thour = i+3;
          b.push({
            text: (thour < 10 ? '0' : '') + thour + ':00',
            value: thour + '00'
          });
        }
        return [a, b]
      }, [])

      const hasIntervalNight = values.trackingIntervalNightInMinutes !== 0;

      return(
        <AdvancedWrapper>
        <Collapse isOpened={advanced}>
          <BorderRow>
            <Col md={4} sm={4}>
              <Header>{formatMessage({id: "trackingIntervalNight", defaultMessage: "Skjul avansert Lokaliseringsintervall natt"})}</Header>
              <Dropdown
                options={trackingIntervalNightsOpt}
                initial={values.trackingIntervalNightInMinutes}
                onChange={(opt) => {
                  const { value } = opt as Option;
                  setFieldValue("trackingIntervalNightInMinutes", value);
                }}
              />
            </Col>
            {hasIntervalNight && 
              <React.Fragment>
                <Col md={4} sm={4}>
                  <Header>{formatMessage({id: "nightStarts", defaultMessage: "Natt starter"})}</Header>
                  <Dropdown
                    options={trackingNightStartsOpt}
                    initial={values.trackingNightStarts}
                    onChange={(opt) => {
                      const { value } = opt as Option;
                      setFieldValue("trackingNightStarts", value);
                    }}
                  />
                </Col>
                <Col md={4} sm={4}>
                  <Header>{formatMessage({id: "nightEnds", defaultMessage: "Dag starter"})}</Header>
                  <Dropdown
                    options={trackingNightEndsOpt}
                    initial={values.trackingNightEnds}
                    onChange={(opt) => {
                      const { value } = opt as Option;
                      setFieldValue("trackingNightEnds", value);
                    }}
                  />
                </Col>
              </React.Fragment>}
          </BorderRow>
        </Collapse>
        </AdvancedWrapper>
      )
    }
  )
)

const mapStateToProps = ({ settings: {tracking: {advanced}}}: AppState) => {
  return {
    advanced
  }
}


export default connect(mapStateToProps)(AdvancedContent);