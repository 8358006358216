/* eslint-disable */
export enum SettingsActionTypes {
  SET_EDITING = "@@Settings/Geofence/SET_EDITING",
  SET_GEOFENCE = "@@Settings/Geofence/SET_GEOFENCE",
  SET_GEOFENCE_TYPE = "@@Settings/Geofence/SET_GEOFENCE_TYPE",
  SET_CONTROL_STATE = "@@Settings/Geofence/SET_CONTROL_STATE",
  SET_GEOFENCE_REFRESH = "@@Settings/Geofence/SET_REFRESH",
  SET_INITIAL_SELECTION = "@@Settings/Geofence/SET_INITIAL_SELECTION",
  GET_FENCE_DISTANCE = "@@Settings/Geofence/GET_FENCE_DISTANCE",
  SET_TRACKING_ADVANCED = "@@Settings/Tracking/SET_ADVANCED",
  SET_LAYOUT = "@@Settings/initialization/SET_LAYOUT",
  SET_INDOOR_DEVICE_MAC = "@@Settings/Indoor/SET_DEVICE_MAC",
  SET_INDOOR_DEVICE_MACS = "@@Settings/Indoor/SET_DEVICE_MACS",
  SET_INDOOR_OPEN_DEVICE = "@@Settings/Indoor/SET_OPEN_DEVICE",
  SET_INDOOR_VALUES_CHANGED= "@@Settings/Indoor/SET_VALUES_CHANGED",
  SET_INDOOR_SELECTED_INDEXES= "@@Settings/Indoor/SET_SELECTED_INDEXES",
  SET_INDOOR_ACTIVE_INDEX= "@@Settings/Indoor/SET_ACTIVE_INDEX",
  SET_INDOOR_SCANNED_MINUTES= "@@Settings/Indoor/SET_INDOOR_SCANNED_MINUTES"
}
