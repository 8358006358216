import * as React from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Formik, Form } from "formik";
import { RootState, DatabaseText, SelectedText, DatabaseCountry } from "./Store/types";
import { setModalState, SetModalState } from "./Store/actions";
import { deleteText, addTexts } from "./Store/routines";
import { ModalTitle, ModalBody, Table, EditTr, ModalFooter, ConfirmBtn, CancelButton, Input, FeedbackWrapper } from "./styles";
import { ThemeProps, withTheme } from "styled-components";
import { ITheme } from "@Theme/types";
import { FeedbackState } from "@Safemate/Feedback/reducer";
import { AppState } from "@Safemate/Store/types";

interface EditModalProps extends ThemeProps<ITheme> {
  editModal: boolean;
  setModalState: SetModalState;
  selected: SelectedText;
  countries: DatabaseCountry[];
  addTexts: Routine<ActionFunctionAny<Action<any>>>;
}

export interface FormValues{
  [index: string]: DatabaseText;
}

const mapStateToProps = ({ translationOverlay: {text: { editModal, selected }, country: { countries }}}: AppState) => {
  return {
    editModal,
    selected,
    countries
  }
}

const mapDispatchToProps = {
  setModalState,
  addTexts
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(({ theme, editModal, addTexts, countries, selected, setModalState }: EditModalProps) => {

  const { formatMessage } = useIntl();

  const mappedValues = React.useMemo(() => {
    const obj: FormValues = {};
    countries.forEach(country => {
      obj[country.languageCode] = null;
    })
    selected.texts.forEach(text => {
      obj[text.languageCode] = text;
    })
    return obj;
  }, [selected, countries])

  Modal.setAppElement("#react");

  return (
    <Modal
      isOpen={editModal}
      onRequestClose={() => setModalState(false)}
      style={
        {
          content: {
            maxWidth: "80%",
            width: "750px",
            height: "fit-content",
            position: "unset",
            background: theme.colors.backgroundPrimary,
            color: ""
          },
          overlay:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }
        }
      }
    >
      <ModalTitle>
        <h2>Edit texts for {selected.label}</h2>
      </ModalTitle>
      <Formik
        initialValues={mappedValues}
        enableReinitialize
        onSubmit={(values) => {
          const textsToSave = [];
          for(const property in values){
            if(values[property])
              textsToSave.push(values[property]);
          }
          addTexts(textsToSave);
        }}
      >
        {({ values, setFieldValue }) =>
          <Form>
            <React.Fragment>
              <EditModalBody label={selected.label} values={values} setFieldValue={setFieldValue}/>
              <ModalFooter>
                <ConfirmBtn type="submit">
                  {formatMessage({ id: "save", defaultMessage: "Lagre" })}
                </ConfirmBtn>
                <CancelButton
                  onClick={() => setModalState(false)}>
                  {formatMessage({ id: "cancel", defaultMessage: "Avbryt" })}
                </CancelButton>
              </ModalFooter>
            </React.Fragment>
          </Form>}
      </Formik>
    </Modal>
  )
}))

interface EditModalBodyProps {
  values: FormValues;
  label: string;
  setFieldValue: any;
  countries: DatabaseCountry[];
  feedback: FeedbackState;
}

const mapStateToEditModalBodyProps = ({ translationOverlay: {country: { countries } }, feedback}: AppState) => {
  return{
    countries,
    feedback
  }
}

const mapDispatchToEditModalBodyProps = {
  deleteText
}

export const EditModalBody = connect(mapStateToEditModalBodyProps, mapDispatchToEditModalBodyProps)(({ values, label, setFieldValue, countries, feedback }: EditModalBodyProps) => {

  const { formatMessage } = useIntl();
  const [ feedbackMessage, setFeedbackMessage ] = React.useState("");
  
  let timeout: NodeJS.Timeout = null;

  React.useEffect(() => {
    clearTimeout(timeout);
    if(feedback.message){
      setFeedbackMessage(formatMessage({id: feedback.message, defaultMessage: feedback.message}));
      timeout = setTimeout(() => {
        setFeedbackMessage("");
      }, feedback.danger ? 200000 : 10000);
    }
  }, [feedback.feedbackUUID])

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    }
  }, [])

  const context = React.useMemo(() => {
    for(let country in countries){
      const value = values[countries[country].languageCode];
      if(value){
        return value.context;
      }
    }
    return "PROFFWEB";
  }, [values, countries])

  return (
    <ModalBody>
      {feedbackMessage &&
        <FeedbackWrapper danger={feedback.danger}>
          {feedbackMessage}
        </FeedbackWrapper>}
      <Table>
        <thead>
          <tr>
            <th>{formatMessage({id: "language", defaultMessage: "Språk"})}</th>
            <th>{formatMessage({id: "text", defaultMessage: "Tekst"})}</th>
          </tr>
        </thead>
        <tbody>
          {countries.map(country => {
            const text = values[country.languageCode];
            return <EditTr>
              <td>
                {formatMessage({id: country.languageCode, defaultMessage: country.languageName})}
              </td>
              <td>
                {text 
                  ? text.translatedText.length > 50 
                    ? <textarea style={{width: "100%"}} value={text.translatedText} onChange={e => setFieldValue(`${country.languageCode}.translatedText`, e.target.value)}/>
                    : <Input style={{width: "100%"}} type="text" value={text.translatedText} onChange={e => setFieldValue(`${country.languageCode}.translatedText`, e.target.value)}/>
                  : <Input style={{width: "100%"}}  type="text" value={!text ? "" : text.translatedText} onChange={e => {
                      const dbText = {
                        context,
                        label,
                        languageCode: country.languageCode,
                        translatedText: e.target.value 
                      }
                      setFieldValue(country.languageCode, dbText)}
                    } placeholder={formatMessage({id: "noText", defaultMessage: "No texts for this label"})}/>}
              </td>
            </EditTr>
          })}
        </tbody>
      </Table>
    </ModalBody>
  )
})
