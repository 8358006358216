
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Routines from '../routines';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import * as Api from '../api';
import { AppAction } from '../../../DefaultSettings/Store/types';

function* alarmAnalysis(action: AppAction<number>){
  try{
    const alarmAnalysis = yield call(Api.alarmAnalysis, action.payload);
    yield put(Routines.alarmAnalysis.success({analysis: alarmAnalysis, id: action.payload}));
  }
  catch(e){
    yield put(Routines.alarmAnalysis.failure());
  }
}
function* alarmAnalysisSaga(){
    yield takeEvery(Routines.alarmAnalysis.TRIGGER, alarmAnalysis);
}

export default alarmAnalysisSaga;
