import styled from "styled-components";
import { FunctionStatus } from "./EventConfig/utils";

export const HeaderWrapper = styled.span`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

export const Highlighted = styled.span`
  color: ${props => props.theme.colors.accent};
  display: flex;
  align-items: center;

  svg {
    fill: ${props => props.theme.colors.accent};
  }

  svg g {
    fill: ${props => props.theme.colors.accent};
  }

  svg path {
    fill: ${props => props.theme.colors.accent};
  }

`;

export const Wrapper = styled.div`
  padding: 0 15px;
`;

export const ActiveIndicator = styled.div<{status: FunctionStatus}>`
  display: inline-flex;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-left: 3.5px;
  ${props => props.status === FunctionStatus.ACTIVE && 'background: #8db11d;'}
  ${props =>
    props.status === FunctionStatus.INACTIVE && 'border: gray 1px solid;'}
  ${props => props.status === FunctionStatus.NONE && 'border: gray 1px dotted;'}
`;

export const MoonWrapper = styled.div`
  margin-left: 3.5px;
  width: 15px; height: 15px;
  border-radius: 50%;
  border: 1px solid #8db11d;
  overflow: hidden;
`;

export const MoonFill = styled.div`
  background-color: #8db11d;
  height: 15px; width: 7.5px;
`;

export const MoonEmpty = styled.div`
  height: 15px; width: 7.5px;
`;