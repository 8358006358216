import * as React from "react";
import {connect} from "react-redux";

import {initialize} from "./Store/routines";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import Billing from "./Billing";

interface PartnerBillingProps{
  initialize: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToProps = {
  initialize
}


export default connect(null, mapDispatchToProps)(
  ({initialize}: PartnerBillingProps) => {
    
    React.useEffect(() => {
      initialize();
    }, []);

    return(
        <Billing/>
    )
  }
)