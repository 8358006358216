import { Geofence, GeofenceDistance } from "./types";

export const populateAvailableTimes = () => { 
  const options = [];
  for (let i = 0; i < 24; i++){
      let name;
      if (i > 9) { name = ''+i+':00'; }
      else { name = '0'+i+':00'; }
      options.push({
        text: name,
        value: i*100
      });
  }
  options.push({
    text: '00:00',
    value: 0});
  return options;
}

export function defaultFence(): Geofence {
  return {
    id: 0,
    deviId: 0,
    name: "Geofence1",
    radius: 200,
    enabled: false,
    status: "",
    lastKnownOutside: false,
    posPath: "",
    notified: false,
    enabledTimeFrom: 0,
    enabledTimeTo: 0,
    enabledTime: false,
    share: false,
    vertices: [],
    polygon: false,
    selected: false,
    indoor: false,
    circle: true,
    shape: null,
    editEnabled: true,
    sharedCustId: 0,
    delayMinutes: 0,
    alertByCall: false,
    inloIds: [],
    index: 0,
    inEdit: false
  };
}

export const isSaveNeeded = (currentFence: Geofence, initialFence: Geofence) => {

  if(
    currentFence.enabled !== initialFence.enabled
    || currentFence.enabledTime !== initialFence.enabledTime
    || currentFence.enabledTimeFrom !== initialFence.enabledTimeFrom
    || currentFence.enabledTimeTo !== initialFence.enabledTimeTo
    || currentFence.name !== initialFence.name
    || currentFence.polygon !== initialFence.polygon
    || currentFence.radius !== initialFence.radius
    || currentFence.share !== initialFence.share
    || currentFence.verticesChanged
  ) {
    return true;
  }

  return false
}

export const getGeofenceDistance = (geofence: Geofence): GeofenceDistance => {
  return {
    id: geofence.id,
    deviId: geofence.deviId,
    name: geofence.name,
    radius: geofence.radius,
    enabled: geofence.enabled,
    posPath: geofence.posPath,
    vertices: geofence.vertices,
    polygon: geofence.polygon,
    verticesChanged: geofence.verticesChanged
  }
}
