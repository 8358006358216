import React from 'react';

const HeadlineAlarm = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width="20px"
      height="20px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
    >
      <g>
        <g>
          <path
            d="M64.263,126.307c6.942,0,12.607-5.666,12.607-12.607H51.656C51.656,120.641,57.323,126.307,64.263,126.307z
                    M104.919,88.625V54.203c0-19.266-13.314-35.414-31.306-39.665v-4.249c0-5.242-4.25-9.35-9.35-9.35
                    c-5.099,0-9.349,4.25-9.349,9.35v4.249c-17.99,4.25-31.307,20.399-31.307,39.665v34.422L11,101.234v6.231h106.528v-6.231
                    L104.919,88.625z"
          />
        </g>
      </g>
    </svg>
  );
};

export default HeadlineAlarm;
