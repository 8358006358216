
import {AccessPoint, IndoorLocation, IndoorLocationDevice} from "@Safemate/Settings/IndoorLocation/types";
import styled, {ThemeProps, withTheme} from "styled-components";
import {Button} from "react-bootstrap";
import {ITheme} from "@Theme/types";
import {injectIntl, WrappedComponentProps} from "react-intl";
import * as React from "react";
import Modal from "react-modal";

export const defaultIndoorLocation = (deviId: number, custId: number): IndoorLocation => {
  return {
    inloId: 0,
    deviId: deviId,
    custId: custId,
    name: "",
    latLng: "",
    address: "",
    indoorLocationDevices: [],
    shared: false,
    scannedTime: null,
    hasGeofence: false
  }
}

export const defaultIndoorLocationName = (deviId: number, custId: number, name: string): IndoorLocation => {
  return {
    inloId: 0,
    deviId: deviId,
    custId: custId,
    name: name,
    latLng: "",
    address: "",
    indoorLocationDevices: [],
    shared: false,
    scannedTime: null,
    hasGeofence: false
  }
}

export const defaultIndoorLocationDevice = (): IndoorLocationDevice => {
  return {
    indeId: 0,
    type: "W",
    macAddress: "",
    name: "",
    scannedTime: new Date(),
    signalStrength: -120
  }
}
export const defaultIndoorLocationDeviceAdd = (): IndoorLocationDevice => {
  return defaultIndoorLocationDeviceAddWithType("W");
}

export const defaultIndoorLocationDeviceAddWithType = (type: string): IndoorLocationDevice => {
  return {
    indeId: 0,
    type: type,
    macAddress: "",
    name: "",
    scannedTime: new Date(),
    signalStrength: -120,
    add: true,
  }
}

export const addAccessPoints = (indoorLocation: IndoorLocation, accessPoints: AccessPoint[])=> {
  const macAddresses:string[] = indoorLocation.indoorLocationDevices.map((indoorLocationDevice) =>{
    return indoorLocationDevice.macAddress
  });
  accessPoints.forEach((accessPoint)=>{
    if(indoorLocation.indoorLocationDevices.length > 0){
      if( !macAddresses.includes(accessPoint.macAddress) ){
        indoorLocation.indoorLocationDevices.push({
          indeId: 0,
          type: accessPoint.type,
          macAddress: accessPoint.macAddress,
          name: accessPoint.name,
          scannedTime: accessPoint.date,
          signalStrength: accessPoint.signalStrength,
        });
      }
    } else{
      indoorLocation.indoorLocationDevices.push({
        indeId: 0,
        type: accessPoint.type,
        macAddress: accessPoint.macAddress,
        name: accessPoint.name,
        scannedTime: accessPoint.date,
        signalStrength: accessPoint.signalStrength,
      });
    }
  });
}

const compareIndoorLocationDevice = ( current: IndoorLocationDevice, initial: IndoorLocationDevice): boolean => {
  if(
    current && initial &&
    (current.name !== initial.name
      ||  current.macAddress !== initial.macAddress)
  ){
    return true;
  }
  return false;
}

const compareIndoorLocation = ( current: IndoorLocation, initial: IndoorLocation): boolean => {
  if(
    current && initial &&
    (current.name !== initial.name
      ||  current.latLng !== initial.latLng
      ||  current.deviId !== initial.deviId
      ||  current.custId !== initial.custId
      ||  current.address !== initial.address
    )
  ){
    return true;
  }
  return false;
}

export const isSaveNeeded = (current: IndoorLocation, initial: IndoorLocation) => {
  if( current.indoorLocationDevices && initial.indoorLocationDevices && current.indoorLocationDevices.length===initial.indoorLocationDevices.length ){
    for(let i=0; i<current.indoorLocationDevices.length; i++){
      if(compareIndoorLocationDevice(current.indoorLocationDevices[i], initial.indoorLocationDevices[i])){
        return true;
      }
    }
  }
  return compareIndoorLocation(current, initial);
}

export const validateIndoorLocation = (indoorLocation: IndoorLocation): boolean =>{
  let valid: boolean = indoorLocation && indoorLocation.name && indoorLocation.name.length > 0 && indoorLocation.latLng && indoorLocation.latLng.length > 0 && indoorLocation.indoorLocationDevices && indoorLocation.indoorLocationDevices.length > 0;
  if(!valid){
    return false;
  }
  indoorLocation.indoorLocationDevices.map((indoorLocationDevice)=>{
    if( !(indoorLocationDevice.macAddress && indoorLocationDevice.macAddress.length > 12)){
      valid = false;
    }
  });
  return valid;
}

const ModalTitle = styled.div`
  padding: 15px;
`;

const ModalBody = styled.div`
  padding: 0 30px;
`;

const ModalFooter = styled.div`
  margin: 20px 15px;
  padding: 15px;
  text-align: 
  right;
`;


const ConfirmButton = styled(Button)`
  background: ${props => props.theme.colors.accent};
  color: white;
  width: 100%;
  margin: 15px auto 0px;
  display: block;
  max-width:460px;
  font-size: 18px!important;
  max-width: 460px;
  height: 40px;
`;

const DeleteButton = styled(Button)`
  background: #d60403;
  color: white;
  width: 100%;
  margin: 15px auto 0px;
  display: block;
  max-width:460px;
  font-size: 18px!important;
  max-width: 460px;
  height: 40px;
`;

const CancelButton = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};  
  width: 100%;
  max-width: 460px;
  margin: 15px auto 0px;
  display: block;
  font-size: 18px!important;
  height: 40px;
`;

interface ConfirmModalProps extends ThemeProps<ITheme>, WrappedComponentProps{
  title: string;
  body: string | JSX.Element;
  confirmText: string;
  confirmFunc: Function;
  cancelFunc?: Function;
  cancelText?: string;
  cancelHide?: boolean;
  deleteButton?: boolean;
  staticBackDrop?: boolean;
}

export const confirmModal = () => {

  const [ open, setOpen ] = React.useState(false);
  Modal.setAppElement("#react");

  return{Comp: injectIntl(withTheme(({theme, title, body, confirmText, confirmFunc, cancelText, cancelHide, cancelFunc, staticBackDrop, deleteButton, intl: {formatMessage}}: ConfirmModalProps) => (
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={
          {
            content: {
              width: "500px",
              height: "fit-content",
              position: "unset",
              background: theme.colors.backgroundPrimary,
              color: "",
            },
            overlay:{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }
          }
        }
        shouldCloseOnOverlayClick={staticBackDrop}
      >
        <ModalTitle>
          <h2>{title}</h2>
        </ModalTitle>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          { deleteButton && <DeleteButton onClick={() => confirmFunc()}>
            {confirmText}
          </DeleteButton> }
          { !deleteButton && <ConfirmButton onClick={() => confirmFunc()}>
            {confirmText}
          </ConfirmButton> }
          { !cancelHide && <CancelButton
            onClick={() => {
              setOpen(false);
              cancelFunc && cancelFunc();
            }}>
            {cancelText && cancelText}
            {!cancelText && formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
          </CancelButton>}
        </ModalFooter>
      </Modal>
    ))), func: setOpen}
}
