import { Checkmark } from "@Safemate/RoleManager/layout";
import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { RootState, Texts } from "./Store/types";
import { Active, HeaderTr, Table, Td, Th, Tr } from "./styles";
import { setModalState, SetModalState } from "./Store/actions";
import { getTextForLabel } from "./Store/routines";
import { Button } from "react-bootstrap";
import { AppState } from "@Safemate/Store/types";


interface FeedbackProps{
  feedbackText: string[];
  originalTexts: Texts;
  setModalState: SetModalState;
  getTextForLabel: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ translationOverlay: { feedbackText }}: AppState) => {
  return {
    feedbackText
  }
}

const mapDispatchToProps = {
  setModalState,
  getTextForLabel
}

const Feedback = connect(mapStateToProps, mapDispatchToProps)(({setModalState, feedbackText, originalTexts, getTextForLabel}: FeedbackProps) => {

  const { formatMessage } = useIntl();

  const texts = React.useMemo(() => {
    return feedbackText.map(txt => {
      return {
        label: txt,
        text: originalTexts[txt],
        exist: !!originalTexts[txt]
      }
    })
  }, [feedbackText, originalTexts])

  return(
    <Table>
      <thead>
        <HeaderTr>
          <Th>Current text</Th>
          <Th>Label</Th>
          <Th>Edit</Th>
          <Th style={{textAlign: "center"}}>Added</Th>
        </HeaderTr>
      </thead>
      <tbody>
        {texts.map(({label, text, exist}) => {
          return (
            <Tr exist={exist}>
              <Td>{text}</Td>
              <Td>{label}</Td>
              <Td>
                  <Button 
                      onClick={() => {
                        getTextForLabel(label);
                        setModalState(true);
                      }}
                  >
                      {formatMessage({id: "edit", defaultMessage: "Endre"})}
                  </Button>
              </Td>
              <Td>
                  <Active
                  active={exist}
                  >
                  {exist && <Checkmark />}
                  </Active>
              </Td>
            </Tr>
          )
        })}
      </tbody>
    </Table>
  )
})

export default Feedback;