import { Device } from "../types";
import {Reducer} from "redux";
import { getAllDevices, getDevice } from "../routines";
import { MapActionTypes } from "../actionTypes";
import { deviceFiltering } from "@Safemate/map/utils";

const deviceState: Device = {
  id: 0,
  dehaId: 0,
  gx8: false,
  custId: 0,
  deviceName: "",
  firmName: "",
  serialNumber: "",
  lastMsgIn: 0,
  posTime: 0,
  showHdop: false,
  trackable: false,
  safemateAsReceiver: false,
  minionTwin: false,
  lastDeviceModifiedTime: 0,
  devices: [],
  filteredDevices: []
};

const device: Reducer<Device> = (state: Device = deviceState, action) => {
  switch (action.type) {
    case getDevice.SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case getAllDevices.SUCCESS:

      let lastModifiedTime = state.lastDeviceModifiedTime;

      for(let i = 0; i < action.payload.length; i++){
        if(lastModifiedTime < action.payload[i].lastMsgIn) {
          lastModifiedTime = action.payload[i].lastMsgIn;
        }
        if(lastModifiedTime < action.payload[i].lastMsgOut) {
          lastModifiedTime = action.payload[i].lastMsgOut;
        }
      }

      return {
        ...state,
        ...action.payload,
        lastDeviceModifiedTime: lastModifiedTime
      }

    case MapActionTypes.RESET_STATE:{
      return deviceState
    }

    default:
      return state;
  }
};

export default device;
