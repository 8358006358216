import { put, takeLatest} from "redux-saga/effects";
import * as Routines from '../routines';
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { AppAction } from "@Safemate/Store/types";


function* selectCustomer(action: AppAction<ICustomerWithPolicy>){
  try{
    yield put(Routines.fetchSettingsFromCustomer(action.payload));
    yield put(Routines.selectCustomer.success(action.payload));
  }
  catch(e){
    yield put(Routines.selectCustomer.failure());
  }
}

function* customerSaga(){
  yield takeLatest(Routines.selectCustomer.TRIGGER, selectCustomer);
}

export default customerSaga;
