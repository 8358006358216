import * as React from "react";
import FieldWrapper, {GenericDropdown, GenericCheckbox} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {satellites, outdatedPositionThreshold} from "./options";
import {LabelEnum} from "../../LabelEnum";
import { time } from "@Elements/Form/utils";

// Dropdowns
export const Satellites = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: { id: 'satellites', defaultMessage: 'Satellites' },
      unitsMargin: '250px'
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.POSITIONING,
  field: SettingEnum.SATELLITES,
  title: LabelEnum.SATELLITES,
  dataOptions: {
    options: satellites,
    skipMap: true
  }
});

// Dropdowns
export const OutdatedPosition = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: time.days,
      unitsMargin: '250px'
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.POSITIONING,
  field: SettingEnum.OUTDATED_POSITION_THRESHOLD,
  title: LabelEnum.OUTDATED_POSITION_THRESHOLD,
  dataOptions: {
    options: outdatedPositionThreshold,
    skipMap: true
  },
  tooltip: LabelEnum.OUTDATED_POSITION_THRESHOLD_TOOLTIP

});

// Checkboxes
export const DisablePositioning = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: true,
      label: {id: LabelEnum.DISABLE_POSITIONING, defaultMessage: "Skru av posisjonering"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.POSITIONING,
  field: SettingEnum.DISABLE_POSITIONING,
});
