import * as React from "react";
import {connect, FastField} from "formik";
import {useIntl, MessageDescriptor} from "react-intl";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {NoFlexContent} from "@Safemate/RoleEditor/WrapperStyles";
import {Option} from "../../../DefaultSettings/Settings/FieldWrapper";
import {SubHeaderWrapper} from "./styles";
import Dropdown from "@Elements/Dropdown/Dropdown";

interface TextFieldProps{
  header: MessageDescriptor;
  field: Array<string>;
  disabled?: boolean;
}

export const TextField = connect<TextFieldProps, UserWithCustomer>(({disabled, header, field, formik: {values}}) => {

  const { formatMessage } = useIntl();

  const fieldname = field.join(".");
  let value = values;
  field.forEach((item: string) => {
    value = value[item];
  })

  return(
    <NoFlexContent>
      <SubHeaderWrapper>
        {formatMessage(header)}
      </SubHeaderWrapper>
      <FastField
        style={{width: "100%"}}
        disabled={disabled}
        type="text"
        name={fieldname}
        value={value}
      />
    </NoFlexContent>
  )
})

interface DropdownFieldProps extends TextFieldProps{
  data: Array<Option>;
  title?: string;
  onChange?: (value: Option) => void;
}

export const DropdownField = connect<DropdownFieldProps, UserWithCustomer>(({onChange, data, title, header, field, formik: {values, setFieldValue}}) => {

  const { formatMessage } = useIntl();

  const fieldname = field.join(".");
  let value = values;
  field.forEach((item: string) => {
    value = value[item];
  })

  return(
    <NoFlexContent>
      <SubHeaderWrapper>
        {formatMessage(header)}
      </SubHeaderWrapper>
      <Dropdown
        options={data}
        initial={value}
        title={title}
        searchable
        onChange={(opt: Option | Option[]) => {

          const value = opt as Option;

          if(onChange){
            onChange(value);
          }
          setFieldValue(fieldname, value.value)
        }}
      />
    </NoFlexContent>
  )
})
