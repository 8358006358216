import * as React from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Collapse } from "react-collapse";
import styled from "styled-components";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../../Elements/Dropdown/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import {LabelWrapper, RowLabel, RowWrapper, ToggleWrap, PaddedCol } from ".";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import { TrackingSettings } from "../../types";
import confirmModal from "../../../Modals/confirmModal";
import {Info, LoaderWrap} from "..";
import { updateDevicePositioning, updateAcceptLBS, saveTracking } from "../../Store/routines";
import Tooltip from "@Elements/Tooltip";
import Loader from "@Elements/Loader";
import Toggle from "@Elements/Switch/Toggle";
import { AppState } from "@Safemate/Store/types";


interface PositionToggleProps extends WrappedComponentProps{
  disablePositioning: boolean;
  processingDevicePositioning: boolean;
  deviId: number;
  updateDevicePositioning: Routine<ActionFunctionAny<Action<any>>>;
}

const mapPositionToggleStateToProps = ({ settings: {tracking: { disablePositioning, processingDevicePositioning }, device: { device: { deviId }}}}:AppState) => {
  return {
    disablePositioning,
    processingDevicePositioning,
    deviId
  }
}

const mapPositionToggleDispatchToProps = {
  updateDevicePositioning
}

export const PositionToggle = connect(mapPositionToggleStateToProps, mapPositionToggleDispatchToProps)(injectIntl(withFormWrapper<TrackingSettings, PositionToggleProps>(({ intl: {formatMessage}, deviId, disablePositioning, processingDevicePositioning, updateDevicePositioning, formik:{setFieldValue, values}}) => {

  const {Comp, func} = confirmModal();

  const positioningEnabled = !disablePositioning;

  return(
      <RowWrapper>
      <LabelWrapper xs={12} sm={5} md={5}>
        <RowLabel>      
          {formatMessage({id: "devicePositioning", defaultMessage: "Posisjonering"})}
        </RowLabel>
        <Tooltip
          popoverId="positioning-popover"
          labelId="tooltip_positioning"
          defaultMessage="Hovedbryter for posisjonering. Dersom denne skrus av så vil enheten ikke sende inn posisjoner, og dermed spare batterilevetid."
        >
          <InformationIcon size="medium" />
        </Tooltip> 
      </LabelWrapper>
      <PaddedCol sm={7} md={7}>
      {processingDevicePositioning ? <LoaderWrap><Loader/></LoaderWrap> 
        : <ToggleWrap>
            <Toggle
              onChange={(value: boolean) => {
                if(!value){
                  func(true);
                }
                else{
                  updateDevicePositioning(!value);
                  setFieldValue("disablePositioning", !value);
                }
              }}
              checked={positioningEnabled}
            />
          </ToggleWrap>}
      </PaddedCol>
      <Comp
        title={`${formatMessage({id: "deactivate", defaultMessage: "Deaktiver"})} ${formatMessage({id: "positioning", defaultMessage: "Posisjonering"})}`}
        body={
          <p>
            <span>{formatMessage({id: "deactivatePositioningDesc", defaultMessage: "Er du sikker på at du vil deaktivere posisjonering på denne enheten? Dette vil medføre at den ikke på noen som helst måte kan lokaliseres, og kun vil fungere som en alarm-enhet."})}</span>
          </p>
        }
        confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
        confirmFunc={() => {
          updateDevicePositioning(true);
          setFieldValue("disablePositioning", true);
          func(false);
        }}
      />
    </RowWrapper>
  )
})))

interface SatellitesProps extends WrappedComponentProps{
  gpsSatCriteriaWithDefault: string;
  disablePositioning: boolean;
  saveTracking: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  settings: TrackingSettings;
  autoTracking: boolean;
  powerSaving: boolean;
  isPrivate: boolean;
}

const StellitesContainer = styled.div`
  .ReactCollapse--collapse {
    transition: all 500ms;
  }
`;

const satellitesOptions = [
  {text: '3', value: 3},
  {text: '4', value: 4},
  {text: '5', value: 5},
  {text: '6', value: 6},
  {text: '7', value: 7},
  {text: '8', value: 8},
  {text: '9', value: 9},
]


const mapSatellitesStateToProps = ({ appData: { isPrivate}, settings: {tracking: {  gpsSatCriteriaWithDefault, disablePositioning, settings, autoTracking, powerSaving}, initialization: {deviId}}} : AppState) => {
  return{
    gpsSatCriteriaWithDefault,
    disablePositioning,
    settings,
    deviId,
    autoTracking,
    powerSaving,
    isPrivate
  }
}

const mapDispatchToProps = {
  saveTracking
}

const StyledP = styled.p`
  padding-top: 20px;
`;

export const Satellites = connect(mapSatellitesStateToProps, mapDispatchToProps)(
  injectIntl(
    withFormWrapper<TrackingSettings, SatellitesProps>(
      ({isPrivate, formik: {values, values: {minNumSatellites}, setFieldValue, initialValues, dirty}, settings, deviId, autoTracking, powerSaving, intl: {formatMessage}, saveTracking, gpsSatCriteriaWithDefault, disablePositioning}) => {

        const [ overrideSatellites, setOverrideSatellites ] = React.useState(minNumSatellites !== null && !disablePositioning);
        const [inigpsSatCriteriaWithDefault, setInigpsSatCriteriaWithDefault] = React.useState(gpsSatCriteriaWithDefault);
        //trackingsubmit
        const handleSubmit = () => {
          if(settings.minNumSatellites != null){
            saveTracking({
              deviceId: deviId,
              settings: {
                ...values,
                autoTracking,
                powerSaving,
                minNumSatellites: null,
              }
            });
          }
        }
        
        React.useEffect(() => {
          setOverrideSatellites(minNumSatellites !== null && !disablePositioning);       
        }, [minNumSatellites])

        React.useEffect(() => {
          if(gpsSatCriteriaWithDefault != null){
            setInigpsSatCriteriaWithDefault(gpsSatCriteriaWithDefault);
          }
        }, [gpsSatCriteriaWithDefault])
        
        return(
          <StellitesContainer>
            {!isPrivate && <RowWrapper noBottomBorder={overrideSatellites && !disablePositioning}>
             <LabelWrapper xs={12} sm={5} md={5}>
              <RowLabel>      
                {formatMessage({id: "overrideMinNumSatellites", defaultMessage: "Overskriv satellittkrav"})}
              </RowLabel>
              <Tooltip
                popoverId="override-satellites-popover"
                labelId="tooltip_overrideMinNumSatellites"
                defaultMessage="Hvis du overskriver her vil kommunenivå-satellittkravet bli ignorert."
              >
                <InformationIcon size="medium" />
              </Tooltip> 
            </LabelWrapper>
            {disablePositioning ?
               <PaddedCol sm={7} md={7}>
                  <Info>{formatMessage({id: "overrideSatellitesInactive", defaultMessage: "Det er ikke mulig å justere satellitt krav når posisjonering er avskrudd."})}</Info>
                </PaddedCol>
              :
              <React.Fragment>
                  <PaddedCol sm={7} md={7}>
                    <ToggleWrap>
                      <Toggle
                        onChange={(value: boolean) => {
                          if(value){
                            setFieldValue("minNumSatellites", parseInt(gpsSatCriteriaWithDefault, 10))
                            if(gpsSatCriteriaWithDefault == null){
                              setFieldValue("minNumSatellites", parseInt(inigpsSatCriteriaWithDefault, 10))
                            }
                          }
                          else{
                            setFieldValue("minNumSatellites", null);
                            //handleSubmit();
                          }
                          setOverrideSatellites(value);
                        }}
                        checked={overrideSatellites}
                      />
                    </ToggleWrap>
                  </PaddedCol>
                </React.Fragment>}
            </RowWrapper>}
            {isPrivate && 
              <div><StyledP>{formatMessage({id: "safemateGoSatelite", defaultMessage: "Safemate Go-specific help text."})}</StyledP></div>
            }
            <Collapse isOpened={isPrivate || (overrideSatellites && !disablePositioning)}>
              <RowWrapper noTopBorder={!isPrivate && overrideSatellites && !disablePositioning}>
                <LabelWrapper xs={12} sm={5} md={5}>
                  <RowLabel>      
                    {formatMessage({id: "minimumNumberOfSatellites", defaultMessage: "Antall satelitter"})}
                  </RowLabel>
                </LabelWrapper>
                <PaddedCol sm={5} md={5}>
                  <ToggleWrap>
                    <Dropdown 
                      options={satellitesOptions} 
                      onChange={(opt) => {
                        const value: number = (opt as Option).value;
                        setFieldValue("minNumSatellites", value);
                      }}
                      initial={isPrivate ? minNumSatellites != null ? minNumSatellites : 4 : minNumSatellites}
                    /> 
                  </ToggleWrap>
                </PaddedCol>
                {/* <PaddedCol sm={2} md={2}>
                  <Button disabled={(initialValues.minNumSatellites === minNumSatellites) || !dirty} type="submit">{formatMessage({id: "save", defaultMessage: "Lagre"})}</Button>
                </PaddedCol> */}
              </RowWrapper>
            </Collapse>
          </StellitesContainer>
        )
      }
    )
  )
)


interface AcceptLBSProps extends WrappedComponentProps{
  acceptLBS: boolean;
  disablePositioning: boolean;
  processingAcceptLBS: boolean;
  deviId: number;
  updateAcceptLBS: Routine<ActionFunctionAny<Action<any>>>;
}

const mapAcceptLBSStateToProps = ({ settings: {tracking: { settings:{ acceptLBS }, processingAcceptLBS, disablePositioning }, device: { device: { deviId }}}}:AppState) => {
  return {
    acceptLBS,
    disablePositioning,
    processingAcceptLBS,
    deviId
  }
}

const mapAcceptLBSDispatchToProps = {
  updateAcceptLBS
}

export const AcceptLBS = connect(mapAcceptLBSStateToProps, mapAcceptLBSDispatchToProps)(injectIntl(withFormWrapper<TrackingSettings, AcceptLBSProps>(({ intl: {formatMessage}, deviId, acceptLBS, processingAcceptLBS, updateAcceptLBS, disablePositioning, formik:{values, setFieldValue}}) => {

  const {Comp, func} = confirmModal();

  return(
    <RowWrapper>
      <LabelWrapper xs={12} sm={5} md={5}>
        <RowLabel>
          {formatMessage({id: "acceptLBS", defaultMessage: "Enable LBS lookup"})}
        </RowLabel>
        <Tooltip
          popoverId="accept-lbs-popover"
          labelId="acceptLBSLabel"
          defaultMessage="Enable LBS lookup"
        >
          <InformationIcon size="medium" />
        </Tooltip>
      </LabelWrapper>
      <PaddedCol sm={7} md={7}>
        {disablePositioning
          ? <Info>{formatMessage({id: "autoTrackingInactive", defaultMessage: "Automatisk lokalisering er ikke tilgjengelig når posisjonering er avskrudd."})}</Info>
          : processingAcceptLBS ? <LoaderWrap><Loader/></LoaderWrap>
            : <ToggleWrap>
              <Toggle
                onChange={(value: boolean) => {
                  //updateAcceptLBS({deviceId: deviId, acceptLBS: value});
                  setFieldValue("acceptLBS", value);
                }}
                checked={values.acceptLBS}
              />
            </ToggleWrap>}
      </PaddedCol>
    </RowWrapper>
  )
})))
