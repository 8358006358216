export interface ISso{
  ldapOnly: boolean;
  ssoSelect: boolean;
  ssoUser: boolean;
  ssoAdmin: boolean;
  renounceUrl: string;
  idps: IdentityProvider[];
}

export interface IdentityProvider{
  type: string;
  key: string;
  name: string;
  logo: string;
  logoAbsolutePath: string;
  request: string;
  requestAhp: string;
  requestSafemateNextOfKin: string;
  requestSafecallNextOfKin: string;
  metadata: string;
}

export const defaultSso = {
  ldapOnly: false,
  ssoSelect: false,
  ssoUser: false,
  ssoAdmin: false,
  renounceUrl: "",
  idps: [] as IdentityProvider[]
}
