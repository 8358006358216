import { Firm } from "../Store/types";


export const popForm = (values:Firm, pptyId: string, billAddrDefined: boolean) => {  
    if(values.addrCity == "safematego"){
          values.addrCity = "";
    }
    if(values.addrZipCode == "safematego"){
      values.addrZipCode = "";
    }
    if(values.addressLine1 == "safematego"){
      values.addressLine1 = "";
    }
    const fillForm: Firm ={
      activatedDate: values.activatedDate || 0,
      lastChangedBy:values.lastChangedBy || 0,
      lastChanged: values.lastChanged || 0,
      created: values.created || 0,
      createdBy: values.createdBy || 0,
      custId: values.custId || 0,
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      email: values.email || "",
      mobile: values.mobile || "",
      status: values.status || 0,
      prgrId: values.prgrId || 0,
      invoiceCreditLimitExclVat: values.invoiceCreditLimitExclVat || 0,
      invoiceBalanceExclVat: values.invoiceBalanceExclVat || 0,
      allowAllPhoneNo: values.allowAllPhoneNo || false,
      latestInvoiceDate: values.latestInvoiceDate || 0,
      orgNum: values.orgNum || "",
      addrName: values.addrName || "",
      billingAddrName: values.billingAddrName || "",
      addrReference: values.addrReference || "",
      addressLine1: values.addressLine1 || "",
      addressLine2: values.addressLine2 || "",
      addrZipCode: values.addrZipCode || "",
      addrCity: values.addrCity || "",
      addrCounId: values.addrCounId || 0,
      country: values.country || "",
      billingAddrReference: values.billingAddrReference || "",
      billingAddrLine1: values.billingAddrLine1 || "",
      billingAddrLine2: values.billingAddrLine2 || "",
      billingAddrZipCode: values.billingAddrZipCode || "",
      billingAddrCity: values.billingAddrCity || "",
      billingAddrCounId: values.billingAddrCounId || 0,
      addrSamesame: values.addrSamesame || false,
      parent: values.parent || 0,
      privateUser: values.privateUser || false,
      defaultAhp: values.defaultAhp || 0,
      forceTfa: values.forceTfa || false,
      forceCoverage: values.forceCoverage || false,
      forceIncomingRing: values.forceIncomingRing || false,
      forceIncomingRingAlways: values.forceIncomingRingAlways || false,
      integrationId: values.integrationId || "",
      integrationRole: values.integrationRole || "",
      priceGroup: values.priceGroup || [],	
      numActiveDevices: values.numActiveDevices || 0,
      name: values.name || "",
      active: values.active || false,
      firmName: values.firmName || "",
      company: values.company || false,
      streetAddress: values.streetAddress || "",
      coaddress: values.coaddress || "",
      languageCode: values.languageCode || "",
      deleted: values.deleted || false,
      addrNameForBilling: values.addrNameForBilling || "",
      informalContactName: values.informalContactName || "",
      pptyId: pptyId || values.pptyId || "",
      billAddrDefined: billAddrDefined || false,
      billingAccepted: true,
      allowDeviceDeletes: (values.allowDeviceDeletes == null || values.allowDeviceDeletes == undefined) ? true : values.allowDeviceDeletes,
    }
    return fillForm;
  }
