import {Reducer} from "redux";
import {initialize, getPostCity} from "../routines";
import { CustomerState } from "../types";

const customerState: CustomerState = {
  pptyId: 0,
  customer: {
    custId: 0,
    parent: 0,
    email: "",
    billingAddrName: "",
    addrReference: "",
    addressLine1: "",
    addressLine2: "",
    addrCity: "",
    firstName: "",
    lastName: "",
    country: "",
    addrZipCode: "",
    name: "",
    privateUser: false,
    billingAccepted: false,
    orgNum: "",
    allowDeviceDeletes: false,
  },
  postCity: {
    postalCodeType: "",
    result: "",
    valid: false
  },
  marketProgId: 0,
};

const customer: Reducer<CustomerState> = (state: CustomerState = customerState, action: any) => {
  switch (action.type) {
    case initialize.SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case getPostCity.SUCCESS:
      return {
        ...state,
        postCity: action.payload
        };

    default:
        return state;
  }
  
};

export default customer;
