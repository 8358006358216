import React from 'react';
import SIcon from './SIcon';

const BatteryQuarter = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M17.5 6c.3 0 .5.2.5.5V8h1v4h-1v1.5c0 .3-.2.5-.5.5h-16c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h16zm0-1h-16C.7 5 0 5.7 0 6.5v7c0 .8.7 1.5 1.5 1.5h16c.8 0 1.5-.7 1.5-1.5V13h.2c.4 0 .8-.3.8-.8V7.8c0-.5-.3-.8-.8-.8H19v-.5c0-.8-.7-1.5-1.5-1.5zM4 11.8V8.2c0-.1-.1-.2-.2-.2h-.6c-.1 0-.2.1-.2.2v3.6c0 .1.1.2.2.2h.6c.1 0 .2-.1.2-.2zm2 0V8.2c0-.1-.1-.2-.2-.2h-.6c-.1 0-.2.1-.2.2v3.6c0 .1.1.2.2.2h.6c.1 0 .2-.1.2-.2z" />
  </svg>
);

const Icon = props => <SIcon {...props} icon={BatteryQuarter} />;

export default Icon;
