import React from 'react';
import SIcon from './SIcon';
import { FormattedMessage } from 'react-intl';

const AlarmPendant = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M10.5 7.5c2 .3 3.5 2.4 3.5 5 0 2.7-1.8 5-4 5s-4-2.3-4-5c0-2.5 1.5-4.7 3.5-5V7C6.9 6.7 5 4.4 5 1.8V.2c0-.1.1-.2.2-.2h.5c.2 0 .3.1.3.2v1.6C6 4 7.6 5.8 9.7 6c2.4.1 4.3-1.7 4.3-4V.2c0-.1.1-.2.2-.2h.5c.2 0 .3.1.3.2V2c0 2.6-2 4.7-4.5 5v.5zm-.5 9c1.6 0 3-1.8 3-4s-1.4-4-3-4-3 1.8-3 4 1.4 4 3 4zm0-1.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    <title>
      <FormattedMessage id="pendantLabel" defaultMessage="Alarmsmykke" />
    </title>
  </svg>
);

const Icon = props => <SIcon {...props} icon={AlarmPendant} />;

export default Icon;
