import * as React from "react";
import { MessageDescriptor } from "react-intl";
import Tooltip from "@Elements/Tooltip";
import { ISensorDTO } from "./types";
import {default as Signal0Icon} from "@Icons/signal/Signal0";
import {default as Signal1Icon} from "@Icons/signal/Signal1";
import {default as Signal2Icon} from "@Icons/signal/Signal2";
import {default as Signal3Icon} from "@Icons/signal/Signal3";
import {default as Signal4Icon} from "@Icons/signal/Signal4";
import moment from "moment";
import { SensorTypeEnum } from "./SensorTypeEnum";
import { WideSignal } from "@Elements/Signal";
import { isError } from "./sensorStatus";

export enum RssiEnum{
  EXCELLENT = "rssiExcellent",
  VERY_GOOD = "rssiVeryGood",
  OKAY = "rssiOkay",
  NOT_GOOD = "rssiNotGood",
  UNUSABLE = "rssiUnusable"
}

export const mapRssi = (sensor: ISensorDTO, formatMessage: (message: MessageDescriptor) => string) => {
  switch(sensor.sensor.type){
    case SensorTypeEnum.BASE_STATION:
    case SensorTypeEnum.WRIST_BAND:
      return getRssiIcon(rssiScaleMapping(sensor), formatMessage, moment(sensor.sensor.rssiSaved).format('DD.MM.YYYY HH:mm'));
    case SensorTypeEnum.SAFEMATE_HOME:{
      if(!isError(sensor.status.icon) && sensor.status.online)
        return <WideSignal
        strength={sensor.sensor.rssi}
        maxStrength={9}
        history={false}
      />
    }

    default: return null;
  }
}

const rssiScaleMapping = (sensor: ISensorDTO): RssiEnum => {
  
  const { rssi } = sensor.sensor;

  switch(sensor.sensor.type){
    case SensorTypeEnum.BASE_STATION:
    case SensorTypeEnum.WRIST_BAND:
      if(rssi < 1 && rssi >= -50) return RssiEnum.EXCELLENT;
      if(rssi >= -60) return RssiEnum.VERY_GOOD;
      if(rssi >= -67) return RssiEnum.OKAY;
      if(rssi >= -70) return RssiEnum.NOT_GOOD;
      else return RssiEnum.UNUSABLE;

    case SensorTypeEnum.SAFEMATE_HOME:

      if(rssi > 8) return RssiEnum.EXCELLENT;
      if(rssi > 6) return RssiEnum.VERY_GOOD;
      if(rssi > 4) return RssiEnum.OKAY;
      if(rssi > 2) return RssiEnum.NOT_GOOD;
      else return RssiEnum.UNUSABLE;

    default: return RssiEnum.UNUSABLE;
  }

}

const getRssiIcon = (rssi: RssiEnum, formatMessage: (message: MessageDescriptor) => string, time?: string) => {
  switch(rssi){
    case RssiEnum.EXCELLENT:
      return (
        <Tooltip
          popoverId={`${rssi}_popover`}
          labelId={rssi}
          defaultMessage={rssi}
          postfix={time && `(${time})`}
        >
          <Signal4Icon title={formatMessage({id: rssi, defaultMessage: rssi})} size={`medium`} />
        </Tooltip>
      );
    case RssiEnum.VERY_GOOD:
      return (
        <Tooltip
          popoverId={`${rssi}_popover`}
          labelId={rssi}
          defaultMessage={rssi}
          postfix={time && `(${time})`}
        >
          <Signal3Icon title={formatMessage({id: rssi, defaultMessage: rssi})} size={`medium`} />
        </Tooltip>
      );
    case RssiEnum.OKAY:
      return (
        <Tooltip
          popoverId={`${rssi}_popover`}
          labelId={rssi}
          defaultMessage={rssi}
          postfix={time && `(${time})`}
        >
          <Signal2Icon title={formatMessage({id: rssi, defaultMessage: rssi})} size={`medium`} />
        </Tooltip>
      );
    case RssiEnum.NOT_GOOD:
      return (
        <Tooltip
          popoverId={`${rssi}_popover`}
          labelId={rssi}
          defaultMessage={rssi}
          postfix={time && `(${time})`}
        >
          <Signal1Icon title={formatMessage({id: rssi, defaultMessage: rssi})} size={`medium`} />
        </Tooltip>
      );
    case RssiEnum.UNUSABLE:
      return (
        <Tooltip
          popoverId={`${rssi}_popover`}
          labelId={rssi}
          defaultMessage={rssi}
          postfix={time && `(${time})`}
        >
          <Signal0Icon title={formatMessage({id: rssi, defaultMessage: rssi})} size={`medium`} />
        </Tooltip>
      );
  }
}