import {Reducer} from "redux";
import {CustomerState} from "../types";
import * as Routines from "../routines";
import { Customer } from "@Safemate/PartnerDashboard/Models/Customer";
import uuid from "uuid";

const customerState: CustomerState = {
  customers: [],
  viewPrices: false,
  totalDevices: 0,
  homeDevices: 0,
  toggledChildren: [],
  filterId: uuid()
};

const customer: Reducer<CustomerState> = (state = customerState, action) => {
  switch (action.type) {
    case Routines.fetchCustomersForList.SUCCESS:
      return{
        ...state,
        ...action.payload
      }
    case Routines.filterPartners.SUCCESS:
      return{
        ...state,
        customers: action.payload,
        filterId: uuid()
      }

    case Routines.setReference.SUCCESS:
      return{
        ...state,
        customers: state.customers.map(customer => {
          if(customer.custId === action.payload.custId){
            return {
              ...customer,
              reference: action.payload.value
            }
          }
          else if(action.payload.custTree.includes(customer.custId)){
            return mapChildren(action.payload.custId, customer, "reference", action.payload.value)
          }
          return customer
        })
      }

    case Routines.toggleChildren.SUCCESS:
      const isToggled = state.toggledChildren.find(id => id === action.payload);
      let updatedList = [...state.toggledChildren];
      if(isToggled){
        updatedList = updatedList.filter(id => id !== action.payload);
      }
      else{
        updatedList.push(action.payload);
      }
      return{
        ...state,
        toggledChildren: updatedList
      }

    case Routines.setSwitchValue.SUCCESS:
      return{
        ...state,
        customers: state.customers.map(customer => {
          if(customer.custId === action.payload.custId){
            return {
              ...customer,
              individualBilling: action.payload.value
            }
          }
          else if(action.payload.custTree.includes(customer.custId)){
            return mapChildren(action.payload.custId, customer, "individualBilling", action.payload.value)
          }
          return customer
        })
      }

    case Routines.setAllowDeviceDeletes.SUCCESS:
      return{
        ...state,
        customers: state.customers.map(customer => {
          if(customer.custId === action.payload.custId){
            return {
              ...customer,
              allowDeviceDelete: action.payload.value
            }
          }
          else if(action.payload.custTree.includes(customer.custId)){
            return mapChildren(action.payload.custId, customer, "allowDeviceDelete", action.payload.value);
          }
          return customer
        })
      }

    default:
      return state;
  }
};

export default customer;

const mapChildren = (custId: number, customer: Customer, variableToUpdate: string, value: boolean): Customer => {

  if(custId === customer.custId){
    customer[variableToUpdate] = value;
  }

  return {
    ...customer,
    children: [...customer.children.map(child => mapChildren(custId, child, variableToUpdate, value))]
  }
}
