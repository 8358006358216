import {InitializationState} from "../types";
import {Reducer} from "redux";
import {initialize, deleteCustomer, saveCustomer} from "../routines";

const initializationState: InitializationState = {
  initialized: false,
  processingDelete: false,
  processingSave: false,
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action) => {
  switch (action.type) {
    case initialize.SUCCESS:
      return {
        ...state,
        initialized: true,
      };

    case deleteCustomer.TRIGGER:
      return{
        ...state,
        processingDelete: true
      }

    case saveCustomer.TRIGGER:
      return{
        ...state,
        processingSave: true
      }

    case deleteCustomer.SUCCESS:
    case deleteCustomer.FAILURE:
      return {
        ...state,
        processingDelete: false
      }

    case saveCustomer.SUCCESS:
    case saveCustomer.FAILURE:
      return {
        ...state,
        processingSave: false
      }

    default:
      return state;
  }
};

export default initialization;
