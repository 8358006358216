import {
  EventSetupSettings, GeofenceSettings, DeviceSettings, OtherSettings, DeviceSettingsWithFallback, OtherSettingsWithFallback,
} from "./types";
import {Geofence} from "@Safemate/Settings/Geofence/types";

type FillEventSetup = (values: EventSetupSettings) => EventSetupSettings;
type FillDeviceSetting = (deviceSettings: DeviceSettingsWithFallback) => DeviceSettings;
type FillOtherSetting = (otherSettings: OtherSettingsWithFallback) => OtherSettings;
type DeconstructOtherSetting = (settings: OtherSettings) => OtherSettings;


export const deconstructOtherSetting: DeconstructOtherSetting = (settings) => {
  const oSetting: OtherSettings = {
    ...settings,
    phoneNoWhiteList: getPhoneNoWhiteList(settings)
  }
  return oSetting;
}

export const mapEventSetup: FillEventSetup = (values) => {
  return {
    deviceEventSetups: values.deviceEventSetups && values.deviceEventSetups.map((setup, index) => { return {...setup, index} }),
    enableDefaultEventSetup: values.enableDefaultEventSetup || values.enableDefaultEventSetupWithFallback,
    sequentialCalling: values.sequentialCalling || values.sequentialCallingWithFallback
  }
}

export const mapDeviceSettings: FillDeviceSetting = deviceSettings => {
  const mappedSettings: DeviceSettings = {
    dehaId: deviceSettings.dehaId,

    triggerFourHeartbeat: deviceSettings.triggerFourHeartbeat || deviceSettings.triggerFourHeartbeatWithFallback,
    selfCheckLimit: deviceSettings.selfCheckLimit || deviceSettings.selfCheckLimitWithFallback,
    messageIntervalPeriodic: deviceSettings.messageIntervalPeriodic || deviceSettings.messageIntervalPeriodicWithFallback,
    callbackTimer: deviceSettings.callbackTimer || deviceSettings.callbackTimerWithFallback,
    prefComMethod: deviceSettings.prefComMethod || deviceSettings.prefComMethodWithFallback,
    twoWayDECTTimer: deviceSettings.twoWayDECTTimer || deviceSettings.twoWayDECTTimerWithFallback,
    answerIncoming: deviceSettings.answerIncoming || deviceSettings.answerIncomingWithFallback,
    silentActive: deviceSettings.silentActive || deviceSettings.silentActiveWithFallback,
    heartbeatInterval: deviceSettings.heartbeatInterval || deviceSettings.heartbeatIntervalWithFallback,
    safemateAsReceiver: deviceSettings.safemateAsReceiver || deviceSettings.safemateAsReceiverWithFallback,
    staticTooLong: deviceSettings.staticTooLong || deviceSettings.staticTooLongWithFallback,
    satellites: deviceSettings.satellites || deviceSettings.satellitesWithFallback,
    disablePositioning: deviceSettings.disablePositioning || deviceSettings.disablePositioningWithFallback,
    outdatedPositionThreshold: deviceSettings.outdatedPositionThreshold || deviceSettings.outdatedPositionThresholdWithFallback,
    acceptLBS: deviceSettings.acceptLBS || deviceSettings.acceptLBSWithFallback,
    geolocationWIFILookupGoogle: deviceSettings.geolocationWIFILookupGoogle || deviceSettings.geolocationWIFILookupGoogleWithFallback,
    geolocationWIFILookupCombain: deviceSettings.geolocationWIFILookupCombain || deviceSettings.geolocationWIFILookupCombainWithFallback,
    trackingInterval: deviceSettings.trackingInterval || deviceSettings.trackingIntervalWithFallback,
    autoTracking: deviceSettings.autoTracking || deviceSettings.autoTrackingWithFallback,
    voicePrompt: deviceSettings.voicePrompt || deviceSettings.voicePromptWithFallback,
    guardTimeBeep: deviceSettings.guardTimeBeep || deviceSettings.guardTimeBeepWithFallback,
    confirmBeep: deviceSettings.confirmBeep || deviceSettings.confirmBeepWithFallback,
    wandererBeep: deviceSettings.wandererBeep || deviceSettings.wandererBeepWithFallback,
    soundLevel: deviceSettings.soundLevel || deviceSettings.soundLevelWithFallback,
    soundLevelTriggerFour: deviceSettings.soundLevelTriggerFour || deviceSettings.soundLevelTriggerFourWithFallback,
    soundLevelTriggerTwo4G: deviceSettings.soundLevelTriggerTwo4G || deviceSettings.soundLevelTriggerTwo4GWithFallback,
    soundLevelRingtoneTriggerTwo4G: deviceSettings.soundLevelRingtoneTriggerTwo4G || deviceSettings.soundLevelRingtoneTriggerTwo4GWithFallback,
    soundLevelRingtoneTriggerFour: deviceSettings.soundLevelRingtoneTriggerFour || deviceSettings.soundLevelRingtoneTriggerFourWithFallback,
    micVolume: deviceSettings.micVolume || deviceSettings.micVolumeWithFallback,
    voicePromptVolumeTriggerFour: deviceSettings.voicePromptVolumeTriggerFour || deviceSettings.voicePromptVolumeTriggerFourWithFallback,
    soundLevelRingtone: deviceSettings.soundLevelRingtone || deviceSettings.soundLevelRingtoneWithFallback,
    dectSoundLevel: deviceSettings.dectSoundLevel || deviceSettings.dectSoundLevelWithFallback,
    twoWayMic: deviceSettings.twoWayMic || deviceSettings.twoWayMicWithFallback,
    lightLevel: deviceSettings.lightLevel || deviceSettings.lightLevelWithFallback,
    lightMode: deviceSettings.lightMode || deviceSettings.lightModeWithFallback,
    led: deviceSettings.led || deviceSettings.ledWithFallback,
    systemSounds: deviceSettings.systemSounds || deviceSettings.systemSoundsWithFallback,
    incomingRingTone: deviceSettings.incomingRingTone || deviceSettings.incomingRingToneWithFallback,
    helpArriveIndicator: deviceSettings.helpArriveIndicator || deviceSettings.helpArriveIndicatorWithFallback,
    callbackIndicator: deviceSettings.callbackIndicator || deviceSettings.callbackIndicatorWithFallback,
    voicePromptVolumeGX8: deviceSettings.voicePromptVolumeGX8 || deviceSettings.voicePromptVolumeGX8WithFallback,
    climaxVoicePromptAlarm: deviceSettings.climaxVoicePromptAlarm || deviceSettings.climaxVoicePromptAlarmWithFallback,
    climaxVoicePromptCallback: deviceSettings.climaxVoicePromptCallback || deviceSettings.climaxVoicePromptCallbackWithFallback,
    climaxVoicePromptFall: deviceSettings.climaxVoicePromptFall || deviceSettings.climaxVoicePromptFallWithFallback,
    climaxVoicePromptWanderer: deviceSettings.climaxVoicePromptWanderer || deviceSettings.climaxVoicePromptWandererWithFallback,
    climaxVoicePromptBed: deviceSettings.climaxVoicePromptBed || deviceSettings.climaxVoicePromptBedWithFallback,
    climaxVoicePromptACFailure: deviceSettings.climaxVoicePromptACFailure || deviceSettings.climaxVoicePromptACFailureWithFallback,
    climaxVoicePromptACRestore: deviceSettings.climaxVoicePromptACRestore || deviceSettings.climaxVoicePromptACRestoreWithFallback,
    batteryThreshold: deviceSettings.batteryThreshold || deviceSettings.batteryThresholdWithFallback,
    powerFailureReportDelay: deviceSettings.powerFailureReportDelay || deviceSettings.powerFailureReportDelayWithFallback,
    sensorSupervisionCheckActive: deviceSettings.sensorSupervisionCheckActive || deviceSettings.sensorSupervisionCheckActiveWithFallback,
    sensorSupervisionCheckTimer: deviceSettings.sensorSupervisionCheckTimer || deviceSettings.sensorSupervisionCheckTimerWithFallback,
    sensorSupervisionCheckTimerPendant: deviceSettings.sensorSupervisionCheckTimerPendant || deviceSettings.sensorSupervisionCheckTimerPendantWithFallback,
    guardTime: deviceSettings.guardTime || deviceSettings.guardTimeWithFallback,
    alarmTimeout: deviceSettings.alarmTimeout || deviceSettings.alarmTimeoutWithFallback,
    helpArrive: deviceSettings.helpArrive || deviceSettings.helpArriveWithFallback,
    localLearning: deviceSettings.localLearning || deviceSettings.localLearningWithFallback,
    bypassTimer: deviceSettings.bypassTimer || deviceSettings.bypassTimerWithFallback,
    alarmSmsReceipt: deviceSettings.alarmSmsReceipt || deviceSettings.alarmSmsReceiptWithFallback,
    sensorSensitivity: deviceSettings.sensorSensitivity || deviceSettings.sensorSensitivityWithFallback,
    teamAlert: deviceSettings.teamAlert || deviceSettings.teamAlertWithFallback,
    firmware: deviceSettings.firmware || deviceSettings.firmwareWithFallback,
    staticIgnoreNights: deviceSettings.staticIgnoreNights || deviceSettings.staticIgnoreNightsWithFallback,
    lightAlarmMode: deviceSettings.lightAlarmMode || deviceSettings.lightAlarmModeWithFallback,
    lightNormalMode: deviceSettings.lightNormalMode || deviceSettings.lightNormalModeWithFallback,
    synchronizeSound: deviceSettings.synchronizeSound || deviceSettings.synchronizeSoundWithFallback,
    screenBrightness: deviceSettings.screenBrightness || deviceSettings.screenBrightnessWithFallback,
    screenLightMode: deviceSettings.screenLightMode || deviceSettings.screenLightModeWithFallback,
    lowBatteryVoicePrompt: deviceSettings.lowBatteryVoicePrompt || deviceSettings.lowBatteryVoicePromptWithFallback,

    teamAlertLearnUrl: deviceSettings.teamAlertLearnUrl,
    climaxReport: deviceSettings.climaxReport,
  };
  return mappedSettings;
}

export const mapOtherSetting: FillOtherSetting = otherSettings => {
  const otherSetting: OtherSettings = {
    deviceTransactionLogsDays: otherSettings.deviceTransactionLogsDays || otherSettings.deviceTransactionLogsDaysWithFallback,
    userTransactionLogsDays: otherSettings.userTransactionLogsDays || otherSettings.userTransactionLogsDaysWithFallback,
    devicePositionHistoryDepthHours: otherSettings.devicePositionHistoryDepthHours || otherSettings.devicePositionHistoryDepthHoursWithFallback,
    devicePositionHistoryDepthPosPlots: otherSettings.devicePositionHistoryDepthPosPlots || otherSettings.devicePositionHistoryDepthPosPlotsWithFallback,
    nokEmployeeLoginIdleHour: otherSettings.nokEmployeeLoginIdleHour || otherSettings.nokEmployeeLoginIdleHourWithFallback,
    userInactivityLimit: otherSettings.userInactivityLimit || otherSettings.userInactivityLimitWithFallback,
    phoneNoWhiteList: otherSettings.phoneNoWhiteList || otherSettings.phoneNoWhiteListWithFallback,
    phoneNoWhiteList1: otherSettings.phoneNoWhiteList1 || otherSettings.phoneNoWhiteList1WithFallback,
    phoneNoWhiteList2: otherSettings.phoneNoWhiteList2 || otherSettings.phoneNoWhiteList2WithFallback,
    phoneNoWhiteList3: otherSettings.phoneNoWhiteList3 || otherSettings.phoneNoWhiteList3WithFallback,
    ahpNumber: otherSettings.ahpNumber,
    msgIntegration: otherSettings.msgIntegration || otherSettings.msgIntegrationWithFallback,
    msgI9nHandler: otherSettings.msgI9nHandler || otherSettings.msgI9nHandlerWithFallback,
    msgI9nEndpoint: otherSettings.msgI9nEndpoint || otherSettings.msgI9nEndpointWithFallback || "",
    msgI9nBeforeCall: otherSettings.msgI9nBeforeCall || otherSettings.msgI9nBeforeCallWithFallback,
    msgI9nAfterCall: otherSettings.msgI9nAfterCall || otherSettings.msgI9nAfterCallWithFallback,
    msgI9nHaltCalls: otherSettings.msgI9nHaltCalls || otherSettings.msgI9nHaltCallsWithFallback,
    msgI9nHaltI9n: otherSettings.msgI9nHaltI9n || otherSettings.msgI9nHaltI9nWithFallback,
    msgIntegrationSecured: otherSettings.msgIntegrationSecured,
    msgI9nUsername: otherSettings.msgI9nUsername || otherSettings.msgI9nUsernameWithFallback || "",
    msgI9nPassword: otherSettings.msgI9nPassword || otherSettings.msgI9nPasswordWithFallback || "",
    msgI9nFailureFallback: otherSettings.msgI9nFailureFallback || otherSettings.msgI9nFailureFallbackWithFallback,
    msgI9nFailureFallbackNumber: otherSettings.msgI9nFailureFallbackNumber || otherSettings.msgI9nFailureFallbackNumberWithFallback,
    msgI9nFailureFallbackCountryCode: otherSettings.msgI9nFailureFallbackCountryCode || otherSettings.msgI9nFailureFallbackCountryCodeWithFallback,
    integrationPermission: otherSettings.integrationPermission,
    aclCheck: otherSettings.aclCheck || otherSettings.aclCheckWithFallback,
    sensioIntegration: otherSettings.sensioIntegration,
    hasPassword: otherSettings.hasPassword,
    inheritPassword: otherSettings.inheritPassword
  };
  return otherSetting;
}


//True or false values can either be set to "true"/"false" (strings) or "1"/"0" (strings).
export const parseCheckboxValue = (checkboxString: boolean, value: string): boolean => {
  return checkboxString ? value === "true" : value === "1";
}

export const convertToBaseCheckboxValue = (checkboxString: boolean, value: boolean): string => {
  return checkboxString ? value ? "true" : "false" : value ? "1" : "0";
}

export const getPhoneNoWhiteList = (values: OtherSettings): string => {
  let phoneNoWhiteList:string = "";
  if( values.phoneNoWhiteList1 && values.phoneNoWhiteList1.length > 0 ){
    phoneNoWhiteList += values.phoneNoWhiteList1;
  }
  if( values.phoneNoWhiteList2 && values.phoneNoWhiteList2.length > 0 ){
    phoneNoWhiteList += ((phoneNoWhiteList.length >0 )? ";" : "") +  values.phoneNoWhiteList2;
  }
  if( values.phoneNoWhiteList3 && values.phoneNoWhiteList3.length > 0 ){
    phoneNoWhiteList += ((phoneNoWhiteList.length >0 )? ";" : "") +  values.phoneNoWhiteList3;
  }
  return phoneNoWhiteList;
}

export const mapGeofence = (geofences: GeofenceSettings): GeofenceSettings => {
  const geofenceArr: Geofence[] = [];
  geofences.geofences.map((geofence)=>{
    geofence.shape = null;
    geofenceArr.push(geofence);
  });
  return {geofences:geofenceArr};
}
