import {AlarmState} from "../types";
import {Reducer} from "redux";
import {getAlarmAnalysis, initializeAlarms, getHistoricAlarmsAndIncrease} from "../routines";

const alarmState: AlarmState = {
  alarms: [],
  historic: [],
  historicLimit: 10,
  alarmAnalysis: {}
};

const alarm: Reducer<AlarmState> = (state: AlarmState = alarmState, action) => {
  switch (action.type) {
    case initializeAlarms.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case getAlarmAnalysis.SUCCESS:
      return{
        ...state,
        alarmAnalysis: {
          ...state.alarmAnalysis,
          [action.payload.transaction]: action.payload.analysis
        }
      }

    case getHistoricAlarmsAndIncrease.SUCCESS:
      return{
        ...state,
        ...action.payload
      }

    default:
      return state;
  }
};

export default alarm;
