import {Reducer} from "redux";
import {InvoiceState} from "../types";
import * as Routines from "../routines";

const invoiceState: InvoiceState = {
  invoiceList: [],
};

const invoices: Reducer<InvoiceState> = (state = invoiceState, action) => {
  switch (action.type) {
    
    case Routines.fetchInvoices.SUCCESS:
      return{
        ...state,
        invoiceList: action.payload,
      }
      
    default:
      return state;
  }
};

export default invoices;
