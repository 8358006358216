import {call, put, select, takeLatest} from "redux-saga/effects";
import * as Routines from "../routines";
import * as Api from '../api';
import {feedbackFailure} from "../../../Feedback/reducer";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";
import { selectCurrentContext } from "@Safemate/Store/selectors";
import { UserWithCustomer } from "@Safemate/UserAdmin/Models/UserWithCustomer";
import { AppAction } from "@Safemate/Store/types";

function* getUser(action: AppAction<number>){
  try{
    const currentContext: SiteTypeEnum = yield select(selectCurrentContext);
    const user: UserWithCustomer = yield call(Api.getUser, currentContext, action.payload);
    yield put(Routines.getUser.success(user));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getUser.failure());
  }
  yield put(Routines.getUser.fulfill());
}

function* userSaga(){
  yield takeLatest(Routines.getUser.TRIGGER, getUser);
}

export default userSaga;
