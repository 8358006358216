import * as React from "react";
import { connect } from "react-redux";
import { Field,} from "formik";
import { LoaderWrap } from "../../UserAdmin/Users/Edit/styles";
import { injectIntl, useIntl, WrappedComponentProps } from "react-intl";
import styled from "styled-components";
import { Row, Button } from "react-bootstrap";
import { IndoorLocation, } from "@Safemate/Settings/IndoorLocation/types";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {
  getIndoorLocationsForDevice,
  getIndoorLocationsForCustomer,
  getCustomersForIndoor
} from "../Store/routines";
import { defaultIndoorLocationName } from "@Safemate/Settings/IndoorLocation/utils";
import {GeneralTitle, getIndoorLocationTabs, IndoorLocationTabs,} from "@Safemate/Settings/tabDefinition";
import { VerticalTabs, Tab } from "../../../Elements/Tabs";
import {IndoorLocationComponent} from "@Safemate/Settings/IndoorLocation/indoorLocationComp";
import AddSafemate from "@Icons/menu/AddSafemate";
import IndoorCustomer from "@Icons/IndoorCustomer";
import IndoorDevice from "@Icons/IndoorDevice";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";

export const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

export const StyledField = styled(Field)`
  width: 100%;
`;

export const StyledField70 = styled(Field)`
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const DeleteBtn = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    fill: ${props => props.theme.colors.backgroundPrimary};

    path {
      fill: ${props => props.theme.colors.backgroundPrimary};
    }
  }
  background: #d60403;
  width: 100%;
  padding: 20px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    margin-right: 0px;
    min-width: 230px;
  }
`;

export const IconBtn = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    fill: ${props => props.theme.colors.backgroundPrimary};

    path {
      fill: ${props => props.theme.colors.backgroundPrimary};
    }
  }
  background: ${props => props.theme.colors.accent};
  color: ${props => props.theme.colors.backgroundPrimary};
  width: 100%;
  padding: 20px 10px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    margin-right: 0px;
    min-width: 230px;
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InnerFlexDiv = styled.div`
  margin: 0.5em;
`;

export const CancelButton = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};
  margin-top: 15px;
`;

export const SaveButton = styled(Button)`
  background: ${props => props.theme.colors.accent};
  margin-left: 15px;
  margin-top: 15px;
`
export const SaveButtonMarginedRight = styled(Button)`
  background: ${props => props.theme.colors.accent};
  margin-right: 15px;
  margin-top: 15px;
`



export const AccentedText = styled.span`
  color: ${props => props.theme.colors.accent};
  font-size: medium;
`;

export const ScanText = styled.span`
  font-size: medium;
`;

export const ListRow = styled(Row)`
  min-height: 48px;
  align-items: center;
  margin: 0px 0px 5px 0px;
  border: ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
`;

export const ListColWrapperLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;
export const ListColWrapperRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  float: right;
`;

export const ListCol = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  width: 100%;
`;

export const ListColRight = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  justify-content: right;
  margin-left: 5px;
`;

const Margined1EMRow = styled(Row)`
  margin: 1em;
`;

export const IndoorHeading = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomerIndoorStyle = styled.div`
  background: ${props => props.theme.colors.accent};
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  justify-content: center;
`;

interface IndoorLocationProps{
  deviId: number,
  dehaId: number,
  custId: number,
  loading: boolean;
  refresh: boolean;
  getIndoorLocationsForDevice: Routine<ActionFunctionAny<Action<any>>>;
  getIndoorLocationsForCustomer: Routine<ActionFunctionAny<Action<any>>>;
  getCustomersForIndoor: Routine<ActionFunctionAny<Action<any>>>;
  indoorLocationsDevice: IndoorLocation[];
  indoorLocationsCustomer: IndoorLocation[];
}

const IndoorLoc = ({ deviId, custId, getCustomersForIndoor, indoorLocationsDevice, indoorLocationsCustomer, getIndoorLocationsForDevice, getIndoorLocationsForCustomer, dehaId, loading, refresh }: IndoorLocationProps) => {

  const { formatMessage } = useIntl();

  const [ indoorLocations, setIndoorLocations ] = React.useState([]);

  React.useEffect(() => {
    getIndoorLocationsForDevice(deviId);
    getIndoorLocationsForCustomer(custId);
    getCustomersForIndoor(deviId);
  }, [deviId, custId, refresh]);

  React.useEffect(() => {
    applyIndoorLocations();
  }, [indoorLocationsCustomer, indoorLocationsDevice]);

  const applyIndoorLocations = () => {
    const indoorLocationsTmp: IndoorLocation[] = [];
    indoorLocationsTmp.push(...indoorLocationsCustomer, ...indoorLocationsDevice);
    indoorLocationsTmp.push(defaultIndoorLocationName(deviId, custId, ""));
    setIndoorLocations(indoorLocationsTmp);
  }

  const indoorTabs:IndoorLocationTabs[] = getIndoorLocationTabs(indoorLocations, formatMessage);

  const indoorLocationTabs : JSX.Element[] = (indoorTabs !==  undefined && indoorTabs.length > 0) ?  indoorTabs.map((indoorLocationTab)=>{
    if(indoorLocationTab.shared){
      return (<Tab key={indoorLocationTab.id} title={<GeneralTitle icon={indoorLocationTab.indoorLocation.inloId < 1 ? <AddSafemate /> : <IndoorCustomer />} title={indoorLocationTab.title} />} name={`${indoorLocationTab.id}`} style={CustomerIndoorStyle}>
        <IndoorLocationComponent indoorLocation={indoorLocationTab.indoorLocation} />
      </Tab>);
    } else {
      return(<Tab key={indoorLocationTab.id} title={<GeneralTitle icon={indoorLocationTab.indoorLocation.inloId < 1 ? <AddSafemate /> : <IndoorDevice />} title={indoorLocationTab.title} />} name={`${indoorLocationTab.id}`}>
        <IndoorLocationComponent indoorLocation={indoorLocationTab.indoorLocation} />
      </Tab>);
    }
  }): null;

  if( indoorLocationTabs && indoorLocationTabs.length > 0 ){
    return (
      <React.Fragment>
        <VerticalTabs>
          { indoorLocationTabs }
        </VerticalTabs>
      </React.Fragment>
    )
  } else{
    return (<LoaderWrap><Loader/></LoaderWrap>)
  }
}


const mapStateToProps = ({ settings: {device: { device:{  ownedByCustomer, dehaId } },initialization: {deviId}, indoorLocation: {indoorLocationsDevice, indoorLocationsCustomer, refresh, loading}}}: AppState) => {
    return {
      deviId, custId:ownedByCustomer, indoorLocationsDevice, indoorLocationsCustomer, loading, dehaId, refresh
    }
}
  
const mapDispatchToProps = {
  getIndoorLocationsForDevice,
  getIndoorLocationsForCustomer,
  getCustomersForIndoor
};

export default connect(mapStateToProps, mapDispatchToProps)(IndoorLoc);
