import {initializePaymentProfiles, getPayment} from '../routines';
import {PaymentProfileState} from "../types";
import {Reducer} from "redux";

const paymentProfileState: PaymentProfileState = {
  individualPaymentProfiles: [],
};

const individualPaymentProfiles: Reducer<PaymentProfileState> = (state: PaymentProfileState = paymentProfileState, action: any) => {
  switch (action.type) {

    case initializePaymentProfiles.SUCCESS:
      return {
        ...state,
        individualPaymentProfiles: action.payload
      };

    case getPayment.SUCCESS:
      return {
        ...state,
        individualPaymentProfiles: action.payload
      };


    default:
        return state;
  }
};

export default individualPaymentProfiles;
