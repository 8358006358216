import styled from 'styled-components';

export const ModalHeader = styled.div`
  font-size: 20px;
  padding: 20px;
  border-bottom: ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.textPrimary};
`;

export const ModalSubHeader = styled.div`
  font-size: 16px;
  padding: 10px 0px;
  color: ${props => props.theme.colors.textPrimary};
`;

export const ModalBody = styled.div`
  padding: 40px;
`;

export const ModalDiv = styled.div`
  background: ${props => props.theme.colors.backgroundTertiary};
  border: ${props => props.theme.colors.border};

  &::first-letter {
    text-transform: uppercase;
  }
`;
