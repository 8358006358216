import { MapType } from "..";
import { formatDateForMap } from "../../utils";
import { Position, Geofence } from "../Store/types";
// import {MapLabel} from "./mapLabel";

export class MapWrapper{
  private map: any;
  private maps: any;
  private positions: Position[] = [];
  private markers: Map<number, any> = new Map<number, any>();
  private labels: Map<number, any> = new Map<number, any>();
  private circles: Map<number, any> = new Map<number, any>();
  private polylines: any[] = [];
  private mapInitialized: boolean = false;
  private initialFences: Geofence[] = [];
  private fences: Geofence[] = [];
  private overlays: any[] = [];
  private drawingManager: any;
  private mapType: MapType;


  constructor(){}

  public init = (map:any, maps: any, initialFences: any, mapType: MapType) => {
    console.log("Map init");
    this.map = map;
    this.maps = maps;
    this.initialFences = initialFences;
    this.mapType = mapType;
    this.drawingManager = new maps.drawing.DrawingManager({
      drawingMode: maps.drawing.OverlayType.NONE,
      drawingControl: false,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.CIRCLE,
          maps.drawing.OverlayType.POLYGON]
      },
      circleOptions : {
        fillColor : 'gray',
        fillOpacity : 0.3,
        strokeWeight : 2,
        strokeColor: 'gray',
        clickable : false,
        editable : true,
        suppressUndo : true,
      },
      polygonOptions : {
        fillColor : 'gray',
        fillOpacity : 0.3,
        strokeWeight : 2,
        strokeColor: 'gray',
        clickable : false,
        editable : true,
        suppressUndo : true,
      }
    });
    this.drawingManager.setMap(map);
    this.setDrawingManager(this.drawingManager);
    this.setMapInitialized(true);
  }

  public createMarkers = () =>{
    const self = this;
    if( self.mapInitialized ){
      self.clearMarkers();
      self.positions.map(( position: Position, index:number ) => {
        self.createMarker(position, index);
      });
      if(this.mapType === MapType.SINGLE){
        self.drawPaths();
      }
      self.fitBounds();
    }
  }

  public isMapInitialized(){
    return this.mapInitialized;
  }

  public setMapInitialized(val: boolean){
    return this.mapInitialized = val;
  }

  public setPositions = ( positions: Position[] ) => {
    const currentPositionIds = this.positions.map(pos => pos.tranId);
    let hasNew = currentPositionIds.length !== positions.length;
    if(!hasNew)
      positions.forEach(pos => {
        if(!currentPositionIds.includes(pos.tranId)){
          hasNew = true;
        }
      })
    if(hasNew){
      this.positions = positions;
      if(this.mapInitialized){
        this.createMarkers();
      }
    }
  }

  private createAccuracyCircle = (position: Position, index:number) => {
    const self = this;
    if( position.accuracy !== 0 && index===0 ){
      const circle = new self.maps.Circle({
        strokeColor: "#808080",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#c7c7c7",
        fillOpacity: 0.35,
        map: self.map,
        center: {lat: parseFloat(position.posLati), lng: parseFloat(position.posLong)},
        radius: position.accuracy,
      });
      self.circles.set(index, circle);
    } else if( position.accuracy && position.accuracy !== 0 ) {
      const circle = new self.maps.Circle({
        strokeColor: "#808080",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#c7c7c7",
        fillOpacity: 0.35,
        map: self.map,
        center: {lat: parseFloat(position.posLati), lng: parseFloat(position.posLong)},
        radius: position.accuracy,
      });
      circle.setMap(null);
      self.circles.set(index, circle);
    }
  }

  private createMarker = (position: Position, index: number) =>{
    const self = this;
    const marker = new self.maps.Marker({
      position: {lat: parseFloat(position.posLati), lng: parseFloat(position.posLong)},
      map : self.map,
      icon : getMarker(position),
      label: {
        fontSize: position.drawMarker ? '14px' : '12px',
        text: position.drawMarker ? position.deviceName + " (" + formatDateForMap(position.posTime) + ")": formatDateForMap(position.posTime),
        className: position.drawMarker ? 'mapMarkerLabelDraw' : 'mapMarkerLabel',
        color: '#fff'
      },
    });
    marker.set('zIndex', 1234 - index);

    if(position.drawMarker){
      var contentString = "<div class=\"infoWindow\">";
      if (position.sat) {
        contentString = contentString.concat("<span>SAT: "+position.sat+"</span><br/>");
      }
      if (position.hdop) {
        contentString = contentString.concat("<span>HDOP: "+position.hdop+"</span><br/>");
      }
      contentString = contentString.concat("</div>");
      
      var infowindow = new self.maps.InfoWindow({
        content : contentString,
        pixelOffset : new self.maps.Size(0, 0)
      });
      
      marker.addListener('click', function(){
        if(self.mapType === MapType.SINGLE){
          infowindow.open(self.map, marker);
        }
        else if(self.mapType === MapType.ALL){
          location.hash = `mapsingle/${position.deviId}`;
        }
      });
    }
    self.markers.set(index, marker);
    self.createAccuracyCircle(position, index);
  }

  private clearMarkers = () => {
    const self= this;
    for(let entry of self.markers.entries()){
      entry[1].setMap(null);
    }
    for(let entry of self.labels.entries()){
      entry[1].setMap(null);
    }
    for(let entry of self.circles.entries()){
      entry[1].setMap(null);
    }
    for(let i = 0; i < self.polylines.length; i++){
      self.polylines[i].setMap(null);
    }
    self.polylines = [];
    self.markers.clear();
    self.labels.clear();
    self.circles.clear();
  }

  private drawPaths = () => {
    const self = this;
    if(self.positions.length > 1) {
      self.polylines = new Array();
      let path = new Array();
      let color = '#000';
      let lastColor = null;
      let lastIsGps = isGps(self.positions[0]);
      path[0] = new self.maps.LatLng(parseFloat(self.positions[0].posLati), parseFloat(self.positions[0].posLong));
      for (let i = 1; i < self.positions.length; i++) {
        const pathElement = new self.maps.LatLng(parseFloat(self.positions[i].posLati), parseFloat(self.positions[i].posLong));
        color = isGps(self.positions[i]) && lastIsGps ? '#000' : '#ffa500';
        if(lastColor && color != lastColor) {
          self.addPolyline(path, lastColor);
          path = [path[path.length-1]];
        }
        path.push(pathElement);
        lastColor = color;
        lastIsGps = isGps(self.positions[i]);
      }
      self.addPolyline(path, color);
    }		
  }

  private addPolyline = (path: any, strokeColor: string) => {
    const self = this;
    var polyline = new this.maps.Polyline({
      path,
      strokeColor,
      strokeWeight : 2,
      strokeOpacity : 1.0,
      clickable : true
    });
    polyline.setMap(this.map);
    polyline.addListener("mouseover", self.showCircles);
    polyline.addListener("mouseout", self.hideCircles);
    this.polylines.push(polyline);
  }

  private showCircles = () => {
    const self = this;
    for(let entry of self.circles.entries()){
      entry[1].setMap(self.map);
    }
  }

  private hideCircles = () => {
    const self = this;
    for(let entry of self.circles.entries()){
      entry[1].setMap(null);
    }
  }
  
  private fitBounds = () => {
    const self = this;
    if( self.positions.length > 0 && self.markers.size > 0){
      let bounds = new self.maps.LatLngBounds();
      self.markers.forEach((marker) => {
        if(bounds && !bounds.contains(marker.getPosition())){
          bounds.extend(marker.getPosition());
        }
      })
      if( self.circles && self.circles.size > 0 ){
        self.circles.forEach(( value ) => {
          bounds = bounds.union(value.getBounds());
        })
      }
      if(bounds && !bounds.isEmpty()) {
        self.map.setOptions({maxZoom: 16});
        self.map.fitBounds(bounds);
        self.map.setOptions({maxZoom: 20});
      }
    }
  } 

  public createFences = (geofence: Geofence[]) => {
    const self = this;
    if(!self.map || !self.maps || !self.drawingManager) return;
    self.fences = geofence;

    self.overlays.forEach((overlay) => {
      overlay.setMap(null);
    })

    self.overlays = [];
    geofence.forEach((fence: Geofence) => {
      if (fence.enabled) {
        if (fence.polygon) {
          self.createPolygon(fence);
        } else {
          self.createCircle(fence);
        }
      }
    });
  }

  public createPolygon = (fence: Geofence) => {

    const path = fence.vertices.map((edge) => {
      return {
        lat: edge.lat,
        lng: edge.lng
      }
    })
  
    var poly = new this.maps.Polygon({
      paths : path,
      fillOpacity : 0.3,
      fillColor : fence.enabled ?'green':'gray',
      strokeColor : fence.enabled ?'green':'gray',
      strokeWeight : 2,
      clickable : true,
      editable : fence.selected,
      suppressUndo : true,
      fence : fence,
    });
  
    fence.shape = poly;
    poly.setMap(this.map);
    this.overlays.push(poly);  

    const self = this;
  }

  public createCircle = (fence: Geofence) => {
    const circle = new this.maps.Circle({
      center : {
        lat : parseFloat(getCenterLat(fence)),
        lng : parseFloat(getCenterLng(fence))
      },
      radius : fence.radius,
      fillOpacity : 0.3,
      fillColor : fence.enabled ? 'green' : 'gray',
      strokeColor : fence.enabled ? 'green' : 'gray',
      strokeWeight : 2,
      clickable : true,
      editable : fence.selected,
      suppressUndo : true,
      fence,
    });
  
    fence.shape = circle;
    circle.setMap(this.map);
    this.overlays.push(circle);

    const self = this;
  }

  public setDrawingManager = (drawingManager: any) => {
    this.drawingManager = drawingManager
  }

  public resetFences = () => {
    const self = this;
    this.fences.forEach(fence => {
      const initialValues = self.initialFences.find(initialFence => initialFence.id === fence.id);
      if(fence.shape && initialValues){
        if(fence.polygon){
          const path = initialValues.vertices.map((edge) => {
            return {
              lat: edge.lat,
              lng: edge.lng
            }
          })

          fence.shape.setOptions({
            paths: path
          })
        }
        fence.shape.setOptions({
          radius: initialValues.radius
        })
      
      }
    })
  }

  public setInitialFences = (initial: Geofence[]) => {
    this.initialFences = initial;
  }

}


export const getMarker = (position: Position) => {
  if(!position.drawMarker){
    if(position.metyId === 1){
      return '/common/images/map/pointer_alarm.png';
    }
    else{
      return '/common/images/map/pointer.png';
    }
  }
  if (position.alarmWarning) {
    return '/proffweb/images/map/alarm-map-icon.png';
  }
  if (position.manDownWarning) {
    return '/proffweb/images/map/fall-map-icon.png';
  }
  if (position.geofenceWarning) {
    return '/proffweb/images/map/geofence-map-icon.png';
  }
  if (position.outOfBatteryWarning) {
    return '/proffweb/images/map/out-of-battery-map-icon.png';
  }
  if (position.selfCheckWarning) {
    return '/proffweb/images/map/selfcheck-map-icon.png';
  }
  if (position.inactiveWarning) {
    return '/proffweb/images/map/inactive-map-icon.png';
  }
  if (position.batteryWarning) {
    return '/proffweb/images/map/low-bat-map-icon.png';
  }
  if (position.offlineWarning) {
    return '/proffweb/images/map/offline-map-icon.png';
  }
  return '/proffweb/images/map/clear-map-icon.png';
}

const isGps = (position: Position) => {
  return position.posType === 0 || position.posType === 6;
};

export const isValid = (position: Position) => {
  if(position.posLati && position.posLong && position.posTime){
    return true;
  }
  return false;
}

const getCenterLat = (fence: Geofence) => {
  if (fence.vertices.length > 0){
    return `${fence.vertices[0].lat}`;
  }
  return "0";
};
const getCenterLng = (fence: Geofence) => {
  if (fence.vertices.length > 0){
    return `${fence.vertices[0].lng}`;
  }
  return "0";
};
