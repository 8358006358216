
import { Info } from "@Elements/Icon/Icons/FontAwesome";
import * as React from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { Params } from "../sensorTable";
import { useIntl } from "react-intl";

interface InfoActionProps{
  sensorId: number;
}

export const InfoAction = ({sensorId}: InfoActionProps) => {
  const { formatMessage  } = useIntl();
  const history = useHistory();
  const {url} = useRouteMatch();
  const params = useParams<Params>();

  const sensorInfoLink = `${url.split(params.name)[0]}info/sensor`;

  return (
    <Info
      onClick={() => {history.push(`${sensorInfoLink}/${sensorId}`)}}
      medium
      animate
      title={formatMessage({id: 'info', defaultMessage: 'Informasjon' })}
    />
  )
}