import * as React from "react";
import ReactModal from "react-modal";
import {Col, Grid, Row, Button} from "react-bootstrap";
import styled, { withTheme, ThemeProps } from "styled-components";
import { ITheme } from "../../../../themes/types";

const Content = styled.div`
  overflow-y: auto;
  max-height: 80vh;
  border-radius: 0 0 5px 5px;
  padding: 30px;
`;

const Title = styled.h4`
  font-size: 22px;
  font-weight: 500;
  margin: 10px 0 20px 0;
  padding: 0;
  display: block;
`;

const Footer = styled.div`
  padding: 0px 30px 30px 30px;
`;

interface LogoModalProps extends ThemeProps<ITheme>{
  isOpen: boolean;
  title?: string;
  content: JSX.Element;
  footer?: JSX.Element;
}

const LogoModal = withTheme(({ theme, isOpen, title, content, footer }: LogoModalProps) => {

  return(
    <ReactModal
      style={{
        content: {
          position: "initial",
          width: "750px",
          padding: "0",
          margin: "80px auto 0",
          overflow: "initial",
          maxWidth: "90%",
          background: theme.colors.backgroundPrimary,
        }
      }}
      isOpen={isOpen}
    >
      <Content>
        <Title>{title}</Title> 
        <Col>
        {content}
        </Col>
      </Content>
      <Footer>
        {footer}
      </Footer>
    </ReactModal>
  )
})

export default LogoModal;