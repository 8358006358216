export const delayOptions = [
  0,
  60,
  120,
  180,
  240,
  300,
  360,
  420,
  480,
  540,
  600,
  900,
  1200,
  1500,
  1800
]

export const delayOptionsWanderer = [
  0,
  10,
  20,
  30,
  40,
  50,
  60,
  120,
  180,
  240,
  300,
  420,
  480,
  540,
  600,
  900,
  1200,
  1500,
  1800
];

export const inactivityOptions = [3600, 7200, 14400, 21600, 28800, 43200];

export const warnTimeOptions = [180, 300, 600, 900, 1200, 1800, 3600];
