import styled from 'styled-components';
import { Popover } from 'react-bootstrap';

const InfoPopover = styled(Popover)`
  font-family: ${props => props.theme.font.family};
  max-width: 500px;
  text-align: justify;

  .arrow::after {
    border-bottom-color: ${({
      theme: {
        colors: { backgroundPrimary }
      }
    }) => backgroundPrimary} !important;
    border-top-color: ${({
      theme: {
        colors: { backgroundPrimary }
      }
    }) => backgroundPrimary} !important;
  }

  background-color: ${({
    theme: {
      colors: { backgroundPrimary }
    }
  }) => backgroundPrimary};
  color: ${({
    theme: {
      colors: { textPrimary }
    }
  }) => textPrimary};
  border-radius: ${({
    theme: { colors: { borderRadius = '4px' } = {} } = {}
  }) => borderRadius};

  .popover-title {
    background-color: ${({
      theme: {
        colors: { backgroundPrimary }
      }
    }) => backgroundPrimary};
    color: ${({
      theme: {
        colors: { textPrimary }
      }
    }) => textPrimary};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default InfoPopover;
