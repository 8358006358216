import { MessageDescriptor } from "react-intl";

import {Option} from "../../../DefaultSettings/Settings/FieldWrapper";
import { OrderFilterEnum } from '../../OrderFilterEnum';
import { LabelEnum } from '../../LabelEnum';

export type GetOrderFilter = (
  formatMessage: (message: MessageDescriptor) => string
) => Option[];


export const getOrderFilter: GetOrderFilter = (formatMessage) => {
  return[
    {text: capitalizeLabel(formatMessage({id: LabelEnum.DEVICE_LIST_SORT_PARTNER, defaultMessage: "Standardsortering"})), value: OrderFilterEnum.DEFAULT},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.CUSTOMER_ID_ASC, defaultMessage: "Customer ID - Stigende"})), value: OrderFilterEnum.CUSTOMER_ID_ASC},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.PRICE_GROUP_ASC, defaultMessage: "Price Group - Stigende"})), value: OrderFilterEnum.PRICE_GROUP_ASC},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.NO_OF_DEVICES_DESC, defaultMessage: "Devices - Synkende"})), value: OrderFilterEnum.NO_OF_DEVICES_DESC},
  ]
}

type CapitalizeLabel = (label: string) => string;

const capitalizeLabel: CapitalizeLabel = (label) => {
  return `${label.charAt(0).toUpperCase()}${label.substr(1, label.length - 1)}`;
}
