import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import Privacy from "../../Elements/Icon/Icons/Privacy";
import mapConfirmModal from "../Modals/mapConfirmModal";
import { Checkbox } from "../../Elements/CheckboxFormik";
import { RootState } from "./Store/types";
import { setMapConsent } from "./Store/routines";
import styled from "styled-components";
import { AppState } from "@Safemate/Store/types";

const MainRow = styled.div`
  display: flex;
  @media only screen and (max-width: 557px) {
  }
`;

const PrivacyRow = styled.div`
    display: flex;
    float: left;
    @media only screen and (max-width: 557px) {
      display:block;
      padding-top: 32px;
      padding-left: 52px;
    }
`;

const SubCol = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 557px) {
    margin-left: 0px;
  }
`;

const CheckWrap = styled.div`
  margin-top: 25px;
  @media only screen and (max-width: 557px) {
    display: none;
  }
`;

const CheckWrapMobile = styled.div`
  margin-top: 25px;
  @media only screen and (min-width: 558px) {
    display:none;
  }
  @media only screen and (max-width: 557px) {
    margin-left: -10px;
    margin-top: -25px;
  }
`;

const PrivacyDiv = styled.div`
  @media only screen and (max-width: 557px) {
    position: relative;
    margin-right: 8px;
    svg{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

interface MapConsentProps{
  mapConsentModal: boolean;
  setMapConsent: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ transactionLog: {status: { mapConsentModal }}}: AppState) => {
  return {
    mapConsentModal
  }
}

const mapDispatchToProps = {
  setMapConsent
}

const MapConsent = connect(mapStateToProps, mapDispatchToProps)(({ mapConsentModal, setMapConsent }: MapConsentProps) => {

  const [ checked, setChecked ] = React.useState(false);
  const { formatMessage } = useIntl();
  const { Comp, func } = mapConfirmModal();

  React.useEffect(() => {
    func(mapConsentModal);
  }, [mapConsentModal])

  return(
    <Comp
      disableClose
      maxWidth="500px"
      title={null}
      body={
        <MainRow>
          <PrivacyDiv>
            <Privacy size="large"/>
          </PrivacyDiv>
          <SubCol>
            <div>
              {formatMessage({id: "mapConsentText", defaultMessage: "Posisjoner er ansett som personopplysninger. Vær klar over at det vil anmerkes i loggene at du har observert posisjonene til denne Safemate-enheten."})}
            </div>
            <CheckWrap>
              <Checkbox
                value={checked}
                name={"mapConsent"}
                id={"mapConsent"}
                label={formatMessage({id: "dontShowAgain", defaultMessage: "Ikke vis igjen"})}
                onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                  setChecked(!checked);
                }}
              />
            </CheckWrap>
          </SubCol>
        </MainRow>
      }
      afterFooter={
        <PrivacyRow>
          <SubCol>
            <CheckWrapMobile>
                <Checkbox
                  value={checked}
                  name={"mapConsent"}
                  id={"mapConsent"}
                  label={formatMessage({id: "dontShowAgain", defaultMessage: "Ikke vis igjen"})}
                  onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                    setChecked(!checked);
                  }}
                />
              </CheckWrapMobile>
            </SubCol>
        </PrivacyRow>
      }
      confirmText={formatMessage({id: "ok", defaultMessage: "ok"})}
      confirmFunc={() => {
        if(checked)
          setMapConsent();
      }}
    />
  )
})

export default MapConsent;
