import { hashUrlParams, parseUrlParams } from "@Safemate/utils";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useLocation } from "react-router";
import { resetPassword } from "../Store/routines";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";

interface ResetPasswordProps{
  resetPassword: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToProps = {
  resetPassword
}

const ResetPassword = connect(null, mapDispatchToProps)(({resetPassword}: ResetPasswordProps) => {

  const { formatMessage } = useIntl();
  const [ token, setToken ] = React.useState("");

  const [ password, setPassword ] = React.useState("");
  const [ passwordCopy, setPasswordCopy ] = React.useState("");

  const keyUpEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter"){
      doPasswordReset();
    }
  }

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  }
  const changePasswordCopy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordCopy(event.currentTarget.value);
  }

  const doPasswordReset = () => {
    resetPassword({password, passwordCopy, token})
  }

  React.useEffect(() => {
    const urlParams = hashUrlParams(window.location.href);
    const passToken = urlParams['passToken'];
    setToken(passToken);
  }, [])

  return (
    <React.Fragment>
      <h4>{formatMessage({id: "resetPasswordDesc", defaultMessage: "Reset passord"})}</h4>
      <input value={password} id="passwordIdReset" type="password" name="password"
        placeholder={formatMessage({id: "resetPasswordPlaceholder", defaultMessage: "resetPasswordPlaceholder"})}
        onKeyUp={keyUpEvent}
        onChange={changePassword} />
      <input value={passwordCopy} id="passwordIdResetCopy" type="password" name="passwordCopy"
        placeholder={formatMessage({id: "resetPasswordPlaceholderCopy", defaultMessage: "resetPasswordPlaceholderCopy"})}
        onKeyUp={keyUpEvent}
        onChange={changePasswordCopy} />
      <Button onClick={doPasswordReset} type="button" className="btn btn-default">
        {formatMessage({id: "resetPasswordProSubmit", defaultMessage: "Reset"})}
      </Button>
    </React.Fragment>
  )
})

export default ResetPassword;