import {TextLabel, TextLabelState, TextLabelWithText} from "../types";
import {Reducer} from "redux";
import { getTextLabels, filter } from "../routines";
import { addTexts } from "@Safemate/TranslationModule/TranslateOverlay/Store/routines";
import { TranslationModuleActionTypes } from "../actionTypes";

const textLabelState: TextLabelState = {
  textLabels: [],
  filteredTextLabels: [],
  loading: true
}

const text: Reducer<TextLabelState> = (state: TextLabelState = textLabelState, action) => {
  switch (action.type) {

    case TranslationModuleActionTypes.SET_LANGUAGE_OPTION:
    case TranslationModuleActionTypes.SET_LANGUAGE_FILTER:
    case TranslationModuleActionTypes.SET_SEARCH:{
      return {
        ...state,
        loading: true
      }
    }

    case filter.TRIGGER:
      return{
        ...state,
        loading: true
      }

    case filter.SUCCESS:
      return {
        ...state,
        loading: false,
        filteredTextLabels: action.payload
      }

    case filter.FULFILL:
      return {
        ...state,
        loading: false
      }

    case addTexts.SUCCESS:
      return {
        ...state,
        textLabels: state.textLabels.map(t => {
          if(t.label === action.payload[0].label){
            return {
              ...t,
              texts: action.payload
            }
          }
          else{
            return t;
          }
        })
      }
    case getTextLabels.SUCCESS:
      return {
        ...state,
        loading: false,
        textLabels: action.payload,
      }

    default:
      return state;
  }
};

export default text;
