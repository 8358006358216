import {DeviceKnownMac, DeviceMac, DeviceMacs} from "./types";
import EditButton from "./editbutton"
import {RowContent} from "../../UserAdmin/Users/utils";
import * as React from "react";
import {Header} from "../../UserAdmin/options";

export type GetDeviceMacListValues = (
  deviceMacs: Array<DeviceMac>,
  flag: boolean
) => Array<Array<RowContent>>;


export const formatDate = (date: Date) => {
    const today = new Date(date);
    const dd = leftPadZero( today.getDate() );
    const mm = leftPadZero( ( today.getMonth() + 1 ) );
    const yyyy = '' + today.getFullYear();
    const hh = leftPadZero( today.getHours() );
    const mi = leftPadZero( today.getMinutes() );
    const ss = leftPadZero( today.getSeconds() );   
    return dd+'/'+mm+'/'+yyyy +' '+hh+':'+mi+':'+ss;
}

export const formatDateOnly = (date: Date) => {
  const today = new Date(date);
  const dd = leftPadZero( today.getDate() );
  const mm = leftPadZero( ( today.getMonth() + 1 ) );
  const yyyy = '' + today.getFullYear();
  return dd+'/'+mm+'/'+yyyy;
}

export const formatNorwegianDate = (date: Date) => {
  const today = new Date(date);
  const dd = leftPadZero( today.getDate() );
  const mm = leftPadZero( ( today.getMonth() + 1 ) );
  const yyyy = '' + today.getFullYear();
  const hh = leftPadZero( today.getHours() );
  const mi = leftPadZero( today.getMinutes() );
  const ss = leftPadZero( today.getSeconds() );   
  return dd+'.'+mm+'.'+yyyy +' '+hh+':'+mi+':'+ss;
}

export const formatNorwegianDateMin = (date: Date) => {
  const today = new Date(date);
  const dd = leftPadZero( today.getDate() );
  const mm = leftPadZero( ( today.getMonth() + 1 ) );
  const yyyy = '' + today.getFullYear();
  const hh = leftPadZero( today.getHours() );
  const mi = leftPadZero( today.getMinutes() );
  return dd+'.'+mm+'.'+yyyy +' '+hh+':'+mi;
}

const leftPadZero = (number: number) =>{
    return (number < 10 ) ? '0'+number : ''+number;
}

export const defaultDeviceKnownMac = (deviId: number): DeviceKnownMac => {
  return {
    dekmId:0,
    demaId: 0,
    deviId: deviId,
    address: "",
    latLng: "",
    location: ""
  }
}

export const defaultDeviceMac = (deviId: number): DeviceMac => {
  return {
    deviId: deviId,
    demaId: 0,
    name: "",
    latLng: "",
    latestTimestamp: new Date(),
    macAddress: "",
    receivedCount: 0,
    type: "",
    deviceKnownMac: defaultDeviceKnownMac(deviId),
    rssi:0,
    selected: false,
    visible: false,
    headerSaved: false
  }
}

export const defaultDeviceMacs = (deviId: number): DeviceMac[] =>{
  let deviceMacs:DeviceMac[] = [];
  deviceMacs.push(defaultDeviceMac(deviId));
  return deviceMacs;
}

export const getDeviceMacListValues:GetDeviceMacListValues = ( deviceMacs, flag ) => {
  return deviceMacs.filter( (deviceMac: DeviceMac)=>{
    if( (flag && deviceMac.deviceKnownMac.demaId > 0 ) || ( !flag && deviceMac.deviceKnownMac.demaId < 1 ) ){
      return true;
    }
    return false;
  } ).map((deviceMac: DeviceMac)=>{
    return [
      {text: deviceMac.name, skipTranslate: true},
      {text: deviceMac.macAddress, skipTranslate: true},
      {text: deviceMac.receivedCount.toString(), skipTranslate: true},
      {text: formatDate(deviceMac.latestTimestamp), skipTranslate: true},
      {text:
          <EditButton deviceMac={deviceMac} />
      }
    ]
  });

}

export const header: Array<Header> = [
  { text: "name"},
  { text: "macAddress" },
  { text: "receivedCount", priority: 2 },
  { text: 'latestTimestamp'},
  { text: 'action', priority: 2 }
]

const compareDeviceMac = (current: DeviceMac, initial: DeviceMac) => {
  if(
    current && initial &&
    (current.demaId !== initial.demaId
      ||  current.latLng !== initial.latLng
      ||  current.macAddress !== initial.macAddress
      ||  current.deviId !== initial.deviId
      ||  current.type !== initial.type
      ||  current.rssi !== initial.rssi
      ||  current.deviceKnownMac.latLng !== initial.deviceKnownMac.latLng
      ||  current.deviceKnownMac.address !== initial.deviceKnownMac.address
      ||  current.deviceKnownMac.location !== initial.deviceKnownMac.location
      ||  current.deviceKnownMac.demaId !== initial.deviceKnownMac.demaId
      ||  current.deviceKnownMac.dekmId !== initial.deviceKnownMac.dekmId)
  ){
    return true;
  }
  return false;
}

export const isSaveNeeded = (current: DeviceMac[], initial: DeviceMac[]) => {
  if(current.length===initial.length ){
    let result = false;
    for(let i=0; i<current.length; i++){
      if(compareDeviceMac(current[i], initial[i])){
        return true;
      }
    }
    return result;
  }
  return false;
}
