import React from 'react';
import SIcon from './SIcon';

const SelfcheckFail = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M15.3 3.4l1.2-1.7-.5-.4L14.8 3c-1.5-.9-3.5-.4-4.5 1l-.1-.1c-.3-.1-.6-.1-.8.1l-.6.9c-.1.2-.1.6.2.7l1.4 1-1.5 2.1c-.1.1 0 .3.1.4.2.1.4.1.5-.1l1.4-2L13 8.5l-1.4 2.1c-.1.2-.1.4.1.5.2.1.4.1.5-.1l1.4-2.1 1.3.9c.2.2.6.1.7-.1l.6-.8c.2-.2.1-.6-.1-.7l-.2-.2c1-1.5.8-3.5-.6-4.6zm.1 4.2l-.4.5.6.4-.4.6-5.7-4 .4-.6.5.5.4-.6c.9-1.2 2.6-1.5 3.8-.7 1.3.9 1.6 2.6.8 3.9z" />
    <path d="M12.3 11.9L10.9 14l-2-1.4 1.4-2.1c.1-.2.1-.4-.1-.5-.1 0-.3 0-.4.2l-1.4 2.1L6.5 11l1.4-2.1c.1-.2.1-.4-.1-.5-.2-.1-.4-.1-.5.1l-1.4 2.1-.2-.2c-.2-.2-.6-.1-.7.1l-.6.8c-.2.2-.1.6.1.7l.1.1c-1 1.5-.8 3.4.6 4.6L4 18.4l.6.4 1.2-1.7c1.5.9 3.5.4 4.5-1l.1.1c.2.2.6.1.7-.1l.6-.8c.2-.2.1-.6-.1-.7l-.1-.1 1.4-2.1c.1-.2.1-.4-.1-.5-.2-.1-.4-.1-.5 0zm-1.7 3.6l-.6-.4-.4.6c-.9 1.2-2.6 1.5-3.8.7-1.2-.9-1.5-2.6-.7-3.8l.5-.6-.6-.4.4-.6 5.6 4-.4.5z" />
  </svg>
);

const Icon = props => <SIcon {...props} icon={SelfcheckFail} />;

export default Icon;
