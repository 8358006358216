import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as Routines from '../Routines';
import { feedbackFailure } from '../../../Feedback/reducer';
import { AppAction } from '../../../Store/types';
import { FilterState } from '../types';
import * as Utils from '../../utils';
import { DeviceListActionTypes } from '../actionTypes';
import * as Api from '../api';
import { getDevices, getFilters, getDeviceTypes, getTwinSuffix, getPartnerDeviceList } from '../selectors';
import { Option } from "@Elements/Dropdown/types";
import { selectCustomers, selectCurrentContext, selectMunicipalities } from '@Safemate/Store/selectors';
import { ICustomerWithPolicy } from '@Safemate/Model/Customer';
import { IDevice } from '@Safemate/Model/Device';
import { SiteTypeEnum } from '@Safemate/SiteTypeEnum';

function* filterDevices(action: AppAction<null>){
  try {
    const devices: IDevice[] = yield select(getDevices);

    const filters: FilterState = yield select(getFilters);

    const currentContext: SiteTypeEnum = yield select(selectCurrentContext);
    const isAhp = currentContext === SiteTypeEnum.AHP;
    const customers: ICustomerWithPolicy[] = isAhp ? yield select(selectMunicipalities) : yield select(selectCustomers);
    const deviceTypes: Option[] = yield select(getDeviceTypes);
    const twinSuffix: string = yield select(getTwinSuffix);
    let filteredDevices = Utils.filterDevices(devices, filters, customers, deviceTypes, twinSuffix);

    if(filteredDevices.length == 0 && filters.search != ""){
      const partnerDeviceList: IDevice[] = yield select(getPartnerDeviceList);
      const filteredPartnerDevices = Utils.filterPartnerDevices(filters, partnerDeviceList);
      yield put(Routines.filterPartnerDevices.success(filteredPartnerDevices));
    }

    yield put(Routines.filterDevices.success(filteredDevices));
  }
  catch(e){
    console.log(e)
    yield feedbackFailure(e);
    yield put(Routines.filterDevices.failure());
  }
}

function* searchDevices(action: AppAction<null>){
  const devices: IDevice[] = yield select(getDevices);

  const filters: FilterState = yield select(getFilters);

  const currentContext: SiteTypeEnum = yield select(selectCurrentContext);
  const isAhp = currentContext === SiteTypeEnum.AHP;
  const customers: ICustomerWithPolicy[] = isAhp ? yield select(selectMunicipalities) : yield select(selectCustomers);
  const deviceTypes: Option[] = yield select(getDeviceTypes);
  const twinSuffix: string = yield select(getTwinSuffix);
  let filteredDevices = [];
  if(filters.maintainFilters){
    filteredDevices = Utils.filterDevices(devices, filters, customers, deviceTypes, twinSuffix);
  }
  else{
    filteredDevices = Utils.devicesSearchNoFilter(filters, devices);
    yield put(Routines.clearFilters.success());
  }
  yield put(Routines.filterDevices.success(filteredDevices));
}

function* filterPartnerDevices(action: AppAction<null>){
  try {
    const filters: FilterState = yield select(getFilters);
    const partnerDeviceList: IDevice[] = yield select(getPartnerDeviceList);
    const filteredPartnerDevices = Utils.filterPartnerDevices(filters, partnerDeviceList);
    yield put(Routines.filterPartnerDevices.success(filteredPartnerDevices));
  }
  catch(e){
    console.log(e)
    yield feedbackFailure(e);
    yield put(Routines.filterPartnerDevices.failure());
  }
}

function* filterSaga(){
    yield takeLatest(Routines.initialize.SUCCESS, filterDevices);
    yield takeLatest(Routines.updateDevice.SUCCESS, filterDevices);
    yield takeLatest(DeviceListActionTypes.FILTER_DEVICE_TYPE, filterDevices);
    yield takeLatest(DeviceListActionTypes.FILTER_ALARM, filterDevices);
    yield takeLatest(DeviceListActionTypes.FILTER_CUSTOMER, filterDevices);
    yield takeLatest(DeviceListActionTypes.FILTER_SEARCH, searchDevices);
    yield takeLatest(DeviceListActionTypes.FILTER_ORDER, filterDevices);
    yield takeLatest(Routines.searchSensors.SUCCESS, filterDevices);
    yield takeLatest(Routines.filterPartnerDevices.TRIGGER, filterPartnerDevices);
}

export default filterSaga;
