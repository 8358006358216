import * as React from "react";

import GeofenceHeader from "./header";
import GeofenceControls from "./Controls";
import GeofenceMap from "./Map/map";
import { EditHeader, BaseUrlContext } from ".";
import { Geofence } from "./types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Col } from "react-bootstrap";
import { AppState } from "@Safemate/Store/types";


interface EditFenceProps{
  geofence: Geofence[];
}

const mapStateToProps = ({ settings: {geofence: { geofence }}}: AppState) => {
  return{
    geofence
  }
}

const EditFence = connect(mapStateToProps)(({geofence}: EditFenceProps) => {

  const baseUrl = React.useContext(BaseUrlContext);

  return(
    <Grid fluid>
      <Col md={3} sm={12}>
        <GeofenceControls/>
      </Col>
      <Col md={9} sm={12}>
        <GeofenceMap/>
      </Col>
    </Grid>
  )
})




export default EditFence;