import {EventSetupSettings, DeviceSettingsWithFallback, OtherSettingsWithFallback} from "./types";

export const defaultDeviceSetting: DeviceSettingsWithFallback = {
  dehaId: 0,
  selfCheckLimit: "",
  messageIntervalPeriodic: "",
  staticTooLong: "",
  staticIgnoreNights: "",
  satellites: "",
  disablePositioning: "",
  outdatedPositionThreshold: "",

  trackingInterval: "",
  autoTracking: "",

  soundLevel: "",
  soundLevelRingtone: "",

  lightLevel: "",
  lightMode: "",
  led: "",
  systemSounds: "",
  incomingRingTone: "",

  batteryThreshold: "",
  acceptLBS: "",

  selfCheckLimitWithFallback: "",
  messageIntervalPeriodicWithFallback: "",
  staticTooLongWithFallback: "",
  staticIgnoreNightsWithFallback: "",
  satellitesWithFallback: "",
  disablePositioningWithFallback: "",
  outdatedPositionThresholdWithFallback: "",

  trackingIntervalWithFallback: "",
  autoTrackingWithFallback: "",

  soundLevelWithFallback: "",
  soundLevelRingtoneWithFallback: "",

  lightLevelWithFallback: "",
  lightModeWithFallback: "",
  ledWithFallback: "",
  systemSoundsWithFallback: "",
  incomingRingToneWithFallback: "",

  batteryThresholdWithFallback: "",
  acceptLBSWithFallback: "",


  // Trigger four
  triggerFourHeartbeat: "",
  triggerFourHeartbeatWithFallback: "",
  soundLevelTriggerFour: "",
  soundLevelTriggerFourWithFallback: "",
  soundLevelRingtoneTriggerFour: "",
  soundLevelRingtoneTriggerFourWithFallback: "",
  micVolume: "",
  micVolumeWithFallback: "",
  voicePromptVolumeTriggerFour: "",
  voicePromptVolumeTriggerFourWithFallback: "",

  // Toplovo 4G
  soundLevelTriggerTwo4G: "",
  soundLevelTriggerTwo4GWithFallback: "",
  soundLevelRingtoneTriggerTwo4G: "",
  soundLevelRingtoneTriggerTwo4GWithFallback: "",

  //GX8
  callbackTimer: "",
  prefComMethod: "",
  twoWayDECTTimer: "",
  answerIncoming: "",
  silentActive: "",
  voicePrompt: "",
  guardTimeBeep: "",
  confirmBeep: "",
  wandererBeep: "",
  powerFailureReportDelay: "",
  sensorSupervisionCheckActive: "",
  sensorSupervisionCheckTimer: "",
  sensorSupervisionCheckTimerPendant: "",
  guardTime: "",
  alarmTimeout: "",
  helpArrive: "",
  twoWayMic: "",
  helpArriveIndicator: "",
  callbackIndicator: "",
  localLearning: "",
  dectSoundLevel: "",
  bypassTimer: "",
  heartbeatInterval: "",
  alarmSmsReceipt: "",
  firmware: "",
  safemateAsReceiver: "",
  voicePromptVolumeGX8: "",
  sensorSensitivity: "",

  climaxVoicePromptAlarm: "",
  climaxVoicePromptCallback: "",
  climaxVoicePromptFall: "",
  climaxVoicePromptWanderer: "",
  climaxVoicePromptBed: "",



  callbackTimerWithFallback: "",
  prefComMethodWithFallback: "",
  twoWayDECTTimerWithFallback: "",
  answerIncomingWithFallback: "",
  silentActiveWithFallback: "",
  voicePromptWithFallback: "",
  guardTimeBeepWithFallback: "",
  confirmBeepWithFallback: "",
  wandererBeepWithFallback: "",
  powerFailureReportDelayWithFallback: "",
  sensorSupervisionCheckActiveWithFallback: "",
  sensorSupervisionCheckTimerWithFallback: "",
  sensorSupervisionCheckTimerPendantWithFallback: "",
  guardTimeWithFallback: "",
  alarmTimeoutWithFallback: "",
  helpArriveWithFallback: "",
  twoWayMicWithFallback: "",
  helpArriveIndicatorWithFallback: "",
  callbackIndicatorWithFallback: "",
  localLearningWithFallback: "",
  dectSoundLevelWithFallback: "",
  bypassTimerWithFallback: "",
  heartbeatIntervalWithFallback: "",
  alarmSmsReceiptWithFallback: "",
  firmwareWithFallback: "",
  safemateAsReceiverWithFallback: "",
  voicePromptVolumeGX8WithFallback: "",
  sensorSensitivityWithFallback: "",

  climaxVoicePromptAlarmWithFallback: "",
  climaxVoicePromptCallbackWithFallback: "",
  climaxVoicePromptFallWithFallback: "",
  climaxVoicePromptWandererWithFallback: "",
  climaxVoicePromptBedWithFallback: "",

  geolocationWIFILookupGoogle: "",
  geolocationWIFILookupCombain: "",
  geolocationWIFILookupGoogleWithFallback: "",
  geolocationWIFILookupCombainWithFallback: "",

  //Team alert
  teamAlert: "",
  teamAlertWithFallback: "",
  teamAlertLearnUrl: "",
  climaxVoicePromptACFailureWithFallback: "",
  climaxVoicePromptACRestoreWithFallback: "",
  climaxVoicePromptACFailure: "",
  climaxVoicePromptACRestore: "",
  climaxReport: [],
  lightAlarmModeWithFallback: "",
  lightNormalModeWithFallback: "",
  lightAlarmMode: "",
  lightNormalMode: "",
  screenBrightnessWithFallback: "",
  screenLightModeWithFallback: "",
  lowBatteryVoicePromptWithFallback: "",
  synchronizeSoundWithFallback: "",
  screenBrightness: "",
  screenLightMode: "",
  lowBatteryVoicePrompt: "",
  synchronizeSound: ""
}

export const defaultOtherSetting: OtherSettingsWithFallback = {
  // Call filter and whitelist
  ahpNumber: "",
  aclCheck: "",
  phoneNoWhiteList: "",

  aclCheckWithFallback: "",
  phoneNoWhiteListWithFallback: "",


  // Privacy
  deviceTransactionLogsDays: "",
  userTransactionLogsDays: "",
  devicePositionHistoryDepthHours: "",
  devicePositionHistoryDepthPosPlots: "",
  nokEmployeeLoginIdleHour: "",
  userInactivityLimit: "",

  deviceTransactionLogsDaysWithFallback: "",
  userTransactionLogsDaysWithFallback: "",
  devicePositionHistoryDepthHoursWithFallback: "",
  devicePositionHistoryDepthPosPlotsWithFallback: "",
  nokEmployeeLoginIdleHourWithFallback: "",
  userInactivityLimitWithFallback: "",


  // Integration
  msgIntegration: "",
  msgI9nHandler: "",
  msgI9nEndpoint: "",
  msgI9nBeforeCall: "",
  msgI9nAfterCall: "",
  msgI9nHaltCalls: "",
  msgI9nHaltI9n: "",
  msgI9nUsername: "",
  msgI9nPassword: "",
  msgI9nFailureFallback: "",
  msgI9nFailureFallbackNumber: "",
  msgI9nFailureFallbackCountryCode: "",

  integrationPermission: "",
  msgIntegrationSecured: "",
  msgIntegrationWithFallback: "",
  msgI9nHandlerWithFallback: "",
  msgI9nEndpointWithFallback: "",
  msgI9nBeforeCallWithFallback: "",
  msgI9nAfterCallWithFallback: "",
  msgI9nHaltCallsWithFallback: "",
  msgI9nHaltI9nWithFallback: "",
  msgI9nUsernameWithFallback: "",
  msgI9nFailureFallbackWithFallback: "",
  msgI9nFailureFallbackNumberWithFallback: "",
  msgI9nFailureFallbackCountryCodeWithFallback: "",
  phoneNoWhiteList1WithFallback: "",
  phoneNoWhiteList2WithFallback: "",
  phoneNoWhiteList3WithFallback: "",
  phoneNoWhiteList1: "",
  phoneNoWhiteList2: "",
  phoneNoWhiteList3: "",
  sensioIntegration: undefined,
  msgI9nPasswordWithFallback: "",
  hasPassword: false,
  inheritPassword: false
}

export const defaultEventSetupSetting: EventSetupSettings = {
  enableDefaultEventSetup: "",
  enableDefaultEventSetupWithFallback:  "",
  sequentialCalling:  "",
  sequentialCallingWithFallback:  "",
  deviceEventSetups: []
}
