import {Reducer} from "redux";
import { RootState } from "../types";
import { couponValidation, customerValidation, forgotPassword, login, logout, prePopulateEmail, registerCustomer, registerUserPrivate, resendVerification, resetPassword, sendGdpr, validate } from "../routines";
import { getUser } from "@Safemate/Store/routines";
import SessionActionTypes from "../actionTypes";
import { LoginStep } from "@Safemate/Session";
import { RegisterStep } from "@Safemate/Session/Register";

const session: RootState = {
  loadingUser: true,
  loggedIn: false,
  validated: false,
  showTfa: false,
  loginStep: LoginStep.LOGIN,
  registerStep: RegisterStep.INDEX,
  prePopulatedEmail: "",
  sendingGdpr: false,
  initialLoad: false,
  showNameValidationModal: false
};

const sessionReducer: Reducer<RootState> = (state: RootState = session, action) => {
  switch (action.type) {

    case getUser.SUCCESS:
      return{
        ...state,
        loggedIn: true
      }

    case getUser.FULFILL:
      return{
        ...state,
        initialLoad: true
      }

    case validate.SUCCESS:
      return{
        ...state,
        validated: action.payload
      }
    
    case prePopulateEmail.SUCCESS:
      return{
        ...state,
        prePopulatedEmail: action.payload
      }

    case login.SUCCESS:
      return{
        ...state,
        loggedIn: true
      }

    case login.FAILURE:
      return{
        ...state,
        showTfa: action.payload
      }

    case logout.SUCCESS:
      return {
        ...state,
        loggedIn: false
      }

    case SessionActionTypes.SET_LOGGED_IN:
      return{
        ...state,
        loggedIn: action.payload
      }

    case SessionActionTypes.SET_LOGIN_STEP:
      return{
        ...state,
        loginStep: action.payload
      }

    case forgotPassword.SUCCESS:
      return{
        ...state,
        loginStep: LoginStep.LOGIN,
        registerStep: RegisterStep.INDEX,
      }

    case resetPassword.SUCCESS:
      return{
        ...state,
        loginStep: LoginStep.LOGIN,
        registerStep: RegisterStep.INDEX,
      }

    case resendVerification.SUCCESS:
      return{
        ...state,
        loginStep: LoginStep.LOGIN,
        registerStep: RegisterStep.INDEX,
      }

    case couponValidation.SUCCESS:
      return{
        ...state,
        registerStep: RegisterStep.PRIVATE_CONFIRM
      }

    case registerUserPrivate.SUCCESS:
      return {
        ...state,
        loginStep: LoginStep.LOGIN,
        registerStep: RegisterStep.INDEX,
        prePopulatedEmail: action.payload
      }

    case customerValidation.SUCCESS:

      if(action.payload){
        return {
          ...state,
          showNameValidationModal: true
        }
      }

      return {
        ...state,
        registerStep: RegisterStep.FIRM_DETAILS
      }

    case customerValidation.FULFILL:
      return {
        ...state,
        showNameValidationModal: false
      }

    case SessionActionTypes.SET_NAME_VALIDATION_MODAL:
      return {
        ...state,
        showNameValidationModal: action.payload
      }

    case registerCustomer.SUCCESS:
      return {
        ...state,
        loginStep: LoginStep.LOGIN,
        registerStep: RegisterStep.INDEX,
        prePopulatedEmail: action.payload
      }

    case SessionActionTypes.SET_REGISTER_STEP:
      return{
        ...state,
        registerStep: action.payload
      }

    case sendGdpr.TRIGGER:
      return{
        ...state,
        sendingGdpr: true
      }

    case sendGdpr.FULFILL:
      return{
        ...state,
        sendingGdpr: false
      }

    default:
      return state;
  }
};

export default sessionReducer;
