import * as React from "react";
import { useIntl } from "react-intl";

import withFormWrapper from "@Elements/Form/formWrapper";
import styled from "styled-components";
import {GeofenceSettings} from "@Safemate/DefaultSettings/Settings/types";
import {Col, Row} from "react-bootstrap";
import { GeofencesIndexProps } from "..";
import { Header, StyledField } from "../styles";

const Suffix = styled.span`
  margin-left: 5px;
`;

const Radius = withFormWrapper<GeofenceSettings, GeofencesIndexProps>(({ formik: { values: { geofences } } , geofencesIndex }) => {

  const { formatMessage  } = useIntl();
  
  if(geofences[geofencesIndex].polygon || geofences[geofencesIndex].indoor || geofences[geofencesIndex].polygon === undefined)
    return null;
  
  return(
    <Row>
      <Col xs={12}>
        <Header>{formatMessage({id: "radius", defaultMessage: "Radius"})}</Header>
        <StyledField type="number" name={`geofences.${geofencesIndex}.radius`} />
        <Suffix>{formatMessage({id: "meter", defaultMessage: "meter"})}</Suffix>
      </Col>
    </Row>
  )
})

export default Radius;
