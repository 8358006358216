import * as React from "react";
import withFormWrapper from "./formWrapper";
import { Field, FormikProps } from "formik";
import { injectIntl, WrappedComponentProps, MessageDescriptor } from "react-intl";
import { SettingDiv } from "./styles";
import InformationIcon from "@Icons/Information1";
import Tooltip from "@Elements/Tooltip";
import { Checkbox } from "@Elements/CheckboxFormik";

interface CheckboxProps<T> extends WrappedComponentProps{
    formik: FormikProps<T>;
}

interface CheckboxOptions{
    label: MessageDescriptor;
    prefix?: string;
    tooltip?: string;
    tooltipTitle?: MessageDescriptor;
    padding?: boolean;
    contents?: boolean;
    disabled?: boolean;
  }


export function CheckWrap<T>(name: string, options: CheckboxOptions): React.FunctionComponent<any>{
  return withFormWrapper(
    injectIntl(({intl: {formatMessage}, formik: {values}}: CheckboxProps<any>) => {

      let value;
      let field = name;
      if(options.prefix){
        value = values[options.prefix][name];
        field = `${options.prefix}.${name}`;
      }
      else{
        value = values[name];
      }

      return(
        <SettingDiv flex padding={options.padding} contents={options.contents} key={name}>
          <Field
              value={value}
              name={field}
              id={field}
              label={formatMessage(options.label)}
              component={Checkbox}
              disabled={options.disabled}
              outerDiv={
                {
                  display: "flex",
                  alignItems: "center",
                  marginRight: "5px"
                }
              }
          />
          {options.tooltip &&
            <Tooltip
                popoverId={`${name}-popover`}
                labelId={options.tooltip}
                defaultMessage={options.tooltip}
                title={options.tooltipTitle ? formatMessage(options.tooltipTitle) : ""}
            >
                <InformationIcon pointer size="medium" />
            </Tooltip>
          }
        </SettingDiv>
      )
    })
  ) as React.FunctionComponent<any>
}