
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Routines from '../Routines';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import * as Api from '../api';
import { AppAction } from '../../../DefaultSettings/Store/types';
import { UpdateDevice } from './device';
import { LabelEnum } from '../../LabelEnum';
import { AlarmAnalysis } from '../types';

function* alarmAnalysis(action: AppAction<number>){
  try{
    const alarmAnalysis: AlarmAnalysis = yield call(Api.alarmAnalysis, action.payload);
    yield put(Routines.alarmAnalysis.success({analysis: alarmAnalysis, device: action.payload}));
  }
  catch(e){
    yield put(Routines.alarmAnalysis.failure());
  }
}

export interface AlarmShare{
  deviceId: number;
  recipients: string;
}

function* shareAlarm(action: AppAction<AlarmShare>){
  try{
    yield call(Api.shareAlarm, action.payload);
    yield put(Routines.shareAlarm.success());
    yield feedbackSuccess(LabelEnum.ALARM_SHARED);
  }
  catch(e){
    yield put(Routines.shareAlarm.failure());
    yield feedbackFailure(e);
  }
}

function* resetAlarm(action: AppAction<UpdateDevice>){
  try{
    yield call(Api.resetAlarm, action.payload.deviceId);
    yield put(Routines.resetAlarm.success());
    yield put(Routines.updateDevice(action.payload));
  }
  catch(e){
    yield put(Routines.resetAlarm.failure());
    yield feedbackFailure(e);
  }
}

function* alarmAnalysisSaga(){
    yield takeEvery(Routines.alarmAnalysis.TRIGGER, alarmAnalysis);
    yield takeLatest(Routines.shareAlarm.TRIGGER, shareAlarm);
    yield takeLatest(Routines.resetAlarm.TRIGGER, resetAlarm);
}

export default alarmAnalysisSaga;
