import React from 'react';
import { Row } from 'react-bootstrap';
import { FastField, Field } from 'formik';
import { Checkbox } from '../../Elements/CheckboxFormik';
import InformationIcon from "../../Elements/Icon/Icons/InformationIcon";
import TooltipWrapper from "../../Elements/Tooltip";
import styled from 'styled-components';

export const Content = styled.div<{endSection?: boolean}>`
  align-items: center;
  display: flex;
  padding: 5px 0px 20px 0px;
  border-bottom: ${props => (props.endSection ? '1px solid #595a5e' : 'none')};
  margin-bottom: ${props => (props.endSection ? '10px' : 'initial')};
`;


interface TooltipProps{
  tooltip: string;
  id: string;
}

export const Tooltip = ({ tooltip, id }: TooltipProps) => {
  return (
    <TooltipWrapper
      popoverId={`${id}-popover`}
      labelId={tooltip}
      defaultMessage={tooltip}
    >
        <InformationIcon size="medium" />
    </TooltipWrapper>
  );
};

interface CheckBoxRowProps{
  endSection?: boolean;
  values: any;
  name: string;
  label: string;
  tooltip?: string;
}

export const CheckBoxRow = ({ endSection, values, name, label, tooltip }: CheckBoxRowProps) => {
  return (
    <Content endSection={endSection}>
      <Field
        value={values}
        name={name}
        id={name}
        label={label}
        component={Checkbox}
      />
      &nbsp;
      {tooltip && <Tooltip id={name} tooltip={tooltip} />}
    </Content>
  );
};
