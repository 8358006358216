import * as React from 'react';
import Slider from 'react-input-slider';

import { SoundLightSettings } from "../../types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { WrappedComponentProps, injectIntl, MessageDescriptor } from 'react-intl';
import styled from 'styled-components';
import { Field } from 'formik';
 
interface ValueSliderProps extends WrappedComponentProps{
  name: string
  limit: number[]
  title?: MessageDescriptor;
  parent?: string;
}

const InputWrapper = styled.div`
  width: 100px;
  margin-bottom: 5px;
`;

const TitleWrapper = styled.div`

    padding: 10px 0;

`;

const StyledField = styled(Field)`
  width: 120px;
  height: 40px;
  font-size: 16px;
  background: none;
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius};
  color: ${props => props.theme.colors.textPrimary};
  outline: none;
  padding: 0 15px;
  transition: 0.3s;
`;

const ValueSlider = injectIntl(
  withFormWrapper<SoundLightSettings, ValueSliderProps>(({ formik: { values, setFieldValue }, intl: { formatMessage }, limit, name, title, parent}) => {

    let disabled = false;

    if(parent){
      const parentvalue = values[parent];
      if(parentvalue === false){
        disabled = true;
      }
    }

    const value = parseInt(values[name] as string, 10);
    const [min, max] = limit;

    return (
      <div>
        <TitleWrapper>{title && <label>{formatMessage(title)}</label>}</TitleWrapper>
        <InputWrapper>
          <StyledField disabled={disabled} type="number" min={min} max={max} name={name}/>
        </InputWrapper>
        <Slider
          disabled={disabled}
          axis="x"
          xmin={min}
          xmax={max}
          xstep={1}
          x={value}
          onChange={({x}) => setFieldValue(name, `${x}`)}
        />
      </div>
    );
  })
)

export default ValueSlider;