import {CustomerState, Customer, Organization, MappedSubOrgs} from "../types";
import {Reducer} from "redux";
import * as Routines from "../routines";
import { SubUnitsActionTypes } from "../actionTypes";

const customerState: CustomerState = {
  selectedCustomer: {
    customerId: 0,
    customerName: "",
    level: 0,
    defaultAhp: 0,
    parent: 0,
    whiteListPhoneNumbers: "",
    policies: {
      custId: 0,
      global: false,
      id: 0,
      policies: {},
      roleName: "",
      technicianRights: false
    },
  },
  parentCustomer: {
    customerId: 0,
    policies: {
      custId: 0,
      global: false,
      id: 0,
      policies: {},
      roleName: "",
      technicianRights: false
    },
    firm: {
      activatedDate: 0,
      active: false,
      addrCity: "",
      addrCounId: 0,
      addressLine1: "",
      addressLine2: "",
      addrName: "",
      addrNameForBilling: "",
      addrReference: "",
      addrSamesame: false,
      addrZipCode: "",
      allowAllPhoneNo: false,
      billingAddrCity: "",
      billingAddrCounId: 0,
      billingAddrLine1: "",
      billingAddrLine2: "",
      billingAddrName: "",
      billingAddrReference: "",
      billingAddrZipCode: "",
      coaddress: "",
      company: false,
      country: "",
      custId: 0,
      created: 0,
      createdBy: 0,
      defaultAhp: 0,
      deleted: false,
      email: "",
      firstName: "",
      firmName: "",
      forceCoverage: false,
      forceIncomingRing: false,
      forceIncomingRingAlways: false,
      forceTfa: false,
      informalContactName: "",
      integrationId: "",
      integrationRole: "",
      invoiceBalanceExclVat: 0,
      invoiceCreditLimitExclVat: 0,
      languageCode: "",
      lastChanged: 0,
      lastChangedBy: 0,
      lastName: "",
      latestInvoiceDate: 0,
      mobile: "",
      name: "",
      numActiveDevices: 0,
      orgNum: "",
      parent: 0,
      prgrId: 0,
      priceGroup: [],
      pptyId: "",
      privateUser: false,
      status: 0,
      streetAddress: "",
      billAddrDefined: false,
      billingCode: "",
      tempAhp: 0
    },
    paymentProfile: {
      pptyId: 0,
      paymentDetail: "",
      paymentProfileType: {
        name: ""
      }
    },
    numberOfDevices: 0,
  },
  paidByCustomer: {
    customerId: 0,
    policies: {
      custId: 0,
      global: false,
      id: 0,
      policies: {},
      roleName: "",
      technicianRights: false
    },
    firm: {
      activatedDate: 0,
      active: false,
      addrCity: "",
      addrCounId: 0,
      addressLine1: "",
      addressLine2: "",
      addrName: "",
      addrNameForBilling: "",
      addrReference: "",
      addrSamesame: false,
      addrZipCode: "",
      allowAllPhoneNo: false,
      billingAddrCity: "",
      billingAddrCounId: 0,
      billingAddrLine1: "",
      billingAddrLine2: "",
      billingAddrName: "",
      billingAddrReference: "",
      billingAddrZipCode: "",
      coaddress: "",
      company: false,
      country: "",
      custId: 0,
      created: 0,
      createdBy: 0,
      defaultAhp: 0,
      deleted: false,
      email: "",
      firstName: "",
      firmName: "",
      forceCoverage: false,
      forceIncomingRing: false,
      forceIncomingRingAlways: false,
      forceTfa: false,
      informalContactName: "",
      integrationId: "",
      integrationRole: "",
      invoiceBalanceExclVat: 0,
      invoiceCreditLimitExclVat: 0,
      languageCode: "",
      lastChanged: 0,
      lastChangedBy: 0,
      lastName: "",
      latestInvoiceDate: 0,
      mobile: "",
      name: "",
      numActiveDevices: 0,
      orgNum: "",
      parent: 0,
      prgrId: 0,
      priceGroup: [],
      pptyId: "",
      privateUser: false,
      status: 0,
      streetAddress: "",
      billAddrDefined: false,
      billingCode: "",
      tempAhp: 0
    },
    paymentProfile: {
      pptyId: 0,
      paymentDetail: "",
      paymentProfileType: {
        name: ""
      }
    },
    numberOfDevices: 0,
  },
  customersForList: [],
  customersForForm: {},
  createForm: false,
  postCity: {
    postalCodeType: "",
    result: "",
    valid: false
  },
  billingPostCity: {
    postalCodeType: "",
    result: "",
    valid: false
  },
  addrSameSame: false,
};

const customer: Reducer<CustomerState> = (state: CustomerState = customerState, action: any) => {
  switch (action.type) {
    case Routines.fetchCustomersForList.SUCCESS:
      return{
        ...state,
        customersForList: action.payload,
        createForm: false,
      }
    
    case Routines.fetchCustomersForForm.SUCCESS: 
      return{
        ...state,
        customersForForm: mapCustomers(action.payload),
        createForm: false
      }

    case Routines.selectCustomer.SUCCESS:
      const {customer, paidByCustomer, parentCustomer} = action.payload;
      return {
        ...state,
        paidByCustomer: paidByCustomer,
        parentCustomer: parentCustomer,
        selectedCustomer: customer,
        createForm: false,
      };

    case Routines.setPaidByCustomer.SUCCESS:
      return {
        ...state,
        paidByCustomer: action.payload,
      };

    case Routines.getPostCity.SUCCESS:
      return {
        ...state,
        postCity: action.payload
      };

    case Routines.getBillingPostCity.SUCCESS:
      return {
        ...state,
        billingPostCity: action.payload
      };

    case Routines.cancelSettings.TRIGGER:
      return{
        ...state,
        customersForForm: action.payload
      }
    
    case SubUnitsActionTypes.CREATE_CUSTOMER:
      return{
        ...state,
        createForm: action.payload
      }
    

    default:
        return state;
  }
  
};

function mapCustomers(customers: Organization[]){
  const mappedSubOrgs : MappedSubOrgs = {};
  customers.forEach((customer: Organization) => {
    mappedSubOrgs[customer.firm.custId] = customer;
  })
  return mappedSubOrgs;
}

export default customer;
