import {NotificationEventsState} from "../types";
import {Reducer} from "redux";
import * as Routines from "../routines";
import { MapActionTypes } from "../actionTypes";

const notificationEvents: Reducer<NotificationEventsState> = (state: NotificationEventsState = {}, action) => {
  switch (action.type) {
    case Routines.getNotificationEvents.SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload.events
      };

    case MapActionTypes.RESET_STATE:{
      return {}
    }

    default:
      return state;
  }
};

export default notificationEvents;
