import * as React from "react";
import {InnerFrame} from "./styled";
import CustomerList from "./customerList";
import {LabelEnum} from "../LabelEnum";
import { Footer } from "./footer";
import styled from "styled-components";
import {Col, Grid, Row} from "react-bootstrap";


import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";
import Tooltip from "@Elements/Tooltip";
import { useIntl } from "react-intl";

const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const StyledH2 = styled.h2`
  margin-bottom: 0px;
`;

export const Frame = styled.div`
  border-radius: 4px;
  padding: 25px;
  .customerList{
    justify-content: left;
    margin-bottom: 25px;
    div{
      justify-content: left;
    }
  }
  h2{
    margin-right: 5px;
  }
  ${props =>
  `
    background: ${props.theme.colors.backgroundPrimary};
    border: ${props.theme.colors.border};
  `
}
`;


const CustomerFrame = () => {

  const { formatMessage } = useIntl();

  return(
    <Frame>
      <Col className="customerList">
      <Tooltip
        popoverId="subUnits-popover"
        labelId={"subUnits"}
        defaultMessage="This is where you may define the organization by adding autonomous sub organizations (sub orgs). This enables tighter access- and financial control."
        className="testclass"
      >
        <Label><StyledH2>{formatMessage({id: LabelEnum.SUBUNITS, defaultMessage: "Underavdelinger"})}</StyledH2></Label>
        <InformationIcon size="medium" />
      </Tooltip>
      </Col>
      <InnerFrame>
        <CustomerList/>
      </InnerFrame>
      <Footer/>
    </Frame>
  )
}

export default CustomerFrame;
