import React from "react";
import { useIntl } from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import { B, DaysTh, Table } from "./TableElementStyles";
import { ModalEnum } from "./utils";
import { connect } from "react-redux";
import { setDeviceEventSetupModal } from "../Store/routines";
import withFormWrapper from "@Elements/Form/formWrapper";
import { EventSetupForm } from "./types";
import confirmModal from "@Safemate/Modals/confirmModal";
import { Checkbox } from "@Elements/CheckboxFormik";
import { AppState } from "@Safemate/Store/types";

interface DaySelectorProps{
  modalType: ModalEnum;
  modalEventSetupIndex: number;
  selectedEventSetup: DeviceEventSetup;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
}

interface DaySelectorState{
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  [index: string]: boolean;
}

const mapStateToProps = ({ eventSetup: {deviceEventSetup: { selectedEventSetup, modalType, modalEventSetupIndex }}}: AppState) => {
  return {
    selectedEventSetup,
    modalType,
    modalEventSetupIndex
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal
}

export const DaySelector = connect(mapStateToProps, mapDispatchToProps)(
  withFormWrapper<EventSetupForm, DaySelectorProps>(({ formik: { setFieldValue}, modalType, modalEventSetupIndex, selectedEventSetup, setDeviceEventSetupModal }) => {
    
    const { Comp: Modal, func: displayModal } = confirmModal();
    const { formatMessage } = useIntl();
    const [ internalDays, setInternalDays ] = React.useState<DaySelectorState>({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    });

    React.useEffect(() => {
      if(ModalEnum.DAYS === modalType){
        displayModal(true);
        setInternalDays({
          monday: selectedEventSetup.warnOnMon,
          tuesday: selectedEventSetup.warnOnTue,
          wednesday: selectedEventSetup.warnOnWed,
          thursday: selectedEventSetup.warnOnThu,
          friday: selectedEventSetup.warnOnFri,
          saturday: selectedEventSetup.warnOnSat,
          sunday: selectedEventSetup.warnOnSun
        })
      }
      else{
        displayModal(false);
      }
    }, [modalType, selectedEventSetup])

    const confirm = () => {
      setFieldValue(`eventSetups.${modalEventSetupIndex}`, {
        ...selectedEventSetup,
        warnOnMon: internalDays.monday,
        warnOnTue: internalDays.tuesday,
        warnOnWed: internalDays.wednesday,
        warnOnThu: internalDays.thursday,
        warnOnFri: internalDays.friday,
        warnOnSat: internalDays.saturday,
        warnOnSun: internalDays.sunday
      });
      closeModal();
    }

    const closeModal = () => {
      setDeviceEventSetupModal({
        selectedEventSetup: null,
        modalType: ModalEnum.NONE
      })
    }
    
    return(
      <Modal
      buttonAlign="center"
      standardBtn
      disableClose
      cancelFunc={closeModal}
      confirmFunc={confirm}
      confirmText={formatMessage({id: "ok", defaultMessage: "Ok"})}
      title={formatMessage({id: "days", defaultMessage: "Days"})}
      body={
        <Table>
          <thead>
            <tr>
              {Object.keys(internalDays).map(key => {
                return(
                  <DaysTh key={`header${key}`}>
                    {formatMessage({id: key, defaultMessage: key})}
                  </DaysTh>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.keys(internalDays).map(key => {
                return(
                  <td>
                    <Checkbox
                      value={internalDays[key]}
                      id={`${key}-checkbox`}
                      onChange={() => {
                        const newState = {...internalDays};
                        newState[key] = !internalDays[key];
                        setInternalDays(newState)
                      }}
                      outerDiv={{
                        width: "100%", textAlign: "center", display: "inline-block"
                      }}
                    />
                  </td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      }
    />
    )
  })
)

interface DaysProps{
  eventSetup: DeviceEventSetup;
}

export const Days = ({ eventSetup }: DaysProps) => {

  const { formatMessage } = useIntl();

  return (
    <div>
      <B selected={eventSetup.warnOnMon}>{formatMessage({ id: 'letterMonday', defaultMessage: 'M' })}</B>
      <B selected={eventSetup.warnOnTue}>{formatMessage({ id: 'letterTuesday', defaultMessage: 'T' })}</B>
      <B selected={eventSetup.warnOnWed}>{formatMessage({ id: 'letterWednesday', defaultMessage: 'O' })}</B>
      <B selected={eventSetup.warnOnThu}>{formatMessage({ id: 'letterThursday', defaultMessage: 'T' })}</B>
      <B selected={eventSetup.warnOnFri}>{formatMessage({ id: 'letterFriday', defaultMessage: 'F' })}</B>
      <B selected={eventSetup.warnOnSat}>{formatMessage({ id: 'letterSaturday', defaultMessage: 'L' })}</B>
      <B last selected={eventSetup.warnOnSun}>{formatMessage({ id: 'letterSunday', defaultMessage: 'S' })}</B>
    </div>
  );
};