import safemateDark from "./safemateDark";
import safemateLight from "./safemateLight";
import {ITheme} from "./types";

interface IThemeCollection{
  safemateDark: ITheme;
  safemateLight: ITheme;
  [index: string]: ITheme;
}

const themes: IThemeCollection = {
  safemateDark,
  safemateLight
};

export default themes;
