import { combineReducers } from "redux";
import { RootState } from "../types";
import feedback from "../../../Feedback/reducer";
import initialization from "./initialization";
import device from "./device";
import soundLight from "./soundLight";
import sensor from "./sensor";
import organisation from "./organisation";
import geofence from "./geofence";
import tracking from "./tracking";
import general from "./general";
import info from "./info";
import indoor from "./indoor";
import indoorLocation from "@Safemate/Settings/Store/Reducer/indoorLocation";
import websocket from "@Safemate/Settings/Store/Reducer/websocket";

export default combineReducers<RootState>({
    feedback,
    initialization,
    device,
    soundLight,
    sensor,
    organisation,
    geofence,
    tracking,
    general,
    info,
    indoor,
    indoorLocation,
    websocket,
});
