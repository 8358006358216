import { MessageDescriptor } from "react-intl";

import {Option} from "../../DefaultSettings/Settings/FieldWrapper";
import { AlarmFilterEnum } from '../AlarmFilterEnum';
import { OrderFilterEnum } from '../OrderFilterEnum';
import { DeviceHardwareEnum, isGx8 } from '../../DeviceHardwareEnum';
import { LabelEnum } from '../LabelEnum';

export type GetAlarmFilter = (
  deviceTypes: DeviceHardwareEnum[], 
  formatMessage: (message: MessageDescriptor) => string
) => Option[];

export type GetOrderFilter = (
  formatMessage: (message: MessageDescriptor) => string
) => Option[];

export type TranslateDeviceTypes = (
  deviceTypes: Option[], 
  formatMessage: (message: MessageDescriptor) => string
) => Option[];

export const getAlarmFilter: GetAlarmFilter = (deviceTypes, formatMessage) => {
  if(deviceTypes.length === 1 && isGx8(deviceTypes[0])){
    return[
      {text: formatMessage({id: LabelEnum.ALARM, defaultMessage: "Alarm"}), value: AlarmFilterEnum.ALARM},
      {text: formatMessage({id: LabelEnum.LOW_BATTERY, defaultMessage: "Lavt Batteri"}), value: AlarmFilterEnum.BATTERY},
      {text: formatMessage({id: LabelEnum.SELFCHECK, defaultMessage: "Selfcheck"}), value: AlarmFilterEnum.SELFCHECK},
      {text: formatMessage({id: LabelEnum.OUTDATEDPOSITION, defaultMessage: "Outdated Position"}), value: AlarmFilterEnum.OUTDATEDPOSITION},
      {text: formatMessage({id: LabelEnum.OFFLINE, defaultMessage: "Offline"}), value: AlarmFilterEnum.OFFLINE},
      {text: formatMessage({id: LabelEnum.UNKNOWN, defaultMessage: "Unknown"}), value: AlarmFilterEnum.UNKNOWN},
      {text: formatMessage({id: LabelEnum.OK, defaultMessage: "OK"}), value: AlarmFilterEnum.OK}
    ]
  }
  return[
    {text: formatMessage({id: LabelEnum.ALARM, defaultMessage: "Alarm"}), value: AlarmFilterEnum.ALARM},
    {text: formatMessage({id: LabelEnum.GEOFENCE, defaultMessage: "Geofence"}), value: AlarmFilterEnum.GEOFENCE},
    {text: formatMessage({id: LabelEnum.OUT_OF_BATTERY, defaultMessage: "Battery Empty"}), value: AlarmFilterEnum.OUT_OF_BATTERY},
    {text: formatMessage({id: LabelEnum.LOW_BATTERY, defaultMessage: "Lavt Batteri"}), value: AlarmFilterEnum.BATTERY},
    {text: formatMessage({id: LabelEnum.STATIC, defaultMessage: "Static"}), value: AlarmFilterEnum.STATIC},
    {text: formatMessage({id: LabelEnum.SELFCHECK, defaultMessage: "Selfcheck"}), value: AlarmFilterEnum.SELFCHECK},
    {text: formatMessage({id: LabelEnum.OUTDATEDPOSITION, defaultMessage: "Outdated Position"}), value: AlarmFilterEnum.OUTDATEDPOSITION},
    {text: formatMessage({id: LabelEnum.OFFLINE, defaultMessage: "Offline"}), value: AlarmFilterEnum.OFFLINE},
    {text: formatMessage({id: LabelEnum.UNKNOWN, defaultMessage: "Unknown"}), value: AlarmFilterEnum.UNKNOWN},
    {text: formatMessage({id: LabelEnum.OK, defaultMessage: "OK"}), value: AlarmFilterEnum.OK}
  ]
}

export const getOrderFilter: GetOrderFilter = (formatMessage) => {
  return[
    {text: capitalizeLabel(formatMessage({id: LabelEnum.DEVICE_LIST_SORT_PRO, defaultMessage: "Standardsortering"})), value: OrderFilterEnum.DEFAULT},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.LAST_CONTACT_ASC, defaultMessage: "Siste kontakt - Stigende"})), value: OrderFilterEnum.LAST_CONTACT_ASC},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.LAST_CONTACT_DESC, defaultMessage: "Siste kontakt - Synkende"})), value: OrderFilterEnum.LAST_CONTACT_DESC},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.NAME, defaultMessage: "Navn"})), value: OrderFilterEnum.NAME},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.NOTES_ADDED_DESC, defaultMessage: "Notat - Synkende"})), value: OrderFilterEnum.NOTES_ADDED_DESC},
    {text: capitalizeLabel(formatMessage({id: LabelEnum.PHONE_NUMBER, defaultMessage: "Telefonnummer"})), value: OrderFilterEnum.PHONE_NUMBER},
  ]
}

type CapitalizeLabel = (label: string) => string;

const capitalizeLabel: CapitalizeLabel = (label) => {
  return `${label.charAt(0).toUpperCase()}${label.substr(1, label.length - 1)}`;
}

export const translateDeviceTypes: TranslateDeviceTypes = (deviceTypes, formatMessage) => {
  return deviceTypes.map(option => {
    return {
      text: formatMessage({id: `deviceHardware${option.value}`, defaultMessage: option.text}),
      value: option.value
    }
  });
}
