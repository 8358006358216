import styled from "styled-components";
import { Col, Grid } from "react-bootstrap";

export const WarnDiv = styled.div`
  color: red;
  font-weight: bold;
`;

export const MainWrap = styled(Grid)<{default?: boolean}>`
  border: ${props => props.default ? "1px solid green" : props.theme.colors.border};
  padding: 10px;
  margin: 5px 0;
  position: relative;
  width: 100%;

  input {
    background: ${props => props.theme.colors.backgroundPrimary};
  }
`;

export const Td = styled.td`
  padding: 5px;
`;

export const Th = styled.th`
  padding: 5px;
`;

export const Day = styled.b<{selected: boolean, last?: boolean}>`
  color: ${props =>
    props.selected
      ? props.theme.colors.accent
      : props.theme.colors.textPrimary};
  padding-right: ${props => (props.last ? '0px' : '5px')};
`;

export const Title = styled.span`
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Section = styled.div`
    padding-botton: 10px;
`;

export const StyledCol = styled(Col)`
  margin: 5px 0px;
`;

export const EventCardWrapper = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Sequential = styled.div`
  align-items: center;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
`;

export const RadioWrapper = styled.div`
  padding: 5px 0px;
  display: flex;
`;
