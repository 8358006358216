import { Role } from "@Safemate/RoleEditor/Store/types";
import { tabs } from "@Safemate/RoleEditor/Tabs";
import { mapCustomTabs, NonStaticRender } from "@Safemate/RoleEditor/utils";

export interface Header{
  id: number;
  name: string;
  status: boolean;
  roleName: string;
  rotate?: boolean;
  align?: string;
}

export interface Content{
  [index: number]: TableElement[]
}

export interface TableElement{
  status: boolean;
  infoText: string;
  information: string;
}

export type MapData = (role: Role, headers: Header[], formatMessage: Function) => Content;

export const mapData: MapData = (role, headers, formatMessage) => {
  const { policies } = role;

  const content: Content = {};

  headers.map(header => {
    Object.entries(policies).forEach(entry => {
      const [ key, value ] = entry;
      const { policyDetails, rolePolicies } = value;
      
      if(policyDetails.parent === header.id){

        const element = {
          status: rolePolicies.status,
          infoText: formatMessage({
            id: policyDetails.infoText,
            defaultMessage: policyDetails.infoText
          }),
          information: rolePolicies.information
        }

        if(!content[header.id]){
          content[header.id] = [element];
        } else{
          content[header.id].push(element);
        }
      }
    })
  });
  return content;
}


export const mapHeaders = (role: Role, isPartner: boolean, formatMessage: Function): Header[] => {

  const header = tabs.map(tab => tab.name).concat(Object.values(mapCustomTabs(role.policies, isPartner)).map((item: NonStaticRender) => item.key)).filter(name => name !== "roletitle");

  const { policies } = role;

  return header.map(header => {
    if(header === "partner" && !isPartner) return null;
    const policy = policies[header];
    return(
      {
        id: policy.policyDetails.id,
        name: formatMessage({id: `${header}RoleEditor`, defaultMessage: header}),
        status: policy.rolePolicies.status,
        roleName: role.global ? `${role.roleName}ROLE` : role.roleName,
        rotate: true,
        align: "center"
      }
    )
  }).filter(item => item);
}