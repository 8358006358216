import * as React from "react";
import User from "../../../../Elements/Icon/Icons/Safemate/solid/User";
import { StyledButton, ModalContent } from './index';
import { StyledModal, ButtonWrap } from '../TableElements';
import { CancelButton } from '../../../DefaultSettings/Settings/styled';
import { LabelEnum } from '../../LabelEnum';
import { useIntl } from "react-intl";

interface PhotoProps{
  deviceName: string;
  deviceSNNum: string;
  deviceId: number;
}

const Photo = ({deviceName, deviceSNNum, deviceId}: PhotoProps) => {

  const { formatMessage } = useIntl();

  const [open, setOpen] = React.useState(false);

  const fallbackImg = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const src = event.currentTarget.src;
    if (src != 'images/menu/person.svg')
      event.currentTarget.src = 'images/menu/person.svg'
  }

  return(
    <React.Fragment>
      <StyledButton
        title={formatMessage({id: LabelEnum.SHOW_USER_IMAGE, defaultMessage: "Vis brukerbilde"})}
        onClick={(event) => {
          setOpen(true);
        }}
        onKeyDown={(event) => {
          setOpen(true);
        }}
      >
        <User
          size="medium"
          inline
        />
      </StyledButton>
      <StyledModal
        open={open}
        setOpen={setOpen}
      >
        <div>
          <div>
            <h2>{`${deviceName} (${deviceSNNum})`}</h2>
          </div>
          <div>
            <ModalContent>
              <img 
                onError={fallbackImg}
                style={{maxWidth: "500px", maxHeight: "500px"}} 
                src={`/ajax/file/userimage/${deviceId}/`}
              />
            </ModalContent>
          </div>
          <ButtonWrap>
            <CancelButton onClick={() => setOpen(false)}>{formatMessage({id: LabelEnum.OK, defaultMessage: "OK"})}</CancelButton>
          </ButtonWrap>
        </div>
      </StyledModal>
    </React.Fragment>
  )
}

export default Photo;
