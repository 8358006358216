import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import settingsSaga from "./settings";
import customerSaga from "./customer";
import deviceHardwareSaga from "./DeviceHardware";
import firmware from "./firmware";
import integration from "./integration";

function* defaultSettingsSaga(){
  yield all([
    fork(initializationSaga),
    fork(settingsSaga),
    fork(customerSaga),
    fork(deviceHardwareSaga),
    fork(firmware),
    fork(integration)
  ])
}
export default defaultSettingsSaga;
