import React from 'react';
import SIcon from './SIcon';
import { FormattedMessage } from 'react-intl';

const PullChord = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M10 4.8V0h1v4.8h.4c.1 0 .2.1.2.2l.4 3.1c0 .2-.1.4-.3.4H9.3c-.2 0-.3-.2-.3-.4l.4-3.2c0-.1.1-.2.2-.2h.4zm.9 7l1.3 1.8c.5.7.8 1.5.8 2.4v3.7c0 .1-.1.2-.2.2h-.5c-.1 0-.2-.1-.2-.2V16c0-.6-.2-1.3-.6-1.8l-1.3-1.8c-.2-.2-.5-.3-.7-.1-.2.2-.3.5-.1.7l1 1.3C11 15.5 9.2 17 8 15.6l-2-2.4V8.5c0-.7-1-.7-1 0v5.3c0 .1 0 .2.1.3l3.3 4.2c.3.4.5.9.5 1.4.1.2 0 .3-.2.3h-.5c-.1 0-.2-.1-.2-.2 0-.3-.1-.6-.3-.8l-3.3-4.2c-.3-.3-.4-.7-.4-1V8.5C4 7.7 4.7 7 5.5 7S7 7.7 7 8.5v4.3l1.9 2.3c.2.3.6-.1.4-.3l-.9-1.2c-.4-.6-.3-1.4.1-2 .8-.8 1.9-.4 2.4.2z" />
    <title>
      <FormattedMessage id="pullCordDevice" defaultMessage="Dra-snor enhet" />
    </title>
  </svg>
);

const Icon = props => <SIcon {...props} icon={PullChord} />;

export default Icon;
