import FieldWrapper, { GenericDropdown} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {LabelEnum} from "../../LabelEnum";
import {
  deviceTransactionLogs,
  userTransactionLogs,
  devicePositionHistoryDepthHours,
  devicePositionHistoryDepthPosPlots,
  userInactivityLimitOptions,
  nokEmployeeLoginIdleHour
} from "./options";
import { time } from "@Elements/Form/utils";
// Dropdowns
export const DeviceTransactionLogs = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: time.days,
      unitsMargin: '250px'
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.PRIVACY,
  field: SettingEnum.DEVICE_TRANSACTION_LOGS,
  title: LabelEnum.DEVICE_TRANSACTION_LOGS_LABEL,
  dataOptions: {
    options: deviceTransactionLogs,
    skipMap: true
  },
  tooltip: LabelEnum.DEVICE_TRANSACTION_LOGS_TOOLTIP

});

export const UserTransactionLogs = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: time.days,
      unitsMargin: '250px'
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.PRIVACY,
  field: SettingEnum.USER_TRANSACTION_LOGS,
  title: LabelEnum.USER_TRANSACTION_LOGS_LABEL,
  dataOptions: {
    options: userTransactionLogs,
    skipMap: true
  },
  tooltip: LabelEnum.USER_TRANSACTION_LOGS_TOOLTIP

});


export const DevicePositionHistoryDepthHours = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: time.hour,
      unitsMargin: '250px'
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.PRIVACY,
  field: SettingEnum.DEVICE_POSITION_HISTORY_DEPTH_HOURS,
  title: LabelEnum.DEVICE_POSITION_HISTORY_DEPTH_HOURS_LABEL,
  dataOptions: {
    options: devicePositionHistoryDepthHours,
    skipMap: true
  },
  tooltip: LabelEnum.DEVICE_POSITION_HISTORY_DEPTH_HOURS_TOOLTIP
});

export const DevicePositionHistoryDepthPosPlots = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: { id: 'dropdownPositions', defaultMessage: 'positions' },
      unitsMargin: '250px'
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.PRIVACY,
  field: SettingEnum.DEVICE_POSITION_HISTORY_DEPTH_POS_PLOTS,
  title: LabelEnum.DEVICE_POSITION_HISTORY_DEPTH_POS_PLOTS_LABEL,
  dataOptions: {
    options: devicePositionHistoryDepthPosPlots,
    skipMap: true
  },
  tooltip: LabelEnum.DEVICE_POSITION_HISTORY_DEPTH_POS_PLOTS_TOOLTIP
});

export const NokEmployeeLoginIdleHour = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {
      units: time.hour,
      unitsMargin: '250px'
    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.PRIVACY,
  field: SettingEnum.NOK_EMPLOYEE_LOGIN_IDLE_HOUR,
  title: LabelEnum.NOK_EMPLOYEE_LOGIN_IDLE_HOUR_LABEL,
  dataOptions: {
    options: nokEmployeeLoginIdleHour,
    skipMap: true,
    translate: true
  },
  tooltip: LabelEnum.NOK_EMPLOYEE_LOGIN_IDLE_HOUR_TOOLTIP
});

export const UserInactivityLimit = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {

    }
  },
  prefix: SettingPrefixEnum.OTHER_SETTING,
  policyPrefix: SettingPrefixEnum.PRIVACY,
  field: SettingEnum.USER_INACTIVITY_LIMIT,
  title: LabelEnum.USER_INACTIVITY_LIMIT,
  dataOptions: {
    options: userInactivityLimitOptions,
    skipMap: true,
    translate: true
  },
  tooltip: LabelEnum.USER_INACTIVITY_LIMIT_TOOLTIP
});
