import {InitializationState} from "../types";
import {Reducer} from "redux";
import {getDevice, getUser, getUserAuditLog, initialize } from "../routines";
import { setFilter } from "../actions";
import { LogActionTypes } from "../actionTypes";

const initializationState: InitializationState = {
  initialized: false,
  user: null,
  device: null,
  filter: []
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action) => {
  switch (action.type) {
    case initialize.SUCCESS:
      return {
        ...state,
        initialized: true,
      };
    case getUserAuditLog.SUCCESS:
      return {
        ...state,
        userAuditLog: action.payload,
      };

    case getUser.SUCCESS:
      return {
        ...state,
        user: action.payload
      }

    case getDevice.SUCCESS:
      return {
        ...state,
        device: action.payload
      }

    case LogActionTypes.SET_FILTER:
      return{
        ...state,
        filter: action.payload
      }

    case LogActionTypes.RESET_STATE:
      return {
        ...state,
        ...initializationState
      }

    default:
      return state;
  }
};

export default initialization;
