import React from 'react';
import SIcon from './SIcon';
import { FormattedMessage } from 'react-intl';

const SmokeAlarm = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M19.5 2.5H.5c-.3 0-.5.2-.5.5v1.5C0 5.9 1.1 7 2.5 7h15C18.9 7 20 5.9 20 4.5V3c0-.3-.2-.5-.5-.5zM4.4 7v.3c0 1 .7 1.7 1.5 1.7h8.6c.8 0 1.4-.7 1.4-1.7V7H4.4zM19 4.5c0 .8-.7 1.5-1.5 1.5h-15C1.7 6 1 5.3 1 4.5v-1h6.1c.2.6.8 1 1.4 1h3c.7 0 1.2-.4 1.4-1H19v1zm-2-2h-4.8c-.1 0-.2.1-.2.2-.1.4-.5.8-1 .8H9c-.5 0-.9-.3-1-.8 0-.1-.1-.2-.2-.2H3h14zM14.8 7v.2c0 .4-.2.8-.7.8H6.3c-.5 0-.8-.2-.8-.8V7h9.3zm-4.4 5.9c-.2-.2-.4-.4-.4-.7v-2c0-.1-.1-.2-.2-.2h-.6c-.1 0-.2.1-.2.2v1.9c0 .6.3 1.2.8 1.6 1.4.9 2.2 2.4 2.2 4v.9c0 .1.1.2.2.2h.5c.1 0 .2-.1.2-.2v-.9c.1-1.9-.9-3.7-2.5-4.8zm3 .2c-.1-.1-.2-.1-.3.1-.2.2-.3.3-.4.5 0 0-.1.1.1.2 0 0 .4.4.5.7.3.4.8.9.8 3.3v.9c0 .1.1.2.2.2h.5c.1 0 .2-.1.2-.2v-.9c0-2.1-.3-3.2-1.6-4.8zM10 17.8v.9c0 .1.1.2.2.2h.5c.1 0 .2-.1.2-.2v-.9c0-1.3-.7-2.5-1.8-3.2C8.4 14 8 13.1 8 12.2v-.1c0-.1-.1-.2-.2-.2h-.6c-.1-.1-.2.1-.2.2v.3c0 1.3.7 2.4 1.7 3.1.8.5 1.3 1.3 1.3 2.3z" />
    <title>
      <FormattedMessage id="smokeDetector" defaultMessage="Røyksensor" />
    </title>
  </svg>
);

const Icon = props => <SIcon {...props} icon={SmokeAlarm} />;

export default Icon;
