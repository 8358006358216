import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  width: 30px;
  height: 30px;
`;

const Add = (props: any) => <Icon {...props} icon={faPlusCircle} />;

export default Add;
