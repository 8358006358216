import styled from "styled-components";

export const SettingDiv = styled.div<{padding?: boolean, flex?: boolean, contents?: boolean}>`
  input {
    background: ${props => props.theme.colors.backgroundPrimary};
  }

  align-items: center;
  
  ${props => props.flex && "display: flex;"}
  ${props => props.padding && "padding: 15px;"}
  ${props => props.contents && "label{display: contents;}"}
`;

export const SettingHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-bottom: 5px;

  div {
    width: auto;
  }
`

export const TitleSpan = styled.span`
  path {
    fill: ${props => props.theme.colors.textPrimary};
  }

  display: flex;
  align-items: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const TitleWrap = styled.p<{right?: boolean}>`
  ${props => props.right ? "margin-right" : "margin-left"}: 5px;
  display: block;
  justify-content: center;

  div {
    display: inline-block;
  }

  &::first-letter {
    
    text-transform: uppercase;
  }
`;