import createTabsComponent from '../createTabsComponent';
import createRouteTabsComponent from '../createRouteTabsComponent';

import StyledContainer from './Container';
import StyledContent from './ContentContainer';
import StyledTabContainer from './TabContainer';
import StyledTab from './Tab';

export const HorizontalTabs = createTabsComponent({
  StyledContainer,
  StyledTabContainer,
  StyledTab,
  StyledContent
});

export const HorizontalRouteTabs = createRouteTabsComponent({
  StyledContainer,
  StyledTabContainer,
  StyledTab,
  StyledContent
});

export default HorizontalTabs;
