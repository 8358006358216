import * as React from "react";
import { Option, ConfirmModal } from "./types";
import { 
  FullWidthStyledInput,
  SelectWrapper,
  Select, 
  TitleContainer, 
  Triangle, 
  SelectBox, 
  StyledLi, 
  ListElement,
  Label
} from './styles';
import { useComponentVisible, EventType } from './utils';
import {Checkbox} from "../../Elements/CheckboxFormik";
import { isNullOrUndefined } from "util";
import Trash from "@Icons/Trash";

export interface DropdownProps{
  options: Array<Option>;
  onChange: (option: Option | Option[]) => void;
  onDelete?:(option: Option) => void;
  deleteEnabled?: boolean;
  initial?: any;
  disabled?: boolean;
  title?: string;
  searchable?: boolean;
  multi?: boolean;
  borderRadius?: string;
  size?: {width?: string; height?: string;}
  units?: string;
  expand?: boolean;
  enableReset?: boolean;
  sort?: boolean;
  wrapperStyle?: {
    [index: string]: string;
  }
}

export default (
  {
    options: initialOptions, 
    initial, 
    disabled, 
    title: initialTitle, 
    searchable, 
    multi, 
    onChange,
    onDelete,
    deleteEnabled,
    size, 
    borderRadius,
    units,
    expand,
    enableReset,
    sort,
    wrapperStyle
}: DropdownProps) => {

  const { selectRef, ref, isComponentVisible, setIsComponentVisible } = useComponentVisible<HTMLUListElement, HTMLDivElement>(false);
  const searchRef = React.useRef<HTMLInputElement>(null);
  
  const [options, setOptions] = React.useState(initialOptions);
  const [selected, setSelected] = React.useState<Option | Option[]>();
  const [title, setTitle] = React.useState(initialTitle);

  let timeoutId: NodeJS.Timeout;
  
  const handleSelect = (option: Option, toggle: boolean) => {
    if(!multi){
      setSelected(option);
      onChange(option);
      setIsComponentVisible(false);
    }
    else{
      let updatedSelected: Option[] = [];
      if(toggle){
        updatedSelected = [...selected, option];
      }
      else{
        if(selected)
          updatedSelected = [...(selected as Option[]).filter((item: Option) => item.value !== option.value)]
      }
      setSelected(updatedSelected);
      onChange(updatedSelected);
    }
  }

  const handleSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setOptions(initialOptions.filter(option => option.text.toLowerCase().includes(value.toLowerCase()) || value === ""));
  }

  const toggle = (event: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLDivElement>) => {
    if(disabled) return;

    if(event.type === EventType.KEYBOARD){
      const keyboardEvent = event as React.KeyboardEvent<HTMLDivElement>;
      if(keyboardEvent.key === "Escape")
        setIsComponentVisible(false);
      
      if(keyboardEvent.key === "Enter"){
        setIsComponentVisible(!isComponentVisible);
        if(!isComponentVisible)
          setMotionDisplay(true);
      }
    }
    else{
      setIsComponentVisible(!isComponentVisible);
      if(!isComponentVisible)
        setMotionDisplay(true);
    }
  }

  React.useEffect(() => {
    if(isComponentVisible && searchRef.current){
      setOptions(initialOptions);
      searchRef.current.focus();
    }
  }, [isComponentVisible])

  React.useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions])

  React.useEffect(() => {
    if(!multi){
      const item = initialOptions.find(option => option.value === initial);
      setSelected(item);
    }
    else{
      setSelected(initial ? initialOptions.filter(option => initial.includes(option.value)) : []);
    }
  }, [initial, initialOptions])

  React.useEffect(() => {
    if(!selected){
      setTitle(initialTitle)
      return;
    }
    if(!multi)
      setTitle((selected as Option).text);
    else{
      const names = (selected as Option[]).map((option: Option) => option.text);
      if(names.length > 2){
        setTitle(`${names.length} valgte`);
      }
      else if(names.length > 0){
        setTitle(names.join(", "));
      }
      else{
        setTitle(initialTitle);
      }
    }
  }, [selected])

  const handleBlur = () => {
    if(disabled) return;
    timeoutId = setTimeout(() => {
      setIsComponentVisible(false);
    });
  }

  const handleFocus = () => {
    if(disabled) return;
    clearTimeout(timeoutId);
  }

  const reset = () => {
    setIsComponentVisible(false);
    setSelected(multi ? [] : null);
    onChange(multi ? [] : null);
  }

  const [motionDisplay, setMotionDisplay] = React.useState(isComponentVisible);

  const selectedFlag = selected &&  (selected as Option[]).length > 0;

  const opts = sort ? options.sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }) : options;

  return(
    <SelectWrapper
      disabled={!!disabled}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => event.key === "Escape" && setIsComponentVisible(false)}
    >
      <Select
        disabled={!!disabled}
        ref={selectRef}
        active={isComponentVisible}
        onKeyDown={toggle}
        searching={isComponentVisible && searchable}
        tabIndex={0}
        size={size}
        borderRadius={borderRadius}
        style={wrapperStyle}
        selected={selectedFlag}
      >
        {isComponentVisible && searchable 
          ? <FullWidthStyledInput
              ref={searchRef}
              onChange={handleSearch}
              placeholder="Søk..."
              customSize={size}
            />
          : <React.Fragment>
              <TitleContainer onClick={toggle} style={{width: "100%"}}>{title}</TitleContainer>
              {enableReset && <TitleContainer onClick={reset} style={{paddingRight: "2.5px", paddingLeft: "2.5px"}} right> &#10006; </TitleContainer>}
              <TitleContainer onClick={toggle} style={{paddingRight: "2.5px", paddingLeft: "2.5px"}}>
                <Triangle
                  initial={isComponentVisible}
                  animate={{
                    rotate: isComponentVisible ? 180 : 0,
                    opacity: isComponentVisible ? 1 : 0.5,
                    scale: isComponentVisible ? 1 : 0.5
                  }}
                  selected={selectedFlag}
                />
              </TitleContainer>
            </React.Fragment>
        }
      </Select>
      <SelectBox
        expand={expand}
        initial={isComponentVisible}
        animate={{
          maxHeight: isComponentVisible ? 250 : 0,
          width: isComponentVisible ? 1 : 0,
          opacity: isComponentVisible? 1 : 0
        }}
        ref={ref}
        size={size}
      >
        {opts.map((option, index) =>
          <ListItem
            open={isComponentVisible}
            option={option}
            index={index}
            multi={multi}
            selectOption={handleSelect}
            selectedOptions={selected as Option[]}
            onDelete={onDelete}
            deleteEnabled={deleteEnabled}
            key={index}
          />
        )}
      </SelectBox>
      {units && (
        <span>
          {units}
        </span>
      )}
    </SelectWrapper>  
  )
}

interface ListItemProps{
  option: Option;
  index: number;
  open: boolean;
  selectOption: (option: Option, selected: boolean) => void;
  multi?: boolean;
  onDelete?: (option: Option) => void;
  deleteEnabled?: boolean;
  selectedOptions?: Option[];
}

const ListItem = ({option, open, index, multi, selectOption, selectedOptions, onDelete, deleteEnabled}: ListItemProps) => {

  const [tab, setTab] = React.useState<number | undefined>(0);

  React.useEffect(() => {
    setTab(open ? 0 : undefined);
  }, [open]);

  let selected = false;
  if(multi && selectedOptions) {
    const selectedValues = selectedOptions.map(option => option.value);
    selected = !isNullOrUndefined(selectedValues.find(value => value === option.value));
  }

  return(
    <StyledLi key={`list-option-${index}`}>
      <ListElement
        tabIndex={tab}
        onClick={() => selectOption(option, !selected)}
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => event.key === "Enter" && selectOption(option, !selected)}
      >
        {multi
          ? <Checkbox
              name={option.text}
              value={selected}
              label={option.text}
              readOnly
            />
          : <Label >{option.text} {option.suffix}</Label>}
          {deleteEnabled &&
            <React.Fragment>
            <Trash
              tabIndex="0"
              size="medium"
              onClick={() => (onDelete(option)) }
              inline
              style={{flex: 1,
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'right',}}
              /> </React.Fragment>}
      </ListElement>
    </StyledLi>
  )
}
