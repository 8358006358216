import React from 'react';
import styled from 'styled-components';
import { gsm100, supportGsmStrength } from '@Safemate/Settings/tabDefinition';

const x = 300;
const y = 100;
const adjX = 1;
const adjY = 1;

const Wrap = styled.div<{size: number}>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  margin: 0;
  padding: 0;
`;

const SVG = styled.svg`
  width: 100%;
  height: 100%;
`;

const Polygon = styled.polygon`
  fill: green;
`;

interface SignalBarsProps{
  dehaId: number;
  strength: number;
  size: number;
}

const SignalBars = ({dehaId, strength, size}: SignalBarsProps) => {

  let gsmStrength = supportGsmStrength.get(dehaId);

  if(!gsmStrength){
    gsmStrength = gsm100;
  }

  const offset = gsmStrength.low === 0 ? 0 : -(gsmStrength.low);
  const scale =  100 / (gsmStrength.high + offset);
  strength = (strength + offset) * scale;

  if(strength < gsmStrength.low){
    return(<React.Fragment></React.Fragment>);
  }

  const darkGrey = '#333';

  return (
    <Wrap size={size}>
      <SVG viewBox={`0 0 ${x * adjX} ${y * adjY}`}>
        <Polygon
          points="0,80 40,80 40,140 0,140"
          style={{ fill: strength > 0 ? 'green' : darkGrey }}
        />
        <Polygon
          points="55,50 95,50 95,140 55,140"
          style={{ fill: strength > 20 ? 'green' : darkGrey }}
        />
        <Polygon
          points="110,15 150,15 150,140 110,140"
          style={{ fill: strength > 40 ? 'green' : darkGrey }}
        />
        <Polygon
          points="165,-20 205,-20 205,140 165,140"
          style={{ fill: strength > 60 ? 'green' : darkGrey }}
        />
        <Polygon
          points="220,-60 260,-60 260,140 220,140"
          style={{ fill: strength > 80 ? 'green' : darkGrey }}
        />
      </SVG>
    </Wrap>
  );
};

export default SignalBars;
