import {call, put, takeLatest, select, take, race, fork} from 'redux-saga/effects';

import {AppAction} from "../../../DefaultSettings/Store/types";
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import * as Routines from '../routines';
import * as Api from '../api';
import {  DeviceInformation} from "../types";



export interface SettingsProps{
    deviceInfo: Array<DeviceInformation>;
    connectedAHPCheck: Array<boolean>;
    connectedDevicesCheck: Array<boolean>;
}


function* setBillingSettings(action: AppAction<SettingsProps>){
  try{
    const { deviceInfo, connectedAHPCheck, connectedDevicesCheck } = action.payload;
    let i = 0;
    for (var item of deviceInfo) {
        if(item.ahpId != null && item.ahpId > 0){
            //if it was initially connected to ahp
            if(!connectedAHPCheck[i]){
                //if ahp got removed
                yield call(ahpSaga, item.deviceId, false);
            }
        }else{
            //wasn't initially connected to ahp
            if(connectedAHPCheck[i]){
                //conect to ahp
                yield call(ahpSaga, item.deviceId, true);
            }
        }
        if(item.status == 1){
          if(!connectedDevicesCheck[i]){
            yield call(deleteSafemate, item.deviceId);
          }
        }
        i++;
    }
    const billingSettings = yield call(Api.getBillingSettings);
    yield put(Routines.getBillingSettings.success(billingSettings));
    yield feedbackSuccess('updatedBillingSettings');
  }
  catch(e){
    yield feedbackFailure(e)
  }
}


export type DeleteSafemate = {
    deviceId: number;
    feedback: string;
  }
  
  function* deleteSafemate(deviceId:number){
    try {
    
      yield call(Api.deleteSafemate, deviceId);
      yield put(Routines.deleteSafemate.success());
      yield reroute();
    }
    catch(e){
      yield feedbackFailure(e);
      yield put(Routines.deleteSafemate.failure());
    }
  }

  export type DeleteIndividualSafemate = {
    deviceId: number;
  }

  function* deleteIndividualSafemate(action: AppAction<DeleteIndividualSafemate>){
    try {
      const { deviceId } = action.payload;
      yield call(Api.deleteSafemate, deviceId);
      yield put(Routines.deleteSafemate.success());
    }
    catch(e){
      yield put(Routines.deleteSafemate.failure());
    }
  }
  
function* ahpSaga(deviId:number, connect: boolean){
    if(connect){
        yield fork(Api.connectToOwnersAhpPrivate, deviId);
    }else{
        yield fork(Api.disconnectFromOwnersAhpPrivate, deviId);

    }
}


function* invoiceSaga(){
  yield takeLatest(Routines.setBillingSettings.TRIGGER, setBillingSettings);
  yield takeLatest(Routines.deleteSafemate.TRIGGER, deleteIndividualSafemate);
}

function* reroute(){
  location.hash = "safemates";
}

export default invoiceSaga;
