export const satellites = [
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"},
  {value: 7, text: "7"},
  {value: 8, text: "8"},
  {value: 9, text: "9"},
];

export const outdatedPositionThreshold = [
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"},
  {value: 7, text: "7"},
  {value: 14, text: "14"},
  {value: 21, text: "21"},
  {value: 28, text: "28"}
];
