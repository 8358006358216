import * as React from "react";
import { Overlay } from 'react-bootstrap';
import { useIntl } from "react-intl";
import styled from "styled-components";
import InfoPopover from "./Popover";


const CenterIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

interface TooltipProps {
  popoverId: string;
  labelId: string;
  defaultMessage: string;
  postfix?: string | React.ReactNode;
  overlay?: any;
  style?: any;
  title?: string;
  customText?: React.ReactNode;
}

const Tooltip = ({
  popoverId,
  labelId,
  defaultMessage,
  postfix,
  overlay,
  style,
  title,
  customText,
  children
}: React.PropsWithChildren<TooltipProps>) => {

  const [show, setShow] = React.useState(false);
  const [target, setTarget] = React.useState();

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleBlur = (event: any) => {
    setShow(false);
  };

  const intl = useIntl();

  return (
    <React.Fragment>
      <Overlay onHide={() => {}} show={show} target={target} placement="top" rootClose>
        {overlay || (
          <InfoPopover 
            title={title ? `${title.charAt(0).toUpperCase()}${title.slice(1)}` : ""}
            id={popoverId}>
            {customText ? customText : <p>{`${intl.formatMessage({ id: labelId, defaultMessage })} ${postfix ? postfix : ""}`}</p>}
          </InfoPopover>
        )}
      </Overlay>
      <CenterIconWrap
        style={style}
        tabIndex={0}
        onClick={handleClick}
        onBlur={handleBlur}
        onKeyDown={event =>
          (event.key === 'Enter' ||
            event.key === ' ' ||
            event.key === 'Spacebar') &&
          handleClick(event)
        }
      >
        {children}
      </CenterIconWrap>
    </React.Fragment>
  );
};

export default Tooltip;