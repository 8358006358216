import { MessageDescriptor } from "@formatjs/intl";
import { DeviceType } from "./SensorTypeEnum";
import { ISensorFunction, ISensorType } from "./types";

export interface DeviceFunctionEnum{
  code: string;
  priority: number;
}

export const FRIDGE_FUNCTION: DeviceFunctionEnum = {
  code: '31',
  priority: 1
};

export const WANDERER_FUNCTION: DeviceFunctionEnum = {
  code: '32',
  priority: 2
};

export const NOT_BACK_IN_BED_FUNCTION: DeviceFunctionEnum = {
  code: '33',
  priority: 3
};

export const NOT_STOOD_UP_FUNCTION: DeviceFunctionEnum = {
  code: '34',
  priority: 4
};

export const EPILEPSY_FUNCTION: DeviceFunctionEnum = {
  code: '35',
  priority: 6
};

export const GENERIC_SENSOR_FUNCTION: DeviceFunctionEnum = {
  code: 'generic_sensor_function',
  priority: 7
};

export const MEDICAL_ALARM: DeviceFunctionEnum = {
  code: 'medical_alarm',
  priority: 8
};

export const CUSTOM_SENSOR_FUNCTION: DeviceFunctionEnum = {
  code: 'custom_sensor_function',
  priority: 9
}
export const OUT_OF_BED: DeviceFunctionEnum = {
  code: "36",
  priority: 5
}
export const OUT_OF_BEDROOM: DeviceFunctionEnum = {
  code: "39",
  priority: 5
}

export const DEFAULT_NAME = 'standardAlarm';

export const UNKNOWN = '-1';

export type GetFunctionPriority = (deviceFunction: ISensorFunction) => number;

export const getFunctionPriority: GetFunctionPriority = (deviceFunction) => {
  if (!deviceFunction) {
    return -1;
  }
  const code = deviceFunction.code || deviceFunction;
  switch (code) {
    case FRIDGE_FUNCTION.code:
      return FRIDGE_FUNCTION.priority;
    case WANDERER_FUNCTION.code:
      return WANDERER_FUNCTION.priority;
    case NOT_BACK_IN_BED_FUNCTION.code:
      return NOT_BACK_IN_BED_FUNCTION.priority;
    case NOT_STOOD_UP_FUNCTION.code:
      return NOT_STOOD_UP_FUNCTION.priority;
    case EPILEPSY_FUNCTION.code:
      return EPILEPSY_FUNCTION.priority;
    case OUT_OF_BED.code:
      return OUT_OF_BED.priority;
    case OUT_OF_BEDROOM.code:
      return OUT_OF_BEDROOM.priority;
    default:
      return 0;
  }
};

export type GetFunctionName = (type: ISensorType, deviceFunction: ISensorFunction, translate: (msg: MessageDescriptor) => string) => string | null;

export const getFunctionName: GetFunctionName = (type, deviceFunction, translate) => {

  if(type.code === DeviceType.PIR_SENSOR && deviceFunction.code === GENERIC_SENSOR_FUNCTION.code){
    return translate
      ? translate({
          id: `default`,
          defaultMessage: "default"
        }).charAt(0).toLocaleUpperCase() + translate({
          id: `default`,
          defaultMessage: "default"
        }).slice(1)
      : "Default"
  }

  if (!deviceFunction)
    return translate
      ? translate({
          id: `sensorFunction${DEFAULT_NAME}`,
          defaultMessage: DEFAULT_NAME
        })
      : DEFAULT_NAME;

  const code = deviceFunction.code;
  switch (code) {
    case FRIDGE_FUNCTION.code:
    case WANDERER_FUNCTION.code:
    case NOT_BACK_IN_BED_FUNCTION.code:
    case NOT_STOOD_UP_FUNCTION.code:
    case EPILEPSY_FUNCTION.code:
    case OUT_OF_BED.code:
    case OUT_OF_BEDROOM.code:
      return translate
        ? translate({
            id: `sensorFunction${code}`,
            defaultMessage: code
          })
        : code;
    case UNKNOWN:
      return null;
    default:
      return translate
        ? translate({
            id: code,
            defaultMessage: code
          })
        : code;
  }
};