import {call, put, takeLatest} from "redux-saga/effects";

import * as Api from '../api';
import * as Routines from '../routines';
import {LabelEnum} from "../../labelEnum";
import {feedbackFailure, feedbackSuccess} from "../../../Feedback/reducer";
import { BillingSettings } from "@Safemate/Billing/Form/types";
import { AppAction } from "@Safemate/Store/types";

function* saveForm(action: AppAction<BillingSettings>){
  try{
    yield call(Api.saveForm, action.payload);
    yield put(Routines.initialize.trigger());
    yield put(Routines.savePrivateBilling.success(action.payload));
    yield feedbackSuccess(LabelEnum.BILLING_SAVED);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.savePrivateBilling.failure());
  } finally{

  }
}

function* settingsSaga(){
  yield takeLatest(Routines.savePrivateBilling.TRIGGER, saveForm);

}

export default settingsSaga;
