import { Field, useFormikContext } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { setRegisterStep, SetRegisterStep } from "../Store/actions";
import { connect } from "react-redux";
import { RegisterUserForm } from "./types";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { userValidation, registerUserPrivate } from "../Store/routines";
import { RegisterStep } from ".";

interface RegisterPrivateProps{
  setRegisterStep: SetRegisterStep;
  userValidation: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToProps = {
  setRegisterStep,
  userValidation
}

export const RegisterPrivate = connect(null, mapDispatchToProps)(({setRegisterStep, userValidation}: RegisterPrivateProps) => {

  const { formatMessage } = useIntl();

  const {values} = useFormikContext<RegisterUserForm>()

  return(
    <React.Fragment>
      <h4>{formatMessage({id: "nyPrivatkunde", defaultMessage: "Register ny bruker"})}</h4>
      <Field type="text" name="firstName" placeholder={formatMessage({id: "firstNameGo", defaultMessage: "firstNameGo"})}/>
      <Field type="text" name="lastName" placeholder={formatMessage({id: "lastNameGo", defaultMessage: "lastNameGo"})}/>
      <Field type="text" name="mobile" placeholder={formatMessage({id: "mobileGo", defaultMessage: "mobileGo"})}/>
      <hr className="solid proHr"></hr>
      <Field type="text" name="username" placeholder={formatMessage({id: "emailGo", defaultMessage: "emailGo"})}/>
      <Field type="password" name="passwordClear" placeholder={formatMessage({id: "passwordGo", defaultMessage: "passwordGo"})}/>
      <Field type="password" name="confirmPassword" placeholder={formatMessage({id: "confirmPasswordPro", defaultMessage: "confirmPasswordPro"})}/>
      <Field type="text" name="couponCode" placeholder={formatMessage({id: "couponCode", defaultMessage: "couponCode"})}/>
      <Button onClick={() => setRegisterStep(RegisterStep.INDEX)} type="button">
        {formatMessage({id: "back", defaultMessage: "Back"})}
      </Button>
      <Button onClick={() => {
        userValidation(values)
      }} type="button" 
        disabled={!values.firstName || !values.lastName || !values.mobile || !values.username || !values.passwordClear || !values.confirmPassword}>
          {formatMessage({id: "next", defaultMessage: "Neste"})}
      </Button>
    </React.Fragment>
  )
})

interface ConfirmPrivateProps{
  setRegisterStep: SetRegisterStep;
  registerUserPrivate: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToConfirmPrivateProps = {
  setRegisterStep, 
  registerUserPrivate
}

export const ConfirmPrivate = connect(null, mapDispatchToConfirmPrivateProps)(({ setRegisterStep, registerUserPrivate }: ConfirmPrivateProps) => {

  const { formatMessage } = useIntl();

  const {values} = useFormikContext<RegisterUserForm>()

  return(
    <React.Fragment>
      <h4 style={{paddingBottom: "10px"}}>{formatMessage({id: "confirmInformationGo", defaultMessage: "Confirm Information"})}</h4>
      <p className="registerConfirmP"><span>{formatMessage({id: "firstName", defaultMessage: "firstName"})}</span> : <span>{values.firstName}</span></p>
      <p className="registerConfirmP"><span>{formatMessage({id: "lastName", defaultMessage: "lastName"})}</span> : <span>{values.lastName}</span></p>
      <p className="registerConfirmP"><span>{formatMessage({id: "mobile", defaultMessage: "mobile"})}</span> : <span>{values.mobile}</span></p>
      <p className="registerConfirmP"><span style={{textTransform: "none"}}>{formatMessage({id: "emailGo", defaultMessage: "emailGo"})}</span> : <span style={{textTransform: "lowercase"}}>{values.username}</span></p>
      <Button type="button" onClick={() => setRegisterStep(RegisterStep.PRIVATE)}>
        {formatMessage({id: "back", defaultMessage: "tilbake"})}
      </Button>
      <Button type="button" onClick={() => registerUserPrivate(values)}>
        {formatMessage({id: "confirm", defaultMessage: "Confirm"})}
      </Button>
    </React.Fragment>
  )
})

export const ConfirmMunicipality = connect(null, mapDispatchToProps)(({ setRegisterStep, userValidation }: RegisterPrivateProps) => {

  const { formatMessage } = useIntl();

  const {values} = useFormikContext<RegisterUserForm>()

  return(
    <React.Fragment>
      <h4 style={{paddingBottom: "10px"}}>{formatMessage({id: "confirmKommune", defaultMessage: "You Are About To Register A User With An E-Mail Containing 'Kommune'. Municipal Users Should Use Safemate Pro, Not Safemate Go."})}</h4>
      <Button type="button" onClick={() => setRegisterStep(RegisterStep.FIRM_ADDRESS)}>
        {formatMessage({id: "abortGo", defaultMessage: "Abort"})}
      </Button>
      <Button type="button" onClick={() => userValidation({...values, force: true})}>
        {formatMessage({id: "registerAnywayGo", defaultMessage: "Register anyway"})}
      </Button>
    </React.Fragment>
  )
})