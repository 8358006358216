import React from 'react';
import SIcon from './SIcon';
import { FormattedMessage } from 'react-intl';

const MagnetDoor = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M10.8 20c0-.6-.6-1-1.4-1S8 19.4 8 20h2.8zm6.2-7c-.6 0-1-.4-1-1V9c0-.6.4-1 1-1V7h-4v13h-1V5L3 6.5V20h-.8c-.1 0-.2-.1-.2-.2v-.5V6.6c0-.5.3-.9.8-1l9-1.6h.2c.5 0 1 .5 1 1v1h4c.6 0 1 .4 1 1v12.7c0 .1-.1.2-.2.2H17V13z" />
    <title>
      <FormattedMessage id="sensorWanderer" defaultMessage="Dørsensor" />
    </title>
  </svg>
);

const Icon = props => <SIcon {...props} icon={MagnetDoor} />;

export default Icon;
