
import { call, put, takeEvery } from 'redux-saga/effects';
import * as Routines from '../routines';
import * as Api from '../api';
import { Policies } from '@Safemate/Policy/types';
import { Device } from '@Safemate/Settings/types';
import { AppAction } from '@Safemate/Store/types';

function* getPolicies(action: AppAction<Device>){
  try{
    const policies: Policies = yield call(Api.getPoliciesForDevice, action.payload.deviId);
    yield put(Routines.getPolicies.success(policies));
  }
  catch(e){
    yield put(Routines.getPolicies.failure());
  }
  yield put(Routines.getPolicies.fulfill());
}
function* policySaga(){
    yield takeEvery(Routines.getPolicies.TRIGGER, getPolicies);
}

export default policySaga;
