import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Col, Button, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {Action, ActionFunctionAny} from "redux-actions";
import { Routine } from "redux-saga-routines";
import styled from "styled-components";
import { Formik, Form } from "formik";

import TrackingInfo from "./trackingInfo";
import { TrackingSettings } from "../../types";
import TrackingInterval from "./trackingInterval";
import Advanced from "./advanced";
import AdvancedContent from "./advancedContent";
import ManualTracking from "../TrackingMode/manualTracking";
import AutoTracking from "../TrackingMode/autoTracking";
import { confirmModal } from "../../General/Tabs/deleteData";
import { saveTracking } from "../../Store/routines";
import UnsavedCheck from "../../../SaveNeeded/unsavedModal";
import { AppState } from "@Safemate/Store/types";

interface OptionsPros extends WrappedComponentProps{
  deviId: number;
  settings: TrackingSettings;
  saveTracking: Routine<ActionFunctionAny<Action<any>>>;
}

export const Header = styled.p`
  min-height: 25px;
`;

const SaveCol = styled(Col)`
  padding: 10px;
  margin-top: 15px;
  text-align: right;
`

const CenteredRow = styled(Row)`
  padding: 10px;
  margin-top: 15px;
  text-align: center;
`;

const Wrapper = styled(Row)`
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius};
  padding: 10px 25px;
`;

export const PaddedCol = styled(Col)`
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const LabelWrapper = styled(Col)`
  @media (max-width: 768px) {
    padding: 10px;
  }
  display: flex;
  align-items: center;
`;

export const RowLabel = styled.span`
  width: 80%
`;

export const RowWrapper = styled.div<{noBottomBorder?: boolean, noTopBorder?: boolean}>`
  ${props => 
    `${props.noBottomBorder ? "border-bottom: none !important;" : ""}
    ${props.noTopBorder ? "border-top: none !important;" : ""}`
  }
  display: flex;
  padding: 25px;
  border: ${props => props.theme.colors.border};
  font-size: 16px;
  font-weight: bold;
  flex-grow: 1;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ToggleWrap = styled.span`
`;

const Options = injectIntl(({intl: {formatMessage}, settings, saveTracking, deviId}: OptionsPros) => {

  const { Comp, func } = confirmModal();

  return(
    <Formik
      onSubmit={(values) => {
        if(values.trackingIntervalNightInMinutes !== 0)
          func(true)
        else
          saveTracking({
            deviceId: deviId,
            settings: values
          })
      }}
      initialValues={{
        ...settings,
        trackingNightEnds: settings.trackingNightEnds || "700",
        trackingNightStarts: settings.trackingNightStarts || "2200"
      }}
      enableReinitialize
    >
    {({values, dirty}) => 
      <Form>
        <Wrapper>
        <CenteredRow>
          <h2>{formatMessage({id: "selectTrackingMode", defaultMessage: "Lokaliseringsmodus"})}</h2>
        </CenteredRow>
        <Row>
          <ManualTracking/>
          <AutoTracking/>
        </Row>
        <Comp
          title={formatMessage({id: "nightTimeTrackingConfirmHead", defaultMessage: "Sette lokaliseringsintervall - natt"})}
          body={
            <p>
              <span>{formatMessage({id: "nightTimeTrackingConfirm", defaultMessage: "Er du sikker på at du ønsker å ha separate intervaller for dag og natt?"})}</span>
            </p>
          }
          confirmText={formatMessage({id: "confirm", defaultMessage: "bekreft"})}
          confirmFunc={() => {
            saveTracking({
              deviceId: deviId,
              settings: values
            });
            func(false);
          }}
        />
        </Wrapper>
        <Wrapper style={{marginTop: "25px"}}>
        <Row>
          <TrackingInfo/>
          <TrackingInterval/>
          <Advanced/>
        </Row>
        <AdvancedContent/>
        <Row>
          <SaveCol md={12} sm={12}>
            <Button disabled={!dirty} type="submit">{formatMessage({id: "save", defaultMessage: "Lagre"})}</Button>
          </SaveCol>
        </Row>
        </Wrapper>
        <UnsavedCheck/>
      </Form>
    }
    </Formik>
  )
})

const mapStateToProps = ({ settings: {tracking: {settings}, device: {device: {deviId}}}}: AppState) => {
  return {
      deviId,
      settings
  }
}

const mapDispatchToProps = {
  saveTracking
}

export default connect(mapStateToProps, mapDispatchToProps)(Options);