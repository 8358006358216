import {GeofenceState} from "../types";
import {Reducer} from "redux";
import {
  fetchGeofence,
  fetchGlobalGeofence,
  saveGeofence,
  deleteGeofence,
  saveGlobalSettings,
  getLatestPosition,
  deleteSharedGeofence, getGeofenceIndoorLocations, getCustomersGeofence
} from "../routines";
import { SettingsActionTypes } from "../actionTypes";
import { Geofence } from "../../Geofence/types";
import { defaultFence } from "../../Geofence/utils";
import { GeofenceTypeEnum } from "../../Geofence/GeofenceTypeEnum";
import { v4 } from "uuid"
import { SaveGeofenceResponse } from "../Saga/geofence";
import { ControlState } from "../../Geofence/Controls";

const geofenceState: GeofenceState = {
    editing: false,
    geofence: [],
    globalGeofence: [],
    currentGeofence: defaultFence(),
    geofenceType: GeofenceTypeEnum.NONE,
    initialFences: [],
    refreshToken: v4(),
    controlState: ControlState.DEFAULT,
    initialSelection: false,
    processDelete: false,
    processSave: false,
    loading: true,
    latestPosition: [],
    indoorLocations:[],
    customers: [],
};

const geofence: Reducer<GeofenceState> = (state: GeofenceState = geofenceState, action: any) => {
  switch (action.type) {

    case fetchGeofence.TRIGGER:
      return {
        ...state,
        loading: true
      }

    case fetchGeofence.SUCCESS:
      return getFetchedState(state, action.payload);

    case fetchGlobalGeofence.SUCCESS:
      return{
        ...state,
        globalGeofence: action.payload
      }

    case getGeofenceIndoorLocations.SUCCESS:
      return{
        ...state,
        indoorLocations: action.payload
      }

    case getLatestPosition.SUCCESS:
      return{
        ...state,
        latestPosition: action.payload
      }

    case getCustomersGeofence.SUCCESS:
      return{
        ...state,
        customers: action.payload
      }

    case saveGeofence.TRIGGER:
      return {
        ...state,
        processSave: true
      }

    case saveGeofence.SUCCESS:
      return {
        ...getSavedState(state, action.payload),
        processSave: false
      }

    case saveGeofence. FAILURE:
      return {
        ...state,
        processSave: false
      }
      
    case deleteGeofence.TRIGGER:
      return{
        ...state,
        processDelete: true
      }

    case deleteGeofence.SUCCESS:
      return {
        ...getFetchedState(state, action.payload),
        processDelete: false
      }

    case deleteSharedGeofence.SUCCESS:
      return {
        ...state,
        globalGeofence: action.payload
      }

      case deleteGeofence.FAILURE:
      return {
        ...state,
        processDelete: false
      }

    case SettingsActionTypes.SET_EDITING:
      return{
        ...state,
        editing: action.payload
      }

    case SettingsActionTypes.SET_GEOFENCE:
      return{
        ...state,
        currentGeofence: action.payload
      }

    case SettingsActionTypes.SET_GEOFENCE_TYPE:
      return{
        ...state,
        geofenceType: action.payload
      }

    case SettingsActionTypes.SET_CONTROL_STATE:
      return{
        ...state,
        controlState: action.payload
      }

    case SettingsActionTypes.SET_GEOFENCE_REFRESH:
      return{
        ...state,
        refreshToken: action.payload
      }

    case SettingsActionTypes.SET_INITIAL_SELECTION:
      return{
        ...state,
        initialSelection: action.payload
      }

    default:
        return state;
  }
};

export default geofence;

const getSavedState = (currentState: GeofenceState, response: SaveGeofenceResponse) => {
  const newState = {
    ...currentState,
    refreshToken: v4(),
    geofence: response.allGeofences,
    currentGeofence: response.savedGeofence,
    controlState: ControlState.EDIT
  };
  newState.initialFences = JSON.parse(JSON.stringify(response.allGeofences));
  return newState;
}

const getFetchedState = (currentState: GeofenceState, geofence: Geofence[]) => {
  const newState = {
    ...currentState,
    refreshToken: v4(),
    geofence: geofence,
    loading: false
  }
  newState.initialFences = JSON.parse(JSON.stringify(geofence));
  return newState;
}
