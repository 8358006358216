/* eslint-disable */
export enum TranslationModuleActionTypes {
  RESET_SELECTED = "@@TranslatePage/RESET_SELECTED",
  SET_SELECTED = "@@TranslatePage/SET_SELECTED",
  SET_MODAL_STATE = "@@TranslatePage/SET_MODAL_STATE",
  SORT_TABLE = "@@TranslatePage/SORT_TABLE",
  SET_SEARCH = "@@TranslatePage/SET_SEARCH",
  SET_LANGUAGE_FILTER = "@@TranslatePage/SET_LANGUAGE_FILTER",
  SET_LANGUAGE_OPTION = "@@TranslatePage/SET_LANGUAGE_OPTION"
}
