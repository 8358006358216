export const batteryThreshold = [
  {value: 10, text: "10%"},
  {value: 20, text: "20%"},
  {value: 30, text: "30%"},
  {value: 40, text: "40%"},
];

export const powerFailureReportDelay = [
  0,
  180,
  300,
  600,
  900,
  1200,
  1800
];
