import { LanguageOptionEnum } from "./LanguageOptionEnum";
import { SortEnum } from "./SortEnum";
import { FilterState, TextLabelWithText } from "./types"

export type Filter = (languageCode: string, texts: TextLabelWithText[], filter: FilterState) => TextLabelWithText[];

export type SortByString = (a: string, b: string) => number;

export const filterTexts: Filter = (languageCode, texts, filter) => {

  return texts
    .filter(textLabel => 
      (filterLanguage(textLabel, filter)) &&
      (textLabel.label.toUpperCase().includes(filter.search.toUpperCase())
      || textLabel.texts.filter(text => text.translatedText.toUpperCase().includes(filter.search.toUpperCase())).length > 0)
    )
    .sort((a, b) => sortTexts(filter.sort, languageCode, a, b))
}

const sortTexts = (sortHeader: SortEnum, languageCode: string, textOne: TextLabelWithText, textTwo: TextLabelWithText): number => {

  const tOne = textOne.texts.find(t => t.languageCode === languageCode);
  const tTwo = textTwo.texts.find(t => t.languageCode === languageCode);

  switch(sortHeader){
    case SortEnum.COUNT_ASC:
      return textOne.count - textTwo.count;
    case SortEnum.COUNT_DESC:
      return textTwo.count - textOne.count;
    case SortEnum.SOURCE_ASC:
      return sortByString(textOne.source, textTwo.source);
    case SortEnum.SOURCE_DESC:
      return sortByString(textTwo.source, textOne.source);
    case SortEnum.LABEL_ASC:
      return sortByString(textOne.label, textTwo.label);
    case SortEnum.LABEL_DESC:
      return sortByString(textTwo.label, textOne.label);
    case SortEnum.TEXT_ASC:
      if(!tOne && !tTwo){
        return 0;
      }
      else if(!tTwo){
        return -1;
      }
      else if(!tOne){
        return 1;
      }
      else{
        return sortByString(tOne.translatedText, tTwo.translatedText);
      }
      case SortEnum.TEXT_DESC:
        if(!tOne && !tTwo){
          return 0;
        }
        else if(!tOne){
          return -1;
        }
        else if(!tTwo){
          return 1;
        }
        else{
          return sortByString(tTwo.translatedText, tOne.translatedText);
        }
    case SortEnum.DEFAULT:
      return 0;
  }
}

const filterLanguage = (text: TextLabelWithText, filter: FilterState) => {

  switch(filter.languageOption){
    case LanguageOptionEnum.ALL:
      return true;
    case LanguageOptionEnum.ADDED:
      return text.texts.filter(t => filter.selectedLanguage.includes(t.languageCode)).length > 0;
    case LanguageOptionEnum.MISSING:
      let match = false;
      text.texts.forEach(t => {
        if(filter.selectedLanguage.includes(t.languageCode)){
          match = true;
        }
      });
      return !match;
  }
}

const sortByString: SortByString = (a,b) => {
  const nameA = a.toUpperCase(); // ignore upper and lowercase
  const nameB = b.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

/* switch (filter){
    case OrderFilterEnum.LAST_CONTACT_ASC:
      return deviceOne.lastMsgIn - deviceTwo.lastMsgIn;
    case OrderFilterEnum.LAST_CONTACT_DESC:
      return deviceTwo.lastMsgIn - deviceOne.lastMsgIn;
    case OrderFilterEnum.NAME:
      return sortByString(deviceOne.deviceName, deviceTwo.deviceName);
    case OrderFilterEnum.PHONE_NUMBER:
        return sortByString(deviceOne.phoneNumber, deviceTwo.phoneNumber);
    case OrderFilterEnum.NOTES_ADDED_DESC:  
    default:
      if (deviceOne.statusRank > deviceTwo.statusRank) return 1;
      if (deviceOne.statusRank < deviceTwo.statusRank) return -1;
      if (deviceOne.tracking && !deviceTwo.tracking) return -1;
      if (deviceTwo.tracking && !deviceOne.tracking) return 1
      return deviceOne.deviceName < deviceTwo.deviceName ? -1 : 1;
  }*/