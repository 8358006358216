import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";

function* getCountries(action: AppAction<string>){
  try {
    const countries = yield call(Api.getCountries, action.payload);
    yield put(Routines.getCountries.success(countries));
  }
  catch(e){
    yield put(Routines.getCountries.failure());
  }
}

function* getTextsMyPage(){
  try {
    const texts = yield call(Api.getTextsMyPage);
    yield put(Routines.getTextsMyPage.success(texts));
  }
  catch(e){
    yield put(Routines.getCountries.failure());
  }
}


function* countrySaga(){
  yield takeLatest(Routines.getCountries.TRIGGER, getCountries);
  yield takeLatest(Routines.getTextsMyPage.TRIGGER, getTextsMyPage);
}

export default countrySaga;
