import { call, put, takeLatest, select } from 'redux-saga/effects';

import * as Routines from '../Routines';
import { feedbackFailure } from '../../../Feedback/reducer';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import { mapDeviceTypes, updateDeviceAndModifiedTime } from '../../utils';
import { selectIsPartner, selectIsPrivate } from '@Safemate/Store/selectors';
import { IDevice, IPartnerDevice } from '@Safemate/Model/Device';

function* initialize(action: AppAction<null>){
  try {

    const isPrivate: boolean = yield select(selectIsPrivate);
    const isPartner: boolean = yield select(selectIsPartner);

    const devices: IDevice[] = yield call(Api.getDevices, 0, 0, isPrivate);

    const { alarms, lastModifiedTime } = updateDeviceAndModifiedTime(devices, 0);

    for(const alarmDevice of alarms){
        yield put(Routines.alarmAnalysis(alarmDevice.deviId));
    }

    yield put(Routines.twinSuffix());

    const deviceTypes = mapDeviceTypes(devices);

    if(isPartner){
      const partnerDevices: IPartnerDevice[] = yield call(Api.getPartnerDeviceList);
      yield put(Routines.partnerDeviceList.success(partnerDevices));
    }

    yield put(Routines.initialize.success({devices, deviceTypes, lastModifiedTime}));

  }
  catch(e){
    if(e.response.status !== 403){
      yield feedbackFailure(e);
    }
    yield put(Routines.initialize.failure());
  }
  yield put(Routines.initialize.fulfill());
}

function* twinSuffix(action: AppAction<null>){
  try{
    const suffix: string = yield call(Api.getTwinSuffix);
    yield put(Routines.twinSuffix.success(suffix));
  }
  catch(e){
    yield put(Routines.twinSuffix.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.twinSuffix.TRIGGER, twinSuffix);
}

export default initializationSaga;
