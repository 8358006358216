import {call, put, select, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure, feedbackSuccess } from '@Safemate/Feedback/reducer';
import { ShareAlarm } from '../api';
import { AlarmAnalysis } from '@Safemate/DeviceList/Store/types';
import { AHPComment, EventViewAlarm } from '@Safemate/Model/EventViewAlarm';
import { getHistoricLimit } from './initialization';



function* shareAlarm(action: AppAction<ShareAlarm>){
  try {
    yield call(Api.shareAlarm, action.payload);
    yield feedbackSuccess("alarmShared");
    yield put(Routines.shareAlarm.success());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.shareAlarm.failure(e));
  }
}

function* getAlarmAnalysis(action: AppAction<number>){
  try{
    const alarmAnalysis: AlarmAnalysis[] = yield call(Api.getAlarmAnalysis, action.payload);
    yield put(Routines.getAlarmAnalysis.success({analysis: alarmAnalysis, transaction: action.payload}));
  }
  catch(e){
    yield put(Routines.getAlarmAnalysis.failure());
  }
}

function* createComment(action: AppAction<AHPComment>){
  try{
    yield call(Api.createComment, action.payload);
    yield put(Routines.createComment.success());
    yield feedbackSuccess("commentCreated")
    yield put(Routines.initialize.trigger());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.createComment.failure(e));
  }
}

function* createCommentAndClose(action: AppAction<AHPComment>){
  try{
    yield call(Api.createCommentAndClose, action.payload);
    yield put(Routines.createCommentAndClose.success());
    yield feedbackSuccess("caseClosed")
    yield put(Routines.initialize.trigger());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.createCommentAndClose.failure(e));
  }
}

function* createCommentAndReOpen(action: AppAction<AHPComment>){
  try{
    yield call(Api.createCommentAndReOpen, action.payload);
    yield put(Routines.createCommentAndReOpen.success());
    yield feedbackSuccess("caseReopened")
    yield put(Routines.initialize.trigger());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.createCommentAndReOpen.failure(e));
  }
}

function* closeAll(action: AppAction<AHPComment>){
  try{
    yield call(Api.closeAll, action.payload);
    yield put(Routines.closeAll.success());
    yield feedbackSuccess("caseClosed")
    yield put(Routines.initialize.trigger());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.closeAll.failure(e));
  }
}

function* deleteComment(action: AppAction<AHPComment>){
  try{
    yield call(Api.deleteComment, action.payload);
    yield put(Routines.deleteComment.success());
    yield feedbackSuccess("commentDeleted")
    yield put(Routines.initialize.trigger());
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteComment.failure(e));
  }
}

function* getHistoricAlarmsAndIncrease(action: AppAction<null>){
  try{
    let historicLimit: number = yield select(getHistoricLimit);
    historicLimit = historicLimit + 10;
    const historic: EventViewAlarm[] = yield call(Api.getHistoricAlarms, historicLimit);
    yield put(Routines.getHistoricAlarmsAndIncrease.success({historic, historicLimit}));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getHistoricAlarmsAndIncrease.failure(e));
  }
}

function* alarmSaga(){
    yield takeLatest(Routines.shareAlarm.TRIGGER, shareAlarm);
    yield takeLatest(Routines.getAlarmAnalysis.TRIGGER, getAlarmAnalysis);
    yield takeLatest(Routines.createComment.TRIGGER, createComment);
    yield takeLatest(Routines.createCommentAndClose.TRIGGER, createCommentAndClose);
    yield takeLatest(Routines.createCommentAndReOpen.TRIGGER, createCommentAndReOpen);
    yield takeLatest(Routines.closeAll.TRIGGER, closeAll);
    yield takeLatest(Routines.deleteComment.TRIGGER, deleteComment);
    yield takeLatest(Routines.getHistoricAlarmsAndIncrease.TRIGGER, getHistoricAlarmsAndIncrease);
}

export default alarmSaga;
