import {call, put, select, takeLatest} from 'redux-saga/effects';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import * as Api from "../api";
import DeviceEventSetup from '@Safemate/EventSetupNew/Model/DeviceEventSetup';
import { useDefault } from '@Safemate/EventSetupNew/Body/utils';
import { Device } from '@Safemate/Settings/types';


function* getDeviceEventSetup(action: AppAction<Device>){
  try{
    const device: Device = action.payload;
    let eventSetups: DeviceEventSetup[] = [];

    if(useDefault(device)){
      eventSetups = yield call(Api.getDefaultDeviceEventSetups, action.payload.deviId);
    }
    else{
      eventSetups = yield call(Api.getDeviceEventSetups, action.payload.deviId);
    }
    yield put(Routines.getDeviceEventSetups.success(eventSetups));
  }
  catch(e){
    yield put(Routines.getDeviceEventSetups.failure());
  }
  yield put(Routines.getDeviceEventSetups.fulfill());
}

interface DeleteDeviceEventSetup{
  eventSetupId: number;
  eventSetups: DeviceEventSetup[];
}

function* deleteDeviceEventSetup(action: AppAction<DeleteDeviceEventSetup>){
  try{
    yield call(Api.deleteDeviceEventSetup, action.payload.eventSetupId);
    yield put(Routines.deleteDeviceEventSetup.success(action.payload));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteDeviceEventSetup.failure());
  }
  yield put(Routines.deleteDeviceEventSetup.fulfill());
}

export interface SaveDeviceEventSetup{
  deviceId: number;
  eventSetups: DeviceEventSetup[];
  sequentialCalling: boolean;
}

function* saveDeviceEventSetup(action: AppAction<SaveDeviceEventSetup>){
  try{
    const response: DeviceEventSetup[] = yield call(Api.saveDeviceEventSetup, action.payload);
    yield put(Routines.saveDeviceEventSetup.success(response));
    yield feedbackSuccess("savedEventSetup");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveDeviceEventSetup.failure());
  }
  yield put(Routines.saveDeviceEventSetup.fulfill());
}

function* deviceEventSetupSaga(){
    yield takeLatest(Routines.getDeviceEventSetups.TRIGGER, getDeviceEventSetup);
    yield takeLatest(Routines.deleteDeviceEventSetup.TRIGGER, deleteDeviceEventSetup);
    yield takeLatest(Routines.saveDeviceEventSetup.TRIGGER, saveDeviceEventSetup);
}

export default deviceEventSetupSaga;
