import { Row } from "react-bootstrap";
import styled from "styled-components";

export const Tr = styled.tr<{exist: boolean, header: boolean}>`
  ${props => !props.exist && "color: red;"}
  border: 1px solid gray;
  ${props => !props.header && `&:hover {
    opacity: 0.6;
    cursor: pointer;
  }`}
`;

export const Wrap = styled.div`
  position: relative;
`

export const Td = styled.td`
  padding: 10px;
`;

export const Th = styled.th`
  padding: 10px;
  &:hover{
    opacity: 0.6;
    cursor: pointer;
  }
`;

export const Span = styled.span`
  display: flex;
  align-items: center;
`;

export const Caret = styled.b<{down?: boolean, up?: boolean}>`
  margin-left: 3px;
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: ${props => (props.down ? `6px dashed` : 'none')};
  border-right: ${props => `6px solid transparent`};
  border-left: ${props => `6px solid transparent`};
  border-bottom: ${props => (props.up ? `6px dashed` : 'none')};
  cursor: pointer;

  &:focus {
    outline: 1px dotted ${props => props.theme.colors.textPrimary};
  }
`;

export const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;