import * as React from "react";
import { injectIntl, WrappedComponentProps, useIntl } from "react-intl";
import { Field } from "formik";
import styled from "styled-components";
import { Col, Row, Button } from "react-bootstrap";

import Dropdown from "../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../Elements/Dropdown/types";
import { CheckWrap, CheckWrapPrivate } from "../../Settings/Components/checkbox";
import { UserForm } from "../types";
import LogoModal from "../../Modals/Logo";
import { RadioGroup, Radio } from "../../../Elements/RadioFormik";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { sendGdprEmail } from "../Store/routines";
import  ArrowDown from "../../../Elements/Icon/Icons/arrows/ArrowDown";
import  ArrowRight from "../../../Elements/Icon/Icons/arrows/ArrowRight";
import { DatabaseCountry } from "../Store/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { MarketEnum } from "@Safemate/MarketEnum";
import { AppState } from "@Safemate/Store/types";
import { LogoEnum } from "@Safemate/Modals/Logo/LogoEnum";


const NewsLetterLink = styled.a`
  color: ${props => props.theme.colors.accent};
`;

const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  padding-bottom: 25px;
  .confirmPassword{
    label{
      text-transform: none;
    }
  }
`;

const StyledRowLessPadBottom = styled(Row)`
  padding-bottom: 0px;
  .confirmPassword{
    label{
      text-transform: none;
    }
  }
`;

const StyledRowMorePadBottom = styled(Row)`
  padding-bottom: 90px;
  padding-top: 10px;
  .confirmPassword{
    label{
      text-transform: none;
    }
  }
`;

const StyledPasswordCol = styled(Col)`
  display: flex;
`;

const PasswordLabel = styled.label`
  padding-left: 3px;
  cursor: pointer;
`;


const StyledCol = styled(Col)`
  margin-top: 20px;
  padding-left: 0px;
  .confirmPassword{
    label{
      text-transform: none;
    }
  }
`;

interface DisableableField extends WrappedComponentProps{
  disabled: boolean;
}

interface EmailProps extends WrappedComponentProps{
  disabled: boolean,
  emailAsUsername: boolean
}


export const EmailAsUsername = injectIntl(({intl: { formatMessage }}: WrappedComponentProps) => {
  const Check = CheckWrap<UserForm>("emailAsUsername", {label: {id: "emailAsUsername", defaultMessage: "Use email as username"}})
  return(
    <StyledCol md={12}>
        <Check/>
    </StyledCol>
  )
})

export const Email = injectIntl(({ intl: { formatMessage }, disabled, emailAsUsername }: EmailProps) => {
  return (
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "emailPro", defaultMessage: "E-post"})}</Label>
        <StyledField
          type="text"
          name="username"
          disabled={disabled}
        />
      </Col>      
    </StyledRow>
  )
})

export const Name = injectIntl(({ intl: { formatMessage }, disabled }: DisableableField) => {
  return (
    <StyledRow>
      <Col md={6}>
        <Label>{formatMessage({id: "firstName", defaultMessage: "Fornavn"})}</Label>
        <StyledField
          type="text"
          name="firstName"
          disabled={disabled}
        />
      </Col>
      <Col md={6}>
        <Label>{formatMessage({id: "lastName", defaultMessage: "Etternavn"})}</Label>
        <StyledField
          type="text"
          name="lastName"
          disabled={disabled}
        />
      </Col>
    </StyledRow>
  )
})

export const PasswordDropdown = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {

  const [show, setShow] = React.useState(false);

  const handleClick = (event: any) => {
    setShow(!show);
  };

  return (
    <StyledRow>
      <Col md={12}>
        <StyledPasswordCol>{show ? <ArrowDown onClick={handleClick} style={{cursor: "pointer"}}/> : <ArrowRight onClick={handleClick} style={{cursor: "pointer", paddingTop: "4px"}}/>}<PasswordLabel onClick={handleClick}>{formatMessage({id: "endrePassord", defaultMessage: "Endre Passord"})}</PasswordLabel></StyledPasswordCol>
        {show &&<Col>
                  <CurrentPassword />
                  <Password/>
                  <Confirm/>
                </Col>}
      </Col>
    </StyledRow>
  )
}) 

export const Password = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {
  return (
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "password", defaultMessage: "Password"})}</Label>
        <StyledField
          type="password"
          name="password"
          placeholder={formatMessage({id: "password", defaultMessage: "Password"})}
        />
      </Col>
    </StyledRow>
  )
}) 

export const Confirm = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {
  return (
    <StyledRow>
      <Col md={12} className="confirmPassword">
        <Label>{formatMessage({id: "confirmPasswordPro", defaultMessage: "Bekreft passord"})}</Label>
        <StyledField
          type="password"
          name="confirm"
          placeholder={formatMessage({id: "confirmPasswordPro", defaultMessage: "Bekreft passord"})}
        />
      </Col>
    </StyledRow>
  )
})

export const CurrentPassword = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {
  return (
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "currentPassword", defaultMessage: "Current Password"})}</Label>
        <StyledField
          type="password"
          name="currentPassword"
          placeholder={formatMessage({id: "currentPassword", defaultMessage: "Current Password"})}
        />
      </Col>
    </StyledRow>
  )
})

interface LanguageProps extends WrappedComponentProps{
  languageCode: string;
  countries: DatabaseCountry[];
}

export const MobileAndLanguage = injectIntl(
  withFormWrapper<UserForm, LanguageProps>(({ intl: { formatMessage }, formik: { setFieldValue }, languageCode, countries}) => {
    const language = React.useMemo(() => {
      return countries.map((country: DatabaseCountry) => {
        return{
          value: `${country.languageCode}`,
          text: formatMessage({id: `${country.languageCode}`, defaultMessage: `${country.languageName}`})
        }
    })
    }, [])

    return (
      <StyledRow>
        <Col md={6}>
          <Label>{formatMessage({id: "mobile", defaultMessage: "Mobil"})}</Label>
          <StyledField
            type="text"
            name="mobile"
          />
        </Col>
        <Col md={6}>
          <Label>{formatMessage({id: "language", defaultMessage: "Språk"})}</Label>
          <Dropdown
            options={language}
            onChange={(option) => {
              const { value } = option as Option;
              setFieldValue("languageCode", value);
            }}
            initial={languageCode}
            size={{width: "100%"}}
          />
        </Col>
      </StyledRow>
    )
  })
);

export const Language = injectIntl(
  withFormWrapper<UserForm, LanguageProps>(({ intl: { formatMessage }, formik: { setFieldValue }, languageCode, countries}) => {

    const language = React.useMemo(() => {
      return countries.map((country: DatabaseCountry) => {
        return{
          value: `${country.languageCode}`,
          text: formatMessage({id: `${country.languageCode}`, defaultMessage: `${country.languageName}`})
        }
    })
    }, [languageCode, countries])

    return (
      <StyledRow>
        <Col md={6}>
          <Label>{formatMessage({id: "language", defaultMessage: "Språk"})}</Label>
          <Dropdown
            options={language}
            onChange={(option) => {
              const { value } = option as Option;
              setFieldValue("languageCode", value);
            }}
            initial={languageCode}
            size={{width: "100%"}}
          />
        </Col>
      </StyledRow>
    )
  })
);



export const Mobile = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {
  return (
    <StyledRow>
      <Col md={12}>
        <Label>{formatMessage({id: "mobile", defaultMessage: "Mobil"})}</Label>
        <StyledField
          type="text"
          name="mobile"
        />
      </Col>      
    </StyledRow>
  )
})

export const ApplyToAll = CheckWrap<UserForm>("applyToAll", {label: {id: "applyToAllRecipients", defaultMessage: "Endre også der dette nummeret er mottaker av varslinger"}});

export const NewsLetter = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => 
  <StyledRow>
    <MarginCol md={12}>
      <span>
        <NewsLetterLink href="https://www.safemate.no/nyhetsbrev/" target="_blank">
          {formatMessage({id: "myPageHerePrivate", defaultMessage: "Med meg pa nyhetsbrev"})}
        </NewsLetterLink>
      </span>
    </MarginCol>
  </StyledRow>
)

export const TfaEnabled = () => {
  const Check = CheckWrap<UserForm>("tfaEnabled", {label: {id: "toFaktorPrivate", defaultMessage: "To-faktor autentisering"}})
  return(
    <StyledRowLessPadBottom>
      <Col md={12}>
        <Check/>
      </Col>
    </StyledRowLessPadBottom>
  )
}

const ScrollBox = styled.div`
  color: black;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 200px;
  padding: 10px;
  overflow-y: scroll;
`;

const GdprOkBtn = styled(Button)`
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 50px;
  justify-content: center;
`;

const GdprCol = styled(Col)`
  > div:first-of-type  {
    margin-top: 10px;
    padding-right: 5px;
  }
`;

const MarginCol = styled(Col)`
  margin-left: 15px;
`;

const SendGdprEmailBtn = styled(Button)`
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 50px;
  justify-content: center;
  margin-bottom: 10px;
`;

interface GdprProps extends WrappedComponentProps{
  sendGdprEmail: Routine<ActionFunctionAny<Action<any>>>;
  market: MarketEnum;
}

const mapStateToGdpProps = ({ appSettings: { market } }: AppState) => {
  return {
    market
  }
}

const mapDispatchFromStateGdpr = {
  sendGdprEmail
}


export const Gdpr = connect(mapStateToGdpProps, mapDispatchFromStateGdpr)(injectIntl(({ intl: { formatMessage }, market, sendGdprEmail }: GdprProps) => {

  const [ showGdpr, setShowGdpr ] = React.useState(false);

  const Check = CheckWrapPrivate<UserForm>("gdprPsAccepted", {id: "accepted", defaultMessage: "Godtatt"})

  const handleSendGdprEmail = (e:any) => {
    sendGdprEmail();
    e.target.disabled = true;
  }
  return(
    <StyledRowMorePadBottom>
      <MarginCol md={12}>
        <GdprCol>
          <Check/>
        </GdprCol>
        <Button onClick={() => setShowGdpr(true)}>
          {formatMessage({id: "myPageShow", defaultMessage: "VIS"})}
        </Button>
      </MarginCol>
      <LogoModal 
        logo={market === MarketEnum.SAFECALL ? LogoEnum.SAFECALL : LogoEnum.SAFEMATE}
        isOpen={showGdpr}
        title={formatMessage({id: "gdprPSTitle", defaultMessage: "Personvernerklæring"})}
        footer={
          <Col>
            <SendGdprEmailBtn
              id="sendMeEmailGdpr"
              onClick={(e) => handleSendGdprEmail(e)}
            >
              {formatMessage({id: "sendMeOnEmail", defaultMessage: "SEND MEG PÅ E-POST"})}
            </SendGdprEmailBtn>
            <GdprOkBtn
              onClick={() => setShowGdpr(false)}
            >
              {formatMessage({id: "ok", defaultMessage: "OK"})}
            </GdprOkBtn>
          </Col>
          
        }
      >
        <ScrollBox dangerouslySetInnerHTML={{ __html: formatMessage({id: "gdprPSText", defaultMessage: "GDPR Placeholder"})}}/>
      </LogoModal>
    </StyledRowMorePadBottom>
  )
})
)

export enum TfaType{
  SMS = "SMS",
  EMAIL = "EMAIL"
}

export const Tfa = () => {
  const { formatMessage } = useIntl();
  return(
    <StyledRowLessPadBottom>
      <Col md={12}>
        <RadioGroup disabled={false}>
          <Field
            component={Radio}
            name="tfaEmail"
            radioValue={TfaType.SMS}
            id="tfaSMS"
            label={formatMessage({id: 'codeSentSMS', defaultMessage: 'Code sent via SMS'})}
          />
          <Field
            component={Radio}
            name="tfaEmail"
            radioValue={TfaType.EMAIL}
            id="tfaEmail"
            label={formatMessage({id: 'codeSentEMail', defaultMessage: 'Code sent via E-Mail'})}
          />
        </RadioGroup>
      </Col>
    </StyledRowLessPadBottom>
  )
}
