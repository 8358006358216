import {call, put, race, take, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { Customer} from "../types";

export interface Initialize{
  deviceId: number;
}

export interface IRace<T> {
  success: AppAction<T>;
  failure: any;
}

function* initialize(action: AppAction<Initialize>){
  try {
    yield put(Routines.initializeSubOrgs());
    const {success: suborgSuccess, failure: subOrgFailure}: IRace<Array<Customer>> = yield race({
      success: take(Routines.initializeSubOrgs.SUCCESS),
      failure: take(Routines.initializeSubOrgs.FAILURE)
    });
    yield put(Routines.initializeSubUnits());
    const {success: subUnitsSuccess, failure: subUnitsFailure} = yield race({
      success: take(Routines.initializeSubUnits.SUCCESS),
      failure: take(Routines.initializeSubUnits.FAILURE)
    });

  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
}

function* getUser(action: AppAction<Initialize>){
  try {
    const user = yield call(Api.getUser);
    yield put(Routines.initializeUser.success(user));
  }
  catch(e){
    yield put(Routines.initializeUser.failure());
  }
}

function* initializeSubOrgs(action: AppAction<string>){
  try{
    const subOrgs = yield call(Api.getSubOrgs);
    yield put(Routines.initializeSubOrgs.success(subOrgs));
  }
  catch(e){
    yield put(Routines.initializeSubOrgs.failure());
  }
}

function* initializeSubUnits(action: AppAction<any>){
  try{

    const subUnits = yield call(Api.getSubUnits);
    const billing = subUnits[0];

    let isBillAddrFilled:boolean;
    billing.firm.billingAddrName != null ? isBillAddrFilled = true : isBillAddrFilled = false;

    const paymentDetail = yield call(Api.getPaymentOptions, billing.custId, billing.parent);

    yield put(Routines.getPayment.success(paymentDetail));
    yield put(Routines.initializeSubUnits.success(billing));
    yield put(Routines.initializeBillingOptions.success({isBillAddrFilled, subUnits}));

  }
  catch(e){
    yield put(Routines.initializeSubUnits.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.initializeUser.TRIGGER, getUser);
    yield takeLatest(Routines.initializeSubOrgs.TRIGGER, initializeSubOrgs);
    yield takeLatest(Routines.initializeSubUnits.TRIGGER, initializeSubUnits);
}

export default initializationSaga;
