import React from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import { HorizontalRow } from '../styles';
import { getIconForType } from '../sensorType';
import AddIcon from "./addIcon";
import { StyledP } from './styles';
import { ILocalLearningSensor } from '../types';
import Loader from '@Elements/Loader';

const translatedText = defineMessages({
  addText: { id: 'addSensTooltip', defaultMessage: 'Legg til sensor' }
});

const NewSensors = styled<any>(HorizontalRow)<{successfullyAdded: boolean}>`
  justify-content: center;
  color: ${props =>
    props.successfullyAdded
      ? 'green'
      : props.theme.colors.textPrimary};
  padding: 10px;
  display: inline-flex;
  font-weight: bold;
  margin: 10px 0;
`;

const ExistingSensor = styled.p`
  color: ${props => props.theme.colors.textPrimary};
`;

interface LocalLearningSensorProps{
  existing: boolean;
  handleAdd?: (sensor: ILocalLearningSensor) => void;
  sensor: ILocalLearningSensor;
  successfullyAdded?: boolean;
}

const LocalLearningSensor = ({ existing, handleAdd, sensor, successfullyAdded }: LocalLearningSensorProps) => {

  const { formatMessage } = useIntl();

  if (existing) {
    return (
      <HorizontalRow
        style={{
          justifyContent: 'center',
          padding: '10px'
        }}
      >
        {getIconForType(sensor.type)}
        <ExistingSensor>{sensor.id}</ExistingSensor>
      </HorizontalRow>
    );
  }

  return (
    <NewSensors
      added={sensor.added}
      successfullyAdded={successfullyAdded}
    >
      {getIconForType(sensor.type)}
      <StyledP>{sensor.id}</StyledP>
      <StyledP>{sensor.time}</StyledP>
      {successfullyAdded ? null : sensor.pending &&
        !successfullyAdded
          ? <Loader />
          : <AddIcon
              tabIndex="0"
              onKeyDown={(event: KeyboardEvent) =>
                event.key === 'Enter' && handleAdd(sensor)
              }
              onClick={() =>
                handleAdd(sensor)
              }
              title={formatMessage(translatedText.addText)}
            />
      }
    </NewSensors>
  );
}

export default LocalLearningSensor;