import {call, put, takeLatest } from 'redux-saga/effects';

import {AppAction} from "../../../DefaultSettings/Store/types";

import * as Routines from '../routines';
import * as Api from '../api';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import {Customer, Dashboard} from "../../Models/Customer";
import { loadCache } from '../utils';



export interface PostalProps{
  pnr: string,
  isBilling: boolean
}

export interface CustomerViewProps{
  customerId: number,
}

export interface individualBillingProps{
  custId: number;
  value: boolean;
  custTree: number[];
}

export interface individualReferenceProps{
  custId: number,
  referenceVal: string,
  custTree: number[];
}



function* fetchCustomersForList(action: AppAction<string | undefined>){
  try{
    const dashboard: Dashboard = yield call(Api.getCustomersForList, 0);
    yield put(Routines.fetchCustomersForList.success(dashboard));
  }
  catch(e){
    console.log(e);
    yield feedbackFailure(e)
  }
}

function* toggleChildren(action: AppAction<Array<number>>){
  try{
    yield put(Routines.toggleChildren.success(action.payload));
  }
  catch(e){
    console.log(e);
    yield put(Routines.toggleChildren.failure());
  }
}

  function* getCustomerView(action: AppAction<CustomerViewProps>){
    try{
      const customerRedirect = yield call(Api.getCustomerView, action.payload.customerId);
      window.location.href = customerRedirect;    
    }
    catch(e){
      console.log(e);
      yield feedbackFailure(e)
    }
  }

  function* setSwitchValue(action: AppAction<individualBillingProps>){
    try{
      const value: boolean = yield call(Api.setIndividualBilling, action.payload);
      yield put(Routines.setSwitchValue.success({custTree: action.payload.custTree, custId: action.payload.custId, value}));
    }
    catch(e){
      console.log(e);
      yield feedbackFailure(e);
      yield put(Routines.setSwitchValue.failure());
    }
  }

  function* setAllowDeviceDeletes(action: AppAction<individualBillingProps>){
    try{
      const value: boolean = yield call(Api.setAllowDeviceDeletes, action.payload);
      yield put(Routines.setAllowDeviceDeletes.success({custTree: action.payload.custTree, custId: action.payload.custId, value}));
    }
    catch(e){
      console.log(e);
      yield feedbackFailure(e);
      yield put(Routines.setAllowDeviceDeletes.failure());
    }
  }

  function* setReference(action: AppAction<individualReferenceProps>){
    try{
      const {custId, referenceVal, custTree} = action.payload;
      const value: string = yield call(Api.setReference, {custId, referenceVal});
      yield feedbackSuccess("referenceSaved");
      yield put(Routines.setReference.success({custTree, custId, value}));
    }
    catch(e){
      console.log(e);
      yield feedbackFailure(e);
      yield put(Routines.setReference.failure());
    }
  }


function* customerSaga(){
  yield takeLatest(Routines.fetchCustomersForList.TRIGGER, fetchCustomersForList);
  yield takeLatest(Routines.toggleChildren.TRIGGER, toggleChildren);
  yield takeLatest(Routines.getCustomerView.TRIGGER, getCustomerView);
  yield takeLatest(Routines.setSwitchValue.TRIGGER, setSwitchValue);
  yield takeLatest(Routines.setAllowDeviceDeletes.TRIGGER, setAllowDeviceDeletes);
  yield takeLatest(Routines.setReference.TRIGGER, setReference);
}

export default customerSaga;
