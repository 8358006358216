import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Dashboard/INITIALIZE'
);
export const initializeAlarms: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Dashboard/INITIALIZE_ALARMS'
);
export const initializeReport: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Dashboard/INITIALIZE_REPORT'
);

// Report
export const fetchAlarms: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Dashboard/FETCH_ALARMS'
);
export const fetchReport: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Dashboard/FETCH_REPORT'
);
export const fetchAlarmHandleReport: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Dashboard/FETCH_ALARM_HANDLE_REPORT'
);
export const fetchAhpReport: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Dashboard/FETCH_AHP_REPORT'
);
