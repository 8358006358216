import React, { Component } from 'react';

export const createTabsComponent = ({
  StyledContainer = 'div',
  StyledTabContainer = 'div',
  StyledTab = 'div',
  StyledContent = 'div'
}) =>
  class Tabs extends Component {
    state = {
      activeTabIndex: this.getInitialIndex()
    };

    getInitialIndex() {
      const indexOrNull = React.Children.map(
        this.props.children,
        (child, index) => (!child ? null : index)
      );
      const firstIndex = indexOrNull.find(value => value !== null);
      return firstIndex;
    }

    // Tabs must be navigable by keyboard.

    getStyleProps() {
      const { children } = this.props;
      const { activeTabIndex } = this.state;
      const currentActive =
        children.length <= activeTabIndex ? 0 : activeTabIndex;
      return children[currentActive].props.style;
    }

    handleKeyboardNavigation = tabIndex => event => {
      event.preventDefault();
      if (
        event.key === 'Enter' ||
        event.key === ' ' ||
        event.key === 'Spacebar'
      ) {
        this.handleTabClick(tabIndex)();
      }
    };

    handleTabClick = tabIndex => () => {
      this.setState({ activeTabIndex: tabIndex });
    };

    renderTabs() {
      const { children } = this.props;
      const { activeTabIndex } = this.state;
      const currentActive =
        children.length <= activeTabIndex ? 0 : activeTabIndex;
      return React.Children.map(children, (child, index) =>
        !child ? null : (
          <StyledTab
            length={100/children.length}
            key={index}
            onClick={this.handleTabClick(index)}
            active={index === currentActive}
            tabIndex={index}
            onKeyPress={this.handleKeyboardNavigation(index)}
          >
            {child.props.renderTitle === undefined
              ? child.props.title
              : child.props.renderTitle()}
          </StyledTab>
        )
      );
    }

    renderContent() {
      const { children } = this.props;
      const { activeTabIndex } = this.state;
      const currentActive =
        children.length <= activeTabIndex ? 0 : activeTabIndex;
      return React.Children.count(children) < 2
        ? children
        : children[currentActive];
    }

    render() {
      return (
        <StyledContainer>
          <StyledTabContainer>{this.renderTabs()}</StyledTabContainer>
          <StyledContent {...this.getStyleProps()}>
            {this.renderContent()}
          </StyledContent>
        </StyledContainer>
      );
    }
  };

export default createTabsComponent;
