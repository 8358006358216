export enum SettingEnum{
    TRACKING = "tracking",
    TRACKING_MANUAL = "trackingManual",
    TRACKING_AUTO = "trackingAuto",
    TRACKING_OPTIONS = "trackingOptions",
    GEOFENCE = "geofence",
    INDOOR = "indoor",
    INDOOR_LOCATION = "indoorLocation",
    SOUND_LIGHT = "lightSound",
    LIGHT = "light",
    SOUND = "sound",
    ORGANIZATION = "org", 
    GENERAL = "misc",
    GENERAL_SETTINGS = "generalSettings",
    DEVICE_LOG = "deviceLogs",
    DELETE_DATA = "deleteDeviceData",
    POSITIONING = "devicePositioning",
    BATTERY = "batteryTreshold",
    ALARM_SETTINGS = "alarmSettings",
    MAN_DOWN_SETTINGS = "manDownSettings",
    LIGHT_SOUND_SETTINGS = "lightSoundSettings",
    HEART_RATE_BLOOD_PRESSURE = "heartRateBloodPressureSettings",
    INFO = "info",
    SENSOR = "sensor",
    GX8_SOUND = "gx8Sound",
    GX8_VOICE_PROMPT = "gx8VoicePrompt",
    GX8_LIGHT = "gx8Light",
    GX8_BATTERY = "gx8Battery",
    GX8_LOGS_REPORT = "gx8LogsReport",
    GX8_GENERAL = "gx8General",
    GX8_COMMUNICATION = "gx8communication",
    GX8_INFORMATION = "gx8Information",
    GX8_LOG = "gx8Log",
    EVENT_CONFIG = "eventConfig",
    FRIDGE_EVENT = "fridgeAlarm",
    WANDERER_EVENT = "wandererAlarm",
    NOT_BACK_IN_BED_EVENT = "notBackInBedAlarm",
    OUT_OF_BED_EVENT = "outOfBedAlarm",
    INACTIVITY_EVENT = "noActivityAlarm",
    EPILEPSY_EVENT = "epilepsyAlarm",
    CUSTOM_EVENT = "customAlarm",
    WALKTEST = "walktest",
    TWIN_DEVICE = "twinDevice",
    SIMULATE = "simulate",

    SENSOR_TEMP = "sensorTemp"
}
