import { Checkbox } from '@Elements/CheckboxFormik';
import { ISchedule } from '@Safemate/Settings/Sensor/types';
import { Field } from 'formik';
import React from 'react';
import {  useIntl } from 'react-intl';
import { DayWrap, Label } from './styles';

interface DayProps{
  day: ISchedule;
  field: string;
}

const Day = ({day, field}: DayProps) => {

  const { formatMessage } = useIntl();

  return (
    <DayWrap>
      <Label>
        {formatMessage({id: day.day.toLowerCase(), defaultMessage: day.day.toLowerCase()})}
      </Label>
      <Field
        value={day.active}
        name={field}
        id={field}
        component={Checkbox}
      />
    </DayWrap>
  );
}

export default Day;
