import {Reducer} from "redux";
import { Subscription, WebsocketState} from "../types";
import {StompSubscription} from "@stomp/stompjs";
import {SettingsActionTypes} from "@Safemate/Settings/Store/actionTypes";

const websocketState: WebsocketState = {
  client: null,
  subscriptions: new Map<string, StompSubscription>()
};

const websocket: Reducer<WebsocketState> = (state: WebsocketState = websocketState, action) => {
  switch (action.type) {
    case SettingsActionTypes.SET_WEBSOCKET_CLIENT:
      return {
        ...state,
        client: action.payload
      }

    case SettingsActionTypes.SET_WEBSOCKET_SUBSCRIPTION:
      const subscription: Subscription = action.payload;
      return {
        ...state,
        subscriptions : state.subscriptions.set(subscription.key, subscription.subscription)
      }

    default:
      return state;
  }
};

export default websocket;
