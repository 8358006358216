import {call, put, takeLatest} from 'redux-saga/effects';

import * as Routines from '../routines';
import * as Api from '../api';
import { OrganizationForm} from "../../Form/types";
import {feedbackFailure, feedbackSuccess} from "../../../Feedback/reducer";
import { AppAction } from '../../../Store/types';



function* saveForm(action: AppAction<any>){
  try{
    const { values, customers } = action.payload;
    const settings:OrganizationForm = values;
    const PPTID :string = values.pptyId;
    const response = yield call(Api.saveForm, {PPTID, settings});
    const subUnits = yield call(Api.getSubUnits);
    const sortedSubUnits = subUnits[0];
    yield put(Routines.initializeSubUnits.success(sortedSubUnits));
    yield feedbackSuccess("settingsSaved");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveForm.failure());
  } finally{

  }
}

function* fetchOrganisation(action: AppAction<number>){
  try {
    const subUnits = yield call(Api.getSubUnits);
    const sortedSubUnits = subUnits[0];
    const paymentDetail = yield call(Api.getPaymentOptions, sortedSubUnits.firm.custId, sortedSubUnits.firm.parent);
    const organisations = yield call(Api.getOrganisation, action.payload);

    yield put(Routines.selectCustomer.success(organisations[0]));
    yield put(Routines.getPayment.success(paymentDetail));
    yield put(Routines.initializeSubUnits.success(sortedSubUnits));
  }
  catch(e){
    yield put(Routines.fetchOrganisation.failure());
  }
}

function* getPostalCity(action: AppAction<string>){
  try{
    const postalCity = yield call(Api.getPostCity, action.payload);
    yield put(Routines.getPostCity.success(postalCity));
  }
  catch(e){
    yield put(Routines.getPostCity.failure());
  }
}

function* customerSaga(){
  yield takeLatest(Routines.saveForm.TRIGGER, saveForm);
  yield takeLatest(Routines.fetchOrganisation.TRIGGER, fetchOrganisation);
  yield takeLatest(Routines.getPostCity.TRIGGER, getPostalCity);

}

export default customerSaga;
