import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";

import { Header, StyledField, ComponentWrapper } from ".";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";
import styled from "styled-components";

const Suffix = styled.span`
  margin-left: 5px;
`;

const Radius = injectIntl(withFormWrapper<GeofenceValues, InjectedIntlProps>(({ intl: {formatMessage}, formik: { values: { geofence } } }) => {
  
  if(geofence.polygon || geofence.indoor || geofence.polygon === undefined)
    return null;
  
  return(
    <ComponentWrapper>
      <Header>{formatMessage({id: "radius", defaultMessage: "Radius"})}</Header>
      <div>
        <StyledField disabled={!geofence.editEnabled} type="number" name="geofence.radius" />
        <Suffix>{formatMessage({id: "meter", defaultMessage: "meter"})}</Suffix>
      </div>
    </ComponentWrapper>
  )
}))

export default Radius;
