import * as React from "react";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { getSensorOptions, mapConfig } from "../utils";
import { RootState } from "@Safemate/Settings/Store/types";
import { connect } from "react-redux";
import SettingTab from "@Safemate/Settings/Components/settingTab";
import { SettingEnum } from "@Safemate/Settings/settingEnum";
import SensorSelect from "../sensorSelect";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { delayOptions } from "../options";
import { TIME_OFFSET, status } from "@Elements/Form/utils";
import { FRIDGE_FUNCTION } from "@Safemate/Settings/Sensor/SensorFunctionEnum";
import { NoValidSensors } from "../components";
import { ISensorDTO } from "@Safemate/Settings/Sensor/types";
import { saveEventConfig } from "@Safemate/Settings/Store/routines";
import { AppState } from "@Safemate/Store/types";


interface FridgeProps{
  sensors: ISensorDTO[];
  dehaId: number;
  deviId: number;
  savedSensorId: number;
  saveEventConfig: Routine<ActionFunctionAny<Action<any>>>;
}

const Fridge = ({ sensors, savedSensorId, dehaId, deviId, saveEventConfig }: FridgeProps) => {

  const [ selectedSensor, setSelectedSensor ] = React.useState<ISensorDTO | null>(null);
  
  const mappedSensors = React.useMemo(() => {
    if(sensors.length > 0){
      return getSensorOptions(sensors, FRIDGE_FUNCTION.code);
    }
    return [];
  }, [sensors])
  
  React.useEffect(() => {
    if(savedSensorId){
      mappedSensors.map(sensor => {
        if(sensor.value.sensor.deseId === savedSensorId){
          setSelectedSensor(sensor.value);
        }
      })
    }else{
      if(mappedSensors.length > 0){
        setSelectedSensor(mappedSensors[0].value);
    }    
  }
  },[mappedSensors, savedSensorId])
  
  if(!sensors || mappedSensors.length === 0) return <NoValidSensors title="fridgeAlarm"/>

  if(!selectedSensor){
    setSelectedSensor(mappedSensors[0].value);
    return null;
  }

  return (
    <SettingTab
      dehaId={dehaId}
      deviId={deviId}
      settings={mapConfig(selectedSensor)}
      save={saveEventConfig}
      settingEnum={SettingEnum.FRIDGE_EVENT}
      header={{
        title: "fridgeAlarm",
        tooltip: "fridgeTabToolTipText",
        tooltipTitle: "fridgeAlarm",
        toggle: true,
        toggleValue: ["eventConfig", "active"],
        children: <SensorSelect  sensors={mappedSensors} sensor={selectedSensor} setSelected={setSelectedSensor}/>
      }}
    />
  )
}

const mapStateToProps = ({ settings: {sensor: { sensors, savedSensorId }, initialization: { deviId, dehaId }}}: AppState) => {
  return{
    sensors,
    savedSensorId,
    dehaId,
    deviId
  }
}

const mapDispatchToProps = {
  saveEventConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(Fridge);

export const FridgeAllowTimeOpen = 
  DropdownWrap(
    "allowedOpenTime", 
    {
      title: {id: "fridgeAllowParagraph", defaultMessage: "Tid tillatt åpen"},
      dataOptions: {
        options: delayOptions,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      prefix: "eventConfig",
      width: "250px"
    }
  );
