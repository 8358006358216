import React from 'react';
import SIcon from './SIcon';
import { FormattedMessage } from 'react-intl';

const DectAlarmWall = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M1.5 3.5h17c.8 0 1.5.7 1.5 1.5v10c0 .8-.7 1.5-1.5 1.5h-17C.7 16.5 0 15.8 0 15V5c0-.8.7-1.5 1.5-1.5zm0 1c-.3 0-.5.2-.5.5v10c0 .3.2.5.5.5h17c.3 0 .5-.2.5-.5V5c0-.3-.2-.5-.5-.5h-17zm16 7.3c0 1.2-1 2.2-2.2 2.2S13 13 13 11.8s1-2.2 2.2-2.2 2.3.9 2.3 2.2zm-1-4.6c0 .7-.6 1.2-1.2 1.2S14 7.9 14 7.2 14.6 6 15.2 6s1.3.6 1.3 1.2zM4 7.5h4c.3 0 .5.2.5.5s-.2.5-.5.5H4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5zm0 4h4c.3 0 .5.2.5.5s-.2.5-.5.5H4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5zm-1-2h6c.3 0 .5.2.5.5s-.2.5-.5.5H3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5z" />
    <title>
      <FormattedMessage
        id="dectMountedLabel"
        defaultMessage="Stasjonær DECT-enhet"
      />
    </title>
  </svg>
);

const Icon = props => <SIcon {...props} icon={DectAlarmWall} />;

export default Icon;
