import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import { Col } from "react-bootstrap";

import { Header, ComponentWrapper } from ".";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";
import Toggle from "@Elements/Switch/Toggle";

interface GeofenceStatusProps extends WrappedComponentProps{
}

const GeofenceStatus = injectIntl(withFormWrapper<GeofenceValues, GeofenceStatusProps>(({ intl: {formatMessage}, formik: { setFieldValue, values: { geofence } } }) => {
 
  if(geofence.id === 0)
    return null;

  return(
    <ComponentWrapper>
      <Header>{formatMessage({id: "status", defaultMessage: "Status"})}</Header>
      <Toggle
        onChange={(value: boolean) => {
          setFieldValue("geofence.enabled", value);
        }}
        checked={geofence.enabled}
        disabled={!geofence.editEnabled}
      />
    </ComponentWrapper>
  )
}))

export default GeofenceStatus;
