import * as React from "react";
import { FormikProps } from "formik";
import { injectIntl, WrappedComponentProps, MessageDescriptor } from "react-intl";
import withFormWrapper from "./formWrapper";
import { SettingDiv, SettingHeader, TitleWrap } from "./styles";
import InformationIcon from "@Icons/Information1";
import Tooltip from "@Elements/Tooltip";
import Toggle from "@Elements/Switch";

interface ToggleOption{
  prefix?: string;
  title?: MessageDescriptor;
  tooltip?: string;
  tooltipTitle?: MessageDescriptor;
}

interface ToggleProps<T> extends WrappedComponentProps{
  formik: FormikProps<T>; 
}

export function ToggleWrap(name: string, options: ToggleOption): React.FunctionComponent<any>{
    return withFormWrapper(
        injectIntl(({intl: {formatMessage}, formik: {values, setFieldValue, setFieldTouched}}: ToggleProps<any>) => {
          
          let value;
          let field = name;
          if(options.prefix){
            value = values[options.prefix][name];
            field = `${options.prefix}.${name}`;
          }
          else{
            value = values[name];
          }

          return(
            <SettingDiv key={name}>
              {options.title &&
                <SettingHeader>
                  <TitleWrap right>
                    {formatMessage(options.title)}
                  </TitleWrap>
                  {options.tooltip &&
                    <Tooltip
                      popoverId={`${options.title}-popover`}
                      labelId={options.tooltip}
                      defaultMessage={options.tooltip}
                      title={options.tooltipTitle ? formatMessage(options.tooltipTitle) : ""}
                    >
                        <InformationIcon size="medium" />
                    </Tooltip>
                  }
                </SettingHeader>}
                <Toggle
                  checked={value}
                  onChange={(value: boolean) => {
                    setFieldTouched(field);
                    setFieldValue(field, value)
                  }}
                />
            </SettingDiv>
          )
        })
    ) as React.FunctionComponent<any>
}