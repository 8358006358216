import {UserState} from "../types";
import {Reducer} from "redux";
import {getUser, getAllowDelete, getTfaRules, getPrivate, saveUser, getUserRoleWithCustomerName} from "../routines";

const userState: UserState = {
  user: {
    userId: 0,
    username: "",
    currentPassword: "",
    password: "",
    passwordClear: "",
    firstName: "",
    lastName: "",
    mobile: "",
    mailTo: "",
    languageCode: "",
    timezone: "",
    facebookUid: "",
    newsletter: false,
    proffwebDark: false,
    adminForCustId: 0,
    beta: false,
    trainingApprovedBy: 0,
    deleted: false,
    attemptsBeforeLockout: "",
    lastActivity: 0,
    tfaEmail: false,
    tfaEnabled: false,
    gdprPsAccepted: false,
    gdprPsDatetime: "",
    deactivated: false,
    tempDeactivated: false,
    deactivatedFrom: "",
    deactivatedTo: "",
    userType: 0,
    name: "",
    id: 0,
    timeZoneOffset: 0,
    active: false,
    trainingApprovedByName: "",
    coveredByTwoFactorAuthentication: false,
    email: "",
    existing: false,
    isNewUser: false,
    trained: false,
    zoneId: "",
    isExternal: false,
    emailAsUsername: false
  },
  allowDelete: "",
  tfaRules: "",
  privateUser: false,
  userWithRolesAndCustomers:{
    permanent: null,
    temporary: null
  }

};

const user: Reducer<UserState> = (state: UserState = userState, action) => {
  switch (action.type) {

    case saveUser.SUCCESS:
    case getUser.SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case getUserRoleWithCustomerName.SUCCESS:
      return {
        ...state,
        userWithRolesAndCustomers: action.payload
      };
    case getAllowDelete.SUCCESS:
      return {
        ...state,
        allowDelete: action.payload
      };

    case getTfaRules.SUCCESS:
      return {
        ...state,
        tfaRules: action.payload
      };

    case getPrivate.SUCCESS:
      return {
        ...state,
        privateUser: action.payload
      };

    default:
      return state;
  }
};

export default user;
