import React from 'react';

const Binocular = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="128px"
      height="128px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      {...props}
    >
      <path
        d="M113.752,48.533l0.01-0.027c0,0-8.442-11.558-21.801-10.889v-0.03c-13.712-18.856-24.282-2.286-24.282-2.286l0.018-0.035
                c-1.06-0.588-2.308-0.929-3.639-0.929c-1.322,0-2.561,0.336-3.617,0.916l0.025,0.048c0,0-10.57-16.57-24.283,2.286v0.03
                c-13.36-0.669-21.799,10.889-21.799,10.889C6.266,53.442,0.844,62.373,0.844,72.566c0,15.541,12.599,28.141,28.143,28.141
                c14.11,0,25.795-10.388,27.826-23.935c0,0,2.115,2.839,6.806,2.839c4.688,0,7.665-2.791,7.665-2.791
                c2.052,13.522,13.729,23.887,27.823,23.887c15.539,0,28.14-12.6,28.14-28.141C127.243,62.391,121.841,53.476,113.752,48.533z
                    M28.127,92.705c-10.65,0-19.282-8.633-19.282-19.282c0-10.648,8.633-19.282,19.282-19.282c10.649,0,19.283,8.634,19.283,19.282
                C47.41,84.072,38.778,92.705,28.127,92.705z M64.058,71.476c-3.865,0-6.998-3.136-6.998-7.001c0-3.864,3.133-6.999,6.998-6.999
                c3.867,0,7.001,3.135,7.001,6.999C71.059,68.34,67.923,71.476,64.058,71.476z M99.103,92.705c-10.649,0-19.283-8.633-19.283-19.282
                c0-10.648,8.634-19.282,19.283-19.282s19.284,8.634,19.284,19.282C118.387,84.072,109.752,92.705,99.103,92.705z"
      />
      <path
        d="M16.518,71.826c-0.611-1.617-0.97-3.265-1.141-4.906c-1.966,3.465-2.435,7.743-0.917,11.76
                c2.712,7.168,10.721,10.782,17.889,8.073c1.502-0.571,2.834-1.378,3.998-2.354C27.893,85.067,19.667,80.158,16.518,71.826z"
      />
      <path
        d="M89.08,71.826c-0.611-1.617-0.971-3.265-1.142-4.906c-1.965,3.465-2.434,7.743-0.915,11.76
                c2.709,7.168,10.721,10.782,17.888,8.073c1.5-0.571,2.836-1.378,4.001-2.354C100.456,85.067,92.227,80.158,89.08,71.826z"
      />
    </svg>
  );
};

export default Binocular;
