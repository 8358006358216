import * as React from "react";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps, useIntl } from "react-intl";
import { Formik, Field, Form } from "formik";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {Button, Col, Grid, Row} from "react-bootstrap";
import styled from "styled-components";

import { SettingEnum as ComponentSettingEnum } from "../../settingEnum";
import {AlarmSettingsForm,  HeartRateBloodPressureForm} from "../types";
import { DeviceComponents } from "../../tabDefinition";
import { DeviceHardwareEnum } from "../../../DeviceHardwareEnum";
import { FooterNoBorder, ButtonContainer, StyledForm } from "./styled";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../../Elements/Dropdown/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { confirmModal } from "./deleteData";
import Toggle from "@Elements/Switch/Toggle";
import { RadioGroup, Radio } from "../../../../Elements/RadioFormik";
import { CancelButton, SaveButton } from "../../../DefaultSettings/Settings/styled";
import { saveAlarmSettings } from "../../Store/routines";
import {
  StyledH3,
  StyledP, ToggleWrapper
} from "./heartRateBloodPressure";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import { AppState } from "@Safemate/Store/types";
import Tooltip from "@Elements/Tooltip";

interface AlarmSettingsProps extends WrappedComponentProps{
  dehaId: number;
  deviId: number;
  alarmSettings: AlarmSettingsForm;
  saveAlarmSettings: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: {alarmSettings}}}: AppState) => {
  return{
    alarmSettings,
    dehaId,
    deviId
  }
}

const mapDispatchToProps = {
  saveAlarmSettings
}

const StyledCol = styled(Col)`
  margin-bottom: 1em;
`;

const StyledGrid = styled(Grid)`
  width: 100%;
  .WatchOne{
    padding-left: 0px!important;
    margin-left: -15px;
  }
`;

export const TimeBtn = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};
  cursor: default;
  border-radius: 0px;
  padding-bottom: 42px;
  pointer-events: none;
`;

export const StyledPPadNoMargin = styled.p`
  padding-bottom: 10px;
  padding-left: 15px;
  border-bottom: 1px solid rgb(136, 136, 136);
  width: 100%;
`;

export const StyledH3PL = styled.h3`
  margin: 0;
  padding-left: 15px;
`;

export const TeamAlertHeader = styled.h3`
  margin: 0;
  padding-left: 15px;
  display: inline-flex;
  align-items: center;
`;

export const InputWrapperNoBorder = styled.div`
  float: right;
  display: flex;
  input{
    padding: 0 5px;
    border-radius: 0px;
  }
  @media (max-width: 991px) {
    float: none;
    margin-left: 15px;
    padding-bottom: 10px;
    width: 100%;
    
  }
  
`;

const AlarmSettings = connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(({ dehaId, deviId, intl: {formatMessage}, alarmSettings, saveAlarmSettings }: AlarmSettingsProps) => {

    let isWatchOne = "";
    const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
      ? DeviceComponents.get(dehaId)! 
      : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;
    
      if(dehaId == 11){
        isWatchOne = "WatchOne";
      }
    return(
      <Formik
        onSubmit={(values) => {
          saveAlarmSettings({deviceId: deviId, settings: values})}
        }
        enableReinitialize
        initialValues={alarmSettings}
      >    
        {({dirty, setValues}) => {
          return(
            <StyledForm>
              <h2>{formatMessage({id: "alarmSettings", defaultMessage: "Alarminnstillinger"})}</h2>
              <StyledGrid fluid>
                  {components.has(ComponentSettingEnum.ALARM_SETTINGS) 
                    && components.get(ComponentSettingEnum.ALARM_SETTINGS)?.map(comp => 
                      <StyledCol className={isWatchOne} md={12}>{comp}</StyledCol>)}
                </StyledGrid>
              <FooterNoBorder>
                <ButtonContainer disabled={!dirty}>
                  <CancelButton disabled={!dirty} onClick={() => setValues(alarmSettings)}>
                    {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
                  </CancelButton>
                  <SaveButton disabled={!dirty} type="submit">
                    {formatMessage({id: "save", defaultMessage: "Lagre"})}
                  </SaveButton>
                </ButtonContainer>
              </FooterNoBorder>
            </StyledForm>
          )}}
      </Formik>
    )
  })
)

export default AlarmSettings;

const Desc = styled.p`
  margin-bottom: 13px;
  color: #858C97;
`;


interface DehaFirmNumProps{
  fiwaNum: number;
  dehaId: number;
}

const mapDehaFirmNumPropsStateToProps = ({ settings: {device:{device: {fiwaNum, dehaId}}}}: AppState) => {
  return {
    fiwaNum,
    dehaId,
  }
}

export const ButtonBehaviour =
  connect(mapDehaFirmNumPropsStateToProps)(withFormWrapper<AlarmSettingsForm, DehaFirmNumProps>(({ formik: { setFieldValue, values: { buttonBehaviour } }, dehaId, fiwaNum }) => {

    const { formatMessage } = useIntl();
    const { Comp, func } = confirmModal();

      return(
        <React.Fragment>
          { ((dehaId === DeviceHardwareEnum.WATCH_TWO && fiwaNum > 1) || dehaId !== DeviceHardwareEnum.WATCH_TWO ) && <React.Fragment>
            <StyledH3>{formatMessage({id: "disableAlarmButton", defaultMessage: "Alarmknapp"})}</StyledH3>
            <ToggleWrapper>
              <Toggle
                onChange={(value: boolean) => {
                  if(value){
                    setFieldValue("buttonBehaviour", "2");
                  }
                  else{
                    func(true);
                  }
                }}
                checked={buttonBehaviour === "2"}
              />
            </ToggleWrapper>
            <Comp
              title={`${formatMessage({id: "deactivate", defaultMessage: "Deaktiver"})} ${formatMessage({id: "alarmButton", defaultMessage: "Skru Av Alarmknapp"})}`}
              body={
                <p>
                  <span>{formatMessage({id: "deactivateButtonDesc", defaultMessage: "Dette valget vil føre til at denne Safematen ikke lenger kan alarmeres av bruker. Er du sikker på at du vil gjøre dette?"})}</span>
                </p>
              }
              confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
              confirmFunc={() => {
                setFieldValue("buttonBehaviour", "1");
                func(false);
              }}
            />
            <StyledP>{formatMessage({id: "disableAlarmButtonDesc", defaultMessage: "Den fysiske alarm-knappen vil være deaktivert på denne enheten."})}</StyledP>
          </React.Fragment>}
        </React.Fragment>
      )
    }
  ))

const buttonSensitivityOpt = [
  {text: "1", value: "1"},
  {text: "2", value: "2"},
  {text: "3", value: "3"},
  {text: "4", value: "4"},
  {text: "5", value: "5"},
  {text: "6", value: "6"},
  {text: "7", value: "7"},
  {text: "8", value: "8"},
  {text: "9", value: "9"},
  {text: "10", value: "10"},
]



export const ButtonSensitivity = connect(mapDehaFirmNumPropsStateToProps)(
  withFormWrapper<AlarmSettingsForm, DehaFirmNumProps>(({formik: { values: { buttonSensitivity, buttonBehaviour }, setFieldValue },  dehaId, fiwaNum}) => {
    const { formatMessage } = useIntl();
    const options = JSON.parse(JSON.stringify(buttonSensitivityOpt));
    if(dehaId === DeviceHardwareEnum.TRIGGER_TWO_4G){
      options.pop();
    }
    return(
    <React.Fragment>
      <div style={{opacity: buttonBehaviour === "2" ? 1 : 0.5}}>
      <StyledH3PL>{formatMessage({id: "alarmSensitivity", defaultMessage: "Alarmsensitivitet"})}</StyledH3PL>
      <ToggleWrapper>
        <InputWrapperNoBorder>
          <Dropdown
            options={options}
            onChange={(opt) => {
              const value: string = (opt as Option).value;
              setFieldValue("buttonSensitivity", value);
            }}
            initial={buttonSensitivity}
            borderRadius={`0px`}
            disabled={ buttonBehaviour !== "2" }
          />
          <TimeBtn>
            {formatMessage({id: "seconds", defaultMessage: "Seconds"})}
          </TimeBtn>
        </InputWrapperNoBorder>
      </ToggleWrapper>
      <StyledPPadNoMargin>{formatMessage({id: "alarmSensitivityDesc", defaultMessage: "Hvor mange sekunder alarm-knappen må holdes inne før en alarm utløses."})}</StyledPPadNoMargin>
      </div>
    </React.Fragment>
  )
}))

export const DeliveryFormat = injectIntl(({intl: { formatMessage }}: WrappedComponentProps) => {
  return(
    <React.Fragment>
      <StyledH3PL>{formatMessage({id: "deliveryFormat", defaultMessage: "Leveringsformat"})}</StyledH3PL>
      <ToggleWrapper>
        <RadioGroup
          disabled={false}
        >
          <Field
            component={Radio}
            name="callAlert"
            radioValue="false"
            id="smsAlert"
            label={formatMessage({id: "smsAlerts", defaultMessage: "Bare SMS"})}
          />
          <Field
            component={Radio}
            name="callAlert"
            id="callAlert"
            radioValue="true"
            label={formatMessage({id: "callAlerts", defaultMessage: "Oppringing og SMS"})}
            outerdiv={{padding: "0"}}
          />
        </RadioGroup>
      </ToggleWrapper>
      <StyledPPadNoMargin></StyledPPadNoMargin>
    </React.Fragment>
  )
})

export const FallDetection = injectIntl(
  withFormWrapper<AlarmSettingsForm, WrappedComponentProps>(({intl: { formatMessage }, formik: { setFieldValue, values: { fallDetection, buttonBehaviour } }}) => {
    const { Comp, func } = confirmModal();
    return(
      <React.Fragment>
        <div style={{opacity: buttonBehaviour === "2" ? 1 : 0.5}}>
        <StyledH3PL>{formatMessage({id: "fallDetection", defaultMessage: "Man down"})}</StyledH3PL>
        <ToggleWrapper>
          <Toggle
            onChange={(value: boolean) => {
              if(value){
                func(true);
              }
              else{
                setFieldValue("fallDetection", value);
              }
            }}
            checked={fallDetection}
            disabled={ buttonBehaviour !== "2" }
          />
        </ToggleWrapper>
        <Comp
          title={`${formatMessage({id: "activate", defaultMessage: "Aktiver"})} ${formatMessage({id: "fallDetection", defaultMessage: "Man down"})}`}
          body={
            <p>
              <span>{formatMessage({id: "fallDetectionDesc", defaultMessage: "Vil du aktivere man down for denne enheten?"})}</span>
            </p>
          }
          confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
          confirmFunc={() => {
            setFieldValue("fallDetection", true);
            func(false);
          }}
        />
        <StyledPPadNoMargin></StyledPPadNoMargin>
        </div>
      </React.Fragment>
    )
  })
)

export const SilentAlarm =
  connect(mapDehaFirmNumPropsStateToProps)(withFormWrapper<AlarmSettingsForm, DehaFirmNumProps>(({ formik: { setFieldValue, values: { silentAlarm, voicePromptSosAlarm, buttonBehaviour } }, dehaId, fiwaNum }) => {

      const { formatMessage } = useIntl();
      const { Comp, func } = confirmModal();

      return(
        <React.Fragment>
          { ((dehaId === DeviceHardwareEnum.WATCH_TWO && fiwaNum > 1) || dehaId !== DeviceHardwareEnum.WATCH_TWO ) && <React.Fragment>
            <div style={{opacity: buttonBehaviour === "2" ? 1 : 0.5}}>
                <React.Fragment>
                  <StyledH3PL>
                    {formatMessage({id: "silentAlarm", defaultMessage: "Lydløs alarm"})}
                  </StyledH3PL>
                  <ToggleWrapper>
                    <Toggle
                      onChange={(value: boolean) => {
                        if(value){
                          if( voicePromptSosAlarm && (dehaId !==  DeviceHardwareEnum.TRIGGER_TWO && dehaId !== DeviceHardwareEnum.WATCH_TWO && dehaId !== DeviceHardwareEnum.WATCH_FIVE) ){
                            func(true);
                          } else {
                            setFieldValue("silentAlarm", "1");
                          }
                        } else {
                          setFieldValue("silentAlarm", "0");
                        }
                      }}
                      checked={silentAlarm === "1"}
                      disabled={ buttonBehaviour !== "2" }
                    />
                    <Comp
                      title={`${formatMessage({id: "activate", defaultMessage: "Aktiver"})} ${formatMessage({id: "silentAlarm", defaultMessage: "Lydløs alarm"})}`}
                      body={
                        (dehaId !== DeviceHardwareEnum.WATCH_TWO && dehaId !== DeviceHardwareEnum.WATCH_FIVE && dehaId !== DeviceHardwareEnum.TRIGGER_TWO_4G) ? <p>
                          <span>{formatMessage({id: "silentAlarmDisableWarn", defaultMessage: "For å sikre lydløs alarm vil talemeldinger ved alarm bli avskrudd."})}</span>
                        </p> : ``
                      }
                      confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
                      confirmFunc={() => {
                        setFieldValue("silentAlarm", "1");
                        func(false);
                      }}
                    />
                  </ToggleWrapper>

                </React.Fragment>
                <StyledPPadNoMargin></StyledPPadNoMargin>
            </div>
          </React.Fragment>}
        </React.Fragment>
      )
    }
  ))

interface SOSCancellationProps{

}

export const SOSCancellation = withFormWrapper<AlarmSettingsForm, SOSCancellationProps>(({formik: { setFieldValue, values: { sosCancellation, buttonBehaviour, silentAlarm, voicePromptSosAlarm }}}) => {
  const hide = silentAlarm === "1" ? false : true;
  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      {hide && <div style={{opacity: (buttonBehaviour !== "2" || silentAlarm === "1" ) ? 0.5 : 1}}>
      <StyledH3PL>{formatMessage({id: "sosCancellation", defaultMessage: "Alarm kansellering"})}</StyledH3PL>
      <ToggleWrapper>
        <Toggle
          onChange={(value: boolean) => {
            setFieldValue("sosCancellation", value);
            if(value){
              setFieldValue("voicePromptSosAlarm", value);
            }
          }}
          checked={sosCancellation}
          disabled={ buttonBehaviour !== "2" || silentAlarm === "1" }
        />
      </ToggleWrapper>
      <StyledPPadNoMargin></StyledPPadNoMargin>
      </div>}
    </React.Fragment>
  )
})

export const T4Vibration = withFormWrapper<AlarmSettingsForm, {}>(({formik: { setFieldValue, values: { vibration }}}) => {

  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      <StyledH3PL>{formatMessage({id: "vibration", defaultMessage: "Vibration"})}</StyledH3PL>
      <ToggleWrapper>
        <Toggle
          onChange={(value: boolean) => {
            setFieldValue("vibration", value);
          }}
          checked={vibration}
        />
      </ToggleWrapper>
      <StyledPPadNoMargin></StyledPPadNoMargin>
    </React.Fragment>
  )
})

interface VoiceAlarmProps{

}

export const VoicePromptSosAlarm = withFormWrapper<AlarmSettingsForm, VoiceAlarmProps>(({ formik: { setFieldValue, values: { voicePromptSosAlarm, buttonBehaviour, silentAlarm, sosCancellation } } }) => {

  const { formatMessage } = useIntl();
  const hide = silentAlarm === "1"  ? false : true;
  return(
    <React.Fragment>
      {hide && <div style={{opacity: (buttonBehaviour !== "2" || silentAlarm === "1" || sosCancellation ) ? 0.5 : 1}}>
      <StyledH3PL>{formatMessage({id: "undoVoiceMessage", defaultMessage: "Talemelding ved alarm"})}</StyledH3PL>
      <ToggleWrapper>
        <Toggle
          onChange={(value: boolean) => {
            setFieldValue("voicePromptSosAlarm", value);
          }}
          checked={voicePromptSosAlarm}
          disabled={ (buttonBehaviour !== "2" || silentAlarm === "1" || sosCancellation ) }
        />
      </ToggleWrapper>
      <StyledPPadNoMargin>{formatMessage({id: "undoVoiceMessageDesc", defaultMessage: "Når en alarm har blitt utløst, vil enhet spille av en melding som lar bærer avbryte alarmen."})}</StyledPPadNoMargin>
      </div>}
    </React.Fragment>
  )
});


export const HeartRateBloodPressureSetting =
  withFormWrapper<HeartRateBloodPressureForm, {}>(({ formik: { setFieldValue, values: { heartRateBloodPressureSetting }}}) => {
      const { formatMessage } = useIntl();
      return(
        <React.Fragment>
          <StyledH3PL>{formatMessage({id: "activateHealthMeasurements", defaultMessage: "Activate health measurements"})}</StyledH3PL>
          <ToggleWrapper>
            <Toggle
              onChange={(value: boolean) => {
                setFieldValue("heartRateBloodPressureSetting", value);
              }}
              checked={heartRateBloodPressureSetting}
            />
          </ToggleWrapper>
          <StyledPPadNoMargin></StyledPPadNoMargin>

        </React.Fragment>
      );
    }
  );

const mapStateToTeamAlertProps = ({ settings: {device: { device: { deviceSerialNumber }}}}: AppState) => {
  return {
    deviceSerialNumber
  }
}
interface TeamAlertProps{
  deviceSerialNumber: string;
}

export const TeamAlert = connect(mapStateToTeamAlertProps)(withFormWrapper<AlarmSettingsForm, TeamAlertProps>(({deviceSerialNumber, formik: { setFieldValue, values: { teamAlert, silentAlarm, teamAlertLearnUrl }}}) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();

  return(
    <React.Fragment>
      <TeamAlertHeader>
        {formatMessage({id: "teamAlert", defaultMessage: "Kollegavarsling"})}
        &nbsp;
        {teamAlertLearnUrl &&
        <a target="_blank" href={teamAlertLearnUrl}><InformationIcon size="medium" /></a>}
      </TeamAlertHeader>
      <ToggleWrapper>
        <Toggle
          onChange={(value: boolean) => {
            if(value && silentAlarm === "0"){
              func(true);
            }
            else{
              setFieldValue("teamAlert", value);
            }
          }}
          checked={teamAlert}
        />
      </ToggleWrapper>
      <StyledPPadNoMargin></StyledPPadNoMargin>
      <Comp
        title={`${formatMessage({id: "activate", defaultMessage: "Aktiver"})} ${formatMessage({id: "teamAlert", defaultMessage: "Kollegavarsling"})}`}
        body={
          <p>
            <span>{`${formatMessage({id: "teamAlertSilentAlarmWarn", defaultMessage: "Vær oppmerksom på at lydløs alarm i øyeblikket er avskrudd for"})} ${deviceSerialNumber}`}</span>
          </p>
        }
        confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
        confirmFunc={() => {
          setFieldValue("teamAlert", true);
          func(false);
        }}
      />
    </React.Fragment>
  )
}))
