import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { injectIntl, WrappedComponentProps } from "react-intl";
import styled from "styled-components";



import confirmModal from "../Modals/confirmModal";
import { deleteUser } from "./Store/routines";

const DeleteButton = styled(Button)`
  margin-left: 5px;
  background: red;
`;

interface FooterProps extends WrappedComponentProps{
  allowDelete: string;
  email: string;
  dirty: boolean;
  isPrivate: boolean;
  deleteUser: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchFromState = {
  deleteUser
}

const Footer = connect(null, mapDispatchFromState)(
  injectIntl(({ intl: { formatMessage }, allowDelete, deleteUser, email, isPrivate, dirty}: FooterProps) => {
    const {Comp, func} = confirmModal();
    return(
      <Row>
        <Col md={12}>
          <Button type="submit" disabled={!dirty}>
            {formatMessage({id: "save", defaultMessage: "Lagre"})}
          </Button>
          <DeleteButton onClick={() => func(true)}>
            {formatMessage({id: "deleteMyPage", defaultMessage: "Slette Bruker"})}
          </DeleteButton>
        </Col>
        <Comp
          title={`${formatMessage({id: "deleteSelf", defaultMessage: "Slett bruker"})}`}
          body={
            <p>
              {!allowDelete
                ? <span>{formatMessage({id: "deleteSelfDesc", defaultMessage: "Er du sikker på at du vil slette denne brukeren?"})}</span>
                :  allowDelete != "UserStillHasDevicesConnected" ? 
                  <span>
                    {`
                      ${formatMessage({id: "theUser", defaultMessage: "Brukeren"})}
                      ${email}
                      ${formatMessage({id: "isTheLastAdminFor", defaultMessage: "er den siste administratoren i"})}
                      ${allowDelete}
                      ${formatMessage({id: "andCantBeDeleted", defaultMessage: "og kan derfor ikke slettes"})}
                    `}
                  </span>
                   : <span>{formatMessage({id: "userHasDevicesConnected", defaultMessage: "Denne brukeren har fremdeles tilkoblede enheter."})}</span>
              } 
            </p>
          }
          info={!!allowDelete}
          confirmText={formatMessage({id: "delete", defaultMessage: "Slette"})}
          confirmFunc={() => {
            deleteUser();
            func(false);
          }}
        />
      </Row>
    )
  })
)

export default Footer;