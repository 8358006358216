import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Col, Glyphicon, Row } from "react-bootstrap";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import moment, { Moment } from "moment";
import { AlarmFilterEnum } from "../../DeviceList/AlarmFilterEnum";
import { FilterAlarm, filterAlarm, SelectDate, selectDate } from "../Store/actions";
import { getTransactionsForDate, getTransactionsForAll, getAllDevices } from "../Store/routines";
import { AppState } from "@Safemate/Store/types";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { Option } from "@Elements/Dropdown/types";
import { getTransactionFilter } from "../utils";
import { Wrapper as DateWrapper } from "@Elements/Datepicker/styles";
import { MapType } from "..";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";

const DropWrap = styled.div`
  width: 180px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const StyledDateWrapper = styled<any>(DateWrapper)`
  margin-right: 15px;
  @media (max-width: 600px) {
    display:flex;
  }
`;

const DateHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 185px;
  @media (max-width: 600px) {
    .SingleDatePickerInput{
      display: flex;
    }
    .SingleDatePickerInput_calendarIcon{
      padding-left: 0px!important;
      margin-left: 0px!important;
    }
    .DateInput{
      width: 110px!important;
    }
  }
`;

const StyledDateInput = styled.input`

  background-color: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
  width: inherit !important;
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius};
  padding: 9px;
`;

interface FilterProps{
  alarmFilter: AlarmFilterEnum[];
  filterAlarm: FilterAlarm;
  selectDate: SelectDate;
  getTransactionsForDate: Routine<ActionFunctionAny<Action<any>>>;
  getAllDevices: Routine<ActionFunctionAny<Action<any>>>;
  datesWithTransactions: number[];
  fromDate: Moment;
  toDate: Moment;
  dehaId: number;
  id: number;
  mapType: MapType;
}

const mapStateToProps = ({ transactionLog: { device: { id, dehaId }, transaction: { alarmFilter, datesWithTransactions, fromDate, toDate}}}: AppState) => {
  return{
    alarmFilter,
    datesWithTransactions,
    fromDate,
    toDate,
    dehaId,
    id
  }
}

const mapDispatchToProps = {
  filterAlarm,
  selectDate,
  getTransactionsForDate,
  getAllDevices
}

const Filter = connect(mapStateToProps, mapDispatchToProps)(({ mapType, alarmFilter, selectDate, filterAlarm, getTransactionsForDate, getAllDevices, datesWithTransactions, fromDate, toDate, dehaId, id }: FilterProps) => {

  const { formatMessage } = useIntl();

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const logPermission = permissions.get(PolicyNameEnum.DEVICE_TRANSACTION_LOG).generalPermissions.all
  
  React.useEffect(() => {
    if(mapType === MapType.SINGLE){
      getTransactionsForDate({
        deviceId: id,
        fromDate: fromDate.format("DD-MM-YYYYTHH:mm"),
        toDate: toDate.format("DD-MM-YYYYTHH:mm")
      })
    }
  }, [fromDate, toDate, alarmFilter])

  if(!logPermission) return null;

  return(
    <>
      <div>
      <label style={{textTransform: "capitalize"}} htmlFor="from-date">{formatMessage({id: "from", defaultMessage: "Fra"})}</label>
      <div>
      <StyledDateInput 
        id="from-date"
        onChange={(e) => {
            const date: Moment = moment(e.target.value)
            selectDate(date, toDate);
        }}
        value={fromDate.format("YYYY-MM-DDTHH:mm")} 
        aria-label="Date and time" 
        type="datetime-local" 
      />
      </div>
      </div>
      <div style={{margin: "0px 5px"}}>
      <label style={{textTransform: "capitalize"}}  htmlFor="to-date">{formatMessage({id: "to", defaultMessage: "Til"})}</label>
      <div>
      <StyledDateInput 
        id="to-date"
        onChange={(e) => {
            const date: Moment = moment(e.target.value)
            selectDate(fromDate, date);
        }}
        value={toDate.format("YYYY-MM-DDTHH:mm")} 
        aria-label="Date and time" 
        type="datetime-local" 
      />
      </div>
      </div>
      <DropWrap>
        <label style={{textTransform: "capitalize"}}>{formatMessage({id: "filter", defaultMessage: "Filter"})}</label>
        <Dropdown
          multi
          initial={alarmFilter}
          options={getTransactionFilter(dehaId, formatMessage)}
          title={formatMessage({id: "selectAlarmFilter", defaultMessage: "Alle tilstander"})}
          onChange={option => {
            const selected = (option as Option[]).map(item => item.value);
            filterAlarm(selected)
            if(mapType === MapType.ALL){
              getAllDevices();
            }
          }}
        />
      </DropWrap>
    </>
  )
})

export default Filter;
