import { combineReducers } from 'redux';

import settings from "./settings";
import customer from "./customer";
import deviceHardware from "./deviceHardware";
import initialization from "./initialization";
import firmware from "./firmware";
import {RootState} from "../types";

export default combineReducers<RootState>({
  settings,
  customer,
  deviceHardware,
  initialization,
  firmware,
});
