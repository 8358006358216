import styled from 'styled-components';
import { mix, rgba } from 'polished';

const Tab = styled.div`
  padding: 15px 5px;
  text-decoration: none;
  border: ${props => props.theme.colors.border};
  background: ${props =>
    props.active
      ? props.theme.colors.backgroundPrimary
      : mix(
          0.5,
          props.theme.colors.backgroundPrimary,
          props.theme.colors.backgroundSecondary
        )};
  color: ${props =>
    props.active
      ? props.theme.colors.textFocus
      : rgba(props.theme.colors.textFocus, 0.75)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 2em;
  cursor: pointer;
  margin-right: 5px;
  text-transform: uppercase;
  width: ${props => `${props.length}%`};
  text-align: center;
  ${props =>
    props.active &&
    `border-bottom: 1px solid ${props.theme.colors.backgroundPrimary}`};

  & svg {
    transition: none;
    fill: ${props =>
      props.active
        ? props.theme.colors.textFocus
        : rgba(props.theme.colors.textFocus, 0.75)};
  }

  @media (max-width: 992px) {
    font-size: 10px;
    padding: 15px 10px;
  }
`;

export default Tab;
