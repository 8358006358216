import * as React from "react";
import {connect} from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { useIntl } from "react-intl";
import styled from "styled-components";

import {initialize} from "./Store/routines";
import { Customer} from "./Store/types";
import Form from "./Form";
import Spinner from "../Spinner";
import {Frame} from "../styles";
import SafemateHeader from "@Safemate/Header";
import { AppState } from "@Safemate/Store/types";

interface SubUnitsProps {
  init: Routine<ActionFunctionAny<Action<any>>>;
  customer: Customer;
  initialized: boolean;
}

const Wrap = styled.div`
  position: relative;
`

// Outer component responsible for loading initial data.
const Billing = ({init, initialized, customer}: SubUnitsProps) => {

  // On mount
  React.useEffect(() => {
    init();
  }, []);

  const { formatMessage } = useIntl();

  if(!initialized)
    return (
      <Wrap>
        <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
      </Wrap>
    )

  return(
    <div>
      <SafemateHeader
        margin="15px"
        icon={null}
        title={formatMessage({id: "Fakturainformasjon", defaultMessage: "Fakturainformasjon"})}
        postfix={customer.name}
      />
      <Frame>
        <Form/>
      </Frame>
    </div>
  )
};

const mapStateToProps = ({billing: {customer: {customer}, initialization: {initialized}}}: AppState) => {
  return{
    initialized,
    customer,
  }
};

const mapDispatchToProps  = {
  init: initialize
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
