import { AppState } from "@Safemate/Store/types";

export const getDevices = ({ deviceList:{device: {devices}} }: AppState) => devices;

export const getFilters = ({deviceList:{filter}}: AppState) => filter;

export const getLastModifiedTime = ({deviceList:{device: { lastModifiedTime }}}: AppState) => lastModifiedTime;

export const getDeviceTypes = ({deviceList:{device: { deviceTypes }}}: AppState) => deviceTypes;

export const getTwinSuffix = ({ deviceList:{initialization: { twinSuffix } }}: AppState) => twinSuffix;

export const getPartnerDeviceList = ({ deviceList:{device: { partnerDeviceList } }}: AppState) => partnerDeviceList;