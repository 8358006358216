import {AppAction, Language, MarketSetting} from "../types";
import * as Api from "../api";
import * as Routines from "../routines";
import { ProSettings } from "@Safemate/Model/ProSettings";
import { call, put, takeLatest } from "redux-saga/effects";
import { MarketEnum } from "@Safemate/MarketEnum";
import {getMarketSetting} from "../api";

export function* initializeSettings(action: AppAction<null>){
  try{
    const settings: ProSettings = yield call(Api.getSettings);
    yield put(Routines.getSettings.success(settings));
  }
  catch(e){
    yield put(Routines.getSettings.failure(e));
  }
}

export function* initializeMarket(action: AppAction<null>){
  try{
    const market: MarketEnum = yield call(Api.getMarket);
    yield put(Routines.getMarket.success(market));
  }
  catch(e){
    yield put(Routines.getMarket.failure(e));
  }
}

export function* initializeMarketSetting(action: AppAction<null>){
  try{
    const marketSetting: MarketSetting = yield call(Api.getMarketSetting);
    yield put(Routines.getMarketSetting.success(marketSetting));
  }
  catch(e){
    yield put(Routines.getMarketSetting.failure(e));
  }
}

export function* initializeLanguages(action: AppAction<null>){
  try{
    const languages: Language[] = yield call(Api.getLanguages);
    yield put(Routines.getLanguages.success(languages));
  }
  catch(e){
    yield put(Routines.getLanguages.failure(e));
  }
}

function* settingSaga(){
  yield takeLatest(Routines.getSettings.TRIGGER, initializeSettings);
  yield takeLatest(Routines.getMarket.TRIGGER, initializeMarket);
  yield takeLatest(Routines.getMarketSetting.TRIGGER, initializeMarketSetting);
  yield takeLatest(Routines.getLanguages.TRIGGER, initializeLanguages);
}

export default settingSaga;
