import * as React from "react";
import styled from "styled-components";
import { Field, FastField } from "formik";
import Radius from "./radius";
import ExistingGeofence from "./existingGeofence";
import { Row, Col, Button } from "react-bootstrap";
import TimeControls from "./timeControls";
import GeofenceStatus from "./status";
import Save from "./save";
import TimeToggle from "./timeToggle";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";
import Name from "./name";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { Geofence, GlobalGeofence } from "../types";
import { connect } from "react-redux";
import { Collapse } from "react-collapse";
import { isSaveNeeded, defaultFence } from "../utils";
import TypeSelector from "./typeSelector";
import { setGeofenceType, SetGeofenceType, setControlState, SetControlState, setRefresh, SetRefresh } from "../../Store/actions";
import { GeofenceTypeEnum } from "../GeofenceTypeEnum";
import { v4 } from "uuid";
import NewName from "./newName";
import GlobalSettings from "./globalSettings";
import Share, {DropdownWrapper} from "./share";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import {Customer} from "@Safemate/AddSafemate/Store/types";
import {Option} from "@Elements/Dropdown/types";
import Dropdown from "@Elements/Dropdown/Dropdown";
import Circle from "@Icons/geofence/GeofenceCircle";
import GeofencePolygon from "@Icons/geofence/GeofencePolygon";
import Wifi from "@Icons/Wifi";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";


export const U = styled.u`
  color: ${props => props.theme.colors.accent};
  font-size: 14px;
`;

export const Header = styled.label`
  width: 70px;
  text-transform: capitalize;
`;

export const Type = styled.div<{status: boolean}>`
  font-weight: bold;
  font-size: 24px;
  display: flex;
  align-items: flex-start;
  text-transform: capitalize;
  cursor: pointer;
  svg {
    margin-right: 5px;
    stroke-width: 5px;
    fill: ${props => props.status ? "green" : "red"};
    stroke:  ${props => props.status ? "green" : "red"};
    height: 24px;
    width: 24px;
  }
`;

export const HeaderWidthFull = styled.label`
  width: 100%;
  text-transform: capitalize;
`;

export const NameWrapper = styled<any>(Header)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
`;

export const PaddedField = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;

  input[type=number] {
    -moz-appearance:textfield;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InheritedGeofenceDiv = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const StyledField = styled(FastField)`
  width: 120px;
  height: 40px;
  font-size: 16px;
  background: none;
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius};
  color: ${props => props.theme.colors.textPrimary};
  outline: none;
  padding: 0 15px;
  transition: 0.3s;
`;

export const TitleField = styled<any>(StyledField)`

  width: 75%;
  text-align: center;

`

export const CapitalizedWrapper = styled(Row)`
  text-transform: capitalize; 
`;

export const ComponentWrapper = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: center;
`;

export const ButtonsContainer = styled.div`
  text-align: center;
`;

export const StyledButton = styled(Button)`
  transition: all 300ms;
  width: 180px;
`;

export const ButtonWrapper = styled.div`
  padding: 7.5px;
`;

export const ShiftedDiv = styled.div`
  margin-left: -30px;
  .ReactCollapse--collapse {
    transition: height 150ms;
    width: 100%;
  }
`;

export const FenceContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const GeofenceTab = styled.div`
  margin: 5px 0;
  border: ${props => props.theme.colors.border};
  padding: 7.5px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
`;

export const TabHeader = styled.div<{open?: boolean}>`
  display: flex;
  width: 100%;
  align-items: center;
  transition: padding 150ms;
  ${props => props.open &&     `
      padding-bottom: 7.5px;
      border-bottom: ${props.theme.colors.border};
    `
  }
`;

export const TabStatus = styled.span<{status: boolean}>`
  color: ${props => props.status ? "green" : "red"};
`;

export const StatusIcon = styled.b`
  font-size: 28px;
  padding-right: 5px;
`;

export const CloseIcon = styled.span`
  margin-left: auto;
  font-size: 25px;
  cursor: pointer;
`;

export const NewFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 15px 0px;
`;

export const NewHeader = styled.span`
  font-weight: bold;
  padding: 10px 0px;
  font-size: 18px;
`;

const Overlay = styled.div`
  padding: 10px;
`;

export enum ControlState{
  DEFAULT,
  NEW,
  REUSE,
  EDIT
}

const mapStateToProps = ({ appData: { isPrivate }, settings: {geofence: { geofence, initialFences, controlState, globalGeofence, indoorLocations, customers }, device: { device: { ownedByCustomer }}, tracking: {autoTracking, disablePositioning}, device: { device: { deviId }}}}: AppState) => {
  return{
    geofences: geofence,
    globalGeofence,
    initialFences,
    deviId,
    controlState,
    autoTracking,
    disablePositioning,
    indoorLocations,
    customers,
    ownedByCustomer,
    isPrivate
  }
}

const mapDispatchToProps = {
  setGeofenceType,
  setControlState,
  setRefresh
}

interface ControlsProps extends WrappedComponentProps{
  geofences: Geofence[];
  globalGeofence: GlobalGeofence[];
  initialFences: Geofence[];
  deviId: number;
  controlState: ControlState;
  setGeofenceType: SetGeofenceType;
  setControlState: SetControlState;
  setRefresh: SetRefresh;
  autoTracking: boolean;
  disablePositioning: boolean;
  indoorLocations: IndoorLocation[];
  customers: Customer[];
  ownedByCustomer: number;
  isPrivate: boolean;
}

const Controls = connect(mapStateToProps, mapDispatchToProps)(injectIntl(withFormWrapper<GeofenceValues, ControlsProps>(
  ({ isPrivate, formik: { values: { geofence }, setFieldValue, submitForm }, intl: { formatMessage }, geofences, globalGeofence, setGeofenceType, setControlState, setRefresh, controlState, deviId, autoTracking, disablePositioning, indoorLocations, customers, ownedByCustomer }) => {

    React.useEffect(() => {
      }, [geofences, globalGeofence, indoorLocations, customers])

    const customerMap: Map<number, Customer> = React.useMemo(() => {
      let customerMap: Map<number, Customer> = new Map<number, Customer>();
      customers.map((customer, index) => {
        customerMap.set(customer.custId, customer);
      });
      return customerMap
    }, [customers]);

    const mappedIndoorLocations: Option[] = React.useMemo(() => {
      return indoorLocations.map((indoorLocation, index) => {
        return{
          text: indoorLocation.custId > 0 && customerMap.get(indoorLocation.custId) ? (indoorLocation.name + " (" + customerMap.get(indoorLocation.custId).firmName + ")") : indoorLocation.name,
          value: indoorLocation.inloId
        }
      })
    }, [indoorLocations]);

    const isDefaultOrEdit = ControlState.DEFAULT === controlState || ControlState.EDIT === controlState;
    const isReusable = globalGeofence.length > 0;

    const filterIndoorLocations = (value:string) => {
      const valNum = value.split(',').map(function(item) {
        return parseInt(item, 10);
      });
      setFieldValue("geofence.inloIds", valNum);
    }

    let sharedGeofenceFlag = false;

    return(
      <ShiftedDiv>
        <Collapse isOpened={isDefaultOrEdit}>
        <FenceContainer>
          {geofences.map(fence => {
            const isOpen = controlState === ControlState.EDIT && geofence.id === fence.id;
            if( fence.sharedCustId > 0 ){
              sharedGeofenceFlag = true;
            }
            return(
              <GeofenceTab key={fence.id} onClick={() => {
                if(!isOpen){
                  setControlState(ControlState.EDIT)
                  setFieldValue("geofence", fence);
                }
              }}>
                <TabHeader open={isOpen}>
                  <TabStatus status={fence.enabled}>
                    { fence.circle && <Type status={fence.enabled}><Circle/></Type> }
                    { fence.polygon && <Type status={fence.enabled}><GeofencePolygon/></Type> }
                    { fence.indoor && <Type status={fence.enabled}><Wifi /></Type> }
                  </TabStatus>


                  {isOpen ? <Name/> : <span>{fence.name}</span>}
                  {isOpen && 
                    <CloseIcon onClick={() => {
                      setControlState(ControlState.DEFAULT)
                      setFieldValue("geofence", defaultFence());
                    }}>
                      &times;
                    </CloseIcon>}
                </TabHeader>
                <Collapse style={{width: "100%"}} isOpened={isOpen}>
                  <GeofenceStatus/>
                  { fence.indoor &&
                    <NewFieldWrapper>
                      <label>{formatMessage({id: "selectIndoorLocationGeofence", defaultMessage: "Wifi location"})}</label>
                      <DropdownWrapper>
                        <Dropdown
                          multi
                          options={mappedIndoorLocations}
                          onChange={option => {
                            const value = (option as Option[]).map(item => item.value);
                            filterIndoorLocations(value.toString());
                          }}
                          initial={ fence.inloIds }
                          title={formatMessage({id: "selectIndoorLocationGeofence", defaultMessage: "Select"})}
                          disabled={!fence.editEnabled}
                        />
                      </DropdownWrapper>
                    </NewFieldWrapper>
                  }
                  <Radius/>
                  <TimeToggle/>
                  <TimeControls/>
                  <Save/>
                </Collapse>
              </GeofenceTab>
            )
          })}
          { sharedGeofenceFlag &&
            <InheritedGeofenceDiv>
              {formatMessage({id: "customerInheritedGeofence", defaultMessage: "This device has geofence definitions stemming from standard settings"})}
            </InheritedGeofenceDiv>
          }
        </FenceContainer>
        </Collapse>
        <Collapse isOpened={ControlState.NEW === controlState}>
          <TypeSelector indoorLocations={indoorLocations}/>
          <NewName mappedIndoorLocations={mappedIndoorLocations} customerMap={customerMap} />
        </Collapse>
        <ButtonsContainer>
          <ButtonWrapper>
            <StyledButton onClick={() => {
              if(isDefaultOrEdit){
                const newFence = defaultFence();

                const takenNames = globalGeofence.map(fence => fence.displayName).concat(geofences.map(fence => fence.name));

                while(takenNames.includes(newFence.name)){
                  const currentCount = parseInt(newFence.name[newFence.name.length - 1], 10);
                  newFence.name = `${newFence.name.slice(0, newFence.name.length - 1)}${currentCount + 1}`;
                }

                newFence.deviId = deviId;
                setFieldValue("geofence", newFence);
                setControlState(ControlState.NEW);
                setGeofenceType(GeofenceTypeEnum.CIRCLE);
              }
              else{
                setGeofenceType(GeofenceTypeEnum.NONE);
                submitForm();
              }
            }}>
              {isDefaultOrEdit ? formatMessage({id: "addNewGeofence", defaultMessage: "Nytt geofence"}) : formatMessage({id: "save", defaultMessage: "Lagre"})}
            </StyledButton>
          </ButtonWrapper>
          {
            controlState === ControlState.NEW &&
            <ButtonWrapper>
              <StyledButton onClick={() => {setControlState(ControlState.DEFAULT)}} type="button">
                {formatMessage({id: "back", defaultMessage: "Back"})}
              </StyledButton>
            </ButtonWrapper>
          }
        </ButtonsContainer>
      </ShiftedDiv>
  )
})))

export default Controls;
