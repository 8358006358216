import React from 'react';
import PropTypes from 'prop-types';
import { getBgColor } from '../utils';

import Signal from './Signal';
import Bar from './Bar';

const TallSignal = ({ strength }) => {
  const maxStrength = 5;
  const signalBars = [];
  for (let i = 0; i < maxStrength; i++) {
    const style = {
      height: ((i + 1) * 24) / maxStrength,
      background:
        i >= 0 && i < strength ? getBgColor(strength, maxStrength) : undefined
    };
    signalBars.push(<Bar key={i} style={style} />);
  }

  return <Signal>{signalBars}</Signal>;
};

TallSignal.propTypes = {
  strength: PropTypes.number.isRequired
};

export default TallSignal;
