import { FilterState } from "./types";
import { OrderFilterEnum } from '../OrderFilterEnum';


export interface PersistedFilters{
  orderFilter: OrderFilterEnum;
}

export const loadFilters = () => {
  try {
    const serializedState = localStorage.getItem('device_list_filter');
    if (serializedState === null) {
      return;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return;
  }
}; 

export const saveFilters = (state: PersistedFilters) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('device_list_filter', serializedState);
  } catch {
    // ignore write errors
  }
};


export const loadCache = (superFirmID: number) => {
  try {
    const serializedState = localStorage.getItem('partner_dashboard_filter_3');
    if (serializedState === null) {
      return;
    }
    const parsedItems = JSON.parse(serializedState);

    if(parsedItems.superFirmID != superFirmID){
      return;
    }
    return parsedItems;
  } catch (err) {
    return;
  }
}; 

export const saveCache = (state: PersistedFilters) => {
  try {
    const serializedState = JSON.stringify(state);
    //removing old localstorage for updates
    localStorage.removeItem('partner_dashboard_filter');
    localStorage.removeItem('partner_dashboard_filter_2');
    localStorage.setItem('partner_dashboard_filter_3', serializedState);
  } catch {
    // ignore write errors
  }
};