import * as React from "react";

import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";
import Spinner from "./Spinner";
import { useIntl } from "react-intl";

const Wrap = styled.div`
  position: relative;
`;

type InitializeProps<T> = T & {
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  initialized: boolean;
  initializeData?: any;
}


function initialize<T>(Comp: React.FunctionComponent<T>){
  return (props: InitializeProps<T>) => {

    const { formatMessage } = useIntl();

    const { initialize, initialized, initializeData } = props;

    React.useEffect(() => {
      initialize(initializeData);
    },[])

    if(!initialized){
      return(
        <Wrap>
          <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
        </Wrap>
      )
    }
    return <Comp {...props}/>
  }
}

export default initialize;