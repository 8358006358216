import * as React from "react";
import { useIntl } from "react-intl";
import { U, ComponentWrapper } from ".";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";


const TimeToggle = withFormWrapper<GeofenceValues, {}>(({ formik: { setFieldValue, values: { geofence } } }) => {

  const { formatMessage } = useIntl();
 
  if(geofence.polygon === undefined)
    return null;

  return(
    <ComponentWrapper>
      <U
        onClick={() => {
          setFieldValue("geofence.timeToggled", !geofence.timeToggled);
        }}
      >
        {
          !geofence.timeToggled 
            ? formatMessage({id: "showTimeControls", defaultMessage: "Vis tidsstyring"})
            : formatMessage({id: "hideTimeControls", defaultMessage: "Skjul tidsstyring"})
        }
      </U>
    </ComponentWrapper>
  )
})

export default TimeToggle;