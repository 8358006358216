import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/INITIALIZE'
);

export const initializeUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/INITIALIZE_USER'
);

// Organisation
export const fetchOrganisation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/FETCH_ORGANISATION'
);


export const selectCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/SELECT_CUSTOMER'
);

export const initializeSubOrgs: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/INITIALIZE_SUB_ORGS'
);

export const initializeBillingOptions: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/INITIALIZE_BILLING_OPTIONS'
);

export const getPostCity: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/GET_POST_CITY'
);


export const initializeSubUnits: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/INITIALIZE_SUB_UNITS'
);

export const cancelSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/CANCEL_SETTINGS'
);

export const saveForm: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/SAVE_FORM'
);

export const getPayment: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/GET_PAYMENT'
);

export const initializePaymentProfiles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerOrganization/INITIALIZE_PAYMENT_PROFILE'
);