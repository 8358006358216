import * as React from "react";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import Check from "@Elements/Icon/Icons/Check";
import { CheckWrapper, PhoneInput, Wrapper } from "@Safemate/DeviceHeader";
import { editSensorName } from "../Store/routines";
import { Pen } from "@Elements/Icon/Icons/FontAwesome";
import { AppState } from "@Safemate/Store/types";

interface SensorNameProps{
  name: string;
  sensorId: number;
  hoverRow: number;
  editSensorName: Routine<ActionFunctionAny<Action<any>>>; 
}

const mapDispatchToProps = {
  editSensorName
}

const mapStateToProps = ({ settings: { sensor: { hoverRow }} }: AppState) => {
  return {
    hoverRow
  }
}

export const SensorName = connect(mapStateToProps, mapDispatchToProps)(({ hoverRow, editSensorName, name, sensorId }: SensorNameProps) => {
  const [ deviceName, setDeviceName ] = React.useState(name)
  const [ edit, setEdit ] = React.useState(false);

  React.useEffect(() => {
    setDeviceName(name);
  }, [name])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDeviceName(value);
  }

  const saveName = () => {
    if(deviceName !== name){
      editSensorName({sensorId, name: deviceName});
    }
    setEdit(false);
  }

  return(
    <div style={{display: "flex", alignItems: "center"}}>
      {edit 
        ? <Wrapper>
          <PhoneInput type="text" value={deviceName} onChange={handleChange}/>
          <CheckWrapper onClick={saveName}><Check size={{width: "25px", height: "25px"}} inline/></CheckWrapper>
        </Wrapper>
        : <> 
        <span onClick={() => setEdit(true)} style={{marginRight: "5px"}}>{deviceName}</span>
        {hoverRow === sensorId && <Pen medium onClick={() => {setEdit(true)}}/>}
        </> }
    </div>
  )
})