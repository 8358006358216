import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {TextField} from "./FormFields";
import {InnerFrame, SubHeaderWrapper} from "./styles";
import { injectIntl, useIntl, WrappedComponentProps } from "react-intl";
import {connect} from "react-redux";
import {RootState} from "../../Store/types";
import {LabelEnum} from "../../LabelEnum";
import Training from './Training';
import EmailAsUsername from './EmailAsUsername';
import {SaveButton} from "../../../DefaultSettings/Settings/styled";
import {NoFlexContent} from "@Safemate/RoleEditor/WrapperStyles";
import {setSendNewPasswordModal, SetSendNewPasswordModal} from "../../Store/actions";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import { AppState } from "@Safemate/Store/types";
import C from "@Elements/Step/Container";
import GdprManager from "./GdprManager";
import withFormWrapper from "@Elements/Form/formWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";

interface UserInformationProps extends WrappedComponentProps{
  ldapSelected: boolean;
  formUsername: string;
  setSendNewPasswordModal: SetSendNewPasswordModal;
  userToEdit: UserWithCustomer;
  enableSendNewPassword: boolean;
  ldapOnly: boolean;
  isPrivate: boolean;
  requireNationalId: boolean;
}

const mapStateToProps = ({appSettings: {ldapOnly}, appData: {isPrivate, requireNationalId}, users: {action: {edit: {user}},user: {ldap: {selected}}}}: AppState) => {
  return{
    ldapSelected: selected,
    userToEdit: user,
    ldapOnly,
    isPrivate,
    requireNationalId
  }
}

const mapDispatchToProps = {
  setSendNewPasswordModal
}

const UserInformation = connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(({ldapOnly, isPrivate, requireNationalId, intl: {formatMessage}, ldapSelected, enableSendNewPassword, userToEdit, formUsername, setSendNewPasswordModal}: UserInformationProps) => {

  return(
    <InnerFrame border>
      <h2>
        {formatMessage({id: LabelEnum.PERSONALIA, defaultMessage: "Personalia"})}
      </h2>
      <EmailAsUsername isPrivate={isPrivate} ldapSelected={ldapSelected} ldapOnly={ldapOnly} formUsername={formUsername} />
      <Row>
        <Col md={4} sm={6}>
          <TextField disabled={ldapOnly && ldapSelected} header={{id: LabelEnum.FIRST_NAME, defaultMessage: "Fornavn"}} field={["user", "firstName"]}/>
        </Col>
        <Col md={4} sm={6}>
          <TextField disabled={ldapOnly && ldapSelected} header={{id: LabelEnum.LAST_NAME, defaultMessage: "Etternavn"}} field={["user", "lastName"]}/>
        </Col>
        <Col md={4} sm={6}>
          <TextField header={{id: LabelEnum.PHONE_NUMBER, defaultMessage: "Telefon"}} field={["user", "mobile"]}/>
        </Col>
      </Row>

      { requireNationalId &&
      <Row>
        <Col md={4} sm={6}>
          <NationalID/>
        </Col>
      </Row>
      }

      {enableSendNewPassword && !ldapOnly && <Row>
        <Col md={4} sm={6}>
          <NoFlexContent>
          <SaveButton onClick={(event) => {
            event.stopPropagation();
            setSendNewPasswordModal(userToEdit);
          }}>
            {formatMessage({id: LabelEnum.SEND_NEW_PASSWORD, defaultMessage: "Send nytt passord"})}
          </SaveButton>
          </NoFlexContent>
        </Col>
      </Row>}
      {!isPrivate &&<Training/>}
      {!isPrivate &&<GdprManager/>}
    </InnerFrame>
  )
}));


const NationalID = withFormWrapper<UserWithCustomer, {}>(({formik: { setFieldValue, values: { user: { nationalIdentityNumber }}}}) => {

  const { formatMessage } = useIntl();

  const censorField = (nationalId: string) => {
    if (!isMasked) {
      return nationalIdentityNumber;
    }
    if(nationalId){
      if (nationalId.length <= 6) {
        return nationalId;
      }
      const visiblePart = nationalId.slice(0, 6);
      const starredPart = '•'.repeat(nationalId.length - 6);
      return visiblePart + starredPart;
    }
    return "";
  }
  const [isMasked, setIsMasked] = React.useState<boolean>(true);
  const [displayValue, setDisplayValue] = React.useState("");
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    setDisplayValue(censorField(nationalIdentityNumber));
  }, [nationalIdentityNumber, isMasked]);


  const toggleMask = () => {
    setIsMasked(!isMasked);
    setDisplayValue(censorField(nationalIdentityNumber));
  };

  return(
    <NoFlexContent>
      <SubHeaderWrapper>
        {formatMessage({id: LabelEnum.NATIONAL_IDENTITY_NUMBER, defaultMessage: "Fødselsnummer"})}
      </SubHeaderWrapper>
      <input
        ref={inputRef}
        maxLength={11}
        type="text"
        value={displayValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const cursorPosition = event.target.selectionStart;
          const rawValue = event.target.value.replace(/•/g, (match, offset) => {
            return nationalIdentityNumber[offset];
          });
          setFieldValue("user.nationalIdentityNumber", rawValue);

          // Move the cursor to the correct position
          setTimeout(() => {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
          }, 0);
        }}
      />
      <span
        onClick={toggleMask}
        style={{
          marginLeft: '5px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon icon={!isMasked ? faEye : faEyeSlash} />
      </span>
    </NoFlexContent>
  )
})


export default UserInformation;
