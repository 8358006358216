import * as React from "react";
import {Button} from "react-bootstrap";
import {injectIntl, WrappedComponentProps, useIntl} from "react-intl";
import {connect as reduxConnect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";

import {CancelButton, Footer, ModalDialog, ResetContainer, SaveButton, SaveContainer} from "./styled";
import {resetDeviceSettings, resetOtherSettings} from "../Store/routines";
import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import {LabelEnum} from "../LabelEnum";
import Modal from "../../Modals";
import {ButtonDiv } from "@Safemate/EventSetupNew/Body/WrapperStyles";
import {ModalDiv, ModalHeader} from "@Safemate/EventSetupNew/Body/ModalStyles";
import {AllPermissions, PolicyContext} from "../../Policy/Provider";
import withFormWrapper from "@Elements/Form/formWrapper";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";
import Loader from "@Elements/Loader";

export enum FooterContext{
  DEVICE_SETTINGS,
  OTHER_SETTINGS,
  INDOOR_LOCATION,
  EVENT_SETUP,
  GEOFENCE
}

interface FooterProps{
  changed: boolean;
  saving: boolean;
  resetting: boolean;
  customerId: number;
  integrationPermission: string;
  beta: boolean;
  context: FooterContext;
}

const mapStateToProps = ({appSettings: { beta }, defaultSettings: { customer: { selectedCustomer: { customerId }}, settings: {saving,  resetting, changed, otherSettings: { integrationPermission }}}} : AppState) => {
  return{
    beta,
    changed,
    resetting,
    saving,
    customerId,
    integrationPermission
  }
};

const ConfirmBtn = styled(Button)`
  background: #d60403;
  color: white;
  width: 100%;
  margin: 15px auto 0px;
  display: block;
  max-width:460px;
  font-size: 18px!important;
  height: 40px;
`;

 const CancelButtonModal = styled(Button)`
  color: white;
  background-color: #f7ae11;
  margin-right: 15px;
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  display: block;
  font-size: 18px!important;
  height: 40px;
`;

const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  svg {
    height: 40px;
    width: 40px;
  }
`

export const FooterComp =  reduxConnect(mapStateToProps)(
  withFormWrapper<any, FooterProps>(
    ({formik: {resetForm, dirty, errors}, beta, saving, resetting, changed, customerId, integrationPermission, context}) => {

      const { formatMessage } = useIntl();

      const hasError = !(Object.keys(errors).length === 0);

      const handleCancel = () => {
        resetForm()
      }

      const permissions: Map<PolicyNameEnum, AllPermissions> = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
      const values = permissions.values();
      let isAllowedToEdit: boolean = false;
      let result = values.next();
      while(!result.done){
        if(result.value.customerPermissions[customerId].edit || result.value.customerPermissions[customerId].all){
          isAllowedToEdit = true;
        }
        result = values.next();
      }

      const [ modal, setModal ] = React.useState(false);

      const openModal = () => {
        setModal(true);
      }

      return(
        <Footer>
          {(context === FooterContext.DEVICE_SETTINGS || context === FooterContext.OTHER_SETTINGS) && beta &&
          <ResetContainer disabled={!isAllowedToEdit || integrationPermission === "false"}>
            <Button style={{minWidth: "150px"}} disabled={!isAllowedToEdit || resetting} bsStyle="danger" onClick={openModal}>
              {resetting ? <Loader/> : formatMessage({id: LabelEnum.REVERT, defaultMessage: "Tilbakestill"})}
            </Button>
            &nbsp;
            <Tooltip
              popoverId="reset-popover"
              labelId={LabelEnum.RESET_SETTINGS}
              defaultMessage="Tilbakestill til opprinnelige standardinnstillinger. Hvis dette er topp-nivå (kommune) vil Safemate sine innstillinger gjelde. Om ikke vil topp-nivå (kommune) gjøre det."
            >
              <InformationIcon size="medium" />
            </Tooltip>
          </ResetContainer>}
          <SaveContainer disabled={(!dirty && !changed) || !isAllowedToEdit}>
            <CancelButton disabled={(!dirty && !changed) || !isAllowedToEdit} onClick={handleCancel}>
              {formatMessage({id: LabelEnum.CANCEL, defaultMessage: "Avbryt"})}
            </CancelButton>
            <SaveButton style={{minWidth: "150px"}} disabled={((!dirty && !changed) || hasError) || !isAllowedToEdit || saving} type="submit">
              {saving ? <Loader/> : formatMessage({id: LabelEnum.SAVE, defaultMessage: "Lagre"})}
            </SaveButton>
          </SaveContainer>
          <Modal
            bodyContent={<ModalBody context={context} setModal={setModal}/>}
            display={modal}
          />
        </Footer>
      )
    }
  )
);

interface ModalBodyProps extends WrappedComponentProps{
  setModal:(modal: boolean) => void;
  context: FooterContext;
  resetDeviceSettings: Routine<ActionFunctionAny<Action<any>>>;
  resetOtherSettings: Routine<ActionFunctionAny<Action<any>>>;
}

const ModalBody = reduxConnect(null, {resetDeviceSettings, resetOtherSettings})(injectIntl(({intl: {formatMessage}, context, resetDeviceSettings, resetOtherSettings, setModal}: ModalBodyProps) =>
  <ModalDiv style={{ textAlign: 'center', width: '500px' }}>
    <ModalHeader style={{ textAlign: 'left' }}>
      {formatMessage({id: LabelEnum.REVERT_SETTINGS, defaultMessage: "Tilbakestill innstillinger"})}
    </ModalHeader>
      <ModalDialog>
        {formatMessage({id: LabelEnum.REVERT_DIALOG, defaultMessage: "Er du sikker på at du ønsker å tilbakestille standardinnstillingene for denne organisasjonen?"})}
      </ModalDialog>
    <ButtonDiv>
      <CancelButtonModal
        id="cancel-revert"
        onClick={() => setModal(false)}
      >
        {formatMessage({id: LabelEnum.CANCEL, defaultMessage: "cancel"})}
      </CancelButtonModal>
      <ConfirmBtn
        bsStyle="danger"
        id="confirm-revert"
        onClick={() => {
          switch(context){
            case FooterContext.DEVICE_SETTINGS:{
              resetDeviceSettings();
              break;
            }
            case FooterContext.OTHER_SETTINGS:
              resetOtherSettings();
              break;
            case FooterContext.INDOOR_LOCATION:
            case FooterContext.EVENT_SETUP:
            case FooterContext.GEOFENCE:
              break;
          }
          setModal(false)
        }}
      >
        {formatMessage({id: LabelEnum.REVERT, defaultMessage: "Tilbakestill"})}
      </ConfirmBtn>
    </ButtonDiv>
  </ModalDiv>
));
