import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import { TrackingDTO, TrackingSettings } from '../../types';
import { Feedback } from '../../labels';

function* fetchTracking(action: AppAction<number>){
  try {
    const tracking: TrackingDTO = yield call(Api.getTracking, action.payload);
    yield put(Routines.fetchTracking.success(tracking));
  }
  catch(e){
    yield put(Routines.fetchTracking.failure());
  }
}

export type SaveTracking = {
  deviceId: number;
  settings: TrackingSettings;
}

function* saveTracking(action: AppAction<SaveTracking>){
  try{
    const tracking = yield call(Api.saveTracking, action.payload);
    yield call(Api.saveTracking, action.payload);
    yield put(Routines.saveTracking.success(tracking));
    yield feedbackSuccess(Feedback.SAVED_TRACKING);
  }
  catch(e){
    yield put(Routines.saveTracking.failure());
    yield feedbackFailure(e);
  }
}

export type UpdateAutoTracking = {
  deviceId: number;
  autoTracking: boolean;
}

function* updateAutoTracking(action: AppAction<UpdateAutoTracking>){
  try{
    //const autoTracking = yield call(Api.toggleAutoTracking, action.payload);
    yield put(Routines.updateAutoTracking.success(action.payload));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.updateAutoTracking.failure());
  }
}

export type UpdateAcceptLBS = {
  deviceId: number;
  acceptLBS: boolean;
}

function* updateAcceptLBS(action: AppAction<UpdateAcceptLBS>){
  try{
    const acceptLBS = yield call(Api.toggleAcceptLBS, action.payload);
    yield put(Routines.updateAcceptLBS.success(acceptLBS));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.updateAcceptLBS.failure());
  }
}

export type UpdatePowerSaving = {
  deviceId: number;
  powerSaving: boolean;
}

function* updatePowerSaving(action: AppAction<UpdatePowerSaving>){
  try{
    yield put(Routines.updatePowerSaving.success(action.payload));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.updatePowerSaving.failure());
  }
}

export type UpdateDevicePositioning = {
  deviceId: number;
  disablePositioning: boolean;
}

function* updateDevicePositioning(action: AppAction<UpdateDevicePositioning>){
  try{
    //const tracking = yield call(Api.updateDevicePositioning, action.payload);
    yield put(Routines.updateDevicePositioning.success(action.payload));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.updateDevicePositioning.failure());
  }
}

export type StartTracking = {
  deviceId: number;
  duration: number;
  interval: number;
}

function* startTracking(action: AppAction<StartTracking>){
  try{
    const tracking: TrackingDTO = yield call(Api.startTracking, action.payload);
    yield put(Routines.startTracking.success(tracking));
    yield feedbackSuccess("trackingStarted");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.startTracking.failure());
  }
}

function* stopTracking(action: AppAction<number>){
  try{
    const tracking: TrackingDTO = yield call(Api.stopTracking, action.payload);
    yield put(Routines.stopTracking.success(tracking));
    yield feedbackSuccess("trackingStopped");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.stopTracking.failure());
  }
}

function* getPosition(action: AppAction<number>){
  try{
    yield call(Api.getPosition, action.payload);
    yield put(Routines.getPosition.success());
    yield feedbackSuccess("takitPositionQuerySent");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getPosition.failure());
  }
}

function* findMe(action: AppAction<number>){
  try{
    yield call(Api.findMe, action.payload);
    yield put(Routines.getPosition.success());
    yield feedbackSuccess("findMeQuerySent");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getPosition.failure());
  }
}

function* trackingSaga(){
    yield takeLatest(Routines.fetchTracking.TRIGGER, fetchTracking);
    yield takeLatest(Routines.saveTracking.TRIGGER, saveTracking);
    yield takeLatest(Routines.updateAutoTracking.TRIGGER, updateAutoTracking);
    yield takeLatest(Routines.updateAcceptLBS.TRIGGER, updateAcceptLBS);
    yield takeLatest(Routines.updatePowerSaving.TRIGGER, updatePowerSaving);
    yield takeLatest(Routines.updateDevicePositioning.TRIGGER, updateDevicePositioning);
    yield takeLatest(Routines.startTracking.TRIGGER, startTracking);
    yield takeLatest(Routines.stopTracking.TRIGGER, stopTracking);
    yield takeLatest(Routines.getPosition.TRIGGER, getPosition);
    yield takeLatest(Routines.findMe.TRIGGER, findMe);
}

export default trackingSaga;
