import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {connect as formikConnect, FormikProps} from "formik";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";
import { CancelButton } from "./styled";
import { cancelSettings} from "../Store/routines";
import {RootState, Organization, CustomerArrState} from "../Store/types";
import { AppState } from "@Safemate/Store/types";
import { useIntl } from "react-intl";

const StyledRow = styled(Row)`
  float:right;
  .buttons{
    width: 230px;
    float: right;
    margin-right: -2px;
  }
  .billinghistory{
    .row{
      margin-right:-14px;
      float: right;
    }
  }
`;


interface FooterProps<T>{
  base: Organization;
  formik: FormikProps<T>;
  cancelSettings: Routine<ActionFunctionAny<Action<any>>>;
}
const mapStateToProps = ( {partnerOrganization: {subUnits: {customers}}} : AppState) => {
  return{
    base: customers
  }
};
const mapDispatchFromState = {
  cancelSettings
}

export const Footer = connect(mapStateToProps, mapDispatchFromState)(
  formikConnect(
      ({formik: {dirty, initialValues, resetForm}, base, cancelSettings}: FooterProps<CustomerArrState>) => {
        const { formatMessage } = useIntl();
        const handleCancel = () => {
          cancelSettings(base);
          resetForm(initialValues);
        }
      return(
        <StyledRow>
          <Col md={12} className="buttons" >
            <CancelButton disabled={(!dirty) } onClick={handleCancel}>
              {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
            </CancelButton>
            <Button type="submit" disabled={(!dirty) } >
              {formatMessage({id: "save", defaultMessage: "Lagre"})}
            </Button>
          </Col>
        </StyledRow>
      )
    })
  )

export default Footer;
