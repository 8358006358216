import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { STable, Sth } from "../Sensor/styles";
import { ContentWrap } from "./styles";
import WalkTestItem from "./walktestItem";
import { IWalktest } from "./types";

export const tabHeaders = defineMessages({
  thDate: { id: 'wtTableHeaderDate', defaultMessage: 'Dato' },
  thTime: { id: 'time', defaultMessage: 'Tid' },
  thSerialNumber: {
    id: 'sensTableHeadSerialNumber',
    defaultMessage: 'Serienummer'
  },
  thName: { id: 'walkTableName', defaultMessage: 'Navn' },
  thSignalStr: { id: 'walkTableSignalStr', defaultMessage: 'Signalstyrke' }
});

interface WalktestTableProps{
  walktests: IWalktest[];
  updatedSensors: string[];
}

const WalktestTable = ({ walktests, updatedSensors }: WalktestTableProps) => {

  const { formatMessage } = useIntl();

  return(
    <ContentWrap>
      <STable>
        <thead>
          <tr>
            <Sth>
              {formatMessage(tabHeaders.thDate)}
            </Sth>
            <Sth>
              {formatMessage(tabHeaders.thTime)}
            </Sth>
            <Sth>
              {formatMessage(tabHeaders.thName)}
            </Sth>
            <Sth>
              {formatMessage(tabHeaders.thSerialNumber)}
            </Sth>
            <Sth>
              {formatMessage(tabHeaders.thSignalStr)}
            </Sth>
          </tr>
        </thead>
        <tbody>
          {walktests.map(walktest => {
            return (<WalkTestItem
              walktest={walktest}
              history={!updatedSensors.includes(walktest.gatewayId)}
              time={walktest.time}
            />)
          })}
        </tbody>
      </STable>
    </ContentWrap>
  )
}
export default WalktestTable;