import * as React from "react";
import { useIntl } from "react-intl";
import {connect} from "react-redux";
import {DeviceHardware, RootState} from "../Store/types";
import {selectDeviceHardware, fetchDeviceFirmware} from "../Store/routines";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {mapDeviceHardwareOptions} from "./utils";
import {LabelEnum} from "../LabelEnum";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { input } from "@Theme/common";
import { Option } from "@Elements/Dropdown/types";
import {Settings} from "../Settings/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { SelectOption } from "../types";
import { AppState } from "@Safemate/Store/types";

interface DeviceHardwareProps{
  deviceHardwares: Array<DeviceHardware>;
  selectedDeviceHardwareId: number;
  selectDeviceHardware: Routine<ActionFunctionAny<Action<any>>>;
  fetchDeviceFirmware: Routine<ActionFunctionAny<Action<any>>>;
  customerId: number;
}

const DeviceHardwareComp = withFormWrapper<Settings, DeviceHardwareProps>(({formik: { resetForm }, fetchDeviceFirmware, deviceHardwares, selectedDeviceHardwareId, selectDeviceHardware, customerId}) => {
  const { formatMessage } = useIntl();


  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const options: SelectOption[] = React.useMemo(() => {
    const mappedHardware = mapDeviceHardwareOptions(deviceHardwares, formatMessage, permissions, customerId);
    if(mappedHardware.length > 0){
      selectDeviceHardware(selectedDeviceHardwareId);
      fetchDeviceFirmware(selectedDeviceHardwareId);
    }
    return mappedHardware;
  }, [deviceHardwares])


  if(options.length === 0) return null;

  return(
    <Dropdown
      size={{width: input.width.medium}}
      title={formatMessage({id: LabelEnum.DEVICE_HARDWARE, defaultMessage: "Device Hardware"})}
      options={options}
      initial={selectedDeviceHardwareId}
      onChange={(object: Option | Option[]) => {
        const value = (object as Option).value;
        selectDeviceHardware(value);
        fetchDeviceFirmware(value);
        if(selectedDeviceHardwareId !== value){
          resetForm();
        }
      }}
    />
  )
})

const mapStateToProps = ({ defaultSettings: {deviceHardware, customer: { selectedCustomer: { customerId } }}}: AppState) => {
  return{
    deviceHardwares: deviceHardware.deviceHardwares,
    selectedDeviceHardwareId: deviceHardware.selectedDeviceHardwareId,
    customerId
  }
};

const mapDispatchToProps  = {
  selectDeviceHardware,
  fetchDeviceFirmware
};


export default connect(mapStateToProps, mapDispatchToProps)(DeviceHardwareComp);
