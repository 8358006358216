import * as React from "react";
import {Form, Formik} from "formik";
import {Button, Grid, Row} from "react-bootstrap";
import { Collapse } from "react-collapse";
import {useIntl} from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";


import Ldap from "./Ldap";
import UserInformation from "./UserInformation";
import {connect} from "react-redux";
import {CustomerState} from "../../Store/types";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import PermanentRole from "./PermanentRole";
import {Role} from "../../Models/Role";
import {BackWrapper, InnerFrameHeader, StyledFrame, SwitchHeader, CenteredRow, TrainedWrapper, InnerFrame} from './styles';
import Footer from "./Footer";
import TemporaryRole from "./TemporaryRole";
import TemporaryDisable from "./TemporaryDisable";
import {saveUser, getUser, sendNewPassword} from "../../Store/routines";
import Disable from "./Disable";
import {selectLdap, SelectLdap, SetSendNewPasswordModal} from "../../Store/actions";
import {LabelEnum} from "../../LabelEnum";
import UnsavedCheck from "../../../SaveNeeded/unsavedModal";
import Switch from "../../../../Elements/Switch";
import {populateEditForm} from "./utils";

import ConfirmModal from "../../../../Roles/RoleEditor/Modals/ConfirmModal";
import {setSendNewPasswordModal} from "../../Store/actions";
import {AppAction} from "../../../DefaultSettings/Store/types";
import { SiteTypeEnum } from "../../../SiteTypeEnum";
import { mapRoles } from "../utils";
import { GlobalRoleName } from "../../../Policy/GlobalRoleName";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { AppState } from "@Safemate/Store/types";
import { ICustomer } from "@Safemate/Model/Customer";
import {formatNorwegianDateMin} from "@Safemate/Settings/Indoor/utils";

const CollapseForm = styled(Form)`
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

interface EditRouterProps{
  id: string;
}

interface EditFormProps{
  siteType: SiteTypeEnum
  userToEdit: UserWithCustomer;
  roles: Array<Role>;
  admin: Role;
  enableSendNewPassword: boolean;
  saveUser: Routine<ActionFunctionAny<Action<any>>>;
  getUser: Routine<ActionFunctionAny<Action<any>>>;
  sendNewPassword: Routine<ActionFunctionAny<AppAction<UserWithCustomer>>>
  selectLdap: SelectLdap;
  isNew: boolean;
  isPrivate: boolean;
  isSso: boolean;
  isAdminSso: boolean;
  customer: CustomerState;
  superfirm: ICustomer;
  setSendNewPasswordModal: SetSendNewPasswordModal;
  sendNewPasswordModal: UserWithCustomer | null;
}

const mapStateFromProps = ({ appData: { isSso, isAdminSso, superfirm, isPrivate, currentContext }, users: {action: {edit: {user, isNew}, sendNewPasswordModal}, role: {roles, admin}, customer}}: AppState) => {
  return{
    siteType: currentContext,
    userToEdit: user,
    roles,
    admin,
    isNew,
    isPrivate,
    isSso,
    isAdminSso,
    superfirm,
    customer,
    sendNewPasswordModal,
    enableSendNewPassword: !isNew && admin.roleName === GlobalRoleName.SUPERFIRM
  }
}

const mapDispatchToProps = {
  saveUser,
  getUser,
  selectLdap,
  sendNewPassword,
  setSendNewPasswordModal
}


const EditForm = connect(mapStateFromProps, mapDispatchToProps)(
  ({getUser, saveUser, sendNewPassword, selectLdap, userToEdit, isNew, enableSendNewPassword, superfirm, isPrivate, isSso, isAdminSso, siteType, roles, sendNewPasswordModal, setSendNewPasswordModal}: EditFormProps) => {

    const isAhp = siteType === SiteTypeEnum.AHP;
    const sso = isAdminSso || isSso;

    const { id } = useParams<EditRouterProps>();
    const { formatMessage } = useIntl();
    
    React.useEffect(() => {
      if(id){
        getUser(id);
      }
    }, [])

    const mappedRoles = React.useMemo(() => {
      return mapRoles(roles, formatMessage, siteType === SiteTypeEnum.AHP);
    }, [roles, formatMessage]);

    const initialValues: UserWithCustomer = React.useMemo(() => {
      // Users with usertype === 1 is an ldap user
      selectLdap(!!userToEdit.user.userType);
      return populateEditForm(userToEdit, {custId: superfirm.custId, firmName: superfirm.name}, isPrivate);
    }, [userToEdit, superfirm])

    const newPassSent = formatMessage({
      id: "newPassSent",
      defaultMessage: "Nytt passord satt."
    });
    
    let receiveEmailLoginInfo = " ";

    if(!isPrivate){
      receiveEmailLoginInfo = formatMessage({
        id: "receiveEmailLoginInfo",
        defaultMessage: "vil motta en e-post med informasjon for tilgang til Safemate Pro."
      });
    }
    
    else{
      receiveEmailLoginInfo = formatMessage({
        id: "receiveEmailLoginInfoGo",
        defaultMessage: "vil motta en e-post med informasjon for tilgang til Safemate Go."
      });
    }

    const [advanced, setAdvanced] = React.useState(false);
    userToEdit.user.newUserFlag = isNew;
    if(isNew){
      userToEdit.user.emailAsUsername = true;
    }

    const createdDate =  userToEdit.user.createdBy && userToEdit.user.createdBy.modifiedDate ? formatNorwegianDateMin(userToEdit.user.createdBy.modifiedDate).split(" ", 2) : null;

    return (
      <StyledFrame>
        <Grid fluid>
          <InnerFrameHeader border>
            <h1>
              {formatMessage({id: LabelEnum.PERSONAL_INFORMATION, defaultMessage: "Personlig informasjon"})}
            </h1>
            <BackWrapper>
              <Link to="/users/list">
                <Button>
                  {formatMessage({id: LabelEnum.BACK, defaultMessage: "Tilbake"})}
                </Button>
              </Link>
            </BackWrapper>
          </InnerFrameHeader>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={values => {saveUser(values)}}
            render={({values}) => {
              return(
              <CollapseForm>
                <Ldap/>
                <UserInformation formUsername={userToEdit.user.username} enableSendNewPassword={enableSendNewPassword}  />
                { !isPrivate && <PermanentRole roles={mappedRoles} /> }
                { !isNew && !isPrivate && !isAhp && !sso &&
                  <React.Fragment>
                    <CenteredRow>
                      <SwitchHeader>
                        {formatMessage({id: LabelEnum.SHOW_ADVANCED, defaultMessage: "Avansert"})}
                      </SwitchHeader>
                      <Switch
                        checked={advanced}
                        onChange={(value: boolean) => {
                          setAdvanced(value)
                        }}
                      />
                    </CenteredRow>
                  
                    <Collapse isOpened={advanced}>
                      { (userToEdit.user.createdBy || userToEdit.user.adminCustomer) &&
                        <InnerFrame style={{padding: "0px 25px"}}>
                          { userToEdit.user.createdBy &&
                            <Row>
                              <TrainedWrapper md={12}>
                                <span>{ `${formatMessage({id: LabelEnum.USER_CREATED_BY_NAME, defaultMessage: "Created by"})} ${userToEdit.user.createdBy.name} (${userToEdit.user.createdBy.userId}) ${ userToEdit.user.createdBy.modifiedDate ? createdDate[0] + " " +formatMessage({id: "time", defaultMessage: "kloken"}).toLowerCase() + " " + createdDate[1] : "" }` }</span>
                              </TrainedWrapper>
                            </Row>
                          }
                          { userToEdit.user.adminCustomer &&
                            <Row>
                              <TrainedWrapper md={12}>
                                <span>{ `${formatMessage({id: LabelEnum.FIRST_ADMIN_CUSTOMER, defaultMessage: "This user was first admin for customer "})} ${userToEdit.user.adminCustomer.firmName}` }</span>
                              </TrainedWrapper>
                            </Row>
                          }
                        </InnerFrame>
                      }
                      <Disable/>
                      <TemporaryRole roles={mappedRoles}/>
                      <TemporaryDisable/>
                    </Collapse>
                  </React.Fragment>
                }
                <Footer/>
                <UnsavedCheck/>
              </CollapseForm>
              )
            }}
          />
        </Grid>
        { enableSendNewPassword && <ConfirmModal
            handleCancel={() => setSendNewPasswordModal(null) }
            handleConfirm={() => { sendNewPassword({userToEdit, newPassSent, receiveEmailLoginInfo}); setSendNewPasswordModal(null) }}
            display={ sendNewPasswordModal !== null }
            headerText={`${formatMessage({
              id: "sendNewPassword",
              defaultMessage: "Send nytt passord"
            })}`}
            bodyText={`${formatMessage({
              id: "sendNewPasswordConfirmText1",
              defaultMessage: "Du er i ferd med å sette et nytt passord for"
            })} ${userToEdit.user.firstName} ${userToEdit.user.lastName}${formatMessage({
              id: "sendNewPasswordConfirmText2",
              defaultMessage: ", som vil bli sendt til applikasjonsbrukeren pr. e-post. Ønsker du å forsette?"
            })}`}
            translations={(id: string, defaultMessage: string) => formatMessage({id, defaultMessage})}
          />}
      </StyledFrame>
    )
  }
)

export default EditForm;
