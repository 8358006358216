import { put, takeLatest} from "redux-saga/effects";

import * as Routines from '../routines';
import { AppAction } from "@Safemate/Store/types";


function* selectDeviceHardware(action: AppAction<number>){
  try{
    yield put(Routines.fetchSettingsFromDeviceHardware(action.payload));
    yield put(Routines.selectDeviceHardware.success(action.payload));
  }
  catch(e){
    yield put(Routines.selectDeviceHardware.failure());
  }
}

function* deviceHardwareSaga(){
  yield takeLatest(Routines.selectDeviceHardware.TRIGGER, selectDeviceHardware);
}

export default deviceHardwareSaga;
