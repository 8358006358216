import * as React from "react";
import { Footer } from "../Components/styles";
import { SignalStrengthReport } from "../General/Tabs/gx8/logsReports";

const WalktestFooter = () => {
  return (
    <Footer>
      <div style={{padding: "10px 0"}}>
      <SignalStrengthReport/>
      </div>
    </Footer>
  )
}

export default WalktestFooter;