import * as React from "react";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";
import withFormWrapper from "@Elements/Form/formWrapper";

import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import { updatePowerSaving } from "../../Store/routines";
import { RowWrapper, LabelWrapper, RowLabel, ToggleWrap, PaddedCol } from "../TrackingOptions";
import { TrackingSettings } from "../../types";
import {Info} from "..";
import Toggle from "@Elements/Switch/Toggle";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";

const NoPadCol = styled(Col)`
  padding: 0;
  margin-top: 10px;
  display: flex;
  flex-grow: 1;
`;

interface PowerSavingProps extends WrappedComponentProps{
  updatePowerSaving: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  powerSaving: boolean;
  autoTracking: boolean;
  disablePositioning: boolean;
}

const PowerSaving = injectIntl(withFormWrapper<TrackingSettings, PowerSavingProps>(({intl: {formatMessage}, updatePowerSaving, deviId, autoTracking, powerSaving, disablePositioning, formik:{setFieldValue}}) => {

  return(
    <RowWrapper>
      <LabelWrapper xs={12} sm={5} md={5}>
        <RowLabel>      
          {formatMessage({id: "powerSaving", defaultMessage: "Strømsparing"})}
        </RowLabel>
        <Tooltip
          popoverId="power-saving-popover"
          labelId="powerSavingDesc"
          defaultMessage="Enheten vil slå av funksjonalitet som bruker mye batteri."
        >
          <InformationIcon size="medium" />
        </Tooltip> 
      </LabelWrapper>
      <PaddedCol sm={7} md={7}>
      {disablePositioning ? <Info>{formatMessage({id: "powerSavingInactive", defaultMessage: "Power saving is not available when positioning is turned off."})}</Info> 
      : !autoTracking ? <Info>{formatMessage({id: "powerSavingInactiveAutoTracking", defaultMessage: "Power saving is not available when auto tracking is turned off."})}</Info>
      : <ToggleWrap>
          <Toggle
            onChange={(value: boolean) => {
              setFieldValue("powerSaving", value);
              updatePowerSaving(value);
            }}
            checked={powerSaving}
          />
        </ToggleWrap>}
      </PaddedCol>
    </RowWrapper>
  )
}))

const mapStateToProps = ({ settings: {tracking: { powerSaving, disablePositioning, autoTracking }, device: {device: {deviId}}}}: AppState) => {
  return {
    powerSaving,
    deviId,
    disablePositioning,
    autoTracking
  }
}

const mapDispatchToProps = {
  updatePowerSaving
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerSaving);