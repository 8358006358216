import styled from 'styled-components';

export const DayWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span{
    margin: 0 !important;
  }

  & :last-child {
    margin 0 0 0 0;
  }
`;

export const Label = styled.p`
  color: ${({
    theme: {
      colors: { textPrimary }
    }
  }) => textPrimary}
  font-weight: bold;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export const ScheduleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  & :last-child {
    margin: 0 0 0 0;
  }
`;