import { ITheme } from "@Theme/types";
import * as React from "react";
import { useIntl } from "react-intl";
import Modal from "react-modal";
import { ThemeProps, withTheme } from "styled-components";
import { CaretRight, PolicyModalHeader, PolicyModalWrapper, StyledButton, StyledLi, StyledUl } from "../layout";
import { Header, TableElement } from "../utils";

export interface PolicyModalData{
  header: Header;
  content: TableElement[];
}

export interface PolicyModalProps extends ThemeProps<ITheme>{
  data: PolicyModalData | null;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PolicyModal = withTheme(({data, open, setOpen, theme}: PolicyModalProps) => {

  if(!data) return null;

  const intl = useIntl();

  const { header, content } = data;

  Modal.setAppElement("#react");

  return(
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      style={
        {
          content: {
            maxWidth: "40%",
            width: "40%",
            height: "fit-content",
            position: "unset",
            background: theme.colors.backgroundPrimary,
            color: ""
          },
          overlay:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }
        }
      }
    >
      <PolicyModalWrapper>
        <PolicyModalHeader>
          {`${intl.formatMessage({id: header.name, defaultMessage: header.name})} ${intl.formatMessage({
            id: 'for',
            defaultMessage: 'for'
          })} ${intl.formatMessage({
            id:  header.roleName,
            defaultMessage: header.roleName
          })}`}
        </PolicyModalHeader>
        <div>
          <OverlayContent
            content={content}
          />
        </div>
        <div style={{ marginTop: '10px' }}>
          <StyledButton onClick={() => setOpen(false)}>
            {intl.formatMessage({id:'closeBtn', defaultMessage: 'Close'})}
          </StyledButton>
        </div>
      </PolicyModalWrapper>
    </Modal>
  )
})

interface OverlayContentProps{
  content: TableElement[];
}

export const OverlayContent = ({content}: OverlayContentProps) => {

  const intl = useIntl();
  return(
    <StyledUl>
      {content.map((item, index) => {
        if (item.status) {
          return(
          <StyledLi key={index}>
            <CaretRight />
            {intl.formatMessage(
              {id: `${item.infoText}${
                item.information != null ? item.information : ''
              }`,
              defaultMessage: item.infoText
            })}
          </StyledLi>)
        }
      }).filter(item => item)}
    </StyledUl>
  )
};

export default PolicyModal;