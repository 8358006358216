import { Role } from "@Safemate/RoleEditor/Store/types";
import * as React from "react";
import { useIntl } from "react-intl";
import { Active, Checkmark } from "../layout";
import { Header, mapData, mapHeaders } from "../utils";
import Actions from "./actions";
import PolicyModal, { PolicyModalData } from "./policyModal";
import { ContentTr, SpacedTd, SpacedTr, Td } from "./tableLayout";

interface ElementProps{
  role: Role;
  isPartner: boolean;
}

export const RoleElement = ({role, isPartner}: ElementProps) => {

  const {formatMessage} = useIntl()

  const headers = mapHeaders(role, isPartner, formatMessage);

  const content = mapData(role, headers, formatMessage);

  const [ open, setOpen ] = React.useState(false);
  const [ modalContent, setModalContent ] = React.useState<PolicyModalData | null>(null);

  return(
    <React.Fragment>
      <ContentTr>
        <Td system={role.global}>
          {role.global ? formatMessage({id: `${role.roleName}ROLE`, defaultMessage: role.roleName}) : role.roleName}
        </Td>
        {Object.values(headers).map((header: Header) => {
          return (
            <Td>
              <Active 
                active={header.status}
                onClick={() => {
                  if(header.status){
                    setModalContent({
                      header,
                      content: content[header.id]
                    })
                    setOpen(true);
                  }
                }}
              >
                {header.status && <Checkmark/>}
              </Active>
            </Td>
          )
        })}
        <Td>
          <Actions role={role}/>
        </Td>
      </ContentTr>
      <SpacedTr><SpacedTd></SpacedTd></SpacedTr>
      <PolicyModal data={modalContent} open={open} setOpen={setOpen}/>
    </React.Fragment>
  )
}

export default RoleElement;