import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Loader from "@Elements/Loader";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({
    theme: {
      colors: { backgroundSecondary }
    }
  }) => backgroundSecondary};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Message = styled.div`
  text-align: center;
  color: ${({
    theme: {
      colors: { textPrimary }
    }
  }) => textPrimary};
`;

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    height: 32px;
    width: 32px;
  }
`;

const MainWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  svg {
    height: 32px;
    width: 32px;
  }
`;

const Icon = props => (
  <FontAwesomeIcon
    icon={faMapMarkerAlt}
    size="3x"
    color={props.theme.colors.accent}
    {...props}
  />
);

class Spinner extends Component {
  render() {
    const {
      message = '',
      config = { stiffness: 100, damping: 2 },
      show
    } = this.props;
    if(!show) return null;
    return (
      <LoaderWrap>
        <Wrap>
          <Loader />
          <Message>
            {message}
          </Message>
        </Wrap>
      </LoaderWrap>
    );
  }
}

export const MainSpinner = () => {
  return <MainWrap>
    <Loader />
  </MainWrap>
}

export const SubLoader = () => {
  return <LoaderWrap>
    <Loader/>
  </LoaderWrap>
}

export default Spinner;
