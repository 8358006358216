import { Policies, Policy } from "./Store/types";

type MapCustomTabs = (policies: Policies, isPartner: boolean) => NonStatic;

export interface NonStatic{
  [index: number]: NonStaticRender;
}

export interface NonStaticRender{
  key: string;
  title: string;
  children: Policy[];
}

export const mapCustomTabs: MapCustomTabs = (policies, isPartner) => {
  const mappedPolicies = Object.values(policies);
  
  const tempNonStatic: NonStatic = {};
  const nonStatic: NonStatic = {};
  mappedPolicies.forEach(item => {
    if(item.policyDetails.parent === 0){
      if(!item.policyDetails.staticRender){
        tempNonStatic[item.rolePolicies.policyId] = {
          key: item.policyName,
          title: item.policyDetails.infoText,
          children: []
        };
      }
    }
  })

  mappedPolicies.forEach(item => {
    if(!item.policyDetails.staticRender){
      if(item.policyDetails.parent !== 0){
        tempNonStatic[item.policyDetails.parent].children.push(item);
      }
    }
  })

  for( const key in tempNonStatic){
    if(tempNonStatic[key].key == "partner"){
      if(isPartner){
        nonStatic[key] = tempNonStatic[key];
      }
    }else{
      nonStatic[key] = tempNonStatic[key];
    }
  }


  return nonStatic;
}