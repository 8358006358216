import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import userSaga from "./user";
import countrySaga from "./country";

function* MyPageSaga(){
  yield all([
    fork(initializationSaga),
    fork(userSaga),
    fork(countrySaga)
  ])
}
export default MyPageSaga;
