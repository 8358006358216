import * as React from "react";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Modal from "react-modal";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { Customer } from "../../types";
import { SettingEnum as ComponentSettingEnum } from "../../settingEnum";
import { DeviceComponents } from "../../tabDefinition";
import { DeviceHardwareEnum } from "../../../DeviceHardwareEnum";
import { Button } from "react-bootstrap";
import styled, { withTheme, ThemeProps } from "styled-components";
import { ITheme } from "../../../../../themes/types";
import { switchSafemate, forgetSafemate, deleteSafemate, resetSafemate, clearWifiCache } from "../../Store/routines";
import { StyledButton, ButtonWrapper } from "./styled";

import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import { TwinDevice } from "@Safemate/Settings/types";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";


interface DeleteDataProps extends WrappedComponentProps{
  dehaId: number;
}

const mapStateToProps = ({ settings: {initialization: { dehaId }}}: AppState) => {
  return{
    dehaId
  }
}

const DeleteData = connect(mapStateToProps)(injectIntl(({ dehaId, intl: {formatMessage} }: DeleteDataProps) => {

  const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;
  
  return(
    <div>
      <h2>{formatMessage({id: "deleteDeviceData", defaultMessage: "Slett enhetdata"})}</h2>
      <div>
          {components.has(ComponentSettingEnum.DELETE_DATA) 
            && components.get(ComponentSettingEnum.DELETE_DATA)?.map(comp => 
                comp)}
        </div>
    </div>
  )

}))

export default DeleteData;

export const modalStyles = {
  content: {
    width: "80%",
    height: "fit-content",
    position: "unset",
    background: "",
    color: ""
  },
  overlay:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  }
}

const ModalTitle = styled.div`
  padding: 15px;
`;

const ModalBody = styled.div`
  padding: 0 30px;
`;

const StyledParagraph = styled.p`
  margin-top: 5px;
`;

const ModalFooter = styled.div`
  margin: 20px 15px;
  padding: 15px;
  text-align: 
  right;
`;


const ConfirmBtn = styled(Button)`
  background: #d60403;
  color: white;
  width: 100%;
  margin: 15px auto 0px;
  display: block;
  max-width:460px;
  font-size: 18px!important;
  height: 40px;
`;

 const CancelButton = styled(Button)`
  color: white;
  background-color: #f7ae11;
  margin-right: 15px;
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  display: block;
  font-size: 18px!important;
  height: 40px;
`;

const DeleteBtn = styled(StyledButton)`
  background: #F12638;
  color: white;
`;

const SwapBtn = styled(StyledButton)`
  background: #A9CF55;
  color: white;
`;

interface ConfirmModalProps extends ThemeProps<ITheme>, WrappedComponentProps{
  title: string;
  body: string | JSX.Element;
  confirmText: string;
  confirmFunc: Function;
  cancelFunc?: Function;
  cancelText?: string;
  cancelHide?: boolean;
  staticBackDrop?: boolean;
}

export const confirmModal = () => {
  
  const [ open, setOpen ] = React.useState(false);
  Modal.setAppElement("#react");

  return{Comp: injectIntl(withTheme(({theme, title, body, confirmText, confirmFunc, cancelText, cancelHide, cancelFunc, staticBackDrop, intl: {formatMessage}}: ConfirmModalProps) => (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      style={
        {
          content: {
            width: "500px",
            height: "fit-content",
            position: "unset",
            background: theme.colors.backgroundPrimary,
            color: "",
          },
          overlay:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }
        }
      }
      shouldCloseOnOverlayClick={staticBackDrop}
    >
      <ModalTitle>
        <h2>{title}</h2>
      </ModalTitle>
      <ModalBody>
        {body}
      </ModalBody>
      <ModalFooter>
        { !cancelHide && <CancelButton
          onClick={() => {
            setOpen(false);
            cancelFunc && cancelFunc();
          }}>
          {cancelText && cancelText}
          {!cancelText && formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
        </CancelButton>}
        <ConfirmBtn onClick={() => confirmFunc()}>
          {confirmText}
        </ConfirmBtn>
      </ModalFooter>
    </Modal>
  ))), func: setOpen}
}

const AccentedText = styled.span`
  color: ${props => props.theme.colors.accent};
`;

const mapSafemateActionStateToProps = ({appData: {isPartner, partnerUser}, settings: {device: { device : { deviceName, deviceSerialNumber, deviId, twin, twinDevice, wifiSupport }}, general : {allowDeviceDeletes}}}: AppState) => {
  return{
    deviceName, deviceSerialNumber, deviId, twin, twinDevice, wifiSupport, allowDeviceDeletes, isPartner, partnerUser
}}

interface  SafemateActionProps extends WrappedComponentProps{
  deviceName: string;
  deviceSerialNumber: string;
  deviId: number;
  twin: boolean;
  allowDeviceDeletes: boolean;
  twinDevice: TwinDevice;
  wifiSupport: boolean;
  customers?: Customer[];
}

const mapDeleteSafemateDispatchToProps = {
  deleteSafemate
}

interface DeleteSafemateProps extends SafemateActionProps{
  deleteSafemate: Routine<ActionFunctionAny<Action<any>>>;
  isPartner: boolean;
  partnerUser: string;
}

export const DeleteSafemate = connect(mapSafemateActionStateToProps, mapDeleteSafemateDispatchToProps)
  (injectIntl(({ isPartner, partnerUser, intl: { formatMessage }, deviceName, deviceSerialNumber, deleteSafemate, deviId, twin, twinDevice, allowDeviceDeletes }: DeleteSafemateProps) => {

  const {Comp, func} = confirmModal();
  const deletesAllowed = isPartner || partnerUser || allowDeviceDeletes;
 
  return(
    <ButtonWrapper>
      <DeleteBtn onClick={() => func(true)}>{formatMessage({id: "deleteSafemate", defaultMessage: "Slett Safemate"})}</DeleteBtn>
      <Tooltip
          popoverId="device-delete-safemate-popover"
          labelId="tooltip_delete"
          defaultMessage="Sletter du en Safemate opphører kostnadene forbundet med abonnement. Du kan legge til en slettet enhet senere."
        >
          <InformationIcon size="medium" />
        </Tooltip>
      <Comp
        title={`${formatMessage({id: "deleteUnit", defaultMessage: "Slett"})} ${formatMessage({id: "unit", defaultMessage: "Enhet"})}`}
        body={
          twin
            ? <p>{`${deviceSerialNumber} ${formatMessage({id: "twinDeleteDesc", defaultMessage: "can ikke bli slettet før den er separert fra"})} ${twinDevice.deviceSerialNumber}`}</p>
            : <p>
                <span>{formatMessage({id: "deleteUnitDesc", defaultMessage: "Vil du virkelig slette enhet"})}</span>
                <AccentedText>{` ${deviceName} (${deviceSerialNumber})?`}</AccentedText>
              </p>
        }
        cancelHide={twin}
        confirmText={twin ? formatMessage({id: "ok", defaultMessage: "Ok"}) : formatMessage({id: "delete", defaultMessage: "Slette"})}
        confirmFunc={() => {
          if(!twin){
            deleteSafemate({
              deviceId: deviId,
              feedback: `${formatMessage({id: "deletedDevice", defaultMessage: "Enhet slettet."})} ${deviceName}`,
              deletesAllowed
            });
          }
          func(false);
        }}
      />
    </ButtonWrapper>
  )
}))

const mapResetSafemateDispatchToProps = {
  resetSafemate
}

interface ResetSafemateProps extends SafemateActionProps{
  resetSafemate: Routine<ActionFunctionAny<Action<any>>>;
}


export const ResetSafemate = connect(mapSafemateActionStateToProps, mapResetSafemateDispatchToProps)
  (injectIntl(({ intl: { formatMessage }, deviceName, deviceSerialNumber, resetSafemate, deviId }: ResetSafemateProps) => {

    const {Comp, func} = confirmModal();

    return(
      <ButtonWrapper>
        <StyledButton onClick={() => func(true)}>
          {formatMessage({id: "defaultSettingsReset", defaultMessage: "Gjenopprett fabrikkinnstillinger"})}
        </StyledButton>
        <Tooltip
          popoverId="device-forget-safemate-popover"
          labelId="tooltip_forget"
          defaultMessage="Dette valget sletter alle historiske data om denne Safematen uten at enhetsinnstillinger påvirkes."
        >
          <InformationIcon size="medium" />
        </Tooltip>
        <Comp
          title={`${formatMessage({id: "restore", defaultMessage: "Gjenopprett"})} ${formatMessage({id: "factorySettings", defaultMessage: "fabrikkinnstillinger"})}`}
          body={
            <React.Fragment>
              <p>
                <span>{formatMessage({id: "defaultSettingsDesc", defaultMessage: "Er du sikker på at du vil gjenopprette fabrikkinnstillinger for"})}</span>
                <AccentedText>{` ${deviceName} (${deviceSerialNumber})?`}</AccentedText>
              </p>
              <StyledParagraph>
                <span>{formatMessage({id: "defaultSettingsNote", defaultMessage: "Merk at dette fører ikke til at historikk blir slettet. Bruk egen funksjon for dette."})}</span>
              </StyledParagraph>
            </React.Fragment>
          }
          confirmText={formatMessage({id: "defaultSettingsReset", defaultMessage: "Gjenopprett fabrikkinnstillinger"})}
          confirmFunc={() => {
            resetSafemate(deviId);
            func(false);
          }}
        />
      </ButtonWrapper>
    )
  })
)

const mapDeleteHistoryDispatchToProps = {
  forgetSafemate
}

interface DeleteHistoryProps extends SafemateActionProps{
  forgetSafemate: Routine<ActionFunctionAny<Action<any>>>;
}

export const DeleteHistory = connect(mapSafemateActionStateToProps, mapDeleteHistoryDispatchToProps)(
  injectIntl(({ intl: { formatMessage }, deviceName, deviceSerialNumber, forgetSafemate, deviId }: DeleteHistoryProps) => {

    const {Comp, func} = confirmModal();

    return(
      <ButtonWrapper>
        <StyledButton onClick={() => func(true)}>
          {formatMessage({id: "forget", defaultMessage: "Slett historikk"})}
        </StyledButton>
        <Tooltip
          popoverId="device-delete-history-safemate-popover"
          labelId="tooltip_delete_history"
          defaultMessage="Sletter du en Safemate opphører kostnadene forbundet med abonnement. Du kan legge til en slettet enhet senere."
        >
          <InformationIcon size="medium" />
        </Tooltip>
        <Comp
          title={`${formatMessage({id: "forgetUnit", defaultMessage: "Glem"})} ${formatMessage({id: "unit", defaultMessage: "Enhet"})}`}
          body={
              <p>
                <span>{formatMessage({id: "forgetUnitDesc", defaultMessage: "Vil du virkelig slette historisk data for enhet "})}</span>
                <AccentedText>{` ${deviceName} (${deviceSerialNumber})?`}</AccentedText>
              </p>
          }
          confirmText={formatMessage({id: "forget", defaultMessage: "Slett historikk"})}
          confirmFunc={() => {
            forgetSafemate(deviId);
            func(false);
          }}
        />
      </ButtonWrapper>
    )
  })
)

const mapClearWifiCacheDispatchToProps = {
  clearWifiCache
}

interface ClearWifiCacheProps extends SafemateActionProps{
  clearWifiCache: Routine<ActionFunctionAny<Action<any>>>;
}

export const ClearWifiCache = connect(mapSafemateActionStateToProps, mapClearWifiCacheDispatchToProps)(
  injectIntl(({ intl: { formatMessage }, deviceName, deviceSerialNumber, clearWifiCache, wifiSupport, deviId }: ClearWifiCacheProps) => {

    const {Comp, func} = confirmModal();

    return(
      <React.Fragment>
        { wifiSupport && <ButtonWrapper>
          <StyledButton onClick={() => func(true)}>
            {formatMessage({id: "clearWifiCacheTitle", defaultMessage: "Clear wifi cache"})}
          </StyledButton>
          <Tooltip
            popoverId="device-clear-wifi-cache-safemate-popover"
            labelId="tooltip_clear_wifi_cache"
            defaultMessage="Clear wifi cache for wifi lookups"
          >
            <InformationIcon size="medium" />
          </Tooltip>
          <Comp
            title={`${formatMessage({id: "clearWifiCacheConfirmTitle", defaultMessage: "Clear wifi cache"})}`}
            body={
              <p>
                <span>{formatMessage({id: "clearWifiCacheConfirmDesc", defaultMessage: "Clear wifi cache for the device"})}</span>
                <AccentedText>{` ${deviceName} (${deviceSerialNumber})?`}</AccentedText>
              </p>
            }
            confirmText={formatMessage({id: "clear", defaultMessage: "Clear"})}
            confirmFunc={() => {
              clearWifiCache(deviId);
              func(false);
            }}
          />
        </ButtonWrapper>}
      </React.Fragment>
    )
  })
)

const mapSwitchSafemateDispatchToProps = {
  switchSafemate
}

interface SwitchSafemateProps extends SafemateActionProps{
  switchSafemate: Routine<ActionFunctionAny<Action<any>>>;
}

const FullWidthInput = styled.input`
  width: 100%;
`;

export const SwitchSafemate = connect(mapSafemateActionStateToProps, mapSwitchSafemateDispatchToProps)(
  injectIntl(({ intl: { formatMessage }, switchSafemate, deviceSerialNumber, deviId }: SwitchSafemateProps) => {

    const {Comp: SwitchModal, func: switchModalToggle} = confirmModal();
    const {Comp: ConfirmModal, func: confirmModalToggle} = confirmModal();

    const input = React.useRef<HTMLInputElement>(null);

    const [ serialNum, setSerialNum ] = React.useState<string>();

    return(
      <ButtonWrapper>
        <SwapBtn onClick={() => switchModalToggle(true)}>
          {formatMessage({id: "switchSafemate", defaultMessage: "Bytt safemate"})}
        </SwapBtn>
        <Tooltip
          popoverId="device-switch-safemate-popover"
          labelId="tooltip_switch"
          defaultMessage="Benytt dette valget om du har Safemate som skal erstattes med en erstatningsenhet. Dette valget får ingen fakturamessige konsekvenser, og den enheten man bytter til arver alle innstillinger fra den gamle Safematen."
        >
          <InformationIcon size="medium" />
        </Tooltip>
        <SwitchModal
          title={`${formatMessage({id: "switch", defaultMessage: "Bytt"})} ${formatMessage({id: "unit", defaultMessage: "Enhet"})}`}
          body={
            <React.Fragment>
              <p>
                <span>{formatMessage({id: "switchUnitDesc", defaultMessage: "Skriv inn serienummer på den nye enheten du skal ta i bruk. Alle innstillinger (varslingsliste, geofence, etc.) vil bevares."})}</span>
              </p>
              <FullWidthInput ref={input} type="text" />
            </React.Fragment>
          }
          confirmText={formatMessage({id: "switch", defaultMessage: "Bytt"})}
          confirmFunc={() => {
            if(input.current)
              setSerialNum(input.current.value);
            switchModalToggle(false);
            confirmModalToggle(true);
          }}
        />
        <ConfirmModal
          title={`${formatMessage({id: "switch", defaultMessage: "Bytt"})} ${formatMessage({id: "unit", defaultMessage: "Enhet"})}`}
          body={
            <p>
              <span>{formatMessage({
                id: "switchUnitConfirmDesc",
                defaultMessage: "Er du sikker på at du vil bytte enheten "
              })}</span>
              <AccentedText>{` ${deviceSerialNumber} `}</AccentedText>
              <span>{formatMessage({
                id: "with",
                defaultMessage: "med"
              })}</span>
              <AccentedText>{` ${serialNum}?`}</AccentedText>
            </p>
          }
          confirmText={formatMessage({id: "switch", defaultMessage: "Bytt"})}
          confirmFunc={() => {
            switchSafemate({
              deviceId: deviId,
              serialNum: serialNum
            })
            confirmModalToggle(false);
          }}
        />
      </ButtonWrapper>
    )
  })
)
