import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import settingsSaga from "./settings";
import customerSaga from "./customer";


function* billingSaga(){
  yield all([
    fork(initializationSaga),
    fork(settingsSaga),
    fork(customerSaga),
    ])
}
export default billingSaga;
