import {call, put, select, takeLatest} from 'redux-saga/effects';
import { EventLog } from "@Safemate/Model/EventLog";
import { AppAction } from "@Safemate/Store/types";
import * as Api from '../api';
import * as Routines from "../routines";
import { selectFilter, selectUserAuditLog } from '../selectors';
import { filterlist } from '@Safemate/Log/utils';
import { LogActionTypes } from '../actionTypes';

export interface GetAuditLog{
  id: number;
  limit: number;
  evenId?:number;
}

function* getUserAuditLog(action: AppAction<GetAuditLog>){
  try {
    const userAuditLog: EventLog[] = yield call(Api.getUserAuditLog, action.payload);
    const filter: number[] = yield select(selectFilter);
    const existingList: EventLog[] = yield select(selectUserAuditLog);
    const list = existingList.concat(userAuditLog);
    console.log(list);
    const filteredUserAuditLog: EventLog[] = filterlist(list, filter);
    yield put(Routines.getUserAuditLog.success({userAuditLog: list, filteredUserAuditLog}));
  }
  catch(e){
    yield put(Routines.getUserAuditLog.failure());
  }
}

function* filterUserAuditLog(action: AppAction<null>){
  const userAuditLog: EventLog[] = yield select(selectUserAuditLog);
  const filter: number[] = yield select(selectFilter);
  const filteredUserAuditLog: EventLog[] = filterlist(userAuditLog, filter);
  yield put(Routines.getUserAuditLog.success({userAuditLog, filteredUserAuditLog}));
}


function* userAuditLog(){
    yield takeLatest(Routines.getUserAuditLog.TRIGGER, getUserAuditLog);
    yield takeLatest(LogActionTypes.SET_FILTER, filterUserAuditLog);
}

export default userAuditLog;
