import PropTypes from 'prop-types';
import React, { Component } from 'react';

const getClassName = function(name, className) {
  let _className = name;
  if (className) {
    _className = `${_className} ${className}`;
  }
  return _className;
};

export class Td extends Component {
  static propTypes = {
    className: PropTypes.string
  };
  render() {
    const { className, children } = this.props;
    return <td className={getClassName('Td', className)}>{children}</td>;
  }
}

export class Th extends Component {
  static propTypes = {
    className: PropTypes.string
  };
  render() {
    const { className, children } = this.props;
    return <th className={getClassName('Th', className)}>{children}</th>;
  }
}

export class Tr extends Component {
  static propTypes = {
    className: PropTypes.string
  };
  render() {
    const { className, children } = this.props;
    return <tr className={getClassName('Tr', className)}>{children}</tr>;
  }
}

export class THead extends Component {
  static propTypes = {
    className: PropTypes.string
  };
  render() {
    const { className, children } = this.props;
    return (
      <thead className={getClassName('Thead', className)}>{children}</thead>
    );
  }
}

export class TBody extends Component {
  static propTypes = {
    className: PropTypes.string
  };
  render() {
    const { className, children } = this.props;
    return (
      <tbody className={getClassName('Tbody', className)}>{children}</tbody>
    );
  }
}

export class Table extends Component {
  static propTypes = {
    className: PropTypes.string
  };
  render() {
    const { className, children } = this.props;
    return (
      <table className={getClassName('Table', className)}>{children}</table>
    );
  }
}
