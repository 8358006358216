import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Link, Redirect, withRouter } from 'react-router-dom';
import { isStyledComponent } from 'styled-components';

export const createRouteTabsComponent = ({
  StyledContainer = 'div',
  StyledTabContainer = 'div',
  StyledTab = 'div',
  StyledContent = 'div'
}) =>
  withRouter(
    class RouteTabs extends Component {
      static propTypes = {
        path: PropTypes.string
      };

      getPath(name) {
        const { path, match } = this.props;
        const matchedUrl = match.url;

        let newUrl = '';
        newUrl += !path ? matchedUrl : path;
        newUrl += newUrl[newUrl.length - 1] !== '/' ? '/' : '';
        newUrl += name;
        return newUrl;
      }

      getRedirectPath() {
        const { children } = this.props;

        const firstTruthyChild = React.Children.toArray(children).filter(
          child => !!child && !child.props.hidden
        )[0];

        const redirectPath = this.getPath(firstTruthyChild.props.name);
        return redirectPath;
      }

      renderTabs(matchedName) {
        const { children } = this.props;

        const isSC = isStyledComponent(StyledTab);
        const Tab = isSC
          ? StyledTab.withComponent(({ active, ...props }) => (
              <Link {...props} />
            ))
          : StyledTab;
        const tabs = React.Children.toArray(children)
          .filter(child => !!child)
          .map(child =>
            child.props.notActuallyATab ? (
              <StyledTab
                length={100/children.length}
                id={`tab-${child.props.name}`}
                tabIndex="0"
                onKeyDown={child.props.onClick}
                onClick={child.props.onClick}
              >
                {child.props.title}
              </StyledTab>
            ) : !child.props.hidden ? (
              <Tab
                length={100/children.length}
                id={`tab-${child.props.name}`}
                to={this.getPath(child.props.name)}
                key={child.props.name}
                active={child.props.name === matchedName}
              >
                {child.props.renderTitle === undefined
                  ? child.props.title
                  : child.props.renderTitle()}
              </Tab>
            ) : null
          );

        return tabs;
      }

      renderContent(matchedName) {
        const { children } = this.props;

        if (React.Children.count(children) < 2) return children;

        const matchedContent = React.Children.toArray(children).find(
          child => child.props.name === matchedName
        );

        return matchedContent;
      }

      render() {
        return (
          <Route
            path={this.getPath(':name?')}
            render={({ match }) => {
              const matchedName = match.params.name;
              const shouldRedirect = matchedName === undefined;
              return shouldRedirect ? (
                <Redirect to={this.getRedirectPath()} />
              ) : (
                <StyledContainer>
                  <StyledTabContainer>
                    {this.renderTabs(matchedName)}
                  </StyledTabContainer>
                  <StyledContent>
                    {this.renderContent(matchedName)}
                  </StyledContent>
                </StyledContainer>
              );
            }}
          />
        );
      }
    }
  );

export default createRouteTabsComponent;
