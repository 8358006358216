import {InitializationState} from "../types";
import {Reducer} from "redux";
import {initialize, initializeUser} from "../routines";
import { getLayout } from "../../LayoutEnum";
import { PartnerOrganizationActionTypes } from "../actionTypes";

const initializationState: InitializationState = {
  initialized: true,
  layout: getLayout(window.innerWidth),
  roles: "",
  user: {
    userId: 0,
    username: "",
    password: "",
    passwordClear: "",
    firstName: "",
    lastName: "",
    mobile: "",
    mailTo: "",
    languageCode: "",
    timezone: "",
    facebookUid: "",
    newsletter: false,
    proffwebDark: false,
    adminForCustId: 0,
    beta: false,
    trainingApprovedBy: 0,
    deleted: false,
    attemptsBeforeLockout: "",
    lastActivity: 0,
    tfaEmail: false,
    tfaEnabled: false,
    gdprPsAccepted: false,
    gdprPsDatetime: "",
    deactivated: false,
    tempDeactivated: false,
    deactivatedFrom: "",
    deactivatedTo: "",
    userType: 0,
    name: "",
    id: 0,
    timeZoneOffset: 0,
    active: false,
    trainingApprovedByName: "",
    coveredByTwoFactorAuthentication: false,
    email: "",
    existing: false,
    isNewUser: false,
    trained: false,
    zoneId: "",
    isExternal: false,
    emailAsUsername: false
  }
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action) => {
  switch (action.type) {
    case initialize.SUCCESS:
      return {
        ...state,
        initialized: true,
        roles: action.payload.roles
      };

    case initializeUser.SUCCESS:
      return {
        ...state,
        user: action.payload
      };

    case PartnerOrganizationActionTypes.SET_LAYOUT:
      return {
        ...state,
        layout: action.payload
      }

    default:
      return state;
  }
};

export default initialization;
