import * as React from "react";
import { injectIntl } from "react-intl";
import { Field } from "formik";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";

const Label = styled.label`
  text-transform: capitalize;
  display: block;
  @media only screen and (min-width: 992px) {
    padding-left: 20px;
  }
`;

const LabelNoCapitalize = styled.label`
  text-transform: none;
  display: block;
  @media only screen and (min-width: 992px) {
    padding-left: 20px;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledCol = styled(Col)`
  input[type="text"], input[type="password"] {
    height: 30px;
  }
`

const StyledMainRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center;
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }

`;

export const OrgNum = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <StyledCol md={4}>
        <Label>{formatMessage({id: "orgNr", defaultMessage: "Org.nr"})}</Label>
      </StyledCol>
      <StyledCol md={8}>
        <StyledField
            type="text"
            name="orgNum"
          />
      </StyledCol>
    </StyledMainRow>
  )
})

export const Email = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <StyledCol md={4}>
        <LabelNoCapitalize>{formatMessage({id: "email", defaultMessage: "E-post"})}</LabelNoCapitalize>
      </StyledCol>
      <StyledCol md={8}>
      <StyledField
          type="text"
          name="email"  
        />
      </StyledCol>
    </StyledMainRow>
  )
})

export const CompanyName = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <StyledCol md={4}>
        <Label>{formatMessage({id: "virksomhetsnavn", defaultMessage: "Virksomhetsnavn"})}</Label>
      </StyledCol>
      <StyledCol md={8}>
        <StyledField
            type="text"
            name="lastName"
          />
      </StyledCol>
    </StyledMainRow>
  )
})
