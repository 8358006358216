import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import paymentProfile from "./paymentProfile";
import customerSaga from "./customer";


function* defaultSettingsSaga(){
  yield all([
    fork(initializationSaga),
    fork(paymentProfile),
    fork(customerSaga),
    ])
}
export default defaultSettingsSaga;
