import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";
import { getMessages, sendMessage } from "../Store/routines";
import { connect } from "react-redux";
import { IMessage } from "../types";
import moment from "moment";
import { AppState } from "@Safemate/Store/types";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const HistoryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  max-height: 12em;
  overflow: auto;
  margin: 10px 0px;
`;

const SendWrapper = styled.div`
  width: 100%;
  height: 2em;
  display: flex;
`;

const SendInput = styled.input`
  flex: 1;
`;

const DateWrapper = styled.span`
  flex: 1;
`;

const MessageCard = styled.div`
  border: 1px solid ${props => props.theme.colors.textPrimary};
  border-radius: 5px;
  margin: 2px 0px;
`;

const MessageSection = styled.div`
  padding: 10px;
`;

const StatusSection = styled.div`
  border-top: ${props => props.theme.colors.border};
  font-style: italic;
  margin: 0px 5px;
  display: flex;
  justify-content: end;
  font-variant: all-petite-caps;
`;

export enum MessageStatusEnum{
  PENDING = "PENDING",
  NOT_DELIVERED = "NOT_DELIVERED",
  RECEIVED = "RECEIVED",
  READ = "READ",
  NOT_READ = "NOT_READ"
}

const mapStateToProps = ({ settings: {initialization: { deviId }, info: { messages }}}: AppState) => {
  return {
    deviId,
    messages
  }
}

const mapDispatchToProps = {
  sendMessage,
  getMessages
}

interface MessageProps{
  sendMessage: Routine<ActionFunctionAny<Action<any>>>;
  getMessages: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  messages: IMessage[];
}

const Message = connect(mapStateToProps, mapDispatchToProps)(({sendMessage, getMessages, messages, deviId}: MessageProps) => {

  const { formatMessage } = useIntl();
  const [ message, setMessage ] = React.useState("");

  React.useEffect(() => {
    getMessages(deviId);

    const refresh = setInterval(() => {
      getMessages(deviId);
    }, 10000);
    return () => {
      clearTimeout(refresh);
    }

  }, [])

  const sendMsg = () => {
    sendMessage({
      deviId,
      message
    })
    setMessage("");
  }

  return(
    <Wrapper>
      <HistoryWrapper>
        {messages.length > 0 
          ? messages.sort(sortMessages).map(message => {
              return (
                <MessageCard>
                  <MessageSection>
                    {message.message}
                  </MessageSection>
                  <StatusSection>
                    <DateWrapper>
                      {moment(message.created).format("LLLL")}
                    </DateWrapper>
                    {formatMessage({id: `MESSAGE_STATUS_${message.status}`, defaultMessage: message.status})}
                  </StatusSection>
                </MessageCard>
              )
            })
          : <MessageCard>
              <MessageSection>
                {formatMessage({id: "noMessageHistory", defaultMessage: "Ingen meldingshistorikk"})}
              </MessageSection>
            </MessageCard>
        }
      </HistoryWrapper>
      <SendWrapper>
        <SendInput maxLength={20} value={message} onChange={(e) => setMessage(e.target.value)} type="text"/>
        &nbsp;&nbsp;
        <Button onClick={sendMsg}>{formatMessage({id: "send", defaultMessage: "Send"})}</Button>
      </SendWrapper>
    </Wrapper>
  )
})

const sortMessages = (a: IMessage, b: IMessage) => {
  return a.created > b.created ? -1 : 1;
}

export default Message;
