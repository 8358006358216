export enum TransactionSource{
  HTTP = 1,
  SMS = 2,
  TCP = 3,
  SERVER = 4,
  XMPP = 5,
  SCAIP = 6,
  HISTORICAL = 7,
  MQTT = 8,
}
