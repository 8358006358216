import * as React from "react";
import {connect as formikConnect} from "formik";
import {useIntl} from "react-intl";
import {connect as reduxConnect} from "react-redux";
import {Collapse} from "react-collapse";

import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {AllPermissions, PolicyContext} from "../../../Policy/Provider";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {InnerFrame, SwitchHeader} from "./styles";
import Switch from "../../../../Elements/Switch";
import DateSelector from "./DateSelector";
import {LabelEnum} from "../../LabelEnum";
import { AppState } from "@Safemate/Store/types";
import {Moment} from "moment";


interface TemporaryDisableProps{
  isNew: boolean;
}

const mapStateToProps = ({users: {action: {edit: {isNew}}}}: AppState) => {
  return {
    isNew
  }
}

const TemporaryDisable = reduxConnect(mapStateToProps)(
  formikConnect<TemporaryDisableProps, UserWithCustomer>(
    ({isNew, formik: {setFieldValue, values: {user: {tempDeactivated, deactivatedTo, deactivatedFrom}}}}) => {

      const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
      const isAllowed = permissions.has(PolicyNameEnum.ACTIVATE) && permissions.get(PolicyNameEnum.ACTIVATE).generalPermissions.all;
      const {formatMessage} = useIntl();

      if (!isAllowed || isNew) return null;

      return (
        <InnerFrame border>
          <SwitchHeader>
            {formatMessage({id: LabelEnum.TEMPORARY_DEACTIVATE_USER, defaultMessage: "Midlertidig Deaktivere Bruker"})}
          </SwitchHeader>
          <Switch
            checked={tempDeactivated}
            onChange={(value: boolean) => {
              setFieldValue("user.tempDeactivated", value);
            }}
          />
          <Collapse isOpened={tempDeactivated}>
            <DateSelector
              disable={!tempDeactivated}
              values={{
                to: deactivatedTo as Moment,
                from: deactivatedFrom as Moment,
                toField: "user.deactivatedTo",
                fromField: "user.deactivatedFrom"
              }}
            />
          </Collapse>
        </InnerFrame>
      )
    }
  )
)


export default TemporaryDisable;
