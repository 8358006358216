import React from 'react';
import styled from 'styled-components';
import extendIcon from '../extendIcon';

const Log = props => {
  return (
    <svg
      viewBox="0 0 128 128"
      width="1em"
      height="1em"
      {...props}
      cursor="pointer"
    >
      ><title>{props.title || 'Log'}</title>
      <path
        d="M127.154,64.469c0,31.276-25.511,56.787-56.786,56.787c-15.727,0-30.054-6.465-40.188-16.774l8.736-8.736
                c8.037,7.861,19.219,12.93,31.452,12.93c24.462,0,44.205-19.743,44.205-44.206S94.83,20.262,70.368,20.262
                c-21.494,0-39.489,14.852-43.509,35.12l14.153-2.097L20.918,81.416L1,53.285l13.279,1.921C18.473,28.125,41.887,7.682,70.368,7.682
                C101.644,7.682,127.154,33.017,127.154,64.469z"
      />
      <path
        d="M91.159,45.947c7.34-3.145,12.23,8.212,5.069,11.533l-22.715,9.084l-4.719,12.754C66,87.356,54.468,82.116,57.088,74.952
                l5.416-15.028c0.699-1.572,1.573-2.795,2.971-3.321L91.159,45.947z"
      />
    </svg>
  );
};

export default extendIcon(Log);
