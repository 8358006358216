import {call, put, takeLatest} from "redux-saga/effects";

import * as Api from '../api';
import * as Routines from '../routines';
import { AppAction } from "@Safemate/Store/types";
import { FirmwareForDevice } from "../types";


function* fetchDeviceFirmware(action: AppAction<number>){

    try{
        const firmware: FirmwareForDevice = yield call(Api.getDeviceFirmware, action.payload);
        yield put(Routines.fetchDeviceFirmware.success({firmware, id: action.payload}));
      }
      catch(e){
        yield put(Routines.fetchDeviceFirmware.failure());
      }
}

function* firmwareSaga(){
  yield takeLatest(Routines.fetchDeviceFirmware.TRIGGER, fetchDeviceFirmware);
}

export default firmwareSaga;
