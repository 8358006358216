import {call, put, takeLatest, race, take, select} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction, AppState, IRace } from '../../../Store/types';
import * as Routines from "../routines";
import { EventViewAlarm } from '@Safemate/Model/EventViewAlarm';
import { prepareAlarms } from '@Safemate/EventView/utils';

function* initialize(action: AppAction<null>){
  try {
    yield put(Routines.initializeAlarms());
    const {success: alarmSuccess, failure: alarmFailure}: IRace<any> = yield race({
      success: take(Routines.initializeAlarms.SUCCESS),
      failure: take(Routines.initializeAlarms.FAILURE)
    });

    if(alarmSuccess){
      yield put(Routines.initialize.success());
    }
    else{
      yield put(Routines.initialize.failure());
    }
  }
  catch(e){
    yield put(Routines.initialize.failure(e));
  }
}

export const getHistoricLimit = ({ eventView: {alarm: { historicLimit }}}: AppState) => historicLimit;

function* initializeAlarms(action: AppAction<null>){
  try{
      const alarms: EventViewAlarm[] = yield call(Api.getAlarms)
      const historicLimit: number = yield select(getHistoricLimit);
      const historic: EventViewAlarm[] = yield call(Api.getHistoricAlarms, historicLimit);
      prepareAlarms(alarms);
      prepareAlarms(historic);
      yield put(Routines.initializeAlarms.success({alarms, historic}));
  }
  catch(e){
      yield put(Routines.initializeAlarms.failure(e));
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.initializeAlarms.TRIGGER, initializeAlarms);
}

export default initializationSaga;
