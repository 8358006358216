import * as React from "react";
import Tooltip from "@Elements/Tooltip";
import * as Icons from "@Icons/FontAwesome"
import { MessageDescriptor } from "react-intl";
import { ISensorDTO } from "./types";
import { SensorTypeEnum } from "./SensorTypeEnum";

export enum SensorStatusEnum{
  UNKNOWN = "unknown",
  LOW_BATTERY = "low_battery",
  TAMPER = "tamper",
  OUT_OF_ORDER = "out_of_order",
  NOT_FOUND = "not_found",
  CONTROL_ERROR = "control_error",
  PENDING_CREATE = "pending_create",
  PENDING_READ = "pending_read",
  PENDING_UPDATE = "pending_update",
  PENDING_DELETE = "pending_delete",
  OK = "ok",
  PROCESSING_REQUIRED = "proc_req",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected"
}

export const mapStatus = (sensor: ISensorDTO, formatMessage: (message: MessageDescriptor) => string) => {
  switch(sensor.sensor.type){
    case SensorTypeEnum.WRIST_BAND:
    case SensorTypeEnum.BASE_STATION:
      return getStatusIcon(sensor.sensor.connected ? SensorStatusEnum.CONNECTED : SensorStatusEnum.DISCONNECTED, formatMessage);
    case SensorTypeEnum.SAFEMATE_HOME:
      return getStatusIcon(sensor.status.icon, formatMessage);

    default: return null;
  }
}

export const getStatusIcon = (icon: SensorStatusEnum | string, formatMessage: (message: MessageDescriptor) => string) => {

  switch (icon) {
    case SensorStatusEnum.UNKNOWN:
      return (
        <Tooltip
          popoverId="unknown_status_popover"
          labelId="sensorUnknown"
          defaultMessage="Statusen til denne sensoren er ukjent"
        >
          <Icons.Unknown title={formatMessage({id: "sensorUnknown", defaultMessage: "Statusen til denne sensoren er ukjent"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.LOW_BATTERY:
      return (
        <Tooltip
          popoverId="sensor_low_battery_popover"
          labelId="sensorLowBattery"
          defaultMessage="Denne sensoren har lavt batteri"
        >
          <Icons.LowBattery title={formatMessage({id: "sensorLowBattery", defaultMessage: "Denne sensoren har lavt batteri"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.TAMPER:
      return (
        <Tooltip
          popoverId="sensor_tamper_popover"
          labelId="sensorNotMounted"
          defaultMessage="Denne sensoren er ikke montert"
        >
          <Icons.Triangle title={formatMessage({id: "sensorNotMounted", defaultMessage: "Denne sensoren er ikke montert"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.OUT_OF_ORDER:
      return (
        <Tooltip
          popoverId="sensor_out_of_order_popover"
          labelId="sensorOutOfOrder"
          defaultMessage="Denne sensoren er ute av stand"
        >
          <Icons.OutOfOrder title={formatMessage({id: "sensorOutOfOrder", defaultMessage: "Denne sensoren er ute av stand"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.NOT_FOUND:
      return (
        <Tooltip
          popoverId="not_found_popover"
          labelId="sensorSelfCheckFailed"
          defaultMessage="Denne sensoren klarte ikke å kommunisere med serveren"
        >
          <Icons.SelfCheckFail title={formatMessage({id: "sensorSelfCheckFailed", defaultMessage: "Denne sensoren klarte ikke å kommunisere med serveren"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.CONTROL_ERROR:
      return (
        <Tooltip
          popoverId="control_error_popover"
          labelId="sensorControlError"
          defaultMessage="Denne sensoren klarte ikke å kommunisere med GX-8"
        >
          <Icons.ControlError title={formatMessage({id: "sensorControlError", defaultMessage: "Denne sensoren klarte ikke å kommunisere med GX-8"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.PENDING_CREATE:
      return (
        <Tooltip
          popoverId="pending_create_popover"
          labelId="pendingCreate"
          defaultMessage="Sensoren er lagt til i systemet, men den har ikke fått kontakt med GX8. Når serveren får kontakt med GX8 blir sensoren registrert"
        >
          <Icons.PendingCreate title={formatMessage({id: "pendingCreate", defaultMessage: "Sensoren er lagt til i systemet, men den har ikke fått kontakt med GX8. Når serveren får kontakt med GX8 blir sensoren registrert"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.PENDING_READ:
      return (
        <Tooltip
          popoverId="pending_read_popover"
          labelId="pendingRead"
          defaultMessage="Sensoren er registrert på GX8 og systemet trenger informasjon om sensoren fra GX8"
        >
          <Icons.PendingRead title={formatMessage({id: "pendingRead", defaultMessage: "Sensoren er registrert på GX8 og systemet trenger informasjon om sensoren fra GX8"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.PENDING_UPDATE:
      return (
        <Tooltip
          popoverId="pending_update_popover"
          labelId="pendingUpdate"
          defaultMessage="Sensoren har blitt oppdatert, men oppdateringene har ikke kommet fram til GX8"
        >
          <Icons.PendingUpdate title={formatMessage({id: "pendingUpdate", defaultMessage: "Sensoren har blitt oppdatert, men oppdateringene har ikke kommet fram til GX8"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.PENDING_DELETE:
      return (
        <Tooltip
          popoverId="pending_delete_popover"
          labelId="pendingDelete"
          defaultMessage="Sensoren er lagt til i systemet, men den har ikke fått kontakt med GX8. Når serveren får kontakt med GX8 blir sensoren registrert"
        >
          <Icons.PendingDelete title={formatMessage({id: "pendingDelete", defaultMessage: "Sensoren er lagt til i systemet, men den har ikke fått kontakt med GX8. Når serveren får kontakt med GX8 blir sensoren registrert"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.OK:
      return (
        <Tooltip
          popoverId="sensor_ok_popover"
          labelId="sensorOk"
          defaultMessage="Alt er ok"
        >
          <Icons.Ok title={formatMessage({id: "sensorOk", defaultMessage: "Alt er ok"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.PROCESSING_REQUIRED:
      return (
        <Tooltip
          popoverId="procReq_popover"
          labelId="procReqTooltip"
          defaultMessage="Trenger konfigurasjon"
        >
          <Icons.ProcessingRequired title={formatMessage({id: "procReqTooltip", defaultMessage: "Trenger konfigurasjon"})} mediumSecondary />
        </Tooltip>
      );
    case SensorStatusEnum.CONNECTED:
      return (
        <Tooltip
          popoverId="connected_popover"
          labelId="connected"
          defaultMessage="Tilkoblet"
        >
          <Icons.Ok title={formatMessage({id: "connected", defaultMessage: "Tilkoblet"})} mediumSecondary />
        </Tooltip>
      )
    default:
      return null;
  }
};

export const isError = (code: string) => {
  return code === SensorStatusEnum.NOT_FOUND || code === SensorStatusEnum.OUT_OF_ORDER || code === SensorStatusEnum.CONTROL_ERROR;
}