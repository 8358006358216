import * as React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Formik, Field, Form } from "formik";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Col, Grid } from "react-bootstrap";
import styled from "styled-components";

import { SettingEnum as ComponentSettingEnum } from "../../settingEnum";
import { ManDownForm  } from "../types";
import { DeviceComponents } from "../../tabDefinition";
import { DeviceHardwareEnum } from "../../../DeviceHardwareEnum";
import { Footer, ButtonContainer, StyledForm, ComponentWrapper, SettingWrapper } from "./styled";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../../Elements/Dropdown/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { confirmModal } from "./deleteData";
import Toggle from "@Elements/Switch/Toggle";
import { RadioGroup, Radio } from "../../../../Elements/RadioFormik";
import { CancelButton, SaveButton } from "../../../DefaultSettings/Settings/styled";
import { saveManDownSettings } from "../../Store/routines";
import { AppState } from "@Safemate/Store/types";

interface ManDownSettingsProps {
    dehaId: number;
    deviId: number;
    manDownSettings: ManDownForm;
    saveManDownSettings: Routine<ActionFunctionAny<Action<any>>>;
}
  
const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: {manDownSettings}}}: AppState) => {
return{
    manDownSettings,
    dehaId,
    deviId
}
}

const mapDispatchToProps = {
    saveManDownSettings
}

const StyledCol = styled(Col)`
    margin-bottom: 1em;
`;

const StyledGrid = styled(Grid)`
    width: 100%;
`;


const TitleWrapper = styled.div`

    padding: 10px 0;

`;


export const ManDown = connect(mapStateToProps, mapDispatchToProps)(
({ dehaId, deviId, manDownSettings, saveManDownSettings }: ManDownSettingsProps) => {

    const { formatMessage } = useIntl();

    const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;
    
    return(
    <Formik
        onSubmit={(values) => {
            saveManDownSettings({deviceId: deviId, settings: values})}
        }
        enableReinitialize
        initialValues={manDownSettings}
    >    
        {({dirty, setValues}) => {
        return(
            <StyledForm>
            <h2>{formatMessage({id: "manDownSettings", defaultMessage: "Man down innstillinger"})}</h2>
            <SettingWrapper>
                {components.has(ComponentSettingEnum.MAN_DOWN_SETTINGS) 
                    && components.get(ComponentSettingEnum.MAN_DOWN_SETTINGS)?.map(comp => 
                    <ComponentWrapper>{comp}</ComponentWrapper>)}
                </SettingWrapper>
            <Footer>
                <ButtonContainer disabled={!dirty}>
                <CancelButton disabled={!dirty} onClick={() => setValues(manDownSettings)}>
                    {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
                </CancelButton>
                <SaveButton disabled={!dirty} type="submit">
                    {formatMessage({id: "save", defaultMessage: "Lagre"})}
                </SaveButton>
                </ButtonContainer>
            </Footer>
            </StyledForm>
        )}}
    </Formik>
    )
})


export const FallDownAlarm = 
  withFormWrapper<ManDownForm, {}>(({ formik: { setFieldValue, values: { fallDownAlert } } }) => {

    const { formatMessage } = useIntl();

    return(
      <React.Fragment>
        <h3>{formatMessage({id: "fallDownAlert", defaultMessage: "Fallalarm"})}</h3>
        <div>
        <Toggle
          onChange={(value: boolean) => {
            setFieldValue("fallDownAlert", value);
          }}
          checked={fallDownAlert}
        />
        </div>
      </React.Fragment>
    )
  }
)

export const WatchTwoRadioManDown = 
  withFormWrapper<ManDownForm, {}>(({ formik: { setFieldValue, values: { fallDownSensitivity, fallDownAlert } } }) => {

    const { formatMessage } = useIntl();


    React.useMemo(() => {
      setFieldValue("fallDownSensitivity", fallDownSensitivity.toString());
      if(fallDownSensitivity == 0 || fallDownSensitivity == "0"){
        setFieldValue("fallDownAlert",false);
      }else{
        setFieldValue("fallDownAlert",true);
      }
    }, [fallDownSensitivity])



    
    

    return(
      <React.Fragment>
        <h3>{formatMessage({id: "fallDownAlert", defaultMessage: "Fallalarm"})}</h3>
        <div>
        <RadioGroup disabled={false}>
              <Field
                component={Radio}
                name="fallDownSensitivity"
                radioValue={"0"}
                id="fall-down-1"
                label={formatMessage({id: 'manDownOff', defaultMessage: 'Off'})}
                />
              <Field
                component={Radio}
                name="fallDownSensitivity"
                id="fall-down-2"
                radioValue={"1"}
                label={formatMessage({id:'manDownLowSensitivity', defaultMessage: 'Low sensitivity'})}
              />
              <Field
                component={Radio}
                name="fallDownSensitivity"
                radioValue={"2"}
                id="fall-down-3"
                label={formatMessage({
                  id: 'manDownHighSensitivity',
                  defaultMessage: 'High Sensitivity'
                })}
              />
            </RadioGroup>
        </div>
      </React.Fragment>
    )
  }
)
  
  
export const TiltAlert = 
withFormWrapper<ManDownForm, {}>(({ formik: { setFieldValue, values: { tiltAlert } } }) => {

  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      <h3>{formatMessage({id: "tiltAlert", defaultMessage: "Tiltalarm"})}</h3>
      <div>
      <Toggle
        onChange={(value: boolean) => {
          setFieldValue("tiltAlert", value);
        }}
        checked={tiltAlert}
      />
      </div>
    </React.Fragment>
  )
}
)
