import { AppAction } from "../../../../Safemate/Store/types";
import {TranslationModuleActionTypes as Actions} from "./actionTypes";
import { TranslationEventBody } from "./types";

export type ResetSelected = () => AppAction<string>

export type AddEmail = (email: TranslationEventBody) => AppAction<TranslationEventBody>

export type AddSMS = (sms: TranslationEventBody) => AppAction<TranslationEventBody>

export type SetModalState = (open: boolean) => AppAction<boolean>

export const resetSelected: ResetSelected = () => ({
  type: Actions.RESET_SELECTED,
  payload: ""
})

export const addEmail: AddEmail = (email) => ({
  type: Actions.ADD_EMAIL,
  payload: email
})

export const addSMS: AddSMS = (sms) => ({
  type: Actions.ADD_SMS,
  payload: sms
})

export const setModalState: SetModalState = (open) => ({
  type: Actions.SET_MODAL_STATE,
  payload: open
})
