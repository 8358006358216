import * as React from "react";
import { connect } from "react-redux";
import {Formik, Form, Field,} from "formik";
import styled from "styled-components";
import { Col, Row, Button } from "react-bootstrap";
import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {
  saveIndoorLocation
} from "@Safemate/Settings/Store/routines";
import {
  setDisplayEdit, SetDisplayEdit,
  setDisplayIndoorDevices, SetDisplayIndoorDevices,
  setIndoorLocation, SetIndoorLocation,
} from "@Safemate/Settings/Store/actions";
import {IndoorLocationMap} from "@Safemate/Settings/IndoorLocation/Map/map";
import {IndoorLocationEdit} from "@Safemate/Settings/IndoorLocation/pages/edit";
import {ReferencePointsEdit} from "@Safemate/Settings/IndoorLocation/pages/indoorReferencePoints";
import {
  ValuesListenerIndoorLocation,
} from "@Safemate/Settings/IndoorLocation/pages/indoorValuesListener";
import {FooterIndoorLocation} from "@Safemate/Settings/IndoorLocation/pages/footer";
import { AppState } from "@Safemate/Store/types";

export const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

export const StyledField = styled(Field)`
  width: 100%;
`;

export const CancelButton = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};
  margin-right: 15px;
`;

export const DeleteBtn = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    fill: white;
  }
  background: #d60403;
  color: white;
  width: 100%;
  padding: 20px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    margin-right: 0px;
    min-width: 230px;
  }
`;

export const IconBtn = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    fill: ${props => props.theme.colors.backgroundPrimary};
  }
  background: ${props => props.theme.colors.accent};
  color: ${props => props.theme.colors.backgroundPrimary};
  width: 100%;
  padding: 20px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    margin-right: 0px;
    min-width: 230px;
  }
`;

export const AccentedText = styled.span`
  color: ${props => props.theme.colors.accent};
`;

export const ListRow = styled(Row)`
  min-height: 48px;
  align-items: center;
  margin: 0px 0px 5px 0px;
  border: ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.backgroundPrimary};
  color: ${props => props.theme.colors.textPrimary};
`;

export const ListColWrapperLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
`;
export const ListColWrapperRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  float: right;
`;

export const ListCol = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
`;

export const ListColRight = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  justify-content: right;
  margin-left: 5px;
`;

export const Margined1EMRow = styled(Row)`
  margin: 1em;
`;

export const RowVAlign = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColVAlign = styled(Col)`
  max-width: 100%;
`;

export const IndoorHeading = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.textPrimary};
  margin-bottom: 15px;
  display: flex;
  text-align: center;
  justify-content: center;
`;

export const VibrationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  padding: 20px;
`;

export const ColFlexCenter = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FooterNoBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IndoorLocationProps{
  valuesChanged: boolean;
  indoorLocation: IndoorLocation;
  deviceSerialNumber: string;
  saveIndoorLocation: Routine<ActionFunctionAny<Action<any>>>;
  setIndoorLocation: SetIndoorLocation;
  setDisplayEdit: SetDisplayEdit;
  setDisplayIndoorDevices: SetDisplayIndoorDevices;
  displayMap: boolean;
  displayEdit: boolean;
  displayIndoorDevices: boolean;
  initialIndoorLocation: IndoorLocation;
}

const IndoorLocationComp =
  ( {
      indoorLocation,
      valuesChanged,
      deviceSerialNumber,
      saveIndoorLocation,
      setIndoorLocation,
      displayMap,
      displayEdit,
      displayIndoorDevices,
      setDisplayEdit,
      initialIndoorLocation,
    }: IndoorLocationProps) => {


  React.useEffect(() => {
    setIndoorLocation(indoorLocation);
    if(indoorLocation.inloId > 0){
      setDisplayEdit(true);
    } else {
      setDisplayEdit(false);
    }
  }, []);

  if( indoorLocation.inloId === initialIndoorLocation.inloId ){
    return (
      <React.Fragment>
        <Formik
          onSubmit={(values) => {
            saveIndoorLocation(values.indoorLocation);
          }
          }
          enableReinitialize
          initialValues={{ indoorLocation: initialIndoorLocation }}
        >
          {({ values, setFieldValue, dirty }) => (
              <React.Fragment>
                {displayEdit &&
                  <React.Fragment>
                    <Row>
                      <Col xs={12} sm={12} md={12} style={{textAlign: "center", paddingBottom: "1em"}}>
                        <IndoorHeading>{indoorLocation.name}</IndoorHeading>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12}>
                        <div>
                          <Row>
                            <Col xs={12} sm={12} md={12}>
                              <IndoorLocationEdit />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>

                    }
              { !displayEdit &&  <Form>
                <React.Fragment>
                  {displayMap &&
                    <Row>
                      <Col xs={12} sm={12} md={12}>
                        <IndoorLocationMap />
                      </Col>
                    </Row> }

                  {displayIndoorDevices &&
                    <Row>
                      <Col xs={12} sm={12} md={12}>
                        <ReferencePointsEdit />
                      </Col>
                    </Row> }

                  <RowVAlign>
                    <ColVAlign xs={12} sm={12} md={12}>
                      <FooterIndoorLocation />
                    </ColVAlign>
                  </RowVAlign>

                  <ValuesListenerIndoorLocation />
                  <UnsavedCheck dirtyOverride={() => {
                    if(valuesChanged)
                      return true;
                    else{
                      return false;
                    }
                    return false;
                  }}/>
                </React.Fragment>
              </Form> }
            </React.Fragment>
          )}
        </Formik>
      </React.Fragment>

    )
  } else {
    return (<React.Fragment></React.Fragment>)
  }

}


const mapStateToProps = ({  settings: {device: { device: { deviceSerialNumber } },
                           indoorLocation: { displayMap, displayEdit, displayIndoorDevices, valuesChanged, initialIndoorLocation} }}: AppState) => {
  return {
    deviceSerialNumber, displayMap, displayEdit, displayIndoorDevices, valuesChanged, initialIndoorLocation
  }
}

const mapDispatchToProps = {
  saveIndoorLocation,
  setIndoorLocation,
  setDisplayEdit,
  setDisplayIndoorDevices,
};

export const IndoorLocationComponent =  connect(mapStateToProps, mapDispatchToProps)(IndoorLocationComp);
