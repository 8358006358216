import * as React from "react";

import {Tab} from '../../Elements/Tabs';
import { getMain } from "./tabDefinition";



type MapTabs = (formatMessage: Function) => JSX.Element[];

export const mapTabs: MapTabs = (formatMessage) => {
  const comps = getMain();

  const entries = comps.entries();
  let result = entries.next();
  const tabs: JSX.Element[] = [];

  while(!result.done){
    const [ menu, comp ] = result.value;
      tabs.push(
        <Tab name={menu} title={formatMessage({id: menu, defaultMessage: menu})}>
          {comp}
        </Tab>
      )
    result = entries.next();
  }
  return tabs;
}
