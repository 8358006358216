import React from 'react';

const Phone = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width={props.width || "128"}
      height={props.height || "128"}
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="m 40.646915,72.162005 c 9.581245,18.776528 38.528643,41.270685 47.206609,39.673625 8.678657,-1.59655 12.125318,-8.53306 12.602946,-11.997111 0.47864,-3.463714 -14.88458,-17.909348 -18.904496,-20.899091 -4.020084,-2.989573 -10.712296,2.17114 -13.840991,4.34014 -3.214477,2.033851 -11.18025,-8.192919 -14.476083,-13.206926 0,0 -8.288152,-14.794226 -5.073507,-16.828248 3.214636,-2.034016 10.879602,-5.820379 9.658529,-10.593195 -1.221417,-4.77281 -8.464636,-24.399815 -12.006756,-25.436815 -3.429504,-1.011225 -11.316808,-0.732523 -16.328833,6.308737 -5.012026,7.04126 2.914685,40.076903 16.680944,56.233914"
        id="path3300"
      />
    </svg>
  );
};

export default Phone;
