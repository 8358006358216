import * as React from "react";
import { useIntl } from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import Modal from "react-modal";
import { 
  getAlarmAnalysis, 
  initialize, 
  shareAlarm, 
  closeAll,
  createComment,
  createCommentAndClose,
  createCommentAndReOpen,
  deleteComment,
  getHistoricAlarmsAndIncrease } from "./Store/routines";
import { connect } from "react-redux";
import { Col, Grid, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { AppState } from "@Safemate/Store/types";
import { AHPComment, EventViewAlarm } from "@Safemate/Model/EventViewAlarm";
import { getIcon } from "@Safemate/DeviceList/DeviceList/utils";
import { getEventIcon } from "./utils";
import { formatDate } from "@Safemate/utils";
import styled from "styled-components";
import Share from "@Elements/Icon/Icons/Safemate/solid/Share";
import { StyledModal } from "@Safemate/DeviceList/DeviceList/TableElements";
import { Close, Delete } from "@Elements/Icon/Icons/FontAwesome";
import { Timeline } from "@Safemate/Timeline";
import { AlarmAnalysis, AlarmAnalysisState } from "@Safemate/DeviceList/Store/types";

import Edit from "@Elements/Icon/Icons/Edit";
import Trash from "@Elements/Icon/Icons/Trash";

const AddressTd = styled.td<{weak?: boolean}>`
  ${props => props.weak ? `color: ${props.theme.eventViewColors.weakAddress};` : ""}
`;

const StyledPopover = styled(Popover)`
  padding: 0;

  .popover-content{
    padding: 0;
  }
`;

const Status = styled.div<{status: number}>`
  display: inline-block;
  width: 6em;
  text-align: center;
  border-radius: 5px;		
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  font-size: 80%;
  line-height: 1.7em;
  margin-bottom: 0px;
  height: auto;

  ${props => `background-color: ${props.status === 1 ? "#f62538" : props.status === 4 ? "#f5a623" : props.status === 5 ? "#a525f6" : props.status === 3 ? "#5ba40b" : props.status === 2 ? "blue" : ""}`}
`;

interface EventViewProps{
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  alarms: EventViewAlarm[];
  historicAlarms: EventViewAlarm[];
  historicLimit: number;
  getHistoricAlarmsAndIncrease: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ eventView: { alarm: { alarms, historic, historicLimit } } }: AppState) => {
  return {
    alarms,
    historicAlarms: historic,
    historicLimit
  }
}

const mapDispatchToProps = {
  initialize,
  getHistoricAlarmsAndIncrease
}

const EventView = connect(mapStateToProps, mapDispatchToProps)(({ initialize, alarms, historicAlarms, historicLimit, getHistoricAlarmsAndIncrease }: EventViewProps) => {

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    initialize();
  }, [])

  const [ historic, setHistoric ] = React.useState(false);
  const [ createEditComment, setCreateEditComment ] = React.useState<AHPComment>();
  const [ deleteComment, setDeleteComment ] = React.useState<AHPComment>();
  const [ shareAlarm, setShareAlarm ] = React.useState<number>();
  const [ closeAll, setCloseAll ] = React.useState<CloseAll>();

  return(
    <React.Fragment>
    <Grid className="eventview" id="eventview" fluid>
      <Row className="headline">
        <Col sm={6}>
          <h1>
            <span>{formatMessage({id: "alarm", defaultMessage: "Alarm"})}</span><span className="highlight">{formatMessage({id: "operator", defaultMessage: "Operatør"})}</span>
          </h1>
        </Col>
        <div style={{float: "right"}}>
          <button 
            className={`btn ${historic ? "btn-default" : "btn-gray"}`} 
            onClick={() => {
              setHistoric(!historic);
            }}
          >
            {formatMessage({id: "historyBtn", defaultMessage: "Historikk"})}
          </button>
        </div>
      </Row>
      {((!historic && alarms.length === 0) || (historic && historicAlarms.length === 0 ))  
        ? <div className="text-align-center margin-top-50">
          <h2 data-text="noAlarms">Ingen alarmer</h2>
        </div>
        : <Row className="list-table">
            <Col sm={12}>
              <table className="customeOne-table margin-top-30">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{formatMessage({id: "time", defaultMessage: "Tid"})}</th>
                    <th>{formatMessage({id: "name", defaultMessage: "Navn"})}</th>
                    <th>{formatMessage({id: "DEVICE_SN_NUM_SHORT", defaultMessage: "Serienr"})}</th>
                    <th>{formatMessage({id: "phone", defaultMessage: "Tlfnr"})}</th>
                    <th>{formatMessage({id: "address", defaultMessage: "Adresse"})}</th>
                    <th>{formatMessage({id: "T34", defaultMessage: "Posisjon"})}</th>
                    <th>{formatMessage({id: "POS_TIME", defaultMessage: "Postid"})}</th>
                    <th>{formatMessage({id: "STATUS", defaultMessage: "Status"})}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!historic 
                    ? alarms.map(alarm => {
                        return(
                          <AlarmRow 
                            alarm={alarm}
                            historic={historic} 
                            setCreateEditComment={setCreateEditComment} 
                            setDeleteComment={setDeleteComment} 
                            setShareAlarm={setShareAlarm}
                          />
                        )
                      })
                    : historicAlarms.map(alarm => {
                        return(
                          <AlarmRow 
                            alarm={alarm}
                            historic={historic} 
                            setCreateEditComment={setCreateEditComment} 
                            setDeleteComment={setDeleteComment} 
                            setShareAlarm={setShareAlarm}
                          />
                        )
                    })
                  }
                </tbody>
              </table>
              <div className="text-align-center">
                {historicAlarms.length < historicLimit && 
                  <button onClick={getHistoricAlarmsAndIncrease} className="morebutton btn btn-gray" data-text="loadMore">{formatMessage({id: "loadMore", defaultMessage: "load more"})}</button>}
              </div>
            </Col>
        </Row>
      }
    </Grid>
    <ShareModal shareAlarmDevice={shareAlarm} setShareAlarm={setShareAlarm}/>
    <CreateEditModal alarms={alarms} historic={historic} setCreateEditComment={setCreateEditComment} setCloseAll={setCloseAll} createEditComment={createEditComment}/>
    <CloseAllModal closeAll={closeAll} setCloseAll={setCloseAll}/>
    <DeleteModal deleteComment={deleteComment} setDeleteComment={setDeleteComment}/>
    </React.Fragment>
  )
})


interface AlarmRowProps{
  alarm: EventViewAlarm;
  historic: boolean;
  setCreateEditComment: Function;
  setDeleteComment: Function;
  setShareAlarm: Function;
}

const AlarmRow = ({alarm, historic, setCreateEditComment, setDeleteComment, setShareAlarm}: AlarmRowProps) => {

  const { formatMessage } = useIntl();
  const [ showComment, setShowComment ] = React.useState(false);
  
  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.style.display = 'none'
  }

  const toGoogle = () => {
		window.open('https://maps.google.com/maps?q=' + alarm.posStr);
	}

	const copyCoords = () => {
		window.prompt(formatMessage({id: "copyToClipboardInstruct", defaultMessage: "copyToClipboardInstruct"}), alarm.posStr);
	}

  const Menu = (
    <StyledPopover style={{padding: "0"}} className="" id="popover-positioned-bottom">
      <ul id="lineMenu">
        <li onClick={() => {
          location.hash = `#mapsingle/${alarm.deviId}`;
        }}>
          <span>{formatMessage({id: "showInMap", defaultMessage: "Se i kart"})}</span>
        </li>
        <li onClick={() => {
          location.hash = `#event_setup/${alarm.deviId}`;
        }}><span data-text="eventSetup">{formatMessage({id: "eventSetup", defaultMessage: "Varslingsoppsett"})}</span>
        </li>
        <li onClick={() => {
          location.hash = `#settings/${alarm.deviId}/${alarm.dehaId}`;
        }}><span data-text="settings">{formatMessage({id: "settings", defaultMessage: "Settings"})}</span>
        </li>
        {alarm.posStr && <li onClick={copyCoords}><span>{formatMessage({id: "copyCoordinates", defaultMessage: "Kopiere koordinatene"})}</span>
        </li>}
        {alarm.posStr && <li onClick={toGoogle}><span>{formatMessage({id: "openInGoogle", defaultMessage: "Åpne i Google Maps"})}</span>
        </li>}
      </ul>
    </StyledPopover>
  )

  return(
    <React.Fragment>
    <tr onClick={() => setShowComment(!showComment)}>
      <td title={alarm.detyName} className="icon type-svg">{getIcon(alarm.dehaId)}</td>
      <td title={alarm.alarmTagDesc} className="icon alarmtype-svg">{getEventIcon(alarm.alarmTag)}</td>
      <td className="icon municipality" data-bind="hoverTip: deviceOwnerName">
        <img onError={handleError} src={`/ajax/file/municipalityimage/fordevice/${alarm.deviId}`}></img>
      </td>
      <td>
        <span>{formatDate(alarm.alarmTime, true, false, false, true, false, formatMessage)}</span>
      </td>
      <td>{alarm.deviceName}</td>
      <td className="highlight">{alarm.deviceSNNum}</td>
      <td>{alarm.devicePhoneNum}</td>
      <AddressTd weak={!alarm.streetAddressOk} onClick={() => {
        if(alarm.posStr){
          location.hash = `#mapsingle/${alarm.deviId}`;
        }
      }} dangerouslySetInnerHTML={{__html: alarm.streetAddressHtml}} className="location action">
      </AddressTd>
      <AddressTd weak={!alarm.streetAddressOk}
        onClick={() => {
          if(alarm.posStr){
            location.hash = `#mapsingle/${alarm.deviId}`;
          }
        }}
        className="location action">
        <span>{alarm.posStr}</span>
      </AddressTd>
      <td>
      <span>{alarm.posTime ? formatDate(alarm.posTime, true, false, false, true, false, formatMessage) : ""}</span>				
      </td>
      <td>
        <Status status={alarm.status}>
          {alarm.statusDesc}
        </Status>
      </td>
      <td className="icon type-svg">
        {!historic &&
        <button 
          onClick={() => {
            setShareAlarm(alarm.deviId);
          }}
          className="eventViewBtn btn-dark-with-line btn-small">
          <span>
            <Share/>
          </span>
        </button>}
      </td>
      <td className="icon type-svg">
      <OverlayTrigger trigger="click" placement="left" overlay={Menu}>
        <button className="eventViewBtn btn-dark-with-line btn-small">
          <span>...</span>
        </button>
      </OverlayTrigger>
      </td>
    </tr>
    {showComment && <Comment showComment={showComment} historic={historic} setCreateEditComment={setCreateEditComment} setDeleteComment={setDeleteComment} alarm={alarm}/>}
    </React.Fragment>
  )
}

interface CommentProps{
  alarm: EventViewAlarm;
  alarmAnalysis: AlarmAnalysisState;
  setCreateEditComment: Function;
  setDeleteComment: Function;
  getAlarmAnalysis: Routine<ActionFunctionAny<Action<any>>>;
  showComment: boolean;
  userId: number;
  historic: boolean;
}

const mapStateToCommentProps = ({ eventView: { alarm: { alarmAnalysis } }, appData: { user: { userId } } }: AppState) => {
  return {
    alarmAnalysis,
    userId
  }
}

const mapDispatchToCommentProps = {
  getAlarmAnalysis
}

const Comment = connect(mapStateToCommentProps, mapDispatchToCommentProps)(({alarm, userId, historic, alarmAnalysis, setCreateEditComment, setDeleteComment, getAlarmAnalysis, showComment}: CommentProps) => {

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    let interval: NodeJS.Timeout;
    if(showComment){
      getAlarmAnalysis(alarm.tranId);
      interval = setInterval(() => {getAlarmAnalysis(alarm.tranId)}, 20000);
    }

    return () => {
      clearInterval(interval);
    }
  }, [])

  return(
    <tr className="comments-row">
      <td colSpan={13} className="comments">
        <div className="comments-holder">
        <Timeline renderCallback={() => {}} alarmAnalysis={alarmAnalysis} id={alarm.tranId} canvasProperties={{margin: 10, textSize: 13, notes: false}}/>
        {alarm.comments.map(comment => {
          return(
            <div className="col-md-12 comment">
              <div className="col-md-3 timestamps">
                <div className="col-md-8">
                  <p>
                    <span>{formatMessage({id: "created", defaultMessage: "Created"})}</span>:<br/><span>{formatDate(comment.created, true, false, false, true, false, formatMessage)}</span></p>
                  {comment.created !== comment.lastChanged && 
                    <p>
                      <span>{formatMessage({id: "changed", defaultMessage: "Changed"})}</span>:<br/><span>{formatDate(comment.lastChanged, true, false, false, true, false, formatMessage)}</span>
                    </p>}
                </div>
                <div className="new-status inputfill col-md-4">
                  <label>{formatMessage({id: "status", defaultMessage: "Status"})}</label>:
                  <br/>
                  <Status status={comment.statusChange}>
                    {comment.statusDesc}
                  </Status>
                </div>
              </div>
              <div className="col-md-8">
                <h2>
                  <span>{comment.title}</span> 
                </h2>
                <h2 style={{float: "right"}}>
                  <span className="gray">{formatMessage({id: "createdBy", defaultMessage: "Created By"})}</span>:<br/> 
                  <span>{comment.user.name}</span>
                </h2>
                <p>{comment.comment}</p>
              </div>
              {!historic && userId === comment.userId && 
                <div className="col-md-1 gray controls">
                  <button 
                    onClick={() => {
                      const newComment = {
                        ...comment,
                        deviId: alarm.deviId,
                        deviceName: alarm.deviceName,
                        similarAlarms: 0
                      }
                      setCreateEditComment(newComment)
                    }}
                    className="btn btn-dark-with-line btn-small"
                  >
                    <span className="device-navigation"><Edit/></span>
                  </button>
                  <button 
                    onClick={() => setDeleteComment(comment)}
                    className="btn btn-dark-with-line btn-small">
                    <span className="device-navigation"><Trash/></span>
                  </button>
                </div>}
            </div>
          )
        })}
        <div className="col-md-12 add-new">
          <button 
            type="button" 
            className="btn btn-dark" 
            onClick={() => {
              const comment: AHPComment = {
                ahexId: alarm.ahexId,
                userId: userId,
                title: "",
                comment: "",
                id: 0,
                lastChanged: 0,
                created: 0,
                statusChange: 0,
                statusDesc: "",
                deleted: false,
                user: undefined,

                deviId: alarm.deviId,
                deviceName: alarm.deviceName,
                similarAlarms: 0
              }
              if (alarm.status != 3) {
                comment.statusChange = 4;
              } else {
                comment.statusChange = 3;
              }
              setCreateEditComment(comment)
            }}
          >{formatMessage({id: "addNewComment", defaultMessage: "Add New Comment"})}</button>
        </div>
      </div>
      </td>
    </tr>
  )
})

interface ShareModalProps{
  setShareAlarm: Function;
  shareAlarmDevice: number;
  shareAlarm: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToShareModalState = {
  shareAlarm
}

const ShareModal = connect(null, mapDispatchToShareModalState)(({setShareAlarm, shareAlarmDevice, shareAlarm}: ShareModalProps) => {

  const [ recipients, setRecipients ] = React.useState("");
  const { formatMessage } = useIntl();

  if(!shareAlarmDevice) return null;

  const handleShare = () => {
    shareAlarm({deviId: shareAlarmDevice, recipients});
    close();
    setRecipients("");
  }

  const close = () => {
    setShareAlarm(0);
  }

  return(
    <StyledModal
      open={!!shareAlarmDevice}
      setOpen={close}
      style={{content: {padding: "unset", width: "780px", maxWidth: "90vw"}}}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title" id="myModalLabel">
            <span data-text="share">{formatMessage({id: "share", defaultMessage: "Del"})}</span> <span className="highlight">{formatMessage({id: "alarm", defaultMessage: "alarm"})}</span>
          </h1>
        </div>
        <div className="modal-body">
          <p>
            <span>{formatMessage({id: "shareAlarmDesc2", defaultMessage: "shareAlarmDesc2"})}</span>
          </p>
          <input style={{width: "100%"}} type="text" value={recipients} onChange={(e) => {
            setRecipients(e.currentTarget.value);
          }}/>
        </div>
        <div className="modal-footer" style={{margin: "20px 15px"}}>
          <button 
            onClick={close}
            type="button" className="btn small btn-gray">
              {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
          </button>
          <button type="button" className="btn small btn-default"
            onClick={handleShare}
          >
            {formatMessage({id: "send", defaultMessage: "Send"})}
          </button>
        </div>
      </div>
    </StyledModal>
  )
})

const EditCommentBody = styled.div`
  border-radius: 7px;
  padding: 15px;
  background: ${props => props.theme.colors.backgroundPrimary};
`

const InputFill = styled.div`
  padding: 10px;
`;

interface CreateEditModalProps{
  alarms: EventViewAlarm[];
  historic: boolean;
  setCreateEditComment: Function;
  setCloseAll: Function;
  createEditComment: AHPComment;
  createComment: Routine<ActionFunctionAny<Action<any>>>;
  createCommentAndClose: Routine<ActionFunctionAny<Action<any>>>;
  createCommentAndReOpen: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToCreateModal = {
  createComment,
  createCommentAndClose,
  createCommentAndReOpen
}

const CreateEditModal = connect(null, mapDispatchToCreateModal)(({ alarms, createComment, createCommentAndClose, createCommentAndReOpen, historic, setCloseAll, setCreateEditComment, createEditComment }: CreateEditModalProps) => {

  const { formatMessage } = useIntl();

  const [ comment, setComment ] = React.useState<AHPComment>(createEditComment);
  const [ closeAllCheck, setCloseAllCheck ] = React.useState(false);

  React.useEffect(() => {
    setComment(createEditComment);
  }, [createEditComment])
  
  if(!comment) return null;

  const similarAlarms = alarms.filter(alarm => alarm.deviId === comment.deviId).length;

  const close = () => {
    setCreateEditComment(null);
  }

  return(
    <StyledModal
      open={!!createEditComment}
      setOpen={close}
      style={{content: {padding: "unset", width: "780px", maxWidth: "90vw"}}}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title" id="myModalLabel">
            <span>{formatMessage({id: "createChange", defaultMessage: "Opprette/endre"})}</span> <span className="highlight">{formatMessage({id: "comment", defaultMessage: "kommentar"})}</span>
          </h1>
        </div>
        <div className="modal-body">
          <EditCommentBody className="edit-comment">
            <InputFill className="inputfill title">
              <label htmlFor="title" data-text="title">{formatMessage({id: "title", defaultMessage: "Tittel"})}</label>
              <input style={{width: "340px"}} type="text" id="title" name="title" value={comment.title} onChange={(e) => setComment({...comment, title: e.currentTarget.value})}/>
            </InputFill>
              <br/>
            <InputFill className="inputfill comment">
              <label htmlFor="comment" data-text="comment">{formatMessage({id: "comment", defaultMessage: "Kommentar"})}</label>
              <textarea style={{padding: "15px", height: "165px", width: "600px", resize: "none"}} id="comment" name="comment" value={comment.comment} onChange={(e) => setComment({...comment, comment: e.currentTarget.value})}/>
            </InputFill>
            <InputFill className="inputfill close-similar">

            </InputFill>
          </EditCommentBody>
        </div>
        <div className="modal-footer" style={{margin: "20px 15px"}}>
          <button onClick={close} type="button" className="btn btn-gray">{formatMessage({id: "cancel", defaultMessage: "Avbryt"})}</button>
          <button 
            onClick={() => {
              createComment(comment);
              close();
            }}
            type="button" 
            className="btn btn-default" data-bind="click: commitCreateComment" data-dismiss="modal">
              {formatMessage({id: "saveComment", defaultMessage: "Lagre kommentar"})}
          </button>
          {!historic
            ? <button 
                onClick={() => {
                  createCommentAndClose(comment);
                  setCloseAll({
                    open: closeAllCheck,
                    comment: {
                      ...comment,
                      similarAlarms
                    }
                  });
                  close();
                }}
                type="button" 
                className="btn btn-red">
                  {formatMessage({id: "saveCommentAndCloseBtn", defaultMessage: "Lagre og lukke"})}
              </button>
            : <button 
                onClick={() => {
                  createCommentAndReOpen(comment);
                  close();
                }}
                type="button" 
                className="btn btn-red">
              { formatMessage({id: "saveCommentAndReopen", defaultMessage: "Lagre og åpne"})}
              </button>}
          {!historic && similarAlarms > 0 && <div className="margin-top-10">
            <input id="closeSimilar" type="checkbox" checked={closeAllCheck} onChange={(e) => setCloseAllCheck(e.currentTarget.checked)}/>
            <label htmlFor="closeSimilar" style={{whiteSpace: "nowrap"}}>
              <span></span>
              <span>
                {formatMessage({id: "closeAllAlarmsFromThisDevice", defaultMessage: "Lukk alle alarmer fra denne enheten"})}
              </span>
            </label>
          </div>}
        </div>
      </div>
    </StyledModal>
  )
})

interface CloseAll{
  open: boolean;
  comment: AHPComment;
}

interface CloseAllModalProps{
  setCloseAll: Function;
  closeAll: CloseAll;
  commentClose: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToCloseAll = {
  commentClose: closeAll
}

const CloseAllModal = connect(null, mapDispatchToCloseAll)(({closeAll, setCloseAll, commentClose}: CloseAllModalProps) => {

  const { formatMessage } = useIntl();

  const close = () => {
    setCloseAll(null);
  }

  if(!closeAll) return null;

  return(
    <StyledModal
      open={closeAll && closeAll.open}
      setOpen={close}
      style={{content: {padding: "unset", width: "780px", maxWidth: "90vw"}}}
    >
      <div className="modal-content">
				<div className="modal-header">
					<h1 className="modal-title" id="myModalLabel">
						<span>{formatMessage({id: "close", defaultMessage: "Lukk"})}</span> <span className="highlight">{formatMessage({id: "allAlarms", defaultMessage: "alle alarmer"})}</span>
					</h1>
					<p data-text="confirmClose">{formatMessage({id: "confirmClose", defaultMessage: "lukk"})}</p>
				</div>
				<div className="modal-body">
					<p>
						<span>{formatMessage({id: "doYouWishToCloseAll", defaultMessage: "Er du sikker på at du vil lukke alle alarmer for enhet"})}</span>
            <span className="highlight">{closeAll.comment.deviceName}</span>?
					</p>
					<p className="margin-top-20">
						<span>{formatMessage({id: "numberOfAlarms", defaultMessage: "Antall alarmer:"})}</span> <span className="highlight">{closeAll.comment.similarAlarms}</span>
					</p>
				</div>
				<div className="modal-footer" style={{margin: "20px 15px"}}>
					<button onClick={close} type="button" className="btn btn-gray">{formatMessage({id: "cancel", defaultMessage: "Avbryt"})}</button>
					<button onClick={() => {
            commentClose(closeAll.comment);
            close();
          }} type="button" className="btn btn-red" data-bind="click: commitCloseAll" data-dismiss="modal">{formatMessage({id: "closeAll", defaultMessage: "Lukk alle"})}</button>
				</div>
			</div>
    </StyledModal>
  )
})

interface DeleteModalProps{
  deleteComment: AHPComment;
  setDeleteComment: Function;
  commitDelete: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToDeleteState = {
  commitDelete: deleteComment
}

const DeleteModal = connect(null, mapDispatchToDeleteState)(({ deleteComment, setDeleteComment, commitDelete }: DeleteModalProps) => {

  const { formatMessage } = useIntl();

  const close = () => {
    setDeleteComment(null);
  }

  if(!deleteComment) return null;

  return(
    <StyledModal
      open={!!deleteComment}
      setOpen={close}
      style={{content: {padding: "unset", width: "780px", maxWidth: "90vw"}}}
    >
      <div className="modal-content">
				<div className="modal-header">
					<h1 className="modal-title" id="myModalLabel">
						<span>{formatMessage({id: "delete", defaultMessage: "Slette"})} </span> <span className="highlight">{formatMessage({id: "comment", defaultMessage: "kommentar"})}</span>
					</h1>
					<p>{formatMessage({id: "confirmDelete", defaultMessage: "confirmDelete"})}</p>
				</div>
				<div className="modal-body">
					<p>
						<span>{formatMessage({id: "doYouWishToDeleteComment", defaultMessage: "doYouWishToDeleteComment"})}</span> <span className="highlight">{deleteComment.title}</span>?
					</p>
				</div>
				<div className="modal-footer" style={{margin: "20px 15px"}}>
					<button onClick={close} type="button" className="btn btn-gray">{formatMessage({id: "cancel", defaultMessage: "Avbryt"})}</button>
					<button onClick={() => {
            commitDelete(deleteComment);
            close();
          }} type="button" className="btn btn-red">{formatMessage({id: "deleteComment", defaultMessage: "Slett"})}</button>
				</div>
			</div>
    </StyledModal>
  )
})

export default EventView;