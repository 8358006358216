import * as React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { Col, Row, Button } from "react-bootstrap";
import { PaymentBillingState, OrganizationForm } from "../Store/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../Elements/Dropdown/types";
import { connect } from "react-redux";
import {RootState} from "../Store/types";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { AllPermissions, PolicyContext } from "../../Policy/Provider";


const Label = styled.label`
  text-transform: capitalize;
  display: block;
  @media only screen and (min-width: 992px) {
    padding-left: 20px;
  }
`;

const StyledMainRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center;
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }

`;

interface BillingProps{
  individualPaymentProfiles: PaymentBillingState[];
}

const mapBillingStateToProps = ({ paymentProfile: { individualPaymentProfiles } }: RootState) => {
  return {
    individualPaymentProfiles,
  }
}

export const Billing = connect(mapBillingStateToProps)(
  withFormWrapper<OrganizationForm, BillingProps>(({ formik: { values: { parent, pptyId, custId }, setFieldValue }, individualPaymentProfiles}) => {

    
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const { formatMessage } = useIntl();

    const billingMethod = React.useMemo(() => {


      return individualPaymentProfiles.map((element:PaymentBillingState) => {
        const value: string = `${element.pptyId}`;
          return {value, text: formatMessage({id: element.name, defaultMessage: element.name})}
      })
    }, [individualPaymentProfiles]) 

    return (
      <StyledMainRow>
        <Col md={4}>
          <Label>{formatMessage({id: "subUnitsPayment", defaultMessage: "Betalingsform"})}</Label>
        </Col>
        <Col md={8}>
            <Dropdown
              options={billingMethod}
              onChange={(option) => {
                const { value } = option as Option;
                setFieldValue("pptyId", value);
              }}
              disabled={!permissions.get(PolicyNameEnum.EDIT_SUB_ORGS).customerPermissions[custId].all}
              initial={pptyId}
              size={{width: "100%"}}
            />
        </Col>
      </StyledMainRow>
    )
  })
);