import {all, fork} from "redux-saga/effects";
import textSaga from "./text";
import countrySaga from "./country";

function* TextModuleSaga(){
  yield all([
    fork(textSaga),
    fork(countrySaga),
  ])
}
export default TextModuleSaga;
