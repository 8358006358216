import * as React from "react";
import WalkTestItem from "./walktestItem";
import { TBody, THead, Tr } from "@Elements/Table";
import * as ST from "@Safemate/Settings/Sensor/styles";
import { CancelButton, SaveButton } from "@Safemate/DefaultSettings/Settings/styled";
import { faFileAlt } from "@fortawesome/pro-regular-svg-icons";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import styled from "styled-components";
import Filesaver from 'file-saver';
import moment from "moment";
import { connect } from "react-redux";
import Modal from "../../Modals";
import { ButtonContainer, Footer } from "../General/Tabs/styled";
import { Icon } from "../General/Tabs/gx8/logsReports";
import { IWalktest } from "./types";
import { AppState } from "@Safemate/Store/types";
import { tabHeaders } from "./walktestTable";

const DownloadButton = styled<any>(SaveButton)`
  display: flex;
  align-items: center;
`;

const header = defineMessages({
  date: { id: 'wtTableHeaderDate', defaultMessage: 'Dato' },
  time: { id: 'walkTableTime', defaultMessage: 'Tid' },
  type: { id: 'wtTableHeaderType', defaultMessage: 'Type' },
  device: { id: 'sensTableHeadSerialNumber', defaultMessage: 'Serienummer' },
  name: { id: 'wtTableHeaderName', defaultMessage: 'Navn' },
  signStr: { id: 'wtTableHeaderSignStr', defaultMessage: 'Signalstyrke' }
});


type ExportWalktest = (walktest: IWalktest[], serialNumber: string, intl: (msg: MessageDescriptor) => string) => void;

const exportWalktest: ExportWalktest = (walktest, serialNumber, intl) => {

  const dateLogged = new Date();
  const blob = new Blob(
    [
      // Header
      `${intl(tabHeaders.thDate)}, ${intl(tabHeaders.thTime)}, ${intl(header.type)}, ${intl(header.device)}, ${intl(header.name)}, ${intl(header.signStr)}\n`,

      ...walktest.filter(item => item.signalStrength !== null).map(item => {
        return `${moment(item.date).format('DD.MM.YYYY')}, ${item.time || ""}, ${item.sensorTypeName}, ${item.gatewayId}, ${item.name}, ${item.signalStrength}\n`
      })
    ],
    { type: 'text/plain;charset=utf-8' }
  );
  Filesaver.saveAs(
    blob,
    `WalkTest - ${serialNumber}_${dateLogged.toLocaleDateString()}_${dateLogged.toLocaleTimeString('nb-NO')}.csv`
  );
};


interface WalktestModalProps{
  display: boolean;
  walktest: IWalktest[];
  handleClose: () => void;
}

export default ({display, walktest, handleClose}: WalktestModalProps) => {
  return (
    <Modal
      bodyContent={<ModalBody walktest={walktest} handleClose={handleClose} />}
      display={display}
      handleClose={handleClose}
    />
  );
};

const mapStateToProps = ({ settings: {device: { device: { deviceSerialNumber }}}}: AppState) => {
  return{
    deviceSerialNumber
  }
}

interface ModalBodyProps{
  walktest: IWalktest[];
  handleClose: () => void;
  deviceSerialNumber: string;
}

const ModalBody = connect(mapStateToProps)(({ deviceSerialNumber, walktest, handleClose }: ModalBodyProps) => {

  const { formatMessage } = useIntl();

  return (
    <div style={{ padding: '20px 40px', border: '1px solid grey' }}>
      <ST.STable>
        <THead>
          <Tr>
          <ST.Sth>
              {formatMessage(tabHeaders.thDate)}
            </ST.Sth>
            <ST.Sth>
              {formatMessage(tabHeaders.thTime)}
            </ST.Sth>
            <ST.Sth>
              {formatMessage(tabHeaders.thName)}
            </ST.Sth>
            <ST.Sth>
              {formatMessage(tabHeaders.thSerialNumber)}
            </ST.Sth>
            <ST.Sth>
              {formatMessage(tabHeaders.thSignalStr)}
            </ST.Sth>
          </Tr>
        </THead>
        <TBody>
          {walktest.map(item => {
            return (
              <WalkTestItem
                walktest={item}
                time={item.time}
              />
            );
          })}
        </TBody>
      </ST.STable>
      <Footer>
        <ButtonContainer>
          <CancelButton onClick={() => handleClose()}>
            {formatMessage({ id: 'closeBtn', defaultMessage: 'Lukk' })}
          </CancelButton>
          <DownloadButton onClick={() => {
            exportWalktest(walktest, deviceSerialNumber, formatMessage)
          }}>
            <Icon icon={faFileAlt}/>
            CSV
          </DownloadButton>
        </ButtonContainer>
      </Footer>
    </div>
  );
});