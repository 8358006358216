import {useIntl} from "react-intl";
import * as React from "react";
import FileAlt from "../../../../Elements/Icon/Icons/Safemate/outline/FileAlt";
import { StyledButtonMob } from './index';
import { DeviceHardwareEnum, isGx8 } from '../../../DeviceHardwareEnum';
import { LabelEnum } from '../../LabelEnum';
import Map from "../../../../Elements/Icon/Icons/settings/Map";

interface LogProps{
  isTracker: boolean;
  deviceId: number
  deviceHardware: DeviceHardwareEnum;
  lastMsgIn: number;
}

const Log = ({ isTracker, lastMsgIn, deviceId, deviceHardware }: LogProps) => {

  if(!lastMsgIn) return null;

  const { formatMessage } = useIntl();

  if(isGx8(deviceHardware)){
    // Transaction log for trackers is not supported
    if(isTracker) return null
    return(
      <StyledButtonMob
        title={formatMessage({id: LabelEnum.SHOW_LOG, defaultMessage: "Logg"})}
        onClick={(event) => {
          event.stopPropagation();
          location.hash = `mapsingle/${deviceId}`;
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') 
            location.hash = `mapsingle/${deviceId}`;
        }}
      >
        <FileAlt
          size="medium"
          inline
        />
      </StyledButtonMob>
    )
  }

  return(
    <StyledButtonMob
      title={formatMessage({id: LabelEnum.SHOW_MAP, defaultMessage: "Kartvisning"})}
      onClick={(event: any) => {
        event.stopPropagation();
        location.hash = `mapsingle/${deviceId}`;
      }}
      onKeyDown={(event: any) => {
        if (event.key === 'Enter') 
          location.hash = `mapsingle/${deviceId}`;
      }}
    >
      <Map
        tabIndex="0"
        size="medium"
        inline
      />
    </StyledButtonMob>
  )
};

export default Log;
