import * as React from "react";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled, { withTheme, ThemeProps } from "styled-components";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps, useIntl } from "react-intl";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import { DeviceComponents } from "../tabDefinition";
import { SettingEnum } from "../settingEnum";
import { DeviceHardwareEnum, isEview07B } from "../../DeviceHardwareEnum";
import confirmModal, { ModalTitle, H2, ModalBody, ModalFooter, ConfirmBtn } from "../../Modals/confirmModal";
import { startTracking, stopTracking } from "../Store/routines"; 
import { Header } from "./TrackingOptions";
import FeetWalk from "../../../Elements/Icon/Icons/Old/FeetWalk";
import FeetStop from "../../../Elements/Icon/Icons/Old/FeetStop";
import { TrackingButton } from ".";
import { ITheme } from "themes/types";
import { AppState } from "@Safemate/Store/types";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    width: 50px;
    height: 50px;
  }
`;

const DeviceInfo = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const TrackingBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const DurationInput = styled.input`
  width: 70px;
`;

const TrackingInfoWrapper = styled.span<{isTracking: boolean}>`
  ${props => props.isTracking && "color: green;"}
`;

const RemainingTracking = styled.div`
  text-align: center;
`;

interface TrackingInfoProps{
  dehaId: number;
  deviceTypeName: string;
  deviceSerialNumber: string;
  tracking: boolean;
  connected: boolean;
}

const mapStateToProps = ({ settings: {device: { device: { connected, dehaId, deviceTypeName, deviceSerialNumber }}, tracking: { settings: { tracking } }}}: AppState) => {
  return {
    dehaId,
    deviceTypeName,
    deviceSerialNumber,
    tracking,
    connected
  }
}

const TrackingInfo = connect(mapStateToProps)(({ dehaId, deviceTypeName, deviceSerialNumber, tracking, connected }: TrackingInfoProps) => {
  
  const components: Map<SettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) ? DeviceComponents.get(dehaId)! : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

  const { formatMessage } = useIntl();

  return(
    <Wrapper>
      {tracking ? <FeetWalk inline size="medium"/> : <FeetStop inline size="medium"/>}
      <DeviceInfo>
        {deviceTypeName}
      </DeviceInfo>
      <DeviceInfo>
        {deviceSerialNumber}
      </DeviceInfo>
      <TrackingInfoWrapper isTracking={tracking}>
        {
          tracking
          ? formatMessage({id: "deviceIsTracking", defaultMessage: "Enheten lokaliserer"})
          : formatMessage({id: "deviceIsNotTracking", defaultMessage: "Enheten lokaliserer ikke"})
        }
      </TrackingInfoWrapper>
      <TrackingBtnWrapper>
        {true
          ? components.has(SettingEnum.TRACKING_MANUAL) && components.get(SettingEnum.TRACKING_MANUAL)?.map(comp => comp)
          : <span>{formatMessage({id: "notConnected", defaultMessage: "Enheten er ikke tilkoblet"})}</span>
        }
      </TrackingBtnWrapper>
    </Wrapper>
  )
})

export default TrackingInfo;

interface ManualTrackingProps extends ThemeProps<ITheme>{
  trackingUntil: number;
  disablePositioning: boolean;
  dehaId: number;
}

const mapManualTrackingStateToProps = ({settings: {tracking: { trackingUntil, disablePositioning }, initialization: { dehaId }}}: AppState) => {
  return{
    trackingUntil,
    disablePositioning,
    dehaId
  }
}


const DurationLabel = styled.label`
  padding-left: 10px;
`;

export const ManualTracking = connect(mapManualTrackingStateToProps)(
  withTheme(({ trackingUntil, disablePositioning, dehaId, theme }: ManualTrackingProps) => {

    const now = Date.now();
    const compare = 5000;
    const isTracking = trackingUntil - now > compare;

    const minutesLeft =  Math.floor(Math.abs(now - trackingUntil) / 60e3)%60;

    const { formatMessage } = useIntl();

    const buttonText = 
    isTracking 
    ? formatMessage({id: "stopManualTracking", defaultMessage: "Stopp lokalisering"})
    : formatMessage({id: "safematesTrackHover", defaultMessage: "Start lokalisering"})
    
    const [ open, setOpen ] = React.useState(false);

    if(!isTracking && disablePositioning) return null;

    return(
      <React.Fragment>
      {(isTracking && isEview07B(dehaId))
        ? <RemainingTracking>
            <span>
              {formatMessage({id: "currentlyTracking", defaultMessage: "Lokalisering pågår"})}
            </span>
            <br/>
            <span>
              {`${formatMessage({id: "remainingTime", defaultMessage: "Gjennstående tid"})}: ${minutesLeft}min`}
            </span>
          </RemainingTracking>
        : <TrackingButton onClick={() => setOpen(true)}>{buttonText}</TrackingButton>}
      <Modal
        isOpen={open}
        onRequestClose={() => {setOpen(false)}}
        style={
          {
            content: {
              maxWidth: "800px",
              width: "80%",
              height: "fit-content",
              position: "unset",
              background: theme.colors.backgroundPrimary,
              color: theme.colors.textPrimary
            },
            overlay:{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }
          }
        }
      >
        <ToggleTracking title={buttonText} isTracking={isTracking} setOpen={setOpen}/>
      </Modal>
      </React.Fragment>
    )
  })
)

interface ToggleTrackingProps{
  startTracking: Routine<ActionFunctionAny<Action<any>>>;
  stopTracking: Routine<ActionFunctionAny<Action<any>>>;
  title: string;
  isTracking: boolean;
  deviId: number;
  s03InSeconds: number
  setOpen: Function;
  trackingUntil: number;
  dehaId: number;
}

const mapToggleTrackingStateToProps = ({settings: {tracking: { settings: { s03InSeconds }, trackingUntil }, device: { device: { deviId, dehaId }}}}: AppState) => {
  return{
    s03InSeconds,
    deviId,
    trackingUntil,
    dehaId
  }
}

const mapToggleTrackingDispatchToProps = {
  startTracking,
  stopTracking
}

const ToggleTracking = connect(mapToggleTrackingStateToProps, mapToggleTrackingDispatchToProps)(({ startTracking, stopTracking, title, isTracking, deviId, dehaId, s03InSeconds, setOpen, trackingUntil }:ToggleTrackingProps) => {

  const [ duration, setDuration ] = React.useState(isEview07B(dehaId) ? "30" : "60");

  const { formatMessage } = useIntl();

  const now = Date.now();
  const hoursLeft =  Math.floor(Math.abs(now - trackingUntil) / 36e5);
  const minutesLeft =  Math.floor(Math.abs(now - trackingUntil) / 60e3)%60;

  const updateDuration = (event: React.ChangeEvent<HTMLInputElement>) => {

    let value = event.currentTarget.value;
    try{
      const intValue = parseInt(value);
      if(isEview07B(dehaId) && intValue > 30){
        value = "30";
      }
    }
    catch{}
    setDuration(value);
  }

  const toggle = () => {
    if(isTracking){
      stopTracking(deviId)
    }
    else{
      startTracking({
        deviceId: deviId,
        duration,
        interval: s03InSeconds
      })
    }
    setOpen(false);
  }

  return(
    <React.Fragment>
      <ModalTitle>
      < H2>{title}</H2>
      </ModalTitle>
      <ModalBody>
        {isTracking
            ? <p>
                {`${formatMessage({id: "timeUntilDeactivation", defaultMessage: "Gjenværende tid"})} ${hoursLeft ? `${hoursLeft}h ` : ""} ${minutesLeft}m. `}
                {formatMessage({id: "stopTrackingConfirmation", defaultMessage: "Er du sikker på at du vil stoppe manuell lokalisering?"})}
            </p>
            : <p>
                {isEview07B(dehaId) 
                  ? formatMessage({id: "triggerFourTracking", defaultMessage: "Enheten vil lokalisere i 30 minutter med det angitte lokaliseringsintervallet."})
                  : <React.Fragment>
                      <DurationInput type="text" value={duration} onChange={updateDuration}></DurationInput>
                      <DurationLabel>{formatMessage({id: "minutes", defaultMessage: "Minutter"})}</DurationLabel>
                    </React.Fragment>}
            </p>
          }
          {isEview07B(dehaId) && !isTracking &&
            <p>{formatMessage({id: "stationaryTrackingMinimum", defaultMessage: "Dersom enheten ligger i ro, vil den ikke lokalisere oftere enn hvert 5. minutt."})}</p>
          }
      </ModalBody>
      <ModalFooter>
        <ConfirmBtn onClick={toggle}>
          {formatMessage({id: isTracking ? "stop" : "start", defaultMessage: isTracking ? "Avslutt" : "Start"})}
        </ConfirmBtn>
      </ModalFooter>
    </React.Fragment>
  )
})
