import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { Wrap } from "./styles";
import { startWalktest, stopWalktest } from "../Store/routines";
import { connect } from "react-redux";
import { updateWalktest, UpdateWalktest } from "../Store/actions";
import Header from "./walktestheader";
import Table from "./walktestTable";
import Footer from "./walktestFooter";
import { IWalktest, WalktestEventDO } from "./types";
import { Client, IMessage } from "@stomp/stompjs";
import { connectWebsocket } from "@Safemate/Websocket";
import { AppState } from "@Safemate/Store/types";

interface WalktestProps{
  deviId: number;
  walktest: IWalktest[];
  walktestRemaining: number;
  walktestRunning: boolean;
  startingWalktest: boolean;
  endingWalktest: boolean;
  updateWalktest: UpdateWalktest;
  stopWalktest: Routine<ActionFunctionAny<Action<any>>>;
  startWalktest: Routine<ActionFunctionAny<Action<any>>>;
}

const WalktestComp = ({ deviId, walktest, startingWalktest, endingWalktest, walktestRemaining, walktestRunning, updateWalktest, startWalktest, stopWalktest }: WalktestProps) => {

  const [ websocket, setWebsocket ] = React.useState<Client>();
  const [ walktestTimeout, setWalktestTimeout ] = React.useState<NodeJS.Timeout>();
  const [ updatedSensors, setUpdatedSensors ] = React.useState<string[]>([]);
  const [ freshStart, setFreshStart] = React.useState(false);

  React.useEffect(() => {
    return () => {
      stop();
    }
  }, [])

  React.useEffect(() => {
    if(walktestRunning){
      setUpdatedSensors([]);
    }
  }, [walktestRunning])

  React.useEffect(() => {
    clearTimeout(walktestTimeout);
    if(walktestRemaining > 0){
      const timeout = setTimeout(() => {
        stop();
      }, walktestRemaining * 1000)
      setWalktestTimeout(timeout);
    }
  }, [walktestRemaining])
 
  const startSocket = () => {

    if(websocket){
      websocket.deactivate();
    }

    const socket = connectWebsocket();

    if(socket.connected){
      socket.subscribe(`/topic/walktest/${deviId}`, (message: IMessage) => {
        processMessage(message);
      });
    }
    else{
      socket.onConnect = (frame) => {
        socket.subscribe(`/topic/walktest/${deviId}`, (message: IMessage) => {
          processMessage(message);
        });
      }
    }
    
    if(!socket.active){
      socket.activate();
    }

    socket.onDisconnect = () => {
      stopWalktest(deviId);
    }

    setWebsocket(socket);
  };

  const stopSocket = () => {
    if (websocket && websocket.active) {
      websocket.unsubscribe(`/topic/walktest/${deviId}`);
      websocket.deactivate();
    }
    else{
      stopWalktest(deviId);
    }
  };

  const start = () => {
    setUpdatedSensors([]);
    startWalktest(deviId);
    startSocket();
    setUpdatedSensors([]);
  }

  const stop = () => {
    stopSocket();
    setFreshStart(true);
  }

  const processMessage = (message: IMessage) => {
    
    try{
      const walktestResult: WalktestEventDO = JSON.parse(message.body);
      let updated: string[] = [];
      if(!freshStart){
        updated = [...updatedSensors];
      }
      if(walktestResult.sensors && walktestResult.sensors.length > 0){
        
        walktestResult.sensors.forEach((walktest: IWalktest) => {  
          updated.push(walktest.gatewayId);
          updateWalktest(walktest);
        })
        if(!freshStart){
          setUpdatedSensors([...updatedSensors, ...updated]);
        }else{
          setFreshStart(false);
          setUpdatedSensors(updated);
        }
      }
    }
    catch(e){
      console.error(`Could not parse walktest message. ${e}`);
    }
  }

  return(
    <Wrap>
      <Header loading={startingWalktest || endingWalktest} walktestRunning={walktestRunning} start={start} stop={stop}/>
      <Table walktests={walktest} updatedSensors={updatedSensors}/>
      <Footer/>
    </Wrap>
  )
}

const mapStateToProps = ({ settings: {initialization: { deviId }, sensor: { walktest, walktestRemaining, startingWalktest, endingWalktest, walktestRunning }}}: AppState) => {
  return {
    deviId,
    walktest,
    walktestRemaining,
    walktestRunning,
    startingWalktest,
    endingWalktest
  }
}

const mapDispatchToProps = {
  startWalktest,
  stopWalktest,
  updateWalktest
}

export default connect(mapStateToProps, mapDispatchToProps)(WalktestComp);