import {useIntl} from "react-intl";
import * as React from "react";
import Users from "../../../../Elements/Icon/Icons/Safemate/solid/Users";
import { StyledButton } from './index';
import { LabelEnum } from '../../LabelEnum';

interface EventSetupActionProps{
  deviceId: number
}

const EventSetupAction = ({deviceId}: EventSetupActionProps) => {

  const { formatMessage } = useIntl();

  return(
    <StyledButton
      title={formatMessage({id: LabelEnum.SHOW_EVENT_SETUP, defaultMessage: "Varslingsliste"})}
      onClick={(event) => {
        event.stopPropagation();
        location.hash = `event_setup/${deviceId}`;
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') 
          location.hash = `event_setup/${deviceId}`;
      }}
    >
      <Users
        size="medium"
        inline
      />
    </StyledButton>
  )
}

export default EventSetupAction;
