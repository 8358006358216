import { EventSetup } from "./types"

export enum RecipientType{
  EMAIL,
  GSM
}

type NewEventSetup = (custId: number, type: RecipientType, sort: number, index: number) => EventSetup;

export const newEventSetup: NewEventSetup = (custId, type, sort, index) => {
  return {
    deviceEventSetupId: 0,
    alarmWarnRecipient: true,
    batteryWarnRecipient: true,
    beta: false,
    deviId: 0,
    custId: custId,
    email: type === RecipientType.EMAIL ? '' : null,
    epilepsyAlarmRecipient: true,
    familyMember: false,
    familyMemberCalling: false,
    familyMemberImage: false,
    familyMemberSchedule: false,
    familyMemberTracking: false,
    familyMemberVacation: false,
    fireWarnRecipient: true,
    fridgeWarnRecipient: true,
    geofenceWarnRecipient: true,
    givenName: "",
    inBedWarnRecipient: true,
    lastChanged: null,
    lastChangedBy: null,
    manDownWarnRecipient: false,
    name: '',
    offlineWarnRecipient: true,
    outOfBedWarnRecipient: true,
    padlocked: true,
    phone: "",
    selfcheckWarnRecipient: true,
    sort: sort,
    index: index,
    staticWarnRecipient: true,
    wandererAlarmRecipient: true,
    warnOnFri: true,
    warnOnMon: true,
    warnOnSat: true,
    warnOnSun: true,
    warnOnThu: true,
    warnOnTue: true,
    warnOnWed: true,
    warnTimeFrom: 0,
    warnTimeTo: 0,
    warnTimeFromString: '00:00',
    warnTimeToString: '00:00',
    familyMemberVacationUntil: 0, 
    trained: false, 
    trainedApprovedBy: "", 
    zoneId: "", 
    zoneIdWithDefault: ""
  }
}
