import {WrappedComponentProps, injectIntl} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import GoogleMap, {Maps} from "google-map-react";
import styled from "styled-components";
import {Row} from "react-bootstrap";
import {DeviceMac, DeviceMacs} from "../types";
import {MapWrapper} from "./MapWrapper";
import {SetValuesChanged,setValuesChanged} from "../../Store/actions";
import {connect} from "react-redux";
import {isSaveNeeded} from "../utils";
import { AppState } from "@Safemate/Store/types";

const MapDivWrapper = styled(Row)`
  height: 40em;
  padding: 0px;
  margin: 0px 0px;
  position: relative;
  border: ${props => props.theme.colors.border};;
  
`;

interface MapProps extends WrappedComponentProps{
  deviceMacs: DeviceMac[];
  deviceMac: DeviceMac;
  setValuesChanged: SetValuesChanged;
  deviceMacInitialValues: DeviceMac;
  deviceMacsInitialValues: DeviceMac[];
  initialPosition: string;
  activeIndex: number;
  selectedIndexes: number[];
  languageCode: string
}

const mapStateToProps = ({ settings: {indoor: { deviceMac, deviceMacs, deviceMacInitialValues, initialPosition, activeIndex, selectedIndexes, deviceMacsInitialValues}, initialization: {user: languageCode}}}: AppState) => {
  return {
    deviceMac, deviceMacs, deviceMacInitialValues, initialPosition, activeIndex, selectedIndexes, deviceMacsInitialValues, languageCode
  }
}

const mapDispatchToProps = {
  setValuesChanged
};

export const Map = injectIntl(withFormWrapper<DeviceMacs, MapProps>(
  ({formik: { setFieldValue, values }, intl: { formatMessage }, deviceMacs, deviceMac, setValuesChanged, deviceMacInitialValues, initialPosition, activeIndex, selectedIndexes, deviceMacsInitialValues, languageCode}) => {

    let mapWrapper: MapWrapper = React.useMemo(() => {
      return new MapWrapper(setFieldValue, deviceMacs, deviceMac, initialPosition);
    }, [])

    React.useEffect( () => {
      mapWrapper.setDeviceMacs(deviceMacs);
      mapWrapper.createMarkers();
      if(activeIndex>-1){
        mapWrapper.setActiveIndex(activeIndex);
      }
    }, [deviceMacs] );

    React.useEffect( () => {
      mapWrapper.setInitialPosition(initialPosition);
    }, [initialPosition] );

    React.useEffect( () => {
      mapWrapper.setActiveIndex(activeIndex);
    }, [activeIndex] );

    React.useEffect( () => {
      mapWrapper.setSelectedIndexes(selectedIndexes);
      if(activeIndex>-1){
        mapWrapper.setActiveIndex(activeIndex);
      }
    }, [selectedIndexes] );

    React.useEffect( () => {
      setValuesChanged( isSaveNeeded(values.devices, deviceMacsInitialValues) );
    }, [values.devices] );

    return(
      <MapDivWrapper>
        <GoogleMap
          defaultZoom={4}
          defaultCenter={{ lat: 62.91776, lng: 10.53775 }}
          bootstrapURLKeys={{key: 'AIzaSyAQxbShd7veF5B0YU9O_uYPOQFCOHoe8no', language: languageCode}}
          options={(map: Maps) => {
            return {
              streetViewControl: true,
              mapTypeControl: true,
              maxZoom: 20,
              mapTypeControlOptions: {
                mapTypeIds: [
                  map.MapTypeId.ROADMAP,
                  map.MapTypeId.SATELLITE,
                  map.MapTypeId.TERRAIN
                ]
              }
            }
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            mapWrapper.init(map, maps);
          }}
        >
        </GoogleMap>
      </MapDivWrapper>
    )}
))

export const IndoorMap = connect(mapStateToProps, mapDispatchToProps)(Map);
