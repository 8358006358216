import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import * as Routines from '../routines';
import { AppAction } from '@Safemate/Store/types';
import { CustomerResponse } from '../types';

function* initialize(action: AppAction<null>){
  try {
    const {customer}: CustomerResponse = yield call(Api.getFirm);
    const paymentProfiles = yield call(Api.getPaymentOptions, customer.custId, customer.parent);
    const marketProgId = yield call(Api.getMarketProgId);
    yield put(Routines.initialize.success({customer, paymentProfiles, marketProgId}));
  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
  yield put(Routines.initialize.failure());
}


function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;



