import { AHPComment } from '@Safemate/Model/EventViewAlarm';
import _axios, {AxiosInstance} from 'axios';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getAlarms = async () => {
  const url: string = `/alarm/forahp`;
  const response = await axios.get(url);
  return response.data;
}

export const getAlarmAnalysis = async (tranId: number) => {
  const url: string = `/alarmanalysis/${tranId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getHistoricAlarms = async (limit: number) => {
  const url: string = `/alarm/forahp/historic/${limit}`;
  const response = await axios.get(url);
  return response.data;
}

export const createComment = async (comment: AHPComment) => {
  const url: string = `/alarm/submit/comment`;
  const response = await axios.post(url, comment);
  return response.data;
}

export const createCommentAndClose = async (comment: AHPComment) => {
  const url: string = `/alarm/submit/comment/close`;
  const response = await axios.post(url, comment);
  return response.data;
}

export const createCommentAndReOpen = async (comment: AHPComment) => {
  const url: string = `/alarm/submit/comment/reopen`;
  const response = await axios.post(url, comment);
  return response.data;
}

export const deleteComment = async (comment: AHPComment) => {
  const url: string = `/alarm/delete/comment/${comment.id}`;
  const response = await axios.delete(url);
  return response.data;
}

export const closeAll = async (comment: AHPComment) => {
  const url: string = `/alarm/submit/comment/close/all`;
  const response = await axios.post(url, comment);
  return response.data;
}

export interface ShareAlarm{
  deviId: number;
  recipients: string;
}

export const shareAlarm = async ({deviId, recipients}: ShareAlarm) => {
  const url: string = `/share/${deviId}?shareRecipient=${recipients}`;
  const response = await axios.get(url);
  return response.data;
}