import _axios, {AxiosInstance} from 'axios';
import { Initialize } from './Saga/initialization';
import {OrganizationForm} from './types';


// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};


export interface SaveSettings{
  settings: OrganizationForm;
  PPTID: string;
}

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getDevice = async ({deviceId, roles}: Initialize) => {
  const url: string = `/deviceSettings/${deviceId}/${roles}`;
  const response = await axios.get(url);
  return response.data;  
};

export const getUser= async () => {
  const url: string = `/user/me?_=${new Date().getMilliseconds()}`;
  const response = await axios.get(url);
  return response.data;
}


// Organisation
export const getOrganisation = async () => {
  const url: string = `/firm/trimmedsuborgunitswithwithrights/`;
  const response = await axios.get(url);
  return response.data;
}

export const getSubUnits = async () => {
  const url = `/firm/suborgunitswithdevicecountandrights`;
  const response = await axios.get(url);
  return response.data;
}

export const saveForm = async ({PPTID, settings}: SaveSettings) => {
  const url: string = `/firm/save/${PPTID}`;
  const response = await axios.post(url, settings);
  return response.data;
}

export const getSubOrgs = async () => {
  const url: string = `/firm/trimmedsuborgunitswithwithrights/`;
  const response = await axios.get(url);
  return response.data;
}

export const getPostCity = async (pnr: string) => {
  const url = `https://api.bring.com/shippingguide/api/postalCode.json?clientUrl=www.safemate.no&pnr=${pnr}`;
  const response = await axios.get(url);
  return response.data;
}

export const getPaymentOptions = async (custId: number, parent: number) => {
  const url: string = `/paymentProfile/choices/${custId}/${parent}`;
  const response = await axios.get(url);
  return response.data;
}


export const getAllowDelete  = async () => {
  const url: string = `/user/allowDelete`;
  const response = await axios.get(url);
  return response.data;
}

export const getTfaRules  = async () => {
  const url: string = `/userroles/getTfaRules`;
  const response = await axios.get(url);
  return response.data;
}

export const getPrivate = async () => {
  const url: string = `/user/private`;
  const response = await axios.get(url);
  return response.data;
};

export const saveUser = async (userForm: UserForm) => {
  const url: string = `/user/me/${userForm.applyToAll}`;
  const response = await axios.post(url, userForm);
  return response.data;
}

export const deleteUser = async () => {
  const url: string = `/user/me`;
  const response = await axios.delete(url);
  return response.data;
}

export const sendGdprEmail = async () => {
  const url: string = `/user/sendEmailGdpr`;
  const response = await axios.get(url);
  return response.data;
};

export const getInvoices = async () => {
  const url: string = `/firm/getXledgerHistory`;
  const response = await axios.get(url);
  const data = response.data;
  return typeof data === 'string' ? JSON.parse(data) : data;
}

export const getBillingSettings = async () => {
  const url = `/firm/privatebillingsettings`;
  const response = await axios.get(url);
  return response.data;
}

export const connectToOwnersAhpPrivate = async (deviId:number) => {
  const url = `/device/connecttoownersdefaultahp/${deviId}/true`;
  const response = await axios.get(url);
  return response.data;
}

export const disconnectFromOwnersAhpPrivate = async (deviId:number) => {
  const url = `/device/disconnectfromownersdefaultahp/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}

export const deleteSafemate = async (deviceId: number) => {
  const url: string = `/device/${deviceId}`;
  const response = await axios.delete(url);
  return response.data;
}

export const getLifetimeNumber = async () => {
  const url: string = `/settings/privatehelpmobile`;
  const response = await axios.get(url);
  return response.data;
};

export const getLifetimeCost = async () => {
  const url: string = `/settings/privatehelpcost`;
  const response = await axios.get(url);
  return response.data;
};

export const getPaymentCurrency = async () => {
  const url: string = `/settings/paymentcurrency`;
  const response = await axios.get(url);
  return response.data;
};


export const getCountries = async () => {
  const url: string = `/language`;
  const response = await axios.get(url);
  return response.data;  
};

export const getTextsMyPage = async () => {
  const url: string = `/text/loggedIn`;
  const response = await axios.get(url);
  return response.data;  
};

export const getMarketProgId = async () => {
  const url: string = `/user/getMarketProgId`;
  const response = await axios.get(url);
  return response.data;
}