import * as React from "react";

import { Td, Active } from "../TranslateOverlay/styles";
import { Tr } from "./styles";
import { Checkmark } from "../../RoleManager/layout";
import { connect } from "react-redux";
import { RootState, TextLabelWithText } from "./Store/types";
import { DatabaseCountry } from "../TranslateOverlay/Store/types";
import { SetSelected, setSelected } from "./Store/actions";
import { AppState } from "@Safemate/Store/types";

interface TextListItemProps {
    text: TextLabelWithText;
    languageCode: string;
    countries: DatabaseCountry[];
    setSelected: SetSelected;
}

const mapStateToProps = ({ appData: {user: { languageCode }}, translationPage: { country: { countries }}}: AppState) => {
  return {
    languageCode,
    countries
  }
}

const mapDispatchToState = {
  setSelected
}

export default connect(mapStateToProps, mapDispatchToState)(({ setSelected, text, languageCode, countries }: TextListItemProps) => {

    const current = text.texts.find(t => t.languageCode === languageCode);

    const select = () => {
      setSelected(text);
    }

    return(
        <Tr header={false} onClick={select} exist={!!current}>
            <Td>{text.count}</Td>
            <Td>{text.source}</Td>
            <Td>{current ? current.translatedText : "-"}</Td>
            <Td>{text.label}</Td>
            {countries.map(country => {
              const active = !!text.texts.find(t => t.languageCode === country.languageCode);
              return <Td>
                <Active active={active}>{active && <Checkmark />}</Active>
              </Td>;
            })}
        </Tr>
    )
})
