import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import withFormWrapper from "@Elements/Form/formWrapper";
import { OrganizationForm } from "./types";
import { RootState, Organization, CustomerState, Customer } from "../Store/types";
import { useIntl } from "react-intl";
import { Col, Row, } from "react-bootstrap";
import Dropdown from "../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../Elements/Dropdown/types";
import { AllPermissions, PolicyContext } from "../../Policy/Provider";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { getPaymentForNew, setPaidByCustomer } from "../Store/routines";
import { AppState } from "@Safemate/Store/types";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";

const Label = styled.label`
  text-transform: capitalize;
  display: block;
`;

const StyledRow = styled(Row)`
  padding-bottom: 10px;
`;

interface ParentProps{
  customersForList: Customer[];
  selectedCustomer: Customer;
  getPaymentForNew: Routine<ActionFunctionAny<Action<any>>>;
}


const mapStateTooProps = ({ subUnits: {customer: { customersForList, selectedCustomer }}} : AppState) => {
  return {
    customersForList,
    selectedCustomer
  }
}

const mapDispatchToProps = {
  getPaymentForNew,
}

export const ParentFirm = connect(mapStateTooProps, mapDispatchToProps)(withFormWrapper<OrganizationForm, ParentProps>(
  ({formik: {setFieldValue, values: { custId, parent }}, customersForList, selectedCustomer, getPaymentForNew}) => {

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const { formatMessage } = useIntl();

    let parentOptions = React.useMemo(() => {
        return customersForList.map((customer) => {
          return {
            value: customer.customerId,
            text: customer.customerName
          }
        }).filter(cust => cust);
    }, [customersForList, selectedCustomer, custId])

    return (
      <StyledRow>
        <Col md={12}>
          <Label>{formatMessage({id: "parentFirm", defaultMessage: "Parent Firm"})}</Label>
        </Col>
        <Col md={12}>
        <Dropdown 
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, custId)}
          options={parentOptions}
          onChange={(option) => {
              const { value } = option as Option;
              getPaymentForNew(value);
              setFieldValue("parent", value);
          }}
          initial={parent}
          size={{width: "100%"}}
        />  
        </Col>
      </StyledRow>
    )
  }
))

interface MovingSuborgProps{
  customersForList: Customer[];
  selectedCustomer: Customer;
  getPaymentForNew: Routine<ActionFunctionAny<Action<any>>>;
  setPaidByCustomer: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToMovingSuborgProps = ({ subUnits: {customer: { customersForList, selectedCustomer }}} : AppState) => {
  return {
    customersForList,
    selectedCustomer
  }
}

const mapDispatchToMovingSuborgProps = {
  getPaymentForNew,
  setPaidByCustomer
}


export const MovingSuborg = connect(mapStateToMovingSuborgProps, mapDispatchToMovingSuborgProps)(withFormWrapper<OrganizationForm, MovingSuborgProps>(
  ({formik: {setFieldValue}, customersForList, selectedCustomer, getPaymentForNew, setPaidByCustomer}) => {

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const { formatMessage } = useIntl();

    let parentOptions = React.useMemo(() => {
        return customersForList.map((customer) => {
          if(customer.customerId === selectedCustomer.customerId) return null; 
          return {
            value: customer.customerId,
            text: customer.customerName
          }
        }).filter(cust => cust);
    }, [customersForList, selectedCustomer])

    return (
      <StyledRow>
        <Col md={12}>
          <Label>{formatMessage({id: "parentFirm", defaultMessage: "Parent Firm"})}</Label>
        </Col>
        <Col md={12}>
        
        <Dropdown 
          disabled={!checkPermissionsForCustomer(permissions, PolicyNameEnum.EDIT_SUB_ORGS, selectedCustomer.customerId)}
          options={parentOptions}
          onChange={(option) => {
              const { value } = option as Option;
              setPaidByCustomer(value);
              setFieldValue("parent", value);
          }}
          initial={selectedCustomer.parent}
          size={{width: "100%"}}
        />  
        </Col>
      </StyledRow>
    )
  }
))
  
