import styled, { css } from 'styled-components';
import { darken } from 'polished';

const activeStyle = css`
  background: ${props => darken(0.05, props.theme.colors.backgroundPrimary)};
  border-bottom: 1px solid #3a3b3f;
  padding-left: 15px;
  line-height: 3;
  ${props =>
    props.active &&
    `border-left: 2px solid ${props.theme.colors.accent}`};
`;

const Tab = styled.div`
  display: block;
  background: ${props => props.theme.colors.backgroundPrimary};
  border-bottom: 1px solid #3a3b3f;
  border-left: 2px solid transparent;
  color: ${p => p.theme.colors.textFocus};
  cursor: pointer;
  line-height: 3;
  padding: 0 15px;
  transition: 0.2s background-color;
  text-decoration: none;
  white-space: nowrap;
  ${props => props.active && activeStyle};

  & svg {
    fill: ${p => p.theme.colors.textFocus};
  }
`;

export default Tab;
