import * as React from "react";
import Battery1Charging from "../../../Elements/Icon/Icons/Battery/Battery1Charging";
import Battery1 from "../../../Elements/Icon/Icons/Battery/Battery1";
import Battery2Charging from "../../../Elements/Icon/Icons/Battery/Battery2Charging";
import Battery2 from "../../../Elements/Icon/Icons/Battery/Battery2";
import Battery3Charging from "../../../Elements/Icon/Icons/Battery/Battery3Charging";
import Battery3 from "../../../Elements/Icon/Icons/Battery/Battery3";
import Battery4Charging from "../../../Elements/Icon/Icons/Battery/Battery4Charging";
import Battery4 from "../../../Elements/Icon/Icons/Battery/Battery4";


export const BatteryLevelToIcon = new Map<number, {charging: React.ReactNode, normal: React.ReactNode}>(
  [
    [1, {charging: <Battery1Charging/>, normal: <Battery1/>}],
    [2, {charging: <Battery2Charging/>, normal: <Battery2/>}],
    [3, {charging: <Battery3Charging/>, normal: <Battery3/>}],
    [4, {charging: <Battery4Charging/>, normal: <Battery4/>}],
  ]
)