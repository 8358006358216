import { AppAction } from "../../../../Safemate/Store/types";
import {TranslationModuleActionTypes as Actions} from "./actionTypes";
import { LanguageOptionEnum } from "./LanguageOptionEnum";
import { TextLabelWithText } from "./types";

export type ResetSelected = () => AppAction<string>

export type SetSelected = (selected: TextLabelWithText) => AppAction<TextLabelWithText>;

export type SetModalState = (open: boolean) => AppAction<boolean>;

export type SortTable = (header: string) => AppAction<string>;

export type SetSearch = (search: string) => AppAction<string>;

export type SetSelectedLanguage = (language: string[]) => AppAction<string[]>

export type SetLanguageOption = (opt: LanguageOptionEnum) => AppAction<LanguageOptionEnum>;

export const resetSelected: ResetSelected = () => ({
  type: Actions.RESET_SELECTED,
  payload: ""
})

export const setSelected: SetSelected = (selected) => ({
  type: Actions.SET_SELECTED,
  payload: selected
})

export const setModalState: SetModalState = (open) => ({
  type: Actions.SET_MODAL_STATE,
  payload: open
})

export const sortTable: SortTable = (header) => ({
  type: Actions.SORT_TABLE,
  payload: header
})

export const setSearch: SetSearch = (search) => ({
  type: Actions.SET_SEARCH,
  payload: search
})

export const setSelectedLanguage: SetSelectedLanguage = (language) => ({
  type: Actions.SET_LANGUAGE_FILTER,
  payload: language
})

export const setLanguageOption: SetLanguageOption = (opt) => ({
  type: Actions.SET_LANGUAGE_OPTION,
  payload: opt
})