import {call, put, select, takeLatest} from 'redux-saga/effects';
import {CallLog, EventLog} from "@Safemate/Model/EventLog";
import { AppAction } from "@Safemate/Store/types";
import * as Api from '../api';
import * as Routines from "../routines";
import { selectDeviceAuditLog, selectFilter } from '../selectors';
import { filterlist } from '@Safemate/Log/utils';
import { LogActionTypes } from '../actionTypes';
import { GetAuditLog } from './userAuditLog';

function* getDeviceAuditLog(action: AppAction<GetAuditLog>){
  try {
    const deviceAuditLog: EventLog[] = yield call(Api.getDeviceAuditLog, action.payload);
    const filter: number[] = yield select(selectFilter);
    const existingList: EventLog[] = yield select(selectDeviceAuditLog);
    const list = existingList.concat(deviceAuditLog);
    const filteredDeviceAuditLog: EventLog[] = filterlist(list, filter);
    yield put(Routines.getDeviceAuditLog.success({deviceAuditLog: list, filteredDeviceAuditLog}));
  }
  catch(e){
    yield put(Routines.getDeviceAuditLog.failure());
  }
}

function* filterDeviceAuditLog(action: AppAction<null>){
  const deviceAuditLog: EventLog[] = yield select(selectDeviceAuditLog);
  const filter: number[] = yield select(selectFilter);
  const filteredDeviceAuditLog: EventLog[] = filterlist(deviceAuditLog, filter);
  yield put(Routines.getDeviceAuditLog.success({deviceAuditLog, filteredDeviceAuditLog}));
}

function* getDeviceCallLog(action: AppAction<GetAuditLog>){
  try {
    const deviceCallLog: CallLog[] = yield call(Api.getDeviceCallLog, action.payload);
    yield put(Routines.getDeviceCallLog.success(deviceCallLog));
  }
  catch(e){
    yield put(Routines.getDeviceCallLog.failure());
  }
}


function* deviceAuditLog(){
    yield takeLatest(Routines.getDeviceAuditLog.TRIGGER, getDeviceAuditLog);
    yield takeLatest(Routines.getDeviceCallLog.TRIGGER, getDeviceCallLog);
    yield takeLatest(LogActionTypes.SET_FILTER, filterDeviceAuditLog);
}

export default deviceAuditLog;
