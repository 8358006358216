import {call, put, select, takeLatest} from "redux-saga/effects";
import * as moment from "moment";
import * as Routines from "../routines";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {AppAction} from "../../../DefaultSettings/Store/types";
import * as Api from '../api';
import {feedbackFailure, feedbackSuccess} from "../../../Feedback/reducer";
import {LabelEnum} from "../../LabelEnum";
import { SiteTypeEnum } from "../../../SiteTypeEnum";
import { selectCurrentContext, selectIsPrivate } from "@Safemate/Store/selectors";
import { User } from "@Safemate/UserAdmin/Models/User";
import { GdprManager } from "@Safemate/Store/types";

interface UserForSave extends User{
  deactivatedFromString: string;
  deactivatedToString: string;
}

export interface UserWithCustomerForSave extends UserWithCustomer{
  toString: string;
  fromString: string;
  user: UserForSave;
}

export interface UserWithCustomerWithText{
  userToEdit: UserWithCustomer;
  newPassSent: String;
  receiveEmailLoginInfo: String;
}

function* saveUser(action: AppAction<UserWithCustomer>){
  try{
    const {user, ...rest}= action.payload;
      const userForSave = {
      ...rest,
      toString: moment.isMoment(rest.to) ? rest.to.format("YYYY-MM-DD") : rest.to,
      fromString: moment.isMoment(rest.from) ? rest.from.format("YYYY-MM-DD") : rest.from,
      user: {
        ...user,
        deactivatedToString: moment.isMoment(user.deactivatedTo) ? user.deactivatedTo.format("YYYY-MM-DD") : user.deactivatedTo,
        deactivatedFromString: moment.isMoment(user.deactivatedFrom) ? user.deactivatedFrom.format("YYYY-MM-DD") : user.deactivatedFrom,
        mailTo: user.mailTo != "" ? user.mailTo : user.emailAsUsername ? user.username : user.email,
        email: user.email != "" ? user.email : user.emailAsUsername ? user.username : user.email,
      }
    };

    if(userForSave.role == -1){
      userForSave.role = 1;
    }

    const isPrivate: boolean = yield select(selectIsPrivate);

    const response: User = yield call(Api.saveUser, userForSave, isPrivate);

    const savedUser: UserWithCustomer = {
      ...action.payload,
      user: {
        ...action.payload.user,
        ...response
      }
    }


    yield feedbackSuccess(LabelEnum.USER_SAVED);
    yield put(Routines.saveUser.success(savedUser));

  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveUser.failure());
  }
  yield put(Routines.saveUser.fulfill());
}

export interface UserToDelete{
  custId: number;
  userId: number
}

function* deleteUser(action: AppAction<UserToDelete>){
  try{
    const siteType: SiteTypeEnum = yield select(selectCurrentContext);
    if(siteType === SiteTypeEnum.AHP){
      yield call(Api.deleteUserAhp, action.payload);
    }
    else{
      yield call(Api.deleteUser, action.payload);
    }
    yield put(Routines.deleteUser.success(action.payload.userId));
    yield feedbackSuccess(LabelEnum.USER_DELETED);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.deleteUser.failure());
  }
  yield put(Routines.deleteUser.fulfill());
}

function* sendNewPassword(action: AppAction<UserWithCustomerWithText>){         

  try{
    yield call(Api.sendNewPassword, `${action.payload.userToEdit.user.username}`);
    yield put(Routines.sendNewPassword.success( action.payload.userToEdit.user.username));
    const firstName = action.payload.userToEdit.user.firstName;
    const lastName = action.payload.userToEdit.user.lastName;
    const feedback = action.payload.newPassSent + " " + firstName + " " + lastName + " " + action.payload.receiveEmailLoginInfo;

    yield feedbackSuccess(feedback);
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.sendNewPassword.failure());
  }
  yield put(Routines.sendNewPassword.fulfill());
}

function* assignGdprManager(action: AppAction<number>){         
  try{
    const gdprManager: GdprManager = yield call(Api.assignGdprManager, action.payload);
    yield put(Routines.assignGdprManager.success(gdprManager));
    yield feedbackSuccess("gdprManagerAssigned")
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.assignGdprManager.failure());
  }
}

function* removeGdprManager(action: AppAction<null>){         
  try{
    yield call(Api.removeGdprManager);
    yield put(Routines.removeGdprManager.success());
    yield feedbackSuccess("gdprManagerRemoved")
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.removeGdprManager.failure());
  }
}

function* actionSaga(){
  yield takeLatest(Routines.saveUser.TRIGGER, saveUser);
  yield takeLatest(Routines.deleteUser.TRIGGER, deleteUser);
  yield takeLatest(Routines.sendNewPassword.TRIGGER, sendNewPassword);
  yield takeLatest(Routines.assignGdprManager.TRIGGER, assignGdprManager);
  yield takeLatest(Routines.removeGdprManager.TRIGGER, removeGdprManager);
}

export default actionSaga;
