import * as React from "react";

import { ThemeProps, withTheme } from "styled-components";
import { ITheme } from "themes/types";
import { AlarmAnalysisState, AlarmAnalysis } from '../DeviceList/Store/types';
import { connect } from "react-redux";
import { formatTime } from '../utils';
import { AppState } from "@Safemate/Store/types";

export interface TimelineProps extends ThemeProps<ITheme>{
  alarmAnalysis: AlarmAnalysisState;
  id: number;
  renderCallback: () => void;
  canvasProperties: {
    margin: number;
    notes: boolean;
    textSize: number;
  }
}

interface CanvasProperties{
  margin?: number;
  notes?: boolean;
  textSize?: number;
}

/*
const mapStateToProps = ({ deviceList: {alarmAnalysis}}: AppState) => {
  return{
    alarmAnalysis
  }
}
*/
export const Timeline = withTheme(connect(null)(({alarmAnalysis, id, renderCallback, canvasProperties, theme}: TimelineProps) => {
  
  const canvasRef = React.useRef<HTMLCanvasElement>(null);


  React.useEffect(() => {
    return () => {
      window.removeEventListener("resize", updateCanvas);
    }
  }, [])

  // Update timeline on changes to this device's alarm analysis
  React.useEffect(() => {
    // Event listener save the current state, so the alarm events won't be updated in an old event listener
    window.removeEventListener("resize", updateCanvas);
    window.addEventListener("resize", updateCanvas);
    updateCanvas();
  }, [alarmAnalysis[id]])
  
  React.useEffect(() => {
    updateCanvas();
  }, [canvasRef])

  const updateCanvas = () => {
    if(canvasRef.current && alarmAnalysis[id] && alarmAnalysis[id].length > 0){
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if(ctx){
        ctx.clearRect(0, 0, 0, 0);
        const parentWidth = canvas.parentElement!.clientWidth;
        ctx.font = `${canvasProperties.textSize}px sans-serif`;
        const timelineEvents = new TimelineEvents(parentWidth, alarmAnalysis[id], ctx, theme.colors.textPrimary, canvasProperties);
        canvas.height = timelineEvents.height.above + timelineEvents.height.below;
        canvas.width = parentWidth;
        drawLine(ctx, timelineEvents);
        drawEvent(ctx, timelineEvents);
        renderCallback();
      }
    }
  }

  const drawLine = (ctx: CanvasRenderingContext2D, timelineEvents: TimelineEvents) => {
    const {margin, lineLen, height} = timelineEvents;
    ctx.beginPath();
    ctx.strokeStyle = theme.colors.textPrimary;
    ctx.fillStyle = theme.colors.textPrimary;
    ctx.moveTo(margin, height.above);
    ctx.lineTo(margin + lineLen, height.above);
    ctx.stroke();
  }

  const drawEvent = (ctx: CanvasRenderingContext2D, timelineEvents: TimelineEvents) => {
    
    const { textSize, timeEventList, bgColor, height } = timelineEvents;

    timeEventList.forEach(event => {
      ctx.strokeStyle = bgColor;
      ctx.lineWidth = 5;
      ctx.font=textSize+"px sans-serif";
      ctx.fillStyle = event.fill;

      if(event.x || event.x === 0) {
        ctx.beginPath();
          ctx.arc(event.x, height.above ,4,0,Math.PI*2,true);
          ctx.stroke();
          ctx.fill();
          
      }				
      if(event.msg) {
        ctx.fillText(event.msg, event.txtX, height.above + event.txtY);
      }				
      if(event.time) {
        ctx.fillText(event.time, event.timeTxtX, height.above + event.timeTxtY);																	
      }
    });
  }

  if(!(alarmAnalysis[id] && alarmAnalysis[id].length > 0)) return null;

  return(
    <div>
      <canvas
        style={{width: "100%"}}
        ref={canvasRef}
      />
    </div>
  )
}));

interface TimeEventList{
  x : number;
  msg : string;
  txtX : number;
  txtY : number;
  time : string;
  timeTxtX : number;
  timeTxtY : number;
  stroke : string;
  fill: string;
}

class TimelineEvents{

  events: AlarmAnalysis[];
  ctx: CanvasRenderingContext2D;
  baseColor: string;

  canvasWidth: number; 
  margin: number = 0; 
  lineLen: number;
  startTime: number = 0; 
  endTime: number;
  lineAtY: number = 0;
  ignoreNotes: boolean = false;
  duration: number = 0;
  step: number = 0;
  textSize: number = 10;
  lineHeight: number;
  bgColor: string = "";

  timeEventList: TimeEventList[] = [];

  height: {above: number, below: number} = {above: 0, below: 0};

  constructor(width: number, events: AlarmAnalysis[], ctx: CanvasRenderingContext2D, baseColor: string, {textSize, margin, notes}: CanvasProperties){
    this.canvasWidth = width;
    this.events = events;
    this.baseColor = baseColor;
    this.ctx = ctx;
    this.endTime = events[events.length - 1].time;

    if(notes)
      this.ignoreNotes = notes;
    if(margin)
      this.margin = margin;
    if(textSize)
      this.textSize = textSize;

    this.lineLen = width - this.margin * 2;
    this.lineHeight = this.textSize + 3;
  
    this.setTimes();
    this.setTimeEventList();
    this.height = this.setEventHeight();
  }

  setTimes = () => {
    let i = 0;
    while(this.startTime === 0 && i < this.events.length - 1){
      this.startTime = this.events[i].time;
      i++;
    }
    if (this.startTime === this.endTime) {
      // Adding 1 to give the x-axis non-zero dimension - otherwise it doesn't draw
      this.endTime = this.endTime+1;
    }
    this.duration = this.endTime - this.startTime;
    this.step = this.lineLen / this.duration;
  }

  getTextX = (tWidth: number, x: number) => {
    let tX = Math.max(1, x - Math.round((tWidth / 2)));
    tX -= Math.max(1, (tX + tWidth) - this.canvasWidth);
    return tX;
  }

  setTimeEventList = () => {
    const textSectorsX = [-20], timeTxtSectorsX = [-20]; 

    this.events.forEach(event => {
      if(event.time) {
        var x = this.margin + Math.round((event.time - this.startTime) * this.step),
          textAtY = -10, 
          textWidth = this.ctx.measureText(event.msg).width,
          textStart = this.getTextX(textWidth, x),
          timeTxtAtY = this.textSize+5,
          timeTxt = formatTime(event.time),
          timeTxtWidth = this.ctx.measureText(timeTxt).width,
          timeTxtStart = this.getTextX(timeTxtWidth, x),
          i;
      
        for(i = 0; i < textSectorsX.length; i++) {
          if(textStart-3 < textSectorsX[i]) {
            textAtY -= this.lineHeight;
          } else {
            break;
          }								
        }
        textSectorsX[i] = textStart + textWidth;
        
        for(i = 0; i < timeTxtSectorsX.length; i++) {
          if(timeTxtStart-3 < timeTxtSectorsX[i]) {
            timeTxtAtY += this.lineHeight;
          } else {
            break;
          }								
        }
        timeTxtSectorsX[i] = timeTxtStart + timeTxtWidth;

        this.timeEventList.push({
          x : x,
          msg : event.msg,
          txtX : textStart,
          txtY : textAtY,
          time : timeTxt,
          timeTxtX : timeTxtStart,
          timeTxtY : timeTxtAtY,
          stroke : EventToColorMap.get(event.type) || this.baseColor,
          fill: EventToColorMap.get(event.type) || this.baseColor
        });
      }
    })
  }

  setEventHeight = () => {
    var maxAbove = 0, maxBelow = 0;
    this.timeEventList.forEach(event => {
      maxAbove = Math.min(maxAbove, event.txtY);
      maxBelow = Math.max(maxBelow, event.timeTxtY);
    })
    return {
      above: Math.abs(maxAbove) + this.lineHeight + this.margin,
      below: maxBelow + this.margin
    };
  }

}

enum TimelineEventEnum{
  TRIGGERED = 1,
	BACKEND_RECEPTION = 2,
	CALL_PRIVATE_START = 3,
	CALL_PRIVATE_PICKUP = 4,
	CALL_SAFEMATE_START = 5,
	CALL_SAFEMATE_PICKUP = 6,
	CALL_PRIVATE_END = 7,
	CALL_ALARM_CENTRAL_START = 8,
	CALL_ALARM_CENTRAL_PICKUP = 9,
	CALL_ALARM_CENTRAL_END = 10,
	CALL_PRIVATE_PIN = 11,
	CALL_SAFEMATE_NOANSWER = 12,

  INTEGRATION_ATTEMPT = 13,
  INTEGRATION_SUCCESS = 14,
  INTEGRATION_FAILURE = 15,

	HEADER_SM_ANSWERED = 101,
	HEADER_NO_SM_PICKUP = 102,
	HEADER_NO_RECEIVER_PICKUP = 103,
	HEADER_NO_RECEIVER_PIN = 104,
	HEADER_NO_ALARM_CENTRAL_PICKUP = 105
}

const EventToColorMap: Map<TimelineEventEnum, string> = new Map<TimelineEventEnum, string>([
  [TimelineEventEnum.CALL_PRIVATE_START, "#f5a623"],
  [TimelineEventEnum.CALL_PRIVATE_PICKUP, "#70981a"],
  [TimelineEventEnum.CALL_SAFEMATE_PICKUP, "#a9cf55"],

  
  [TimelineEventEnum.CALL_ALARM_CENTRAL_START, "#f5a623"],
  [TimelineEventEnum.CALL_ALARM_CENTRAL_PICKUP, "#70981a"],
  [TimelineEventEnum.CALL_ALARM_CENTRAL_END, "#f6182d"],
  
  [TimelineEventEnum.CALL_PRIVATE_PIN, "#70981a"],
  [TimelineEventEnum.CALL_PRIVATE_END, "#EA2331"],

  [TimelineEventEnum.HEADER_SM_ANSWERED, "#a9cf55"],
  [TimelineEventEnum.HEADER_NO_SM_PICKUP, "#f6182d"],
  [TimelineEventEnum.HEADER_NO_RECEIVER_PICKUP, "#f6182d"],
  [TimelineEventEnum.HEADER_NO_RECEIVER_PIN, "#f6182d"],
  [TimelineEventEnum.HEADER_NO_ALARM_CENTRAL_PICKUP, "#f6182d"],

  [TimelineEventEnum.INTEGRATION_ATTEMPT, "#189cf6"],
  [TimelineEventEnum.INTEGRATION_SUCCESS, "#70981a"],
  [TimelineEventEnum.INTEGRATION_FAILURE, "#f6182d"],
])
