import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/INITIALIZE'
);

export const twinSuffix: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/TWIN_SUFFIX'
);

export const partnerDeviceList: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/PARTNER_DEVICE_LIST'
);


// Filter
export const filterDevices: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/FILTER_DEVICES'
);

export const searchSensors: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/SEARCH_SENSORS'
);

export const filterPartnerDevices: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/FILTER_PARTNER_DEVICES'
);

export const clearFilters: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/CLEAR_FILTERS'
);

// Alarm analysis
export const alarmAnalysis: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/ALARM_ANALYSIS'
);

export const shareAlarm: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/SHARE_ALARM'
);

export const resetAlarm: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/RESET_ALARM'
);

// Device
export const updateDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/UPDATE_DEVICE'
);

// Tracking
export const startTracking: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/START_TRACKING'
);

export const getAllNotes: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/GET_ALL_NOTES'
);

export const updateNotes: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Devices/UPDATE_NOTES'
);