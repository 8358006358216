import { DeviceMac } from "../../Indoor/types";
import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import {feedbackFailure, feedbackSuccess} from "../../../Feedback/reducer";
import {Feedback} from "../../labels";

function* getDeviceMacs(action: AppAction<number[]>){
  try {
    const learnLink:string = yield call(Api.getLearnLink);
    yield put(Routines.getLearnLink.success(learnLink));
    const deviceMacs: DeviceMac[] = yield call(Api.getDeviceMacs, action.payload);
      yield put(Routines.getDeviceMacs.success(deviceMacs));
    }
    catch(e){
      yield put(Routines.getDeviceMacs.failure());
    }
}

function* getInitialPosition(action: AppAction<number>){
  try {
    const position:string = yield call(Api.getInitialPosition, action.payload);
    yield put(Routines.getInitialPosition.success(position));
  }
  catch(e){
    yield put(Routines.getInitialPosition.failure());
  }
}

function* addDeviceKnownMac(action: AppAction<DeviceMac>){
  try{
    const response: DeviceMac = yield call(Api.addDeviceKnownMac, action.payload);
    yield put(Routines.addDeviceKnownMac.success(response));
    yield feedbackSuccess(Feedback.ADD_DEVICE_KNOWN_MAC);
  }
  catch(e){
    console.log(e);
    yield put(Routines.addDeviceKnownMac.failure());
    yield feedbackFailure(e);
  }
}

function* addDeviceKnownMacs(action: AppAction<DeviceMac[]>){
  try{
    const response: DeviceMac[] = yield call(Api.addDeviceKnownMacs, action.payload);
    yield put(Routines.addDeviceKnownMacs.success(response));
    yield feedbackSuccess(Feedback.ADD_DEVICE_KNOWN_MAC);
  }
  catch(e){
    console.log(e);
    yield put(Routines.addDeviceKnownMac.failure());
    yield feedbackFailure(e);
  }
}

function* deleteDeviceKnownMac(action: AppAction<DeviceMac>){
  try{
    const response: DeviceMac = yield call(Api.deleteDeviceKnownMac, action.payload);
    yield put(Routines.deleteDeviceKnownMac.success(response));
    yield feedbackSuccess(Feedback.DELETE_DEVICE_KNOWN_MAC);
  }
  catch(e){
    console.log(e);
    yield put(Routines.deleteDeviceKnownMac.failure());
    yield feedbackFailure(e);
  }
}

function* scanWifi(action: AppAction<number>){
  try{
    yield call(Api.scanWifi, action.payload);
    yield put(Routines.scanWifi.success());
    yield feedbackSuccess(Feedback.SCAN_WIFI_SUCCESS);
  }
  catch(e){
    yield put(Routines.scanWifi.failure());
    yield feedbackFailure(e);
  }
}

function* scanBLE(action: AppAction<number>){
  try{
    yield call(Api.scanBLE, action.payload);
    yield put(Routines.scanBLE.success());
    yield feedbackSuccess(Feedback.SCAN_BLE_SUCCESS);
  }
  catch(e){
    yield put(Routines.scanBLE.failure());
    yield feedbackFailure(e);
  }
}

function* indoorSaga(){
    yield takeLatest(Routines.getDeviceMacs.TRIGGER, getDeviceMacs);
    yield takeLatest(Routines.getInitialPosition.TRIGGER, getInitialPosition);
    yield takeLatest(Routines.addDeviceKnownMac.TRIGGER, addDeviceKnownMac);
    yield takeLatest(Routines.addDeviceKnownMacs.TRIGGER, addDeviceKnownMacs);
    yield takeLatest(Routines.deleteDeviceKnownMac.TRIGGER, deleteDeviceKnownMac);
    yield takeLatest(Routines.scanWifi.TRIGGER, scanWifi);
    yield takeLatest(Routines.scanBLE.TRIGGER, scanBLE);
}

export default indoorSaga;
