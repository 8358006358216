import * as React from "react";
import FieldWrapper, {GenericTextField, GenericDropdown, GenericCheckbox} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import {callbackTimerOpts, prefComMethod, twoWayDectTimer} from "./options";
import {LabelEnum} from "../../LabelEnum";
import { time, status, TIME_OFFSET } from "@Elements/Form/utils";
import { heartBeatOpts } from "@Safemate/Settings/General/Tabs/gx8/options";

// Inputs
export const SelfCheck = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.SELF_CHECK_LIMIT,
  title: LabelEnum.SELF_CHECK_INTERVAL,
  tooltip: LabelEnum.SELF_CHECK_INTERVAL_TOOLTIP,
  postfix: time.minutes
});
export const HeartbeatInterval  = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.MESSAGE_INTERVAL_PERIODIC,
  title: LabelEnum.HEARTBEAT,
  tooltip: LabelEnum.HEARTBEAT_TOOLTIP,
  postfix: time.minutes
});

export const SensorDisconnectTreshold = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.SENSOR_DISCONNECT_THRESHOLD,
  title: LabelEnum.SENSOR_DISCONNECT_THRESHOLD,
  tooltip: LabelEnum.SENSOR_DISCONNECT_THRESHOLD_TOOLTIP,
  postfix: time.minutes
})

// Dropdowns
export const HeartbeatIntervalGX8 = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.HEARTBEAT_INTERVAL,
  title: LabelEnum.HEARTBEAT,
  tooltip: LabelEnum.HEARTBEAT_GX8_TOOLTIP,
  dataOptions: {
    options: heartBeatOpts,
    nullValue: status.disable,
    offset: TIME_OFFSET.SECONDS_IN_MINUTE
  }
});

export const CallbackTimer = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.CALLBACK_TIMER,
  title: LabelEnum.CALLBACK_TIMER,
  dataOptions: {
    options: callbackTimerOpts,
    nullValue: status.disable,
    offset: TIME_OFFSET.SECONDS_IN_SECOND
  }
});

export const PrefComMethod = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.PREF_COM_METHOD,
  title: LabelEnum.PREF_COM_METHOD,
  dataOptions: {
    options: prefComMethod,
    skipMap: true
  }
});

export const TwoWayDectTimer = FieldWrapper({
  componentOptions: {
    Component: GenericDropdown,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.TWO_WAY_DECT_TIMER,
  title: LabelEnum.TWO_WAY_DECT_TIMER,
  dataOptions: {
    options: twoWayDectTimer,
    offset: TIME_OFFSET.SECONDS_IN_SECOND
  }
});

// Checkboxes
export const SilentActive = FieldWrapper({
  componentOptions: {
    Component: GenericCheckbox,
    props: {
      checkboxString: false,
      label: {id: LabelEnum.SILENT_ACTIVE, defaultMessage: "Stille panel"}
    }
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.SILENT_ACTIVE
});


// Generated 
export const TriggerFourHeartbeat = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.COMMUNICATION,
  field: SettingEnum.TRIGGER_FOUR_HEARTBEAT,
  title: LabelEnum.HEARTBEAT,
  tooltip: LabelEnum.HEARTBEAT_TOOLTIP,
  postfix: time.seconds 
});
