import {call, put, takeLatest, select} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { OrgValues } from '../../Organisation';
import { feedbackSuccess, feedbackFailure } from '../../../Feedback/reducer';
import { Feedback } from '../../labels';
import { RootState } from '../types';
import { OrganisationForm } from '@Safemate/Settings/General/types';
import {getDehaId} from "@Safemate/Settings/Store/selectors";

function* fetchOrganisation(action: AppAction<number>){
  try {
    const organisations = yield call(Api.getOrganisation, action.payload);
    yield put(Routines.fetchOrganisation.success(organisations));
  }
  catch(e){
    yield put(Routines.fetchOrganisation.failure());
  }
}

export interface SaveOrganisation{
  deviceId: number;
  values: OrganisationForm;
}

function* saveOrganisation(action: AppAction<SaveOrganisation>){
  try{
    let dehaId = yield select(getDehaId);
    const response = yield call(Api.saveOrganisation, action.payload);
    console.log(dehaId);
    yield put(Routines.saveOrganisation.success(response));
    const { deviceId } = action.payload;
    yield put(Routines.initialize.trigger({deviceId}));
    yield feedbackSuccess(Feedback.SUBORG_CHANGED);
    if (action.payload.deviceId !== response) {
      // Looks like the device id has changed, meaning we need to navigate away.
      console.log("Device has changed. Rerouting.")
      yield reroute(response, dehaId);
    }
  }
  catch(e){
    console.log(e);
    yield feedbackFailure(e);
    yield put(Routines.saveOrganisation.failure());
  }
}

function* reroute(deviceId: number, dehaId: any){
  location.hash = "settings/"+deviceId+"/"+dehaId+"/misc/org";
}

function* organisationSaga(){
    yield takeLatest(Routines.fetchOrganisation.TRIGGER, fetchOrganisation);
    yield takeLatest(Routines.saveOrganisation.TRIGGER, saveOrganisation);
}

export default organisationSaga;
