import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@RoleEditor/INITIALIZE'
);

// Roles
export const getRole: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@RoleEditor/GET_ROLE'
);
export const saveRole: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@RoleEditor/SAVE_ROLE'
);
export const getPolicies: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@RoleEditor/GET_POLICIES'
);