import * as React from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import Tooltip from "@Elements/Tooltip";
import InformationIcon from "@Icons/Information1";
import styled from "styled-components";

const HeaderLight = styled.h2`
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: ${props => props.theme.margins.standard};
  padding: 0;
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  padding-bottom: ${props => props.theme.paddings.standard};
  margin-bottom: 15px;
  & > * {
    margin-right: ${props => props.theme.margins.standard};
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
`;

interface SettingsHeaderProps{
  title: string;
  tooltip?: string;
  tooltipTitle?: MessageDescriptor;
  customText?: React.ReactNode;
}

const SettingsHeader = ({title, tooltip, customText, tooltipTitle, children}: React.PropsWithChildren<SettingsHeaderProps>) => {

  const { formatMessage } = useIntl();

  return(
    <HeaderWrap>
      <HeaderLight>
        {title}
      </HeaderLight>
      {(tooltip || customText) &&
      <div>
        <Tooltip
          customText={customText}
          labelId={tooltip}
          defaultMessage={tooltip}
          popoverId={`${title}-popover`}
          title={tooltipTitle ? formatMessage(tooltipTitle) : ""}
        >
          <InformationIcon size="large" />
        </Tooltip>
      </div>}
      {children}
    </HeaderWrap>
  )
}

export default SettingsHeader;