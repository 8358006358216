import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/INITIALIZE'
);

export const initializeDeviceHardware: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/INITIALIZE_DEVICE_HARDWARE'
);


// Settings
export const fetchSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/FETCH_SETTINGS'
);

export const fetchCustomerSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/FETCH_CUSTOMER_SETTINGS'
);

export const saveDeviceSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/SAVE_DEVICE_SETTINGS'
);

export const saveOtherSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/SAVE_OTHER_SETTINGS'
);

export const saveEventSetupSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/SAVE_EVENT_SETUP_SETTINGS'
);

export const saveIndoorLocationSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/SAVE_INDOOR_LOCATION_SETTINGS'
);

export const deleteIndoorLocation: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/DELETE_INDOOR_LOCATION'
);

export const saveGeofenceSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/SAVE_GEOFENCE_SETTINGS'
);

export const deleteGeofence: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/DELETE_GEOFENCE'
);

export const fetchSettingsFromDeviceHardware:  Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/FETCH_SETTINGS_FROM_DEVICE_HARDWARE'
);

export const fetchSettingsFromCustomer:  Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/FETCH_SETTINGS_FROM_CUSTOMER'
);

export const resetDeviceSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/RESET_DEVICE_SETTINGS'
);

export const resetOtherSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/RESET_OTHER_SETTINGS'
);

export const cancelSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/CANCEL_SETTINGS'
);

export const getClimaxConfig: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/CLIMAX_CONFIG'
);

// Customer
export const selectCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/SELECT_CUSTOMER'
);

export const customerPosition: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/CUSTOMER_POSITION'
);

// DeviceHardware
export const selectDeviceHardware: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/SELECT_DEVICE_HARDWARE'
);

// Firmware
export const fetchDeviceFirmware: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/FETCH_DEVICE_FIRMWARE'
);

//  Integration
export const testIntegrationPing: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/TEST_INTEGRATION_PING'
);

export const resetIntegrationPassword: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/RESET_INTEGRATION_PASSWORD'
);

// Customer Indoor locations
export const customerIndoorLocations: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@DefaultSettings/CUSTOMER_INDOOR_LOCATION'
);
