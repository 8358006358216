import React from 'react';
import { Grid, Row } from 'react-bootstrap';

import { titleTooltips as Tips } from '../tooltips';
import { Tooltip } from '../layoutComp';

import {
  TabHeader,
  Content,
  SubHeader,
  CapitalizedString
} from '../WrapperStyles';
import { useIntl } from 'react-intl';
import { FullWidthStyledInput } from '../FormStyles';

const RoleTitle = () => {

  const { formatMessage } = useIntl();

  return (
    <Grid fluid>
      <Row>
        <TabHeader>
          <CapitalizedString>
            {formatMessage({id: 'roleTitleRoleEditor', defaultMessage: 'Role Title'})}
          </CapitalizedString>
        </TabHeader>
      </Row>
      <Row>
        <SubHeader>
          <CapitalizedString>
            {formatMessage({id: 'title', defaultMessage: 'Title'})}
          </CapitalizedString>
          <Tooltip
            id="title"
            tooltip={formatMessage({id: Tips.title, defaultMessage: Tips.titleContent})}
          />
        </SubHeader>
      </Row>
      <Row>
        <Content>
          <FullWidthStyledInput
            name="roleName"
          />
        </Content>
      </Row>
    </Grid>
  );
};

export default RoleTitle;
