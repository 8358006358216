import {Reducer} from "redux";
import { v4 } from "uuid";
import {createRoutine, Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {AxiosError, AxiosResponse} from "axios";
import {put} from "redux-saga/effects";
import {LabelEnum} from "./LabelEnum";
import { feedbackText } from "@Safemate/TranslationModule/TranslateOverlay/Store/routines";


export interface FeedbackState{
  danger: boolean;
  message: string;
  feedbackUUID: string;
}

export const feedback: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Feedback/FEEDBACK'
);

export function* feedbackFailure(e: unknown){
  try{

    const error = e as AxiosError;

    const response: AxiosResponse = error.response;

    if(response.status === 403){
      yield put(feedbackText.failure(LabelEnum.NO_ACCESS));
      yield put(feedback.failure(LabelEnum.NO_ACCESS));
      return;
    }

    if(response.data && response.data.message){
      yield put(feedbackText.failure(response.data.message));
      yield put(feedback.failure(response.data.message))
      return;
    }
    if(response.status === 504 || response.status === 502){
      yield put(feedbackText.failure(LabelEnum.NO_SERVER));
      yield put(feedback.failure(LabelEnum.NO_SERVER));
      return;
    }
    yield put(feedbackText.failure(`Failed in request to ${error.config.url} ${response.status} - ${response.statusText}`));
    yield put(feedback.failure(`Failed in request to ${error.config.url} ${response.status} - ${response.statusText}`));
  }
  catch(e){
    // Generic error
    yield put(feedbackText.failure(LabelEnum.TECHNICAL_ERROR));
    yield put(feedback.failure(LabelEnum.TECHNICAL_ERROR));
  }
}

export function* feedbackFailureString(message: string){
  yield put(feedbackText.failure(message));
  yield put(feedback.failure(message));
}

export function* feedbackSuccess(message: string){
  yield put(feedbackText.success(message));
  yield put(feedback.success(message));
}

export function* feedbackFullfill(){
  yield put(feedback.fulfill(null));
}

const feedbackState: FeedbackState = {
  message: "",
  danger: false,
  feedbackUUID: v4()
};

const feedbackReducer: Reducer<FeedbackState> = (state: FeedbackState = feedbackState, action: any) => {
  switch (action.type) {
    case feedback.SUCCESS:
      return {
        danger: false,
        message: action.payload,
        feedbackUUID: v4()
      };

    case feedback.FAILURE:
      return{
        danger: true,
        message: action.payload,
        feedbackUUID: v4()
      }

    case feedback.FULFILL:
      return{
        danger: false,
        message: "",
        feedbackUUID: v4()
      }

    default:
      return state;
  }
};

export default feedbackReducer;
