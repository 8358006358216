import {WrappedComponentProps, injectIntl} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {IndoorLocation, IndoorLocationFormik} from "../types";
import Edit from "../../../../Elements/Icon/Icons/Edit";
import Pin from "../../../../Elements/Icon/Icons/Pin";
import Trash from "../../../../Elements/Icon/Icons/Trash";
import {connect} from "react-redux";
import {
  AccentedText,
  DeleteBtn,
  FlexDiv,
  IconBtn,
  IndoorHeading,
  InnerFlexDiv
} from "@Safemate/Settings/IndoorLocation";
import {deleteIndoorLocation} from "@Safemate/Settings/Store/routines";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {
  SetDisplayIndoorDevices, setDisplayIndoorDevices,
  setDisplayMap,
  SetDisplayMap,
} from "@Safemate/Settings/Store/actions";
import {confirmModal} from "@Safemate/Settings/IndoorLocation/utils";
import {accessToCustomer, checkPermissionsForCustomer} from "@Safemate/Policy/rightsUtil";
import {PolicyNameEnum} from "@Safemate/PolicyNameEnum";
import { AppState } from "@Safemate/Store/types";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import {Device} from "@Safemate/Settings/types";



interface EditProps extends WrappedComponentProps{
  indoorLocation: IndoorLocation,
  deleteIndoorLocation: Routine<ActionFunctionAny<Action<any>>>,
  setDisplayMap: SetDisplayMap;
  setDisplayIndoorDevices: SetDisplayIndoorDevices;
  device: Device;
}

const mapStateToProps = ({ settings: {device: { device }, indoorLocation: { indoorLocation}}}: AppState) => {
  return {
    indoorLocation, device
  }
}

const mapDispatchToProps = {
  deleteIndoorLocation,
  setDisplayMap,
  setDisplayIndoorDevices,
};

export const EditLocation = injectIntl(withFormWrapper<IndoorLocationFormik, EditProps>(
  ({formik: { setFieldValue, values }, intl: { formatMessage }, deleteIndoorLocation, indoorLocation, setDisplayMap, setDisplayIndoorDevices, device }) => {

    const {Comp, func} = confirmModal();

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const sharedAccess = checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_INDOOR_CUSTOMER, device.ownedByCustomer);
    const sharedLocation = values.indoorLocation.custId > 0 && values.indoorLocation.deviId == 0;
    const disabledEdit: boolean = sharedLocation ? !sharedAccess : false;

    return(
      <React.Fragment>
        <Row>
          <Col xs={12} md={4} mdOffset={4}>
            <FlexDiv>
              <InnerFlexDiv>
                <IconBtn onClick={() => setDisplayIndoorDevices(true)}><React.Fragment><Edit inline size={"medium"}  />&nbsp;{`${ disabledEdit ? formatMessage({id: "viewIndoorLocationReferencePoints", defaultMessage: "View reference points"}) : formatMessage({id: "editIndoorLocationReferencePoints", defaultMessage: "Edit reference points"}) } (${values.indoorLocation.indoorLocationDevices.length})`}</React.Fragment></IconBtn>
              </InnerFlexDiv>
              <InnerFlexDiv>
                <IconBtn onClick={() => setDisplayMap(true)}><React.Fragment><Pin  inline size={"medium"} />&nbsp;{ disabledEdit ? formatMessage({id: "viewIndoorLocationMap", defaultMessage: "View location"}) : formatMessage({id: "editIndoorLocationMap", defaultMessage: "Pinpoint again"}) }</React.Fragment></IconBtn>
              </InnerFlexDiv>
              {
                !disabledEdit && !indoorLocation.shared && <InnerFlexDiv>
                  <DeleteBtn disabled={disabledEdit} onClick={() => func(true)}><React.Fragment>
                    <Trash inline

                           size={"medium"}/>&nbsp;
                    {formatMessage({
                      id: "deleteIndoorLocationButton",
                      defaultMessage: "Delete indoor location"
                    })}</React.Fragment>
                  </DeleteBtn>
                </InnerFlexDiv>
              }
            </FlexDiv>
          </Col>
        </Row>
        {
          !disabledEdit && !indoorLocation.shared &&
          <Comp
            title={`${formatMessage({id: "delete", defaultMessage: "Delete"})} ${formatMessage({id: "indoorLocation", defaultMessage: "indoor location"})}`}
            body={
              <React.Fragment>
                  <p>
                      <span>{formatMessage({id: "deleteIndoorLocationConfirmtext", defaultMessage: "Do you want to really delete indoor location"})}</span>
                      <AccentedText>{` ${indoorLocation.name}?`}</AccentedText>
                  </p>
                  { device.twin && device.twinDevice && <p>
                      <span>{`${formatMessage({id: "deleteIndoorLocationTwinConfirmtext", defaultMessage: "This action will delete WIFI Location"})} ${indoorLocation.name} ${formatMessage({id: "deleteIndoorLocationTwinForBoth", defaultMessage: "for both"})} ${device.deviceSerialNumber} & ${device.twinDevice.deviceSerialNumber}.`}</span>
                  </p> }
              </React.Fragment>
            }
            confirmText={formatMessage({id: "delete", defaultMessage: "Slette"})}
            confirmFunc={() => {
              deleteIndoorLocation(indoorLocation);
              func(false);
            }}
            deleteButton={true}
          />

        }
      </React.Fragment>
    )}
))

export const IndoorLocationEdit = connect(mapStateToProps, mapDispatchToProps)(EditLocation);
