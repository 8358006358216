import _axios, {AxiosError, AxiosInstance} from 'axios';
import { SiteTypeEnum } from '../../SiteTypeEnum';
import {UserToDelete, UserWithCustomerForSave} from "./Saga/actions";

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getUsers = async (siteType: SiteTypeEnum) => {
  const url: string = siteType === SiteTypeEnum.AHP ? `/user/forahp` : `/user/forfirm`;
  const response = await axios.get(url);
  return response.data;
};

export const getUser = async (siteType: SiteTypeEnum, id: number) => {
  const url: string = `/user/userforfirm/${id}`;
  const response = await axios.get(url);
  return response.data;
};

export const getDeletedUsers = async () => {
  const url: string = `/user/deletedforfirm`;
  const response = await axios.get(url);
  return response.data;
}

export const getLdapUsers = async () => {
  const url: string = `/user/ldapforfirm`;
  const response = await axios.get(url);
  return response.data;
}

export const getRoles = async () => {
  const url: string = `/userroles/rolenames`;
  const response = await axios.get(url);
  return response.data;
}

export const saveUser = async (userToSave: UserWithCustomerForSave, isPrivate: boolean) => {
  const url: string = `/user/withfirmlinks/${isPrivate}`;
  const response = await axios.post(url, userToSave);
  return response.data;
}

export const deleteUser = async (userToDelete: UserToDelete) => {
  const url: string = `/user/removefromfirm/${userToDelete.custId}/${userToDelete.userId}`;
  const response = await axios.delete(url);
  return response.data;
}

export const deleteUserAhp = async (userToDelete: UserToDelete) => {
  const url: string = `/user/removefromahp/${userToDelete.userId}`;
  const response = await axios.delete(url);
  return response.data;
}


export const getAdminRole = async() => {
  const url: string = "/userroles/admin";
  const response = await axios.get(url);
  return response.data;
}

export const sendNewPassword = async(sendNewPassword: string) => {
  const url: string = `/user/forgot/${sendNewPassword}`;
  const response = await axios.get(url);
  return response.data;
}

export const assignGdprManager = async (userId: number) => {
  const url: string = `/customer/setGdprManagerForCustomer/${userId}`;
  const response = await axios.get(url);
  return response.data;
}

export const removeGdprManager = async () => {
  const url: string = `/customer/removeGdprManager`;
  const response = await axios.get(url);
  return response.data;
}