import styled from 'styled-components';

export const Wrapper = styled.div`
  float: left;
  display: flex;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledFieldset = styled.fieldset`
  ${Wrapper} {
    padding-right: 25px;
  }
`;

export const CheckLabel = styled.label`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const StyledCheck = styled.input`
  display: none;
`;

export const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledSpan = styled.span`
  display: inline-block;
  width: ${props => (props.large ? ' 30px' : '21px')};
  height: ${props => (props.large ? ' 30px' : '21px')};
  font-weight: 300;
  background: ${props => (props.active ? props.theme.colors.accent : 'none')};
  border: ${props => (props.active ? '0' : '1px solid #aaadb0')};
  border-radius: ${props => (props.circle ? '15px' : '3px')};
  margin: -1px 4px 0 0;
  padding: 0;
  transition: 0.1s;
  background-position: 50%;
  vertical-align: middle;
  flex-shrink: 0;
`;

export const BoxWrapper = styled.div`
  float: ${props => (props.horizontal ? 'left' : 'initial')};
`;
