import React from 'react';
import SIcon from './SIcon';
import { FormattedMessage } from 'react-intl';

const MountedButtons = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M.6 17.164h15.1c.3 0 .5-.2.5-.5v-1.5c0-1.3-1.1-2.4-2.4-2.4H2.5c-1.3 0-2.4 1.1-2.4 2.4v1.5c0 .2.2.5.5.5zm11.7-4.4v-.3c0-.9-.6-1.6-1.4-1.6H5.5c-.8 0-1.4.7-1.4 1.6v.3h8.2zm-11.2 2.4c0-.8.7-1.5 1.5-1.5h11.2c.8 0 1.5.7 1.5 1.5v1H1.1v-1zm4-2.4v-.2c0-.4.2-.8.7-.8h4.7c.5 0 .8.2.8.8v.2H5.1zm-1.5-5c-.3-.3-.5-.8-.5-1.3s.2-.9.6-1.2l3-2.4c.4-.2.8-.4 1.3-.4h11.8c.1 0 .2.1.2.2v.5c0 .1-.1.2-.2.2H8c-.3 0-.6.1-.8.3l-3 2.4c-.3.2-.3.7 0 1 .2.2.6.2.8 0l1.8-1.5c.3-.2.6-.3.9-.3H13c.3 0 .5.2.5.5s-.2.5-.5.5h-2.6c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h4.3c.4 0 .8-.1 1.1-.4l1.4-1h2.6c.1 0 .2.1.2.2v.5c0 .1-.1.2-.2.2h-2.3l-1.1.8c-.5.4-1.1.6-1.7.6h-4.3c-.8 0-1.6-.6-1.7-1.4-.1-.3 0-.7.1-1H7.7c-.1 0-.1 0-.2.1l-1.8 1.5c-.6.5-1.5.5-2.1 0z" />
    <title>
      <FormattedMessage id="panicButton" defaultMessage="Alarmknapp" />
    </title>
  </svg>
);

const Icon = props => <SIcon {...props} icon={MountedButtons} />;

export default Icon;
