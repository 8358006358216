import * as React from "react";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { getSensorOptions, mapConfig } from "../utils";
import { RootState } from "@Safemate/Settings/Store/types";
import { connect } from "react-redux";
import SettingTab from "@Safemate/Settings/Components/settingTab";
import { SettingEnum } from "@Safemate/Settings/settingEnum";
import SensorSelect from "../sensorSelect";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { WANDERER_FUNCTION } from "@Safemate/Settings/Sensor/SensorFunctionEnum";
import { LoaderWrap } from "@Safemate/Spinner";
import Loader from "@Elements/Loader";
import { NoValidSensors } from "../components";
import { ISensorDTO } from "@Safemate/Settings/Sensor/types";
import { saveEventConfig } from "@Safemate/Settings/Store/routines";
import { AppState } from "@Safemate/Store/types";

interface WandererProps{
  sensors: ISensorDTO[];
  dehaId: number;
  deviId: number;
  savedSensorId: number;
  saveEventConfig: Routine<ActionFunctionAny<Action<any>>>;
}

const Wanderer = ({ sensors, dehaId, deviId, saveEventConfig, savedSensorId }: WandererProps) => {

  const [ selectedSensor, setSelectedSensor ] = React.useState<ISensorDTO | null>(null);

  const mappedSensors = React.useMemo(() => {
    if(sensors.length > 0){
      return getSensorOptions(sensors, WANDERER_FUNCTION.code);
    }
    return [];
  }, [sensors])
  
  React.useEffect(() => {
    if(savedSensorId){
      mappedSensors.map(sensor => {
        if(sensor.value.sensor.deseId === savedSensorId){
          setSelectedSensor(sensor.value);
        }
      })
    }else{
      if(mappedSensors.length > 0){
        setSelectedSensor(mappedSensors[0].value);
    }    
  }
  },[mappedSensors, savedSensorId])
  
  if(!sensors || mappedSensors.length === 0) return <NoValidSensors title="wandererAlarm"/>

  if(!selectedSensor){
    setSelectedSensor(mappedSensors[0].value);
    return null;
  }

  return (
    <SettingTab
      dehaId={dehaId}
      deviId={deviId}
      settings={mapConfig(selectedSensor)}
      save={saveEventConfig}
      settingEnum={SettingEnum.WANDERER_EVENT}
      header={{
        title: "wandererAlarm",
        tooltip: "wandererAlarmTabToolTipText",
        tooltipTitle: "wandererAlarm",
        toggle: true,
        toggleValue: ["eventConfig", "active"],
        children: <SensorSelect  sensors={mappedSensors} sensor={selectedSensor} setSelected={setSelectedSensor}/>
      }}
    />
  )
}

const mapStateToProps = ({ settings: {sensor: { sensors, savedSensorId }, initialization: { deviId, dehaId }}}: AppState) => {
  return{
    sensors,
    dehaId,
    deviId,
    savedSensorId
  }
}

const mapDispatchToProps = {
  saveEventConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(Wanderer);

const mapVoiceSatelliteStateToProps = ({ settings: {sensor: { sensors }, initialization: { deviId }}}: AppState) => {
  return{
    sensors,
    deviId
  }
}

interface VoiceSatelliteProps{
  sensors: ISensorDTO[];
  deviId: number;
}

export const VoiceSatellite = connect(mapVoiceSatelliteStateToProps)(({sensors, deviId}: VoiceSatelliteProps) => {

  const options = [
    {
      text: 'GX-8',
      value: null
    }
  ].concat(
    sensors
      .filter(
        sensor =>
        sensor.type && sensor.type.supportsDect
      )
      .map(sensor => ({
        text: `${sensor.sensor.name}(${sensor.sensor.gatewayId})`,
        value: sensor.sensor.deseId
      }))
  )

  const Drop = DropdownWrap(
    "voiceSatellite", 
    {
      title: {id: "dectDeviceLabel", defaultMessage: "Lydenhet"},
      dataOptions: {
        options: options,
        skipMap: true
      },
      prefix: "eventConfig",
      width: "250px"
    }
  );
  if(!deviId){
    <LoaderWrap><Loader/></LoaderWrap>
  }
  return <Drop/>
})
