import * as React from "react";
import { Col } from "react-bootstrap";
import { useIntl } from "react-intl";

import { setTrackingAdvanced, SetTrackingAdvanced } from "../../Store/actions";
import { connect } from "react-redux";
import {Header} from ".";
import Toggle from "@Elements/Switch/Toggle";
import { AppState } from "@Safemate/Store/types";

interface AdvancedProps{
  setTrackingAdvanced: SetTrackingAdvanced;
  advanced: boolean;
}

const Advanced = ({setTrackingAdvanced, advanced}: AdvancedProps) => {

  const { formatMessage} = useIntl();

  return(
    <React.Fragment>
      <Col md={4} sm={4}>
        <Header>{formatMessage({id: "advancedTracking", defaultMessage: "Avanserte innstillinger"})}</Header>
        <Toggle
          onChange={(value: boolean) => {
            setTrackingAdvanced(value);
          }}
          checked={advanced}
        />
      </Col>
    </React.Fragment>
  )
}

const mapStateToProps = ({ settings: {tracking: {advanced}}}: AppState) => {
  return {
    advanced
  }
}

const mapDispatchToProps = {
  setTrackingAdvanced
}

export default connect(mapStateToProps, mapDispatchToProps)(Advanced);