import React from 'react';
import SIcon from './SIcon';
import { FormattedMessage } from 'react-intl';

const MovementDetector = props => (
  <svg viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path d="M15.8 3.3c-.2 0-.4-.2-.4-.4s.2-.4.4-.4.4.2.4.4-.2.4-.4.4zm0 .9c.7 0 1.3-.6 1.3-1.3s-.6-1.3-1.3-1.3c-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3zm-5.5-2.7h.2c.2 0 .3.1.3.3.2 3.2 2.7 5.8 5.9 5.9.2 0 .3.2.3.3v.2c0 .2-.2.3-.3.3-3.7-.2-6.6-3.1-6.8-6.8.1 0 .2-.2.4-.2zm2 0h.2c.2 0 .3.1.3.3.2 2.1 1.8 3.8 3.9 3.9.2 0 .3.2.3.3v.2c0 .2-.2.3-.4.3-2.6-.2-4.6-2.2-4.8-4.8.2 0 .3-.2.5-.2zM10 11.2c.1 0 .8.5.5 1.5-.1.4-.6.6-.7.6-.1 0-.8.2-1.2-.1-1.3-.8-1.1-.7-1.2-.8-.2-.1-.3-.3-.4-.4l-.1.4 1 1.2c.2.2.3.5.4.9l.2 2.1c.1.7-.4 1.3-1 1.3-1 .1-1.3-.8-1.3-1l-.1-1.4-.7 1.7c-.2.5-.9.9-1.6.6-.6-.3-.9-1-.6-1.6l1.3-2.9c-.5-.6-.6-1.2-.5-1.8l.5-2c.1-.5.5-1 1-1.3-.8-.6-.8-1.5-.4-2.2.4-.6 1-.9 1.5-.9.8 0 1.5.6 1.7 1.4.1.4.2 1.3-.7 1.9.4.3.8.8 1 1.3l.3.9.1.1c.5.2.9.5 1 .5zM6.6 5.9c-.6 0-1.1.6-1 1.2.1.5.5.8 1 .8.6 0 1.1-.6 1-1.2-.1-.5-.5-.8-1-.8zm-2 10.9l1-2.3-.6-.6-1.2 2.6c-.1.2 0 .4.2.5.3.1.5 0 .6-.2zm2.9-5.6c.1.2.2.4.5.6l1.3.8c.2.1.4.1.6-.1.1-.2.1-.4-.1-.6l-1.3-.8c0-.1-.1-.1-.1-.2l-.3-.9c-.2-.5-.5-1-1-1.3 0 0-.5-.3-1-.1-.6.3-.8.7-.9 1l-.5 2c-.1.4 0 .8.3 1.1l1.6 1.8c.1.1.2.3.2.4l.1 1.9c0 .1.1.4.5.3.2 0 .4-.2.3-.4l-.2-2.1c0-.2-.1-.3-.2-.4L6 12.7l.7-2.7c0-.1.2-.1.2 0 .1 0 .2.2.3.4l.3.8z" />
    <title>
      <FormattedMessage id="pirLabel" defaultMessage="PIR-sensor" />
    </title>
  </svg>
);

const Icon = props => <SIcon {...props} icon={MovementDetector} />;

export default Icon;
