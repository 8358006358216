import _axios, {AxiosInstance} from 'axios';
import { DatabaseText } from './types';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getTexts = async (languageCode: string) => {
  const url: string = `/text/${languageCode}`;
  const response = await axios.get(url);
  return response.data;  
};

export const addTexts = async (texts: DatabaseText[]) => {
  const url: string = `/text/addtexts`;
  const response = await axios.post(url, texts.filter(text => text.translatedText));
  return response.data;  
};

export const deleteText = async (id: number) => {
  const url: string = `/text/${id}`;
  const response = await axios.delete(url);
  return response.data;
}

export const getTextForLabel = async (label: string) => {
  const url: string = `/text/forlabel/${label}`;
  const response = await axios.get(url);
  return response.data;  
};
export const getCountries = async () => {
  const url: string = `/language`;
  const response = await axios.get(url);
  return response.data;  
};

export const getUser = async () => {
  const url: string = `/user/auth`;
  const response = await axios.get(url);
  return response.data;
}