import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {Customer} from "../../Models/Customer";

export const populateEditForm = (values: UserWithCustomer, superfirm: Customer, isPrivate: boolean): UserWithCustomer => {

  let role = values.role;
  let subOrgs = values.subOrgs;

  if(isPrivate){
    role = 1;
    subOrgs = [superfirm];
  }
  
  return {
    ...values,
    role,
    subOrgs,
  }
}
