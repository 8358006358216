import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { formatDate } from "../utils";
import { RootState } from "./Store/types";
import Dashboard from "../../Elements/Icon/Icons/menu/Dashboard";
import { AppState } from "@Safemate/Store/types";


const InfoWrapper = styled.div`
  display: inline-block;
  margin-left: 1.5em;
  margin-right: 1.5em;
  height: 1.5em;
  line-height: 0.9em;
  font-weight: bold;
  font-size: 1.75em
`;

const TimeHeader = styled.span`
  vertical-align: top;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.4em;
  color: ${props => props.theme.colors.textPrimary};
`;

const LastContact = styled.span`
  color: #55951b;
`;

const LastPos = styled.span`
  color: #b53237;
`;

const Name = styled.span`
  color: #f5af13;
`;

interface TrackHeaderProps{
  deviceName: string;
  serialNumber: string;
  posTime: number;
  lastMsgIn: number;
  isTrackerOnly: boolean;
}

const mapStateToProps = ({ appData:{ isTrackerOnly }, transactionLog: {device: { deviceName, serialNumber, lastMsgIn, posTime }}}: AppState) => {
  return{
    deviceName,
    serialNumber,
    posTime,
    lastMsgIn,
    isTrackerOnly
  }
}

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  button {
    margin: 5px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
`;


const TrackHeader = connect(mapStateToProps)(({ deviceName, isTrackerOnly, serialNumber, posTime, lastMsgIn }: TrackHeaderProps) => {

  const { formatMessage } = useIntl();

  if(!isTrackerOnly) return null;

  return(
    <div>
      <HeaderWrapper>
        <InfoWrapper>
          <Name>{`${deviceName} (${serialNumber})`}</Name>
        </InfoWrapper>
        <ButtonWrapper>
          <button onClick={() => {location.hash = `safemates`}} className="btn btn-dark-with-line btn-small"><Dashboard inline size="medium"/></button>
        </ButtonWrapper>
      </HeaderWrapper>
      <InfoWrapper>
        <TimeHeader>{formatMessage({id:"lastServerContact", defaultMessage: "Siste kontakt med server"})}</TimeHeader>
        <br/>
        <LastContact>{formatDate(lastMsgIn, false, false, false, true, false, formatMessage)}</LastContact>
      </InfoWrapper>
      <InfoWrapper>
        <TimeHeader>{formatMessage({id:"lastValidPos", defaultMessage: "Siste gyldige posisjon"})}</TimeHeader>
        <br/>
        <LastPos>{formatDate(posTime, false, false, false, true, false, formatMessage)}</LastPos>
      </InfoWrapper>
    </div>
  )

})

export default TrackHeader;