import * as React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Svg = styled(motion.svg)<{selected?: boolean}>`
  fill: ${p => (p.selected ? p.theme.colors.backgroundPrimary : p.theme.colors.textPrimary)};
  width: 20px;
  height: 20px;
`;

export const Triangle = (props: any) => (
  <Svg viewBox="0 0 2 2" {...props}>
    <polygon points="0,0 2,0 1,2" />
  </Svg>
);

export const Select = styled.div<{disabled?: boolean, active: boolean, searching?: boolean, size?: {width?: string; height?: string}, borderRadius?: string, selected?: boolean}>`
  transition: border 0.3s;
  width: ${props => props.size && props.size.width ? props.size.width : "100%"};
  ${props => props.size && props.size.height && `height: ${props.size.height}`};
  display: flex;
  align-items: center;
  cursor: ${props => (!props.disabled ? 'pointer' : '')};
  border-radius: ${props => props.borderRadius ? props.borderRadius:  props.theme.colors.borderRadius};
  border: ${props => props.theme.colors.border};
  border-color: ${props => (props.active && !props.disabled ? '#adadad' : '')};
  background-color: ${props => (props.selected ? props.theme.colors.accent : props.theme.colors.backgroundPrimary)};
  color: ${props => (props.selected ? props.theme.colors.backgroundPrimary : props.theme.colors.textPrimary)};

  &:hover {
    border-color: ${props => (!props.disabled ? '#adadad' : '')};
  }
`;


export const SelectWrapper = styled.div<{ref?: any, disabled: boolean}>`
  position: relative;
  background: none;
  border-radius: ${props => props.theme.colors.borderRadius};
  color: ${props => (props.disabled ? 'gray' : props.theme.colors.textPrimary)};
  outline: none;
  transition: border 0.3s;
  width: 100% !important;
`;

export const SelectBox = styled(motion.ul)<{expand?: boolean, disabled?: boolean, size?: {width?: string; height?: string}}>`
  position: ${props => props.expand ? "relative" : "absolute"};
  border-radius: ${props => props.theme.colors.borderRadius};
  z-index: 10000;
  min-width: ${props => props.size && props.size.width || "100%"};
  background: ${props => props.theme.colors.backgroundSecondary};
  border: 1px dotted #858c97;
  color: ${props => props.theme.colors.accent};
  max-height: 300px;
  overflow: auto;
  list-style-type: none;
  padding: 0;
`;

export const StyledLi = styled(motion.li)<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  background: ${props => (props.disabled ? 'gray' : props.theme.colors.backgroundPrimary)};
  color: ${props => props.theme.colors.accent};

  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    color: ${props =>
      props.disabled ? 'inherit' : props.theme.colors.textPrimary};
  }
`;

export const ExpandDiv = styled.div<{level: number, last: boolean}>`
  padding: 10px 10px 10px ${props => props.level * 20 + (props.last ? 8 : 0)}px;
`

export const ListElement = styled.div<{disabled?: boolean}>`
  width: 100%;
  display: inline-block;
  background: ${props => (props.disabled ? 'gray' : 'initial')};
  border-top: 1px solid ${props => props.theme.colors.backgroundSecondary};
  padding: ${props => props.theme.paddings.standard};
  label {
    margin-bottom: 0;
  }
  svg {
    margin-bottom: 0;
    float: right;
    &:hover {
      fill: ${props => props.disabled ? 'inherit' : props.theme.colors.accent};
    }
  }
`;

export const Caret = styled.b<{open?: boolean, last?: boolean}>`
  display: inline-block;
  width: 8px;
  height: 0;
  vertical-align: middle;
  border-top: ${props =>
  props.last
    ? '4px solid'
    : props.open
    ? '4px solid transparent'
    : '4px dashed'};
  border-right: ${props =>
  props.last ? '4px solid' : props.open ? 'none' : '4px solid transparent'};
  border-left: ${props =>
  props.last
    ? '4px solid'
    : props.open
    ? '4px dashed'
    : '4px solid transparent'};
  border-bottom: ${props =>
  props.last ? '4px solid' : props.open ? '4px solid transparent' : 'none'};
  border-radius: ${props => (props.last ? '50%' : 'none')};
`;

export const TitleContainer = styled.span<{right?: boolean}>`
  padding: 10px;
  display: flex;
  margin-left: ${props => (props.right ? 'auto' : '')};
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledInput = styled.input`
  border-radius: 5px;
  line-height: 2.3;
  width: 40%;
  height: 40px;
  background: ${props =>
    props.disabled
      ? 'rgba(202,202,203,0.1)'
      : props.theme.colors.backgroundPrimary};
  ${props => (props.disabled ? 'cursor: not-allowed' : '')};
  border: ${props => props.theme.colors.border};
  padding-left: 15px;
`;

export const FullWidthStyledInput = styled(StyledInput)<{customSize?: {width?: string; height?: string}}>`
  width: ${props => props.customSize && props.customSize.width ? props.customSize.width : "100%"};
  height: ${props => props.customSize && props.customSize.height ? props.customSize.height : "42px"};
`;
