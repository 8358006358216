// General
export const heartBeatOpts = [
  0,
  2,
  3,
  4,
  5,
  10,
  15,
  30,
  60,
  120,
  180,
  240,
  360,
  480,
  720,
  1440,
  2880,
  4320,
  5760,
  7200,
  8640,
  10080,
  20160,
  30240,
  40320
];

export const sensorSupCheckTimerOpts = [0, 14400, 18000, 21600, 28800, 43200, 86400];

export const sensorSupCheckTimerPendantOpts = [0, 90000, 172800, 259200, 345600, 432000, 518400, 604800];

export const guardTimeOpts = [0, 10, 20, 30, 60, 120, 180, 240];

export const bypassTimerOpts = [0, 30, 60, 120, 180, 240, 300, 600];

export const sensorSensitivity = [
  {text: "1", value: 1},
  {text: "2", value: 2},
  {text: "3", value: 3},
  {text: "4", value: 4},
  {text: "5", value: 5}
]


// Communication
export const prefComOpts = [
  { text: 'Ethernet', value: 0 },
  { text: 'Mobilnett', value: 1 }
]

export const twoWayDECTTimerOpts = [
  300,
  600,
  900,
  1200
]

export const callbackTimerOpts = [
  0,
  300,
  600,
  1200,
  1800,
  2400,
  3000,
  3600
]

export const answerIncomingOpts = [
  {value: 0, text: "answerIncOpt1"},
  {value: 1, text: "answerIncOpt2"},
  {value: 2, text: "answerIncOpt3"},
]

export const externalAntennaOpts = [
  {value: 0, text: "internalAntenna"},
  {value: 1, text: "externalAntenna"}
]

// Light and Sound

export const micAndSpeakerOptions = [
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 }
];

export const micVolumeOptions = micAndSpeakerOptions;

export const dectSpeakerOptions = [
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 }
];

export const voicePromptVolumeOptions = [
  { text: '1', value: 1},
  { text: '2', value: 2},
  { text: '3', value: 3},
  { text: '4', value: 4},
  { text: '5', value: 5},
  { text: '6', value: 6},
  { text: '7', value: 7},
  { text: '8', value: 8},
  { text: '9', value: 9},
  { text: '10', value: 10},
]

// Battery

export const lowBatteryDefOpts = [
  { text: '10%', value: 0 },
  { text: '20%', value: 1 },
  { text: '30%', value: 2 },
  { text: '40%', value: 3 }
]

export const powerFailureReportOpts = [
  0,
  180,
  300,
  600,
  900,
  1200,
  1800,
  3600
]

export const lightModeOptions = [
  { text: '0', value: 0 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
];