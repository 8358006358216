import React from 'react';
import ReactSwitch from 'react-switch';
import styled from 'styled-components';
import { injectIntl, defineMessages } from 'react-intl';

const text = defineMessages({
  on: { id: 'onLabel', defaultMessage: 'På' },
  off: { id: 'offLabel', defaultMessage: 'Av' }
});

const UnChecked = styled.div`
  line-height: 28px;
  font-weight: bold;
  font-size: 16px;
`;

const Checked = styled(UnChecked)`
  margin-left: 10px;
`;

const ToggleLabel = styled.p`
  text-transform: uppercase;
`;

const Switch = props => {
  return (
    <ReactSwitch
      width={80}
      onColor="#8db11d"
      uncheckedIcon={
        <UnChecked>
          <ToggleLabel>{props.intl.formatMessage(text.off)}</ToggleLabel>
        </UnChecked>
      }
      checkedIcon={
        <Checked>
          <ToggleLabel>{props.intl.formatMessage(text.on)}</ToggleLabel>
        </Checked>
      }
      {...props}
    />
  );
};

export default injectIntl(Switch);
