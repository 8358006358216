import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { Geofence, GlobalGeofence, GeofenceDistance } from '../../Geofence/types';
import { GeofenceValues } from '../../Geofence';
import { feedbackSuccess, feedbackFailure } from '../../../Feedback/reducer';
import { Feedback } from '../../labels';
import { Position } from '@Safemate/map/Store/types';
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import { Customer } from "@Safemate/AddSafemate/Store/types";

function* fetchGeofence(action: AppAction<number>){
  try {
    const geofence: Geofence[] = yield call(Api.getGeofence, action.payload);
    yield put(Routines.fetchGeofence.success(geofence));
  }
  catch(e){
    yield put(Routines.fetchGeofence.failure());
  }
}

function* fetchGlobalGeofence(action: AppAction<number>){
  try {
    const geofence: GlobalGeofence[] = yield call(Api.getGlobalGeofence, action.payload);
    yield put(Routines.fetchGlobalGeofence.success(geofence));
  }
  catch(e){
    yield put(Routines.fetchGlobalGeofence.failure());
  }
}

function* deleteGeofence(action: AppAction<GeofenceValues>){
  try{
    const response = yield call(Api.deleteGeofence, action.payload);
    yield put(Routines.deleteGeofence.success(response));
    yield feedbackSuccess(Feedback.DELETED_GEOFENCE);
  }
  catch(e){
    yield put(Routines.deleteGeofence.failure());
    yield feedbackFailure(e);
  }
}

function* deleteSharedGeofence(action: AppAction<number>){
  try{
    const response: GlobalGeofence[] = yield call(Api.deleteSharedGeofence, action.payload);
    yield put(Routines.deleteSharedGeofence.success(response));
    yield feedbackSuccess(Feedback.DELETED_SHARED_GEOFENCE);
  }
  catch(e){
    yield put(Routines.deleteSharedGeofence.failure());
    yield feedbackFailure(e);
  }
}

interface SaveGeofence{
  values: GeofenceValues;
}

export interface SaveGeofenceResponse{
  savedGeofence: Geofence;
  allGeofences: Geofence[];
}

export interface SavedGeofenceSuccess{
  savedGeofence: Geofence;
  allGeofences: Geofence[];
}

interface UpdateDevicePositioning {
  deviceId: number;
  disablePositioning: boolean;
}

interface UpdateAutoTracking {
  deviceId: number;
  autoTracking: boolean;
}

function* saveGeofence(action: AppAction<SaveGeofence>){
  try{
    const response: SaveGeofenceResponse = yield call(Api.saveGeofence, action.payload.values);
    if(action.payload.values.disablePositioning || action.payload.values.disablePositioning  && !action.payload.values.autoTracking){
      //turn on positioning and autotracking
      const updateDevicePos :UpdateDevicePositioning = {deviceId: action.payload.values.deviId, disablePositioning: false}
      const updateAutoTracking :UpdateAutoTracking = {deviceId: action.payload.values.deviId, autoTracking: true}
      yield call(Api.updateDevicePositioning, updateDevicePos);
      if(!action.payload.values.autoTracking){
        yield call(Api.toggleAutoTracking, updateAutoTracking);
      }
      yield put(Routines.saveGeofence.success(response));
      yield feedbackSuccess(Feedback.SAVED_GEOFENCE_POSITIONING_TRACKING);
    }else if(!action.payload.values.autoTracking && action.payload.values.geofence.enabled){
      //turn on auto tracking
      const updateDevicePos :UpdateDevicePositioning = {deviceId: action.payload.values.deviId, disablePositioning: false}
      yield put(Routines.saveGeofence.success(response));
      yield feedbackSuccess(Feedback.SAVED_GEOFENCE_TRACKING);
    }else{
      //tracking and positioning are both on or it's only disabling a geofence
      yield put(Routines.saveGeofence.success(response));
      yield feedbackSuccess(Feedback.SAVED_GEOFENCE);
    }
  }
  catch(e){
    console.log(e);
    yield put(Routines.saveGeofence.failure());
    yield feedbackFailure(e);
  }
}

function* getFenceDistance(action: AppAction<GeofenceDistance>){
  try {
    const response: number = yield call(Api.getFenceDistance, action.payload);
    yield put(Routines.getFenceDistance.success(response));
    yield feedbackSuccess("null");
  }
  catch(e){
    console.log(e);
    yield put(Routines.getFenceDistance.failure());
    yield feedbackFailure(e);
  }
}

function* getLatestPosition(action: AppAction<number>){
  try{
    const response: Position[] = yield call(Api.getLatestPosition, action.payload);
    yield put(Routines.getLatestPosition.success(response));
  }
  catch(e){
    yield put(Routines.getLatestPosition.failure());
    yield feedbackFailure(e);
  }
}

export interface GlobalSettings{
  deviId: number;
  delayDay: number;
  delayNight: number;
  delayMotionless: number;
  callAlert: string;
  geofenceWifiLocationAlert: boolean;
}

function* saveGlobalSettings(action: AppAction<GlobalSettings>){
  try{
    const response = yield call(Api.saveGlobalSettings, action.payload);

    yield put(Routines.saveGlobalSettings.success(response));
    yield feedbackSuccess(Feedback.SAVED_GLOBAL_SETTINGS);
  }
  catch(e){
    console.log(e);
    yield put(Routines.saveGlobalSettings.failure());
    yield feedbackFailure(e);
  }
}

function* getGeofenceIndoorLocations(action: AppAction<number>){
  try{
    const response: IndoorLocation[] = yield call(Api.getGeofenceIndoorLocations, action.payload);
    yield put(Routines.getGeofenceIndoorLocations.success(response));
  }
  catch(e){
    yield put(Routines.getGeofenceIndoorLocations.failure());
    yield feedbackFailure(e);
  }
}

function* getCustomersGeofence(action: AppAction<number>){
  try{
    const response: Customer[] = yield call(Api.getCustomersGeofence, action.payload);
    yield put(Routines.getCustomersGeofence.success(response));
  }
  catch(e){
    yield put(Routines.getCustomersGeofence.failure());
    yield feedbackFailure(e);
  }
}

function* geofenceSaga(){
    yield takeLatest(Routines.fetchGeofence.TRIGGER, fetchGeofence);
    yield takeLatest(Routines.fetchGlobalGeofence.TRIGGER, fetchGlobalGeofence);
    yield takeLatest(Routines.deleteGeofence.TRIGGER, deleteGeofence);
    yield takeLatest(Routines.deleteSharedGeofence.TRIGGER, deleteSharedGeofence);
    yield takeLatest(Routines.saveGeofence.TRIGGER, saveGeofence);
    yield takeLatest(Routines.getFenceDistance.TRIGGER, getFenceDistance);
    yield takeLatest(Routines.saveGlobalSettings.TRIGGER, saveGlobalSettings);
    yield takeLatest(Routines.getLatestPosition.TRIGGER, getLatestPosition);
    yield takeLatest(Routines.getGeofenceIndoorLocations.TRIGGER, getGeofenceIndoorLocations);
    yield takeLatest(Routines.getCustomersGeofence.TRIGGER, getCustomersGeofence);
}

export default geofenceSaga;
