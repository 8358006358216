import {SoundLightState} from "../types";
import {Reducer} from "redux";
import { fetchSoundLight, saveSoundLight } from "../routines";

const soundLightState: SoundLightState = {
    settings:{
        soundLevel: "",
        soundLevelRingtone: "",
        micVolume: "",
        lightLevel: "",
        lightMode: "",
        led: "",
        voicePromptVolume: "",
        soundOnOff: false,
        ringingOnOff: false,
        screenBrightness: "",
        lowBatteryVoicePrompt: false,
        screenLightMode: "1",

        volumeLevel: 0,
        dectVolumeLevel: 0,
        twoWayMic: 0,
        promptVol: 0,
        guardTimeBeep: false,
        confirmBeep: false,
        wandererBeep: false,
        helpArriveIndicator: false,
        callbackIndicator: false,
    }
};

const soundLight: Reducer<SoundLightState> = (state: SoundLightState = soundLightState, action: any) => {
  switch (action.type) {
    case fetchSoundLight.SUCCESS:
      return {
          ...state,
        settings: action.payload
      };

    case saveSoundLight.SUCCESS:
      return {
        ...state,
        settings: action.payload
      }

    default:
        return state;
  }
};

export default soundLight;
