import * as React from "react";
import {SvgWrapArrow, SvgWrap} from "./styles";
import Arrow from "./arrow";
import Organisation from "./organisation";
import styled from "styled-components";
import { Customer } from "@Safemate/PartnerDashboard/Models/Customer";


const StyledH1 = styled.h1`
  margin-right: 5px;
`;



interface ArrowsProps{
  customer: Customer;
}

export const Arrows = ({customer}: ArrowsProps) => {

  return(
    <SvgWrapArrow>
      <Arrow customer={customer}/>
    </SvgWrapArrow>
  )
}

interface OrgBillingProps{
  customerId: number;
  customerName:string;
}

export const OrgBilling = ({customerId, customerName}: OrgBillingProps) => {

  return(
    <SvgWrap>
      <React.Fragment>
        <h1>
            <Organisation customerId={customerId} customerName={customerName}/>
          </h1>
      </React.Fragment>
    </SvgWrap>
  )
}

export default Arrows;