import { AppState } from "@Safemate/Store/types";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { Routine } from "redux-saga-routines";
import { connect } from "react-redux";
import { Action, ActionFunctionAny } from "redux-actions";
import { sendGdpr, acceptGdpr } from "../Store/routines";

interface GdprProps{
  sendingGdpr: boolean;
  sendGdpr: Routine<ActionFunctionAny<Action<any>>>;
  acceptGdpr: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ session: { sendingGdpr } }: AppState) => {
  return {
    sendingGdpr
  }
}

const mapDispatchToProps = {
  sendGdpr,
  acceptGdpr
}

const Gdpr = connect(mapStateToProps, mapDispatchToProps)(({sendingGdpr, acceptGdpr, sendGdpr}: GdprProps) => {

  const { formatMessage } = useIntl();

  return(
    <React.Fragment>
      <h4>{formatMessage({id: "gdprPSTitle", defaultMessage: "gdprPSTitle"})}</h4>
      <div className="scroll-box" dangerouslySetInnerHTML={{ __html: formatMessage({id: "gdprPSText", defaultMessage: "GDPR Placeholder"})}} />
      <Button onClick={sendGdpr} disabled={sendingGdpr} id="sendMeEmailGdpr" type="button" className="sendMeEmailGdpr" >
        {formatMessage({id: "sendMeOnEmail", defaultMessage: "sendMeOnEmail"})}
      </Button>
      <Button onClick={acceptGdpr} type="button">
        {formatMessage({id: "accept", defaultMessage: "accept"})}
      </Button>
    </React.Fragment>

)})

export default Gdpr;
