import {createRoutine, Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {UserToDelete} from "./Saga/actions";
import {UserWithCustomer} from "../Models/UserWithCustomer";
import { AppAction } from "@Safemate/Store/types";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/INITIALIZE'
);

export const initializeUsers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/INITIALIZE_USERS'
);

export const initializeDeletedUsers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/INITIALIZE_DELETED_USERS'
);

export const initializeLdapUsers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/INITIALIZE_LDAP_USERS'
);

export const initializeRoles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/INITIALIZE_ROLES'
);

export const initializeAdminRole: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/INITIALIZE_ADMIN_ROLE'
);

export const initializeAhpRoles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/INITIALIZE_AHP_ROLES'
);
//Actions
export const deleteUser: Routine<ActionFunctionAny<AppAction<UserToDelete>>> = createRoutine(
  '@@UserAdmin/DELETE_USER'
);

export const saveUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/SAVE_USER'
);

export const sendNewPassword: Routine<ActionFunctionAny<AppAction<UserWithCustomer>>> = createRoutine(
  '@@UserAdmin/SEND_NEW_PASSWORD'
);

export const assignGdprManager: Routine<ActionFunctionAny<AppAction<UserWithCustomer>>> = createRoutine(
  '@@UserAdmin/ASSIGN_GDPR_MANAGER'
);

export const removeGdprManager: Routine<ActionFunctionAny<AppAction<UserWithCustomer>>> = createRoutine(
  '@@UserAdmin/REMOVE_GDPR_MANAGER'
);

//User
export const getUser: Routine<ActionFunctionAny<AppAction<UserWithCustomer>>> = createRoutine(
  '@@UserAdmin/GET_USER'
);
