import { combineReducers } from 'redux';
import feedback from "../../../Feedback/reducer";
import role from "./role";
import initialization from "./initialization";
import {RootState} from "../types";


export default combineReducers<RootState>({
  feedback,
  role,
  initialization,
});
