import * as React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { Role } from "../Store/types";
import Copy from '../../../Elements/Icon/Icons/Copy';
import Edit from '../../../Elements/Icon/Icons/Edit';
import Trash from '../../../Elements/Icon/Icons/Trash';
import confirmModal from "../../Modals/confirmModal";
import { deleteRole } from "../Store/routines";
import { connect } from "react-redux";

const ActionWrapper = styled.span`
  white-space: nowrap;
  float: right;
`;

const AccentedText = styled.span`
  color: ${props => props.theme.colors.accent};
`;

interface ActionsProps{
  role: Role;
  deleteRole: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToProps = {
  deleteRole
}

const Actions = connect(null, mapDispatchToProps)(({ role, deleteRole }: ActionsProps) => {

  const {Comp, func} = confirmModal();
  const {formatMessage} = useIntl();

  const { global, id, roleName } = role;

  return (
    <React.Fragment>
    <ActionWrapper>
      <Copy
        tabIndex="0"
        title={formatMessage({id: 'copy', defaultMessage: 'Copy'})}
        size="medium"
        onClick={() =>
          (location.hash = `role_editor/${id}/true`)
        }
        onKeyDown={(event: KeyboardEvent) =>
          event.key === 'Enter' &&
          (location.hash = `role_editor/${id}/true`)
        }
        inline
        style={{
          marginRight: '10px'
        }}
      />
      {!global && 
        <Edit
          tabIndex="0"
          title={formatMessage({id: 'edit', defaultMessage: 'Edit'})}
          size="medium"
          onClick={() => (location.hash = `role_editor/${id}`)}
          onKeyDown={(event: KeyboardEvent) =>
            event.key === 'Enter' &&
            (location.hash = `role_editor/${id}`)
          }
          inline
          style={{
            marginRight: '10px'
          }}
        />}
      {!global && 
        <Trash
          tabIndex="0"
          title={formatMessage({id: 'delete', defaultMessage: 'Delete'})}
          size="medium"
          onClick={() => func(true)}
          onKeyDown={(event: KeyboardEvent) => event.key === 'Enter' && func(true)}
          inline
          style={{
            marginRight: '10px'
          }}
        />}
    </ActionWrapper>
    <Comp
        maxWidth="40%"
        title={formatMessage({id: "deleteRole", defaultMessage: "Slett rolle"})}
        body={
          <p>
            <span>{formatMessage({id: "deleteRoleDesc", defaultMessage: "Vil du virkelig slette rolle"})}</span>
            <AccentedText>{` ${roleName}?`}</AccentedText>
          </p>
        }
        confirmText={formatMessage({id: "delete", defaultMessage: "Slette"})}
        confirmFunc={() => {
          deleteRole(role.id);
          func(false);
        }}
      />
    </React.Fragment>
  );
});

export default Actions;