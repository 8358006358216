import {call, put, takeLatest} from "redux-saga/effects";
import {AppAction} from "../types";
import * as Api from '../api';
import * as Routines from '../routines';

function* getPaymentForNew(action: AppAction<number>){
    try{
      const paymentDetail = yield call(Api.getPaymentOptions, 0, action.payload);
      yield put(Routines.getPayment.success(paymentDetail));
    }
    catch(e){
      yield put(Routines.getPayment.failure());
    }
  }


function* paymentProfileSaga(){
  yield takeLatest(Routines.getPaymentForNew.TRIGGER, getPaymentForNew);
}

export default paymentProfileSaga;
