export enum LabelEnum{
  BATTERY_THRESHOLD = "batteryThresholdForWarning",
  DEVICE_HARDWARE = "deviceHardware",
  SETTINGS_SAVED = "settingsSaved",
  SUBUNITS = "subUnits",
  POWER_FAILURE_REPORT_DELAY = "batteryAndPowerPowerToolTipTitle",
  SELF_CHECK_INTERVAL = "selfCheckInterval",
  OUTDATED_POSITION_THRESHOLD = "outdatedPositionLabel",
  OUTDATED_POSITION_THRESHOLD_TOOLTIP = "outdatedPositionTooltip",
  SELF_CHECK_INTERVAL_TOOLTIP = "tooltip_frequencySelfCheck",
  HEARTBEAT = "heartbeat",
  HEARTBEAT_TOOLTIP = "tooltip_messageIntervalPeriodic",
  HEARTBEAT_GX8_TOOLTIP = "generalSensorHeartbeatToolTipText",
  CALLBACK_TIMER = "communicationCallbackTimer",
  PREF_COM_METHOD = "communicationPreferredParagraphTitle",
  TWO_WAY_DECT_TIMER = "communicationDECTParagraphTitle",
  SILENT_ACTIVE = "communicationSilentParagraphTitle",
  SENSOR_SUPERVISION_CHECK_TIMER = "defaultSettingsSensorSupervisionTimer",
  SENSOR_SUPERVISION_CHECK_TIMER_PENDANT = "defaultSettingsSensorSupervisionTimerPendant",
  SENSOR_SUPERVISION_CHECK_ACTIVE = "generalSensorSupervisionToolTipTitle",
  GUARD_TIME = "guardTimeText",
  BYPASS_TIMER = "bypassTimerText",
  LOCAL_LEARNING = "activateLocalLearning",
  STATIC_IGNORE_NIGHTS = "defaultSettingsNotNightsDesc",
  SATELLITES = "defaultSettingsSatelliteReq",
  DISABLE_POSITIONING = "defaultSettingsDisablePosition",
  LIGHT_LEVEL = "ledStrength",
  LIGHT_MODE = "lightMode",
  LIGHT_ALARM_MODE = "lightAlarmMode",
  LIGHT_NORMAL_MODE = "lightNormalMode",
  SOUND_LEVEL = "volume",
  DECT_SOUND_LEVEL = "dectVolume",
  TWO_WAY_MIC = "soundMicVolume",
  CALL_VOLUME = "callVolume",
  SOUND_LEVEL_RINGTONE = "ringtoneVolume",
  SYSTEM_SOUNDS = "systemSounds",
  INCOMING_RINGTONE = "ringTone",
  CONFIRM_BEEP = "soundConfirmParagraphTitle",
  WANDERER_BEEP = "wandererBeep",
  HELP_ARRIVE_INDICATOR = "soundHelpArriveIndicatorParagraphTitle",
  CALLBACK_INDICATOR = "soundCallbackIndicatorParagraphTitle",
  TRACKING_INTERVAL = "trackingInterval",
  AUTO_TRACKING = "autoTracking",
  RESET_SETTINGS = "revertToDefaultDefaultSettings",
  WIFI_DELETE_HAS_GEOFENCE = "wifiDeleteHasGeofenceTooltip",
  WIFI_DELETE_HAS_GEOFENCE_NAMES = "wifiDeleteHasGeofenceNamesTooltip",
  WIFI_DELETE_HAS_GEOFENCE_DEVICE_COUNT = "wifiDeleteHasGeofenceDeviceCountTooltip",
  CANCEL = "cancel",
  SAVE = "save",
  REVERT = "revert",
  REVERT_SETTINGS = "revertSettings",
  REVERT_DIALOG = "revertDialog",
  ENABLE_LED = "enableLed",
  ALARM_SMS_RECEIPT = "alarmSmsReceipt",
  MIC_VOLUME="microphoneSensitivityToolTipTitle",
  VOICE_PROMPT_VOLUME="voicePromptVolume",
  DEVICE_TRANSACTION_LOGS_LABEL="deviceTransactionLogsLabel",
  DEVICE_TRANSACTION_LOGS_TOOLTIP="deviceTransactionLogsToolTip",
  USER_TRANSACTION_LOGS_LABEL="userTransactionLogsLabel",
  USER_TRANSACTION_LOGS_TOOLTIP="userTransactionLogsToolTip",
  DEVICE_POSITION_HISTORY_DEPTH_HOURS_LABEL="devicePositionHistoryDepthHoursLabel",
  DEVICE_POSITION_HISTORY_DEPTH_HOURS_TOOLTIP="devicePositionHistoryDepthHoursToolTip",
  DEVICE_POSITION_HISTORY_DEPTH_POS_PLOTS_LABEL="devicePositionHistoryDepthPosPlotsLabel",
  DEVICE_POSITION_HISTORY_DEPTH_POS_PLOTS_TOOLTIP="devicePositionHistoryDepthPosPlotsToolTip",
  NOK_EMPLOYEE_LOGIN_IDLE_HOUR_LABEL="nokEmployeeLoginIdleHourLabel",
  NOK_EMPLOYEE_LOGIN_IDLE_HOUR_TOOLTIP="nokEmployeeLoginIdleHourToolTip",
  ACCEPT_LBS="acceptLBS",
  GEOLOCATION_WIFI_LOOKUP_GOOGLE = "geolocationWIFILookupGoogle",
  GEOLOCATION_WIFI_LOOKUP_COMBAIN = "geolocationWIFILookupCombain",
  SELECT_FIRMWARE = "selectFirmware",
  SELECT_FIRMWARE_TOOLTIP = "selectFirmwareTooltip",
  SAFEMATE_AS_RECEIVER = "safemateAsReceiver",
  SAFEMATE_AS_RECEIVER_LABEL = "safemateAsReceiverLabel",
  SAFEMATE_AS_RECEIVER_TOOLTIP = "safemateAsReceiverTooltip",
  SENSOR_DISCONNECT_THRESHOLD = "sensorDisconnectThresholdLabel",
  SENSOR_DISCONNECT_THRESHOLD_TOOLTIP = "sensorDisconnectThresholdTooltip",
  ACL_CHECK_LABEL = "aclCheckLabel",
  PHONE_NO_WHITE_LIST = "phoneNoWhiteListLabel",
  PHONE_NO_WHITE_LIST_TOOLTIP = "phoneNoWhiteListTooltip",
  MESSAGE_IN_HANDLER = "meldingsMottaker",
  MESSAGE_ADDRESS = "meldingsAdresse",
  INTEGRATIOn_WHITELIST = "integrationWhitelist",
  INTEGRATIOn_WHITELIST_TOOLTIP = "integrationWhitelistTooltip",
  MESSAGE_ADDRESS_TOOLTIP = "msgi9nEndpointTooltip",
  INTEGRATION_AFTER_CALL = "integrateAfterCallsPro",
  INTEGRATION_AFTER_CALL_TOOLTIP = "integrateAfterCallsTooltip",
  HALT_CALLS_UPON_SUCCESSFUL_INTEGRATION = "haltCallsUponSuccessfulIntegrationPro",
  HALT_CALLS_UPON_SUCCESSFUL_INTEGRATION_TOOLTIP = "haltCallsOnSuccIntNotCheckedMsg",
  HALT_INTEGRATION_UPON_ANSWERED_CALL = "haltIntegrationUponAnsweredCallPro",
  HALT_INTEGRATION_UPON_ANSWERED_CALL_TOOLTIP = "haltIntOnAnswCallNotCheckedMsg",
  INTEGRATION_FAILURE_FALLBACK = "msgI9nFailureFallbackLabel",
  INTEGRATION_FAILURE_FALLBACK_TOOLTIP = "msgI9nFailureFallbackTooltip",
  INTEGRATION_BEFORE_CALLS = "integrateBeforeCallsPro",
  INTEGRATION_BEFORE_CALLS_TOOLTIP = "integrateBeforeCallsTooltip",
  SUCCESSFULLY_PINGED_ENDPOINT = "successfullyPingedEndpoint",
  SUCCESSFULLY_RESET_PASSWORD = "successfullyResetPassword",
  INTEGRATION_SECURED = "integrationSecured",
  INTEGRATION_USERNAME = "integrationUsername",
  INTEGRATION_USERNAME_TOOLTIP = "integrationUsernameTooltip",
  INTEGRATION_PASSWORD= "integrationPassword",
  INTEGRATION_PASSWORD_TOOLTIP = "integrationPasswordTooltip",
  ORDER_OF_ESCALATION = "orderOfEscalation",
  INTEGRATION_DROPDOWN = "integrationDropdown",
  ORDER_ESCALATION_MESSAGE = "OrderEscalationMessage",
  ENABLE_DEFAULT_EVENT_SETUP = "enableDefaultEventSetup",
  SENSOR_SENSITIVITY = "sensorSensitivity",
  INTEGRATE_AND_CALL_DESC = "integrateAndCallWithoutDelayMessage",
  INTEGRATE_CALL_FIRST_DESC = "callFirstThenIntegrateMessage",
  INTEGRATE_FIRST_DESC = "integrateFirstThenCallMessage",
  ENABLE_INTEGRATION = "enableIntegration",
  TEAM_ALERT = "teamAlert",
  VOICE_PROMPT_ALARM = "voicePromptAlarmTooltip",
  VOICE_PROMPT_CALLBACK = "voicePromptCallbackTooltip",
  VOICE_PROMPT_FALL = "voicePromptFallTooltip",
  VOICE_PROMPT_WANDERER = "voicePromptWandererTooltip",
  VOICE_PROMPT_BED = "voicePromptBedTooltip",
  VOICE_PROMPT_AC_FAILURE = "voicePromptACFailureTooltip",
  VOICE_PROMPT_AC_RESTORE = "voicePromptACRestoreTooltip",
  INTEGRATION_ID = "integrationId",
  WIFI_LOCATION = "wifiLocation",
  GEOFENCE = "geofence",
  USER_INACTIVITY_LIMIT = "userInactivityLimit",
  USER_INACTIVITY_LIMIT_TOOLTIP = "userInactivityLimitTooltip",
  SYNCHRONIZE_SOUND = "synchronizeSound",
  GUARD_TIME_BEEP = "soundGuardParagraphTitle"
}
