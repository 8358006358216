import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/pro-solid-svg-icons';

const Icon = styled(FontAwesomeIcon)`
  width: 24px;
  height: 24px;
  margin-right: auto;
  margin-left: auto;
`;

const Fire = props => <Icon {...props} icon={faFire} />;

export default Fire;
