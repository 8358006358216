import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import EditFunctionIcon from "@Icons/FontAwesome/EditFunction";
import { defineMessages, useIntl } from "react-intl";
import confirmModal from "@Safemate/Modals/confirmModal";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { editSensorFunction } from "@Safemate/Settings/Store/routines";
import { CUSTOM_SENSOR_FUNCTION, getFunctionName, UNKNOWN } from "../SensorFunctionEnum";
import { displayFunctionType, getForFunction } from "../SensorFunctionTypeEnum";
import { HorizontalRow, SelectWrap } from "../styles";
import Dropdown from "@Elements/Dropdown/Dropdown";
import withFormWrapper from "@Elements/Form/formWrapper";
import { Option } from "@Elements/Dropdown/types";
import { ISensorDTO } from "../types";
import { deconstructFunction, mapFunction } from "./utils";
import { AppState } from "@Safemate/Store/types";


const editFunctionModal = defineMessages({
  title: { id: 'editFunctionTitle', defaultMessage: 'Endre funksjon: ' },
  updateBtn: { id: 'universalUpdateBtn', defaultMessage: 'Oppdater' }
});

const label = defineMessages({
  function: { id: 'functionLabel', defaultMessage: 'Funksjon:' },
  functionType: { id: 'functionTypeLabel', defaultMessage: 'Funksjonstype:' },
  chooseFunction: {
    id: 'chooseFunctionLabel',
    defaultMessage: 'Velg funksjon'
  },
  chooseFunctionType: {
    id: 'chooseFunctionTypeLabel',
    defaultMessage: 'Velg funksjonstype'
  }
});


interface EditFunctionProps{
  sensor: ISensorDTO;
  dehaId: number;
  editSensorFunction: Routine<ActionFunctionAny<Action<any>>>;
}


const mapStateToProps = ({ settings: {initialization: { dehaId }}}: AppState) => {
  return {
    dehaId
  }
}

const mapDispatchToProps = {
  editSensorFunction,
}

export const EditFunction = connect(mapStateToProps, mapDispatchToProps)(({ sensor, editSensorFunction }: EditFunctionProps) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();

  const { functionType, name, gatewayId, deseId } = sensor.sensor;

  return(
      <Formik
        enableReinitialize
        initialValues={{
          deviceFunction: sensor.function.code,
          funcType: functionType
        }}
        onSubmit={(values) => {
          editSensorFunction({
            sensorId: deseId,
            functionId: values.deviceFunction,
            funcType: values.funcType
          })
        }}
      >{({ submitForm }) => 
        <React.Fragment>
          <EditFunctionIcon 
            onClick={() => func(true)}
            medium
            animate
            title={formatMessage({ id: 'editFuncActionTooltip', defaultMessage: 'Endre funksjon' })}
          />
          <Comp
            title={`${formatMessage(editFunctionModal.title)} ${name} (${gatewayId})`}
            standardBtn
            body={
              <Form>
                <EditFunctionForm sensor={sensor}/>
              </Form>
            }
            confirmText={formatMessage(editFunctionModal.updateBtn)}
            confirmFunc={() => {
              submitForm();
              func(false);
            }}
          />
        </React.Fragment>
      }
    </Formik>
  )
})

interface EditFunctionValues{
  deviceFunction: string;
  funcType: string;
}

interface EditFunctionForm{
  sensor: ISensorDTO;
}

const EditFunctionForm = withFormWrapper<EditFunctionValues, EditFunctionForm>(({ formik: { setFieldValue, values: { deviceFunction, funcType } }, sensor }) => {

  const { formatMessage } = useIntl();

  const mappedOptions = React.useMemo(() => {
    if(sensor.type.supportedFunctions){

      let hasCustom = false;

      const functions = sensor.type.supportedFunctions.filter(func => func.code !== UNKNOWN).map(func => {
        const functionName = getFunctionName(sensor.type, func, formatMessage);
        if(!functionName) return null;

        if(func.code === CUSTOM_SENSOR_FUNCTION.code){
          hasCustom = true;
        }

        return {
          text: functionName,
          value: func.code
        }
      }).filter(func => func && func.value !== CUSTOM_SENSOR_FUNCTION.code) as Option[];

      if(hasCustom){
        const customOptions = getForFunction(CUSTOM_SENSOR_FUNCTION.code, formatMessage);
        return functions.concat(customOptions);
      }
      return functions;
    }
    return []
  }, [sensor.type])

  const functionTypes = React.useMemo(() => {
    return getForFunction(deviceFunction, formatMessage);
  }, [deviceFunction])

  return (
    <div>
      <HorizontalRow>
        <p>{formatMessage(label.function)}</p>
        <SelectWrap>
        <Dropdown
          expand
          options={mappedOptions}
          initial={mapFunction(deviceFunction, funcType)}
          size={{width: "200px"}}
          title={formatMessage(label.chooseFunction)}
          onChange={(value) => {

            const opt = value as Option;

            const {deviceFunction, funcType} = deconstructFunction(opt, formatMessage);

            setFieldValue("deviceFunction", deviceFunction);
            setFieldValue("funcType", funcType);
          }}
        />
        </SelectWrap>
      </HorizontalRow>
      {displayFunctionType(sensor.type.code, deviceFunction) && 
        <HorizontalRow>
          <p>{formatMessage(label.functionType)}</p>
          <SelectWrap>
          <Dropdown
            expand
            options={functionTypes}
            initial={sensor.sensor.functionType}
            size={{width: "200px"}}
            title={formatMessage(label.chooseFunctionType)}
            onChange={(value) => {
              setFieldValue("funcType", (value as Option).value);
            }}
          />
          </SelectWrap>
        </HorizontalRow>
      }
    </div>
  )
})
