import * as React from "react";
import {injectIntl, useIntl} from "react-intl";

import withFormWrapper from "@Elements/Form/formWrapper";
import Toggle from "@Elements/Switch/Toggle";
import { GeofencesIndexProps } from "..";
import { GeofenceSettings } from "../../types";
import { Header } from "../styles";

export const GeofenceStatus = withFormWrapper<GeofenceSettings, GeofencesIndexProps>((
  { formik: { setFieldValue, values: { geofences } }, geofencesIndex }) => {
 
  const { formatMessage } = useIntl();
  
  if(geofences[geofencesIndex].id === 0)
    return null;

  return(
    <div style={{margin: "4px 0px"}}>
      <Header>{formatMessage({id: "status", defaultMessage: "Status"})}</Header>
      <Toggle
        onChange={(value: boolean) => {
          setFieldValue(`geofences.${geofencesIndex}.enabled`, value);
        }}
        checked={geofences[geofencesIndex].enabled}
      />
    </div>
  )
})