import {OtherSettings, OtherSettingsWithFallback} from "@Safemate/DefaultSettings/Settings/types";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import * as React from "react";
import {
  deconstructOtherSetting, mapOtherSetting,
} from "@Safemate/DefaultSettings/Settings/utils";
import {
  settingsTabWrapper
} from "@Safemate/DefaultSettings/Settings/TabContent";
import {Form, Formik} from "formik";
import {Frame} from "@Safemate/styles";
import {Row} from "react-bootstrap";
import {FooterComp, FooterContext} from "@Safemate/DefaultSettings/Settings/footer";
import UnsavedCheck from "@Safemate/SaveNeeded/unsavedModal";
import {AppState} from "@Safemate/Store/types";
import {saveOtherSettings} from "@Safemate/DefaultSettings/Store/routines";
import {connect} from "react-redux";
import Tab from "@Elements/Tabs/Tab";
import { useIntl } from "react-intl";

interface OtherSettingsProps{
  tabs: JSX.Element[];
  otherSettings: OtherSettingsWithFallback;
  saveOtherSettings: Routine<ActionFunctionAny<Action<any>>>;
}

const OtherSettingsComp = ({ tabs, otherSettings, saveOtherSettings }: OtherSettingsProps) => {
 
  const { formatMessage } = useIntl();
  const settings: OtherSettings = mapOtherSetting(otherSettings);

  return(
    <Tab key={`otherSettings`} title={formatMessage({id: "otherSettings", defaultMessage: "Other Settings"})} name={`otherSettings`}>
      <Formik
        initialValues={settings}
        enableReinitialize
        onSubmit={(values: OtherSettings) => {saveOtherSettings(deconstructOtherSetting(values))}}
      >
        <Form>
          <Frame>
            <Row>
              <div style={{paddingBottom: "1em"}}>
                {settingsTabWrapper(tabs)}
              </div>
              <FooterComp context={FooterContext.OTHER_SETTINGS}/>
            </Row>
          </Frame>
          <UnsavedCheck />
        </Form>
      </Formik>
    </Tab>
  )
}

const mapStateToProps = ({ defaultSettings: {settings: {otherSettings}, customer: {selectedCustomer}}}: AppState) => {
  return{
    customer: selectedCustomer,
    otherSettings
  }
};

const mapDispatchToProps = {
  saveOtherSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherSettingsComp);
