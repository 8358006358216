import { Button } from "react-bootstrap";
import styled from "styled-components";

export const PolicyModalWrapper = styled.div`
  padding: 20xp;
`;

export const PolicyModalHeader = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
`;

export const PolicyButton = styled.button`
  background: transparent;
  border: none;
  height: 100%;
`;

export const Active = styled.div<{active: boolean}>`
  ${props => props.active && "cursor: pointer;"}
  margin: 0 auto;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  background: ${props => (props.active ? '#64800c' : '#a9adb0')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Checkmark = styled.div`
  display: inline-block;

  &:after {
    content: '';
    display: block;
    width: 6px;
    margin-bottom: 2px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const StyledButton = styled(Button)`
  transition: color 0.3s;
`;

export const StyledLi = styled.li`
  padding: 2px;
`;

export const StyledUl = styled.ul`
  list-style-type: none;
`;

export const CaretRight = styled.b`
  margin-right: 5px;
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  color: ${props => props.theme.colors.textPrimary}
  border-left: 8px solid ${props => props.theme.colors.accent};
`;