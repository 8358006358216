import * as React from "react";
import { connect } from "react-redux";
import { useIntl, } from "react-intl";
import { Formik, Field } from "formik";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {Button, Col, Grid, Row} from "react-bootstrap";
import styled from "styled-components";

import {SettingEnum, SettingEnum as ComponentSettingEnum} from "../../settingEnum";
import {HeartRateBloodPressureForm, HeartRateBloodPressure} from "../types";
import { DeviceComponents } from "../../tabDefinition";
import { DeviceHardwareEnum } from "../../../DeviceHardwareEnum";
import {Footer, ButtonContainer, StyledForm} from "./styled";
import withFormWrapper from "@Elements/Form/formWrapper";
import Toggle from "@Elements/Switch/Toggle";
import { RadioGroup, Radio } from "../../../../Elements/RadioFormik";
import { CancelButton, SaveButton } from "../../../DefaultSettings/Settings/styled";
import {saveHeartRateBloodPressureSettings, requestBloodPressure, requestHeartRate, getHeartRateBloodPressure} from "../../Store/routines";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import {formatNorwegianDate} from "../../Indoor/utils";
import {StyledModal, Close} from "../../Indoor/field";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";

export const ButtonWrapper = styled.div`
  float: right;
  display: inline-flex;
  align-items: center;
  padding-bottom: 1em;
  div{
    height: 26px;
    width: 26px;
  }
`;

export const InfoWrapper = styled.div`
  height: 26px;
  float: left;
  width: 26px;
`;


export const StyledP = styled.p`
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(136, 136, 136);
  width: 100%;
`;

export const StyledPPad = styled.p`
  padding-left: 15px;
  float: left;
  @media (min-width: 992px) {
    width: 100%;
    margin-top: -15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(136, 136, 136);
  }
`;

export const StyledH3 = styled.h3`
  margin: 0;
`;

export const StyledH3Pad = styled.h3`
  margin: 0;
  padding-left: 15px;
  float: left;
`;

export const StyledH2 = styled.h2`
  margin: 0;
`;

export const InputWrapper = styled.div`
  float: right;
  display: flex;
  input{
    padding: 0 5px;
    border-radius: 0px;
  }
  @media (max-width: 991px) {
    float: none;
    margin-left: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(136,136,136);
    width: 100%;
    
  }
  
`;

export const InputWrapperWrapper = styled.div`
  @media (max-width: 991px) {
    display: table-footer-group;
  }
`;


export const MinuteBtn = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};
  cursor: default;
  border-radius: 0px;
  padding-bottom: 40px;
  pointer-events: none;
`;


export const ToggleWrapper = styled.div`
  float: right;
`;

export const StyledButton = styled(Button)`
  margin-right: 10px;
  min-width: 200px;
`;

const Th = styled.th<{priority?: number}>`
  @media (max-width: 1200px) {
    ${props => props.priority && props.priority <= 1 && 'display: none;'};
  }
  @media (max-width: 992px) {
    ${props => props.priority && props.priority <= 2 && 'display: none;'};
  }
  @media (max-width: 768px) {
    ${props => props.priority && props.priority <= 3 && 'display: none;'};
  }
  border: 0;
  background: none;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 12px;
`;

const Td = styled.td`
  
`;

const Tr = styled.tr`
  border-top: ${props => props.theme.colors.border};
  td {
    transition: 0.3s;
    vertical-align: middle;
    padding: 15px;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
  @media (max-width: 991px) {
    width: 100px;
  }
`;

const Table = styled.table`
  width: 100%;
`;

interface HeartRateSettingsProps {
    dehaId: number;
    deviId: number;
    heartRateBloodPressureSettings: HeartRateBloodPressureForm;
    saveHeartRateBloodPressureSettings: Routine<ActionFunctionAny<Action<any>>>;
    requestHeartRate: Routine<ActionFunctionAny<Action<any>>>;
    requestBloodPressure: Routine<ActionFunctionAny<Action<any>>>;
    getHeartRateBloodPressure: Routine<ActionFunctionAny<Action<any>>>;
    heartRateBloodPressures: HeartRateBloodPressure[];



}
  
const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: {heartRateBloodPressureSettings, heartRateBloodPressures}}}: AppState) => {
  return{
      heartRateBloodPressureSettings,
      dehaId,
      deviId,
      heartRateBloodPressures
  }
}

const mapDispatchToProps = {
  saveHeartRateBloodPressureSettings,
  requestHeartRate,
  requestBloodPressure,
  getHeartRateBloodPressure,
}

const StyledCol = styled(Col)`
    margin-bottom: 1em;
`;

const StyledColNoPadLeft = styled(Col)`
    margin-bottom: 1em;
    padding-left: 0px;
    @media (max-width: 991px) {
      display: table;
    }
`;

const StyledGrid = styled(Grid)`
    width: 100%;
    padding-left: 0px;
`;

export const HeartRateBloodPressureSettings = connect(mapStateToProps, mapDispatchToProps)(
({ dehaId, deviId, heartRateBloodPressureSettings, saveHeartRateBloodPressureSettings, requestHeartRate, requestBloodPressure, getHeartRateBloodPressure, heartRateBloodPressures }: HeartRateSettingsProps) => {

  const { formatMessage } = useIntl();
  let popup = false;

  React.useEffect(() => {
    let transactionInterval: NodeJS.Timeout;
    getHeartRateBloodPressure(deviId)
    transactionInterval = setInterval(() => {
      getHeartRateBloodPressure(deviId)
    }, 20000);

    return () => {
      // Cleanup polling
      clearTimeout(transactionInterval);
    }

  }, [])

  React.useEffect(() => {
    popup = heartRateBloodPressureSettings.calibrateBloodPressureSetting;
  }, [heartRateBloodPressureSettings])

    const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
    ? DeviceComponents.get(dehaId)! 
    : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;


    
    return(
    <Formik
        onSubmit={(values) => {
          saveHeartRateBloodPressureSettings({deviceId: deviId, settings: values});
        }}
        enableReinitialize
        initialValues={heartRateBloodPressureSettings}
    >    
        {({dirty, setValues}) => {
        return(
            <StyledForm>
            <StyledGrid fluid>
            <h2>{formatMessage({id: "healthSettings", defaultMessage: "Health Settings"})}</h2>
              <StyledCol xs={12} sm={12}>
                {components.has(ComponentSettingEnum.HEART_RATE_BLOOD_PRESSURE)
                    && components.get(ComponentSettingEnum.HEART_RATE_BLOOD_PRESSURE)?.map(comp =>
                    <StyledColNoPadLeft md={12}>{comp}</StyledColNoPadLeft>)}
                { (dehaId === DeviceHardwareEnum.WATCH_TWO || dehaId === DeviceHardwareEnum.WATCH_FIVE) &&
                <ButtonWrapper>
                  <StyledButton
                    onClick={() => {
                      requestBloodPressure(deviId);
                    }}
                  >
                    {formatMessage({id: "requestBloodPressure", defaultMessage: "Request blood pressure"})}
                  </StyledButton>
                  <Tooltip
                    popoverId="requestHeartRateBloodPressure-popover"
                    labelId="tooltip_requestHeartRateBloodPressure"
                    defaultMessage="Click to get heart rate or blood pressure respectively"
                  >
                    <InformationIcon size="medium" />
                  </Tooltip>
                </ButtonWrapper> }
                { (dehaId === DeviceHardwareEnum.WATCH_TWO || dehaId === DeviceHardwareEnum.WATCH_FIVE) &&
                <ButtonWrapper>
                  <StyledButton
                    onClick={() => {
                      requestHeartRate(deviId);
                    }}
                  >
                    {formatMessage({id: "requestHeartRate", defaultMessage: "Request heart rate"})}
                  </StyledButton>
                </ButtonWrapper> }
              </StyledCol>
              <StyledCol xs={12} sm={12}>
                <Table>
                  <thead>
                    <Tr>
                      <Th>{formatMessage({id: "time", defaultMessage: "Time"})}</Th>
                      <Th>{formatMessage({id: "heartRate", defaultMessage: "Heart rate"})}</Th>
                      <Th>{formatMessage({id: "systolicBloodPressure", defaultMessage: "Systolic blood pressure"})}</Th>
                      <Th>{formatMessage({id: "diastolicBloodPressure", defaultMessage: "Diastolic blood pressure"})}</Th>
                    </Tr>
                  </thead>
                  <tbody>
                  {heartRateBloodPressures.map((hrbp)=>(
                    <Tr key={hrbp.hrbpId}>
                      <Td>{formatNorwegianDate(hrbp.logDate)}</Td>
                      <Td>{hrbp.heartRate}</Td>
                      <Td>{(hrbp.systolicBloodPressure < 0) ? `-` : `${hrbp.systolicBloodPressure}` }</Td>
                      <Td>{(hrbp.diastolicBloodPressure < 0) ? `-` : `${hrbp.diastolicBloodPressure}`}</Td>
                    </Tr>
                  ))
                  }
                  </tbody>
                </Table>
                <Footer>
                  <ButtonContainer disabled={!dirty}>
                    <CancelButton disabled={!dirty} onClick={() => setValues(heartRateBloodPressureSettings)}>
                      {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
                    </CancelButton>
                    <SaveButton disabled={!dirty} type="submit">
                      {formatMessage({id: "save", defaultMessage: "Lagre"})}
                    </SaveButton>
                  </ButtonContainer>
                </Footer>
              </StyledCol>

            </StyledGrid>

            </StyledForm>
        )}}
    </Formik>
    )
})


export const HeartRateBloodPressureSetting =
  withFormWrapper<HeartRateBloodPressureForm, {}>(({ formik: { setFieldValue, values: { heartRateBloodPressureSetting }}}) => {

      const { formatMessage } = useIntl();

      return(
        <React.Fragment>
            <StyledH3>{formatMessage({id: "activateHealthMeasurements", defaultMessage: "Activate health measurements"})}</StyledH3>
            <ToggleWrapper>
            <Toggle
              onChange={(value: boolean) => {
                setFieldValue("heartRateBloodPressureSetting", value);
              }}
              checked={heartRateBloodPressureSetting}
            />
          </ToggleWrapper>
          <StyledP>{formatMessage({id: "makeWatchTwoMonitor", defaultMessage: "Make the Watch Two monitor your health"})}</StyledP>
        </React.Fragment>
      );
    }
  );

export const HeartRate =
  withFormWrapper<HeartRateBloodPressureForm, {}>(({ formik: { setFieldValue, values: { heartRateInterval, heartRateBloodPressureSetting } } }) => {

      const { formatMessage } = useIntl();

      return(
        <React.Fragment>
          {heartRateBloodPressureSetting && <React.Fragment>
          <StyledH3Pad>{formatMessage({id: "heartRateInterval", defaultMessage: "heartRateInterval"})}</StyledH3Pad>
          <InputWrapperWrapper>
            <InputWrapper>
              <StyledField
                value={heartRateInterval}
                name={`heartRateInterval`}
                type="text"
              />
              <MinuteBtn>
              {formatMessage({id: "minute", defaultMessage: "minutes"})}
              </MinuteBtn>
            </InputWrapper>
          </InputWrapperWrapper>
          <StyledPPad>{formatMessage({id: "rateMeasureHeartRate", defaultMessage: "How often would you like to measure the heart rate?"})}</StyledPPad>
        </React.Fragment>}
        </React.Fragment>
      );
    }
  );

export const BloodPressure =
  withFormWrapper<HeartRateBloodPressureForm, {}>(({ formik: { setFieldValue, values: { bloodPressureInterval, heartRateBloodPressureSetting } } }) => {

      const { formatMessage } = useIntl();

      return(
        <React.Fragment>
          {heartRateBloodPressureSetting && <React.Fragment>
          <StyledH3Pad>{formatMessage({id: "bloodPressureInterval", defaultMessage: "bloodPressureInterval"})}</StyledH3Pad>
          <InputWrapperWrapper>
            <InputWrapper>
              <StyledField
                value={bloodPressureInterval}
                name={`bloodPressureInterval`}
                type="text"
              />
              <MinuteBtn>
              {formatMessage({id: "minute", defaultMessage: "minutes"})}
              </MinuteBtn>
            </InputWrapper>
          </InputWrapperWrapper>
          <StyledPPad>{formatMessage({id: "intervalBloodPressure", defaultMessage: "How often would you like to measure the blood pressure?"})}</StyledPPad>
        </React.Fragment>}
          </React.Fragment>
      );
    }
  );

export const CalibrateBloodPressureSetting =
  withFormWrapper<HeartRateBloodPressureForm, {}>(({ formik: { setFieldValue, values: { calibrateBloodPressureSetting, openPopup } } }) => {

      const { formatMessage } = useIntl();
      let popup = calibrateBloodPressureSetting;

      return(
        <React.Fragment>
          <StyledH3>{formatMessage({id: "calibrateBloodPressureSetting", defaultMessage: "Calibrate Blood Pressure"})}</StyledH3>
          <ToggleWrapper>
            <Toggle
              onChange={(value: boolean) => {
                setFieldValue("calibrateBloodPressureSetting", value);
                setFieldValue("openPopup", (!popup && value));
              }}
              checked={calibrateBloodPressureSetting}
            />
          </ToggleWrapper>
          <StyledP>{formatMessage({id: "setBaseValuesForBP", defaultMessage: "Set base values for blood pressure and heart rate for your age group."})}</StyledP>
          <StyledModal open={openPopup}>
            <React.Fragment>
              <Close onClick={()=>{setFieldValue("openPopup", false)}}>
                &times;
              </Close>
              <Grid fluid>
                <Row>
                  <Col xs={12}>
                    <p>{formatMessage({id: "calibrationPopupText", defaultMessage: "Careful when calibrating device"})}</p>
                  </Col>
                </Row>
              </Grid>
            </React.Fragment>
          </StyledModal>
        </React.Fragment>
      );
    }
  );

export const CalibrateSystolicPressure =
  withFormWrapper<HeartRateBloodPressureForm, {}>(({ formik: { setFieldValue, values: { calibrateSystolicPressure, calibrateBloodPressureSetting } } }) => {

      const { formatMessage } = useIntl();

      return(
        <React.Fragment>
          {calibrateBloodPressureSetting && <React.Fragment>
          <StyledH3Pad>{formatMessage({id: "baseHeartRate", defaultMessage: "Base heart rate"})}</StyledH3Pad>
          <InfoWrapper>
          <Tooltip
            popoverId="baseHeartRate-popover"
            labelId="tooltip_baseHeartRate"
            defaultMessage="Base heart rate information"
          >

            <InformationIcon size="medium" />
          </Tooltip>
          </InfoWrapper>
          <InputWrapperWrapper>
            <InputWrapper>
              <StyledField
                value={calibrateSystolicPressure}
                name={`calibrateSystolicPressure`}
                type="text"
              />
              <MinuteBtn>
              {formatMessage({id: "minute", defaultMessage: "minutes"})}
              </MinuteBtn>
            </InputWrapper>
          </InputWrapperWrapper>
          <StyledPPad>{formatMessage({id: "whatBaseHeartRate", defaultMessage: "What is the base heart rate for your age group?"})}</StyledPPad>
        </React.Fragment>}
        </React.Fragment>
      );
    }
  );

export const CalibrateDiastolicPressure =
  withFormWrapper<HeartRateBloodPressureForm, {}>(({ formik: { setFieldValue, values: { calibrateDiastolicPressure, calibrateBloodPressureSetting } } }) => {

      const { formatMessage } = useIntl();

      return(
        <React.Fragment>
        {calibrateBloodPressureSetting && <React.Fragment>
          <StyledH3Pad>{formatMessage({id: "baseBloodPressure", defaultMessage: "Base blood pressure"})}</StyledH3Pad>
          <InfoWrapper>
          <Tooltip
            popoverId="baseBloodPressure-popover"
            labelId="tooltip_baseBloodPressure"
            defaultMessage="Base blood pressure information"
          >
            <InformationIcon size="medium" />
          </Tooltip>
          </InfoWrapper>
          <InputWrapperWrapper>
            <InputWrapper>
              <StyledField
                value={calibrateDiastolicPressure}
                name={`calibrateDiastolicPressure`}
                type="text"
              />
              <MinuteBtn>
              {formatMessage({id: "minute", defaultMessage: "minutes"})}
              </MinuteBtn>
            </InputWrapper>
          </InputWrapperWrapper>
          <StyledPPad>{formatMessage({id: "whatBaseBloodPressure", defaultMessage: "What is the base blood pressure for your age group?"})}</StyledPPad>
        </React.Fragment> }
        </React.Fragment>
      );
    }
  );
