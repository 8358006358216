import {call, put, takeLatest} from 'redux-saga/effects';
import { EventLog } from "@Safemate/Model/EventLog";
import { AppAction } from "@Safemate/Store/types";
import * as Api from '../api';
import * as Routines from "../routines";
import { IUser } from '@Safemate/Model/User';
import { IDevice } from '@Safemate/Model/Device';

interface Initialize{
  deviceId: number;
}

function* initialize(action: AppAction<Initialize>){
}


function* getUser(action: AppAction<number>){
  try {
    const user: IUser = yield call(Api.getUser, action.payload);
    yield put(Routines.getUser.success(user));
  }
  catch(e){
    yield put(Routines.getUser.failure());
  }
}

function* getDevice(action: AppAction<number>){
  try {
    const device: IDevice = yield call(Api.getDevice, action.payload);
    yield put(Routines.getDevice.success(device));
  }
  catch(e){
    yield put(Routines.getDevice.failure());
  }
}


function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.getUser.TRIGGER, getUser);
    yield takeLatest(Routines.getDevice.TRIGGER, getDevice);
}

export default initializationSaga;
