import styled from "styled-components";
import { Button } from "react-bootstrap";

export const TranslationTab = styled.div<{open: boolean}>`
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: ${props =>props.open ? "100%" : "120px"};
  transition: 0.5s;
  overflow: auto;
`;

export const TranslationContent = styled.div<{open: boolean}>`
  height: ${props => props.open ? "300px" : "0px"};
  visibility: ${props => props.open ? "visible" : "hidden"};
  overflow: ${props => props.open ? "auto" : "hidden"};
  background: ${props => props.theme.colors.backgroundPrimary};
  transition: 0.5s;
`

export const Search = styled.input`
  height: 24px;
  padding: 10px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Tr = styled.tr<{exist: boolean}>`
  color: ${props => props.exist ? "green" : "red"};
  border: 1px solid gray;
`;

export const HeaderTr = styled.tr`
  border: 1px solid gray;
`;

export const EmailContentTr = styled.tr`
  border: 1px solid gray;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const EditTr = styled.tr`
  margin-bottom: 3px;
`;

export const Td = styled.td`
  padding: 10px;
`;

export const Th = styled.th`
  padding: 10px;
`;

export const ModalTitle = styled.div`
  padding: 15px;
`;

export const ModalBody = styled.div`
  padding: 0 30px;
  max-height: 400px;
  overflow: auto;
`;

export const ModalFooter = styled.div`
  margin: 20px 15px;
  padding: 15px;
  text-align: 
  right;
`;

export const ConfirmBtn = styled(Button)`
  background: ${props => props.theme.colors.accent};
  color: white;
  width: 100%;
  margin: 15px auto 0px;
  display: block;
  max-width:460px;
  font-size: 18px!important;
  height: 40px;
  margin-bottom: 15px;
`;

export const CancelButton = styled(Button)`
  color: white;
  background-color: #C9C6C1;
  margin-right: 15px;
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  display: block;
  font-size: 18px!important;
  height: 40px;
`;

export const Active = styled.div<{active: boolean}>`
  margin: 0 auto;
  height: 18px;
  width: 18px;
  border-radius: 18px;
  background: ${props => (props.active ? '#64800c' : '#a9adb0')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaddedWrapper = styled.div`
  padding: 5px;
`;

export const Label = styled.label`
  font-weight: bold;
  margin-right: 5px;
`;

export const Input = styled.input`
  width: 100%;
  margin-bottom: 5px;
`;

export const FeedbackWrapper = styled.div<{danger?: boolean}>`
  font-weight: bold;
  color: ${props => props.danger ? "#a94442" : "#2d5016"};
  background-color: ${props => props.danger ? "#f2dede" : "#9f9"};
  border-color: ${props => props.danger ? "#ebccd1" : "#009900"};
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
`;