import {connect} from "react-redux";
import {WrappedComponentProps, injectIntl, useIntl} from "react-intl";
import * as React from "react";

import { TableRow, mapHeader, mapPartners} from "./utils";
import Header from "./Header";
import  Loader  from "@Elements/Loader";
import {LoaderWrap} from "./Edit/styles";
import { SiteTypeEnum } from "../../SiteTypeEnum";
import {Customer} from "../Models/Customer";
import styled, { withTheme } from "styled-components";
import TableExport from "../../../Elements/Export/TableExport";
import { getExportData, ExportType } from "./utils";
import { AppState } from "@Safemate/Store/types";
import { StyledTable } from "@Safemate/DeviceList/DeviceList";
import { FixedSizeList as List } from 'react-window';
import { AutoSizer, Column, RowMouseEventHandlerParams, WindowScroller } from "react-virtualized";
import { Td, Th } from "./layout";

interface UserProps extends WrappedComponentProps{
  customersInitialized: boolean;
  siteType: SiteTypeEnum;
  customers: Customer[];
  filterId: string;
  viewPrices: boolean;
  totalDevices: number;
  homeDevices: number;
  toggledChildren: number[];
}

const mapStateToProps = ({ partnerDashboard: {customer: {customers, filterId, viewPrices, totalDevices, homeDevices, toggledChildren}, initialization: {customersInitialized, siteType}}}: AppState) => {
  return{
    customersInitialized,
    siteType,
    customers,
    filterId,
    viewPrices,
    totalDevices,
    homeDevices,
    toggledChildren
  }
}


const PartnerDashboard = connect(mapStateToProps, null)(
  injectIntl(
    ({customers, filterId, customersInitialized, toggledChildren, viewPrices, totalDevices, homeDevices, intl: {formatMessage}}: UserProps) => {

      const rowRef = React.useRef<HTMLDivElement>(null); 

      const mappedPartners = React.useMemo(() => {
        return mapPartners(customers, 0, toggledChildren, []);
      }, [customers, filterId, toggledChildren]);
      return (
        <div> 
          <Header totalDevices={totalDevices} homeDevices={homeDevices} />
          {!customersInitialized
            ? <LoaderWrap><Loader/></LoaderWrap>
            : 
            <div ref={rowRef}>
              <VirtualizedTable data={mappedPartners} row={rowRef} viewPrices={viewPrices}/>
              <ExportData/>
            </div>
          }
        </div>
      )
    }
  )
);

interface TableProps{
  data: TableRow[];
  row: React.MutableRefObject<HTMLDivElement | null>;
  viewPrices: boolean;
}

interface Columns{
  key: string;
  width?: number;
  class?: string;
  flexGrow?: number;
  label?: string;
}

const columnsNormal: Columns[] = [
  {key: "expand", width: 50, class: "centered"},
  {key: "custId", width: 50, class: "centered", label: "customerId"},
  {key: "customerName", width: 100, flexGrow: 2, label: "name"},
  {key: "orgNumber", width: 100, label: "orgNr"},
  {key: "prices", width: 120, label: "priceGroup"},
  {key: "children", width: 40, label: "Suborgs"},
  {key: "deviceCount", width: 120, label: "Devices"},
  {key: "reference", width: 200, flexGrow: 2, label: "Reference"},
  {key: "billingGroup", width: 100, label: "Billing"},
  {key: "allowDeviceDeletes", width: 100, label: "allowDeviceDeletes"},
  {key: "orgBilling", width: 50, label: "View"},
]

const VirtualizedTable = ({ data, row, viewPrices }: TableProps) => {
  
  const getRow = ({index}: any) => data[index];

  const { formatMessage } = useIntl();

  const [width, setWidth] = React.useState(1000);

  React.useEffect(() => {
    window.addEventListener("resize", setTableWidth);
    setTableWidth();
    return () => {
      window.removeEventListener("resize", setTableWidth);
    }

  }, [])

  React.useEffect(() => {
    setTableWidth();
  }, [row.current && row.current.clientWidth])

  const setTableWidth = () => {
    if(row.current){
      // Resize table to match parent div size, if parent div is larger than the window resize to fit in window
      const width = row.current.clientWidth;
      const windowWidth = window.outerWidth > window.innerWidth ? window.innerWidth : window.outerWidth;
      if(windowWidth < 500){
        setWidth(windowWidth < width ? windowWidth * 0.95 : width);
      }else{
        setWidth(windowWidth < width ? windowWidth - 100 : width);
      }
    }
  }

  const rowStyleFormat = (row: any) => {
    if(!data[row.index]) return {}
    const level = data[row.index].expand.options.level;
    const managedCustomer = data[row.index].custId.options.managedCustomer;
    
    const style: any = {}

    if(level){
      style["paddingLeft"] = `${level*30}px`;
    }
    if(managedCustomer){
      style["background-color"] = "#1a82a9 !important";
    }
    return style
  }

  return (
    <WindowScroller>
      {({height, isScrolling, onChildScroll, scrollTop}) => (
      <StyledTable
        autoHeight={true}
        isScrolling={isScrolling}
        onScroll={onChildScroll}
        scrollTop={scrollTop}
        rowGetter={getRow}
        height={height}
        rowHeight={84}
        rowCount={data.length}
        width={width}
        headerHeight={20}
        rowStyle={rowStyleFormat}
      >
        {getColumns(viewPrices, formatMessage)}
      </StyledTable>
      )}
    </WindowScroller>
  )
};

const getColumns = (viewPrices: boolean, formatMessage: Function) => {

  const elementRenderer = (node: any) => {
    if(node.cellData.dataKey === "prices" && !viewPrices){
      return null;
    }
    return node.cellData.data;
  }

  return columnsNormal.map(col => {

    if(col.key === "prices" && !viewPrices)
      return null;

    let flex = col.width ? 0 : 1
    if(col.flexGrow)
      flex = col.flexGrow;

    return (
      <Column
        label={col.label && formatMessage({id: col.label, defaultMessage: col.label})}
        className={col.class}
        cellRenderer={elementRenderer}
        dataKey={col.key}
        width={col.width || 0}
        style={{whiteSpace: "pre-wrap"}}
        flexGrow={flex}
      />
    )
  }).filter(item => item);
}


const ExportDiv = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

interface ExportDataProps extends WrappedComponentProps{
  customers: Customer[];
  viewPrices: boolean;
}

const mapStateToPropsExportData = ({ partnerDashboard: {customer: {customers, viewPrices}}}: AppState) => {
  return {
    customers,
    viewPrices
  }
}

const ExportData = connect(mapStateToPropsExportData)(injectIntl(({ customers, viewPrices, intl: {formatMessage}}: ExportDataProps) => {
  const exportData = React.useMemo(() => {
    return getExportData(ExportType.CSV, customers, viewPrices, formatMessage);
  }, []);
  return(
    <React.Fragment>
      <ExportDiv style={{marginTop:'1em'}}>
        <TableExport type="csv" data={exportData} fileName={`municipalities`} />
      </ExportDiv>
    </React.Fragment>
  )
}))

export default PartnerDashboard;
