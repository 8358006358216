import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Col, Glyphicon, Row } from "react-bootstrap";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import moment, { Moment } from "moment";
import TableExport from "../../Elements/Export/TableExport";
import { getExportData, ExportType } from "./Log/utils";
import { Device, RootState, Transaction } from "./Store/types";
import Settings from "../../Elements/Icon/Icons/settings/Settings";
import Users from "../../Elements/Icon/Icons/Safemate/solid/Users";
import { DeviceHardwareEnum } from "../DeviceHardwareEnum";
import Dropdown from "../../Elements/Dropdown/Dropdown";
import { getTransactionFilter } from "./utils";
import { AlarmFilterEnum } from "../DeviceList/AlarmFilterEnum";
import { DisplayGraph, displayGraph, FilterAlarm, filterAlarm, SelectDate, selectDate } from "./Store/actions";
import {Option} from "../DefaultSettings/Settings/FieldWrapper";
import SmallPin from "../../Elements/Icon/Icons/SmallPin";
import { getCurrentPosition, getTransactionsForDate } from "./Store/routines";
import  Loader  from "@Elements/Loader";
import { AllPermissions, PolicyContext } from "../Policy/Provider";
import { PolicyNameEnum } from "../PolicyNameEnum";
import { Wrapper as DateWrapper } from "@Elements/Datepicker/styles";
import { AppState } from "@Safemate/Store/types";
import Analysis from "@Elements/Icon/Icons/FontAwesome/Analysis";
import { LogParams, MapType } from ".";
import { useParams } from "react-router";
import { SvgButton } from "@Safemate/styles";
import Filter from "./Filter";
import { IDevice } from "@Safemate/Model/Device";

const FetchPositionBtn = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
    margin-top: 0px;
    fill: ${props => props.theme.colors.accentContrast}
  }
  margin-right: 10px;
  min-width: 250px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    margin-right: 0px;
    min-width: 230px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    display: block;
  }
`;

const DropWrap = styled.div`
  margin-right: 10px;
  width: 100%;
  @media (max-width: 600px) {
    display: none;
  }
`;

const DropWrapMobile = styled.div`
  margin-right: 10px;
  width: 100%;
  @media (max-width: 600px) {
    margin-bottom: 10px;
    margin-right: 0px;
    margin-left: 3px;
    width: 155px!important;
  }
  @media (min-width: 601px){
    display: none;
  }
`;

const StyledDateWrapper = styled<any>(DateWrapper)`
  margin-right: 15p.1x;
  @media (max-width: 600px) {
    display:flex;
  }
`;

const DateHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 185px;
  margin-right:10px;
  @media (max-width: 600px) {
    .SingleDatePickerInput{
      display: flex;
    }
    .SingleDatePickerInput_calendarIcon{
      padding-left: 0px!important;
      margin-left: 0px!important;
    }
    .DateInput{
      width: 110px!important;
    }
  }
`;

interface HeaderProps{
  filteredTransactions: Transaction[];
  serialNumber: string;
  dehaId: number;
  alarmFilter: AlarmFilterEnum[];
  filterAlarm: FilterAlarm;
  selectDate: SelectDate;
  getCurrentPosition: Routine<ActionFunctionAny<Action<any>>>;
  fetchingPosition: boolean;
  datesWithTransactions: number[];
  safemateAsReceiver: boolean;
  minionTwin: boolean;
  isTrackerOnly: boolean;
  displayGraph: DisplayGraph;
  mapType: MapType;
  devices: IDevice[];
  googleMaps: any;
  device: Device;
  gx8: boolean;
}

const mapStateToProps = ({ appData:{ isTrackerOnly}, transactionLog: { transaction: { filteredTransactions, alarmFilter, datesWithTransactions, googleMaps }, device: { serialNumber, dehaId, safemateAsReceiver, minionTwin, devices, gx8 }, status: { fetchingPosition }}}: AppState) => {
  return{
    filteredTransactions,
    serialNumber,
    dehaId,
    alarmFilter,
    fetchingPosition,
    datesWithTransactions,
    safemateAsReceiver,
    minionTwin,
    isTrackerOnly,
    devices,
    googleMaps,
    gx8
  }
}

const mapDispatchToProps = {
  filterAlarm,
  selectDate,
  getCurrentPosition,
  displayGraph
}

const Header = connect(mapStateToProps, mapDispatchToProps)(({
  displayGraph,
  isTrackerOnly,
  mapType,
  googleMaps,
  gx8,
}: HeaderProps) => {

  const { formatMessage } = useIntl();

  
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const logPermission = permissions.get(PolicyNameEnum.DEVICE_TRANSACTION_LOG).generalPermissions.all

  
  if(isTrackerOnly || !logPermission) return null;
  return(
    <>
      <Row style={{display: "flex", alignItems: "end"}}>
        <Col style={{display: "flex"}} md={10}>
          { !gx8 && googleMaps && <Filter mapType={mapType}/> }
          { gx8 && <Filter mapType={mapType}/> }
        </Col>
        <Col style={{display: "flex", justifyContent: "end"}} md={2}>
          <SvgButton style={{marginLeft: "10px"}} onClick={displayGraph}><Analysis medium/>{formatMessage({id: "graph", defaultMessage: "Graf"})}</SvgButton>
        </Col>
      </Row>
    </>
  )
})

export default Header;

interface ActionButtonsProps{
  filteredTransactions: Transaction[];
  serialNumber: string;
  dehaId: number;
  alarmFilter: AlarmFilterEnum[];
  filterAlarm: FilterAlarm;
  selectDate: SelectDate;
  getCurrentPosition: Routine<ActionFunctionAny<Action<any>>>;
  fetchingPosition: boolean;
  datesWithTransactions: number[];
  safemateAsReceiver: boolean;
  minionTwin: boolean;
  isTrackerOnly: boolean;
  devices: IDevice[];
  mapType: MapType;
}

export const ActionButtons = connect(mapStateToProps, mapDispatchToProps)(({ filteredTransactions, dehaId, mapType, devices, getCurrentPosition, fetchingPosition, serialNumber  }: ActionButtonsProps) => {

  const { id } = useParams<LogParams>();
  const deviceId = parseInt(id);

  const [ csvData, setCsvData ] = React.useState<string[][]>([]);
  const [ pdfData, setPdfData ] = React.useState<string[][]>([]);
  
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const supportFetchPosition = dehaId === DeviceHardwareEnum.WATCH_ONE || dehaId === DeviceHardwareEnum.WATCH_TWO || dehaId === DeviceHardwareEnum.WATCH_THREE || dehaId === DeviceHardwareEnum.WATCH_FIVE || dehaId === DeviceHardwareEnum.TRIGGER_THREE_E || dehaId === DeviceHardwareEnum.TRIGGER_FOUR || dehaId === DeviceHardwareEnum.TRIGGER_THREE || dehaId === DeviceHardwareEnum.TRIGGER_TWO_4G || dehaId === DeviceHardwareEnum.WATCH_FOUR || dehaId === DeviceHardwareEnum.TRACK_TWO;

  const { formatMessage } = useIntl();

  let csvTimeout: NodeJS.Timeout;
  let pdfTimeout: NodeJS.Timeout;
  
  const name = `${formatMessage({id: "transactions", defaultMessage: "Transactions"})}_${serialNumber}`;

  React.useEffect(() => {
    csvTimeout = setTimeout(() => {
      setCsvData(getExportData(ExportType.CSV, filteredTransactions, formatMessage, dehaId, mapType, filteredTransactions, devices));
    }, 1000);
    pdfTimeout = setTimeout(() => {
      setPdfData(getExportData(ExportType.PDF, filteredTransactions, formatMessage, dehaId, mapType, filteredTransactions, devices));
    }, 1000);
    return () => {
      // Cleanup polling
      clearTimeout(csvTimeout);
      clearTimeout(pdfTimeout);
    }
  }, [filteredTransactions])

  const getPos = () => {
    if(!fetchingPosition)
    getCurrentPosition(deviceId)
  }

  return(
    <>
      {permissions.get(PolicyNameEnum.DEVICE_TRACKING).generalPermissions.all && supportFetchPosition &&
        <button className="btn btn-default svg-icon" style={{marginRight: "10px"}} onClick={getPos}>{fetchingPosition ? <Loader/> : <React.Fragment><SmallPin inline/> {formatMessage({id: "takitForcePositionMessage", defaultMessage: "FETCH CURRENT POSITION"})}</React.Fragment>}</button>}
      {permissions.get(PolicyNameEnum.DEVICE_LOGS).generalPermissions.all &&
      <React.Fragment>
        <TableExport type="csv" data={csvData} fileName={name} />
        <TableExport colWidth={[10, 14, 18, 10, 22, 8, 8, 10]} type="pdf" data={pdfData} fileName={name} />
      </React.Fragment>}
    </>
  )
})
