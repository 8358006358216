import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import transactionSaga from "./transaction";
import deviceSaga from "./device";
import alarmAnalysis from "./alarmAnalysis";
import notificationEvents from "./notificationEvents";
import statusSaga from "./status";
import policySaga from "./policy";
import geofenceSaga from "./geofence";

function* MyPageSaga(){
  yield all([
    fork(initializationSaga),
    fork(transactionSaga),
    fork(deviceSaga),
    fork(alarmAnalysis),
    fork(notificationEvents),
    fork(statusSaga),
    fork(policySaga),
    fork(geofenceSaga)
  ])
}
export default MyPageSaga;
