import { SettingPrefixEnum, SettingEnum } from "../settingEnum";
import FieldWrapper, { GenericTextField } from "../FieldWrapper";
import { LabelEnum } from "../../LabelEnum";


export const PhoneNoWhiteList1  = FieldWrapper({
    componentOptions: {
      Component: GenericTextField,
      props: {}
    },
    prefix: SettingPrefixEnum.OTHER_SETTING,
    policyPrefix: SettingPrefixEnum.WHITELIST,
    field: SettingEnum.PHONE_NO_WHITE_LIST_1,
    title: LabelEnum.PHONE_NO_WHITE_LIST,
    disabledField: SettingEnum.AHP_NUMBER,
    disabledFieldValue: "1",
  });
  
  export const PhoneNoWhiteList2  = FieldWrapper({
    componentOptions: {
      Component: GenericTextField,
      props: {}
    },
    prefix: SettingPrefixEnum.OTHER_SETTING,
    policyPrefix: SettingPrefixEnum.WHITELIST,
    field: SettingEnum.PHONE_NO_WHITE_LIST_2,
    disabledField: SettingEnum.AHP_NUMBER,
    disabledFieldValue: "1",
  });
  
  export const PhoneNoWhiteList3  = FieldWrapper({
    componentOptions: {
      Component: GenericTextField,
      props: {}
    },
    prefix: SettingPrefixEnum.OTHER_SETTING,
    policyPrefix: SettingPrefixEnum.WHITELIST,
    field: SettingEnum.PHONE_NO_WHITE_LIST_3,
    disabledField: SettingEnum.AHP_NUMBER,
    disabledFieldValue: "1",
  });
