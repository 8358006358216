import {call, put, race, take, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction, IRace } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure } from '../../../Feedback/reducer';
import { Role } from '../types';

export interface Initialize{
  roleId?: number;
  duplicate?: boolean;
}

function* initialize(action: AppAction<Initialize>){
  try{
    if(action.payload.roleId){
      yield put(Routines.getRole(action.payload));
      const {success, failure}: IRace<Role> = yield race({
        success: take(Routines.getRole.SUCCESS),
        failure: take(Routines.getRole.FAILURE)
      });    
      if(success){
        yield put(Routines.initialize.success());
        return;
      }
    }
    else{
      yield put(Routines.getPolicies());
      const {success, failure}: IRace<Role> = yield race({
        success: take(Routines.getPolicies.SUCCESS),
        failure: take(Routines.getPolicies.FAILURE)
      });
      if(success){
        yield put(Routines.initialize.success());
        return
      }
    }
    yield put(Routines.initialize.failure());
    
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.initialize.failure());
  }
}

function* initializationSaga(){
  yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
