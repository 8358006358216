import { combineReducers } from "redux";
import { RootState } from "../types";
import initialization from "./initialization";
import userAuditLog from "./userAuditLog";
import deviceAuditLog from "./deviceAuditLog";

export default combineReducers<RootState>({
    initialization,
    userAuditLog,
    deviceAuditLog
});
