import { AppAction } from "@Safemate/Store/types";
import Actions from "./actionTypes";
import { LoginStep } from "..";
import { RegisterStep } from "../Register";

export type SetLoggedIn = (loggedIn: boolean) => AppAction<boolean>

export const setLoggedIn: SetLoggedIn = (loggedIn) => ({
  type: Actions.SET_LOGGED_IN,
  payload: loggedIn
})

export type SetLoginStep = (step: LoginStep) => AppAction<LoginStep>

export const setLoginStep: SetLoginStep = (step) => ({
  type: Actions.SET_LOGIN_STEP,
  payload: step
})

export type SetRegisterStep = (step: RegisterStep) => AppAction<RegisterStep>

export const setRegisterStep: SetRegisterStep = (step) => ({
  type: Actions.SET_REGISTER_STEP,
  payload: step
})

export type SetNameValidationModal = (show: boolean) => AppAction<boolean>

export const setNamevalidationModal: SetNameValidationModal = (show) => ({
  type: Actions.SET_NAME_VALIDATION_MODAL,
  payload: show
})