import * as React from "react";
import {ComponentProps} from "./FieldWrapper";
import {MessageDescriptor, WrappedComponentProps, injectIntl} from "react-intl";
import {TitleSpan, TitleWrap} from "./styled";
import SelfcheckFail from "../../../Elements/Icon/Icons/Safemate/solid/SelfcheckFail";
import {
  CallbackTimer,
  HeartbeatInterval,
  HeartbeatIntervalGX8,
  PrefComMethod,
  SelfCheck,
  SilentActive,
  TwoWayDectTimer,
  TriggerFourHeartbeat,
  SensorDisconnectTreshold
} from "./Communication";
import {SettingPrefixEnum} from "./settingEnum";
import Location from "../../../Elements/Icon/Icons/Safemate/solid/Location";
import LightbulbOnSolid from "../../../Elements/Icon/Icons/Safemate/solid/LightbulbOnSolid";
import Cog from "../../../Elements/Icon/Icons/Safemate/solid/Cog";
import BatteryBolt from "../../../Elements/Icon/Icons/Safemate/solid/BatteryBolt";
import {StaticTooLong} from "./NotInUse";
import {DisablePositioning, Satellites, OutdatedPosition} from "./Positioning";
import {ACCEPT_LBS, AutoTracking, GEOLOCATION_COMBAIN, GEOLOCATION_GOOGLE, TrackingInterval} from "./Tracking";
import {
  CallbackIndicator,
  ConfirmBeep,
  WandererBeep,
  DectSoundLevel,
  GuardTimeBeep,
  HelpArriveIndicator,
  IncomingRingtone,
  LightLevel,
  SoundLevel,
  SoundLevelGX8,
  SoundLevelRingtone,
  SoundLevelRingtoneWatchOne,
  SoundLevelWatchOne,
  SystemSounds,
  TwoWayMic,
  LightMode,
  SoundLevelTriggerFour,
  SoundLevelRingToneTriggerFour,
  MicTriggerFour,
  VoicePromptVolumeTriggerFour,
  VoicePromptVolumeGX8,
  SoundLevelTriggerTwo4G,
  SoundLevelRingtoneTriggerTwo4G,
  VoicePromptAlarm,
  VoicePromptCallback,
  VoicePromptFall,
  VoicePromptWanderer,
  VoicePromptBed,
  VoicePromptACFailure,
  VoicePromptACRestore,
  LightAlarmMode,
  LightNormalMode,
  SynchronizeSound,
  ScreenBrightness,
  ScreenLightMode,
  LowBatteryVoicePrompt
} from "./SoundLight";
import {BatteryThreshold, PowerFailureReportDelay} from "./Battery";
import {
  BypassTimer,
  GuardTime,
  LocalLearning,
  SensorSupervisionCheckActive,
  SensorSupervisionCheckTimer,
  AlarmSmsReceipt, ACLCheck,
  SensorSensitivity,
  TeamAlert,
  SensorSupervisionCheckTimerPendant
} from "./General";
import {
  PhoneNoWhiteList1, PhoneNoWhiteList2, PhoneNoWhiteList3
} from "./Whitelist";
import {
  Integration
} from "./Integration";
import Static from "../../../Elements/Icon/Icons/static";
import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import { DeviceHardwareEnum } from '../../DeviceHardwareEnum';
import { Led } from './SoundLight/index';
import Privacy from "../../../Elements/Icon/Icons/Privacy";
import {
  DevicePositionHistoryDepthHours,
  DevicePositionHistoryDepthPosPlots,
  DeviceTransactionLogs, NokEmployeeLoginIdleHour,
  UserInactivityLimit,
  UserTransactionLogs
} from "./Privacy";
import { SelectFirmware } from "./Firmware";
import ClimaxReportSelector from "./Communication/climaxReport";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList, faCogs, faMicrochip, faVolumeUp, faMicrophoneAlt } from '@fortawesome/pro-solid-svg-icons';
import { DefaultEventSetup, EnableDefaultEventSetup } from "./EventSetup";
import Users from "@Elements/Icon/Icons/Safemate/solid/Users";
import {LabelEnum} from "@Safemate/DefaultSettings/LabelEnum";
import Tooltip from "@Elements/Tooltip";
import {DefaultGeofences} from "@Safemate/DefaultSettings/Settings/Geofence";
import MobileApp from "@Icons/MobileApp";

export interface TabInfo {
  components: Array<React.FunctionComponent<ComponentProps>>;
  title: Title;
}

export interface Title{
  component: React.ReactElement;
  tooltip?: string;
}

export interface Safemate{
  communication?: TabInfo;
  notInUse?: TabInfo;
  positioning?: TabInfo;
  tracking?: TabInfo;
  lightSound?: TabInfo;
  battery?: TabInfo;
  general?: TabInfo;
  privacy?: TabInfo;
  [index: string]: TabInfo | undefined;
}

export interface TitleProps extends React.PropsWithChildren<WrappedComponentProps>{
  message: MessageDescriptor;
  tooltip?: string;
}

export const Title = injectIntl(({children, tooltip, message, intl: {formatMessage}}: TitleProps) => {
  return(
    <React.Fragment>
      <TitleSpan>
        {children}
        <TitleWrap>
          {formatMessage(message)}
          &nbsp;
          {tooltip &&
            <Tooltip
              popoverId={`${message.id}-popover`}
              labelId={tooltip}
              defaultMessage={tooltip}
            >
              <InformationIcon size="medium" />
            </Tooltip>
          }
        </TitleWrap>
      </TitleSpan>
    </React.Fragment>
  )
})

export const defaultCom = {
  title: {
    component: <Title message={{id:"communication", defaultMessage:"Kommunikasjon"}}><SelfcheckFail inline size="medium"/></Title>,
  }
}

export const defaultNotInuse = {
  title: {
    component: <Title message={{id:"motionless", defaultMessage:"Ikke i aktiv bruk"}}><Static inline size="medium"/></Title>,
    tooltip: "tooltip_staticTooLongInterval"
  }
}

export const defaultPositioning = {
  title: {
    component: <Title message={{id:"tracking", defaultMessage:"Lokalisering"}}><Location inline size="medium"/></Title>
  }
}

export const defaultLightSound = {
  title: {
    component: <Title message={{id:"lightSound", defaultMessage:"Lys og lyd"}}><LightbulbOnSolid inline size="medium"/></Title>
  }
}

export const defaultSound = {
  title: {
    component: <Title message={{id:"sound", defaultMessage:"Lyd"}}><FontAwesomeIcon style={{width: "24px", height: "24px"}} icon={faVolumeUp} size="lg" /></Title>
  }
}

export const defaultLight = {
  title: {
    component: <Title message={{id:"light", defaultMessage:"Lys"}}><LightbulbOnSolid inline size="medium"/></Title>
  }
}

export const defaultVoicePrompt = {
  title: {
    component: <Title message={{id:"voicePrompt", defaultMessage:"Talemeldinger"}}><FontAwesomeIcon style={{width: "24px", height: "24px"}} icon={faMicrophoneAlt} size="lg" /></Title>
  }
}

export const defaultBattery = {
  title: {
    component: <Title message={{id:"battery", defaultMessage:"Batteri"}}><BatteryBolt inline size="medium"/></Title>
  }
}

export const defaultGeneral = {
  title: {
    component: <Title message={{id:"generalSettings", defaultMessage:"Generelle innstillinger"}}><Cog inline size="medium"/></Title>
  }
}

export const defaultPrivacy = {
  title: {
    component: <Title message={{id:"privacySettings", defaultMessage:"Privacy"}}><Privacy inline size="medium"/></Title>
  }
}

export const defaultMobileApp = {
  title: {
    component: <Title message={{id:"mobileAppSettings", defaultMessage:"Mobile App"}}><MobileApp inline size="medium"/></Title>
  }
}

export const defaultFirmware = {
  title: {
    component: <Title message={{id:"firmware", defaultMessage:"Firmware"}}><FontAwesomeIcon style={{width: "24px", height: "24px"}} icon={faMicrochip} size="lg" /></Title>
  }
}

export const defaultWhitelist = {
  title: {
    component: <Title message={{id: "whitelist", defaultMessage: "Whitelist"}}><FontAwesomeIcon style={{width: "24px", height: "24px"}} icon={faThList} size="lg" /></Title>,
    tooltip: LabelEnum.PHONE_NO_WHITE_LIST_TOOLTIP,
  }
}

export const defaultIntegration = {
  title: {
    component: <Title message={{id: "integration", defaultMessage: "Integration"}}><FontAwesomeIcon style={{width: "24px", height: "24px"}} icon={faCogs} size="lg" /></Title>,
    tooltip: "tooltip_integrationTitle"
  }
}

export const defaultEventSetup = {
  title: {
    component: <Title message={{id: "eventSetup", defaultMessage: "Varslingsliste"}}><Users inline size="medium"/></Title>,
  }
}

export const defaultGeofence = {
  title: {
    component: <Title message={{id: "geofence", defaultMessage: "Geofence"}}><Users inline size="medium"/></Title>,
  }
}

export const DEFAULT: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION,
    {
      components: [SelfCheck, HeartbeatInterval],
      ...defaultCom
    }],
    [SettingPrefixEnum.NOT_IN_USE,
    {
      components: [StaticTooLong],
      ...defaultNotInuse
    }],
    [SettingPrefixEnum.POSITIONING,
    {
      components: [DisablePositioning, AutoTracking, GEOLOCATION_GOOGLE, GEOLOCATION_COMBAIN, ACCEPT_LBS, TrackingInterval, Satellites, OutdatedPosition],
      ...defaultPositioning
    }],
    [SettingPrefixEnum.SOUND_LIGHT,
    {
      components: [
        MicTriggerFour, 
        SoundLevelTriggerFour, 
        SoundLevelRingToneTriggerFour, 
        VoicePromptVolumeTriggerFour, 
        SystemSounds,
        Led],
      ...defaultLightSound
    }],
    [SettingPrefixEnum.BATTERY,
    {
      components: [BatteryThreshold],
      ...defaultBattery
    }]
  ]
)


export const ALL: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.POSITIONING, DEFAULT.get(SettingPrefixEnum.POSITIONING) as TabInfo],
    [SettingPrefixEnum.NOT_IN_USE, DEFAULT.get(SettingPrefixEnum.NOT_IN_USE) as TabInfo],
    [SettingPrefixEnum.BATTERY, DEFAULT.get(SettingPrefixEnum.BATTERY) as TabInfo],
    [SettingPrefixEnum.GENERAL, {
      components: [AlarmSmsReceipt, TeamAlert],
      ...defaultGeneral
    }]
  ]
)

export const OtherTab: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.PRIVACY, {
      components: [DeviceTransactionLogs, UserTransactionLogs, UserInactivityLimit],
      ...defaultPrivacy
    }],
    [SettingPrefixEnum.WHITELIST, {
      components: [ACLCheck, PhoneNoWhiteList1, PhoneNoWhiteList2, PhoneNoWhiteList3],
      ...defaultWhitelist
    }],
    [SettingPrefixEnum.INTEGRATION, {
      components: [  Integration ],
      ...defaultIntegration
    }],
    [SettingPrefixEnum.MOBILE_APP, {
      components: [DevicePositionHistoryDepthHours, DevicePositionHistoryDepthPosPlots, NokEmployeeLoginIdleHour],
      ...defaultMobileApp
    }]
  ]
)

export const EventSetupTab: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.EVENT_SETUP, {
      components: [ EnableDefaultEventSetup, DefaultEventSetup ],
      ...defaultEventSetup
    }]
  ]
)

export const GeofenceTab: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.GEOFENCE, {
      components: [ DefaultGeofences ],
      ...defaultGeofence
    }]
  ]
)


export const TRIGGER_THREE: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, {
      components: [SelfCheck],
      ...defaultCom
    }],
    [SettingPrefixEnum.SOUND_LIGHT, {
      components: [Led],
      ...defaultLightSound
    }],
  ]
)

export const TRIGGER_FOUR: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, {
      components: [SelfCheck, TriggerFourHeartbeat],
      ...defaultCom
    }],
    [SettingPrefixEnum.SOUND_LIGHT, DEFAULT.get(SettingPrefixEnum.SOUND_LIGHT) as TabInfo],
  ]
)


export const TRACK_THREE = TRIGGER_THREE;
export const TRIGGER_THREE_E = TRIGGER_FOUR;
export const WATCH_FOUR = TRIGGER_FOUR;

export const WATCH_SIX = TRIGGER_FOUR;
export const TRACK_TWO = TRIGGER_FOUR;

export const TRIGGER_TWO: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, DEFAULT.get(SettingPrefixEnum.COMMUNICATION) as TabInfo],
    [SettingPrefixEnum.SOUND_LIGHT, {
      components: [LightMode],
      ...defaultLightSound
    }]
  ]
)


export const TRIGGER_TWO_4G: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, DEFAULT.get(SettingPrefixEnum.COMMUNICATION) as TabInfo],
    [SettingPrefixEnum.SOUND_LIGHT, {
      components: [LightMode, SoundLevelTriggerTwo4G, SoundLevelRingtoneTriggerTwo4G],
      ...defaultLightSound
    }],
  ]
)

export const TRACK_FOUR: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, DEFAULT.get(SettingPrefixEnum.COMMUNICATION) as TabInfo],
  ]
)

export const WATCH_TWO: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, DEFAULT.get(SettingPrefixEnum.COMMUNICATION) as TabInfo],
    [SettingPrefixEnum.SOUND_LIGHT, {
      components: [ScreenBrightness, ScreenLightMode, LowBatteryVoicePrompt],
      ...defaultLightSound
    }]
  ]
)

export const WATCH_FIVE: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, DEFAULT.get(SettingPrefixEnum.COMMUNICATION) as TabInfo],
    [SettingPrefixEnum.SOUND_LIGHT, {
      components: [ScreenBrightness, ScreenLightMode, LowBatteryVoicePrompt],
      ...defaultLightSound
    }]
  ]
)

export const SAFEMATE_HELP: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, DEFAULT.get(SettingPrefixEnum.COMMUNICATION) as TabInfo],
  ]
)

export const TRACK_ONE = TRIGGER_TWO;
export const TRIGGER_ONE = SAFEMATE_HELP;


export const WATCH_ONE: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, {
      components: [SelfCheck],
      ...defaultCom
    }],
    [SettingPrefixEnum.SOUND_LIGHT, {
      components: [SoundLevelWatchOne, SoundLevelRingtoneWatchOne],
      ...defaultLightSound
    }],
  ]
)

export const WATCH_THREE = WATCH_ONE;

export const SAFEMATE_HOME: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, {
      components: [ClimaxReportSelector, SelfCheck, HeartbeatIntervalGX8, CallbackTimer, PrefComMethod, TwoWayDectTimer, SilentActive],
      ...defaultCom
    }],
    [SettingPrefixEnum.SOUND, {
      components: [SoundLevelGX8, VoicePromptVolumeGX8, DectSoundLevel, TwoWayMic, SynchronizeSound,
        GuardTimeBeep, ConfirmBeep, WandererBeep],
      ...defaultSound
    }],
    [SettingPrefixEnum.LIGHT, {
      components: [LightAlarmMode, LightNormalMode, HelpArriveIndicator, CallbackIndicator],
      ...defaultLight
    }],
    [SettingPrefixEnum.VOICE_PROMPT, {
      components: [VoicePromptAlarm, VoicePromptCallback, VoicePromptFall, VoicePromptWanderer, VoicePromptBed, VoicePromptACFailure, VoicePromptACRestore],
      ...defaultVoicePrompt
    }],
    [SettingPrefixEnum.GENERAL, {
      components: [SensorSupervisionCheckActive, SensorSupervisionCheckTimer, SensorSupervisionCheckTimerPendant, GuardTime, BypassTimer, SensorSensitivity, LocalLearning],
      ...defaultGeneral
    }],
    [SettingPrefixEnum.FIRMWARE, {
      components: [SelectFirmware],
      ...defaultFirmware
    }]
  ]
)

export const GTX_SOLE: Map<SettingPrefixEnum, TabInfo> = new Map<SettingPrefixEnum, TabInfo>(
  [
    [SettingPrefixEnum.COMMUNICATION, {
      components: [SelfCheck],
      ...defaultCom
    }],
  ]
)

export const SAFEMATE_HOME_DT35 = SAFEMATE_HOME;
export const SAFEMATE_HOME_MAX = SAFEMATE_HOME;

export const SAFEMATE_HOME_STCB1 = SAFEMATE_HOME;

export const SettingToPolicyMap: Map<SettingPrefixEnum, PolicyNameEnum> = new Map<SettingPrefixEnum, PolicyNameEnum>(
  [
    [SettingPrefixEnum.COMMUNICATION, PolicyNameEnum.DEFAULT_COMMUNICATION],
    [SettingPrefixEnum.NOT_IN_USE, PolicyNameEnum.DEFAULT_NOT_USED],
    [SettingPrefixEnum.TRACKING, PolicyNameEnum.DEFAULT_TRACKING],
    [SettingPrefixEnum.POSITIONING, PolicyNameEnum.DEFAULT_POSITIONING],
    [SettingPrefixEnum.SOUND_LIGHT, PolicyNameEnum.DEFAULT_LIGHT_SOUND],
    [SettingPrefixEnum.SOUND, PolicyNameEnum.DEFAULT_LIGHT_SOUND],
    [SettingPrefixEnum.LIGHT, PolicyNameEnum.DEFAULT_LIGHT_SOUND],
    [SettingPrefixEnum.VOICE_PROMPT, PolicyNameEnum.DEFAULT_LIGHT_SOUND],
    [SettingPrefixEnum.BATTERY, PolicyNameEnum.DEFAULT_BATTERY],
    [SettingPrefixEnum.GENERAL, PolicyNameEnum.DEFAULT_GENERAL],
    [SettingPrefixEnum.PRIVACY, PolicyNameEnum.DEFAULT_PRIVACY],
    [SettingPrefixEnum.MOBILE_APP, PolicyNameEnum.DEFAULT_PRIVACY],
    [SettingPrefixEnum.FIRMWARE, PolicyNameEnum.DEFAULT_FIRMWARE],
    [SettingPrefixEnum.WHITELIST, PolicyNameEnum.DEFAULT_WHITELIST],
    [SettingPrefixEnum.INTEGRATION, PolicyNameEnum.DEFAULT_INTEGRATION],
    [SettingPrefixEnum.EVENT_SETUP, PolicyNameEnum.DEFAULT_EVENT_SETUP],
    [SettingPrefixEnum.INDOOR_LOCATION, PolicyNameEnum.DEVICE_INDOOR_CUSTOMER],
    [SettingPrefixEnum.GEOFENCE, PolicyNameEnum.CUSTOMER_GEOFENCE]
  ]
)

export const DeviceComponents: Map<DeviceHardwareEnum, Map<SettingPrefixEnum, TabInfo>> = new Map<DeviceHardwareEnum,Map<SettingPrefixEnum, TabInfo>>([
  [DeviceHardwareEnum.DEFAULT, DEFAULT],
  [DeviceHardwareEnum.ALL, ALL],
  [DeviceHardwareEnum.TRIGGER_ONE, TRIGGER_ONE],
  [DeviceHardwareEnum.TRACK_ONE, TRACK_ONE],
  [DeviceHardwareEnum.TRIGGER_TWO, TRIGGER_TWO],
  [DeviceHardwareEnum.TRIGGER_THREE, TRIGGER_THREE],
  [DeviceHardwareEnum.TRIGGER_THREE_E, TRIGGER_THREE_E],
  [DeviceHardwareEnum.TRIGGER_FOUR, TRIGGER_FOUR],
  [DeviceHardwareEnum.TRACK_THREE, TRACK_THREE],
  [DeviceHardwareEnum.WATCH_ONE, WATCH_ONE],
  [DeviceHardwareEnum.WATCH_TWO, WATCH_TWO],
  [DeviceHardwareEnum.WATCH_THREE, WATCH_THREE],
  [DeviceHardwareEnum.SAFEMATE_HOME, SAFEMATE_HOME],
  [DeviceHardwareEnum.SAFEMATE_HOME_DT35, SAFEMATE_HOME_DT35],
  [DeviceHardwareEnum.SAFEMATE_HOME_MAX, SAFEMATE_HOME_MAX],
  [DeviceHardwareEnum.SAFEMATE_HELP, SAFEMATE_HELP],
  [DeviceHardwareEnum.TRIGGER_TWO_4G, TRIGGER_TWO_4G],
  [DeviceHardwareEnum.WATCH_FOUR, WATCH_FOUR],
  [DeviceHardwareEnum.TRACK_TWO, TRACK_TWO],
  [DeviceHardwareEnum.GTX_SOLE, GTX_SOLE],
  [DeviceHardwareEnum.WATCH_FIVE, WATCH_FIVE],
  [DeviceHardwareEnum.WATCH_SIX, WATCH_SIX],
  [DeviceHardwareEnum.TRACK_FOUR, TRACK_FOUR],
  [DeviceHardwareEnum.SAFEMATE_HOME_STCB1, SAFEMATE_HOME_STCB1],
])
