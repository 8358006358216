import * as React from "react";

import { connect } from "react-redux";
import { AppState } from "@Safemate/Store/types";
import {IUser} from "@Safemate/Model/User";
import {CustomRole} from "@Safemate/Model/Role";
import {GlobalRoles} from "@Safemate/GlobalRoles";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";

interface LandingHandlerProps{
  isPrivate: boolean;
  isPartner: boolean;
  user: IUser;
  activeRoles: CustomRole[];
  currentContext: SiteTypeEnum;
}

const mapPropsToState = ({ appData: { user, activeRoles, isPartner, isPrivate, currentContext } }: AppState) => {
  return{
    isPrivate,
    isPartner,
    user,
    activeRoles,
    currentContext
  }
}

export const LandingHandler = connect(mapPropsToState)(({ currentContext, isPrivate, isPartner, activeRoles }: LandingHandlerProps) => {

  const roles = activeRoles.filter(k => k.global).map( k=> k.roleNameOriginal.toUpperCase());


  if(currentContext === SiteTypeEnum.AHP){
    location.hash = "dashboard";
  } else if(isPartner){
    location.hash = "partner_dashboard";
  } else if( isPrivate || roles.includes(GlobalRoles.TRACKER) ){
    location.hash = "safemates";
  } else if( roles.includes(GlobalRoles.SUPERFIRM) || roles.includes(GlobalRoles.FIRM) ){
    location.hash = "dashboard";
  } else {
    location.hash = "safemates";
  }

  return <React.Fragment></React.Fragment>
})
