import * as React from "react";
import { FormikProps } from "formik";
import { injectIntl, WrappedComponentProps, MessageDescriptor } from "react-intl";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { DataOptions } from "@Safemate/DefaultSettings/Settings/FieldWrapper";
import withFormWrapper from "./formWrapper";
import { mapOptions, status, translateOptions } from "./utils";
import { SettingDiv, SettingHeader, TitleWrap } from "./styles";
import InformationIcon from "@Icons/Information1";
import Tooltip from "@Elements/Tooltip";
import { Option } from "@Elements/Dropdown/types";

interface DropdownOptions{
  prefix?: string;
  title?: MessageDescriptor;
  tooltip?: string;
  tooltipTitle?: MessageDescriptor
  padding?: boolean;
  width?: string;
  translate?: boolean;
  dataOptions: DataOptions;
}

interface DropdownProps<T> extends WrappedComponentProps{
  formik: FormikProps<T>; 
}

export function DropdownWrap(name: string, options: DropdownOptions): React.FunctionComponent<any>{
  return withFormWrapper(
    injectIntl(({intl: {formatMessage}, formik: {values, setFieldValue, setFieldTouched}}: DropdownProps<any>) => {

      let value;
      let field = name;
      if(options.prefix){
        value = values[options.prefix][name];
        field = `${options.prefix}.${name}`;
      }
      else{
        value = values[name];
      }

      let formattedData = options.dataOptions.options;
      if(options.dataOptions && !options.dataOptions.skipMap) {
        const { nullValue, offset } = options.dataOptions;
        formattedData = mapOptions(
          options.dataOptions.options, 
          formatMessage, 
          formatMessage(nullValue || status.disable), 
          offset,
          false,
          options.dataOptions.mapRoundDown
        );
      }
      
      if(options.dataOptions.translate){
        formattedData = translateOptions(formattedData, formatMessage);
      }

      return(
      <SettingDiv padding={options.padding} key={name}>
        {options.title &&
          <SettingHeader>
            <TitleWrap right>
              {formatMessage(options.title)}
            </TitleWrap>
            {options.tooltip &&
              <Tooltip
                popoverId={`${options.title}-popover`}
                labelId={options.tooltip}
                defaultMessage={options.tooltip}
                title={options.tooltipTitle ? formatMessage(options.tooltipTitle) : ""}
              >
                  <InformationIcon size="medium" />
              </Tooltip>
            }
          </SettingHeader>}
        <Dropdown
          title={options.title ? formatMessage(options.title) : name}
          options={formattedData}
          initial={value}
          size={{width: options.width}}
          onChange={(object: Option | Option[]) => {
            const value = (object as Option).value;
            setFieldTouched(field)
            setFieldValue(field, value)
          }}
        />
      </SettingDiv>)
    })
  ) as React.FunctionComponent<any>
}