import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Billing/INITIALIZE'
);


export const savePrivateBilling: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
    '@@Billing/SAVE_PRIVATE_BILLING'
);

export const getPostCity: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Billing/GET_POST_CITY'
);
