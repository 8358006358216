import styled from "styled-components";

export const SvgWrap = styled.span`
  white-space: nowrap;
  justify-content: space-between;
  display: flex;
  width: 100px;

  svg {
    fill: ${props => props.theme.colors.textPrimary};
  }
`;
