import * as React from "react";
import styled from "styled-components";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { DeviceComponents } from "../../tabDefinition";
import { SettingEnum } from "../../settingEnum";
import { connect } from "react-redux";
import { DeviceHardwareEnum } from "../../../DeviceHardwareEnum";
import { Col } from "react-bootstrap";
import { AppState } from "@Safemate/Store/types";

const Wrapper = styled.div<{active?: boolean}>`
  margin: 3px;
  padding: 15px;
  min-height: 230px;
  border: 1px solid ${props => props.active ? "green" : "gray"};
  border-radius: 5px;
`;

const NoPadCol = styled(Col)`
  padding: 0;
`;

interface ManualTrackingProps extends InjectedIntlProps{
  dehaId: number;
  trackingUntil: number;
}

const ManualTracking = injectIntl(({ intl: {formatMessage}, dehaId, trackingUntil }: ManualTrackingProps) => {

  const components: Map<SettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) ? DeviceComponents.get(dehaId)! : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;
  
  const now = Date.now();
  const isTracking = trackingUntil - now > 0;

  return(
    <NoPadCol md={6} sm={6}>
      <Wrapper active={isTracking}>
        <h3>{formatMessage({id: "manualTracking", defaultMessage: "Manuell lokalisering"})}</h3>
        {components.has(SettingEnum.TRACKING_MANUAL) && components.get(SettingEnum.TRACKING_MANUAL)?.map(comp => comp)}
      </Wrapper>
    </NoPadCol>
  )
})

const mapStateToProps = ({ settings: {device: {device: {dehaId}}, tracking: {trackingUntil}}}: AppState) => {
  return {
      dehaId,
      trackingUntil
  }
}

export default connect(mapStateToProps)(ManualTracking);