import * as React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { LogoEnum } from "./LogoEnum";

export const Header = styled.div`
  background: #2C2E32;
  height: 100px;
  width: 500px;
  border-radius: 5px 5px 0 0;
  position: relative;
  @media (max-width: 550px) {
		width: 100%;
	}
  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(44, 46, 50, 0);
    border-top-color: #2C2E32;
    border-width: 6px;
    margin-left: -6px;
  }
`;

const LogoContainer = styled.div<{logo: LogoEnum}>`
  background: url(${props => { 
    let logoPath = "logo.png";
    switch (props.logo){
      case LogoEnum.SAFEMATE_PRO:
        logoPath = "logoprologin.png";
      case LogoEnum.SAFEMATE_GO:
        logoPath =  "logogologin.png";
      case LogoEnum.SAFECALL_PRO:
        logoPath =  "logo_safecall_pro_login.png";
      case LogoEnum.SAFECALL_GO:
        logoPath =  "logo_safecall_go_login.png";
      case LogoEnum.SAFECALL:
        logoPath =  "safecall-logo.png";
      default: logoPath =  "logo.png";
    }
    return require(`../../../../assets/images/logo/${logoPath}`)
  }}) center center no-repeat;
  height: 100px;
  @media (min-width: 551px) {
    min-width: 500px;
	}
`;

const Content = styled.div`
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  padding: 30px;
`;

const Title = styled.h4`
  text-align: center;
  font-size: 22px;
  color: #5F6A6D;
  font-weight: 300;
  margin: 10px 0 20px 0;
  padding: 0;
  display: block;
`;

const Footer = styled.div`
  padding: 0px 30px 30px 30px;
`;

interface LogoModalProps{
  isOpen?: boolean;
  logo: LogoEnum;
  title?: string;
  content?: JSX.Element;
  footer?: JSX.Element;
}

const LogoModal = ({ isOpen, logo, title, content, footer, children }: React.PropsWithChildren<LogoModalProps>) => {

  return(
    <ReactModal
      style={{
        content: {
          position: "initial",
          width: "500px",
          padding: "0",
          margin: "80px auto 0",
          overflow: "initial",
          maxWidth: "90%",
        }
      }}
      isOpen={isOpen}
    >
      <Header>
        <LogoContainer logo={logo}/>
      </Header>
      <Content>
        {title && <Title>{title}</Title>}
        <React.Fragment>
          {children}
        </React.Fragment>
      </Content>
      {footer && <Footer>
        {footer}
      </Footer>}
    </ReactModal>
  )
}

export const LogoLoginModal = ({ logo, children }: React.PropsWithChildren<LogoModalProps>) => {

  return(
    <ReactModal
      style={{
        content: {
          position: "initial",
          width: "500px",
          padding: "0",
          margin: "80px auto 0",
          overflow: "initial",
          maxWidth: "90%",
        }
      }}
      isOpen={true}
    >
      <Header>
        <LogoContainer logo={logo}/>
      </Header>
      <React.Fragment>
        {children}
      </React.Fragment>
    </ReactModal>
  )
}

export const Logoheader = ({logo}: LogoModalProps) => {
  return(
    <Header>
      <LogoContainer logo={logo}/>
    </Header>
  )
}

export default LogoModal;