import {Reducer} from "redux";
import { AppData } from "../types";
import { defaultUser } from "@Safemate/Model/User";
import { ICustomerWithPolicy, defaultCustomer } from "@Safemate/Model/Customer";
import { defaultSso } from "@Safemate/Model/Sso";
import { getCustomer, getPartner, getPrivate, getSso, getSuborgsWithRoles, getTexts, getMunicipalities, getUser, initialize, getPartnerUser, getBackdoorUser, getRestrictedUser, getActiveRoles, isSso, getAdminSso, getLastAlarm, getAhpRoles, getBillingFilled, isAhp, isPro, getGdprManager, getRequireNationalId, getPreventPartnerReferenceChange, initializeCustomerHierarchy } from "../routines";
import { getCookie, getSelectedRoles, isTrackerOnly, setCookie, setSelectedRolesCookie } from "@Safemate/utils";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";
import Actions from "../actionTypes";
import { CustomRole } from "@Safemate/Model/Role";
import { setStyle, saveUser } from "@Safemate/MyPage/Store/routines";
import { acceptGdpr } from "@Safemate/Session/Store/routines";
import { savePrivateBilling } from "@Safemate/Billing/Store/routines";
import { changeAhpStyleSheet } from "@Safemate/MyPage/fields";
import { assignGdprManager, removeGdprManager } from "@Safemate/UserAdmin/Store/routines";

export const getPreviousContext = () => {
  const context = getCookie("site_context");
  switch (context){

    case SiteTypeEnum.AHP:{
      changeAhpStyleSheet(true);
      return SiteTypeEnum.AHP
    }

    case SiteTypeEnum.PROFFWEB:
    default:
      changeAhpStyleSheet(false);
      return SiteTypeEnum.PROFFWEB;
    
  }
}

export const setSiteContext = (context: SiteTypeEnum) => {
  setCookie("site_context", context, 30, null);
}

const appState: AppData = {
  initialized: false,
  customersWithPolicy: [] as ICustomerWithPolicy[],
  customerHierarchy: {
    children: [],
    customer: {
      custId: 0,
      firmName: ""
    },
    rights: false
  },
  municipalities: [] as ICustomerWithPolicy[],
  user: defaultUser,
  superfirm: defaultCustomer,
  sso: defaultSso,
  isSso: false,
  isAdminSso: false,
  texts: null,
  partnerUser: "",
  backdoorUser: "",
  isPrivate: false,
  isAhp: false,
  isPro: false,
  isPartner: false,
  isTrackerOnly: false,
  isRestrictedUser: false,
  currentContext: getPreviousContext(),
  activeRoles: [] as CustomRole[],
  selectedRoles: getSelectedRoles(),
  lastAlarm: 0,
  showAlarmNotification: false,
  ahpRoles: {
    AHP: false,
    SUPERAHP: false,
    TECHNICIAN: false,
  },
  isBillingFilled: false,
  gdprManager: {
    userId: 0,
    name: "",
    email: ""
  },
  requireNationalId: false,
  preventPartnerReferenceChange: false
};

const app: Reducer<AppData> = (state: AppData = appState, action) => {
  switch (action.type) {
    case initialize.FULFILL:
      return {
        ...state,
        initialized: true
      }
    
    case saveUser.SUCCESS:
    case getUser.SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case getCustomer.SUCCESS:
      return {
        ...state,
        superfirm: action.payload,
      };

    case getSso.SUCCESS:
      return {
        ...state,
        sso: action.payload
      };

    case isSso.SUCCESS:
      return {
        ...state,
        isSso: action.payload
      }

    case getAdminSso.SUCCESS:
      return {
        ...state,
        isAdminSso: action.payload
      }
    
    case getTexts.SUCCESS:
      return {
        ...state,
        texts: action.payload
      }

    case getPrivate.SUCCESS:
      return {
        ...state,
        isPrivate: action.payload
      }

    case getPartner.SUCCESS:
      return {
        ...state,
        isPartner: action.payload
      }

    case getSuborgsWithRoles.SUCCESS:
      return {
        ...state,
        customersWithPolicy: action.payload,
        isTrackerOnly: isTrackerOnly(action.payload)
      }

    case getMunicipalities.SUCCESS:
      return {
        ...state,
        municipalities: action.payload
      }

    case getPartnerUser.SUCCESS:
      return {
        ...state,
        partnerUser: action.payload
      }

    case getBackdoorUser.SUCCESS:
      return {
        ...state,
        backdoorUser: action.payload
      }

    case getRestrictedUser.SUCCESS:
      return {
        ...state,
        isRestrictedUser: action.payload
      }

    case getActiveRoles.SUCCESS:
      return{
        ...state,
        activeRoles: action.payload
      }

    case getAhpRoles.SUCCESS:
      return {
        ...state,
        ahpRoles: action.payload,
      }
    
    case Actions.SET_SITE_CONTEXT:
      setSiteContext(action.payload);
      return{
        ...state,
        currentContext: action.payload
      }

    case Actions.SET_SELECTED_ROLES:
      setSelectedRolesCookie(action.payload);
      return{
        ...state,
        selectedRoles: action.payload
      }

    case setStyle.SUCCESS:
      return{
        ...state,
        user: {
          ...state.user,
          proffwebDark: action.payload
        }
      }

    case getLastAlarm.SUCCESS:

      const showAlarmNotification = action.payload && state.lastAlarm !== action.payload && location.hash.indexOf('safemates') == -1;

      return{
        ...state,
        lastAlarm: action.payload,
        showAlarmNotification
      }

    case Actions.SET_SHOW_ALARM_NOTIFICATION:
      return {
        ...state,
        showAlarmNotification: action.payload
      }

    case acceptGdpr.SUCCESS:
      return{
        ...state,
        user: {
          ...state.user,
          gdprPsAccepted: true
        }
      }

    case getBillingFilled.SUCCESS:
      return {
        ...state,
        isBillingFilled: action.payload
      }
    
    case savePrivateBilling.SUCCESS:
      return {
        ...state,
        isBillingFilled: true
      }

    case isAhp.SUCCESS:

      return {
        ...state,
        isAhp: action.payload,
      }

    case isPro.SUCCESS:

      return{
        ...state,
        isPro: action.payload,
      }

    case getGdprManager.SUCCESS:
      return{
        ...state,
        gdprManager: action.payload
      }

    case getRequireNationalId.SUCCESS:
      return{
        ...state,
        requireNationalId: action.payload
      }

    case getPreventPartnerReferenceChange.SUCCESS:
      return{
        ...state,
        preventPartnerReferenceChange: action.payload
      }

    case assignGdprManager.SUCCESS:
      return{
        ...state,
        gdprManager: action.payload
      }

    case removeGdprManager.SUCCESS:
      return{
        ...state,
        gdprManager: {
          userId: 0,
          name: "",
          email: ""
        }
      }
    
    case initializeCustomerHierarchy.SUCCESS:
      return{
        ...state,
        customerHierarchy: action.payload
      }

    default:
      return state;
  }
};


export default app;
