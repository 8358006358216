import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Icon extends React.Component {
  state = {
    hover: false
  };

  onClick = () => {
    const { onClick } = this.props;
    this.setState({ hover: false });
    onClick();
  };

  onKeyDown = event => {
    const { onClick } = this.props;
    if (event.key === 'Enter') {
      this.setState({ hover: false });
      onClick();
    }
  };

  mouseEnter = () => {
    this.setState({ hover: true });
  };

  mouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    const {
      icon,
      small,
      medium,
      mediumSecondary,
      large,
      fa,
      color,
      disabled,
      animate,
      onClick,
      onKeyDown,
      className,
      title,
      removeFocus
    } = this.props;
    const { hover } = this.state;
    const i = fa ? FontAwesomeIcon : icon;
    const props = { className };
    if (fa) props.icon = icon;
    props.onClick = animate ? this.onClick : onClick;
    props.onKeyDown = animate ? this.onKeyDown : onKeyDown;
    props.tabIndex = onClick ? '0' : '-1';
    const SIcon = styled(i)`
      transition: 0.1s;

      &:hover {
        ${!disabled  &&
        `opacity: 1;
        transform: scale(1);`}
      }

      opacity: ${disabled ? 0.3 : 0.8};
      transform: scale(0.8);
      width: ${({ theme: { icons } }) => {
        if (small) return icons.small;
        if (medium) return icons.medium;
        if (mediumSecondary) return icons.large;
        if (large) return '50px';
        return '100px';
      }};
      height: ${({ theme: { icons } }) => {
        if (small) return icons.small;
        if (medium) return icons.medium;
        if (mediumSecondary) return icons.large;
        if (large) return '50px';
        return '100px';
      }};
      color: ${({
        theme: {
          colors: { svgFill }
        }
      }) => color || svgFill};
      cursor: ${disabled ? 'not-allowed' : onClick ? 'pointer' : 'inherit'};
      fill: ${({
        theme: {
          colors: { svgFill }
        }
      }) => svgFill};
    `;
    if (animate) {
      return (
        <div>
          <SIcon {...props} title={title} />
        </div>
      );
    }
    return <SIcon title={title} {...props} />;
  }
}

export default Icon;
