import * as React from "react";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";
import { NewFieldWrapper, NewHeader, StyledField } from ".";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Option} from "@Elements/Dropdown/types";
import Dropdown from "@Elements/Dropdown/Dropdown";
import {DropdownWrapper} from "@Safemate/Settings/Geofence/Controls/share";
import {Customer} from "@Safemate/AddSafemate/Store/types";

interface NewNameProps extends WrappedComponentProps{
  mappedIndoorLocations: Option[]
  customerMap: Map<number, Customer>
}

const NewName = injectIntl(withFormWrapper<GeofenceValues, NewNameProps>(({ intl: { formatMessage }, formik: { setFieldValue, values: { geofence } }, customerMap, mappedIndoorLocations }) => {

  const filterIndoorLocations = (value:string) => {
    const valNum = value.split(',').map(function(item) {
      return parseInt(item, 10);
    });
    setFieldValue("geofence.inloIds", valNum);
  }

  React.useEffect(() => {
  }, [geofence.inloIds, geofence.indoor])


  if( geofence.indoor && mappedIndoorLocations.length > 0 ){

    return (
      <React.Fragment>
        <NewFieldWrapper>
          <NewHeader>{`2. ${formatMessage({id: "nameGeofence", defaultMessage: "Navngi geofence"})}`}</NewHeader>
          <StyledField
            style={{width: "100%"}}
            name="geofence.name"
          />
        </NewFieldWrapper>
        <NewFieldWrapper>
          <NewHeader>{`3. ${formatMessage({id: "selectIndoorForGeofenceHeading", defaultMessage: "Select Wifi Locations"})}`}</NewHeader>
          <DropdownWrapper>
            <Dropdown
              multi
              options={mappedIndoorLocations}
              onChange={option => {
                const value = (option as Option[]).map(item => item.value);
                filterIndoorLocations(value.toString());
                setFieldValue("geofence.enabled", true);
                setFieldValue("geofence.radius", 0);
              }}
              initial={ geofence.inloIds }
              size={{height: "40px"}}
              title={formatMessage({id: "selectIndoorLocationGeofence", defaultMessage: "Select"})}
            />
          </DropdownWrapper>
        </NewFieldWrapper>
      </React.Fragment>

    );
  } else {
    return (
      <NewFieldWrapper>
        <NewHeader>{`2. ${formatMessage({id: "nameGeofence", defaultMessage: "Navngi geofence"})}`}</NewHeader>
        <StyledField
          style={{width: "100%"}}
          name="geofence.name"
        />
      </NewFieldWrapper>
    );
  }
}))

export default NewName;
