import * as React from "react";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Formik } from "formik";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { SettingEnum as ComponentSettingEnum } from "../../settingEnum";
import { BatteryForm } from "../types";
import { DeviceComponents } from "../../tabDefinition";
import { DeviceHardwareEnum, isGx8 } from "../../../DeviceHardwareEnum";
import { ComponentWrapper, Footer, ButtonContainer, StyledForm, SettingWrapper } from "./styled";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../../Elements/Dropdown/types";
import withFormWrapper from "@Elements/Form/formWrapper";
import { CancelButton, SaveButton } from "../../../DefaultSettings/Settings/styled";
import { saveBattery } from "../../Store/routines";
import SettingsHeader from "../header";
import { AppState } from "@Safemate/Store/types";

interface BatteryProps extends WrappedComponentProps{
  dehaId: number;
  deviId: number;
  battery: BatteryForm;
  saveBattery: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ settings: {initialization: { deviId, dehaId }, general: {battery}}}: AppState) => {
  return{
    battery,
    dehaId,
    deviId,
  }
}

const mapDispatchToProps = {
  saveBattery,
}

const Battery = connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(({ dehaId, deviId, intl: {formatMessage}, battery, saveBattery }: BatteryProps) => {

    const components: Map<ComponentSettingEnum, JSX.Element[]> = DeviceComponents.has(dehaId) 
      ? DeviceComponents.get(dehaId)! 
      : DeviceComponents.get(DeviceHardwareEnum.DEFAULT)!;

    const settingEnum = isGx8(dehaId) ? ComponentSettingEnum.GX8_BATTERY : ComponentSettingEnum.BATTERY;
    
    return(
      <Formik
        onSubmit={(values) => {
          saveBattery({
            deviceId: deviId,
            settings: values
          })
        }}
        enableReinitialize
        initialValues={battery}
      >    
        {({dirty, setValues}) => 
        <StyledForm>
          <SettingsHeader 
            title={formatMessage({id: "batteryTreshold", defaultMessage: "Batteriterskel"})}
            tooltip={isGx8(dehaId) ? "batteryAndPowerHeadlineToolTipText" : undefined}
            tooltipTitle={{
              id: "batteryAndPowerSettingsTab",
              defaultMessage: "Batteri- og strøminnstillinger"
            }}
          />
          <SettingWrapper>
              {components.has(settingEnum) 
                && components.get(settingEnum)?.map(comp => {
                  return <ComponentWrapper>{comp}</ComponentWrapper>
              })}
          </SettingWrapper>
          <Footer>
            <ButtonContainer disabled={!dirty}>
              <CancelButton disabled={!dirty} onClick={() => setValues(battery)}>
                {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
              </CancelButton>
              <SaveButton disabled={!dirty} type="submit">
                {formatMessage({id: "save", defaultMessage: "Lagre"})}
              </SaveButton>
            </ButtonContainer>
          </Footer>
        </StyledForm>}
      </Formik>
    )
  })
)

export default Battery;

const batteryThresholdOpt = [
  {text: "10%", value: "10"},
  {text: "20%", value: "20"},
  {text: "30%", value: "30"},
  {text: "40%", value: "40"}
]

export const BatteryThreshold = withFormWrapper<BatteryForm>(({ formik: { values: { batteryThreshold }, setFieldValue } }) => {
  return(
    <Dropdown 
      options={batteryThresholdOpt} 
      onChange={(opt) => {
        const value: number = (opt as Option).value;
        setFieldValue("batteryThreshold", value);
      }}
      initial={batteryThreshold}
    /> 
  )
})