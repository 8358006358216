import React from "react";
import { useIntl } from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import { B, DaysTh, Table } from "./TableElementStyles";
import { intToTime, ModalEnum } from "./utils";
import { RootState } from "../Store/types";
import { connect } from "react-redux";
import { setDeviceEventSetupModal } from "../Store/routines";
import withFormWrapper from "@Elements/Form/formWrapper";
import { EventSetupForm } from "./types";
import confirmModal from "@Safemate/Modals/confirmModal";
import { Checkbox } from "@Elements/CheckboxFormik";
import { ModalBody, ModalSubHeader } from "./ModalStyles";
import { checkCoverage, days } from "./validation";
import { AccentDiv } from "./WrapperStyles";
import { AppState } from "@Safemate/Store/types";

interface CoverageProps{
  modalType: ModalEnum;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ eventSetup: {deviceEventSetup: { modalType }}}: AppState) => {
  return {
    modalType
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal
}

const Coverage = connect(mapStateToProps, mapDispatchToProps)(
  withFormWrapper<EventSetupForm, CoverageProps>(({ formik: { submitForm, values: { eventSetups } }, modalType, setDeviceEventSetupModal }) => {
  
  const { Comp: Modal, func: displayModal } = confirmModal();
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    displayModal(modalType === ModalEnum.COVERAGE);
  }, [modalType])

  const confirm = () => {
    submitForm();
    closeModal();
  }

  const closeModal = () => {
    setDeviceEventSetupModal({
      selectedEventSetup: null,
      modalType: ModalEnum.NONE
    })
  }

  const coverage = checkCoverage(eventSetups, true);

  return(
    <Modal
      buttonAlign="center"
      standardBtn
      disableClose
      cancelFunc={closeModal}
      confirmFunc={confirm}
      confirmText={formatMessage({id: "ok", defaultMessage: "Ok"})}
      title={formatMessage({id: "lackingCoverage", defaultMessage: "Lacking coverage"})}
      body={
        <React.Fragment>
          <ModalSubHeader>
            {formatMessage({id: "coverageDesc", defaultMessage: "Setup incomplete. There are periods where no-one will receive alarm calls. These periods are:"})}
          </ModalSubHeader>
          <React.Fragment>
            {days.map(day => {
              if(coverage[day]){
                return(
                  <React.Fragment>
                    <AccentDiv>{day}</AccentDiv>
                    {coverage[day].map(item => 
                      <div>
                        {intToTime(item.from)} - {intToTime(item.to)}
                      </div>
                    )}
                  </React.Fragment>
                )
              }
            })}
          </React.Fragment>
        </React.Fragment>
      }
    />
  )
}))

export default Coverage;