import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { Customer } from '../types';

function* initialize(action: AppAction<null>){
  try {
    const customers: Customer[] = yield call(Api.getCustomers);
    yield put(Routines.initialize.success(customers));
  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
