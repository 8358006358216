import React from 'react';
import styled from 'styled-components';
import { Grid, Row } from 'react-bootstrap';
import { connect } from 'formik';
import { Role } from './Store/types';
import { useIntl } from 'react-intl';

const MenuItemWrapper = styled.span`
  display: flex;
`;

const StatusWrapper = styled.div<{status: boolean}>`
  width: 18px;
  height: 12px;
  border-radius: 10px;
  background: ${props => (props.status ? '#8ab01c' : 'transparent')};
  border: ${props => (props.status ? 'initial' : '1px solid #acadb1')};
  display: flex;
  align-self: center;
  margin-left: 20px;
`;

const StatusContent = styled.div<{status: boolean}>`
  width: ${props => (props.status ? '10px' : '12px')};
  height: ${props => (props.status ? '10px' : '12px')};
  border-radius: ${props => (props.status ? '10px' : '12px')};
  background: ${props =>
    props.status ? props.theme.colors.backgroundSecondary : 'transparent'};
  border: ${props => (props.status ? 'initial' : '1px solid #acadb1')};
  margin-left: ${props => (props.status ? 'auto' : 'initial')};
  margin-right: 1px;
  align-self: center;
`;

interface MenuItemProps{
  menuKey: string;
  toggle?: boolean;
}

const MenuItem = connect<MenuItemProps, Role>(({ formik: { values, setFieldValue }, menuKey, toggle }) => {
  const toggled = values.policies[menuKey] && values.policies[menuKey].rolePolicies.status;
  const { formatMessage } = useIntl();
  return (
    <MenuItemWrapper>
      {formatMessage({id: `${menuKey}RoleEditor`, defaultMessage: menuKey})}
      {toggle &&
        <StatusWrapper
          status={toggled}
          onClick={() =>
            setFieldValue(`policies.${menuKey}.rolePolicies.status`, !toggled)
          }
        >
          <StatusContent status={toggled} />
        </StatusWrapper>
      }
    </MenuItemWrapper>
  );
});

export default MenuItem;
