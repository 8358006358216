import styled from 'styled-components';

export const Wrapper = styled.div<{disabled?: boolean}>`
  display: inline;

  input {
    background-color: ${props => props.theme.colors.backgroundSecondary};
    color: ${props => props.theme.colors.textPrimary};
    font-size: ${props => props.theme.font.normal};
    width: inherit !important;
    border-radius: inherit !important;
    border: none !important;
  }

  .react-datepicker-wrapper {
    border: ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.colors.borderRadius};
    background-color: ${props => props.theme.colors.backgroundSecondary};
  }
  td {
    &:focus {
      background: rgb(0, 0, 0, 0.5);
      color: white;
    }
  }
`;
