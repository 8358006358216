import {call, put, takeLatest} from 'redux-saga/effects';

import * as Routines from '../routines';
import * as Api from '../api';
import { AppAction } from '@Safemate/Store/types';

function* getPostalCity(action: AppAction<string>){
  try{
    const postalCity = yield call(Api.getPostCity, action.payload);
    yield put(Routines.getPostCity.success(postalCity));
  }
  catch(e){
    yield put(Routines.getPostCity.failure());
  }
}

function* customerSaga(){
  yield takeLatest(Routines.getPostCity.TRIGGER, getPostalCity);
}

export default customerSaga;
