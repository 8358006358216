import * as React from "react";
import {Form, Formik, } from "formik";
import {connect} from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { emptyForm, populateForm} from "./utils";
import {Customer, RootState, MappedSubOrgs, Organization} from "../Store/types";
import {saveCustomer } from "../Store/routines";
import {Footer} from "./footer";

import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { ShippingAddress, Email, AlarmCentral, SubOrgName, OrgNummer, Billing} from "./fields";
import { ParentFirm, MovingSuborg } from "./parentFirm";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import confirmModal from "../../Modals/confirmModal";
import { AppState } from "@Safemate/Store/types";
import { OrganizationForm } from "./types";


const NoPadRow = styled(Row)`
  margin: 0px;
`;

interface FormProps{
  selectedCustomer: Customer;
  parentCustomer: Organization;
  paidByCustomer: Organization;
  saveCustomer: Routine<ActionFunctionAny<Action<any>>>;
  customersForForm: MappedSubOrgs;
  createForm: boolean;
}

const FormComp = ({ selectedCustomer, paidByCustomer, parentCustomer, saveCustomer, customersForForm, createForm }: FormProps) => {
  const {Comp, func} = confirmModal();

  let initialValues: OrganizationForm = {
    activatedDate: 0,
    addrCity: "",
    addrCounId: 0,
    addressLine1: "",
    addressLine2: "",
    addrName: "",
    addrNameForBilling: "",
    addrReference: "",
    addrSamesame: false,
    addrZipCode: "",
    allowAllPhoneNo: false,
    allowDeviceDeletes: false,
    billingAddrCity: "",
    billingAddrCounId: 0,
    billingAddrLine1: "",
    billingAddrLine2: "",
    billingAddrName: "",
    billingAddrReference: "",
    billingAddrZipCode: "",
    billingCode: "",
    company: false,
    custId: 0,
    defaultAhp: 0,
    email: "",
    firstName: "",
    forceCoverage: false,
    forceIncomingRing: false,
    forceIncomingRingAlways: false,
    forceTfa: false,
    integrationId: "",
    integrationRole: "",
    invoiceBalanceExclVat: 0,
    invoiceCreditLimitExclVat: 0,
    lastChanged: 0,
    lastChangedBy: 0,
    lastName: "",
    mobile: "",
    orgNum: "",
    parent: 0,
    prgrId: 0,
    pptyId: "",
    status: 0,
    tempAhp: 0
  };

  const { formatMessage } = useIntl();
  const saveMessage = formatMessage({id: "subUnitSaved", defaultMessage: "Enhet lagret: "});
  const errorParentMessage = formatMessage({id: "errorMoveSuborg", defaultMessage: "Suborg paid by parent org cannot be moved."} );

  const { Comp: NameValidationModal, func: showNameValidationModal } = confirmModal();

  const customer = customersForForm[selectedCustomer.customerId];
  if(customer){
    const customerInfo = customer.firm;
    initialValues = 
      createForm 
        ? emptyForm(selectedCustomer.customerId, customerInfo.addrSamesame)
        : populateForm(customerInfo, customer.paymentProfile ? `${customer.paymentProfile.pptyId}` : "", customerInfo.addrSamesame)
  }
  const validate = (values: OrganizationForm) => {
    if(values.parent != selectedCustomer.parent && values.pptyId == "3" && values.custId != 0){
      func(true);
    }else{
      validateName(values)
    }
  }

  const validateName = (values: OrganizationForm) => {
    (async () => {
      try{
        const response = await fetch(`/ajax/firm/validateOrgName/${values.lastName}`);
        const result: boolean = await response.json();
        console.log(result);
        if(result){
          showNameValidationModal(true);
        }
        else{
          saveCustomer({formValues: values, selectedCustomer, errorParentMessage, saveMessage});
        }
      }
      catch(e){
        console.log(e);
      }
    })()
  }


  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        validate(values);
      }}
    >
      {({values}) => {
        return(
          <Form id="form">
            {createForm && <ParentFirm/>}
            {!createForm && selectedCustomer.parent > 0 && <MovingSuborg/>}
            <SubOrgName custId={selectedCustomer.customerId}/>
            <Email custId={selectedCustomer.customerId}/>
            <OrgNummer custId={selectedCustomer.customerId}/>
            <AlarmCentral/>
            <Billing/>
            <NoPadRow>
              <ShippingAddress custId={selectedCustomer.customerId}/>
            </NoPadRow>
            <Footer/>
            <UnsavedCheck/>
            <Comp
              title={`${formatMessage({id: "moveSuborg", defaultMessage: "Move SubOrg"})}`}
              body={
                <p>
                  <span>{formatMessage({id: "aboutAddSuborg", defaultMessage: "You are about to add suborg"})} {selectedCustomer.customerName}  {formatMessage({id: "under", defaultMessage: "under"})} {customersForForm && customersForForm[values.parent] && customersForForm[values.parent].firm.lastName} {formatMessage({id: "whereSubscrPaid", defaultMessage: "where its subscriptions would have to be paid by"} )} {paidByCustomer.paymentProfile && paidByCustomer.paymentProfile.pptyId != 3 ? paidByCustomer.firm && paidByCustomer.firm.lastName : paidByCustomer.paymentProfile && paidByCustomer.paymentProfile.paymentDetail } </span>
                  
                  <br/>
                  <br/>
                  <span>{formatMessage({id: "wantToContinue", defaultMessage: "Do you want to continue?"} )}</span>
                </p>
              }
              confirmText={formatMessage({id: "ok", defaultMessage: "OK"})}
              confirmFunc={() => {
                validateName(values)
                func(false);
              }}
            />
            <NameValidationModal
              title=""
              standardBtn
              confirmText={formatMessage({id: "yes", defaultMessage: "Yes"})}
              body={
                <React.Fragment>
                <span>{`${values.lastName} ${formatMessage({id: "duplicateCompanyName", defaultMessage: "already exists and is in active use. Are you sure you want to create another instance of"})} ${values.lastName}?`}</span>
                <br/>
                <span><a target="_blank" href="https://servicedesk.sensio.no/nb-NO/support/home">{formatMessage({id: "toSupport", defaultMessage: "To support"})}</a></span>
                </React.Fragment>
              }
              confirmFunc={() => {
                showNameValidationModal(false)
                saveCustomer({formValues: values, selectedCustomer, errorParentMessage, saveMessage});
              }}
            />
          </Form>
        )
      }}

    </Formik>
  )
}

const mapStateToProps = ({ subUnits: {customer: {customersForForm, selectedCustomer, parentCustomer, paidByCustomer, createForm}}}: AppState) => {
  return{
    selectedCustomer,
    parentCustomer,
    paidByCustomer,
    customersForForm,
    createForm,
  }
};

const mapDispatchToProps = {
  saveCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComp);
