import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from "react-redux";

import { updateAutoTracking, updatePowerSaving } from "../../Store/routines";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import { LabelWrapper, RowLabel, ToggleWrap, RowWrapper, PaddedCol } from "../TrackingOptions";
import {Info, LoaderWrap} from "..";
import withFormWrapper from "@Elements/Form/formWrapper";
import { TrackingSettings } from "../../types";
import Tooltip from "@Elements/Tooltip";
import Loader from "@Elements/Loader";
import Toggle from "@Elements/Switch/Toggle";
import { AppState } from "@Safemate/Store/types";



interface AutoToggleProps extends WrappedComponentProps {
  updateAutoTracking: Routine<ActionFunctionAny<Action<any>>>;
  updatePowerSaving: Routine<ActionFunctionAny<Action<any>>>;
  autoTracking: boolean;
  processingAutoTracking: boolean;
  disablePositioning: boolean;
  deviId: number;
}

const AutoToggle = injectIntl(withFormWrapper<TrackingSettings, AutoToggleProps>(({ intl: {formatMessage}, updateAutoTracking, autoTracking, updatePowerSaving, disablePositioning, processingAutoTracking, deviId, formik:{setFieldValue, values} }) => {
  return(
    <RowWrapper>
      <LabelWrapper xs={12} sm={5} md={5}>
        <RowLabel>      
          {formatMessage({id: "automaticTracking", defaultMessage: "Automatisk lokalisering"})}
        </RowLabel>
        <Tooltip
          popoverId="auto-tracking-popover"
          labelId="autoTrackingDesc"
          defaultMessage="Enheten vil forsøke å lokalisere iht. angitt lokaliseringsintervall mens Safematen er i bevegelse. Når Safematen er i ro, vil den spare batterikapasitet ved å skru av lokaliseringen."
        >
          <InformationIcon size="medium" />
        </Tooltip> 
      </LabelWrapper>
      <PaddedCol sm={7} md={7}>
      {disablePositioning 
        ? <Info>{formatMessage({id: "autoTrackingInactive", defaultMessage: "Automatisk lokalisering er ikke tilgjengelig når posisjonering er avskrudd."})}</Info>
        : processingAutoTracking ? <LoaderWrap><Loader/></LoaderWrap>
          : <ToggleWrap>
              <Toggle
                onChange={(value: boolean) => {
                  updateAutoTracking(value);
                  setFieldValue("autoTracking", value);
                  if(!value){
                    setFieldValue("powerSaving", false);
                    updatePowerSaving(false);
                  }
                }}
                checked={autoTracking}
              />
            </ToggleWrap>}
      </PaddedCol>
    </RowWrapper>
  )
}))

const mapStateToProps = ({ settings: {tracking: { autoTracking, disablePositioning, processingAutoTracking, powerSaving }, device: {device: {deviId}}}}: AppState) => {
  return {
    autoTracking,
    disablePositioning,
    processingAutoTracking,
    deviId,
    powerSaving
  }
}

const mapDispatchToProps = {
  updateAutoTracking,
  updatePowerSaving
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoToggle);