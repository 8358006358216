import {InitializationState} from "../types";
import {Reducer} from "redux";
import {initialize, initializeUser, saveInactivity, saveEventConfig} from "../routines";
import { getLayout } from "../../LayoutEnum";
import { SettingsActionTypes } from "../actionTypes";

const initializationState: InitializationState = {
  initialized: false,
  layout: getLayout(window.innerWidth),
  roles: "",
  deviId: 0,
  dehaId: 0,
  user: {
    userId: 0,
    username: "",
    password: "",
    passwordClear: "",
    firstName: "",
    lastName: "",
    mobile: "",
    mailTo: "",
    languageCode: "",
    timezone: "",
    facebookUid: "",
    newsletter: false,
    proffwebDark: false,
    adminForCustId: 0,
    beta: false,
    trainingApprovedBy: 0,
    deleted: false,
    attemptsBeforeLockout: "",
    lastActivity: 0,
    tfaEmail: false,
    tfaEnabled: false,
    gdprPsAccepted: false,
    gdprPsDatetime: "",
    deactivated: false,
    tempDeactivated: false,
    deactivatedFrom: "",
    deactivatedTo: "",
    userType: 0,
    name: "",
    id: 0,
    timeZoneOffset: 0,
    active: false,
    trainingApprovedByName: "",
    coveredByTwoFactorAuthentication: false,
    email: "",
    existing: false,
    isNewUser: false,
    trained: false,
    zoneId: "",
    isExternal: false,
    emailAsUsername: false,
    translator: false
  },
  saving: false,
  supportDeviceMac: false
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action) => {
  switch (action.type) {

    /*case SettingsActionTypes.CLEANUP:
      return {
        ...initializationState
      }*/

    case initialize.SUCCESS:
      return {
        ...state,
        initialized: true,
        roles: action.payload.roles,
        deviId: action.payload.deviceId,
        dehaId: action.payload.dehaId,
        supportDeviceMac: action.payload.supportDeviceMac
      };

    case initialize.TRIGGER:
      return{
        ...state,
        deviId: action.payload.deviceId,
        dehaId: action.payload.dehaId
      }

    case initializeUser.SUCCESS:
      return {
        ...state,
        user: action.payload
      };

    case SettingsActionTypes.SET_LAYOUT:
      return {
        ...state,
        layout: action.payload
      }

    case saveInactivity.TRIGGER:
    case saveEventConfig.TRIGGER:
      return {
        ...state,
        saving: true
      }
    case saveInactivity.SUCCESS:
    case saveEventConfig.SUCCESS:
      return {
        ...state,
        saving: false
      }

    default:
      return state;
  }
};

export default initialization;
