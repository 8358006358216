import {CustomerState, Customer} from "../types";
import {Reducer} from "redux";
import {initializeSubOrgs, selectCustomer, initializeBillingOptions, getPostCity, getMarketProgId} from "../routines";

const customerState: CustomerState = {
  selectedCustomer: {
    customerId: 0,
    customerName: "",
    defaultAhp: 0,
    level: 0,
    parent: 0,
    policies: {
      custId: 0,
      global: false,
      id: 0,
      policies: {},
      roleName: "",
      technicianRights: false
    },
    paymentProfile: {
      pptyId: 0,
      paymentDetail: "",
      paymentProfileType: {
        name: "",
      }
    },
    landCode: "",
    invoiceCode: "",
  },
  billingInfo: {
    filteredBillingOptions: [],
    isBillAddrFilled: false,
  },
  customers: [],
  createForm: false,
  selectedRootParent: true,
  postCity: {
    postalCodeType: "",
    result: "",
    valid: false
  }
};

const customer: Reducer<CustomerState> = (state: CustomerState = customerState, action: any) => {
  switch (action.type) {
    case selectCustomer.SUCCESS:
      return {
          ...state,
        selectedCustomer: action.payload,
      };

    case initializeSubOrgs.SUCCESS:
      const customerArr: Customer[] = action.payload;
      return{
        ...state,
        customers: action.payload,
        selectedCustomer: customerArr[0],
        selectedRootParent: true,
      }

    case initializeBillingOptions.SUCCESS:
      return {
        ...state,
        billingInfo: action.payload
        };

    case getPostCity.SUCCESS:
      return {
        ...state,
        postCity: action.payload
        };
    case getMarketProgId.SUCCESS:
        return {
          ...state,
          marketProgId: action.payload
        };

    default:
        return state;
  }
  
};

export default customer;
