import { combineReducers } from 'redux';
import {RootState} from "../types";
import user from "./user";
import initialization from "./initialization";
import role from "./role";
import invoices from "./invoices";
import feedback from "../../../Feedback/reducer";

export default combineReducers<RootState>({
  user,
  initialization,
  role,
  feedback,
  invoices,
});
