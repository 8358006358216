import * as React from "react";
import styled from 'styled-components';
import { Button } from "react-bootstrap";

import Log from "./log";
import EventSetupAction from './eventSetup';
import SettingsAction from './settings';
import { AllPermissions, GeneralSettingsPolicies, PolicyContext } from '../../../Policy/Provider';
import { PolicyNameEnum } from '../../../PolicyNameEnum';
import TrackerPhone from './trackerPhone';
import Track from './track';
import Photo from './photo';
import { DeviceHardwareEnum } from "../../../DeviceHardwareEnum";
import { AppState } from "@Safemate/Store/types";
import { connect } from "react-redux";
import { IDevice } from "@Safemate/Model/Device";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";

interface ActionsProps{
  device: IDevice;
  isTrackerOnly: boolean;
  ahpContext: boolean;
}

export const ModalContent = styled.p`
  width: 100%;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  background: ${props => props.theme.colors.backgroundSecondary};
  border: ${props => props.theme.colors.border};
  border-radius: 3px;
  line-height: 42px;
  width: 55px;
  height: 40px;
  padding: 0;
  margin: 0 2.5px;

  svg {
    height: 26px;
    width: 26px;
  }

  &:hover {
    background: ${props => props.theme.colors.accent};
  }
  @media (max-width: 550px) {
		margin: 0 2px;
  }
  
  @media (max-width: 360px) {
		margin: 0 0.5px;
	}
`

export const StyledButtonMob = styled(Button)`
  background: ${props => props.theme.colors.backgroundSecondary};
  border: ${props => props.theme.colors.border};
  border-radius: 3px;
  line-height: 42px;
  width: 55px;
  height: 40px;
  padding: 0;
  margin: 0 2.5px;

  svg {
    height: 26px;
    width: 26px;
  }

  &:hover {
    background: ${props => props.theme.colors.accent};
  }
  @media (max-width: 550px) {
		margin: 0 2px;
    width: 45px;
  }
`

const ActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Placeholder = styled.div`
  border: none;
  background: none;
  border-radius: 3px;
  line-height: 42px;
  width: 55px;
  height: 40px;
  padding: 0;
  margin: 0 2.5px;
`;

const mapStateToProps = ({ appData: { isTrackerOnly, currentContext } }: AppState) => {
  return {
    isTrackerOnly,
    ahpContext: currentContext === SiteTypeEnum.AHP
  }
}

const Actions = connect(mapStateToProps)(({isTrackerOnly, ahpContext, device: {deviceTwin, safemateAsReceiver, lastMsgIn ,deviceName, deviceSNNum, deviId, dehaId, ownedByCustId, formattedPhoneNumE164}}: ActionsProps) => {

  const isTracker = isTrackerOnly;

  const isClone = deviceTwin && deviceTwin.deviId === deviId;
  
  if(ahpContext){
    return(
      <ActionWrap>
        <Log isTracker={isTracker} lastMsgIn={lastMsgIn} deviceId={deviId} deviceHardware={dehaId}/>
        {!isClone && <EventSetupAction deviceId={deviId}/>}
        <SettingsAction deviceId={deviId} deviceHardware={dehaId}/>
      </ActionWrap>
    )
  }

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  const eventSetupAccess = permissions.get(PolicyNameEnum.EVENT_SETUP).customerPermissions[ownedByCustId].all;
  const logAccess = permissions.get(PolicyNameEnum.DEVICE_ACCESS_MAP).customerPermissions[ownedByCustId].all || permissions.get(PolicyNameEnum.DEVICE_TRANSACTION_LOG).customerPermissions[ownedByCustId].all;
  const settingsAccess = permissions.get(PolicyNameEnum.DEVICE_SETTINGS).customerPermissions[ownedByCustId].all
  const generalSettingsAccess = permissions.get(PolicyNameEnum.GENERAL_SETTINGS).customerPermissions[ownedByCustId].all;

  let showSettings = settingsAccess;

  if(!settingsAccess && generalSettingsAccess){
    GeneralSettingsPolicies.forEach(policy => {
      if(permissions.get(policy).customerPermissions[ownedByCustId].all){
        showSettings = true;
      }
    })
  }

  if(isTracker){
    return(
      <ActionWrap>
        <TrackerPhone phoneNumber={formattedPhoneNumE164}/>
        <Log isTracker={isTracker} lastMsgIn={lastMsgIn} deviceId={deviId} deviceHardware={dehaId}/>
        <Track deviceName={deviceName} deviceSNNum={deviceSNNum} deviceId={deviId} deviceHardware={dehaId}/>
        <Photo deviceName={deviceName} deviceSNNum={deviceSNNum} deviceId={deviId}/>
      </ActionWrap>
    )  
  }
  
  const displayEventSetup = ((safemateAsReceiver && dehaId === DeviceHardwareEnum.SAFEMATE_HOME ) || dehaId != DeviceHardwareEnum.SAFEMATE_HOME) && eventSetupAccess;

  return(
    <ActionWrap>
      {logAccess  && <Log isTracker={isTracker} lastMsgIn={lastMsgIn} deviceId={deviId} deviceHardware={dehaId}/>}
      {displayEventSetup && (!isClone ? <EventSetupAction deviceId={deviId}/> : <Placeholder/>)}
      {showSettings && <SettingsAction deviceId={deviId} deviceHardware={dehaId}/>}
    </ActionWrap>
  )
})

export default Actions;
