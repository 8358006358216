import * as React from "react";
import { injectIntl, WrappedComponentProps} from "react-intl";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {RootState} from "../Store/types";
import {Col,  Row, Button} from "react-bootstrap";
import {  DeviceInformation} from "../Store/types";
import BillingModal from "./billingModal";
import {  CheckWrapPrivateTab } from "../../Settings/Components/checkbox";
import withFormWrapper from "@Elements/Form/formWrapper";
import {Action, ActionFunctionAny} from "redux-actions";
import { setBillingSettings, deleteSafemate} from "../Store/routines";
import Modal from "react-modal";
import styled, { withTheme, ThemeProps } from "styled-components";
import { ITheme } from "../../../../themes/types";
import { AppState } from "@Safemate/Store/types";


const APointer = styled.a`
    cursor:pointer;
    padding-left: 15px;
    font-weight: 500;
`;

const StyledA = styled.a`
    cursor:pointer;
    font-weight: 500;
`;

const StyledH4 = styled.h4`
    padding-left: 0px;
    font-weight: 400;
`;

const StyledH4PerMonth = styled.h4`
    padding-left: 0px;
    font-weight: 400;
    margin-left: -8px;
`;

const StyledAllVat = styled.h4`
    font-weight: 400;
    @media (max-width: 991px) {
        padding-left: 0px;
        margin-left: -8px;
    }
    @media (min-width: 992px) {
        margin-left: 50px;
    }
    @media (min-width: 1200px) {
        margin-left: 83px;
    }
`;

const StyledDiv = styled(Col)`
    font-weight: bold;
`;

const StyledDivPerMonth = styled(Col)`
    font-weight: bold;
    margin-left: -8px;
`;

const TotalCol = styled(Col)`
    width: auto;
    float: right;
`;
const StyledCol = styled(Col)`
    padding-left: 25px;
    padding-bottom: 10px;
`;

const StyledColPadTop = styled(Col)`
    margin-top: 15px;
`;

const StyledWrapCol = styled(Col)`
    width:90%;
`;

const StyledColModalSub = styled(Col)`
    border: 1px solid grey;
    padding: 15px;
    margin-bottom: 20px;
`;

const StyledRowPerMonth = styled(Row)`
    padding-top: 10px;
    width: 100%;
    border-top: 2px dotted;
    margin-top: 25px;
    margin-left: -8px;
`;

const StyledRow = styled(Row)`
    width: 100%;
`;

const StyledRowPadT = styled(Row)`
    padding-top:5px;
`;

const StyledRowAHP = styled(Row)`
    width: 100%;
    padding-bottom: 15px;
`;

const LogoContainer = styled.div`
  background: url("../../../proffweb/dist/gfx/logogologin.png") center center no-repeat;
  height: 100px;
  width: 100%;
`;


const Wrap = styled.div`
  padding-left: 15px;
  padding-bottom: 20px;
`;

export const modalStyles = {
    content: {
      width: "80%",
      height: "fit-content",
      position: "unset",
    },
    overlay:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }
  }
  
  const ModalTitle = styled.div`
    padding: 15px;
  `;
  
  const ModalBody = styled.div`
    padding: 0 30px;
  `;
  
  const ModalFooter = styled.div`
    margin: 20px 15px;
    padding: 15px;
    text-align: 
    right;
  `;
  
  
  const ConfirmBtn = styled(Button)`
    background: #d60403;
    color: white;
    width: 100%;
    margin: 15px auto 0px;
    display: block;
    max-width:460px;
    font-size: 18px!important;
    height: 40px;
    margin-right: 10px;
  `;
  
   const CancelButton = styled(Button)`
    color: white;
    background-color: #f7ae11;
    width: 100%;
    max-width: 460px;
    margin: 0 auto;
    display: block;
    font-size: 18px!important;
    height: 40px;
    margin-right: 15px;
  `;

  const CancelBillingButton = styled(Button)`
    margin-right: 10px;
    float: right;
`;

  const SubmitButton = styled(Button)`
    float: right;
  `;

  


  interface ConfirmModalProps extends ThemeProps<ITheme>, WrappedComponentProps{
    title: string;
    body: string | JSX.Element;
    confirmText: string;
    confirmFunc: Function;
  }

const AccentedText = styled.span`
  color: ${props => props.theme.colors.accent};
`;

interface ListProps extends WrappedComponentProps{
  deviceInfo: Array<DeviceInformation>;
  setBillingSettings: Routine<ActionFunctionAny<Action<any>>>;
  deleteSafemate: Routine<ActionFunctionAny<Action<any>>>;
  paymentCurrency: string;
}
  
const mapStateToPropsZip = ( { myPageBilling: {settings:{deviceInfo}, initialization:{paymentCurrency}}} : AppState) => {
    return{
        deviceInfo,
        paymentCurrency
    }
};

const mapDispatchToProps = {
    setBillingSettings,
    deleteSafemate
  }

  
const List = connect(mapStateToPropsZip, mapDispatchToProps)(withFormWrapper(injectIntl(({intl: {formatMessage}, paymentCurrency, deviceInfo, setBillingSettings, deleteSafemate}: ListProps) => {


    let iniVals;
    let monthlyCost = 0;
    const [ showBilling, setShowBilling ] = React.useState(false);
    const [ deleteAllDevices, setDeleteAllDevices ] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [deleteDeviceIndex, setDeleteDeviceIndex] = React.useState(0);


    const connectedDevices: number[] = [];
    const connectedAHP: number[] = [];

    let [ connectedDevicesCheck, setConnectedDevicesCheck ] = React.useState(
        new Array(deviceInfo.length).fill(false)
    );
    let [ connectedAHPCheck, setConnectedAHPCheck ] = React.useState(
        new Array(deviceInfo.length).fill(false)
    );

    let [ cdcCopy, setCdcCopy ] = React.useState(
        new Array(deviceInfo.length).fill(false)
    );
    let [ cacCopy, setCacCopy ] = React.useState(
        new Array(deviceInfo.length).fill(false)
    );

    let [ deletedDevicesId, setDeletedDevicesId ] = React.useState(
        new Array(deviceInfo.length).fill(0)
    );

    let manualDirty = false;

    

    const confirmModal = () => {
        const item = deviceInfo[deleteDeviceIndex];
        const [ open, setOpen ] = React.useState(false);
        Modal.setAppElement("#react");
      
        return{Comp: injectIntl(withTheme(({theme, intl: {formatMessage}}: ConfirmModalProps) => (
          <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            style={
              {
                content: {
                  width: "500px",
                  height: "fit-content",
                  position: "unset",
                  background: theme.colors.backgroundPrimary,
                  color: "",
                },
                overlay:{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }
              }
            }
          >
            <ModalTitle>
              <h2>{`${formatMessage({id: "deleteUnit", defaultMessage: "Slett"})} ${formatMessage({id: "unit", defaultMessage: "Enhet"})}`}</h2>
            </ModalTitle>
            <ModalBody>
                <p> 
                    <span>{formatMessage({id: "deleteUnitDesc", defaultMessage: "Vil du virkelig slette enhet"})}</span>
                    <AccentedText>{` ${item.deviceName} (${item.deviceSNNum})?`}</AccentedText>
                </p>
            </ModalBody>
            <ModalFooter>
              <CancelButton
                onClick={() => setOpen(false)}>
                {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
              </CancelButton>
              <ConfirmBtn onClick={() => {
                setDeletedDevicesId(deletedDevicesId => [...deletedDevicesId, deviceInfo[deleteDeviceIndex].deviceId]);
                handleDeviceDelete(deleteDeviceIndex, item);
                const deviceId = deviceInfo[deleteDeviceIndex].deviceId
                deleteSafemate({deviceId});
                func(false);
              }}>
                {formatMessage({id: "delete", defaultMessage: "Slette"})}
              </ConfirmBtn>
            </ModalFooter>
          </Modal>
        ))), func: setOpen}
      }
      let {Comp, func} = confirmModal();


      const deleteAllModal = () => {
        const item = deviceInfo[deleteDeviceIndex];
        const [ open, setOpen ] = React.useState(false);
        Modal.setAppElement("#react");
      
        return{DeleteAll: injectIntl(withTheme(({theme, intl: {formatMessage}}: ConfirmModalProps) => (
          <Modal
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            style={
              {
                content: {
                  width: "500px",
                  height: "fit-content",
                  position: "unset",
                  background: theme.colors.backgroundPrimary,
                  color: "",
                },
                overlay:{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }
              }
            }
          >
            <ModalTitle>
              <h2>{formatMessage({id: "cancelAllSubscriptions", defaultMessage: "Slett mine abonnement"})}</h2>
            </ModalTitle>
            <ModalBody>
                <p> 
                    <span>{formatMessage({id: "areYouSure", defaultMessage: "Vil du virkelig"})} {formatMessage({id: "cancelAllSubscriptions", defaultMessage: "Slett mine abonnement"})}</span>
                </p>
            </ModalBody>
            <ModalFooter>
              <CancelButton
                onClick={() => {
                    setOpen(false)}}>
                {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
              </CancelButton>
              <ConfirmBtn onClick={() => {
                   handleDeviceDeleteAll();
                   deleteFunc(false);
              }}>
                {formatMessage({id: "delete", defaultMessage: "Slette"})}
              </ConfirmBtn>
            </ModalFooter>
          </Modal>
        ))), deleteFunc: setOpen}
      }
      let {DeleteAll, deleteFunc} = deleteAllModal();



    React.useEffect(() => {
        setTotal(0);
        let newTotal = 0;
        if(deviceInfo.length > 0 ){
            connectedDevicesCheck = new Array(deviceInfo.length).fill(false);
            setConnectedDevicesCheck(connectedDevicesCheck);
            setCdcCopy(connectedDevicesCheck);

            connectedAHPCheck = new Array(deviceInfo.length).fill(false);
            setConnectedAHPCheck(connectedAHPCheck);
            setCacCopy(connectedAHPCheck);

        } 
        deviceInfo.map((item, i) => {
            if(item.ahpId != null && item.ahpId > 0){
                connectedAHPCheck.map((item, index) => {
                        if(index === i ){
                            setCacCopy(connectedAHPCheck => {
                                let newState = [...connectedAHPCheck];
                                newState[i] = !connectedAHPCheck[i];
                                return newState;
                            })
                            setConnectedAHPCheck(connectedAHPCheck => {
                                let newState = [...connectedAHPCheck];
                                newState[i] = !connectedAHPCheck[i];
                                return newState;
                            })
                        }
                    }
                );
                connectedAHP.push(item.deviceId);
                newTotal += item.ahpPriceInclVat;
            }
            if(item.status == 1){
                connectedDevicesCheck.map((item, index) => {
                    if(index ===i){
                        setConnectedDevicesCheck(connectedDevicesCheck => {
                            let newState = [...connectedDevicesCheck];
                            newState[i] = !connectedDevicesCheck[i];
                            return newState;
                        })
                        setCdcCopy(connectedDevicesCheck => {
                            let newState = [...connectedDevicesCheck];
                            newState[i] = !connectedDevicesCheck[i];
                            return newState;
                        })
                    }
                }
                );
                connectedDevices.push(item.deviceId);
                newTotal += item.priceInclVat;
            }
        })
        setTotal(newTotal);
    }, [deviceInfo])

    

    const handleOnChangeDevice = (position:number, item:DeviceInformation) => {
        let newTotal = total;
        const updatedConnectedDevicesCheck = connectedDevicesCheck.map((item, index) =>{
            if(index === position){
                if(!item){
                    newTotal = total + deviceInfo[position].priceInclVat;
                    return !item;
                }else{
                    setDeleteDeviceIndex(position);
                    func(true);
                    return item;
                }
            }else{
                return item;
            }
        });
        setTotal(newTotal);
        setConnectedDevicesCheck(updatedConnectedDevicesCheck);
        connectedDevices.push(item.deviceId);
    };

    
    const handleOnChangeAHP = (position:number, item:DeviceInformation) => {
        let newTotal = total;
        const updatedConnectedAHPCheck = connectedAHPCheck.map((item, index) =>{
            if(index === position){
                if(!item){
                    newTotal += deviceInfo[position].ahpPriceInclVat;
                }else{
                    newTotal -= deviceInfo[position].ahpPriceInclVat;
                }
                return !item;
            }else{
                return item;
            }
        });
        setConnectedAHPCheck(updatedConnectedAHPCheck);
        connectedAHP.push(item.deviceId);
        if(!connectedDevicesCheck[position]){
            const updatedConnectedDevicesCheck = connectedDevicesCheck.map((item, index) =>{
                if(index === position){
                    if(!item){
                        newTotal += deviceInfo[position].priceInclVat;
                        return !item;
                    }else{
                        setDeleteDeviceIndex(position);
                        func(true);
                        return item;
                    }
                }else{
                    return item;
                }
            });
            setConnectedDevicesCheck(updatedConnectedDevicesCheck);
            connectedDevices.push(item.deviceId);
        }
        setTotal(newTotal);

    };

    const handleDeviceDelete = (position:number, item:DeviceInformation) => {
        let newTotal = total;
        const updatedConnectedDevicesCheck = connectedDevicesCheck.map((item, index) =>{
            if(index === position){
                newTotal -= deviceInfo[position].priceInclVat;
                return !item;
            }else{
                return item;
            }
        });
        const updatedCDCCopy = cdcCopy.map((item, index) =>{
            if(index === position){
                return !item;
            }else{
                return item;
            }
        });
        setCdcCopy(updatedCDCCopy);
        setConnectedDevicesCheck(updatedConnectedDevicesCheck);
        connectedDevices.push(item.deviceId);

        const updatedConnectedAHPCheck = connectedAHPCheck.map((item, index) =>{
            if(index === position){
                if(!item){
                    return item;
                }else{
                    newTotal -= deviceInfo[position].ahpPriceInclVat;
                    return !item;
                }
            }else{
                return item;
            }
        });
        const updatedCACCopy =  cacCopy.map((item, index) =>{
            if(index === position){
                if(!item){
                    return item;
                }else{
                    return !item;
                }
            }else{
                return item;
            }
        });
        setTotal(newTotal);
        setCacCopy(updatedCACCopy);
        setConnectedAHPCheck(updatedConnectedAHPCheck);
        connectedAHP.push(item.deviceId);
    };

    const handleDeviceDeleteAll = () => {
        let newTotal = total;
        const updatedConnectedDevicesCheck = connectedDevicesCheck.map((item, index) =>{
            if(item){
                newTotal -= deviceInfo[index].priceInclVat;
                return !item;
            }else{
                return item;
            }
        });
        setConnectedDevicesCheck(updatedConnectedDevicesCheck);

        const updatedConnectedAHPCheck = connectedAHPCheck.map((item, index) =>{
                if(!item){
                    return item;
                }else{
                    newTotal -= deviceInfo[index].ahpPriceInclVat;
                    return !item;
                }
        });
        setTotal(newTotal);
        setConnectedAHPCheck(updatedConnectedAHPCheck);
        setDeleteAllDevices(true);
    };

    React.useEffect(() => {
        if(deleteAllDevices){
            setDeleteAllDevices(false);
            setShowBilling(false);
            setBillingSettings({deviceInfo, connectedDevicesCheck, connectedAHPCheck});
        }
    }, [connectedAHPCheck && connectedDevicesCheck])

    
    const handleSubmit = () => {
        setShowBilling(false);
        setBillingSettings({deviceInfo, connectedDevicesCheck, connectedAHPCheck});
    }

    if((!arrayEquals(cdcCopy, connectedDevicesCheck))||(!arrayEquals(cacCopy, connectedAHPCheck))){
        manualDirty = true;
    }else{
        manualDirty = false;
    }

    function arrayEquals(a:any, b:any) {
        return Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index]);
    }


  return(
    <StyledWrapCol >
    <Col className="privateTabSection">
        <LogoContainer/>
      {deviceInfo.map((item, index) => {
          if(item.ahpId != null && item.ahpId > 0){
              monthlyCost += item.ahpPriceInclVat;
          }
          if(item.status == 1){
            monthlyCost += item.priceInclVat;
          }
          if(deletedDevicesId != null && !deletedDevicesId.includes(item.deviceId) || deletedDevicesId == null){
          return(
            <React.Fragment>
            <Wrap>
                <Col>
                    <StyledH4>{item.deviceName}</StyledH4>
                </Col>
                <StyledRow>
                    <StyledCol md={8}>
                        {formatMessage({id: "monthlySubscription", defaultMessage: "Månedsabonnement"})}
                    </StyledCol>
                    <StyledDiv md={4}>
                        {item.priceInclVat.toFixed(2).replace(".", ",")}
                    </StyledDiv>
                </StyledRow>
                {item.ahpId != null && item.ahpId > 0 &&
                    <StyledRowAHP>
                        <StyledCol md={8}>
                            {formatMessage({id: "247respons", defaultMessage: "24/7 respons"})}
                        </StyledCol> 
                        <StyledDiv md={4}>   
                            {item.ahpPriceInclVat.toFixed(2).replace(".", ",")}
                        </StyledDiv>                 
                    </StyledRowAHP>
                } 
            </Wrap>

            </React.Fragment>
            )}
      }

      )}
            <Wrap  className="privateTabSection">
                <StyledRowPerMonth>
                <Col md={8}>
                    <StyledH4PerMonth>{formatMessage({id: "perMonth", defaultMessage: "Per måned"})}</StyledH4PerMonth>
                </Col> 
                <StyledDivPerMonth md={4}>   
                    {total.toFixed(2).replace(".", ",")}
                </StyledDivPerMonth> 
                <Col md={12}>
                <StyledAllVat>{formatMessage({id: "allPricesIncludeVAT", defaultMessage: "Alle priser er inkludert mva."})}</StyledAllVat>    
                </Col>                
                </StyledRowPerMonth>
            </Wrap>
        </Col>
        {deviceInfo.length > 0 && <StyledColPadTop>
            <APointer onClick={() => setShowBilling(true)}>
            {formatMessage({id: "changeSubscription", defaultMessage: "Endre abonnement"})}
            </APointer>
        </StyledColPadTop>}
        <BillingModal 
            isOpen={showBilling}
            title={formatMessage({id: "changeSubscription", defaultMessage: "Endre abonnement"})}
            content={<Col>{deviceInfo.map((itemCheck, i) => {
                const Check = CheckWrapPrivateTab<ListProps>(i, connectedDevicesCheck, handleOnChangeDevice, itemCheck);
                const CheckAHP = CheckWrapPrivateTab<ListProps>(i, connectedAHPCheck, handleOnChangeAHP, itemCheck);
                if(deletedDevicesId != null && !deletedDevicesId.includes(itemCheck.deviceId) || deletedDevicesId == null){
                    return(
                        <StyledColModalSub>
                            <Col> <StyledH4>{itemCheck.deviceName} </StyledH4></Col>
                            <Row>
                                <Col md={10}>
                                    <Check/>
                                    {formatMessage({id: "monthlySubscription", defaultMessage: "Månedsabonnement"})}
                                </Col>
                                <Col md={2}>
                                    {itemCheck.priceInclVat.toFixed(2).replace(".", ",")}
                                </Col>
                            </Row>
                            <StyledRowPadT>
                                <Col md={10}>
                                    <CheckAHP/>
                                    {formatMessage({id: "247respons", defaultMessage: "24/7 respons"})}
                                </Col> 
                                <Col md={2}>   
                                    {itemCheck.ahpPriceInclVat.toFixed(2).replace(".", ",")}
                                </Col>                 
                            </StyledRowPadT>
                        </StyledColModalSub>)}
                })}
                        <Comp/>
                        <DeleteAll/>
                        <Row>
                            <Col md={10}>
                            </Col>
                            <TotalCol md={2}>
                                {formatMessage({id: "total", defaultMessage: "Totalt"})} {total.toFixed(2).replace(".", ",")} {paymentCurrency}
                            </TotalCol>
                        </Row>

                    </Col>}
            footer={
            <Row>
                <Col md={6}>
                    <StyledA onClick={() => deleteFunc(true)}>
                        {formatMessage({id: "cancelAllSubscriptions", defaultMessage: "Slett mine abonnement"})}
                    </StyledA>
                </Col>
                <Col md={6}>
                    <SubmitButton
                        disabled={!manualDirty}
                        onClick={handleSubmit}
                    >
                    {formatMessage({id: "updateSubscriptions", defaultMessage: "OPPDATER ABONNEMENT"})}
                    </SubmitButton>
                    <CancelBillingButton
                        onClick={() => {
                            setConnectedDevicesCheck(cdcCopy);
                            setConnectedAHPCheck(cacCopy);
                            setShowBilling(false)}}
                    >
                    {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
                    </CancelBillingButton>
                </Col>
            </Row>
            
            }
        /> 
    </StyledWrapCol>
  )
})));

export default List;
