import {Reducer} from "redux";
import {InitializationState} from "../types";
import * as Routines from "../routines";
import { SiteTypeEnum } from "../../../SiteTypeEnum";

const initializationState: InitializationState = {
  customersInitialized: false,
  siteType: SiteTypeEnum.PROFFWEB
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action: any) => {
  switch (action.type) {
    case Routines.filterPartners.SUCCESS:
      return {
        ...state,
        customersInitialized: true
      };
    case Routines.setCacheValues.SUCCESS:
      return {
        ...state,
        customersInitialized: true
      };
    case Routines.initialize.SUCCESS:
      return{
        ...state,
        siteType: action.payload
      }

    default:
      return state;
  }
};

export default initialization;
