import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from 'react-bootstrap';

export const NextOfKinCheckWrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;
  }

  margin-right: 7.5px;

  span {
    transition: background 0.1s !important;
  }
`;

export const NextOfKinDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NextOfKinDivSvg = styled.div`
  fill: gray;
  float: initial;

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const EventAlarm = styled.div<{selected?: boolean, color?: boolean}>`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;

  svg,
  path {
    fill: ${props =>
      props.color
        ? props.color
        : props.selected
        ? props.theme.colors.backgroundPrimary
        : props.theme.colors.textPrimary};
  }

  @media (max-width: 705px) {
    padding-top: 12px;
    padding-bottom: 0;
  }
`;

export const EventType = styled.div<{selected?: boolean}>`
  position: absolute;
  margin: 5px;
  color: ${props =>
    props.selected
      ? props.theme.colors.backgroundPrimary
      : props.theme.colors.textPrimary};
`;

export const RotatedFontAwesomeIcon = styled(FontAwesomeIcon)`
  transform: rotate(90deg);
`;

export const EventText = styled.div<{selected?: boolean}>`
  padding: 5px;
  user-select: none;
  font-weight: bold;
  text-align: center;
  color: ${props =>
    props.selected
      ? props.theme.colors.backgroundPrimary
      : props.theme.colors.textPrimary};

  @media (max-width: 768px) {
    font-size: 13px;
  }

  @media (max-width: 705px) {
    font-size: 11px;
  }
`;

export const StyledTimeKeeper = styled.div`
  .react-timekeeper {
    width: 100% !important;
  }

  div {
    left: auto !important;
  }

  button {
    transition: background 0.15s ease-out 0s !important;
  }

  text-align: center;
`;

export const HeartWrapper = styled.span`
  fill: ${props => props.theme.colors.accent};
`;

export const MainHeader = styled.span`
  font-size: 24px;
`;

export const AccentDiv = styled.div`
  color: ${props => props.theme.colors.accent};
`;

export const ClockWarningDiv = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f2dede;
  color: #a94442;
  font-weight: 700;
`;

export const ErrorDiv = styled.div`
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  font-weight: 700;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
`;

export const ButtonDiv = styled.div`
  text-align: center;
  padding: 10px;
`;

export const TimeWrapper = styled.div`
  margin: 0 auto;
  width: 350px;
  font-size: 50px;
  text-align: center;
`;

export const TableEventWrapper = styled.span`
  svg {
    width: 20px;
    height: 20px;
    fill: ${props => props.theme.colors.textPrimary};
  }
`;

export const NextOfKinDivWrapper = styled.div<{disabled?: boolean}>`
  padding: 15px 0;
  display: flex;

  
  ${props => props.disabled && "opacity: 0.7;"}

  @media (min-width: 510px) {
    align-items: center;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const TrashWrap = styled.span`
  svg {
    fill: ${props => props.theme.colors.textPrimary};
  }
`;

export const StyledRow = styled(Row)`
  padding: 10px 0px;
`;

export const AddRow = styled(StyledRow)`
  display: flex;
  align-items: center;
`;