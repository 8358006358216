import styled from "styled-components";
import { SaveContainer } from "../../../DefaultSettings/Settings/styled";
import { Button } from "react-bootstrap";
import { Form } from "formik";

export const ComponentWrapper = styled.div`

    margin-bottom: 1.5em;

`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 14.7px;
  div{
    height: 26px;
    width: 26px;
  }
`;

export const StyledButton = styled(Button)`
  margin-right: 10px;
  height: 4.5em;
  min-width: 325px;
`;

export const ButtonContainer = styled(SaveContainer)`
  padding: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  width: 100%;
  border-top: ${props => props.theme.colors.border};
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
`;

export const FooterNoBorder = styled.div`
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-content: flex-end;
`;

export const StyledForm = styled(Form)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
`;

export const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
`

export const SettingWrapper = styled.div`
  padding: 0 15px;
`;

export const StatusWrap = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding ${({ theme: { paddings: { standard = '0px' } = {} } = {} }) =>
    standard};
  background-color: ${props => props.theme.colors.statusBarBackground};
  border-radius: ${({
    theme: { colors: { borderRadius = '50%' } = {} } = {}
  }) => borderRadius};
`;

export const TwinSection = styled.div`
  padding: 15px;
`;

export const TwinLink = styled.p`
  cursor: pointer;
`;

export const SimulateWrap = styled.div`
  padding: 5px;
`;