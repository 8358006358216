import {createRoutine, Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/INITIALIZE'
);

export const fetchCustomersForList: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/FETCH_CUSTOMERS_FOR_LIST'
);

export const initializeCustomers: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/INITIALIZE_CUSTOMERS'
);


export const toggleChildren: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/TOGGLE_CHILDREN'
);


export const getCustomerView: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/GET_CUSTOMER_VIEW'
);

export const setSwitchValue: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/SET_SWITCH_VALUE'
);

export const setAllowDeviceDeletes: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/SET_ALLOW_DEVICE_DELETES'
);

export const setReference: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/SET_REFERENCE'
);

export const filterPartners: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/FILTER_PARTNERS'
);

export const setCacheValues: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@PartnerDashboard/SET_CACHE_VALUES'
);






