import * as React from "react";
import { ISensorDTO } from "../Sensor/types";
import { EventConfigForm } from "./types";
import { ActiveIndicator, MoonEmpty, MoonFill, MoonWrapper } from '../styles';
import { IOption } from "@Elements/Dropdown/types";

type GetSensorOptions = (sensors: ISensorDTO[], ...code: string[]) => IOption<ISensorDTO>[]

export const getSensorOptions: GetSensorOptions = (sensors, ...code) => {


  return sensors
    .filter(sensor => sensor.function && code.includes(sensor.function.code))
    .map(sensor => {
      return{
        text: sensor.sensor.name,
        value: sensor,
        suffix: <ActiveIndicator
          status={ 
            sensor.eventConfig.active 
              ? FunctionStatus.ACTIVE
              : FunctionStatus.INACTIVE}/>
      }
    })
}

export enum FunctionStatus{
  ACTIVE,
  INACTIVE,
  NONE
}

type GetFunctionStatus = (sensors: ISensorDTO[], func: string) => FunctionStatus;

export const getFunctionStatus: GetFunctionStatus = (sensors, func) => {

  if(!sensors) return FunctionStatus.NONE;
  
  const relevantSensors = sensors.filter(sensor => sensor.function.code === func);
  if(relevantSensors.length === 0) return FunctionStatus.NONE;
  
  return !!relevantSensors.find(sensor => sensor.eventConfig.active) ? FunctionStatus.ACTIVE : FunctionStatus.INACTIVE;
}

export const mapConfig = (sensor: ISensorDTO): EventConfigForm => {
  return {
    sensorId: sensor.sensor.deseId,
    eventConfig: {...sensor.eventConfig}
  }
}

export const Moon = () => <MoonWrapper><MoonFill></MoonFill><MoonEmpty></MoonEmpty></MoonWrapper>