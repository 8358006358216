import React from 'react';
import {Route} from 'react-router-dom';
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { VerticalTabs, Tab } from "../../Elements/Tabs";
import SettingsSaga from './Store/Saga';
import SettingsReducer from './Store/Reducer';
import configureProvider from '../DefaultProvider';
import { initialize, saveRole } from "./Store/routines";
import { Role, RootState } from './Store/types';
import Spinner from "../Spinner";
import styled from 'styled-components';
import { mapCustomTabs, NonStatic, NonStaticRender } from './utils';
import mapTabs from './Tabs';
import { CancelButton, SaveButton } from '../DefaultSettings/Settings/styled';
import UnsavedCheck from "../SaveNeeded/unsavedModal";
import  Loader  from "@Elements/Loader";
import SafemateHeader from '../Header';
import { AppState } from '@Safemate/Store/types';
import { useParams } from 'react-router';

const Wrap = styled.div`
  position: relative;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 30px;
    width: 30px;
  }
`;

interface RoleEditorProps{
  role: Role;
  saving: boolean;
  isSso: boolean;
  isPartner: boolean;
  initialized: boolean;
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  saveRole: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ appData: {isSso, isPartner}, roleEditor: {role: { role, saving }, initialization: { initialized }}}: AppState) => {
  return{
    role,
    saving,
    initialized,
    isSso,
    isPartner
  }
}

const mapDispatchToProps = {
  initialize,
  saveRole
}

interface RoleEditorParams{
  id?: string;
  duplicate?: string;
}

const RoleEditor = connect(mapStateToProps, mapDispatchToProps)(({role, saving, isSso, isPartner, initialize, saveRole, initialized}: RoleEditorProps) => {

  const [ nonStatic, setNonStatic ] = React.useState<NonStatic>({});

  let { id, duplicate } = useParams<RoleEditorParams>();

  const isDuplicate = duplicate ? duplicate === "true" : false;
  const roleId = id ? parseInt(id) : 0;

  React.useEffect(() => {
    initialize({
      roleId, 
      duplicate: isDuplicate});
  }, []);

  React.useEffect(() => {
    setNonStatic(mapCustomTabs(role.policies, isPartner));
  }, [role.policies]);
  
  const { formatMessage } = useIntl();

  if(!initialized)
    return (
      <Wrap>
        <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
      </Wrap>
    )

  const initialValues = {
    ...role
  }

  if(isDuplicate){
    initialValues.id = 0;
    initialValues.roleName = "";
  }


  if(roleId == 1){
    initialValues.policies.partner.rolePolicies.status = false;
    initialValues.policies.partnerInvoiceMenu.rolePolicies.status = false;
    initialValues.policies.partnerPrices.rolePolicies.status = false;
    initialValues.policies.requireIp.rolePolicies.status = false;
  }


  return(
    <React.Fragment>
    <SafemateHeader
        icon={null}
        title={`${formatMessage({id: "role", defaultMessage: "Role"})} ${formatMessage({id: "editor", defaultMessage: "editor"})} - ${initialValues.id ? initialValues.roleName : formatMessage({id: "creatingNew", defaultMessage: "creating new"})}`}
    />
    <Formik
      onSubmit={(values) => saveRole(values)}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ dirty }) =>
      <Form>
        <VerticalTabs>
          {mapTabs(nonStatic, isSso)}
        </VerticalTabs>
        <Footer>
          <CancelButton 
            onClick={() => {
              location.hash = 'role_manager';
            }}
          >{formatMessage({id: "cancel", defaultMessage: "Avbryt"})}</CancelButton>
          {saving 
            ? <LoaderWrap><Loader/></LoaderWrap>
            : <SaveButton disabled={!dirty} type="submit">{formatMessage({id: "saveRole", defaultMessage: "Save Role"})}</SaveButton>
          }
        </Footer>
        <UnsavedCheck/>
      </Form>}
    </Formik>
    </React.Fragment>
  )
})

export default RoleEditor;