import {AlarmAnalysisState} from "../types";
import {Reducer} from "redux";
import * as Routines from "../Routines";

const alarmAnalysis: Reducer<AlarmAnalysisState> = (state: AlarmAnalysisState = {}, action) => {
  switch (action.type) {
    case Routines.alarmAnalysis.SUCCESS:
      return {
        ...state,
        [action.payload.device]: action.payload.analysis
      };

    default:
      return state;
  }
};

export default alarmAnalysis;
