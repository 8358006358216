import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import DeleteIcon from "@Icons/FontAwesome/Delete";
import { isGx8 } from "@Safemate/DeviceHardwareEnum";
import { ISensorDTO } from "../types";
import { defineMessages, useIntl } from "react-intl";
import confirmModal from "@Safemate/Modals/confirmModal";
import { deleteSensor } from "../../Store/routines";
import { connect } from "react-redux";
import { AppState } from "@Safemate/Store/types";


const modalText = defineMessages({
  title: { id: 'deleteSensorModalTitle', defaultMessage: 'Fjerne sensor' },
  deleteBtn: { id: 'deleteSensorBtn', defaultMessage: 'Slett sensor' }
});

interface DeleteActionProps{
  sensor: ISensorDTO;
  dehaId: number;
  deleteSensor: Routine<ActionFunctionAny<Action<any>>>;
}


const mapStateToProps = ({ settings: {initialization: { dehaId }}}: AppState) => {
  return {
    dehaId
  }
}

const mapDispatchToProps = {
  deleteSensor,
}

export const DeleteAction = connect(mapStateToProps, mapDispatchToProps)(({ sensor: {sensor}, dehaId, deleteSensor }: DeleteActionProps) => {

  const { formatMessage } = useIntl();
  const { Comp, func } = confirmModal();

  const confirmDelete = () => {
    deleteSensor(sensor)
    func(false);
  }

  return(
    <React.Fragment>
      <DeleteIcon 
        onClick={() => func(true)}
        medium
        animate
        title={formatMessage({ id: 'deleteActionTooltip', defaultMessage: 'Slett sensor' })}
      />
      <Comp
        title={`${formatMessage(modalText.title)} ${isGx8(dehaId) ? sensor.name : sensor.macAddress}`}
        body={null}
        confirmText={formatMessage(modalText.deleteBtn)}
        confirmFunc={confirmDelete}
      />
    </React.Fragment>
  )
})
