import styled from 'styled-components';

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  & > :last-child {
    margin-top: auto;
  }
`;

export const StatusDescription = styled.div<{show: boolean}>`
  font-size: 32px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')}
  color: green;
`;

export const ContentWrap = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({
    theme: {
      margins: { standard }
    }
  }) => standard};
  & > * {
    margin-bottom: ${({
      theme: {
        margins: { standard }
      }
    }) => standard};
  }
  & > :last-child {
    margin-bottom: 0;
  }
`;