import React from 'react';
import { Formik, connect as formikConnect } from 'formik';
import { FormattedMessage } from 'react-intl';
import NavigationPrompt from 'react-router-navigation-prompt';
import UnsavedChangesModal from '../../Roles/RoleEditor/Modals/UnsavedChangesModal';
import { useLocation } from 'react-router';


const UnsavedCheck = props => {


  const { formik: { dirty = false } = {}, manualDirty, dirtyOverride, formLocation } = props;
  const location = formLocation? formLocation : useLocation();
  const currentLocation = location.pathname;

  let finalDirty = dirty || manualDirty;

  if (dirtyOverride) {
    finalDirty = dirtyOverride();
  }
  return (
    <React.Fragment>
      <NavigationPrompt when={finalDirty}>
        {({ onConfirm, onCancel }) => (
          <UnsavedChangesModal
            display={true}
            handleContinue={() => {
              onConfirm();
            }}
            handleCancel={() => {
              location.hash = currentLocation;
              onCancel();
            }}
            translations={(text, fallback) => (
              <FormattedMessage id={text} defaultMessage={fallback} />
            )}
            headerText={
              <FormattedMessage
                id="navigationWithoutSaveTitle"
                defaultMessage="Ulagrede endringer"
              />
            }
            bodyText={
              <FormattedMessage
                id="navigationWithoutSaveDesc"
                defaultMessage="Du er i ferd med å forlate siden, men du har ulagrede endringer som vil gå tapt. Vil du fortsette?"
              />
            }
          />
        )}
      </NavigationPrompt>
    </React.Fragment>
  );
};

export default formikConnect(UnsavedCheck);
