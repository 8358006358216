import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Used as child of a Tabs components, which uses the `renderTitle` and `title`
 * props to render the tab label.
 */
const Tab = ({ children }) => <Fragment>{children}</Fragment>;

Tab.propTypes = {
  title: PropTypes.node,
  renderTitle: PropTypes.func,
  children: PropTypes.node,
  name: PropTypes.string,
  style: PropTypes.object
};

export default Tab;
