import _axios, {AxiosInstance} from 'axios';
import { CheckTwinSerialNumber } from './Saga/device';
import { AddDeviceForm } from './types';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getCustomers = async () => {
  const url: string = `/firm/trimmedsuborgunits/true`;
  const response = await axios.get(url);
  return response.data;  
};

export const addDevice = async (addDevice: AddDeviceForm) => {
  const url: string = `/device/save/firm`;
  const response = await axios.post(url, addDevice);
  return response.data;
}

export const getTwin = async (deviId: number) => {
  const url: string = `/device/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}

export const checkTwinSerialNumber = async ({twinDeviId, serialNumber}: CheckTwinSerialNumber) => {
  const url: string = `/device/twinserial/${twinDeviId}/${serialNumber}`;
  const response = await axios.get(url);
  return response.data;
}

