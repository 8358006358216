import * as React from "react";
import {SettingToPolicyMap, TabInfo, DeviceComponents, OtherTab, EventSetupTab, GeofenceTab} from "./tabDefinition";
import {Tab} from "../../../Elements/Tabs";
import {SettingPrefixEnum} from "./settingEnum";
import {Header} from "./styled";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import { DeviceHardwareEnum } from "@Safemate/DeviceHardwareEnum";
import {VerticalTabs} from "@Elements/Tabs";
import {IndoorLocationSettings} from "@Safemate/DefaultSettings/Settings/types";
import HorizontalTabs from "@Elements/Tabs/HorizontalTabs";
import { AllPermissions } from "@Safemate/Policy/Provider";
import DeviceSetting from "./deviceSetting";
import OtherSetting from "./otherSetting";
import IndoorLocations from "./indoorLocationSetting";
import Geofence from "./geofenceSetting";
import EventSetup from "./eventSetupSetting";

export const mapMainTabs = (categoryKey: number, permissions: Map<PolicyNameEnum, AllPermissions>, indoorLocationSettings: IndoorLocationSettings,  custId: number, formatMessage: Function) => {
 
  const categoryMap: Map<SettingPrefixEnum, TabInfo> = DeviceComponents.get(categoryKey)!;
  const deviceSettingsTabs = mapTabs(categoryMap.entries(), permissions, custId);
  const othersTabs = mapTabs(OtherTab.entries(), permissions, custId);
  const eventSetupTab = mapContent(EventSetupTab.entries(), permissions, custId);
  const geofenceTab = mapContent(GeofenceTab.entries(), permissions, custId);
  const hasIndoorPermission = hasIndoorLocationCustomerPermission(permissions, indoorLocationSettings, custId);

  const tabs: JSX.Element[] = [];

  if(deviceSettingsTabs.length > 0){
    tabs.push(<Tab key={`deviceSettings`} title={formatMessage({id: "settings", defaultMessage: "Settings"})} name={`deviceSettings`}>
      <DeviceSetting tabs={deviceSettingsTabs}/>
    </Tab>);
  }
  if( othersTabs.length > 0 ){
    tabs.push(<Tab key={`otherSettings`} title={formatMessage({id: "otherSettings", defaultMessage: "Other Settings"})} name={`otherSettings`}>
      <OtherSetting tabs={othersTabs}/>
    </Tab>);
  }
  if(eventSetupTab.length > 0){
    tabs.push(<Tab key={`eventSetupTab`} title={formatMessage({id: "eventSetupTab", defaultMessage: "Event setup"})} name={`eventSetupTab`}>
      <EventSetup tabs={eventSetupTab} />
    </Tab>);
  }

  if(geofenceTab.length > 0){
    tabs.push(<Tab key="geofenceTab" title={formatMessage({id: "geofenceTab", defaultMessage: "Geofence"})} name={`geofenceTab`}>
      <Geofence />
    </Tab>);
  }

  if( indoorLocationSettings && indoorLocationSettings.indoorLocations && indoorLocationSettings.indoorLocations.length > 0 && hasIndoorPermission ){
    tabs.push(<Tab key="indoorLocations" title={formatMessage({id: "indoorLocations", defaultMessage: "Indoor locations"})} name={`indoorLocations`}>
      <IndoorLocations/>
    </Tab>);
  }

  return tabs;

}

export const mapTabs = (entries: IterableIterator<[SettingPrefixEnum, TabInfo]>, permissions: Map<PolicyNameEnum, AllPermissions>, custId: number) => {
  const Tabs: Array<React.ReactElement> = [];
  let result = entries.next();
  while(!result.done){
    const tabType: SettingPrefixEnum = result.value[0];
    const policy: PolicyNameEnum = SettingToPolicyMap.get(tabType) as PolicyNameEnum;
    const {components, title}: TabInfo = result.value[1];
    if(permissions.get(policy)!.customerPermissions[custId].edit || permissions.get(policy)!.customerPermissions[custId].read || permissions.get(policy)!.customerPermissions[custId].all)
      Tabs.push(
        <Tab
          key={tabType}
          renderTitle={() => (title.component)}
          name={tabType.toString()}
        >
          <Header>
            <h2>
              {React.cloneElement(title.component, {tooltip: title.tooltip})}
            </h2>
          </Header>
          {components.map(comp =>
            React.createElement(comp)
          )}
        </Tab>
      );
    result = entries.next();
  }
  return Tabs;
}

export const mapContent = (entries: IterableIterator<[SettingPrefixEnum, TabInfo]>, permissions: Map<PolicyNameEnum, AllPermissions>, custId: number) => {
  const Tabs: Array<React.ReactElement> = [];
  let result = entries.next();
  while(!result.done){
    const tabType: SettingPrefixEnum = result.value[0];
    const policy: PolicyNameEnum = SettingToPolicyMap.get(tabType) as PolicyNameEnum;
    const {components, title}: TabInfo = result.value[1];
    if(permissions.has(policy) && (permissions.get(policy)!.customerPermissions[custId].edit || permissions.get(policy)!.customerPermissions[custId].read || permissions.get(policy)!.customerPermissions[custId].all))
      Tabs.push(
        <div>
          {components.map(comp =>
            React.createElement(comp)
          )}
        </div>
      );
    result = entries.next();
  }
  return Tabs;
}


export const isDeviceHardwareContent = (deviceHardware: number, permissions: Map<PolicyNameEnum, AllPermissions>, custId: number) => {
  const categoryKey = DeviceComponents.has(deviceHardware) ? deviceHardware : DeviceHardwareEnum.DEFAULT;
  const categoryMap: Map<SettingPrefixEnum, TabInfo> = DeviceComponents.get(categoryKey);
  const entries = categoryMap.entries();
  let result = entries.next();
  let content = false;
  while(!result.done){
    const tabType: SettingPrefixEnum = result.value[0];
    const policy: PolicyNameEnum = SettingToPolicyMap.get(tabType) as PolicyNameEnum;
    if(permissions.get(policy)!.customerPermissions[custId].edit || permissions.get(policy)!.customerPermissions[custId].read || permissions.get(policy)!.customerPermissions[custId].all){
      content = true;
    }
    result = entries.next();
  }
  return content;
}

export const hasIndoorLocationCustomerPermission = (permissions: Map<PolicyNameEnum, AllPermissions>, indoorLocationSettings: IndoorLocationSettings, custId: number): boolean => {
  const policy: PolicyNameEnum = PolicyNameEnum.DEVICE_INDOOR_CUSTOMER;

  if( (indoorLocationSettings
    && indoorLocationSettings.indoorLocations
    && indoorLocationSettings.indoorLocations.length > 0)
    && (permissions.get(policy)!.customerPermissions[custId].edit || permissions.get(policy)!.customerPermissions[custId].read)
  ){
    return true;
  }
  return false;
}

export const settingsTabWrapper = (tabs : JSX.Element[]): JSX.Element => {
  if(tabs && tabs.length>0){
    return (<VerticalTabs>{tabs}</VerticalTabs>);
  } else {
    return (<React.Fragment></React.Fragment>);
  }
}

export const settingsHorizontalTabWrapper = (tabs : JSX.Element[]): JSX.Element => {
  if(tabs && tabs.length>0){
    return (<VerticalTabs>{tabs}</VerticalTabs>);
  } else {
    return (<React.Fragment></React.Fragment>);
  }
}

export const defaultSettingHorizontalTabWrapper = (tabs : React.ReactNode[]): JSX.Element => {
  if(tabs && tabs.length>0){
    return (<HorizontalTabs>{tabs}</HorizontalTabs>);
  } else {
    return (<React.Fragment></React.Fragment>);
  }
}
