import {UserWithCustomer} from "../../Models/UserWithCustomer";
import moment from "moment";

export const defaultValues: UserWithCustomer = {
  from: moment(),
  lastOnlineDate: 0,
  role: 0,
  subOrgs: [],
  superAdmin: false,
  temporary: false,
  temporaryRole: 0,
  temporarySubOrgs: [],
  to: moment(),
  user: {
    active: false,
    deactivated: false,
    deactivatedFrom: moment(),
    deactivatedTo: moment(),
    email: "",
    firstName: "",
    lastActivity: 0,
    lastName: "",
    mailTo: "",
    mobile: "",
    name: "",
    tempDeactivated: false,
    trained: false,
    trainingApprovedBy: 0,
    trainingApprovedByName: "",
    userId: 0,
    userType: 0,
    username: "",
    coveredByTwoFactorAuthentication: false,
    emailAsUsername: false,
    newUserFlag: false,
    mapConsent: false,
    lastSso: "",
    languageCode: "",
    beta: false,
    gdprManager: false,
    nationalIdentityNumber: "",
    createdBy: undefined,
    deactivatedBy: undefined,
    adminCustomer: undefined
  }
};
