import * as React from "react";
import {Form, Formik, } from "formik";
import {connect} from "react-redux";
import { Grid} from "react-bootstrap";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";
import { useIntl } from "react-intl";

import { Firm } from "../Store/types";
import { popForm} from "../Form/utils";
import { RootState, Organization} from "../Store/types";
import {saveForm, fetchOrganisation} from "../Store/routines";
import {Footer} from "../Form/footer";

import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { Billing } from "./fields";

interface OrganisationProps {
  fetchOrganisation: Routine<ActionFunctionAny<Action<any>>>;
  saveForm: Routine<ActionFunctionAny<Action<any>>>;
  customers: Organization;
}


export const UpdateButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const H3 = styled.h4`
  white-space: normal;
  padding-left: 20px;
  padding-bottom: 20px;
  font-weight: bold!important;

`;

const StyledSpan = styled.span`
  @media (max-width: 600px) {
    font-size: 24px;
    margin-left: 10px;
  }
`;


const HeaderWrapper = styled.span`
  display: flex;
  @media (max-width: 600px) {
    display: block;
  }
`;

export interface OrgValues {
  custId: string;
  updatedAhp: boolean;
  deviId: number;
}

const Address = ({  saveForm, customers, fetchOrganisation}: OrganisationProps) => {

  React.useEffect(() => {
    fetchOrganisation();
  }, [])
  const intl = useIntl();


  let iniVals;
  let pptyId:string;
  let billAddrDefined:boolean;
  let countryCode:string;
  customers.paymentProfile != null ? pptyId = customers.paymentProfile.pptyId+'' : customers.firm.privateUser ? pptyId="6" : pptyId="4";
  countryCode = customers.firm.country;
  customers.firm.billingAddrName === null || customers.firm.billingAddrName === "" ? billAddrDefined = false : billAddrDefined = true;
  iniVals = popForm(customers.firm, pptyId, billAddrDefined);
  return(
    <Formik
      initialValues={iniVals}
      enableReinitialize
      onSubmit={(values: Firm) => saveForm({values, customers})}
    >
    {({values: { mobile }, dirty}) => 
        <Form id="addressForm">
                    <H3>
                      <HeaderWrapper>
                        <StyledSpan>
                          {intl.formatMessage({ id: "Fakturainstillinger", defaultMessage: "Fakturainstillinger" })} 
                        </StyledSpan>
                      </HeaderWrapper>
                    </H3>
                      <Billing/>
                      <Footer />
                      <UnsavedCheck/>
        </Form>
    }
      </Formik>
    
  )
}

const mapStateToProps = ({ subUnits: {customers}}: RootState) => {
  return{
    customers
  }
};

const mapDispatchToProps = {
  saveForm,
  fetchOrganisation
}


export default connect(mapStateToProps, mapDispatchToProps)(Address);