import * as React from "react";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import styled from "styled-components";
import Users from "../Elements/Icon/Icons/Safemate/solid/Users";
import Map from "../Elements/Icon/Icons/settings/Map";
import FileAltLight from "../Elements/Icon/Icons/FileAltLight";
import { AllPermissions, PolicyContext } from "./Policy/Provider";
import { defineMessages, useIntl } from "react-intl";
import {Client, IMessage, StompSubscription} from "@stomp/stompjs";
import { connectWebsocket } from "@Safemate/Websocket";
import {SetSubscription, setSubscription} from "@Safemate/Settings/Store/actions";
import { AppState } from "@Safemate/Store/types";
import { connect } from "react-redux";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";
import { PolicyNameEnum } from "./PolicyNameEnum";
import { Device, DeviceConnectionEvent } from "./Settings/types";
import { excludeConnectedHeader } from "./Settings/tabDefinition";
import { isGx8 } from "./DeviceHardwareEnum";
import { getIcon } from "./DeviceList/DeviceList/utils";
import { HeaderWrapper, Highlighted } from "./Settings/styles";
import { useHistory, useLocation } from "react-router";
import Settings from "@Elements/Icon/Icons/settings/Settings";
import { Pen } from "@Elements/Icon/Icons/FontAwesome";
import Check from "@Elements/Icon/Icons/Check";
import { updateName } from "./EventSetupNew/Store/routines";
import { FilterSearch, filterSearch } from "./DeviceList/Store/actions";
import Search from "@Elements/Icon/Icons/Search";

const connectedMessages = defineMessages({
  offline: {
    id: 'panelOffline',
    defaultMessage: 'Frakoblet'
  },
  online: {
    id: 'panelOnline',
    defaultMessage: 'Tilkoblet'
  }
});

const Heading = styled.div`
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    svg{
      width: 2em!important;
    }
  }
  @media (max-width: 450px) {
    display: flow-root;
  }
`;

const H1 = styled.h1`
  white-space: normal;

`;

export const StyledSpan = styled.span`
  @media (max-width: 600px) {
    font-size: 18px;
    margin-left: 10px;
  }
`;

const StyledSpanSecond = styled.span`
  @media (max-width: 600px) {
    font-size: 18px;
    margin-left: 10px;
  }
`;

const StyledDeviceSpan = styled.span`
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  button {
    margin: 5px;
  }
`;

const NavButton = styled.button<{active?: boolean}>`
  ${props => props.active ? `background: ${props.theme.colors.accent};` : ""}
  

  svg {
    ${props => props.active ? `fill: ${props.theme.colors.backgroundSecondary};` : ""}
  }
`;

export const PhoneInput = styled.input`
  border-radius: 4px 0 0 4px;
`;

export const Wrapper = styled.div`
  background: ${props => props.theme.colors.backgroundPrimary};
  display: inline-flex;
`;

export const CheckWrapper = styled.button<{disabled?: boolean}>`
  flex: 0 1;
  border: ${props => props.theme.colors.border};
  border-left: none;
  border-radius: 0 4px 4px 0;
  color: white;
  fill: white;
  display: inline-flex;
  background: ${props => props.theme.colors.backgroundPrimary};
  align-items: center;
  padding: 0 15px;
  transition: 0.2s;

  ${props => props.disabled && "cursor: not-allowed;"}
  ${props => !props.disabled && `&:hover{
    background: ${props.theme.colors.accent};
    svg g{
      fill: ${props.theme.colors.backgroundSecondary};
    }
  }`}

  svg g{
    fill: ${props => props.disabled ? props.theme.colors.borderColor : props.theme.colors.textPrimary};
  }
`;

interface HeaderProps{
    dehaId: number;
    deviId: number;
    custId: number;
    isTwin: boolean;
    name: string;
    serialNumber: string;
    deviceId: string;
    isPrivate: boolean;
    filterSearch: FilterSearch;
    updateName: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ appData: {isPrivate} }: AppState) => {
  return{
    isPrivate
  }
}

const mapDispatchToProps = {
  updateName,
  filterSearch,
  setSubscription,
}

const DeviceHeader = connect(mapStateToProps, mapDispatchToProps)(({
  isPrivate, 
  filterSearch, updateName,
  dehaId, deviId, custId, isTwin, name, serialNumber, children}: React.PropsWithChildren<HeaderProps>) => {

    const { formatMessage } = useIntl();
    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const settings = checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_SETTINGS, custId) || checkPermissionsForCustomer(permissions, PolicyNameEnum.GENERAL_SETTINGS, custId);
    const eventSetup = checkPermissionsForCustomer(permissions, PolicyNameEnum.EVENT_SETUP, custId) && !isTwin;
    const log = checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_ACCESS_MAP, custId) || checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_TRANSACTION_LOG, custId);

    const deviceIcon: React.ReactNode | undefined = getIcon(dehaId);

    const [ editName, setEditName ] = React.useState(false);
    const [ deviceName, setDeviceName ] = React.useState(name);

    React.useEffect(() => {
      setDeviceName(name);
    }, [name])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setDeviceName(value);
    }

    const saveName = () => {
      if(deviceName !== name){
        updateName({deviceId: deviId, deviceName});
      }
      setEditName(false);
    }

    const { pathname } = useLocation();
    const history = useHistory();


    const headerClick = () => {
      if(!isPrivate){
        filterSearch(serialNumber);
        history.push("/safemates");
      }
    }

    return(
        <Heading>
           <H1>
            <HeaderWrapper>
              <StyledDeviceSpan>
                {children}
              </StyledDeviceSpan>
              <Highlighted>
                &nbsp;
                {deviceIcon}
                <StyledSpanSecond>
                  {editName 
                  ? <Wrapper>
                      <PhoneInput type="text" value={deviceName} onChange={handleChange}/>
                      <CheckWrapper><Check onClick={saveName} size={{width: "25px", height: "25px"}} inline/></CheckWrapper>
                    </Wrapper>
                  : <span>
                    {`${deviceName} `}
                    <Pen onClick={() => setEditName(true)} medium/>
                  </span>}
                  <span style={{cursor: "pointer"}} onClick={headerClick}>{` (${serialNumber}) `}</span>
                </StyledSpanSecond>
              </Highlighted>
            </HeaderWrapper>
          </H1>
          <ButtonWrapper>
            {log && <NavButton active={pathname.includes("mapsingle")} onClick={() => {location.hash = `mapsingle/${deviId}`}} className="btn btn-dark-with-line btn-small">{isGx8(dehaId) ? <FileAltLight inline size="medium"/> : <Map inline size="medium"/>}</NavButton>}
            {eventSetup && <NavButton active={pathname.includes("event_setup")} onClick={() => {location.hash = `event_setup/${deviId}`}} className="btn btn-dark-with-line btn-small"><Users inline size="medium"/></NavButton>}
            {settings && <NavButton active={pathname.includes("settings")} onClick={() => {location.hash = `settings/${deviId}/${dehaId}`}} className="btn btn-dark-with-line btn-small"><Settings inline size="medium"/></NavButton>}
          </ButtonWrapper>
        </Heading>
    )
})

export default DeviceHeader;
