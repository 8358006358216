import {DeviceMac} from "./types";
import {setDeviceMac, SetDeviceMac} from "../Store/actions";
import {connect} from "react-redux";
import {default as EditIcon} from "../../../Elements/Icon/Icons/Edit";
import {LabelEnum} from "../../UserAdmin/LabelEnum";
import * as React from "react";
import { useIntl } from "react-intl";

interface EditButtonProps {
  deviceMac: DeviceMac;
  setDeviceMac: SetDeviceMac
}

const mapEditButtonDispatchToProps = {
  setDeviceMac
}

const EditButton = connect(null, mapEditButtonDispatchToProps)(({deviceMac, setDeviceMac}:EditButtonProps) => {

  const { formatMessage } = useIntl();

  return(<EditIcon
    tabIndex="0"
    title={formatMessage({id: LabelEnum.EDIT, defaultMessage: "edit"})}
    size="medium"
    onClick={(event: any) => {
      event.stopPropagation();
      setDeviceMac(deviceMac);
    }}
    inline
  />)
});

export default EditButton;
