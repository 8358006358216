import React from 'react';
import styled from 'styled-components';
import Modal from '../../../Safemate/Modals';
import { StyledButton } from '../../Styles/FormElementStyles';

export const TextContainer = styled.div`
  min-width: 500px;
  text-align: center;
  color: ${props => props.theme.modalColors.text};
  display: block;
  padding: 25px;
  background-color: ${props => props.theme.backgroundSecondary};
  border-radius: 5px 5px 0 0;
`;

export const HeaderContent = props => {
  return (
    <TextContainer>
      <h1>{props.headerText}</h1>
    </TextContainer>
  );
};

export const Body = props => {
  return (
    <div style={{ padding: '20px' }}>
      <p style={{ textAlign: 'center' }}>{props.bodyText}</p>
      <StyledButton
        id="cancel-delete"
        color="#f7ae11"
        className="btn"
        onClick={props.handleCancel}
        style={{ width: '100%' }}
      >
        {props.translations ? props.translations('cancel', 'Cancel') : 'Cancel'}
      </StyledButton>
      <StyledButton
        id="confirm-delete"
        color="#d60403"
        className="btn"
        onClick={props.handleConfirm}
        style={{ width: '100%' }}
      >
        {props.translations
          ? props.translations('continue', 'Continue')
          : 'Continue'}
      </StyledButton>
    </div>
  );
};

const ConfirmModal = ({
  display,
  handleCancel,
  handleConfirm,
  headerText,
  ...props
}) => {
  return (
    <Modal
      display={display}
      headerContent={<HeaderContent headerText={headerText} />}
      bodyContent={
        <Body
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          {...props}
        />
      }
      {...props}
    />
  );
};

export default ConfirmModal;
