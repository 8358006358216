import {call, put, race, take, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import * as Routines from '../routines';
import {DeviceHardware} from "../types";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {GlobalRoles} from "../../../GlobalRoles";
import {accessToCustomer} from "../../../Policy/rightsUtil";
import {User} from "@Safemate/MyPage/Store/types";
import { getSuborgsWithRoles, getUser } from '@Safemate/Store/routines';
import { ICustomerWithPolicy } from '@Safemate/Model/Customer';
import { AppAction } from '@Safemate/Store/types';

export interface IRace<T> {
  success: AppAction<T>;
  failure: any;
}

function* initialize(action: AppAction<any>){
  try {
    yield put(getUser());
    yield put(getSuborgsWithRoles());
    const {success: suborgSuccess, failure: subOrgFailure}: IRace<ICustomerWithPolicy[]> = yield race({
      success: take(getSuborgsWithRoles.SUCCESS),
      failure: take(getSuborgsWithRoles.FAILURE)
    });
    yield put(Routines.initializeDeviceHardware());
    const {success: deviceHardwareSuccess, failure: deviceHardwareFailure}: IRace<Array<DeviceHardware>> = yield race({
      success: take(Routines.initializeDeviceHardware.SUCCESS),
      failure: take(Routines.initializeDeviceHardware.FAILURE)
    });

    if (suborgSuccess && deviceHardwareSuccess) {
      const selectedFirm: ICustomerWithPolicy = suborgSuccess.payload.find(customer =>
        accessToCustomer(customer.policies, PolicyNameEnum.DEFAULT_SETTINGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM])) as ICustomerWithPolicy;
      yield put(Routines.getClimaxConfig.trigger(selectedFirm.customerId))
      yield put(Routines.selectCustomer.trigger(selectedFirm));
      const {success: selectCustomerSuccess, failure: selectCustomerFailure}: IRace<ICustomerWithPolicy> = yield race({
        success: take(Routines.selectCustomer.SUCCESS),
        failure: take(Routines.selectCustomer.FAILURE)
      });
      if(selectCustomerSuccess) {
        yield put(Routines.initialize.success());
        return
      }
    }
  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
  yield put(Routines.initialize.failure());
}


function* initializeDeviceHardware(action: AppAction<any>){
  try{
    const deviceHardware: number = yield call(Api.getDeviceHardware);
    yield put(Routines.initializeDeviceHardware.success(deviceHardware));
  }
  catch(e){
    yield put(Routines.initializeDeviceHardware.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.initializeDeviceHardware.TRIGGER, initializeDeviceHardware);
}

export default initializationSaga;
