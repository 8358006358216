import * as React from "react";
import { injectIntl, WrappedComponentProps, useIntl } from "react-intl";
import { Field } from "formik";
import styled from "styled-components";
import { Col, Row, Button } from "react-bootstrap";

import Dropdown from "../../../Elements/Dropdown/Dropdown";
import { Option } from "../../../Elements/Dropdown/types";
import { BillingSettings } from "./types";
import LogoModal from "../../Modals/Logo";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {connect as formikConnect} from "formik";
import {RootState, PostCity, Customer} from "../Store/types";
import {Action, ActionFunctionAny} from "redux-actions";
import { getPostCity } from "../Store/routines";
import withFormWrapper from "@Elements/Form/formWrapper";
import { AppState } from "@Safemate/Store/types";
import { MarketEnum } from "@Safemate/MarketEnum";
import { LogoEnum } from "@Safemate/Modals/Logo/LogoEnum";

const Label = styled.label`
  text-transform: capitalize;
  display: block;
  @media only screen and (min-width: 992px) {
    padding-left: 20px;
  }
`;

const LabelNoCapitalize = styled.label`
  text-transform: none;
  display: block;
  @media only screen and (min-width: 992px) {
    padding-left: 20px;
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  padding-bottom: 10px;
`;

const StyledMainRow = styled(Row)`
  margin-bottom: 10px;
  @media (min-width: 992px) {
    display:flex;
    align-items:center;
  }
  .col-md-6 .col-md-4{
    padding-left: 0px;
  }
  .col-md-6 .col-md-4 label{
    margin-top:10px;
  }
  .col-md-6 .col-md-9{
    padding-left: 0px;
  }

`;

export const Email = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <LabelNoCapitalize>{formatMessage({id: "emailInvoice", defaultMessage: "Faktura pr. e-post"})}</LabelNoCapitalize>
      </Col>
      <Col md={8}>
      <StyledField
          type="text"
          name="email"  
        />
      </Col>
    </StyledMainRow>
  )
})

export const Reference = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "addrReference", defaultMessage: "Referanse"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
          type="text"
          name="addrReference"
          
        />
      </Col>
    </StyledMainRow>
  )
})

export const AddressOne = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
          <Col md={4}>
            <Label>{formatMessage({id: "addressLine1", defaultMessage: "Adresselinje 1"})} *</Label>
            </Col>
            <Col md={8}>
            <StyledField
                type="text"
                name="addressLine1"
                
              />
          </Col>
    </StyledMainRow>
  )
})

export const AddressTwo = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
          <Col md={4}>
          <Label>{formatMessage({id: "addressLine2", defaultMessage: "Adresselinje 2"})}</Label>
          </Col>
          <Col md={8}>
            <StyledField
                type="text"
                name="addressLine2"
                
              />
          </Col>
    </StyledMainRow>
  )
})

export const Post = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "addrCity", defaultMessage: "Poststed"})} *</Label>
        </Col>
        <Col md={8}>

        <StyledField
          type="text"
          name="addrCity"
          
        />
        </Col>
    </StyledMainRow>
  )
})

export const FirstName = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "firstName", defaultMessage: "Fornavn"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
            type="text"
            name="firstName"
          />
      </Col>
    </StyledMainRow>
  )
})

export const LastName = injectIntl(({ intl: { formatMessage }}) => {
  return (
    <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "lastName", defaultMessage: "Etternavn"})}</Label>
      </Col>
      <Col md={8}>
        <StyledField
            type="text"
            name="lastName"
          />
      </Col>
    </StyledMainRow>
  )
})


export const Land = 
  withFormWrapper<BillingSettings>(({ formik: { setFieldValue, values: { country } }}, marketProgId) => {
    const { formatMessage } = useIntl();

    const CountryMethod = React.useMemo(() => {
      return [
        {value: "nbno", text: formatMessage({id: "Norway", defaultMessage: "Norway"})},
        {value: "SE", text: formatMessage({id: "Sweden", defaultMessage: "Sweden"})},
        {value: "DK", text: formatMessage({id: "Denmark", defaultMessage: "Denmark"})},
        {value: "IS", text: formatMessage({id: "Iceland", defaultMessage: "Iceland"})}
      ]
    }, [country])

    return (
      <StyledMainRow>
        <Col md={4}>
          <Label>{formatMessage({id: "country", defaultMessage: "Land"})} *</Label>
        </Col>
        <Col md={8}>
          <Dropdown
            options={CountryMethod}
            onChange={(option) => {
              const { value } = option as Option;
              setFieldValue("country", value);
            }}
            initial={country}
            size={{width: "200px"}}
          />  
        </Col>
      </StyledMainRow>
    )
  })

export const Invoice =
  withFormWrapper<BillingSettings>(({formik: { setFieldValue, values: { pptyId } }}) => {

    const { formatMessage } = useIntl();

    const invoiceMethod = React.useMemo(() => {
      return [
        {value: "6", text: formatMessage({id: "e-post", defaultMessage: "e-post"})},
        {value: "4", text: formatMessage({id: "post", defaultMessage: "post"})}
      ]
    }, [])

    return (
      <StyledMainRow>
          <Col md={4}>
            <Label>{formatMessage({id: "invoiceMethod", defaultMessage: "Fakturametode"})}</Label>
          </Col>
          <Col md={8}>
            <Dropdown
                options={invoiceMethod}
                onChange={(option) => {
                  const { value } = option as Option;
                  setFieldValue("pptyId", value);
                }}
                initial={pptyId}
                size={{width: "200px"}}
              />
          </Col>
      </StyledMainRow>
    )
  })


const ScrollBox = styled.div`
  color: black;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 200px;
  padding: 10px;
  overflow-y: scroll;
`;

const BillingOkBtn = styled(Button)`
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 50px;
  justify-content: center;
`;

export const BillingHistory = injectIntl(({ intl: { formatMessage } }: WrappedComponentProps) => {

  const [ showBilling, setShowBilling ] = React.useState(false);

  return(
    <StyledRow>
      <Col md={12}>
          <Button className="billingButtonGo" onClick={() => setShowBilling(true)}>
            {formatMessage({id: "historyGo", defaultMessage: "History"})}
          </Button>
      </Col>
      <LogoModal
        logo={LogoEnum.SAFEMATE}
        isOpen={showBilling}
        title={formatMessage({id: "billingHistory", defaultMessage: "Billing History"})}
        footer={
          <BillingOkBtn
            onClick={() => setShowBilling(false)}
          >
            {formatMessage({id: "ok", defaultMessage: "OK"})}
          </BillingOkBtn>
        }
      >
        <ScrollBox dangerouslySetInnerHTML={{ __html: formatMessage({id: "invoiceHistory", defaultMessage: "Billing History is not yet implemented. Please contact invoice@safemate.no for invoice questions."})}}/>
      </LogoModal>
    </StyledRow>
  )
})

interface ZipProps{
  getPostCity: Routine<ActionFunctionAny<Action<any>>>;
  postCity: PostCity;
  market: MarketEnum;
}
const mapStateToPropsZip = ( { appSettings: { market }, billing: {customer: {postCity}}} : AppState) => {
  return{
    postCity,
    market
  }
};
const mapDispatchFromStateZip = {
  getPostCity
}

export const Zip = connect(mapStateToPropsZip, mapDispatchFromStateZip)(
  withFormWrapper<BillingSettings, ZipProps>(
    ({formik: { setFieldValue }, postCity, getPostCity, market }) => {

      const enablePostAutoFill = market === MarketEnum.NORWAY;
      
      const [ postalCity, setPostalCity ] = React.useState("");
      const { formatMessage } = useIntl();
      
      React.useEffect(() => {
        if(postalCity != postCity.result){
          setPostalCity(postCity.result);
          setFieldValue("addrCity", postCity.result);
        }
      })
      

      const getPostalCity = (e: React.FormEvent<HTMLInputElement>) => {
        if(e.currentTarget.value.length == 4 && enablePostAutoFill){
          getPostCity(e.currentTarget.value);
        }
      }

    return(
      <StyledMainRow>
      <Col md={4}>
        <Label>{formatMessage({id: "addrZipCode", defaultMessage: "Postnummer"})} *</Label>
      </Col>      
      <Col md={8}>
        <StyledField
            type="text"
            name="addrZipCode"
            onKeyUp={getPostalCity}
          />
      </Col>
    </StyledMainRow>
    )
  })
)

const mapBillingInformationStateToProps = ({ billing: {customer: { customer }} }: AppState) => {
  return {
    customer
  }
}

interface BillingInformationProps{
  customer: Customer;
}

export const BillingInformation = connect(mapBillingInformationStateToProps)(
  formikConnect<BillingInformationProps, BillingSettings>(
    ({ customer }) => {

      const [ billingInfoAccepted, setBillingInfoAccepted ] = React.useState(customer.billingAccepted);
      const { formatMessage } = useIntl();

      const handleBillingAccept = () => {
        setBillingInfoAccepted(true);
      }

      return(
        <StyledRow>
          <LogoModal 
            logo={LogoEnum.SAFEMATE}
            isOpen={!billingInfoAccepted}
            title={formatMessage({id: "billing", defaultMessage: "Billing"})}
            footer={
              <BillingOkBtn
                onClick={() => handleBillingAccept()}
              >
                {formatMessage({id: "iUnderstand", defaultMessage: "I UNDERSTAND"})}
              </BillingOkBtn>
            }
          >
            <ScrollBox dangerouslySetInnerHTML={{ __html: formatMessage({id: "billingInformation001", defaultMessage: "Before you can add Safemate devices to your account, we need to have a billing address to bill your Safemate monthly subscription fee."})}}/>
          </LogoModal>
        </StyledRow>
      )
  })
)
