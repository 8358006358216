import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as DOMPurify from "dompurify";

enum Status{
  UNMOUNTED = "unmounted",
  EXITED = "exited",
  ENTERING = "entering",
  ENTERED = "entered",
  EXITING = "exiting"
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

interface FeedbackBoxProps{
  show: boolean;
  duration: number;
  msg: string;
  setShow: Function;
  danger: boolean;
  small?: boolean;
}

export const FeedbackBox = ({show, duration, msg, setShow, danger, small}: FeedbackBoxProps) => {

  const [ status, setStatus ] = React.useState<Status>(Status.UNMOUNTED);

  const {formatMessage } = useIntl();

  let exitTimeout: NodeJS.Timeout = null;

  React.useEffect(() => {
    if(show){
      performEnter();
      exitTimeout = setTimeout(() => {
        setShow(false);
      }, duration)
    }
  }, [])

  React.useEffect(() => {
    clearTimeout(exitTimeout);
    exitTimeout = setTimeout(() => {
      setShow(false);
    }, duration)
  }, [msg])

  React.useEffect(() => {
    let nextStatus = null;
    if(show){
      if(status !== Status.ENTERING && status !== Status.ENTERED){
        nextStatus = Status.ENTERING;
      }
    }
    else{
      if(status === Status.ENTERING || status === Status.ENTERED){
        nextStatus = Status.EXITING;
      }
    }
    updateStatus(nextStatus);
  }, [show, status])

  const updateStatus = (nextStatus: Status) => {
    if (nextStatus !== null) {
      if (nextStatus === Status.ENTERING) {
        performEnter();
      } else {
        performExit();
      }
    } else if (status === Status.EXITED) {
      setStatus(Status.UNMOUNTED);
    }
  }

  const performEnter = () => {
    setStatus(Status.ENTERING);
    setTimeout(() => {
      if(danger){
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      setStatus(Status.ENTERED);
    }, 0 )
  }

  const performExit = () => {
    setStatus(Status.EXITING);
    setTimeout(() => {
      setStatus(Status.EXITED);
    }, 500 )
  }


  const purifiedMessage = DOMPurify.sanitize(msg);


  if(status === Status.UNMOUNTED) return null;
  
  if(small){
    return(
      <Row>
          <Col md={12}>
            <div className={`alert ${danger ? "alert-danger": ""}`} role="alert">
              <button type="button" className="close" aria-label="Close" onClick={() => {setShow(false)}}>
                <span aria-hidden="true">&times;</span>
              </button>
              <span dangerouslySetInnerHTML={{__html: formatMessage({id: purifiedMessage, defaultMessage: purifiedMessage})}}></span>
					</div>
          </Col>
        </Row>
    )
  }

  return(
    <Grid
        style={{
          marginTop: "20px",
          transition: `opacity 0.5s ease-in-out`,
          opacity: 0.1,
          ...transitionStyles[status]
        }}
      >
        <Row>
          <Col md={12}>
            <div className={`alert ${danger ? "alert-danger": ""}`} role="alert">
              <button type="button" className="close" aria-label="Close" onClick={() => {setShow(false)}}>
                <span aria-hidden="true">&times;</span>
              </button>
              <span dangerouslySetInnerHTML={{__html: formatMessage({id: purifiedMessage, defaultMessage: purifiedMessage})}}></span>
					</div>
          </Col>
        </Row>
      </Grid>
  )
}
