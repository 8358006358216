import DeleteModalBody from "../../../EventSetupNew/Delete";
import * as React from "react";
import { useIntl } from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import { FieldArray } from "formik";
import { SettingEnum, SettingPrefixEnum } from "../settingEnum";
import { EventSetupSettings } from "../types";

interface DeleteProps{
  display: (display: boolean) => void;
  index: number;
}

export const Delete = withFormWrapper<EventSetupSettings, DeleteProps>(({display, index, formik: { setFieldValue, values: { deviceEventSetups }}}) => {

  const { formatMessage } = useIntl();

  const close = () => {
    display(false);
  }
  
  const prefix = `${SettingEnum.EVENT_SETUP}`; 

  return(
    <FieldArray
      name={prefix}
      render={(arrayHelpers) => (
      <DeleteModalBody
          //translations={formatMessage}
          headerText={formatMessage({
            id: 'deleteRecipientHeader',
            defaultMessage: 'Slett alarmmottaker'
          })}
          bodyText={`${formatMessage({
            id: 'deleteRecipientText',
            defaultMessage:
              'Er du sikker på at du vil slette denne alarmmottakeren?'
          })}`}
          handleCancel={close}
          confirmDelete={() => {
            deviceEventSetups.filter(setup => setup.index > index).forEach(setup => {
              setFieldValue(`${prefix}.${setup.index}.index`, setup.index - 1);
            })
            deviceEventSetups.filter(setup => setup.sort > deviceEventSetups[index].sort).forEach(setup => {
              setFieldValue(`${prefix}.${setup.index}.sort`, setup.sort - 1);
            })
            arrayHelpers.remove(index);
            close();
          }}
        />
      )}
      />
  )
})
