import {Customer, MappedSubOrgs} from "../Store/types";
import * as React from "react";
import {selectCustomer} from "../Store/routines";
import {connect} from "react-redux";
import {CustomerRow} from "./styled";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import {GlobalRoles} from "../../GlobalRoles";
import {accessToCustomer, checkPermissionsForCustomer} from "../../Policy/rightsUtil";
import { AppState } from "@Safemate/Store/types";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";


interface CustomerListProps{
  customersForList: Customer[];
  customersForForm: MappedSubOrgs;
  selectedCustomer: Customer;
  selectCustomer: (customerId: Customer, customerKey:number) => void;
}

const CustomerList = ({ customersForList, customersForForm, selectedCustomer, selectCustomer }: CustomerListProps) =>{

  const select = (customer: Customer, customerKey:number) =>
    selectedCustomer.customerId !== customer.customerId &&
    selectCustomer(customer, customerKey)

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  return(
    <React.Fragment>
      {customersForList.map((customer: Customer, index) => {

        const hasAccess = checkPermissionsForCustomer(permissions, PolicyNameEnum.SUB_ORGS, customer.customerId);
        const customerKey = index;
        return(
          <CustomerRow
            active={selectedCustomer.customerId === customer.customerId}
            level={customer.level}
            hasAccess={hasAccess}
            key={customer.customerId}
            onClick={() => hasAccess && select(customer, customerKey)}
            onKeyDown={(event) => event.key === "Enter" && hasAccess && select(customer, customerKey)}
            tabIndex={hasAccess ? 0 : undefined}
          >
            {customer.customerName} ({customersForForm[customer.customerId] ? customersForForm[customer.customerId].numberOfDevices : 0})
          </CustomerRow>
        )
      })}
    </React.Fragment>
  )
}

const mapStateToProps = ({ subUnits: {customer: { customersForList, customersForForm, selectedCustomer }}}: AppState) => ({
  customersForList,
  customersForForm,
  selectedCustomer
})

const mapDispatchToProps = {
  selectCustomer: selectCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);