import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/INITIALIZE'
);

export const initializeUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/INITIALIZE_USER'
);

// Transactions
export const getTransactions: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_TRANSACTIONS'
);
export const getTransactionsForAll: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_TRANSACTIONS_FOR_ALL'
);
export const getMoreTransactions: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_MORE_TRANSACTIONS'
);
export const getAddress: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_ADDRESS'
);
export const getPositions: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_POSITIONS'
);

export const getPositionsUuid: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_POSITIONS_UUID'
);
export const getPosition: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_POSITION'
);
export const getDatesWithTxForDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_DATES_WITH_TX_FOR_DEVICE'
);
export const getTransactionsForDate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_TRANSACTIONS_FOR_DATE'
);

// Device
export const getDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_DEVICE'
);
export const getAllDevices: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_ALL_DEVICES'
);
export const getCurrentPosition: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_CURRENT_POSITION'
);
export const getBatteryForRange: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_BATTERY_FOR_RANGE'
);
export const getSignalForRange: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_SIGNAL_FOR_RANGE'
);

// Alarm analysis
export const alarmAnalysis: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/ALARM_ANALYSIS'
);

// Notification event
export const getNotificationEvents: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/NOTIFICATION_EVENTS'
);

export const setMapConsent: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/SET_MAP_CONSENT'
);

// Policy
export const getPolicies: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Map/GET_POLICIES'
);

//Geofence

export const fetchGeofence: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_GEOFENCE'
);
export const fetchGlobalGeofence: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Settings/FETCH_GLOBAL_GEOFENCE'
);
