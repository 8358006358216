import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@AddSafemate/INITIALIZE'
);

// Device
export const addDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@AddSafemate/ADD_DEVICE'
);
export const getTwin: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@AddSafemate/GET_TWIN'
);
export const checkTwinSerialNumber: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@AddSafemate/CHECK_TWIN_SERIAL_NUMBER'
);