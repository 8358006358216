import styled from 'styled-components';

const Signal = styled.div`
  width: 24px;
  height: 24px;
  background: gray;
  border-radius: 50%;
`;

export default Signal;
