import {all, fork} from "redux-saga/effects";
import roleSaga from "./role";
import initializationSaga from "./initialization";

function* RoleManagerSaga(){
  yield all([
    fork(roleSaga),
    fork(initializationSaga)
  ])
}
export default RoleManagerSaga;
