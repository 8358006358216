import {WrappedComponentProps, injectIntl} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import {AccessPoint, IndoorLocation, IndoorLocationFormik} from "../types";
import {connect} from "react-redux";
import {
  setDisplayEdit, SetDisplayEdit,
  setDisplayMap, SetDisplayMap,
  setScanSuccess, SetScanSuccess,
  setDisplayIndoorDevices, SetDisplayIndoorDevices,
  setScanStart, SetScanStart, setScanInProgress, SetScanInProgress , setWebsocketClient, SetWebsocketClient, setSubscription, SetSubscription
} from "@Safemate/Settings/Store/actions";
import {Col, Row} from "react-bootstrap";
import {
  addAccessPoints,
  validateIndoorLocation,
  defaultIndoorLocationDeviceAdd
} from "@Safemate/Settings/IndoorLocation/utils";
import {Client, IMessage, StompSubscription} from "@stomp/stompjs";
import {connectWebsocket} from "@Safemate/Websocket";
import AddSafemate from "@Icons/menu/AddSafemate";
import {
  AccentedText, ButtonContainer, FooterNoBorder, IndoorHeading,
  Margined1EMRow,
} from "@Safemate/Settings/IndoorLocation/indoorLocationComp";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {scanWifiIndoorLocation, scanWifiIndoorLocationFailure} from "@Safemate/Settings/Store/routines";
import {LoaderWrap} from "@Safemate/UserAdmin/Users/Edit/styles";
import {CancelButton, IconBtn, SaveButton, SaveButtonMarginedRight, ScanText} from "@Safemate/Settings/IndoorLocation";
import { checkPermissionsForCustomer } from "@Safemate/Policy/rightsUtil";
import {PolicyNameEnum} from "@Safemate/PolicyNameEnum";
import {DeviceHardwareEnum} from "@Safemate/DeviceHardwareEnum";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import {confirmModal} from "@Safemate/Settings/Components/modal";
import {Device} from "@Safemate/Settings/types";



interface FooterProps extends WrappedComponentProps{
  displayMap: boolean;
  displayEdit: boolean;
  device: Device;
  displayIndoorDevices: boolean;
  valuesChanged: boolean;
  setDisplayEdit: SetDisplayEdit;
  setDisplayMap: SetDisplayMap;
  setDisplayIndoorDevices: SetDisplayIndoorDevices;
  scanWifiIndoorLocation: Routine<ActionFunctionAny<Action<any>>>;
  scanWifiIndoorLocationFailure: Routine<ActionFunctionAny<Action<any>>>;
  setScanSuccess: SetScanSuccess;
  setScanStart: SetScanStart;
  setScanInProgress: SetScanInProgress;
  scanStart: boolean;
  scanInProgress: boolean;
  scanSuccess: boolean;
  indoorLocation: IndoorLocation;
  client: Client | null;
  subscriptions: Map<string, StompSubscription> | null;
  setWebsocketClient: SetWebsocketClient;
  setSubscription: SetSubscription;
  ownedByCustomer: number;
}

const mapStateToProps = ({ settings: {device:{ device }, indoorLocation: { indoorLocation, displayMap, displayEdit, displayIndoorDevices, valuesChanged, scanSuccess, scanStart, scanInProgress}, websocket:{ client, subscriptions },initialization: {deviId}, device: { device: { ownedByCustomer, deviceSerialNumber, deviceName , dehaId} }}}: AppState) => {
  return {
    indoorLocation, displayMap, displayEdit, displayIndoorDevices, valuesChanged, device, scanSuccess, scanStart, scanInProgress, client, subscriptions, ownedByCustomer
  }
}

const mapDispatchToProps = {
  setDisplayEdit,
  setDisplayMap,
  setDisplayIndoorDevices,
  setScanSuccess,
  setScanStart,
  setScanInProgress,
  scanWifiIndoorLocation,
  scanWifiIndoorLocationFailure,
  setWebsocketClient,
  setSubscription,
};


export const Footer = injectIntl(withFormWrapper<IndoorLocationFormik, FooterProps>(
  ({formik: { setFieldValue, values, dirty }, intl: { formatMessage },
     displayMap, displayEdit, displayIndoorDevices, valuesChanged
     , setDisplayEdit, setDisplayMap, device
     , scanSuccess,scanWifiIndoorLocation, setDisplayIndoorDevices, setScanSuccess
     , indoorLocation, setScanStart, setScanInProgress, scanStart, scanInProgress, scanWifiIndoorLocationFailure
     , client, setWebsocketClient, subscriptions, setSubscription, ownedByCustomer
   }) => {

    const [ scannedDevices, setScannedDevices ] = React.useState(0);

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const isNew: boolean = values.indoorLocation.inloId < 1;

    const sharedAccess = checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_INDOOR_CUSTOMER, ownedByCustomer);
    const sharedLocation = values.indoorLocation.custId > 0 && values.indoorLocation.deviId == 0;
    const disabledEdit: boolean = sharedLocation ? !sharedAccess : false;
    const timeOutMillis = device.dehaId === DeviceHardwareEnum.TRIGGER_TWO_4G ? 180000 : 60000;

      const {Comp, func} = confirmModal();

    React.useEffect(() => {
      setScanStart(true);
      return () => {
        stopSocket();
      }
    }, []);

    React.useEffect(() => {
      let time: any = null;
      if(scanInProgress){
          startSocket();
        time = setTimeout(() => {
          cancelScan(true)
        }, timeOutMillis);
      }
      return () => {
        if(scanInProgress && time){
          clearTimeout(time);
          stopSocket();
        }
      }
    }, [scanInProgress]);

    React.useEffect(() => {
      setFieldValue(`indoorLocation`, indoorLocation);
    }, [indoorLocation]);


    const startSocket = () => {

      if(!device.deviId) return;

      if(client && !client.active){
        client.activate();
      } else {
        const socket = connectWebsocket();

        if(!socket.active){
          socket.activate();
        }
        setWebsocketClient(socket);
      }

      if(client.connected){
        setSubscription({ key: "accesspoints", subscription: client.subscribe(`/topic/accesspointscan/${device.deviceSerialNumber}`, (message: IMessage) => {
            handleAccessPointScanEvent(message);
          }) });
      }
      else{
        client.onConnect = (frame) => {
          setSubscription({ key: "accesspoints", subscription: client.subscribe(`/topic/accesspointscan/${device.deviceSerialNumber}`, (message: IMessage) => {
              handleAccessPointScanEvent(message);
            }) });
        }
      }

      if(!client.active){
        client.activate();
      }

      client.onDisconnect = () => {
        stop();
      }

    };

    const stopSocket = () => {
      if (client && client.active) {
        let subscription: StompSubscription | null = subscriptions.get("accesspoints");
        if(subscription){
          subscription.unsubscribe();
          subscriptions.delete("accesspoints");
        }
      }
    };

    const cancelScan = (clear?:boolean) => {
      if(clear){
        scanWifiIndoorLocationFailure("scanTimedOut");
      }
      stopSocket();
      setScanStart(true);
    }

    const cancel = () => {
      cancelScan(false);
    }

    const cancelMap = ( ) => {
      if(isNew){
        setScanSuccess(true);
        setDisplayMap(false);
      }
    }

    const handleAccessPointScanEvent = (message: IMessage) => {
      const accessPoints: AccessPoint[] = JSON.parse(message.body);
      addAccessPoints(values.indoorLocation, accessPoints);
      setScannedDevices(accessPoints.length);
      setFieldValue("indoorLocation.indoorLocationDevices", values.indoorLocation.indoorLocationDevices);
      cancel();
      setScanSuccess(true);
    }

    const startScanEvent = () => {
        if(device.twin){
            func(true);
        } else {
            scanWifiIndoorLocation(device.deviId);
        }

    }

    const addIndoorLocationDevice = () => {
      values.indoorLocation.indoorLocationDevices.push(defaultIndoorLocationDeviceAdd());
      setFieldValue("indoorLocation.indoorLocationDevices", values.indoorLocation.indoorLocationDevices);
    }

    const validateValues: boolean = validateIndoorLocation(values.indoorLocation);

    const changed = valuesChanged || dirty;



    return(
      <React.Fragment>
        { isNew && scanStart && <React.Fragment>
          <Margined1EMRow>
            <Col xs={12} sm={12} md={12}>
              <Row>
                <Col md={12} style={{paddingBottom: "15px", textAlign: "center"}}>
                  <ScanText>{`${formatMessage({id: "indoorLocationStartScanText",defaultMessage: "Make sure to place the device in the desired location for scan"})}`}</ScanText>
                </Col>
                <Col xs={12} md={4} mdOffset={4}>
                  <IconBtn  onClick={() => startScanEvent()}><AddSafemate inline />&nbsp;{formatMessage({id: "indoorLocationStartScan", defaultMessage: "Start scan"})}</IconBtn>
                </Col>
              </Row>
            </Col>
          </Margined1EMRow>
            { device.twinDevice &&
                <Comp
                    title={`${formatMessage({id: "indoorLocationStartScan", defaultMessage: "Start scan"})}`}
                    body={
                        <React.Fragment>
                            <IconBtn style={{width: "100%"}} onClick={() => {scanWifiIndoorLocation(device.deviId); func(false);} }><AddSafemate inline />&nbsp;{ device.deviceName } ({ device.deviceSerialNumber })</IconBtn>
                            <IconBtn style={{marginTop:"20px", width: "100%"}} onClick={() => {scanWifiIndoorLocation(device.twinDevice.deviId); func(false);}}><AddSafemate inline />&nbsp;{ device.twinDevice.deviceName } ({ device.twinDevice.deviceSerialNumber }</IconBtn>
                        </React.Fragment>
                    }
                />
            }
        </React.Fragment> }

        { scanInProgress && <React.Fragment>
          <Margined1EMRow>
            <Col xs={12} sm={12} md={12}>
              <Row>
                <Col md={12} style={{paddingBottom: "15px", textAlign: "center"}}>
                  <ScanText>{`${formatMessage({id: "addIndoorLocationScanText",defaultMessage: "Please wait while device scan for networks."})}`}</ScanText>
                  <div style={{paddingTop: "25px"}}><LoaderWrap><Loader/></LoaderWrap></div>
                </Col>
              </Row>
            </Col>
          </Margined1EMRow>
        </React.Fragment> }

        { scanSuccess && isNew && <React.Fragment>
          <Margined1EMRow>
            <Col xs={12} sm={12} md={12}>
              <Row>
                <Col style={{paddingBottom: "15px", textAlign: "center"}}>
                  <IndoorHeading>{formatMessage({id: "wifiLocationScanSuccessfulHeader", defaultMessage: "Scan successful"})}</IndoorHeading>
                </Col>
                <Col md={12} style={{paddingBottom: "15px", textAlign: "center"}}>
                  <ScanText>{`${scannedDevices} ${formatMessage({id: "addIndoorLocationScanCompleteText", defaultMessage: "wifi lookup found for "})} `}</ScanText>
                  <AccentedText>{`${device.deviceName} (${device.deviceSerialNumber})`}</AccentedText>
                </Col>
                <Col md={12} style={{paddingBottom: "15px", textAlign: "center"}}>
                  <ScanText>{`${formatMessage({id: "addIndoorLocationScanCompleteTextLocatePin", defaultMessage: "Locate the wifi location on map"})} `}</ScanText>
                </Col>
              </Row>
            </Col>
          </Margined1EMRow>
        </React.Fragment> }


        <FooterNoBorder>

            {
              isNew && ( (scanInProgress || scanSuccess) || displayMap || displayIndoorDevices || scanSuccess || displayMap || displayIndoorDevices ) &&
              <ButtonContainer>
                {
                  (scanInProgress || scanSuccess) &&
                  <CancelButton onClick={() => cancel() }>
                    {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
                  </CancelButton>
                }
                {
                  displayMap &&
                  <CancelButton onClick={() => cancelMap()}>
                    {formatMessage({id: "addIndoorLocationCancelMap", defaultMessage: "Back"})}
                  </CancelButton>
                }
                {
                  displayIndoorDevices &&
                  <CancelButton onClick={() => setDisplayMap(true)}>
                    {formatMessage({id: "addIndoorLocationCancelName", defaultMessage: "Back"})}
                  </CancelButton>
                }
                {
                  scanSuccess &&
                  <SaveButton onClick={() => {setScanSuccess(false);setDisplayMap(true); }}>
                    {formatMessage({id: "addIndoorLocationScanCompleteButton", defaultMessage: "Locate on map"})}
                  </SaveButton>
                }
                {
                  displayMap &&
                  <SaveButton onClick={() => setDisplayIndoorDevices(true)}>
                    {formatMessage({id: "addIndoorLocationMapCompleteButton", defaultMessage: "Next"})}
                  </SaveButton>
                }
                {
                  displayIndoorDevices &&
                  <SaveButton  disabled={!changed || !validateValues} type="submit">
                    {formatMessage({id: "addIndoorLocationSave", defaultMessage: "Save"})}
                  </SaveButton>
                }
              </ButtonContainer>
            }

            {
              !isNew && ( (displayIndoorDevices && !disabledEdit) || (!displayEdit) ||  (!disabledEdit && !displayEdit) ) &&
              <ButtonContainer>

                { displayIndoorDevices && !disabledEdit &&
                  <React.Fragment>
                    <SaveButtonMarginedRight onClick={() => startScanEvent()}>
                      {formatMessage({id: "indoorLocationScanMoreDevice", defaultMessage: "Scan more devices"})}
                    </SaveButtonMarginedRight>
                    <SaveButtonMarginedRight onClick={() => addIndoorLocationDevice()}>
                      {formatMessage({id: "indoorLocationAddDevice", defaultMessage: "Add device"})}
                    </SaveButtonMarginedRight>
                  </React.Fragment>

                }
                {
                  !displayEdit &&
                  <CancelButton onClick={() => setDisplayEdit(true)}>
                    {formatMessage({id: "back", defaultMessage: "Back"})}
                  </CancelButton>
                }

                { !disabledEdit && !displayEdit &&
                  <SaveButton  disabled={!changed || !validateValues} type="submit">
                    {formatMessage({id: "save", defaultMessage: "Save"})}
                  </SaveButton>
                }

              </ButtonContainer>
            }
        </FooterNoBorder>
      </React.Fragment>
    )}
))

export const FooterIndoorLocation = connect(mapStateToProps, mapDispatchToProps)(Footer);
