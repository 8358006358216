import * as React from "react";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { RootState } from "@Safemate/Settings/Store/types";
import { connect } from "react-redux";
import SettingTab from "@Safemate/Settings/Components/settingTab";
import { SettingEnum } from "@Safemate/Settings/settingEnum";
import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { inactivityOptions, warnTimeOptions } from "../options";
import { TIME_OFFSET, status } from "@Elements/Form/utils";
import { FieldWrapper, InactivityWrapper, PeriodText, PeriodWrapper, StyledInput } from "../styles";
import { Col } from "react-bootstrap";
import withFormWrapper from "@Elements/Form/formWrapper";
import { useIntl } from "react-intl";
import Tooltip from "@Elements/Tooltip";
import InformationIcon from "@Icons/Information1";
import { TitleWrap } from "@Elements/Form/styles";
import { saveInactivity } from "@Safemate/Settings/Store/routines";
import { InactivitySettings } from "@Safemate/Settings/General/types";
import { AppState } from "@Safemate/Store/types";
import { Field } from "formik";


interface InactivityProps{
  inactivity: InactivitySettings;
  dehaId: number;
  deviId: number;
  saveInactivity: Routine<ActionFunctionAny<Action<any>>>;
}

const Inactivity = ({ inactivity, dehaId, deviId, saveInactivity }: InactivityProps) => {

  return (
    <SettingTab
      dehaId={dehaId}
      deviId={deviId}
      settings={inactivity}
      save={saveInactivity}
      settingEnum={SettingEnum.INACTIVITY_EVENT}
      header={{
        title: "noActivityAlarm",
        tooltip: "inactivitySettingsTabToolTipText",
        tooltipTitle: "noActivityAlarm",
        toggle: true,
        toggleValue: ["inactivityFunction"]
      }}
    />
  )
}

const mapStateToProps = ({ settings: {general: { inactivity }, initialization: { deviId, dehaId }}}: AppState) => {
  return{
    inactivity,
    dehaId,
    deviId
  }
}

const mapDispatchToProps = {
  saveInactivity
}

export default connect(mapStateToProps, mapDispatchToProps)(Inactivity);

interface InactivityFieldProps{
  intervalName: string;
  periodToName: string;
  periodFromName: string;
  count: number;
}

export const InactivityField = withFormWrapper<InactivitySettings, InactivityFieldProps>(
  ({ formik: { values, setFieldValue }, intervalName, periodToName, periodFromName, count }) => {

  const { formatMessage } = useIntl();

  const Interval = DropdownWrap(
    intervalName, 
    {
      title: {id: "interval", defaultMessage: "Intervall"},
      tooltip: "inactivityLimitToolTipText",
      tooltipTitle: {id: "inactivityLimitToolTipTitle", defaultMessage: "Inaktivitetsgrense"},
      dataOptions: {
        options: inactivityOptions,
        offset: TIME_OFFSET.SECONDS_IN_SECOND
      },
      width: "250px"
    }
  );

/*
style={{padding: "0px 15px"}}
        type="time"
        id="timeInput"
        step="60"
        value={
          this.state.value === false
            ? this.props.value
              ? this.props.value
              : ''
            : this.state.value
        }
        onChange={this.handleChange}
        onBlur={this.handleBlur}*/

  return (
    <React.Fragment>
      <TitleWrap right>
        {`${formatMessage({id: "inactivitySchedule", defaultMessage: "Tidsskjema"})} ${count}`}
      </TitleWrap>
      <InactivityWrapper>
        <Col md={6}>
          <Interval/>
        </Col>
        <Col md={6}>
        <FieldWrapper>
          <PeriodWrapper>
            <PeriodText>
              {formatMessage({id: "inactivityFromParagraphTitle", defaultMessage: "Fra:"})}
            </PeriodText>
            <StyledInput
              type="time"
              name={periodFromName}
              step="60"
            />
          </PeriodWrapper>
          <PeriodWrapper>
            <PeriodText>
              {formatMessage({id: "inactivityToParagraphTitle", defaultMessage: "Til:"})}
            </PeriodText>
            <StyledInput
              type="time"
              name={periodToName}
              step="60"
            />
          </PeriodWrapper>
          &nbsp;
          <Tooltip
            labelId="inactivityPeriodToolTipText"
            defaultMessage="Her angis tidsrommet det aksepteres at man ikke måler livstegn."
            popoverId={`${periodFromName}-popover`}
            title={formatMessage({id: "inactivityPeriodToolTipTitle", defaultMessage: "Periode"})}
          >
            <InformationIcon size="medium" />
          </Tooltip>
        </FieldWrapper>
        </Col>
      </InactivityWrapper>
    </React.Fragment>
  );
});

export const InactivityWarnTime = 
  DropdownWrap(
    "inactivityWarnTime", 
    {
      title: {id: "inactivityAlertToolTipTitle", defaultMessage: "Inaktivitetsgrense"},
      tooltip: "inactivityAlertToolTipText",
      tooltipTitle: {id: "inactivityAlertToolTipTitle", defaultMessage: "Inaktivitetsgrense"},
      dataOptions: {
        options: warnTimeOptions,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      width: "250px"
    }
  );