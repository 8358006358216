import * as React from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { Row } from "react-bootstrap";

import {NameWrapper, StyledField, TitleField} from ".";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";
import styled from "styled-components";
import Edit from "@Icons/Edit";
import { Field } from "formik";

const CenteredRow = styled(Row)`
  text-align: center;
`;

const Name = injectIntl(withFormWrapper<GeofenceValues, InjectedIntlProps>(({ formik: { values: { geofence } } }) => {

  const [editName, setEditName] = React.useState(false);

  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if(ref.current)
      ref.current.focus();
  }, [editName])
  if( geofence.editEnabled ){
    return(
      <div style={{marginRight: "0.5em"}}>
        {editName
          ? <StyledField style={{width: "88%", height: "30px", marginRight: "0.5em"}} name="geofence.name" onBlur={() => setEditName(false)} />
          : <span style={{marginRight: "1em"}} onClick={() => setEditName(true)}>{geofence.name}</span>}
        <Edit inline onClick={() => setEditName(!editName)}/>
      </div>
    )
  } else {
    return <span>{geofence.name}</span>
  }


}))

export default Name;
