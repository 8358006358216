import { DropdownWrap } from "@Elements/Form/DropdownWrap";
import { powerFailureReportOpts } from "./options";
import { TIME_OFFSET, status } from "@Elements/Form/utils";

export const PowerFailureReport = 
  DropdownWrap(
    "powerFailureReportDelay", 
    {
      title: {id: "batteryAndPowerPowerParagraphTitle", defaultMessage: "Strømbruddsforsinkelse"},
      tooltip: "batteryAndPowerPowerToolTipText",
      tooltipTitle: {id: "batteryAndPowerPowerToolTipTitle", defaultMessage: "Strømbruddsforsinkelse"},
      dataOptions: {
        options: powerFailureReportOpts,
        offset: TIME_OFFSET.SECONDS_IN_SECOND,
        nullValue: status.disable
      },
      width: "250px"
    }
  );