import { AppAction } from "@Safemate/Store/types";
import {DefaultSettingActionTypes as Actions} from "./actionTypes";

export const selectCustomer = (customerId: number): AppAction<number> => ({
  type: Actions.SET_CUSTOMER,
  payload: customerId
});

export const selectDeviceHardware = (deviceHardwareId: number): AppAction<number> => ({
  type: Actions.SET_DEVICE_HARDWARE_ID,
  payload: deviceHardwareId
})

export type SetReportsChanged= (changed : boolean) => AppAction<boolean>

export const reportsChanged: SetReportsChanged = (changed) => ({
  type: Actions.REPORTS_CHANGED,
  payload: changed
})
