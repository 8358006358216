import React from 'react';
import styled from 'styled-components';
import { Body, HeaderContent } from '@Safemate/Modals/DeleteModal';

const HeaderBackground = styled.div`
  background: ${props => props.theme.modalColors.headerBackground};
`;

const DeleteModalBody = props => {
  return (
    <div>
      <HeaderBackground>
        <HeaderContent headerText={props.headerText} />
      </HeaderBackground>
      <Body {...props} />
    </div>
  );
};

export default DeleteModalBody;
