import { TrackingState } from "../types";
import { Reducer } from "redux";
import { fetchTracking, saveTracking, updateAutoTracking, startTracking, stopTracking, updatePowerSaving, updateDevicePositioning, updateAcceptLBS } from "../routines";
import { SettingsActionTypes } from "../actionTypes";
import { TrackingDTO } from "../../types";

const trackingState: TrackingState = {
    settings:{
      s03InMinutes: 0,
      s03InSeconds: 0,
      inactiveTrackingInterval: 0,
      trackingIntervalNightInMinutes: 0,
      trackingNightStarts: "",
      trackingNightEnds: "",
      tracking: false,
      minNumSatellites: null,
      acceptLBS: false,
      autoTracking: false,
      disablePositioning: false,
    },
    autoTracking: false,
    powerSaving: false,
    trackingUntil: 0,
    advanced: false,
    gpsSatCriteriaWithDefault: "",
    disablePositioning: false,
    processingAutoTracking: false,
    processingDevicePositioning: false,
    processingAcceptLBS: false
};

const tracking: Reducer<TrackingState> = (state: TrackingState = trackingState, action: any) => {
  switch (action.type) {
    case saveTracking.SUCCESS:
    case fetchTracking.SUCCESS:
    case startTracking.SUCCESS:
    case stopTracking.SUCCESS:
      return assignTrackingState(action.payload, state);

    // case updateAutoTracking.TRIGGER:{
    //   return{
    //     ...state,
    //     processingAutoTracking: true
    //   }
    // }

    // case updateAutoTracking.FAILURE: {
    //   return {
    //     ...state,
    //     processingAutoTracking: false
    //   }
    // }

    case updateAutoTracking.SUCCESS: {
      return{
        ...state,
        autoTracking: action.payload
      }
    }

    case updateAcceptLBS.TRIGGER: {
      return{
        ...state,
        processingAcceptLBS: true
      }
    }

    case updateAcceptLBS.FAILURE: {
      return{
        ...state,
        processingAcceptLBS: false
      }
    }

    case updateAcceptLBS.SUCCESS: {
      return{
        ...assignTrackingState(action.payload, state),
        processingAcceptLBS: false
      }
    }

    // case updateDevicePositioning.TRIGGER:{
    //   return{
    //     ...state,
    //     processingDevicePositioning: true
    //   }
    // }

    // case updateDevicePositioning.FAILURE: {
    //   return {
    //     ...state,
    //     processingDevicePositioning: false
    //   }
    // }

    case updateDevicePositioning.SUCCESS: {
      return{
        ...state,
        disablePositioning: action.payload
      }
    }

    case SettingsActionTypes.SET_TRACKING_ADVANCED:
      return {
        ...state,
        advanced: action.payload
      }

    case updatePowerSaving.SUCCESS:
      return{
        ...state,
        powerSaving: action.payload
      }

    default:
        return state;
  }
};

const assignTrackingState = (data: TrackingDTO, state: TrackingState) => {
  const { autoTracking, powerSaving, trackingUntil, gpsSatCriteriaWithDefault, disablePositioning, ...rest} = data;
  return {
    ...state,
    settings: {...rest, autoTracking, disablePositioning, powerSaving},
    trackingUntil,
    powerSaving,
    autoTracking,
    gpsSatCriteriaWithDefault,
    disablePositioning,
  };
}

export default tracking;

