export interface IUser{
  userId: number;
  username: string;
  password: string;
  passwordClear: string;
  firstName: string;
  lastName: string;
  mobile: string;
  mailTo: string;
  languageCode: string;
  timezone: string;
  facebookUid: string;
  newsletter: boolean;
  proffwebDark: boolean;
  adminForCustId: number;
  beta: boolean;
  trainingApprovedBy: number;
  deleted: boolean;
  attemptsBeforeLockout: string;
  lastActivity: number;
  tfaEmail: boolean;
  tfaEnabled: boolean;
  gdprPsAccepted: boolean;
  gdprPsDatetime: string;
  deactivated: boolean;
  tempDeactivated: boolean;
  deactivatedFrom: string;
  deactivatedTo: string;
  userType: number;
  name: string;
  id: number;
  timeZoneOffset: number;
  active: boolean;
  trainingApprovedByName: string;
  coveredByTwoFactorAuthentication: boolean;
  email: string;
  existing: boolean;
  isNewUser: boolean;
  trained: boolean;
  zoneId: string;
  isExternal: boolean;
  emailAsUsername: boolean;
  nationalIdentityNumber: string;
  translator: boolean;
  mapConsent: boolean;
}

export const defaultUser = {
  userId: 0,
  username: "",
  password: "",
  passwordClear: "",
  firstName: "",
  lastName: "",
  mobile: "",
  mailTo: "",
  languageCode: "",
  timezone: "",
  facebookUid: "",
  newsletter: false,
  proffwebDark: false,
  adminForCustId: 0,
  beta: false,
  trainingApprovedBy: 0,
  deleted: false,
  attemptsBeforeLockout: "",
  lastActivity: 0,
  tfaEmail: false,
  tfaEnabled: false,
  gdprPsAccepted: false,
  gdprPsDatetime: "",
  deactivated: false,
  tempDeactivated: false,
  deactivatedFrom: "",
  deactivatedTo: "",
  userType: 0,
  name: "",
  id: 0,
  timeZoneOffset: 0,
  active: false,
  trainingApprovedByName: "",
  coveredByTwoFactorAuthentication: false,
  email: "",
  existing: false,
  isNewUser: false,
  trained: false,
  zoneId: "",
  isExternal: false,
  emailAsUsername: false,
  nationalIdentityNumber: "",
  translator: false,
  mapConsent: false
}
