import * as React from "react";
import {connect as formikConnect} from "formik";
import {connect as reduxConnect} from "react-redux";
import {Ldap, RootState} from "../../Store/types";
import {Option} from "../../../DefaultSettings/Settings/FieldWrapper";
import {InnerFrame, LoaderWrap} from "./styles";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {SelectLdap, selectLdap} from "../../Store/actions";
import {LabelEnum} from "../../LabelEnum";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { SiteTypeEnum } from "../../../SiteTypeEnum";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";
import { useIntl } from "react-intl";

interface LdapProps{
  ldap: Ldap;
  isNew: boolean;
  siteType: SiteTypeEnum;
  selectLdap: SelectLdap;
}

const mapStateToProps = ({ appData: {currentContext}, users: {user: {ldap}, action: {edit: {isNew}}}}: AppState) => {
  return{
    ldap,
    isNew,
    siteType: currentContext
  }
}

const mapDispatchToProps = {
  selectLdap
}

const LdapComp = reduxConnect(mapStateToProps, mapDispatchToProps)(
    formikConnect<LdapProps, UserWithCustomer>(
      ({formik: {setValues, values}, selectLdap, ldap: {ldapUsers, loading, selected}, isNew, siteType}) => {

        const { formatMessage } = useIntl();
        
        const [ldapSelected, setLdapSelected] = React.useState();

        React.useEffect(() => {
          if(!selected)
            setLdapSelected(null);
        }, [selected])

        if (((!ldapUsers || ldapUsers.length === 0) && !loading) || !isNew || siteType === SiteTypeEnum.AHP) return null;

        return (
          <InnerFrame border>
            <h2>
              LDAP
            </h2>
            {loading
              ? <LoaderWrap><Loader/></LoaderWrap>
              : <Dropdown
                  searchable
                  title={formatMessage({id: LabelEnum.SELECT_LDAP_USER, defaultMessage: "Velg LDAP bruker"})}
                  options={ldapUsers}
                  initial={ldapSelected}
                  onChange={(value: Option | Option[]) => {
                    const selected = (value as Option).value;
                    setLdapSelected(selected)
                    selectLdap(true);
                    setValues({
                      ...values,
                      user: {
                        ...values.user,
                        firstName: selected.firstName,
                        lastName: selected.lastName,
                        username: selected.username,
                        mailTo: selected.email,
                        mobile: selected.mobile
                      }
                    })
                  }}
                />
            }
          </InnerFrame>
        )
      }
    )
  )

export default LdapComp;
