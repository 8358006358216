import { FastField } from "formik";
import styled from "styled-components";

export const Header = styled.label`
  width: 100%;
  text-transform: capitalize;
`;

export const StyledField = styled(FastField)`
  width: 120px;
  height: 40px;
  font-size: 16px;
  background: none;
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius};
  color: ${props => props.theme.colors.textPrimary};
  outline: none;
  padding: 0 15px;
  transition: 0.3s;
`;

export const U = styled.u`
  color: ${props => props.theme.colors.accent};
  font-size: 14px;
  cursor: pointer;
`;

export const ComponentWrapper = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: center;
`;

export const Type = styled.div<{active: boolean}>`
  margin: 5px 0;
  border: ${props => props.theme.colors.border};
  ${props => props.active && `border-color: ${props.theme.colors.accent}`};
  padding: 7.5px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  text-transform: capitalize;
  cursor: pointer;
  svg {
    margin-right: 5px;
    stroke-width: 5px;
    fill: ${props => props.theme.colors.textPrimary};
    stroke:  ${props => props.theme.colors.textPrimary};
    height: 20px;
    width: 20px;
  }
`;