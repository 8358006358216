import _axios, {AxiosInstance} from 'axios';
import { GetPositions, GetPosition, GetTransactions, GetTransactionsForDate, GetAllTransactions } from './Saga/transaction';
import { Transaction } from './types';
import {AlarmFilterEnum} from "@Safemate/DeviceList/AlarmFilterEnum";

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export interface GetTransactionsApi extends GetTransactions{
  maxTransactionId: number;
  alarmFilter: AlarmFilterEnum[];
}

export const getAllTransactions = async ({maxTransactionId, transactionCount, deviceIds}: GetAllTransactions) => {
  let response;
  if(deviceIds){
    const url = `/device/txs/${maxTransactionId}/${transactionCount}`;
    response = await axios.post(url, deviceIds);
  }
  else{
    const url: string = `/device/txs/0/${maxTransactionId}/${transactionCount}/false`;
    response = await axios.get(url);
  }
  return response.data;
}

export const getTransactions = async ({deviceId, maxTransactionId, transactionCount, filter, alarmFilter}: GetTransactionsApi) => {
  const url: string = `/device/txswithmessagetype/${deviceId}/${maxTransactionId}/${transactionCount}/${filter}/${(alarmFilter.length>0 ? alarmFilter : 'none')}`;
  const response = await axios.get(url);
  return response.data;  
};

export const getMoreTransactions = async ({deviceId, maxTransactionId, transactionCount, filter, alarmFilter}: GetTransactionsApi) => {
  const url: string = `/device/moretxswithmessagetype/${deviceId}/${maxTransactionId}/${transactionCount}/${filter}/${(alarmFilter.length>0 ? alarmFilter : 'none')}`;
  const response = await axios.get(url);
  return response.data;  
};

export const getPositions = async ({deviceId, count, uuid}: GetPositions) => {
  const url: string = `/device/followme/${deviceId}/${count}`;
  const response = await axios.get(url);
  return response.data;
}

export const getPositionsUuid = async ({deviceId, count, uuid}: GetPositions) => {
  const url: string = `/device/followme/${deviceId}/${count}/${uuid}`;
  const response = await axios.get(url);
  return response.data;
}

export const getPosition = async ({deviceId, tranId}: GetPosition) => {
  const url: string = `/device/followme/transaction/${deviceId}/${tranId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getAddress = async ({deviId, posLati, posLong}: Transaction) => {
  const url: string = `/geocode/${posLati}/${posLong}/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getDevice = async (deviceId: number) => {
  const url: string = `/transactionLog/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getAllDevices = async (retrieveFrom: number) => {
  const url: string = `device/devicewithstatusforuser/0/${retrieveFrom}`;
  const response = await axios.get(url);
  return response.data
}

export const getCurrentPosition = async (deviceId: number) => {
  const url: string = `/device/forcetrackingmessage/${deviceId}?_=${new Date().getTime()}`
  const response = await axios.get(url);
  return response.data;
}

export const getPoliciesForDevice = async (deviceId: number) => {
  const url: string = `/userroles/getpoliciesfordevice/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getDatesWithTxForDevice = async (deviceId: number) => {
  const url: string = `/transactionLog/dateswithtxfordevice/${deviceId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getTransactionsForDate = async ({deviceId, fromDate, toDate, filter, alarmFilter}: GetTransactionsForDate) => {
  const offset = new Date().getTimezoneOffset();
  const url: string = `/transactionLog/txsfordateanddevice/${fromDate}/${toDate}/${offset}/${deviceId}/${filter}/${(alarmFilter.length>0 ? alarmFilter : 'none')}`;
  const response = await axios.get(url);
  return response.data;
}

export const alarmAnalysis = async (transactionId: number) => {
  const url =  `/alarmanalysis/${transactionId}`;
  const response = await axios.get(url);
  return response.data;
}

export const getNotificationEvents = async (transactionId: number) => {
  const url = `/eventLog/transaction/${transactionId}`;
  const response = await axios.get(url);
  return response.data;
}

export const setMapConsent = async () => {
  const url = `user/mapconsent/true?_=${new Date().getTime()}`;
  const response = await axios.get(url);
  return response.data;
}

export const getUser = async () => {
  const url = `user/me?_=${new Date().getTime()}`;
  const response = await axios.get(url);
  return response.data;
}

export const getGeofence = async (deviceId: number) => {
  const url: string = `/geofence/device/${deviceId}/`;
  const response = await axios.get(url);
  return response.data;
}
export const getGlobalGeofence = async () => {
  const url: string = `/geofence/existingGeofences`;
  const response = await axios.get(url);
  return response.data;
}

export const getBatteryForRange = async (deviId: number) => {
  const url: string = `/transactionLog/batteryfortimerange/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}
export const getSignalForRange = async (deviId: number) => {
  const url: string = `/transactionLog/signalfortimerange/${deviId}`;
  const response = await axios.get(url);
  return response.data;
}
