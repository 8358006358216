import * as React from "react";
import styled from "styled-components";
import { HeaderWrapper } from "./styles";
import { useIntl } from "react-intl";

const Heading = styled.div`
  margin-bottom: 11px;
  display: flex;
  @media (max-width: 600px) {
    svg{
      width: 2em!important;
    }
  }
`;

const H1 = styled.h1`
  white-space: normal;

`;

const StyledSpan = styled.span`
  @media (max-width: 600px) {
    font-size: 24px;
    margin-left: 10px;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  button {
    margin: 5px;
  }
`;

const SettingsHeader = () => {

    const { formatMessage } = useIntl();



    return(
        <Heading>
          <H1>
            <HeaderWrapper>
              <StyledSpan>
                {` ${formatMessage({ id: "orgatea", defaultMessage: "Organisasjon Atea" })} `}
              </StyledSpan>
            </HeaderWrapper>
          </H1>
        </Heading>
    )
}

export default SettingsHeader;