import * as React from "react";
import {Form, Formik, } from "formik";
import {connect} from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Grid, Row, Col, Button } from "react-bootstrap";


import { Firm } from "../Store/types";
import { popForm} from "../Form/utils";
import { RootState, Organization} from "../Store/types";
import { saveForm, fetchOrganisation } from "../Store/routines";
import {Footer} from "../Form/footer";

import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { Name, ZipAndPost, Land, InvoiceAndEmail, Reference, AddressOne, AddressTwo } from "./fields";
import { AppState } from "@Safemate/Store/types";




interface OrganisationProps {
  fetchOrganisation: Routine<ActionFunctionAny<Action<any>>>;
  saveForm: Routine<ActionFunctionAny<Action<any>>>;
  customers: Organization;
}


export const UpdateButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const H3 = styled.h4`
  white-space: normal;
  padding-left: 0px;
  padding-bottom: 20px;
  font-weight: bold!important;

`;

const StyledSpan = styled.span`
  @media (max-width: 600px) {
    font-size: 24px;
    margin-left: 10px;
  }
`;


const HeaderWrapper = styled.span`
  display: flex;
  @media (max-width: 600px) {
    display: block;
  }
  @media (min-width: 992px){
    margin-top:20px;
  }
`;

const MarginTopCol = styled(Col)`
  @media (min-width: 992px){
    margin-top:50px;
  }
`;

export interface OrgValues {
  custId: string;
  updatedAhp: boolean;
  deviId: number;
}

const Address = ({  saveForm, customers, fetchOrganisation}: OrganisationProps) => {

  React.useEffect(() => {
    fetchOrganisation();
  }, [])
  const intl = useIntl();


  let iniVals;
  let pptyId:string;
  let billAddrDefined:boolean;
  let countryCode:string;
  customers.paymentProfile != null ? pptyId = customers.paymentProfile.pptyId+'' : customers.firm.privateUser ? pptyId="6" : pptyId="4";
  countryCode = customers.firm.country;
  customers.firm.billingAddrName === null || customers.firm.billingAddrName === "" ? billAddrDefined = false : billAddrDefined = true;
  iniVals = popForm(customers.firm, pptyId, billAddrDefined);
  if(countryCode === "" || !countryCode){
    countryCode = iniVals.country;
  }
  return(
    <Formik
      initialValues={iniVals}
      enableReinitialize
      onSubmit={(values: Firm) => saveForm({values, customers})}
    >
    {({values: { mobile }, dirty}) => 
        <Form id="addressForm">
                <Row>
                    <Col md={6}>
                    <H3>
                      <HeaderWrapper>
                        <StyledSpan>
                          {intl.formatMessage({ id: "billingAddrName", defaultMessage: "Fakturaadresse" })} 
                        </StyledSpan>
                      </HeaderWrapper>
                    </H3>
                      <Name />
                      <AddressOne/>
                      <AddressTwo/>
                      <ZipAndPost/>
                      <Land countryCode={countryCode}/>
                    </Col>
                    <MarginTopCol md={6}>
                      <Reference/>
                      <InvoiceAndEmail invoiceCode={pptyId} />
                    </MarginTopCol>
                </Row>
                    <Footer />
                    <UnsavedCheck/>
        </Form>
    }
      </Formik>
    
  )
}

const mapStateToProps = ({ myPageBilling: {subUnits: {customers}}}: AppState) => {
  return{
    customers
  }
};

const mapDispatchToProps = {
  saveForm,
  fetchOrganisation
}


export default connect(mapStateToProps, mapDispatchToProps)(Address);