import * as React from "react";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {connect} from "react-redux";
import {selectCustomer, getClimaxConfig} from "../Store/routines";
import {CustomerRow} from "./styled";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import {GlobalRoles} from "../../GlobalRoles";
import {accessToCustomer} from "../../Policy/rightsUtil";
import { AppState } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";

interface CustomerListProps{
  customers: ICustomerWithPolicy[];
  selectedCustomer: ICustomerWithPolicy;
  selectCustomer: (customerId: ICustomerWithPolicy) => void;
  getClimaxConfig: Routine<ActionFunctionAny<Action<any>>>;
}

const CustomerList = (props: CustomerListProps) =>{

  const select = (customer: ICustomerWithPolicy) => {
    if(props.selectedCustomer.customerId !== customer.customerId){
      props.selectCustomer(customer);
      props.getClimaxConfig(customer.customerId);
    }
  }

  return(
    <React.Fragment>
      {props.customers.map((customer: ICustomerWithPolicy) => {

        const hasAccess = accessToCustomer(customer.policies, PolicyNameEnum.DEFAULT_SETTINGS, [GlobalRoles.SUPERFIRM, GlobalRoles.FIRM])

        return(
          <CustomerRow
            active={props.selectedCustomer.customerId === customer.customerId}
            level={customer.level}
            hasAccess={hasAccess}
            key={customer.customerId}
            onClick={() => hasAccess && select(customer)}
            onKeyDown={(event) => event.key === "Enter" && hasAccess && select(customer)}
            tabIndex={hasAccess ? 0 : undefined}
          >
            {customer.customerName}
          </CustomerRow>
        )
      })}
    </React.Fragment>
  )
}

const mapStateToProps = ({appData: {customersWithPolicy}, defaultSettings: { customer: { selectedCustomer } }}: AppState) => ({
  customers: customersWithPolicy,
  selectedCustomer
})

const mapDispatchToProps = {
  selectCustomer: selectCustomer,
  getClimaxConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
