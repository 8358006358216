import * as React from "react";
import { Button, Row } from "react-bootstrap";
import { injectIntl, WrappedComponentProps } from "react-intl";
import styled from "styled-components";
import { Routine } from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { getPosition } from "../../Store/routines"; 
import { connect } from "react-redux";
import { TrackingButton } from "..";
import { AppState } from "@Safemate/Store/types";

const StyledP = styled.p`
  padding-top: 20px;
`;

interface GetPositionProps extends WrappedComponentProps{
  getPosition: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  disablePositioning: boolean;
}

const mapStateToProps = ({ settings: {device: { device : { deviId }}, tracking: { disablePositioning }}}: AppState) => {
  return{
    deviId,
    disablePositioning
  }
}

const mapDispatchToProps = {
  getPosition
}

const GetPosition = connect(mapStateToProps, mapDispatchToProps)(injectIntl(({ intl: {formatMessage}, getPosition, deviId, disablePositioning }: GetPositionProps) => {
  if(disablePositioning) return null;
  return(
      <TrackingButton onClick={() => getPosition(deviId)}>
        {formatMessage({id: "takitForcePositionMessage", defaultMessage: "Hent nåværende posisjon"})}
      </TrackingButton>
  )
}))

export default GetPosition;