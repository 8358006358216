import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import { Col, Row, Button } from "react-bootstrap";

import { NewFieldWrapper, NewHeader } from ".";
import withFormWrapper from "@Elements/Form/formWrapper";
import { GeofenceValues } from "..";
import { Radio } from "../../../../Elements/RadioFormik";
import { setGeofenceType, SetGeofenceType } from "../../Store/actions";
import { connect } from "react-redux";
import { GeofenceTypeEnum } from "../GeofenceTypeEnum";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { defaultFence } from "../utils";
import { Geofence, GlobalGeofence } from "../types";
import Circle from "../../../../Elements/Icon/Icons/geofence/GeofenceCircle";
import GeofencePolygon from "../../../../Elements/Icon/Icons/geofence/GeofencePolygon";
import Wifi from "@Icons/Wifi";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import { AppState } from "@Safemate/Store/types";

export const Type = styled.div<{active: boolean}>`
  margin: 5px 0;
  border: ${props => props.theme.colors.border};
  ${props => props.active && `border-color: ${props.theme.colors.accent}`};
  padding: 7.5px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  text-transform: capitalize;
  cursor: pointer;
  svg {
    margin-right: 5px;
    stroke-width: 5px;
    fill: ${props => props.theme.colors.textPrimary};
    stroke:  ${props => props.theme.colors.textPrimary};
    height: 20px;
    width: 20px;
  }
`;

interface TypeSelectorProps extends WrappedComponentProps{
  setGeofenceType: SetGeofenceType;
  globalGeofence: GlobalGeofence[];
  fences: Geofence[];
  geofenceType: GeofenceTypeEnum;
  indoorLocations: IndoorLocation[];
}

const mapStateToProps = ({ settings: {geofence: { globalGeofence, geofence, geofenceType }}}: AppState) => {
  return{
    globalGeofence,
    fences: geofence,
    geofenceType
  }
}

const mapDispatchToProps = {
  setGeofenceType
}

const TypeSelector = injectIntl(withFormWrapper<GeofenceValues, TypeSelectorProps>(
  ({ intl: {formatMessage}, formik: { setFieldValue, values: { geofence } }, setGeofenceType, geofenceType, indoorLocations }) => {

    return(
      <NewFieldWrapper>
        <NewHeader>{`1. ${formatMessage({ id: "drawGeofence", defaultMessage: "Tegn inn nytt geofence" })}`}</NewHeader>      
        <Type
          active={geofenceType === GeofenceTypeEnum.CIRCLE}
          onClick={() => {
            setGeofenceType(GeofenceTypeEnum.CIRCLE)
            setFieldValue("geofence", geofence);
            setFieldValue("geofence.circle", true);
            setFieldValue("geofence.polygon", false);
            setFieldValue("geofence.indoor", false);
          }}
        >
          <Circle/> {formatMessage({ id: "circle", defaultMessage: "Sirkel" })}
        </Type>   
        <Type
          active={geofenceType === GeofenceTypeEnum.POLYGON}
          onClick={() => {
            setGeofenceType(GeofenceTypeEnum.POLYGON)
            setFieldValue("geofence", geofence);
            setFieldValue("geofence.circle", false);
            setFieldValue("geofence.polygon", true);
            setFieldValue("geofence.indoor", false);
          }}
        >
          <GeofencePolygon/> {formatMessage({ id: "polygon", defaultMessage: "Polygon" })}
        </Type>
        {
          indoorLocations && indoorLocations.length > 0 && <Type
            active={geofenceType === GeofenceTypeEnum.INDOOR}
            onClick={() => {
              setGeofenceType(GeofenceTypeEnum.INDOOR)
              setFieldValue("geofence", geofence);
              setFieldValue("geofence.circle", false);
              setFieldValue("geofence.polygon", false);
              setFieldValue("geofence.indoor", true);
              setFieldValue("geofence.radius", 0);
            }}
          >
            <Wifi /> {formatMessage({ id: "indoorLocationGeofence", defaultMessage: "IndoorLocation" })}
          </Type>
        }

      </NewFieldWrapper>
    )
  })
)

export default connect(mapStateToProps, mapDispatchToProps)(TypeSelector);
