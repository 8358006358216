
import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Row, Col } from "react-bootstrap";
import Plus from "../../../../Elements/Icon/Icons/Safemate/solid/PlusCircle";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import styled from 'styled-components';
import { connect } from "react-redux";
import { RootState } from '../../Store/types';
import { Option } from '../../../../Safemate/DefaultSettings/Settings/FieldWrapper';
import {  getOrderFilter } from './utils';
import { LabelEnum } from "../../LabelEnum";
import { DeviceInterface, PartnerInfo } from "../utils";
import { OrderFilterEnum } from "../../OrderFilterEnum";
import { 
  filterOrder,
  FilterOrder
} from '../../Store/actions';
import { AppState } from "@Safemate/Store/types";
import { ICustomer } from "@Safemate/Model/Customer";

interface HeaderProps extends WrappedComponentProps{
  selectedOrderFilter: OrderFilterEnum;
  filterOrder: FilterOrder;
  customersInitialized: boolean;
  totalDevices: number;
  homeDevices: number;
  superfirm: ICustomer;
}

const StyledCol = styled(Col)`
  padding: 15px 0;
  float: right;
`
const SmallWrap = styled.div`
  @media (max-width: 768px) {
    margin: 0 !important;
  }
`

const FourthWrap = styled(SmallWrap)`
  margin-left: 10px;
  ul label {
    padding: 0px;
  }
`
const mapStateToProps = (
  {
    appData: {
      superfirm
    },
    partnerDashboard: {
      customer: {customers}, 
      filter: {orderFilter: selectedOrderFilter},
      initialization: {customersInitialized},
    }
  }: AppState) => {
  return{
    selectedOrderFilter,
    customers,
    customersInitialized,
    superfirm
  }
}

const mapDispatchToProps = {
  filterOrder
}

export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(({
    filterOrder, 
    selectedOrderFilter,
    customersInitialized,
    intl: {formatMessage},
    totalDevices,
    homeDevices,
    superfirm
  }: HeaderProps) => {

  const companyName = superfirm.name;
  return(
    <React.Fragment>
      <Row>
        <h1>
          {`${formatMessage({id: "Partneroversikt", defaultMessage: "Partneroversikt"})} ${companyName}  (${totalDevices}/${homeDevices})`}
        </h1>
      </Row>
        {customersInitialized && <StyledCol lg={3} md={3} sm={3} xs={12}>
          <FourthWrap>
            <Dropdown
              initial={selectedOrderFilter}
              options={getOrderFilter(formatMessage)}
              title={formatMessage({id: LabelEnum.DEVICE_LIST_SORT_PARTNER, defaultMessage: "Standardsortering"})}
              onChange={option => {
                const selected = (option as Option).value;
                filterOrder(selected);
              }}
            />
          </FourthWrap>
        </StyledCol>}
    </React.Fragment>
  )
}));
