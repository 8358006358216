import confirmModal from "@Safemate/Modals/confirmModal";
import * as React from "react";
import { useIntl } from "react-intl";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import DeviceEventSetup from "../Model/DeviceEventSetup";
import { setDeviceEventSetupModal } from "../Store/routines";
import { Caret, TimeTable } from "./TableElementStyles";
import { formatTime, getValidHour, getValidMinute, ModalEnum, SortEnum, useDefault } from "./utils";
import { connect } from "react-redux";
import { TimeInput } from "./FormElementStyles";
import withFormWrapper from "@Elements/Form/formWrapper";
import { EventSetupForm } from "./types";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AppState } from "@Safemate/Store/types";
import { Device } from "@Safemate/Settings/types";

interface TimeSelectorProps{
  modalType: ModalEnum;
  modalEventSetupIndex: number;
  selectedEventSetup: DeviceEventSetup;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
  device: Device;
}

const mapStateToProps = ({ eventSetup: {deviceEventSetup: { modalType, modalEventSetupIndex, selectedEventSetup }, device: { device }}}: AppState) => {
  return {
    modalType,
    modalEventSetupIndex,
    selectedEventSetup,
    device
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal
}

const TimeSelector = connect(mapStateToProps, mapDispatchToProps)(withFormWrapper<EventSetupForm, TimeSelectorProps>(
  ({ formik: { setFieldValue }, device, setDeviceEventSetupModal, modalType, modalEventSetupIndex, selectedEventSetup }) => {
  
  const { formatMessage } = useIntl();
  const { Comp: Modal, func: displayModal } = confirmModal();
  const [ time, setTime ] = React.useState("");

  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const disable: boolean = !permissions.get(PolicyNameEnum.EMAIL_RECIPIENTS).generalPermissions.all || useDefault(device);

  const splitTime = time.split(":");
  let hour = "";
  let minute = ""
  if(splitTime.length > 1){
    hour = splitTime[0];
    minute = splitTime[1];
  }

  React.useEffect(() => {
    displayModal(modalType === ModalEnum.FROM || modalType === ModalEnum.TO);
    setTime(modalType === ModalEnum.FROM ? selectedEventSetup.warnTimeFromString : modalType === ModalEnum.TO ? selectedEventSetup.warnTimeToString : "");
  }, [modalType, selectedEventSetup])

  const changeHour = (type: SortEnum) => {
    setTime(`${formatTime(getValidHour(parseInt(hour) + (type === SortEnum.INCREMENT ? 1 : -1)))}:${minute}`);
  }

  const changeMinute = (type: SortEnum) => {
    setTime(`${hour}:${formatTime(getValidMinute(parseInt(minute) + (type === SortEnum.INCREMENT ? 5 : -5)))}`);
  }
  
  const confirm = () => {
    const valueString = `${hour}:${minute}`;
    const value = parseInt(valueString.replace(":", ""));
    setFieldValue(`eventSetups.${modalEventSetupIndex}`, {
      ...selectedEventSetup,
      warnTimeFrom: modalType === ModalEnum.FROM ? value : selectedEventSetup.warnTimeFrom,
      warnTimeFromString: modalType === ModalEnum.FROM ? valueString : selectedEventSetup.warnTimeFromString,
      warnTimeTo: modalType === ModalEnum.TO ? value : selectedEventSetup.warnTimeTo,
      warnTimeToString: modalType === ModalEnum.TO ? valueString : selectedEventSetup.warnTimeToString
    })
    closeModal();
  }

  const closeModal = () => {
    setDeviceEventSetupModal({
      selectedEventSetup: null,
      modalType: ModalEnum.NONE
    })
  }

  return(

    <Modal
      buttonAlign="center"
      standardBtn
      disableClose
      cancelFunc={closeModal}
      confirmFunc={confirm}
      confirmText={formatMessage({id: "ok", defaultMessage: "Ok"})}
      title={formatMessage({id: "time", defaultMessage: "Time"})}
      body={
        <TimeTable>
          <thead>
            <tr>
              <th>
                <Caret
                  size={45}
                  up
                  tabIndex={0}
                  id={`increase-hour-${modalType}`}
                  onClick={() => !disable && changeHour(SortEnum.INCREMENT)}
                  onKeyDown={(event) => (event.key === "Enter" && !disable) && changeHour(SortEnum.INCREMENT)}
                />
              </th>
              <th />
              <th>
                <Caret
                  size={45}
                  up
                  tabIndex={0}
                  id={`increase-minute-${modalType}`}
                  onClick={() => !disable && changeMinute(SortEnum.INCREMENT)}
                  onKeyDown={(event) => (event.key === "Enter" && !disable) && changeMinute(SortEnum.INCREMENT)}
                />
              </th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <TimeInput
                id={`hour-${modalType}`}
                value={hour}
                disabled
              />
            </td>
            <td>:</td>
            <td>
              <TimeInput
                id={`minute-${modalType}`}
                value={minute}
                disabled
              />
            </td>
          </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>
                <Caret
                  size={45}
                  down
                  tabIndex={0}
                  id={`decrease-hour-${modalType}`}
                  onClick={() => !disable && changeHour(SortEnum.DECREMENT)}
                  onKeyDown={(event) => (event.key === "Enter" && !disable) && changeHour(SortEnum.DECREMENT)}
                />
              </th>
              <th />
              <th>
                <Caret
                  size={45}
                  down
                  tabIndex={0}
                  id={`decrease-minute-${modalType}`}
                  onClick={() => !disable && changeMinute(SortEnum.DECREMENT)}
                  onKeyDown={(event) => (event.key === "Enter" && !disable) && changeMinute(SortEnum.DECREMENT)}
                />
              </th>
            </tr>
          </tfoot>
        </TimeTable>
      }
    />

  )
}))

export default TimeSelector;