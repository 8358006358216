import React from 'react';

const HeartRight = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="25px"
      height="25px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      {...props}
    >
      <path
        d="M92.151,6c-10.939,0-21.438,5.092-28.288,13.137C57.012,11.092,46.515,6,35.575,6
                C16.212,6,1,21.212,1,40.575c0,23.761,21.373,43.121,53.747,72.544l9.116,8.232l9.116-8.3
                c32.371-29.356,53.747-48.716,53.747-72.477C126.727,21.212,111.514,6,92.151,6z M63.863,104.384l-0.629-0.633
                C33.313,76.66,13.573,58.743,13.573,40.575c0-12.573,9.429-22.002,22.002-22.002c9.681,0,19.111,6.225,22.441,14.835h5.557
                L63.863,104.384z"
      />
    </svg>
  );
};

export default HeartRight;
