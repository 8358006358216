import _axios, {AxiosInstance} from 'axios';
import { Initialize } from './Saga/initialization';
import { Role } from './types';

// Setting up axios ------------------------------------------------------------
let axios: AxiosInstance;
const defaultBaseURL = '/ajax';

// For use in safemate proffweb
export const setBaseURL = (baseURL: string) => {
  axios = _axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' }
  });
};

setBaseURL(defaultBaseURL);
// ------------------------------------------------- Finish setting up axios ---

export const getRole = async ({roleId, duplicate}: Initialize) => {
  const url: string = `/userroles/role?roleId=${roleId}&duplicate=${duplicate}`;
  const response = await axios.get(url);
  return response.data;  
};

export const saveRole = async (role: Role) => {
  const url: string = `/userroles/save`;
  const response = await axios.post(url, role);
  return response.data;  
};

export const getPolicies = async () => {
  const url: string = `/userroles/getpolicies`;
  const response = await axios.get(url);
  return response.data;
}