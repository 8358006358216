import * as React from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import AddSafemate from "@Safemate/AddSafemate";
import  Devices  from "@Safemate/DeviceList";
import Dashboard from "@Safemate/Dashboard";
import Billing from "@Safemate/Billing";
import { GlobalRoles } from "@Safemate/GlobalRoles";
import { connect } from "react-redux";
import { AppState } from "@Safemate/Store/types";
import { ICustomerWithPolicy } from "@Safemate/Model/Customer";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { accessToCustomer } from "@Safemate/Policy/rightsUtil";
import PartnerDashboard from "@Safemate/PartnerDashboard";
import  PartnerBilling  from "@Safemate/PartnerBilling";
import  UserAdmin  from "@Safemate/UserAdmin";
import Settings from "@Safemate/Settings";
import PartnerOrganization from "@Safemate/PartnerOrganization";
import SubUnits from "@Safemate/SubUnits";
import EventSetup from "@Safemate/EventSetupNew";
import TransactionLog, { MapType } from "@Safemate/map";
import MyPage from "@Safemate/MyPage";
import MyPageBilling from "@Safemate/PrivateMyBilling";
import RoleEditor from "@Safemate/RoleEditor";
import RoleManager from "@Safemate/RoleManager";
import DefaultSettings from "@Safemate/DefaultSettings";
import TranslatePage from "@Safemate/TranslationModule/TranslatePage";
import Gdpr from "@Safemate/Gdpr";
import { AnimatePresence } from "framer-motion";
import { useLocation } from "react-router";
import Transition from "./Transition";
import SafemateLog from "@Safemate/Log";
import {LandingHandler} from "@Safemate/Router/LandingHandler";
import EventView from "@Safemate/EventView";

interface RouterProps{
  isPrivate: boolean;
  customersWithPolicy: ICustomerWithPolicy[];
  isBillingFilled: boolean;
}

const mapPropsToState = ({ appData: { isPrivate, customersWithPolicy, isBillingFilled } }: AppState) => {
  return{
    isPrivate,
    customersWithPolicy,
    isBillingFilled
  }
}

export const Router = connect(mapPropsToState)(({ isPrivate, customersWithPolicy, isBillingFilled }: RouterProps) => {
  let location = useLocation();

  if(isPrivate && !isBillingFilled){
    return(
      <Switch location={location} key={location.pathname}>
        <Route path="/">
          <Billing/>
        </Route>
      </Switch>
    )
  }
  
  return(
    <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <Transition>
              <LandingHandler/>
            </Transition>
          </Route>
          <Route path="/dashboard">
            <Transition>
              <Dashboard/>
            </Transition>
          </Route>
          <Route path="/partner_dashboard">
            <Transition>
            <PartnerDashboard/>
            </Transition>
          </Route>
          <Route path="/partner_billing">
            <Transition>
            <PartnerBilling/>
            </Transition>
          </Route>
          <Route path="/partner_organization">
            <Transition>
            <PartnerOrganization/>
            </Transition>
          </Route>
          <Route path="/safemates/:params?">
            <Transition>
              <Devices/>
            </Transition>
          </Route>
          <Route path="/add_safemate/:params?">
            <Transition>
            <AddSafemate/>
            </Transition>
          </Route>
          <Route path="/billing">
            <Transition>
            <Billing/>
            </Transition>
          </Route>
          <Route path="/users">
            <Transition>
            <UserAdmin/>
            </Transition>
          </Route>
          <Route path="/settings/:id/:dehaId" render={() => {
            return(
            <Settings/>)
            }}/>
          <Route path="/sub_units_react">
            <Transition>
            <SubUnits/>
            </Transition>
          </Route>
          <Route path="/event_setup/:id">
            <Transition>
            <EventSetup/>
            </Transition>
          </Route>
          <Route path="/mapsingle/:id">
            <Transition>
            <TransactionLog mapType={MapType.SINGLE}/>
            </Transition>
          </Route>
          <Route path="/map">
            <Transition>
            <TransactionLog mapType={MapType.ALL}/>
            </Transition>
          </Route>
          <Route path="/my_page">
            <Transition>
            <MyPage/>
            </Transition>
          </Route>
          <Route path="/my_page_private">
            <Transition>
            <MyPageBilling/>
            </Transition>
          </Route>
          <Route path="/role_editor/:id?/:duplicate?">
            <Transition>
            <RoleEditor/>
            </Transition>
          </Route>
          <Route path="/role_manager">
            <Transition>
            <RoleManager/>
            </Transition>
          </Route>
          <Route path="/default_settings">
            <Transition>
            <DefaultSettings/>
            </Transition>
          </Route>
          <Route path="/user_audit_log/:id">
            <Transition>
            <SafemateLog/>
            </Transition>
          </Route>
          <Route path="/device_audit_log/:id">
            <Transition>
            <SafemateLog/>
            </Transition>
          </Route>
          <Route path="/device_call_log/:id">
            <Transition>
            <SafemateLog/>
            </Transition>
          </Route>
          <Route path="/translate">
            <Transition>
            <TranslatePage/>
            </Transition>
          </Route>
          <Route path="/eventview">
            <Transition>
            <EventView/>
            </Transition>
          </Route>
          <Route path="/gdpr">
            <Transition>
            <Gdpr/>
            </Transition>
          </Route>
        </Switch>
        </AnimatePresence>
  )
})

const hasAccess = (allowedGlobals: GlobalRoles[], customersWithPolicy: ICustomerWithPolicy[], policy?: PolicyNameEnum) => {
  
  for(let i = 0; i < customersWithPolicy.length; i++){
    if(!policy){
      if(allowedGlobals.includes(customersWithPolicy[i].policies.roleName as GlobalRoles)){
        return true;
      }
    }
    else{
      if(accessToCustomer(customersWithPolicy[i].policies, policy, allowedGlobals)){
        return true;
      }
    }
  }
  return false;
}
