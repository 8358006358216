import { AppAction } from "../../Store/types";
import { AlarmFilterEnum } from "../../DeviceList/AlarmFilterEnum";
import { MapActionTypes } from "./actionTypes";
import { Moment } from "moment";

export type FilterAlarm = (alarmfilters: AlarmFilterEnum[]) => AppAction<AlarmFilterEnum[]>;

export const filterAlarm: FilterAlarm = (alarmfilters) => ({
  type: MapActionTypes.FILTER_ALARM,
  payload: alarmfilters
})

export type SelectDate = (fromDate: Moment, toDate: Moment) => AppAction<{fromDate: Moment, toDate: Moment}>;

export const selectDate: SelectDate = (fromDate, toDate) => ({
  type: MapActionTypes.SELECT_DATE,
  payload: {fromDate, toDate}
})

export type Rerender = (render: boolean) => AppAction<boolean>;

export const rerender: Rerender = (render) => ({
  type: MapActionTypes.RERENDER,
  payload: render
})

export type ResetState = () => AppAction<null>;

export const resetState: ResetState = () => ({
  type: MapActionTypes.RESET_STATE,
  payload: null
})

export type DisplayGraph = () => AppAction<boolean>;

export const displayGraph: DisplayGraph = () => ({
  type: MapActionTypes.DISPLAY_GRAPH,
  payload: null
})

export type GenerateMapUuid = (uuid: string) => AppAction<string>;

export const generateMapUuid: GenerateMapUuid = (uuid) => ({
  type: MapActionTypes.GENERATE_MAP_UUID,
  payload: uuid
})


export type GoogleMaps = (googleMaps: any) => AppAction<any>;

export const googleMaps: GoogleMaps = (googleMaps) => ({
  type: MapActionTypes.GOOGLE_MAPS,
  payload: googleMaps
})

