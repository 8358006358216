import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { Role } from '../types';
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import { Initialize } from './initialization';



function* getRole(action: AppAction<Initialize>){
  try{
    const roles: Role[] = yield call(Api.getRole, action.payload);
    yield put(Routines.getRole.success(roles));
  }
  catch(e){
    console.log(e);
    yield feedbackFailure(e);
    yield put(Routines.getRole.failure());
  }
}

function* saveRole(action: AppAction<Role>){
  try{
    const role = yield call(Api.saveRole, action.payload);
    yield put(Routines.saveRole.success(role));
    yield feedbackSuccess("roleSaved");
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.saveRole.failure());
  }
}

function* getPolicies(action: AppAction<null>){
  try{
    const role: Role = yield call(Api.getPolicies);
    yield put(Routines.getPolicies.success(role));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getPolicies.failure());
  }
}

function* roleSaga(){
    yield takeLatest(Routines.getRole.TRIGGER, getRole);
    yield takeLatest(Routines.saveRole.TRIGGER, saveRole);
    yield takeLatest(Routines.getPolicies.TRIGGER, getPolicies);
}

export default roleSaga;
