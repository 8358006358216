import * as React from "react";
import styled from "styled-components";
import { injectIntl, useIntl, WrappedComponentProps } from "react-intl";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { startLocalLearning, stopLocalLearning, addLocalLearning } from "@Safemate/Settings/Store/routines";
import confirmModal from "@Safemate/Modals/confirmModal";
import { ButtonWrap, Centered, DescriptionParagraph, HeaderRow, LoaderWrap, SensorHeader, SensorWrapper, Wrapper } from "./styles";
import { BackWrapper } from "@Safemate/UserAdmin/Users/Edit/styles";
import LocalLearningSensor from "./localLearningSensor";
import { ILocalLearningSensor, ISensorDTO, LocalLearningEventDO } from "../types";
import { Client, IMessage } from "@stomp/stompjs";
import { connectWebsocket } from "@Safemate/Websocket";
import Loader from "@Elements/Loader";
import { useHistory } from "react-router";
import { AppState } from "@Safemate/Store/types";


const mapStateToProps = ( { 
  settings: {
    device: { device: {deviId}},
    sensor: { sensors, learningRemaining, learningMode, startingLearningMode, endingLearningMode, addedLocalLearning }
  }
}: AppState ) => {
  return {
    deviId,
    learningRemaining,
    learningMode,
    startingLearningMode, 
    endingLearningMode,
    sensors,
    addedLocalLearning
  }
}

const mapDispatchToProps = {
  startLocalLearning,
  stopLocalLearning,
  addLocalLearning,
}

interface LocalLearningProps {
  startLocalLearning: Routine<ActionFunctionAny<Action<any>>>;
  stopLocalLearning: Routine<ActionFunctionAny<Action<any>>>;
  addLocalLearning: Routine<ActionFunctionAny<Action<any>>>;
  deviId: number;
  learningRemaining: number;
  learningMode: boolean;
  startingLearningMode: boolean; 
  endingLearningMode: boolean;
  sensors: ISensorDTO[];
  returnPath?: string;
  addedLocalLearning: String[];
}

interface SensorList{
  [index: string]: ILocalLearningSensor;
}

const LocalLearning = connect(mapStateToProps, mapDispatchToProps)(
    ({ 
      returnPath, 
      startLocalLearning, 
      stopLocalLearning, 
      addLocalLearning, 
      deviId, 
      sensors, 
      learningRemaining,
      learningMode,
      startingLearningMode,
      endingLearningMode,
      addedLocalLearning
    }: LocalLearningProps) => {

      const { formatMessage } = useIntl();
      const history = useHistory();

      const [ websocket, setWebsocket ] = React.useState<Client>();
      const [ learnSensors, setLearnSensors ] = React.useState<SensorList>({});
      const [ learnTimeout, setLearnTimeout ] = React.useState<NodeJS.Timeout>();

      let sensorStorage: SensorList = {};

      React.useEffect(() =>{
        return () => {
          stopLearn();
          stopSocket();
        }
      }, [])

      React.useEffect(() => {
        clearTimeout(learnTimeout);
        if(learningRemaining > 0){
          const timeout = setTimeout(() => {
            stopSocket();
            stopLearn();
          }, learningRemaining * 1000)
          setLearnTimeout(timeout);
        }
      }, [learningRemaining])
      

      const start = () => {
        startSocket();
        startLocalLearning(deviId);
      }

      const stop = (exit?: boolean) => {
        stopSocket();
        stopLearn();
        sensorStorage = {};
        setLearnSensors({});
        if(exit){
          history.push(returnPath)
        }
      }

      const startSocket = () => {

        if(websocket){
          websocket.deactivate();
        }

        const socket = connectWebsocket();

        if(socket.connected){
          socket.subscribe(`/topic/locallearning/${deviId}`, (message: IMessage) => {
            processMessage(message);
          });
        }
        else{
          socket.onConnect = (frame) => {
            socket.subscribe(`/topic/locallearning/${deviId}`, (message: IMessage) => {
              processMessage(message);
            });
          }
        }
        
        if(!socket.active){
          socket.activate();
        }

        socket.onDisconnect = () => {
          stop();
        }

        setWebsocket(socket);
      };

      const stopSocket = () => {
        if (websocket && websocket.active) {
          websocket.unsubscribe(`/topic/locallearning/${deviId}`)
        }
      };

      const stopLearn = () => {
        if(learningMode)
          stopLocalLearning(deviId);
      }

      const handleAdd = (sensor: ILocalLearningSensor) => {
        addLocalLearning({
          deviId,
          gatewayId: sensor.id,
          rssi: sensor.rssi
        });
        const sensorState = {...learnSensors};
        sensorState[sensor.id].pending = true;
        setLearnSensors(sensorState);
      };

      const processMessage = (message: IMessage) => {
        try {
          const event: LocalLearningEventDO = JSON.parse(message.body);
          if (event && event.sensors && event.sensors.length > 0) {
            event.sensors.forEach((sensor: ILocalLearningSensor) => {
              if (sensor.id) {
                sensorStorage[sensor.id] = { ...sensorStorage[sensor.id], ...sensor };
                setLearnSensors({...sensorStorage});
              } else {
                console.warn(`Missing id: ${message}`);
              }
            });
          }
        } catch (e) {
          console.error(`Could not process device stream message: ${e}`);
        }
      };
      
      const sensorIds = Object.keys(learnSensors);

      const existingSensors: ILocalLearningSensor[] = [];
      const newSensors: ILocalLearningSensor[] = [];

      const unaddedSensors = Object.values(learnSensors).filter(s => s.added !== true)


      sensorIds.forEach(sensor => {
        const sensorData = learnSensors[sensor];
        if (sensorData.added && !addedLocalLearning.includes(sensorData.id)) {
          existingSensors.push(sensorData);
        } else {
          newSensors.push(sensorData);
        }
      });

      const {Comp, func} = confirmModal();

      return (
        <Wrapper>
          <HeaderRow>
            <h1>
              {formatMessage({id: "localLearningPageTitle", defaultMessage: "Lokal Innlæring"})}
            </h1>
          </HeaderRow>
          <Description/>
          {learningMode &&
          <React.Fragment>
          {
            newSensors.length === 0 &&
            existingSensors.length === 0 ?
            (
              <WaitingForSensors/>
            ) :
            (
              <SensorWrapper>
                {(newSensors.length > 0) &&
                <Centered>
                  <SensorHeader>
                    {formatMessage({id: "newSensors", defaultMessage: "Nye sensorer"})}:
                  </SensorHeader>
                </Centered>}
                {newSensors.map(sensor => {
                  let successfullyAdded = false;
                  sensors.forEach(item => {
                    if(item.sensor.gatewayId === sensor.id){
                      successfullyAdded = true;
                    }
                  })

                  return (
                    <LocalLearningSensor
                      existing={false}
                      key={sensor.id}
                      handleAdd={handleAdd}
                      sensor={sensor}
                      successfullyAdded={addedLocalLearning.includes(sensor.id)}
                    />
                  )
                })}
                {existingSensors.length > 0 &&
                  <Centered>
                    <SensorHeader>
                      {formatMessage({id: "existingSensors", defaultMessage: "Eksisterende sensorer"})}
                    </SensorHeader>
                    {existingSensors.map(sensor => {
                      return(
                        <LocalLearningSensor
                          existing={true}
                          key={sensor.id}
                          sensor={sensor}
                        />
                      )
                    })}
                  </Centered>
                }
              </SensorWrapper>
            )
          }
          </React.Fragment>
          }
          <ButtonWrap
          >
            <Cancel stop={stop} push={history.push} back={returnPath} showReminder={unaddedSensors.length !== 0}/>
            {learningMode ? 
              endingLearningMode ?
              <LoaderWrap><Loader/></LoaderWrap> :
              <Button
                onClick={() => {
                    unaddedSensors.length === 0
                      ? stop(true)
                      : func(true)
                  }
                }
              >
                {formatMessage({id: 'localLearningCompleteBtn', defaultMessage: 'Fullfør Lokal innlæring'})}
              </Button> :
              startingLearningMode ?
              <LoaderWrap><Loader/></LoaderWrap> :
              <Button
                onClick={() => {
                  start();
                }}
              >
                {formatMessage({id: "startLocalLearning", defaultMessage: "Start innlæring"})}
              </Button>}
          </ButtonWrap>
          <Reminder
            Modal={Comp}
            confirm={() => {
              stop(true);
              func(false);
            }}
          />
        </Wrapper>
      )
    }
)

interface HeaderProps extends WrappedComponentProps{
  back: string;
  push: Function;
  showReminder: boolean;
  stop: Function;
}

const Cancel = injectIntl(({ intl: { formatMessage }, stop, back, push, showReminder}: HeaderProps) => {

  const {Comp, func} = confirmModal();

  return(
    <React.Fragment>
      <BackWrapper>
        <Button onClick={() => {
          if(showReminder){
            func(true);
          }
          else{
            stop();
            push(back);
          }}}>
          {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
        </Button>
      </BackWrapper>
      <Reminder 
        Modal={Comp}
        confirm={() => {
          stop();
          push(back)
          func(false)
        }}
      />
    </React.Fragment>
  )
})

interface ReminderProps{
  confirm: Function;
  Modal: React.FunctionComponent<any>;
}

const Reminder = ({confirm, Modal}: ReminderProps) => {

  const { formatMessage } = useIntl();

  return(
    <Modal
      title={formatMessage({id: "localLearningCompleteBtn", defaultMessage: "Fullfør Lokal innlæring"})}
      body={
        <p>
          {formatMessage({id: 'notAddedSensText', defaultMessage: 'Sensorer har blitt oppdaget men ikke lagt til. Disse sensorene kan bli lagt til ved å trykke på pluss-tegnet ved siden av gjeldende sensor. Dersom du ønsker å fortsette uten å legge de til, kan du trykke på "Fullfør Lokal innlæring".'})}
        </p>
      }
      confirmText={formatMessage({id: "localLearningCompleteBtn", defaultMessage: "Fullfør Lokal innlæring"})}
      confirmFunc={confirm}
    />
  )
}

const Description = injectIntl(({intl: { formatMessage }}: WrappedComponentProps) => {
  return(
    <div>
      <DescriptionParagraph>
        {formatMessage({id: 'localLearningDescription', defaultMessage: 'Trykk på "Pluss"-ikonet ved siden av sensoren for å legge til enheten.'})}
      </DescriptionParagraph>
    </div>
  )
})

const WaitingDiv = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
`;

const SpinnerDiv = styled.div`
  margin: 0;
  animation: rotation 1s infinite linear;
  color: ${props => props.theme.colors.backgroundPrimary};
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
    svg {
      color: ${props => props.theme.colors.backgroundPrimary};
    }
  }
`;

const RotatingIcon = styled(FontAwesomeIcon)`
  width: 1em;
  height: 1em;
`;

interface WaitingForSensorsProps extends WrappedComponentProps {

}

const WaitingForSensors = injectIntl(({ intl: { formatMessage }}: WaitingForSensorsProps) => {
  return(
    <WaitingDiv>
      {formatMessage({id: 'localLearningWaitingText', defaultMessage: 'Venter på sensorkommunikasjon...'})}
      <SpinnerDiv>
        <RotatingIcon
          icon={faSpinner}
          style={{ color: '#cacacb' }}
        />
      </SpinnerDiv>
    </WaitingDiv>
  )
})

export default LocalLearning;