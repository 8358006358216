import {UserAuditLogState} from "../types";
import {Reducer} from "redux";
import {getUserAuditLog} from "../routines";
import { EventLog } from "@Safemate/Model/EventLog";
import { getUser } from "@Safemate/Store/routines";
import { LogActionTypes } from "../actionTypes";

const userAuditLogState: UserAuditLogState = {
  userAuditLog: [] as EventLog[],
  filteredUserAuditLog: [] as EventLog[]
};

const userAuditLog: Reducer<UserAuditLogState> = (state: UserAuditLogState = userAuditLogState, action) => {
  switch (action.type) {

    case getUserAuditLog.SUCCESS:
      return {
        ...state,
        ...action.payload
      };

    case LogActionTypes.RESET_STATE:
      return {
        ...state,
        ...userAuditLogState
      }

    default:
      return state;
  }
};

export default userAuditLog;
