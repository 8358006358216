import * as React from "react";
import {useIntl} from "react-intl";
import { connect } from "react-redux";

import withFormWrapper from "@Elements/Form/formWrapper";
import {GeofenceTypeEnum} from "@Safemate/Settings/Geofence/GeofenceTypeEnum";
import { Geofence } from "@Safemate/Settings/Geofence/types";
import Circle from "@Elements/Icon/Icons/geofence/GeofenceCircle";
import GeofencePolygon from "@Elements/Icon/Icons/geofence/GeofencePolygon";
import Wifi from "@Icons/Wifi";
import {IndoorLocation} from "@Safemate/Settings/IndoorLocation/types";
import styled from "styled-components";
import { AppState } from "@Safemate/Store/types";
import { GeofenceSettings } from "../../types";
import { GeofencesIndexProps } from "..";
import { Type } from "../styles";

const Header = styled.span`
  font-weight: bold;
  padding: 10px 0px;
  font-size: 18px;
`;

const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 15px 0px;
`;



interface TypeSelectorProps extends GeofencesIndexProps{
  setGeofenceType: Function;
  fences: Geofence[];
  geofenceType: GeofenceTypeEnum;
  indoorLocations: IndoorLocation[];
}

const mapStateToProps = ({ defaultSettings: { settings: {customerIndoorLocations }} }: AppState) => {
  return {
    indoorLocations: customerIndoorLocations
  }
}

export const TypeSelector = connect(mapStateToProps)(withFormWrapper<GeofenceSettings, TypeSelectorProps>(
  ({ formik: { setFieldValue, values: { geofences } }, setGeofenceType, geofenceType, indoorLocations, geofencesIndex }) => {

    const { formatMessage } = useIntl();

    React.useEffect(() => {
      const element = document.getElementById('geofenceTypeSelector');
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);

    return(
      <FieldWrapper id="geofenceTypeSelector">
        <Header>{`1. ${formatMessage({ id: "drawGeofence", defaultMessage: "Tegn inn nytt geofence" })}`}</Header>      
        <Type
          active={geofenceType === GeofenceTypeEnum.CIRCLE}
          onClick={() => {
            setGeofenceType(GeofenceTypeEnum.CIRCLE)
            setFieldValue(`geofences.${geofencesIndex}.circle`, true);
            setFieldValue(`geofences.${geofencesIndex}.polygon`, false);
            setFieldValue(`geofences.${geofencesIndex}.indoor`, false);
            setGeofenceType(GeofenceTypeEnum.CIRCLE);
          }}
        >
          <Circle/> {formatMessage({ id: "circle", defaultMessage: "Sirkel" })}
        </Type>   
        <Type
          active={geofenceType === GeofenceTypeEnum.POLYGON}
          onClick={() => {
            setGeofenceType(GeofenceTypeEnum.POLYGON)
            setFieldValue(`geofences.${geofencesIndex}.circle`, false);
            setFieldValue(`geofences.${geofencesIndex}.polygon`, true);
            setFieldValue(`geofences.${geofencesIndex}.indoor`, false);
            setGeofenceType(GeofenceTypeEnum.POLYGON);
          }}
        >
          <GeofencePolygon/> {formatMessage({ id: "polygon", defaultMessage: "Polygon" })}
        </Type>
        {
          indoorLocations && indoorLocations.length > 0 && <Type
            active={geofenceType === GeofenceTypeEnum.INDOOR}
            onClick={() => {
              setGeofenceType(GeofenceTypeEnum.INDOOR)
              setFieldValue(`geofences.${geofencesIndex}.circle`, false);
              setFieldValue(`geofences.${geofencesIndex}.polygon`, false);
              setFieldValue(`geofences.${geofencesIndex}.indoor`, true);
              setFieldValue(`geofences.${geofencesIndex}.radius`, 0);
              setGeofenceType(GeofenceTypeEnum.INDOOR);
            }}
          >
            <Wifi /> {formatMessage({ id: "indoorLocationGeofence", defaultMessage: "IndoorLocation" })}
          </Type>
        }

      </FieldWrapper>
    )
  })
)