import {Reducer} from "redux";
import { v4 } from "uuid";

import {InfoState} from "../types";
import {saveImage, removeImage, getNote, saveNote, sendMessage, getMessages} from "../routines";
import { DeviceNoteDTO } from "../../Info/types";

const infoState: InfoState = {
  imageUUID: v4(),
  note: {
    id: 0,
    deviId: 0,
    note: "",
    sms: false,
    lastChanged: 0,
    lastChangedBy: 0
  },
  log: {
    changedBy: ""
  },
  messages: []
};

const info: Reducer<InfoState> = (state: InfoState = infoState, action) => {
  switch (action.type) {
    case saveImage.SUCCESS:
      return {
        ...state,
        imageUUID: v4()
      };

    case removeImage.SUCCESS:
      return{
        ...state,
        imageUUID: v4()
      };

    case saveNote.SUCCESS:
    case getNote.SUCCESS:
      return updateState(state, action.payload)

    case sendMessage.SUCCESS:
    case getMessages.SUCCESS:
      return {
        ...state,
        messages: action.payload
      }

    default:
      return state;
  }
};

const updateState = (currentState: InfoState, payload: DeviceNoteDTO) => {
  
  const state = {
    ...currentState,
    log: {
      changedBy: payload.changedBy
    }
  };

  if(!payload.deviceNote){
    return {
      ...state,
      note: {...infoState.note}
    }
  }

  return {
    ...state,
    note: payload.deviceNote
  }
}

export default info;
