import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/INITIALIZE'
);

export const getUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_USER'
);

export const isSso: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/IS_SSO'
);

export const getSso: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_SSO'
);

export const getAdminSso: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_ADMIN_SSO'
);

export const getCustomer: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_CUSTOMER'
);

export const getAhp: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_AHP'
);

export const getTexts: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_TEXTS'
);

export const getPrivate: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_PRIVATE'
);

export const getPartner: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_PARTNER'
);

export const getSuborgsWithRoles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_SUBORGS_WITH_ROLES'
);

export const getMunicipalities: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_MUNICIPALITIES'
);

export const getPartnerUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_PARTNER_USER'
);

export const getBackdoorUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_BACKDOOR_USER'
);

export const getRestrictedUser: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_RESTRICTED_USER'
);

export const redirectPartner: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/REDIRECT_PARTNER'
);

export const getActiveRoles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_ACTIVE_ROLES'
);

export const getSettings: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_SETTINGS'
);

export const getMarket: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_MARKET'
);

export const getMarketSetting: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_MARKET_SETTING'
);

export const getLastAlarm: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_LAST_ALARM'
);
export const getAhpRoles: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_AHP_ROLES'
);
export const getBillingFilled: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_BILLING_FILLED'
);
export const isAhp: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/IS_AHP'
);
export const isPro: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/IS_PRO'
);
export const authAhp: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/AUTH_AHP'
);
export const authPro: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/AUTH_PRO'
);

export const getGdprManager: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/GET_GDPR_MANAGER'
);

export const getRequireNationalId: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_REQUIRE_NATIONAL_ID'
);

export const getPreventPartnerReferenceChange: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_PREVENT_PARTNER_REFERENCE_CHANGE'
);

export const getLanguages: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@Setup/GET_LANGUAGES'
);

export const initializeCustomerHierarchy: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@UserAdmin/INITIALIZE_CUSTOMER_HIERARCHY'
);