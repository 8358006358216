import styled from "styled-components";

export const SettingDiv = styled.div`
input {
  background: ${props => props.theme.colors.backgroundPrimary};
}
padding: 15px;
`;

export const SettingHeader = styled.div`
display: flex;
align-items: center;
font-size: 18px;
padding-bottom: 5px;

div {
  width: auto;
}
`

export const TitleSpan = styled.span`
path {
  fill: ${props => props.theme.colors.textPrimary};
}

display: flex;
align-items: center;

&::first-letter {
  text-transform: uppercase;
}
`;

export const TitleWrap = styled.p<{right?: boolean}>`
${props => props.right ? "margin-right" : "margin-left"}: 5px;
white-space: nowrap;
display: block;
justify-content: center;

div {
  display: inline-block;
}

&::first-letter {
  text-transform: uppercase;
}
`;

export const SaveContainer = styled.div<{disabled?: boolean}>`
  margin-left: auto;
  ${props => props.disabled &&
    `button{
      pointer-events:none;
    }`
  }
`;

export const ButtonContainer = styled(SaveContainer)`
  padding: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  width: 100%;
  border-top: ${props => props.theme.colors.border};
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
`;