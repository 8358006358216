import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/INITIALIZE'
);
export const initializeAlarms: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/INITIALIZE_ALARMS'
);
export const initializeReport: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/INITIALIZE_REPORT'
);

// Alarm
export const shareAlarm: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/SHARE_ALARM'
);
export const getAlarmAnalysis: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/GET_ALARM_ANALYSIS'
);
export const createComment: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/CREATE_COMMENT'
);
export const createCommentAndClose: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/CREATE_COMMENT_AND_CLOSE'
);
export const createCommentAndReOpen: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/CREATE_COMMENT_AND_REOPEN'
);
export const closeAll: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/CLOSE_ALL'
);
export const deleteComment: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/DELETE_COMMENT'
);
export const getHistoricAlarmsAndIncrease: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventView/GET_HISTORIC_ALARMS_AND_INCREASE'
);