import * as React from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { Collapse } from "react-collapse";
import styled from "styled-components";

import { RootState, User } from "../Store/types";
import {initialize, saveUser, getTextsMyPage,  initializeUser} from "../Store/routines";
import { Formik, Form, Field } from "formik";
import SafemateHeader from "../../Header";
import { PasswordDropdown, Email, Name, Mobile, Language, ApplyToAll, NewsLetter, Gdpr, Tfa, TfaType, TfaEnabled, EmailAsUsername, changeStyleSheet } from "./fields";
import Footer from "./footer";
import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import Dashboard from "../../../Elements/Icon/Icons/menu/Dashboard";
import { DatabaseCountry } from "../Store/types";
import  Loader  from "@Elements/Loader";
import {LoaderWrap} from "@Safemate/UserAdmin/Users/Edit/styles";
import { AppState } from "@Safemate/Store/types";
import { Style } from "@Safemate/MyPage/fields";



const StyledGrid = styled(Grid)`
  padding: 25px;
  border-radius: ${props => props.theme.colors.borderRadius};
  border: ${props => props.theme.colors.border};
`;

const DeleteButton = styled(Button)`
  margin-left: 5px;
  background: red;
`;

const styledCol = styled(Col)`
@media (min-width: 992px) {
  margin-top:63px;
}
`;

const CollapseWrapper = styled.div`
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  button {
    margin: 5px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const H1 = styled.h1`
  white-space: normal;
  font-size: 18px;
`;

const H1Settings = styled.h1`
  white-space: normal;
  font-size: 18px;
`;

export enum TfaRule{
  USER = "User"
}

interface MyPage extends WrappedComponentProps{
  user: User;
  allowDelete: string;
  tfaRules: string;
  privateUser: boolean;
  saveUser: Routine<ActionFunctionAny<Action<any>>>;
  initialized: boolean;
  getTextsMyPage: Routine<ActionFunctionAny<Action<any>>>;
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  onChangeLanguage: Function;
  texts: any[];
  countries: DatabaseCountry[];
}

const mapStateToProps = ({myPageBilling: {initialization: { initialized }, user: { user, allowDelete, tfaRules, privateUser }, country: {countries, texts}}}: AppState) => {
  return {
    initialized,
    user,
    allowDelete,
    tfaRules,
    privateUser,
    countries,
    texts
  } 
}

const mapDispatchToState = {
  initialize,
  saveUser,
  getTextsMyPage
}

const FormComp = ({  user, allowDelete, tfaRules, privateUser, saveUser, initialized, countries, onChangeLanguage, getTextsMyPage, initialize, texts, intl: { formatMessage }}: MyPage) => {

    React.useEffect(() => {
      initialize();
    }, []);

  const isTracker = false;


  if (user === undefined || !initialized){
    return (
      <LoaderWrap><Loader/></LoaderWrap>
    )
  } else {
    return(
      <Formik
        onSubmit={(value) => {
          saveUser({
            ...value,
            passwordClear: value.password,
            tfaEmail: value.tfaEmail === TfaType.EMAIL
          })
        }}
        enableReinitialize
        initialValues={{
          username: user.username,
          mailTo: user.mailTo,
          firstName: user.firstName,
          lastName: user.lastName,
          mobile: user.mobile,
          proffwebDark: user.proffwebDark,
          tfaEmail: user.tfaEmail ? TfaType.EMAIL : TfaType.SMS,
          tfaEnabled: user.tfaEnabled,
          gdprPsAccepted: user.gdprPsAccepted,
          password: "",
          currentPassword: "",
          applyToAll: false,
          confirm: "",
          emailAsUsername: user.emailAsUsername,
          languageCode: user.languageCode
        }}
      >
        {({values: { mobile, emailAsUsername }, dirty}) =>
          <Form>
            <HeaderWrapper>
              <SafemateHeader
                icon={null}
                title={`${formatMessage({id: "min", defaultMessage: "Min"})} ${formatMessage({id: "side", defaultMessage: "side"})}`}
              />
              {isTracker && <ButtonWrapper>
                <button onClick={() => {location.hash = `safemates`}} className="btn btn-dark-with-line btn-small"><Dashboard inline size="medium"/></button>
              </ButtonWrapper>}
            </HeaderWrapper>
            <StyledGrid fluid>
              <Row>
                <Col md={6}>
                  <H1>{formatMessage({id: "profile", defaultMessage: "Profile"})}</H1>
                  <Email disabled={user.isExternal} emailAsUsername={emailAsUsername} />
                  <Name disabled={user.isExternal}/>
                  <Mobile/>
                  {!user.isExternal &&
                  <React.Fragment>
                    <PasswordDropdown/>
                  </React.Fragment>}
                </Col>
                <Col md={6}>
                  <CollapseWrapper>
                    <Collapse isOpened={dirty && user.mobile !== mobile}>
                      <ApplyToAll/>
                    </Collapse>
                  </CollapseWrapper>
                  <H1Settings>{formatMessage({id: "instilligner", defaultMessage: "Instillinger"})}</H1Settings>
                  <Language languageCode={user.languageCode} countries={countries}/>
                  <Style/>
                  {privateUser && <TfaEnabled/>}
                  <Gdpr/>
                  {tfaRules === TfaRule.USER &&
                  <Tfa/>}
                  <NewsLetter/>
                </Col>
              </Row>
              <Footer
                dirty={dirty}
                email={user.mailTo}
                allowDelete={allowDelete}
                isPrivate={privateUser}
              />
            </StyledGrid>
            <UnsavedCheck/>
          </Form>
        }
      </Formik>
    )
  }


  }



export default connect(mapStateToProps, mapDispatchToState)(injectIntl(FormComp));
