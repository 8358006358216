import { Field } from "formik";
import styled from "styled-components";

export const DropdownWrapper = styled.div`
  width: 40%;
  margin-left: auto;
  margin-right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DropdownTitle = styled.p`
  margin-right: 10px;
`;

export const InactivityWrapper = styled.div`
  border: ${props => props.theme.colors.border};
  padding: 10px;
  display: flex;
  align-items: flex-end;
`;

export const FieldWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PeriodText = styled.p`
  margin: 0 5px;
`;

export const StyledInput = styled(Field)<{disabled?: boolean}>`
  border-radius: 5px;
  line-height: 2.3;
  width: 100%;
  height: 40px;
  background: ${props =>
    props.disabled
      ? 'rgba(202,202,203,0.1)'
      : props.theme.colors.backgroundPrimary};
  ${props => (props.disabled ? 'cursor: not-allowed' : '')};
  border: ${props => props.theme.colors.border};
  padding: 0px 15px;
`;