import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import {ITheme} from "../../../themes/types";

interface SizeProps{
  size?: string;
  theme?: ITheme;
}

const size = (props: SizeProps) => {
  if (props.size === 'small') return props.theme ? props.theme.icons.small : "16px";
  else if (props.size === 'medium') return props.theme ? props.theme.icons.medium : "24px";
  else if (props.size === 'large') return props.theme ? props.theme.icons.large : "32px";
  return '1em';
};

interface ComponentProps{
  size?: string;
  color?: string;
  float?: boolean;
  inline?: boolean;
  pointer?: boolean;
  tabIndex?: string;
  title?: string;
  onClick?: (event: MouseEvent) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  marginAuto?: boolean;
  overwriteSize?: {height: number; width: number;}
}

const extendIcon = (WrappedComponent: React.FunctionComponent<ComponentProps & any>) => {
  /**
   * Additional icon functionality can be defined here in ExtendedIcon.
   */
  class ExtendedIcon extends Component<ComponentProps & any, {}> {
    /*static displayName = `ExtendedIcon(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`;*/

    render() {
      const { size, overwriteSize, color, float, inline, pointer, ...otherProps } = this.props;
      return <WrappedComponent {...otherProps} />;
    }
  }

  return styled(ExtendedIcon)`
    width: ${props => props.overwriteSize ? props.overwriteSize.width : size(props)};
    height: ${props => props.overwriteSize ? props.overwriteSize.height : size(props)};
    fill: ${props =>
      (props.color && props.theme.colors[props.color]) ||
      props.color ||
      props.theme.colors.textPrimary};
    transition: 0.5s fill;
    display: ${props => (props.inline ? 'inline' : 'block')};
    ${props => props.float && `float: ${props.float};`};
    ${props => props.pointer && 'cursor: pointer;'};
    ${props => props.marginAuto && 'margin: auto;'};
  `;
};

export default extendIcon;
