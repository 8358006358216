import styled from 'styled-components';

const Bar = styled.div`
  border: 1px solid gray;

  border-radius: 4px;
  height: 15px;
  flex-grow: 1;

  & + & {
    margin-left: 5px;
  }
`;

export default Bar;
