import {InitializationState} from "../types";
import {Reducer} from "redux";
import {initialize, initializeUser } from "../routines";
import { MapActionTypes } from "../actionTypes";
import {user} from "../../../UserAdmin/utils";
import { MapType } from "@Safemate/map";


const initializationState: InitializationState = {
  initialized: false,
  mapType: MapType.SINGLE,
  user: user()
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action) => {
  switch (action.type) {
    case initialize.SUCCESS:
      return {
        ...state,
        initialized: true,
        mapType: action.payload.mapType
      };
    case initializeUser.SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case MapActionTypes.RESET_STATE:{
      return initializationState
    }

    default:
      return state;
  }
};

export default initialization;
