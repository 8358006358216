import styled from 'styled-components';

export const STable = styled.table`
  width: 100%;
`;

export const Sth = styled.th`
  border: 0;
  background: none;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 12px;

  @media (max-width: 768px) {
    padding: 12px 5px;
  }
`;

export const Std = styled.td`
  vertical-align: middle;
  padding: 12px;
  border-top: ${props => props.theme.colors.border};
`;

export const HiddenInput = styled.input`
  display:none;
`;

export const StdSecondary = styled.td`
  vertical-align: middle;
  padding: 12px;
  border-top: ${props => props.theme.colors.border};

  @media (max-width: 768px) {
    padding: 12px 5px;
  }
`;

export const WrapDiv = styled.div<{color?: string}>`
  display: inline;
  svg {
    ${props => props.color && `fill: ${props.theme.deviceStatus[props.color]};`}
    width: 32px;
    height: 32px;
  }
`
export const HorizontalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

export const SelectWrap = styled.div`
  width: 200px;
`;

export const AddWrap = styled.div`
  justify-content: flex-end;
  display: flex;
  padding: 0 0 10px 0;
`;
