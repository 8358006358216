import { Policies } from "@Safemate/Policy/types";
import * as React from "react";
import { connect } from "react-redux";
import EventSetupBody from "./Body";
import Header from "./Header";
import { initialize } from "./Store/routines";
import { AppState } from "@Safemate/Store/types";
import { SiteTypeEnum } from "@Safemate/SiteTypeEnum";
import { useParams } from "react-router";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import Spinner from "../Spinner";
import { useIntl } from "react-intl";
import styled from "styled-components";

const Wrap = styled.div`
  position: relative;
`;

interface EventSetupProps{
  policies: Policies;
  initialized: boolean;
  selectingDeviceLoad: boolean;
  currentContext: SiteTypeEnum;
  initialize: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ appData: {currentContext}, eventSetup: {policy: { policies }, initialization: { initialized }, device: { selectingDeviceLoad }}}: AppState) => {
  return {
    policies,
    initialized,
    selectingDeviceLoad,
    currentContext
  }
}

const mapDispatchToProps = {
  initialize
}

interface EventSetupParams{
  id: string;
}

const EventSetup =  connect(mapStateToProps, mapDispatchToProps)(({ initialized, initialize }: EventSetupProps) => {

  const {id} = useParams<EventSetupParams>();

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    initialize({deviceId: id})
  }, [])

  if(!initialized){
    return(
      <Wrap>
        <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
      </Wrap>
    )
  }

  return (
    <React.Fragment>
      <Header/>
      <EventSetupBody/>
    </React.Fragment>
  )
})

export default EventSetup;
