import * as React from "react";
import { Col, Button } from "react-bootstrap";
import styled from "styled-components";
import { useIntl } from "react-intl";
import Loader from "@Elements/Loader";

const Wrapper = styled.div`
  border: ${props => props.theme.colors.border};
  border-radius: 4px;
  padding: 30px;
  height: 325px;
`;

const Desc = styled.p`
  color: #858C97;
  line-height: 18px;
  font-size: 13px;
`;

const ContentWrapper = styled.div`
  margin-top: 40px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 60px;
  display: flex;
`;

export const NextButton = styled(Button)`
  margin-left: auto;
`;

const H2 = styled.h2`
  margin-bottom: 0;
  &::first-letter {
    font-size: 36px;
  }
`;

export const LoaderWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  svg {
    height: 30px;
    width: 30px;
  }
`;

interface StepProps{
  title: string;
  desc: string;
  twin?: boolean;
}

const Step = ({children, title, desc}: React.PropsWithChildren<StepProps>) => {

  return(
    <Col lg={4}>
      <Wrapper>
        <H2>
          {title}
        </H2>
        <Desc>
          {desc}
        </Desc>
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </Wrapper>
    </Col>
  )
}

export default Step;