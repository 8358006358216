import * as React from "react";
import { useIntl } from "react-intl";
import { ModalBody, ModalDiv, ModalHeader } from "@Safemate/EventSetupNew/Body/ModalStyles";
import withFormWrapper from "@Elements/Form/formWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventWrapperRow } from "@Safemate/EventSetupNew/Body/GridStyles";
import { ButtonDiv, EventAlarm, EventText, EventType, RotatedFontAwesomeIcon } from "@Safemate/EventSetupNew/Body/WrapperStyles";
import { Event } from "./types";
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { EventSetupSettings } from "../types";
import { SettingEnum, SettingPrefixEnum } from "../settingEnum";
import { Grid } from "react-bootstrap";
import { AllEvents } from "@Safemate/EventSetupNew/Body/Events/eventTypes";
import { StyledButton } from "@Safemate/EventSetupNew/Body/FormElementStyles";
import { EventCardWrapper } from "./styles";

interface EventsProps{
  index: number;
  display: (display: boolean) => void;
}

export const Events = withFormWrapper<EventSetupSettings, EventsProps>(
  ({formik: { setFieldValue, initialValues: { deviceEventSetups: initial }, values: { deviceEventSetups } }, index, display}) => {

  const { formatMessage } = useIntl();

  const prefix = `${SettingEnum.EVENT_SETUP}.${index}`;

  return(
    <ModalDiv style={{ textAlign: 'center' }}>
      <ModalHeader style={{ textAlign: 'left' }}>
        {formatMessage({  
          id: 'eventTypes',
          defaultMessage: 'Event Types'
        })}
      </ModalHeader>
      <Grid fluid>{
        AllEvents.map(event => 
          <EventCardWrapper xs={6} sm={4} md={3}>
            <EventCard   
              selected={deviceEventSetups[index][event.type]}
              event={event}
              index={index}
            />
          </EventCardWrapper>
        )
      }</Grid>
      <ButtonDiv>
        <StyledButton
          id="confirm-events"
          onClick={() => {
            display(false);
          }}
        >
          {formatMessage({ id: 'ok', defaultMessage: 'Ok' })}
        </StyledButton>
        <StyledButton
          id="cancel-events"
          onClick={() => {
            AllEvents.forEach(event => {
              setFieldValue(`${prefix}.${event.type}`, initial[index][event.type]);
            })
            display(false);
          }}
        >
          {formatMessage({
            id: 'cancel',
            defaultMessage: 'Cancel'
          })}
        </StyledButton>
      </ButtonDiv>
    </ModalDiv>
  )
})

interface EventCardProps{
  index: number;
  event: Event;
  selected: boolean;
}

const EventCard = withFormWrapper<EventSetupSettings, EventCardProps>(({ formik: { setFieldValue }, index, event, selected }) => {

  const { formatMessage } = useIntl();

  const prefix = `${SettingEnum.EVENT_SETUP}.${index}.${event.type}`;

  return (
    <EventWrapperRow
      write={true}
      id={event.type}
      selected={selected}
      tabIndex={0}
      onClick={() => {
        setFieldValue(prefix, !selected);
      }}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          setFieldValue(prefix, !selected);
        }
      }}
    >
      <EventType selected={selected}>
        {(event.phone && <RotatedFontAwesomeIcon icon={faPhone} />) ||
          (event.sms && <FontAwesomeIcon icon={faEnvelope} />)}
      </EventType>
      <EventAlarm selected={selected} color={event.alarm}>
        {event.iconMedium}
      </EventAlarm>
      <EventText selected={selected}>
        {formatMessage({ id: event.text.label, defaultMessage: event.text.fallback })}
      </EventText>
    </EventWrapperRow>
  )
})