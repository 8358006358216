export const sensorSupervisionCheckTimer = [0, 14400, 18000, 21600, 28800, 43200, 86400];

export const sensorSupervisionCheckTimerPendant = [0, 90000, 172800, 259200, 345600, 432000, 518400, 604800];

export const guardTime = [0, 10, 20, 30, 60, 120, 180, 240];

export const bypassTimer = [0, 30, 60, 120, 180, 240, 300];

export const sensorSensitivity = [
  {text: "1", value: 1},
  {text: "2", value: 2},
  {text: "3", value: 3},
  {text: "4", value: 4},
  {text: "5", value: 5}
]

export const alarmSmsReceipt = [
  {text: "alarmSmsReceiptAll", value: "all"}, 
  {text: "alarmSmsReceiptAllNone", value: "none"}, 
  {text: "alarmSmsReceiptCalled", value: "called"}, 
  {text: "alarmSmsReceiptAccepted", value:"accepted"}
];