import {injectIntl, WrappedComponentProps} from "react-intl";
import withFormWrapper from "@Elements/Form/formWrapper";
import * as React from "react";
import {Button, Col, Row} from "react-bootstrap";
import {IndoorLocationFormik} from "../types";
import Trash from "../../../../Elements/Icon/Icons/Trash";
import {connect} from "react-redux";
import {
  AccentedText, CheckBoxWrapper,
  IndoorHeading,
  ListCol,
  ListColRight,
  ListColWrapperLeft,
  ListColWrapperRight,
  ListRow,
  StyledField,
} from "@Safemate/Settings/IndoorLocation";
import {setIndoorLocationValuesChanged, SetIndoorLocationValuesChanged,} from "@Safemate/Settings/Store/actions";
import {formatNorwegianDateMin} from "@Safemate/Settings/Indoor/utils";
import {default as Signal4Icon} from "@Icons/signal/Signal4";
import {default as Signal3Icon} from "@Icons/signal/Signal3";
import {default as Signal2Icon} from "@Icons/signal/Signal2";
import {default as Signal1Icon} from "@Icons/signal/Signal1";
import {default as Signal0Icon} from "@Icons/signal/Signal0";
import {default as Wifi} from "@Icons/Wifi";
import {default as Bluetooth} from "@Icons/Bluetooth";
import InformationIcon from "../../../../Elements/Icon/Icons/InformationIcon";
import {FieldArray} from "formik";
import {Checkbox} from "../../../../Elements/CheckboxFormik";
import {confirmModal} from "@Safemate/Settings/IndoorLocation/utils";
import {PolicyNameEnum} from "@Safemate/PolicyNameEnum";
import {accessToCustomer, checkPermissionsForCustomer} from "@Safemate/Policy/rightsUtil";
import {SettingAllowCustomerGlobalRoles, } from "@Safemate/Settings/tabs";
import { Customer } from "@Safemate/AddSafemate/Store/types";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { Option } from "@Elements/Dropdown/types";
import styled from "styled-components";
import Check from "@Icons/Check";
import {ColFlexCenter} from "@Safemate/Settings/IndoorLocation/indoorLocationComp";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import {saveIndoorLocationName} from "@Safemate/Settings/Store/routines";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";

const DropdownWrapper = styled.div`  
  width: 100%;
`;

const Wrapper = styled.div`
  background: ${props => props.theme.colors.backgroundPrimary};
  display: inline-flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

const CheckWrapper = styled.button<{disabled?: boolean}>`
  flex: 0 1;
  border: ${props => props.theme.colors.border};
  border-left: none;
  border-radius: 0 4px 4px 0;
  color: white;
  fill: white;
  display: inline-flex;
  background: ${props => props.theme.colors.backgroundPrimary};
  align-items: center;
  padding: 0 15px;
  transition: 0.2s;

  ${props => props.disabled && "cursor: not-allowed;"}
  ${props => !props.disabled && `&:hover{
    background: ${props.theme.colors.accent};
    svg g{
      fill: ${props.theme.colors.backgroundSecondary};
    }
  }`}

  svg g{
    fill: ${props => props.disabled ? props.theme.colors.borderColor : props.theme.colors.textPrimary};
  }
`;

const OpacityButton = styled(Button)`
  opacity: 1;
`;

interface ReferencePointsProps extends WrappedComponentProps{
  setIndoorLocationValuesChanged: SetIndoorLocationValuesChanged;
  saveIndoorLocationName: Routine<ActionFunctionAny<Action<any>>>;
  valuesChanged: boolean;
  customers: Customer[];
  ownedByCustomer: number;
  isPrivate: boolean;
}

const mapStateToProps = ({ settings: {indoorLocation: { valuesChanged, customers }, device: { device: { ownedByCustomer }}}, appData:{isPrivate}}: AppState) => {
  return {
    valuesChanged, customers, ownedByCustomer, isPrivate
  }
}



const mapDispatchToProps = {
  setIndoorLocationValuesChanged,
  saveIndoorLocationName
};

export const IndoorReferencePoints = injectIntl(withFormWrapper<IndoorLocationFormik, ReferencePointsProps>(
  ({formik: { setFieldValue, values }, intl: { formatMessage }, setIndoorLocationValuesChanged, saveIndoorLocationName, customers, ownedByCustomer, valuesChanged, isPrivate  }) => {

    const {Comp, func} = confirmModal();

    const [ deviceIndex, setDeviceIndex ] = React.useState(0);

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const sharedAccess = checkPermissionsForCustomer(permissions, PolicyNameEnum.DEVICE_INDOOR_CUSTOMER, ownedByCustomer) && !isPrivate;
    const sharedLocation = values.indoorLocation.custId > 0 && values.indoorLocation.deviId == 0;
    const disabledEdit: boolean = sharedLocation ? !sharedAccess : false;
    const isNew: boolean = values.indoorLocation.inloId < 1;

    const spliceDevice = (index: number) => {
      values.indoorLocation.indoorLocationDevices.splice(index, 1);
    }

    const mappedCustomers: Option[] = React.useMemo(() => {
      return customers.map((customer) => {
        return{
          text: customer.firmName,
          value: customer.custId
        }
      })
    }, [customers])

    const signalBar = (signalStrength: number) => {
      return (
        <ListColWrapperRight>
          <ListColRight>
            {
              signalStrength<1 && signalStrength>=-50 &&
              <Signal4Icon size={`medium`} />
            }
            {
              signalStrength<-50 && signalStrength>=-60 &&
              <Signal3Icon size={`medium`} />
            }
            {
              signalStrength<-60 && signalStrength>=-67 &&
              <Signal2Icon size={`medium`} />
            }
            {
              signalStrength<-67 && signalStrength>=-70 &&
              <Signal1Icon size={`medium`} />
            }
            {
              signalStrength<-70&&
              <Signal0Icon size={`medium`} />
            }
          </ListColRight>

        </ListColWrapperRight>
      )
    }

    return(
      <React.Fragment>
        <FieldArray name="devices" render={({ insert, remove, push }) => (
        <React.Fragment>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <IndoorHeading>
                    <span style={{paddingRight: "10px"}}>{formatMessage({id: "indoorLocationNameHeading", defaultMessage: "Indoor location name"})}</span>
                    <Tooltip
                      popoverId={`indoorLocationNameInfo`}
                      labelId={`indoorLocationNameLabel`}
                      defaultMessage="Name of the indoor location"
                    >
                      <InformationIcon size="medium" />
                    </Tooltip>
                  </IndoorHeading>
                </Col>
              </Row>
              <Row>
                <ColFlexCenter xs={12} sm={12} md={4} mdOffset={4} style={{paddingBottom: '10px'}}>
                  <Wrapper>
                    <StyledField style={{borderRadius: "4px 0 0 4px"}} type="text" name={`indoorLocation.name`} placeHolder={formatMessage({id: "indoorLocationNamePlaceHolder", defaultMessage: "Indoor location name"})} disabled={disabledEdit}/>
                    <CheckWrapper disabled={disabledEdit || isNew} onClick={() => saveIndoorLocationName(values.indoorLocation)} type={"button"}><Check size={{width: "25px", height: "25px"}} inline/></CheckWrapper>
                  </Wrapper>
                </ColFlexCenter>
                { sharedAccess && <Col sm={12} md={4} mdOffset={4}>
                  <Row>
                    <Col sm={12} md={12} style={{paddingBottom: '10px'}}>
                      <CheckBoxWrapper>
                        <Checkbox value={values.indoorLocation.shared}
                                  label={formatMessage({id: "sharedIndoorLocationCustomerLabel", defaultMessage: "Share Wi-Fi location"})}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const checked = e.target.checked;
                                    setFieldValue("indoorLocation.shared", checked)
                                    setFieldValue( "indoorLocation.custId", values.indoorLocation.custId > 0 ? values.indoorLocation.custId : ownedByCustomer )
                                  }}
                                  id={`sharedIndoorLocationCustomer`}
                                  disabled={sharedLocation}
                        />
                        <Tooltip
                          popoverId={`sharedIndoorLocation-popover`}
                          labelId={`sharedIndoorLocationInfo`}
                          defaultMessage="Share indoor location for sub org"
                        >
                          <InformationIcon size="medium" />
                        </Tooltip>
                      </CheckBoxWrapper>
                    </Col>
                    { values.indoorLocation.shared &&

                        <Col sm={12} md={12} style={{paddingBottom: '10px'}}>
                          <DropdownWrapper>
                            <Dropdown
                              options={mappedCustomers}
                              onChange={(option) => {
                                const { value } = option as Option;
                                setFieldValue("indoorLocation.custId", value);
                              }}
                              initial={ values.indoorLocation.custId > 0 ? values.indoorLocation.custId : ownedByCustomer}
                              searchable={true}
                              disabled={sharedLocation}
                              size={{height: "40px"}}
                            />
                          </DropdownWrapper>
                        </Col>
                    }
                    </Row>

                </Col>
                 }
              </Row>
            </Col>
        </Row>
        {
          !isNew &&
          <div>

              {
                values.indoorLocation.indoorLocationDevices && values.indoorLocation.indoorLocationDevices.length > 0 && values.indoorLocation.indoorLocationDevices.map((indoorLocationDevice, index)=>{
                    return (
                      <React.Fragment>
                        { !indoorLocationDevice.add && <ListRow key={`indoorDeviceKey-${indoorLocationDevice.indeId > 0 ? indoorLocationDevice.indeId :index}`}>
                          <Col xs={2} md={1}><ListColWrapperLeft><ListCol>{ (indoorLocationDevice.type==="W") ? <Wifi size={`medium`} /> : <Bluetooth size={`medium`} />}</ListCol></ListColWrapperLeft></Col>
                          <Col xs={10} md={4}><ListColWrapperLeft><ListCol>{indoorLocationDevice.name}</ListCol></ListColWrapperLeft></Col>
                          <Col xs={6} md={2}><ListColWrapperLeft><ListCol>{formatNorwegianDateMin(indoorLocationDevice.scannedTime)}</ListCol></ListColWrapperLeft></Col>
                          <Col xs={6} md={1}><ListColWrapperLeft><ListCol>{signalBar(indoorLocationDevice.signalStrength)}</ListCol></ListColWrapperLeft></Col>
                          <Col xs={10} md={3}><ListColWrapperLeft><ListCol>{indoorLocationDevice.macAddress}</ListCol></ListColWrapperLeft></Col>
                          <Col xs={2} md={1}>
                            {
                              !disabledEdit && <ListColWrapperRight>
                                <ListColRight>
                                  <Trash
                                    tabIndex="0"
                                    title={formatMessage({id: 'delete', defaultMessage: "Slett"})}
                                    size="medium"
                                    onClick={(event: any) => {
                                      setDeviceIndex(index);
                                      func(true);
                                    }}
                                    inline
                                  />
                                </ListColRight>
                              </ListColWrapperRight>
                            }
                          </Col>
                        </ListRow>}
                        {
                          indoorLocationDevice.add && <ListRow key={index}>
                            <Col xs={2} md={1}><ListColWrapperLeft><ListCol>{ (indoorLocationDevice.type==="W") ? <Wifi size={`medium`} /> : <Bluetooth size={`medium`} />}</ListCol></ListColWrapperLeft></Col>
                            <Col xs={10} md={4}><ListColWrapperLeft><ListCol><StyledField type="text" name={`indoorLocation.indoorLocationDevices.${index}.name`} /></ListCol></ListColWrapperLeft></Col>
                            <Col xs={6} md={2}><ListColWrapperLeft><ListCol>{formatNorwegianDateMin(indoorLocationDevice.scannedTime)}</ListCol></ListColWrapperLeft></Col>
                            <Col xs={6} md={1}><ListColWrapperLeft><ListCol>{signalBar(indoorLocationDevice.signalStrength)}</ListCol></ListColWrapperLeft></Col>
                            <Col xs={10} md={3}><ListColWrapperLeft><ListCol><StyledField type="text" name={`indoorLocation.indoorLocationDevices.${index}.macAddress`} /></ListCol></ListColWrapperLeft></Col>
                            <Col xs={2} md={1}>
                              <ListColWrapperRight>
                                <ListColRight>
                                  <Trash
                                    tabIndex="0"
                                    title={formatMessage({id: 'delete', defaultMessage: "Slett"})}
                                    size="medium"
                                    onClick={(event: any) => {
                                      setDeviceIndex(index);
                                      func(true);
                                    }}
                                    inline
                                  />

                                </ListColRight>
                              </ListColWrapperRight>
                            </Col>
                          </ListRow>
                        }

                      </React.Fragment>
                    )

                  }
                )
              }
          </div>
        }

        </React.Fragment>
          )} />
        <Comp
          title={`${formatMessage({id: "delete", defaultMessage: "Delete"})} ${formatMessage({id: "indoorLocation", defaultMessage: "indoor location"})}`}
          deleteButton={true}
          body={values.indoorLocation.indoorLocationDevices[deviceIndex] &&
            <p>
              <span>{formatMessage({id: "deleteIndoorLocationDeviceConfirmtext", defaultMessage: "Do you want to really delete indoor device"})}</span>
              <AccentedText>{` ${values.indoorLocation.indoorLocationDevices[deviceIndex].name} (${values.indoorLocation.indoorLocationDevices[deviceIndex].macAddress})?`}</AccentedText>
            </p>
          }
          confirmText={formatMessage({id: "delete", defaultMessage: "Slette"})}
          confirmFunc={() => {
            spliceDevice(deviceIndex);
            setIndoorLocationValuesChanged(true);
            func(false);
          }}
        />
      </React.Fragment>
    )}
))

export const ReferencePointsEdit = connect(mapStateToProps, mapDispatchToProps)(IndoorReferencePoints);
