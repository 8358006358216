import {createRoutine, Routine} from 'redux-saga-routines';
import {Action, ActionFunctionAny} from "redux-actions";

// Initialization
export const initialize: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/INITIALIZE'
);

// Policy
export const getPolicies: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/GET_POLICIES'
);

// Device
export const getDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/GET_DEVICE'
);
export const setDevice: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/SET_DEVICE'
);
export const updateName: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/UPDATE_NAME'
);
export const updateOverride: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/UPDATE_OVERRIDE'
);

// Device Event Setup
export const getDeviceEventSetups: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/GET_DEVICE_EVENT_SETUPS'
)
export const deleteDeviceEventSetup: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/DELETE_DEVICE_EVENT_SETUP'
);
export const saveDeviceEventSetup: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/SAVE_DEVICE_EVENT_SETUP'
);
export const editSort: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/EDIT_SORT'
);
export const setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/SET_DEVICE_EVENT_SETUP_MODAL'
);

// Ahp
export const getDefaultAhp: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/GET_DEFAULT_AHP'
);
export const getAhpPrivateLink: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/GET_AHP_PRIVATE_LINK'
);
export const setAhp: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/SET_AHP'
);

// Capability
export const getCapabilities: Routine<ActionFunctionAny<Action<any>>> = createRoutine(
  '@@EventSetup/GET_CAPABILITIES'
);