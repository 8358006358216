import * as React from "react";
import { useIntl } from "react-intl";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { connect } from "react-redux";
import { resendVerification } from "../Store/routines";
import { Button } from "react-bootstrap";

interface ResendVerificationProps{
  resendVerification: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToProps = {
  resendVerification
}

const ResendVerification = connect(null, mapDispatchToProps)(({resendVerification}: ResendVerificationProps) => {

  const { formatMessage}  = useIntl();

  const [ username, setUsername ] = React.useState("");

  const keyUpEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === "Enter"){
      doResendVerification();
    }
  }

  const changeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  }

  const doResendVerification = () => {
    resendVerification({username})
  }

  return(
    <React.Fragment>
      <h4>{formatMessage({id: "forgotDesc", defaultMessage: "forgotDesc"})}</h4>
      <input value={username} style={{textTransform: "lowercase"}} className="focus" type="email"
          placeholder={formatMessage({id: "email", defaultMessage: "E-post"})}
          onKeyUp={keyUpEvent}
          onChange={changeUsername}
        />
      <Button disabled={!username} onClick={doResendVerification} type="button" className="btn btn-default">
        {formatMessage({id: "sendVericationEmail", defaultMessage: "sendVericationEmail"})}
      </Button>
    </React.Fragment>
  )
})

export default ResendVerification;