import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {  deleteCustomer, getPaymentForNew } from "../Store/routines";
import {RootState, Customer, MappedSubOrgs} from "../Store/types";
import { CreateCustomer, createCustomer } from "../Store/actions";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import {GlobalRoles} from "../../GlobalRoles";
import {accessToCustomer} from "../../Policy/rightsUtil";
import { LoaderWrap } from "../Form/styled";
import Loader from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";



const DeleteButton = styled(Button)`
  margin-top:10px;
  width: 100%;
`;

const NewOrgButton = styled(Button)`
  margin-top:10px;
  width: 100%;
`;

interface FooterProps{
  selectedCustomer: Customer;
  customersForForm: MappedSubOrgs;
  processingDelete: boolean;
  createCustomer: CreateCustomer;
  deleteCustomer: Routine<ActionFunctionAny<Action<any>>>;
  getPaymentForNew: Routine<ActionFunctionAny<Action<any>>>;
}
const mapStateToProps = ( { subUnits: {customer: {selectedCustomer, customersForForm}, initialization: { processingDelete }}} : AppState) => {
  return{
    selectedCustomer,
    customersForForm,
    processingDelete
  }
};
const mapDispatchFromState = {
  deleteCustomer,
  createCustomer,
  getPaymentForNew
}

export const Footer = connect(mapStateToProps, mapDispatchFromState)(
  ({ deleteCustomer, createCustomer, getPaymentForNew, selectedCustomer, customersForForm, processingDelete}: FooterProps) => {

    const { formatMessage } = useIntl();

    if(!customersForForm[selectedCustomer.customerId]) return null;


    const { firm: { parent = 0 } = {}, numberOfDevices = 0 } = customersForForm[selectedCustomer.customerId];
    
    const handleDelete = () => {
      deleteCustomer(selectedCustomer.customerId);
    }
    const handleNew = () => {
      createCustomer();
      getPaymentForNew(selectedCustomer.customerId);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const hasAccess = accessToCustomer(selectedCustomer.policies, "", [GlobalRoles.SUPERFIRM]);
    
    let customerName = selectedCustomer.customerName;

    if(customerName.length > 15){
      customerName = customerName.substring(0.,15) + "...";
    }


  return(
    <Row>
      {hasAccess && <Col md={12}>
        {processingDelete 
          ? <LoaderWrap><Loader/></LoaderWrap>
          : <DeleteButton onClick={handleDelete} disabled={!(numberOfDevices == 0 && parent != 0)}>
              {formatMessage({id: "Delete", defaultMessage: "SLETTE"})} {customerName}
            </DeleteButton>}

        <NewOrgButton onClick={handleNew}>
          {formatMessage({id: "newSuborg", defaultMessage: "LEGG TIL NY UNDERAVDELING"})}
        </NewOrgButton>
      </Col> }
    </Row>
  )
})

export default Footer;