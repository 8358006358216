import { put, race, select, take, takeLatest} from 'redux-saga/effects';
import * as Routines from "../routines";
import {AppAction} from "../../../DefaultSettings/Store/types";
import {Customer} from "../../Models/Customer";
import { SiteTypeEnum } from "../../../SiteTypeEnum";
import { selectIsPartner } from '@Safemate/Store/selectors';

export interface Initialize{
  isPartner: boolean;
}

export interface IRace<T> {
  success: AppAction<T>;
  failure: any;
}

function* initialize(action: AppAction<Initialize>){
  try {

    const isPartner: boolean = yield select(selectIsPartner);

    if(isPartner){
      yield put(Routines.fetchCustomersForList());
      const {success: forListSuccess, failure: forListFailure}: IRace<Array<Customer>> = yield race({
        success: take(Routines.fetchCustomersForList.SUCCESS),
        failure: take(Routines.fetchCustomersForList.FAILURE)
      });
      yield put(Routines.initializeCustomers.success());
    }else{
      location.hash = "safemates";
    }
  }
  catch(e){
    console.log(e);
    yield put(Routines.initialize.failure());
  }
}

function* initializationSaga(){
  yield takeLatest(Routines.initialize.TRIGGER, initialize);
}

export default initializationSaga;
