/*
This has to match the DEHA_ID in the device_hardware table, these IDs should never change,
but if there's a bug with the selection of device hardware it is probably because of a ID mismatch.
 */

export enum DeviceHardwareEnum{
  DEFAULT= -1,
  ALL = 0,
  TRIGGER_ONE = 1,
  RIO_APP= 2 ,
  TRACK_ONE = 3 ,
  TRIGGER_TWO= 4,
  TRIGGER_THREE = 5,
  ANALOG_TURBO = 7,
  TRACK_THREE = 8,
  WATCH_ONE = 11,
  SAFEMATE_HOME = 13,
  WATCH_TWO = 14,
  TRIGGER_FOUR = 15,
  TRIGGER_FIVE = 16,
  WATCH_THREE = 17,
  SAFEMATE_HELP = 20,
  TRIGGER_THREE_E = 21,
  SAFEMATE_HOME_DT35 = 22,
  TRIGGER_TWO_4G = 23,
  WATCH_FOUR = 24,
  TRACK_TWO = 25,
  SAFEMATE_HOME_MAX = 26,
  GTX_SOLE = 28,
  WATCH_FIVE = 29,
  WATCH_SIX = 30,
  TRACK_FOUR = 31,
  SAFEMATE_HOME_STCB1 = 32,
}



export const isGx8 = (dehaId: DeviceHardwareEnum) => 
  dehaId === DeviceHardwareEnum.SAFEMATE_HOME || dehaId === DeviceHardwareEnum.SAFEMATE_HOME_DT35 || dehaId === DeviceHardwareEnum.SAFEMATE_HOME_MAX || dehaId === DeviceHardwareEnum.SAFEMATE_HOME_STCB1;

export const isEview07B = (dehaId: DeviceHardwareEnum) => 
  dehaId === DeviceHardwareEnum.TRIGGER_FOUR || dehaId === DeviceHardwareEnum.TRIGGER_THREE_E || dehaId === DeviceHardwareEnum.WATCH_FOUR || dehaId === DeviceHardwareEnum.TRACK_TWO || dehaId === DeviceHardwareEnum.WATCH_SIX;
