import { FastField } from "formik";
import styled from "styled-components";

export const StyledInput = styled(FastField)<{disabled?: boolean}>`
  border-radius: 5px;
  line-height: 2.3;
  width: 40%;
  height: 40px;
  background: ${props =>
    props.disabled
      ? 'rgba(202,202,203,0.1)'
      : props.theme.colors.backgroundPrimary};
  ${props => (props.disabled ? 'cursor: not-allowed' : '')};
  border: ${props => props.theme.colors.border};
  padding-left: 15px;
`;

export const FullWidthStyledInput = styled<any>(StyledInput)`
  width: 100%;
  height: 42px;
`;