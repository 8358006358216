import React, { Component } from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import { saveAs } from 'file-saver';
import ReactPDF, {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";
import ExportPDF from "../Icon/Icons/Safemate/outline/ExportPDF";
import ExportCSV from "../Icon/Icons/Safemate/outline/ExportCSV";
import {Button, Col} from "react-bootstrap";
import Loader from "@Elements/Loader";
import { SvgButton } from "@Safemate/styles";

interface IExportComponentProps{
  type: string,
  data: Array<Array<string>>,
  fileName: string,
  colWidth?: number[];
  pageNumber?: number;
}

interface IExportComponentPropsUncapped{
  type: string,
  data: Array<Array<string>>,
  fileName: string,
  colWidth?: number[];
  pageNumber?: number;
  inProgress?: string;
  inProgressSetter: Function;
}

const ExportButton = styled(Button)`
  min-width: 90px;
`

const ExportCsvOrPdf = (props: IExportComponentProps): any => {

  const data = props.data;
  if(props.type === "csv"){
    const filename = props.fileName + "_" + getFormatedDate() + "." + props.type;
    return  <CSVLink data={data} filename={filename} >
      <button className="btn btn-default svg-icon">
        <ExportCSV size="medium" inline /> CSV
      </button>
    </CSVLink>;
  } else if(props.type === "pdf" ){
    if(props.data.length<252){
      let pageNum=1;
      return  <button className="btn btn-default svg-icon" style={{marginLeft: '1em'}} onClick={async () => {
        const filename = props.fileName + "_" + getFormatedDate() + "_"+ pageNum +"." + props.type;
        const doc = <PdfDocument colWidth={props.colWidth} data={props.data} fileName={props.fileName} type={props.type} pageNumber={pageNum}></PdfDocument>;
        if( (props.data.length - 1) > (pageNum * maxRowsPerDocument) ){
          pageNum = pageNum + 1;
        }
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, filename);
      }}>
        <ExportPDF size="medium" inline /> PDF
      </button>;
    } else {
      return <React.Fragment></React.Fragment>
    }

  }
}

const ExportCsvOrPdfUncapped = (props: IExportComponentPropsUncapped): any => {
    let pageNum=1;
    const data = props.data;
    const [loading, setLoading] = React.useState(false);
    const [loadingCSV, setLoadingCSV] = React.useState(false);
    const handleClickPDF = (pageNum: number) => {
      if(!loading){
        setLoading(true);
      }
    };

    React.useEffect(() => {
      if(loading){
        handleClicker(pageNum);
      }
    },[loading])

    const handleClicker = async(pageNum: number) => {
      const filename = props.fileName + "_" + getFormatedDate() + "_"+ pageNum +"." + props.type;
      const doc = <PdfDocument colWidth={props.colWidth} data={props.data} fileName={props.fileName} type={props.type} pageNumber={pageNum}></PdfDocument>;
      if( (props.data.length - 1) > (pageNum * maxRowsPerDocument) ){
        pageNum = pageNum + 1;
      }
      const asPdf = pdf();
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      await saveAs(blob, filename);
      setLoading(false);
    }


    if(props.type === "csv"){
      const filename = props.fileName + "_" + getFormatedDate() + "." + props.type;
      return  <CSVLink data={data} filename={filename} >
        <button className="btn btn-default svg-icon">
          {loadingCSV ? <Loader/> : <Col><ExportCSV size="medium" inline /> CSV</Col>  }
        </button>
      </CSVLink>;
    } else if(props.type === "pdf" ){
      if(props.data){
        return  <button className="btn btn-default svg-icon" style={{marginLeft: '1em'}} onClick={() => {
            setLoading(true);
            handleClickPDF(pageNum);
        }}>
          {loading ? <Loader/> : <Col><ExportPDF className="loadd" size="medium" inline />PDF</Col>  }
          
          
          </button>;
      } else {
        return <React.Fragment></React.Fragment>
      }
  
    }
  }

export default class TableExport extends Component<IExportComponentProps>{
  render() {
    return (
      <ExportCsvOrPdf colWidth={this.props.colWidth} type={this.props.type} data={this.props.data} fileName={this.props.fileName} />
    )
  }
}

export class TableExportUncapped extends Component<IExportComponentPropsUncapped>{
    render() {
      return (
        <ExportCsvOrPdfUncapped colWidth={this.props.colWidth} type={this.props.type} data={this.props.data} fileName={this.props.fileName} inProgress={this.props.inProgress} inProgressSetter={this.props.inProgressSetter}  />
      )
    }
  }
  
  

const getFormatedDate = () => {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = '' + d.getFullYear(),
    hour = '' + d.getHours(),
    min = '' + d.getMinutes(),
    secs = '' + d.getSeconds();


  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  if (hour.length < 2)
    hour = '0' + hour;
  if (min.length < 2)
    min = '0' + min;
  if (secs.length < 2)
    secs = '0' + secs;
  return [year, month, day, hour, min, secs].join('_');
}

Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    paddingTop: 50,
    paddingLeft: "0%",
    paddingRight: "0%",
    paddingBottom: 50,
    flexDirection: 'column',
  },
  table: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    fontSize: 7,
    color: "#000",

  },
  containerHeader: {
    flexDirection: 'row',
    backgroundColor: "#334A5E",
    fontWeight: "bold",
    color: "#fff",
    flexGrow: 1,
  },
  containerOdd: {
    flexDirection: 'row',
    backgroundColor: "#F5F5F5",
    fontStyle: 'bold',
    flexGrow: 1,
  },
  containerEven: {
    flexDirection: 'row',
    backgroundColor: "#fff",
    fontStyle: 'bold',
    flexGrow: 1,
  },

});

const getCellStyles = (width: Array<number>) => {
  const cellStyles: Array<ReactPDF.Styles> = [];
  for(let i =0; i<width.length; i++){
    cellStyles.push(StyleSheet.create({

      tableCell: {
        width: `${width[i]}%`,
        padding: "5px 2px",
      }
    }));
  }
  return cellStyles;
}

const getTableRows = (data: Array<Array<string>>, start: number, end: number) : Array<Array<string>> => {
  const tableRows: Array<Array<string>> = [[]];
  const maxRow = end>data.length ? data.length-1 : end;
  for(let i = start; i<=maxRow; i++){
    tableRows.push(data[i])
  }
  return tableRows;
}

const maxRowsPerDocument = 250;

function PdfDocument(props: IExportComponentProps){
  const tableData: Array<Array<string>> = props.data;
  let pdfColWidth: Array<number> = props.colWidth ? props.colWidth : [];

  let header: Array<string> = [];
  if( tableData && tableData.length > 0 ){
    header = tableData[0];
  }
  let startRow = props.pageNumber ? props.pageNumber < 2 ? 1 : (( props.pageNumber - 1) * maxRowsPerDocument ) + 1 : 1;
  let maxRow = props.pageNumber ? props.pageNumber * maxRowsPerDocument : maxRowsPerDocument;

  let tableRows: Array<Array<string>> = [[]];

  if( startRow <= tableData.length ){
    tableRows = getTableRows(tableData, startRow, maxRow);
  }

  const cellStyles = getCellStyles(pdfColWidth);
  console.log(cellStyles);
  console.log(tableRows);
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.table}>
          { header && header.length > 0 ?
            <View style={ styles.containerHeader }>
              {header.map((colValue, colIndex)=>{
                const cellStyle = cellStyles[colIndex];
                return (
                  <Text style={cellStyle.tableCell}>{colValue}</Text>
                )
              })}
            </View> : ""
          }
          { tableRows && tableRows.length > 0 &&
            tableRows.map((rowValue, rowIndex)=>{
              return (<View style={ rowIndex%2 === 0 ? styles.containerEven : styles.containerOdd }>
                {rowValue.map((colValue, colIndex)=>{
                  const cellStyle = cellStyles[colIndex];
                  return (
                    <Text style={cellStyle.tableCell}>{colValue}</Text>
                  )
                })}
              </View>)
            })
          }
        </View>
      </Page>
    </Document>
  );
}
