import * as React from "react";
import { compose } from "redux";
import {connect as formikConnect, Field, FormikProps} from "formik";
import {connect as reduxConnect} from "react-redux";
import {injectIntl, WrappedComponentProps, MessageDescriptor} from "react-intl";
import Slider from 'react-input-slider';
import styled from "styled-components";
import {CheckboxWrap, SettingDiv, SettingHeader, TitleWrap} from "./styled";
import InformationIcon from "../../../Elements/Icon/Icons/InformationIcon";
import {Checkbox} from "../../../Elements/CheckboxFormik";
import {convertToBaseCheckboxValue, parseCheckboxValue} from "./utils";
import {SettingEnum, SettingPrefixEnum} from "./settingEnum";
import {PolicyNameEnum} from "../../PolicyNameEnum";
import {SettingToPolicyMap} from "./tabDefinition";
import {AllPermissions, PolicyContext} from "../../Policy/Provider";
import {confirmModal} from "../../Settings/General/Tabs/deleteData";
import Dropdown from "@Elements/Dropdown/Dropdown";
import { mapOptions } from "@Elements/Form/utils";
import Tooltip from "@Elements/Tooltip";
import { AppState } from "@Safemate/Store/types";

interface Setting{
  [index: string]: string;
}

export interface ComponentProps extends WrappedComponentProps{
  settings: Setting;
  customerId: number;
  formik: FormikProps<Setting>;
  field: string;
  isChanged: boolean;
  disabled: boolean;
  fieldType?: string;
  width?: string;
  data?: Array<Option>;
  units?: any;
  unitsMargin?: string;
  modal?: boolean;
  modalActive?: boolean;
  modalHeader?: MessageDescriptor;
  modalText?: MessageDescriptor;
  modalOkText?: MessageDescriptor;
  modalCancelText?: MessageDescriptor;
}

export interface Option{
  text: string;
  value: any;
}

export interface DataOptions{
  options: Array<any>;
  nullValue?: MessageDescriptor;
  offset?: number;
  translate?: boolean;
  skipMap?: boolean;
  mapRoundDown?: boolean;
}

export interface ComponentOptions{
  Component: React.FunctionComponent<ComponentProps>;
  props: any;
}

type Wrap = (comp: React.FunctionComponent<ComponentProps>) => React.FunctionComponent<ComponentProps>;

interface FieldWrapperParams{
  componentOptions: ComponentOptions;
  prefix: SettingPrefixEnum;
  field: SettingEnum;
  policyPrefix?: SettingPrefixEnum;
  title?: string;
  tooltip?: string;
  postfix?: MessageDescriptor;
  dataOptions?: DataOptions;
  disabledField?: string;
  disabledFieldValue?: string;
  hideField?: string;
  hideFieldValue?: string;
}

const FieldWrapper = ({componentOptions: {Component, props: compProps}, prefix, field, policyPrefix, title, postfix, tooltip, dataOptions, disabledField, disabledFieldValue, hideField, hideFieldValue}: FieldWrapperParams): React.FunctionComponent<ComponentProps> => {

  const mapStateToProps = ({defaultSettings: {settings, customer: { selectedCustomer: { customerId }}}}: AppState) => ({
    settings: prefix === SettingPrefixEnum.DEVICE_SETTING ? settings.deviceSettings : settings.otherSettings,
    customerId
  });

  const wrap: Wrap = compose(reduxConnect(mapStateToProps), formikConnect, injectIntl);
  return wrap((props: ComponentProps) => {

    const {settings, intl: {formatMessage}, formik: {values, touched}} = props;
    const isChanged = !!settings[field] || ( disabledField && disabledFieldValue && settings[disabledField] === disabledFieldValue ) || !!(values[field] !== settings[`${field}WithFallback`] && touched[field]);

    if(dataOptions && dataOptions.translate){
      dataOptions.options = dataOptions.options.map(option => {
        return {value: option.value, text: formatMessage({id: option.text, defaultMessage: option.text})}
      });
    }
    
    const permissions: Map<PolicyNameEnum, AllPermissions> = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
    const policy: PolicyNameEnum = SettingToPolicyMap.get(policyPrefix ? policyPrefix : prefix) as PolicyNameEnum;
    const currentPermission = permissions.get(policy).customerPermissions[props.customerId];

    let disabled = (!currentPermission.edit && !currentPermission.all ) && currentPermission.read || ( disabledField && disabledFieldValue && settings[disabledField] === disabledFieldValue );
    let hide = (field.includes("msgI9n") && values[SettingEnum.INTEGRATION] === "false" ) || (hideField && hideFieldValue && values[hideField] === hideFieldValue) ;

    return (
      <SettingDiv isChanged={isChanged} hide={hide} key={field}>
        {title &&
          <SettingHeader>
            <TitleWrap right>
              {formatMessage({id: title!, defaultMessage: title!})}
            </TitleWrap>
            {tooltip &&
              <Tooltip
                popoverId={`${title}-popover`}
                labelId={tooltip}
                defaultMessage={tooltip}
              >
                  <InformationIcon size="medium" />
              </Tooltip>
            }
          </SettingHeader>}
        <Component
          field={field}
          isChanged={isChanged}
          disabled={disabled}
          data={
            dataOptions &&
              (dataOptions.skipMap
                ? dataOptions.options
                : mapOptions(dataOptions.options, formatMessage, dataOptions.nullValue && formatMessage(dataOptions.nullValue!), dataOptions.offset, false, dataOptions.mapRoundDown)
              )
          }
          {...props}
          {...compProps}
        /> {postfix && formatMessage(postfix)}
      </SettingDiv>
    )
  });
}

interface CheckboxProps extends ComponentProps{
  label?: MessageDescriptor;
  checkboxString?: boolean;
  tooltip?: string;
  customTooltip?: any;
}

export const GenericCheckbox = ({tooltip, customTooltip, checkboxString, label, modal, modalActive, modalHeader, modalCancelText, modalOkText, modalText , intl: {formatMessage}, disabled, isChanged, field, formik: {values, setFieldTouched, setFieldValue}, ...rest}: CheckboxProps) => {
  const name = field;;
  const { Comp, func } = confirmModal();
  const value = parseCheckboxValue(checkboxString!, values[field]);
  let changeValue: boolean = false;

  return(
    <CheckboxWrap
      isChanged={isChanged}
      isSet={value}
    >
      {
        // @ts-ignore
        <Checkbox
          disabled={disabled}
          value={value}
          name={name}
          id={name}
          label={label && formatMessage(label)}
          onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
            changeValue = event.currentTarget.checked;
            if(modal){
              if( modalActive && changeValue ){
                func(true);
              } else if( !modalActive && !changeValue ){
                func(true);
              } else {
                setFieldTouched(name)
                setFieldValue(name, convertToBaseCheckboxValue(checkboxString!, changeValue));
              }
            } else {
              setFieldTouched(name)
              setFieldValue(name, convertToBaseCheckboxValue(checkboxString!, changeValue));
            }
          }}
          outerDiv={
            {
              display: "flex",
              alignItems: "center",
              marginRight: "5px"
            }
          }
          {...rest}
        />
      }
      {
        modal &&
        <Comp
          title={modalHeader && formatMessage(modalHeader)}
          body={
            <p>
              <span>{modalText && formatMessage(modalText)}</span>
            </p>
          }
          confirmText={modalOkText && formatMessage(modalOkText)}
          cancelText={modalCancelText && formatMessage(modalCancelText)}
          confirmFunc={() => {
            setFieldTouched(name)
            setFieldValue(name, convertToBaseCheckboxValue(checkboxString!, changeValue));
            func(false);
          }}
        />
      }
      {customTooltip && React.createElement(customTooltip)}
      {tooltip &&
        <Tooltip
          popoverId={`${name}-popover`}
          labelId={tooltip}
          defaultMessage={tooltip}
        >
            <InformationIcon pointer size="medium" />
        </Tooltip>}
    </CheckboxWrap>
  )
}

interface Styles{
  [index: string]: string;
}

export const GenericDropdown = (props: ComponentProps) => {


  const { intl: {formatMessage}, disabled, data, field, isChanged, formik: {values, setFieldValue, setFieldTouched}, units, unitsMargin } = props;
  const fieldname: string = field;

  let value: number | string  = parseInt(values[field])

  if(isNaN(value)){
    value = values[field]
  }

  let selected = data && data.find((item: Option) => item.value === value);

  const unitsMessage = (units) ? formatMessage(units) : undefined ;

  const styles: Styles = {
    marginRight: "5px",
    display: "inline-flex"
  };

  if(!isChanged){
    styles["border"] = "1px solid green";
  }

  return(
    <div>
      {disabled
        ? (selected ? selected.text : values[field])
        : <Dropdown
            key={fieldname}
            title={formatMessage({id: field, defaultMessage: field})}
            options={(data as Option[])}
            initial={value}
            onChange={(object: Option | Option[]) => {
              const value = (object as Option).value;
              setFieldTouched(fieldname)
              setFieldValue(fieldname, value)
            }}
            units={unitsMessage}
            wrapperStyle={styles}
            size={{width: "250px"}}
          />}
    </div>
  )
}

const SliderInput = styled.input`
  width: 120px;
  height: 40px;
  font-size: 16px;
  background: none;
  border: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.colors.borderRadius};
  color: ${props => props.theme.colors.textPrimary};
  outline: none;
  padding: 0 15px;
  transition: 0.3s;
`;

const InputWrapper = styled.div`
  width: 100px;
  margin-bottom: 5px;
`;


export const GenericSlider = (props: ComponentProps) => {

  const { intl: {formatMessage}, disabled, data, field, isChanged, formik: {values, setFieldValue, setFieldTouched} } = props;
  const fieldname: string = field;

  let value: number = parseInt(values[field])

  
  const min = data![0].value;
  const max = data![1].value;
  
  if(isNaN(value)){
    value = 0
  }

  const setValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(fieldname, event.currentTarget.value);
  }

  return(
    <React.Fragment>
        <InputWrapper>
          <SliderInput value={value} onChange={setValue} type="number" min={min} max={max} disabled={disabled}/>
        </InputWrapper>
      { !disabled && <Slider
        axis="x"
        xmin={min}
        xmax={max}
        xstep={1}
        x={value}
        onChange={({x}) => setFieldValue(fieldname, x)}
        styles={{disabled}}
      /> }
      </React.Fragment>
  )
}

export const GenericTextField = (props: ComponentProps) => {
  const { field, disabled, width, fieldType } = props;
  const name = field;

  return(
    <Field
      style={{width: width ? width : "200px"}}
      disabled={disabled}
      key={name}
      name={name}
      type={fieldType || "text"}
    />
  )
}

export default FieldWrapper;
