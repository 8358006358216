import {useIntl} from "react-intl";
import {default as EditIcon} from "../../../../Elements/Icon/Icons/Edit";
import {LabelEnum} from "../../LabelEnum";
import * as React from "react";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {RouteEnum} from "../../RouteEnum";
import { useHistory } from "react-router";

interface EditProps{
  user: UserWithCustomer;
}

const Edit = ({user}: EditProps) => {

  const { formatMessage } = useIntl();
  const history = useHistory();

  return(
  <EditIcon
    tabIndex="0"
    title={formatMessage({id: LabelEnum.EDIT, defaultMessage: "edit"})}
    size="medium"
    onClick={(event: any) => {
      event.stopPropagation();
      history.push(`${RouteEnum.EDIT}/${user.user.userId}`);
    }}
    onKeyDown={(event: any) => {
      if (event.key === 'Enter') {
        history.push(`${RouteEnum.EDIT}/${user.user.userId}`);
      }
    }}
    inline
  />)
}

export default Edit;
