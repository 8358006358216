import {call, put, select, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { feedbackFailure, feedbackSuccess } from '../../../Feedback/reducer';
import { Position, Device as TransactionDevice } from '../types';
import { IDevice } from '@Safemate/Model/Device';
import { selectFilterSelector, selectLastDeviceModifiedTime } from '../selectors';
import { AlarmFilterEnum } from '@Safemate/DeviceList/AlarmFilterEnum';
import { deviceFiltering } from '@Safemate/map/utils';



function* getDevice(action: AppAction<number>){
  try {
    const device: TransactionDevice = yield call(Api.getDevice, action.payload);
    yield put(Routines.getDevice.success(device));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getDevice.failure());
  }
}

function* getAllDevices(action: AppAction<null>){
  try{
    const retrieveFrom: number = yield select(selectLastDeviceModifiedTime);
    const alarmFilters: AlarmFilterEnum[] = yield select(selectFilterSelector);
    const devices: IDevice[] = yield call(Api.getAllDevices, retrieveFrom);

    const filteredDevices: IDevice[] = devices.filter(device => deviceFiltering(device, alarmFilters));

    const mappedPositions: Position[] = filteredDevices.map(device => {
      return {
        accuracy: 100,
        alarmWarning: device.alarmWarning,
        battery: device.battery,
        batteryState: device.batteryState,
        batteryWarning: device.batteryWarning,
        deviceName: device.deviceName,
        fallbackAvailable: false,
        geofenceWarning: device.geofenceWarning,
        gps: false,
        gsm: false,
        hdop: 0,
        inactiveWarning: device.inactiveWarning,
        manDownWarning: device.manDownWarning,
        messageType: null,
        messageTypeDesc: "",
        metyId: 0,
        offlineWarning: device.offlineWarning,
        posLati: device.posLati,
        posLong: device.posLong,
        posTime: device.posTime,
        posType: device.posType,
        posTypeDesc: "",
        recTs: 0,
        sat: 0,
        selfCheckWarning: device.selfCheckWarning,
        siteId: device.siteId,
        temp: 0,
        tranId: 0,
        deviId: device.deviId,
        drawMarker: true
      }
    })
    yield put(Routines.getTransactionsForAll({
      transactionCount: 0
    }));
    yield put(Routines.getAllDevices.success({devices, filteredDevices, mappedPositions}));
  }
  catch(e){
    console.log(e);
    yield feedbackFailure(e);
    yield put(Routines.getAllDevices.failure(e));
  }
}

function* getCurrentPosition(action: AppAction<number>){
  try{
    yield call(Api.getCurrentPosition, action.payload);
    yield put(Routines.getCurrentPosition.success());
    yield feedbackSuccess("takitPositionQuerySent");
  }
  catch(e){
    yield put(Routines.getCurrentPosition.failure());
    yield feedbackFailure(e);
  }
}

function* deviceSaga(){
    yield takeLatest(Routines.getDevice.TRIGGER, getDevice);
    yield takeLatest(Routines.getAllDevices.TRIGGER, getAllDevices);
    yield takeLatest(Routines.getCurrentPosition.TRIGGER, getCurrentPosition);
}

export default deviceSaga;
