import React from "react"
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import DeviceEventSetup from "@Safemate/EventSetupNew/Model/DeviceEventSetup"
import { RootState } from "@Safemate/EventSetupNew/Store/types"
import { Capabilities } from "@Safemate/Settings/Store/types"
import { getEvents } from "./eventTypes"
import { EventAlarm, EventText, EventType, RotatedFontAwesomeIcon, TableEventWrapper } from "../WrapperStyles"
import { useIntl } from "react-intl"
import { connect } from "react-redux"
import { ModalEnum, useDefault } from "../utils"
import withFormWrapper from "@Elements/Form/formWrapper"
import { EventSetupForm } from "../types"
import confirmModal from "@Safemate/Modals/confirmModal"
import { setDeviceEventSetupModal } from "@Safemate/EventSetupNew/Store/routines"
import { Col, Grid } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { EventWrapperRow } from "../GridStyles";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import { AppState } from "@Safemate/Store/types";
import { Device } from "@Safemate/Settings/types";

interface EventSelectorProps{
  selectedEventSetup: DeviceEventSetup;
  modalType: ModalEnum;
  modalEventSetupIndex: number;
  capability: Capabilities;
  device: Device;
  setDeviceEventSetupModal: Routine<ActionFunctionAny<Action<any>>>;
}

interface EventSelectorState{
  alarmWarnRecipient: boolean;
  batteryWarnRecipient: boolean;
  epilepsyAlarmRecipient: boolean;
  fireWarnRecipient: boolean;
  fridgeWarnRecipient: boolean;
  geofenceWarnRecipient: boolean;
  inBedWarnRecipient: boolean;
  manDownWarnRecipient: boolean;
  offlineWarnRecipient: boolean;
  outOfBedWarnRecipient: boolean;
  selfcheckWarnRecipient: boolean;
  outOfBatteryWarnRecipient: boolean;
  staticWarnRecipient: boolean;
  wandererAlarmRecipient: boolean;
  [index: string]: boolean;
}

const mapStateToEventSelectorProps = ({ eventSetup: {deviceEventSetup: { selectedEventSetup, modalType, modalEventSetupIndex }, device: { device }, capability }}: AppState) => {
  return{
    selectedEventSetup,
    modalType,
    modalEventSetupIndex,
    capability,
    device
  }
}

const mapDispatchToProps = {
  setDeviceEventSetupModal
}

export const EventSelector = connect(mapStateToEventSelectorProps, mapDispatchToProps)(
  withFormWrapper<EventSetupForm, EventSelectorProps>(({formik: { setFieldValue }, device, capability, selectedEventSetup, modalType, modalEventSetupIndex, setDeviceEventSetupModal}) => {

    const { Comp: Modal, func: displayModal } = confirmModal();
    const { formatMessage } = useIntl();

    const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

    const [ events, setEvents ] = React.useState<EventSelectorState>({
      alarmWarnRecipient: false,
      batteryWarnRecipient: false,
      epilepsyAlarmRecipient: false,
      fireWarnRecipient: false,
      fridgeWarnRecipient: false,
      geofenceWarnRecipient: false,
      inBedWarnRecipient: false,
      manDownWarnRecipient: false,
      offlineWarnRecipient: false,
      outOfBedWarnRecipient: false,
      selfcheckWarnRecipient: false,
      outOfBatteryWarnRecipient: false,
      staticWarnRecipient: false,
      wandererAlarmRecipient: false,
    })

    React.useEffect(() => {
      if(modalType === ModalEnum.EVENT_TYPES){
        displayModal(true);
        setEvents({
          alarmWarnRecipient: selectedEventSetup.alarmWarnRecipient,
          batteryWarnRecipient: selectedEventSetup.batteryWarnRecipient,
          epilepsyAlarmRecipient: selectedEventSetup.epilepsyAlarmRecipient,
          fireWarnRecipient: selectedEventSetup.fireWarnRecipient,
          fridgeWarnRecipient: selectedEventSetup.fridgeWarnRecipient,
          geofenceWarnRecipient: selectedEventSetup.geofenceWarnRecipient,
          inBedWarnRecipient: selectedEventSetup.inBedWarnRecipient,
          manDownWarnRecipient: selectedEventSetup.manDownWarnRecipient,
          offlineWarnRecipient: selectedEventSetup.offlineWarnRecipient,
          outOfBedWarnRecipient: selectedEventSetup.outOfBedWarnRecipient,
          selfcheckWarnRecipient: selectedEventSetup.selfcheckWarnRecipient,
          outOfBatteryWarnRecipient: selectedEventSetup.outOfBatteryWarnRecipient,
          staticWarnRecipient: selectedEventSetup.staticWarnRecipient,
          wandererAlarmRecipient: selectedEventSetup.wandererAlarmRecipient,
        })
      }
      else{
        displayModal(false);
      }
    }, [modalType, selectedEventSetup])

    const updateEvent = (type: string) => {
      if(permissions.get(PolicyNameEnum.GSM_RECIPIENTS).generalPermissions.all){
        const newState = {...events};
        newState[type] = !events[type];
        setEvents(newState);
      }
    }

    const confirm = () => {
      setFieldValue(`eventSetups.${modalEventSetupIndex}`, {
        ...selectedEventSetup,
        alarmWarnRecipient: events.alarmWarnRecipient,
        batteryWarnRecipient: events.batteryWarnRecipient,
        epilepsyAlarmRecipient: events.epilepsyAlarmRecipient,
        fireWarnRecipient: events.fireWarnRecipient,
        fridgeWarnRecipient: events.fridgeWarnRecipient,
        geofenceWarnRecipient: events.geofenceWarnRecipient,
        inBedWarnRecipient: events.inBedWarnRecipient,
        manDownWarnRecipient: events.manDownWarnRecipient,
        offlineWarnRecipient: events.offlineWarnRecipient,
        outOfBedWarnRecipient: events.outOfBedWarnRecipient,
        selfcheckWarnRecipient: events.selfcheckWarnRecipient,
        outOfBatteryWarnRecipient: events.outOfBatteryWarnRecipient,
        staticWarnRecipient: events.staticWarnRecipient,
        wandererAlarmRecipient: events.wandererAlarmRecipient
      });
      closeModal();
    }

    const closeModal = () => {
      setDeviceEventSetupModal({
        selectedEventSetup: null,
        modalType: ModalEnum.NONE,
        modalEventSetupIndex: 0
      })
    }

    const disable = !permissions.get(PolicyNameEnum.GSM_RECIPIENTS).generalPermissions.all || useDefault(device)

    return(
      <Modal
      buttonAlign="center"
      standardBtn
      disableClose
      cancelFunc={closeModal}
      confirmFunc={confirm}
      confirmText={formatMessage({id: "ok", defaultMessage: "Ok"})}
      title={formatMessage({id: "eventTypes", defaultMessage: "Event Types"})}
      body={
        <Grid fluid>
          {getEvents(capability).map((event) => {
            return(
              <Col
                xs={6} sm={4} md={3}
                style={{display: "flex", alignItems: "center", justifyContent: "center"}}
              >
                <EventWrapperRow id={event.type} selected={events[event.type]} write={!disable}
                  onClick={(e) => !disable && updateEvent(event.type)} onKeyDown={(e) => (e.key === "Enter" && !disable) && updateEvent(event.type)}
                >
                  <EventType selected={events[event.type]}>
                    {event.phone 
                      ? <RotatedFontAwesomeIcon icon={faPhone}/> 
                      : event.sms ? <FontAwesomeIcon icon={faEnvelope}/> : null}
                  </EventType>
                  <EventAlarm selected={events[event.type]}>
                      {event.iconMedium}
                  </EventAlarm>
                  <EventText selected={events[event.type]}>
                    {formatMessage({id: event.text.label, defaultMessage: event.text.fallback})}
                  </EventText>
                </EventWrapperRow>
              </Col>
            )
          })}
        </Grid>
      }
    />
    )
  })
)

interface EventsProps{
  eventSetup: DeviceEventSetup
  capability: Capabilities;
}

const mapStateToProps = ({ eventSetup: {capability}}: AppState) => {
  return {
    capability
  }
}

export const Events = connect(mapStateToProps)(({ eventSetup, capability }: EventsProps) => {

  const { formatMessage } = useIntl();

  const events = getEvents(capability).filter(event => eventSetup[event.type]);

  return(
    <div style={{fontSize: "12px"}}>
      {events.length > 0 
        ? events.map((event, index) => {
          return (
            <TableEventWrapper key={event.type} style={{marginRight: index >= events.length - 1 ? "0px" : "10px"}}>
              {event.iconSmall}
            </TableEventWrapper>
          )})
        : formatMessage({id: "noneSelected", defaultMessage: "Ingen valgt"}) 
      }
    </div>
  )
})