import { DeviceState } from "../types";
import {Reducer} from "redux";
import { getDevice, setAhp, updateName, updateOverride } from "../routines";

const deviceState: DeviceState = {
  device: {
    deviId: 0,
    dehaId: 0,
    detyId: 0,
    ahpId: 0,
    ownedByCustomer: 0,
    deviceSerialNumber: "",
    deviceName: "",
    deviceTypeName: "",
    deviceMac: "",
    deviceId: "",
    deviceImei: "",
    twin: false,
    twinDevice: undefined,
    twinLearn: "",
    twinChild: false,
    formattedPhoneNumE164: "",
    fiwaNum: 0,
    fiwaName: "",
    activatedDate: 0,
    lastMsgIn: 0,
    firmName: "",
    trainingApprovedBy: 0,
    trainingApprovedByName: "",
    neverUsed: false,
    failedSelfCheck: false,
    tracking: false,
    offline: false,
    staticTooLong: false,
    batteryLow: false,
    inAlarmMode: false,
    gsmStrength: 0,
    posLong: "",
    posLati: "",
    watchRemoved: false,
    watchRemovedEnabled: false,
    wifiSupport: false,
    delayDay: "",
    delayNight: "",
    delayMotionless: "",
    callAlert: "",
    geofenceWifiLocationAlert: false,
    policies: undefined,
    peaktech: false,
    eview: false,
    eview07B: false,
    takit: false,
    toplovo: false,
    climax: false,
    connectionCounter: 0,
    connectionResetDate: "",
    lastConnectionClick: "",
    connectedSince: "",
    connected: false,
    hasDeviceSensor: false,
    deviceStatus: "",
    sequentialCalling: false,
    forceCoverage: false,
    connectedToDefaultAhp: false,
    enableDefaultEventSetup: false,
    overrideDefaultEventSetup: false,
    allowedGsmRecipients: 0,
    positionDisabled: false,
    nokNationalId: false,
  },
  selectingDeviceLoad: false
};

const device: Reducer<DeviceState> = (state: DeviceState = deviceState, action) => {
  switch (action.type) {
    case getDevice.SUCCESS:
      return {
        ...state,
        device: action.payload
      }

    case updateName.SUCCESS:
      return {
        ...state,
        device: {
          ...state.device,
          deviceName: action.payload
        }
      }

    case updateOverride.SUCCESS:
      return {
        ...state,
        device: {
          ...state.device,
         overrideDefaultEventSetup: action.payload
        }
      }  

    case setAhp.SUCCESS:
      return {
        ...state,
        device: {
          ...state.device,
          ahpId: action.payload.ahpId
        }
      }

    default:
      return state;
  }
};

export default device;
