import * as React from "react";
import { Transaction, RootState, AlarmAnalysisState, NotificationEventsState } from "../Store/types";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import styled from "styled-components";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { alarmAnalysis, getNotificationEvents, getPosition } from "../Store/routines";
import { Timeline } from '../../Timeline';
import {getLocation, mapHeader, mapTransaction, setGpsQualityHdop, setGpsQualityNumSat} from "./utils";
import { AllPermissions, PolicyContext } from "../../Policy/Provider";
import { PolicyNameEnum } from "../../PolicyNameEnum";
import { HiddenTr } from "../../../Elements/SpacedList/layout";
import {Button} from "react-bootstrap";
import {rerender, Rerender} from "../Store/actions";
import {formatDate} from "@Safemate/Settings/Indoor/utils";
import { AppState } from "@Safemate/Store/types";
import { IDevice } from "@Safemate/Model/Device";
import { MapType } from "..";

export const Th = styled.th<{priority?: number}>`
  @media (max-width: 1200px) {
    ${props => props.priority && props.priority <= 1 && 'display: none;'};
  }
  @media (max-width: 992px) {
    ${props => props.priority && props.priority <= 2 && 'display: none;'};
  }
  @media (max-width: 768px) {
    ${props => props.priority && props.priority <= 3 && 'display: none;'};
  }
  border: 0;
  background: none;
  font-weight: 400;
  font-size: 13px;
  color: #5D6164;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 12px;
`;

export const Td = styled.td<{priority?: number}>`
  @media (max-width: 1200px) {
    ${props => props.priority && props.priority <= 1 && 'display: none;'};
  }
  @media (max-width: 992px) {
    ${props => props.priority && props.priority <= 2 && 'display: none;'};
  }
  @media (max-width: 768px) {
    ${props => props.priority && props.priority <= 3 && 'display: none;'};
  }
`;

const HiddenTd = styled.td`
  padding: 12px;
`;

export const Tr = styled.tr<{alarm?: boolean, new?: boolean, notifyEvent?: boolean}>`
  outline: ${props => props.theme.colors.border};
  td {
    transition: 0.3s;
    vertical-align: middle;
    padding: 15px;
    ${props => (props.alarm || props.notifyEvent) && "cursor: pointer;"}
    background: ${props => 
      props.alarm 
        ? props.theme.tableColors.alarm 
        : props.notifyEvent
          ? props.theme.tableColors.notifyEvent
          : props.new
            ? props.theme.tableColors.new 
            : props.theme.colors.backgroundPrimary};
  }
`;

export const FirstTd = styled<any>(Td)`
  border-left: ${props => props.theme.colors.border};
`;

export const LastTd = styled<any>(Td)`
  border-right: ${props => props.theme.colors.border};
`;

export const Table = styled.table`
  width: 100%;
`;

const StyledHiddenTr = styled<any>(HiddenTr)`
  border: ${props => props.theme.colors.border};
`;

interface LogTableProps{
  filteredTransactions: Transaction[];
  alarmAnalysis: AlarmAnalysisState;
  notificationEvents: NotificationEventsState;
  getAlarmAnalysis: Routine<ActionFunctionAny<Action<any>>>;
  getNotificationEvents: Routine<ActionFunctionAny<Action<any>>>;
  getPosition: Routine<ActionFunctionAny<Action<any>>>;
  dehaId: number;
  render: boolean;
  beta: boolean;
  isTrackerOnly: boolean;
  rerender: Rerender;
  mapType: MapType;
  devices: IDevice[];
  googleMaps?:any;
}

const mapStateToProps = ({ appData: {isTrackerOnly}, transactionLog: {initialization:  {user: {beta}}, transaction: { filteredTransactions, render, googleMaps }, alarmAnalysis, notificationEvents, device: { dehaId, devices }}}: AppState) => {
  return{
    filteredTransactions,
    alarmAnalysis,
    notificationEvents,
    dehaId,
    devices,
    render,
    beta,
    isTrackerOnly,
    googleMaps
  }
}

const mapDispatchToProps = {
  getAlarmAnalysis: alarmAnalysis,
  getNotificationEvents,
  getPosition,
  rerender
}

const LogTable = connect(mapStateToProps, mapDispatchToProps)(({ mapType, beta, isTrackerOnly, filteredTransactions, alarmAnalysis, getNotificationEvents, notificationEvents, getAlarmAnalysis, dehaId, devices, render, rerender, getPosition, googleMaps }: LogTableProps) => {
  
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
  const { formatMessage } = useIntl();

  const [ selectedRow, setSelectedRow ] = React.useState<number>();
  const List = React.useMemo(() => {
    let tx: JSX.Element[] = [];
    filteredTransactions.map(transaction => {
      const mappedTransaction = mapTransaction(transaction, formatMessage, dehaId, devices, mapType);
      tx.push(<Tr key={transaction.id} new={transaction.isNew} alarm={transaction.alarmType} notifyEvent={transaction.isNotifyEvent} onClick={() => {if(transaction.isNotifyEvent || transaction.alarmType) {loadDetails(transaction)}}}>
        {mappedTransaction.map(item => {
          if(item.display){
            return <Td priority={item.priority}>{
              item.value === "poorPosition" ?
                <Button onClick={()=>{showPoorPosition(transaction)}}>{formatMessage({id: `showPoorPosition`, defaultMessage: `Position available`})}</Button>
                : item.value
            }</Td>
          }
        }).filter(item => item)}
      </Tr>)
      if(selectedRow === transaction.id){
        tx = tx.concat((mappedTransaction.map((item, index) => {
          if(item.display && item.priority && item.value){
            return (<StyledHiddenTr priority={item.priority}>
                <HiddenTd colSpan={99}>{item.value}</HiddenTd>
            </StyledHiddenTr>)
          }
        })).filter(item => item) as JSX.Element[]);
      }
      if(notificationEvents[transaction.id] && notificationEvents[transaction.id].length > 0 && selectedRow === transaction.id){
        tx.push(
          <tr key={`notification-${transaction.id}`}>
            <td colSpan={10}>
              <table style={{width: "100%", marginBottom: "1em"}}>
                <thead>
                  <tr>
                    <Th>{formatMessage({id: "time", defaultMessage:"Tidspunkt"})}</Th>
                    <Th>{formatMessage({id: "events", defaultMessage:"Events"})}</Th>
                    <Th>{formatMessage({id: "receiver", defaultMessage:"Mottaker"})} / {formatMessage({id: "descriptionText", defaultMessage:"Beskrivelse"})}</Th>
                  </tr>
                </thead>
                <tbody>
                {notificationEvents[transaction.id].map(notificationEvent =>
                  <Tr key={notificationEvent.evtyId}>
                    <FirstTd>
                      <span xs={8}>{formatDate(notificationEvent.createdTime)}</span>
                     </FirstTd>
                    <Td>
                      <span>{formatMessage({id: `et.${notificationEvent.eventType.name}`, defaultMessage: notificationEvent.eventType.name})}</span>
                    </Td>
                    <LastTd>
                      <span>{notificationEvent.description}</span>
                    </LastTd>
                  </Tr>
                )}
                </tbody>
              </table>
            </td>
          </tr>
        )
      }

      if(alarmAnalysis[transaction.id] && selectedRow === transaction.id){
        tx.push(
          <tr>
            <td colSpan={99}>
              <Timeline 
                id={transaction.id}
                alarmAnalysis={alarmAnalysis}
                renderCallback={() => {}}
                canvasProperties={{
                  margin: 5,
                  notes: true,
                  textSize: 13
                }}
              />
            </td>
          </tr>
        )
      }
    })
    return tx;
  }, [filteredTransactions, alarmAnalysis, notificationEvents, selectedRow, render])

  const loadDetails = (transaction: Transaction) => {
    getNotificationEvents(transaction.id);
    if(transaction.alarmType)
    getAlarmAnalysis(transaction.id);
    setSelectedRow(selectedRow === transaction.id ? 0 : transaction.id);
  }

  const showPoorPosition = (transaction: Transaction) => {
    transaction.poorPositionDisplayed = true;
    setGpsQualityNumSat(transaction, true);
    setGpsQualityHdop(transaction, true);
    getLocation(transaction,render, googleMaps);
    getPosition({
      deviceId: transaction.deviId,
      tranId: transaction.id
    });
    rerender(render);
  }
  
  const header = mapHeader(formatMessage, dehaId, mapType)
  if(isTrackerOnly || !permissions.get(PolicyNameEnum.DEVICE_TRANSACTION_LOG).generalPermissions.all) return null;

  return(
    <Table>
      {List.length!=0 && <thead>
        <tr>
          {header.map(item => {
            if(item.display)
              return <Th priority={item.priority}>{item.value}</Th>
          }).filter(item => item)}
        </tr>
      </thead>}
      <tbody>
        {List}
      </tbody>
    </Table>
  )
})

const WrapDiv = styled.div<{color?: string}>`
  display: inline;

  svg {
    ${props => props.color && `fill: ${props.theme.deviceStatus[props.color]};`}
    width: 30px;
    height: 30px;
  }

  img {
    width: 30px;
    height: 30px;
  }
`

interface MunicialityImageProps{
  deviceId: number;
}

export const MunicialityImage = React.memo(({deviceId}: MunicialityImageProps) => {

  const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      event.currentTarget.style.display = 'none'
  }

  return(
    <WrapDiv>
      <img src={`/ajax/file/municipalityimage/fordevice/${deviceId}`} onError={handleError}/>
    </WrapDiv>
  )
});

export default LogTable;
