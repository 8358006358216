export const lightLevel = [
  {value: 0, text: "0"},
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
];

export const soundLevel = [
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"},
];

// Sliders
export const soundLevelTriggerFour = [
  {value: 0, text: "min"},
  {value: 100, text: "max"}
]

export const soundLevelRingToneTriggerFour = soundLevelTriggerFour;
export const voicePromptVolumeTriggerFour = soundLevelTriggerFour;

export const micTriggerFour = [
  {value: 0, text: "min"},
  {value: 15, text: "max"}
]


export const soundLevelGX8 = [
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"},
  {value: 7, text: "7"},
  {value: 8, text: "8"},
  {value: 9, text: "9"},
  {value: 10, text: "10"},
];

export const twoWayMic = soundLevelGX8;

export const voicePromptVolumeGX8 = [
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"},
  {value: 7, text: "7"},
  {value: 8, text: "8"},
  {value: 9, text: "9"},
  {value: 10, text: "10"},
]

export const soundLevelRingtone = [
  {value: 0, text: "0"},
  {value: 1, text: "1"},
  {value: 2, text: "2"},
];

export const soundLevelRingtoneTriggerTwo4G = [
  {value: 0, text: "0"},
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"},
  {value: 7, text: "7"},
  {value: 8, text: "8"},
];

export const soundLevelWatchOne = [
  {value: 0, text: "lowVol"},
  {value: 1, text: "mediumVol"},
  {value: 2, text: "highVol"},
];
export const soundLevelRingtoneWatchOne = soundLevelWatchOne;

export const dectSoundLevel = [
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
];

export const lightMode = [
  {value: 1, text: "testmode"},
  {value: 2, text: "normalmode"},
  {value: 3, text: "off"},
]


export const lightLevelClimax = [
  {value: 0, text: "0"},
  {value: 1, text: "1"},
  {value: 2, text: "2"},
  {value: 3, text: "3"},
  {value: 4, text: "4"},
  {value: 5, text: "5"},
  {value: 6, text: "6"},
  {value: 7, text: "7"},
  {value: 8, text: "8"},
  {value: 9, text: "9"},
];