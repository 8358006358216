import withFormWrapper from "@Elements/Form/formWrapper";
import { AllPermissions, PolicyContext } from "@Safemate/Policy/Provider";
import { PolicyNameEnum } from "@Safemate/PolicyNameEnum";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { newEventSetup } from "../Model/DeviceEventSetup";
import { EventSetupForm } from "./types";
import { uniqueGsmRecipients, useDefault } from "./utils";
import { AppState } from "@Safemate/Store/types";
import { Device } from "@Safemate/Settings/types";

export enum RecipientType{
  EMAIL,
  GSM
}

interface AddRecipientProps{
  type: RecipientType;
  device: Device;
}

const mapStateToProps = ({ eventSetup: {device: { device }}}: AppState) => {
  return {
    device
  }
}

const AddRecipient = connect(mapStateToProps)(
  withFormWrapper<EventSetupForm, AddRecipientProps>(({ device, formik: { setFieldValue, values: { eventSetups }}, type }) => {
  
  const { formatMessage } = useIntl();
  const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;

  if(useDefault(device)) return null;

  if(RecipientType.EMAIL === type && !permissions.get(PolicyNameEnum.EMAIL_RECIPIENTS).generalPermissions.all){
    return null;
  }
  else if(RecipientType.GSM === type && (!permissions.get(PolicyNameEnum.GSM_RECIPIENTS).generalPermissions.all || uniqueGsmRecipients(eventSetups) >= device.allowedGsmRecipients)){
    return null;
  }

  const addRecipient = () => {
    let sort = 0;
    eventSetups.forEach(setup => {
      sort = Math.max(sort, setup.sort + 1);
    })
    const newSetup = newEventSetup(type, device, sort);
    setFieldValue("eventSetups", [
      ...eventSetups,
      newSetup
    ]);
  }

  return(
    <Button
      id={`new-${type}-recipient`}
      onClick={addRecipient}
      style={{ marginLeft: 'auto' }}
    >
      {type === RecipientType.EMAIL ? formatMessage({id: "newEmail", defaultMessage: "NEW EMAIL RECIPIENT"}) : formatMessage({id: 'newGsm',defaultMessage: 'NEW GSM RECIPIENT'})}
    </Button>
  )
}))

export default AddRecipient;