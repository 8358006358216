import { FilterState } from './Store/types';
import { OrderFilterEnum } from './OrderFilterEnum';
import {Customer} from "./Models/Customer";



export const filterPartners = (filters: FilterState, partners: Customer[]) => {
  return partners.sort((a, b) => {
    const f = filterOrder(filters.orderFilter, a, b)
    return f;
  });
}

const filterOrder = (filter: OrderFilterEnum, partnerOne: Customer, partnerTwo: Customer): number => {
  switch (filter){
    case OrderFilterEnum.CUSTOMER_ID_ASC:
      return partnerOne.custId - partnerTwo.custId;
    case OrderFilterEnum.PRICE_GROUP_ASC:
      return partnerOne.prices.safematePrice - partnerTwo.prices.safematePrice;
    case OrderFilterEnum.NO_OF_DEVICES_DESC:
        return partnerTwo.numberOfDevices - partnerOne.numberOfDevices;
    default:
        return sortByString(partnerOne.customerName, partnerTwo.customerName);
  }
}

type SortByString = (a: string, b: string) => number;

const sortByString: SortByString = (a,b) => {
  const nameA = a.toUpperCase(); // ignore upper and lowercase
  const nameB = b.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}


