import styled, {ThemeProps, withTheme} from "styled-components";
import {ITheme} from "@Theme/types";
import {injectIntl, WrappedComponentProps} from "react-intl";
import * as React from "react";
import Modal from "react-modal";
import {Button} from "react-bootstrap";

const ModalTitle = styled.div`
  padding: 15px;
`;

const ModalBody = styled.div`
  padding: 0 30px;
`;

const StyledParagraph = styled.p`
  margin-top: 5px;
`;

const ModalFooter = styled.div`
  margin: 20px 15px;
  padding: 15px;
  text-align: 
  right;
`;


const DeleteBtn = styled(Button)`
  background: #d60403;
  color: white;
  width: 100%;
  margin: 15px auto 0px;
  display: block;
  max-width:460px;
  font-size: 14px;
  height: 40px;
`;

const ConfirmBtn = styled(Button)`
  color: white;
  width: 100%;
  margin: 15px auto 0px;
  display: block;
  max-width:460px;
  font-size: 14px;
  height: 40px;
`;


const CancelButton = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};  
  width: 100%;
  max-width: 460px;
  margin: 15px auto 0px;
  display: block;
  font-size: 18px!important;
  height: 40px;
`;

const SwapBtn = styled(Button)`
  background: #A9CF55;
  color: white;
`;
interface ConfirmModalProps extends ThemeProps<ITheme>, WrappedComponentProps{
  title?: string;
  body: string | JSX.Element;
  confirmText?: string;
  confirm?: boolean;
  confirmFunc?: Function;
  cancelFunc?: Function;
  cancelText?: string;
  cancelHide?: boolean;
  staticBackDrop?: boolean;
}

export const confirmModal = () => {

  const [ open, setOpen ] = React.useState(false);
  Modal.setAppElement("#react");

  return{Comp: injectIntl(withTheme(({theme, title, body, confirm, confirmText, confirmFunc, cancelText, cancelHide, cancelFunc, staticBackDrop, intl: {formatMessage}}: ConfirmModalProps) => (
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={
          {
            content: {
              width: "500px",
              height: "fit-content",
              position: "unset",
              background: theme.colors.backgroundPrimary,
              color: "",
            },
            overlay:{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }
          }
        }
        shouldCloseOnOverlayClick={staticBackDrop}
      >
        { title && <ModalTitle>
          <h2>{title}</h2>
        </ModalTitle> }
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          { !cancelHide && <CancelButton
            onClick={() => {
              setOpen(false);
              cancelFunc && cancelFunc();
            }}>
            {cancelText && cancelText}
            {!cancelText && formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
          </CancelButton>}
          {
            confirm && <ConfirmBtn onClick={() => confirmFunc()}>
              {confirmText}
            </ConfirmBtn>
          }

        </ModalFooter>
      </Modal>
    ))), func: setOpen}
}
