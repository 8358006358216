import { StatusState } from "../types";
import {Reducer} from "redux";
import {getCurrentPosition, initialize, setMapConsent} from "../routines";
import { MapActionTypes } from "../actionTypes";

const statusState: StatusState = {
  fetchingPosition: false,
  mapConsentModal: false,
  mapConsent: false

};

const status: Reducer<StatusState> = (state: StatusState = statusState, action) => {
  switch (action.type) {

    case initialize.SUCCESS:
      return {
        ...state,
        mapConsentModal: mapConsentModal( state, action.payload.mapConsentModal)
      }

    case setMapConsent.SUCCESS:
      return {
        ...state,
        mapConsent: true,
        mapConsentModal: false
      }

    case getCurrentPosition.TRIGGER:
      return {
        ...state,
        fetchingPosition: true
      };

    case getCurrentPosition.SUCCESS:
    case getCurrentPosition.FAILURE:
      return {
        ...state,
        fetchingPosition: false
      }

    case MapActionTypes.RESET_STATE:{
      return statusState
    }

    default:
      return state;
  }
};

const mapConsentModal = (state: StatusState, initialize: boolean) : boolean => {
  return state.mapConsent ? false : initialize;
}

export default status;
