import {Reducer} from "redux";
import {InitializationState} from "../types";
import * as Routines from "../routines";
import { SiteTypeEnum } from "../../../SiteTypeEnum";

const initializationState: InitializationState = {
  customersInitialized: false,
  userInitialized: false,
  deletedUsersInitialized: false,
  rolesInitialized: false,
  ldapInitialized: false,
  adminRoleInitialized: false,
  initializedAll: false,
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action: any) => {
  switch (action.type) {
    case Routines.initializeUsers.SUCCESS:
      return {
        ...state,
        userInitialized: true
      };
    case Routines.initializeDeletedUsers.SUCCESS:
      return{
        ...state,
        deletedUsersInitialized: true
      }
    case Routines.initializeRoles.SUCCESS:
      return {
        ...state,
        rolesInitialized: true
      };
    case Routines.initializeLdapUsers.SUCCESS:
      return {
        ...state,
        ldapInitialized: true
      };
    case Routines.initializeAdminRole.SUCCESS:
      return {
        ...state,
        adminRoleInitialized: true
      };

    case Routines.initialize.SUCCESS:
      return{
        ...state,
        initializedAll: true
      }

    default:
      return state;
  }
};

export default initialization;
