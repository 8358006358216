import {EventSetupSettings} from "@Safemate/DefaultSettings/Settings/types";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import * as React from "react";
import {Form, Formik} from "formik";
import {Frame} from "@Safemate/styles";
import {Row} from "react-bootstrap";
import {FooterComp, FooterContext} from "@Safemate/DefaultSettings/Settings/footer";
import UnsavedCheck from "@Safemate/SaveNeeded/unsavedModal";
import {AppState} from "@Safemate/Store/types";
import {saveEventSetupSettings} from "@Safemate/DefaultSettings/Store/routines";
import {connect} from "react-redux";
import { mapEventSetup } from "./utils";

interface EventSetupSettingsProps{
  tabs: JSX.Element[];
  eventSetupSettings: EventSetupSettings;
  saveEventSetupSettings: Routine<ActionFunctionAny<Action<any>>>;
}

const EventSetupSettingsComp = ({ tabs, eventSetupSettings, saveEventSetupSettings }: EventSetupSettingsProps) => {

  return(
    <Formik
      initialValues={mapEventSetup(eventSetupSettings)}
      enableReinitialize
      onSubmit={(values: EventSetupSettings) => {saveEventSetupSettings(values)}}
    >
      <Form>
        <Frame>
          <Row>
            <div style={{paddingBottom: "1em"}}>
              {tabs}
            </div>
            <FooterComp context={FooterContext.EVENT_SETUP}/>
          </Row>
        </Frame>
        <UnsavedCheck />
      </Form>
    </Formik>
  )
}

const mapStateToProps = ({ defaultSettings: {settings: { eventSetupSettings }}}: AppState) => {
  return{
    eventSetupSettings
  }
};

const mapDispatchToProps = {
  saveEventSetupSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(EventSetupSettingsComp);
