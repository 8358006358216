import {GlobalRoles} from "../GlobalRoles";

export type GetGlobalRoleName = (role: GlobalRoles) => string;

export enum GlobalRoleName{
  SUPERFIRM = "ROLE_superfirm",
  FIRM = "ROLE_firm",
  TRACKER = "ROLE_tracker",
  TECHNICIAN = "ROLE_technician",
  RESTRICTED_USER = "ROLE_user_restricted"
}

export const GlobalRoleNameMap = new Map<GlobalRoles, GlobalRoleName>([
  [GlobalRoles.SUPERFIRM, GlobalRoleName.SUPERFIRM],
  [GlobalRoles.FIRM, GlobalRoleName.FIRM],
  [GlobalRoles.TRACKER, GlobalRoleName.TRACKER],
  [GlobalRoles.TECHNICIAN, GlobalRoleName.TECHNICIAN],
  [GlobalRoles.RESTRICTED_USER, GlobalRoleName.RESTRICTED_USER],
]);

// Map.get can return undefined if no result, return role as default.
export const getGlobalRoleName: GetGlobalRoleName = (role) => {
  const entry = GlobalRoleNameMap.get(role);
  return entry || role;
}

export interface AhpRoles{
  AHP: boolean;
  SUPERAHP: boolean;
  TECHNICIAN: boolean;
}
