import {CountryState} from "../types";
import {Reducer} from "redux";
import {getCountries, getTextsMyPage } from "../routines";

const countryState: CountryState = {
  countries: [],
  texts: [],
};

const country: Reducer<CountryState> = (state: CountryState = countryState, action) => {
  switch (action.type) {
    case getCountries.SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };

    case getTextsMyPage.SUCCESS:
      return {
        ...state,
        texts: action.payload,
      };

    default:
      return state;
  }
};

export default country;
