import * as React from "react";
import { MessageDescriptor} from "react-intl";
import { Invoice, CustomerModel as Customer, InvoiceFile} from "../Store/types";
import { formatDateMonthYearOnly } from '../../utils';
import { Col, Row, Button } from "react-bootstrap";
import styled from "styled-components";
import ExportPDF from "../../../Elements/Icon/Icons/Safemate/outline/ExportPDF";


const ExportButton = styled(Button)`
  min-width: 90px;
  svg{
    fill: black!important;
  }
`

export type MapMunicipalities = (
  customer: Array<Invoice>,
) => Array<Array<RowContent>>;

export type Header = {
  text: string;
  priority?: number;
  align?: string;
  export?: boolean;
}

export type MapHeader = (formatMessage: (message: MessageDescriptor) => string, isAhp: boolean, isPrivate: boolean) => Header[];


export interface RowContent{
  text: string | React.ReactElement;
  priority?: number;
  className: string;
  skipTranslate: boolean;
  parent: number;

}

export const mapInvoices: MapMunicipalities = (
  invoices
) => {
  if(invoices != null){
    return invoices.map((item) => {
      let floatAmount = parseFloat(item.node.amount);
      
      const invoiceDateFormatted = formatDateMonthYearOnly(item.node.invoiceDate);
      const mappedUser = [
        {text: item.node.invoiceDate ? invoiceDateFormatted: "", skipTranslate: true},
        {text: item.node.invoiceNumber, skipTranslate: true},
        {text: item.node.subledger.description, skipTranslate: true},
        {text: item.node.amount && floatAmount.toFixed(2), skipTranslate: true},
        {text: item.node.invoiceFile ? <Col><ExportData invoiceNumber={item.node.invoiceNumber} invoiceFileData={item.node.invoiceFile} invoiceDate={invoiceDateFormatted} /></Col> : "", skipTranslate: true},
      ];
      if(item.node.invoiceFile){
        return mappedUser;
      }
    });
  }

};


interface ExportDataProps {
  invoiceFileData: InvoiceFile;
  invoiceDate: string;
  invoiceNumber: string;
}

const ExportData = (({invoiceFileData,invoiceDate}: ExportDataProps) => {
  const fileName = invoiceDate + " " + invoiceFileData;

  // const binaryString = window.atob(invoiceFileData.invoiceBytes);
  // const bytes = new Uint8Array(binaryString.length);
  // const mappedData = bytes.map((byte, i) => binaryString.charCodeAt(i));
  // const blob = new Blob([mappedData], { type: 'application/pdf' });
  const url = invoiceFileData.url;

  return(
    <React.Fragment>
      <a href={url} download={fileName} >
        <ExportButton>
        <ExportPDF size="medium" inline /> PDF
        </ExportButton>
      </a>
    </React.Fragment>
  )
})

export const addMonths = (date:Date, months:number) => {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}





export const makeShortSubOrgStr = (orgList: Array<Customer>, formatMessage: (message: MessageDescriptor) => string) => {
  var str = formatMessage({id: 'multiple...', defaultMessage: "Flere..."});
  if(orgList.length == 1) {
    str = orgList[0].customerName;
  }
  return str;
};




export const mapHeader: MapHeader = (formatMessage) => {
 const header: Header[] = [
  { text: formatMessage({id: "datoUtsendt", defaultMessage: "Dato utsendt"})},
  { text: formatMessage({id: "utstedTil", defaultMessage: "Utsted Til"}) },
  { text: formatMessage({id: "belop", defaultMessage: "Beløp"})},
  { text: formatMessage({id: "export", defaultMessage: "Export"})},]
  return header;
}
