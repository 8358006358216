export const MessageRecipients = [
  {text: "SPIP", value: "INTELECOM"}, 
];

export enum OrderOfEscalationEnum{
  CALL_FIRST_THEN_INTEGRATE = "callFirstThenIntegrate",
  INTEGRATE_FIRST_THEN_CALL = "integrateFirstThenCall",
  INTEGRATE_AND_CALL_WITHOUT_DELAY = "integrateAndCallWithoutDelay"
}

export const orderOfEscalationOpts = [
  OrderOfEscalationEnum.CALL_FIRST_THEN_INTEGRATE,
  OrderOfEscalationEnum.INTEGRATE_FIRST_THEN_CALL,
  OrderOfEscalationEnum.INTEGRATE_AND_CALL_WITHOUT_DELAY
];

