import {call, put, race, take, takeLatest} from 'redux-saga/effects';
import * as Api from '../api';
import { AppAction } from '../../../Store/types';
import * as Routines from "../routines";
import { Customer} from "../types";
import {accessToCustomer} from "../../../Policy/rightsUtil";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {GlobalRoles} from "../../../GlobalRoles";
import { feedbackFailure } from '../../../Feedback/reducer';


export interface Initialize{
  deviceId: number;
  roles: string;
}

export interface IRace<T> {
  success: AppAction<T>;
  failure: any;
}

function* initialize(action: AppAction<Initialize>){
  try {
    yield getUser(action);
    yield getAllowDelete(action);
    yield getTfaRules(action);
    yield getPrivate(action);
    yield getBillingSettings(action);
    yield getAhpLifetimeNumber(action);
    yield getLifetimeCost(action);
    yield getCountries(action);
    yield getPaymentCurrency(action);
    yield getMarketProgId();

    const { roles } = action.payload;
    
    yield put(Routines.initializeSubOrgs(action.payload.roles));
    const {success: suborgSuccess, failure: subOrgFailure}: IRace<Array<Customer>> = yield race({
      success: take(Routines.initializeSubOrgs.SUCCESS),
      failure: take(Routines.initializeSubOrgs.FAILURE)
    });
    yield put(Routines.initializeSubUnits());
    const {success: subUnitsSuccess, failure: subUnitsFailure} = yield race({
      success: take(Routines.initializeSubUnits.SUCCESS),
      failure: take(Routines.initializeSubUnits.FAILURE)
    });
  }
  catch(e){
    yield put(Routines.initialize.failure());
  }
}

function* getMarketProgId(){
  try {
    const marketProgId = yield call(Api.getMarketProgId);
    yield put(Routines.getMarketProgId.success(marketProgId));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getMarketProgId.failure());
  }
}

function* getAllowDelete(action: AppAction<any>){
  try {
    const allowDelete: string = yield call(Api.getAllowDelete);
    yield put(Routines.getAllowDelete.success(allowDelete));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getAllowDelete.failure());
  }
}

function* getBillingSettings(action: AppAction<any>){
  try {
    const billingSettings = yield call(Api.getBillingSettings);
    yield put(Routines.getBillingSettings.success(billingSettings));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getAllowDelete.failure());
  }
}


function* getCountries (action: AppAction<any>){
  try {
    const countries = yield call(Api.getCountries);
    yield put(Routines.getCountries.success(countries));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getCountries.failure());
  }
}

function* getTfaRules (action: AppAction<any>){
  try {
    const tfaRules: string = yield call(Api.getTfaRules);
    yield put(Routines.getTfaRules.success(tfaRules));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getTfaRules.failure());
  }
}

function* getPrivate (action: AppAction<any>){
  try {
    const privateUser: string = yield call(Api.getPrivate);
    yield put(Routines.getPrivate.success(privateUser));
  }
  catch(e){
    yield feedbackFailure(e);
    yield put(Routines.getTfaRules.failure());
  }
}


function* getUser(action: AppAction<Initialize>){
  try {
    const user = yield call(Api.getUser);
    yield put(Routines.initializeUser.success(user));
  }
  catch(e){
    yield put(Routines.initializeUser.failure());
  }
}

function* getInvoices(action: AppAction<Initialize>){
  try {
    const invoices = yield call(Api.getInvoices);
    if(invoices.data == null){
      yield put(Routines.fetchInvoices.success(invoices.data));
    }else{
      const invoiceData = invoices.data.arTransactions.edges;
      yield put(Routines.fetchInvoices.success(invoiceData));
    }

  }
  catch(e){
    yield put(Routines.fetchInvoices.success(null));
  }
}


function* initializeSubOrgs(action: AppAction<string>){
  const roles: string = action.payload;

  try{
    const subOrgs = yield call(Api.getSubOrgs, roles);
    yield put(Routines.initializeSubOrgs.success(subOrgs));
  }
  catch(e){
    yield put(Routines.initializeSubOrgs.failure());
  }
}

function* initializeSubUnits(action: AppAction<any>){
  try{

    const subUnits = yield call(Api.getSubUnits);
    const billing = subUnits[0];

    let isBillAddrFilled:boolean;
    billing.firm.billingAddrName != null ? isBillAddrFilled = true : isBillAddrFilled = false;

    const paymentDetail = yield call(Api.getPaymentOptions, billing.custId, billing.parent);

    yield put(Routines.getPayment.success(paymentDetail));
    yield put(Routines.initializeSubUnits.success(billing));
    yield put(Routines.initializeBillingOptions.success({isBillAddrFilled, subUnits}));

  }
  catch(e){
    yield put(Routines.initializeSubUnits.failure());
  }
}

function* getAhpLifetimeNumber(action: AppAction<Initialize>){
  try {
    const lifetimeNumber = yield call(Api.getLifetimeNumber);
    yield put(Routines.getLifetimeMobile.success(lifetimeNumber));
  }
  catch(e){
    yield put(Routines.initializeUser.failure());
  }
}

function* getLifetimeCost(action: AppAction<Initialize>){
  try {
    const lifetimeNumber = yield call(Api.getLifetimeCost);
    yield put(Routines.getLifetimeCost.success(lifetimeNumber));
  }
  catch(e){
    yield put(Routines.getLifetimeCost.failure());
  }
}

function* getPaymentCurrency(action: AppAction<Initialize>){
  try {
    const paymentCurrency = yield call(Api.getPaymentCurrency);
    yield put(Routines.getPaymentCurrency.success(paymentCurrency));
  }
  catch(e){
    yield put(Routines.getPaymentCurrency.failure());
  }
}

function* initializationSaga(){
    yield takeLatest(Routines.initialize.TRIGGER, initialize);
    yield takeLatest(Routines.initializeUser.TRIGGER, getUser);
    yield takeLatest(Routines.initializeInvoices.TRIGGER, getInvoices);
    yield takeLatest(Routines.initializeSubOrgs.TRIGGER, initializeSubOrgs);
    yield takeLatest(Routines.initializeSubUnits.TRIGGER, initializeSubUnits);
}

export default initializationSaga;
