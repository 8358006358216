import { getMarket, getSso, getUser } from "@Safemate/Store/routines";
import { AppState } from "@Safemate/Store/types";
import { hashUrlParams } from "@Safemate/utils";
import * as React from "react";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { validate, prePopulateEmail, getTexts } from "./Store/routines";
import Login from "./Login";
import { BackgroundDiv, ContentDiv } from "./styles";
import Feedback from "@Safemate/Feedback";
import { Logoheader } from "@Safemate/Modals/Logo";
import { MarketEnum } from "@Safemate/MarketEnum";
import { LogoEnum } from "@Safemate/Modals/Logo/LogoEnum";
import { useIntl } from "react-intl";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ForgotPassword/resetPassword";
import { setLoginStep, SetLoginStep } from "./Store/actions";
import ResendVerification from "./Verification/resendVerification";
import Register, { RegisterStep } from "./Register";
import Gdpr from "./Gdpr";
import { MainSpinner } from "@Safemate/Spinner";

interface SessionProps{
  market: MarketEnum;
  ldapOnly: boolean;
  loggedIn: boolean;
  loadingUser: boolean;
  initialLoad: boolean;
  gdprPsAccepted: boolean;
  loginStep: LoginStep;
  registerStep: RegisterStep;
  setLoginStep: SetLoginStep;
  getUser: Routine<ActionFunctionAny<Action<any>>>;
  getTexts: Routine<ActionFunctionAny<Action<any>>>;
  getMarket: Routine<ActionFunctionAny<Action<any>>>;
  getSso: Routine<ActionFunctionAny<Action<any>>>;
  validate: Routine<ActionFunctionAny<Action<any>>>;
  prePopulateEmail: Routine<ActionFunctionAny<Action<any>>>;
}

const mapStateToProps = ({ session: { loggedIn, loadingUser, loginStep, registerStep, initialLoad }, appSettings: {market}, appData: {sso: { ldapOnly }, user: { gdprPsAccepted }} }: AppState) => {
  return {
    market,
    ldapOnly,
    loggedIn,
    loginStep,
    loadingUser,
    initialLoad,
    gdprPsAccepted,
    registerStep
  }
}

const mapDispatchToProps = {
  getUser,
  getMarket,
  getTexts,
  getSso,
  validate,
  prePopulateEmail,
  setLoginStep
}

export enum LoginStep{
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  RESEND_VERIFICATION,
  REGISTER
}

const Session = connect(mapStateToProps, mapDispatchToProps)(({ children, market, initialLoad, gdprPsAccepted, loginStep, registerStep, setLoginStep, ldapOnly, validate, prePopulateEmail, getTexts, getSso, getMarket, getUser, loggedIn, loadingUser }: React.PropsWithChildren<SessionProps>) => {

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    const urlParams = hashUrlParams(window.location.href);

    getMarket();
    getTexts();
    getSso();

    const tokenIdParam = urlParams['tokenId'];
    const passToken = getPassToken();
    
    if(tokenIdParam){
      validate(tokenIdParam);
      prePopulateEmail(tokenIdParam);
    }


    if(passToken){
      setLoginStep(LoginStep.RESET_PASSWORD);
    }

    if(!loggedIn){
      getUser();
    }
  }, [])

  const getPassToken = () => {
    const urlParams = hashUrlParams(window.location.href);
    const passToken = urlParams['passToken'];
    return passToken;
  }

  const logo = React.useMemo(() => {

    const isSafecall = market === MarketEnum.SAFECALL;

    switch(registerStep){
      case RegisterStep.PRIVATE:
      case RegisterStep.PRIVATE_CONFIRM:
        return isSafecall ? LogoEnum.SAFECALL_GO : LogoEnum.SAFEMATE_GO;
      default: return isSafecall ? LogoEnum.SAFECALL : LogoEnum.SAFEMATE
    }
  }, [registerStep]);

  if(!initialLoad){
    return <MainSpinner/>
  }

  if(!loggedIn || !gdprPsAccepted){

    let Comp = null;
    let footer: JSX.Element[] = [];

    const addForgotPassword = (list: JSX.Element[]) => {
      if(!ldapOnly){
        list.push(<a onClick={() => setLoginStep(LoginStep.FORGOT_PASSWORD)}>{formatMessage({id: "forgotPassword", defaultMessage: "Glemt passord"})}</a>);
      }
    }

    const addResendVerification = (list: JSX.Element[]) => {
      if(!ldapOnly){
        list.push(<a onClick={() => setLoginStep(LoginStep.RESEND_VERIFICATION)}>{formatMessage({id: "resendVerification", defaultMessage: "Resend Verification"})}</a>);
      }
    }

    const addRegister = (list: JSX.Element[]) => {
      if(!ldapOnly){
        list.push(<a onClick={() => setLoginStep(LoginStep.REGISTER)}>{formatMessage({id: "registerNyGo", defaultMessage: "Register"})}</a>);
      }
    }

    const addLogin = (list: JSX.Element[]) => {
      list.push(<a onClick={() => setLoginStep(LoginStep.LOGIN)}>{formatMessage({id: "toLogin", defaultMessage: "Til login"})}</a>);
    }

    if(loggedIn && !gdprPsAccepted){
      Comp = <Gdpr/>
    }

    else{

      switch(loginStep){
        case LoginStep.FORGOT_PASSWORD:{
          Comp = <ForgotPassword/>
          addLogin(footer);
          break;
        }
        case LoginStep.RESET_PASSWORD: {
          Comp = <ResetPassword/>
          addLogin(footer);
          break;
        }
        case LoginStep.RESEND_VERIFICATION: {
          Comp = <ResendVerification/>
          addLogin(footer);
          break;
        }
        case LoginStep.REGISTER: {
          Comp = <Register/>
          addLogin(footer);
          break;
        }

        default: {
          Comp = <Login/>
          addForgotPassword(footer);
          addResendVerification(footer);
          addRegister(footer);
          break;
        }
      }
    }

    return (
    <BackgroundDiv>
      <ContentDiv>
        <Logoheader logo={logo}/>
        <div style={{margin: 0}} className="login-box" id="login-box">
          <div className="content">
            <Feedback small={true}/>
            {Comp}
          </div>
          <div className="footer">
            {footer}
          </div>
      </div>
      </ContentDiv>
    </BackgroundDiv>
    )
  }
  
  return <React.Fragment>{children}</React.Fragment>;
  
})

export default Session;