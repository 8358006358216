import * as React from "react";
import { Col, Grid, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Routine } from "redux-saga-routines";
import styled from "styled-components";
import { Form, Formik } from "formik";
import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { fetchTracking, saveTracking, updateDevicePositioning, updateAcceptLBS } from "../Store/routines";
import {Action, ActionFunctionAny} from "redux-actions";
import TrackingOptions from "./trackingOpts";
import TrackingInfo from "./trackingInfo";
import { TrackingSettings } from "../types";
import Footer from "../Components/Footer";
import withFormWrapper from "@Elements/Form/formWrapper";
import { AppState } from "@Safemate/Store/types";


const BorderCol = styled(Col)`
  @media (max-width: 992px) {
    margin-bottom: 15px;
  }
`;

export const TrackingButton = styled(Button)`
  margin: 5px;
`;

export const Info = styled.p`
  font-size: 14px;
  font-weight: normal;
`;

export const LoaderWrap = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 40px;
    width: 40px;
  }
`;

interface TrackingProps{
  deviId: number;
  fetchTracking: Routine<ActionFunctionAny<Action<any>>>;
  saveTracking: Routine<ActionFunctionAny<Action<any>>>;
  updateDevicePositioning: Routine<ActionFunctionAny<Action<any>>>;
  updateAcceptLBS: Routine<ActionFunctionAny<Action<any>>>;
  settings: TrackingSettings;
  autoTracking: boolean;
  powerSaving: boolean;
}

const Tracking = withFormWrapper<TrackingSettings, TrackingProps>(({deviId, fetchTracking, saveTracking, settings, autoTracking, powerSaving}) => {

  React.useEffect(() => {
    fetchTracking(deviId);
  }, [deviId])
  
  return(
    <Formik
      onSubmit={(values) => {
        //updateDevicePositioning({deviceId: deviId, disablePositioning: values.disablePositioning});
        //updateAcceptLBS({deviceId: deviId, acceptLBS: values.acceptLBS});
        saveTracking({
          deviceId: deviId,
          settings: {
            ...values,
            autoTracking,
            powerSaving
          }
        })
      }}
      initialValues={{
        ...settings
      }}
      enableReinitialize
    >
      {({values, dirty}) => 
        <Form>
          <Grid fluid>    
            <BorderCol sm={12} md={3}>
              <TrackingInfo/>
            </BorderCol>
            <Col sm={12} md={9}>
              <TrackingOptions/>
            </Col>
          </Grid>   
          <UnsavedCheck/>
          <Footer
            settings={settings}
          />
        </Form> 
        }
    </Formik>
  )
})

const mapStateToProps = ({ settings: {tracking: {settings, autoTracking, powerSaving}, initialization: {deviId}}}: AppState) => {
  return {
    deviId,
    settings,
    autoTracking,
    powerSaving
  }
}

const mapDispatchToProps  = {
  fetchTracking,
  saveTracking,
  updateDevicePositioning,
  updateAcceptLBS
};


export default connect(mapStateToProps, mapDispatchToProps)(Tracking);