import styled from 'styled-components';

export const RadioWrapper = styled.div`
  float: left;
  padding-right: 25px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const RadioLabel = styled.label`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '.3' : '1')};
`;

export const StyledRadio = styled.input`
  display: none;
`;

export const StyledSpan = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: -3px 8px 0 0;
  vertical-align: middle;
  border-radius: 50%;
  background: ${props =>
    props.active
      ? props.theme.colors.accent
      : props.theme.colors.backgroundPrimary};
  border: ${props =>
    props.active
      ? `1px solid ${props.theme.colors.accent}`
      : props.theme.colors.border};
  box-shadow: ${props =>
    props.active
      ? `inset 0 0 0 3px ${props.theme.colors.backgroundPrimary}`
      : 'initial'};
  transition: 0.3s;
`;
