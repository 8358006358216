import { Checkmark } from "@Safemate/RoleManager/layout";
import * as React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import EventModal from "./EventModal";
import { TranslationTypeEnum } from "./Store/TranslationTypeEnum";
import { DatabaseCountry, RootState, TranslationEventBody } from "./Store/types";
import { Active, EmailContentTr, HeaderTr, Table, Td, Th } from "./styles";
import { AppState } from "@Safemate/Store/types";

interface EventProps{
  events: TranslationEventBody[];
  countries: DatabaseCountry[];
}

const mapStateToProps = ({ translationOverlay: {country: { countries }}}: AppState) => {
  return {
    countries
  }
} 

const Event = connect(mapStateToProps)(({ events, countries }: EventProps) => {

  const { formatMessage } = useIntl();

  const [ modal, setModalState ] = React.useState<boolean>(false);

  return(
    <Table>
      <thead>
        <HeaderTr>
          <Th>
            {formatMessage({id: "message", defaultMessage: "Melding"})}
          </Th>
          {countries.map(country => {
            return <Th>{formatMessage({id: country.languageCode, defaultMessage: country.languageName})}</Th>
          })}
        </HeaderTr>
      </thead>
      <tbody>
        {events.map(event => {
          return (
            <React.Fragment>
              <EmailContentTr onClick={() => setModalState(true)}>
                <Td>{event.type === TranslationTypeEnum.EMAIL && <div><span dangerouslySetInnerHTML={{__html: event.subject}}></span></div>}<div><span dangerouslySetInnerHTML={{__html: event.message}}></span></div></Td>
                {countries.map(country => {
                  const active = !!event.texts.find(t => t.languageCode === country.languageCode);
                  return <Td>
                    <Active active={active}>{active && <Checkmark />}</Active>
                  </Td>;
                })}
              </EmailContentTr>
              <EventModal open={modal} setModalState={setModalState} event={event} countries={countries}/>
            </React.Fragment>
          )
        })}
      </tbody>
    </Table>
  )
})

export default Event;