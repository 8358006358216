import * as React from "react";
import { useIntl } from "react-intl";
import { Routine } from "redux-saga-routines";
import { Action, ActionFunctionAny } from "redux-actions";
import { connect } from "react-redux";
import styled from "styled-components";

import { setLayout, SetLayout } from "./Store/actions";
import {initialize, initializeUser} from "./Store/routines";
import { RootState, Customer as CustomerType } from "./Store/types";
import Spinner from "../Spinner";
import { mapTabs } from "./tabs";
import { getLayout, Layout } from "./LayoutEnum";
import UserInformation from "./UserInformation";



import {Col, Row} from "react-bootstrap";
import { AppState } from "@Safemate/Store/types";
import { VerticalRouteTabs, HorizontalRouteTabs } from "@Elements/Tabs";


interface MyPageBillingProps{
  init: Routine<ActionFunctionAny<Action<any>>>;
  customer: CustomerType;
  initializeUser: Routine<ActionFunctionAny<Action<any>>>;
  setLayout: SetLayout;
  initialized: boolean;
  layout: Layout;
}

const Wrap = styled.div`
  position: relative;
`;

const StyledColNoLRPad = styled(Col)`
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 25px;
`


const MyPageBilling = ({ customer, initializeUser, initialized, init, layout, onChangeLanguage, setLayout }: MyPageBillingProps) => {

  const [ windowLayout, setWindowLayout ] = React.useState(getLayout(window.innerWidth));

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    initializeUser();
    init();
    window.addEventListener("resize", resizeEvent);
    return () => {
      window.removeEventListener("resize", resizeEvent);
    }

  }, [])

  React.useEffect(() => {
    if(windowLayout !== layout)
      setLayout(windowLayout);
  }, [windowLayout])

  const resizeEvent = (event: UIEvent) => {
    const win: Window = event.currentTarget as Window;
    const newLayout = getLayout(win.innerWidth);
    setWindowLayout(newLayout);
  }

  const settings = formatMessage({id: "settings", defaultMessage: "settings"});
  const billingInformation = formatMessage({id: "billingInformation", defaultMessage: "billingInformation"});
  const billingHistory = formatMessage({id: "billingHistory", defaultMessage: "billingHistory"});
  const tabs = React.useMemo(() => {
    return mapTabs(settings, billingInformation, billingHistory);
  }, [settings, billingInformation, billingHistory]);
  if (!initialized)
  return (
    <Wrap>
      <Spinner show message={formatMessage({id: "initData", defaultMessage: "Initialisering av data"})}/>
    </Wrap>
    )

  return (
    <React.Fragment>
        <UserInformation onChangeLanguage={onChangeLanguage} />
        {layout === Layout.VERTICAL
        ? <VerticalRouteTabs>
          {tabs}
        </VerticalRouteTabs>
        :           
        <StyledColNoLRPad md={12}>
          <Row>
          <HorizontalRouteTabs>
            {tabs}
          </HorizontalRouteTabs>    
          </Row>   
        </StyledColNoLRPad>
      }
      </React.Fragment>
  )
}

const mapStateToProps = ( { myPageBilling: {initialization: { initialized, layout,  }, customer: {selectedCustomer}}}: AppState) => {
  return{
    initialized,
    layout,
    customer: selectedCustomer,
  }
}

const mapDispatchToProps = {
  init: initialize,
  initializeUser: initializeUser,
  setLayout
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPageBilling);
