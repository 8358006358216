import {InitializationState} from "../types";
import {Reducer} from "redux";
import {initialize, twinSuffix} from "../Routines";

const initializationState: InitializationState = {
  initialized: false,
  twinSuffix: ""
};

const initialization: Reducer<InitializationState> = (state: InitializationState = initializationState, action) => {
  switch (action.type) {
    case initialize.TRIGGER:
      return{
        ...state,
        initialized: false
      }
    case initialize.FULFILL:
      return {
        ...state,
        initialized: true
      };

    case twinSuffix.SUCCESS:
      return {
        ...state,
        twinSuffix: action.payload
      }

    default:
      return state;
  }
};

export default initialization;
