export const titleTooltips = {
  title: 'roleTitleTooltip',
  titleContent: 'Navnet på rollen brukes når man oppretter nye brukere.'
};

export const accessTooltips = {
  access: 'accessTabTooltip',
  accessContent: 'accessTabTooltip',
  twoFactorAuth: 'twoFactorTooltip',
  twoFactorAuthContent:
    'Tofaktorautentisering gir sikrere pålogging fordi applikasjonsbrukeren blir tilsendt en kode i tillegg til å måtte oppgi passordet sitt.',
  formatHeaderTooltip: 'formatTooltip',
  formatHeaderTooltipContent:
    'Om man i rolledefinisjonen velger å la applikasjonsbrukeren velge format selv, så vil et eget valg dukke opp på min side for applikasjonsbrukere tilhørende denne rollen.',
  requireTFA: 'requireTfaTooltip',
  requireIp: 'requireIpToolTip',
  requireIpContent: 'requireIpToolTip',
  requireIpRange: 'requireIpRangeToolTip',
  requireIpFromContent: 'requireIpFromToolTip',
  requireIpTo: 'requireIpToToolTip',
  requireIpToContent: 'requireIpToToolTip',
};

export const userAdminTooltips = {
  userAdmin: 'userAdminTabTooltip',
  userAdminContent:
    'Brukeradministrasjon åpner for at rollen skal få tilgang til menyvalget "Brukere".',
  allowedTo: 'allowedToTooltip',
  allowedToContent:
    'Her angis hvilken adgang rollen har til å administrere andre applikasjonsbrukere.',
  auditLog: 'auditLogTooltip',
  auditLogContent:
    'Applikasjonsbrukerloggen viser handlinger applikasjonsbrukeren har foretatt som kan ha betydning for personvernet.',
  deletedUsers: 'deletedUsersTooltip',
  deletedUsersContent:
    'Angir om rollen skal ha adgang til slettede brukere eller ikke.',
  lastLogin: 'lastLoginTooltip',
  lastLoginContent:
    'Angir om rollen skal ha adgang til å se tidsstempelet for når den enkelte applikasjonsbruker sist var logget inn.',
  activate: 'deactivateUserTooltip',
  activateContent: 'Angir om rollen skal ha adgang til å deaktivere brukere.',
  promoteTemp: 'promoteTempTooltip',
  promoteTempContent:
    'Angir om rollen skal ha adgang til å gi andre applikasjonsbrukere midlertidige roller i tilleg til den primærrollen som vedkommende har.'
};

export const eventSetupTooltips = {
  eventSetup: 'eventSetupTooltip',
  eventSetupContent:
    'Her angis rollens adgang til å utføre endringer i varslingslisten.',
  gsmRecipients: 'gsmRecipientsTooltip',
  gsmRecipientsContent:
    'GSM-mottakere er mottakere som mottar hendelser på mobiltelefon, som oppringning og/eller SMS-melding',
  nextOfKin: 'nextOfKinTooltip',
  nextOfKinContent: 'Her gis adgang til å angi GSM-mottkere som pårørende.',
  emailRecipients: 'emailRecipientsTooltip',
  emailRecipientsContent: 'Rollens adgang til å angi e-postmottakere.',

  alarmCenterConnection: 'alarmCenterConnectionTooltip',
  controlCalling: 'controlCallingTooltip',
  deviceNameChange: 'deviceNameChangeTooltip'
};

export const deviceSettingsTooltips = {
  deviceSettings: 'deviceSettingsTooltip',
  deviceSettingsContent:
    'Rollens adgang til å gjøre endringer i enhetsinnstillinger for en gitt enhet.',
  tracking: 'deviceTrackingTooltip',
  trackingContent: 'Fane for lokalisering (Gjelder kun mobile-enheter).',
  indoor: "deviceIndoorTooltip",
  indoorContent: "Fane for innendørlokalisering (Gjelder kun mobile-enheter).",
  geofence: 'deviceGeofenceTooltip',
  geofenceContent: 'Fane for geofence (Gjelder kun mobile-enheter).',
  lightSound: 'deviceLightSoundTooltip',
  lightSoundContent: 'Fane for å angi lys- og lydinnstillinger.',
  organization: 'deviceOrganizationTooltip',
  organizationContent:
    'Fane for å kunne se/endre enhetens organisasjonstilhørighet.',
  info: 'deviceInfoTooltip',
  infoContent: 'Fane for å presentere informasjon om enheten.',
  deviceNote: 'deviceNoteToolTip',
  deviceNoteContent: 'Adgang til å legge til/endre/slette et notat på en enhet',
  accessMap: 'deviceMapTooltip',
  accessMapContent:
    'Adgang til å inspisere posisjonsplott (Gjelder kun mobile-enheter).',
  accessTransactionLog: 'deviceTransactionLogTooltip',
  accessTransactionLogContent:
    'Adgang to inspisere transaksjonsloggen for enheten.',
  logs: 'deviceLogTooltip',
  logsContent: 'Adgang til å eksportere transaksjonsloggen.'
};

export const generalSettingsTooltips = {
  generalSettings: 'generalSettingsTooltip',
  generalSettingsContent:
    'Innstillinger som underligger fanen "Generelt" til den gjeldende enheten.',
  Gx8: {
    header: 'gx8SettingsTooltip',
    lightSound: 'gx8LightSoundTooltip',
    battery: 'gx8BatteryTooltip',
    logsReport: 'gx8LogsReportTooltip',
    general: 'gx8GeneralTooltip',
    communication: 'gx8CommunicationTooltip',
    information: 'gx8InformationTooltip'
  },
  safemate: {
    header: 'safemateSettingsTooltip',
    general: 'safemateGeneralTooltip',
    deviceLogs: 'safemateDeviceLogsTooltip',
    deletedDeviceData: 'safemateDeletedDataTooltip',
    devicePositioning: 'safematePositioningTooltip',
    batteryThreshold: 'safemateBatteryTooltip',
    alarm: 'safemateAlarmTooltip',
    manDown: 'safemateManDownTooltip'
  }
};
