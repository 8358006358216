import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as Routines from '../routines';
import { feedbackFailure } from '../../../Feedback/reducer';
import { AppAction } from '../../../Store/types';
import { FilterState } from '../types';
import * as Utils from '../../utils';
import { PartnerDashboardActionTypes } from '../actionTypes';
import * as Api from '../api';
import { getFilters, getCustomers } from '../selectors';
import { Customer } from '@Safemate/PartnerDashboard/Models/Customer';

function* filterPartners(action: AppAction<null>){
  try {

    const filters: FilterState = yield select(getFilters);

    const customers: Customer[] = yield select(getCustomers);

    const filterPartners = Utils.filterPartners(filters, customers);
    yield put(Routines.filterPartners.success(filterPartners));
  }
  catch(e){
    console.log(e);
    yield feedbackFailure(e);
    yield put(Routines.filterPartners.failure());
  }
}

function* filterSaga(){
    yield takeLatest(Routines.initialize.SUCCESS, filterPartners);
    yield takeLatest(Routines.initializeCustomers.SUCCESS, filterPartners);
    yield takeLatest(Routines.setCacheValues.SUCCESS, filterPartners);
    yield takeLatest(PartnerDashboardActionTypes.FILTER_ORDER, filterPartners);
}

export default filterSaga;
