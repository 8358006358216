import * as React from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import { useIntl } from "react-intl";
import { Collapse } from "react-collapse";
import styled from "styled-components";

import init from "../Initialization";
import { RootState, User, DatabaseCountry } from "./Store/types";
import { initialize, saveUser, getTextsMyPage } from "./Store/routines";
import { Formik, Form, Field } from "formik";
import SafemateHeader from "../Header";
import {
  Email,
  Name,
  Password,
  Confirm,
  MobileAndLanguage,
  ApplyToAll,
  NewsLetter,
  Style,
  Gdpr,
  Tfa,
  TfaType,
  TfaEnabled,
  EmailAsUsername,
  RolesWithCustomerNames, CurrentPassword
} from "./fields";
import Footer from "./footer";
import UnsavedCheck from "../SaveNeeded/unsavedModal";
import Dashboard from "../../Elements/Icon/Icons/menu/Dashboard";
import {LoaderWrap} from "@Safemate/UserAdmin/Users/Edit/styles";
import  Loader  from "@Elements/Loader";
import { AppState } from "@Safemate/Store/types";



const StyledGrid = styled(Grid)`
  padding: 25px;
  border-radius: ${props => props.theme.colors.borderRadius};
  border: ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.backgroundPrimary};
`;


const CollapseWrapper = styled.div`
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  button {
    margin: 5px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

export enum TfaRule{
  USER = "User"
}

interface MyPageProps{
  user: User;
  allowDelete: string;
  tfaRules: string;
  privateUser: boolean;
  saveUser: Routine<ActionFunctionAny<Action<any>>>;
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  countries: DatabaseCountry[];
  texts: any[];
  isTracker: boolean;
  isSso: boolean;
  initialized: boolean;
}

const mapStateToProps = ({ appData: { isTrackerOnly, isSso, user }, myPage: {initialization: { initialized }, user: { allowDelete, tfaRules, privateUser }, country : {countries, texts}}}: AppState) => {
  return {
    initialized,
    user,
    allowDelete,
    tfaRules,
    privateUser,
    countries,
    texts,
    isTracker: isTrackerOnly,
    isSso
  } 
}

const mapDispatchToState = {
  initialize,
  saveUser
  
}

const MyPage = connect(mapStateToProps, mapDispatchToState)(
  ({isTracker, isSso, initialized, user, saveUser, allowDelete, tfaRules, privateUser, countries, initialize}: MyPageProps) => {

    React.useEffect(() => {
      initialize();
    }, []);

    const { formatMessage } = useIntl(); 


    if (user === undefined || !initialized){
      return (
        <LoaderWrap><Loader/></LoaderWrap>
      )
    } else {
      return(
        <Formik
          onSubmit={(value) => {
            saveUser({
              ...value,
              passwordClear: value.password,
              tfaEmail: value.tfaEmail === TfaType.EMAIL
            })
          }}
          enableReinitialize
          initialValues={{
            username: user.username,
            mailTo: user.mailTo,
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            proffwebDark: user.proffwebDark,
            tfaEmail: user.tfaEmail ? TfaType.EMAIL : TfaType.SMS,
            tfaEnabled: user.tfaEnabled,
            gdprPsAccepted: user.gdprPsAccepted,
            password: "",
            applyToAll: false,
            confirm: "",
            emailAsUsername: user.emailAsUsername,
            languageCode: user.languageCode
          }}
        >
          {({values: { mobile, emailAsUsername }, dirty}) =>
            <Form>
              <HeaderWrapper>
                <SafemateHeader
                  icon={null}
                  title={`${formatMessage({id: "min", defaultMessage: "MinTemp"})} ${formatMessage({id: "side", defaultMessage: "side"})}`}
                />
                {isTracker && <ButtonWrapper>
                  <button onClick={() => {location.hash = `safemates`}} className="btn btn-dark-with-line btn-small"><Dashboard inline size="medium"/></button>
                </ButtonWrapper>}
              </HeaderWrapper>
              <StyledGrid fluid>
                <Row>
                  <Col md={6}>
                    {!isSso && <EmailAsUsername />}
                    <Email disabled={user.isExternal || isSso} emailAsUsername={emailAsUsername} />
                    <Name disabled={user.isExternal || isSso}/>
                    {!user.isExternal && !isSso &&
                    <React.Fragment>
                      <CurrentPassword />
                      <Password/>
                      <Confirm/>
                    </React.Fragment>}
                  </Col>
                  <Col md={6}>
                    <MobileAndLanguage languageCode={user.languageCode} sso={isSso} countries={countries}/>
                    <CollapseWrapper>
                      <Collapse isOpened={user.mobile !== mobile}>
                        <ApplyToAll/>
                      </Collapse>
                    </CollapseWrapper>
                    <NewsLetter/>
                    <Style/>
                    {privateUser && <TfaEnabled/>}
                    <Gdpr/>
                    {tfaRules === TfaRule.USER &&
                    <Tfa/>}
                    {!privateUser && <RolesWithCustomerNames />}
                  </Col>
                </Row>
                <Footer
                  dirty={dirty}
                  email={user.mailTo}
                  allowDelete={allowDelete}
                  isPrivate={privateUser}
                />
              </StyledGrid>
              <UnsavedCheck/>
            </Form>
          }
        </Formik>
      )
    }
  }
)

export default MyPage;
