import * as React from "react";
import { Button } from 'react-bootstrap';
import { Routine } from 'redux-saga-routines';
import { ActionFunctionAny, Action } from "redux-actions";

import FeetWalk from "../../../../Elements/Icon/Icons/Old/FeetWalk";
import { StyledButton, ModalContent } from './index';
import { StyledModal, ButtonWrap } from '../TableElements';
import styled from 'styled-components';
import { CancelButton } from '../../../DefaultSettings/Settings/styled';
import { connect } from 'react-redux';
import { startTracking } from '../../Store/Routines';
import { isGx8 } from '../../../DeviceHardwareEnum';
import { LabelEnum } from '../../LabelEnum';
import { useIntl } from "react-intl";

interface TrackProps{
  deviceName: string;
  deviceSNNum: string;
  deviceId: number;
  deviceHardware: number;
  startTracking: Routine<ActionFunctionAny<Action<number>>>;
}

const HighlightSpan = styled.span`
  color: ${props => props.theme.colors.accent};
`;

const mapDispatchToProps = {
  startTracking
}

const Track = connect(null, mapDispatchToProps)(({deviceName, deviceSNNum, deviceId, deviceHardware, startTracking}: TrackProps) => {

  // GX8 (Safemate home) does not support tracking
  if(isGx8(deviceHardware)) return null;

  const { formatMessage } = useIntl();

  const [open, setOpen] = React.useState(false);

  return(
    <React.Fragment>
      <StyledButton
        title={formatMessage({id: LabelEnum.START_TRACKING, defaultMessage: "Start lokalisering"})}
        onClick={(event) => {
          setOpen(true);
        }}
        onKeyDown={(event) => {
          setOpen(true);
        }}
      >
        <FeetWalk
          size="medium"
          inline
        />
      </StyledButton>
      <StyledModal
        open={open}
        setOpen={setOpen}
      >
        <div>
          <div>
            <h2>{formatMessage({id: LabelEnum.TRACKING, defaultMessage: "Sporingsmodus"})}</h2>
          </div>
          <div>
            <ModalContent>
              <span>{formatMessage({id: LabelEnum.START_TRACKING_PART_ONE, defaultMessage: "Sette enhet"})}</span>
              <HighlightSpan>{` ${deviceName} (${deviceSNNum}) `}</HighlightSpan>
              <span>{formatMessage({id: LabelEnum.START_TRACKING_PART_TWO, defaultMessage: "i sporingsmodus nå?"})}</span>
            </ModalContent>
          </div>
          <ButtonWrap>
            <CancelButton onClick={() => setOpen(false)}>{formatMessage({id: LabelEnum.CANCEL, defaultMessage: "Avbryt"})}</CancelButton>
            <Button onClick={() => {
              startTracking(deviceId)
              setOpen(false)
            }}>
              {formatMessage({id: LabelEnum.OK, defaultMessage: "OK"})}
            </Button>
          </ButtonWrap>
        </div>
      </StyledModal>
    </React.Fragment>
  )
});

export default Track;
