import * as React from "react";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { Tr, Td, Active } from "./styles";
import { Checkmark } from "../../RoleManager/layout";
import { getTextForLabel } from "./Store/routines";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { Button } from "react-bootstrap";
import { setModalState, SetModalState } from "./Store/actions";

interface TextListItemProps {
    exist: boolean;
    value: string;
    label: string;
    setModalState: SetModalState;
    getTextForLabel: Routine<ActionFunctionAny<Action<any>>>;
}

const mapDispatchToProps = {
    getTextForLabel,
    setModalState
  }

export default connect(null, mapDispatchToProps)(({ getTextForLabel, setModalState, exist, value, label }: TextListItemProps) => {

    const { formatMessage } = useIntl(); 

    return(
        <Tr exist={exist}>
            <Td>{value}</Td>
            <Td>{label}</Td>
            <Td>
                <Button 
                    onClick={() => {
                      getTextForLabel(label);
                      setModalState(true);
                    }}
                >
                    {formatMessage({id: "edit", defaultMessage: "Endre"})}
                </Button>
            </Td>
            <Td>
                <Active
                active={exist}
                >
                {exist && <Checkmark />}
                </Active>
            </Td>
        </Tr>
    )
})
