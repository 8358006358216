import * as React from "react";
import Modal from "react-modal";
import styled, { withTheme, ThemeProps } from "styled-components";
import { Button } from "react-bootstrap";
import { ITheme } from "@Theme/types";
import { useIntl } from "react-intl";

export const modalStyles = {
  content: {
    width: "80%",
    maxWidth: "500px",
    height: "fit-content",
    position: "unset",
    background: "",
    color: ""
  },
  overlay:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  }
}

export const ModalTitle = styled.div`
  padding: 15px;
`;

export const H2 = styled.h2`
  text-transform: lowercase;
  &::first-letter{
    text-transform: uppercase;
  }
`;

export const ModalBody = styled.div`
  padding: 0 30px;
`;

export const ModalFooter = styled.div`
  margin: 20px 15px;
  padding: 15px;
  text-align: right;
  @media only screen and (min-width: 623px) {
    margin: 20px 15px;
  }
  @media only screen and (max-width: 622px) {
    margin: 20px 15px;
  }
`;

export const ConfirmBtn = styled(Button)`
  background: #F12638;
  color: white;
  margin-right: 10px;
  min-width: 200px;
  float: left;
`;

export const CancelButton = styled(Button)`
  color: white;
  background: ${props => props.theme.colors.borderColor};
  margin-right: 15px;
  float: left;
  @media only screen and (max-width: 557px) {
    min-width: 200px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
`;


export interface ConfirmModalProps{
  title: string | null;
  body: string | JSX.Element | null;
  afterFooter: string | JSX.Element | null;
  info?: boolean;
  confirmText?: string;
  confirmFunc?: Function;
  maxWidth?: string;
  disableClose?: boolean;
}

export interface ExtendedConfirmModalProps extends ConfirmModalProps, ThemeProps<ITheme>{
}

const confirmModal = () => {
  
  const [ open, setOpen ] = React.useState(false);
  Modal.setAppElement("#react");

  const { formatMessage } = useIntl();

  return{Comp: withTheme(({theme, maxWidth, title, body, afterFooter, info, confirmText, confirmFunc, disableClose}: ExtendedConfirmModalProps) => (
    <Modal
      isOpen={open}
      onRequestClose={() => {
        if(!disableClose)
          setOpen(false)
      }}
      style={
        {
          content: {
            maxWidth: "80%",
            width: "500px",
            height: "fit-content",
            position: "unset",
            background: theme.colors.backgroundPrimary,
            color: ""
          },
          overlay:{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }
        }
      }
    >
      {title &&
      <ModalTitle>
        <H2>{title}</H2>
      </ModalTitle>}
      {body &&
      <ModalBody>
        {body}
      </ModalBody>}
      <ModalFooter>
        {info 
          ? 
            <CancelButton onClick={() => setOpen(false)}>
              {formatMessage({id: "ok", defaultMessage: "OK"})}
            </CancelButton>
          :
            <React.Fragment>
              <CancelButton
                onClick={() => setOpen(false)}>
                {formatMessage({id: "cancel", defaultMessage: "Avbryt"})}
              </CancelButton>
              <ConfirmBtn onClick={() => confirmFunc && confirmFunc()}>
                {confirmText}
              </ConfirmBtn>
            </React.Fragment>
        }
      </ModalFooter>
      {afterFooter && 
        <ModalBody>
          {afterFooter}
        </ModalBody>}
    </Modal>
  )), func: setOpen}
}

export default confirmModal;