import * as React from "react";
import {Row} from "react-bootstrap";
import {InnerFrame, TrainedWrapper} from "./styles";
import {AllPermissions, PolicyContext} from "../../../Policy/Provider";
import {PolicyNameEnum} from "../../../PolicyNameEnum";
import {Checkbox} from "../../../../Elements/CheckboxFormik";
import {connect as formikConnect} from "formik";
import {UserWithCustomer} from "../../Models/UserWithCustomer";
import {connect as reduxConnect} from "react-redux";
import {LabelEnum} from "../../LabelEnum";
import { AppState } from "@Safemate/Store/types";
import { useIntl } from "react-intl";
import {User} from "@Safemate/UserAdmin/Models/User";
import {formatNorwegianDateMin} from "@Safemate/Settings/Indoor/utils";

interface DisableProps{
  isNew: boolean;
  user: User
}

const mapStateToProps = ({ users: { action: {edit: {isNew, user:{user}}}}}: AppState) => {
  return{
    isNew, user
  }
}

const Disable = reduxConnect(mapStateToProps)(
  formikConnect<DisableProps, UserWithCustomer>(
    ({isNew, user, formik: {setFieldValue, values: {user: {deactivated}}}}) => {

      const { formatMessage } = useIntl();

      const deactivatedDate =  user.deactivatedBy && user.deactivatedBy.modifiedDate ? formatNorwegianDateMin(user.deactivatedBy.modifiedDate).split(" ", 2) : null;

      const permissions = React.useContext(PolicyContext) as Map<PolicyNameEnum, AllPermissions>;
      const isAllowed = permissions.has(PolicyNameEnum.ACTIVATE) && permissions.get(PolicyNameEnum.ACTIVATE).generalPermissions.all;

      if (!isAllowed || isNew ) return null;

      return (
        <InnerFrame border>
          <h2>
            {formatMessage({id: LabelEnum.DEACTIVATE_USER, defaultMessage: "Deaktivere bruker"})}
          </h2>

          <Row>
            <TrainedWrapper md={12}>
              <Checkbox
                value={deactivated}
                name={"user.deactivated"}
                id={"user.deactivated"}
                label={formatMessage({id: LabelEnum.DEACTIVATE_CURRENT_USER, defaultMessage: "Deaktiver denne brukeren"})}
                onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                  const value = event.currentTarget.checked;
                  setFieldValue("user.deactivated", value);
                }}
              />{
              user.deactivated && user.deactivatedBy && <span style={{marginLeft: "10px"}}> { `(${formatMessage({id: LabelEnum.DEACTIVATED_BY_NAME, defaultMessage: "Deactivated by"})} ${user.deactivatedBy.name} (${user.deactivatedBy.userId}) ${ user.deactivatedBy.modifiedDate ? deactivatedDate[0] + " " +formatMessage({id: "time", defaultMessage: "kloken"}).toLowerCase() + " " + deactivatedDate[1] : "" })` }</span>
            }
            </TrainedWrapper>
          </Row>
        </InnerFrame>
      )
    }
  )
);

export default Disable;
