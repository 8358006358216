import {DeviceHardwareState} from "../types";
import {Reducer} from "redux";
import {initializeDeviceHardware, selectDeviceHardware} from "../routines";

const deviceHardwareState: DeviceHardwareState = {
  selectedDeviceHardwareId: 0,
  deviceHardwares: []
};

const deviceHardware: Reducer<DeviceHardwareState> = (state: DeviceHardwareState = deviceHardwareState, action) => {
  switch (action.type) {
    case selectDeviceHardware.SUCCESS:
      return {
        ...state,
        selectedDeviceHardwareId: action.payload
      };

    case initializeDeviceHardware.SUCCESS:
      return {
        ...state,
        deviceHardwares: action.payload
      }

    default:
        return state;
  }
};

export default deviceHardware;
