import { Option } from "@Elements/Dropdown/types";
import moment from "moment";
import { MessageDescriptor } from "react-intl";
import { ICustomerWithPolicy } from "./Model/Customer";
import { GlobalRoleName } from "./Policy/GlobalRoleName";
import { SiteTypeEnum } from "./SiteTypeEnum";
import { CustomRole } from "./Model/Role";
import {GlobalRoles} from "@Safemate/GlobalRoles";
import {MarketEnum} from "@Safemate/MarketEnum";

let marketUrls: Map<string,string> = new Map<string, string>();

export const setLocale = (langCode: string) => {
  moment.locale(langCode);
}

export type FormatDate = (
  value: number|string,
  inclTime: boolean,
  inclSeconds: boolean,
  shortYear: boolean,
  prosaDate: boolean,
  timeAgo: boolean,
  formatMessage: (message: MessageDescriptor) => string
) => string;

export type FormatDateMonth = (
  value: number|string,
) => string;


export const formatDate: FormatDate = (val, inclTime, inclSeconds, shortYear, prosaDate, timeAgo, formatMessage) =>{
  const m =  moment(new Date(val));
  let str = '';
  if (shortYear) {
    str = m.format('DD.MM.YY');
  } else if (prosaDate){
    str = m.calendar();
  } else if (timeAgo) {
    str = m.fromNow();
  } else {
    str = m.format('DD.MM.YYYY');
  }

  if(inclTime) {
    if(prosaDate) {
      str += ' ' + formatMessage({id: 'kl', defaultMessage: "kl"});
    }
    if(inclSeconds){
      str += ' ' + m.format("HH:mm:ss");
    }
    else{
      str += ' ' + m.format("HH:mm");
    }
  }
  return str;
};


export const formatDateMonthYearOnly: FormatDateMonth = (val) =>{
  const m =  moment(new Date(val));
  let str = '';
  str = m.format('MM.YYYY');
  
  return str;
};

export const formatYearMonth: FormatDateMonth = (val) =>{
  const m =  moment(new Date(val));
  let str = '';
  str = m.format('YYYY-MM');
  return str;
};

export const formatYearMonthDay: FormatDateMonth = (val) =>{
  const m =  moment(new Date(val));
  let str = '';
  str = m.format('YYYYMMDD');
  return str;
};

export const formatTime = (dateValue: string | number) => {
  if(!dateValue) {
    return '';
  }
  let date: Date;
  if(typeof dateValue == 'string') {
    date = new Date(parseInt(dateValue,10));
  } else if(typeof dateValue == 'number') {
    date = new Date(dateValue);
  }
  else return "";
  
  const options = {hour: "numeric", minute: "numeric", second: "numeric", hour12: false};
  var base =  new Intl.DateTimeFormat("nb-NO", options).format(date);
  return base;
};

export const formatDateForMap = (val: number) => {
  var m =  moment(new Date(val));
  var now =  moment(new Date());
  if (now.diff(m, 'days') > 5) {
    return m.format('DD.MM HH:mm');
  }
  return m.format('ddd HH:mm');
};

export const addUrlParams = (url: string, ...params: Option[]) => {
  let first = true;
  for (let i = 0; i < params.length; i++) {
    const param = params[i];
    if (param.value) {
      if (first) {
        url += '?';
        first = false;
      } else {
        url += '&';
      }
      url += `${param.text}=${encodeURIComponent(param.value)}`;
    }
  }
  return url;
};

export const addRolesToRequest = () => {
  let cookieRoles = getCookie("roles");
  const parsedCookie = cookieRoles ? JSON.parse(cookieRoles) as CustomRole[] : null;
  if(parsedCookie && parsedCookie.length > 0){
    const mappedRoles = parsedCookie.map(role => role.id);
    if (mappedRoles.length > 1) {
      const joinedString = mappedRoles.slice(1).join("&roles=");
      const finalString = mappedRoles[0] + "&roles=" + joinedString;
      return `?roles=${finalString}`;
    } else if (mappedRoles.length === 1) {
      return `?roles=${mappedRoles[0]}`;
    }
  }
  return "";
}

export const getSelectedRoles = () => {
  let cookieRoles = getCookie("roles");
  
  
  const parsedCookie = cookieRoles ? JSON.parse(cookieRoles) as CustomRole[] : [];

  //Clear old cookies
  const filtered = parsedCookie.filter(cookie => typeof(cookie) !== "string");
  setSelectedRolesCookie(filtered);

  return parsedCookie;
}

export const setSelectedRolesCookie = (selectedRoles: CustomRole[]) => {
  setCookie("roles", JSON.stringify(selectedRoles), 30, null);
}

export const getAvailableRoles = (roles: CustomRole[]) => {
  let cookieRoles = getCookie("roles");
  cookieRoles ? cookieRoles = JSON.parse(cookieRoles) : null;
  return roles.map(role => ({
    name: role.roleName,
    value: role.id,
    checked: cookieRoles ? cookieRoles.includes(`${role.id}`) : true
  }));
}

export const parseUrlParams = (url: string) => {
  if(!url) return {};
  const params: any = {};
  const paramArray = url.split("&");
  for(let i = 0; i < paramArray.length; i++){
    const param = paramArray[i].split("=");
    params[param[0]] = param[1];
  }
	return params;
}

export const hashUrlParams = (url: string) => {
  const urlObject = new URL(url);

  const hash = urlObject.hash.slice(2);
  
  const hashPairs = hash.split('&');

  const variables: {[index: string]: string} = {};

  hashPairs.forEach(pair => {
    const [key, value] = pair.split('=');
    variables[key] = value;
  });

  return variables;
}

export const removeHashFromUrl = () => {
  const uri = window.location.toString();
  if (uri.indexOf("#") > 0) {
    var clean_uri = uri.substring(0, uri.indexOf("#"));
    window.history.replaceState({}, document.title, clean_uri);
  }
}

export const setCookie = (c_name: string, value: string, exdays: number, domain: string) => {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  var c_value = escape(value)
      + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString())
          + ((domain == null) ? "" : "; domain=" + domain);
  document.cookie = c_name + "=" + c_value;
};

export const getCookie = (c_name: string) => {
  var i, x, y, ARRcookies = document.cookie.split(";");
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x == c_name) {
      return unescape(y);
    }
  }
  return '';
};


export const isTrackerOnly = (customers: ICustomerWithPolicy[]) => {
  let tracker = false;
  let otherRole = false;
  for(let customer in customers){
    if(customers[customer].policies && customers[customer].policies.roleName === GlobalRoles.TRACKER){
      tracker = true;
    }
        
    if(customers[customer].policies && customers[customer].policies.roleName !== GlobalRoles.TRACKER){
      otherRole = true;
    }
  }
  if(tracker && !otherRole){
      return true;
  }
}

export const isAdmin = (customers: ICustomerWithPolicy[]) => {
  let admin = false;
  for(let customer in customers){
    if(customers[customer].policies && customers[customer].policies.global && customers[customer].policies.roleName === GlobalRoles.SUPERFIRM){
      admin = true;
    }
    break;
  }
  return admin;
}

export const getSiteType = (isAhp: boolean) => {
  return isAhp ? SiteTypeEnum.AHP : SiteTypeEnum.PROFFWEB;
}

export const getZipCodeLength = (market: MarketEnum): number => {
  return market === MarketEnum.ICELAND ? 3 : market === MarketEnum.SWEDEN ? 5 : 4;
}
