
import FieldWrapper, {GenericTextField} from "../FieldWrapper";
import {SettingEnum, SettingPrefixEnum} from "../settingEnum";
import { time } from "@Elements/Form/utils";

// Inputs
export const StaticTooLong = FieldWrapper({
  componentOptions: {
    Component: GenericTextField,
    props: {}
  },
  prefix: SettingPrefixEnum.DEVICE_SETTING,
  policyPrefix: SettingPrefixEnum.NOT_IN_USE,
  field: SettingEnum.STATIC_TOO_LONG,
  postfix: time.hours
});
