import {IndoorLocationState} from "@Safemate/Settings/Store/types";
import {defaultIndoorLocation} from "@Safemate/Settings/IndoorLocation/utils";
import {Reducer} from "redux";
import {
  getIndoorLocationsForDevice,
  getIndoorLocationsForCustomer,
  scanWifiIndoorLocation,
  saveIndoorLocation,
  getCustomersForIndoor, deleteIndoorLocation
} from "@Safemate/Settings/Store/routines";
import {SettingsActionTypes} from "@Safemate/Settings/Store/actionTypes";


const indoorLocationState: IndoorLocationState = {
  indoorLocation: defaultIndoorLocation(0, 0),
  initialIndoorLocation: defaultIndoorLocation(0, 0),
  indoorLocationsDevice: [],
  indoorLocationsCustomer: [],
  loading: true,
  refresh: false,
  scanStart: false,
  scanInProgress: false,
  scanSuccess: false,
  displayMap: false,
  displayEdit: false,
  displayIndoorDevices: false,
  valuesChanged: false,
  customers: []
};

const indoorLocation: Reducer<IndoorLocationState> = (state: IndoorLocationState = indoorLocationState, action: any) => {
  switch (action.type) {

    case getIndoorLocationsForDevice.SUCCESS:
      return {
        ...state,
        indoorLocationsDevice: action.payload,
        loading : false,
      }

    case getIndoorLocationsForCustomer.SUCCESS:
      return {
        ...state,
        indoorLocationsCustomer: action.payload,
        loading : false,
      }

    case scanWifiIndoorLocation.SUCCESS:
      return {
        ...state,
        scanStart: false,
        scanInProgress: true,
        scanSuccess: false,
      }

    case saveIndoorLocation.SUCCESS:
      return {
        ...state,
        valuesChanged: false,
        refresh: !state.refresh
      }

    case deleteIndoorLocation.SUCCESS:
      return {
        ...state,
        valuesChanged: false,
        refresh: !state.refresh
      }

    case scanWifiIndoorLocation.FAILURE:
      return {
        ...state,
        scanStart: true,
        scanInProgress: false,
      }

    case getCustomersForIndoor.SUCCESS:
      return {
        ...state,
        customers: action.payload
      }

    case SettingsActionTypes.SET_INDOOR_LOCATION:
      return {
        ...state,
        indoorLocation: action.payload,
        initialIndoorLocation: JSON.parse(JSON.stringify(action.payload)),
      }

    case SettingsActionTypes.SET_INDOOR_SCAN_START:
      return {
        ...state,
        scanStart: action.payload,
        scanSuccess: false,
        scanInProgress: false,
      }

    case SettingsActionTypes.SET_INDOOR_SCAN_IN_PROGRESS:
      return {
        ...state,
        scanStart: true,
        scanSuccess: false,
        scanInProgress: action.payload,
      }

    case SettingsActionTypes.SET_INDOOR_SCAN_SUCCESS:
      return {
        ...state,
        scanStart: false,
        scanSuccess: action.payload,
        scanInProgress: false,
      }

    case SettingsActionTypes.SET_INDOOR_LOCATION_VALUES_CHANGED:
      return {
        ...state,
        valuesChanged: action.payload,
      }

    case SettingsActionTypes.SET_INDOOR_DISPLAY_MAP:
      return {
        ...state,
        displayMap: action.payload,
        displayIndoorDevices: false,
        displayEdit: false,
      }

    case SettingsActionTypes.SET_INDOOR_DISPLAY_EDIT:
      return {
        ...state,
        displayMap: false,
        displayIndoorDevices: false,
        displayEdit: action.payload,
      }

    case SettingsActionTypes.SET_INDOOR_DISPLAY_INDOOR_DEVICES:
      return {
        ...state,
        displayMap: false,
        displayIndoorDevices: action.payload,
        displayEdit: false,
      }

    default:
      return state;

  }
}

export default indoorLocation;
