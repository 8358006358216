import { Row } from "react-bootstrap";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${props => 
    `
      border: ${props.theme.colors.border};
      padding: ${props.theme.paddings.standard};
    `
  }
`;

export const SensorWrapper = styled.div`
  display: flex; 
  flex-direction: column;
`;

export const SensorHeader = styled.span`
  font-size: 20px;
  font-weight: 500;
`;

export const FamilyWrapper = styled.div`
  margin-top: 5px;
  display: inline-flex;
  flex-direction: column;
  align-self: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-left: 2px solid gray;
`;

export const Centered = styled.div`
  text-align: center;
`;

export const LoaderWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  svg {
    height: 30px;
    width: 30px;
  }
`;

export const HeaderRow = styled(Row)`
  padding: 15px;
  width: 100%;
  text-align: center;
`;

export const ButtonWrap = styled.div`
  min-height: 50px; 
  margin: 10px auto; 
  display: flex;
  justify-content: flex-end;
  padding-top: ${({
    theme: {
      paddings: { standard }
    }
  }) => standard};

  & > * {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: ${({
      theme: {
        margins: { standard }
      }
    }) => standard};
  }
  & > :last-child {
    margin-right: 0;
  }
`;

export const DescriptionParagraph = styled.p`
  padding: 15px;
`;

export const SaveWrap = styled.div`
  padding: 10px;
`;

export const StyledP = styled.p`
  margin: 0 ${props => props.theme.margins.standard} 0 0;
`;

export const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
`;