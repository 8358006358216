import * as React from "react";
import {connect} from "react-redux";

import partnerDashboardReducer from "./Store/Reducer";
import partnerDashboardSaga from "./Store/Saga";
import {initialize} from "./Store/routines";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";
import Dashboard from "./Dashboard";
import configureProvider from "../DefaultProvider";
import { AppState } from "@Safemate/Store/types";


interface PartnerDashboardProps{
  initialize: Routine<ActionFunctionAny<Action<any>>>;
  isPartner: boolean;
}

const mapDispatchToProps = {
  initialize
}

const mapStateToProps = ({ appData: { isPartner } }: AppState) => {
  return {
    isPartner
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(
  ({initialize}: PartnerDashboardProps) => {

    React.useEffect(() => {
      initialize();
    }, []);

    return(
      <Dashboard/>
    )
  }
)