import { useIntl } from "react-intl";
import {default as LogIcon} from "../../../../Elements/Icon/Icons/Log";
import {LabelEnum} from "../../LabelEnum";
import * as React from "react";

interface LogProps{
  userId: number
}

const Log = ({userId}: LogProps) => {

  const { formatMessage } = useIntl();

  return(
    <LogIcon
      tabIndex="0"
      title={formatMessage({id: LabelEnum.LOG, defaultMessage: "Logg"})}
      size="medium"
      onClick={(event: any) => {
        event.stopPropagation();
        location.hash = `user_audit_log/${userId}`;
      }}
      onKeyDown={(event: any) => {
        if (event.key === 'Enter') 
          location.hash = `user_audit_log/${userId}`;
      }}
      inline
    />
  )
}

export default Log;
