import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import userAuditLog from "./userAuditLog";
import deviceAuditLog from "./deviceAuditLog";

function* MyPageSaga(){
  yield all([
    fork(initializationSaga),
    fork(userAuditLog),
    fork(deviceAuditLog)
  ])
}
export default MyPageSaga;
