import { combineReducers } from "redux";
import { RootState } from "../types";
import textLabel from "./textLabel";
import country from "@Safemate/TranslationModule/TranslateOverlay/Store/Reducer/country";
import text from "./text";
import filter from "./filter";

export default combineReducers<RootState>({
  textLabel,
  filter,
  text,
  country
});