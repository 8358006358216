import {all, fork} from "redux-saga/effects";
import initializationSaga from "./initialization";
import customerSaga from "./customer";
import userSaga from "./user";
import invoicesSaga from "./invoices";
import settingsSaga from "./settings";
import countrySaga from "./country";

function* SettingsSaga(){
  yield all([
    fork(initializationSaga),
    fork(customerSaga),
    fork(userSaga),
    fork(invoicesSaga),
    fork(settingsSaga),
    fork(countrySaga)
  ])
}
export default SettingsSaga;
