import * as React from "react";

export enum EventType{
    CLICK = "click",
    KEYBOARD = "keydown"
  }

export function useComponentVisible<T, S>(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = React.useState(initialIsVisible);
    const ref = React.useRef<T & HTMLElement>(null);
    const selectRef = React.useRef<S & HTMLElement>(null);
  
    const handleClickOutside = (event: MouseEvent | KeyboardEvent) => {

      if(event.type === EventType.KEYBOARD){
        const keyboardEvent = event as KeyboardEvent;
        if(keyboardEvent.key !== "Escape" && keyboardEvent.key !== "Tab")
          return;
      }
      if (ref.current && !ref.current.contains(event.target as Element) 
        && selectRef.current && !selectRef.current.contains(event.target as Element)) {
        setIsComponentVisible(false);
      }
    };
  
    React.useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      document.addEventListener('keydown', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
        document.removeEventListener('keydown', handleClickOutside, true);
      };
    });
  
    return { selectRef, ref, isComponentVisible, setIsComponentVisible };
  }

  export function useTemp<T>(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = React.useState(initialIsVisible);
    const ref = React.useRef<T & HTMLElement>(null);
  
    const handleClickOutside = (event: MouseEvent | KeyboardEvent) => {

      if(event.type === EventType.KEYBOARD){
        const keyboardEvent = event as KeyboardEvent;
        if(keyboardEvent.key !== "Escape" && keyboardEvent.key !== "Tab")
          return;
      }
      if (ref.current && !ref.current.contains(event.target as Element)) {
        setIsComponentVisible(false);
      }
    };
  
    React.useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      document.addEventListener('keydown', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
        document.removeEventListener('keydown', handleClickOutside, true);
      };
    });
  
    return { ref, isComponentVisible, setIsComponentVisible };
  }