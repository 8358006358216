import * as React from "react";
import {Form, Formik, } from "formik";
import {connect} from "react-redux";
import {Routine} from "redux-saga-routines";
import {Action, ActionFunctionAny} from "redux-actions";

import { mapValues} from "./utils";
import { RootState, Customer} from "../Store/types";
import {savePrivateBilling} from "../Store/routines";
import {Footer} from "./footer";

import UnsavedCheck from "../../SaveNeeded/unsavedModal";
import { Email, FirstName, LastName, Zip, Post, Reference, Land , Invoice, AddressOne, AddressTwo, BillingInformation } from "./fields";
import { BillingSettings } from "./types";
import { AppState } from "@Safemate/Store/types";

interface FormProps{
  savePrivateBilling: Routine<ActionFunctionAny<Action<any>>>;
  customer: Customer;
  pptyId: number;
  marketProgId: number;
}

const FormComp = ({ savePrivateBilling, customer, pptyId, marketProgId }: FormProps) => {
     
      return(
        <Formik
          initialValues={mapValues(customer, pptyId, marketProgId)}
          enableReinitialize
          onSubmit={(values: BillingSettings) => savePrivateBilling(values)}
        >
        {() => 
            <Form id="form">
              <BillingInformation/>
              <FirstName/>
              <LastName/>
              <Reference />
              <AddressOne />
              <AddressTwo />
              <Zip/>
              <Post/>
              <Land/>
              <Invoice/>
              <Email/>
              <Footer />
              <UnsavedCheck/>
            </Form>
        }
          </Formik>
        
      )
  }

const mapStateToProps = ({ billing: {customer: { pptyId, customer, marketProgId }}}: AppState) => {
  return{
    pptyId,
    customer,
    marketProgId
  }
};

const mapDispatchToProps = {
  savePrivateBilling
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComp);
